import GraphQL from '@/util/GraphQL'

export default {
  namespaced: true,
  
  state: {
    measures: []
  },

  getters: {
    getterGlobalMeasures: (state) => (filters = null) => {
      return filters ? state.measures.findAllInArray(filters) : state.measures
    },

    getterGlobalMeasure: (state) => (filters = null) => {
      return filters ? state.measures.findInArray(filters) : false
    },    

  },

  actions: {
    loadGlobalMeasures(context) {
      const query = `
        {
          measures (
            orderBy: [{ column: "title", order: "ASC" }]
          ) {
            id
            title
            slug
            is_package
            type_measure_id
            hide_in_flexible_path
            hide_in_compliance_margin_control
            study_id     
            is_pre_emptive
            has_larger_pv_included
            exclude_in_combination_reason
            exclude_measures
            is_package
            measures {
              id
            }
          }
        }
      `
      GraphQL({ query })
        .then(({ data }) => {
          context.commit('setGlobalMeasures', { measures: data.measures })
        })
    }
  },

  mutations: {
    setGlobalMeasures(state, { measures }) {
      state.measures = measures
    }
  }
}
