<template>
  <DashboardCard
    title="Searchs by devices"
  >
    <div
      class="psui-w-full psui-p-6 psui-flex psui-items-center psui-space-x-3 psui-relative cursor-pointer"
      @click.stop="showTable = !showTable"
    >
      <span 
        class="btn-arrow material-icons-round psui-text-gray-50 psui-flex-none transition-all psui-transform psui-cursor-pointer"
        :class="showTable ? 'open psui-rotate-90' : 'closed psui-rotate-0'"
      >
        chevron_right
      </span>
      
      <div class="psui-w-full psui-flex psui-flex-col">
        <h3 class="psui-text-gray-80 psui-text-h5 psui-font-bold">
          {{ getMappedItems[selectedListIndex].title }}
        </h3>
      </div>
    </div>

    <div
      v-if="showTable"
      class="w-full mt-4"
    >
      <table class="table table-sm">
        <thead>
          <tr>
            <th>Searchs count</th>
            <th>Device ID</th>
            <th>Platform</th>
            <th>Browser</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, indexHere) in getMappedItems[selectedListIndex].items"
            :key="`listItem_${indexHere}`"
          >
            <td>{{ item.searchs_count }}</td>
            <td>{{ item.device_id }}</td>
            <td>{{ item.user_device_platform }}</td>
            <td>{{ item.user_device_browser }}</td>
            <td>{{ item.user_email }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </DashboardCard>
</template>

<script>
import { colors } from '@/business-logic/constants/chartDefaultOptions'
import { MathHelpers } from '@igortrindade/lazyfy'
export default {
  name: 'SearchsDevices',
  props: ['timeFrameSelected', 'jurisdictionSelected'],
  data() {
    return {
      searchs: [],
      selectedListIndex: 0,
      colors,
      showTable: false
    }
  },
  computed: {
    getTotal() {
      return this.getMappedItems.reduce((acc, i) => acc + i.total, 0)
    },
    getMappedItems() {
      return [ 
        {
          title: "Searchs by devices",
          total: this.searchs.reduce((acc, i) => acc + parseInt(i.count), 0),
          items: this.searchs
        }
      ]
    }
  },
  watch: {
    timeFrameSelected: {
      deep: true,
      handler() {
        this.getData()
      } 
    },
    '$route.path': {
      deep: true,
      handler() {
        this.getData()
      } 
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$api.get('/api/admin/city/dashboard/get_data/searchs', { params: {
        init: this.timeFrameSelected.init, 
        end: this.timeFrameSelected.end,
        jurisdiction_id: this.$route.params.jurisdiction_id
      }})
        .then(({ data }) => {
          this.searchs = data.searchs
        })
    },

    getChartOptions() {
      const self = this
      return {
        series: this.getMappedItems.map((item) => MathHelpers.getValueOrMinPercentage(this.getTotal, item.total, 1) ),
        colors: colors,
        chart: {
          type: 'donut',
          toolbar: { show: false, },
        },
        tooltip: {
          followCursor: false,
          fixed: {
            enabled: true,
            position: "topRight",
            offsetX: 0,
            offsetY: 0,
          },
          custom: function ({ seriesIndex }) {
            const item = self.getMappedItems[seriesIndex]
            return `
              <div class="psui-flex" style="background-color: ${ colors[seriesIndex] } !important;">
                <div class="apexcharts-tooltip-content psui-w-full psui-bg-white psui-p-4">
                  <h4 class="psui-text-gray-80 psui-text-xsmall psui-font-bold">${ item.title }</h4>
                  <div class="psui-w-full psui-flex psui-items-center">
                    <h2 class="psui-text-gray-80 psui-text-small">${ self.formatUnits( item.total ) } policies</h2>
                  </div>
                </div>
              </div>
            `
          }
        }
      }
    },

    getPercentage(value) {
      return 100 / this.getTotal * value
    },

    setSelectedList(index) {
      this.selectedListIndex = index
      this.showTable = true
    }
  }
}
</script>