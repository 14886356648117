<template>
  <div class="policy-form">
    <h2 
      class="ts--accent--book--3 mb-3"
      :class="`${newPolicyTitle ? 'text-bluebrand' : 'text-gray03'}`"
    >
      {{ 'Create a new policy' | policyTextRephrase }}
    </h2>
    <InputWithLabel
      v-model="newPolicyTitle"
      :label="'Policy Name' | policyTextRephrase"
      :validation="validation.title"
      @keydown.enter="submit"
    />
    <transition
      name="fade"
      mode="out-in"
    >
      <PsButton
        v-if="newPolicyTitle.length > 0" 
        class="mt-5" 
        size="medium" 
        :label="submitFormLabel"
        :loading="isSubmiting"
        @click="submit()"
      />
    </transition>
  </div>
</template>

<script>
import InputWithLabel from '@/components/general/InputWithLabel.vue'
import PolicyApiService from '@/services/api/PolicyApiService'


function initialState() {
  return {
    newPolicyTitle: '',
    isSubmiting : false,
    validation: {
      title: {
        hasError: false,
        label: ''
      },
    }
  }
}
export default {
  name: 'ExistingBuildingPolicyFormCreate',
  components: { InputWithLabel },
  props: {
    submitFormLabel: {
      type: String,
      default: 'Create and Save'
    }
  },
  data: () => {
    return initialState()
  },
  watch:{
    newPolicyTitle: {
      immediate: true,
      handler: async function(value) {
        if (!value) {
          return
        }
        const isProduction = Boolean(window.location.hostname === 'explorer.localenergycodes.com')
        if (!isProduction) {
          const appendQa = Boolean(window.location.hostname.includes('ddev-'))
          const titlePrefix = `[PS${appendQa ? ' QA': ''}] `
          if (this?.newPolicyTitle && titlePrefix.startsWith(this.newPolicyTitle) && this.newPolicyTitle.length < titlePrefix.length) {
            this.newPolicyTitle = titlePrefix
          } if (!this.newPolicyTitle.startsWith(titlePrefix)) {
            this.newPolicyTitle = `${titlePrefix}${this.newPolicyTitle || ''}`
          }
        }
      }
    }
  },
  methods: {
    reInitState() {
      Object.assign(this.$data, initialState())
    },
    submit() {      
      if (this.isSubmiting) return
      this.isSubmiting = true
      this.validationClear('title')     

      if (!this.newPolicyTitle) {
        this.validation.title.hasError = true
        this.validation.title.label = 'Please add a policy name.'
        this.isSubmiting = false
      } else if (this.newPolicyTitle && this.newPolicyTitle.length < 3) {
        this.validation.title.hasError = true
        this.validation.title.label = 'A policy name should have at least three characters.'
        this.isSubmiting = false
      } else {
        this.storePolicy()  
      }    
    },
    
    async storePolicy() {

      const payload = {
        title: this.newPolicyTitle,
        user_id: this.$store.state.auth?.loggedUser?.id,
        jurisdiction_id: this.$store.getters['lastJurisdictionVisited'].id,
      } 

      try {
        const policy_id = await this.$store.dispatch('policy/createPolicy', { policy : payload  })
        const policy = await PolicyApiService.getNewPolicy(policy_id, true)
        this.$emit('store-success', policy)
        this.isSubmiting = false
      } catch (err) {
        console.error(err)
        this.isSubmiting = false
      }        
    }

  }
}
</script>

<style>

</style>