<template>
  <div class="w-full flex flex-col bg-ash-200">
    <div class="w-full flex flex-col bg-ash-200">
      <div class="w-full p-6 px-8 border-b border-ash-200 bg-white flex justify-between">
        <PageTitle title="User Analysis Dashboard" />
      </div>
    </div>

    <div class="w-full h-auto flex px-8 py-4 justify-end">
      <PsDropdown ref="dropdownTimeFrame">
        <template #dropdownTrigger>
          <div class="w-full flex space-x-4">
            <InputWithLabel
              :value="timeFrameSelected.init"
              class="mt-6"
              :label="'Init'"
              :mask="'####-##-##'"
              placeholder="YYYY/MM/DD"
              @blur="timeFrameSelected.init = $event"
            />
            <InputWithLabel
              :value="timeFrameSelected.end"
              class="mt-6"
              :label="'End'"
              :mask="'####-##-##'"
              placeholder="YYYY/MM/DD"
              @blur="timeFrameSelected.end = $event"
            />
          </div>
        </template>
        <template #items>
          <div class="w-full flex flex-col bg-white p-3 space-y-3 rounded">
            <PsButton
              v-for="timeFrame in getTimeFramesForAnalysis" 
              :key="timeFrame.label" 
              :label="timeFrame.label"
              @click="setTimeFrame(timeFrame)"
            />
          </div>
        </template>
      </PsDropdown>
    </div>
    
    
    <div class="w-full h-auto grid grid-cols-2 gap-4 p-4">
      <AdminDashboardTopJurisdiction 
        class="w-1/2 psui-w-full h-auto psui-rounded-md psui-bg-white psui-shadow-elevation-5 p-8"
        :time-frame-selected="timeFrameSelected"
      />
      <AdminDashboardTopJurisdictionVisited 
        class="w-1/2 psui-w-full h-auto psui-rounded-md psui-bg-white psui-shadow-elevation-5 p-8"
        :time-frame-selected="timeFrameSelected"
      />
      <AdminDashboardUserJurisdictionRecorrentVisits 
        class="w-1/2 psui-w-full h-auto psui-rounded-md psui-bg-white psui-shadow-elevation-5 p-8"
        :time-frame-selected="timeFrameSelected"
      />
      <AdminDashboardTopClimateZones 
        class="w-1/2 psui-w-full h-auto psui-rounded-md psui-bg-white psui-shadow-elevation-5 p-8"
        :time-frame-selected="timeFrameSelected"
      />
      <AdminDashboardTopPoliciesVisited 
        class="w-1/2 psui-w-full h-auto psui-rounded-md psui-bg-white psui-shadow-elevation-5 p-8"
        :time-frame-selected="timeFrameSelected"
      />
      <AdminDashboardTopUsersBySession 
        class="w-1/2 psui-w-full h-auto psui-rounded-md psui-bg-white psui-shadow-elevation-5 p-8"
        :time-frame-selected="timeFrameSelected"
      />
      <AdminDashboardSessionsUniqueUsers 
        class="w-1/2 psui-w-full h-auto psui-rounded-md psui-bg-white psui-shadow-elevation-5 p-8"
        :time-frame-selected="timeFrameSelected"
      />
      <AdminDashboardMobileInfo 
        class="w-1/2 psui-w-full h-auto psui-rounded-md psui-bg-white psui-shadow-elevation-5 p-8"
        :time-frame-selected="timeFrameSelected"
      />
      <AdminDashboardOsInfo 
        class="w-1/2 psui-w-full h-auto psui-rounded-md psui-bg-white psui-shadow-elevation-5 p-8"
        :time-frame-selected="timeFrameSelected"
      />
    </div>
  </div>
</template>

<script>
import AdminDashboardMobileInfo from './AdminDashboardMobileInfo.vue'
import AdminDashboardOsInfo from './AdminDashboardOsInfo.vue'
import AdminDashboardTopJurisdiction from './AdminDashboardTopJurisdiction.vue'
import AdminDashboardTopJurisdictionVisited from './AdminDashboardTopJurisdictionVisited.vue'
import AdminDashboardTopClimateZones from './AdminDashboardTopClimateZones.vue'
import AdminDashboardTopPoliciesVisited from './AdminDashboardTopPoliciesVisited.vue'
import AdminDashboardTopUsersBySession from './AdminDashboardTopUsersBySession.vue'
import AdminDashboardUserJurisdictionRecorrentVisits from './AdminDashboardUserJurisdictionRecorrentVisits.vue'
import AdminDashboardSessionsUniqueUsers from './AdminDashboardSessionsUniqueUsers.vue'
import getTimeFramesForAnalysis from '@/util/getTimeFramesForAnalysis'
export default {
  name: 'AdminDashboardIndex',
  components: { 
    AdminDashboardMobileInfo, 
    AdminDashboardOsInfo, 
    AdminDashboardTopJurisdiction, 
    AdminDashboardTopJurisdictionVisited, 
    AdminDashboardTopClimateZones, 
    AdminDashboardTopPoliciesVisited,
    AdminDashboardTopUsersBySession,
    AdminDashboardUserJurisdictionRecorrentVisits,
    AdminDashboardSessionsUniqueUsers,
  },
  data() {
    return {
      getTimeFramesForAnalysis,
      timeFrameSelected: getTimeFramesForAnalysis[5]
    }
  },
  methods: {
    setTimeFrame(timeFrame) {
      this.timeFrameSelected = timeFrame
      this.$refs.dropdownTimeFrame.close()
    }
  }
}
</script>