<template>
  <ul>
    <li 
      class="pt-6 pb-8 pl-2 pr-4 border-b border-deepsky-200 " 
    >
      <p class="text-sm font-bold psui-text-gray-50 psui-pb-4 psui-uppercase tracking-wide">
        Guides
      </p>
      <p
        class="text-base psui-text-blue-60 transition-all hover:opacity-70 cursor-pointer psui-flex"
        @click="openQuickGuideUrl"
      >
        Quick Guide
        <PsIcon
          icon="open_in_new"
          size="22"
          color="psui-text-gray-50"
          icon-classes="psui-pl-2 psui-text-gray-50"
        />
      </p>
      <p
        class="text-base psui-text-blue-60 pt-4 transition-all hover:opacity-70 cursor-pointer psui-flex"
        @click="openKeyConceptsUrl"
      >
        Key Concepts for 2022 Code Cycle
        <PsIcon
          icon="open_in_new"
          size="22"
          color="psui-text-gray-50"
          icon-classes="psui-pl-2 psui-text-gray-50"
        />
      </p>
    </li>
    <li 
      class="pt-10 pb-8 pl-2 pr-4 border-b border-deepsky-200 " 
    >
      <p class="text-sm font-bold psui-text-gray-50 psui-pb-4 psui-uppercase tracking-wide">
        Methodology and Sources
      </p>
      <p
        class="text-base psui-text-blue-60 transition-all hover:opacity-70 cursor-pointer"
        @click="onClickBuildingStock"
      >
        Building Stock
      </p>
      <p
        class="text-base psui-text-blue-60 pt-4 transition-all hover:opacity-70 cursor-pointer"
        @click="onClickResultsSources"
      >
        Results Sources
      </p>
      <p
        class="text-base psui-text-blue-60 pt-4 transition-all hover:opacity-70 cursor-pointer"
        @click="onClickUtilityRates"
      >
        Utility Rate Info
      </p>
    </li>
    <li 
      class="pt-10 pl-2 pr-4" 
    >
      <p class="text-sm font-bold psui-text-gray-50 psui-uppercase tracking-wide">
        Help
      </p>
      <PsTooltip>
        <template #trigger>
          <p
            class="text-base psui-text-blue-60 transition-all hover:opacity-70 cursor-pointer psui-pt-4 psui-flex"
            @click="openAssistanceWithReachCodesUrl"
          >
            Assistance with Reach Codes
            <PsIcon
              icon="open_in_new"
              size="22"
              icon-classes="psui-pl-2 psui-text-gray-50"
            />
          </p>
        </template>
        <template #content>
          You will be redirected to the Local Energy Codes website
        </template>
      </PsTooltip>
      <p
        class="text-base psui-text-blue-60 transition-all hover:opacity-70 cursor-pointer psui-pt-4"
        @click="openAssistance"
      >
        Assistance with this tool
      </p>
      <p
        class="text-base psui-text-gray-60 psui-pt-4"
      >
        You can also send an e-mail to <br> 
        contact@policystudio.co
      </p>
    </li>
  </ul>
</template>

<script>
import { GA_LABELS } from '@/mixins/GaEventsMixin'
import { utilityRatesOptions, resultsSourcesOptions } from '@/util/Drawers'

export default {
  name: 'SidebarMenuHelp',
  methods: {
    openChangeLog() {     
      this.$eventBus.$emit('openDrawerChangelog')
    },    
    openUrl(url) {
      window.open(url)
    },
    openQuickGuideUrl() {
      this.openUrl('https://intercom.help/explorer-local-energy-codes/en/collections/3076245-quick-guide')
      this.gaEvent(GA_LABELS.MENU_INFO_QUICK_GUIDE, { value: 'MENU_INFO_QUICK_GUIDE' })
    },
    openKeyConceptsUrl() {
      this.openUrl('https://intercom.help/explorer-local-energy-codes/en/collections/3517138-new-construction-quick-guide')
      this.gaEvent(GA_LABELS.MENU_INFO_NEW_CONSTRUCTION_QUICK_GUIDE, { value: 'MENU_INFO_NEW_CONSTRUCTION_QUICK_GUIDE' })
    },
    openAssistanceWithReachCodesUrl() {
      this.openUrl('https://localenergycodes.com/content/contact-us')
    },
    onClickBuildingStock() {
      this.$eventBus.$emit('openDrawerContent', { type: 'helper', slug: 'building-stock-estimation-methodology'})
    },
    onClickResultsSources() {
      this.$eventBus.$emit('openDrawerContent', resultsSourcesOptions(this.$route))
    },
    onClickUtilityRates() {
      this.$eventBus.$emit('openDrawerContent', utilityRatesOptions(this.$route) )
    },
    openAssistance() {
      window.Intercom('show')
    },
  }
}
</script>

<style scoped>
  p {
    max-width: 400px;
  }
  
</style>