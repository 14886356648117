

export default (projectionsByYears) => {

  if (!projectionsByYears || projectionsByYears.length === 0) {
    return []
  }
  
  const firstProjectionsByYear = projectionsByYears.length && Array.isArray(projectionsByYears[0]) ? projectionsByYears[0] : []

  const keysToReduce = [
    'forecast_emissions_savings',
    'forecast_initial_cost',
    'forecast_kwh_savings',
    'forecast_lifecycle_savings',
    'forecast_therms_savings',
    'forecast_units_affected',
    'city_wide_subsidy_needed',
    'city_wide_five_year_payback',
    'city_wide_subsidy_needed_care',
    'city_wide_five_year_payback_care',
  ]
  
  for(const [index, projectionByYear] of firstProjectionsByYear.entries()) {
    
    for(const key of keysToReduce) {
      projectionByYear[key].current = projectionsByYears.reduce((acc, curr) => {
        return acc + curr[index][key].current
      }, 0)
      projectionByYear[key].cummulative = projectionsByYears.reduce((acc, curr) => {
        return acc + curr[index][key].cummulative
      }, 0)
    }
  }
  
  // console.log(firstProjectionsByYear)
  return firstProjectionsByYear
  
}