export default {
  setContentContainerWidth(state, width) {
    if (width) {
      state.contentContainerWidth = width      
    }
  },
  setFilterIsNotLoaded(state, filter) {
    const filters = state.filtersToWaitForResults.filter((item) => item != filter)
    filters.push(filter)
    state.filtersToWaitForResults = filters
  },

  setFilterIsLoaded(state, filter) {
    state.filtersToWaitForResults = state.filtersToWaitForResults.filter((item) => item != filter)
  },

  setSidebarPolicyTooltipShouldHide(state, status) {
    state.sidebarPolicyTooltipShouldHide = status
  },
  setRedirectTo(state, { route = null, event = '' } = {}) {
    state.redirectTo = route
    state.redirectToEventToDispatchAfter = event
  },
  setDeviceInfo(state, { user_device, is_admin, is_team, is_dev, log_events,feature_flags }) {
    state.deviceInfo = {
      ...user_device,
      feature_flags,
      is_admin,
      is_team,
      is_dev,
      log_events
    }
    state.isDeviceInfoLoaded = true
  },
  setAdminAndTeamDevices(state, adminAndTeamDevices) {
    state.adminAndTeamDevices = adminAndTeamDevices
  },
  setComponentStructureTracing(state, componentStructureTracing) {
    state.componentStructureTracing = componentStructureTracing
  },
  setQaHotKeyPressed(state, qaHotKeyPressed) {
    state.qaHotKeyPressed = qaHotKeyPressed
  },
  setDebugHotKeyPressed(state, debugHotKeyPressed) {
    state.debugHotKeyPressed = debugHotKeyPressed
  }
}
