<template>
  <div class="w-full  flex flex-col">
    <h4 class="psui-text-h5 psui-text-gray-80 font-bold whitespace-nowrap">
      Sessions / Unique Users
    </h4>
    <div class="block w-full">
      <ApexChartGeneral 
        :options="getChartOptions()"
        :palette-color-index="0"
      />
    </div>

    <div class="w-full flex justify-between">
      <div class="psui-flex psui-space-x-2">
        <PsChartLegend
          v-for="(item, index) in items" 
          :key="`AdminDashboardSessionsUniqueUsers_${index}`"
          :text="item.label"
          :total="formatUnits(item.value)"
          :percentage="`${formatNumber(getPercentage(item.value))}`"
          :dot-color="{ backgroundColor: colors[index] }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { colors } from '@/business-logic/constants/chartDefaultOptions'
export default {
  name: 'AdminDashboardSessionsUniqueUsers',
  props: ['timeFrameSelected'],
  data() {
    return {
      colors,
      items: []
    }
  },
   computed: {
    
    getTotal() {
      return this.top_jurisdictions_with_policies.reduce((acc, item) => acc + parseInt(item.policies_count), 0)
    },
  },

  watch: {
    timeFrameSelected: {
      deep: true,
      handler() {
        this.getData()
      } 
    }
  },

  mounted() {
    this.getData()
  },

  methods: {

    getData() {
      this.$api.get('/api/admin/dashboard/get_data/sessions_unique_users', { params: {
        init: this.timeFrameSelected.init, 
        end: this.timeFrameSelected.end,
        onlyLogEventsUsers: this.onlyLogEventsUsers
      }})
        .then(({ data }) => {
          this.items = data.items
        })
    },

    getChartOptions() {
      const self = this
      return { 
        series: this.items.map((serie) => parseInt(serie.value)),
        colors: colors,
        chart: {
          type: 'donut',
          toolbar: { show: false, },
        },
        tooltip: {
          custom: function ({ seriesIndex }) {
            return `
              <div class="psui-flex" style="background-color: ${ colors[seriesIndex] } !important;">
                <div class="apexcharts-tooltip-content psui-w-full psui-bg-white psui-p-4">
                  <h4 class="psui-text-gray-80 psui-text-xsmall psui-font-bold">${ self.items[seriesIndex].label }</h4>
                </div>
              </div>
            `
          }
        }
      }
    },

    setOnlyLogEventsUsers() {
      this.onlyLogEventsUsers = !this.onlyLogEventsUsers
      this.getData()
    },

    getPercentage(value) {
      return 100 / this.items.reduce((acc, i) => acc + i.value, 0) * value
    },
  }
}
</script>