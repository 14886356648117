<template>
  <BaseModal
    ref="editUserDeviceModal"
    :show-modal-parent="showModal"
    modal-dialog-classes="w-full max-w-sm"
    title="Edit User device"
    @close="showModal = false"
  >
    <div class="w-full flex flex-col items-start mt-6">
      <PsInputSelect
        label="Device belongs to:"
        :value="user_device.user_id"
        :items="users"
        layout="default"
        key-label="email"
        key-value="id"
        @input="user_device.user_id = $event"
      />

      <p
        class="text-red font-bold cursor-pointer mt-2"
        @click="user_device.user_id = null"
      >
        Clear user
      </p>

      <PsButton
        class="psui-mt-6"
        label="Update"
        size="big"
        @click="update()" 
      />
    </div>
  </BaseModal>
</template>

<script>
export default {
  name: 'AdminUserDeviceEditModal',
  data() {
    return {
      showModal: false,
      user_device: {
        id: null,
        user_id: null,
      },
      users: []
    }
  },
  mounted() {
    this.getUsers()
    this.$eventBus.$on('openAdminUserDeviceEditModal', (user_device) => {
      this.user_device = { ...user_device }
      this.showModal = true
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openAdminUserDeviceEditModal')
  },
  methods: {
    getUsers() {
      const query = `
        {
           users (
            orderBy: [
              { column: "is_admin", order: "DESC" },
              { column: "email", order: "ASC" },
            ]
           ) {
            id
            email
           }
        }
      `

      this.$graphql({ query, caller: 'AdminUserDeviceEditModal' })
        .then(({ data }) => {
          this.users = data.users
        })
    },

    update() {
      this.$api.post(`/api/admin/user_devices/update`, this.user_device)
        .then(() => {
          this.showModal = false
          this.$emit('update')
        })
    }
  }
}
</script>

<style>

</style>