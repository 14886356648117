<template>
  <div class="w-full flex flex-col space-y-4">
    <DashboardCard
      v-for="(itemTypePrototype, itemTypePrototypeIndex) in getUniqueTypePrototypesItems"
      :key="`itemTypePrototypeIndex` + itemTypePrototypeIndex"
    >
      <PoliciesProfileTypePrototypeItem
        :get-mapped-policies="getMappedPolicies"
        :item-type-prototype="itemTypePrototype"
        :item-type-prototype-index="itemTypePrototypeIndex"
      />
    </DashboardCard>
  </div>
</template>

<script>
import { ArrayHelpers } from '@igortrindade/lazyfy'
import PoliciesProfileTypePrototypeItem from './PoliciesProfileTypePrototypeItem'
export default {
  components: {
    PoliciesProfileTypePrototypeItem
  },
  props: ['policies', 'getMappedPolicies'],
  computed: {

    getUniqueTypePrototypesItems() {
      return ArrayHelpers.uniqueByKey(this.getMappedPolicies, 'type_prototype_id')
    },
  },

  methods: {
  }
}
</script>