<template>
  <BaseDrawer
    ref="baseDrawer"
    title="Select Policies to Compare"
    class="w-full"
    :hierarchy="DRAWER_HIERARCHY.SECONDARY"
  >  
    <h4
      v-if="comparisonJurisdiction && userPoliciesByComparisonJurisdiction.length > 0"
      class="subtitle mt-2 text-header-4 text-ash-500 mb-8"
    >
      Policies for 
      <span class="text-header-4 text-deepsky-500 border-gray05 font-normal">
        {{ comparisonJurisdiction | editJurisdictionTitleTypeBasedOnJurisdictionType('title_type') }}
      </span>
    </h4>    
    <div
      v-else-if="comparisonJurisdiction && userPoliciesByComparisonJurisdiction.length == 0"
      class="w-full"
    >
      <h2 class="text-header-3 text-ash-400">
        You have no saved policies to add for this jurisdiction.
      </h2>
    </div>
    <div
      v-if="comparisonJurisdiction && userPoliciesByComparisonJurisdiction.length > 0"
      class="w-full"
    >
      <AppTabHeader
        :items="showPoliciesByOptions"
        :selected.sync="showPoliciesBy"
        class="mb-8"
      />

      <div class="w-full flex mb-3">
        <div class="flex items-center opacity-100-all-childrens-on-hover mr-auto">
          <h3 class="text-header-2 font-bold text-gray02 ml-3">
            Policy Name
          </h3>
          <button
            class="focus:outline-none"
            :class="{ 'opacity-0' : orderBy.column != 'title' }"
          >
            <feather
              size="16"
              :type="orderBy.direction == 'asc' ? 'arrow-down' : 'arrow-up'"
              class="text-blue ml-2 cursor-pointer"
              @click="setOrderColumn('title')"
            />
          </button>
        </div>
        <div class="flex items-center opacity-100-all-childrens-on-hover">
          <h3 class="text-header-2 font-bold text-gray02 psui-pr-2">
            Last Update
          </h3>
          <button
            class="focus:outline-none"
            :class="{ 'opacity-0' : orderBy.column != 'updated_at' }"
          >
            <feather
              size="16"
              :type="orderBy.direction == 'asc' ? 'arrow-down' : 'arrow-up'"
              class="text-blue ml-2 cursor-pointer"
              @click="setOrderColumn('updated_at')"
            />
          </button>
        </div> 
      </div>
      <div 
        v-for="policy in policiesFiltered" 
        :key="policy.id"
        class="w-full flex py-4 border-b border-deepsky-200 last:border-b-0 text-gray-04 opacity-100-all-childrens-on-hover"
      >
        <div class="pl-1 flex items-center mr-auto">
          <Checkbox
            class="mr-4 cursor-pointer transition-all"              
            :label="`${policy.title}`"
            :value="checkIfPolicyIsOnComparison(policy.id)"
            label-classes="text-ash-600 text-p-2"
            @change="onTooglePolicy(policy.id)"
          />
        </div>
        <div class="w-20 pl-4">
          <feather
            v-if="$store.getters['policy/getterPolicyType'](policy.id) == POLICY_TYPES.SHARED.value"
            v-tooltip="`Shared with me`"
            type="users"
            class="text-ash-300"
          />
        </div>
        <div class="w-1/5 flex items-center">
          <h3 class="text-p-2 text-gray04">
            {{ formatDate({ date: policy.updated_at}) }}
          </h3>
        </div>
      </div>
    </div>
  </BaseDrawer>
</template>

<script>
import AppTabHeader from '@/components/general/AppTabHeader.vue'
import { mapGetters } from 'vuex'
import { showPoliciesByOptions } from '@/business-logic/constants/policiesEnum'
import { POLICY_TYPES } from '@/modules/app/policy/shared/enums.js'
import { DRAWER_HIERARCHY, STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import Comparison from '@/models/Comparison.js'

export default {
  name: 'ComparisonPoliciesSelectDrawer',
  components: { AppTabHeader },  
  data: () => ({
    showPoliciesByOptions: Object.values(showPoliciesByOptions),
    showPoliciesBy: showPoliciesByOptions.ALL,
    orderBy: {
      column: 'title',
      direction: 'asc'
    },
    POLICY_TYPES,
    DRAWER_HIERARCHY,
    comparison_id : null,
  }),
  computed: {
    getComparison(){
      return this.comparison_id ? new Comparison({...this.$store.getters['comparison/getterUserComparison']({ id: this.comparison_id })}) : false
    },
    comparisonJurisdiction() {
      return this.getComparison?.jurisdiction ?? null
    },
    userPoliciesByComparisonJurisdiction() {
      if (!this.comparisonJurisdiction) {
        return []
      }
      const policies = this.$store.getters['policy/getterUserPoliciesGroupedByJurisdiction'][this.comparisonJurisdiction.id]
      return policies.filter(policy => {
        return !policy?.policy_containers.some(container =>
          container.study_type.slug === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS
        )
      })
    },
    policiesFiltered() {
      if (!this.getComparison) return []

      return this.userPoliciesByComparisonJurisdiction ? this.userPoliciesByComparisonJurisdiction.filter((policy) => {
        if (this.showPoliciesBy === showPoliciesByOptions.SHARED ) {
          return policy.user_id !== this.getterLoggedUser.id
        } else if ( this.showPoliciesBy === showPoliciesByOptions.USER ) {
          return policy.user_id === this.getterLoggedUser?.id || !this.getterLoggedUser
        } else {
          return true
        }
      })
      .sort((a, b) => {
        const key = this.orderBy.column
        const order = this.orderBy.direction

        if (!Object.prototype.hasOwnProperty.call(a, key) || !Object.prototype.hasOwnProperty.call(b, key)) {
          // property doesn't exist on either object
          return 0
        }

        const varA = (typeof a[key] === 'string')
          ? a[key].toUpperCase() : a[key]
        const varB = (typeof b[key] === 'string')
          ? b[key].toUpperCase() : b[key]

        let comparison = 0
        if (varA > varB) {
          comparison = 1
        } else if (varA < varB) {
          comparison = -1
        }
        return (
          (order === 'desc') ? (comparison * -1) : comparison
        )

      }) : []
    },
    ...mapGetters(['getterLoggedUser'])
  },
  mounted() {
    this.$eventBus.$on('openComparisonEditPoliciesDrawer', ({ comparison_id }) => {            
      this.comparison_id = comparison_id 
      this.$refs.baseDrawer.openDrawer()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openComparisonEditPoliciesDrawer')
  },
  methods: {
    onTooglePolicy(policy_id) {
      this.$store.dispatch('comparison/updateComparisonPolicies', { comparison_id : this.getComparison.id, policy_id }).then(() => {
        this.$eventBus.$emit('comparisonUpdated')
      })
    },
    checkIfPolicyIsOnComparison(policy_id) {            
      return this.getComparison?.isPolicyOnComparison({ policy_id })
    },
    setOrderColumn(column) {
      if(this.orderBy.column == column) {
        this.orderBy.direction = (this.orderBy.direction == 'asc') ? 'desc' : 'asc'
      } else {
        this.orderBy.column = column
        this.orderBy.direction = 'asc'
      }
    },
  }
}
</script>

<style lang="scss" scoped>  
  ::v-deep .app-drawer-content {    
    margin-top: 0;    
  }

  ::v-deep {
    &.app-drawer {
      width: 678px;
    }
  }
  
  ::v-deep .app-checkbox-item {
    span {
      white-space: normal;
      width: 250px;
      word-break: break-all; 
    }
  }
</style>