import { PolicyOptionBuilder } from "../index"
import { POLICY_OPTIONS_DB_SLUGS } from '@/util/Enums.js'
import { getDeepObjectTypesAndValues } from "@/util/checkDeepObjectChanges"
import { defaultExistingBuildingsImpactAssumptionValues } from "@/models/ImpactAssumptions"
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import { CUSTOM_COMBINATION_PRESETS } from '@/modules/app/jurisdiction/study-results/shared/enums.js'
import CustomCombination from '@/models/CustomCombination'
import Store from '@/store'
import CustomCombinationApiService from '@/services/api/CustomCombinationApiService'
import PolicyApiService from '@/services/api/PolicyApiService'
import StudyDataApiService from '@/services/api/StudyDataApiService'
import Study from '@/models/Study'

export class ExistingBuildingsPOBuilder extends PolicyOptionBuilder {
  static name = 'existing-buildings-default-state'
  static policyOptionSlug = POLICY_OPTIONS_DB_SLUGS.DEFAULT_STATE_EXISTING
  static studyTypeSlug = STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS

  options = {}
  constructor(policyId, climateZonesByPrototypes, options) {
    super(policyId, climateZonesByPrototypes)
    this.options = {
      ...{
        targetScorePercentage: 100,
        preset: null,
        assumptions: {
          annual_penetration_rate: defaultExistingBuildingsImpactAssumptionValues.annual_penetration_rate
        },
        savePrescriptive: true,
        saveFlexible: true,
        openPackagePreset: true,
      },
      ...(options || {}),
    }
    this.validateOptions()
  }

  validateOptions() {
    const checkKeysTypesAndValues = {
      'targetScorePercentage': [null, 'number'],
      'preset': [null, 'string'],
      'assumptions.annual_penetration_rate': [null, 'number'],
      'savePrescriptive': [null, 'boolean'],
      'saveFlexible': [null, 'boolean'],
      'openPackagePreset': [null, 'boolean']
    }
    const objectKeyInfo = getDeepObjectTypesAndValues(this.options)
    for (const [key, rules] of Object.entries(checkKeysTypesAndValues)) {
      if (!objectKeyInfo?.[key] || !objectKeyInfo?.[key]?.some((x) => rules.includes(x))) {
        throw new Error(`Existing Buildings options for ${key} is not valid!`)
      }
    }
  }

  async postInstall(args) {
    const totalData = {
      flexibleConfigs: ExistingBuildingsPOBuilder.mergeFlexConfigs(args.map((r) => r.flexibleConfigs)),
      containers: args.map((r) => r.containers).flat(Infinity),
    }

    const flexibleToSave = this.options.saveFlexible === true ? totalData.flexibleConfigs : {
      ...(totalData?.flexibleConfigs || {}),
      tiers: totalData?.flexibleConfigs?.tiers?.map((tier) => {
        return {
          ...tier,
          mandatory_measures: [],
          excluded_measures: [],
          target_scores: tier?.target_scores?.map((tS) => {
            return {
              ...tS,
              value: null
            }
          }) || []
        }
      }) || []
    }

    const containersToSave = this.options.savePrescriptive === true ? totalData.containers : totalData?.containers?.map((container) => {
      return {
        ...container,
        custom_combinations: container?.custom_combinations.map((cc) => {
          return {
            ...cc,
            measures: [],
          }
        })
      }
    }) || []
    return await PolicyApiService.completeBatchStore({id: this.policyId }, containersToSave, flexibleToSave)
  }

  async install() {
    const promises = []
    for (const {prototype_id: prototypeId, climate_zones: climateZones} of this.climateZonesByPrototypes) {
      promises.push(this.installPrototype(prototypeId, climateZones))
    }
    const results = await Promise.all(promises)
    return {
      flexibleConfigs: ExistingBuildingsPOBuilder.mergeFlexConfigs(results.map((r) => r.flexibleConfigs)),
      containers: results.map((r) => r.container).flat(Infinity),
    }
  }

  async installPrototype(prototypeId, climateZonesRaw) {
    const policy = await this.getPolicy()
    const prototype = Store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: prototypeId })
    const typePrototype = Store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id: prototype.type_prototype_id })
    const studyType = Store.getters['globalEntities/Study/getterStudyStudyType']({ study_id: prototype.study_id })
    const study = Store.getters['globalEntities/Study/getterGlobalStudy']({ id: +prototype.study_id })
    const enabledPresetsOnStudy = (new Study(study))?.enabled_cost_ratio_presets || []
    let usePreset = this.options.preset
    if (
        usePreset === CUSTOM_COMBINATION_PRESETS.ALL_POSSIBLE_ON_BILL.value &&
        enabledPresetsOnStudy.includes(CUSTOM_COMBINATION_PRESETS.ALL_POSSIBLE_ON_BILL_2025.value)
    ) {
      usePreset = CUSTOM_COMBINATION_PRESETS.ALL_POSSIBLE_ON_BILL_2025.value
    }

    const newContainer = {
      study_type_id: studyType.id,
      type_prototype_id: typePrototype.id,
      type_prototype_order: typePrototype.order,
    }
    const typeVintages = Store.getters['globalEntities/TypeVintage/getterGlobalTypeVintages']().map((typeVintage) => typeVintage.id)
    const vintages = Store.getters['globalEntities/Vintage/getterGlobalVintages']({ study_id : prototype.study_id, type_vintage_id : typeVintages })
    const flexibleConfigs = []
    const customCombinations = []

    // Get all presets
    const presetPromises = []
    const studyDataPromises = []
    for (const climateZoneRaw of climateZonesRaw) {
      if (this.options?.openPackagePreset === true) {
        studyDataPromises.push(StudyDataApiService.get({ prototype_id: prototypeId, climate_zone_raw: climateZoneRaw }))
      }
      for (const vintage of vintages) {
        presetPromises.push(this.getPresets(vintage.id, prototypeId, climateZoneRaw))
      }
    }
    const allPresets = await Promise.all(presetPromises)
    const allStudyDataByClimateZone = await Promise.all(studyDataPromises)

    for await (const climateZoneRaw of climateZonesRaw) {
      const allStudyData = (this.options?.openPackagePreset === true) ? allStudyDataByClimateZone.shift() : []
      const commonCustomCombinationInfo = {
        prototype_id: prototype.id,
        jurisdiction_id : policy.jurisdiction_id,
        study_id: prototype.study_id,
        climate_zone_raw: climateZoneRaw
      }

      const targetScoresByClimateZone = []
      for await (const vintage of vintages) {
        let presets = allPresets.shift()?.[usePreset] || []
        if (this.options?.openPackagePreset === true) {
          const studyData = allStudyData.filter((x) => +x.vintage_id === +vintage.id) || null
          presets = (await ExistingBuildingsPOBuilder.getNormalizedPresetMeasures(presets, prototype.study_id, prototypeId, climateZoneRaw, vintage.id, studyData)) || []
        }

        const measures = (Store.getters['globalEntities/Measure/getterGlobalMeasures']({ id: presets.map((p) => p.measure_id), study_id: prototype.study_id }) || [])
            .filter((m) => m.hide_in_compliance_margin_control !== true)


        // Define prescriptive configs
        customCombinations.push(
          new CustomCombination({
            ...commonCustomCombinationInfo,
            vintage_id : vintage.id,
            measures: measures.map((m) => { return { id: m.id } }),
            meta: {
              assumptions: await this.getAssumptionsMeta()
            }
          }),
        )

        const targetScore = Math.round(presets.reduce((prev, cc) => {
          const measure = Store.getters['globalEntities/Measure/getterGlobalMeasure']({ id: cc.measure_id, study_id: prototype.study_id })
          if((!measure?.hide_in_flexible_path || (measure?.is_package === true && measure?.measures?.length)) && measure?.hide_in_compliance_margin_control !== true) {
            return prev + Math.round(Number(cc?.energy_savings_combined || 0))
          }
          return prev
        }, 0) * ((this.options.targetScorePercentage || 0) / 100))
        targetScoresByClimateZone.push({
          type_vintage_id: vintage.type_vintage_id,
          value: targetScore
        })
      }

      const commonAttr = {
        climate_zone_id: Store.getters['globalEntities/ClimateZone/getterGlobalClimateZone']({ raw: climateZoneRaw }).id,
        prototype_id: prototypeId,
      }
      flexibleConfigs.push({
        cost_effective_presets: [
          {...commonAttr, preset: usePreset}
        ],
        tiers: [
          {
            ...commonAttr,
            title: '',
            target_scores: targetScoresByClimateZone,
            mandatory_measures: [],
            excluded_measures: [],
          }
        ],
      })
    }

    newContainer.custom_combinations = customCombinations
    return {
      flexibleConfigs,
      container: newContainer,
    }
  }

  async getAssumptionsMeta() {
    return {
      annual_penetration_rate: this.options.assumptions.annual_penetration_rate
    }
  }

  async getPresets(vintageId, prototypeId, climateZoneRaw) {
    const { data } = await CustomCombinationApiService.getPresets({ vintage_id: vintageId, prototype_id: prototypeId, climate_zone_raw: climateZoneRaw })
    return data.presets
  }

  static mergeFlexConfigs(data) {
    const mergeFnc = (acc, flexData) => {
      acc.cost_effective_presets = [...acc.cost_effective_presets, ...(flexData.cost_effective_presets || [])]
      acc.tiers = [...acc.tiers, ...(flexData.tiers || [])]
    }
    return data.reduce((acc, curr) => {
      if (Array.isArray(curr)) {
        curr.forEach((item) => { mergeFnc(acc, item) })
      } else {
        mergeFnc(acc, curr)
      }
      return acc
    }, {
      cost_effective_presets: [],
      tiers: [],
      version: 3
    })
  }

  // Normalize preset (open packages)
  static async getNormalizedPresetMeasures(presets, studyId, prototypeId, climateZoneRaw, vintageId, studyData) {
    studyData = studyData || (await StudyDataApiService.get({ prototype_id: prototypeId, climate_zone_raw: climateZoneRaw, vintage_id: vintageId }))

    const addedIds = []
    return presets?.map((presetItem) => {
      if (!presetItem?.id) return null
      const measure = Store.getters['globalEntities/Measure/getterGlobalMeasure']({ id:presetItem.id, study_id: studyId })
      if (measure?.hide_in_compliance_margin_control === true) return null
      if (!(measure?.is_package === true && measure?.hide_in_flexible_path === true)) {
        if (addedIds.includes(presetItem.id)) return null
        addedIds.push(presetItem.id)
        return {
          ...presetItem,
          excluded_measures: typeof measure?.exclude_measures === 'string' ?
              JSON.parse(measure?.exclude_measures || '[]') : measure?.exclude_measures || [],
          id: presetItem.id,
          measure_id: presetItem.id,
          hide_in_flexible_path: measure?.hide_in_flexible_path,
          is_package: measure?.is_package,
          measures: (measure?.measures || []),
        }
      }

      return (measure?.measures || []).map(({id: pMeasureId}) => {
        const subMeasure = Store.getters['globalEntities/Measure/getterGlobalMeasure']({ id:pMeasureId, study_id: studyId })
        const studyDataInfo = studyData?.find((s) => +s.measure_id === pMeasureId)
        if (!subMeasure || !studyDataInfo || !studyDataInfo?.energy_savings_combined || addedIds.includes(pMeasureId)) {
          return null
        }
        addedIds.push(pMeasureId)
        return {
          ...presetItem,
          ...(studyDataInfo?.measure || {}),
          excluded_measures: typeof studyDataInfo?.measure?.exclude_measures === 'string' ?
              JSON.parse(studyDataInfo?.measure?.exclude_measures || '[]') : studyDataInfo?.measure?.exclude_measures || [],
          energy_savings_combined: studyDataInfo?.energy_savings_combined,
          id: pMeasureId,
          measure_id: pMeasureId,
          hide_in_flexible_path: subMeasure?.hide_in_flexible_path,
          is_package: subMeasure?.is_package,
          measures: (subMeasure?.measures || []),
        }
      }).filter((m) => m)
    })?.flat(Infinity)?.filter((m) => m) || []
  }
}

export class ExistingBuildingsGeneralCostEffectiveRetrofitOnBillPoBuilder extends ExistingBuildingsPOBuilder {
  static name = 'existing-buildings-general-cost-effective-retrofit-on-bill'
  static policyOptionSlug = POLICY_OPTIONS_DB_SLUGS.GENERAL_COST_EFFECTIVE_RETROFIT_ON_BILL
  constructor(policyId, climateZonesByPrototypes) {
    super(policyId, climateZonesByPrototypes, {
      targetScorePercentage: 100,
      preset: CUSTOM_COMBINATION_PRESETS.ALL_POSSIBLE_ON_BILL.value,
      assumptions: {
        annual_penetration_rate: defaultExistingBuildingsImpactAssumptionValues.annual_penetration_rate
      },
      savePrescriptive: true,
      saveFlexible: true,
      openPackagePreset: false,
    })
  }
}

export class ExistingBuildingsGeneralCostEffectiveRetrofitTdvOrOnBillPoBuilder extends ExistingBuildingsPOBuilder {
  static name = 'existing-buildings-general-cost-effective-retrofit-tdv-or-on-bill'
  static policyOptionSlug = POLICY_OPTIONS_DB_SLUGS.GENERAL_COST_EFFECTIVE_RETROFIT_TDV_OR_ON_BILL
  constructor(policyId, climateZonesByPrototypes) {
    super(policyId, climateZonesByPrototypes, {
      targetScorePercentage: 100,
      preset: CUSTOM_COMBINATION_PRESETS.ALL_POSSIBLE_ON_BILL_OR_TDV.value,
      assumptions: {
        annual_penetration_rate: defaultExistingBuildingsImpactAssumptionValues.annual_penetration_rate
      },
      savePrescriptive: true,
      saveFlexible: true,
      openPackagePreset: false,
    })
  }
}

// New Existing POs
export class ExistingBuildingsPrescriptiveOnBillPoBuilder extends ExistingBuildingsPOBuilder {
  static name = 'existing-buildings-prescriptive-on-bill'
  static policyOptionSlug = POLICY_OPTIONS_DB_SLUGS.PRESCRIPTIVE_ON_BILL
  constructor(policyId, climateZonesByPrototypes) {
    super(policyId, climateZonesByPrototypes, {
      targetScorePercentage: 100,
      preset: CUSTOM_COMBINATION_PRESETS.ALL_POSSIBLE_ON_BILL.value,
      assumptions: {
        annual_penetration_rate: defaultExistingBuildingsImpactAssumptionValues.annual_penetration_rate
      },
      savePrescriptive: true,
      saveFlexible: false,
      openPackagePreset: false,
    })
  }
}

export class ExistingBuildingsFlexibleOnBillPoBuilder extends ExistingBuildingsPOBuilder {
  static name = 'existing-buildings-flexible-on-bill'
  static policyOptionSlug = POLICY_OPTIONS_DB_SLUGS.FLEXIBLE_ON_BILL
  constructor(policyId, climateZonesByPrototypes) {
    super(policyId, climateZonesByPrototypes, {
      targetScorePercentage: 100,
      preset: CUSTOM_COMBINATION_PRESETS.ALL_POSSIBLE_ON_BILL.value,
      assumptions: {
        annual_penetration_rate: defaultExistingBuildingsImpactAssumptionValues.annual_penetration_rate
      },
      savePrescriptive: false,
      saveFlexible: true,
      openPackagePreset: true,
    })
  }
}