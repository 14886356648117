<template>
  <ul>
    <li class="py-8 px-6 border-b border-deepsky-200">
      <h2 class="text-header-6 text-deepsky-900 font-bold ">
        Guides
      </h2>

      <p class="text-header-4 text-ash-500 pt-4">
        Learn the basics
      </p>

      <p
        class="text-header-4 text-deepsky-500 pt-4 transition-all hover:opacity-70 cursor-pointer"
      >
        <a 
          target="_blank"
          href="https://intercom.help/explorer-local-energy-codes/en/collections/3076245-quick-guide"
          @click="gaEvent(GA_LABELS.MENU_INFO_QUICK_GUIDE, { value: 'MENU_INFO_QUICK_GUIDE' })"
        >
          <button
            class="psui-font-normal"
          >
            California Code and Reach Codes
          </button>
        </a>
      </p>

      <p
        class="text-header-4 text-deepsky-500 pt-4 transition-all hover:opacity-70 cursor-pointer"
      >
        <a 
          target="_blank"
          href="https://intercom.help/explorer-local-energy-codes/en/collections/3517138-new-construction-quick-guide"
          @click="gaEvent(GA_LABELS.MENU_INFO_NEW_CONSTRUCTION_QUICK_GUIDE, { value: 'MENU_INFO_NEW_CONSTRUCTION_QUICK_GUIDE' })"
        >
          <button
            class="psui-font-normal"
          >
            Key Concepts for the 2022 Code Cycle
          </button>
        </a>
      </p>
    </li>

    <li class="py-8 px-6 border-b border-deepsky-200">
      <h2 class="text-header-6 text-deepsky-900 font-bold ">
        Methodology
      </h2>
      <p class="text-header-4 text-ash-500 pt-4">
        How do we calculate the data?
      </p>
      <p
        class="text-header-4 text-deepsky-500 pt-4 transition-all hover:opacity-70 cursor-pointer"
        @click="$eventBus.$emit('openDrawerContent', { type: 'helper', slug: 'building-stock-estimation-methodology'})"
      >
        Building Stock
      </p>
    </li>
    
    <li 
      class="pt-8 px-6 border-b border-deepsky-200 transition-all text-deepsky-900 hover:text-deepsky-500 cursor-pointer" 
      @click="onClickResultsSources()"
    >
      <h2 class="text-header-6 font-bold">
        Results Sources
      </h2>
      <p class="text-header-4 text-ash-500 pt-4 pb-8">
        Where do the cost-effectiveness results in this tool come from?
      </p>
    </li>
    <li 
      class="pt-8 px-6 border-b border-deepsky-200 transition-all text-deepsky-900 hover:text-deepsky-500 cursor-pointer" 
      @click="onClickUtilityRates()"
    >
      <h2 class="text-header-6 font-bold">
        Utility Rate Info
      </h2>
      <p class="text-header-4 text-ash-500 pt-4 pb-8">
        What utility rates were assumed for these calculations?
      </p>
    </li>
  </ul>
</template>

<script>
import { utilityRatesOptions, resultsSourcesOptions } from '@/util/Drawers'
import { GA_LABELS } from '@/mixins/GaEventsMixin'

export default {
  name: 'SidebarMenuInfo',
    data: () => ({
    GA_LABELS,
  }),
  methods: {
    onClickUtilityRates() {
      this.$eventBus.$emit('openDrawerContent', utilityRatesOptions(this.$route) )
    },
    onClickResultsSources() {
      this.$eventBus.$emit('openDrawerContent', resultsSourcesOptions(this.$route))
    },
  }
}
</script>

<style scoped>
  p {
    max-width: 320px;
  }
</style>