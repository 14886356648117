<template>
  <div>
    <EditRequirementsNewBuildingsSD2022AdminInfo
      v-if="getAllElectricCombination && getMixedFuelCombination"
      v-if-admin
      :requirement-all-electric="getAllElectricCombination" 
      :requirement-mixed-fuel="getMixedFuelCombination"
    />

    <div class="psui-w-full">
      <h3 class="psui-text-blue-60 psui-text-xsmall psui-font-bold psui-uppercase psui-mb-2">
        {{ 'Policy Requirements' | policyTextRephrase }}
      </h3>

      <div
        v-if="isComplianceMarginEnabled"
        class="psui-flex flex-col psui-text-blue-70 psui-font-bold psui-mb-3"
      >
        <div
          class="psui-bg-blue-20 py-1/2 px-3 psui-rounded-t psui-flex psui-items-center"
          :class="{ 'psui-bg-blue-20': isComplianceMarginUnique }"
        >
          <PsSwitch
            ref="switchItem"
            class="flex-shrink-0"
            size="small"
            label=""
            background-color="psui-bg-blue-60"
            :value="isComplianceMarginUnique"
            @change="toggleUniqueComplianceMargin"
          />
          <span
            class="psui-text-small psui-ml-1 psui-mr-1"
            :class="{'psui-text-blue-70': isComplianceMarginUnique}"
          >Require the same minimum performance score regardless of fuel type (recommended)</span>
          <PsIcon
            icon="info_outline"
            size="16"
            class="psui-items-center psui-justify-center psui-mx-2 psui-cursor-pointer"
            :class="isHoveringHelperIcon ? 'psui-text-gray-40' : 'psui-text-gray-50'"
            @mouseenter.native="onHoverHelperIcon()"
            @mouseleave.native="onHoverHelperIcon()"
            @click.native="openRequireEqualMinimumPerformanceHelper()"
          />
        </div>
      </div>

      <div class="psui-flex psui-flex-col">
        <div class="psui-w-full psui-flex psui-flex-row psui-py-1">
          <div class="psui-w-175 psui-gap-y-2">
            <div
              class="psui-flex psui-items-center psui-cursor-pointer opacity-100-childrens-on-hover"
              @click="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: `edit_requirements_new_buildings_sf_2022_fuel_type` })"
            >
              <span class="psui-text-p psui-text-blue-70 psui-font-bold psui-text-p psui-mx-1">
                Fuel Type
              </span>
              <PsIcon
                icon="info"
                size="16"
                class="psui-text-blue-60 text-16 psui-opacity-0 transition-all"
                :style="{ 'display': 'flex' }"
              />
            </div>
          </div>

          <div class="psui-flex psui-w-3/4">
            <div
              class="psui-gap-y-2"
              :class="isPvSystemEnabled ? 'third-width-responsive' : 'half-width-responsive'"
            >
              <div
                class="psui-flex psui-items-center psui-cursor-pointer opacity-100-childrens-on-hover"
                @click="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: `edit_requirements_new_buildings_sf_2022_fuel_choice` })"
              >
                <span class="psui-text-p psui-text-blue-70 psui-font-bold psui-text-p psui-mr-1">
                  Fuel Choice
                </span>
                <PsIcon                
                  icon="info"
                  size="16"
                  class="psui-text-blue-60 text-16 psui-opacity-0 transition-all"
                  :style="{ 'display': 'flex' }"
                />
              </div>
            </div>

            <div
              class="psui-gap-y-2"
              :class="isPvSystemEnabled ? 'third-width-responsive' : 'half-width-responsive'"
            >
              <div
                class="psui-flex psui-items-center psui-cursor-pointer opacity-100-childrens-on-hover"
                @click="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: `edit_requirements_new_buildings_sf_2022_compliance_margin` })"
              >
                <span class="psui-text-p psui-text-blue-70 psui-font-bold psui-text-p psui-mr-1">
                  Compliance Margin
                </span>
                <PsIcon
                  icon="info"
                  size="16"
                  class="psui-text-blue-60 text-16 psui-opacity-0 transition-all"
                  :style="{ 'display': 'flex' }"
                />
              </div>
              <p class="psui-text-xsmall psui-text-gray-50 psui-leading-4">
                Extra required score {{ requiredScoreSubtitle }}
              </p>
            </div>

            <div
              v-if="isPvSystemEnabled"
              class="psui-gap-y-2"
              :class="isPvSystemEnabled ? 'third-width-responsive ml-1/2' : 'psui-w-0'"
            >
              <div
                class="psui-flex psui-items-center psui-cursor-pointer opacity-100-childrens-on-hover"
                @click="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: `edit_requirements_new_buildings_sf_2022_larger_pv_system` })"
              >
                <span class="psui-text-p psui-text-blue-70 psui-font-bold psui-text-p psui-mr-1">
                  Larger PV System
                </span>
                <PsIcon                
                  icon="info"
                  size="16"
                  class="psui-text-blue-60 text-16 psui-opacity-0 transition-all"
                  :style="{ 'display': 'flex' }"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="psui-flex psui-flex-col psui-space-y-2">
          <EditRequirementsNewBuildingsSF2022AllElectric
            v-if="getAllElectricCombination"
            :requirement-all-electric="getAllElectricCombination" 
            :requirement-mixed-fuel="getMixedFuelCombination"
            :is-compliance-margin-unique="false"
            type-fuel-slug="all-electric"
            :class="{'pointer-events-none opacity-60' : isSaving }"
            @update-requirements="onUpdateRequirements"
          />                  
          <EditRequirementsNewBuildingsSF2022MixedFuel
            v-if="getMixedFuelCombination"
            :requirement-all-electric="getAllElectricCombination" 
            :requirement-mixed-fuel="getMixedFuelCombination"
            :is-compliance-margin-unique="false"
            type-fuel-slug="mixed-fuel"
            :class="{'pointer-events-none opacity-60' : isSaving }"
            @update-requirements="onUpdateRequirements"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditRequirementsNewBuildingsSD2022AdminInfo from './EditRequirementsNewBuildingsSD2022AdminInfo.vue'
import EditRequirementsNewBuildingsSF2022AllElectric from './EditRequirementsNewBuildingsSF2022AllElectric.vue'
import EditRequirementsNewBuildingsSF2022MixedFuel from './EditRequirementsNewBuildingsSF2022MixedFuel.vue'
import PolicyRequirementsNewBuildingsMixin from '@/modules/app/policy/show/requirements/requirements-drawer/PolicyEditRequirementsNewBuildingsSF2022/PolicyRequirementsNewBuildingsMixin.js'

// TODO - Replace type fuel strings by Enums
// TODO - Remove inline style block from PsIcon (why we have display content inside the policy-studio-ui?)

export default {
  name: 'EditRequirementsNewBuildingsSF2022Meta',
  components: { 
    EditRequirementsNewBuildingsSF2022AllElectric, 
    EditRequirementsNewBuildingsSF2022MixedFuel,
    EditRequirementsNewBuildingsSD2022AdminInfo
  },
  props: ['custom_combinations'],
  mixins: [PolicyRequirementsNewBuildingsMixin],
  data: () => ({
    isHoveringHelperIcon: false,
  }),
  computed: {
    requiredScoreSubtitle() {
      switch(this.getStudyComplianceMarginKey) {
        case 'edr1_total_margin':
          return '(EDR1)'
        case 'compliance_margin':
        default:
          return '(EDR2eff)'
      }
    },
  },
  methods: {
    onHoverHelperIcon(){
      this.isHoveringHelperIcon = !this.isHoveringHelperIcon
    },
  }
}
</script>

<style lang="scss" scoped>
  .psui-gap-y-2 {
    grid-row-gap: 0.125rem;
    row-gap: 0.125rem;
  }

  .psui-w-175 {
    width: 175px;
  }

  .psui-el-dropdown-menu {
    &-item {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
</style>
