<template>
  <div class="w-full  flex flex-col">
    <h4 class="psui-text-h5 psui-text-gray-80 font-bold whitespace-nowrap">
      User jurisdictions recorrent visits
    </h4>
    <div class="block w-full">
      <ApexChartGeneral 
        :options="getChartOptions()"
        :palette-color-index="0"
      />
    </div>
    <PsButton 
      class="w-48"
      label="Download CSV"
      icon="file_download"
      size="big"
      @click="generateCsv()"
    />
  </div>
</template>

<script>
import { combinationColors } from '@/business-logic/constants/chartDefaultOptions'
import { objArrayToCsv, downloadRawData } from '@igortrindade/lazyfy'
import dayjs from 'dayjs'
export default {
  name: 'AdminDashboardTopJurisdiction',
  props: ['timeFrameSelected'],
  data() {
    return {
      combinationColors,
      user_jurisdictions_recorrent_visits: []
    }
  },
   computed: {
    
    getTotal() {
      return this.user_jurisdictions_recorrent_visits.reduce((acc, item) => acc + item.views_total, 0)
    },

    getChartSeries() {
      const data = this.user_jurisdictions_recorrent_visits.map((user, index) => {
        return {
          x: user.email,
          y: user.jurisdictions.reduce((acc, jurisdiction) => acc + jurisdiction.views_count, 0),
          user,
          fillColor: this.combinationColors[0][index]
        }
      })
      return [{ data }]
    }
  },

  watch: {
    timeFrameSelected: {
      deep: true,
      handler() {
        this.getData()
      } 
    }
  },

  mounted() {
    this.getData()
  },

  methods: {

    getData() {
      this.$api.get('/api/admin/dashboard/get_data/user_jurisdictions_recorrent_visits', { params: {
        limit: 30,
        init: this.timeFrameSelected.init, 
        end: this.timeFrameSelected.end 
      }})
        .then(({ data }) => {
          this.user_jurisdictions_recorrent_visits = data.user_jurisdictions_recorrent_visits
        })
    },

    generateCsv() {
      this.$api.get('/api/admin/dashboard/get_data/user_jurisdictions_recorrent_visits', { params: { 
        limit: 5000, 
        init: this.timeFrameSelected.init, 
        end: this.timeFrameSelected.end 
      }})
        .then(({ data }) => {
          const items = data.user_jurisdictions_recorrent_visits.map((item) => {

            let jurisdictions = ''
            for(const jurisdiction of item.jurisdictions) {
              jurisdictions += `${jurisdiction.jurisdiction_type} ${jurisdiction.jurisdiction_title} (${jurisdiction.views_count} views); `
            }
            return {
              ["User id"]: item.id,
              ["User email"]: item.email,
              ["Jurisdictions visited"]: jurisdictions
            }
          })
          const fileName = `user jurisdictions recorrent visits time frame: ${this.timeFrameSelected.init} -  ${this.timeFrameSelected.end} generated at: ${dayjs().format('YYYY-MM-DD HH:mm:ss')}.csv`
          downloadRawData(objArrayToCsv(items, ','), fileName)

        })
    },

    getChartOptions() {
      const self = this
      return { 
        chart: {
          type: 'bar',
          stacked: false,
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: true
            }
          }
        },
        series: this.getChartSeries, 
        yaxis: {
          labels: {
            formatter: function(value) {
              return self.formatUnits(value)
            }
          },
        },
        tooltip: {
          custom: function ({ seriesIndex, dataPointIndex }) {
            const item = self.getChartSeries[seriesIndex].data[dataPointIndex]
            let string = `
              <div class="psui-flex" style="background-color: ${ item.fillColor } !important;">
                <div class="apexcharts-tooltip-content psui-w-full psui-bg-white psui-p-4">
                  <h4 class="psui-text-gray-80 psui-text-xsmall psui-font-bold">${ item.user.email }</h4>
                  <div class="psui-w-full psui-flex psui-flex-col space-y-2">`

            for(const jurisdiction of item.user.jurisdictions) {
              string += `<h2 class="psui-text-gray-80 psui-text-small">${jurisdiction.jurisdiction_type} ${jurisdiction.jurisdiction_title}: ${ self.formatUnits(jurisdiction.views_count) } views</h2>`
            }
                    
                    
            string +=`
                  </div>
                </div>
              </div>
            `

            return string
          }
        }
      }
    }
  }
}
</script>