import calculateBuildingStocks from './calculateBuildingStocks'
import generatePolicyVintageData from './generatePolicyVintageData'
import generatePolicyOtherItemsData from './generatePolicyOtherItemsData'
import sumStudyResultData from '../study-results/sumStudyResultData'
import generatePolicyProjections from './generatePolicyProjections'
import Store from '@/store'
import { defaultExistingBuildingsImpactAssumptionValues } from '@/modules/app/assumptions/shared/defaultAssumptions'
import ImpactAssumptions from '@/models/ImpactAssumptions'
import generateProjectionsByYearForChart from './generateProjectionsByYearForChart'
import reduceProjectionsByYears from './reduceProjectionsByYears'

export default ({ policy, items }) => {
  return new Promise( (resolve, reject) => {
    try {
      if (!policy?.id) {
        resolve([])
        return
      }

      for( const [index, total] of items.entries()) {

        const projectionsByYears = []

        for(const studyType of total.items) {
          for(const study of studyType.items) {
            for(const prototype of study.items) {
              for(const climate_zone of prototype.items) {

                for(const vintage of climate_zone.items) {

                  vintage.building_stock_units = Store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
                    jurisdiction_id: policy.jurisdiction_id,
                    climate_zone_prefix: climate_zone.climate_zone_prefix,
                    type_vintage_id: vintage.type_vintage_id,
                    type_prototype_id: prototype.type_prototype_id,
                    prototype_id: prototype.id,
                  })
                  
                  if(index > 0) calculateBuildingStocks({ total, studyType, study, prototype, climate_zone, vintage })
                  for(const studyData of vintage.items) {
                    // sum here is just to initializate the studyData.data object

                    const customCombinationFilters = {
                      policy_id: policy.id,
                      climate_zone_raw: climate_zone.climate_zone_raw,
                      prototype_id: prototype.id,
                      vintage_id: vintage.id,
                    }

                    const custom_combinations = Store.getters['policy/getterFilteredPolicyCustomCombinations'](customCombinationFilters)
                    const ccMetaAssumption = custom_combinations[0]?.meta?.assumptions ? custom_combinations[0].meta.assumptions : {}
                    const impactAssumptions = new ImpactAssumptions(ccMetaAssumption, defaultExistingBuildingsImpactAssumptionValues)
                    sumStudyResultData({ data: studyData.data, dataToAggregate: studyData })
                    generatePolicyProjections({ impactAssumptions, data: studyData.data, building_stock_units: vintage.building_stock_units })
                  }
                  generatePolicyVintageData({ vintageData: vintage.data, studyDataItems: vintage.items.map((item) => { return { ...item.data }} )  })
                  
                  // Generate projections in this level to avoid summing up the same data twice by measures
                  const projectionsByYear = generateProjectionsByYearForChart({ policy, climate_zone, prototype, vintage, studyData: vintage.data })
                  projectionsByYears.push(projectionsByYear)

                } 
                generatePolicyOtherItemsData({ itemData: climate_zone.data, itemsRef: climate_zone.items.map((item) => { return { ...item.data }} )  })
              
              
              }
              generatePolicyOtherItemsData({ itemData: prototype.data, itemsRef: prototype.items.map((item) => { return { ...item.data }} )  })
            }
            generatePolicyOtherItemsData({ itemData: study.data, itemsRef: study.items.map((item) => { return { ...item.data }} )  })
          }
          generatePolicyOtherItemsData({ itemData: studyType.data, itemsRef: studyType.items.map((item) => { return { ...item.data }} )  })
        }
        generatePolicyOtherItemsData({ itemData: total.data, itemsRef: total.items.map((item) => { return { ...item.data }} )  })
        
        const reducedProjectionsByYears = reduceProjectionsByYears(projectionsByYears)

        if(index == items.length-1) resolve(reducedProjectionsByYears)
      }
    } catch (error) {
      reject(error)
    }
  })
}

