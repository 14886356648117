<template>
  <div class="host w-full flex flex-wrap">
    <div class="w-full shimmer psui-h-8 psui-rounded" />
    <div class="w-full shimmer psui-h-64 psui-my-4 psui-rounded" />      
    <div class="w-full shimmer psui-h-32 psui-my-2 psui-rounded" />
    <div class="w-full shimmer psui-h-32 psui-my-2 psui-rounded" />
    <div class="w-full shimmer psui-h-32 psui-my-2 psui-rounded" />
  </div>
</template>

<script>
export default {
  name: 'PolicyEditRequirementsSkeleton'
}
</script>

<style scoped lang="scss">
.host {
  padding-top: 12px;
  padding-right: 24px;
  padding-bottom: 60px;
  padding-left: 24px;  
  max-height: 100vh;  
}
</style>