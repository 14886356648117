import ComparisonIndex from '@/modules/app/comparison/shared/ComparisonIndex.vue'
import ComparisonListIndex from '@/modules/app/comparison/list/ComparisonListIndex.vue'
import ComparisonListHeader from '@/modules/app/comparison/list/ComparisonListHeader.vue'
import ComparisonShowIndex from '@/modules/app/comparison/show/ComparisonShowIndex.vue'
// import ComparisonHeader from '@/modules/app/comparison/ComparisonHeader.vue'
import ComparisonShowHeader from '@/modules/app/comparison/show/ComparisonShowHeader.vue'

export default [
  {
    path: '/comparisons',
    component: ComparisonIndex,
    children: [
      { 
        name: 'ComparisonList',
        path: '', 
        component: ComparisonListIndex,
        meta: {
          header: ComparisonListHeader
        },
      },
      { 
        name: 'ComparisonShow',
        path: ':comparison_id', 
        component: ComparisonShowIndex,
        meta: {
          header: ComparisonShowHeader
        },
      },
    ]
  }
]
