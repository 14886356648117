<template>
  <div class="psui-w-full psui-h-full psui-flex psui-flex-col psui-overflow-y-auto psui-bg-gray-10">
    <SummaryHeader class="psui-bg-white psui-shadow-elevation-5 psui-mb-10" />
    <div class="w-full block px-10 pb-7">
      <SummaryWidgetStudyTypes />
    </div>
    <div class="w-full block p-10 pt-0">
      <SummaryCostEffectivenessStudies />
    </div>      
  </div>
</template>

<script>
import SummaryCostEffectivenessStudies from './SummaryCostEffectivenessStudies.vue'
import SummaryWidgetStudyTypes from './SummaryWidgetStudyTypes.vue'
import SummaryHeader from './SummaryHeader.vue'

export default {
  name: 'SummaryIndex',
  components: { 
    SummaryHeader, 
    SummaryWidgetStudyTypes,
    SummaryCostEffectivenessStudies
  },
  metaInfo() {
    return {
      title: this.jurisdiction?.title_type ? `Summary for ${this.jurisdiction?.titles.title_type} | ` : ''
    }
  },
  computed: {
    jurisdiction() {
      return this.$store.getters['lastJurisdictionVisited']
    },
  },
  beforeDestroy() {
    this.$eventBus.$off('filtersUpdated')
  },
  mounted(){
    this.$store.commit('assumptions/closeDrawer')
  },
  methods: {  
    openExistingBuildingPolicyWizardModal() {
      this.$eventBus.$emit('openExistingBuildingPolicyWizardModal')
    }, 
    openNewBuildingPolicyWizardModal() { 
      this.$eventBus.$emit('openNewBuildingPolicyWizardModal')
    },
  }
}
</script>

<style lang="scss" scoped>
  .actions, ::v-deep .actions {
    font-weight: bold;
    font-size: 10px;
    line-height: 100%;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  ::v-deep .modal-dialog {
    padding: 32px;
    width: 440px;
  }

</style>
