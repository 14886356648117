<template>
  <div>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 py-6 px-8">
      <div class="d-flex">
        <vue-skeleton-loader
          :width="80"
          :height="21"
          class="mb-2"
        />
        <vue-skeleton-loader
          :width="700"
          :height="44"
        />
      </div>

      <div class="d-flex">
        <vue-skeleton-loader
          :width="80"
          :height="21"
          class="mb-2"
        />
        <vue-skeleton-loader
          :width="700"
          :height="44"
        />
      </div>

      <div class="w-full col-span-2">
        <vue-skeleton-loader
          :width="1200"
          :height="80"
        />
      </div>

      <div 
        v-for="index in 4"
        :key="index"
        class="d-flex"
      >
        <vue-skeleton-loader
          :width="120"
          :height="21"
          class="mb-2"
        />
        <vue-skeleton-loader
          :width="700"
          :height="44"
        />
      </div>

      <div class="w-full col-span-2">
        <vue-skeleton-loader
          :width="1400"
          :height="140"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkeletonPolicyOptionForm',
}
</script>
