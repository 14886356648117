import PolicyApiService from "@/services/api/PolicyApiService"
export class PolicyOptionBuilder {
  name = null
  policyOptionSlug = null
  studyTypeSlug = null
  constructor(policyId, climateZonesByPrototypes) {
    this.policyId = policyId
    this.climateZonesByPrototypes = Array.isArray(climateZonesByPrototypes) ? climateZonesByPrototypes : []
  }

  async getPolicy() {
    if (!this.policyId) {
      throw new Error(`You must inform the policy ID to create a policy option builder`)
    }
    if (!this.policy) {
      this.policy = await PolicyApiService.getNewPolicy(this.policyId, true)
    }
    return this.policy
  }

  async isDisabled() {
    return !!(await this.disabledText())
  }

  async disabledText() {
    return null
  }

  async install() {
    throw new Error(`Install not implemented!`)
  }
}






