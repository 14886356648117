import Api from '@/util/Api'
import GraphQL from '@/util/GraphQL'
import Store from '@/store'
import DataAndObjectFunctions from '@/util/DataAndObjectFunctions'
import { ERRORS } from '@/util/Enums'
export default class CustomBuildingEstimateApiService {  

  store(payload = null) {
    if(!Store.getters['getterLoggedUser']) return new Promise((res, rej) => rej(ERRORS.USER_NOT_LOGGED_IN))
    return Api.post(`/api/custom_building_estimate/store`, { custom_building_estimate: DataAndObjectFunctions.filterObjectKeys(CUSTOM_BUILDING_ESTIMATE_PARAMS, payload) })
  }

  static store_batch(custom_building_estimates = []) {
    if(!Store.getters['getterLoggedUser']) return new Promise((res, rej) => rej(ERRORS.USER_NOT_LOGGED_IN))
    return Api.post(`/api/custom_building_estimate/store_batch`, { custom_building_estimates })
  }

  get() {
    const query = `
      {
        currentUser {
          custom_building_estimates {
            id
            user_id
            policy_id
            jurisdiction_id
            climate_zone_prefix
            construction_implementation_type_id
            type_prototype_id
            building_height_id
            year
            growth_rate
            units
            units_type
          }
        }
      }
    `
    return GraphQL({ query, caller: 'CustomBuildingEstimateApiService.get' })
    .then(({ data })=>{
      return data?.currentUser?.custom_building_estimates ? data.currentUser.custom_building_estimates : []
    })

  }

  static delete_batch(custom_building_estimates = []) {
    if(!Store.getters['getterLoggedUser']) return new Promise((res, rej) => rej(ERRORS.USER_NOT_LOGGED_IN_DELETE_FAIL))
    return Api.delete(`/api/custom_building_estimate/delete_batch`, { data: { custom_building_estimates } })
  }

}

export const CUSTOM_BUILDING_ESTIMATE_PARAMS = [
  'policy_id', 
  'jurisdiction_id', 
  'climate_zone_prefix', 
  'construction_implementation_type_id', 
  'type_prototype_id', 
  'building_height_id', 
  'year', 
  'growth_rate', 
  'units',
]
