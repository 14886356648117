<template>
  <div class="psui-w-full psui-h-full psui-flex psui-justify-center psui-p-8">
    <div class="psui-flex psui-flex-col psui-items-center psui-justify-center psui-text-center">
      <img
        src="/images/no-data.png"
        alt="There’s no data to show"
        class="psui-mb-6"
      >
      <p class="psui-text-h5 psui-font-bold psui-text-gray-70 psui-mb-2">
        There’s no data to show
      </p>
      <p class="psui-text-p psui-text-gray-60">
        Your policy has no requirements! Add requirements to your policy to view its impact.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PolicyShowContentEmpty'
}
</script>

<style>

</style>