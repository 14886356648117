<template>
  <Dropdown
    ref="dropdown"
    :button-classes="'px-2 bg-transparent w-full'"
    :dropdown-dialog-classes="'left-0 bg-white'"
  >
    <template #buttonLabel>            
      <feather
        type="more-horizontal"
        size="14"
        class="text-ash-400 mr-1"
      />
    </template>
    <template #items>
      <DropdownMenuList 
        :items="dropdownItems" 
        @update:selected="onUpdateSelected"
      />
    </template>
  </Dropdown>
</template>

<script>
import { copyToClipboard } from '@/util/Helpers'

const ACTIONS = {
  EDIT: { value: 'EDIT', label: 'Edit Policy Name' },
  SHARE: { value: 'SHARE', label: 'Share' },
  DUPLICATE: { value: 'DUPLICATE', label: 'Duplicate' },
  DELETE: { value: 'DELETE', label: 'Delete' }
}

export default {
  name: 'DropdownPolicyActions',
  props: {
    userCanEditPolicy : {
      type: Boolean,
      default: false
    },
    policy: {
      type: Object,
      required: true
    },
    actions: {
      type: Array,
      default: () => ([ACTIONS.EDIT.value, ACTIONS.SHARE.value, ACTIONS.DUPLICATE.value, ACTIONS.DELETE.value])

    },
    shouldRedirectAfterPolicyEdit: {
      default: true
    }
  },
  computed: {
    dropdownItems() {
      const actionLabels = []
      for (const action of this.actions) {
        if (this.userCanEditPolicy) {
          actionLabels.push(ACTIONS[action].label)
        } else if (!this.userCanEditPolicy && action !== ACTIONS.EDIT.value) {

          actionLabels.push(ACTIONS[action].label)
        }
      }
      return actionLabels
    }
  },
  methods: {
    onUpdateSelected(value) {
      switch (value) {
        case ACTIONS.EDIT.label:
          this.$eventBus.$emit('openModalPolicyCreateEdit', this.policy.id, this.shouldRedirectAfterPolicyEdit)
          break

        case ACTIONS.SHARE.label:
          copyToClipboard(`${window.location.origin}/policies/${this.policy.id}`)
          this.$toast.success('Policy url copied to clipboard!')
          break

        case ACTIONS.DUPLICATE.label:
          this.$eventBus.$emit('openDuplicatePolicyModal', { policy: this.policy })
          break

        case ACTIONS.DELETE.label:
          this.$eventBus.$emit('openModalDeletePolicy', { policy: this.policy })
          break
      }
      this.$refs.dropdown.close()
    }
  }
}
</script>