<template>
  <div class="w-full">
    <vue-skeleton-loader
      v-for="i in 5"
      :key="`skeleton-comparison-${i}`"
      class="mb-3 rounded"
      :width="width"
      :height="133"
    />
  </div>
</template>

<script>
export default {
  name: 'StudyResultsContentSkeleton',
	data() {
		return {
			width: window.innerWidth - 300
		}
	},
}
</script>