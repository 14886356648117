import { find, findAll, findIndex } from '@/util/Functions'
import { CUSTOM_COMBINATION_PRESETS } from '@/modules/app/jurisdiction/study-results/shared/enums.js'
import MeasureMenuTier from '@/models/MeasureMenuTier'
import Store from '@/store'
import Study from '@/models/Study'

export const eventBus = (event, payload) => {
  if(window && window.$eventBus) window.$eventBus.$emit(event, payload)
}

export const getMeasureScoreTextValue = ({ measure, climate_zone, prototype, type_vintage_index, flexible_path_setup }) => {

  if (hasMeasureScore({measure, type_vintage_index })) {
    if (checkMeasureIsExcluded({ measure, climate_zone, prototype, type_vintage_index, flexible_path_setup})) {
      return '--'
    } else if (checkMeasureIsMandatory({ measure, climate_zone, prototype, type_vintage_index, flexible_path_setup })) {
      return 'Mandatory'
    } {
      const measureScore = getMeasureScore({ measure, type_vintage_index })
      return measureScore && measureScore > 0 ? measureScore : '--'
    }
  } else if ( climate_zone?.type_vintages?.[type_vintage_index]?.base_target_score == 0 ) {
    return 'N/A'
  } else {
    return '--'
  }

}

export const getMeasureScore = ({ measure, type_vintage_index }) => {      
  return getMeasureScoreRaw({ measure, type_vintage_index }) ? Math.round(getMeasureScoreRaw({ measure, type_vintage_index })) : null
}

export const getMeasureScoreRaw = ({ measure, type_vintage_index }) => {      
  return type_vintage_index != null && measure.type_vintages[type_vintage_index].study_data ? measure.type_vintages[type_vintage_index].study_data.measure_score_raw : null
}

export const getMeasureCredit = ({ climate_zone, measure, prototype, type_vintage_index, flexible_path_setup }) => {
  const isMeasureInCustomCombination = climate_zone?.type_vintages?.[type_vintage_index]?.custom_combination_measures_ids?.includes(parseInt(measure.id))
  const measureTargetScore = getMeasureScore({ measure, type_vintage_index })
  const measureIsMandatory = checkMeasureIsMandatory(({ measure, climate_zone, prototype, type_vintage_index, flexible_path_setup }))
  return isMeasureInCustomCombination && measureTargetScore >= 1 && !measureIsMandatory ? measureTargetScore : null
}

export const getMeasureCreditTextValue = ({ climate_zone, measure, prototype, type_vintage_index, flexible_path_setup }) => {
  return getMeasureCredit(({ climate_zone, measure, prototype, type_vintage_index, flexible_path_setup })) ?? '--'
}

export const hasMeasureScore = ({ measure, type_vintage_index }) => {
  return getMeasureScore({ measure, type_vintage_index }) === null ? false : true
}

export const getMaxTargetScorePresetSelected = ({ climate_zone_id, prototype_id, flexible_path_setup }) => {
  const prototype = Store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: prototype_id })
  const study = Store.getters['globalEntities/Study/getterGlobalStudy']({ id: +prototype.study_id })
  const enabledPresetsOnStudy = (new Study(study))?.enabled_cost_ratio_presets || []
  let default_preset = CUSTOM_COMBINATION_PRESETS.ALL_POSSIBLE_ON_BILL.value
  if (enabledPresetsOnStudy.includes(CUSTOM_COMBINATION_PRESETS.ALL_POSSIBLE_ON_BILL_2025.value)) {
    default_preset = CUSTOM_COMBINATION_PRESETS.ALL_POSSIBLE_ON_BILL_2025.value
  }

  const cost_effective_presets = flexible_path_setup?.cost_effective_presets
  if (cost_effective_presets) {
    const preset = cost_effective_presets.findInArray({ climate_zone_id, prototype_id })
    return preset && preset.preset ? preset.preset : default_preset
  } else {
    return default_preset
  }
}

export const getMaxPossibleScore = ({ climate_zone, type_vintage, max_target_scores_by_presets, type_vintage_index, prototype, flexible_path_setup}) => {
  const measuresByVintageAndPresets = getPresetMeasures({type_vintage, climate_zone, prototype, flexible_path_setup, max_target_scores_by_presets})
      .map(measure => measure.id)

  const maxCostEffectiveScore = getMaxCostEffectiveScore({ climate_zone, prototype, type_vintage, max_target_scores_by_presets, flexible_path_setup })
  
  const excludedMeasuresByVintage = getExcludedMeasuresByVintage({ climate_zone, type_vintage_index, prototype, flexible_path_setup })
  
  const mandatoryMeasuresByVintage = getMandatoryMeasuresByVintage({ climate_zone, type_vintage_index, prototype, flexible_path_setup } )    

  const measureScoreFromExcludedMeasuresByVintage = excludedMeasuresByVintage.reduce((prev, measure)=>{
    if(measuresByVintageAndPresets.includes(measure.id)){
      return prev + getMeasureScore({ measure, type_vintage_index })
    }
    return prev
  }, 0)

  const measureScoreFromMandatoryMeasuresByVintage = mandatoryMeasuresByVintage.reduce((prev, measure ) => {    
    if(measuresByVintageAndPresets.includes(measure.id)){
      return prev + getMeasureScore({ measure, type_vintage_index })
    }
    return prev
  }, 0)

  return Math.max(0, maxCostEffectiveScore - measureScoreFromMandatoryMeasuresByVintage - measureScoreFromExcludedMeasuresByVintage)
}

export const getMaxCostEffectiveScore = ({ climate_zone, prototype, type_vintage, max_target_scores_by_presets, flexible_path_setup }) => {

  const maxTargetScorePresetSelected = getMaxTargetScorePresetSelected({ climate_zone_id: climate_zone.id, prototype_id: prototype.id, flexible_path_setup })
  if (max_target_scores_by_presets) {
    const maxTargetScore = max_target_scores_by_presets.findInArray({ type_vintage_id: type_vintage.id, climate_zone_raw: climate_zone.raw })
    return maxTargetScore[maxTargetScorePresetSelected]
  } else {
    console.error('MAX TARGET SCORE NOT FOUND')
    return 0
  }
}

export const getTier = ({ climate_zone_id, prototype_id, flexible_path_setup }) => {
  try {
    const tierFinded = find(flexible_path_setup.tiers, { climate_zone_id, prototype_id })
    return tierFinded ? new MeasureMenuTier(tierFinded) : getDefaultTier({ climate_zone_id, prototype_id })
  } catch (error) {
    return getDefaultTier({ climate_zone_id, prototype_id })
  }
}

export const getTiers = ({ climate_zone_id, prototype_id, flexible_path_setup }) => {
  const tiers = findAll(flexible_path_setup.tiers, { climate_zone_id, prototype_id })
  if(!tiers.length) return [getDefaultTier({ climate_zone_id, prototype_id })]
  return tiers
}

export const getDefaultTier = ({ climate_zone_id, prototype_id }) => {
  return new MeasureMenuTier({ climate_zone_id, prototype_id })
}

export const setTierTargetScore = (payload) => {
 
  const { flexible_path_setup, prototype, climate_zone, type_vintage, value } = payload
  
  let tierFinded = find(flexible_path_setup.tiers, { prototype_id: prototype.id, climate_zone_id: climate_zone.id })
  if(tierFinded) {
    tierFinded = new MeasureMenuTier({...tierFinded })
    tierFinded.updateTargetScore({ type_vintage_id: type_vintage.id, value })
  } else {
    flexible_path_setup.tiers.push( 
      new MeasureMenuTier({
        climate_zone_id : climate_zone.id, 
        prototype_id : prototype.id , 
        target_scores : [{ type_vintage_id : type_vintage.id, value }]
      })
    )
  }

  eventBus('updateFlexiblePathSetup', flexible_path_setup)
}

export const updateTier = ({ tier, flexible_path_setup }) => {
  let flexiblePathSetup = { ... flexible_path_setup }    
  const tierIndex = findIndex(flexiblePathSetup.tiers, { prototype_id : tier.prototype_id , climate_zone_id: tier.climate_zone_id})
  if (tierIndex !== -1) {
    flexiblePathSetup.tiers.splice(tierIndex, 1, {...tier})      
  } else {
    flexiblePathSetup.tiers.push(tier)
  }

  eventBus('updateFlexiblePathSetup', flexible_path_setup)
}

export const getTierTargetScore = ({ tier, prototype, flexible_path_setup, type_vintage, climate_zone, max_target_scores_by_presets }) => {
  
  const targetScore = tier?.target_scores ? find(tier.target_scores, { type_vintage_id: type_vintage.id }) : false
  if(targetScore) return targetScore.value
  const maxTargetScore = getMaxCostEffectiveScore({ climate_zone, prototype, type_vintage, max_target_scores_by_presets, flexible_path_setup })
  return Math.round(maxTargetScore)
}

export const getCustomCombinationMeasures = ({ climate_zone, type_vintage_index, type_vintage }) => {
  type_vintage = type_vintage ?? climate_zone?.type_vintages?.[type_vintage_index] 
  const customCombinationMeasureIds = type_vintage?.custom_combination_measures_ids ?? []
  const customCombinationMeasures = climate_zone.measures.filter(measure => {
    return customCombinationMeasureIds?.includes(parseInt(measure.id)) ? true : false
  })
  return customCombinationMeasures
}

export const getCustomCombinationMeasuresByTypeVintageExcludingTheMandatoriesOrExcluded = ({ climate_zone, prototype, type_vintage_index, flexible_path_setup }) => {
  const customCombinationMeasures = getCustomCombinationMeasures({ climate_zone, type_vintage_index })    
  const customCombinationMeasuresByTypeVintageExcludingTheMandatories = customCombinationMeasures.filter((measure) => {
    return checkMeasureIsMandatory({ measure, climate_zone, prototype, type_vintage_index, flexible_path_setup }) ||
      checkMeasureIsExcluded({ measure, climate_zone, prototype, type_vintage_index, flexible_path_setup }) 
      ? false : true
  })
  return customCombinationMeasuresByTypeVintageExcludingTheMandatories
}

export const checkMeasureIsExcluded = ({ measure, climate_zone, type_vintage_index, prototype, flexible_path_setup }) => {
  const tier = getTier({ climate_zone_id: climate_zone.id, prototype_id: prototype.id, flexible_path_setup })
  if(!tier.excluded_measures.length) return false
  const type_vintage_id = climate_zone?.type_vintages?.[type_vintage_index]?.id
  return find(tier.excluded_measures, { measure_id: measure.id, type_vintage_id })
}

export const checkMeasureIsCostEffectiveness = ({ measure, type_vintage_index }) => {
  return measure.type_vintages[type_vintage_index].study_data && measure.type_vintages[type_vintage_index].study_data.is_cost_effectiveness
}

export const getPresetMeasures = ({type_vintage, climate_zone, prototype, flexible_path_setup, max_target_scores_by_presets}) => {
  const presetSelected = getMaxTargetScorePresetSelected({ climate_zone_id:climate_zone.id, prototype_id:prototype.id, flexible_path_setup })
  return (max_target_scores_by_presets || [])
      .find(preset => preset.type_vintage_id == type_vintage.id && preset.climate_zone_raw == climate_zone.raw)
      ?.data[presetSelected] || []
}

export const checkMeasureIsPreset = ({type_vintage, measure, climate_zone, prototype, flexible_path_setup, max_target_scores_by_presets}) => {
  return getPresetMeasures({type_vintage, climate_zone, prototype, flexible_path_setup, max_target_scores_by_presets})
      .find(preSetMeasure => preSetMeasure.id == measure.id)
}

//@deprecated
export const checkMeasureIsMandatoryByDefault = ({ climate_zone, measure, type_vintage_index }) => {
  return hasMeasureScore({ measure, type_vintage_index }) && 
    getMeasureScore({ measure, type_vintage_index }) < 1 &&
    getMeasureScoreRaw({ measure, type_vintage_index }) > 0 &&
    checkMeasureIsInCustomCombinatationByTypeVintage({ climate_zone, measure, type_vintage_index })
}

export const checkMeasureCanBeMandatory = ({ climate_zone, prototype, measure, type_vintage_index, flexible_path_setup }) => {
  // if (!checkMeasureIsCostEffectiveness({ measure, type_vintage_index })) return false
  if (!measure.include_in_combination) return false
  if (getOverlapedMandatoryMeasures({ climate_zone, prototype, measure, type_vintage_index, flexible_path_setup })) return false
  if (getOverlapedCustomComtinationMeasures({ climate_zone, measure, type_vintage_index })) return false
  return true
}

export const checkMeasureCanBeExcluded = ({ climate_zone, measure, type_vintage_index }) => {
  if (
    checkMeasureIsInCustomCombinatationByTypeVintage({ climate_zone, measure, type_vintage_index }) 
  ) return false
  return true
}

export const checkMeasureIsMandatory = ({ climate_zone, measure, prototype, type_vintage_index, flexible_path_setup }) => {
  const tier = getTier({ climate_zone_id: climate_zone.id, prototype_id: prototype.id, flexible_path_setup })
  if(!tier.mandatory_measures.length) return false

  const type_vintage_id = climate_zone?.type_vintages?.[type_vintage_index]?.id
  return find(tier.mandatory_measures, { measure_id: measure.id, type_vintage_id })
}

export const toggleExcludedMeasures = ({ measure, climate_zone, prototype, type_vintage, type_vintage_index, flexible_path_setup }) => {

  
  if (
    checkMeasureIsMandatory({ measure, climate_zone, prototype, type_vintage_index, flexible_path_setup }) && 
    !checkMeasureIsExcluded({ measure, climate_zone, prototype, type_vintage_index, flexible_path_setup })
  ) {      
    toggleMandatoryMeasures({ measure, climate_zone, type_vintage, prototype, type_vintage_index, flexible_path_setup })
  }

  const tier = getTier({ climate_zone_id: climate_zone.id, prototype_id : prototype.id, flexible_path_setup })
  tier.toggleExcludedMeasures({ measure_id: measure.id, type_vintage_id: type_vintage.id })
  updateTier({ tier, flexible_path_setup })
}

export const toggleMandatoryMeasures = ({ measure, climate_zone, type_vintage, prototype, type_vintage_index, flexible_path_setup }) => {
  if (!checkMeasureCanBeMandatory({ climate_zone, prototype, measure, type_vintage_index, flexible_path_setup })) return false 
  if (
    checkMeasureIsExcluded({ measure, climate_zone, prototype, type_vintage_index, flexible_path_setup }) && 
    !checkMeasureIsMandatory({ measure, climate_zone, prototype, type_vintage_index, flexible_path_setup })
  ) {
    toggleExcludedMeasures({measure, climate_zone, type_vintage, type_vintage_index, flexible_path_setup})
  }

  // if(!checkMeasureIsCostEffectiveness({ measure, type_vintage_index })) return

  const tier = getTier({ climate_zone_id: climate_zone.id, prototype_id : prototype.id, flexible_path_setup })
  tier.toggleMandatoryMeasures({ measure_id: measure.id, type_vintage_id: type_vintage.id })
  updateTier({ tier, flexible_path_setup })    
}

 // https://epcgo.atlassian.net/wiki/spaces/BL/pages/583794759/Measure+Credit+Table
export const getMeasureCreditTableRows = ({ climate_zone, prototype, flexible_path_setup }) => {
  let rows = []
  
  for ( const measure of climate_zone.measures) {
    const row = { measure, valid_measure_credits : 0 }
    for ( const type_vintage_index in climate_zone.type_vintages) {
      if (getMeasureCredit({ climate_zone, measure, prototype, type_vintage_index, flexible_path_setup })) {
        row.valid_measure_credits++
      }
    }
    rows.push(row)
  }
  return rows
}

export const checkMeasureIsInCustomCombinatationByTypeVintage = ({ climate_zone, measure, type_vintage_index }) => {
  return climate_zone?.type_vintages?.[type_vintage_index]?.custom_combination_measures_ids?.includes(parseInt(measure.id))
}

export const checkMeasureIsInCustomCombinatationByAnyTypeVintage = ({ climate_zone, measure }) => {
  let isCustomCombinationMeasureAndHasStudyDataInAnyTypeVintage = false
      
  for ( const type_vintage of climate_zone.type_vintages.values() ) {
    if (type_vintage?.custom_combination_measures_ids?.includes(parseInt(measure.id))) {
      isCustomCombinationMeasureAndHasStudyDataInAnyTypeVintage = true
      break
    }
  }

  return isCustomCombinationMeasureAndHasStudyDataInAnyTypeVintage
}

export const shouldBeIncludedAtMeasureMenuListInAnyTypeVintage = ({ measure, climate_zone, prototype, flexible_path_setup }) => {
  if (measure.hide_in_flexible_path) return false
  
  let shouldBeIncluded = false
  for ( const type_vintage_index in measure.type_vintages ) {
    if (getMeasureScore({ measure, type_vintage_index }) || checkMeasureIsMandatory({ measure, climate_zone, prototype, type_vintage_index, flexible_path_setup }) || checkMeasureIsInCustomCombinatationByTypeVintage({ climate_zone, measure, type_vintage_index })) {
      shouldBeIncluded = true
      break
    }
  }

  return shouldBeIncluded
}

// eslint-disable-next-line no-unused-vars
export const shouldBeIncludedInTypeVintage = ({ climate_zone, type_vintage_index, flexible_path_setup }) => {
  // return climate_zone?.type_vintages?.[type_vintage_index]?.custom_combination_measures_ids?.length > 0 ? true : false
  return true
}

export const getOverlapedMandatoryMeasures = ({ climate_zone, prototype, measure, type_vintage_index, flexible_path_setup }) => {
  if (measure.include_in_combination !== true ) return false
  
  let overlapedMandatoryMeasures = []

  const exclude_measures_ids = measure.exclude_measures ?? []
  for( const exclude_measures_id of exclude_measures_ids ) {
    const excluded_measure = climate_zone.measures.find(measure => parseInt(measure.id) === parseInt(exclude_measures_id))
    if (excluded_measure && checkMeasureIsMandatory({ measure: excluded_measure , climate_zone, prototype, type_vintage_index, flexible_path_setup })) {
      overlapedMandatoryMeasures.push(excluded_measure)
    }
  }

  return overlapedMandatoryMeasures.length > 0 ? overlapedMandatoryMeasures : false      
}

export const getOverlapedCustomComtinationMeasures = ({ climate_zone, measure, type_vintage_index }) => {
  if (measure.include_in_combination !== true ) return false
  
  let overlapedCustomCombinationMeasures = []

  const exclude_measures_ids = measure.exclude_measures ?? []

  for( const exclude_measures_id of exclude_measures_ids ) {
    const excluded_measure = climate_zone.measures.find(measure => parseInt(measure.id) === parseInt(exclude_measures_id))
    const global_measure = excluded_measure ? Store.getters['globalEntities/Measure/getterGlobalMeasures']({id:excluded_measure.id}).pop() : null
    const can_ignore = Boolean(excluded_measure?.hide_in_flexible_path === true && global_measure?.is_package === true)
    if (!can_ignore && excluded_measure && checkMeasureIsInCustomCombinatationByTypeVintage({ climate_zone, measure: excluded_measure, type_vintage_index })) {
      overlapedCustomCombinationMeasures.push(excluded_measure)
    }
  }

  return overlapedCustomCombinationMeasures.length > 0 ? overlapedCustomCombinationMeasures : false      
}

export const getExcludedMeasuresByVintage = ({ climate_zone, type_vintage_index, prototype, flexible_path_setup }) => {
  return climate_zone.measures.filter((measure) => checkMeasureIsExcluded({ measure, climate_zone, type_vintage_index, prototype, flexible_path_setup }))
}

export const getMandatoryMeasuresByVintage = ({ climate_zone, type_vintage_index, prototype, flexible_path_setup }) => {
  return climate_zone.measures.filter((measure) => checkMeasureIsMandatory({ measure, climate_zone, type_vintage_index, prototype, flexible_path_setup }))
}

export default {
  getMeasureScoreTextValue,
  getMeasureScore,
  getMeasureScoreRaw,
  getMeasureCredit,
  getMeasureCreditTextValue,
  hasMeasureScore,
  getMaxPossibleScore,
  getMaxCostEffectiveScore,
  getTier,
  getTiers,
  getDefaultTier,
  setTierTargetScore,
  updateTier,
  getTierTargetScore,
  getCustomCombinationMeasures,
  getCustomCombinationMeasuresByTypeVintageExcludingTheMandatoriesOrExcluded,
  checkMeasureIsExcluded,
  checkMeasureIsCostEffectiveness,
  checkMeasureIsMandatoryByDefault,
  checkMeasureCanBeMandatory,
  checkMeasureCanBeExcluded,
  checkMeasureIsMandatory,
  toggleExcludedMeasures,
  toggleMandatoryMeasures,
  getMeasureCreditTableRows,
  checkMeasureIsInCustomCombinatationByTypeVintage,
  checkMeasureIsInCustomCombinatationByAnyTypeVintage,
  shouldBeIncludedAtMeasureMenuListInAnyTypeVintage,
  shouldBeIncludedInTypeVintage,
  getOverlapedMandatoryMeasures,
  getOverlapedCustomComtinationMeasures,
  getExcludedMeasuresByVintage,
  getMandatoryMeasuresByVintage,
  getMaxTargetScorePresetSelected,
  checkMeasureIsPreset,
  getPresetMeasures,
}