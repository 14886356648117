<template>
  <div class="psui-w-full psui-px-4 sm:psui-px-8">
    <PoliciesListUserPolicies :is-loading="isLoading" />
  </div>
</template>

<script>
import PoliciesListUserPolicies from './PoliciesListUserPolicies.vue'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'

export default {
  name: 'PoliciesList',
  components: { PoliciesListUserPolicies },
  data: () => ({
    isLoading : false,
  }),
  computed: {
    getUser() {
      return this.$store.state.auth.loggedUser
    },
    getAllUserPolicies() {
      return this.$store.getters['policy/getterUserPolicies']()
    },
  },
  mounted() {
    this.init()
  },
  methods : {
    async init() {
      this.isLoading = true
      await this.$store.dispatch('policy/getAllUserPolicies')
      if(this.getAllUserPolicies.length > 0 && this.$route.name === 'PoliciesList') {
        this.$router.push({query:{only_study_type:`${Object.values(STUDY_TYPES_DB_SLUGS).join()}`}}, () => {})
      }
      setTimeout(() => {
        this.isLoading = false
      }, 300)
      
    }
  }
}
</script>

<style lang="scss" scoped>
.actions-wrapper:hover {
  .policy-title {
    font-weight: bold;
    color: #4B8CA6 !important;
  }
}
</style>