<template>
  <BaseAppHeader v-if="getPolicy">
    <template #top-left> 
      <div class="psui-flex w-full justify-between items-center pb-3 pl-2">
        <div class="psui-flex psui-items-center">
          <PsTooltip class="back-to-policies">
            <template #trigger>
              <button
                class="flex items-center psui-text-gray-80 hover:psui-text-blue-60"
                @click="goToPoliciesList"
              >
                <i class="material-icons-round icon flex items-center">arrow_back</i>
              </button>        
            </template>
            <template #content>
              {{ 'Back to all policies' | policyTextRephrase }}
            </template>
          </PsTooltip>
          <h1 
            v-tooltip="{ content: getPolicy.title, placement: 'bottom-start' }"
            class="policy-header-title psui-text-h4 psui-text-gray-80 font-bold flex-shrink-0 transition-all pl-1/2"
          >
            {{ getPolicy.title }}
          </h1>
          <span class="psui-pl-2 psui-text-h4 psui-text-gray-40 mr-2">
            {{ getPolicy.jurisdiction | editJurisdictionTitleTypeBasedOnJurisdictionType('title_type') }}
          </span>
          <PsMiniTag
            v-if="getPolicyStudyTypes"
            :message="getPolicyStudyTypes"
            layout="solid-default"
          />
          <PsMiniTag
            v-if="getPolicy.codeCycle && getPolicyStudyTypeSlugs === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS"
            :message="`${getPolicy.codeCycle} Code Cycle`"
            layout="solid-default"
            class="psui-ml-2"
          />
          <AppSharedIcon v-if="getPolicyType && getPolicyType === POLICY_TYPES.SHARED.value" />
        </div>
      </div>
    </template>
    <template #top-right>
      <div class="flex gap-2 transition-all pb-3">
        <PsButton 
          label="Share"
          icon="share"
          size="small"
          layout="onlytext"
          @click="copyToClipboard"
        />
        <PsTooltip :ignore-dialog="getPolicySelectedHasCustomCombination">
          <template #trigger>
            <PsButton
              data-test-id="policy-show-header-edit-assumptions" 
              :label="userCanNotEditPolicy ? 'View Assumptions' : 'Edit Assumptions'"
              icon="tune"
              size="small"
              layout="onlytext"
              :disabled="!getPolicySelectedHasCustomCombination"
              @click="openAssumptionsSidebar"
            />   
          </template>
          <template #content>
            You need to select a measure first.
          </template>
        </PsTooltip>
        <PsButton
          v-if="canDuplicatePolicy"
          :label="'Duplicate Policy' | policyTextRephrase"
          icon="text_snippet"
          size="small"
          icon-position="left"
          @click="openPolicyDuplicateModal()"
        />
        <PsButton
          v-if="getPolicyType === POLICY_TYPES.DRAFT.value"
          :label="'Save Policy' | policyTextRephrase"
          icon="text_snippet"
          size="small"
          icon-position="left"
          @click="openLoginModal()"
        />
      </div>
    </template>
    <template #bottom-left>
      <div class="psui-w-full psui-flex psui-relative psui-justify-between psui-items-center pl-8 policy-show-tabs">
        <PsTabHeader
          data-test-id="policy-show-header-tab-header" 
          :items="getTabs"
          layout="underline"
          key-label="title"
          key-value="pathName"
          :selected="getTabSelected"
          @change="updateRoute"
        />
      </div>
    </template>
  </BaseAppHeader>
</template>

<script>
import AppSharedIcon from '../../../../components/general/AppSharedIcon.vue'
import { POLICIES_TAB_DESCRIPTION, POLICY_TYPES } from "../shared/enums.js"
import { STUDY_TYPES_DB_SLUGS, ASSUMPTIONS_DRAWER_TYPES } from '@/util/Enums.js'
import { copyUrlToClipboard } from '@/util/Helpers'
import BaseAppHeader from '@/components/general/BaseAppHeader.vue'
import FlexiblePathService from '@/services/api/FlexiblePathService'

export default {
  name: 'PolicyShowHeader',
  components:{BaseAppHeader, AppSharedIcon},
  data() {
    return {
      userPoliciesIds: '',
      POLICIES_TAB_DESCRIPTION,
      tabSelected: false,
      POLICY_TYPES,
      STUDY_TYPES_DB_SLUGS,
      flexible_path_setup: null,
    }
  },
  computed: {
    getPolicyType(){
      return this.$store.getters['policy/getterPolicyType'](this.$route.params.policy_id)
    },
    getPolicyStudyTypes() {
      const policyStudyTypes = this.$store.getters['policy/getterPolicyStudyTypes']({ key: 'title', policy_id: this.$route.params.policy_id })
      return policyStudyTypes ? policyStudyTypes.join(',') : ''
    },
    getPolicyStudyTypeSlugs() {
      const policyStudyTypes = this.$store.getters['policy/getterPolicyStudyTypes']({ key: 'slug', policy_id: this.$route.params.policy_id })
      return policyStudyTypes ? policyStudyTypes.join(',') : ''
    },
    getPolicyCodeCycle() {      
      return this.getPolicy?.code_cycle 
    },
    getPolicy() {
      return this.$store.getters['policy/getterUserPolicy']({ id: this.$route.params.policy_id })
    },
    getPolicyCustomCombinations() {
      const policy_id = this.$route.params.policy_id
      if (!policy_id) return []
      return this.$store.getters['policy/getterPolicyCustomCombinations'](policy_id) 
    },
    policyHasCustomCombinations() {
      return this.$store.getters['policy/getterPolicyCustomCombinations'](this.$route.params.policy_id)
    },
    getTabs() {
      const study_type_slug = this.$store.getters['policy/getterPolicyStudyTypes']({ key: 'slug', policy_id: this.$route.params.policy_id })[0] ?? null

      const isUserAdminOrTeam = this.$store.getters['general/getterDeviceIsAdminOrTeam']
      const flexibleTab = { 
        title: 'Flexible Compliance',
        pathName: 'flexible_path', 
        requireCustomCombinations: true,
        visibleOnlyInStudyType: STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS,
        isUserAdminOrTeam
      }

      let tabs = [
        { title: 'Requirements', pathName: 'requirements' },
        { title: 'Impact', pathName: 'impact', requireCustomCombinations: true },
        { title: 'Documents', pathName: 'documents', requireCustomCombinations: true },
      ] 

      if (isUserAdminOrTeam) tabs.push(flexibleTab)
      return tabs.filter(tab => {
        if (tab.requireCustomCombinations && this.getPolicyCustomCombinations?.length <= 0 && !this.hasFlexConfigured) return false
        if (tab.visibleOnlyInStudyType && tab.visibleOnlyInStudyType !== study_type_slug) return false
        return true
      })
    },
    getTabSelected() {
      const activeTabSelected = this.getTabs.find((tab) => tab.pathName == this.$route.params.policy_active_tab)
      return activeTabSelected ?? this.getTabs[0]
    },
    userCanNotEditPolicy() {
      return !this.$store.getters['policy/getterUserCanEditPolicy'](this.$route.params.policy_id)
    },
    canDuplicatePolicy() {
      return !this.$store.getters['policy/getterUserCanEditPolicy'](this.$route.params.policy_id, false)
    },
    getPolicySelectedHasCustomCombination(){
      return this.getPolicy.policy_containers
      .map(container => container.custom_combinations)
      .reduce((acc,curr)=> acc.concat(curr),[])
      .length ? true : false
    },
    hasFlexConfigured() {
      return Boolean(this.flexible_path_setup?.tiers?.some((tier) => {
        return tier?.excluded_measures?.length || tier?.target_scores?.some((ts) => ts?.value != null && ts?.value > 0)
      }))
    }
  },
  watch: {
    '$route.params.policy_id': {
      immediate: true,
      handler: async function(val, oldVal) {
        if (val !== oldVal) {
          this.flexible_path_setup = await (new FlexiblePathService({ policy_id : this.$route.params.policy_id })).getSetup()
        }
      }
    },
  },
  mounted() {
    this.$eventBus.$on('FlexPathUpdated', async () => {
      this.flexible_path_setup = await (new FlexiblePathService({ policy_id : this.$route.params.policy_id })).getSetup()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('FlexPathUpdated')
  },
  methods: {
    updateRoute(tab){
      if (tab.pathName !== this.$route.params.policy_active_tab) {
        this.$router.push({...this.$route, params:{...this.$route.params, policy_active_tab: tab.pathName }}).catch(() => {})
      }
    },
    copyToClipboard(){
      copyUrlToClipboard()
      this.$appToast({ message: 'Link copied to clipboard!' })
    },
    openAssumptionsSidebar() {
      const getAssumptionsDrawerByPolicyStudyType = this.$store.getters['policy/getterPolicyStudyTypes']({policy_id:this.getPolicy.id, policy:this.getPolicy})[0] === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS ?
      ASSUMPTIONS_DRAWER_TYPES.EXISTING_RESULTS :
      ASSUMPTIONS_DRAWER_TYPES.NEW_RESULTS

      const getPolicyTabByPolicyStudyType = this.$store.getters['globalEntities/StudyType/getterGlobalStudyType']({slug: this.$store.getters['policy/getterPolicyStudyTypes']({policy_id:this.getPolicy.id, policy:this.getPolicy})[0]})
      
      const drawerOptions = {
        ...this.$store.getters['assumptions/getterAssumptionsDrawerOptions'],
        type: getAssumptionsDrawerByPolicyStudyType,
        tab: getPolicyTabByPolicyStudyType
      }
      
      this.$store.commit('assumptions/setDrawerOptions', drawerOptions)
      this.$store.commit('assumptions/openDrawerAtImpactTab')
    },
    goToPoliciesList(){
      const policyStudyTypes = this.$store.getters['policy/getterPolicyStudyTypes']({ key: 'slug', policy_id: this.$route.params.policy_id }) ?? []
      this.$router.push({
        name: 'PoliciesList',
        query: {
          only_study_type: policyStudyTypes.join(',')
        }
      })
    },
    openLoginModal() {     
      this.$eventBus.$emit('openModalUserSign')
      this.$store.commit('general/setRedirectTo', { route: this.$route.path })      
    },
    openPolicyDuplicateModal() {
      const policy_id = this.$route.params.policy_id
      const policy = this.$store.getters['policy/getterUserPolicy']({ id: policy_id })
      this.$eventBus.$emit('openDuplicatePolicyModal', { policy })
    },
  },
}
</script>

<style lang="scss" scoped>

  ::v-deep .psui-el-tooltip.back-to-policies {
    .psui-el-tooltip-wrapper {
      .psui-el-tooltip-dialog {
        left: 212px !important;
      }
    }
  }

  .icon {
    font-size: 18px;
  }

  .shared-icon {
    font-size: 16px;
  }

  .policy-header-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: 640px) {
      max-width: 6.25rem;
    }
    @media (min-width: 641px) and (max-width: 1024px) {
      max-width: 15rem;
    }
    @media (min-width: 1025px) and (max-width: 1280px) {
      max-width: 20rem;
    }
    @media (min-width: 1281px) and (max-width: 1600px) {
      max-width: 25rem;
    }
    @media (min-width: 1601px) {
      max-width: 40rem;
    }
  }

  ::v-deep.app-header {
    padding-left: 0;
  }
  
</style>
