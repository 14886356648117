import Api from '@/util/Api'
import Graphql from '@/util/GraphQL'
import CustomCombination from '@/models/CustomCombination'
import asyncCacheResolver from '@/util/asyncCacheResolver'

export default class CustomCombinationApiService {
  
  static updateOrCreate(customCombination) {
    const merged = new CustomCombination(customCombination)
    return Api.post(`/api/custom_combinations/store`, merged)
      .then(({ data }) => {
        return data.custom_combination 
    })
  }

  static updateById(customCombinationId, updateProps) {
    return Api.post(`/api/custom_combinations/update_or_create/${customCombinationId}`, updateProps)
      .then(({ data }) => {
        return data.custom_combination
      })
  }

  static show(custom_combination_id) {
    return Api.get(`/api/custom_combinations/show/${custom_combination_id}`)
        .then(({ data }) => data.custom_combination)
  }

  static get(custom_combination_id) {
    const query = `
    {
      custom_combination(where: [{column: "id", value: "${custom_combination_id}"}]) {
        id
        user_id
        policy_id
        jurisdiction_id
        climate_zone_raw
        fuel_id
        prototype_id
        vintage_id
        meta
        policy_container_id
        measures {
          id
          type_measures {
            id
            title
          } 
        }
        fuel {
          id
          type_fuel {
            id
            title
            slug
          }
        }
      }  
    }`
    return Graphql({ query, caller: 'CustomCombinationApiService.get' })
      .then(({ data }) => data.custom_combination)
  }
  
  static getPresets({ vintage_id, prototype_id, climate_zone_raw }) {
    const cacheKey = `CustomCombinationApiService.getPresets:${vintage_id}:${prototype_id}:${climate_zone_raw}`
    return asyncCacheResolver(cacheKey, () => {
      return Api.post(`/api/custom_combination/get_presets`, {
        vintage_id,
        prototype_id,
        climate_zone_raw
      }).catch(() => {
        return {
          presets: {}
        }
      })
    })
  }

  static delete(custom_combination_id) {
    return Api.delete(`/api/custom_combinations/delete/${custom_combination_id}`)
  }

}
