import { render, staticRenderFns } from "./BaseLayoutWithHeaderAndSideBar.vue?vue&type=template&id=536678f1&scoped=true"
import script from "./BaseLayoutWithHeaderAndSideBar.vue?vue&type=script&lang=js"
export * from "./BaseLayoutWithHeaderAndSideBar.vue?vue&type=script&lang=js"
import style0 from "./BaseLayoutWithHeaderAndSideBar.vue?vue&type=style&index=0&id=536678f1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "536678f1",
  null
  
)

export default component.exports