<template>
  <transition name="fade">
    <div class="login-modal-wrapper">
      <LoaderSpinner />
    </div>
  </transition>
</template>

<script>
import LoaderSpinner from '@/components/general/LoaderSpinner.vue'

export default {
  name: 'LoginLoading',
  components: { LoaderSpinner },
}
</script>

<style lang="scss" scoped>
.login-modal-wrapper {
  position: fixed;
  inset: 0px;
  background-color: rgba(78, 78, 78, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 60;
  padding: 1rem;
  overflow-y: auto;
}
</style>
