<template>
  <div
    v-if="filters"
    class="my-7"
  >
    <h2 class="font-bold psui-text-gray-80 mb-4 psui-text-big">
      New Nonresidential Construction
    </h2>
    <AssumptionsBuildingEstimatesInputs
      :filters="filters"
      unit-measurement="Square feet"
    />
  </div>
</template>

<script>

import AssumptionsBuildingEstimatesInputs from '../AssumptionsBuildingEstimatesInputs.vue'

export default {
  name: 'AssumptionsResidentialNewOverall',
  components: { AssumptionsBuildingEstimatesInputs },
  props: {
    filters: Object,
  },
}
</script>

<style lang="scss" scoped>
  ::v-deep .psui-el-input {
    width: 130px;
  }
</style>