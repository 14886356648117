<template>
  <BaseDrawer 
    ref="baseDrawer" 
    :title="getData('drawerTitle')"
    :subtitle="getData('drawerSubtitle')"
    :hierarchy="DRAWER_HIERARCHY.SECONDARY"
  >
    <div
      v-if="checkIfTabsShouldBeVisible"
      class="w-full flex pr-4 pb-2"
    >
      <div
        v-for="columnTab in getColumnTabs"
        :key="columnTab.key"
        class="border-b-2 py-3 pr-3"
        :class="[columnTab.key === getColumnTabSelectedKey ? 'border-blue04 text-pri font-bold' : 'border-blue04-10 text-gray font-semi-bold']"
      >
        <button 
          :class="[columnTab.key === getColumnTabSelectedKey ? 'border-blue04 text-blue02 font-bold' : 'border-blue04-10 text-gray font-semi-bold']"
          class="focus:outline-none"
          @click="onSelectColumnTab(columnTab.key)"
        >
          {{ columnTab.title }}
        </button>
      </div>     
    </div>
    <UserPreferencesDragAndDropEditColumnsList
      class="mt-4/5"
      :column-tab-selected-key="getColumnTabSelectedKey"
      :user-preferences-column-tabs-key="userPreferenceKey"
    />
  </BaseDrawer>
</template>

<script>
import UserPreferencesDragAndDropEditColumnsList from './UserPreferencesDragAndDropEditColumnsList.vue'
import { DRAWER_HIERARCHY } from '@/util/Enums.js'

const defaults = {
  drawerTitle : 'Add/Hide Columns',
  drawerSubtitle : 'Select or re-arrange columns to display'
}

export default {
  name: 'TheEditColumnsDrawer',
  components: { UserPreferencesDragAndDropEditColumnsList },
  data() {
    return {
      drawerTitle : false,
      drawerSubtitle : false,      
      userPreferenceKey: false,
      DRAWER_HIERARCHY,
      hideTabsIfIsOnlyOne : true,
    }
  },
  computed: {
    checkIfTabsShouldBeVisible() {
      if (!this.getColumnTabs) return false
      if (Object.values(this.getColumnTabs).length === 1 && this.hideTabsIfIsOnlyOne) return false
      
      return true
    },
    getColumnTabs() {
      return this.userPreferenceKey ? this.$store.getters['getterUserPreference'](this.userPreferenceKey) : false
    },
    getColumnTabSelectedKey() {
      const activeTabSelected = Object.values(this.getColumnTabs)?.findInArray({ isActive: true })?.key ?? false
      return activeTabSelected ?? Object.values(this.getColumnTabs)?.[0].key
    }
  },  
  mounted() {
    this.$eventBus.$on('openTheEditColumnsDrawer', (args) => this.openDrawer(args))
  },
  beforeDestroy() {
    this.$eventBus.$off('openTheEditColumnsDrawer')
  },
  methods: {
    getColumGroupTitle() {
    },
    getData(key) {
      return this[key] ? this[key] : defaults[key] ?? ''
    },
    openDrawer({ drawerTitle, drawerSubtitle, userPreferenceKey, hideTabsIfIsOnlyOne }) {
      if (!userPreferenceKey) {
        console.error('TheEditColumnsDrawer has been open without a userPreferenceKey')
        return 
      }

      if (drawerTitle) this.drawerTitle = drawerTitle
      if (drawerSubtitle) this.drawerSubtitle = drawerSubtitle
      if (hideTabsIfIsOnlyOne != undefined) this.hideTabsIfIsOnlyOne = hideTabsIfIsOnlyOne

      this.userPreferenceKey = userPreferenceKey
      this.$refs.baseDrawer.openDrawer()
    },
    onSelectColumnTab(selectedColumnTabKey) {
      let columnTabs = { ... this.getColumnTabs }
      for ( let columnTabKey in columnTabs) {
        columnTabs[columnTabKey].isActive = columnTabKey === selectedColumnTabKey ? true : false
      }
      this.$store.dispatch('setUserPreferenceData', { path : this.userPreferenceKey, data: columnTabs })
    }
  }
}
</script>
<style lang="scss" scoped>
  ::v-deep {
    &.app-drawer {
      width: 478px;
    }
  }
</style>
