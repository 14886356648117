import GraphQL from '@/util/GraphQL'

export default {
  namespaced: true,

  state: {
    fuels: []
  },

  getters: {
    getterGlobalFuels: (state) => (filters = null) => {
      return filters ? state.fuels.findAllInArray(filters) : state.fuels
    },

    getterGlobalFuel: (state) => (filters = null) => {
      return filters ? state.fuels.findInArray(filters) : false
    },

  },

  actions: {
    loadGlobalFuels(context) {
      const query = `
        {
          fuels (
            orderBy: [{ column: "order", order: "ASC" }]
          ) {
            id
            title
            slug
            study_id
            type_fuel_id
            order
            type_fuel {
              id
              title
              slug
              order
            }
          }
        }
      `
      GraphQL({ query })
        .then(({ data }) => {
          data.fuels.sort((a, b) => a.type_fuel.order - b.type_fuel.order )
          context.commit('setGlobalFuels', { fuels: data.fuels})
        })
    }
  },

  mutations: {
    setGlobalFuels(state, { fuels }) {
      state.fuels = fuels
    }
  }
}
