<template>
  <BaseDrawerLeft ref="baseDrawerLeft">
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-class="opacity-0 -translate-x-40"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-200 transform"
      leave-class="opacity-100"
      leave-to-class="opacity-0 -translate-x-40"
      mode="out-in"
    >
      <div :is="menu" />
    </transition>
  </BaseDrawerLeft>
</template>

<script>
import SidebarMenuNextSteps from './SidebarMenuNextSteps.vue'
import SidebarMenuHelp from './SidebarMenuHelp.vue'
import BaseDrawerLeft from '@/layout/BaseDrawerLeft.vue'
import SidebarMenuInfo from './SidebarMenuInfo.vue'
import SidebarMenuInfoHelp from '@/components/general/SidebarMenuInfoHelp.vue'

export default {
  name: 'SidebarLeftMenu',
  components: { BaseDrawerLeft, SidebarMenuHelp, SidebarMenuNextSteps, SidebarMenuInfo, SidebarMenuInfoHelp },
  data: () => ({
    menu: false
  }),
  mounted() {
    this.$eventBus.$on('openSidebarMenu', ( { menu }) => {
      this.menu = menu
      this.$refs.baseDrawerLeft.openDrawer()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openSidebarMenu')
  },
}
</script>

<style lang="scss" scoped>
  // ::v-deep {
  //   ul {
      
  //     li {        
  //       min-width: 378px;
  //       padding-bottom: 40px;
  //       padding-top: 40px;
  //       border-bottom: 1px solid #e4eef2;
	// 			padding-left: 14.5px;
	// 			padding-right: 14.5px;    
  //       cursor: pointer;
      
  //       h2 {
  //         font-size: 24px;
  //         line-height: 30px;
  //         font-weight: bold;
  //         color: app-color(pri);
  //         transition: 350ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  //         +p {
  //           margin-top: 14px;
  //         }
  //       }
  //       p {
  //         font-size: 16px;
  //         line-height: 22px;
  //         color: #76848a;
  //       }

  //       &:hover {
  //         h2 {
  //           color: app-color(sec);
  //         }
  //       }
  //       &:last-of-type {
  //         border-bottom: 0;
  //       }
        
  //     }
  //   }
  // }
</style>