import CustomCombinationMetaData from './meta-data/custom-combination/CustomCombinationMetaData'

export default class CustomCombination {
  constructor( obj = { }) {
    this.id = obj.id || null
    this.user_id = obj.user_id || null 
    this.policy_id = obj.policy_id || null 
    this.policy_container_id = obj.policy_container_id || null 
    this.jurisdiction_id = obj.jurisdiction_id || null 
    this.climate_zone_raw = obj.climate_zone_raw || null 
    this.study_id = obj.study_id || null 
    this.prototype_id = obj.prototype_id || null 
    this.vintage_id = obj.vintage_id || null 
    this.fuel_id = obj.fuel_id || null
    this.measures =  obj.measures || []
    this.meta =  obj.meta ? new CustomCombinationMetaData(obj.meta) : {}
  }
}