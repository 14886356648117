<template>
  <PsAccordionItem
    title="Electrification Rate Business as Usual"
    class="display-impact-assumption-icon"
    :class="{'form-dirty': hasValueUpdated(null, null) && !getDisabledStatusByStudyType }"
    :disabled="getDisabledStatusByStudyType"
  >
    <template #header-additionals>
      <PsIcon
        icon="help_outline"
        size="16"
        display="flex"
        class="help psui-items-center psui-h-5 hover:psui-text-blue-60"
        :icon-classes="hasValueUpdated(null, null) ? 'mr-5': ''"
        @click.native.stop="$eventBus.$emit('openDescriptionModal', { slug: 'electrification_business_as_usual', ...descriptionModelObject })"
      />
    </template>
    <template #default>
      <span>
        Without this {{ 'policy' | policyTextRephrase('small') }}, we assume that <b>this percentage of new units</b> would be constructed as all-electric (powered by electricity only).
      </span>
      <transition
        name="psui-el-compound"
        @enter="startTransition"
        @after-enter="endTransition"
        @before-leave="startTransition"
        @after-leave="endTransition"
      >
        <div
          v-if="specifyBy.length === 0"
          class="psui-flex psui-gap-3 psui-items-center mt-4/5"
        >
          <PsInput
            class="w-1/2"
            :class="{'selected': hasValueUpdated(null, null)}"
            :value="getMixedFuelPerPrototype(null, null)"
            :disabled="isInputDisabled(null, null)"
            :show-tooltip="isInputDisabled(null, null)"
            :tooltip-text="getTooltipText(null, null)"
            layout="mini"
            hint="mixed-fuel"
            @click.native="checkIfUserCanEditAssumption"
            @input="onInput(null, null, maxValue-$event.target.value)"
            @change="updateAssumption(null, null,maxValue - $event.target.value)"
            @mouseenter="onMouseEnterInsideInput(null, null, mixedFuelPrefix)"
            @mouseleave="onMouseLeaveInsideInput"
          >
            <template #append>
              <span
                v-if="!hasValueUpdated(null, null) || isInputDisabled(null, null) || !canShowResetButton(null, null, mixedFuelPrefix)"
                class="psui-text-gray-50 psui-text-xsmall"
              >%</span>
              <PsTooltip v-else-if="canShowResetButton(null, null, mixedFuelPrefix)">
                <template #trigger>
                  <PsIcon 
                    icon="restart_alt"
                    icon-classes="psui-text-green-20 cursor-pointer mt-1"
                    size="17"
                    @click.native="updateAssumption(null, null, getBaseValue)"
                  />
                </template>
                <template #content>
                  <h1 class="mb-1">
                    Restore to default value
                  </h1>
                  <span class="psui-font-normal">{{ maxValue - getBaseValue }}% mixed fuel</span>
                </template>
              </PsTooltip>
            </template>
          </PsInput>
          <PsInput
            :data-test-id="`input-assumption-${assumptionKey}`"
            class="w-1/2"
            :class="{'selected': hasValueUpdated(null, null)}"
            :value="getAssumptionValue(null, null)"
            :disabled="isInputDisabled(null, null)"
            :show-tooltip="isInputDisabled(null, null)"
            :tooltip-text="getTooltipText(null, null)"
            layout="mini"
            hint="all-electric"
            @click.native="checkIfUserCanEditAssumption"
            @input="onInput(null, null, $event.target.value)"
            @change="updateAssumption(null, null, $event.target.value)"
            @mouseenter="onMouseEnterInsideInput(null, null, allElectricPrefix)"
            @mouseleave="onMouseLeaveInsideInput"
          >
            <template #append>
              <span
                v-if="!hasValueUpdated(null, null) || isInputDisabled(null, null) || !canShowResetButton(null, null, allElectricPrefix)"
                class="psui-text-gray-50 psui-text-xsmall"
              >%</span>
              <PsTooltip v-else-if="canShowResetButton(null, null, allElectricPrefix)">
                <template #trigger>
                  <PsIcon 
                    icon="restart_alt"
                    icon-classes="psui-text-green-20 cursor-pointer mt-1"
                    size="17"
                    @click.native="updateAssumption(null, null, getBaseValue)"
                  />
                </template>
                <template #content>
                  <h1 class="mb-1">
                    Restore to default value
                  </h1>
                  <span class="psui-font-normal">{{ getBaseValue }}% all-electric</span>
                </template>
              </PsTooltip>
            </template>
          </PsInput>
        </div>
      </transition>

      <AssumptionsGrowthWidgetPolicyAssumptions
        v-if="$route.name === 'PolicyShow' && canHaveMultipleSpecifyBy"
        ref="switchItem"
        class="mt-4/5 w-full ml-0"
        header-title="Specify By:"
        :specify-by-options="RESIDENTIAL_NEW_SPECIFICATIONS"
        :specify-by.sync="specifyBy"
        :toggle-confirmer="checkCanAutoToggleAssumptionLevel"
        @toggle="onAssumptionLevelAutoToggle"
      />

      <div
        v-else-if="getSingleSpecifyByEnabled"
        class="flex items-center justify-between mt-3 psui-bg-gray-20 py-1/2 px-3 rounded-t-6"
        :class="{'psui-bg-blue-10 psui-text-blue-60': specifyBy.length > 0 }"
      >
        <span
          class="psui-text-small mr-auto"
          :class="{'psui-font-bold':specifyBy.length > 0}"
        >{{ `Assume different values for each ${getSingleSpecifyByEnabled.label.toLowerCase()}` }}</span>
        <PsSwitch
          ref="switchItem"
          size="small"
          class="flex-shrink-0"
          label="Breakdown by construction types"
          :value="specifyBy.length > 0"
          @change="toggleSingleSpecifyBy($event)"
        />
      </div>
      <transition
        name="psui-el-compound"
        @enter="startTransition"
        @after-enter="endTransition"
        @before-leave="startTransition"
        @after-leave="endTransition"
      >
        <div
          v-if="isSpecifyByClimateZoneEnabled && canSpecifyByClimateZone"
          class="mt-2"
        >
          <div class="flex justify-between items-center">
            <span class="psui-text-gray-80 psui-font-bold psui-text-p">Climate Zone</span>
            <PsToggle
              :items="getClimateZonesWithinPolicySelected"
              key-label="prefix"
              key-value="raw"
              :selected="getClimateZoneSelected"
              @update:selected="climate_zone = $event"
            />
          </div>
          <div
            v-if="!isSpecifyByBuildingTypeEnabled"
            class="psui-flex psui-gap-3 psui-items-center mt-4/5"
          >
            <PsInput
              class="w-1/2"
              :class="{'selected':hasValueUpdated(null, getClimateZoneSelected)}"
              :value="getMixedFuelPerPrototype(null, getClimateZoneSelected)"
              :disabled="isInputDisabled(null, getClimateZoneSelected)"
              :show-tooltip="isInputDisabled(null, getClimateZoneSelected)"
              :tooltip-text="getTooltipText(null, getClimateZoneSelected)"
              layout="mini"
              hint="mixed-fuel"
              @click.native="checkIfUserCanEditAssumption"
              @input="onInput(null, getClimateZoneSelected, maxValue-$event.target.value)"
              @change="updateAssumption(null, getClimateZoneSelected, maxValue - $event.target.value)"
              @mouseenter="onMouseEnterInsideInput(null, getClimateZoneSelected, mixedFuelPrefix)"
              @mouseleave="onMouseLeaveInsideInput"
            >
              <template #append>
                <span
                  v-if="!hasValueUpdated(null, getClimateZoneSelected) || isInputDisabled(null, getClimateZoneSelected) || !canShowResetButton(null, getClimateZoneSelected, mixedFuelPrefix)"
                  class="psui-text-gray-50 psui-text-xsmall"
                >%</span>
                <PsTooltip v-else-if="canShowResetButton(null, getClimateZoneSelected, mixedFuelPrefix)">
                  <template #trigger>
                    <PsIcon 
                      icon="restart_alt"
                      icon-classes="psui-text-green-20 cursor-pointer mt-1"
                      size="17"
                      @click.native="updateAssumption(null, getClimateZoneSelected, getBaseValue)"
                    />
                  </template>
                  <template #content>
                    <h1 class="mb-1">
                      Restore to default value
                    </h1>
                    <span class="psui-font-normal">{{ maxValue - getBaseValue }}% mixed-fuel</span>
                  </template>
                </PsTooltip>
              </template>
            </PsInput>
            <PsInput
              class="w-1/2"
              :class="{'selected':hasValueUpdated(null, getClimateZoneSelected)}"
              :value="getAssumptionValue(undefined, getClimateZoneSelected)"
              :disabled="isInputDisabled(null, getClimateZoneSelected)"
              :show-tooltip="isInputDisabled(null, getClimateZoneSelected)"
              :tooltip-text="getTooltipText(null, getClimateZoneSelected)"
              layout="mini"
              hint="all-electric"
              @click.native="checkIfUserCanEditAssumption"
              @input="onInput(null, getClimateZoneSelected, $event.target.value)"
              @change="updateAssumption(null, getClimateZoneSelected, $event.target.value)"
              @mouseenter="onMouseEnterInsideInput(null, getClimateZoneSelected, allElectricPrefix)"
              @mouseleave="onMouseLeaveInsideInput"
            >
              <template #append>
                <span
                  v-if="!hasValueUpdated(null, getClimateZoneSelected) || isInputDisabled(null, getClimateZoneSelected) || !canShowResetButton(null, getClimateZoneSelected, allElectricPrefix)"
                  class="psui-text-gray-50 psui-text-xsmall"
                >%</span>
                <PsTooltip v-else-if="canShowResetButton(null, getClimateZoneSelected, allElectricPrefix)">
                  <template #trigger>
                    <PsIcon 
                      icon="restart_alt"
                      icon-classes="psui-text-green-20 cursor-pointer mt-1"
                      size="17"
                      @click.native="updateAssumption(null, getClimateZoneSelected, getBaseValue)"
                    />
                  </template>
                  <template #content>
                    <h1 class="mb-1">
                      Restore to default value
                    </h1>
                    <span class="psui-font-normal">{{ getBaseValue }}% all-electric</span>
                  </template>
                </PsTooltip>
              </template>
            </PsInput>
          </div>
        </div>
      </transition>
      <transition
        name="psui-el-compound"
        @enter="startTransition"
        @after-enter="endTransition"
        @before-leave="startTransition"
        @after-leave="endTransition"
      >
        <div
          v-if="isSpecifyByBuildingTypeEnabled && canSpecifyByPrototype"
          class="psui-flex psui-flex-col psui-mt-4 space-y-10"
        >
          <div
            v-for="prototype in getPrototypesWithinPolicySelected"
            :key="`prototype-switch-${prototype.id}`"
            class="psui-flex psui-flex-col psui-justify-between"
          >
            <div
              v-if="canPrototypeInputBeVisible(prototype)"
              class="psui-flex psui-flex-col"
            >
              <span
                class="flex-shrink-0 psui-text-gray-80 psui-text-big psui-font-bold mb-4"
              >{{ prototype.title | prototypeTitleHomesAsUnits }}</span>
              <div class="psui-flex psui-gap-3">
                <PsInput
                  :value="getMixedFuelPerPrototype(prototype, getClimateZoneSelected)"
                  :class="{'selected':hasValueUpdated(prototype, getClimateZoneSelected)}"
                  :disabled="isInputDisabled(prototype, getClimateZoneSelected)"
                  :show-tooltip="isInputDisabled(prototype, getClimateZoneSelected)"
                  :tooltip-text="getTooltipText(prototype, getClimateZoneSelected)"
                  layout="mini"
                  hint="mixed-fuel"
                  @click.native="checkIfUserCanEditAssumption"
                  @input="onInput(prototype, getClimateZoneSelected, maxValue - $event.target.value)"
                  @change="updateAssumption(prototype, getClimateZoneSelected, maxValue - $event.target.value)"
                  @mouseenter="onMouseEnterInsideInput(prototype, getClimateZoneSelected, mixedFuelPrefix)"
                  @mouseleave="onMouseLeaveInsideInput"
                >
                  <template #append>
                    <span
                      v-if="!hasValueUpdated(prototype, getClimateZoneSelected) || isInputDisabled(prototype, getClimateZoneSelected) || !canShowResetButton(prototype, getClimateZoneSelected, mixedFuelPrefix)"
                      class="psui-text-gray-50 psui-text-xsmall"
                    >%</span>
                    <PsTooltip v-else-if="canShowResetButton(prototype, getClimateZoneSelected, mixedFuelPrefix)">
                      <template #trigger>
                        <PsIcon 
                          icon="restart_alt"
                          icon-classes="psui-text-green-20 cursor-pointer mt-1"
                          size="17"
                          @click.native="updateAssumption(prototype, getClimateZoneSelected,getBaseValue)"
                        />
                      </template>
                      <template #content>
                        <h1 class="mb-1">
                          Restore to default value
                        </h1>
                        <span class="psui-font-normal">{{ maxValue - getBaseValue }}% mixed-fuel</span>
                      </template>
                    </PsTooltip>
                  </template>
                </PsInput>
                <PsInput
                  :value="getAssumptionValue(prototype, getClimateZoneSelected)"
                  layout="mini"
                  :class="{'selected': hasValueUpdated(prototype, getClimateZoneSelected)}"
                  :disabled="isInputDisabled(prototype, getClimateZoneSelected)"
                  :show-tooltip="isInputDisabled(prototype, getClimateZoneSelected)"
                  :tooltip-text="getTooltipText(prototype, getClimateZoneSelected)"
                  hint="all-electric"
                  @click.native="checkIfUserCanEditAssumption"
                  @input="onInput(prototype, getClimateZoneSelected, $event.target.value)"
                  @change="updateAssumption(prototype, getClimateZoneSelected, $event.target.value)"
                  @mouseenter="onMouseEnterInsideInput(prototype, getClimateZoneSelected, allElectricPrefix)"
                  @mouseleave="onMouseLeaveInsideInput"
                >
                  <template #append>
                    <span
                      v-if="!hasValueUpdated(prototype, getClimateZoneSelected) || isInputDisabled(prototype, getClimateZoneSelected) || !canShowResetButton(prototype, getClimateZoneSelected, allElectricPrefix)"
                      class="psui-text-gray-50 psui-text-xsmall"
                    >%</span>
                    <PsTooltip v-else-if="canShowResetButton(prototype, getClimateZoneSelected, allElectricPrefix)">
                      <template #trigger>
                        <PsIcon 
                          icon="restart_alt"
                          icon-classes="psui-text-green-20 cursor-pointer mt-1"
                          size="17"
                          @click.native="updateAssumption(prototype, getClimateZoneSelected, getBaseValue)"
                        />
                      </template>
                      <template #content>
                        <h1 class="mb-1">
                          Restore to default value
                        </h1>
                        <span class="psui-font-normal">{{ getBaseValue }}% all-electric</span>
                      </template>
                    </PsTooltip>
                  </template>
                </PsInput>
              </div>
            </div>
          </div>
        </div> 
      </transition>
    </template>
  </PsAccordionItem>
</template>

<script>
import AssumptionsGrowthWidgetPolicyAssumptions from '@/modules/app/assumptions/building-estimates/AssumptionsGrowthWidgetPolicyAssumptions.vue'
import { STUDY_TYPES_DB_SLUGS } from '@/store/global-entities/StudyType.js'
import { ASSUMPTIONS_KEYS } from '@/util/Enums.js'
import AssumptionImpactsByPrototypeAndClimateZoneMixin from './AssumptionImpactsByPrototypeAndClimateZoneMixin'

export default {
  name:"AssumptionsElectrificationBusinessAsUsual",
  components:{
    AssumptionsGrowthWidgetPolicyAssumptions
  },
  props:['tabSelected', 'descriptionModelObject'],
  mixins: [ AssumptionImpactsByPrototypeAndClimateZoneMixin ],
  data: ()=> ({
    assumptionKey: ASSUMPTIONS_KEYS.all_electric_shares_without_policy,
    allElectricPrefix: 'all-electric',
    mixedFuelPrefix: 'mixed-fuel',
  }),
  computed: {
    getBaseValue() {
      return this.defaultNewBuildingsImpactAssumptionValues[this.assumptionKey]
    },
    getDisabledStatusByStudyType() {
      return this.tabSelected.slug !== STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS
    },
  },
  methods: {
    getMixedFuelPerPrototype(prototype, climate_zone, assumptionKey = null) {
      return this.maxValue - this.getAssumptionValue(prototype, climate_zone, assumptionKey)
    },
    isInputDisabled(prototype, climateZone) {
      return this.isOnDefaultState(prototype, climateZone, true)
    },
    // Special case about being base value on default state
    getLocalAssumptionValue(prototypeId, climateZoneRaw, assumptionKey = null) {
      if (this.isPolicyAssumption) {
        const prototype = this.getPrototypesWithinPolicySelected?.find((p) => p.id === prototypeId)
        const climateZone = this.getClimateZonesWithinPolicySelected?.find((c) => c.raw === climateZoneRaw)
        if (this.isOnDefaultState(prototype, climateZone, true)) {
          return this.getBaseValue
        }
      }
      return AssumptionImpactsByPrototypeAndClimateZoneMixin.methods.getLocalAssumptionValue.call(this, prototypeId, climateZoneRaw, assumptionKey)
    },
    // Special case override for updating electrification rate with policy if value is higher than it.
    updateAssumption(prototype, climate_zone, value, assumptionKey = null, mergeWithOtherAssumptions = null) {
      value = this.sanitizeInputValue(value)
      const withPolicyAssumptionValue = +this.getSavedAssumptionValue(prototype?.id, climate_zone?.raw, ASSUMPTIONS_KEYS.all_electric_shares_with_policy)
      if (+value > withPolicyAssumptionValue) {
        mergeWithOtherAssumptions = {
          ...(mergeWithOtherAssumptions || {}),
          [ASSUMPTIONS_KEYS.all_electric_shares_with_policy]: value
        }
      }
      AssumptionImpactsByPrototypeAndClimateZoneMixin.methods.updateAssumption.call(this, prototype, climate_zone, value, assumptionKey, mergeWithOtherAssumptions)
    },
    getTooltipText(prototype, climateZone) {
      const isOnDefaultState = this.isOnDefaultState(prototype, climateZone, true)
      if(isOnDefaultState){
        const text = `There are currently no policy requirements selected, add requirements to your policy to edit your With Policy All-Electric Construction Assumption.`
        return this.$options.filters.policyTextRephrase(text)
      } 
    }
}}

</script>

<style lang="scss" scoped>
  ::v-deep .psui-el-switch.size-small .psui-el-switch-button.toggle-true {
    background: #318FAC;
  }

  ::v-deep .psui-el-input.status-resting.layout-mini.selected {
    .psui-el-input-wrapper {
      border-color: #5DB883;
    }
  }

  .psui-el-accordion-item {
    &.status-opened {
      .help {
        color: #318FAC;
      }
    }
  }
</style>
