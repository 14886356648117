import { render, staticRenderFns } from "./StepJurisdictionAndTitle.vue?vue&type=template&id=697d9803&scoped=true"
import script from "./StepJurisdictionAndTitle.vue?vue&type=script&lang=js"
export * from "./StepJurisdictionAndTitle.vue?vue&type=script&lang=js"
import style0 from "./StepJurisdictionAndTitle.vue?vue&type=style&index=0&id=697d9803&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "697d9803",
  null
  
)

export default component.exports