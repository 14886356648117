import { GeneralPerformanceByFuelPolicyImpactAlgorithm } from "./general-performance-by-fuel"
import { POLICY_IMPACT_ALGORITHMS } from './index'
import Store from '@/store'

export class PolicyImpactAlgorithmFactory {
  static createByType(type, policy, policyContainer, climateZoneRaw) {
    switch(type) {
      case POLICY_IMPACT_ALGORITHMS.GENERAL_PERFORMANCE_BY_FUEL:
        return new GeneralPerformanceByFuelPolicyImpactAlgorithm(policy, policyContainer, climateZoneRaw)
      case POLICY_IMPACT_ALGORITHMS.GENERAL_PRESCRIPTIVE_BY_MEASURE:
      default:
        return null
    }
  }

  static createByPrototype(prototype, policy, policyContainer, climateZoneRaw) {
    const prototypeAlgorithm = prototype && prototype.impact_algorithm_policy_impact ? prototype.impact_algorithm_policy_impact : null
    const availableAlgorithms = Object.keys(POLICY_IMPACT_ALGORITHMS).map(k => POLICY_IMPACT_ALGORITHMS[k])
    if (prototypeAlgorithm && availableAlgorithms.includes(prototypeAlgorithm)) {
      return this.createByType(prototypeAlgorithm, policy, policyContainer, climateZoneRaw)
    }

    const prototypeStudyGroupId = prototype && prototype.study && prototype.study.study_group_id ? prototype.study.study_group_id : null
    if (!prototypeStudyGroupId) {
      throw new Error(`Could not create PolicyImpactAlgorithm because prototype has no algorithm set and no study group has passed`)
    }

    const typeByPrototypeGroup = prototypeStudyGroupId === 1 ? POLICY_IMPACT_ALGORITHMS.GENERAL_PRESCRIPTIVE_BY_MEASURE :
      POLICY_IMPACT_ALGORITHMS.GENERAL_PERFORMANCE_BY_FUEL
    return this.createByType(typeByPrototypeGroup, policy, policyContainer, climateZoneRaw)
  }

  static createByCustomCombinations(customCombinations, policy) {
    if (!customCombinations || !customCombinations.length) {
      return null
    }

    const firstContainer = [...customCombinations].shift()
    const workingPolicyContainerId = firstContainer && firstContainer.policy_container_id ? firstContainer.policy_container_id : null
    const workingClimateZone = firstContainer && firstContainer.climate_zone_raw ? firstContainer.climate_zone_raw : null
    if (!workingPolicyContainerId || !workingClimateZone) {
      return null
    }

    const policyContainer = policy && policy.policy_containers ?
      policy.policy_containers.find((container) => workingPolicyContainerId === container.id) : null
    const prototype = Store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: firstContainer.prototype_id })
    if (!policyContainer || !prototype) {
      return null
    }

    return this.createByPrototype(prototype, policy, policyContainer, workingClimateZone)
  }
}
