import GraphQL from '@/util/GraphQL'

export const DBIDS_BUILDING_TYPES = {
  RESIDENTIAL: 1,
  NON_RESIDENTIAL: 2
}

export const BUILDING_TYPES_DB_SLUGS = {
  RESIDENTIAL:'residental-buildings',
  NON_RESIDENTIAL: 'non-residental-buildings'
}

export default {
  namespaced: true,
  
  state: {
    building_types: []
  },

  getters: {    
    getterGlobalBuildingTypes: (state) => (filters = null) => {
      return filters ? state.building_types.findAllInArray(filters) : state.building_types
    },

    getterGlobalBuildingType: (state) => (filters = null) => {
      return filters ? state.building_types.findInArray(filters) : false
    },

    getterBuildingTypeSelected: state => routeFullPath => {
      const url = new URL(window.location.origin + routeFullPath)
      const building_type_selected_slug = url.searchParams.get('only_building_type')
      if(!building_type_selected_slug) return state.building_types.findInArray({ slug: 'residental-buildings' })
      return state.building_types.findInArray({ slug: building_type_selected_slug })
    },

    getterBuildingTypesWithTypePrototypes(state, getters, rootState) {
      // Added filter to exclude ADU as requested on: https://epcgo.atlassian.net/browse/DDEV-1298?focusedCommentId=15407&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-15407
      const items =  state.building_types.map((building_type) => {
        const type_prototypes = rootState.globalEntities
          .TypePrototype.type_prototypes
          .filter((type_prototype) => type_prototype.building_type_id == building_type.id)
        return {
          type_prototypes,
          ...building_type
        }
      })
      return items
    },

    getterBuildingTypeByTypePrototypeId: (state, getters, rootState, rootGetters) => (type_prototype_id) => {
      const type_prototype = rootGetters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id: type_prototype_id })
      const building_type = state.building_types.findInArray({ id: type_prototype.building_type_id })
      return building_type
    },

    getterBuildingTypeByTypePrototypeIdIsNonResidential: (state, getters, rootState, rootGetters) => (type_prototype_id) => {
      const type_prototype = rootGetters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id: type_prototype_id })
      const building_type = state.building_types.findInArray({ id: type_prototype.building_type_id })
      return building_type.slug === BUILDING_TYPES_DB_SLUGS.NON_RESIDENTIAL
    },

  },

  actions: {
    loadGlobalBuildingTypes(context) {
      const query = `
        {
          building_types (
            orderBy: [{ column: "order", order: "ASC" }]
          ) {
            id
            title
            icon
            slug
          }
        }
      `
      GraphQL({ query })
        .then(({ data }) => {
          context.commit('setGlobalBuildingTypes', { building_types: data.building_types})
        })
    }
  },

  mutations: {
    setGlobalBuildingTypes(state, { building_types }) {
      state.building_types = building_types
    }
  }
}