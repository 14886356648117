import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import { mapGetters } from 'vuex'
import StudyResultApiService from '@/services/api/StudyResultApiService'
import dayjs from 'dayjs'
import { deepMergeObject } from '@igortrindade/lazyfy'
import { 
  forecast_emissions_savings,
  forecast_therms_savings, 
  forecast_lifecycle_savings,
  forecast_kwh_savings,
  therms_savings,
  annual_bill_savings,
  initial_cost,
  forecast_initial_cost,
  city_wide_subsidy_needed,
  subsidy_needed,
  city_wide_five_year_payback,
  five_years_payback,
  city_wide_subsidy_needed_care,
  subsidy_needed_care,
  city_wide_five_year_payback_care,
  five_years_payback_care, 
  kwh_savings } from '@/modules/app/shared/default-app-columns.js'


export default {
    computed: {
      getAvailablePrototypes(){
        let arrayOfAvailablePrototypes = []
        const getStudyGroupsFilteredByBuildingTypeSelected = this.studyTypes
        .filter(study_type => study_type.slug === this.getQueryKey('only_study_type')
        .shift())
        for (const studyType of getStudyGroupsFilteredByBuildingTypeSelected) {
          for (const studyGroup of studyType.study_groups) {
            for (const typePrototype of this.type_prototypes) {
              for (const prototype of this.getStudyGroupPrototypesFilteredByTypePrototypeAndSortedByStudyRelease({ studyGroup, typePrototype }) ) {
                const canShowByStudyFilter = Boolean(!this.checkQueryHasKey('exclude_study_ids') || !this.getQueryKey('exclude_study_ids').includes(prototype.study.id.toString()))
                if (!arrayOfAvailablePrototypes.find((p) => p?.slug === prototype?.slug) && canShowByStudyFilter) {
                  arrayOfAvailablePrototypes.push(prototype)
                }
              }
            }
          }
        }

        return arrayOfAvailablePrototypes
      },
      getDefaultJurisdictionBuildingStockUnits() {
        return this.$store.getters['assumptions/buildingStocks/getterDefaultJurisdictionBuildingStocksUnits']({
          climate_zone_prefix: this.getterLastClimateZonesVisited[0].prefix
        })
      },
      getterLastClimateZonesVisited(){
        return this.$store.getters['lastClimateZonesVisited']
      },
      getterLastStudyTypeVisited(){
        return this.$store.getters['lastStudyTypeVisited']
      },
      getDefaultJurisdictionBuildintEstimatesUnits(){
        return this.$store.getters['assumptions/buildingEstimates/getterDefaultJurisdictionBuildingEstimateUnits']({
          climate_zone_prefix: this.getterLastClimateZonesVisited[0].prefix
        })
      },
      ...mapGetters('globalEntities/StudyType', ['getterStudyTypeNewBuildings'])
    },
    methods: {
        getStudyResultsInitialData() {
            return StudyResultApiService.get().then(({ study_types, type_prototypes }) => {
                return { study_types, type_prototypes }
            })
        },
        checkIfEntityShouldBeVisibleInStudyResults(key, value) {
            switch (key) {
                case 'study_type':
                    if (!this.$route.query.only_study_type && value.slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS) return true
                    return this.$route.query.only_study_type === value.slug

                case 'prototype': {
                    const getStudyId = this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: value.id }).study_id
                    if(!window.$vueInstance.checkQueryHasKey('exclude_study_ids') && !window.$vueInstance.checkQueryHasKey('exclude_type_prototype_ids')) return this.getLatestStudyIdsByAllPrototypes().includes(Number(getStudyId))

                    const study = this.$store.getters['globalEntities/Study/getterGlobalStudy']({ id: getStudyId })
                    return !window.$vueInstance.checkQueryKeyValue('exclude_type_prototype_ids', value.type_prototype_id) &&
                        !window.$vueInstance.checkQueryKeyValue('exclude_study_ids', getStudyId) &&
                        study.published_at
                }

                case 'vintage': {

                  return !window.$vueInstance.checkQueryKeyValue('exclude_type_vintage_ids', value.type_vintage_id) && value.type_vintage_id
                }

                case 'fuel':
                    return !window.$vueInstance.checkQueryKeyValue('exclude_type_fuel_ids', value.type_fuel_id)

                // TODO - Refactor so we understand what means study study_index inside this array
                case 'check_study_in_array':
                    if (window.$vueInstance.checkQueryHasKey('exclude_study_ids')){
                        return window.$vueInstance.checkQueryKeyValue('exclude_study_ids', value.study.id)
                    }
                    if (!value.study) {
                        return value.study_index !== 0
                    }
                    return !this.getLatestStudyIdsByAllPrototypes().includes(Number(value.study?.id))
            }
        },
        getLatestStudyIdsByAllPrototypes() {
            const studiesByPrototypes = this.$store.getters['globalEntities/Prototype/getterGlobalPrototypes']().reduce((acc, curr) => {
                const study = this.$store.getters['globalEntities/Study/getterGlobalStudy']({ id: curr.study_id })
                if (!curr.type_prototype_id || !study || study?.is_private === true) {
                    return acc
                }

                let item = acc.find((i) => i.type_prototype_id === curr.type_prototype_id && i.study_group_id === curr.study.study_group_id)
                if (!item) {
                    item = {
                        studies: [],
                        type_prototype_id: curr.type_prototype_id,
                        study_group_id: curr.study.study_group_id,
                    }
                    acc.push(item)
                }
                item.studies.push(study)
                return acc
            }, []).map((pS) => {
                const latestStudy = [...pS.studies].sort((a,b)=> dayjs(a.published_at) - dayjs(b.published_at)).pop()
                return Number(latestStudy.id)
            })

            return [...new Set(studiesByPrototypes)]
        },
        getStudyGroupPrototypesFilteredByTypePrototypeAndSortedByStudyRelease({ studyGroup, typePrototype }) {
          let availablePrototypes = this
            .getStudyGroupPrototypes(studyGroup)
            .filter((prototype) => {
              const proportionalTypePrototypes = Array.isArray(prototype?.proportional_type_prototypes) ?
                prototype.proportional_type_prototypes.map((p) => parseInt(p.id)) : []
              const typePrototypeIds = [...new Set([parseInt(prototype.type_prototype_id), ...proportionalTypePrototypes])]
              return typePrototypeIds.includes(parseInt(typePrototype.id))
            })

          availablePrototypes = availablePrototypes
            .filter((prototype) => {
              if (window.$vueInstance.checkQueryHasKey('exclude_study_ids')) {
                return !window.$vueInstance.checkQueryKeyValue('exclude_study_ids', prototype?.study?.id?.toString())
              }
              return prototype?.study?.id && this.getLatestStudyIdsByAllPrototypes().includes(Number(prototype.study.id))
            })
            .filter(prototype => {
              if (studyGroup.study_type_id === this.getterStudyTypeNewBuildings.id) {
                return dayjs(prototype.study.released_at).get('year') >= 2022
              }
              return true
            })

          if (!window.$vueInstance.checkQueryHasKey('exclude_study_ids')) {
            // Remove duplicates by slug
            const slugs = availablePrototypes.map(o => o.slug)
            return availablePrototypes.filter(({slug}, index) => !slugs.includes(slug, index + 1))
          }
          return availablePrototypes
        },
        getStudyGroupPrototypes(study_group) {
          return [...study_group.studies].reduce((prev, study) => {
            study.prototypes.forEach((prototype) => {
              const replicatedStudy = deepMergeObject({}, study)
              replicatedStudy.fuels = study.fuels ? study.fuels?.map((f) => { return {...f}}) : null
              replicatedStudy.vintages = study.vintages ? study.vintages?.map((v) => { return {...v}}) : null
              const replicatedStudyGroup = deepMergeObject({}, study_group)
              delete replicatedStudyGroup.studies
              prev.push({ ... prototype, study : { ...replicatedStudy, study_group: replicatedStudyGroup } })
            })
            return prev
          }, [])
        },
        getVintagesOrdered(vintages) {
          const vintagesOrdered = [...vintages]
          vintagesOrdered.sort((a,b) => a.type_vintage?.order - b.type_vintage?.order )
          return vintagesOrdered
        },
        getFuelsOrdered(fuels) {
          const fuelsOrdered = [...fuels]
          fuelsOrdered.sort((a,b) => a.type_fuel?.order - b.type_fuel?.order )
          return fuelsOrdered
        },
        getIfColumnShouldBeDisabled(columnKey, studyData) {
          if (['tdv2022_benefit_to_cost_ratio', 'tdv_benefit_to_cost_ratio', 'on_bill_cost_ratio', 'lsc_2025_benefit_to_cost_ratio', 'on_bill_cost_ratio_2025', 'on_bill_2025_care'].includes(columnKey)) {
              return studyData.every((curr) => {
                  return curr.data?.[columnKey] == null || curr.data?.[columnKey] === 0
              })
          }
          const getUnitsBasedOnStudyType = this.lastStudyTypeVisited == STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS 
          ? this.getDefaultJurisdictionBuildingStockUnits : this.getDefaultJurisdictionBuildintEstimatesUnits

          const fallbackKeys = {
            [forecast_therms_savings().key]: therms_savings().key,
            [forecast_kwh_savings().key]: kwh_savings().key,
            [forecast_lifecycle_savings().key]: annual_bill_savings().key,
            [forecast_initial_cost().key]: initial_cost().key,
            [city_wide_subsidy_needed().key]: subsidy_needed().key,
            [city_wide_five_year_payback().key]: five_years_payback().key,
            [city_wide_subsidy_needed_care().key]: subsidy_needed_care().key,
            [city_wide_five_year_payback_care().key]: five_years_payback_care().key,
          }

          return studyData.every(sd => {
            if(columnKey == forecast_emissions_savings().key) {
              return sd.data?.[therms_savings().key] == 0 && sd.data?.[kwh_savings().key] == 0  
            }
            
            if(Object.keys(fallbackKeys).includes(columnKey)) {
              const key = fallbackKeys[columnKey]
              
              return sd.data?.[key] == 0
            }

            return sd.data?.[columnKey] == 0
          }) && getUnitsBasedOnStudyType == 0
        },
    }
}

