<template>
  <transition
    :enter-active-class="transitionType == 'drawer' ? 'transition ease-out duration-200 transform' : ''"
    :enter-class="transitionType == 'drawer' ? 'opacity-0 translate-x-full': ''"
    :enter-to-class="transitionType == 'drawer' ? 'opacity-100': ''"
    :leave-active-class="transitionType == 'drawer' ?'transition ease-in duration-200 transform': ''"
    :leave-class="transitionType == 'drawer' ? 'opacity-100': ''"
    :leave-to-class="transitionType == 'drawer' ? 'opacity-0 translate-x-full': ''"
    mode="out-in"
  >
    <div 
      v-if="showDrawer"
      ref="baseDrawer"
      :class="[`layout--${layout}`, drawerClasses, getClassesByHierarchy]" 
      class="app-drawer origin-right fixed top-0 w-auto h-screen psui-bg-white shadow-lg flex flex-col psui-overflow-hidden"
      :style="{ right: getRightPosition, maxWidth: getDrawerMaxWith, width: drawerWidth }"
    >
      <slot name="mainHeader" />

      <div class="w-full flex flex-col psui-overflow-hidden">
        <div class="w-full flex h-screen overflow-hidden">
          <div 
            v-if="$slots.leftPanel" 
            class="psui-h-screen psui-relative left-panel"
            :class="{ 'open' : leftPanelIsOpen }"
          >
            <PsButton          
              :icon="'expand_more'"
              layout="outline"
              size="small"
              icon-position="left"
              class="toggle-button"
              @click="toggleLeftPanel()"
            />

            <transition
              name="fade"
              mode="out-in"
            >
              <slot
                v-if="leftPanelIsOpen"
                name="leftPanel"
              />
            </transition>
          </div>

          <div
            class="transition-content w-full flex flex-col"
            :style="{ maxWidth: getContentMaxWidth }"
          >
            <slot name="header">
              <div class="app-drawer-header psui-flex psui-flex-col psui-bg-white psui-shadow-elevation-5 psui-mb-4">
                <div
                  class="ps-tooltip-adjustments psui-w-auto psui-flex psui-justify-between psui-items-center psui-py-6 psui-pl-12 psui-pr-6"
                >
                  <h1 
                    v-if="title"
                    v-tooltip="{ content: `Helper id: ${id}`, delay: { show: 1500, hide: 0 }}"            
                    class="app-drawer-title psui-text-gray-50 psui-text-small psui-font-bold psui-uppercase row-onhover"
                  >
                    {{ title }}
                  </h1>
              
                  <PsButton
                    v-if="enableFullScreen"
                    layout="onlytext"
                    :icon="hasRequestedFullScreenMode ? 'fullscreen_exit' : 'fullscreen_enter'"
                    class="app-drawer-close psui-ml-auto psui-mr-2 psui-w-6"
                    @click.native="requestFullScreenToggle"
                  />
                  <PsTooltip
                    :ignore-dialog="!showTooltip"
                    layout="gray"
                    css-class="psui-w-32"
                    position="custom"
                    custom-position="left: inherit; top: 4px; right: 0;"
                  >
                    <template #trigger>
                      <PsButton
                        layout="onlytext"
                        icon="close"
                        class="app-drawer-close"
                        @click.native="closeDrawer()"
                      />
                    </template>
                    <template #content>
                      <p
                        v-if="showTooltip"
                      >
                        Save and close
                      </p>
                    </template>
                  </PsTooltip>
                </div>
              </div>
  
              <slot 
                v-if="$slots.actions"
                name="actions" 
              />
  
              <AppBreadcrumb
                v-else-if="breadcrumbs && !$slots.actions"
                :items="breadcrumbs"
                class="psui-w-full psui-px-12 psui-mb-3"
              />
            </slot>
        
            <div 
              class="app-drawer-content psui-w-auto psui-overflow-y-auto psui-pl-10 psui-pr-8"
              :class="[$slots.footer ? 'psui-pb-5' : 'psui-pb-12']"
            >
              <div
                v-if="subtitle"
                class="w-auto flex flex-col app-drawer-title-wrapper psui-mb-3"
              >
                <slot name="subtitle">
                  <p
                    v-if="subtitle"
                    class="psui-text-gray-60 psui-text-p"
                    v-html="subtitle"
                  />
                </slot>
              </div>
              
              <slot name="default" />
            </div>
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import AppBreadcrumb from '@/components/general/AppBreadcrumb.vue'
import { DRAWER_HIERARCHY } from '@/util/Enums.js'

export default {
  name: 'BaseDrawer',
  components: { AppBreadcrumb },
  props: {
    title: {
      type: String,
    },
    id: {
      type: [String, Number],
      default: ''
    },
    subtitle: {
      type: String,
      required: false
    },
    layout: {
      type: String,
      required: false,
      default: 'default'
    },
    drawerClasses: {
      type: String,
      required: false,
      default: ''
    },
    breadcrumbs: {
      type: [Array, null],
      required: false
    },
    enableFullScreen: {
      type: Boolean,
      default: false,
    },
    /** You should pass a HTML element ID */
    alignToElement: {
      type: [Boolean, String],
      default: 'main-content'
    },
    hierarchy: {
      type: [String],
      required: true,
      validator: key => {
        return Object.keys(DRAWER_HIERARCHY).includes(key)
      }
    },
    drawerWidth: {
      type: [String, Number],
      default: ''
    },
    openLeftPanelOnMounted:{
      type:Boolean,
      default: false
    },
    transitionType: {
      type: String,
      default: 'drawer'
    }
  },
  data() {
    return {
      showDrawer: false,
      alignToElementObserver : null,
      // there is nothing to trigger watcher reload in computeDrawerMaxWidth method, so, we need to update it manually
      drawerMaxWidth: null,
      hasRequestedFullScreenMode: false,
      showTooltip: false,
      leftPanelIsOpen: false
    }
  },
  computed: {
    getDrawerMaxWith() {
      return this.drawerWidth ? this.drawerWidth : this.hasRequestedFullScreenMode ? `calc(100% - ${this.getRightPosition.replace(' !important', '')})` : this.computeDrawerMaxWidth
    },
    getRightPosition() {
      if(!this.$store.state.assumptions.drawerOptions.visibility) return '0px'
      return '340px !important'
    },
    getClassesByHierarchy(){
      if(this.hierarchy === DRAWER_HIERARCHY.PRIMARY) {
        return 'psui-z-30'
      } else if(this.hierarchy === DRAWER_HIERARCHY.SECONDARY) {
        return 'psui-z-40'
      } else {
        return ''
      }
    },
    getContentContainerWidth() {
      return this.$store.state.general.contentContainerWidth
    },
    computeDrawerMaxWidth() {
      const safeSpace = this.layout == 'requirements' ? 0 : 80
      const maxWidth = this.getContentContainerWidth - safeSpace
      return `${maxWidth}px`
    },

    getContentMaxWidth() {
      let drawerWidth = this.computeDrawerMaxWidth

      // ToDO: If we want to remove "modal" design, just remove this -48
      
      const { visibility } = this.$store.getters['assumptions/getterAssumptionsDrawerOptions']
      const getMarginRight = visibility ? 28 : 48
      const width = parseInt(drawerWidth.replace('px', ''))
      if(this.layout == 'requirements') {
        drawerWidth = this.transitionType == 'drawer' ? width : width - getMarginRight 
        drawerWidth = (drawerWidth + 200)
      }

      if(!this.$slots.leftPanel) {
        return drawerWidth + 'px'
      }

      return  drawerWidth - (this.leftPanelIsOpen ? 200 : 16) + 'px'
    }
  },
  mounted() {
    if(this.openLeftPanelOnMounted) this.leftPanelIsOpen = this.openLeftPanelOnMounted
    this.$eventBus.$on('customCombinationChanged', this.customCombinationChanged)
    document.addEventListener('keyup', this.handleEsc)
    window.addEventListener('resize', this.updateDrawerWidth)
  },
  beforeDestroy() {
    this.$eventBus.$off('customCombinationChanged', this.customCombinationChanged)
    document.removeEventListener('keyup', this.handleEsc)
    if (this.alignToElementObserver) this.alignToElementObserver.disconnect()
  },
  methods: {
    customCombinationChanged() {
      if(this.$route.name !== 'PolicyShow') return
      this.showTooltip = true
    },
    updateDrawerWidth() {
      this.drawerMaxWidth = this.computeDrawerMaxWidth
      this.$forceUpdate()
    },
    handleEsc(evt) {
      if (this.showDrawer && evt.keyCode === 27) this.closeDrawer()
    },
    openDrawer() {      
      this.showDrawer = true
      this.updateDrawerWidth()
      window.addEventListener('resize', this.updateDrawerWidth)
    },
    closeDrawer() {
      this.showDrawer = false
      this.$emit('close')

      window.removeEventListener('resize', this.updateDrawerWidth)
      if (this.hasRequestedFullScreenMode) this.requestFullScreenToggle()
    },
    requestFullScreenToggle() {
      this.$eventBus.$emit('toggleEditRequirementsDrawerFullScreen')
      this.hasRequestedFullScreenMode = !this.hasRequestedFullScreenMode
    },
    toggleLeftPanel() {
      this.leftPanelIsOpen = !this.leftPanelIsOpen
      this.$emit('toggleLeftPanel', this.leftPanelIsOpen)
      this.updateDrawerWidth()
    }
  }
}
</script>

<style lang="scss" scoped>
  .toggle-button {
    position: absolute;
    right: 6px;
    top: 4px !important;
  }

  .app-drawer {
    &-close {
      &.psui-el-button.size-big.icon-left {
        padding: 0;
        color: #798490;

        i {
          margin: 0;
        }
      }
    }
  }

  .ps-tooltip-adjustments {
    ::v-deep {
      .psui-el-tooltip .psui-el-tooltip-wrapper .psui-el-tooltip-dialog .psui-el-tooltip-content.layout-gray {
        padding: 8px 16px 8px 16px;

        .psui-el-tooltip-content-wrapper {
          margin-top: 0rem;
        }
      }
    }
  }

  .transition-content {
    transition: max-width 0.2s linear;
  }


  .left-panel {

    background: #E6ECF2;
    border-right: 2px solid #D6DDE5;
    width: 16px !important;
    transition: all 0.2s ease-in-out;
    flex: none;

    :deep(.psui-el-button.layout-outline) {
      cursor: pointer;
      padding: 4px;
      border-radius: 16px;
      background: #FFF;
      border-color: #D6DDE5;
      color: #798490; // gray-50
      position: absolute;
      right: -16px;
      top: 12px;
    }

    :deep(.psui-el-button.layout-outline):hover {
      background: #4B8CA6;
      color: #FFF;
    }
    
    :deep(.psui-el-button.size-small.icon-left i) {
      font-size: 16px;
      margin-right: 0;
      transform: rotate(-90deg);
    }

    &.open {
      width: 200px !important;
      :deep(.psui-el-button.size-small.icon-left i) {
        transform: rotate(90deg);
      }
    }

  }
</style>
