<template>
  <div class="psui-w-full psui-h-full psui-flex psui-flex-col psui-overflow-y-auto psui-bg-gray-10">
    <div
      :is="getHeaderComponent"
      class="psui-sticky psui-top-0 psui-bg-white psui-mb-6 psui-shadow-elevation-5"
    />
    
    <router-view />
    
    <ModalComparisonEditDetails />
    <ModalDeleteComparison />
    <ModalDuplicateComparison />
    <DrawerComparisonEditColumns />
    <ComparisonEditPoliciesDrawer />
  </div>
</template>

<script>
import ComparisonEditPoliciesDrawer from '../shared/ComparisonEditPoliciesDrawer.vue'
import ModalDuplicateComparison from '@/modules/app/comparison/shared/ModalDuplicateComparison.vue'
import ModalComparisonEditDetails from '@/modules/app/comparison/shared/ModalComparisonEditDetails.vue'
import ModalDeleteComparison from '@/modules/app/comparison/shared/ModalDeleteComparison'
import DrawerComparisonEditColumns from '@/modules/app/comparison/DrawerComparisonEditColumns.vue'

export default {
  name: 'ComparisonIndex',
  components: { 
    ModalComparisonEditDetails, 
    ModalDeleteComparison,
    ModalDuplicateComparison,
    DrawerComparisonEditColumns, 
    ComparisonEditPoliciesDrawer
  },
  computed:{
    getHeaderComponent(){
      return this.$route?.meta?.header
    }
  },
  metaInfo() {
    return {
      title: 'Policy Comparison | '
    }
  },
}
</script>

<style lang="scss" scoped>
  ::v-deep .modal-dialog {
    max-width: 26.25rem;
  }
</style>