<template>
  <div
    class="relative psui-rounded-md psui-bg-gray-20 mx-6 mb-7 p-2"
    :class="{'psui-bg-blue-20':specifyBy.length}"
  >
    <p
      class="psui-text-small psui-text-gray-60 flex justify-between items-center mb-2 px-2 py-1"
      :class="{'psui-text-blue-60 psui-font-bold':specifyBy.length}"
    >
      {{ headerTitle }}
    </p>
    <div class="psui-rounded-md psui-shadow-elevation-5 psui-bg-white p-3">
      <!-- <PsRadioButtonSimple
        id="overall"
        label="Overall"
        size="small"
        class="radio-button"
        :checked="radioButtonValue === 'overall'"
        @change="setSpecifyByRadioValue('overall')"       
      />
      <hr class="psui-border-blue-70 opacity-10 my-4">

      <div class="w-1/2">
        <PsRadioButtonSimple
          id="specifyby"
          label="Specify by"
          size="small"
          class="radio-button"
          :checked="radioButtonValue === 'specifyby'"
          @change="setSpecifyByRadioValue('specifyby')"
        />
      </div> -->

      <div
        v-if="buildingType"
        class="checkbox-wrapper w-1/2 flex flex-col space-y-1"
      >
        <PsCheckboxSimple 
          v-for="(specify, key) in specifyByOptionsFilteredByClimateZonesLength"
          :key="key"
          :data-test-id="`assumptions-growth-rate-widget-ps-checkbox-simple-${buildingType.slug}`"
          size="small"
          :label="specify.label"
          class="checkbox-button"
          :checked="specifications.includes(specify.key)"
          @change="setSpecificationsByCheckboxValue(specify.key)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: 'AssumptionsGrowthWidget',
  props: {
    specifyByOptions: Object,
    specifyBy: [ Boolean, Array ],
    headerTitle: String,
    buildingType: Object
  },
  data() {
    return {
      specifications: [],
      radioButtonValue: null
    }
  },
  computed: {
    ...mapGetters(['lastJurisdictionVisited']),
    specifyByOptionsFilteredByClimateZonesLength() {
      if(this.lastJurisdictionVisited.climate_zones.length > 1) {
        return this.specifyByOptions
      } else {
        const specifyByOptionsWithoutClimateZone = Object.keys(this.specifyByOptions)
          .filter(key => key !== 'CLIMATE_ZONE')
          .reduce((obj, key) => {
            obj[key] = this.specifyByOptions[key]
            return obj
          }, {})
        return specifyByOptionsWithoutClimateZone
      }
    },
  },
  watch: {
    specifications(value) {
      if (value == this.specifyBy) return
      // this.$emit('update:specifyBy', value.length > 0 ? value : [] )
    },
    specifyBy(value) {
      this.specifications = value
      if(value.length === 0) this.radioButtonValue = 'overall'
      if(value.length !== 0) this.radioButtonValue = 'specifyby'
    },
    radioButtonValue(value){
      if(value === 'specifyby' && this.specifications.length === 0) this.specifications.push(this.specifyByOptions['BUILDING_TYPE'].key)
      if(value === 'overall') this.specifications = []
    }
  },
  mounted(){
    this.radioButtonValue = 'overall'
    this.specifications = this.specifyBy
  },
  methods:{
    setSpecifyByRadioValue(value){
      this.radioButtonValue = value
    },
    setSpecificationsByCheckboxValue(value){
      if(!this.specifications.includes(value)) {
        this.specifications.push(value)
        this.$emit('update:key',{key:value,value:true})
      } else {
        this.specifications = this.specifications.filter(spec=>spec !== value)
        this.$emit('update:key',{key:value,value:false})
      }

      this.$emit('update:specifyBy', this.specifications.length > 0 ? this.specifications : [] )
    }
  }
}
</script>
<style lang='scss' scoped>
  .radio-button {
    background: none;

    ::v-deep label span {
    font-weight: 700;
    color: #28323B;
    text-transform: capitalize;
    }
  } 
  
  .checkbox-button {
    background: none;
  }
</style>
