<template>
  <div class="w-full flex items-center justify-center h-screen py-6 overflow-y-auto block p-3">
    <div
      v-if="!showConfirmation"
      class="login-card-w my-auto"
    >
      <div class="apply-card flex flex-wrap justify-center">
        <h3 class="mb-6 w-full text-left text-pri text-lg font-semi-bold">
          Reset your password
        </h3>
        <h4 class="mb-6 w-full text-left text-gray04">
          Please enter your new password
        </h4>
        <InputWithLabel
          v-model="user.password"
          :label="'Password'"
          :type="'password'"
        />

        <div class="w-full mt-3">
          <p
            v-if="user.password.length >= 8"
            class="italic text-header-2 text-gray03 flex items-center"
          >
            Your password must contain 8 characters <feather
              type="check"
              size="14"
            />
          </p>
          <p
            v-else
            class="italic text-header-2 text-red flex items-center"
          >
            Your password must contain 8 characters <feather
              type="x"
              size="14"
            />
          </p>
        </div>

        <InputWithLabel
          v-model="user.password_confirmation"
          class="mt-6"
          :label="'Confirm'"
          :type="'password'"
        />
        <div
          class="w-full mt-3"
          :class="!user.password_confirmation ? 'invisible' : ''"
        >
          <p
            v-if="user.password == user.password_confirmation"
            class="italic text-header-2 text-gray03 flex items-center"
          >
            Confirm your password <feather
              type="check"
              size="14"
            />
          </p>
          <p
            v-else
            class="italic text-header-2 text-red flex items-center"
          >
            Confirm your password <feather
              type="x"
              size="14"
            />
          </p>
        </div>
        <div class="w-full flex justify-between items-center mt-6">
          <button
            class="button bg-sec text-white rounded-full uppercase"
            @click="updateUser()"
          >
            Update
          </button>
        </div>
      </div>
    </div>

    <div
      v-else
      class="login-card-w my-auto"
    >
      <div class="apply-card flex flex-wrap justify-center">
        <h3 class="mb-6 w-full text-left text-pri text-lg font-semi-bold">
          Reset your password
        </h3>
        <h4 class="text-gray04 text-left leading-2 mt-6">
          Password successfully updated!
        </h4>
        <h4 class="text-gray04 text-left leading-2 mt-6">
          Do you want to check 
          <router-link
            class="text-sec font-medium"
            :to="{ name: 'UserAccount'}"
          >
            your profile
          </router-link>
          or
          <router-link
            class="text-sec font-medium"
            :to="{ name: 'welcome'}"
          >
            Go to homepage
          </router-link>
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Reset',
  data() {
    return {
      showConfirmation: false,
      user: {
        password: '',
        password_confirmation: '',
      },
      validation: {
        password: {
          hasError: false,
          label: ''
        },
        password_confirmation: {
          hasError: false,
          label: ''
        },
      }
    }
  },
  mounted() {
    this.$store.commit('general/setRedirectTo')
    if(this.$route.query.recover_code) {
      this.verify()
    }
  },
  methods: {
    async verify() {
      this.$api.get(`/recover/verify?recover_code=${this.$route.query.recover_code}`)
      .then(({ data }) => {
        const { token } = data

        if(token) {
          this.$store.commit('setLoggedUserToken', { type: 'app', token })
          this.$store.dispatch('getLoggedUser', false)
        }

      })
      .catch((err) => {
        if(err.response.status == 404 && this.$store.getters['getterLoggedUser']) {
          this.$toast.info('You are logged in!')
          this.$router.push({ name: 'UserAccount' })
        } else {
          this.$toast.error('Token invalid, please generate new password.')
          this.$router.push({ name: 'UserSignin' })
        }
      })
    },

    updateUser() {
      if(!this.user.password) {
        this.validation.password.hasError = true
        this.validation.password.label = 'Please insert a new password'
        return
      }
      if(this.user.password != this.user.password_confirmation) {
        this.validation.password_confirmation.hasError = true
        this.validation.password_confirmation.label = 'Confirmation doesnt match'
        return
      }

      const user = this.$store.getters.getterLoggedUser

      this.$api.post(`/api/users/update_password/${user.id}`, { password: this.user.password })
        .then(() => {
          this.$toast.success('Password updated!')
          this.showConfirmation = true
          this.$router.push({ name: 'UserAccount' })
          setTimeout(() => {
            this.$toast.info('Login successful')
          }, 2000)
        })
        .catch((error) => {
          console.log(error)
          this.$toast.error('Something went wrong! Please try again.')
        })
    }
  }
}
</script>