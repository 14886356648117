<template>
  <BaseModal
    ref="baseModal"
    :show-modal-parent.sync="showModalParent"
    :modal-dialog-classes="'w-auto bg-white'"
    @close="close"
  >
    <AssumptionsModalSaveToPolicy
      v-if="
        getterUserPoliciesByJurisdiction &&
          getterUserPoliciesByJurisdiction.length > 0 &&
          userHasPolicies
      "
      :opened_from="open_modal_referrer"
    />
  </BaseModal>
</template>
 
<script>
import AssumptionsModalSaveToPolicy from '@/modules/app/assumptions/shared/AssumptionsModalSaveToPolicy.vue'
// Please DO NOT REMOVE this comment
// eslint-disable-next-line
import ExistingBuildingPolicyWizard from '@/modules/app/policy/wizard/ExistingBuildingPolicyWizard.vue'
import { mapGetters } from 'vuex'
 
export default {
  name: 'AssumptionsModal',
  components: {
    AssumptionsModalSaveToPolicy,
  },
  data: () => ({
    open_modal_referrer: false,
    showModalParent: false,
    userHasPolicies: false,
  }),
  computed: {
    // ...mapGetters(['isUserLoggedIn']),
    ...mapGetters('policy', ['getterUserPoliciesByJurisdiction']),
  },
  mounted() {
    this.$eventBus.$on(
      'openAssumptionsModal',
      ({eventPayload, userHasPolicies}) => {
        this.open_modal_referrer = eventPayload
        this.userHasPolicies =
        userHasPolicies
        this.open()
      }
    )
    this.$eventBus.$on('closeModalAssumptions', this.close)
    this.$store.dispatch('policy/getUserPolicies')
  },
  beforeDestroy() {
    this.$eventBus.$off('openAssumptionsModal')
    this.$eventBus.$off('closeModalAssumptions')
  },
  methods: {
    open() {
      this.showModalParent = true
      this.$store.commit('general/setRedirectTo', {
        route: window.location.pathname,
        event: { name: 'openAssumptionsModal' },
      })
    },
    close() {
      this.showModalParent = false
      this.$store.commit('general/setRedirectTo')
    },
  },
}
</script>
