import { render, staticRenderFns } from "./AdminJurisdictionList.vue?vue&type=template&id=3bbe1490"
import script from "./AdminJurisdictionList.vue?vue&type=script&lang=js"
export * from "./AdminJurisdictionList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports