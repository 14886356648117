import GraphQL from '@/util/GraphQL'
import { TYPE_PROTOTYPES_DB_SLUGS } from '@/util/Enums.js'

export const DBIDS_TYPE_PROTOTYPES = {
  SINGLE_FAMILY: 3,
  MULTIFAMILY : 1,
}

export const INTERCOM_LINK_PER_TYPE_PROTOTYPE_SLUG = {
  [TYPE_PROTOTYPES_DB_SLUGS.ADU]: 'https://intercom.help/explorer-local-energy-codes/en/articles/6619711',
  [TYPE_PROTOTYPES_DB_SLUGS.SINGLE_FAMILY]: 'https://intercom.help/explorer-local-energy-codes/en/articles/6705963',
  [TYPE_PROTOTYPES_DB_SLUGS.MULTIFAMILY]: 'https://intercom.help/explorer-local-energy-codes/en/articles/6705967'
}


export default {
  namespaced: true,
  
  state: {
    type_prototypes: []
  },

  getters: {
    getterGlobalTypePrototypes: (state) => (filters = null) => {
      return filters ? state.type_prototypes.findAllInArray(filters) : state.type_prototypes
    },

    getterGlobalTypePrototype: (state) => (filters = null) => {
      return filters ? state.type_prototypes.findInArray(filters) : false
    },

    getterGlobalTypePrototypeSingleFamily(state, getters) {
      return getters['getterGlobalTypePrototype']({ slug : TYPE_PROTOTYPES_DB_SLUGS.SINGLE_FAMILY })
    },

  },
  actions: {
    loadGlobalTypePrototypes(context) {
      const query = `
        {
          type_prototypes ( 
            orderBy: [{ column: "order", order: "ASC" }]
          ) {
            id
            title
            slug
            color
            icon
            building_stock_title
            building_stock_helper
            building_type_id
            hide_in_building_estimates
            title_building_estimates_existing
            order
            units_normalization
          }
        }
      `
      GraphQL({ query })
        .then(({ data }) => {
          context.commit('setGlobalTypePrototypes', { type_prototypes: data.type_prototypes})
        })
    }
  },

  mutations: {
    setGlobalTypePrototypes(state, { type_prototypes }) {
      state.type_prototypes = type_prototypes
    }
  }
}
