<template>
  <div class="psui-w-full psui-bg-white psui-shadow-elevation-5 psui-rounded-md psui-p-4 psui-mb-4 overflow-y-auto psui-divide-y psui-divide-gray-20">
    <div class="psui-w-full psui-flex psui-items-center psui-bg-white psui-pt-1 psui-pb-3">
      <div class="psui-w-1/3 psui-pl-4">
        <h3 class="psui-font-bold psui-text-gray-80 opacity-100-all-childrens-on-hover psui-flex psui-items-center">
          Comparison Name
        </h3>
      </div>

      <div class="psui-w-1/4">
        <h3 class="psui-font-bold psui-text-gray-80 opacity-100-all-childrens-on-hover psui-flex psui-items-center">
          City/County
        </h3>
      </div>

      <div class="psui-w-1/5">
        <h3 class="psui-font-bold psui-text-gray-80 opacity-100-all-childrens-on-hover psui-flex psui-items-center">
          {{ 'Policies' | policyTextRephrase }}
        </h3>
      </div>

      <div class="psui-w-1/5">
        <h3 class="psui-font-bold psui-text-gray-80 opacity-100-all-childrens-on-hover psui-flex psui-items-center">
          Author
        </h3>
      </div>

      <div class="psui-w-1/5">
        <h3 class="psui-font-bold psui-text-gray-80 opacity-100-all-childrens-on-hover psui-flex psui-items-center">
          Unique views
        </h3>
      </div>

      <div class="psui-w-auto psui-pr-4">
        <i class="psui-icon psui-text-white">more_horiz</i>
      </div>
    </div>

    <div 
      v-for="index in 3" 
      :key="index"
      class="list-policy psui-w-full psui-flex psui-items-center py-3 psui-text-gray-60 actions-wrapper hover:psui-bg-blue-10 hover:psui-text-blue-60 transition-all"
    >
      <div class="psui-w-1/3 psui-pl-4">
        <vue-skeleton-loader
          :width="Math.round((Math.random() * 100) + 80)"
          :height="27"
        />
      </div>

      <div class="psui-w-1/4">
        <vue-skeleton-loader
          :width="Math.round((Math.random() * 100) + 50)"
          :height="27"
        />
      </div>

      <div class="psui-w-1/5">
        <vue-skeleton-loader
          :width="Math.round((Math.random() * 100) + 20)"
          :height="27"
        />      
      </div>

      <div class="psui-w-1/5">
        <vue-skeleton-loader
          :width="Math.round((Math.random() * 100) + 30)"
          :height="27"
        />      
      </div>

      <div class="psui-w-1/5">
        <vue-skeleton-loader
          :width="Math.round((Math.random() * 100) + 20)"
          :height="27"
        />
      </div>

      <div class="psui-w-auto psui-pr-4">
        <div style="width: 24px; height: 24px;" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComparisonListTableSkeleton',
}
</script>