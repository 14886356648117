<template>
  <div class="w-full">
    <p
      v-if="!localPrototypes || !localPrototypes.length"
      class="text-red text-base"
    >
      This list is empty!
    </p>
    <table
      v-else
      class="table table-bordered whitespace-nowrap"
    >
      <thead>
        <tr>
          <th width="15%">
            Prototypes
          </th>

          <th width="55%">
            Fuel Switch Package
          </th>

          <th width="30%">
            Actions
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="prototype in localPrototypes"
          :key="prototype.id"
        >
          <td>{{ prototype.id }} - {{ prototype.title }}</td>

          <td>
            <div class="w-full relative">
              <VueSelect
                v-model="prototype.fuel_switch_package_measure_id"
                class="vue-select"
                placeholder="Select a Measure"
                label="title"
                :options="measures"
                :multiple="false"
                :reduce="(option) => option.id"
              />
              <label>Measures</label>
            </div>
          </td>
          
          <td>
            <PsButton
              :disabled="!prototype.fuel_switch_package_measure_id"
              label="Make Default For All Prototypes"
              size="medium"
              @click="
                makeFuelSwitchPackageDefaultToAllPrototypes(
                  prototype.fuel_switch_package_measure_id
                )
              "
            />
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="!localPrototypes || !localPrototypes.length" />
    <div v-else>
      <PsButton
        label="Save"
        layout="solid"
        class="mt-6"
        :disabled="isSavingPrototypes"
        @click="savePrototypes()"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminFuelSwitchPackage",
  props: ["type", "title", "prototypes", "measures"],
  data() {
    return {
      localPrototypes: [],
      isSavingPrototypes: false,
    }
  },
  computed: {
    getTableToOrdenateItems() {
      return `${this.type}s`
    },
    getFuelTypePrototype() {
      return this.localPrototypes.map((prototype) => prototype.type_prototype)
    },
  },
  watch: {
    prototypes: {
      handler(prototypes) {
        this.localPrototypes = [...prototypes]
      },
      immediate: true,
    },
  },
  methods: {
    makeFuelSwitchPackageDefaultToAllPrototypes(
      fuel_switch_package_measure_id
    ) {
      if (!fuel_switch_package_measure_id) return

      for (const prototype of this.localPrototypes) {
        prototype.fuel_switch_package_measure_id =
          fuel_switch_package_measure_id
      }
    },

    savePrototypes() {
      if (this.isSavingPrototypes) return

      this.isSavingPrototypes = true
      const requests = this.localPrototypes.map((prototype) => {
        return this.$api.put(
          `/studies/${this.$route.params.study_id}/prototype/${prototype.id}`,
          prototype
        )
      })

      Promise.all(requests)
        .then(() => {
          this.$toast.success("Item saved successfully")
          this.isSavingPrototypes = false
          this.$emit("changeErrorMessages")
        })
        .catch(() => {
          this.$toast.error("Error saving items")
          this.isSavingPrototypes = false
        })
    },
  },
}
</script>
