import Jurisdiction from '@/models/Jurisdiction'
import { defaultExistingBuildingsImpactAssumptionValues, defaultNewBuildingsImpactAssumptionValues } from '@/models/ImpactAssumptions'
import Store from '@/store'
export default class Policy {

  constructor( obj = {}) {
    this.id = obj.id || null
    this.title = obj.title || null 
    this.user_id = obj.user_id || null
    this.user_device_id  = obj.user_device_id  || null
    this.jurisdiction_id = obj.jurisdiction_id || null
    this.policy_option_id = obj.policy_option_id || null
    this.created_at = obj.created_at || null
    this.updated_at = obj.updated_at || null
    this.deleted_at = obj.deleted_at || null
    
    // Assumptions
    for ( let key in {...defaultExistingBuildingsImpactAssumptionValues, ...defaultNewBuildingsImpactAssumptionValues}) {
      this[key] = typeof obj[key] != 'undefined' ? obj[key] : typeof defaultExistingBuildingsImpactAssumptionValues[key] != 'undefined' ?
        defaultExistingBuildingsImpactAssumptionValues[key] : defaultNewBuildingsImpactAssumptionValues[key]
    }

    // Relationships
    
    this.custom_combinations = obj.custom_combinations || []
    this.jurisdiction = obj.jurisdiction ? new Jurisdiction(obj.jurisdiction) : new Jurisdiction()
    this.policy_containers = obj.policy_containers || []
    this.policy_option = obj.policy_option || null
    this.code_cycle = obj.code_cycle || null

    // Helpers
    this.jurisdiction_title = obj.jurisdiction?.title
    this.policy_active_tab = obj.policy_active_tab || null
    this.typePrototypes = obj.typePrototypes || []
    this.policyMap = obj.policyMap || []
    this.loading = typeof obj.loading != 'undefined' ? obj.loading : false
    this.silent_run_steps = typeof obj.silent_run_steps != 'undefined' ? obj.silent_run_steps : []

    if(Array.isArray(this.policy_containers)) this.orderPolicyContainers()
  }

  get codeCycle() {
    return this.code_cycle?.title ?? ''
  }

  orderPolicyContainers() {
    this.policy_containers.sort((a, b) => {
      const typePrototypeA = Store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id: a.type_prototype_id })
      const typePrototypeB = Store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id: b.type_prototype_id })
      return typePrototypeA.order - typePrototypeB.order
    })
  }
}
