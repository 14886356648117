<template>
  <thead class="psui-z-20 psui-top-0 psui-bg-white">
    <th class="psui-sticky psui-z-10 psui-left-0 psui-flex psui-items-start psui-bg-white psui-text-gray-80 psui-text-small font-bold">
      <div class="psui-w-full psui-h-12 psui-flex psui-items-start psui-py-2 psui-pl-3 column-group-header">
        Fuel Type
      </div>
    </th>
    <th 
      v-for="(column, columnIndex) of columns" 
      :key="`${column.key}-${column.key}`" 
      class="psui-font-bold align-top comparison-table-column w-auto pb-2"
    >
      <div class="psui-w-full psui-flex flex-col px-2 column-group-header">
        <span 
          class="column-group-header-title psui-flex psui-text-gray-80 psui-text-small font-bold ml-auto"
          @mouseenter="showHelper[columnIndex] = true"
          @mouseleave="showHelper[columnIndex] = false"
        >
          <PsIcon
            icon="info_outline"
            size="16"
            class="info-outline-icon-open-modal info-helper psui-items-center psui-justify-center psui-cursor-pointer"                
            :style="{ display: 'flex', marginTop: '1px', marginRight: '0px', visibility: showHelper[columnIndex] ? 'unset' : 'hidden' }"
            @click.native.stop="$eventBus.$emit('openDescriptionModal', column.hasHelper)"
          />
          <span 
            class="order-3 ml-auto"
          >{{ column.title }}</span>
        </span>
        <span
          v-if="columnIndex !== 0"
          class="psui-text-gray-50 psui-text-xsmall font-normal ml-auto"
          :class="{'text-left': columnIndex === 0}"
        >{{ column.description }}</span>
      </div>
    </th>
  </thead>
</template>

<script>
export default {
  name: 'PolicyTableHeader',
  props: {
    columns: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      hiddenItems: [],
      showHelper: {},
    }
  },
  watch: {
    columns: {
      handler() {
        this.initializeShowHelper()
      },
      deep: true
    }
  },
  created() {
    this.initializeShowHelper()
  },
  methods: {
    initializeShowHelper() {
      this.showHelper = this.columns.reduce((acc, _, index) => {
        acc[index] = false
        return acc
      }, {})
    }
  },
}
</script>

<style lang="scss" scoped>
.superheader-border {
  border-color: #CFDFE5;
}  

.info-outline-icon-open-modal {
  color: #798490; // psui-text-gray-50

  &:hover {
    color: #318FAC; // psui-text-blue-60  
  }
}
.column-group-header{
  padding: 7px 8px;

  &-title {
    margin-bottom: 2px;
  }
}
</style>