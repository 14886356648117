import BaseLayoutWithHeaderAndSideBar from '@/layout/BaseLayoutWithHeaderAndSideBar'
import SidebarContentLogin from '@/modules/app/login/components/SidebarContentLogin'
import Signin from '@/modules/app/login/components/Signin'
import Signup from '@/modules/app/login/components/Signup'
import Recover from '@/modules/app/login/components/Recover'
import Reset from '@/modules/app/login/components/Reset'

export default [
  {
    path: '/login',
    component: BaseLayoutWithHeaderAndSideBar,
    children: [
      {
        path: '',
        name: 'UserSignin',
        component: Signin,
        meta: {
          sidebar: {
            size: 'large',
            content: SidebarContentLogin
          }
        }
      },
      {
        path: 'register',
        name: 'UserSignup',
        component: Signup,
        meta: {
          sidebar: {
            size: 'large',
            content: SidebarContentLogin
          }
        }
      },
      {
        path: 'recover',
        name: 'UserPasswordRecover',
        component: Recover,
        meta: {
          sidebar: {
            size: 'large',
            content: SidebarContentLogin
          }
        }
      },
      {
        path: 'reset',
        name: 'UserPasswordReset',
        component: Reset,
        meta: {
          sidebar: {
            size: 'large',
            content: SidebarContentLogin
          }
        }
      }
    ]
  },
  {
    path: '/reset',
    redirect: { name: 'UserPasswordReset'}
  }
]