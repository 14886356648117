<template>
  <BaseModal
    ref="baseModal"
    modal-dialog-classes="max-w-md"
  >
    <div
      v-if="studyGroup"
      class="w-full"
    >
      <h4 class="w-full text-header-6 font-bold text-deepsky-900 text-left">
        {{ studyGroup.title }} Study Version History
      </h4>
      
      <div class="timeline mt-10">
        <div 
          v-for="study in studyGroup.studies"
          :key="study.id"
          class="timeline-item w-auto flex items-center ml-6 mr-14 mb-4 p-8 border rounded flex-wrap last:mb-0"
          :class="checkIfStudyIsLastPublishedVersion(study.id) ? 'active border-solid bg-deepsky-100 border-deepsky-450 text-deepsky-500 cursor-pointer' : 'text-ash-450 border-dashed border-ash-300 bg-ash-100'"
          @click.stop="onVersionClick(study)"
        >
          <div>
            <h3 class="text-p-3 font-bold">
              {{ study.released_at_formatted_version ? study.released_at_formatted_version : 'Upcoming Version' }}
            </h3>
            <h4 class="text-header-2 font-bold">
              {{ study.title_by_prototypes }}
            </h4>
          </div>
          <div class="psui-ml-auto psui-flex-shrink-0 psui-text-right">
            <AppButton 
              v-if="!study.published_at && study.source_pdf" 
              label="Download PDF"
            />
            <span
              v-if="study.summary_message_tag"
              class="text-header-1 text-lime-400 border rounded-xs p-1 border-lime-400 font-bold flex-none"
            >
              {{ study.summary_message_tag }}
            </span>
            <h6
              class="text-header-1 font-bold mt-2 text-inherit cursor-pointer transition-all hover:opacity-80"
              :class="[
                { 'text-ash-450' : study.showChanges },
                { 'psui-cursor-default psui-pointer-events-none' : !study.version_updated_changes }
              ]"
              @click.stop="$set(study, 'showChanges', !study.showChanges)"
            >
              {{ !study.showChanges ? 'Changes in this version' : 'Hide version changes' }}
            </h6>
          </div>
            
          <transition
            name="fade"
            mode="out-in"
          >
            <div 
              v-if="study.showChanges"
              class="w-full flex-shrink-0 border-t-2 border-ash-200 mt-4"
            >
              <h5 class="text-header-2 text-ash-600 font-bold mt-4">
                What's new in this version:
              </h5>
              <RenderHtmlExtended  
                class="app-drawer-content-styles mt-3"
                :html="study.version_updated_changes"
              />
            </div>
          </transition>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import RenderHtmlExtended from '@/components/general/RenderHtmlExtended.vue'
import Study from '@/models/Study'

export default {
  name: 'StudyGroupVersionsTimelineModal',
  components: { RenderHtmlExtended },
  data: () => ({
    studyGroup: false,
    showChanges: false,
    typePrototype: false,
  }),
  computed: {
    getPrototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt() {
      return this.$store.getters['globalEntities/Prototype/getterPrototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt']({ 
        type_prototype: this.typePrototype, 
        study_group: this.studyGroup 
      })
    }
  },
  mounted() {    
    this.$eventBus.$on('openStudyGroupVersionsTimelineModal', ({ studyGroupId, typePrototype }) => {
      this.getStudyGroup(studyGroupId)
      this.showChanges = false
      this.typePrototype = typePrototype
      this.$refs.baseModal.open()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openStudyGroupVersionsTimelineModal')
  },
  methods: {
    openPdf() {
      window.open(this.study.source_pdf_update)
    },
    checkIfStudyIsLastPublishedVersion(study_id) {
      const lastPublishedVersionStudy = this.lastPublishedStudyVersion()
      return lastPublishedVersionStudy && lastPublishedVersionStudy?.id == study_id ? true : false

    },
    lastPublishedStudyVersion(studies = this.studyGroup.studies) {
      const versions = studies.filter(study => study.published_at)
      versions.sort( (a, b ) => a.published_at - b.published_at )
      return versions[0]
    },
    onVersionClick(study) {
      const setStudyToShow = Boolean( this.checkIfStudyIsLastPublishedVersion(study.id) && study.show_version_history )
      if ( !setStudyToShow ) return

      const route_exclude_study_ids = this.$route?.query?.exclude_study_ids ? this.$route?.query?.exclude_study_ids.split(',') : []
      const forecastPrototypeId = this.getPrototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt ? this.getPrototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt?.[0]?.id : false

      if ( route_exclude_study_ids.includes(`${study.id}`) ) return

      this.$router.push(this.getSeeResultsQueryParams(study))
      this.scrollToForecastPrototype(forecastPrototypeId)
      this.$refs.baseModal.close()
    },
    getSeeResultsQueryParams(study) {      
      const study_type = this.$store.getters['globalEntities/Study/getterStudyStudyType']({ study_id: study.id })
      const studies = this.$store.getters['globalEntities/Study/getterStudiesByStudyType']({ study_type_id: study_type.id })
      const exclude_study_ids = studies.removeAllInArray({ id: study.id }).map((study) => study.id)
      return {
        query: { 
          exclude_study_ids : exclude_study_ids.join(','), 
          only_study_type : this.$route.query.only_study_type 
        }
      }
    },
    scrollToForecastPrototype(forecastPrototypeId) {
      const studyResultsIndexEl = document.getElementById(`study-results-index`)
      const studyResultsHeaderEl = document.getElementById(`study-results-header`)
      const prototypeEl = document.getElementById(`forecast-prototype-${forecastPrototypeId}`)
      studyResultsIndexEl.scrollTo({ top: prototypeEl.offsetTop - studyResultsHeaderEl.offsetHeight - 30, left: 0, behavior: 'smooth' })
    },
    async getStudyGroup(studyGroupId) {

      const isPrivate = this.$store.getters.getterLoggedUser && this.$store.getters.getterLoggedUserType == 'admin'
      const isPrivateQuery  = isPrivate ? 
        `(orderBy: [{column: "released_at", order: "desc"}])` : 
        `(
          orderBy: [
            {column: "released_at", order: "desc"}
          ], 
          where: [
            { column: "is_private", operation: "=", value: "false" }
            { column: "study_group_id", operation: "=", value: "${studyGroupId}"}
          ],
          orWhere: [
            { column: "show_version_history", operation: "=", value: "true" }
            { column: "study_group_id", operation: "=", value: "${studyGroupId}"}
          ]
        )`
      const query = `
        {
          study_group (
            where: [
              { column: "id", operation: "=", value: "${studyGroupId}"},
            ]
          ) {
            id
            title
            order
            studies ${isPrivateQuery} {
              id
              title
              released_at
              published_at
              source_pdf
              version_updated_changes
              show_version_history
              custom_fields {
                item_id
                data
              }
            }
          }
        }
      `

      const { data } = await this.$graphql({ query })
      if (data.study_group) {            
        data.study_group.studies = data.study_group.studies.map(study => new Study(study))
        this.studyGroup = data.study_group
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .timeline-item {
    position: relative;
    h3:before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      box-sizing: content-box;
      background-color: #D4D8D9;
      border-width: 4px;
      border-color: white;    
      border-radius: 50%;
      left: -34px;
      margin-top: 5px;
      z-index: 1;
    }
    &:after {
      content: "";
      position: absolute;
      width: 2px;
      height: calc(100% + 18px);
      background-color: #D4D8D9;      
      left: -26px;
      top: 0;
    }

    &:last-of-type {
      &:after {
        height: 100%;
      }
    }
    &.active {
      h3:before {
        border-color: #ffffff;
        background-color: #69A7BF;
        width: 14px; height: 14px;
        left: -36px;
      }
      + div::after {
        top: -18px;
        height: calc(100% + 36px);
      }
    } // :first-of-type
  }
  .timeline-item ::v-deep {    
    .app-drawer-content-styles * {
      color: #81878A;
    }
  }
</style>
