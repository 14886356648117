<template>
  <BaseModal
    ref="baseModal"
    modal-dialog-classes="w-full max-w-sm"
    title="Duplicate to My Policies"
    :show-modal-parent="showModalParent"
    @close="close"
  > 
    <PolicyFormDuplicate
      v-if="policy_id"
      :policy-id="policy_id"
      :title="title"
      @store-succcess="close"
    />
  </BaseModal>
</template>

<script>

// import UserSignForm from '@/modules/app/login/components/UserSignForm.vue'
import PolicyFormDuplicate from './PolicyFormDuplicate.vue'

const initialState = () => {
  return {
    policy_id : false,
    title: '',
    showModalParent: false
  }
}

export default {
  name: 'ModalDuplicatePolicy',
  components: { PolicyFormDuplicate},
  data: () => ({
    ... initialState()
  }),
  computed: {
    validationTitle() {
      if(!this.title) return { hasError: true, label: 'Please inform the Policy Name'}
      return { hasError: false }
    }
  },
  mounted() {
    this.$eventBus.$on('openDuplicatePolicyModal', ({ policy }) => {
      this.showModalParent = true
      this.policy_id = policy.id
      this.title = policy.title + ` (2)`
    })
  },
  beforeDestroy() {    
    this.$eventBus.$off('openDuplicatePolicyModal')
  },
  methods: {
    close() {
      this.showModalParent = false
      this.resetState()
    },
    resetState() {
      Object.assign( this.$data, initialState())
    },
    
  }
}
</script>