import EventBus from '@/util/EventBus'
export default (Store) => {
  Store.subscribe((mutation) => {
    const mutationsToWatch = [
      'assumptions/updateAssumptionsKeyValue', 
      'assumptions/clearPolicyImpactAssumptions', 
      'assumptions/clearResultsImpactAssumptions', 
      'assumptions/buildingEstimates/clearCustomBuildingEstimates', 
      'assumptions/buildingEstimates/setCustomBuildingEstimates', 
      'assumptions/buildingStocks/clearCustomBuildingStocks', 
      'assumptions/buildingStocks/setCustomBuildingStocks', 
    ]
    if(mutationsToWatch.includes(mutation.type)) {
      EventBus.$emit('editAssumptionsChanged', mutation.type)
    }   
  })
}