<template>
  <BaseModal
    :show-modal-parent="showModal"
    modal-dialog-classes="w-auto"
    title="User devices list"
    @close="showModal = false"
  >
    <div class="w-full flex flex-col items-start mt-6">
      <table class="table table-bordered table-hover table-striped">
        <thead>
          <tr>
            <td>Id</td>
            <td>Uid</td>
            <td>Os</td>
            <td>Platform</td>
            <td>Last session at</td>
            <td>City</td>
            <td>Country</td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="user_device in user_devices"
            :key="user_device.uid"
          >
            <td>{{ user_device.id }}</td>
            <td>{{ user_device.uid }}</td>
            <td>{{ user_device.os }}</td>
            <td>{{ user_device.platform }}</td>
            <td>{{ user_device.last_session_at }}</td>
            <td>{{ user_device.city }}</td>
            <td>{{ user_device.country }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </BaseModal>
</template>

<script>
export default {
  name: 'AdminUserDeviceListModal',
  data() {
    return {
      showModal: false,
      user_id: null,
      user_devices: []
    }
  },
  mounted() {
    this.$eventBus.$on('openAdminUserDeviceListModal', (user_id) => {
      this.user_id = user_id
      this.showModal = true
      this.getUserDevices()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openAdminUserDeviceListModal')
  },
  methods: {
    getUserDevices() {
      const query = `
        {
           user_devices (
            where: [
              { column: "user_id", value: "${this.user_id}" }
            ]
            orderBy: [
              { column: "created_at", order: "DESC" },
            ]
           ) {
            id
            uid
            os
            platform
            created_at
            last_session_at
            city
            country
           }
        }
      `

      this.$graphql({ query, caller: 'AdminUserDeviceEditModal' })
        .then(({ data }) => {
          this.user_devices = data.user_devices
        })
    }
  }
}
</script>