import Vue from "vue"
import VueGtag from "vue-gtag"
import { bootstrap as bootstrapGtag } from 'vue-gtag'

export default (Store) => {

  Vue.use(VueGtag, {
    config: { id: 'GTM-MDXTJXWG' },
    includes: [{ id: 'G-1XH49KPBGC'}], // Lets test if everything works without sending directly to analytics
    bootstrap: false
  })

  // Add timeout to avoid tracking before the redirect of admin and team users to the domain https://explorer-production-team.vercel.app/
  Store.subscribe((mutation) => {
    if( mutation.type === 'general/setDeviceInfo' && Store.getters['general/getterDeviceInfo']?.log_events && window.location.hostname !== 'localhost') {
      bootstrapGtag().then(() => {
        console.log('Gtag service started')
      })
    }
  })

}