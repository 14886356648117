<template>
  <div class="psui-w-full">
    <!-- <PsRadioButtonSimple
      v-for="preset in CUSTOM_COMBINATION_PRESETS"
      :key="preset.value"
      :checked="preset.value === maxTargetScorePresetSelected"
      :label="preset.label"
      size="small"
      class="px-5 py-1 psui-text-gray-60 psui-font-bold"
      @change="updateMaxTargetScorePreset(preset)"
    /> -->
    <!-- MAX COST-EFFECTIVE SCORES ROW -->
    <div class="table-score psui-w-full psui-flex psui-border-b psui-border-white">
      <button
        class="table-score-info-icon flex items-center"
        @click="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: 'policy-flexiblepath-max-cost-effective-score' })"
      >
        <feather
          size="16"
          type="info"
          class="text-deepsky-600 flex-shrink-0"
        />
      </button>
      <div
        class="
          table-score-first-column
          w-full h-10 flex items-center
          psui-bg-blue-50 psui-text-small text-deepsky-800 font-bold
          opacity-100-childrens-on-hover
        "
      > 
        <span class="pr-1">Max Cost-Effective Score</span>
        <PsDropdown
          ref="customCombinationPresents"
          class="psui-el-dropdown-custom-combination-presets ml-3"
        >
          <template #dropdownTrigger>
            <span 
              class="psui-el-dropdown-menu-item psui-flex psui-justify-between psui-items-center psui-w-full psui-text-gray-50 psui-bg-white psui-cursor-pointer psui-border psui-border-gray-20 psui-px-2 psui-py-0.5 psui-rounded"
            >
              <span class="psui-text-small">
                {{ getMaxTargetScorePresetSelectedByPreset.label }}
              </span>
              <PsIcon 
                icon="expand_more"
                class="psui-text-gray-50 psui-cursor-pointer leading-none"
                size="18"
                display="flex"
              />
            </span>
          </template>
          <template #items>
            <PsDropdownList 
              :items="CUSTOM_COMBINATION_PRESETS"
              :selected="maxTargetScorePresetSelected"
              left-label="label"
              key-value="value"
              layout="onlytext"
              @change="updateMaxTargetScorePreset"
              @openDescriptionModal="openDescriptionModal"
            />
          </template>
        </PsDropdown>
      </div>

      <div
        v-for="(type_vintage, type_vintage_index) in type_vintages" 
        :id="`max-target-score-type_vintage-${type_vintage.id}`"
        :key="`type_vintage-${type_vintage.id}`"
        class=" cell-width h-10 flex items-center pl-4 flex-shrink-0 psui-bg-blue-50 text-deepsky-900 text-header-2 font-bold"
      >
        <span v-if="shouldBeIncludedInTypeVintage({ climate_zone, type_vintage_index })">
          {{ getMaxCostEffectiveScore({ climate_zone, prototype, type_vintage, max_target_scores_by_presets, flexible_path_setup }) }}
        </span>
        <span v-else>--</span>        
      </div>
    </div>
    
    <!-- MAX POSSIBLE SCORE -->
    <!-- v-if="shouldIncludeMaxPossibleScoreRow" -->
    <div 
      class="table-score w-full flex border-b border-white"
    >
      <button
        class="table-score-info-icon flex items-center"
        @click="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: 'policy-flexiblepath-max-possible-score' })"
      >
        <feather
          size="16"
          type="info"
          class="text-deepsky-600 flex-shrink-0"
        />
      </button>
      <div
        class="
        table-score-first-column
        w-full h-10 flex items-center pl-10
        psui-bg-blue-50 psui-text-small text-deepsky-800 font-bold
        opacity-100-childrens-on-hover"
      >
        <span class="pr-1">Max Possible Score</span>
      </div>
      <div
        v-for="(type_vintage, type_vintage_index) in type_vintages" 
        :id="`max-target-score-type_vintage-${type_vintage.id}`"
        :key="`type_vintage-${type_vintage.id}`"
        class=" cell-width h-10 flex items-center pl-4 flex-shrink-0 psui-bg-blue-50 text-deepsky-900 text-header-2 font-bold"
      >
        <span v-if="shouldBeIncludedInTypeVintage({ climate_zone, type_vintage_index })">
          {{ getMaxPossibleScore({ climate_zone, type_vintage, max_target_scores_by_presets, type_vintage_index, prototype, flexible_path_setup}) }}
        </span>
        <span v-else>--</span>        
      </div>
    </div>

    <div
      class="w-full"
    >
      <div class="table-score flex bg-deepsky-300 hover:bg-deepsky-200 transition-all">
        <button
          class="table-score-info-icon flex items-center"
          @click="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: 'policy-flexiblepath-measuremenu-targetscore' })"
        >
          <feather
            size="16"
            type="info"
            class="text-deepsky-600 flex-shrink-0"
          />
        </button>
        <button
          class="table-score-chevron flex items-center"
          @click="showImpacts = !showImpacts"
        >
          <feather
            size="16"
            :type="!showImpacts ? 'chevron-right' : 'chevron-down'"
            class="text-deepsky-600 flex-shrink-0"
          />
        </button>
        <div 
          class="
            table-score-target
            w-full h-10 flex items-center
            text-header-2 text-deepsky-700 font-bold
            opacity-100-childrens-on-hover cursor-pointer"
          @click="showImpacts = !showImpacts"
        >
          <span class="pr-1 flex">{{ tier.title ? tier.title : 'Target Score' }}</span>
        </div>
        <v-popover 
          v-for="(type_vintage, type_vintage_index) in type_vintages" 
          :key="`type_vintage-${type_vintage.id}`"
          :open="showMaxScoreTooltip && showMaxScoreTooltip[type_vintage.id]"
          trigger="manual"
          :auto-hide="false"
          placement="right-center"
          :popover-class="`vue-popover-theme cee-popover-maxtargetscore`"
        >
          <div class="cell-width h-10 flex items-center flex-shrink-0">
            <TargetScoreInput
              v-if="shouldBeIncludedInTypeVintage({ climate_zone, type_vintage_index })"
              :policy="policy"
              :prototype="prototype"
              :climate_zone="climate_zone"
              :tier="tier"
              :type_vintage="type_vintage"
              :type_vintage_index="type_vintage_index"
              :flexible_path_setup="flexible_path_setup"
              :close_modal_without_changes="closeModalWithoutChanges"
              :max_target_scores_by_presets="max_target_scores_by_presets"
              @showMaxScoreTooltip="onShowMaxScoreTooltip(type_vintage.id, $event)"
              @openPolicyFlexiblePathScoreWarningModal="onOpenPolicyFlexiblePathScoreWarningModal($event)"
            />
            <span
              v-else
              class="pl-4"
            >--</span>
          </div>
          <!-- <AppTooltipWithAction
            v-if="getMaxCostEffectiveScore({ climate_zone, prototype, type_vintage, max_target_scores_by_presets, flexible_path_setup }) == getMaxPossibleScore({ climate_zone, type_vintage, max_target_scores_by_presets, type_vintage_index, prototype, flexible_path_setup })"
            slot="popover"
            message="This is above the Max Cost-Effective Score"
            @btn-action-on-click="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: 'policy-flexiblepath-max-cost-effective-score' })"          
          />
          <AppTooltipWithAction
            v-else          
            slot="popover"
            message="This is above the Max Possible Score"
            @btn-action-on-click="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: 'policy-flexiblepath-max-possible-score' })"          
          /> -->
        </v-popover>
      </div>
      <MeasureMenuImpacts
        v-show="showImpacts"
        :show-impacts="showImpacts"
        :tier="tier"
        :policy="policy"
        :prototype="prototype"
        :climate_zone="climate_zone"
        :type_vintages="type_vintages"
        :flexible_path_setup="flexible_path_setup"
        :max_target_scores_by_presets="max_target_scores_by_presets"
      />
    </div>
  </div>
</template>

<script>
// import AppTooltipWithAction from '@/components/general/AppTooltipWithAction.vue'
import MeasureMenuImpacts from './MeasureMenuImpacts.vue'
import TargetScoreInput from '../TargetScoreInput.vue'
import { getMaxTargetScorePresetSelected, getMaxCostEffectiveScore, getMaxPossibleScore, shouldBeIncludedInTypeVintage } from '@/services/measure-menu/MeasureMenuGeneralService'
import { CUSTOM_COMBINATION_PRESETS } from '@/modules/app/jurisdiction/study-results/shared/enums.js'
import { setTierTargetScore } from '@/services/measure-menu/MeasureMenuGeneralService'

export default {
  name: 'MeasureMenuTier',
  components: { 
    TargetScoreInput, 
    MeasureMenuImpacts, 
    // AppTooltipWithAction,
  },
  props: ['tier', 'policy', 'prototype', 'climate_zone', 'type_vintages', 'flexible_path_setup', 'max_target_scores_by_presets'],
  data() {
    return {
      showImpacts: false,
      showMaxScoreTooltip: false,
      CUSTOM_COMBINATION_PRESETS,
      targetScore: [Number, String],
      maxPossibleScore: [Number, String],
      maxCostEffectiveScore: [Number, String],
      typeVintageId: [Number, String],
      climateZoneId: [Number, String],
      prototypeId: [Number, String],
      typeVintage: [Object, String],
      closeModalWithoutChanges: null,
      lastTargetScoreInput: [Number, String],
    }
  },
  computed: {
    maxTargetScorePresetSelected() {
      return getMaxTargetScorePresetSelected({ climate_zone_id : this.climate_zone.id, prototype_id: this.prototype.id, flexible_path_setup: this.flexible_path_setup})
    },
    getMaxTargetScorePresetSelectedByPreset() {
      const presetSelected = getMaxTargetScorePresetSelected({ climate_zone_id : this.climate_zone.id, prototype_id: this.prototype.id, flexible_path_setup: this.flexible_path_setup})

      return this.CUSTOM_COMBINATION_PRESETS[presetSelected]
    },
    shouldIncludeMaxPossibleScoreRow() {
      let shouldInclude = false
      
      const { climate_zone, max_target_scores_by_presets, prototype, flexible_path_setup } = this      
      for (const [type_vintage_index, type_vintage] of this.type_vintages.entries()) {
        const maxCe = getMaxCostEffectiveScore({ climate_zone, prototype, type_vintage, max_target_scores_by_presets, flexible_path_setup })
        const maxPossible = getMaxPossibleScore({ climate_zone, type_vintage, max_target_scores_by_presets, type_vintage_index, prototype, flexible_path_setup })
        if (maxPossible < maxCe) {
          shouldInclude = true
          continue
        }
      }

      return shouldInclude
    },
    getPolicyId() {
      return this.$route.params?.policy_id
    },
    isScoreAboveMaxAchievableAndBelowMaxCostEffective() {
      return this.targetScore > this.maxPossibleScore && this.targetScore < this.maxCostEffectiveScore
    },
    isScoreAboveMaxAchievableAndMaxCostEffectiveAndEqual() {
      return this.targetScore > this.maxPossibleScore && this.targetScore > this.maxCostEffectiveScore && this.maxCostEffectiveScore == this.maxPossibleScore
    },
    isScoreAboveMaxAchievableAndMaxCostEffectiveAndNotEqual() {
      return this.targetScore > this.maxPossibleScore && this.targetScore > this.maxCostEffectiveScore && this.maxCostEffectiveScore != this.maxPossibleScore
    },
    userCanEditPolicy() {
      return this.$store.getters['policy/getterUserCanEditPolicy'](this.policy.id)
    },
  },
  created() {
    this.initShowMaxScoreTooltip()
  },
  methods: {
    openDescriptionModal({type,id,slug}) {
      this.$eventBus.$emit('openDescriptionModal', {type,id,slug})
    },
    getMaxCostEffectiveScore,
    shouldBeIncludedInTypeVintage,
    getMaxPossibleScore,
    setTierTargetScore,
    initShowMaxScoreTooltip() {
      this.showMaxScoreTooltip = this.type_vintages.reduce((acc, type) => {
        acc[type.id] = false
        return acc
      }, {})
    },
    onShowMaxScoreTooltip(type_vintage_id, value) {
      this.showMaxScoreTooltip[type_vintage_id] = value
    },
    updateMaxTargetScorePreset(preset) {      
      const setup = { ...this.flexible_path_setup }      
      setup.cost_effective_presets.removeInArray({ climate_zone_id: this.climate_zone.id, prototype_id : this.prototype.id })      
      setup.cost_effective_presets.push({ climate_zone_id: this.climate_zone.id, prototype_id : this.prototype.id, preset: preset.value })
      this.$eventBus.$emit('updateFlexiblePathSetup', setup)
      this.$refs.customCombinationPresents.close()
    },
    onOpenPolicyFlexiblePathScoreWarningModal(payload){
      const { targetScoreInput, maxPossibleScore, maxCostEffectiveScore, typeVintageId, climateZoneId, prototypeId, typeVintage, lastTargetScoreInput } = payload

      this.targetScore = targetScoreInput
      this.maxPossibleScore = maxPossibleScore
      this.maxCostEffectiveScore = maxCostEffectiveScore
      this.typeVintageId = typeVintageId
      this.climateZoneId = climateZoneId
      this.prototypeId = prototypeId
      this.typeVintage = typeVintage
      this.lastTargetScoreInput = lastTargetScoreInput

      let title = `Careful, a Target Score of ${this.targetScore} cannot be achieved cost-effectively.`
      let scenario = 'scenario_1'
      let description = ''
      if (this.isScoreAboveMaxAchievableAndBelowMaxCostEffective || this.isScoreAboveMaxAchievableAndMaxCostEffectiveAndNotEqual) {
        description = `According to cost-effectiveness study results, a score of <b>${this.maxCostEffectiveScore}</b> is the maximum cost-effective score. Based on the measures you have deleted or made mandatory, a score of <b>${this.maxPossibleScore}</b> is the maximum possible score.`
        scenario = 'scenario_2'
      } else if (this.isScoreAboveMaxAchievableAndMaxCostEffectiveAndEqual) {
        description = `According to cost-effectiveness study results, a score of <b>${this.maxCostEffectiveScore}</b> is the maximum cost-effective score.`
        scenario = 'scenario_3'
      }

      let buttons = []

      if (this.isScoreAboveMaxAchievableAndMaxCostEffectiveAndEqual) {
        buttons = [
          {
            title: 'Proceed with the new score anyway',
            action: this.proceedWithTheNewScore,
          },
          {
            title: 'Cancel',
            action: this.goBack,
            ignoreDontShowAgain: true,
          }
        ]
      } else {
        buttons = [
          {
            title: 'Proceed with the new score anyway',
            action: this.proceedWithTheNewScore       
          },
          {
            title: 'Restore deleted and mandatory measures and set my Target Score to the maximum cost-effective score',
            action: this.restoreDeletedMeasures,
          },
          {
            title: 'Cancel',
            action: this.goBack,
            ignoreDontShowAgain: true,
          }
        ]
      }

      this.$eventBus.$emit('openOptionsModal',{
        title,
        description: `
        <p class="psui-text-gray-50 mt-4 psui-text-p">
          ${description}
          <a
            target="_blank"
            class="psui-text-blue-60 psui-font-bold psui-cursor-pointer"
            href="https://intercom.help/explorer-local-energy-codes/en/articles/7903616-max-cost-effective-score-calculations"
          >Learn More</a>
        </p>`,
        buttons,
        checkbox: {
          label: "Don't show this next time",
          neverAskUserProp: `do_not_show_policy_flexible_path_score_warning_modal-${this.getPolicyId}-${scenario}`,
        }
      })
    },
    proceedWithTheNewScore() {
      const type_vintage = this.typeVintage
      const climate_zone = this.climate_zone
      const prototype = this.prototype
      const value = this.targetScore
      const flexible_path_setup = this.flexible_path_setup
    
      this.setTierTargetScore({ type_vintage, climate_zone, prototype, flexible_path_setup, value })
    },
    restoreDeletedMeasures(){
      if(this.isScoreAboveMaxAchievableAndMaxCostEffectiveAndNotEqual || this.isScoreAboveMaxAchievableAndBelowMaxCostEffective) {
        
        if(this.typeVintageId == null || this.climateZoneId == null || this.prototypeId == null) return
        
        const setup = {
          ...this.flexible_path_setup,
          tiers: this.flexible_path_setup.tiers.map(tier => {

            if (tier.climate_zone_id == this.climateZoneId && tier.prototype_id == this.prototypeId) {
              const tierClimateZoneRaw = this.$store.getters['globalEntities/ClimateZone/getterGlobalClimateZone']({ id: tier.climate_zone_id }).raw

              const preset = this.flexible_path_setup.cost_effective_presets.find(preset => {
                return preset.type_vintage_id == tier.type_vintage_id && preset.climate_zone_id == tier.climate_zone_id
              })?.preset || CUSTOM_COMBINATION_PRESETS.ALL_POSSIBLE_ON_BILL.value
              const presetMeasures = this.max_target_scores_by_presets.find(preset => {
                return preset.climate_zone_raw == tierClimateZoneRaw && preset.type_vintage_id == this.typeVintageId
              })
              
              const presetMeasuresIds = presetMeasures?.data?.[preset]?.map((m) => m.measure_id) || []

              return {
                ...tier,
                excluded_measures: tier.excluded_measures.filter(measure => measure.type_vintage_id !== this.typeVintageId || !presetMeasuresIds.includes(measure.measure_id)),
                mandatory_measures: tier.mandatory_measures.filter(measure => measure.type_vintage_id !== this.typeVintageId || !presetMeasuresIds.includes(measure.measure_id)),
              }
            } else {
              return tier
            }
          }),
        }
      
        if(this.userCanEditPolicy){
          const type_vintage = this.typeVintage
          const climate_zone = this.climate_zone
          const prototype = this.prototype
          const max_target_scores_by_presets = this.max_target_scores_by_presets
          const value = this.getMaxCostEffectiveScore({ climate_zone, prototype, type_vintage, max_target_scores_by_presets, flexible_path_setup: setup })
          
          this.setTierTargetScore({ type_vintage, climate_zone, prototype, flexible_path_setup: setup, value })

          this.closeModalWithoutChanges = false

          setTimeout(() => {
            this.closeModalWithoutChanges = null
          }, 1000)
        }
      }
    },
    goBack() {
      const type_vintage = this.typeVintage
      const climate_zone = this.climate_zone
      const prototype = this.prototype
      const value = this.lastTargetScoreInput
      const flexible_path_setup = this.flexible_path_setup

      this.setTierTargetScore({ type_vintage, climate_zone, prototype, flexible_path_setup, value })

      this.closeModalWithoutChanges = true

      setTimeout(() => {
        this.closeModalWithoutChanges = null
      }, 1000)

    },
  },
}
</script>

<style lang="scss" scoped>
  ::v-deep {
    .psui-el-dropdown-menu {
      width: 8.5rem;

      &-dialog-wrapper {
        margin-top: 4px;
      }

      &.is-open {
        .dropdown-expand {
          transform: rotate(180deg);
        }
      }
    }
  }

  .table-score {
    position: relative;

    &-target {
      padding-left: 56px;
    }

    &-chevron{
      position: absolute;
      top: 12px;
      left: 8px;
    }

    &-info-icon {
      position: absolute;
      top: 12px;
      left: 32px;
    }

    &-first-column{
      padding-left: 56px;
    }
  }
</style>
