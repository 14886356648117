<template>
  <ul class="app-navigation--main psui-mt-6 md:psui-mt-auto psui-mb-4">
    <li>
      <button 
        v-if="getUser"
        id="sidebar-my-account-button"
        data-test-id="sidebar-my-account-button"
        class="psui-flex psui-items-center w-full psui-space-x-2 psui-text-accentSmall psui-font-bold psui-text-blue-20 hover:psui-text-blue-50"
        :class="$route.name === 'UserAccount' ? 'router-link-active' : ''"
        @click="onClickYourAccount()"
      >
        <PsIcon
          icon="person"
          size="22"
          class="icon"
        />
        <span>MY ACCOUNT</span>
      </button>
      <PsButton
        v-else
        id="login-register-sidebar-button"
        data-test-id="login-register-sidebar-button"
        label="Login/register"
        layout="solid"
        size="medium"
        class="psui-ml-5 psui-mb-4"
        @click="onClickYourAccount()"
      />
      <button 
        v-if="!getUser"
        class="psui-flex psui-items-center w-full psui-space-x-2 psui-text-accentSmall psui-font-bold psui-text-blue-20 hover:psui-text-blue-50 md:psui-hidden"
        :class="$route.name === 'UserAccount' ? 'router-link-active' : ''"
        @click="onClickYourAccount()"
      >
        <PsIcon
          icon="login"
          size="22"
          class="icon"
        />
        <span>MY ACCOUNT</span>
      </button>
    </li>

    <li v-feature-flag="['PS_TEMPLATE_DESIGN']">
      <button 
        class="psui-flex psui-items-center psui-space-x-2 psui-text-accentSmall psui-font-bold psui-text-blue-20 hover:psui-text-blue-50"
        @click="onClickInfoHelp()"
      >
        <PsIcon
          icon="help"
          size="22"
          class="icon"
        />
        <span>INFO & HELP</span>
      </button>
    </li>

    <li v-feature-flag="['PS_TEMPLATE_DESIGN']">
      <button
        class="psui-flex psui-items-center psui-space-x-2 psui-text-accentSmall psui-font-bold psui-text-blue-20 hover:psui-text-blue-50"
        @click="onClickNextSteps()"
      >
        <PsIcon
          icon="map"
          size="22"
          class="icon"
        />
        <span>NEXT STEPS</span>
      </button>
    </li>

    <li
      v-if="getUser && $store.getters.getterLoggedUserType == 'admin'"
      v-feature-flag="['PS_TEMPLATE_DESIGN']"
    >
      <button 
        class="psui-flex psui-items-center psui-space-x-2 psui-text-accentSmall psui-font-bold psui-text-blue-20 hover:psui-text-blue-50"
        @click="onClickAdmin()"
      >
        <PsIcon
          icon="dashboard_customize"
          size="22"
          class="icon"
        />
        <span>ADMIN DASHBOARD</span>
      </button>
    </li>

    <li
      v-if="$store.getters['general/getterDeviceIsAdminOrTeam']"
      v-feature-flag="['PS_TEMPLATE_DESIGN']"
    >
      <button 
        class="psui-flex psui-items-center psui-space-x-2 psui-text-accentSmall psui-font-bold psui-text-blue-20 hover:psui-text-blue-50"
        @click="$eventBus.$emit('openQAModal')"
      >
        <PsIcon
          icon="build"
          size="22"
          class="icon"
        />
        <span>QA TICKETS</span>
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SidebarDefaultNavigationFooter',
  computed: {
    getUser() {
      return this.$store.state.auth.loggedUser
    }
  },
  methods: {
    onClickNextSteps() {
      this.$eventBus.$emit('openSidebarMenu', { menu : 'SidebarMenuNextSteps' })
    },
    onClickInfoHelp() {
      this.$eventBus.$emit('openSidebarMenu', { menu : 'SidebarMenuInfoHelp' })
    },
    onClickYourAccount() {
      if(!this.$store.state.general.redirectTo) this.$store.commit('general/setRedirectTo', { route: this.$route.fullPath })
      if(this.getUser) return this.$router.push({ name: 'UserAccount' })
      this.$router.push({ name: 'UserSignin' })
    },
    onClickAdmin() {
      this.$router.push('/admin')
    }
  }
}
</script>

<style lang="scss" scoped>

  ul {
    > li {
      width: 100%;
      white-space: nowrap;

      > a, button {
        padding: 7px 20px;
        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);

        span {
          display: none;

          @media (min-width: 768px) {
            display: block;
          }
        }
      }

      a, button {
        &.router-link-active
        &.router-link-exact-active,
        &.router-link-active {
          background-color: #003548;
          color: #64B5CE;
          cursor: pointer;

          .icon {
            color: #64B5CE;
          }
        }
      }
      
      ul {
        padding-left: 30px;
        padding-top: 12px;

        li {
          min-height: 21px;
          padding-bottom: 6px;
        }
      }
    }
  }

  ::v-deep #login-register-sidebar-button {
    display: none !important;
    @media (min-width: 768px) {
      display: flex !important;
    }
  }
</style>