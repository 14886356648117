import Store from '@/store'
import { BUILDING_ESTIMATES_BASE_YEARS } from '@/modules/app/assumptions/shared/enums.js'

export default (jurisdiction_building_estimates) => {

  const jurisdiction = Store.getters['lastJurisdictionVisited']
  const construction_implementation_types = Store.getters['globalEntities/ConstructionImplementationType/getterGlobalConstructionImplementationTypes']
  const type_prototypes = Store.getters['globalEntities/TypePrototype/getterGlobalTypePrototypes']()
  const building_heights = Store.getters['globalEntities/BuildingHeight/getterGlobalBuildingHeights']

  for(const climate_zone of jurisdiction.climate_zones) {
    for(const construction_implementation_type of construction_implementation_types) {
      for(const type_prototype of type_prototypes) {
        for(const building_height of building_heights) {
          for(const year of BUILDING_ESTIMATES_BASE_YEARS) {
            
            const item = {
              jurisdiction_id: jurisdiction.id,
              climate_zone_prefix: climate_zone.prefix,
              construction_implementation_type_id: construction_implementation_type.id,
              type_prototype_id: type_prototype.id,
              building_height_id: building_height.id,
              year
            }

            const building_estimate_exists = jurisdiction_building_estimates.findInArray(item)

            if(!building_estimate_exists) {
              jurisdiction_building_estimates.push({
                ...item, 
                units: 0, 
                base_building_stock_units: 0, 
                growth_rate: 0, 
                row_number: 0
              })
            }
          }
        }
      }
    }
  }

  return jurisdiction_building_estimates
}