import { formatNumberExtended } from './formatNumberExtended'
import { normalizeToZero } from './valueNormalizers'

export default (value, data) => {
  let formatted
  formatted = formatNumberExtended(parseFloat(value), 3, 3, normalizeToZero(-0.005))
  const emissions_savings_pct = parseFloat(data['emissions_savings_pct'])
  if (!isNaN(emissions_savings_pct)) {
    const percentage = formatNumberExtended(emissions_savings_pct * 100, 1, 1)
    formatted = `${formatted} (${percentage}%)`
  }
  return formatted
}
