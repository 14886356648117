<template>
  <div
    id="sidebar-container"
    :style="{ width: sidebarWith }"
    class="psui-absolute psui-top-0 -bg-pri psui-h-screen psui-flex psui-items-start z-10 psui-transition psui-delay-500"
    :class="{'is-opened' : sideMenuIsOpen}"
  >
    <div class="psui-flex psui-flex-col psui-w-full psui-h-full">
      <div class="psui-w-full psui-h-full psui-max-w-full psui-max-h-full psui-overflow-y-auto psui-overflow-x-hidden psui-flex">
        <div 
          :is="getSidebarContent" 
          :state-sidebar="sideMenuIsOpen"
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NewAppSideBar',
  props: {
    sidebarWith: String,
    sidebar: Object
  },
  data: () => ({
    sideMenuIsOpen: false,
    windowWidth: 0,
  }),
  computed: {    
    showSideMenu() {
      return (this.windowWidth > 768  || this.windowWidth < 768 && this.sideMenuIsOpen) ? true : false
    },
    getSidebarContent() {
      return this.sidebar?.content ?? false
    },
  },
  mounted() {
    this.windowWidth = window.innerWidth
    this.$eventBus.$on('toggleSideMenu', (open) => {
      this.sideMenuIsOpen = open
    })
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
      this.sideMenuIsOpen = false
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('toggleSideMenu')
  },
  methods: {
    toggleSidebar() {
      this.sideMenuIsOpen = !this.sideMenuIsOpen
    },
  }
}
</script>

<style lang="scss">
  #sidebar-container {
    transition: width 200ms cubic-bezier(.4,0,.2,1);
    background-color: #002A3A;

    &.is-opened {
      width: 210px !important;

      .app-navigation--main {
        span {
          display: block !important;
        }
      }
    }
  }
</style>