<template>
  <BaseModal
    ref="baseModal"
    title="Quality Assurance System"
    :disable-close="isLoading"
    modal-dialog-classes="max-w-md"
    :hierarchy="MODAL_HIERARCHY.SECONDARY"
  >
    <PsDialog
      v-if="!ticket.componentTreeStructure"
      theme="informative"
      layout="horizontal"
      :has-close="false"
      class="psui-mt-6"
    >
      <p>
        The ticked will be created under the card <a
          class="font-bold hover:underline"
          :href="`https://epcgo.atlassian.net/browse/${ getParentIssueId }`"
          target="_blank"
        >{{ getParentIssueId }}</a>
      </p>
      <p class="psui-mt-1">
        You can watch the <a
          class="font-bold hover:underline"
          :href="ticket.logrocket_session_url"
          target="_blank"
        >Log Rocket Session</a>
      </p>
    </PsDialog>

    <QualityAssuranceComponentRequest v-if="!ticket.componentTreeStructure" />

    <div
      v-else-if="!ticket.logrocket_session_url"
      class="w-full flex flex-col items-start space-y-4 mt-4"
    >
      <PsDialog
        theme="alert"
        layout="horizontal"
        :has-close="false"
      >
        <p>
          The Log Rocket Session was not automatically started, start the Log Rocket session and perform the behavior or action that causes the issue.
        </p>
      </PsDialog>
      <PsButton
        class="mt-2"
        label="Start Log Rocket manually"
        @click="startLogRocketManually()"
      />
    </div>

    <div
      v-else
      class="w-full flex flex-col mt-6 space-y-4"
    >
      <div class="w-full flex space-x-4">
        <PsChips
          v-for="ticketOption in ticketOptions"
          :key="ticketOption.value"
          type="radio"
          :label="ticketOption.label"
          :checked="ticket.ticketType == ticketOption.value"
          @click="ticket.ticketType = ticketOption.value"
          @change="ticket.ticketType = ticketOption.value"
        />
      </div>

      <div class="w-full flex flex-col">
        <InputWithLabel
          v-model="ticket.summary"
          label="Please, summarize the error in max 10 words"
        />
        <p
          v-if="ticket.ticketType"
          class="psui-pl-2 psui-pt-2 psui-italic psui-text-small"
        >
          {{ ticketOptions.findInArray({ value: ticket.ticketType }).description }}
        </p>
      </div>

      <div class="w-full flex psui-pt-4">
        <PsButton
          v-tooltip="getErrors.length ? getErrors[0] : false"
          :disabled="getErrors.length || isLoading ? true : false"
          :loading="isLoading"
          label="Generate ticket"
          @click="openJiraTicket()"
        />
      </div>
    </div>

    <div
      v-if="ticket.componentTreeStructure"
      v-check-env="'development'"
      class="psui-bg-red-10 rounded p-3 mt-4"
    >
      {{ ticket.componentTreeStructure }}
    </div>

    <PsDialog
      v-if="issuesCreated.length > 0"
      theme="success"
      layout="horizontal"
      :has-close="false"
      class="psui-mt-6"
    >
      <p class="psui-bold">
        Previous tickets created in this session:
      </p>
      <ul class="psui-mt-2">
        <li
          v-for="issueKey in issuesCreated"
          :key="issueKey"
        >
          <a
            :href="`${issuesBaseUrl}${issueKey}`"
            class="psui-block psui-text-red-20 hover:psui-text-blue-20"
            target="_blank"
          >
            {{ issueKey }}
          </a>
        </li>
      </ul>
    </PsDialog>
  </BaseModal>
</template>

<script>
import QualityAssuranceComponentRequest from './QualityAssuranceComponentRequest.vue'
import LogRocket from 'logrocket'
import { MODAL_HIERARCHY } from '@/util/Enums.js'


const initTicket = () => ({
  ticketType: null,
  summary: '',
  componentTreeStructure: '',
  logrocket_session_url: false,
  createWithoutLogRocketSession: false
})
export default {
  name: 'QualityAssuranceModal',
  components: { QualityAssuranceComponentRequest },
  data() {
    return {
      issuesBaseUrl: 'https://epcgo.atlassian.net/browse/',
      ticketOptions: [
        { value: 'Bug', label: 'Bug/Error', description: `Use when something went wrong or is broken on the app.` },
        { value: 'BL', label: 'Business Logic', description: `Use when when you didn't get the results that you were expecting.` },
        { value: 'Design', label: 'Design', description: `Use when something didn't match with the design resources.` },
        { value: 'UX', label: 'UX', description: `Use when some user experience should be changed or rethinked.` },
        { value: 'Copy/Content', label: 'Copy/Content', description: `Use when you want copy updates.` },
      ],
      ticket: initTicket(),
      issuesCreated: [],
      isLoading: false,
      MODAL_HIERARCHY,
      toastInstance: null

    }
  },
  computed: {
    getErrors() {
      const errors = []
      if(!this.ticket.ticketType) errors.push(`Select an issue type`)
      if(!this.ticket.componentTreeStructure) errors.push(`To help dev team find the issue, trigger the QA modal holding keyboard "Q" and click on issue element of the screen.`)
      if(!this.ticket.summary || this.ticket.summary.replaceAll('  ', ' ').split(' ').length < 2) errors.push(`Provide a summary with at least 2 words and less than 10`)
      return errors
    },

    getParentIssueId() {
      const regex = /ddev-[0-9]{3,}/g
      if (window.location.origin.match(regex)) {
        return window.location.origin.match(regex)[0].toUpperCase()
      }
      // DEFAULT Product Requests > APP - Bugs & Maintenance
      return 'DDEV-477'
    }
  },
  mounted() {
    this.$eventBus.$on('openQAModal', (componentTreeStructure = '') => {
      if (this.$refs.baseModal) {
        this.$refs.baseModal.open()
        this.ticket.componentTreeStructure = componentTreeStructure
        this.checkLogRocketSession()
        this.setIssueIdByURL()
      }
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openQAModal')
    this.$refs.baseModal.showModal = false
  },
  methods: {
    setIssueIdByURL(){
      const regex = /ddev-[0-9]{3,}/g
      if (window.location.origin.match(regex)) {
        return window.location.origin.match(regex)[0].toUpperCase()
      }
      return 'DDEV-477'
    },
    checkLogRocketSession(isManually = false) {
      LogRocket.getSessionURL(sessionURL => {
        this.ticket.logrocket_session_url = sessionURL
        if(isManually) {
          this.toastInstance.message =  `Log Rocket session started! Perform or highlight where the issue happens and open the QA modal again ¯\\_(ツ)_/¯`
          this.toastInstance.type =  'success'
          this.$refs.baseModal.showModal = false
          setTimeout(() => { this.toastInstance.dismiss() }, 5000)
        }
      })
    },

    openJiraTicket() {
      if (this.isLoading) return false

      this.isLoading = true

      const url = 'https://a4030bd9-7fda-4b29-b92a-a8169df6a480.hello.atlassian-dev.net/x1/g41PIXdvkJbdpW43MwgRrGgnWGU'

      const user_device = this.$store.getters['general/getterAdminOrTeamDeviceAndUser']
      const body = {
        summary: `${this.ticket.ticketType} __ ${this.ticket.summary.replace(/"/g, "'")}`,
        projectId: '10011', // https://confluence.atlassian.com/jirakb/how-to-get-project-id-from-the-jira-user-interface-827341414.html
        issueType: '10011', // You can find the ID editing the issue here https://epcgo.atlassian.net/jira/settings/issues/issue-types
        epicSlug: this.getParentIssueId,
        description: [
          { key: 'SOURCE_LOGROCKET_URL', value: this.ticket.logrocket_session_url },
          { key: 'SOURCE_COMPONENT_TREE', value: this.ticket.componentTreeStructure },
          { key: 'SOURCE_EXPLORER_URL', value: window.location.href },
          { key: 'SOURCE_EXPLORER_USER', value: user_device?.user?.email ?? false },
          { key: 'SOURCE_EXPLORER_VERSION_APP', value: this.$store.getters['general/getterAppVersion'] },
          { key: 'SOURCE_EXPLORER_VERSION_APP_VUEX', value: this.$store.getters['general/getterAppVuexVersion'] },
          { key: 'SOURCE_EXPLORER_VERSION_API', value: 'not-available' },
        ],
        user_device_email: user_device?.user?.email ?? false,
      }

      this.$api.post('/api/admin/jira_proxy/store', { url, body })
          .then((response) => {
            const issueKey = response.data.data.key
            if (!issueKey) {
              this.$appToast({type:'error', message:
                    `Something went wrong while creating the new QA card. Probably you're trying to create cards inside a test only (non-qa) link. If not, please contact devs.`  })
              return
            }
            if (LogRocket._isInitialized) {
              LogRocket.track('JiraQaCreated', body)
              LogRocket.startNewSession()
            }
            this.$appToast({ message: `Ticket has been created`, type: 'success', duration: 3000 })
            this.issuesCreated.push(issueKey)
            this.isLoading = false
            this.sendSlackMessage({ ...this.ticket }, issueKey)
            this.$set(this, 'ticket', initTicket())
          })
          .catch((err) => {
            console.log(err)
            if (this.ticket.logrocket_session_url) {
              alert(`Something went wrong, please send this link to devs: ${this.ticket.logrocket_session_url}`)
            } else {
              alert(`Something went wrong`)
            }
            this.isLoading = false
          })
    },

    sendSlackMessage(ticket, key) {

      const message = this.ticket.summary + ` \n Check the issue on: ${this.issuesBaseUrl}${key}`
      const data = {
        channel: 'qa-notifications',
        title: `New QA ticket created on ${this.getParentIssueId}`,
        message: message,
      }
      this.$api.post(`/api/admin/slack_proxy/send_message`, data)
    },

    startLogRocketManually() {
      this.$eventBus.$emit('initLogRocketManually')
      this.checkLogRocketSession(true)
      this.toastInstance = this.$appToast({ type: 'info', message: `Starting Log Rocket Session, you be notified when it's ready.`, duration: 0 })
    }
  }
}
</script>
