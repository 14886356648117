/**
 * 
 */

export default ({ currentQuarterDate, assumption, installation_effects_years }) => {

  let baselineInstallationsStartedPercentage = 100
  let baselineInstallationsCompletedPercentage = 100
  
  const thirds = assumption?.getHelpers({years: installation_effects_years})
  const first_third_end_at = thirds?.first_third_end_at || assumption.first_third_end_at
  const second_third_end_at = thirds?.second_third_end_at || assumption.second_third_end_at

  if(currentQuarterDate.isBefore(first_third_end_at)) {
    baselineInstallationsStartedPercentage = assumption.likelihood_installations_first_third
  }
  if(currentQuarterDate.isSameOrAfter(assumption.start_at_date.add(assumption.delay_installation, 'years')) &&  currentQuarterDate.isBefore(first_third_end_at.add(assumption.delay_installation, 'years'))) {
    baselineInstallationsCompletedPercentage = assumption.likelihood_installations_first_third
  }

  // Baseline second third
  if(currentQuarterDate.isSameOrAfter(first_third_end_at) && currentQuarterDate.isBefore(second_third_end_at)) {
    baselineInstallationsStartedPercentage = assumption.likelihood_installations_second_third
  }
  if(currentQuarterDate.isSameOrAfter(first_third_end_at.add(assumption.delay_installation, 'years')) && currentQuarterDate.isBefore(second_third_end_at.add(assumption.delay_installation, 'years'))) {
    baselineInstallationsCompletedPercentage = assumption.likelihood_installations_second_third
  }

   // Baseline second third
   if(currentQuarterDate.isSameOrAfter(second_third_end_at)) {
    baselineInstallationsStartedPercentage = assumption.likelihood_installations_final_third
  }
  if(currentQuarterDate.isSameOrAfter(second_third_end_at.add(assumption.delay_installation, 'years'))) {
    baselineInstallationsCompletedPercentage = assumption.likelihood_installations_final_third
  }

  return {
    baselineInstallationsStartedPercentage,
    baselineInstallationsCompletedPercentage
  }
}
