import { checkIfPolicyIsOutdated } from '@/util/Helpers'

export default {
  computed: {
    getPolicySelectedId() {
      return this.$route.params.policy_id || null
    },
    getPolicySelected() {
      return this.getPolicySelectedId ? this.$store.getters['policy/getterUserPolicy']({id: this.getPolicySelectedId}) : null
    },
    isPolicyAssumption() {
      return !!this.getPolicySelectedId
    },
  },
  methods: {
    checkIfUserCanEditAssumption() {
      if (!this.isPolicyAssumption) return true
      const userCanEditPolicy =  this.$store.getters['assumptions/getterUserCanEditTheSelectedPolicy']
      if (!userCanEditPolicy) {
        const outdatedInfo = checkIfPolicyIsOutdated(this.getPolicySelected)
        const isOutdatedModalBlocker = Boolean(outdatedInfo.has_outdated_studies === true && outdatedInfo.is_loading === false && !outdatedInfo.block_policy)
        this.$eventBus.$emit('openPolicyUserPermissionDuplicateAlertModal', {
          policy: this.getPolicySelected,
          disableUserLoginCall2Action: isOutdatedModalBlocker,
          disableBtns: isOutdatedModalBlocker,
          customTitle: isOutdatedModalBlocker ? `You can't edit this policy assumptions` : undefined,
          customDescription: isOutdatedModalBlocker ? `This policy is read-only since it is based on an outdated policy architecture and cost-effectiveness study results. Please create a new policy to continue policy development.` : undefined,
        })
      }
      return userCanEditPolicy
    },
  }
}