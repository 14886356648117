import { render, staticRenderFns } from "./TutorialItem.vue?vue&type=template&id=08d12a34&scoped=true"
import script from "./TutorialItem.vue?vue&type=script&lang=js"
export * from "./TutorialItem.vue?vue&type=script&lang=js"
import style0 from "./TutorialItem.vue?vue&type=style&index=0&id=08d12a34&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08d12a34",
  null
  
)

export default component.exports