import BaseModel from './BaseModel'
import { getYearsBetweenDates } from '@/util/DateFunctions'

export default class CodeCycle extends BaseModel {
  constructor(obj = {}) {
    super(obj)

    this.id = obj.id || null
    this.title = obj.title || ''
    this.slug = obj.slug || ''
    this.start_at = obj.start_at || false
    this.end_at = obj.end_at || false
  }

  get isCurrent() {
    return this.slug == '2022' ? true : false
  }
  
  get years() {
    return getYearsBetweenDates(this.start_at, this.end_at)
  }
}