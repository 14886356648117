<template>
  <div class="psui-w-full psui-h-full psui-flex psui-flex-col psui-justify-start psui-items-start psui-pb-4 psui-overflow-y-auto">
    <router-link
      v-feature-flag="['PS_TEMPLATE_DESIGN']"
      :to="{ name: 'AppHome', query: { utm_source : 'explorer-sidebar' }}"
      class="psui-p-3 md:psui-p-6 psui-inline-block"
    >
      <img
        src="/logo/cee-mobile.svg"
        width="32"
        alt="Cost Effectiveness Explorer"
        class="psui-mx-auto"
        :class="sideMenuIsOpen ? 'psui-hidden' : 'md:psui-hidden'"
      >
      <img
        src="/logo/cee.svg"
        width="150"
        alt="Cost Effectiveness Explorer"
        :class="sideMenuIsOpen ? 'psui-block' : 'psui-hidden  md:psui-block'"
      >
    </router-link>
     
    <div
      v-else-feature-flag
      class="psui-p-3 md:psui-p-6 psui-inline-block"
    >
      <img
        src="/logo/IECC_LOGO_MOBILE.svg"
        width="32"
        alt="Policy Studio Labs"
        class="psui-mx-auto"
        :class="sideMenuIsOpen ? 'psui-hidden' : 'md:psui-hidden'"
      >
      <img
        src="/logo/IECC_LOGO.svg"
        width="128"
        alt="Policy Studio Labs"
        :class="sideMenuIsOpen ? 'psui-block' : 'psui-hidden  md:psui-block'"
      >
    </div>

    <div class="psui-p-4 psui-flex psui-items-center psui-justify-center md:psui-hidden">
      <button
        aria-label="Menu"
        class="psui-flex psui-cursor-pointer focus:psui-outline-none"
        @click="toggleSidebar()"
      >
        <PsIcon 
          :icon="sideMenuIsOpen ? 'close' : 'menu'"
          size="24"
          icon-classes="arrow-next psui-text-blue-20 psui-transition"
          display="flex"
        />
      </button>
    </div>

    <router-link
      v-feature-flag="['PS_TEMPLATE_DESIGN']"
      :to="{ name: 'AppHome' }"
      :class="$route.name === 'AppHome' ? 'router-link-exact-active' : ''"
      class="home-link psui-w-full psui-flex psui-items-center psui-space-x-2 psui-transition psui-duration-300 psui-ease-in-out psui-text-blue-20 hover:psui-text-blue-50"
    >
      <PsIcon
        icon="home"
        size="22"
        class="icon"
        display="flex"
      />
      <span :class="sideMenuIsOpen ? 'psui-block' : 'psui-hidden'">Home</span>
    </router-link>

    <SidebarDefaultNavigationMain />

    <SidebarDefaultNavigationFooter />
    <StateProgramReferralText :state-sidebar="stateSidebar" />

    <DrawerFaq />
    <DrawerChangelog />
  </div>
</template>

<script>

import DrawerChangelog from './DrawerChangelog.vue'
import SidebarDefaultNavigationFooter from './SidebarDefaultNavigationFooter.vue'
import SidebarDefaultNavigationMain from './SidebarDefaultNavigationMain.vue'
import DrawerFaq from './DrawerFaq.vue'
import { isFeatureFlagEnabled } from '@/util/Functions.js'

export default {
  name: 'SidebarDefault',
  components: { 
    DrawerFaq, 
    SidebarDefaultNavigationMain, 
    SidebarDefaultNavigationFooter, 
    DrawerChangelog
  },
  props: {
    stateSidebar: Boolean,
  },
  data: () => ({
    sideMenuIsOpen: false,
    isFeatureFlagEnabled,    
  }),
  methods: {
    toggleSidebar() {
      this.sideMenuIsOpen = !this.sideMenuIsOpen
      this.$eventBus.$emit('toggleSideMenu', this.sideMenuIsOpen)
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  padding: 8.5px 0px 8.5px 20px;
}

.home-link.router-link-exact-active {
  font-weight: bold;
  background-color: #003548;
  color: #64B5CE;
  .icon {
    color: #64B5CE;
  }
}

.home-link {
  padding: 7.5px 20px;

  span {
    @media (min-width: 768px) {
      display: block;
    }
  }
}
</style>