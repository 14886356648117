<template>
  <BaseModal
    ref="baseModal"
    :title="title"
    :hierarchy="MODAL_HIERARCHY.SECONDARY"
    :disable-close="slug === 'outdated_data' && $route.name === 'PolicyShow'"
    @close="reInitValues()"
  >
    <div class="w-full">
      <div
        v-if="isLoading"
        class="w-full flex justify-start my-6"
      >
        <AppLoader />
      </div>
      <div
        v-else-if="!isLoading && hasError"
        class="w-full flex flex-col items-start space-y-4"
      >
        <h4 class="text-header-5">
          Oops!
        </h4>
        <p class="text-p-2">
          We can’t find this resource right now. Send us an <a
            class="psui-text-blue-60 font-bold"
            href="mailto:explorer@localenergycodes.com"
          >email</a> for help.
        </p>
        <p v-if="$store.getters.getterLoggedUserIsAdmin">
          <b>{{ getKeyValue.key }}: {{ getKeyValue.value }}</b>
        </p>
        <PsButton
          v-if="$store.getters.getterLoggedUserIsAdmin"
          label="Add new content on admin"
          class="mt-7"
          @click="openAdminCreateHelper()"
        />
      </div>
      <div
        v-else
        class="mt-4"
      >
        <RenderHtmlExtended
          class="description"
          :html="description"
        />
        
        <div class="psui-flex psui-items-center psui-mt-6 psui-space-x-1">
          <PsButton
            label="Got it"
            size="big"
            @click="close"
          />
          <a
            v-if="customButtonUrl"
            :href="customButtonUrl"
            target="_blank"
          >
            <PsButton
              :label="customButtonTitle"
              layout="onlytext"
              size="big"
              icon="open_in_new"
              icon-position="right"
            />  
          </a>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import RenderHtmlExtended from '../../../components/general/RenderHtmlExtended.vue'
import { MODAL_HIERARCHY } from '@/util/Enums.js'
import { CommonHelpers } from "@igortrindade/lazyfy"

export default {
  name: 'MainBenefitsModal',
  components: { RenderHtmlExtended },
  data() {
    return {
      isLoading: true,
      hasError: false,
      title: '',
      description: '',
      content: '',
      type: '',
      MODAL_HIERARCHY,
      id: null,
      slug: null,
      studySource: null,
      learnMoreButton: false,
      customButtonUrl: null,
      customButtonTitle: null,
    }
  },
  computed: {
    getKeyValue() {
      if(this.slug) return { key: 'slug', value: this.slug }
      return { key: 'id', value: this.id }
    }
  },
  mounted() {
    this.$eventBus.$on('openMainBenefitsModal', ({ type, id, slug, button}) => {
      this.customButtonTitle = button?.title
      this.customButtonUrl = button?.url
      this.isLoading = true
      this.hasError = false
      this.$refs.baseModal.showModal = true
      this.getModalDescription({ type, id, slug })
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openMainBenefitsModal')
  },
  methods: {
    reInitValues() {
      Object.assign(this.$data, this.$options.data())
    },
    close() {
      this.$refs.baseModal.showModal = false
      this.isLoading = true
      setTimeout(() => {
        this.title = ''
        this.description = ''
        this.content = ''
      }, 500)
    },

    async getModalDescription({ type, id = null, slug = null }) {
      if(type === 'prototype' && this.$route.name === 'PolicyOptions'){
        this.getStudySourceTitle(id)
      }
      this.$refs.baseModal.showModal = true
      this.type = type
      this.id = id
      this.slug = slug

      let where = ''
      if (slug) where += `${!where ? `where` : `orWhere`}: [{column: "slug", operation: "=", value: "${this.slug}"}]`
      if (id) where += `${!where ? `where` : `orWhere`}: [{column: "id", operation: "=", value: "${this.id}"}]`

      const query = `
        {
          ${type} (
            ${where}
          ) {
            id
            title
            description
            content
            ${type !== 'study' ? `slug` : ``}
          }
        }
      `

      const { data } = await this.$graphql({ query })
      this.isLoading = false

      if (!data[type]) {
        this.hasError = true
      } else {
        const dataType = Array.isArray(data[type]) ? data[type] : [data[type]]
  
        const dataBySlug = dataType?.find((item) => {
          return item?.slug === this.slug
        })
  
        const dataById = dataType?.find((item) => {
          return item?.id === this.id
        })
  
        const helper = dataBySlug || dataById || null
        this.title = helper?.title
        this.description = helper?.description
        this.content = helper?.content
      }

    },

    openDrawerContent() {
      this.close()
      const payload = { type: this.type, id: this.id, slug: this.slug }
      this.$eventBus.$emit('openDrawerContent', payload)
    },

    getStudySourceTitle(prototype_id) {
      const prototype = this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: prototype_id })
      const study = this.$store.getters['globalEntities/Study/getterGlobalStudy']({ id: prototype.study_id })
      this.studySource = study
    },
    
    openStudySourceInfo(study_id){
      this.close()
      this.$eventBus.$emit('openDrawerContent', { type: 'study', id: study_id })
    },

    openAdminCreateHelper() {
      CommonHelpers.copyToClipboard(this.getKeyValue.value)
      this.$appToast({ message: `The ${ this.getKeyValue.key }: ${ this.getKeyValue.value } was copied to your cpliboard, please add a new helper using it.`, type: 'info' })
      this.$router.push('/admin/helpers/create')
    }, 

    goToPolicyOptions(){
      this.close()
      this.$router.push({ name: 'PolicyOptions' })
    }
  },
}
</script>

<style lang="scss" scoped>
.description ::v-deep {
  font-size: 16px;
  line-height: 20px;
  color: app-color(gray04);

  *:not(a):not(.psui-el-dialog *) {
    color: app-color(gray04) !important;
    font-size: 16px;
    line-height: 20px;
  }

  .psui-el-dialog.theme-informative {
    color: #318fac !important;
    margin-bottom: 12px;
  }
}
</style>
