<template>
  <div class="w-full h-full flex flex-col justify-between pl-20 pr-10 py-10">
    <router-link
      v-feature-flag="['PS_TEMPLATE_DESIGN']"
      to="/"
      class="w-full text-center mb-6 inline-block"
    >
      <img
        src="/logo/cee.svg"
        width="180px"
        alt="Cost Effectiveness Explorer"
      >
    </router-link>
    
    <router-link
      v-else-feature-flag
      :to="getStudyResultsRoute({ jurisdiction_slug: null, queryRoute })"
      class="w-full text-center mb-6 inline-block"
    >
      <img
        src="/logo/IECC_LOGO.svg"
        width="180px"
        alt="Policy Studio Labs"
      >
    </router-link>

    <div class="w-full h-3/4 flex items-center">
      <h2 class="text-bigtitle-3 font-bold text-white">
        Sign in or register to create custom combinations of measures and more!
      </h2>
    </div>

    <LocalEnergyLogo class="mb-3" />
  </div>
</template>

<script>
import { isFeatureFlagEnabled } from '@/util/Functions.js'
import { mapGetters } from 'vuex'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'

export default {
  data: function() {
    return {
      show: true,
      isFeatureFlagEnabled,
    }    
  },
  computed: {
    queryRoute(){
      return !this.$route.query.only_study_type && !this.lastStudyTypeVisited ? STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS : this.lastStudyTypeVisited
    },
    ...mapGetters(['lastStudyTypeVisited']),
  }
}
</script>

<style lang="scss" scoped>  

#sidebar--welcome {
  display: flex;
  flex-direction: column;
  padding-left: 160px;
  padding-right: 75px;

  > * {
    flex-shrink: 0;
  }

	.my-auto {
		margin-top: auto;
		margin-bottom: auto;
  }
}

</style>