

export const getSubsidyColumnsValues = ({ 
  subsidy_needed,
  five_year_payback,
  subsidy_needed_care,
  five_year_payback_care, 
  units, 
  care_program_enrollment_rate = 12, 
}) => {

  care_program_enrollment_rate = care_program_enrollment_rate / 100
  
  const city_wide_subsidy_needed = (units * ( 1 - care_program_enrollment_rate )) * subsidy_needed
  const city_wide_five_year_payback = (units * ( 1 - care_program_enrollment_rate )) * five_year_payback
  const city_wide_subsidy_needed_care = (units * care_program_enrollment_rate) * subsidy_needed_care
  const city_wide_five_year_payback_care = (units * care_program_enrollment_rate) * five_year_payback_care

  return {
    city_wide_subsidy_needed,
    city_wide_five_year_payback,
    city_wide_subsidy_needed_care,
    city_wide_five_year_payback_care,
  }
}