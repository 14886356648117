<template>
  <div>
    <div class="flex justify-between items-center psui-text-small">
      <div class="flexible-wrapper flex space-x-1 items-center w-full">
        <span class="psui-text-blue-80 psui-font-bold">Required flexible score</span>
        <PsIcon
          size="16"
          display="flex"
          class="flexible-icon ml-auto psui-text-gray-40 cursor-pointer hover:psui-text-blue-60"
          icon="info_outline"
          @click.native="$eventBus.$emit('openDescriptionModal', { type: 'helper' , slug: 'require_flexible_score' })"
        />
      </div>
      <div
        v-if="isTargetScoreGreaterThanMaxTargetScore"
        v-tooltip="'The required flexible score cannot be achieved cost-effectively because certain measures are mandatory or have been removed.'"
        class="highlight-icon"
      >
        <div class="icon" />
      </div>
    </div>
    <div
      v-if="maxTargetScore >= 0"
      v-tooltip="inputTooltip"
      class="w-full flex items-center psui-border psui-justify-between psui-border-gray-30 transition-all rounded-sm mt-1 h-8"
      :class="[
        getTargetScoreHasError ? 
          'bg-candy-200 border border-candy-500' : 
          'psui-border-gray-30',
        inputFocus ? 'psui-border-green-70' : '',
        {'hover:psui-border-green-20' : !getTargetScoreHasError && userCanEditPolicy},
      ]"
    > 
      <input 
        ref="input" 
        v-model="targetScoreInput"
        v-mask="['#', '##', '###']"
        :min="1"
        type="tel" 
        class="bg-transparent text-header-2 h-full psui-pl-2 z-10 placeholder-deepsky-450 target-score-input flex psui-text-gray-50 w-full"
        :class="{'caret-candy-500 text-candy-500' : getTargetScoreHasError }"
        :disabled="!userCanEditPolicy"
        @change="checkTargetScore()"
        @input="onInputChange(type_vintage.id, climate_zone.id, prototype.id)"
        @focus="onInputFocus"
        @blur="onInputBlur"
        @keyup.enter="$refs.input.blur()"
      >
      <div class="flex flex-col mr-1">
        <PsIcon
          size="14"
          class="ml-auto psui-text-gray-50 cursor-pointer"
          display="flex"
          icon="expand_less"
          @click.native="setTargetScore('increment')"
        />
        <PsIcon
          size="14"
          display="flex"
          class="ml-auto psui-text-gray-50 cursor-pointer"
          icon="expand_more"
          @click.native="setTargetScore('decrement')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getMaxCostEffectiveScore, getMaxPossibleScore, getTierTargetScore, setTierTargetScore } from '@/services/measure-menu/MeasureMenuGeneralService'
import { defaultManualTooltipOptions } from '@/util/Helpers'
import FlexiblePathService from '@/services/api/FlexiblePathService'

export default {
  name: 'PolicyExistingBuildingsRequirementsFlexibleTargetScoreInput',
  props: [
    'policy',
    'prototype',
    'climate_zone',
    'type_vintage',
    'type_vintage_index',
    'flexible_path_setup',
    'max_target_scores_by_presets',
    'close_modal_without_changes',
    'tier'
  ],
  data() {
    return {
      targetScoreInput: 0,
      lastTargetScoreInput: [Number, String],
      inputFocus: false,
      inputTooltip: {
        ...defaultManualTooltipOptions,
        placement: 'left-center',
        show: false,
      },
      flexiblePathService: new FlexiblePathService({ policy_id : this.$route.params.policy_id }),
      typeVintageId: null,
      climateZoneId: null,
      prototypeId: null,
      modalOpenTimeout: null,
    }
  },
  computed: {
    getTargetScoreHasError() {
      return this.maxTargetScore && this.maxTargetScore > 0 && (!this.targetScoreInput || this.targetScoreInput < 1)
    },
    maxTargetScore() {
      return Math.min(this.maxCostEffectiveScore, this.maxPossibleScore)
    },
    maxCostEffectiveScore() {
      const { climate_zone, prototype, type_vintage, max_target_scores_by_presets, flexible_path_setup } = this
      return this.getMaxCostEffectiveScore({ climate_zone, prototype, type_vintage, max_target_scores_by_presets, flexible_path_setup })
    }, 
    maxPossibleScore() {
      const { climate_zone, type_vintage, max_target_scores_by_presets, type_vintage_index, prototype, flexible_path_setup } = this
      return this.getMaxPossibleScore({ climate_zone, type_vintage, max_target_scores_by_presets, type_vintage_index, prototype, flexible_path_setup })
    },
    shouldShowTooltip() {
      return Boolean(this.getTargetScoreHasError)
    },
    userCanEditPolicy() {
      return this.$store.getters['policy/getterUserCanEditPolicy'](this.policy.id)
    },
    isTargetScoreGreaterThanMaxTargetScore() {
      return this.targetScoreInput > this.maxTargetScore
    },
    getTierTargetScoreRaw(){
      return this.getTierTargetScore({ tier:this.tier, prototype:this.prototype, flexible_path_setup:this.flexible_path_setup, type_vintage:this.type_vintage, climate_zone:this.climate_zone, max_target_scores_by_presets:this.max_target_scores_by_presets })
    },
    isScoreAboveMaxAchievableAndBelowMaxCostEffective() {
      return this.targetScoreInput > this.maxPossibleScore && this.targetScoreInput < this.maxCostEffectiveScore
    },
    isScoreAboveMaxAchievableAndMaxCostEffectiveAndEqual() {
      return this.targetScoreInput > this.maxPossibleScore && this.targetScoreInput > this.maxCostEffectiveScore && +this.maxCostEffectiveScore === +this.maxPossibleScore
    },
    isScoreAboveMaxAchievableAndMaxCostEffectiveAndNotEqual() {
      return this.targetScoreInput > this.maxPossibleScore && this.targetScoreInput > this.maxCostEffectiveScore && +this.maxCostEffectiveScore !== +this.maxPossibleScore
    },
  },
  watch: {
    getTierTargetScoreRaw(value){
      this.targetScoreInput = value
    },
    inputFocus(focus) {
      this.inputTooltip.show = Boolean(focus && this.shouldShowTooltip)
    },
    targetScoreInput(value) {
      this.toggleInputTooltip()
      // Small workaround to define if the number has been changed by the user or at created()
      const isUserChanges = typeof value === 'string'
      if (!isUserChanges) {
        this.lastTargetScoreInput = value
      }      
    },
    close_modal_without_changes(value) {
      if(value == true) {
        this.targetScoreInput = this.lastTargetScoreInput
      } else if (value == false) {
        this.targetScoreInput = this.getTierTargetScore({ tier: this.tier, prototype: this.prototype, flexible_path_setup: this.flexible_path_setup, type_vintage: this.type_vintage, climate_zone: this.climate_zone, max_target_scores_by_presets: this.max_target_scores_by_presets })
      }
    },
  },
  created() {
    const { tier, type_vintage, prototype, flexible_path_setup, climate_zone, max_target_scores_by_presets } = this    
    const targetScoreInput = this.getTierTargetScore({ tier, prototype, flexible_path_setup, type_vintage, climate_zone, max_target_scores_by_presets })  
    this.targetScoreInput = targetScoreInput
    this.lastTargetScoreInput = targetScoreInput
  },
  beforeDestroy() {
    this.$eventBus.$off('openDebugVariablesModal')
  },
  methods: {
    getMaxCostEffectiveScore,
    getMaxPossibleScore,
    getTierTargetScore,
    setTierTargetScore,
    onInputBlur() {
      if (this.getTargetScoreHasError) {
        this.targetScoreInput = this.lastTargetScoreInput
      }
      // Workaround to onIconClick works
      setTimeout(() => {
        this.inputFocus = false
      },200)
    },
    onInputFocus() {
      this.inputFocus = true     
    },
    onIconClick() {
      !this.inputFocus ? this.$refs.input.focus() : this.$refs.input.blur()
    },
    setTargetScore(type) {
      const value = type === 'increment' ? +this.targetScoreInput + 1 : +this.targetScoreInput - 1
      if (value < 0) {
        return
      }

      this.lastTargetScoreInput = this.targetScoreInput
      this.targetScoreInput = value.toString() // it must be a string to avoid watcher above to trigger changing lastTargetScore
      this.checkTargetScore(800)
    },
    checkTargetScore(modalDebounce) {
      if(isNaN(this.targetScoreInput)) {
        console.error(`Target score isn't a valid number: "${this.targetScoreInput}" typeof(${typeof(this.targetScoreInput)})`)
        this.targetScoreInput = 0
      }

      if(this.getTargetScoreHasError) {
        this.targetScoreInput = this.lastTargetScoreInput
        return
      }

      if(this.isScoreAboveMaxAchievableAndBelowMaxCostEffective || this.isScoreAboveMaxAchievableAndMaxCostEffectiveAndEqual || this.isScoreAboveMaxAchievableAndMaxCostEffectiveAndNotEqual) {
        this.openModal(modalDebounce)
        return
      }

      const { type_vintage, climate_zone, prototype, type_vintage_index, flexible_path_setup, targetScoreInput : value } = this
      this.setTierTargetScore({ type_vintage, climate_zone, prototype, type_vintage_index, flexible_path_setup, value })
      this.lastTargetScoreInput = this.targetScoreInput
    },
    toggleInputTooltip() {
      if (this.maxTargetScore && this.maxTargetScore > 0 && (+this.targetScoreInput === 0 || !this.targetScoreInput)) {
        this.inputTooltip.content = `Choose a value of 1 or greater, or toggle off your required flexible score`
        this.inputTooltip.show = true
      } else {
        this.inputTooltip.show = false
      }
    },
    openModal(debounceTime=400) {
      if (this.modalOpenTimeout) {
        clearTimeout(this.modalOpenTimeout)
      }

      this.modalOpenTimeout = setTimeout(() => {
        const targetScoreInput = this.targetScoreInput
        const maxCostEffectiveScore = this.maxCostEffectiveScore
        const maxPossibleScore = this.maxPossibleScore
        const typeVintageId = this.typeVintageId
        const climateZoneId = this.climateZoneId
        const prototypeId = this.prototypeId
        const typeVintage = this.type_vintage
        const lastTargetScoreInput = this.lastTargetScoreInput

        const payload = {
          targetScoreInput,
          maxPossibleScore,
          maxCostEffectiveScore,
          typeVintageId,
          climateZoneId,
          prototypeId,
          typeVintage,
          lastTargetScoreInput
        }

        this.$emit('openPolicyFlexiblePathScoreWarningModal', payload)
      }, debounceTime)
    },
    onInputChange(typeVintageId, climateZoneId, prototypeId) {
      
      this.typeVintageId = typeVintageId
      this.climateZoneId = climateZoneId
      this.prototypeId = prototypeId
    },
  }
}
</script>

<style>

  .target-score-input {
    transition: all .5s;
  }
  
  .caret-candy-500 {
    caret-color:  #D65C5A !important;
  }

</style>

<style lang="scss" scoped>
  .highlight-icon {
    position: static;
    box-sizing: border-box;
    margin-left: 0.75rem;

    .icon {
      border-radius: 9999px;
      width: 8px;
      height: 8px;
      position: relative;
      background-color: #EDAB3E;        
      border: 1px solid #E0EFF6;

      &::before {
        border-radius: 9999px;
        position: absolute;
        transform-origin: center;
        content: '';
        z-index: -1;
      }
    }
  }
  .flexible-wrapper {
    .flexible-icon{
      visibility: hidden;
    }

    &:hover {
      .flexible-icon{
        visibility:visible;
      } 
    }
  }

</style>
