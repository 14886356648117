import GraphQL from '@/util/GraphQL'

export default {
  namespaced: true,
  
  state: {
    code_cycles: []
  },

  getters: {
    getterGlobalCodeCycles: (state) => (filters = null) => {
      return filters ? state.code_cycles.findAllInArray(filters) : state.code_cycles
    },

    getterGlobalCodeCycle: (state) => (filters = null) => {
      return filters ? state.code_cycles.findInArray(filters) : false
    },
  },

  actions: {
    loadGlobalCodeCycles(context) {
      const query = `
        {
          code_cycles {
            id
            title
            slug
            start_at
            end_at
          }
        }
      `
      GraphQL({ query })
        .then(({ data }) => {
          context.commit('setGlobalCodeCycles', { code_cycles: data.code_cycles})
        })
    }
  },

  mutations: {
    setGlobalCodeCycles(state, { code_cycles }) {
      state.code_cycles = code_cycles
    }
  }
}