<template>
  <div
    v-if="open"
    class="confirm-popover"
    :class="[getComponentClass,{left: side === 'left'}]"
    :style="{ top: top + 'px', left: left + 'px', 'z-index': (open && top && left) ? '51' : '-1', 'max-width': (maxWidth) ? maxWidth + 'px' : '300px' }"
    @mouseleave="checkNeedToClose"
  >
    <span v-if="!textAsHtml">{{ text }}</span>
    <span
      v-else
      v-html="text"
    />
    <a
      v-if="learnMoreLink && buttons && buttons.length > 1"
      class="ml-1 mt-0 hover:opacity-50 underline"
      :href="learnMoreLink"
      target="_blank"
    >Learn more here.</a>
    <div
      class="actions space-x-2"
      :class="{'mb-0': disableFooter}"
    >
      <div
        v-if="buttons && buttons.length"
        class="buttons-container"
      >
        <button
          v-for="(button, index) in buttons"
          :key="`btn:${index}`"
          class="button"
          @click="confirmPopoverAction(index)"
        >
          {{ button }}
        </button>
      </div>
      <a
        v-if="learnMoreLink && buttons && buttons.length === 1"
        class="mt-0 hover:opacity-50"
        :href="learnMoreLink"
        target="_blank"
      >
        Learn More
      </a>
    </div>

    <div
      v-if="!disableFooter"
      class="actions divisor flex items-center"
    >
      <Checkbox
        v-if="neverAskProp"
        class="border border-deepsky-200 px-2 rounded-sm mr-2 h-8 font-normal flex"
        label="Don't show this again"
        :value="setNeverAskProp"
        checkbox-classes="mb-0 flex items-center"
        type="checkbox"
        @input="setNeverAskProp = !setNeverAskProp"
      />
      <div v-else />
      <button
        class="font-bold  mr-3 hover:opacity-50"
        @click="closeConfirmPopover()"
      >
        Cancel
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ConfirmPopover',
  data() {
    return {
      targetElem: null,
      open: false,
      setNeverAskProp: false,
      neverAskProp: null,
      text: null,
      buttons: null,
      learnMoreLink: null,
      onConfirmCallback: null,
      onCloseCallback: null,
      top: 0,
      left: 0,
      side: 'right',
      sumTop: 0,
      sumLeft: 0,
      layout: 'standard',
      guaranteeScrollElem: null,
      disableFooter: false,
      maxWidth: null,
      textAsHtml: false,
      shouldCloseOnMouseLeave: false,
    }
  },
  computed:{
    getComponentClass() {
      return this.layout
    }
  },
  watch: {
    '$route' () {
      this.closeConfirmPopover()
    }
  },
  mounted() {
    this.$eventBus.$on('openConfirmPopover', ({ targetElem, text, neverAskUserProp, learnMoreLink, onConfirmCallback, onCloseCallback, side='right', okButtonText, buttons, guaranteeScrollElem, layout='standard', sumTop, sumLeft, disableFooter, maxWidth, textAsHtml }) => {
      if (!targetElem) {
        console.error(`Can't open confirm popover without defining targetElem`)
        return
      }

      const userPreference = neverAskUserProp ? this.$store.getters['getterUserPrefferenceByPath'](neverAskUserProp) : null
      const btnIdx = (userPreference && !isNaN(userPreference) && Number(userPreference) > 0) ? Number(userPreference) - 1 : undefined
      if (userPreference === 'true' || btnIdx !== undefined) {
        this.setNeverAskProp = false
        this.onConfirmCallback = onConfirmCallback || null
        this.onCloseCallback = onCloseCallback || null
        this.confirmPopoverAction(btnIdx)
        return
      }

      this.layout = layout
      this.side = side
      this.targetElem = targetElem
      this.sumTop = sumTop ?? 0
      this.sumLeft = sumLeft ?? 0
      this.adjustPositioning()
      
      this.setNeverAskProp = false
      this.neverAskProp = neverAskUserProp || null
      this.text = text || null
      this.learnMoreLink = learnMoreLink || null
      this.onConfirmCallback = onConfirmCallback || null
      this.onCloseCallback = onCloseCallback || null
      this.guaranteeScrollElem = guaranteeScrollElem || null
      this.disableFooter = Boolean(disableFooter === true)
      this.maxWidth = !isNaN(maxWidth) ? +maxWidth : null
      this.textAsHtml = Boolean(textAsHtml === true)
      this.shouldCloseOnMouseLeave = false
      
      this.buttons = buttons || null
      if (!this.buttons?.length && !Array.isArray(this.buttons)) {
        this.buttons = [okButtonText || 'I understand']
      }

      this.open = true

      // Scrolls if needed to show all container
      if (guaranteeScrollElem) {
        setTimeout(() => {
          if (window.innerHeight < this.$el.getBoundingClientRect().bottom) {
            const scrollTop = (guaranteeScrollElem?.scrollTop || 0) + (this.$el.getBoundingClientRect().bottom - window.innerHeight) + 10
            guaranteeScrollElem?.scroll({
              top: scrollTop,
              left: 0,
              behavior: 'smooth'
            })
          }
        }, 100)
      }

      // document.addEventListener('scroll', this.adjustPositioning)
      // if (guaranteeScrollElem) {
      //   guaranteeScrollElem.addEventListener('scroll', this.adjustPositioning)
      // }
    })

    this.$eventBus.$on('closeConfirmPopover', ({ preventWhenHovering = false } = {}) => {
      const isHovering = Boolean(this.$el?.parentNode?.querySelector(":hover") === this.$el)
      if (isHovering && preventWhenHovering) {
        this.shouldCloseOnMouseLeave = true
        return
      }
      this.closeConfirmPopover()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openConfirmPopover')
    this.$eventBus.$off('closeConfirmPopover')
  },
  methods: {
    checkNeedToClose() {
      if(this.shouldCloseOnMouseLeave) {
        this.closeConfirmPopover()
        this.shouldCloseOnMouseLeave = false
      }
    },
    adjustPositioning() {
      if (!this.targetElem) {
        return
      }
      if (!document.contains(this.targetElem)) {
        this.closeConfirmPopover()
        return
      }

      const { top, width, left, height } = this.targetElem.getBoundingClientRect()
      this.top = (top - 10) || 0
      if (this.side === 'right') {
        this.left = (left + width + 5) || 0
      } else if (this.side === 'left'){
        this.top += 3
        this.left = (left - 315) || 0
      }

      this.top += this.sumTop
      this.left += this.sumLeft

      const topElt = document.elementFromPoint(left,top)
      this.open = Boolean(this.targetElem.isSameNode(topElt) || this.targetElem?.contains(topElt) || topElt?.contains(this.targetElem))

      // Adjust bottom to top
      if (this.side === 'bottom') {
        let tries = 10
        const interval = setInterval(() => {
          if (!this.open) {
            clearInterval(interval)
            return
          }
          const elInfo = this?.$el?.getBoundingClientRect()
          const safeHeight = window.innerHeight - 100
          const targetElInfo = this?.targetElem?.getBoundingClientRect()
          if (targetElInfo && elInfo && elInfo.bottom > safeHeight) {
            this.top = targetElInfo.top - elInfo.height - 10 + this.sumTop
            this.left = left
          } else if (targetElInfo && elInfo && elInfo.bottom <= safeHeight) {
            this.top += height + 40
            this.left = left
            clearInterval(interval)
          }
          tries--
          if (tries <= 0 || elInfo || !targetElInfo) clearInterval(interval)
        }, 10)
      }
    },
    closeConfirmPopover() {
      if (this.onCloseCallback) {
        this.onCloseCallback()
      }

      // document.removeEventListener('scroll', this.adjustPositioning)
      // if (this.guaranteeScrollElem) {
      //   this.guaranteeScrollElem.removeEventListener('scroll', this.adjustPositioning)
      // }

      this.open = false
      this.targetElem = null
      this.top = 0
      this.left = 0
      this.sumTop = 0
      this.sumLeft = 0
      this.setNeverAskProp = false
      this.neverAskProp = null
      this.text = null
      this.learnMoreLink = null
      this.onConfirmCallback = null
      this.onCloseCallback = null
      this.layout = 'standard'
      this.guaranteeScrollElem = null
      this.disableFooter = false
      this.maxWidth = null
      this.textAsHtml = false
      this.shouldCloseOnMouseLeave = false
    },
    confirmPopoverAction(btnIdx = undefined) {
      if (this.onConfirmCallback) {
        this.onConfirmCallback({setNeverAskProp: this.setNeverAskProp, selectedButtonIdx: btnIdx})
      }
      if (this.setNeverAskProp) {
        const value = this.buttons?.length > 1 && btnIdx !== undefined ? (btnIdx + 1).toString() : 'true'
        this.$store.dispatch('setUserPreferences', { payload: { path: this.neverAskProp, value } })
      }
      this.closeConfirmPopover()
    },
  }
}
</script>

<style scoped lang="scss">
.confirm-popover {
  position: fixed;
  top: 0;
  left: 0;
  border-radius: 6px;
  padding: 1rem;
  background-color: #00465F;
  color: #ECF7FB;
  z-index: -1;
  font-size: 12px;
  line-height: 130%;
  font-weight: bold;
  max-width: 300px;

  .actions {
    margin-bottom: 0.5rem;
    &:not(.divisor){
      margin-bottom: 12px;
    }
    &.mb-0 {
      margin-bottom: 0;
    }

    &.flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &.divisor {
      border-top: 1px solid #318FAC;
      padding-top: 12px;
      margin-bottom: 0;
    }

    .buttons-container {
      display: inline-block;
      .button {
        font-size: 12px;
        line-height: 130%;
        display: inline-block;
        background: #115D78;
        border-radius: 20px;
        padding: 8px 12px;
        cursor: pointer;
        margin-top: 0.5rem;
        margin-right: 0.5rem;

        &:hover {
          background: #003548;
        }

        &:last-of-type {
          margin-right: 0;
        }

        &.mt-0 {
          margin-top: 0;
        }
      }
    }
  }

  ::v-deep {
    .app-checkbox-item {
      border: 0;

      .whitespace-pre {
        color: #ECF7FB !important;
      }

      i {
        height: 16px;
      }
    }
  }

  &.standard {
    &:before {
      position: absolute;
      top: 10px;
      left: -10px;
      content: attr(pseudo-content-value);
      height: 0;
      border-top: 11px solid transparent;
      border-bottom: 11px solid transparent;
      border-right: 12px solid #00465F;
    }
  }
  

  &.left:before {
    right: -10px;
    border-left: 12px solid #00465F;
    border-right: unset;
    left: unset;
  }
}
</style>
