export const ASSUMPTION_LEVEL = {
  BASE: 'BASE',
  PROTOTYPE: 'PROTOTYPE',
  CLIMATE_ZONE: 'CLIMATE_ZONE',
  PROTOTYPE_AND_CLIMATE_ZONE: 'PROTOTYPE_AND_CLIMATE_ZONE'
}

export function extractAssumptionValuesFromPolicy(policy, assumptionKey, baseValue) {
  let auxList = (policy?.policy_containers.map((policyContainer) => {
    return policyContainer?.custom_combinations?.map((customCombination) => {
      return {
        type_prototype_id: policyContainer.type_prototype_id,
        prototype_id: customCombination.prototype_id,
        climate_zone_raw: customCombination.climate_zone_raw,
        value: customCombination?.meta?.assumptions?.[assumptionKey] ?? baseValue,
      }
    }) || []
  }) || []).flat(Infinity)
  const valuesByCustomCombination = []
  auxList.forEach((i) => {
    if (valuesByCustomCombination.findInArray(i)) {
      return
    }
    valuesByCustomCombination.push(i)
  })
  return valuesByCustomCombination
}

export function checkIfHasDifferenceBetweenAssumptionValues(assumptionValues, checkPrototype=true, checkClimateZone=false) {
  return assumptionValues.reduce((acc, curr, idx, list) => {
    if (acc) return true
    return list.some((item, idx2) => {
      const prototypeChanged = Boolean(!checkPrototype || (checkPrototype && item.prototype_id !== curr.prototype_id))
      const climateZoneChanged = Boolean(!checkClimateZone || (checkClimateZone && item.climate_zone_raw !== curr.climate_zone_raw))
      return Boolean(idx !== idx2 && prototypeChanged && climateZoneChanged && item.value != curr.value)
    })
  }, false)
}

export function getAssumptionsLevel(assumptionValues) {
  const checkPrototype = checkIfHasDifferenceBetweenAssumptionValues(assumptionValues, true, false)
  const prototypes = [... new Set(assumptionValues.map((a) => a.prototype_id))]
  const isAllPrototypesEqual = prototypes.every((prototype) => {
    const prototypeValues = assumptionValues.filter((a) => a.prototype_id === prototype).map((a) => Number(a.value))
    return Math.min(...prototypeValues) === Math.max(...prototypeValues)
  })

  const checkClimateZone = checkIfHasDifferenceBetweenAssumptionValues(assumptionValues, false, true)
  const climateZones = [... new Set(assumptionValues.map((a) => a.climate_zone_raw))]
  const isAllClimateZonesEqual = climateZones.every((climateZone) => {
    const climateZoneValues = assumptionValues.filter((a) => a.climate_zone_raw === climateZone).map((a) => Number(a.value))
    return Math.min(...climateZoneValues) === Math.max(...climateZoneValues)
  })

  const checkBoth = checkPrototype && checkClimateZone
  if (checkPrototype && isAllPrototypesEqual) {
    return ASSUMPTION_LEVEL.PROTOTYPE
  } else if (checkClimateZone && isAllClimateZonesEqual) {
    return ASSUMPTION_LEVEL.CLIMATE_ZONE
  } else if (checkBoth) {
    return ASSUMPTION_LEVEL.PROTOTYPE_AND_CLIMATE_ZONE
  } else {
    return ASSUMPTION_LEVEL.BASE
  }
}
