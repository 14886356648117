<template>
  <div
    v-if="columnGroup"
    class="w-auto flex flex-col h-full"
    :class="[ 'column-group', `column-group-${columnGroup.key}`]"
  >
    <!-- Super header -->
    <div class="w-full pt-1 sticky top-0 left-0 bg-white z-5">
      <div class="superheader-border w-full border-b-2 border-deepsky-200 h-8 flex items-center">
        <feather 
          size="16" 
          type="info" 
          class="superheader-border-feather text-blue mr-2 cursor-pointer" 
          @click="openColumnsHelper(columnGroup.hasHelper)"
        />
        <h2 class=" text-deepsky-900 text-header-2 font-bold">
          {{ columnGroup.title }}
        </h2>
      </div>
      <div class="flex bg-white pt-2">
        <div
          v-for="(column, colIndex) in columnGroup.columns"
          :key="colIndex"
          class="column-group-subheader flex pr-4"
        >
          <div class="flex mx-auto">
            <feather 
              size="16" 
              type="info" 
              class="column-group-helper-button text-blue -ml-2 mr-2 cursor-pointer" 
              @click="openColumnsHelper(column.hasHelper)"
            />
            <div class="flex flex-col items-start">
              <p class="text-deepsky-900 font-bold text-header-2">
                {{ column.title }}
              </p>
              <p class="text-ash-500 h-4">
                {{ column.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex">
      <template 
        v-for="(column, colIndex) in columnGroup.columns"
      >        
        <!-- ITEM -->
        <div
          :key="colIndex"
          class="comparison-table-column w-full flex flex-col"
        >
          <div 
            v-for="policy in policies"
            :key="`cell-${column.key}-${policy.id}`"
            class="comparison-table-row w-full flex flex-col text-gray04 text-center py-2 row-scope-item border-box justify-center  "
            :style="{ minHeight: `${itemsRowHeight}px` }"
          >
            <!-- {{column.key}} -->
            <template v-if="'building_type' === column.key">
              <!-- <pre>{{policy.prototypes}}</pre> -->
              <div class="flex flex-col items-center">
                <div
                  v-for="prototype in policy.prototypes"
                  :key="prototype.id"
                  class="prototype-wrapper p-1 border rounded-sm mb-1/2 last:mb-0 flex-shrink"
                  :style="{ '--prototype-color' : prototype.color ? prototype.color : 'black' }"
                >
                  <div class="bg-ash-600 psui-text-xsmall font-bold text-white rounded-sm px-2 py-1 ">
                    {{ prototype.title_abbreviated ? prototype.title_abbreviated : prototype.title }}
                  </div>
                </div>
              </div>
            </template>
            
            <template v-else-if="'vintages' === column.key">
              <div
                v-for="vintageType in availableVintageTypes" 
                :key="vintageType.id"
                class="mx-2 py-1 mb-1 first:mt-1 rounded-full text-header-2 border font-medium"
                :class="{ 
                  'border-ash-300 bg-ash-100 text-ash-400' : !policyHasVintageType(policy, vintageType),
                  'border-deepsky-450 bg-deepsky-450 text-white' : policyHasVintageType(policy, vintageType)
                }"
              >
                {{ vintageType.title }}
              </div>
            </template>

            <template v-else-if="'climate_zone' === column.key">
              <div class="flex flex-wrap justify-center">
                <div 
                  v-for="climateZonePrefix in availableClimateZones"
                  :key="`${policy.id}-${climateZonePrefix}`"
                  class="text-header-2 flex-shrink-0 mr-2 last:mr-0 text-ash-600 font-bold rounded-full w-10 h-10 border border-ash-300 flex items-center justify-center opacity-20"
                  :class="{ 'opacity-100' : policyHasClimateZone(policy, climateZonePrefix)}"
                >
                  {{ climateZonePrefix }}
                </div>
              </div>
            </template>

            <template v-else-if="'policy_type' === column.key">
              <span class="text-header-2 h-full w-full flex items-center justify-around">{{ getPolicyTypeLabel(policy.id) }}</span>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import GraphQL from '@/util/GraphQL'
import { checkPolicyVintages }from '@/util/Helpers.js'

export default {
  name: 'ComparisonTableGroupScope',
  props: [
    'policies',
    'itemsRowHeight'
  ],
  data: () => ({
    availableVintageTypes : []
  }),
  computed: {
    columnGroup() {
      return this.$store.getters['comparison/getterExistingBuildingsColumnsSelected'].columnGroups.find((columnGroup) => columnGroup.key === 'scope')
    },
    availableClimateZones() {
      const climateZonesPrefixByPolicies = this.policies.map(policy => policy.climate_zones.map(cz => cz.prefix))
      const climateZones = [].concat(...climateZonesPrefixByPolicies)      
      return [...new Set(climateZones)].sort((a, b) => a - b)
    }
  },
  created() {
    this.setAvailableVintageTypes()
  },
  updated() {
    this.$emit('checkScopeRowHeight')
  },
  methods: {
    setAvailableVintageTypes() {
      const query = `{
        type_vintages(
          orderBy: [{ column: "order", order: "asc" }]
        ) {
          id,
          title
        }
      }`
      GraphQL({ query }, { shouldCache: true })
        .then(({ data }) => {
          if (data.type_vintages) {
            this.availableVintageTypes = data.type_vintages
          }
        })
    },
    policyHasVintageType(policy, type_vintage) {
      const custom_combinations = this.$store.getters['policy/getterPolicyCustomCombinations'](policy.id)
      
      return policy.climate_zones.some(cz => checkPolicyVintages({custom_combinations,type_vintage,climate_zone_raw:cz.raw}))
    },
    policyHasClimateZone(policy, climateZonePrefix) {
      return policy.climate_zones.map(cz => parseInt(cz.prefix)).includes(parseInt(climateZonePrefix))
    },
    getPolicyTypeLabel(policy_id) {
      return this.$store.getters['policy/getterPolicyTypeLabel'](policy_id)
    },
  }
}
</script>

<style scoped lang="scss">
  .prototype-wrapper {
    border-color : var(--prototype-color);
    > div {
      background-color : var(--prototype-color);
    }
  }

  .comparison-table-column {
    max-width: 140px;
  }

  .column-group-subheader {
    min-width: 140px;
  }
</style>