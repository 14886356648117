import { copyToClipboard, copyUrlToClipboard } from '@/util/Helpers'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'


export default {
  methods: {
    copyToClipboard(string) {
      copyToClipboard(string)
    },
    copyUrlToClipboard() {
      copyUrlToClipboard()
    },
    openColumnsHelper({ type = 'helper', slug, id }) {
      this.$eventBus.$emit('openDrawerContent', { type, id: id, slug })      
    },
    getLatestPublishedStudWithValidPrototypeAndVintageByTypePrototypeTypeVintageAndStudyType({type_prototype_id, type_vintage}) {
      const { id: study_type_id } = this.$store.getters['globalEntities/StudyType/getterGlobalStudyType']({ slug: STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS})
      const studiesPublishedByStudyTypeAndTypePrototype = this.$store.getters['globalEntities/Study/getterLatestPublishedStudiesByStudyTypeAndTypePrototype']({ type_prototype_id, study_type_id })
      const studiesWithPrototypeAndVintage = studiesPublishedByStudyTypeAndTypePrototype.map((study) => {
        const vintage = this.$store.getters['globalEntities/Vintage/getterGlobalVintage']({ study_id : study.id, type_vintage_id : type_vintage.id })
        const prototype = this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({ study_id : study.id, type_prototype_id })
        return { ...study, prototype, vintage }
      }).filter(study => study.prototype && study.vintage)
      return studiesWithPrototypeAndVintage.length ? studiesWithPrototypeAndVintage[0] : false
    }
  }
}