import Vue from 'vue'
import UserEventApiService from '@/services/api/UserEventApiService'
import Store from '@/store'
import Api from '@/util/Api'
import { PsUrls } from '@/data/ps-urls'
import * as sha1 from 'sha1'
import { isFeatureFlagEnabled } from '@/util/Functions.js'

const requireAuthUrl = PsUrls.find((i) => i.url === window.location.host)
let passedAuth = sessionStorage.getItem('url-auth') === 'true' || Boolean(!requireAuthUrl) || Boolean(!requireAuthUrl.pass)
import { STUDY_TYPES_DB_SLUGS, ROUTE_EXCEPTIONS } from '@/util/Enums.js'

export default (router) => {
  router.beforeEach((to, from, next) => {
    do {
      if (!passedAuth) {
        const password= window.prompt('Please enter the access password')
        if (!requireAuthUrl.pass || sha1(password) === requireAuthUrl.pass) {
          passedAuth = true
          sessionStorage.setItem('url-auth', 'true')
        }
      }
    } while(requireAuthUrl && !passedAuth)
    
    let nextCallsNeeded = 1
    nextCallsNeeded += Store.state.general.isDeviceInfoLoaded || to?.path !== '/' ? 0 : 1

    const callNext = () => {
      nextCallsNeeded--
      if (nextCallsNeeded === 0 && passedAuth) {
        const isDeviceInfoLoaded = Store.state.general.isDeviceInfoLoaded
        if (isDeviceInfoLoaded && isFeatureFlagEnabled(['PS_TEMPLATE_DESIGN']) && to?.path === '/') {            
          router.push({ name: 'StudyResults' })
          return 
        } 
        next()
      }
    }

    if (to?.path === '/' && to?.query?.jurisdiction) {
      nextCallsNeeded += Store.getters['lastJurisdictionVisited'] ? 0 : 1
      Api.get(`/app/jurisdictions/${to.query.jurisdiction}`)
        .then(({ data }) => {
          const jurisdiction = data?.jurisdictions?.find((j) => j.slug === to.query.jurisdiction)
          if (jurisdiction) {
            Store.dispatch('setUserLastJurisdictionAndFirstClimateZoneVisited', { value: jurisdiction, context: 'AutoUpdateLastJurisdictionVisitedFromHome'})
          }
          callNext()
        })
        .catch( () => { callNext() })
    }
    
    const unwatch = Store.watch(
      (state) => state.general.isDeviceInfoLoaded,
      (newValue) => {
        if (newValue) {          
          callNext()          
          unwatch()
        }
      }
    )

    /**
     * Google Analytics tracking and UserEventApi
     */
    if(Store.getters['general/getterDeviceInfo']?.log_events) {
      if(Vue.$gtag) {
        Vue.$gtag.pageview(to.path)
      }
      UserEventApiService.log({ event: 'NAVIGATION', notes: window.location.href })
    }
    
    /**
     * Scroll to top on route change
     */
    setTimeout(() => {
      const mainContent = document.getElementById('main-content')
      if(mainContent) {
        document.getElementById('main-content').scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
    }, 200)

    if(to.meta?.auth?.required && to.meta?.auth.is_admin && !Store.getters['getterLoggedUserIsAdmin']) {
      Vue.$toast.info(`You don't have permission to access the admin dashboard`)
      return router.push({ name: 'AdminSignIn' })
    }

    const isNotRoutingToExceptions = !Object.values(ROUTE_EXCEPTIONS).includes(to.name)
    const hasNoJurisdictionSelected = !Store.getters['lastJurisdictionVisited'] && !to.params?.jurisdiction_slug

    if (isNotRoutingToExceptions && hasNoJurisdictionSelected) {
      window.$eventBus.$emit('openModalJurisdictionSelection', { customRedirect: to.name })
      if (from.name != null) {
        return false
      }
    }

    if(!Store.getters['lastStudyTypeVisited']) {
      Store.dispatch('setUserLastStudyTypeVisited',{
        context:'updateLastStudyTypeVisitedFromGeneralRouterHook',
        value:STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS
      })
    }
    
    if((to.name == 'StudyResults' || to.name == 'PolicyOptions') && !to.query.only_study_type) {
      to.query.only_study_type = Store.getters['lastStudyTypeVisited'] || STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS
    } 
    
    callNext()
  })
}
