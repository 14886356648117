import GraphQL from '@/util/GraphQL'

export default {
  namespaced: true,
  
  state: {
    vintages: []
  },

  getters: {
    getterGlobalVintages: (state) => (filters = null) => {
      return filters ? state.vintages.findAllInArray(filters) : state.vintages
    },

    getterGlobalVintage: (state) => (filters = null) => {
      return filters ? state.vintages.findInArray(filters) : false
    },
    
    getterVintageById: state => vintage_id => {
      return state.vintages.findInArray({ id: vintage_id })
    }
  },

  actions: {
    loadGlobalVintages(context) {
      const query = `
        {
          vintages (
            orderBy: [{ column: "order", order: "ASC" }]
          ) {
            id
            title
            slug
            study_id
            type_vintage_id
            type_vintage {
              id
              title
              slug
              color
              title_long
              flexible_path_title
              order
            }
          }
        }
      `
      GraphQL({ query })
        .then(({ data }) => {
          data.vintages.sort((a, b) => ((getVintageOrderByTypeVintage(a))) - (getVintageOrderByTypeVintage(b)) )
          context.commit('setGlobalVintages', { vintages: data.vintages })
        })
    }
  },

  mutations: {
    setGlobalVintages(state, { vintages }) {
      state.vintages = vintages
    }
  }
}

const getVintageOrderByTypeVintage = (vintage) => {
  if(!vintage.type_vintage) return 999
  return vintage.type_vintage.order
}
