var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center"},[_c('p',{staticClass:"psui-text-gray-80 psui-font-bold"},[_vm._v(" Filter by ")]),_c('div',{staticClass:"flex items-center gap-1 ml-2"},[_c('Dropdown',{ref:"dropdownShowPoliciesBy",attrs:{"dropdown-dialog-classes":"left-0 bg-white"},scopedSlots:_vm._u([{key:"buttonLabel",fn:function(){return [_c('PsChips',{attrs:{"label":"Building Type","type":"button","checked":_vm.isFilteringByTypePrototype}})]},proxy:true},{key:"items",fn:function(){return _vm._l((_vm.getTypePrototypesFromStudyTypesByRoute),function(type_prototype){return _c('PsCheckbox',{key:`filter-${type_prototype.id}`,attrs:{"label":`${type_prototype.title}`,"size":"small","value":!_vm.checkQueryKeyValue(
              'exclude_type_prototype_ids',
              type_prototype.id
            )},on:{"input":function($event){return _vm.toggleQueryKeyValue(
              'exclude_type_prototype_ids',
              type_prototype.id
            )}}})})},proxy:true}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }