<template>
  <section>
    <div class="flex flex-col">
      <div class="flex flex-row justify-between items-center pb-2">
        <div class="text-base font-bolder pt-6">
          Please, add the features that reflect the features that are upcoming
        </div>
        <button
          class="mt-6 button button-blue04"
          @click.prevent="addFeature"
        >
          Add feature
        </button>
      </div>
      <ul>
        <AdminUpcomingFeatureCard
          v-for="(feature, index) in upcomingFeatures"
          :key="index"
          :index="index"
          :title.sync="upcomingFeatures[index].title"
          :content.sync="upcomingFeatures[index].content"
          :img.sync="upcomingFeatures[index].img"
          @feature-index="removeFeature"
        />
      </ul>
    </div>
  </section>
</template>

<script>
import AdminUpcomingFeatureCard from "./AdminUpcomingFeatureCard.vue"

export default {
  name: "AdminUpcomingFeatures",
  components: { AdminUpcomingFeatureCard },
  props: ["features"],
  emits: ["upcoming-features"],
  data() {
    return {
      upcomingFeatures: [{
        title: "",
        content: "",
        img: "",
      }],
    }
  },
  watch: {
    upcomingFeatures: {
      handler: function () {
        this.$emit(
          "upcoming-features",
          JSON.stringify(this.upcomingFeatures)
        )
      },
      deep: true,
    },
    features:{
      handler:function(){
      if (this.features) {
          this.upcomingFeatures = JSON.parse(this.features)
        }
      },
      immediate: true
    }
  },
  methods: {
    addFeature() {
      const upcomingfeature = {
        title: "",
        content: "",
        img: "",
      }

      this.upcomingFeatures.push(upcomingfeature)
    },
    removeFeature(featureIndex) {
      this.upcomingFeatures = [...this.upcomingFeatures.filter(
        (feature) => this.upcomingFeatures.indexOf(feature) !== featureIndex
      )]
    },
  },
}
</script>

<style>
</style>