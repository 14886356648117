<template>
  <div
    v-if="study_type"
    class="p-6"
  >
    <InputWithLabel
      v-model="study_type.title"
      class="mt-6"
      :label="'Title'"
    />

    <InputWithLabel
      v-model="study_type.title_alternate"
      class="mt-6"
      :label="'Title alternate'"
    />

    <div class="w-full relative mt-6">
      <VueSelect 
        v-model="study_type.study_groups"
        class="vue-select"
        placeholder="Select study_groups related with this study group"
        label="title"
        :options="study_groups"
        :multiple="true"
      />
      <label>Study groups</label>
    </div>

    <hr class="border-b border-gray05 my-6">

    <div
      v-if="study_type.hasError"
      v-html="study_type.validationPhraseHtml"
    />
    <div>
      <PsButton
        v-if="!$route.params.study_type_id"
        :disabled="study_type.hasError"
        label="Create"
        size="big"
        icon="add"
        class="psui-mt-6"
        @click="store()"
      />
  
      <PsButton
        v-else
        :disabled="study_type.hasError"
        label="Update"
        size="big"
        icon="update"
        @click="update()"
      />
    </div>
  </div>
</template>

<script>
import StudyType from '@/models/StudyType'
export default {
  name: 'AdminStudyTypeForm',
  data() {
    return {
      study_type: new StudyType(),
      study_groups: []
    }
  },
  mounted() {
    if(this.$route.params.study_type_id) {
      this.getStudyType()
    }

    this.getStudyGroups()
  },
  methods: {

    getStudyGroups() {
      const query = `
        {
          study_groups {
            id
            title
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.study_groups = data.study_groups
        })
    },

    async getStudyType() {
      const query = `
        {
          study_type (
            findBy: { column: "id", value: "${this.$route.params.study_type_id}"}
          ){
            id
            title
            title_alternate
            study_groups {
              id
              title
            }
          }
        }
      `
      const { data } = await this.$graphql({ query })

      this.study_type = data.study_type
    },

    store() {
      this.$api.post(`/api/study_type/store`, this.study_type, { loader: true })
        .then(() => {
          this.$router.push({ name: 'AdminStudyTypeList' })
        })
    },

    update() {
      this.study_type.raw = this.raw
      this.$api.post(`/api/study_type/update/${this.study_type.id}`, this.study_type, { loader: true })
        .then(() => {
          this.$router.push({ name: 'AdminStudyTypeList' })
        })
    }
  }
}
</script>
