import { render, staticRenderFns } from "./PolicyHeaderUserWarnings.vue?vue&type=template&id=26acd00e&scoped=true"
import script from "./PolicyHeaderUserWarnings.vue?vue&type=script&lang=js"
export * from "./PolicyHeaderUserWarnings.vue?vue&type=script&lang=js"
import style0 from "./PolicyHeaderUserWarnings.vue?vue&type=style&index=0&id=26acd00e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26acd00e",
  null
  
)

export default component.exports