<template>
  <div class="w-full h-full overflow-y-auto">
    <BuildingEstimatesHeader class="sticky top-0 bg-white z-20" />

    
    <div class="w-full flex flex-col px-12 py-6">
      <div class="w-full my-3 mb-6 text-black flex">
        <VueSelect 
          class="vue-select"
          placeholder="Select a policy to use"
          label="title"
          :reduce="item => item.id"
          :options="user_policies"
          :value="$store.state.assumptions.buildingStocks.policy_id_selected"
        />
        <PsButton
          class="ml-3 whitespace-nowrap"
          label="Clear all building stocks"
          @click="clearBuildingStocks()"
        />
      </div>
      
      <div class="my-4 flex space-x-3">
        <div class="bg-red">
          <PsButton 
            label="Toggle Assumptions Drawer"
            @click="$store.commit('assumptions/toggleDrawer',{})"
          />
        </div>
        <div class="bg-red">
          <PsButton 
            label="Toggle Assumptions Drawer"
            :outline="true"
            @click="$store.commit('assumptions/toggleDrawer',{})"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BuildingEstimatesHeader from '@/modules/app/jurisdiction/building-estimates/BuildingEstimatesHeader.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'BuildingEstimatesPlayground',
  components: { BuildingEstimatesHeader },
  data() {
    return {
      type_vintages: [],
      building_types: [],
      user_policies: [],
      hiddenItems: [],
    }
  },
  computed: {
    ...mapGetters(['lastJurisdictionVisited']),
    climateZones() {
      return this.lastJurisdictionVisited?.climate_zones ?? []
    }
  },
  mounted() {
    this.getData()
    this.getUserPolicies()
  },
  methods: {

    getValue({ climate_zone_prefix, type_vintage_id, type_prototype_id}) {
      return this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({ 
        climate_zone_prefix,
        type_vintage_id,
        type_prototype_id,
      })
    },
    getData() {
      const query = `
        {
          type_vintages {
            id
            title
            slug
          }
          building_types (
            where: [
              { column: "slug", operation: "NOT ILIKE", value: "%total%" }
            ]
          ) {
            id
            title
            slug
            type_prototypes {
              id
              title
              slug
            }
          }
        }
      `

      this.$graphql({ query })
        .then(({ data }) => {
          this.type_vintages = data.type_vintages
          this.building_types = data.building_types
        })
    },


    getUserPolicies() {
      const query = `
        {
          currentUser {
            policies (
              where: [
                { column: "jurisdiction_id", operation: "=", value: "${ this.$store.getters['lastJurisdictionVisited'].id }"}
              ]
            ) {
              id
              title
            }
          }
        }
      `

      this.$graphql({ query })
        .then(({ data }) =>{
          this.user_policies = data.currentUser.policies
        })
    },

    setUserBuildingStock( { climate_zone_prefix, type_prototype_id, type_vintage_id }) {
      const building_stock = {
        jurisdiction_id: this.$store.getters['lastJurisdictionVisited'].id,
        climate_zone_prefix,
        type_prototype_id,
        type_vintage_id
      }

      this.$store.commit('assumptions/buildingStocks/setUserBuildingStocks', building_stock)
    },

    clearBuildingStocks() {
      this.$store.commit('assumptions/buildingStocks/clearAllBuildingStocks')
      this.$store.dispatch('assumptions/buildingStocks/getJurisdictionBuildingStocks', this.$store.getters['lastJurisdictionVisited'].id)
      this.$store.commit('assumptions/buildingEstimates/clearAllBuildingEstimates')
      this.$store.dispatch('assumptions/buildingEstimates/getJurisdictionBuildingEstimates', this.$store.getters['lastJurisdictionVisited'].id)
    },

    toggleHiddenItem(item) {
      if(this.hiddenItems.indexOf(item) > -1) {
        this.hiddenItems.splice(this.hiddenItems.indexOf(item), 1)
      } else {
        this.hiddenItems.push(item)
      }
    },

    addValueToSum(value, label) {
      this.$refs.inAppCalculator.addValueToSum(value, label)
    },
  }, // methods
}
</script>