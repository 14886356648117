<template>
  <div class="psui-flex psui-flex-col psui-overflow-y-auto psui-justify-between psui-h-full">
    <div
      class="psui-overflow-y-auto psui-flex psui-flex-col psui-space-y-3 psui-px-12 psui-flex-grow psui-h-full psui-pb-5"
    >
      <p class="psui-text-p psui-font-bold psui-text-blue-80">
        This option will apply to
        {{ study_type_slug === 'new-buildings' ? 'new' : 'existing' }}:
      </p>
      <div
        v-for="(prototype, index) in availablePrototypes"
        :key="`${index}-${prototype.slug}`"
        class="card-prototype psui-flex psui-rounded-lg psui-p-4 psui-relative"
        :class="[
          checkPrototypeDisabled(prototype) ? 'disabled psui-bg-gray-20' : 'psui-bg-blue-10',
          !displayClimateZones.includes(index) && !checkPrototypeDisabled(prototype)
            ? 'psui-cursor-pointer'
            : 'psui-cursor-default',
        ]"
        @click.stop="openPrototypeCard(prototype, index)"
      >
        <div
          v-if="lastJurisdictionVisited.climate_zones && getIfClimateZoneDropdownShouldBeToggle(prototype)"
          class="psui-flex-shrink-0"
          :class="
            !getIfClimateZoneDropdownShouldBeToggle(prototype) ? 'psui-cursor-default' : 'psui-mr-3 cursor-pointer'
          "
        >
          <span
            class="material-icons-round psui-flex-none transition-all psui-transform"
            :class="{
              'open psui-rotate-90': displayClimateZones.includes(index),
              'closed psui-rotate-0': displayClimateZones.includes(index),
              'psui-text-gray-50': checkPrototypeDisabled(prototype),
              'psui-text-blue-50': !checkPrototypeDisabled(prototype),
            }"
            @click.stop="toggleArrayOfClimateZones(index, prototype)"
          >
            chevron_right
          </span>
        </div>
        <div class="card-prototype-list psui-flex-grow psui-flex psui-flex-col psui-items-center psui-space-y-4 psui-relative">
          <div class="card-prototype-title psui-flex psui-items-center psui-w-full">
            <div
              class="psui-flex psui-items-center psui-leading-none"
              @mouseenter="onHoverTypePrototype(index)"
              @mouseleave="onHoverTypePrototype(false)"
            >
              <PsCheckboxSimple
                :input-attrs="{ id: `policy-option-drawer-prototype-${ prototype.id }` }"
                class="prototype-checkbox-simple psui-flex psui-items-center psui-leading-none"
                :class="checkPrototypeDisabled(prototype) ? 'psui-text-gray-50' : 'psui-text-blue-80'"
                :layout="
                  getClimateZonesLengthByPrototype(prototype.id) ===
                    prototype.availableClimateZones
                    ? 'default'
                    : 'mixed'
                "
                :disabled="checkPrototypeDisabled(prototype)"
                :checked="checkIfPrototypeIsSelected(prototype.id)"
                @change="togglePrototypeSelected(prototype, index)"
              >
                <template #content>
                  <PsIcon
                    :icon="getPrototypeIcon(prototype)"
                    :color="checkPrototypeDisabled(prototype) ? 'psui-text-gray-50' : 'psui-text-blue-60'"
                    :style="{ display: 'flex' }"
                  />
                  <p class="psui-text-small psui-ml-3">
                    {{ getPrototypeTitle(prototype) }}
                  </p>
                </template>
              </PsCheckboxSimple>
              <div
                class="psui-w-2 psui-h-2 psui-flex psui-items-center psui-ml-1 cursor-pointer psui-leading-none "
                :class="isHoveringTypePrototype === index ? 'opacity-1' : 'opacity-0'"
                @click.stop="openPrototypeDescriptionModal(prototype)"
              >
                <PsIcon
                  icon="info"
                  size="16"
                  class="psui-items-center psui-justify-center"
                  :class="checkPrototypeDisabled(prototype) ? 'psui-text-gray-50' : 'psui-text-blue-60'"
                  :style="{ display: 'flex' }"
                />
              </div>
            </div>
            <div 
              v-if="checkPrototypeDisabled(prototype)"
              class="card-prototype-tooltip psui-relative psui-flex-auto psui-h-full"
            >
              <PsRichTooltip
                layout="gray"
                css-class="psui-w-64 ml-auto"
                class="psui-leading-none psui-w-full psui-h-full"
              >
                <template #trigger>
                  <span class="stretched-link" />
                </template>
                <template #content>
                  This policy option cannot be applied to
                  {{ getPrototypeTitle(prototype) }} as some of the necessary policy requirements are not cost-effective or
                  study results are not available.
                </template>
              </PsRichTooltip>
            </div>
            <div
              v-if="!isLoading"
              class="card-prototype-units psui-ml-auto psui-cursor-default"
            >
              <PsRichTooltip
                layout="gray"
                class="psui-flex psui-leading-none"
              >
                <template #trigger>
                  <div class="psui-flex psui-items-center psui-cursor-default">
                    <p class="psui-text-small psui-text-gray-50 mr-1">
                      {{ `${formatUnits(getAllUnitsByTypePrototype(prototype))} units` }}
                    </p>
                    <a
                      :href="getIntercomLinkFromPrototypeSlug(prototype)"
                      target="_blank"
                      class="flex items-center text-opacity-0"
                    >
                      <PsIcon
                        icon="info"
                        size="16"
                        class="psui-text-gray-40  psui-cursor-pointer"
                      />
                    </a>
                  </div>
                </template>
                <template #content>
                  <p class="psui-font-bold psui-text-gray-80 psui-text-xsmall">
                    {{ tooltipMessage() }}
                  </p>
                </template>
              </PsRichTooltip>
            </div>
            <vue-skeleton-loader
              v-else
              :width="60"
              :height="25"
              class="rounded psui-ml-auto"
            />
          </div>

          <div
            v-for="climate_zone in lastJurisdictionVisited.climate_zones"
            :key="climate_zone.raw"
            class="card-prototype-item psui-w-full psui-relative"
            :class="displayClimateZones.includes(index) ? 'psui-flex psui-flex-col' : 'psui-hidden'"
          >
            <div class="card-prototype-title psui-flex psui-items-center psui-w-full">
              <div
                class="psui-flex psui-items-center"
                @mouseenter="onHoverClimateZone(climate_zone.raw)"
                @mouseleave="onHoverClimateZone(false)"
              >
                <PsCheckboxSimple
                  class="psui-text-gray-60"
                  :disabled="isPrototypeClimateZoneDisabled(prototype, climate_zone.raw)"
                  :checked="
                    checkIfClimateZoneIsSelected({
                      climate_zone_raw: climate_zone.raw,
                      prototype,
                    })
                  "
                  @change="
                    toggleClimateZoneSelected({
                      climate_zone_raw: climate_zone.raw,
                      prototype,
                    })
                  "
                >
                  <template #content>
                    <p
                      class="psui-text-small"
                      :class="
                        isPrototypeClimateZoneDisabled(prototype, climate_zone.raw)
                          ? 'psui-text-gray-40'
                          : 'psui-text-gray-60'
                      "
                    >
                      Climate Zone {{ climate_zone.prefix }}
                    </p>
                    <small
                      v-if="isPrototypeClimateZoneDisabled(prototype, climate_zone.raw)"
                      class="ml-3"
                    >This climate zone is not available</small>
                  </template>
                </PsCheckboxSimple>
                <div
                  v-show="
                    isHoveringClimateZone === climate_zone.raw &&
                      isPrototypeClimateZoneDisabled(prototype, climate_zone.raw)
                  "
                  class="psui-ml-1 psui-cursor-default psui-flex psui-items-center"
                >
                  <PsRichTooltip
                    layout="gray"
                    class="psui-flex psui-leading-none"
                  >
                    <template #trigger>
                      <PsIcon
                        icon="info"
                        size="16"
                        class="psui-text-gray-40 psui-cursor-default psui-items-center psui-justify-center"
                        :style="{ display: 'flex' }"
                      />
                    </template>
                    <template #content>
                      <p class="psui-text-gray-80 psui-text-xsmall">
                        This policy option cannot be applied to climate zone
                        {{ climate_zone.prefix }} because one or more necessary requirements for your selected policy option were not found to be
                        cost-effective. 
                      </p>
                    </template>
                  </PsRichTooltip>
                </div>
              </div>
              <div class="card-prototype-units psui-ml-auto psui-cursor-default">
                <PsRichTooltip class="psui-flex psui-leading-none">
                  <template #trigger>
                    <div class="psui-flex psui-items-center">
                      <p class="psui-text-small psui-text-gray-50 psui-ml-auto mr-1">
                        {{ `${formatUnits(getAllUnitsByTypePrototype(prototype, climate_zone.raw))} units` }}
                      </p>
                      <PsIcon
                        icon="info"
                        size="16"
                        class="psui-text-gray-40 text-opacity-0 psui-cursor-default psui-items-center psui-justify-center"
                        :style="{ display: 'flex' }"
                      />
                    </div>
                  </template>
                  <template #content>
                    <p class="psui-font-bold psui-text-gray-80 psui-text-xsmall">
                      {{ tooltipMessage() }}
                    </p>
                  </template>
                </PsRichTooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="isLoading && !isSomePrototypeDisabled"
        class="psui-flex psui-items-center psui-mb-5 psui-space-x-2"
      >
        <vue-skeleton-loader
          :width="300"
          :height="25"
        />
      </div>
      <div
        v-if="!isLoading && isSomePrototypeDisabled"
        class="psui-flex psui-items-center psui-justify-start psui-mb-5 psui-space-x-2"
      >
        <p class="psui-text-gray-50 psui-text-small">
          Some prototypes may not be available
        </p>
        <PsRichTooltip
          layout="gray"
          css-class="psui-w-64"
          class="psui-flex psui-leading-none"
        >
          <template #trigger>
            <PsIcon
              icon="info"
              size="16"
              class="psui-text-gray-40 psui-cursor-default psui-items-center psui-justify-center"
              :style="{ display: 'flex' }"
            />
          </template>
          <template #content>
            Other building types are not compatible with this policy option or study results are not available.
          </template>
        </PsRichTooltip>
      </div>
    </div>
    <div
      class="psui-w-full psui-flex psui-items-end psui-space-x-6 psui-py-4 psui-px-12 psui-bg-white psui-shadow-elevation-30 psui-border psui-border-gray-20"
    >
      <div class="ps-input-label psui-w-9/12">
        <PsInput
          id="policy-option-policy-title-input"
          ref="psInput"
          :value="policy.title"
          :label="'Now give a name to your policy:' | policyTextRephrase"
          :placeholder="'My Policy 1' | policyTextRephrase"
          layout="mini"
          @input="$emit('update:policy', { ...policy, title: $event.target.value })"
          @keyup.native.enter="onEnterKey"
        />
      </div>
      <PsTooltip
        position="top"
        :ignore-dialog="!getInputError"
      >
        <template #trigger>
          <span class="create-a-policy-button">
            <PsButton
              data-test-id="policy-option-create-policy-button"
              :disabled="getInputError"
              :label="'Create a policy' | policyTextRephrase"
              size="big"
              @click="nextStep"
            />
          </span>
        </template>
        <template #content>
          {{ getTooltipMessage | policyTextRephrase }}
        </template>
      </PsTooltip>
    </div>
  </div>
</template>

<script>
import { POLICY_OPTIONS_ALGORITHMS } from '@/business-logic/services/policy-options/factory.js'
import { STUDY_TYPES_DB_SLUGS, PROTOTYPES_DB_SLUGS } from '@/util/Enums.js'
import { mapGetters } from 'vuex'
import { INTERCOM_LINK_PER_TYPE_PROTOTYPE_SLUG } from '@/store/global-entities/TypePrototype.js'


export default {
  name: 'PolicyOptionCreate',
  props: ['policyOption', 'policy', 'prototypesSelected', 'study_type_slug'],
  data: () => ({
    displayClimateZones: [],
    isLoading: false,
    isSomePrototypeDisabled: false,
    isHoveringTypePrototype: false,
    isHoveringClimateZone: false,
    isEnterKeyExecuting: false,
    hasRun: false,
  }),
  computed: {
    ...mapGetters(['lastJurisdictionVisited']),
    getAllClimateZones() {
      return this.lastJurisdictionVisited.climate_zones.map((climate_zone) => climate_zone.raw)
    },
    availablePrototypes() {
      const studyTypeAlgorithms = this.policyOption.algorithms
        .filter((a) => a?.study_type_slug && a?.study_type_slug === this.study_type_slug)
        .map((a) => a.name)
      return this.policyOption.prototypes.filter((p) => {
        return (p?.policy_option_algorithms || []).some((pO) => studyTypeAlgorithms.includes(pO))
      }).sort((a, b) => {
        const typePrototypeAOrder = (this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id: a?.type_prototype_id }))?.order ?? 9999
        const typePrototypeBOrder = (this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id: b?.type_prototype_id }))?.order ?? 9999
        return typePrototypeAOrder - typePrototypeBOrder
      })
    },
    getInputError() {
      return !this.policy.title || this.policy.loading || (!this.policyOption.is_advanced && !this.prototypesSelected.length)
    },
    getTooltipMessage() {
      if(!this.policy.title){
        return 'Give your policy a name first.'
      } else if(!this.policyOption.is_advanced && !this.prototypesSelected.length) {
        return 'Select at least one prototype first.'
      } else {
        return ''
      }
    }
  },
  watch: {
    availablePrototypes: {
      handler: async function () {
        await this.resetState()
      },
      immediate: true,
    },
    getAllClimateZones: {
      handler: async function () {
        await this.resetState()
      },
      immediate: true,
      deep: true,
    },
    isLoading(newVal, oldVal) {
      if (this.hasRun) return
      if (newVal !== oldVal && newVal === false) {
        this.availablePrototypes.forEach((prototype, index) => {
          this.selectAllPrototypes(prototype, index)
        })
        this.hasRun = true
      }
    }
  },
  mounted() {
    this.stopImmediatePropagationFunction()
    this.hideIntercom()
  },
  beforeDestroy() {
    this.showIntercom()
  },
  methods: {
    async resetState() {
      if (this.isLoading) return
      this.isLoading = true
      this.isSomePrototypeDisabled = false
      for await (let prototype of this.availablePrototypes) {
        let availableClimateZones = 0
        for await (let climateZone of this.getAllClimateZones) {
          if (!prototype.climateZonesDisabledMessages) {
            prototype.climateZonesDisabledMessages = {}
          }
          prototype.climateZonesDisabledMessages[climateZone] = await this.checkPrototypeClimateZoneDisabled(prototype, climateZone)
          if (prototype.climateZonesDisabledMessages[climateZone]) {
            this.isSomePrototypeDisabled = true
          } else {
            availableClimateZones++
          }
        }
        prototype.availableClimateZones = availableClimateZones
      }
      this.$emit('update:prototypes-selected', [])
      this.displayClimateZones = []
      this.isLoading = false
    },
    onEnterKey() {
      if (this.isEnterKeyExecuting || this.getInputError) {
        return
      } 
      this.isEnterKeyExecuting = true
      this.nextStep()
    },
    stopImmediatePropagationFunction() {
      let prototypeCards = document.querySelectorAll('.prototype-checkbox-simple')
      function handleClick(e) {
        e.stopImmediatePropagation()
      }
      prototypeCards.forEach((prototypeCard) => prototypeCard.addEventListener('click', handleClick, { capture: true }))
    },
    hideIntercom() {
      const intercom = document.querySelector('.intercom-app')
      if (intercom) {
        intercom.style.display = 'none'
      }
    },
    showIntercom(){
      const intercom = document.querySelector('.intercom-app')
      if (intercom) {
        intercom.style.display = 'block'
      }
    },
    getIfClimateZoneDropdownShouldBeToggle(prototype) {
      return this.lastJurisdictionVisited?.climate_zones.length > 1 && !this.checkPrototypeDisabled(prototype)
    },
    toggleArrayOfClimateZones(index, prototype) {
      if (!this.getIfClimateZoneDropdownShouldBeToggle(prototype)) return
      if (this.displayClimateZones.includes(index)) {
        this.displayClimateZones = this.displayClimateZones.filter(climate_zone => climate_zone !== index)
      } else {
        this.displayClimateZones.push(index)
      }
    },
    getClimateZonesLengthByPrototype(prototype_id) {
      return this.prototypesSelected.filter((object) => object.prototype_id === prototype_id)[0]?.climate_zones.length
    },
    getAllUnitsByTypePrototype(prototype, climateZoneRaw = undefined) {
      if (this.study_type_slug === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS) {
        return this.getAllBuildingsEstimatesByTypePrototype(prototype, climateZoneRaw)
      }
      return this.getAllBuildingsStocksByTypePrototype(prototype, climateZoneRaw)
    },
    getAllBuildingsEstimatesByTypePrototype(prototype, climateZoneRaw = undefined) {
      const typePrototypeId = this.getTypePrototype(prototype.type_prototype_id).id
      const filters = {
        type_prototype_id: typePrototypeId,
        prototype_id: prototype.id,
      }
      if (climateZoneRaw) {
        filters.climate_zone_prefix = climateZoneRaw ? climateZoneRaw?.split('-')?.[0] ?? null : undefined
      }
      return this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits'](filters)
    },
    getAllBuildingsStocksByTypePrototype(prototype, climateZoneRaw = undefined) {
      const typePrototypeId = this.getTypePrototype(prototype.type_prototype_id).id
      const filters = {
        type_prototype_id: typePrototypeId,
        prototype_id: prototype.id,
      }
      if (climateZoneRaw) {
        filters.climate_zone_prefix = climateZoneRaw ? climateZoneRaw?.split('-')?.[0] ?? null : undefined
      }
      return this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits'](filters)
    },
    getPrototypeIcon(prototype) {
      return this.getTypePrototype(prototype.type_prototype_id).icon
    },
    getTypePrototype(typePrototypeId) {
      return this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id: typePrototypeId })
    },
    getPrototypeTitle(prototype) {
      return this.getTypePrototype(prototype.type_prototype_id)?.title || prototype?.title || 'Unknown'
    },
    togglePrototypeSelected(prototypeSelected, index) {
      if (
        !this.displayClimateZones.includes(index) &&
        this.checkIfPrototypeIsSelected(prototypeSelected.id) &&
        this.getIfClimateZoneDropdownShouldBeToggle(prototypeSelected)
      ) {
        this.displayClimateZones.push(index)
        return
      }

      if (this.getIfClimateZoneDropdownShouldBeToggle(prototypeSelected) && !this.displayClimateZones.includes(index)) {
        this.displayClimateZones.push(index)
      }

      let newPrototypesSelected = this.prototypesSelected
      const prototypeIsSelected = newPrototypesSelected.find((object) => object.prototype_id === prototypeSelected.id)
      if (newPrototypesSelected.length === 0 || !prototypeIsSelected) {
        const prototypeObject = {
          prototype_id: prototypeSelected.id,
          climate_zones: [...this.getAllClimateZones].filter(
            (climateZone) => !this.isPrototypeClimateZoneDisabled(prototypeSelected, climateZone)
          ),
        }
        newPrototypesSelected.push(prototypeObject)
      }
      else {
        newPrototypesSelected = newPrototypesSelected.filter((object) => object.prototype_id !== prototypeSelected.id)
      }
      this.$emit('update:prototypes-selected', newPrototypesSelected)
    },
    selectAllPrototypes(prototype, index) {
      if(this.isLoading) return 
      if (!this.displayClimateZones.includes(index) && !this.checkPrototypeDisabled(prototype)) {
        let newPrototypesSelected = this.prototypesSelected
        const prototypeIsSelected = newPrototypesSelected.find((object) => object.prototype_id === prototype.id)
        if (newPrototypesSelected.length === 0 || !prototypeIsSelected) {
          const prototypeObject = {
            prototype_id: prototype.id,
            climate_zones: [...this.getAllClimateZones].filter((climateZone) => !this.isPrototypeClimateZoneDisabled(prototype, climateZone)) 
          }
          newPrototypesSelected.push(prototypeObject)
        } else {
          newPrototypesSelected = newPrototypesSelected.filter((object) => object.prototype_id !== prototype.id)
        }
      }
    },
    toggleClimateZoneSelected({ climate_zone_raw, prototype }) {
      let newPrototypesSelected = this.prototypesSelected
      const prototypeIsSelected = newPrototypesSelected.findIndex((object) => object.prototype_id === prototype.id)
      if (newPrototypesSelected.length === 0 || prototypeIsSelected < 0) {
        const prototypeObject = {
          prototype_id: prototype.id,
          climate_zones: [climate_zone_raw],
        }
        newPrototypesSelected.push(prototypeObject)
      } else if (prototypeIsSelected !== -1) {
        const climateZoneIsSelected =
          newPrototypesSelected[prototypeIsSelected].climate_zones.includes(climate_zone_raw)
        climateZoneIsSelected
          ? (newPrototypesSelected[prototypeIsSelected].climate_zones = newPrototypesSelected[
              prototypeIsSelected
            ].climate_zones.filter((climate_zone) => climate_zone !== climate_zone_raw))
          : newPrototypesSelected[prototypeIsSelected].climate_zones.push(climate_zone_raw)
      }

      if (newPrototypesSelected[prototypeIsSelected]?.climate_zones.length === 0) {
        newPrototypesSelected = newPrototypesSelected.filter((object) => object.prototype_id !== prototype.id)
      }

      this.$emit('update:prototypes-selected', newPrototypesSelected)
    },
    forceCheckboxUpdateIfDifferentFromSelectedPrototype(isPrototypeSelected) {
      let prototypeCardsCheck = document.querySelectorAll('.prototype-checkbox-simple')
      function handleChange(e) {
        const targetCheckedValue = e.target.checked
        if (targetCheckedValue !== undefined) {
          if (isPrototypeSelected !== targetCheckedValue) {
            e.target.checked = true
          }
        }
      }
      prototypeCardsCheck.forEach((prototypeCardCheck) =>
        prototypeCardCheck.addEventListener('change', handleChange, { capture: true })
      )
    },
    checkIfPrototypeIsSelected(prototype_id) {
      const isPrototypeSelected = this.prototypesSelected?.find((object) => object.prototype_id === prototype_id)
        ? true
        : false
      this.forceCheckboxUpdateIfDifferentFromSelectedPrototype(isPrototypeSelected)
      return isPrototypeSelected
    },
    checkIfClimateZoneIsSelected({ climate_zone_raw, prototype }) {
      const getPrototypeIndex = this.prototypesSelected.findIndex((object) => object.prototype_id === prototype.id)
      return this.prototypesSelected[getPrototypeIndex]?.climate_zones?.find(
        (climate_zone) => climate_zone === climate_zone_raw
      )
        ? true
        : false
    },
    nextStep() {
      this.$store.dispatch('setUserLastStudyTypeVisited', {
        value: this.study_type_slug,
        context: 'updateLastStudyTypeVisitedFromPolicyScopeAvailableTypePrototypes',
      })
      this.$emit('nextStep')
      this.isEnterKeyExecuting = false
      this.policy.title = ''
      this.$refs.psInput.$el.querySelector('input').blur()
    },
    checkPrototypeDisabled(prototype) {
      const isAllClimateZonesDisabled = this.getAllClimateZones.every((climateZoneRaw) => {
        return !!prototype?.climateZonesDisabledMessages?.[climateZoneRaw]
      })
      return Boolean(this.isLoading || prototype?.disabled || isAllClimateZonesDisabled)
    },
    isPrototypeClimateZoneDisabled(prototype, climateZone) {
      return Boolean(prototype?.climateZonesDisabledMessages?.[climateZone])
    },
    async checkPrototypeClimateZoneDisabled(prototype, climateZone) {
      const builder = Object.values(POLICY_OPTIONS_ALGORITHMS).find((policyOptionsAlgorithm) => {
        return policyOptionsAlgorithm.policy_option_slug === this.policyOption.slug
      })?.constructor
      if (!builder) {
        return true
      }
      return await new builder(null, [
        {
          prototype_id: prototype.id,
          climate_zones: [climateZone],
        },
      ]).disabledText()
    },
    onHoverTypePrototype(value) {
      this.isHoveringTypePrototype = value
    },
    onHoverClimateZone(value) {
      this.isHoveringClimateZone = value
    },
    openPrototypeDescriptionModal(prototype) {
      const prototypeId = prototype.id
      const { slug: prototypeSlug } = this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: prototypeId })
      if (prototypeSlug === PROTOTYPES_DB_SLUGS.ADU) {
        this.$eventBus.$emit("openDescriptionModal", {
          type: "prototype",
          id: prototypeId,
          button: {
            title: "More Info",
            url: "https://intercom.help/explorer-local-energy-codes/en/articles/6619711-adus"
          }
        })
      } else {
        this.$eventBus.$emit("openDescriptionModal", {
          type: "prototype",
          id: prototypeId,
        })
      }
    },
    tooltipMessage() {
      if (this.$route.query.only_study_type === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS) {
        return 'Estimated number of new units that will be constructed from 2023 through 2025'
      } 
      return 'Estimated number of existing units'
    },
    openPrototypeCard(prototype, index) {
      if (this.checkPrototypeDisabled(prototype)) return
      if (!this.displayClimateZones.includes(index)) {
        this.togglePrototypeSelected(prototype, index)
      }
    },
    getIntercomLinkFromPrototypeSlug(prototype){
      const getTypePrototypeIdByPrototype = this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({id: prototype.id}).type_prototype_id
      const getTypePrototypeSlugByTypePrototypeId = this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({id: getTypePrototypeIdByPrototype}).slug
      return INTERCOM_LINK_PER_TYPE_PROTOTYPE_SLUG[getTypePrototypeSlugByTypePrototypeId]
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep.psui-el-input {
  .psui-el-input-wrapper {
    input {
      padding: 9.5px 16px;
    }
  }
}

::v-deep .psui-el-tooltip {
  flex-grow: 1;
  .psui-el-tooltip-wrapper {
    .psui-el-tooltip-dialog {
      width: 180px;
      z-index: 50;
    }
  }
}

.card-prototype-units {
  .text-opacity-0 {
    opacity: 0;
  }

  &:hover {
    .text-opacity-0 {
      opacity: 1;
    }
  }

   ::v-deep .psui-el-tooltip {
    .psui-el-tooltip-wrapper{
      .psui-el-tooltip-dialog {
        .psui-el-tooltip-content {
          padding: 6px 12px 8px 12px;
        }
      }
    }
  }
}

.card-prototype-tooltip {
  .stretched-link {
    &:after {
      width: 95%; height: 56px;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.card-prototype-list {
  $border-color: #D6DDE5;
  $left: 0.75rem;

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    left: $left; bottom: 17px;
    height: calc(100% - 41.5px);
    border-left: 1px solid $border-color;
  }

  .card-prototype-item {
    padding-left: 2.3rem;

    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      left: $left; bottom: 11.5px;
      width: 23px; height: 100%;
      border-left: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      border-bottom-left-radius: 8px;
    }
  }
}

.create-a-policy-button {
  ::v-deep {
    .psui-el-button.size-big {
      padding: 0.625rem 2.5rem;
    }
  }
}

.ps-input-label {
  ::v-deep {
    .psui-el-input label {
      display: flex;
      line-height: 1;
      margin-bottom: 6px;
    }
  }
}
</style>
