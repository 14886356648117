<template>
  <PsDropdown>
    <template #dropdownTrigger>
      <PsButton
        label="Quick guide"
        layout="onlytext"
        icon="arrow_drop_down"
        size="small"
        icon-position="right"
      />
    </template>

    <template #items>
      <ul class="psui-w-full psui-font-bold psui-my-3">
        <a
          target="_blank"
          href="https://intercom.help/explorer-local-energy-codes/en/collections/3517138-new-construction-quick-guide"
          class="psui-text-gray-60 hover:psui-bg-blue-10 hover:psui-text-blue-60 psui-font-bold psui-cursor-pointer psui-flex psui-items-center psui-gap-3 psui-px-5 psui-py-2 transition-all"
          @click="
            gaEvent(GA_LABELS.MENU_INFO_NEW_CONSTRUCTION_QUICK_GUIDE, {
              value: 'MENU_INFO_NEW_CONSTRUCTION_QUICK_GUIDE',
            })
          "
        >
          <li class="psui-w-auto psui-text-small">
            <span> New Construction </span>
          </li>
        </a>

        <li
          class="psui-font-bold psui-flex psui-items-center psui-gap-3 psui-px-5 psui-py-2"
        >
          <span
            class="psui-w-auto psui-text-small psui-text-gray-40 psui-cursor-default align-itens-left"
          >
            Existing Buildings
          </span>
        </li>
      </ul>
    </template>
  </PsDropdown>
</template>

<script>
import { GA_LABELS } from "@/mixins/GaEventsMixin"

export default {
  name: 'PoliciesListHeaderQuickGuide',
  data: () => ({
    GA_LABELS
  })
}
</script>

<style>

</style>