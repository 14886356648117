<template>
  <div class="psui-w-full psui-flex psui-flex-col psui-items-center psui-py-8 psui-px-6 psui-text-center">
    <img
      src="/images/comparison/choosing-study.png"
      alt="Choosing Study"
      class="psui-inline-block psui-mb-6"
    >
    <p 
      class="psui-text-h5 psui-font-bold psui-text-gray-70 psui-mb-2"
    >
      {{ getComparisionPageDescriptionBasedOnUserPolicies | policyTextRephrase('small') }}
    </p>
    <p 
      v-if="getUserExistingPolicyLength >= 2"
      class="psui-text-p psui-text-gray-60 psui-mb-6"
    >
      You have {{ getUserExistingPolicyLength }} {{ 'policies' | policyTextRephrase('small') }} that you can compare.<br>Comparison is currently only available for Existing Building {{ 'Policies' | policyTextRephrase('small') }},<br>but we are working to expand this functionality for New Buildings as well.
    </p>
    <p
      v-else 
      class="psui-text-p psui-text-gray-60 psui-mb-6"
    >
      {{ 'Explore policy options and create two or more existing building policies to compare.' | policyTextRephrase('small') }} 
      <br>Comparison is currently only available for Existing Buildings,
      <br>but we are working to expand this functionality for New Buildings as well.
    </p>
    <div class="flex gap-2">
      <PsButton
        data-test-id="comparison-list-is-empty-create-comparison-button"
        :icon="getUserExistingPolicyLength >= 2 ? 'dashboard' : 'explore'"
        :label="getUserExistingPolicyLength >= 2 ? 'Create comparison' : 'Explore Existing Building Policy Options' | policyTextRephrase('small')"
        size="small"
        @click="takeToPolicyOptionsOrCreateComparison"
      /> 
    </div>
  </div>
</template>

<script>
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import { mapGetters } from 'vuex'

const COMPARINSONPAGEDESCRIPTION = {
  has_policy: 'You haven’t created any comparisons yet!',
  has_no_policy: 'You don’t have enough policies to start a comparison!',
}

export default {
  name: 'ComparisonListEmpty',
  props: {
    jurisdiction: {
      type: [Object, Boolean],
      default: false
    },
  },
  computed: {
    ...mapGetters(['lastStudyTypeVisited']),
    getComparisionPageDescriptionBasedOnUserPolicies(){
      return this.getUserExistingPolicyLength >= 2 ? COMPARINSONPAGEDESCRIPTION.has_policy : COMPARINSONPAGEDESCRIPTION.has_no_policy
    },
    getUserExistingPolicyLength(){
      return this.$store.getters['policy/getterUserPolicies']()
      .map(policy => policy.policy_containers.map(container => container.study_type.slug)[0])
      .filter(policy_type => policy_type === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS).length
    },
  },
  methods: {
    takeToPolicyOptionsOrCreateComparison(){
      if(this.getUserExistingPolicyLength >= 2){
        this.$eventBus.$emit('openComparisonPoliciesSelectDrawer')
      } else {
        if(this.lastStudyTypeVisited !== STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS) {
          this.$store.dispatch('setUserLastStudyTypeVisited', {
            context: 'updateLastStudyTypeVisitedFromComparisonListEmpty',
            value: STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS
          })
        }
        this.$router.push({
          name: 'PolicyOptions',
          query: { only_study_type: STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS }
        })
      }
    }
  },
}
</script>
