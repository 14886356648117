import GraphQL from '@/util/GraphQL'
import Api from '@/util/Api'

export default class FeatureFlagsApiService {

    static getFeatureFlags (){
        const query = `
        {
          feature_flags {
              id
              type
              value
              feature
            }
          }
        `
        return GraphQL({query,caller:'FeatureFlagsApiService.getFeatureFlags'})
        .then(({data}) => {
          return data.feature_flags
        })
    }

    static getFeatureFlagById (id) {
      const query = `
      {
        feature_flags(
          where:[{column:"id",operation: "=", value:"${id}"}]
        ) {
            id
            type
            value
            feature
          }
        }
      `
      return GraphQL({query,caller:'FeatureFlagsApiService.getFeatureFlagById'})
      .then(({data}) => {
        return data.feature_flags.pop()
      })
    }

    static update_or_create(feature_flag) {
      return Api.post(`/api/admin/feature_flag/update_or_create`, feature_flag)
    }

    static delete(feature_flag_id) {
      return Api.delete(`/api/admin/feature_flag/delete/${feature_flag_id}`)
    }
}