<template>
  <div
    v-if="measuresDic && measure.measures && measuresHasExcludedMeasureNotExcluded(measure.measures)"
    class="p-4 border border-red rounded-sm mt-4"
  >
    <h2 class="text-header-3 text-red pb-2">
      Other Suggested Exclusions
    </h2>
    <template v-for="measure in measure.measures">
      <div
        v-if="measureHasExcludedMeasureNotExcluded(measure)"
        :key="measure.id"
        class="mt-2"
      >
        <ul class="border p-4 border-ash-300">
          <li class="pb-2">
            <strong>Source: </strong> {{ measure.title }}
            <span 
              class="border rounded-sm px-3 py-1 text-accent-2 transition-all hover:bg-black hover:text-white cursor-pointer ml-2"
              @click="$emit('addMeasureToExcludedMeasures', measure.exclude_measures)"
            >
              Add all
            </span>
          </li>
          <ul class="text-header-2 pl-2">
            <template v-for="excluded_measure_id in measure.exclude_measures">
              <li
                v-if="measuresDic[excluded_measure_id] && !measureIsExcluded(excluded_measure_id)"
                :key="`${measure.id}-${excluded_measure_id}`"
              >                  
                <span
                  class="border rounded-sm px-3 py-1 text-accent-2 transition-all hover:bg-black hover:text-white cursor-pointer mr-2"
                  @click="$emit('addMeasureToExcludedMeasures', [excluded_measure_id])"
                >
                  Add
                </span>
                {{ measuresDic[excluded_measure_id].title }}
              </li>
            </template>
          </ul>
        </ul>
      </div>
    </template>
  </div>  
</template>

<script>
export default {
  name: 'AdminStudyItemMeasureFormExclusionSuggestions',
  props: ['measure', 'measuresDic'],
  methods: {
    measuresHasExcludedMeasureNotExcluded(measures) {
      if (!measures) return false
      
      for(const measure of measures) {
        if (this.measureHasExcludedMeasureNotExcluded(measure)) {
          return true
        }
      }

      return false
    },

    measureHasExcludedMeasureNotExcluded(measure) {
      if (!measure.exclude_measures) return false
      
      for ( const measure_id of this.sanitizeMeasureIds(measure.exclude_measures)) {        
        if (!this.measureIsExcluded(measure_id)) {
         return true
        }
      }

      return false
    },

    measureIsExcluded(measureID) {
      return this.measure.exclude_measures.includes(measureID) ? true : false
    },

    sanitizeMeasureIds(measure_ids) {
      return measure_ids.filter((measure_id) => this.measuresDic[measure_id] ? true : false )
    }
  }
}
</script>

<style>

</style>