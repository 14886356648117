import dayjs from 'dayjs'
import ImpactAssumptions from '@/models/ImpactAssumptions'
import getProjectionsByQuarter from './getProjectionsByQuarter'
import getProjectionsInYear from './getProjectionsInYear'

/**
 * Get projections BY YEAR
 */
 export default ({ 
  assumption,
  building_stock_units,
  kwh_savings,
  therms_savings,
  annual_bill_savings,
  initial_cost,
  installation_effects_years,
  subsidy_needed,
  five_year_payback,
  subsidy_needed_care,
  five_year_payback_care
}) => {

  if (!(assumption instanceof ImpactAssumptions)) assumption = new ImpactAssumptions(assumption)

  const projectionsByYear = []
  const projectionsByQuarter = getProjectionsByQuarter({
    assumption, 
    building_stock_units,
    kwh_savings,
    therms_savings,
    annual_bill_savings,
    initial_cost,
    installation_effects_years,
    subsidy_needed,
    five_year_payback,
    subsidy_needed_care,
    five_year_payback_care
  })

  let index = 0
  for(let currentYear = assumption.start_at; dayjs(currentYear).isBefore(dayjs(assumption.getAdvancedFinishAt)); currentYear = dayjs(currentYear).add(1, 'year').format('YYYY')) {
    const currentYearDate = dayjs(assumption.start_at).add(index, 'years')
    projectionsByYear.push(getProjectionsInYear({ currentYearDate, index, projectionsByYear, projectionsByQuarter }))
    index++
  }
  return projectionsByYear
}
