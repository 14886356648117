export const HOME_AVAILABLE_RESOURCES = [
  {
    key: 'cost_effectiveness_studies',
    title: 'Cost-Effectiveness Studies',
    description: 'Explore the latest statewide cost-effectiveness studies and view the findings relevant for your jurisdiction.',
    icon: 'document_scanner',
    path: 'StudyResults'
  },
  {
    key: 'building_estimates',
    title: 'Building Estimates',
    description: 'Review new and existing building stock estimates for any California jurisdiction.',
    icon: 'bar_chart',
    path: 'BuildingEstimates'
  },
]

export const HOME_TESTIMONIALS = [
  {
    description:'I can now find and prepare key information for our community in minutes vs hours. And, with the Explorer\'s sharing features, it is easier than ever to digitally engage with stakeholders.',
    user:'Cory Downs',
    position:'Conservation Specialist',
    jurisdiction:'City of Chula Vista',
  },
  {
    description:`I've used the Explorer since its launch to develop a reach code. Everything I need is right there!`,
    user:'David Freedman',
    position:'Palm Springs Sustainability Commissioner 2015 - 2023',
    jurisdiction: null
  }
]
