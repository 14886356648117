import getStudyColumnsBySelectedStatus from '@/business-logic/services/columns/getStudyColumnsBySelectedStatus'
import { COMPARISON_TYPES } from '@/util/Enums.js'
import { groupBy } from '@/util/Functions'
import { uniqueByKey } from '@igortrindade/lazyfy'
import Jurisdiction from '@/models/Jurisdiction'

export default {
  getterShowComparisonHeader: (state)=>{
    return state.showComparisonJurisdictionSelect
  },
  getterAvailableJurisdictions: (state) => {
    return [...state.availableJurisdictions ]
  },
  
  getterComparisonColumns: (state) => state.comparisonColumns,
  getterExistingBuildingsColumns: (state, getters) => getters.getterComparisonColumns['existingBuildings'],
  getterNewBuildingsColumns: (state, getters) => getters.getterComparisonColumns['newBuildings'],
  
  getterExistingBuildingsColumnsSelected: (state, getters) => getStudyColumnsBySelectedStatus(getters.getterComparisonColumns['existingBuildings'], true),
  getterExistingBuildingsColumnsUnselected: (state, getters) => getStudyColumnsBySelectedStatus(getters.getterComparisonColumns['existingBuildings'], false),

  getterOnlyShowingDifferentAssumptions: (state) => ( buildingType = 'existingBuildings' ) => {
    if (state.comparisonColumns[buildingType]) {
      const differentColumns = [ ...state.comparisonColumns[buildingType].columnGroups.find((columnGroup) => columnGroup.key === 'different_assumptions').columns ]
      const sharedColumns = [ ... state.comparisonColumns[buildingType].columnGroups.find((columnGroup) => columnGroup.key === 'shared_assumptions').columns ]
  
      const differentColumnsActivated = differentColumns.map(column => column.isActive).filter(i => i)
      const sharedColumnsUnactived = sharedColumns.map(column => column.isActive).filter(i => !i)

      if (
          differentColumns.length &&
          ( differentColumns.length === differentColumnsActivated.length ) &&
          ( sharedColumns.length === sharedColumnsUnactived.length )
      ) {
        return true
      } else {
        return false
      }
    }
  },

  getterUserComparisons: state => (filters = null) => {
    const userComparisons = [...state.userComparisons, ...state.userSharedComparisons]
    return filters ? userComparisons.findAllInArray(filters) : userComparisons
  },

  getterUserComparison: state => (filters = null) => {
    const userComparisons = [...state.userComparisons, ...state.userSharedComparisons]
    return filters ? userComparisons.findInArray(filters) : false
  },

  getterComparisonTypeLabel: (state, getters) => comparison_id => {
    const comparisonTypeValue = getters['getterComparisonType'](comparison_id)
    return COMPARISON_TYPES[comparisonTypeValue]?.label ?? ''
  },

  getterJurisdictionsFromUserComparisons : (state, getters) => {
    const userComparisons = getters.getterUserComparisons()
    return uniqueByKey(userComparisons, 'jurisdiction_id').map((comparison) =>  new Jurisdiction(comparison.jurisdiction))
  },

  getterUserCanEditComparison: (state, getters, rootState, rootGetters) => comparison_id => {
    const comparison = getters.getterUserComparison({ id: comparison_id })
    if(!comparison) return false
    const loggedUser = rootGetters['getterLoggedUser']
    const deviceInfo = rootGetters['general/getterDeviceInfo']
    return ((loggedUser && comparison.user_id === loggedUser.id) || (deviceInfo && comparison.user_device_id === deviceInfo.id))
  },

  getterUserComparisonsGroupedByJurisdiction: (state, getters) => {
    const userComparisons = getters.getterUserComparisons()
    if (userComparisons.length > 0) {
      return groupBy(userComparisons, (c) => c.jurisdiction_id )
    } else {
      return false
    }
  },

  getterComparisonType: (state, getters, rootState, rootGetters) => comparison_id => {
    const comparisonFromState = state.userComparisons.find((comparison => comparison.id == comparison_id))

    if(!comparisonFromState) return null
    
    const loggedUser = rootGetters['getterLoggedUser']
    const deviceInfo = rootGetters['general/getterDeviceInfo']

    if ((loggedUser && comparisonFromState.user_id === loggedUser.id) || (deviceInfo && comparisonFromState.user_device_id === deviceInfo.id)) {
      return COMPARISON_TYPES.OWN.value
    }

    return COMPARISON_TYPES.SHARED.value
  },

}
