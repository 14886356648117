import packageJson from '@/../package.json'

export default {
  getterLoaderShow: state => state.loaderShow,
  sidebarWidth: (state) => {
    return state.sidebar.width + 'px !important'
  },
  getterAppVersion: () => packageJson.version,
  getterAppVuexVersion: () => packageJson.vuexVersion,
  getterDeviceInfo: state => state.deviceInfo,
  getterDeviceIsAdminOrTeam: state => state.deviceInfo?.is_admin || state.deviceInfo?.is_team,
  getterAdminOrTeamDeviceAndUser(state, getters) {
    if(!getters.getterDeviceIsAdminOrTeam) return false
    return state.adminAndTeamDevices.findInArray({ id: getters.getterDeviceInfo.id })
  },
  getterSessionInfo: (state) => {
    return {
      ...state.deviceInfo,
      appVersion: packageJson.version,
      vuexVersion: state.vuexVersion
    }
  },
  getterContentContainerWidth: state => state.contentContainerWidth,
}
