<template>
  <div 
    class="table-container psui-w-full psui-bg-white psui-shadow-elevation-5 psui-rounded-md psui-mb-4 overflow-y-auto"
    style="max-height: calc(95vh - 153.974px)"
  >
    <div
      class="table-header table-header-box-shadow psui-w-full psui-flex psui-items-center psui-bg-white psui-pt-5 psui-pb-3 psui-z-20 psui-top-0"
    >
      <div class="table-header-name psui-w-1/3 psui-max-w-md psui-pl-4">
        <h3 class="psui-font-bold psui-text-gray-80 opacity-100-all-childrens-on-hover psui-flex psui-items-center">
          Comparison Name
          <button
            class="focus:outline-none ml-2 w-4 h-4 psui-flex psui-items-center psui-justify-center"
            :class="{ 'opacity-0' : orderByColumn != 'title' }"
          >
            <feather
              size="16"
              class="text-blue cursor-pointer"
              :type="orderByDirection == 'asc' ? 'arrow-down' : 'arrow-up'"
              @click="setOrderColumn('title')"
            />
          </button>
        </h3>
      </div>

      <div class="psui-w-1/4">
        <h3 class="psui-font-bold psui-text-gray-80 opacity-100-all-childrens-on-hover psui-flex psui-items-center">
          City/County
          <button
            class="focus:outline-none ml-2 w-4 h-4 psui-flex psui-items-center psui-justify-center"
            :class="{ 'opacity-0' : orderByColumn != 'jurisdiction_title' }"
          >
            <feather
              size="16"
              class="text-blue cursor-pointer"
              :type="orderByDirection == 'asc' ? 'arrow-down' : 'arrow-up'"
              @click="setOrderColumn('jurisdiction_title')"
            />
          </button>
        </h3>
      </div>

      <div class="psui-w-1/5">
        <h3 class="psui-font-bold psui-text-gray-80 opacity-100-all-childrens-on-hover psui-flex psui-items-center">
          {{ 'Policies' | policyTextRephrase }}
        </h3>
      </div>

      <div class="psui-w-1/5">
        <h3 class="psui-font-bold psui-text-gray-80 opacity-100-all-childrens-on-hover psui-flex psui-items-center">
          Author
        </h3>
      </div>

      <div class="psui-w-1/5">
        <h3 class="psui-font-bold psui-text-gray-80 opacity-100-all-childrens-on-hover psui-flex psui-items-center">
          Unique views
          <PsIcon
            icon="info"
            size="16"
            class="psui-text-blue-50 psui-opacity-0 psui-transition psui-leading-none psui-cursor-pointer psui-ml-1"
            display="flex"
            @click.native="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: 'policy_comparison_unique_views' })"
          />
        </h3>
      </div>

      <div class="psui-w-auto psui-pr-4">
        <i class="psui-icon psui-text-white">more_horiz</i>
      </div>
    </div>

    <div 
      v-for="(comparison, index) in comparisonsOrdered"
      :key="`${index}-${comparison.id}`"
      :data-test-id="`comparison-list-table-comparison-id-${comparison.id}`"
      class="table-body psui-w-full psui-flex psui-items-center overflow-y-auto py-3 psui-text-gray-60 hover:psui-bg-blue-10 hover:psui-text-blue-60 transition-all"
    >
      <div class="table-body-name psui-w-1/3 psui-max-w-md psui-pl-4">
        <router-link :to="`/comparisons/${comparison.id}`">
          <PsRichTooltip
            v-if="getComparisonTypePrototypesToolTipContent(comparison)"
            layout="blue"
          >
            <template #trigger>
              <h3>{{ comparison.title }}</h3>
            </template>
            <template #content>
              <span class="psui-font-bold psui-break-words psui-block psui-mb-1">
                {{ comparison.title }}
              </span>
              <span class="psui-font-normal">
                {{ getComparisonTypePrototypesToolTipContent(comparison) }}
              </span>
            </template>
          </PsRichTooltip>
          <h3 v-else>
            {{ comparison.title }}
          </h3>
        </router-link>
      </div>

      <div class="psui-w-1/4">
        <h3 :data-test-id="`comparison-list-table-jurisdiction-title_type-${comparison.jurisdiction.title_type}`">
          <PsTooltip 
            :ignore-dialog="comparison.jurisdiction.type !== JURISDICTION_TYPES.COUNTY && comparison.jurisdiction.type !== JURISDICTION_TYPES.CCA && comparison.jurisdiction.type !== JURISDICTION_TYPES.REN && comparison.jurisdiction.type !== JURISDICTION_TYPES.COG_CAG && comparison.jurisdiction.type !== JURISDICTION_TYPES.UTILITY && comparison.jurisdiction.type !== JURISDICTION_TYPES.AQMD"
            :class="{ 'cursor-pointer': comparison.jurisdiction.type === JURISDICTION_TYPES.COUNTY || comparison.jurisdiction.type === JURISDICTION_TYPES.CCA || comparison.jurisdiction.type === JURISDICTION_TYPES.REN || comparison.jurisdiction.type === JURISDICTION_TYPES.COG_CAG || comparison.jurisdiction.type === JURISDICTION_TYPES.UTILITY || comparison.jurisdiction.type === JURISDICTION_TYPES.AQMD }"
          >
            <template #trigger>
              {{ comparison.jurisdiction | editJurisdictionTitleTypeBasedOnJurisdictionType('title_type', true) }}
            </template>
            <template #content>
              {{ getTooltip(comparison.jurisdiction) }}
            </template>
          </PsTooltip>
        </h3>
      </div>

      <div class="psui-w-1/5">
        <h3>
          {{ comparison.policies.length }}
        </h3>      
      </div>

      <div class="psui-w-1/5">
        <h3 :data-test-id="`comparison-list-table-comparison-type-${getComparisonType(comparison)}`">
          {{ getComparisonType(comparison) }}
        </h3>
      </div>

      <div class="psui-w-1/5">
        <ComparisonStatistics
          type="unique" 
          :comparison_id="comparison.id" 
        />
      </div>
      
      <div class="psui-w-auto psui-flex psui-items-center psui-pr-4">
        <ComparisonListTableUserActions :comparison="comparison" />
      </div>
    </div>
  </div>
</template>

<script>
import ComparisonListTableUserActions from './ComparisonListTableUserActions.vue'
import ComparisonStatistics from '../statistics/ComparisonStatistics.vue'
import { COMPARISON_TYPES, JURISDICTION_TYPES } from '@/util/Enums.js'
import UserEventApiService from '@/services/api/UserEventApiService'
import { truncateString } from "@/util/Helpers"
import { mapGetters } from 'vuex'
import { joinCommaPlusAnd } from '@/util/Functions'

export default {
  name: 'ComparisonListTable',  
  components: { 
    ComparisonListTableUserActions, 
    ComparisonStatistics
  },
  props: ['comparisons'],
  data: () => ({
    COMPARISON_TYPES,
    JURISDICTION_TYPES
  }),
  computed: {
    ...mapGetters(['getterLoggedUser']),
    orderByColumn: {
      get() {
        return this.$store.state.auth.user_preferences?.policies?.myPolicies?.orderByColumn ?? 'title'
      },
      set(value) {
        this.$store.dispatch('setUserPreferences', { payload: { path: 'policies.myPolicies.orderByColumn', value } })
      }
    },
    orderByDirection: {
      get() {
        return this.$store.state.auth.user_preferences?.policies?.myPolicies?.orderByDirection ?? 'asc'
      },
      set(value) {
        this.$store.dispatch('setUserPreferences', { payload: { path: 'policies.myPolicies.orderByDirection', value } })
      }
    },
    comparisonsOrdered() {
      return this.comparisons.map(item => item)
      .sort((a, b) => {        
        const valueA = this.orderByColumn === 'jurisdiction_title' ? this.parsejurisdictionAsFullText(a.jurisdiction, true) : a[this.orderByColumn]
        const valueB = this.orderByColumn === 'jurisdiction_title' ? this.parsejurisdictionAsFullText(b.jurisdiction, true) : b[this.orderByColumn] 
        return ((valueA?.toLowerCase() || '') < (valueB?.toLowerCase() || '') ? -1 : 1) * (this.orderByDirection == 'asc' ? 1 : -1)
      })
    },
    getAllUserComparisons() {
      return this.$store.getters['comparison/getterUserComparisons']()
    },
  },
  methods: {
    addView(comparison) {
      UserEventApiService.log({ event: 'COMPARISON_SHOW', table: 'comparisons', table_item_id: comparison.id })
    },
    addShare(comparison) {
      UserEventApiService.log({ event: 'COMPARISON_SHARE_URL', table: 'comparisons', table_item_id: comparison.id })
    },
    getComparisonDescription(string) {
      return truncateString(string, 150)
    },
    getAllUserComparisonsByJurisdiction() {
      const comparisons_ids = this.$store.getters['comparison/getterUserComparisonsGroupedByJurisdiction']()[this.getFilterByJurisdiction]?.map((i) => i.id).join(',')
      this.$router.push({ name: 'ComparisonList', query: { jurisdiction_id: this.getFilterByJurisdiction, comparisons_ids }})
    },
    setOrderColumn(column) {
      if(this.orderByColumn == column) {
        this.orderByDirection = (this.orderByDirection == 'asc') ? 'desc' : 'asc'
      } else {
        this.orderByColumn = column
        this.orderByDirection = 'asc'
      }
    },
    getComparisonType(comparison){
      return COMPARISON_TYPES[this.$store.getters['comparison/getterComparisonType'](comparison.id)].label_for_table
    },
    getComparisonTypePrototypes(comparison){
      const typePrototypes = comparison.policies.flatMap(policy =>
        policy.policy_containers.map(container => container.type_prototype.title)
      )
      return new Set(typePrototypes)
    },
    getComparisonTypePrototypesToolTipContent(comparison){
      if (comparison.description) {
        return comparison.description
      }
      return `This comparison is comparing the impact of ${Array.from(this.getComparisonTypePrototypes(comparison)).length} different building types ${joinCommaPlusAnd(Array.from(this.getComparisonTypePrototypes(comparison)))}.`
    },
    getTooltip(jurisdiction){
      if(jurisdiction.titles.type === JURISDICTION_TYPES.COUNTY) {
          return jurisdiction.titles.type_long
      } else  {
        return jurisdiction.titles.title
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .psui-el-tooltip {
    .psui-el-tooltip-wrapper {
      .psui-el-tooltip-dialog {
        width: 180px;
        left: inherit !important;
        z-index: 50;

        .psui-el-tooltip-content {
          span {
            line-height: 120%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }

  .table-header-box-shadow {
    position: relative;
  }

  .table-header-box-shadow::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 1rem;
    right: 1rem;
    height: 5px;
    box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.05);
    pointer-events: none;
  }

  .table-container {
    padding-bottom: 16px;
    
    .table-header {
      position: sticky;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .table-header,
    .table-body {
      &-name {
        h3 {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 90%;
        }
      }
    }

    .table-body:nth-of-type(n) {
      padding-left: 1rem;
      padding-right: 1rem;
      position: relative;
    }

    .table-body:nth-of-type(n)::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 1rem;
      right: 1rem;
      border-top-width: 1px;
      border-color: #E6ECF2;
    }
  }
</style>
