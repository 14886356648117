<template>
  <div class="psui-pb-6">
    <small><strong>Debug below the Measure and Study that the algorithm is using to calculate impacts </strong></small>
    <table
      v-if="perHomeImpact"
      class="psui-table-auto"
    >
      <thead>
        <th class="pr-6 py-2">
          Fuel
        </th>
        <th class="pr-6 py-2">
          Measure
        </th>
        <th class="pr-6 py-2">
          Study
        </th>
      </thead>
      <tbody>
        <tr
          v-for="fuel in perHomeImpact"
          :key="fuel.fuel_slug"
        >
          <td class="psui-pr-6 psui-align-top">
            {{ fuel.title }}
          </td>
          <td class="psui-pr-6 psui-align-top">
            <span
              v-if="fuel.studyData.measure"
              v-go-to-admin-edit="{ name: 'AdminStudyItemEdit', params: { study_item_id: fuel.studyData.measure.id, type: 'measure' }}"
            >
              {{ fuel.studyData.measure.title }}
            </span>
            <span v-else>Virtual Study Data</span>
          </td>
          <td class="psui-pr-6 psui-align-top">
            <span v-go-to-admin-edit="getStudyEditRoute(fuel.studyData.study_id)">             
              {{ $store.getters['globalEntities/Study/getterGlobalStudy']({ id: fuel.studyData.study_id }).title }} ({{ $store.getters['globalEntities/Study/getterGlobalStudy']({ id: fuel.studyData.study_id }).released_at_formatted }})
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { PolicyImpactAlgorithmFactory } from '@/business-logic/services/impact-algorithms/policy/factory'

export default {
  name: 'EditRequirementsNewBuildingsSD2022AdminInfo',
  props: [
    'requirement-all-electric', 
    'requirement-mixed-fuel',
    'policy'
  ],
  data: () => ({
    perHomeImpact : null
  }),
  watch: {
    $props: {
      deep: true,
      handler() {
        this.initAlgorithm()
      }
    }
  },
  mounted() {
    this.initAlgorithm()
  },
  methods: {
    getStudyTitle(custom_combination) {
      const prototype = this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: custom_combination.prototype_id })
      const study = this.$store.getters['globalEntities/Study/getterGlobalStudy']({ id: prototype.study_id })
      return study.title
    },
    
    async initAlgorithm() {
      this.perHomeImpact = null
      if([this.requirementAllElectric, this.requirementMixedFuel].length == 0) return
      const impactAlgorithm = PolicyImpactAlgorithmFactory.createByCustomCombinations([this.requirementAllElectric, this.requirementMixedFuel], this.policy)      
      impactAlgorithm.computePerHomeImpact()
        .then((result) => {
          this.perHomeImpact = this.$set(this, 'perHomeImpact', result)
        })
    },
    getStudyEditRoute(study_id) {
      const study = this.$store.getters['globalEntities/Study/getterGlobalStudy']({ id: study_id })
      return {
        name: 'AdminStudyGroupStudyEdit',
        params: {
          study_group_id: study.study_group_id,
          study_id
        }
      }
    }
  }
}
</script>

<style scoped>
  table * {
    font-size: 12px !important;
  }
</style>