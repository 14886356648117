<template>
  <thead class="sticky z-15 top-0 bg-white py-2 text-header-2">
    <tr>
      <th class="sticky z-10 left-0 bg-white psui-text-gray-80 text-header-3">
        <span>Measures & Packages</span>
      </th>
      <th
        v-for="columnGroup of columnsSelectedForStudy.columnGroups" 
        :key="columnGroup.key"
        :colspan="columnGroup.columns.reduce( (prev, cur) => cur.isActive ? prev + 1 : prev ,0)"
        class="text-left bg-white psui-text-gray-80"
      >
        <div
          class="flex items-center psui-text-p font-bold psui-leading-none"
          :class="{'no-max-width': columnGroup.key === 'city_wide_impact'}"
        >
          <AppInfoHelper 
            :absolute="true" 
            :size="18"
            position="right" 
            class="info-helper"
            @icon-click="$eventBus.$emit('openDrawerContent', columnGroup.hasHelper)"
          >
            <span>{{ columnGroup.title }}</span>
          </AppInfoHelper>
          <span 
            v-if="columnGroup.key === 'city_wide_impact'"
            class="city-wide-actions flex hide-if-collapsed transition-all"
          >
            <PsButton 
              label="Edit Assumptions"
              layout="onlytext"
              icon="tune"
              size="small"
              class="psui-ml-6 psui-mr-1"
              @click.native="openAssumptionsDrawer"
            />
            <PsButton
              label="Chart"
              layout="onlytext"
              icon="bar_chart"
              size="small"
              @click.native="$emit('openProjectionsModal', { columnKey: columnGroup.columns[0].key })"
            />
          </span>
        </div>
      </th>
    </tr>
    <tr>
      <th class="sticky z-10 left-0 bg-white text-gray-50 psui-text-xsmall psui-font-normal psui-text-gray-50 desc">
        <span v-if="studyType === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS">       
          Select the measures you want to combine <br>to create your {{ 'policy' | policyTextRephrase }}.          
        </span>
        <span v-else>          
          Explore the impact, cost-effectiveness, and <br>compliance margins for the studied packages.
        </span>
      </th>
      <template v-for="columnGroup of columnsSelectedForStudy.columnGroups">
        <th 
          v-for="column of columnGroup.columns" 
          :key="columnGroup.key + '-' + column.key" 
          class="font-bold opacity-100-all-childrens-on-hover bg-white align-top pl-4"
        >
          <div
            class="flex flex-col items-end ml-auto"
            @mouseleave="toggleWarningAlert($event, false, column)"
          >
            <span class="text-header-2 psui-text-gray-80 relative flex flex-row">
              <AppInfoHelper 
                :absolute="false" 
                size="16"
                position="left"
                class="info-helper"
                @icon-click="$eventBus.$emit('openDescriptionModal', { type: column.hasHelper.type, id: column.hasHelper.id, slug: column.hasHelper.slug, button: column.hasHelper.customButton})"
              >
                <button class="flex focus:outline-none order-1 mr-1">
                  <span 
                    :type="orderDirection == 'asc' ? 'arrow_upward' : 'arrow_upward'"
                    size="16"
                    class="psui-text-blue-50 cursor-pointer material-icons-round psui-text-p opacity-0 psui-text-p psui-leading-none transition-all duration-300 ease-in" 
                    @click="$parent.setOrderColumn({ orderColumn: column.key })" 
                  >{{ orderDirection == 'asc' ? 'arrow_downward' : 'arrow_upward' }}</span>
                </button>
                <span
                  class="order-3 ml-1"
                  :class="{'is-warning-column': canShowColumnWarning(column, study.id)}"
                >{{ updateColumnDescriptionCopies(study.id, column).title || column.title }}</span>
                <span
                  v-if="canShowColumnWarning(column, study.id)"
                  class="svg-position"
                  @mouseover="toggleWarningAlert($event, true, column)"
                >
                  <span class="cursor-pointer">
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                    >
                      <circle
                        cx="5"
                        cy="5"
                        r="4.5"
                        fill="#EDAB3E"
                      />
                    </svg>
                  </span>
                </span>
              </AppInfoHelper>             
            </span>
            <span class="psui-text-gray-50 psui-text-xsmall font-normal">{{ updateColumnDescriptionCopies(study.id, column).description }}</span>
          </div>
        </th>
      </template>
    </tr>
  </thead>
</template>

<script>
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import { updateColumnDescriptionCopies } from '@/util/Functions.js'

export default {
  name: 'ForecastTableHead',
  props:['study', 'studyType'],
  data: () => ({
    STUDY_TYPES_DB_SLUGS,
    updateColumnDescriptionCopies
  }),
  computed: {
    columnsSelectedForStudy() {
      return this.$parent.columnsSelectedForStudy
    },
    orderColumn() {
      return this.$parent.orderColumn
    },
    orderDirection() {
      return this.$parent.orderDirection
    },
  },
  methods:{
    openAssumptionsDrawer(){
      this.$store.commit('assumptions/openDrawerAtImpactTab')
    },
    canShowColumnWarning(column, studyId) {
      const enabledKeys = ['annual_bill_savings', 'lifecycle_savings', 'forecast_lifecycle_savings']
      // Let's create a config in the future to enable those kind of warnings. For a while, it will be done right here because of the deadline for webinar
      return enabledKeys.includes(column.key) && +studyId === 48
    },
    toggleWarningAlert(event, show, column) {
      if (!show) {
        this.$eventBus.$emit('closeConfirmPopover', { preventWhenHovering: true })
        return
      }
      this.$eventBus.$emit('openConfirmPopover', {
        targetElem: event.target,
        text: `Values displayed are based on 2025 escalation rates. Download the full set of results <a style="text-decoration: underline" href="https://localenergycodes.com/download/1799/file_path/fieldList/2022%20Single%20Family%20Retrofits%20Study%20Data.xlsx" target="_blank">here</a>.`,
        textAsHtml: true,
        side: 'right',
        buttons: [],
        disableFooter: true,
        sumTop: -7,
        sumLeft: 5
      })
    },
  }
}
</script>

<style scoped lang="scss">
  .no-max-width {
    max-width: unset !important;
  }

  .is-warning-column {
    padding-right: 12px;
  }

  .svg-position {
    position: absolute;
    right: calc(-1rem + 15px);
    top: 4px;
    ::v-deep .psui-el-tooltip-trigger {
      margin-right: calc(0.313rem + 1px); // 5px
      margin-bottom: 0.125rem; // 2px
      margin-left: -0.094rem; // 1.5px
    }
  }
</style>