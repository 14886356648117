<template>
  <div class="assumption_tab">
    <PsAccordion class="psui-px-6 pt-5">
      <AssumptionsImpactsItems
        data-test-id="assumptions-drawer-items"
        :get-tab-selected="getTabSelected"
        :user-can-edit-policy="getterUserCanEditPolicy"
        :electrification-rate-should-not-been-shown="electrificationRateShouldNotBeenShown"
      />
    </PsAccordion>
  </div>
</template>

<script>
import AssumptionsImpactsItems from '@/modules/app/assumptions/impacts/AssumptionsImpactsItems.vue'

import { mapGetters } from "vuex"
import { ASSUMPTIONS_DRAWER_TYPES, ASSUMPTIONS_PATHS } from '@/util/Enums'
import { STUDY_TYPES_DB_SLUGS } from '@/store/global-entities/StudyType.js'

export default {
  name: 'AssumptionsImpacts',
  filters: {
    capitalize: (value)=>{
      return value
      .toString()
      .replace(/-/g,' ')
      .replace(/(^\w)|([-\s]\w)/g, match => match.toUpperCase())
    }
  },
  components: { 
    AssumptionsImpactsItems
  },
  props: ['path'],
  data:()=>({
    STUDY_TYPES_DB_SLUGS,
    ASSUMPTIONS_PATHS
  }),
  computed: {
    ...mapGetters(['lastJurisdictionVisited']),
    getDrawerType(){
      return `${this.$store.getters.lastJurisdictionVisited.type}-wide Assumptions`
    },
    getTitle() {
      return this.$route.name !== 'PolicyShow' ? (this.$route.query.only_study_type || this.getPolicyType) : 'Policy Assumptions'
    },
    getTabSelected: {
      get() {
        this.$route.query.only_study_type
        
        const selectedTab = this.$route.query.only_study_type ? 
        this.$store.getters['globalEntities/StudyType/getterGlobalStudyType']({slug:this.$route.query.only_study_type}) : 
        this.$store.getters['assumptions/getterAssumptionsDrawerOptions'].tab
        
        return selectedTab ? {...selectedTab} :
        this.getPolicyType ? this.getStudyTypes.findInArray({slug:this.getPolicyType}) :
        this.getStudyTypes[1]
      },
      set(tab) {
        this.$store.commit('assumptions/updateDrawerTab', tab)
      }
    },
    getStudyTypes(){
      return this.$store.getters['globalEntities/StudyType/getterGlobalStudyTypes']()
    },
    getTabItemsByUrl(){
      return this.$route.name === 'PolicyShow' ? this.getTabItemsByPolicySelected() : this.getStudyTypes
    },
    getPolicyType(){
      return this.$route.params.policy_id ?
      this.$store.getters['policy/getterUserPolicy']({id: this.$route.params.policy_id}).policy_containers[0].study_type.slug 
      : this.lastStudyTypeVisited
    },
    getterUserCanEditPolicy(){
      return this.$route.params.policy_id ?
      this.$store.getters['policy/getterUserCanEditPolicy'](this.$route.params.policy_id) 
      : true
    },
    electrificationRateShouldNotBeenShown(){
      return this.$route.name !== 'PoliciesList' && this.$route.name !== 'PolicyShow'
    }
  },
  watch:{
    getTabSelected() {
      this.updateDrawerType()
    },
    getPolicyType(value){
      const newtab = this.getStudyTypes.findInArray({slug:value}) || this.getTabSelected
      this.$store.commit('assumptions/updateDrawerTab', newtab)
    }
  },
  mounted() {
    this.updateDrawerType()
    this.$store.commit('assumptions/updateDrawerTab', this.getTabSelected)
  },
  methods:{
    updateDrawerType() {
      if(this.$route.params.policy_id) return 
      if(this.getTabSelected.slug === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS ) {
        this.$store.commit('assumptions/updateDrawerType', ASSUMPTIONS_DRAWER_TYPES.NEW_RESULTS)
      } 
      if(this.getTabSelected.slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS) {
        this.$store.commit('assumptions/updateDrawerType', ASSUMPTIONS_DRAWER_TYPES.EXISTING_RESULTS)
      }
    },
    getTabItemsByPolicySelected(){
      return [...this.getStudyTypes].map((study_type) => study_type.slug !== this.getPolicyType ? {...study_type, disabled: true} : study_type)
    },
    openBuildingEstimatesAssumptionsDrawer() {
      this.$store.commit('assumptions/updateDrawerPath', ASSUMPTIONS_PATHS.RESIDENTIAL_BUILDINGS)
    }
  }
}
</script>

<style lang="scss" scoped>

  
  .building-estimates-border {
    border-bottom: 1px solid rgba(0, 70, 95, .1);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box;

    &:hover {
      ::v-deep span {
        color: #318FAC;
      }
    }
  }

  ::v-deep {    

    .psui-el-accordion-item {

      &-header {
        position: relative;
        &:before {
          display: none;
          content: "";
          position: absolute;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          top: 23px;
          right: 24px;
          transition: 300ms ease-in-out all;
        }        
      }
      &.form-dirty .psui-el-accordion-item-header::before {
        background: #5DB883;
        display: block;
      }
    }

    .psui-el-accordion-item-content {
      font-size: 16px;
      line-height: 130%;
      color: #515E6A;
    }
    .dynamic {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      font-weight: bold;
      color: #4B8CA6;
      position: relative;
      display: inline-block;
      cursor: pointer;    
      padding: 0;
      min-height: 0;
      &:after {
        content: "";
        position: absolute;
        border-bottom: 1px solid #4B8CA6;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
    .different-from-base-value .dynamic {
      color: #44A06A;
      &:after {
        border-bottom-color: #44A06A;
      }
    }
  }
</style>
