<template>
  <div class="card-main psui-flex psui-flex-col psui-justify-center psui-pt-16">
    <transition
      name="fade"
      mode="out-in"
    >
      <div class="psui-flex psui-flex-col psui-mx-20 psui-mb-8 psui-justify-center psui-text-center card-main-space">
        <img 
          :src="`/images/policy_wizard/step_auto_generate.png`"
          class="psui-mx-auto" 
        >
        <div 
          class="card-main-space"
        >
          <p class="text-20 psui-text-blue-80">
            {{ title }}
          </p>
          <div class="psui-flex psui-justify-center">
            <div class="dot-flashing psui-text-blue-80" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: "ComparePoliciesWizardStepOne",
  props: ['title']
}
</script>

<style scoped lang="scss">
  .card-main {
    width: 90%;

    @media (min-width: 48rem) {
      width: 50rem;
      height: 35.313rem;
    }

    &-space {
      > :not(template) ~ :not(template) {
        --space-y-reverse: 0;
        margin-top: calc(3.313rem * calc(1 - var(--space-y-reverse)));
        margin-bottom: calc(3.313rem * var(--space-y-reverse));
      }
    }
  }

  .btn-back {
    background-color: transparent;
  }
</style>
