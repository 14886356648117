import { noFormatNumber } from './valueNormalizers'
import { GREATER_THEN_ONE_NUMBER } from '@/business-logic/constants/helpersEnum'

export function formatNumberExtended(value, maximumFractionDigits=2, minimumFractionDigits=2, adjustValueFunc=null) {
  if (value === GREATER_THEN_ONE_NUMBER) {
    return '∞'
  }

  const noFormat = noFormatNumber(value)
  if (noFormat) {
    return noFormat
  }
  if (adjustValueFunc) {
    value = adjustValueFunc(value)
  }
  return new Intl.NumberFormat('en-US', { maximumFractionDigits, minimumFractionDigits }).format(value)
}
