export const CUSTOM_COMBINATION_PRESETS = {
  ALL_POSSIBLE_ON_BILL: { policyOptionSlug: 'general-cost-effective-retrofit-on-bill', value: 'ALL_POSSIBLE_ON_BILL', label: `On-Bill (2022)` },
  ALL_POSSIBLE_ON_BILL_OR_TDV: { policyOptionSlug: 'general-cost-effective-retrofit-tdv-or-on-bill', value: 'ALL_POSSIBLE_ON_BILL_OR_TDV', label: `On-Bill (2022) or TDV` },
  ALL_POSSIBLE_ON_BILL_2025: { policyOptionSlug: null, value: 'ALL_POSSIBLE_ON_BILL_2025', label: `On-Bill (2025)`, customCombinationWarn: `The study calculated results based on two alternate forecasts for future electricity and gas costs. These are referred to as "2022 escalation rates" and "2025 escalation rates". In most cases the cost-effectiveness determinations are consistent between the two. By default, the tool uses the 2025 escalation rates for determining the maximum cost-effective score, as they are consistent with the CEC’s 2025 long-term system cost (LSC) compliance metric in the upcoming code cycle.` },
  ALL_POSSIBLE_LSC_2025: { policyOptionSlug: null, value: 'ALL_POSSIBLE_LSC_2025', label: `LSC` },
  ALL_POSSIBLE_ON_BILL_2025_OR_LSC_2025: { policyOptionSlug: null, value: 'ALL_POSSIBLE_ON_BILL_2025_OR_LSC_2025', label: `On-Bill (2025) or LSC` }
}

export const CUSTOM_COMBINATION_FILTERS = {
  NONE: { value: 'NONE', label: `None` },
  CUSTOM: { value: 'CUSTOM', label: `Custom` },
  ...CUSTOM_COMBINATION_PRESETS
}
