<template>
  <BaseAppHeader 
    id="comparison-header"
  >
    <template #top-left>
      <div class="psui-flex psui-items-center psui-flex-shrink-0 psui-w-auto psui-mr-auto psui-justify-self-start">
        <h1 class="psui-text-h4 psui-text-gray-80 psui-font-bold">
          {{ 'Policy Comparison' | policyTextRephrase }}
        </h1>
      </div>
    </template>
    <template #bottom-left>
      <div
        v-if="getAllUserComparisons.length"
        class="psui-flex pb-2"
      >
        <ComparisonListFilters
          :jurisdictions="comparisonsJurisdictions"
          class="psui-flex"
        />        
      </div>
    </template>
    <template #top-right>
      <div class="ps-tooltip-adjustments">
        <PsTooltip
          :ignore-dialog="!(getUserExistingPolicyLength < 2)"
          layout="gray"
          css-class="psui-w-48"
        >
          <template #trigger>
            <PsButton
              data-test-id="comparison-list-header-create-comparison-button"
              label="Create a comparison"
              :disabled="getUserExistingPolicyLength < 2"
              size="small"
              icon="dashboard"
              @click="$eventBus.$emit('openComparisonPoliciesSelectDrawer')"
            /> 
          </template>
          <template          
            #content
          >
            {{ 'Create two or more existing building policies to compare.' | policyTextRephrase }}          
          </template>
        </PsTooltip>
      </div>
    </template>
  </BaseAppHeader>
</template>

<script>
import BaseAppHeader from '@/components/general/BaseAppHeader.vue'
import ComparisonListFilters from './ComparisonListFilters.vue'
import { uniqueByKey } from "@igortrindade/lazyfy"
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'

export default {
  name: 'ComparisonListHeader',
  components:{
    BaseAppHeader, 
    ComparisonListFilters
  },
  computed: {
    getAllUserComparisons() {
      return this.$store.getters['comparison/getterUserComparisons']().filter(Boolean)
    },
    comparisonsJurisdictions() {
      if (!this.getAllUserComparisons) return []
      return uniqueByKey(
        this.getAllUserComparisons,
        "jurisdiction_id"
      ).map((comparison) => comparison.jurisdiction)
    },
    getUserExistingPolicyLength(){
      return this.$store.getters['policy/getterUserPolicies']()
      .map(policy => policy.policy_containers.map(container => container.study_type.slug)[0])
      .filter(policy_type => policy_type === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS).length
    },
  },
}
</script>

<style lang="scss" scoped>
  .ps-tooltip-adjustments {
    ::v-deep {
      .psui-el-tooltip .psui-el-tooltip-wrapper .psui-el-tooltip-dialog .psui-el-tooltip-content.layout-gray {
        padding: 9px 16px 9px 16px;
        .psui-el-tooltip-content-wrapper {
          margin-top: 0rem;
          font-weight: 700;
        }
      }
    }
  }
</style>