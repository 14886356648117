import Api from '@/util/Api'
import asyncCacheResolver from '@/util/asyncCacheResolver'

export default class BuildingEstimateApiService {

  static getJurisdictionUnits(jurisdiction_id) {
    return asyncCacheResolver(`BuildingEstimateApiService.getJurisdictionUnits:${jurisdiction_id}`, () => {
      return Api.get(`/api/building_estimates/get_all?jurisdiction_id=${jurisdiction_id}`, {
        headers: {
          'accept-cache': 'true',
          // 'invalidate-cache': 'true', // ToDO: Remove it!
        }
      }).then(({ data }) => {
        return Array.isArray(data.building_estimates) ? data.building_estimates : []
      }).catch((error) => {
        console.error(`Could not get jurisdiction '${jurisdiction_id}' building estimates: `, error)
        return []
      })
    })
  }

}