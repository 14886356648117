<template>
  <ul
    class="cursor-pointer psui-text-gray-40 psui-text-xsmall font-bold flex items-center space-x-0.5 mb-4 px-6 transition-all"
    @click="updatePath"
  >
    <li>{{ getAssumptionsBreadcumbPath }}</li>
    <li
      v-if="isOnResultsPage"
      class="flex"
    >
      <i class="material-icons psui-text-p leading-4">chevron_right</i>
    </li>
    <li>{{ drawer }}</li>
    <li v-if="isOnResultsPage">
      New Buildings
    </li>
  </ul>
</template>

<script>

export default {
  name: 'AssumptionsBreadcrumb',
  props: ['path', 'drawer','jurisdiction'],
  computed:{ 
    isOnResultsPage() {
      return this.$route.name === 'StudyResults'
    },
    getAssumptionsBreadcumbPath(){
      return this.$route.name === 'StudyResults' ? `${this.jurisdiction.type}-wide Assumptions` :
      this.$route.name === 'PolicyShow' ? 'Policy Impact Assumptions' : 'Building Estimates Assumptions'
    }
  },
  methods: {
    updatePath() {
      this.$emit('update:path', false)
      this.$store.commit('assumptions/updateDrawerIsEditingFromChart', false)
    },
  },
}
</script>

<style scoped>
li:hover {
  transition: inherit;
  opacity: 0.8;
}
</style>
