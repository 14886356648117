<template>
  <div class="app-editor">
    <label v-if="label">{{ label }}</label>
    <TinyMce
      v-model="childValue"
      api-key="tkmhxjyryr01gjnbxt5waapli5cpc9t8e5fr4skm7lcjq6p0"
      :init="init"
    />
  </div>
</template>

<script>
import TinyMce from '@tinymce/tinymce-vue'

export default {
  name: 'ContentEditor',
  components: {
    TinyMce: TinyMce
  },
  props: {
    content: {
      type: String
    },
    height: {
      type: Number,
      default: 400
    },
    label: {
      type: String,
    }
  },
  computed: {
    childValue: {
      get () {
        return this.content
      },
      set (newValue) {
        this.$emit('update:content', newValue)
      }
    },
    init() {
      return {
        height: this.height,
        extended_valid_elements: [
          'app-utility-rates-table', 
          'forecast-assumptions-widget',
          'custom-admin-ps-dialog'
        ],
        custom_elements: [
          'app-utility-rates-table',
          'forecast-assumptions-widget',
          'custom-admin-ps-dialog'
        ],
        setup: (editor) => {
          registerDropdownVueComponents(editor)
        },
        plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
        menubar: 'file edit view insert format tools table help',
        toolbar: 'undo redo | formatselect | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | my-components | help ',
        content_css: ['/tinyMce.css'],
        color_map: [
          '002A3A', 'Blue 01',
          '4B8CA6', 'Blue 02',
          'ffffff', 'White',
          '000000', 'Black',
          '76848A', 'gray',
          '383B44', 'gray02',
          '76848A', 'gray03',
          'F7F7F7', 'gray06',
          '82C2DD', 'blue04',
          'E4EEF2', 'blue05'
        ],
        init_instance_callback: function(editor) {
          editor.on('KeyDown', function(e) {
            if (e.keyCode === 27) {
              console.log('e.keyCode', e.keyCode, editor)
              const editor = window.tinyMCE.activeEditor
              const dom = editor.dom
              const parentBlock = window.tinyMCE.activeEditor.selection.getSelectedBlocks()[0]
              const containerBlock = parentBlock.parentNode.nodeName === 'BODY' ? dom.getParent(parentBlock, dom.isBlock) : dom.getParent(parentBlock.parentNode, dom.isBlock)
              const newBlock = window.tinyMCE.activeEditor.dom.create('p')
              newBlock.innerHTML = '<br data-mce-bogus="1">'
              dom.insertAfter(newBlock, containerBlock)
              const rng = dom.createRng()
              newBlock.normalize()
              rng.setStart(newBlock, 0)
              rng.setEnd(newBlock, 0)
              editor.selection.setRng(rng)
            }
          })
        }
      }
    }
  }
}

function registerDropdownVueComponents(editor) {
  /* example, adding a toolbar menu button */
  editor.ui.registry.addMenuButton('my-components', {
    text: 'Components',
    fetch: function(callback) {
      var items = [
        {
          type: 'menuitem',
          text: 'Utility Rates Table',
          onAction: function() {
            editor.insertContent('&nbsp;<app-utility-rates-table />')
          }
        },
        {
          type: 'menuitem',
          text: 'Forecast Assumptions Widget',
          onAction: function() {
            editor.insertContent('&nbsp;<forecast-assumptions-widget />')
          }
        },
        {
          type: 'menuitem',
          text: 'Info Dialog',
          onAction: function() {
            editor.insertContent('&nbsp;<custom-admin-ps-dialog />')
          }
        },

      ]
      callback(items)
    }
  })
}
</script>

<style lang="scss">
  app-utility-rates-table {
    border: 1px solid red;
  }
</style>
<style lang="scss" scoped>  
  label {
    font-weight: bold;
    font-size: .85rem;
    color: #737273;
    padding-bottom: 5px;
    display: block;
  }
</style>