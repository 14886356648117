<template>
  <div class="psui-card psui-w-full psui-bg-white psui-rounded-lg p-6 psui-shadow-elevation-5 psui-mb-4">
    <div class="psui-card-header psui-flex psui-items-center psui-justify-between psui-mb-4">
      <h4 
        v-if="!customTitle"
        class="psui-text-h6 psui-font-bold psui-text-gray-80"
      >
        {{ title }}
      </h4>

      <div
        v-else
        class="psui-bg-gray-10 psui-p-px psui-rounded psui-w-full"
      >
        <PsBreadcrumb
          :items="title" 
          class="psui-card-breadcrumb psui-m-2"
          @reset-state="getResetState"
        />
      </div>
    </div>
    <CardProjection
      v-if="getPolicyItemSelected"
      class="mt-6"
      :study_data="getPolicyItemSelected"
      :assumption="getPolicyAvgAssumptions"
      :study_data_instance="policy_summary_data.data"
      :column-selected-key="columnSelectedKey"
      @update:columnSelectedKey="$emit('update:columnSelectedKey', $event)"
    />
  </div>
</template>

<script>
import CardProjection from '@/components/projections/CardProjection.vue'

export default {
  name: 'PolicyChartCard',
  components: { CardProjection },
  props: ['policy', 'policy_summary_data', 'columnSelectedKey', 'customTitle'],
  computed: {
    getPolicyItemSelected() {
      return Array.isArray(this.policy_summary_data) ? this.policy_summary_data?.[0] : this.policy_summary_data?.studyData
    },
    getPolicyStudyType(){
      return this.$store.getters['policy/getterPolicyStudyTypes']({policy_id: this.policy?.id, policy: this.policy})[0]
    },
    title() {
      if (this.customTitle) {
        return this.customTitle
      }
      return `Total ${this.policy?.jurisdiction?.titles.wide_type} Impact`
    },
    getPolicyAvgAssumptions() {
      return this.$store.getters['policy/getterPolicyAvgAssumptions']({ policy_id: this.policy?.id })
    }
  },
  methods: {
    getResetState(item) {
      this.$emit('resetPolicyImpactItemSelected', item, this.columnSelectedKey)
    }

  }
}
</script>
