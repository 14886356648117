<template>
  <div class="h-screen max-h-screen overflow-y-hidden flex items-start m-0 relative">
    <TheMaintenanceMode v-if="shouldShowMaintenancePage" />
    <template v-else>
      <router-view />
      <QualityAssuranceModal />
      <DebugVariablesModal />
      <FooterNotesByDomain />
    </template>
  </div>
</template>
<script>
import TheMaintenanceMode from './components/general/TheMaintenanceMode.vue'
import FooterNotesByDomain from './components/general/FooterNotesByDomain.vue'
import QualityAssuranceModal from './components/development/QualityAssuranceModal.vue'
import DebugVariablesModal from './components/development/DebugVariablesModal.vue'
import 'vue-toast-notification/dist/theme-sugar.css'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import { isFeatureFlagEnabled } from '@/util/Functions.js'

export default {
  name: 'App',
  components: { 
    QualityAssuranceModal, 
    FooterNotesByDomain,
    TheMaintenanceMode,
    DebugVariablesModal
  },
  data: () => ({
    isUnderMaintenance : false,
    isFeatureFlagEnabled
  }),
  computed: {
    shouldShowMaintenancePage() {
      const userIsLoggedAsAdmin = this.$store.getters['getterLoggedUserType'] === 'admin' ? true : false
      // const userIsTrackedAsAdminByDevice = this.$store.getters['general/getterDeviceInfo']?.is_admin
      // const userIsAdmin = userIsLoggedAsAdmin || userIsTrackedAsAdminByDevice

      if (this.isUnderMaintenance && (userIsLoggedAsAdmin || this.$route.name === 'AdminSignIn')) {
         return false
      } else if (this.isUnderMaintenance) {
         return true
      } else {
        return false
      }
    }
  },
  created() {
    if (isFeatureFlagEnabled(['PS_TEMPLATE_DESIGN'])) {
      let favicon = document.querySelector("link[rel~='icon']")
      if(favicon) {
        favicon.href = '/new_favicon.png?' + new Date().getTime()
      }
    }

    this.checkForMaintenanceMode()
    this.initStore()
    this.developmentClearCacheHotKeyEventListener()
    this.qaHotKeyEventListener()
    this.debugHotKeyEventListener()
    if (this.$route.query.code) {
      this.tryGoogleLogin()
    }
  },
  methods: {

    initStore() {
      this.$store.commit('assumptions/closeDrawer') // Drawer should always start as closed DDEV-243
      this.$store.dispatch('assumptions/initBuildingsData')
      this.$store.dispatch('globalEntities/loadGlobalEntities')
      this.$store.dispatch('general/checkDeviceInfo')
      this.$store.commit('assumptions/setupImpactAssumptionsModels')
    },

    developmentClearCacheHotKeyEventListener() {
      document.addEventListener("keydown", function(event) {
        if (event.altKey && event.code === "KeyX") {
          event.preventDefault()
          localStorage.clear()
          sessionStorage.clear()
          window.location.reload(true)
        }
      })
    },

    qaHotKeyEventListener() {
      document.addEventListener("keydown", (event) => {
        if (event.altKey && event.code === "KeyQ" && !this.$store.state.general.qaHotKeyPressed) {
          event.preventDefault()
          event.stopImmediatePropagation()
          event.stopPropagation()
          this.$store.commit('general/setQaHotKeyPressed', true)
        }
      })
      document.addEventListener("keyup", (event) => {
        event.preventDefault()
        if(this.$store.state.general.qaHotKeyPressed) this.$store.commit('general/setQaHotKeyPressed', false)
      })
    },

    debugHotKeyEventListener() {
      document.addEventListener("keydown", (event) => {
        if (event.altKey && event.code === "KeyA" ) {
          if(!this.$store.state.general?.deviceInfo?.is_dev) return
          event.preventDefault()
          event.stopImmediatePropagation()
          event.stopPropagation()
          this.$eventBus.$emit('openDebugVariablesModal')
        }
      })
    },

    async checkForMaintenanceMode() {
      const query = `
        {
          custom_field (
            where: [
              { column: "item_type", value: "SYSTEM_MAINTENANCE"}
            ]
          ) {
            item_id
            item_type
            data
          }
        }
      `
      const { data } = await this.$graphql({ query })
      const isUnderMaintenance = data?.custom_field?.data?.SYSTEM_MAINTENANCE?.value === 'true' ? true : false
      this.isUnderMaintenance = isUnderMaintenance
    },

    tryGoogleLogin() {
      const code = this.$route.query.code
      const type = this.$route.name == 'AdminSignIn' ? 'admin' : 'user'
      
      this.$api.get(`/api/auth/google/fetch_user?type=${type}&code=${code}`).then(({ data }) => {
        const token = data.token
        this.$store.commit('setLoggedUserToken', { token, type })      
        
        this.$store.dispatch('getLoggedUser')
        .then(() => {
          if (!this.$store.state.general.redirectTo) {
            if (this.$route.name == 'AdminSignIn') {
              this.$router.push('/admin')
            } else {
              this.$router.push({
                name: 'PoliciesList',
                query: { only_study_type: `${Object.values(STUDY_TYPES_DB_SLUGS).join()}` },
              })
            }
          } else {      
            this.$router.push({path:this.$store.state.general.redirectTo})
          }
        })
      })
      .then(()=>{
        if(this.$store.state.general.redirectTo == '/comparisons/')
          this.$eventBus.$emit('redirectFromGoogleLogin', { isLoading: true })
          this.$store.dispatch('comparison/getUserComparisons')
          .then(()=>{
            this.$eventBus.$emit('redirectFromGoogleLogin', { isLoading:false })
          })
      })
    },
  },
}
</script>
<style lang="scss">
  @import './styles/output/merged.css';
</style>