<template>
  <div
    v-if="!column.isChart" 
    class="psui-flex psui-items-center opacity-100-all-childrens-on-hover justify-end psui-text-small psui-relative"
    :class="isNotCustomCombination ? 'psui-text-gray-80 font-normal' : 'font-bold'"
  >
    <div
      v-if="canShowMaxTag"
      class="psui-flex psui-items-center"
    >
      <AppInfoHelper 
        :absolute="true" 
        :size="18"
        position="left" 
        class="info-helper mr-1"
        @icon-click="$eventBus.$emit('openDescriptionModal', { type: 'helper' , slug: maxTagHelperSlug })"
      />
      <PsTooltip
        class="mr-1/2"
      >
        <template #trigger>
          <PsMiniTag
            layout="solid-info"
            message="MAX"
            class="psui-cursor-pointer"
            @click="$eventBus.$emit('openDescriptionModal', { type: 'helper' , slug: maxTagHelperSlug })"
          />
        </template>
        <template #content>
          {{ maxTagTooltipText | policyTextRephrase}}
        </template>
      </PsTooltip>
    </div>
    <button 
      v-if="column.hasProjections && isNotCustomCombination"
      class="icon-projections transition-all opacity-0 mr-2"
      @click="$emit('openProjectionsModal')"
    >
      <img
        class="ml-2"
        src="/icons/chart_table.svg"
        alt=""
      >
    </button> 
    <transition
      name="fade"
      mode="out-in"
    >
      <span
        :key="studyDataRow.data[column.key]"
        v-tooltip="getColumnTooltip({ column: column, value: studyDataRow.data[column.key] })"
        :data-test-id="`table-content-cell-${column.key}-${getTypePrototypeId}`"
        class="ps-table-cell-value"
      >
        {{ formatStudyData(column.key, studyDataRow.data[column.key], studyDataRow.data, studyDataRow.study_id) }}
      </span>
    </transition>
  </div>
  <div
    v-else
    class="w-full flex justify-end items-center"
  >
    <p 
      :class="isNotCustomCombination ? 'psui-text-gray-80 font-normal' : 'psui-text-gray-80 font-bold'"
      class="mr-2" 
    >
      {{ formatStudyData(column.key, studyDataRow.data[column.key], studyDataRow.data) }}
    </p>
    <SimpleProgressBar
      :value="studyDataRow.data[column.key]"
      :break-even="progressBarBreakEven(column.key)"
    />
  </div>
</template>

<script>
import formatStudyData from '@/formatters/formatStudyData'
import { compliance_margin, edr1_total_margin } from '@/modules/app/shared/default-app-columns'

export default {
  name: 'ForecastTableContentCell',
  props: ['column', 'studyDataRow', 'prototype'],
  computed: {
    isNotCustomCombination() {
      return this.studyDataRow.id ? true : false
    },
    canShowMaxTag() {
      const enabledMaxTagColumnKeys = [compliance_margin().key, edr1_total_margin().key]
      return enabledMaxTagColumnKeys.includes(this.column.key) && this.studyDataRow.is_max === true
    },
    maxTagTooltipText() {
      if (this.column.key === compliance_margin().key) {
        return `Maximum cost-effective compliance margin`
      }
      return `Maximum cost-effective hourly source energy margin`
    },
    maxTagHelperSlug() {
      if (this.column.key === compliance_margin().key) {
        return 'results-new-construction-max-compliance-margin'
      }
      return 'results-new-construction-max-edr1-total-margin'
    },
    getTypePrototypeId(){
      return this.prototype.type_prototype_id ? this.prototype.type_prototype_id : null
    }
  },
  methods: {
    progressBarBreakEven(columnKey) {
      const data = formatStudyData(columnKey, this.studyDataRow.data[columnKey], this.studyDataRow.data)
      if (Number(data) === 0 || data === '--') {
        return this.studyDataRow.data[columnKey]
      }
      return 1
    },
    getColumnTooltip({ column, value }) {
      if (!column.hasTooltipOnContentCell) return false
      return column.hasTooltipOnContentCell({column, value})
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .psui-el-mini-tag {
  border-radius: 1rem;
}
::v-deep .psui-el-mini-tag span {
  padding: 0px 10px 0px 10px; 
}
</style>
