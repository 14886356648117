<template>
  <div
    v-click-outside="close"
    class="w-full flex items-center whitespace-nowrap mr-4"
  >
    <span class="psui-text-small psui-text-gray-60">Measures combined:</span>    
    <PsDropdown
      :key="componentKey"
      ref="PSDropdown"
    >
      <template #dropdownTrigger>
        <div class="flex psui-text-blue-60 psui-text-small psui-cursor-pointer psui-items-center hover:opacity-80">
          <PsIcon
            icon="expand_more"
            size="18"
          />
          <span
            class="psui-text-blue-60 psui-font-bold"
            @click.stop="updateComponent()"
          >{{ getFilterSelected.label }}</span>
        </div>
      </template>
      <template #items>
        <div class="py-3">
          <PsRadioButton
            v-for="option in getOptions"
            :key="option.value"
            :input-id="getOptionKey()"
            :input-value="option"
            :label="option.label"
            :value="value"
            size="small"
            class="px-5 py-1 psui-text-gray-60 psui-font-bold"
            @input="setInputValue"
          />
        </div>
      </template>
    </PsDropdown>
  </div>
</template>

<script>
import { randomString, find } from '@/util/Functions'

export default {
  name: 'CustomCombinationPresetsDropdown',
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: false
    },
  },
  data() {
    return {
      componentKey: 0,
      isOpen: false,
    }
  },
  computed: {
    getOptions() {
      return this.options
    },
    getFilterSelected() {
      return find(this.options, this.value)
    },
  },
  methods: {
    getOptionKey() {
      return randomString(10)
    },
    setInputValue(option) {
      if (option == this.value) return
      this.$emit('input', option)
      this.$emit('setCustomCombinationPreset', option)
      this.$refs.PSDropdown.close()
      this.isOpen = false
    },
    updateComponent() {
      this.componentKey += 1
      this.$nextTick(() => {
        if (this.isOpen == true) {
          this.$refs.PSDropdown.close()
          this.isOpen = false
          return 
        }
        this.$refs.PSDropdown.open()
        this.isOpen = true
      })
    },
    close() {
      this.isOpen = false
    },
  },
}
</script>

<style scoped lang="scss">
  button {
    transition: 300ms ease-in-out all;
    color: #979797;
    &.is-selected, &:hover {
      color: #002A3A;
    }
  }
</style>