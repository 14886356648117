
export default class IndexedDBService {
  
  tablesAvailable = ['user_device_id']
  databaseVersion = 1

  constructor({ databaseName = 'cee', table = 'user_device_id', keyPath = 'id' } = {}) {
    this.databaseName = databaseName
    this.table = table
    this.keyPath = keyPath
    this.db = null
  }

  async openDatabase() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.databaseName, this.databaseVersion)

      request.onerror = () => {
        reject(new Error("Failed to open database"))
      }

      request.onsuccess = () => {
        this.db = request.result
        resolve()
      }

      request.onupgradeneeded = event => {
        const db = event.target.result
        this.tablesAvailable.forEach((table) => {
          if (!db.objectStoreNames.contains(table)) {
            db.createObjectStore(table, { keyPath: this.keyPath })
          }
        })
      }
    })
  }

  async closeDatabase() {
    if (this.db) {
      this.db.close()
      this.db = null
    }
  }

  getStore (operationType = 'readwrite') {
    const transaction = this.db.transaction(this.table, operationType)
    return transaction.objectStore(this.table)
  }

  async add(data) {
    return new Promise((resolve, reject) => {
      const store = this.getStore('readwrite')
      const request = store.put(data)
      request.onerror = (event) => {
        reject(event.target.error)
      }
      request.onsuccess = (event) => {
        console.log('ADD item ao db pelo worker')
        resolve(event.target.result)
      }
    })
  }
  
  async get(id) {
    return new Promise((resolve, reject) => {
      const store = this.getStore('readonly')
      const request = store.get(id)
      request.onerror = (event) => {
        reject(event.target.error)
      }
      request.onsuccess = (event) => {
        console.log('GET item ao db pelo worker')
        if(!event.target.result) {
          resolve(null)
          return
        }

        resolve(event.target.result)
      }
    })
  }

  async getAll() {
    return new Promise((resolve, reject) => {
      const store = this.getStore('readonly')
      const request = store.getAll()
      request.onerror = (event) => {
        reject(event.target.error)
      }
      request.onsuccess = (event) => {
        resolve(event.target.result)
      }
    })
  }

  async delete(id) {
    return new Promise((resolve, reject) => {
      const store = this.getStore('readwrite')
      const request = store.delete(id)
      request.onerror = (event) => {
        reject(event.target.error)
      }
      request.onsuccess = (event) => {
        resolve(event.target.result)
      }
    })
  }

  // deleta all
  async deleteAll() {
    return new Promise((resolve, reject) => {
      const store = this.getStore('readwrite')
      const request = store.clear()
      request.onerror = (event) => {
        reject(event.target.error)
      }
      request.onsuccess = (event) => {
        resolve(event.target.result)
      }
    })
  }

}