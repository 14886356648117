import BasePdfService from '@/services/pdf/BasePdfService'
import Policy from '@/models/Policy'
import Store from '@/store'
import { uniqueByKey } from '@igortrindade/lazyfy'
import MainVuex from '@/store'
import { jurisdictionTitleTypeBasedOnJurisdictionType } from '@/util/Helpers.js'
import { POLICY_TYPE_PDF } from '@/util/Enums.js'
import { checkIfPrototypeStudyHasComplianceMarginEDR1Enabled } from '@/modules/app/policy/show/requirements/requirements-drawer/PolicyEditRequirementsNewBuildingsSF2022/helpers.js'

export default class PolicyCostEffectivenessEvidencePdf extends BasePdfService {

  fontSizeDefault = 10
  policy_id = null
  study_types = []
  studies = []
  climate_zones = []
  type_prototypes = []
  prototypes = []
  vintages = []
  fuels = []
  policy = new Policy()
  tables = []
  policyType = ''

  tablesHeaders = []

  type = POLICY_TYPE_PDF.REQUIREMENTS_SUMMARY

  constructor(args) {    
    super(args)
    this.policy_id = args.policy_id
    this.policyType = args.policyType
  }

  setPdfArgs() {
    this.setPdfTitle()
    this.setPdfSubtitle()
    this.setPdfSources()
  }

  setPdfTitle() {
    this.title = jurisdictionTitleTypeBasedOnJurisdictionType(this.policy.jurisdiction, 'title_type', null, true)
    if (this.title.length >= 48) {
      const words = this.title.split(' ')
      let currentLine = ''
      let lines = []
      for (const word of words) {
        if ((currentLine + ' ' + word).length <= 48) {
          currentLine += ' ' + word
        } else {
          lines.push(currentLine.trim())
          currentLine = word
        }
      }
      lines.push(currentLine.trim())
      this.title = lines.join('\n')
    }

    this.pageHeaderTitle = this.policy.jurisdiction?.titles.title_type
    
    if(this.policyType ==='new') {
       this.headline = 'Requirements Summary'
    } else {
      this.headline = 'Requirements Summary - Prescriptive Compliance Tables'
    }
    this.type = this.headline
  }

  setPdfSubtitle() {
    const { title } = this.policy
    this.subtitle = title.length > 60 ? title.slice(0, 58) + '...' : title
    this.pageHeaderSubtitle = title
  }

  setPdfSources() {
    const policy_custom_combinations = MainVuex.getters['policy/getterPolicyCustomCombinations'](this.policy_id)
    this.prototypes = MainVuex.getters['globalEntities/Prototype/getterGlobalPrototypes']({ id: policy_custom_combinations.map((custom_combination) => custom_combination.prototype_id) })
    for(const prototype of this.prototypes) {
      const study = MainVuex.getters['globalEntities/Study/getterGlobalStudy']({ id: prototype.study_id })
      if(!this.sources.findInArray({ id: study.id })) this.registerSourceStudy(study.id)
    }
  }

  async initPdf() {
    try {
      this.getPolicy()
      this.addTableHeaders()
      this.setPdfArgs()
      await this.generateTables()
      await this.export()
      
    } catch (error) {
      console.error(error)
      throw new Error(error)
    }
  }

  getPolicy() {
    const policy = Store.getters['policy/getterUserPolicies']({ id: this.policy_id })[0]
    this.policy = new Policy(policy)
  }

  generateTables() {

    this.getPolicyContainersWithData().forEach((policy_container, pCIndex) => {

      const type_prototype = Store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id: policy_container.type_prototype_id })
      const isComplianceMarginEDR1Enabled = checkIfPrototypeStudyHasComplianceMarginEDR1Enabled(policy_container)

      let startY 
      
      policy_container.climate_zones.map((climate_zone, cZIndex) => {

        startY = this.doc.lastAutoTable.finalY + 5 || this.margins.top + 30

        const headers = this.getTableHeaders()
        const typePrototypePlusClimateZoneHeader = this.getTableTypePrototypePlusClimateZoneHeader({ headers, type_prototype, climate_zone })
        const head = [typePrototypePlusClimateZoneHeader, headers]
        const body = this.getTableBody(climate_zone.custom_combinations, isComplianceMarginEDR1Enabled)

        if (this.policyType === 'existing' && (pCIndex !== 0 || cZIndex !== 0)) {
          this.addPage()
          startY = this.margins.top + 30
        } else if(this.policyType === 'new'){
          const pageHeight = this.doc.internal.pageSize.height
          const pageHeightAvailable = pageHeight - (startY + this.margins.bottom)  
          const rowHeights = this.calculateRowHeights(body)                  
          if(pageHeightAvailable < rowHeights) {
            this.addPage()
            startY = this.margins.top + 30
          }
        }
  
        this.doc.autoTable({
          theme: 'grid',
          margin: {
            top: this.margins.top + 20,
            bottom: this.margins.bottom + 20,
            left: this.margins.left,
            right: this.margins.right
          },
          styles: {
            font: 'Lato-Regular',
            fontSize: this.fontSizeDefault,
            cellPadding: { top: 4, right: 5, left: 6, bottom: 4 },
          },
          bodyStyles: { lineColor: this.colors.gray10 },
          startY: startY,
          head,
          body,
          willDrawCell: (data) => {
            this.onPolicyRequirementsSummaryTableBreak(data)
          },
          didDrawPage: () => {
            this.addPolicyRequirementsSummaryHeadline()
          },
        })

      })

    })
  }

  addPolicyRequirementsSummaryHeadline() {
    this.doc.setTextColor(this.colors.blue80)
    this.doc.setFontSize(16)
    this.doc.setFont('Lato-Bold')
    let text 
    if(this.policyType ==='new') {
      text = 'Requirements Summary'
    } else {
      text = 'Requirements Summary - Prescriptive Compliance Tables'
    }
    this.doc.text(text, this.margins.left, this.margins.top + 25, { baseline : 'bottom' })
  }

  getTableHeaders() {
    return this.tablesHeaders.map((header) => {
      return { 
        content: header,
        styles: { 
          fontSize: 9,
          font: 'Lato-Bold',
          fillColor: this.colors.gray10,
          textColor: this.colors.gray80,
        } 
      }
    })
  }

  getTableTypePrototypePlusClimateZoneHeader({ headers, type_prototype, climate_zone }) {
    return [{ 
      content: `${ type_prototype.title } in Climate Zone ${climate_zone.prefix}`, 
      colSpan: headers.length,
      isHeadline: true,
      styles: { 
        fontSize: 12,
        font: 'Lato-Bold',
        textColor: this.colors.blue80,
        cellPadding: { top: 8, right: 5, left: 0, bottom: 4 },
        fillColor: '#FFF'
      }
    }]
  }

  getPolicyContainersWithData() {
    return this.policy.policy_containers.map((policy_container) => {
      return {
        ...policy_container,
        climate_zones: this.getPolicyContainerClimateZonesWithCustomCombination(policy_container)
      }
    })
  }

  getPolicyContainerClimateZonesWithCustomCombination(policy_container) {

    const climate_zone_raws = uniqueByKey(policy_container.custom_combinations, 'climate_zone_raw').map((cc) => cc.climate_zone_raw)

    const climate_zones = climate_zone_raws.map((climate_zone_raw) => {
      const climate_zone = Store.getters['globalEntities/ClimateZone/getterGlobalClimateZone']({ raw: climate_zone_raw })
      const custom_combinations = policy_container.custom_combinations.findAllInArray({ climate_zone_raw })

      custom_combinations.sort((a, b) => {

        if(this.policyType === 'new') {
          const typeFuelA = Store.getters['globalEntities/TypeFuel/getterGlobalTypeFuel']({ id: a.fuel.type_fuel_id })
          const typeFuelB = Store.getters['globalEntities/TypeFuel/getterGlobalTypeFuel']({ id: b.fuel.type_fuel_id })
          return typeFuelA.order - typeFuelB.order
        } else {
          const typeVintageA = Store.getters['globalEntities/TypeVintage/getterGlobalTypeVintage']({ id: a.vintage.type_vintage_id })
          const typeVintageB = Store.getters['globalEntities/TypeVintage/getterGlobalTypeVintage']({ id: b.vintage.type_vintage_id })
          return typeVintageA.order - typeVintageB.order
        }

      })
      return {
        ...climate_zone,
        custom_combinations
      }
    })

    climate_zones.sort((a, b) => a.prefix - b.prefix)

    return climate_zones
  }

  onPolicyRequirementsSummaryTableBreak(data) {
    if (data.pageCount > 1) {
      const sufix = '  (Continued)'
      const currentTitle =  data.table.head[1].cells[0].text[0]
      const modifiedTitle =  data.table.head[1].cells[0].text + sufix
      
      if (currentTitle.indexOf(sufix) === -1) {
        data.table.head[1].cells[0].text = [modifiedTitle]
      }
    }
  }

  calculateRowHeights(data) {
    const rowHeight = 24
    let totalPadding = 0

    data.forEach(row => {
      if(row) totalPadding += rowHeight
    })  

    return totalPadding += rowHeight
  }

  async addSecondPage() {
    
    if (this.policy) {
      this.addPage()
      const { pageNumber } = this.doc.internal.getCurrentPageInfo()
      this.doc.movePage(pageNumber, 2)

      let pageTitleFontSize = 15
      let titleFontSize = 11
      let textFontSize = 8
      let maxWidth = this.pageWidth - (this.margins.left + this.margins.right)
      let text = '' 
      let policy = {}
      let splitText

      if(this.policyType == 'existing') {
        this.doc.setTextColor(this.colors.blue80)
        this.doc.setFontSize(pageTitleFontSize)
        this.doc.setFont('Lato-Bold')
        this.doc.text(this.headline, this.margins.left, this.margins.top + 20, { baseline : 'bottom' })


        // First paragraph
        this.doc.setTextColor(this.colors.blue80)
        this.doc.setFontSize(titleFontSize)
        this.doc.setFont('Lato-Bold')
        this.doc.text(`About this document`, this.margins.left, this.margins.top + 48, { baseline : 'bottom' })
        
        this.doc.setTextColor(this.colors.gray02)
        this.doc.setFontSize(textFontSize)
        this.doc.setFont('Lato-Regular')
        text = `This document features tables from the policy you crafted using the Cost-Effectiveness Explorer. These tables outline the mandatory measures that permit applicants must install to comply with your policy.`
        splitText = this.doc.splitTextToSize(text, maxWidth)
        this.doc.text(splitText, this.margins.left, this.margins.top + 58, { baseline : 'bottom' })
        

        // Second paragraph
        this.doc.setTextColor(this.colors.blue80)
        this.doc.setFontSize(titleFontSize)
        this.doc.setFont('Lato-Bold')
        this.doc.text(`What is included`, this.margins.left, this.margins.top + 80, { baseline : 'bottom' })

        this.doc.setTextColor(this.colors.gray02)
        this.doc.setFontSize(textFontSize)
        this.doc.setFont('Lato-Regular')
        text = 'This document includes tables on the following prototype and climate zone combinations:\n'
        policy = this.policy
        for (let container of policy.policy_containers) {
          let prototypeTitle = container.custom_combinations[0].prototype.title
          let climateZones = policy.jurisdiction.climate_zones.map(zone => zone.prefix)
          
          if (climateZones.length > 1) {
            let lastZone = climateZones.pop()
            text += `${prototypeTitle}: Climate Zones ${climateZones.join(', ')} and ${lastZone}. `
          } else {
            text += `${prototypeTitle}: Climate Zone ${climateZones[0]}. `
          }
        }
        this.doc.setTextColor(this.colors.gray02)
        this.doc.setFontSize(textFontSize)
        this.doc.setFont('Lato-Regular')
        splitText = this.doc.splitTextToSize(text, maxWidth)
        this.doc.text(splitText, this.margins.left, this.margins.top + 90, { baseline : 'bottom' })


        // Third paragraph
        this.doc.setTextColor(this.colors.blue80)
        this.doc.setFontSize(titleFontSize)
        this.doc.setFont('Lato-Bold')
        this.doc.text(`Intended use for ordinance compliance`, this.margins.left, this.margins.top + 120, { baseline : 'bottom' })

        this.doc.setTextColor(this.colors.gray02)
        this.doc.setFontSize(textFontSize)
        this.doc.setFont('Lato-Regular')
        text = `Building owners must install all required measures according to their building type and building vintage.`
        splitText = this.doc.splitTextToSize(text, maxWidth)
        this.doc.text(splitText, this.margins.left, this.margins.top + 130, { baseline : 'bottom' })


        // Fourth paragraph
        this.doc.setTextColor(this.colors.blue80)
        this.doc.setFontSize(titleFontSize)
        this.doc.setFont('Lato-Bold')
        this.doc.text(`Intended use for policy makers`, this.margins.left, this.margins.top + 155, { baseline : 'bottom' })

        this.doc.setTextColor(this.colors.gray02)
        this.doc.setFontSize(textFontSize)
        this.doc.setFont('Lato-Regular')
        text = 'While developing your policy, use these tables in discussions with colleagues and stakeholders to clarify ordinance requirements. When drafting ordinance language, incorporate these tables to specify required measures. And when designing public outreach materials and compliance documents, incorporate these tables to guide permit applicants.\n\nPlease use caution when changing these tables. They are based on criteria designed to meet state and federal requirements. We recommend contacting the reach code team for assistance at https://www.localenergycodes.com/content/contact-us.'
        splitText = this.doc.splitTextToSize(text, maxWidth)
        this.doc.text(splitText, this.margins.left, this.margins.top + 165, { baseline : 'bottom' })


        // Fifth paragraph
        this.doc.setTextColor(this.colors.blue80)
        this.doc.setFontSize(titleFontSize)
        this.doc.setFont('Lato-Bold')
        this.doc.text(`Notes on building vintage for existing buildings`, this.margins.left, this.margins.top + 218, { baseline : 'bottom' })
  
        this.doc.setTextColor(this.colors.gray02)
        this.doc.setFontSize(textFontSize)
        this.doc.setFont('Lato-Regular')
        text = 'This tables show the measures required under the ordinance according to building vintage.\n\nBuilding vintage is the year in which the original construction permit for the building was submitted.  The dates in the model ordinance are different than those in the cost-effectiveness study and Cost-Effectiveness Explorer.  The dates here represent the year in which the code cycle went into effect (i.e. 1979), as opposed to the nominal code cycle date cited in the study (i.e. 1978).\n\nAdditionally the suggested end year for the third vintage is 2010 rather than 2005 as energy codes from 2005 to 2011 were substantially similar. The Cost-Effectiveness Explorer uses 2005 to correspond with available building stock data however we are in the process of updating those data to include these additional years.'
        splitText = this.doc.splitTextToSize(text, maxWidth)
        this.doc.text(splitText, this.margins.left, this.margins.top + 228, { baseline : 'bottom' })
      }

      if(this.policyType == 'new') {  
        this.doc.setTextColor(this.colors.blue80)
        this.doc.setFontSize(pageTitleFontSize)
        this.doc.setFont('Lato-Bold')
        this.doc.text(`About this Document`, this.margins.left, this.margins.top + 20, { baseline : 'bottom' })
        
        this.doc.setTextColor(this.colors.gray02)
        this.doc.setFontSize(textFontSize)
        this.doc.setFont('Lato-Regular')
        text = `This document summarizes the requirements of a hypothetical policy designed using the Cost-Effectiveness Explorer.`
        splitText = this.doc.splitTextToSize(text, maxWidth)
        this.doc.text(splitText, this.margins.left, this.margins.top + 50, { baseline : 'bottom' })


        this.doc.setTextColor(this.colors.blue80)
        this.doc.setFontSize(13)
        this.doc.setFont('Lato-Bold')
        this.doc.text(`What is included`, this.margins.left, this.margins.top + 80, { baseline : 'bottom' })


        this.doc.setTextColor(this.colors.blue80)
        this.doc.setFontSize(titleFontSize)
        this.doc.setFont('Lato-Bold')
        this.doc.text(`Intended use for ordinance compliance`, this.margins.left, this.margins.top + 100, { baseline : 'bottom' })

        this.doc.setTextColor(this.colors.gray02)
        this.doc.setFontSize(textFontSize)
        this.doc.setFont('Lato-Regular')
        text = `Building owners must ensure their projects meet the outlined requirements according to their building type and climate zone.`
        splitText = this.doc.splitTextToSize(text, maxWidth)
        this.doc.text(splitText, this.margins.left, this.margins.top + 110, { baseline : 'bottom' })


        this.doc.setTextColor(this.colors.blue80)
        this.doc.setFontSize(titleFontSize)
        this.doc.setFont('Lato-Bold')
        this.doc.text(`Intended use for policy makers`, this.margins.left, this.margins.top + 133, { baseline : 'bottom' })

        this.doc.setTextColor(this.colors.gray02)
        this.doc.setFontSize(textFontSize)
        this.doc.setFont('Lato-Regular')
        text = 'While developing your policy, you can discuss these tables with colleagues and stakeholders to detail the ordinance requirements. When drafting your ordinance language, you can include these tables in the ordinance text as you specify the required compliance margins. And when creating public outreach materials and compliance documents, you may find these tables helpful.\n\nPlease use caution when changing these tables. They are based on criteria designed to meet state and federal requirements. We recommend contacting the reach code team for assistance at https://www.localenergycodes.com/content/contact-us.'
        splitText = this.doc.splitTextToSize(text, maxWidth)
        this.doc.text(splitText, this.margins.left, this.margins.top + 143, { baseline : 'bottom' })
      }
    } 
  }
}