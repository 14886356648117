import GraphQL from '@/util/GraphQL'

export default {
  namespaced: true,
  
  state: {
    climate_zones: []
  },

  getters: {
    getterGlobalClimateZones : (state) => (filters = null) => {
      return filters ? state.climate_zones.findAllInArray(filters) : state.climate_zones
    },
    getterGlobalClimateZone : (state) => (filters = null) => {
      return filters ? state.climate_zones.findInArray(filters) : state.climate_zones
    },

  },

  actions: {
    loadGlobalClimateZones(context) {
      const query = `
        {
          climate_zones (
            orderBy: [{ column: "prefix", order: "ASC" }]
          ) {
            id
            prefix
            suffix
            raw
            fallback_climate_zone {
              raw
            }
          }
        }
      `
      GraphQL({ query })
        .then(({ data }) => {
          context.commit('setGlobalClimateZones', { climate_zones: data.climate_zones})
        })
    }
  },

  mutations: {
    setGlobalClimateZones(state, { climate_zones }) {
      state.climate_zones = climate_zones
    }
  }
}
