
export const getIntercomBootPayload = (user) => {
  if (user) {
    console.log('Intercom service identifying user')
    return {
      app_id: 'm73wzwth',
      user_id : user.id,
      email : user.email
    }
  }
  console.log('Intercom service unassign user')
  return { app_id: 'm73wzwth' }
}

export default (Store) => {

  setTimeout(() => {
    if(window.Intercom) window.Intercom('boot', getIntercomBootPayload(Store.getters['getterLoggedUser']))
  }, 2000)
  
  /**
   * Watch user change
   */
  Store.subscribe((mutation) => {
    
    if(mutation.type === 'setLoggedUser' ) {
      
      if (!window.Intercom.booted) {
        window.Intercom('boot', getIntercomBootPayload(Store.getters['getterLoggedUser']))
      } else {
        window.Intercom('update', getIntercomBootPayload(Store.getters['getterLoggedUser']))
      }
    }
    
    if(mutation.type === 'setLogout') {
      window.Intercom('boot', getIntercomBootPayload())
    }
    
  })

}