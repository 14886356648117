<template>
  <div
    class="policy-container psui-bg-white psui-pt-4 psui-px-6 psui-pb-6 psui-rounded-md psui-shadow-elevation-5"
  >
    <!-- head -->
    <div class="psui-flex psui-justify-between">
      <div
        class="prototype-title-wrapper psui-mb-4 psui-flex psui-items-center psui-space-x-2 psui-leading-none relative"
        @mouseenter="onHoverTypePrototype"
        @mouseleave="onHoverTypePrototype"
        @click="openPrototypeDescriptionModal(getPrototypeId)"
      >
        <PsIcon 
          :style="{ display: 'flex' }"
          :icon="typePrototype.icon"
          size="28"
          :color="isHoveringTypePrototype ? 'psui-text-blue-60' : 'psui-text-gray-60'"
          class="psui-m-1"
        />
        <h1
          class="prototype-title psui-text-h6 psui-font-bold psui-mt-1 psui-pr-6"
        >
          {{ typePrototype.title }}
        </h1>
        <PsIcon
          :style="{ 'display': 'flex', 'margin-top': '0.375rem' }"
          icon="info"
          size="16"
          class="info-icon psui-text-blue-60 psui-items-center psui-justify-center psui-mx-1 absolute right-0"
        />
      </div>
      <PsTooltip>
        <template #trigger>
          <AppLoader
            v-if="isDeletingPolicyContainer"
          />
          <PsButton
            v-else-if="userCanEditPolicy && policyContainerCanBeDeleted" 
            label="Remove Building Type"
            size="small"
            layout="onlytext"
            icon="delete"
            icon-position="right"
            @click="removeContainerFromPolicy(policyContainer)"
          />
        </template>
        <template          
          #content
        >
          <span v-if="!policyContainerCanBeDeleted">
            You must have at least one prototype attached to the policy.
          </span>
          <span>
            Click to remove 
          </span>
        </template>
      </PsTooltip>
    </div>
    <!-- body -->
    <div>
      <div 
        :is="getRequirementsComponent" 
        :policy-container="policyContainer"
        :flexible_path_setup="flexible_path_setup"
      />
    </div>
  </div>    
</template>

<script>
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import PolicyContainerRequirementsByFuel from './PolicyContainerRequirementsByFuel.vue'
import PolicyContainerRequirementsByVintage from './PolicyContainerRequirementsByVintage.vue'
import FlexiblePathService from '@/services/api/FlexiblePathService.js'

export default {
  name: 'PolicyContainer',
  components: {
    PolicyContainerRequirementsByFuel,
    PolicyContainerRequirementsByVintage,
  },
  props: ['policy-container', 'policy','flexible_path_setup'],
  data: () => ({
    STUDY_TYPES_DB_SLUGS,
    isDeletingPolicyContainer: false,
    isHoveringTypePrototype: false,
  }),
  computed: {
    typePrototype() {
      return this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id: this.policyContainer.type_prototype_id })
    },
    getContainerStudyType() {
      return this.policyContainer.study_type.slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS  ? STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS : STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS
    },
    getRequirementsComponent() {      
      return {
        [STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS] : PolicyContainerRequirementsByVintage,
        [STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS] : PolicyContainerRequirementsByFuel,
      }[this.getContainerStudyType]
    },
    userCanEditPolicy() {
      return this.$store.getters['policy/getterUserCanEditPolicy'](this.policy.id)
    },
    policyContainerCanBeDeleted(){      
      return this.policy.policy_containers.length >= 1
    },
    getPrototypeId(){
      return this.policyContainer?.custom_combinations[0]?.prototype_id
    }
  },
  methods:{
    async removeContainerFromPolicy(policy_container){
      if (!this.userCanEditPolicy) return

      const type_prototype_id = policy_container.type_prototype_id
      const getPrototypesByTypePrototype = this.$store.getters['globalEntities/Prototype/getterGlobalPrototypes']({type_prototype_id, allow_policy_creation:true})
      const flexiblePath = new FlexiblePathService({ policy_id : this.$route.params.policy_id })

      const cleanedFlexiblePathSetup = {
          ...this.flexible_path_setup,
          tiers: this.flexible_path_setup.tiers.filter(tier => !getPrototypesByTypePrototype.find(prototype => prototype.id == tier.prototype_id)),
          cost_effective_presets: this.flexible_path_setup.cost_effective_presets.filter(cep => !getPrototypesByTypePrototype.find(prototype => prototype.id == cep.prototype_id))
        }
        

      this.isDeletingPolicyContainer = true 
      await Promise.all([
        flexiblePath.updateSetup(cleanedFlexiblePathSetup),
        this.$store.dispatch('policy/deleteContainerFromPolicy', policy_container)
      ])
      .then(()=>{
        this.$appToast({type:'success', message: 'A building type was successfully deleted.' })
        setTimeout(()=>{
          this.isDeletingPolicyContainer = false
        }, 2000)
      })
      this.$store.dispatch('setUserLastStudyTypeVisited', { value: policy_container.study_type.slug, context: 'updateLastStudyTypeVisitedFromPolicyContainer'})
    },
    onHoverTypePrototype(){
      this.isHoveringTypePrototype = !this.isHoveringTypePrototype
    },
    openPrototypeDescriptionModal(prototype_id) {
      if(!prototype_id) return
      this.$eventBus.$emit('openDescriptionModal', { type: 'prototype', id: prototype_id })
    },
  }
}
</script>

<style lang="scss" scoped>

  ::v-deep .psui-el-tooltip {
    .psui-el-tooltip-wrapper {
      .psui-el-tooltip-dialog{
        width: 200px;
        z-index: 5000;
        text-align: center;
      }
    }
  }

  .prototype-title-wrapper {
    cursor: default;
    .prototype-title {
      color: #28323b; // gray-80
    }
    .info-icon {
      visibility: hidden;
    }

    &:hover {
      cursor: pointer;
      .prototype-title {
        color: #318FAC; // blue-60
      }
      .info-icon {
        visibility: visible;
      }
    }
  }

</style>