<template>
  <div class="psui-w-full psui-flex psui-flex-col psui-p-6">
    <VueSelect 
      :value="jurisdiction.type"
      class="vue-select"
      placeholder="Select a type"
      :class="jurisdiction.type ? 'filled' : ''"
      label="type_content"
      :options="getJurisdictionTypes"
      @input="onSelectJurisdictionType"
    />

    <InputWithLabel
      v-model="jurisdiction.title"
      class="mt-6"
      :label="'Title'"
    />

    <InputWithLabel
      v-model="jurisdiction.city"
      class="mt-6"
      :label="'City'"
    />

    <InputWithLabel
      v-model="jurisdiction.county"
      class="mt-6"
      :label="'County'"
    />

    <div class="flex justify-between mt-6 mb-2">
      <Checkbox
        :value="jurisdiction.is_collection"
        label="Is Collection?"
        @change="setJurisdictionIsCollection"
      />
    </div>
    <hr class="border-b border-gray05 my-6">
    <div>
      <PsButton
        label="Create"
        icon="add"
        size="big"
        @click="store()"
      /> 
    </div>
  </div>
</template>

<script>
import { JURISDICTION_TYPES } from '@/util/Enums.js'
import Jurisdiction from '@/models/Jurisdiction.js'

export default {
  name: 'AdminJurisdictionForm',
  data:() => ({
    jurisdiction: {
      id: '',
      title: '',
      type: '',
      city: '',
      county: '',
      slug: '',
      content: '',
      is_collection: false,
      collections:[],
      climate_zones:[]
    },
    jurisdictions: [],
    JURISDICTION_TYPES
  }),
  computed:{
    getJurisdictionTypes(){
      return Object.values(JURISDICTION_TYPES).map((type) => type.replace(/./, c => c.toUpperCase()))
    },
    getJurisdictionType(){
      return this.jurisdiction?.type
    },
  },
  watch:{
    getJurisdictionType(type){
      if(!type) this.jurisdiction.is_collection = false
    }
  },
  mounted() {
    this.getJurisdictions()
  },
  methods: {
    onSelectJurisdictionType(type) {
      this.jurisdiction.type = type
      this.jurisdiction.is_collection = !(type == JURISDICTION_TYPES.COUNTY || type == JURISDICTION_TYPES.CITY)
    },
    setJurisdictionIsCollection(is_collection) {
      this.jurisdiction.is_collection = is_collection

      const isCountyOrCity = this.jurisdiction.type === JURISDICTION_TYPES.COUNTY || this.jurisdiction.type === JURISDICTION_TYPES.CITY

      if (is_collection && isCountyOrCity) {
        this.jurisdiction.type = JURISDICTION_TYPES.AQMD
      } else if (!is_collection && (!isCountyOrCity)) {
        this.jurisdiction.type = JURISDICTION_TYPES.CITY
      }

    },
    async getJurisdictions() {
      const query = `
        {
          jurisdictions {
            id
            title
            type
            city
            county
            slug
            created_at
            climate_zones {
              id
              raw
            }
          }
        }
      `
      const { data } = await this.$graphql({ query })
      this.jurisdictions = data.jurisdictions
    },
    
    store() { 	
      const getJurisdictionTitle = this.jurisdiction.title.replace(' ','-').replace(/./, c => c.toLowerCase())

      this.jurisdiction.slug = `${getJurisdictionTitle}-${this.jurisdiction.type.toLowerCase()}`

      this.$api.post(`/jurisdiction`, new Jurisdiction(this.jurisdiction), { loader: true })
        .then(() => {
          this.$toast.success('Jurisdiction successfully created')
          this.$router.push({ name: 'AdminJurisdictionList' })
        })
    },

    update() {
      this.$api.put(`/jurisdiction/${this.jurisdiction.id}`, this.jurisdiction, { loader: true })
        .then(() => {
          this.$toast.success('Jurisdiction successfully updated')
          this.$router.push({ name: 'AdminJurisdictionList' })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .vue-select {
    height: auto !important;
  }

  label {
    width: fit-content;
  }
</style>
