var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-full"},[_c('PolicyShowContentHeader',{attrs:{"title":_vm._f("policyTextRephrase")(`Policy Requirements`),"subtitle":_vm._f("policyTextRephrase")(`Edit the requirements of your policy`)}}),(_vm.policy && _vm.policy.is_flexible_path)?_c('div',{staticClass:"w-full grid grid-cols-12"},[_c('div',{staticClass:"flexiblepath-sidebar flex flex-col pt-11 bg-white border-t border-l border-b rounded-l-6 border-ash-200 px-4",class:{
        'col-span-12 pl-10 pr-10' : _vm.getterContentContainerWidth <= 1080,
        'col-span-3 pr-0' : _vm.getterContentContainerWidth > 1080,
        'pb-7 pl-10' : _vm.getterContentContainerWidth >= 1380,
      }},[_c('div',{staticClass:"w-full flex justify-start flex-wrap",class:{'space-y-2' : _vm.getterContentContainerWidth > 1080 && _vm.getterContentContainerWidth < 1250,}},[_c('button',{staticClass:"text-header-2 text-ash-700 flex items-center transition-all hover:opacity-70 mr-5",on:{"click":function($event){return _vm.$eventBus.$emit('openModalPolicyFlexiblePathDownload')}}},[_c('feather',{staticClass:"text-deepsky-450 mr-2 flex-shrink-0",attrs:{"type":"download"}}),_vm._v(" Download tables ")],1),_c('button',{staticClass:"text-header-2 text-ash-700 flex items-center transition-all hover:opacity-70 mr-5",on:{"click":function($event){return _vm.$store.commit('tutorial/setTutorialShow', { type: 'flexiblePath', show: true })}}},[_c('feather',{staticClass:"text-deepsky-450 mr-2 flex-shrink-0",attrs:{"type":"info"}}),_vm._v(" Quick Tutorial ")],1)]),_c('div',{staticClass:"w-full flex flex-col pr-6"},[_c('p',{staticClass:"text-header-2 text-ash-500 mt-4"},[_vm._v(" Allow permit applicants to choose the measures that work best for them from a menu. ")]),_c('button',{staticClass:"text-header-2 flex justify-start text-deepsky-500 text-sm font-bold mt-2",on:{"click":function($event){return _vm.$store.commit('tutorial/setTutorialShow', { type: 'flexiblePath', show: true })}}},[_vm._v(" Learn more ")])]),(_vm.type_prototypes.length > 0)?[_c('div',{staticClass:"w-full mt-6 grid",class:{
            'grid-cols-2 gap-4' : _vm.getterContentContainerWidth <= 1080,
            'grid-cols-1 gap-0' : _vm.getterContentContainerWidth > 1080,
          }},[_vm._l((_vm.type_prototypes),function(type_prototype){return _c('button',{key:`type_prototype-${type_prototype.id}`,staticClass:"w-full flex items-center space-x-4 p-3 transition duration-300",class:[ 
              type_prototype.id === _vm.type_prototype_selected.id ? 'bg-deepsky-100 text-deepsky-450 border-deepsky-450' : 'bg-ash-200 text-ash-500 border-ash-300 hover:bg-deepsky-100 hover:text-deepsky-450 hover:border-deepsky-450',
              {
                'border-b-4' : _vm.getterContentContainerWidth <= 1080,
                'border-b-0 border-r-4 my-2 rounded-l-md' : _vm.getterContentContainerWidth > 1080,
              }
            ],on:{"click":function($event){return _vm.setTypePrototype(type_prototype)}}},[_c('div',{staticClass:"p-3 rounded-full",class:[
                { 'hidden' : _vm.getterContentContainerWidth > 1080 && _vm.getterContentContainerWidth < 1380 }, 
                type_prototype.id == _vm.type_prototype_selected.id ? 'bg-deepsky-200' : 'bg-ash-200'
              ]},[_c('inline-svg',{attrs:{"src":`${type_prototype.custom_fields.data.icon.value}`,"width":"48","height":"48","aria-label":"Type prototype","stroke":type_prototype.id == _vm.type_prototype_selected.id ? '#69A7BF' : '#A9AEB0'}})],1),_c('h3',{staticClass:"text-header-3 font-bold"},[_vm._v(" "+_vm._s(type_prototype.title)+" ")])])}),_vm._m(0)],2)]:_c('SkeletonTypePrototypeList')],2),_c('div',{staticClass:"flexiblepath-content w-full flex flex-col p-10 bg-ash-200 overflow-y-auto rounded-r-6",class:{
        'col-span-12' : _vm.getterContentContainerWidth <= 1080,
        'col-span-9' : _vm.getterContentContainerWidth > 1080,
      }},[(_vm.prototypes.length && _vm.flexible_path_setup)?_vm._l((_vm.prototypes),function(prototype,index){return _c('PolicyFlexiblePathMeasureMenuTables',{key:`prototype-${prototype.id}-${index}`,staticClass:"mx-auto xl:mr-auto",attrs:{"policy":_vm.policy,"type_vintages":_vm.type_vintages,"prototype":prototype,"flexible_path_setup":_vm.flexible_path_setup,"flexible-path-service":_vm.flexiblePathService},on:{"updateFlexiblePathSetup":_vm.updateFlexiblePathSetup}})}):_c('SkeletonPolicyFlexiblePathMeasureMenuTables'),_c('PolicyFlexiblePathTutorial')],2),_c('PolicyFlexiblePathDownloadModal',{attrs:{"policy":_vm.policy}})],1):(_vm.policy)?_c('PolicyFlexiblePathNotAvailable',{attrs:{"policy":_vm.policy}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"ts--accent--2 mt-5 mb-8 text-gray03"},[_vm._v(" *Please note that the Flexible Paths feature is still in beta. Please "),_c('a',{staticClass:"underline text-gray03",attrs:{"target":"_blank","href":"https://localenergycodes.com/content/contact-us","title":"Contact us"}},[_vm._v("email us")]),_vm._v(" any feedback or corrections. ")])
}]

export { render, staticRenderFns }