<template>
  <div
    :id="`forecast-prototype-${ prototype.id }`" 
    class="w-full flex-col pb-10"
  >
    <div class="psui-mb-6 psui-flex-col psui-flex-wrap ">
      <div class="psui-flex mb-1/2 psui-items-center psui-justify-between">
        <div
          class="psui-flex psui-items-center justify-items-center psui-text-gray-80 psui-mr-1"
        >
          <div            
            class="psui-flex psui-items-center psui-justify-items-center psui-text-h5 transition-all"                        
          >
            <PsIcon
              v-if-admin
              v-go-to-admin-edit="{ name: 'AdminStudyItemEdit', params: { study_item_id: prototype.id, type: 'prototype' }}"
              icon="edit"
              size="16"
              class="text-violet"
            />
            <h2 
              class="prototype-title w-full xl:w-auto font-bold flex items-center cursor-pointer mb-0"
            >
              {{ prototype.title ? prototype.title : prototype.type_prototype.title }}
              <PsIcon
                icon="info"
                size="16"
                display="flex"
                class="prototype-title-icon psui-text-blue-60 ml-1/2"
                @click.native="openPrototypeDescriptionModal(prototype)"
              />            
            </h2>
            <span
              v-if="getPrototypeBuildingsAsText"
              class="unit-description psui-text-gray-50 psui-ml-2 flex items-center"
              :class="{'psui-cursor-pointer':getIfPrototypeIsNewBuilding}"
            >
              <span>{{ getPrototypeBuildingsAsText }}</span>
              <PsIcon
                v-if="getIfPrototypeIsNewBuilding"
                icon="info"
                size="16"
                display="flex"
                class="unit-description-icon psui-text-blue-60 items-center ml-2"
                @click.native="openStudyUnitsDescriptionModal()"
              />
            </span>
          </div>
        </div>
      </div>
      <div class="source-field source-text mr-auto psui-bg-gray-20 px-3 py-1 psui-rounded flex whitespace-normal">
        <span class="psui-text-gray-50 psui-font-bold">Study Source:</span>
        <span
          class="psui-text-gray-50 psui-cursor-pointer psui-ml-1 hover:psui-text-blue-60"
          @click.stop="onClickStudySourceInfo(study.id)"
        >{{ study.title }}</span>
        <span class="border-l-2 psui-border-gray-30 mx-3 shadow-divider" />
        <span class="psui-text-gray-50 psui-font-bold">Release Date:</span>
        <span 
          v-if="study.released_at"
          class="psui-ml-1 cursor-pointer transition psui-text-gray-50 hover:psui-text-blue-60"
          @click="$eventBus.$emit('openStudyGroupVersionsTimelineModal', { studyGroupId: studyGroup.id })"
        >
          {{ formatDate({ date: study.released_at, format: 'MMMM D, YYYY', from: 'mm/dd/yyyy' }) }}
        </span>
        <PrototypeVersion 
          :study="study" 
          :prototype="prototype"
          :study-group="studyGroup"
          class="source-text psui-ml-1"
        />
        <template v-if="getStudyTypeSlugFromRoute === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS">
          <span class="border-l-2 psui-border-gray-30 mx-3 shadow-divider" />
          <PrototypeCodeCycle
            :study="study"
            :prototype="prototype"
            :study-group="studyGroup"
            class="source-text psui-ml-1"
          />
        </template>
      </div>
    </div>
    <div class="w-full">
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PrototypeVersion from './PrototypeVersion.vue'
import PrototypeCodeCycle from './PrototypeCodeCycle.vue'
import TypePrototype from '@/models/TypePrototype'
import { STUDY_TYPES } from '@/util/Enums'
import { DBIDS_TYPE_PROTOTYPES } from '@/store/global-entities/TypePrototype'
import { DBIDS_BUILDING_TYPES } from '@/store/global-entities/BuildingType'
import formatUnits from '@/formatters/formatUnits'
import formatNumber from '@/formatters/formatNumber'
import dayjs from 'dayjs'
import { GA_LABELS } from '@/mixins/GaEventsMixin'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import { POLICY_OPTIONS_ALGORITHMS } from '@/business-logic/services/policy-options/factory.js'
import { POLICY_OPTIONS_DB_SLUGS, PROTOTYPES_DB_SLUGS } from '@/util/Enums.js'

export default {
  name: 'StudyResultsContentPrototype',
  components: { PrototypeVersion, PrototypeCodeCycle },
  props: { 
    prototype: {
      type: Object,
      required: true
    },
    study: {
      type: Object,
      required: true
    },
    studyGroup: {
      type: Object,
      required: true
    },
  },
  data:()=>({
    isHoveringTypePrototype: false,
    GA_LABELS,
    STUDY_TYPES_DB_SLUGS,
    isPOButtonDisabled: true,
  }),
  computed: {
    typePrototype() {
      return this.prototype?.type_prototype ? new TypePrototype(this.prototype.type_prototype) : {}
    },
    prototypeBuildingFilters() {
      const jurisdiction_id = this.lastJurisdictionVisited?.id ?? null
      const climate_zone_prefix = this.$route?.params?.climate_zone_raw?.split('-')?.[0] ?? null      
      return jurisdiction_id && climate_zone_prefix ? { jurisdiction_id, climate_zone_prefix } : null
    },
    hasNewerVersionReleased() {      
      return this.nextPrototype && this.nextPrototype.study.published_at ? true : false
    },
    customIsUpdating() {
      return this.$store.state.assumptions.buildingEstimates.is_updating
    },
    getPrototypeBuildingsAsText() {
      if (!this.prototypeBuildingFilters || !this.prototype ) return false

      const prototype_id = this.prototype.id
      if (!this.getPrototypeBuildings) return false

      const study_type_id = this.$store.getters['globalEntities/Prototype/getterPrototypeStudyTypeId']({ prototype_id })
      const prototype = this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: prototype_id })
      const type_prototype_id = this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id: prototype?.type_prototype_id })?.id
      const building_type_id = this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id: prototype?.type_prototype_id })?.building_type_id
      const study = this.$store.getters['globalEntities/Study/getterGlobalStudy']({ id: prototype.study_id })
      const code_cycle = this.$store.getters['globalEntities/CodeCycle/getterGlobalCodeCycle']({ id: study.code_cycle_id })
      const codeCycleLabel = code_cycle ? `(${dayjs(code_cycle.start_at).year()}-${dayjs(code_cycle.end_at).year()})` : ''
      

      // This mess is described at https://miro.com/app/board/uXjVOwk7f_0=/
      if (study_type_id == STUDY_TYPES.EXISTING_BUILDINGS) {
        if (building_type_id == DBIDS_BUILDING_TYPES.NON_RESIDENTIAL) {
          return `${formatUnits(this.getPrototypeBuildings)} building area`
        } else {
          return `${formatUnits(this.getPrototypeBuildings)} Total Units`          
        }
      } else if (study_type_id == STUDY_TYPES.NEW_BUILDINGS) {

        if (building_type_id == DBIDS_BUILDING_TYPES.RESIDENTIAL) {
          
          if (type_prototype_id == DBIDS_TYPE_PROTOTYPES.SINGLE_FAMILY) {
           return `${formatUnits(this.getPrototypeBuildings)} new homes ${codeCycleLabel}`
          } else if (type_prototype_id == DBIDS_TYPE_PROTOTYPES.MULTIFAMILY) {
            return `${formatUnits(this.getPrototypeBuildings)} new dwelling units ${codeCycleLabel}`
          } else {
            return `${formatUnits(this.getPrototypeBuildings)} new units ${codeCycleLabel}`
          }

        } else if (building_type_id == DBIDS_BUILDING_TYPES.NON_RESIDENTIAL) {
          return `${formatNumber(this.getPrototypeBuildings)} ft2 new building area ${codeCycleLabel}`
        } else {
          return null
        }
        
      } else {
        return null
      }
    },
    getStudyTypeSlugFromRoute() {
      return this.$route.query.only_study_type
    },
    availableTypePrototypes() {
      let availableTypePrototypes = []
      this.$store.getters['policy/getterPolicyOptionsWithPrototypes']().map((policyOption) => {
        availableTypePrototypes = [...new Set([...availableTypePrototypes, ...(policyOption?.prototypes?.map((p) => p.type_prototype_id) || [])])]
      })
      return availableTypePrototypes
    },
    getPrototypeBuildings(){
      return this.$store.getters['globalEntities/Prototype/getterPrototypeBuildings']({ prototype_id:this.prototype.id, filters : this.prototypeBuildingFilters })
    },
    newBuildingEstimatesTooltipContent(){
      const getClimateZoneFromRoute = this.$store.getters['globalEntities/ClimateZone/getterGlobalClimateZone']({raw: this.$route.params.climate_zone_raw})
      
      const content = this.studyGroup.id === 2 ? 
      `${formatNumber(this.getPrototypeBuildings)} new ${this.prototype.title} will be constructed in Climate Zone ${getClimateZoneFromRoute.prefix} in the ${this.lastJurisdictionVisited.titles.title_type} from 2023 to 2025.` :
      `${formatNumber(this.getPrototypeBuildings)} ft2 of new ${this.prototype.title} building area will be constructed in Climate Zone ${getClimateZoneFromRoute.prefix} in the ${this.lastJurisdictionVisited.titles.title_type} from 2023 to 2025.`
      
      return this.studyGroup.study_type_id === 2 ? content : false
    },
    getIfPrototypeIsNewBuilding(){
      const study = this.$store.getters['globalEntities/Study/getterGlobalStudy']({id:this.prototype.study.id})
      const study_type_id = this.$store.getters['globalEntities/StudyGroup/getterGlobalStudyGroup']({id:study.study_group_id}).study_type_id

      const study_type = this.$store.getters['globalEntities/StudyType/getterGlobalStudyType']({id: study_type_id})
      return study_type.slug == STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS
    },
    ...mapGetters(['lastJurisdictionVisited']),
    ...mapGetters('assumptions', ['isUpdating']),
  },
  watch: {
    prototype: {
      handler: async function (prototype) {
          this.isPOButtonDisabled = await this.checkPrototypePolicyOptionsDisabled(
            prototype
          )
      },
      immediate: true,
    },
  },
  methods: {
    openPrototypeDescriptionModal(prototype) {
      const prototypeId = prototype.id
      const { slug: prototypeSlug } = this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: prototypeId })
      if (prototypeSlug === PROTOTYPES_DB_SLUGS.ADU) {
        this.$eventBus.$emit("openDescriptionModal", {
          type: "prototype",
          id: prototypeId,
          button: {
            title: "More Info",
            url: "https://intercom.help/explorer-local-energy-codes/en/articles/6619711-adus"
          }
        })
      } else {
        this.$eventBus.$emit("openDescriptionModal", {
          type: "prototype",
          id: prototypeId,
        })
      }
    },
    openStudyUnitsDescriptionModal(){
      if(this.newBuildingEstimatesTooltipContent){
        this.$eventBus.$emit('openNewBuildingAreaInfoModal', { description: this.newBuildingEstimatesTooltipContent, hrefLink: 'https://intercom.help/explorer-local-energy-codes/en/articles/7016709-where-do-these-construction-projections-come-from' })      
      }
    },
    onClickStudySourceInfo(study_id){
      this.gaEvent(GA_LABELS.RESULTS_STUDY_SOURCE_INFO, { value: study_id })
      this.$eventBus.$emit('openDrawerContent', { type: 'study', id: study_id })
    }, 
    async checkPrototypePolicyOptionsDisabled(prototype) {
      for await(const algorithm of Object.values(POLICY_OPTIONS_ALGORITHMS)) {
        if([POLICY_OPTIONS_DB_SLUGS.DEFAULT_STATE, POLICY_OPTIONS_DB_SLUGS.DEFAULT_STATE_EXISTING].includes(algorithm.policy_option_slug)) continue
        const builder = new algorithm.constructor(null, [
          {
            prototype_id: prototype?.id,
            climate_zones: this.$store.getters['lastJurisdictionVisited'].climate_zones.map((cz) => cz.raw)
          },
        ])
        const isDisabled = await builder.isDisabled()
        if (!isDisabled) return false

      }
      return true
    },
    isTypePrototypeAvailable(type_prototype_id) {
      return this.availableTypePrototypes.includes(type_prototype_id)
    },
    goToPolicyOptionsTab(prototype){
      const typePrototypeId = prototype?.type_prototype_id
      const typePrototypesExcluded = this.availableTypePrototypes.filter(type_prototype_id => type_prototype_id !== typePrototypeId).join(',')
      if(this.$route.query.only_study_type === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS || !this.$route.query.only_study_type) {
        this.$router.push({
          name: 'PolicyOptions',
          query: {
            ...this.$route.query,
            only_study_type: STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS,
            exclude_type_prototype_ids: typePrototypesExcluded
          }
        })
      } else if(this.$route.query.only_study_type === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS) {
        this.$router.push({
          name: 'PolicyOptions',
          query: {
            ...this.$route.query,
            only_study_type: STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS,
            exclude_type_prototype_ids: typePrototypesExcluded
          }
        })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  .source-field {
    width: fit-content;
  }

  .source-text {
    font-size: 14px;
    line-height: 130%;
    letter-spacing: 0;
  }

  .unit-description {
    &-icon {
      visibility: hidden;
    }
    &:hover {
      .unit-description-icon {
        visibility: visible;
      }
    }
  }

  .prototype-title {
    &-icon {
      visibility: hidden;
    }
    &:hover {
      .prototype-title-icon {
        visibility: visible;
      }
    }
  }
</style>
