<template>
  <div class="psui-w-full psui-h-full psui-flex psui-flex-col psui-justify-start psui-pb-4">
    <router-link
      :to="{ name: 'AppHome', query: { utm_source : 'explorer-sidebar' } }"
      class="psui-w-full psui-text-center psui-p-6 psui-inline-block"
    >
      <img
        src="/logo/cee.svg"
        width="150"
        alt="Cost Effectiveness Explorer"
      >
    </router-link>
    
    <div 
      v-for="group in groups"
      :key="group.key"
      class="w-full flex flex-col justify-start psui-pl-6 psui-ml-1"
    >
      <h3
        :class="[group.items.findInArray({ to: $route.name}) ? 'border-white' : 'border-sec']" 
        class="border-l-4 hover:no-underline cursor-default mt-6 -ml-1 uppercase mb-2 text-red pl-4"
      >
        {{ group.label }}
      </h3>
      <ul class="w-full list-none text-white">
        <router-link 
          v-for="(item, index) in group.items"
          :key="index + item.to"
          class="flex hover:underline text-gray mb-1"
          :to="{ name: item.to }"
        >
          <h4 
            class="ml-3 inline-block" 
            :class="item.to && $route.name == item.to ? 'text-white' : ''"
          >
            {{ item.label }}
          </h4>
        </router-link>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

      groups: [
        {
          key: 'study-group',
          label: 'Study Data',
          items: [
            { to: 'AdminStudyGroupList', label: 'StudyGroups', match_route: 'study-group'},
            { to: 'AdminSyncList', label: 'Study Syncs', match_route: 'sync'},
            { to: 'PrototypesConfiguration', label:'Prototypes', match_route: 'prototypes-configuration'}
          ]
        },

        {
          key: 'website-content',
          label: 'Website Content',
          items: [
            { to: 'AdminFrequentQuestionList', label: 'FAQ', match_route: 'frequent_question'},
            { to: 'AdminHelperList', label: 'Helpers', match_route: 'helpers'},
            { to: 'AdminArticleIndex', label: 'Articles', match_route: 'articles'},
            { to: 'AdminFeatureFlagIndex', label: 'Feature Flags', match_route: 'feature_flag'}
          ]
        },

        {
          key: 'user-analysis',
          label: 'User Analysis',
          items: [
            { to: 'AdminUserList', label: 'Users', match_route: 'users'},
            { to: 'AdminDashboardIndex', label: 'User Analysis', match_route: 'dashboard'},
            { to: 'AdminJurisdictionDashboardIndex', label: 'Jurisdiction Analysis', match_route: 'jurisdiction-dashboard'},
            { to: 'AdminPoliciesList', label: 'Policies', match_route: 'policy'},
            { to: 'AdminUserDeviceList', label: 'User devices', match_route: 'user_devices'},
          ]
        },

        {
          key: 'static-data',
          label: 'Static Data',
          items: [
            { to: 'AdminJurisdictionList', label: 'Jurisdictions', match_route: 'jurisdiction'},
            { to: 'AdminClimateZoneList', label: 'Climate Zones', match_route: 'climate_zone'},
            { to: 'AdminBuildingStockList', label: 'Building Stock', match_route: 'building_stock'}, 
          ]
        },

        {
          key: 'developers',
          label: 'Developers',
          items: [
            { to: 'AdminTypeFilterList', label: 'Types', match_route: 'filter'},
            { to: 'AdminStudyTypeList', label: 'Study Types', match_route: 'study-type'},
            { to: 'AdminStudyItemList', label: 'Study Items', match_route: 'study_item'},
            { to: 'AdminStudyMapList', label: 'Study Maps', match_route: 'study_map'},
            { to: 'AdminBuildingHeightList', label: 'Building Height', match_route: 'building_height'},
            { to: 'AdminBuildingTypeList', label: 'Building Type', match_route: 'building_type'},
            { to: 'AdminConstructionImplementationTypeList', label: 'Construction Implementation Type', match_route: 'construction_implementation_type'},
            { to: 'AdminBuildingEstimateIndex', label: 'Building Estimate', match_route: 'building_estimate'},
            { to: 'AdminPolicyOptionsIndex', label: 'Policy Options', match_route: 'policy_options'},
            { to: 'AdminDefaultAssumptionInputList', label: 'Default Assumption Input', match_route: 'default_assumption_input'},
            { to: 'CustomFieldList', label: 'Custom Fields', match_route: 'custom_field'},
          ]
        },


      ]
    }
  }
}
</script>
