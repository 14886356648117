<template>
  <div class="psui-flex psui-w-full psui-px-6 md:psui-px-10 psui-py-8 psui-gap-6 psui-flex-wrap">
    <div class="footer-resource psui-flex psui-flex-col">
      <p class="psui-text-small psui-text-blue-80 psui-font-bold psui-mb-2">
        A resource from:
      </p>
      
      <div class="content psui-flex psui-items-center">
        <a
          href="https://localenergycodes.com?utm_source=cee-footer"
          target="_blank"
          title="Statewide Reach Codes Program"
          class="psui-py-1 psui-my-px"
        >
          <img
            src="images/home_page/california_energy_logo.svg"
            alt="California Energy Codes & Standards Logo"
          >
        </a>

        <div class="vertical-divider psui-bg-gray-60 opacity-20" />

        <p class="psui-text-small psui-text-blue-80">
          Find more reach <br>code resources at <br>
          <a
            class="psui-text-blue-60"
            href="https://localenergycodes.com?utm_source=cee-footer"
            target="_blank"
            title="Statewide Reach Codes Program"
          >LocalEnergyCodes.com</a>
        </p>
      </div>
    </div>

    <div class="footer-powered psui-flex psui-flex-col">
      <p class="psui-text-small psui-text-gray-80 psui-font-bold psui-mb-2">
        Powered by:
      </p>
      <div class="content psui-flex psui-items-center">
        <a
          href="https://www.policystudio.co/?utm_source=cee-footer"
          target="_blank"
          title="Policy Studio - Digital Tools to Accelerate Decarbonization"
          class="wrapper"
        >
          <img
            src="images/home_page/policy_studio_logo.svg"
            alt="Policy Studio - Digital Tools to Accelerate Decarbonization"
          >
        </a>

        <div class="vertical-divider psui-bg-gray-60 opacity-20" />

        <p class="policy-studio-logo psui-text-gray-70">
          Digital Tools <br>to Accelerate <br>Decarbonization
        </p>
      </div>
    </div>

    <div class="footer-about psui-flex psui-flex-col">
      <p class="psui-text-small psui-text-blue-80 psui-font-bold psui-mb-2">
        About this tool
      </p>
      <p class="psui-text-small psui-text-blue-80">
        This tool was designed to help local government policymakers create data-driven policies for reducing greenhouse gas emissions through building decarbonization and energy efficiency measures. Our goal is to empower policymakers to make informed choices that not only benefit their local communities, but also contribute to the global effort to combat climate change.
      </p>
      <!-- <a
        class="psui-text-blue-60"
        href="#"
      >Learn More</a> -->
    </div>
  </div>
</template>

<script>
export default {
  name:'HomeFooter'
}
</script>

<style lang="scss" scoped>

  @import url('https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap');

  .vertical-divider {
    margin-left: 19px;
    margin-right: 19px;
    height: 60px;
  }

  .content {
    .policy-studio-logo {
      font-family: 'Space Grotesk', sans-serif;
      font-size: 13px;
      line-height: 120%;
    }
  }

  .footer {
    &-resource {
      order: 2;

      @media (min-width: 1360px) {
        order: 1;
      }
    }
    &-powered {
      order: 3;

      @media (min-width: 1360px) {
        order: 2;
      }
    }
    &-about {
      order: 1;
      width: auto;

      @media (min-width: 1360px) {
        order: 3;
        flex: 1 1 0%;
      }
    }
  }
</style>