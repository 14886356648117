import CustomBuildingEstimate from '@/models/CustomBuildingEstimate'
import DataAndObjectFunctions from '@/util/DataAndObjectFunctions'

export default {

  setJurisdictionBuildingEstimates(state, { jurisdiction_id, building_estimates }) {
    state.jurisdiction_building_estimates = state.jurisdiction_building_estimates.removeAllInArray({ jurisdiction_id })
    state.jurisdiction_building_estimates = [ ...state.jurisdiction_building_estimates, ...building_estimates]
  },

  deleteCustomBuildingEstimateOfPolicy(state, policy_id) {
    state.custom_building_estimates = state.custom_building_estimates.filter((cbe) => cbe.policy_id !== policy_id)
  },

  setCustomBuildingEstimates(state, {custom_building_estimates, policy_id_selected = null }) {

    const payloadCustomBuildingEstimatesCopy = custom_building_estimates
      .filter((cbe) => cbe.policy_id == policy_id_selected)
      .map(pcbe => new CustomBuildingEstimate({ ...pcbe, policy_id: policy_id_selected }))

    for(const cbe of payloadCustomBuildingEstimatesCopy) {
      const filterPayload = DataAndObjectFunctions.filterObjectKeys(CustomBuildingEstimate.filtrableKeys, cbe)
      const buildingEstimateExists = state.custom_building_estimates.findInArray(filterPayload)
      if (buildingEstimateExists) {
        buildingEstimateExists.id = cbe.id
        buildingEstimateExists.units = cbe.units
        buildingEstimateExists.user_id = cbe.user_id
      } else {
        state.custom_building_estimates.push(cbe)
      }
    }

  },
  
  clearAllBuildingEstimates(state) {
    state.jurisdiction_building_estimates = []
    state.custom_building_estimates = []
  },
  
  clearCustomBuildingEstimates(state, cbe_to_clear = null) {
    if (cbe_to_clear?.length) {
      state.custom_building_estimates = [...state.custom_building_estimates].filter(cbe => {
        return !cbe_to_clear.findInArray({
          building_height_id: cbe.building_height_id,
          construction_implementation_type_id: cbe.construction_implementation_type_id,
          type_prototype_id: cbe.type_prototype_id,
          climate_zone_prefix: cbe.climate_zone_prefix,
          year: cbe.year,
          jurisdiction_id: cbe.jurisdiction_id,
          policy_id: cbe.policy_id
        })
      })
    } else {
      state.custom_building_estimates = []
    }
  },

  buildingEstimatesInit(state) {
    state.is_updating = true
  },
  
  buildingEstimatesFinish(state) {
    state.is_updating = false
  },
  
  buildingEstimatesFail(state) {
    state.is_updating = false
  },

}
