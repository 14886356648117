import BaseModel from './BaseModel'
export default class StudyType extends BaseModel {
  constructor(obj = {}) {
    super()
    this.id = obj.id || null
    this.title = obj.title || ''
    this.slug = obj.slug || ''
    this.title_alternate = obj.title_alternate || ''
    this.study_groups = obj.study_groups || []
  }

  get requireds() {
    return [
      { item: 'title', label: 'title' }
    ]
  }

}