<template>
  <div class="donut-chart psui-w-full psui-h-full psui-rounded-md psui-bg-white psui-shadow-elevation-5 psui-p-8 psui-flex psui-flex-col psui-justify-between">
    <div class="psui-w-full psui-flex psui-justify-between mb-6">
      <h4 class="psui-text-h5 psui-text-gray-80 psui-font-bold">
        Existing Units
      </h4>
      <PsCardInfos
        id="card-edit-icon" 
        title="Total"
        :subtitle="`as of 2020`"
        :total="formatUnits(getTotal)"
        @click="openAssumptionsDrawer()"
      />
    </div>

    <div
      class="psui-block"
      style="height: 300px;"
    >
      <div ref="chartExistingUnits" />
      <ApexChartGeneral 
        :options="getChartOptions()"
        :palette-color-index="paletteColorIndex"
      />
    </div>

    <div class="psui-flex psui-mx-auto psui-space-x-2">
      <div
        v-for="(type_prototype, index) in getTypePrototypesWithUnitsExcludingAdu"
        :key="type_prototype.id"
        class="card-icon-helper flex items-center"
      >
        <PsChartLegend 
          :text="type_prototype.title_building_estimates_existing || type_prototype.title"
          :total="formatUnits(type_prototype.building_stock_units)"
          :percentage="`${formatNumber(getPercentage(type_prototype.building_stock_units))}`"
          :dot-color="{ backgroundColor: getColors[index] }"
        />
        <a
          :href="INTERCOM_LINK_PER_TYPE_PROTOTYPE_SLUG[type_prototype.slug]"
          target="_blank"
          class="text-opacity-0 flex items-center"
        >
          <PsIcon
            icon="info"
            size="16"
            class="psui-text-gray-40 psui-cursor-pointer"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { colors } from '@/business-logic/constants/chartDefaultOptions'
import { MathHelpers } from '@igortrindade/lazyfy'
import { INTERCOM_LINK_PER_TYPE_PROTOTYPE_SLUG, } from '@/store/global-entities/TypePrototype.js'
import { STUDY_TYPES_DB_SLUGS, TYPE_PROTOTYPES_DB_SLUGS }from '@/util/Enums.js'

export default {
  name: 'ExistingUnitsDonutsChart',
  props: ['getTypePrototypesWithUnits', 'paletteColorIndex', 'overrideColors'],
  data() {
    return {
      colors,
      INTERCOM_LINK_PER_TYPE_PROTOTYPE_SLUG
    }
  },
  computed: {
    getTotal() {
      return this.getTypePrototypesWithUnitsExcludingAdu.reduce((acc, i) => acc + i.building_stock_units, 0)
    },
    getTypePrototypesWithUnitsExcludingAdu() {
      return this.getTypePrototypesWithUnits.filter((type_prototype) => type_prototype.slug !== 'adu')
    },
    getColors() {
      return this.overrideColors || colors
    }
  },
  methods: {
    getChartOptions() {
      const self = this
      return {
        series: this.getTypePrototypesWithUnitsExcludingAdu.map((item) => MathHelpers.getValueOrMinPercentage(this.getTotal, parseInt(item.building_stock_units), 1) ),
        colors: this.getColors,
        chart: {
          type: 'donut',
          toolbar: { show: false, },
        },
        tooltip: {
          followCursor: false,
          fixed: {
            enabled: true,
            position: "topRight",
            offsetX: 0,
            offsetY: 0,
          },
          custom: function ({ seriesIndex }) {
            const type_prototype = self.getTypePrototypesWithUnitsExcludingAdu[seriesIndex]
            return `
              <div class="psui-flex" style="background-color: ${ self.getColors[seriesIndex] } !important;">
                <div class="apexcharts-tooltip-content psui-w-full psui-bg-white psui-p-4">
                  <h4 class="psui-text-gray-80 psui-text-xsmall psui-font-bold">${ type_prototype.title_building_estimates_existing || type_prototype.title }</h4>
                  <div class="psui-w-full psui-flex psui-items-center">
                    <h2 class="psui-text-gray-80 psui-text-small">${ self.formatUnits(type_prototype.building_stock_units) } Units</h2>
                    <span class="psui-text-gray-30 psui-mx-1">|</span>
                    <h2 class="psui-text-gray-50 psui-text-small">${ self.formatNumber(self.getPercentage(type_prototype.building_stock_units)) }%</h2>
                  </div>
                  <div class="w-full flex flex-wrap">
                    ${type_prototype ? `<p class="psui-text-gray-40 psui-text-xsmall psui-font-bold whitespace-normal">${ type_prototype.building_stock_helper} ${type_prototype.slug === TYPE_PROTOTYPES_DB_SLUGS.SINGLE_FAMILY ? 'including accessory dwelling units (ADUs)': '' }</p>` : ''}
                  </div>
                </div>
              </div>
            `
          }
        }
      }
    },

    goToStudyResults({ type_prototype_id, climate_zone }) {
      const type_prototypes = this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototypes']()
      const excludedTypePrototypes = type_prototypes.filter((type_prototype) => type_prototype.id != type_prototype_id)
      const forecastRoute = {
        name: 'StudyResults',
        params: { climate_zone_raw: climate_zone.raw },
        query: { exclude_type_prototype_ids : excludedTypePrototypes.map((item) => item.id).join(',') }
      }
      this.$router.push(forecastRoute)
    },
    getPercentage(value) {
      return 100 / this.getTotal * value
    },
    openAssumptionsDrawer() {
      this.$store.dispatch('setUserLastStudyTypeVisited', { value: STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS, context: 'updateLastStudyTypeVisitedFromExistingUnitsDonutsChart'})
      this.$store.commit('assumptions/openDrawerAtBuildingEstimatesResidentialExisting')
    },

  }
}
</script>

<style scoped>
  .apexcharts-tooltip-content {
    max-width: 200px;
  }

  .apexcharts-tooltip-content.apexcharts-theme-dark {
    background: #fff;
  }
  .space-x-2>:not(template)~:not(template) {
    --margin-left: calc(0.35rem*(1 - var(--space-x-reverse)));
    margin-left: calc(0.35rem*(1 - var(--space-x-reverse)));
  }
  .apexcharts-tooltip-content {
    box-shadow: remove;
    /* border: 1px solid #E6ECF2; */
  }

  .card-icon-helper .text-opacity-0 {
    opacity: 0;      
  }

  .card-icon-helper:hover .text-opacity-0 {
    opacity: 1;
  }
</style>
