import Api from '@/util/Api'
import Container from '@/models/Container'

export default class PolicyContainerApiService {

    static update_or_create(payload, config = false){
        return  Api.post('/api/policy_container/update_or_create', new Container(payload), config)        
        .then(({data})=>{
            return data
        })
    }

    static delete(payload, config = false) {
        return Api.delete(`/api/policy_container/delete/${payload}`, config)
    }
}
