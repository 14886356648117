<template>
  <div class="w-full p-6">
    <div class="w-full flex justify-end space-x-3">
      <PsButton
        label="Sync Jurisdiction and Climate Zones"
        icon="sync"
        size="big"
        @click="sync()"
      />
      <PsButton
        label="Sync Jurisdiction Collections"
        icon="sync"
        size="big"
        @click="syncJurisdictionCollections()"
      />
      <router-link
        :to="{ name: 'AdminJurisdictionCreate' }"
      >
        <PsButton 
          label="Create"
          icon="add"
          size="big"
        />
      </router-link>
      <PsButton 
        label="Download CSV"
        icon="add"
        size="big"
        @click="generateJurisdictionsCsv()"
      />
    </div>

    <InputWithLabel
      v-model="search"
      class="mt-6"
      :label="'Search jurisdictions by title'"
      @keydown.enter="updateList(offset, limit)"
    />

    <div>
      <Dropdown          
        ref="dropdown"
        :button-classes="'px-2 bg-transparent'"
        :dropdown-dialog-classes="'left-0 bg-white'"
      >
        <template v-slot:buttonLabel>            
          <span 
            class="text-header-2 pb-1 flex items-center"
          >
            Filter by Type
            <feather
              class="ml-2"
              type="chevron-down"
              size="14"
            />
          </span>
        </template>
        <template v-slot:items>
          <div class="flex flex-col p-5 max-h-1/2 overflow-y-auto">
            <Checkbox
              v-for="item in getJurisdictionTypes"
              :key="item" 
              :value="collections.find(el => el == item)"
              :label="item"
              @change="toggleFilter(item)"
            />
          </div>
        </template>
      </Dropdown>
    </div>
    

    <div class="w-full overflow-x-auto">
      <table class="table table-bordered mt-6 whitespace-nowrap">
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>City</th>
            <th>County</th>
            <th>Is collection?</th>
            <th>Collections</th>
            <th>Climate Zones</th>
            <th>Policies</th>
            <th>Created at</th>
            <th class="psui-w-32">
              Edit
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="jurisdiction in jurisdictions"
            :key="jurisdiction.id"
          >
            <td>{{ jurisdiction.id }}</td>
            <td>{{ jurisdiction.title }}</td>
            <td>{{ jurisdiction.city }}</td>
            <td>{{ jurisdiction.county }}</td>
            <td>{{ jurisdiction.is_collection }}</td>
            <td>{{ getCollectionLength(jurisdiction) }} </td>
            <td>
              <router-link
                v-for="climate_zone in jurisdiction.climate_zones"
                :key="`jurisdiction-cz-${climate_zone.id}`"
                class="font-bold text-blue02"
                :to="{ name: 'AdminClimateZoneEdit', params: { climate_zone_id: climate_zone.id}}"
              >
                {{ climate_zone.raw }}
              </router-link>
            </td>
            <td>{{ jurisdiction.policies[0].count_total }}</td>
            <td>{{ jurisdiction.created_at }}</td>
            <td class="psui-flex">
              <router-link 
                :to="{ name: 'AdminJurisdictionEdit', params: { jurisdiction_id: jurisdiction.id}}" 
              >
                <PsButton 
                  label="Edit" 
                  size="medium"
                  icon="edit"
                />
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <Paginator
      v-model="offset"
      :length="jurisdictions.length"
      :limit="limit"
      @getItems="updateList(offset, limit)"
    />
  </div>
</template>

<script>
import { objArrayToCsv, downloadRawData } from '@igortrindade/lazyfy'
import dayjs from 'dayjs'
import { JURISDICTION_TYPES } from '@/util/Enums.js'

export default {
  name: 'AdminJurisdictionList',
  data() {
    return {
      search: '',
      jurisdictions: [],
      offset: 0,
      limit: 12,
      collections: []
    }
  },
  computed:{
    getJurisdictionTypes(){
      return [
        ...Object.values(JURISDICTION_TYPES).map((jur)=>{
          return jur.replace('_',' ').replace(/./,i => i.toUpperCase()) 
        }),
      ]
    },
    getFilters(){
      return this.collections.length > 0 ?
      `
      whereIn: [
            { column: "type", value: [${this.collections.map(m => `"${m}"`).join(',')}] }
          ]
      ` 
      :
      ""
    }
  },
  mounted() {
    this.updateList()
  },
  methods: {
    getCollectionLength(jurisdiction){
      return (jurisdiction.collections.length ?? false).toString()
    },
    toggleFilter(type){
      const getType = this.collections.find(el => el == type) 
      if(!getType) {
        this.collections.push(type) 
      } else {
        this.collections.splice(type.indexOf(),1)
      }

      this.updateList()
    },
    async getJurisdictions(offset = 0, limit = 12) {
      this.offset = offset
      const query = `
        {
          jurisdictions(
            where: [
              {column: "title", operation: "ILIKE", value: "%${this.search}%"},
            ]
            ${this.getFilters},
            orderBy: [{column: "id", order: "asc"}], 
            paginate: { offset: ${offset}, limit: ${limit}}
          ) {
            id
            title
            type
            city
            county
            created_at
            is_collection
            climate_zones {
              id
              raw
            }
            policies ( count: "id" ) {
              count_total
            }
            collections {
              id
              jurisdiction_id
              climate_zone_prefix
              percentage
            }
          }
        }
      `
      const { data } = await this.$graphql({ query })

      return data.jurisdictions.filter(jur => {
        return this.collections.includes(jur.type) || this.collections.length == 0
      })
    },

    updateList(offset = 0, limit = 12) {
      this.getJurisdictions(offset, limit)
        .then((jurisdictions) => {
          this.jurisdictions = jurisdictions
        })
    },

    generateJurisdictionsCsv() {


      this.getJurisdictions(0, 5000)
        .then((jurisdictions) => {

          const data = jurisdictions.map((jurisdiction) => {
            return {
              "Jurisdiiction": jurisdiction.title,
              "Type": jurisdiction.type,
              "Climate Zones": jurisdiction.climate_zones.map((climate_zone) => climate_zone.raw).join('; '),
              "Policies total": jurisdiction.policies[0].count_total
            }
          }).sort((a, b) => b["Policies total"] - a["Policies total"])
          const fileName = 'jurisdictions_with_policies' + dayjs().format('YYYY-MM-DD HH:mm:ss') + '.csv'
          downloadRawData(objArrayToCsv(data, ','), fileName)
          
        })

    },

    sync() {
      this.$api.get('/jurisdiction/sync')
        .then(() => {
          this.$toast.success('Sync jurisdiction and climate zone done')
        })
    },

    syncJurisdictionCollections() {
      this.$api.get('/api/admin/jurisdiction_collection/sync')
        .then(() => {
          this.$toast.success('Sync jurisdiction and climate zone done')
        })
    }
  }
}
</script>