import DataAndObjectFunctions from "@/util/DataAndObjectFunctions"
import formatNumber from '@/formatters/formatNumber'
import { BUILDING_TYPE_DB_SLUGS, TYPE_PROTOTYPES_DB_SLUGS } from '@/util/Enums.js'
import Store from '@/store'

/**
 * Abstracted Data and Object functions to a own module
 */
 export const findByObj = DataAndObjectFunctions.findedByObj
 export const findByString = DataAndObjectFunctions.findByString
 export const find = DataAndObjectFunctions.find
 export const findIndex = DataAndObjectFunctions.findIndex
 export const findAll = DataAndObjectFunctions.findAll
 export const removeAll = DataAndObjectFunctions.removeAll
 export const filterObjectKeys = DataAndObjectFunctions.filterObjectKeys
 export const initClassData = DataAndObjectFunctions.initClassData
 
export const randomString = (length) => {
  var result           = ''
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const randomInt = (max, min = 0) => {
  return Math.floor((Math.random() * max) + min)
}

export const randomLetters = (length) => {
  var result           = ''
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  var charactersLength = characters.length
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const handleTableScroll = (ev) => {
  if(ev.target.scrollLeft > 0) {
    ev.target.classList.add('table-has-scroll-left')
  } else {
    ev.target.classList.remove('table-has-scroll-left')
  }

  if( (ev.target.scrollLeft + ev.target.offsetWidth + 10 ) >= ev.target.scrollWidth) {
    ev.target.classList.remove('table-has-scroll-right')
  } else {
    ev.target.classList.add('table-has-scroll-right')
  }
}

export const objectHasAnyNullValue = (obj) => {
  for (let key in obj) {
    if (obj[key] === null) {
      return true
    }
  }
  return false
}

export const sumValuesFromObject = (obj) => {
  let sum = 0
  for( let el in obj ) {
    if( obj?.[el] ) {
      sum += parseFloat( obj[el] )
    }
  }
  return sum
}

export const joinCommaPlusAnd = (a) => {
  return [a.slice(0, -1).join(', '), a.slice(-1)[0]].join(a.length < 2 ? '' : ' and ')
}

export const onlyUniqueInArray = (value, index, self) => {
  return self.indexOf(value) === index
}

export const getMeasuresLengthText = (measures) => {
  const measuresLength = measures.length

  if (measuresLength === 0) {
    return null
  } else if (measuresLength === 1) {
    return `<span class="psui-flex psui-items-center psui-justify-center psui-bg-blue-60 psui-text-white psui-rounded-full psui-w-6 psui-h-6 psui-mr-3">${measuresLength}</span> Measure combined`
  } else {
    return `<span class="psui-flex psui-items-center psui-justify-center psui-bg-blue-60 psui-text-white psui-rounded-full psui-w-6 psui-h-6 psui-mr-3">${measuresLength}</span> Measures combined`
  }
}

const timestamps = []
export const delayExecution = (ms = 200, callback) => {
  const timestamp = Date.now()
  timestamps.push(timestamp)
  setTimeout( async () => {
    if(timestamps.length === 1) {
      callback()
    }
    timestamps.splice(timestamps.indexOf(timestamp), 1)
  }, ms)
}

export const checkRouteParams = ({ Router, type, item }) => {
  if(!Router.history.current[type][item]) {
    return []
  } else {
    return  Router.history.current[type][item].split(',').map(id => parseInt(id))
  }
}

export const removeNegative = (string) => {
  if (!string) return 0
  if (typeof string != 'string') string = string.toString()
  return parseFloat(string.toString().replace('-', ''))
}

export const isNegative = (string) => {
  if (typeof string != 'string') string = string.toString()
  return (string.indexOf('-') >= 0 || string[0] == '-') ? true : false
}

export const roundDecimal = (number, decimals = 1) => {
  var p = Math.pow(10, decimals)
  return Math.round(number * p) / p
}

export const compactNumber = (number, prefix = '') => {
  let numberIsNegative = false
  if (isNegative(number)) {
    numberIsNegative = true
    number = removeNegative(number)
  }
  const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"]
  var tier = Math.log10(Math.abs(number)) / 3 | 0
  if(tier == 0) return number
  var suffix = SI_SYMBOL[tier]
  var scale = Math.pow(10, tier * 3)
  var scaled = number / scale
  const string = getToFixedDecimalIfDiffFromZero(scaled)
  return `${numberIsNegative ? '-' : ''}` + prefix + string + suffix
}

export const getToFixedDecimalIfDiffFromZero = (number) => {
  const string = number.toFixed(1)
  if(string.includes('.0')) {
    return number.toFixed(0)
  }
  return number.toFixed(1)
}


export const checkAndRoundDecimalIfExists = (number) => {
  if (number.toString().includes(".")) {
    const nearest = getNearestRoundValue(number)
    return roundDecimal(number, nearest.decimals)
  }
  return number
}

/**
 * get nearest round to value
 */
export const getNearestRoundValue = (number) => {
  number = removeNegative(number)
  const items = [
    { from: 0, to: 0.5, value: 0.01, decimals: 2 },
    { from: 0.5, to: 2, value: 0.1, decimals: 1 },
    { from: 2, to: 5, value: 0.5, decimals: 1 },
    { from: 5, to: 24, value: 1 },
    { from: 25, to: 50, value: 5 },
    { from: 50, to: 100, value: 10 },
    { from: 100, to: 250, value: 25 },
    { from: 250, to: 500, value: 50 },
    { from: 500, to: 2500, value: 100 },
    { from: 2500, to: 5000, value: 500 },
    { from: 5000, to: 25000, value: 1000 },
    { from: 25000, to: 50000, value: 5000 },
    { from: 50000, to: 250000, value: 10000 },
    { from: 250000, to: 1250000, value: 50000 },
    { from: 1250000, to: 100000000, value: 100000 },
    { from: 100000000, to: 999999999999999, value: 500000 },
  ]

  const filtered = items.filter( (item) => number >= item.from && number <= item.to )
  if (filtered.length && filtered.length <= 2) return filtered[0]
  return { value: 1 }
}

export const roundToNearest = (number, nearest) => {
  let numberIsNegative = false
  let roundedNumber
  if (isNegative(number)) {
    numberIsNegative = true
    number = removeNegative(number)
  }
  if (typeof nearest === "undefined" || !nearest) {
    nearest = getNearestRoundValue(number)
  }
  roundedNumber = checkAndRoundDecimalIfExists( Math.round(number / nearest.value) * nearest.value )
  return numberIsNegative ? `-${roundedNumber}` : roundedNumber
}

export const getNumberOfLabels = (number) => {
  if (["0", "1", "2", "4", "5"].includes(number.toString().charAt(0)))
    return 3
  if (["3", "6", "7", "8", "9"].includes(number.toString().charAt(0)))
    return 4
}

export const getParentScrollableEl = (node) => {
  
  if (node == null) {
    return null
  }

  if (node.scrollHeight > node.clientHeight) {
    return node
  } else {
    return getParentScrollableEl(node.parentNode)
  }

}

export const getParentVueComponentByName = (comp, name) => {
  if (comp.$options.name === name) {
    return comp
  } else {
    return getParentVueComponentByName(comp.$parent, name)
  }
}

export const groupBy = (xs, f) => {
  return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {})  
}

export const getStudyColumnsBySelectedStatus = (studyColumns, selectedStatus = true) => {
  const studyTypeFiltered = { ...studyColumns }
  studyTypeFiltered.columnGroups = studyColumns.columnGroups.map((columnGroup) => {
    const columnGroupFiltered = { ...columnGroup }
    columnGroupFiltered.columns = columnGroup.columns.filter((column) => column.isActive === selectedStatus )
    return columnGroupFiltered
  }).filter((columnGroup) => columnGroup.columns.length)
  return studyTypeFiltered
}

export const getSuperscriptStringFromNumber = (number) => {
  if(!Number.isInteger(number)) return

  const getNumberLength = number.toString().length

  if(getNumberLength == 1) {
    return getSuperscript(number) 
  } else {
    let newSuperscriptNumber
    const string = number.toString()

    for(const numb of string) {
      newSuperscriptNumber += getSuperscript(parseInt(numb))
    }

    return newSuperscriptNumber
  }

  function getSuperscript(superscript) {
    switch(superscript) {
      case 1:        
        return  String.fromCodePoint(0x00B9)
      case 2:
        return String.fromCodePoint(0x00B2)
      case 3:
        return String.fromCodePoint(0x00B3)
      case 4:
        return String.fromCodePoint(0x2074)
      case 5:
        return String.fromCodePoint(0x2075)
      case 6:
        return String.fromCodePoint(0x2076)
      case 7:
        return String.fromCodePoint(0x2077)
      case 8:
        return String.fromCodePoint(0x2078)
      case 9:
        return String.fromCodePoint(0x2079)
      case 0:
        return String.fromCodePoint(0x2070)
    }
  }
}

export const sortByPolicyOptionRecommended = (a, b) => {
  if (a.is_recommended && !b.is_recommended) return -1
  if (!a.is_recommended && b.is_recommended) return 1
  return 0
}

export const getBuildingTypeSlugByStudyId = (studyId) => {
  const { type_prototype_id } = getPrototypeByStudyId(studyId)
  const { building_type_id } = getTypePrototype(type_prototype_id)
  return getBuildingType(building_type_id).slug
}

export const getPrototypeByStudyId = (studyId) => Store.getters['globalEntities/Prototype/getterGlobalPrototype']({ study_id: studyId })
export const getTypePrototype = (typePrototypeId) => Store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id: typePrototypeId })
const getBuildingType = (buildingTypeId) => Store.getters['globalEntities/BuildingType/getterGlobalBuildingType']({ id: buildingTypeId })

export const updateStudyResultsColumnGroupTitleAndHelper = (studyId, columnGroup) => {
  if (!studyId) return {}
  const columnGroupKeyArray = ['per_home_results']
  const { type_prototype_id } = getPrototypeByStudyId(studyId)
  const { building_type_id, units_normalization } = getTypePrototype(type_prototype_id)
  const buildingTypeSlug = getBuildingType(building_type_id).slug

  let columnGroupTitle, columnGroupHasHelper
  
  if (buildingTypeSlug === BUILDING_TYPE_DB_SLUGS.RESIDENTIAL || !columnGroupKeyArray.includes(columnGroup.key)) {
    columnGroupTitle = columnGroup.title
    columnGroupHasHelper = columnGroup.hasHelper
  } else if (buildingTypeSlug === BUILDING_TYPE_DB_SLUGS.NON_RESIDENTIAL && columnGroupKeyArray.includes(columnGroup.key)) {
    const typePrototypeUnitsNormalizationFormatNumber = formatNumber(units_normalization)
    columnGroupTitle = `Per ${typePrototypeUnitsNormalizationFormatNumber} ft² of Building Area`
    columnGroupHasHelper = {
      id: 117,
      type: 'helpers',
      slug: 'per_sqft_results',
    }
  }
  
  return {
    columnGroupTitle,
    columnGroupHasHelper,
  }
}

export const updateColumnCopiesAndHelpers = (studyId, column) => {
  if (!studyId) return
  column = {...column}
  const columnKeyArray = 'forecast_units_affected'
  const buildingTypeSlug = getBuildingTypeSlugByStudyId(studyId)
  const { type_prototype_id } = getPrototypeByStudyId(studyId)
  const typePrototypeSlug = getTypePrototype(type_prototype_id).slug
  const { title, hasHelper } = column
  const { type, id, slug, button = '' } = hasHelper
  const columnHasHelper = { type, id, slug, button }

  let columnTitle = title

  if (buildingTypeSlug == BUILDING_TYPE_DB_SLUGS.NON_RESIDENTIAL && column.key == columnKeyArray ) {
    columnTitle = `Affected Area`
    column.description = `(ft² building area)`
    columnHasHelper.id = 118
    columnHasHelper.slug = 'forecast_sqft_affected'
    if (column.chartProjection) {
      column.chartProjection.title = `Number of Affected ft²`
    }
  } else if (buildingTypeSlug == BUILDING_TYPE_DB_SLUGS.RESIDENTIAL && column.key == columnKeyArray) {
    columnTitle = `Affected Units`
    if (column.chartProjection) {
      column.chartProjection.title = `Number of Affected Units`
    }
  } else if((buildingTypeSlug === BUILDING_TYPE_DB_SLUGS.NON_RESIDENTIAL || typePrototypeSlug === TYPE_PROTOTYPES_DB_SLUGS.MULTIFAMILY) && ['compliance_margin'].includes(column.key)) {
    columnTitle = `Compliance Margin`
  }

  return {
    column,
    columnTitle,
    columnHasHelper,
  }
}

export const updateColumnDescriptionCopies = (studyId, column) => {

  if (!studyId) return

  const columnKeyArray = ['compliance_margin']
  const buildingTypeSlug = getBuildingTypeSlugByStudyId(studyId)
  const { type_prototype_id } = getPrototypeByStudyId(studyId)
  const typePrototypeSlug = getTypePrototype(type_prototype_id).slug

  const { description } = column

  const replaceObj = { description }
  if ((buildingTypeSlug === BUILDING_TYPE_DB_SLUGS.NON_RESIDENTIAL || typePrototypeSlug === TYPE_PROTOTYPES_DB_SLUGS.MULTIFAMILY) && columnKeyArray.includes(column.key)) {
    replaceObj.description = `(Efficiency TDV)`
    replaceObj.title = `Compliance Margin`
  } else if ((typePrototypeSlug === TYPE_PROTOTYPES_DB_SLUGS.SINGLE_FAMILY || TYPE_PROTOTYPES_DB_SLUGS.ADU) && columnKeyArray.includes(column.key)) {
    replaceObj.description = `(EDR2eff)`
  } 

  return replaceObj
}

export const getStudyTypeSlugByPrototypeId = (prototypeId) => { 
  const studyTypeId = Store.getters['globalEntities/Prototype/getterPrototypeStudyTypeId']({ prototype_id: prototypeId })
  return Store.getters['globalEntities/StudyType/getterGlobalStudyType']({id: studyTypeId}).slug
}

export const getPolicyCustomCombinationsBasedOnFlexiblePathSetupIfNeeded = (policy_id, flexible_path_setup) => {
  let policy_custom_combinations = Store.getters['policy/getterPolicyCustomCombinations'](policy_id)

  if (flexible_path_setup && Array.isArray(flexible_path_setup?.tiers) && flexible_path_setup?.tiers?.length) {
    flexible_path_setup?.tiers.forEach((tier) => {
      const climateZone = getClimateZoneOrPrototype(tier.climate_zone_id, 'ClimateZone')
      const prototype = getClimateZoneOrPrototype(tier.prototype_id, 'Prototype')

      const isTierConfigured = tier?.excluded_measures?.length || tier?.mandatory_measures?.length || tier?.target_scores?.some((ts) => ts?.value != null && ts?.value > 0)
      if (!climateZone || !isTierConfigured || !prototype) {
        return
      }

      tier.target_scores.forEach((target_score) => {
        if(target_score?.value != null && target_score?.value > 0 && target_score.type_vintage_id !== undefined) {
          const vintage = Store.getters['globalEntities/Vintage/getterGlobalVintage']({ study_id: prototype.study_id, type_vintage_id: target_score.type_vintage_id })
          
          let newPolicyCustomCombination = policy_custom_combinations.find(el => el?.climate_zone_raw === climateZone.raw && el?.prototype_id === prototype.id && el.vintage_id === vintage.id)
          if (!newPolicyCustomCombination) {
            newPolicyCustomCombination = { climate_zone_raw: climateZone.raw, prototype_id: prototype.id, vintage_id: vintage.id, vintage, prototype, measures: [] }
            policy_custom_combinations.push({...newPolicyCustomCombination})
          }
        }
      })
    })
  }

  return policy_custom_combinations
}

const getClimateZoneOrPrototype = (id, entityType) => {
  // 'globalEntities/ClimateZone/getterGlobalClimateZone' and 'globalEntities/Prototype/getterGlobalPrototype'
  return Store.getters[`globalEntities/${entityType}/getterGlobal${entityType}`]({ id: id?.toString() }) || 
         Store.getters[`globalEntities/${entityType}/getterGlobal${entityType}`]({ id: +id })
}

export const defaultTooltipOptions = {
  html: true,
  placement: 'bottom-center',
  show: false,
  trigger: 'hover',
  hideOnTargetClick: false,
  autoHide: false,
  popperOptions: {
    modifiers: {
      preventOverflow: { enabled: true },
      hide: { enabled: false }
    }
  },
  classes: 'custom-tooltip'
}

export function createTooltipOptions(condition) {
  let tooltip = { ...defaultTooltipOptions }

  if (condition) {
    tooltip.content = 'Click to view and edit requirements'
    tooltip.show = true
  } else {
    tooltip.content = false
    tooltip.show = false
  }

  return tooltip
}

export const isFeatureFlagEnabled = (binding) => {  
  const featureFlags = Store.getters['general/getterDeviceInfo']?.feature_flags || []
  if (binding && featureFlags.length > 0 && featureFlags.some(ff => binding.some(b => b == ff.feature))) {
    return true
  } else {
    return false
  }
}