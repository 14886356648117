<template>
  <div class="w-full p-6">
    <div class="w-full flex justify-end">
      <router-link :to="{ name: 'AdminFrequentQuestionCreate' }">
        <PsButton
          label="Create"
          icon="add"
          size="medium"
        />
      </router-link>
    </div>
    <table class="table table-bordered mt-6 whitespace-nowrap">
      <thead>
        <tr>
          <th>ID</th>
          <th>Title</th>
          <th>Created at</th>
          <th class="psui-w-48">
            Edit
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="frequent_question in frequent_questions"
          :key="frequent_question.email"
        >
          <td>{{ frequent_question.id }}</td>
          <td>{{ frequent_question.title }}</td>
          <td>{{ frequent_question.created_at }}</td>
          <td class="psui-flex psui-space-x-2">
            <router-link
              :to="{
                name: 'AdminFrequentQuestionEdit',
                params: { frequent_question_id: frequent_question.id },
              }"
            >
              <PsButton
                label="Edit"
                icon="edit"
                size="medium"
              />
            </router-link>
            <PsButton
              layout="caution"
              size="medium"
              label="Delete"
              icon="delete"
              @click="selectFrequentQuestionToDelete(frequent_question)"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <BaseModal
      ref="deleteModal"
      modal-dialog-classes="w-full max-w-sm"
      title="Attention"
    >
      <div
        v-if="frequent_question_selected"
        class="w-full flex flex-col items-start mt-6"
      >
        <p class="text-base text-gray04">
          Delete
          <span class="text-red font-bold">{{
            frequent_question_selected.title
          }}</span>?
        </p>
        <PsButton
          layout="caution"
          class="psui-mt-6"
          label="Confirm delete"
          size="big"
          @click="deleteFrequentQuestion()"
        />
      </div>
    </BaseModal>

    <Paginator
      v-model="offset"
      :length="frequent_questions.length"
      :limit="limit"
      @getItems="getFrequentQuestions({ offset })"
    />
  </div>
</template>

<script>
export default {
  name: 'AdminFrequentQuestionsList',
  data() {
    return {
      frequent_questions: [],
      frequent_question_selected: null,
      offset: 0,
      limit: 12,
    }
  },
  mounted() {
    this.getFrequentQuestions()
  },
  methods: {
    async getFrequentQuestions({ offset = 0 } = {}) {
      this.offset = offset
      const query = `
        {
          frequent_questions(
            orderBy: [{column: "id", order: "asc"}], 
            paginate: { offset: ${this.offset}, limit: ${this.limit}}
          ) {
            id
            title
            created_at
          }
        }
      `
      const { data } = await this.$graphql({ query })

      this.frequent_questions = data.frequent_questions
    },

    selectFrequentQuestionToDelete(frequent_question) {
      this.frequent_question_selected = frequent_question
      this.$refs.deleteModal.showModal = true
    },

    deleteFrequentQuestion() {
      this.$api
        .delete(`/frequent_questions/${this.frequent_question_selected.id}`)
        .then(({ data }) => {
          this.$toast.success(data.message)
          this.getFrequentQuestions()
          this.$refs.deleteModal.showModal = false
          this.frequent_question_selected = null
        })
    },
  },
}
</script>
