import Vue from 'vue'

export default () => {
    if ('serviceWorker' in navigator && !window.Cypress) {
        const oldScriptPath = `/workers/cache-service-worker.js`
        const scriptPath = `/cache-service-worker.js`
        navigator.serviceWorker.getRegistrations().then((registrations) => {
            if (window.location.hostname === 'localhost') {
                registrations.forEach((r) => r.unregister())
                return
            }

            const oldInstallation = registrations.find((r) => (new URL(r.active.scriptURL)).pathname === oldScriptPath)
            if (oldInstallation) {
                oldInstallation.unregister()
            }
            const firstInstall = !registrations.some((r) => (new URL(r.active.scriptURL)).pathname === scriptPath)
            navigator.serviceWorker.register(scriptPath, { scope: '/' }).then((registration) => {
                registration.onupdatefound = () => {
                    const installingWorker = registration.installing
                    if (installingWorker == null) {
                        return
                    }
                    installingWorker.onstatechange = () => {
                        if (installingWorker.state === 'activated' && !firstInstall) {
                            let remainingSeconds = 30
                            const finishUpdate = () => {
                                clearInterval(interval)
                                window.location.reload(true)
                            }
                            const genText = () => `New App version released. This tab will automatically reload in ${remainingSeconds} seconds.<span class="v-toast__action psui-text-accent-small">Update now</span>`
                            const toast = Vue.$toast.info(genText(), { duration: remainingSeconds * 1000, onDismiss: () => {  finishUpdate() } })
                            const interval = setInterval(() => {
                                if (remainingSeconds <= 0) {
                                    finishUpdate()
                                }

                                remainingSeconds--
                                toast.message = genText()
                            }, 1000)
                        }
                    }
                }
            }).catch(error => {
                console.error('Error during cache service worker registration:', error)
            })
        })
    }
}