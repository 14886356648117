<template>
  <v-popover
    ref="measureTooltip"
    :placement="getTooltip.placement"
    :trigger="getTooltip.trigger"
    :popover-class="getTooltip.classes"
    :delay="getTooltip && getTooltip.delay ? getTooltip.delay : undefined"
    :open="!isConfirmPopoverOpen && tooltipIsOpen && getTooltip && getTooltip.content"
  >
    <div
      class="flex items-center justify-start transition-all mr-3 flex-shrink-0"
      :class="[
        !studyDataRow.measure_is_added ? '' : 'opacity-100'
      ]"
    >
      <Icon 
        v-if="getIcon"
        :icon="getIcon.material"
        class="material-icons my-auto"
        :class="[getIcon.class, { 'cursor-pointer' : !getIcon.disabled }]"
        :data-test-id="!getIcon.disabled ? 'policy-measure-is-available-to-select' : 'policy-measure-is-not-available-to-select'"
        @click.native="toggleMeasure()"  
      />
    </div>

    <template slot="popover">
      <div v-html="getTooltip.content" />
      <div v-if="getTooltip && getTooltip.button">
        <a
          class="button"
          :href="getTooltip.button.link"
          target="_blank"
        >
          {{ getTooltip.button.title }}
        </a>
      </div>

      <div
        v-if="getTooltip.trigger == 'manual'"
        class="w-full flex space-x-2"
      />
    </template>
  </v-popover>
</template>

<script>

export default {
  name: 'ForecastTableRowActions',
  props: ['studyDataRow', 'isViewMode'],
  data() {
    return {
      tooltipIsOpen: false,
      dontShowTooltipAgainOnSession: false,
      isConfirmPopoverOpen: false,
    }
  },
  computed: {
    getTooltip() {
      let options = { placement: "top", hideOnTargetClick: false, trigger: 'manual' }
      if (this.isConfirmPopoverOpen || this.studyDataRow?.measure_is_added) {
        return { ...options, classes: 'hide' }
      }
      if (this.studyDataRow?.measure?.include_in_combination === false) {
        options.trigger = 'hover'
        options.content = this.studyDataRow.measure.exclude_in_combination_reason
        if (!options.content) {
          const prototype = this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: this.studyDataRow.prototype_id })
          if (prototype.allow_policy_creation !== true) {
            options.content = 'Results for this study are not available to use in policies yet, but will be available soon'
          }
        }
        options.classes =
          "tooltip-combination-warnings tooltip-combination-conflict w-auto"
      } else if (this.studyDataRow.measure_combination_conflict) {
        options.trigger = 'hover'
        options.content = `<strong>Cannot be combined with ${this.studyDataRow.measure_combination_conflict.title}</strong>`
        options.classes =
          "tooltip-combination-warnings tooltip-combination-conflict w-auto"
      } else if(this.studyDataRow.isTdvButNotOnBillEffective) {
        options.content = 'FYI, this measure/package is cost-effective on a TDV basis only.'
        options.helper_slug = 'tdv_but_non_bill_cost_ratio'
      } else if (!this.studyDataRow.isCostEffective) {
        options.trigger = 'hover'
        options.hideOnTargetClick = true
        options.delay = {
          show: 3000,
          hide: 100
        }
        options.content = `<strong>Careful, this measure is not cost-effective on its own, requiring it is complicated.</strong>`
        options.classes = "tooltip-combination-warnings tooltip-not-cost-effective w-auto"
        options.button = {
          title: 'Learn More',
          link: 'https://intercom.help/explorer-local-energy-codes/en/articles/6805213',
        }
      }
      if (!options.content) {
        options.trigger = 'manual'
      }
      return options
    },
    getIcon() {
      const { studyDataRow } = this
      if (this.isViewMode) {
        return {
          material: 'ic:round-check-box',
          class: 'psui-text-blue-60',
          disabled: true
        }
      } else if (studyDataRow.measure_is_added) {
        return {
          material: 'ic:round-check-box',
          class: studyDataRow.isCostEffective ? 'psui-text-blue-60' : 'psui-text-gray-50'
        }
      } else if (studyDataRow.measure_could_be_combined && studyDataRow.isCostEffective) {
        return {
          material: 'ic:round-check-box-outline-blank',
          class: 'psui-text-blue-60'
        }
      } else if (this.getTooltip.classes.includes('tooltip-combination-warnings') && !this.getTooltip.classes.includes(' tooltip-not-cost-effective')) {
        return {
          material: 'ic:round-check-box-outline-blank',
          class: 'psui-text-red-15',
          disabled: true,
        }
      } else if (!studyDataRow.isCostEffective) {
        return {
          material: 'ic:round-check-box-outline-blank',
          class: 'psui-text-gray-50',
          confirm: {
            text: 'Careful, this measure is not cost-effective on its own, requiring it is complicated.',
            learnMoreLink: 'https://intercom.help/explorer-local-energy-codes/en/articles/6805213',
            skipPreferenceKey: 'skip_non_cost_effective_measures_select_confirmation',
          }
        }
      } else {
        return {
          material: 'check_box_outline_blank',
          class: 'psui-text-gray-30',
          disabled: true,
        }
      }
    }

  },
  methods: {
    toggleMeasure() {
      if(this.getIcon.disabled) return
      if(this.getTooltip && this.studyDataRow.isTdvButNotOnBillEffective && !this.dontShowTooltipAgainOnSession) {
        this.tooltipIsOpen = !this.studyDataRow.measure_is_added
      }

      const doMeasureToggle = () => {
        this.$emit('toggleMeasure')
      }

      if (this.getIcon?.confirm && !this.studyDataRow.measure_is_added) {
        this.isConfirmPopoverOpen = true
        this.$eventBus.$emit('openConfirmPopover', {
          targetElem: this.$el,
          text: this.getIcon?.confirm?.text,
          neverAskUserProp: this.getIcon?.confirm?.skipPreferenceKey,
          learnMoreLink: this.getIcon?.confirm?.learnMoreLink,
          onConfirmCallback: doMeasureToggle,
          onCloseCallback: () => {
            this.isConfirmPopoverOpen = false
          }
        })
        return
      }
      doMeasureToggle()
    }
  }
}
</script>
