import { toggleInArray } from '@igortrindade/lazyfy'

export default class Comparison {

  constructor( obj = {}) {
    this.id = obj.id || null
    this.title = obj.title || null
    this.description = obj.description || null
    this.user_id = obj.user_id || null
    this.user_device_id = obj.user_device_id || null
    this.policy_ids = obj.policy_ids || null
    this.policies = obj.policies || []
    this.jurisdiction = obj.jurisdiction || null
    this.jurisdiction_id = obj.jurisdiction_id || null
    this.created_at = obj.created_at || null
    this.updated_at = obj.updated_at || null
    this.deleted_at = obj.deleted_at || null
    this.data = obj
  }

  isPolicyOnComparison({ policy_id }) {
    const policyChecker = this.policies.find(p => p.id == policy_id)    
    const policyIdChecker = this.policy_ids.split(',').find(id => id == policy_id)    
    return policyChecker || policyIdChecker ? true : false
  }

  getPolicyIds() {
    return this.policy_ids ? this.policy_ids.split(',') : []
  }

  togglePolicy({ policy_id }) {
    const policy_ids = toggleInArray(this.getPolicyIds(), policy_id)
    const policies = toggleInArray([...this.policies], { id: policy_id })
    this.policy_ids = policy_ids.join(',')
    this.policies = policies
  }

}
