import Jurisdiction from '@/models/Jurisdiction'
import toggleColumnIsActive from '@/business-logic/services/columns/toggleColumnIsActive'
import moveColumnItem from '@/business-logic/services/columns/moveColumnItem'
import moveColumnGroup from '@/business-logic/services/columns/moveColumnGroup'

export default {
  setJurisdiction(state, jurisdiction) {
    if (jurisdiction) state.jurisdiction = new Jurisdiction(jurisdiction)
  },
  
  toggleColumnIsActive,

  moveColumnItem,
  
  moveColumnGroup,

  setForecastLoader(state, { loader }) {
    state.forecastLoader = loader
  },
}
