<template>
  <li class=" flex flex-row gap-1 border border-gray05 mb-1 justify-between items-center">
    <div class="grid grid-cols-2 p-2 w-full gap-2">
      <InputWithLabel 
        :label="'Title'" 
        :value="title"
        class="place-self-start" 
        @input="$emit('update:title', $event)"
      />      
      <TextareaWithLabel 
        :label="'Content'" 
        :value="content"
        :rows="4"
        class="col-start-1"
        @input="$emit('update:content', $event)"
      />
      <NativeSelectInput 
        :label="'Image'" 
        :items="items"
        :value="img" 
        class="h-14 row-start-1 col-start-2 mt-0"
        @input="$emit('update:img', $event)"
      />
      <div class="row-start-2 col-start-2 row-span-1 place-self-left">
        <img 
          v-if="img"
          :src="img" 
          alt="Image content" 
          width="150"         
          height="auto"
          @error="checkImgValidity"
        >
      </div>
    </div>    
    <button
      class="button button-blue04 mr-2"
      @click.prevent="removeFeature"
    >
      Remove
    </button>
  </li>
</template>

<script>
import InputWithLabel from "../../../components/general/InputWithLabel.vue"
import NativeSelectInput from "../../../components/general/NativeSelectInput.vue"
import TextareaWithLabel from "../../../components/general/TextAreaWithLabel.vue"

const IMAGES = [
  {
    label: "Auto Generate",
    value: "/images/upcoming_features/auto-generate-1.jpg"
  },
  {
    label: "Choosing Study",
    value: "/images/upcoming_features/choosing-study-1.jpg"
  },
  {
    label: "Comparison",
    value: "/images/upcoming_features/comparison-1-1.jpg"
  },
  {
    label: "Download Data",
    value: "/images/upcoming_features/download-data-1.jpg"
  },
  {
    label: "Energy Code",
    value: "/images/upcoming_features/energy-codes.jpg"
  },
  {
    label: "Estimates",
    value: "/images/upcoming_features/estimates.jpg"
  },
  {
    label: "Flexible Path",
    value: "/images/upcoming_features/flexible-paths-1.jpg"
  },
  {
    label: "Making Policies",
    value: "/images/upcoming_features/making-policies-1.jpg"
  },
  {
    label: "Measure",
    value: "/images/upcoming_features/measure-by-measure-1.jpg"
  },
  {
    label: "New Policy",
    value: "/images/upcoming_features/new-policy-1.jpg"
  },
  {
    label: "New Studies",
    value: "/images/upcoming_features/new-studies-is-coming.jpg"
  },
  {
    label: "Prescriptive",
    value: "/images/upcoming_features/prescriptive.jpg"
  },
  {
    label: "Upload Data",
    value: "/images/upcoming_features/upload-data-1.jpg"
  },
  {
    label: "User",
    value: "/images/upcoming_features/user-1.jpg"
  },
  {
    label: "More Studies",
    value: "/images/more-studies.jpg"
  },
  {
    label: "Impact Comparison",
    value: "/images/impact-comparison.jpg"
  },
  {
    label: "Impact Estimation",
    value: "/images/impact-estimation.jpg"
  },
  {
    label: "Mobile Experience",
    value: "/images/mobile-experience.jpg"
  },
  {
    label: "Policy SignIn",
    value: "/images/add-to-policy-sign-in.jpg"
  }
]


export default {

  name: "AdminUpcomingFeaturesCard",
  components: { InputWithLabel, NativeSelectInput, TextareaWithLabel },
  props: ["index", "title", "content", "img"],
  emits: ["feature-index", "update:title", "update:content", "update:img"],
  data(){
    return {
      items: IMAGES,      
    }
  },
  methods: {
    removeFeature() {
      this.$emit("feature-index", this.index)
    },
    checkImgValidity(event) {
      return event.target.src='/loading.png'
    }
  },
}
</script>

<style>
</style>