<template>
  <div class="">
    <div class="block">
      <ApexChartGeneral 
        :options="getChartOptions()"
        :palette-color-index="combinationColors[0]"
      />
    </div>
    <div class="w-full flex flex-col">
      <div
        class="psui-w-full psui-p-6 psui-flex psui-items-center psui-space-x-3 psui-relative cursor-pointer"
        @click.stop="showTable = !showTable"
      >
        <span 
          class="btn-arrow material-icons-round psui-text-gray-50 psui-flex-none transition-all psui-transform psui-cursor-pointer"
          :class="showTable ? 'open psui-rotate-90' : 'closed psui-rotate-0'"
        >
          chevron_right
        </span>
        
        <div class="psui-w-full psui-flex psui-flex-col">
          <h3 class="psui-text-gray-80 psui-text-h5 psui-font-bold">
            {{ policiesListTitle }}
          </h3>
        </div>
      </div>

      <div
        v-if="showTable"
        class="w-full mt-4"
      >
        <JurisdictionDashboardPoliciesList :policies="policiesListSelected" />
      </div>
    </div>
  </div>
</template>

<script>
import { MathHelpers, ArrayHelpers } from '@igortrindade/lazyfy'
import { combinationColors } from '@/business-logic/constants/chartDefaultOptions'
import JurisdictionDashboardPoliciesList from './JurisdictionDashboardPoliciesList'
export default {
  components: { JurisdictionDashboardPoliciesList },
  props: ['getPoliciesByType', 'itemTypePrototype', 'itemTypePrototypeIndex', 'policyTypeSelected'],
  data() {
    return {
      combinationColors,
      showTable: false,
      policiesListSelected: [],
      policiesListTitle: 'Click on the chart to see the list',
    }
  },
  
  computed: {


    climateZones() {
      return ArrayHelpers.uniqueByKey(this.getPoliciesByType, 'climate_zone_raw').map((i) => i.climate_zone_raw)
    },

    

    getChartSeries() {
      return this.climateZones.map((climate_zone_raw, climate_zone_current_index_color) => {
        return {
          data: this.getTypeVintageOrFuelPoliciesMapped(climate_zone_raw).map((item) => {
            
            const { policies, title, typeVintageOrFuel, policies_quantity } = item
                        
            return {
              x: typeVintageOrFuel.title,
              y: policies_quantity,
              fillColor: combinationColors[0][climate_zone_current_index_color],
              strokeColor: combinationColors[0][climate_zone_current_index_color],
              title,
              climate_zone_raw,
              typeVintageOrFuel,
              policies_quantity,
              total_policies_quantity: this.getPoliciesByType.length,
              policies,
              percentage: MathHelpers.getPercentageOfAmount(this.getPoliciesByType.length, policies_quantity),
              itemTypePrototype: this.itemTypePrototype,
            }
          })
        }
      })
    }

  },

  watch: {
    'policyTypeSelected': function(){
      this.policiesListTitle = 'Click on the chart to see the list'
      this.policiesListSelected = []
      this.showTable = false
    }
  },

  methods: {
    getChartOptions() {
      const self = this
      return { 
        chart: {
          type: 'bar',
          toolbar: { show: false, },
          events: {
            dataPointSelection: function(event, chartContext, { seriesIndex, dataPointIndex }) {
              const item = self.getChartSeries[seriesIndex].data[dataPointIndex]
              self.policiesListTitle = item.title
              self.policiesListSelected = item.policies
            }
          }
        },
        series: this.getChartSeries, 
        yaxis: {
          labels: {
            formatter: function(value) {
              return self.formatUnits(value)
            }
          },
        },
        tooltip: {
          custom: function ({ seriesIndex, dataPointIndex }) {
            const item = self.getChartSeries[seriesIndex].data[dataPointIndex]
            return `
              <div class="psui-flex" style="background-color: ${ item.fillColor } !important;">
                <div class="apexcharts-tooltip-content psui-w-full psui-bg-white psui-p-4">
                  <h4 class="psui-text-gray-80 psui-text-xsmall psui-font-bold">Climate Zone ${ item.climate_zone_raw }</h4>
                  <div class="psui-w-full psui-flex psui-items-center">
                    <h2 class="psui-text-gray-80 psui-text-small">${ self.formatUnits(item.policies_quantity) } Policies</h2>
                    <span class="psui-text-gray-30 psui-mx-1">|</span>
                    <h2 class="psui-text-gray-50 psui-text-small">${ self.formatNumber(MathHelpers.getPercentageOfAmount(self.getPoliciesByType.length, item.policies_quantity)) }%</h2>
                  </div>
                  <h4 class="psui-text-gray-40 psui-text-xsmall psui-font-bold">${ item.typeVintageOrFuel.title }</h4>
                </div>
              </div>
            `
          }
        }
      }
    },

    getTypeVintageOrFuelPoliciesMapped(climate_zone_raw) {
      if(this.policyTypeSelected.key === 'vintage_id') {
        return this.$store.getters['globalEntities/TypeVintage/getterGlobalTypeVintages']({ hide_in_building_estimates: false }).map((type_vintage) => {
            
          const vintagesIds = this.$store.getters['globalEntities/Vintage/getterGlobalVintages']({ type_vintage_id: type_vintage.id })
            .map((vintage) => vintage.id)

          const policiesInTypeVintage = ArrayHelpers.findAll(this.getPoliciesByType, {
            climate_zone_raw,
            vintage_id: vintagesIds
          }, false)

          const policies = ArrayHelpers.uniqueByKey(policiesInTypeVintage, 'id')

          const title = `Climate Zone: ${climate_zone_raw} - Type Vintage: ${type_vintage.title}`

          return {
            title, 
            policies,
            policies_quantity: policies.length,
            typeVintageOrFuel: type_vintage
          }
        })
      } else {
        return this.$store.getters['globalEntities/TypeFuel/getterGlobalTypeFuels']({ slug: ['all-electric', 'mixed-fuel'] }).map((type_fuel) => {

          const fuelsIds = this.$store.getters['globalEntities/Fuel/getterGlobalFuels']({ type_fuel_id: type_fuel.id })
            .map((fuel) => fuel.id)

          const policiesInTypeFuel = ArrayHelpers.findAll(this.getPoliciesByType, {
            climate_zone_raw,
            fuel_id: fuelsIds
          }, false).filter((policy) => {
            return policy.policy_containers.filter((policy_container) => {
              return policy_container.custom_combinations.filter((custom_combination) => {
                return ['requred', 'allowed'].includes(custom_combination?.meta?.fuel_choice)
              }).length > 0
            }).length > 0
          })

          const policies = ArrayHelpers.uniqueByKey(policiesInTypeFuel, 'id')
          const title = `Climate Zone: ${climate_zone_raw} - Type Fuel: ${type_fuel.title} [Allowed or Required]`

          return {
            title, 
            policies,
            policies_quantity: policies.length,
            typeVintageOrFuel: type_fuel
          }
        })
      }
    },
  }
}
</script>

<style>

</style>