<template>
  <BaseModal
    ref="baseModal"
    modal-dialog-classes="w-auto psui-bg-gray-10"
    :hierarchy="MODAL_HIERARCHY.SECONDARY"
    :show-modal-parent.sync="showModalParent"
    @close="closeModal"
  >
    <component
      :is="currentStep.component"
      ref="wizard"
      :title="comparePoliciesWizardStepOneTitle"
      :comparison-id="newComparisonId"
      @register-success="onRegisterSuccess"
      @close="closeModal"
    />
  </BaseModal>
</template>

<script>
import ComparePoliciesWizardStepOne from '@/modules/app/comparison/ComparePoliciesWizardStepOne.vue'
import ComparePoliciesWizardStepTwo from '@/modules/app/comparison/ComparePoliciesWizardStepTwo.vue'
import { MODAL_HIERARCHY } from '@/util/Enums.js'
import ComparisonPoliciesSteps from '@/modules/app/comparison/ComparisonPoliciesSteps.js'

export default {
  name: 'ComparePoliciesWizardModal',
  components: { 
    ComparePoliciesWizardStepOne,
    ComparePoliciesWizardStepTwo,
  },
  data() {
    return {
      MODAL_HIERARCHY,
      showModalParent: false,
      currentStep: ComparisonPoliciesSteps[0],
      ComparisonPoliciesSteps,
      comparePoliciesWizardStepOneTitle: '',
      newComparisonId: null,
    }
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.state.auth.loggedUser
    }
  },
  mounted() {
    this.$eventBus.$on('openComparePoliciesWizardModal', ({ policyComparison, currentJurisdiction }) => {
      this.open({ policyComparison, currentJurisdiction })
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openComparePoliciesWizardModal')
  },
  methods: {
    async open({ policyComparison, currentJurisdiction }) {
      try {
        this.showModalParent = true
        this.comparePoliciesWizardStepOneTitle = this.currentStep.title({ policyComparison, currentJurisdiction })
        const newComparison = await this.currentStep.createComparison({ policyComparison }) 
        this.newComparisonId = newComparison.id
        setTimeout(() => {
          this.currentStep = this.ComparisonPoliciesSteps[1]
        }, 1000)
      } catch(error) {
        this.closeModal()
        this.$appToast({ type: 'info', message: 'Something went wrong, please try again.' })
      }
    },
    closeModal() {
      this.showModalParent = false
      this.currentStep = this.ComparisonPoliciesSteps[0]
    },
    onRegisterSuccess(){
      this.closeModal()
    }
  }
}
</script>