<template>
  <tbody>          
    <template v-for="studyDataRow of getStudyDataOrdered">
      <tr 
        v-if="isRowVisible(studyDataRow)"
        :key="studyDataRow.id"
        :class="getTableRowClass(studyDataRow)"
        class="opacity-100-all-opacity-0-childrens-on-hover"
      >
        <td class="sticky z-10 py-2 left-0 flex items-center">
          <ForecastTableRowActions
            v-if="study.study_group.study_type_id === 1"
            :study-data-row="studyDataRow"
            :is-view-mode="isViewMode"  
            class="psui-flex psui-items-center"
            @toggleMeasure="$parent.toggleMeasure(studyDataRow)"
          />
          <div class="title-wrapper flex items-center flex-grow-1">
            <AppInfoHelper 
              :absolute="true" 
              position="right"                  
              @icon-click="$eventBus.$emit('openDescriptionModal', { type: 'measure', id: studyDataRow.measure.id })"
            >
              <span
                :ref="`measureTitle-measure-${studyDataRow.measure.id}`"
                v-go-to-admin-edit="{ name: 'AdminStudyItemEdit', params: { study_item_id: studyDataRow.measure.id, type: 'measure' }}"
                class="ellipsis overflow-hidden psui-text-gray-80 psui-text-small"
              >
                {{ studyDataRow.measure.title }}
              </span>
            </AppInfoHelper>
          </div>
          <button
            v-if="!studyDataRow.isCostEffective && studyDataRow.measure.include_in_combination !== false && showNonCeMeasureAlertButton"
            class="ml-auto psui-text-gray-60 hover:psui-text-blue-60 border px-2 py-1 psui-text-small rounded-sm align-middle psui-border-gray-30"
            @click="$eventBus.$emit('openDescriptionModal', { type: 'helpers' , slug: 'measure_not_cost_effective', button: notCostEffectiveMeasureButton })"
          >
            $
          </button>
        </td>
        <template v-for="columnGroup of columnsSelectedForStudy.columnGroups">
          <td
            v-for="column of columnGroup.columns"
            :key="studyDataRow.id + '-' + columnGroup.key + '-' + column.key"
          >
            <ForecastTableContentCell
              :data-test-id="column.key"
              :column="column" 
              :study-data-row="studyDataRow"
              :prototype="prototype"
              @openProjectionsModal="$emit('openProjectionsModal', { columnKey: column.key, measure: studyDataRow.measure } )"
            />
          </td>
        </template>
      </tr>            
    </template>

    <transition name="fade">
      <tr
        v-if="getMeasureCombination && getMeasureCombination.measures_combined > 1"
        class="row-customcombination psui-bg-blue-20 sticky z-10 bottom-0"
      >
        <td class="sticky z-10 left-0 psui-text-small psui-text-blue-60 font-bold">
          <span 
            class="pl-1 psui-flex psui-items-center" 
            v-html="getMeasureCombination.measure.title"
          />
        </td>
        <template v-for="columnGroup of columnsSelectedForStudy.columnGroups">
          <td 
            v-for="column of columnGroup.columns" 
            :key="getMeasureCombination.id + '-' + columnGroup.key + '-' + column.key"
            class="py-3 psui-text-small psui-text-blue-60"
          >
            <ForecastTableContentCell 
              :column="column" 
              :study-data-row="getMeasureCombination" 
              :is-custom-combination="true" 
              :prototype="prototype" 
              :vintage="vintage" 
            />
          </td>
        </template>
      </tr>
    </transition>
  </tbody>
</template>

<script>
import ForecastTableRowActions from './ForecastTableRowActions.vue'
import ForecastTableContentCell from './ForecastTableContentCell.vue'
export default {
  name: 'ForecastTableBody',
  components: { ForecastTableContentCell, ForecastTableRowActions },
  props: {
    isViewMode: {
      type: Boolean,
      default: false
    },
  },
  data:()=>({
    notCostEffectiveMeasureButton:{
      title: 'Learn More',
      url: 'https://intercom.help/explorer-local-energy-codes/en/articles/6805213'
    },
    showNonCeMeasureAlertButton: false,
  }),
  computed: {
    expanded() {
      return this.$parent.expanded
    },
    getMeasureCombination() {
      return this.$parent.getMeasureCombination
    },
    getStudyDataOrdered() {
      return this.$parent.getStudyDataOrdered
    },
    study() {
      return this.$parent.study
    },
    vintage() {
      return this.$parent.vintage
    },
    prototype() {
      return this.$parent.prototype
    },
    columnsSelectedForStudy() {
      return this.$parent.columnsSelectedForStudy
    },
    getDeviceIsAdmin() {
      return this.$store.getters['general/getterDeviceInfo'].is_admin
    }
  },
  mounted() {
    this.$eventBus.$on('userPreferencesUpdated', this.updateShowNonCeMeasureAlertButton)
  },
  beforeDestroy() {
    this.$eventBus.$off('userPreferencesUpdated', this.updateShowNonCeMeasureAlertButton)
  },
  methods: {
    showTootipRowMeasureTitle(measure, type) {
      const widthLimit = type === 'measure' ? 210 : 240
      const element = this.$refs?.[`measureTitle-${type}-${measure.id}`]?.[0] ?? null      
      return element && element.offsetWidth < widthLimit ? false : measure.title
    },
    getTableRowClass(studyDataRow) {
      if (this.isViewMode) return ''
      return {
        'row-disabled' : this.expanded && !studyDataRow.measure_could_be_combined,
        'row-default' : this.expanded && !studyDataRow.measure_is_added,
        'row-added' : this.expanded && studyDataRow.measure_is_added,
        'row-added-not-cost-effective' : this.expanded && studyDataRow.measure_is_added && !studyDataRow.isCostEffective && studyDataRow.measure.include_in_combination !== false
      }
    },
    isRowVisible(studyDataRow) {      
      if (this.isViewMode && !studyDataRow.measure_is_added) return false
      return true
    },
    updateShowNonCeMeasureAlertButton() {
      this.showNonCeMeasureAlertButton = Boolean(this.$store.getters['getterUserPrefferenceByPath']('skip_non_cost_effective_measures_select_confirmation') === 'true')
    }
  }
}
</script>

<style lang="scss" scoped>  
  tr.row-disabled td *:not(.opacity-0) {
    &:not(.material-icons) {
      color: #798490;
    }
  }

  .title-wrapper {
    max-width: 300px;
    padding-right: 14px;
  }
</style>
