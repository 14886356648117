<template>
  <div class="psui-flex psui-items-center psui-ml-1">
    <Dropdown
      ref="dropdown"
      title="Show only if cost-effective based on"
      :dropdown-dialog-classes="'left-0 bg-white p-6'"
      :min-width-dropdown="'300px'"
      button-classes="psui-border-0 p-0"
    >
      <template #buttonLabel>
        <PsChips
          label="Cost-Effectiveness"
          type="button"
          :checked="isFiltering"
        />
      </template>
      <template
        #items
        class="w-full min-w-3/4"
      >        
        <div class="flex flex-col mt-4">
          <Checkbox 
            label="On-Bill Benefit/Cost Ratio"
            label-classes="ts--accent--2 text-gray02"
            :value="checkQueryKeyValue('show_only_cost_effectiveness', 'onBill')"
            :tooltip="getHelperContent('forecast_filter_on_bill_benefit_to_cost_ratio')"
            @change="toggleQueryKeyValue('show_only_cost_effectiveness', 'onBill')"
          />
          <Checkbox
            label="TDV Benefit/Cost Ratio"
            label-classes="ts--accent--2 text-gray02"
            :value="checkQueryKeyValue('show_only_cost_effectiveness', 'tdv')"
            :tooltip="getHelperContent('forecast_filter_tdv_benefit_to_cost_ratio')"
            @change="toggleQueryKeyValue('show_only_cost_effectiveness', 'tdv')"
          />
        </div>
        <p class="desc">
          TDV may not be reported by some studies.
        </p>
      </template>
    </Dropdown>
  </div>
</template>

<script>
export default {
  name: 'FilterCostEffective',
  computed: {
    isFiltering() {
      return this.checkQueryHasKey('show_only_cost_effectiveness')
    }
  },
  methods: {
    getHelperContent(slug) {            
      return { 
        content: () => { return this.getHelperContentData(slug) }, 
        loadingContent: 'Loading...',
        html: true
      }
    },
    async getHelperContentData(slug) {
      const query = `
        {
          helper (
            where: [
              {column: "slug", operation: "=", value: "${slug}"},
            ]
          ) {            
            description
          }
        }
      `
      const { data } = await this.$graphql({ query })
      return data.helper?.description ?? 'Oops, content not found...'
    }
  }
}
</script>
<style scoped>
  ::v-deep .desc {  
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    color: #81878A;
  }
</style>