<template>
  <div class="flex items-center">
    <p class="psui-text-gray-80 psui-font-bold">
      Filter by
    </p>
    <div class="flex items-center gap-1 ml-2">
      <Dropdown 
        v-if="jurisdictions"
        ref="dropdownJurisdictionFilter"
      >
        <template #buttonLabel>
          <PsChips
            label="City/County"
            type="button"
            :checked="!isSelectingAllJurisdictions"
          />
        </template>
        <template #items>
          <div class="w-full flex flex-col font-medium text-gray02">
            <PsCheckbox
              v-for="(jurisdiction) in jurisdictions" 
              :key="`filterby-${jurisdiction.id}`"
              :input-value="checkJurisdictionIsSelected(jurisdiction)"
              :value="checkJurisdictionIsSelected(jurisdiction)"
              :label="getJurisdictionTitleType(jurisdiction)"
              size="small"
              @input="setFilterByJurisdiction(jurisdiction)"
            />
          </div>
        </template>
      </Dropdown>
      
      <PsChips
        label="New Buildings"
        type="checkbox"
        :checked="checkIfRouteHasTypeSelected(STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS)"
        :disabled="checkIfCanToggleStudyType(STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS)"
        @change.prevent="setStudyTypeSelected(STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS)"
      />

      <PsChips
        label="Existing Buildings"
        type="checkbox"
        :checked="checkIfRouteHasTypeSelected(STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS)"
        :disabled="checkIfCanToggleStudyType(STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS)"
        @change.prevent="setStudyTypeSelected(STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS)"
      />

      <Dropdown                 
        ref="dropdownShowPoliciesBy"
        dropdown-dialog-classes="left-0 bg-white"
      >
        <template #buttonLabel>
          <PsChips
            label="Author"
            type="button"
            :checked="isFilteringByAuthor"
          />
        </template>
        <template #items>        
          <PsCheckbox
            v-for="option in getPolicyTypesByUser"
            :key="`filter-${option.label}`"
            :label="`${option.label}`"
            size="small"
            :input-value="checkShowByOptionSelected(option.value)"
            :value="checkShowByOptionSelected(option.value)"
            @input="setFilterByAuthor(option.value)"
          />
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<script>
import { POLICY_TYPES } from '@/modules/app/policy/shared/enums.js'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'


export default {
  name: 'PoliciesListFilters',
  props: {
    jurisdictions: {
      type: Array,
      required: true,
    }
  },
  data: () => ({
    authors:[],
    STUDY_TYPES_DB_SLUGS
  }),
  computed: {
    getFilterByJurisdiction() {
      return this.$store.state.auth.user_preferences?.policies?.myPolicies?.filterByJurisdictionV2 ?? []
    },
    getFilterByAuthor() {
      return this.$store.state.auth.user_preferences?.policies?.myPolicies?.filterByAuthorV3 ?? []
    },
    getFilterByStudyType(){
      return this.$store.state.auth.user_preferences?.policies?.myPolicies?.filterByStudyTypeV3 ?? []
    },
    isSelectingAllJurisdictions() {
      return !this.getFilterByJurisdiction || !this.getFilterByJurisdiction.length || this.jurisdictions.length === this.getFilterByJurisdiction.length
    },
    isFilteringByAuthor() {
      return this.getFilterByAuthor.length > 0
    },
    getPolicyTypesByUser(){
      let policyTypes = []

      if(this.$store.getters['getterLoggedUser']){
        for (const [key, value] of Object.entries(POLICY_TYPES) ) {
          if(key !== POLICY_TYPES.DRAFT.value) policyTypes.push(value)
        }
      } else {
        policyTypes = POLICY_TYPES
      }
      return policyTypes
    },
    getGlobalStudyTypes(){
      return this.$store.getters['globalEntities/StudyType/getterGlobalStudyTypes']()
    }
  },
  beforeDestroy() {
    this.resetFilterByJurisdiction()
  },
  methods: {
    checkJurisdictionIsSelected(jurisdiction) {
      return this.getFilterByJurisdiction.includes(jurisdiction.id.toString())
    },
    setFilterByJurisdiction(jurisdiction) {
      this.$store.dispatch('setUserPreferences', {
        mutation: 'setUserPreferencesFilterByJurisdiction',
        payload : { jurisdiction, jurisdictions: this.jurisdictions }
      })
    },
    checkShowByOptionSelected(option) {
      return this.getFilterByAuthor.includes(option) ?? false
    },
    setFilterByAuthor(authors) {
      this.$store.dispatch('setUserPreferences',{ 
        mutation: 'setUserPreferencesFilterByAuthor',
        payload : { authors } 
       })
    },
    setStudyTypeSelected(study_type){
      this.toggleQueryKeyValue('only_study_type', study_type)
    },
    checkIfRouteHasTypeSelected(study_type){
      return this.checkQueryKeyValue('only_study_type', study_type)
    },
    checkIfCanToggleStudyType(study_type){
      return this.getQueryKey('only_study_type').length <= 1 && this.checkQueryKeyValue('only_study_type', study_type)
    },
    resetFilterByJurisdiction() {
      this.$store.dispatch('setUserPreferences', { mutation: 'resetUserPreferencesFilterByJurisdiction' })
    },
    getJurisdictionTitleType(jurisdiction) {
      if (jurisdiction) {
        return this.$options.filters.editJurisdictionTitleTypeBasedOnJurisdictionType(jurisdiction, 'title_type')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
 ::v-deep .psui-el-checkbox {
  background: none;
}

  ::v-deep .psui-el-checkmark {
    color: #798490;
    span {
      color: #515E6A;
      font-weight: 700;
    }     
   } 
  
  ::v-deep .dropdowndialog {
    padding: 12px 24px
  }

  ::v-deep .dropdown-button {
    border: none;
  }

  ::v-deep .psui-el-chips {
    label {
      color: rgb(121 132 144/var(--tw-text-opacity));
      background-color: rgb(243 246 249/var(--tw-bg-opacity));
      --tw-bg-opacity: 1;
    }
  }
</style>
