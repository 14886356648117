import { comparisonColumns } from './comparisonColumns'

export const initialState = () => ({
  showComparisonJurisdictionSelect: false,
  comparisonColumns,
  availableJurisdictions: [],
  userComparisons: [],
  userSharedComparisons: [],
})

export default {...initialState()}