<template>
  <BaseModal
    ref="baseModal"
    modal-dialog-classes="w-full max-w-sm"
    :title="modalTitle"
    :show-modal-parent.sync="showModalParent"
    @close="close"  
  >
    <template #default>
      <div
        v-if="!showDuplicatePolicyForm"
        class="psui-flex psui-flex-col"
      >
        <p
          v-if="modalDescription"
          class="psui-mt-4 psui-text-gray-50"
        >
          {{ modalDescription }}
        </p>

        <div
          v-if="!disableUserLoginCall2Action"
          class="psui-mt-4 psui-text-gray-50"
        >
          <p class="psui-text-big">
            Is this your policy? <a
              class="psui-text-blue-60 psui-cursor-pointer"
              @click="onClickToLogin"
            >Login</a> and start editing it.
          </p>
        </div>
        <div
          v-if="!disableModalBtns"
          class="psui-flex psui-mt-6"
        >
          <PsButton
            label="Duplicate Policy"
            size="big"
            layout="solid"
            @click="showDuplicatePolicyForm = true"
          />
          <PsButton
            label="Maybe Later"
            size="big"
            layout="onlytext"
            @click="close"
          />
        </div>
      </div>
      <PolicyFormDuplicate
        v-else
        :policy-id="policy_id"
        :title="title"
        @store-succcess="close"
      />
    </template>
  </Basemodal>
</template>

<script>
import PolicyFormDuplicate from '@/modules/app/policy/shared/PolicyFormDuplicate.vue'

export default {
  name: "PolicyUserPermissionDuplicateAlertModal",
  components:{PolicyFormDuplicate},
  data: () => ({
    policy_id: null,
    showModalParent: false,
    title: null,
    showDuplicatePolicyForm: false,
    modalTitle: null,
    modalDescription: null,
    disableModalBtns: false,
    disableUserLoginCall2Action: false,
  }),
  computed:{
    getUser() {
      return this.$store.state.auth.loggedUser
    }
  },
  mounted() {
    this.$eventBus.$on('openPolicyUserPermissionDuplicateAlertModal', ({ policy, customTitle, customDescription, disableBtns, disableUserLoginCall2Action }) => {
      const contentContainer = document.getElementById('content-container')
      contentContainer.classList.add('z-50')
      contentContainer.classList.remove('z-10')

      this.policy_id = policy.id
      this.title = policy.title + ` (2)`
      this.showModalParent = true
      this.modalTitle = customTitle || `Let's duplicate this policy, so you can edit the requirements and assumptions`
      this.modalDescription = customDescription || null
      this.disableModalBtns = Boolean(disableBtns === true)
      this.disableUserLoginCall2Action = Boolean(disableUserLoginCall2Action === true) 
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openPolicyUserPermissionDuplicateAlertModal')
  },
  methods:{
    close() {
      this.showModalParent = false
      this.showDuplicatePolicyForm = false
      const contentContainer = document.getElementById('content-container')
      contentContainer.classList.add('z-10')
      contentContainer.classList.remove('z-50')
    },
    onClickToLogin() {
      if(this.getUser) {
        this.$router.push({ name: 'UserAccount' })
        this.close()
      }
      this.$router.push({ name: 'UserSignin' })
      this.$emit('close')
      this.close()
    },
  }  
}
</script>