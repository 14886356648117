<template>
  <span    
    class="px-1 py-1/2 psui-text-gray-50 psui-bg-gray-20 psui-ml-2 psui-rounded psui-flex psui-items-center psui-cursor-pointer"
  >
    <PsTooltip>
      <template #trigger>
        <i
          class="material-icons-round shared-icon psui-flex psui-items-center"
        >
          supervisor_account
        </i>
      </template>
      <template #content>
        Shared with you
      </template>
    </PsTooltip> 
  </span>
</template>

<script>
export default {
  name: 'AppSharedIcon'
}
</script>

<style>

</style>