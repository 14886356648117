<template>
  <vue-skeleton-loader
    :width="36"
    :height="16"
    class="inline-block"
  />
</template>

<script>
export default {
    name:'SkeletonPolicyEditRequirementsTableInput'
}
</script>

<style>

</style>