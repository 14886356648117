<template>
  <div 
    class="app-drawer-footer psui-w-full psui-flex psui-flex-col psui-bg-white mt-auto"
    :class="isExpanded ? 'is-open psui-shadow-elevation-30' : 'psui-shadow-elevation-20'"
  >
    <div 
      class="psui-w-auto psui-cursor-pointer transition-all psui-flex psui-items-center psui-py-2 psui-px-6 psui-shadow-elevation-5"
      @click="collapse()"
    >
      <div class="custom-button psui-flex psui-items-center psui-justify-start">
        <PsButton          
          :icon="isExpanded ? 'expand_more' : 'expand_less'"
          layout="outline"
          size="small"
          icon-position="left"
          :disabled="true"
          class="psui-mr-2"
        />
        
        <h1 class="psui-text-blue-80 psui-text-accentSmall psui-font-bold psui-uppercase">
          Fuel choice incentive
        </h1>
      </div>

      <PsBadgeWithIcon
        v-if="showPolicyAlterCostAffectFuelChoiceAlert"
        class="self-start ml-2"
        message="Your policy may alter costs that affect fuel"
        icon="info"
        layout="green"
      />
    </div>
    <div
      v-if="isExpanded"
      class="psui-flex psui-bg-gray-10 psui-pt-2 psui-pb-4 psui-px-6"
    >
      <div class="psui-grid psui-grid-cols-2 psui-gap-4">
        <div class="psui-space-y-3">
          <h1 class="text-sm psui-text-gray-60 psui-font-bold">
            All-Electric Fuel Cost<span class="psui-text-small psui-font-normal psui-ml-1">– Cost per unit</span>
          </h1>

          <div class="psui-grid psui-grid-cols-2 psui-space-x-px">
            <div class="psui-bg-gray-20 psui-py-3 psui-px-4 psui-rounded-tl-md psui-rounded-bl-md">
              <p
                v-if="!isLoading"
                class="psui-flex psui-items-center psui-text-p psui-font-bold psui-text-gray-80 mb-6"
              >
                {{ fuelChoiceCostWithoutPolicy | absFormatter | moneyFormatter }}
                <PsIcon
                  :icon="fuelChoiceCostWithoutPolicy > 0 ? 'arrow_upward' : 'arrow_downward'"
                  size="16"
                  class="arrow psui-ml-1"
                />
                <span
                  :class="`psui-text-small ${fuelChoiceCostWithoutPolicy > 0 ? 'psui-text-red-20' : 'psui-text-green-70'} psui-pl-px`"
                >
                  {{ fuelChoiceCostWithoutPolicy > 0 ? 'higher' : 'lower' }}
                </span>
              </p>
              <SkeletonPolicyEditRequirementsFooter v-else />
              <p class="psui-text-small psui-text-gray-60">
                Without your policy
              </p>
            </div>
            <div class="psui-bg-gray-20 psui-py-3 psui-px-4 psui-rounded-tr-md psui-rounded-br-md">
              <p
                v-if="!isLoading"
                class="psui-flex psui-items-center psui-text-p psui-font-bold psui-text-gray-80 mb-6"
              >
                {{ fuelChoiceCostWithPolicy | absFormatter | moneyFormatter }}
                <PsIcon
                  :icon="fuelChoiceCostWithPolicy > 0 ? 'arrow_upward' : 'arrow_downward'"
                  size="16"
                  class="arrow psui-ml-1"
                />
                <span
                  :class="`psui-text-small ${fuelChoiceCostWithPolicy > 0 ? 'psui-text-red-20' : 'psui-text-green-70'} psui-pl-px`"
                >
                  {{ fuelChoiceCostWithPolicy > 0 ? 'higher' : 'lower' }}
                </span>
              </p>
              <SkeletonPolicyEditRequirementsFooter v-else />
              <p class="psui-text-small psui-text-gray-60">
                {{ 'With your policy' | policyTextRephrase }}
              </p>
            </div>
          </div>

          <p class="psui-text-small psui-text-gray-60">
            Construction cost for builders that choose to build all electric instead of mixed fuel.
          </p>
        </div>
        
        <div class="psui-space-y-3">
          <h1 
            v-if="getPolicyCodeCycle"
            class="text-sm psui-text-gray-60 psui-font-bold"
          >
            All-Electric Fuel Assumptions <span class="psui-text-small psui-font-normal">– {{ parseInt(getPolicyCodeCycle.start_at) | yearFromDate }}–{{ getPolicyCodeCycle.end_at | yearFromDate }}</span>
          </h1>
          <div class="psui-grid psui-grid-cols-2 psui-space-x-px psui-shadow-elevation-5 rounded-md">
            <div class="psui-bg-white psui-py-2 psui-px-4 psui-rounded-tl-md psui-rounded-bl-md">
              <PsTooltip
                :ignore-dialog="!isOnDefaultState"
                class="tooltip-wrapper-input-number"
              >
                <template #trigger>
                  <DropdownWithInputNumber
                    :disabled="!getterUserCanEditPolicy || isOnDefaultState"
                    :value="allElectricSharesWithoutPolicy"
                    :placeholder="`${allElectricSharesWithoutPolicy}`"
                    :base-value="defaultNewBuildingsImpactAssumptionValues.all_electric_shares_without_policy"
                    :min-value="0"
                    :max-value="100"
                    class="border w-20 h-8 mb-1 rounded-6 psui-border psui-border-gray-30 psui-text-small"
                    :class="{'psui-bg-gray-10 psui-text-gray-40 psui-cursor-default': !getterUserCanEditPolicy|| isOnDefaultState, 'psui-text-gray-50': getterUserCanEditPolicy}"
                    @update:value="changePolicyAssumption('all_electric_shares_without_policy', $event)"
                    @click="openNewBuildingsDuplicateModal"
                  />
                </template>
                <template #content>
                  <div class="text-left">
                    {{ 'There are currently no policy requirements selected, add requirements to your policy to edit your With Policy All-Electric Construction Assumption.' | policyTextRephrase }}
                  </div>
                </template>
              </PsTooltip>
              <p class="psui-text-small psui-text-gray-60">
                Without your policy
              </p>
            </div>

            <div 
              v-if="getPolicyCodeCycle"
              v-add-debug-variable="{ getPolicyCodeCycle_title: getPolicyCodeCycle.title }"
            />

            <div class="psui-bg-white psui-py-2 psui-px-4 psui-rounded-tr-md psui-rounded-br-md">
              <PsTooltip
                :ignore-dialog="!isOnDefaultState && !isAllElectricMandatory"
                class="tooltip-wrapper-input-number"
              >
                <template #trigger>
                  <DropdownWithInputNumber
                    :disabled="!getterUserCanEditPolicy || isAllElectricMandatory || isOnDefaultState"
                    :value="allElectricSharesWithPolicy"
                    :placeholder="`${allElectricSharesWithPolicy}`"
                    :base-value="defaultNewBuildingsImpactAssumptionValues.all_electric_shares_with_policy"
                    :min-value="0"
                    :max-value="100"
                    class="border w-20 h-8 mb-1 rounded-6 psui-border psui-border-gray-30 psui-text-small"
                    :class="{'psui-bg-gray-10 psui-text-gray-40 psui-cursor-default': !getterUserCanEditPolicy || isOnDefaultState || isAllElectricMandatory, 'psui-text-gray-50': getterUserCanEditPolicy}"
                    @update:value="changePolicyAssumption('all_electric_shares_with_policy', $event)"
                    @click="openNewBuildingsDuplicateModal"
                  />
                </template>
                <template #content>
                  <div
                    v-if="isOnDefaultState"
                    class="text-left"
                  >                    
                    {{ 'There are currently no policy requirements selected, add requirements to your policy to edit your With Policy All-Electric Construction Assumption.' | policyTextRephrase }}
                  </div>
                  <div
                    v-else-if="isAllElectricMandatory"
                    class="text-left"
                  >
                    {{ 'Your policy requirements mandate all-electric construction. To reduce the percentage of all-electric homes with your policy, edit your policy requirements to allow mixed fuel construction.' | policyTextRephrase }}
                  </div>
                </template>
              </PsTooltip>
              <p class="psui-text-small psui-text-gray-60">
                {{ 'With your policy' | policyTextRephrase }}
              </p>
            </div>
          </div>

          <p class="psui-text-small psui-text-gray-60">
            Assumption on the share of builders choosing all electric.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TYPE_FUELS_DB_SLUGS } from '@/util/Enums'
import { PolicyImpactAlgorithmFactory } from '@/business-logic/services/impact-algorithms/policy/factory'
import { FUEL_CHOICES } from '@/business-logic/services/impact-algorithms'
import { defaultNewBuildingsImpactAssumptionValues} from '@/models/ImpactAssumptions'
import { checkDeepObjectChanges } from '@/util/checkDeepObjectChanges'
import { checkIfPolicyIsOutdated } from "@/util/Helpers"
import SkeletonPolicyEditRequirementsFooter from '@/modules/app/policy/show/skeleton/SkeletonPolicyEditRequirementsFooter.vue'
import formatCurrency from '@/formatters/formatCurrency'
import dayjs from 'dayjs'


export default {
  name: 'EditRequirementsNewBuildingsSF2022Footer',
  components:{SkeletonPolicyEditRequirementsFooter},
  filters:{
    moneyFormatter: (value) => {
      return formatCurrency(value)
    },
    yearFromDate: (value) => {
      if (!isNaN(value)) {
        return new Date(Number(value)).getFullYear()
      }
      return dayjs(value).year()
    },
    absFormatter: (value) => {
      return isNaN(value) ? value : Math.abs(Number(value))
    }
  },
  props: ['custom_combinations', 'policy'],
  data: () => ({
    isExpanded : true,
    policyImpactAlgorithm: null,
    fuelChoiceCostWithoutPolicy: 0,
    fuelChoiceCostWithPolicy: 0,
    debouncers: {},
    isLoading: false,
    defaultNewBuildingsImpactAssumptionValues,
    localAssumptionValues: {},
    oldCustomCombinationMetas: []
  }),
  computed: {
    getPolicySelected() {
      return this.$store.getters['assumptions/getterPolicySelected']
    },
    allElectricCustomCombination() {
      return this.custom_combinations?.find(this.findByFuelFunc(TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC)) || null
    },
    mixedFuelCustomCombination() {
      return this.custom_combinations?.find(this.findByFuelFunc(TYPE_FUELS_DB_SLUGS.MIXED_FUEL)) || null
    },
    showPolicyAlterCostAffectFuelChoiceAlert() {
      //Both fuel choices are allowed &&
      const allElectrictAndMixedAreBothAllowed = Boolean(
        ( this.allElectricCustomCombination?.meta?.fuel_choice === 'allowed' || !this.allElectricCustomCombination?.meta?.fuel_choice  ) &&
        ( this.mixedFuelCustomCombination?.meta?.fuel_choice === 'allowed' || !this.mixedFuelCustomCombination?.meta?.fuel_choice  )
      )
      // if there's a compliance margin above 0 in one or both rows
      const complianceMarginAboveOneOrBothRows = Boolean(this.allElectricCustomCombination?.meta?.compliance_margin_value > 0 || this.mixedFuelCustomCombination?.meta?.compliance_margin_value > 0)
      // or Larger PV System is Required in one or both rows
      const largePvSystemIsRequiredInOneOrBothRows = Boolean(this.allElectricCustomCombination?.meta?.require_pv_system || this.mixedFuelCustomCombination?.meta?.require_pv_system)
      
      return Boolean(allElectrictAndMixedAreBothAllowed && (largePvSystemIsRequiredInOneOrBothRows || complianceMarginAboveOneOrBothRows))
    },
    isOnDefaultState() {
      if (!this.policyImpactAlgorithm) {
        return false
      }
      const {
        [TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC]: allElectricCustomCombination,
        [TYPE_FUELS_DB_SLUGS.MIXED_FUEL]: mixedFuelCustomCombination
      } = this.policyImpactAlgorithm.getCustomCombinationsByFuel()
      const allElectricMetaInfo = this.policyImpactAlgorithm.getCustomCombinationMetaInfo(allElectricCustomCombination)
      const mixedFuelMetaInfo = this.policyImpactAlgorithm.getCustomCombinationMetaInfo(mixedFuelCustomCombination)
      return Boolean(allElectricMetaInfo.isOnDefaultState && mixedFuelMetaInfo.isOnDefaultState)
    },
    isAllElectricMandatory() {
      return Boolean(this.allElectricCustomCombination?.meta?.fuel_choice === FUEL_CHOICES.REQUIRED ||
          this.mixedFuelCustomCombination?.meta?.fuel_choice === FUEL_CHOICES.NOT_ALLOWED)
    },
    allElectricSharesWithoutPolicy() {
      const assumption = 'all_electric_shares_without_policy'
      if (this.isOnDefaultState && !this.isLoading) return defaultNewBuildingsImpactAssumptionValues?.[assumption]
      if(this.localAssumptionValues?.[assumption] !== undefined) return this.localAssumptionValues?.[assumption]
      if(this.allElectricCustomCombination?.meta?.assumptions?.[assumption] !== undefined) return this.allElectricCustomCombination?.meta?.assumptions?.[assumption]
      return defaultNewBuildingsImpactAssumptionValues?.[assumption]
    },
    allElectricSharesWithPolicy() {
      const assumption = 'all_electric_shares_with_policy'
      if (this.isAllElectricMandatory) return 100
      if (this.isOnDefaultState && !this.isLoading) return defaultNewBuildingsImpactAssumptionValues?.[assumption]
      if(this.localAssumptionValues?.[assumption] !== undefined) return this.localAssumptionValues?.[assumption]
      if(this.allElectricCustomCombination?.meta?.assumptions?.[assumption] !== undefined) return this.allElectricCustomCombination?.meta?.assumptions?.[assumption]
      return defaultNewBuildingsImpactAssumptionValues?.[assumption]
    },
    totalBuildings() {
      return this.policyImpactAlgorithm?.totalBuildingUnits || this.localAssumptionValues?.['buildings_units'] || null
    },
    getPolicyCodeCycle() {
      const key = 'policy_selected_code_cycle'
      const value = this.getPolicySelected?.code_cycle || this.localAssumptionValues?.[key] || null
      if (this.getPolicySelected?.code_cycle && value !== null) {
        this.updateInstantAssumption(key, value)
      }
      return value
    },
    getterUserCanEditPolicy(){
      return this.$route.params.policy_id ?
      this.$store.getters['policy/getterUserCanEditPolicy'](this.$route.params.policy_id) 
      : this.$store.getters['policy/getterUserCanEditPolicy'](this.getPolicySelected?.id)
    },
    isReadOnlyOutdatedPolicy() {
      const policy = this.$store.getters['policy/getterUserPolicy']({id: this.$route.params.policy_id})
      const outdatedInfo = checkIfPolicyIsOutdated(policy)
      return Boolean(outdatedInfo.has_outdated_studies === true && outdatedInfo.is_loading === false && !outdatedInfo.block_policy)
    },
  },
  watch: {
    custom_combinations: {
      deep: true,
      async handler() {
        const ccAssumptions = this.custom_combinations?.[0]?.meta?.assumptions
        const newAllElectricSharesWithoutPolicy = ccAssumptions === null ? null : ccAssumptions?.all_electric_shares_without_policy ?? this.allElectricSharesWithoutPolicy
        const newAllElectricSharesWithPolicy = ccAssumptions === null ? null : ccAssumptions?.all_electric_shares_with_policy ?? this.allElectricSharesWithPolicy

        this.updateInstantAssumption('all_electric_shares_without_policy', newAllElectricSharesWithoutPolicy)
        this.updateInstantAssumption('all_electric_shares_with_policy', newAllElectricSharesWithPolicy)
        const currentMetas = this.custom_combinations.map((cc) => {
          return {
            ...(cc?.meta || {})
          }
        })
        if (!this.checkSkipReload(this.oldCustomCombinationMetas, currentMetas)) {

          await this.computeEstimates()
        }
        this.oldCustomCombinationMetas = currentMetas
      }
    },
    totalBuildings: {
      async handler(value) {
        if (value) {
          this.updateInstantAssumption('buildings_units', value)
        }
        await this.computeEstimates()
      }
    }
  },
  mounted() {
    this.computeEstimates()
    this.hideIntercom()
    this.$eventBus.$on('customCombinationChanged', (customCombination) => {
      const cc = this.custom_combinations.find((cc) => cc.id === customCombination.id)
      if (cc) {
        cc.meta = customCombination.meta
      }
    })
    this.$eventBus.$on('buildingEstimatesChanged', () => {
      if (this.policyImpactAlgorithm) {
        this.policyImpactAlgorithm.updateBuildings()
      }
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('customCombinationChanged')
    this.$eventBus.$off('buildingEstimatesChanged')
    this.showIntercom()
  },
  methods: {
    hideIntercom() {
      const intercom = document.querySelector('.intercom-app')
      if (intercom) {
        intercom.style.display = 'none'
      }
    },
    showIntercom(){
      const intercom = document.querySelector('.intercom-app')
      if (intercom) {
        intercom.style.display = 'block'
      }
    },
    findByFuelFunc(fuelSlug) {
      return (customCombination) => {
        if (customCombination?.fuel?.type_fuel?.slug === fuelSlug || customCombination?.fuel?.slug === fuelSlug) {
          return true
        }
        if (customCombination?.fuel_id) {
          const fuel = this.$store.getters['globalEntities/Fuel/getterGlobalFuel']({ id: customCombination?.fuel_id })
          const typeFuel = this.$store.getters['globalEntities/TypeFuel/getterGlobalTypeFuel']({ id: fuel.type_fuel_id })
          return typeFuel.slug === fuelSlug
        }
        return false
      }
    },
    collapse() {
      this.isExpanded = !this.isExpanded
    },
    updateInstantAssumption(assumption, value) {
      this.localAssumptionValues = {...this.localAssumptionValues, [assumption]: value}
    },
    changePolicyAssumption(assumption, value) {
      if (assumption === 'all_electric_shares_with_policy' && this.isAllElectricMandatory) {
        return
      }
      value = Number(value)
      this.updateInstantAssumption(assumption, value)
      const assumptionsToUpdate = {
        [assumption]: value,
      }
      this.debounce(() => {
        const currentAllElectricSharesWithPolicyMeta = this.allElectricCustomCombination?.meta?.assumptions?.['all_electric_shares_with_policy']
        if (this.allElectricSharesWithPolicy < this.allElectricSharesWithoutPolicy || currentAllElectricSharesWithPolicyMeta && currentAllElectricSharesWithPolicyMeta < this.allElectricSharesWithoutPolicy) {
          this.updateInstantAssumption('all_electric_shares_with_policy', this.allElectricSharesWithoutPolicy)
          assumptionsToUpdate.all_electric_shares_with_policy = this.allElectricSharesWithoutPolicy
        }
        this.$store.dispatch('assumptions/updateAssumptionsKeyValue', { assumptions: assumptionsToUpdate, force: true })
      }, assumption)
    },
    checkSkipReload(oldMeta, newMeta) {
      if (!oldMeta?.length || !newMeta?.length) {
        return false
      }
      const disableReloadChanges = ['assumptions', 'assumptions.all_electric_shares_with_policy', 'assumptions.all_electric_shares_without_policy']
      const keysChanged = [
          ...checkDeepObjectChanges(oldMeta[0], newMeta[0]).filter((key) => !disableReloadChanges.includes(key)),
          ...checkDeepObjectChanges(oldMeta[1], newMeta[1]).filter((key) => !disableReloadChanges.includes(key)),
      ]
      return !keysChanged?.length
    },
    debounce(func, debouncerName = 'default', timeout = 500) {
      if (this.debouncers[debouncerName]) {
        clearTimeout(this.debouncers[debouncerName])
      }
      this.debouncers[debouncerName] = setTimeout(async () => {
        this.debouncers[debouncerName] = null
        func()
      }, timeout)
    },
    async computeEstimates() {
      if (!this.custom_combinations?.length) return

      this.isLoading = true
      const that = this
      this.debounce(async () => {
        try {
          this.policyImpactAlgorithm = PolicyImpactAlgorithmFactory.createByCustomCombinations(that.custom_combinations, that.policy)
          if(this.policyImpactAlgorithm.customCombinations.length == 0) return
          if (!this.policyImpactAlgorithm) {
            console.error(`Could create policyImpactAlgorithm!`)
            return
          }

          const [allElectricPerHome, mixedFuelPerHome] = await this.policyImpactAlgorithm.computePerHomeImpact({
            bringMinimumCost: true
          })
          this.fuelChoiceCostWithoutPolicy = (allElectricPerHome?.minimumCostData?.initial_cost || 0)
          this.fuelChoiceCostWithPolicy = this.mixedFuelCustomCombination?.meta?.fuel_choice === FUEL_CHOICES.NOT_ALLOWED ?
              (allElectricPerHome?.data?.initial_cost || 0) :
              (allElectricPerHome?.data?.initial_cost || 0) - (mixedFuelPerHome?.data?.initial_cost || 0)
        } catch (error) {
          console.error(`Could not compute estimates on new buildings footer: `, error)
          this.$appToast({type: 'info', message: error?.response?.data?.message ?? 'Sorry, try again later' })
        } finally {
          this.isLoading = false
          this.$forceUpdate()
        }
      }, 'computeEstimates', 2000)
    },
    openNewBuildingsDuplicateModal(){
      const policy = this.$store.getters['policy/getterUserPolicy']({id: this.$route.params.policy_id})
      if(this.getterUserCanEditPolicy) return

      let args = { policy }
      if (this.isReadOnlyOutdatedPolicy) {
        args = {... args,
          disableUserLoginCall2Action: true,
          disableBtns: true,
          customTitle: `You can't edit this policy`,
          customDescription: `This policy is read-only since it is based on an outdated policy architecture and cost-effectiveness study results. Please create a new policy to continue policy development.`
        }
      }
      this.$eventBus.$emit('openPolicyUserPermissionDuplicateAlertModal', args)
    }
  }
}
</script>

<style lang="scss" scoped>
  .arrow {
    color: #274970;
  }

  .psui-el-input {
    max-width: 80px;
    i {
      font-size: 12px;
      right: 8px;
      line-height: 120%;
      color: #798490;
    }
    &:after {
      z-index: 10;
    }
  }

  ::v-deep .psui-cursor-default > * {
    cursor: default !important;
  }

  ::v-deep .app-checkbox {
    display: flex;   

    button {
      justify-content: flex-start;
      margin-left: 8px;
      font-size: 14px;
      line-height: 130%;

      input {
        text-align: left;

        &::placeholder {
          font-size: 14px;
        }
      }

      &::after {
        content: '%';
        font-size: 12px;
        margin-left: auto;
        margin-right: 8px;
      }
    }
  }

  ::v-deep .psui-el-tooltip.tooltip-wrapper-input-number {
    .psui-el-tooltip-wrapper {
      .psui-el-tooltip-dialog {
        width: 210px;
        text-align: justify;
      }
    }
  }

  .mb-6 {
    margin-bottom: 6px;
  }

  .custom-button {
    :deep(.psui-el-button.layout-outline.disabled) {
      cursor: pointer;
      padding: 4px;
      border-radius: 16px;
      border-color: #D6DDE5; // gray-30
      color: #798490; // gray-50
    }

    :deep(.psui-el-button.size-small.icon-left i) {
      font-size: 16px;
      margin-right: 0; 
    }
  }
</style>
