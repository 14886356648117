<template>
  <div
    :id="`forecast-study-type-${studyType.id}`"
    class="w-full"
  >    
    <div class="w-full pt-5 px-3 md:px-0 flex flex-col">        
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'StudyType',
  props: {
    studyType: {
      type: Object,
      required: true
    },
  },
  methods: {
    setModuleVisited(moduleVisited){
      this.$store.dispatch('setModuleVisited',moduleVisited)
    }
  }
}
</script>
