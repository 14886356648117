<template>
  <div>
    <PsAccordionItem
      title="Applicability Rate"
      class="display-impact-assumption-icon"
      :class="{ 'form-dirty' : hasValueUpdated(null, null) }"
    >
      <template #header-additionals>
        <PsIcon
          icon="help_outline"
          size="16"
          display="flex"
          class="help psui-items-center psui-h-5 hover:psui-text-blue-60"
          :icon-classes="hasValueUpdated(null, null) ? 'mr-5': ''"
          @click.native.stop="$eventBus.$emit('openDescriptionModal', { slug: 'impact_applicability_rate_assumption', ...descriptionModelObject })"
        />
      </template>
      <template #default>
        <span class="psui-text-gray-60 psui-text-big">
          We assume that the {{ 'policy' | policyTextRephrase('small') }} requirements will apply to <b>this percentage of units</b>, and the rest will have already implemented a similar upgrade or otherwise be exempt.
        </span>
        <transition
          name="psui-el-compound"
          @enter="startTransition"
          @after-enter="endTransition"
          @before-leave="startTransition"
          @after-leave="endTransition"
        >
          <PsInput
            v-if="specifyBy.length === 0"
            :data-test-id="`input-assumption-${assumptionKey}`"
            class="mt-4/5"
            :has-error="canShowErrorInfoInput(null, null) ? 'Insert a valid value' : false"
            :class="{'selected': hasValueUpdated(null, null) && !canShowErrorInfoInput(null, null)}"
            layout="mini"
            :value="getAssumptionValue(null, null)"
            @click.native="checkIfUserCanEditAssumption"
            @input="onInput(null, null, $event.target.value)"
            @change="updateAssumption(null, null, $event.target.value)"
            @mouseenter="onMouseEnterInsideInput(null, null)"
            @mouseleave="onMouseLeaveInsideInput"
          >
            <template #append>
              <span
                v-if="!hasValueUpdated(null, null) || !canShowResetButton(null, null)"
                class="psui-text-gray-50 psui-text-xsmall"
              >%</span>
              <PsTooltip v-else-if="canShowResetButton(null, null)">
                <template #trigger>
                  <PsIcon 
                    icon="restart_alt"
                    icon-classes="psui-text-green-20 cursor-pointer mt-1"
                    size="17"
                    @click.native="updateAssumption(null, null, getBaseValue)"
                  />
                </template>
                <template #content>
                  <h1 class="mb-1">
                    Restore to default value
                  </h1>
                  <span class="psui-font-normal">{{ getBaseValue }}</span>
                </template>
              </PsTooltip>
            </template>
          </PsInput>
        </transition>
        
        <div
          v-if="$route.name === 'PolicyShow' && canSpecifyByPrototype"
          class="psui-flex flex-col psui-text-gray-60"
        >
          <div
            class="psui-bg-gray-10 py-1/2 px-3 psui-rounded-t psui-flex psui-items-center mt-5"
            :class="{'psui-bg-blue-10': isSpecifyByBuildingTypeEnabled}"
          >
            <span
              class="psui-text-small mr-auto"
              :class="{'psui-text-blue-60 psui-font-bold': isSpecifyByBuildingTypeEnabled}"
            >Assume different values for each building type</span>
            <PsSwitch
              ref="switchItem"
              class="flex-shrink-0"
              size="small"
              label="Breakdown by construction types"
              :value="isSpecifyByBuildingTypeEnabled"
              @change="toggleSingleSpecifyBy($event, RESIDENTIAL_NEW_SPECIFICATIONS.BUILDING_TYPE)"
            />
          </div>
          <transition
            name="psui-el-compound"
            @enter="startTransition"
            @after-enter="endTransition"
            @before-leave="startTransition"
            @after-leave="endTransition"
          >
            <div
              v-if="isSpecifyByBuildingTypeEnabled && canSpecifyByPrototype"
              class="flex flex-col mt-4/5 gap-4"
            >
              <div
                v-for="(prototype) in getPrototypesWithinPolicySelected"
                :key="`prototype-switch-${prototype.id}`"
                class="flex items-center justify-between"
              >
                <span
                  class="flex-shrink-0 psui-text-gray-60 psui-text-small"
                >{{ prototype.title | prototypeTitleHomesAsUnits }} </span>

                <PsInput
                  class="fixed-input"
                  layout="mini"
                  :has-error="canShowErrorInfoInput(prototype, null) ? 'Insert a valid value' : false"
                  :class="{'selected': hasValueUpdated(prototype, null) && !canShowErrorInfoInput(prototype, null)}"
                  :value="getAssumptionValue(prototype, null)"
                  @click.native="checkIfUserCanEditAssumption"
                  @input="onInput(prototype, null, $event.target.value)"
                  @change="updateAssumption(prototype, null, $event.target.value)"
                  @mouseenter="onMouseEnterInsideInput(prototype, null)"
                  @mouseleave="onMouseLeaveInsideInput"
                >
                  <template #append>
                    <span
                      v-if="!hasValueUpdated(prototype, null) || !canShowResetButton(prototype, null)"
                      class="psui-text-gray-50 psui-text-xsmall"
                    >%</span>
                    <PsTooltip v-else-if="canShowResetButton(prototype, null)">
                      <template #trigger>
                        <PsIcon 
                          icon="restart_alt"
                          icon-classes="psui-text-green-20 cursor-pointer mt-1"
                          size="17"
                          @click.native="updateAssumption(prototype, null, getBaseValue)"
                        />
                      </template>
                      <template #content>
                        <h1 class="mb-1">
                          Restore to default value
                        </h1>
                        <span class="psui-font-normal">{{ getBaseValue }}</span>
                      </template>
                    </PsTooltip>
                  </template>
                </PsInput>
              </div>
            </div>
          </transition>
        </div>
      </template>
    </PsAccordionItem>
  </div>
</template>

<script>
import { ASSUMPTIONS_KEYS,STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import AssumptionImpactsByPrototypeAndClimateZoneMixin from './AssumptionImpactsByPrototypeAndClimateZoneMixin'
import { defaultExistingBuildingsImpactAssumptionValues, defaultNewBuildingsImpactAssumptionValues } from '@/models/ImpactAssumptions'

export default {
  name: 'AssumptionsImpactsPenetrationRate',
  props:['tab-selected', 'userCanEditPolicy', 'descriptionModelObject'],
  mixins: [ AssumptionImpactsByPrototypeAndClimateZoneMixin ],
  data:()=>({
    assumptionKey: ASSUMPTIONS_KEYS.applicability_rate,
  }),
  computed: {
    getBaseValue() {
      return this.tabSelected.slug === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS ?
          defaultNewBuildingsImpactAssumptionValues[this.assumptionKey] :
          defaultExistingBuildingsImpactAssumptionValues[this.assumptionKey]
    },
  },
}
</script>

<style lang="scss" scoped>
  .psui-el-input.status-resting.layout-mini, .psui-el-input {
    width: 140px;
    &.selected{
      ::v-deep .psui-el-input-wrapper {
        border-color: #5DB883;
      }
    }
  }

  ::v-deep .psui-el-switch.size-small .psui-el-switch-button.toggle-true {
    background: #318FAC;
  }

  ::v-deep .psui-el-input.status-resting.layout-mini.selected {
    .psui-el-input-wrapper {
      border-color: #5DB883;
    }
  }

  ::v-deep .psui-el-input.status-error.layout-mini {
    position: relative;
    .psui-el-input-hint {
        position: absolute;
      }
    .psui-el-input-wrapper {
      .psui-el-input-append {
        display: none;
      }
    }
  }

  .psui-el-accordion-item {
    &.status-opened {
      .help {
        color: #318FAC;
      }
    }
  }
</style>
