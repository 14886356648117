import { extractAssumptionValuesFromPolicy, getAssumptionsLevel, ASSUMPTION_LEVEL } from '@/util/AssumptionsHelpers'
import { STUDY_TYPES_DB_SLUGS } from '@/store/global-entities/StudyType.js'
import { defaultExistingBuildingsImpactAssumptionValues, defaultNewBuildingsImpactAssumptionValues } from '@/models/ImpactAssumptions'
import { getQuartersInYear } from '@/util/DateFunctions'
import dayjs from 'dayjs'
import AssumptionsGlobalMixin from '../shared/AssumptionsGlobalMixin'
import {GA_LABELS} from "@/mixins/GaEventsMixin";

export default {
  mixins: [ AssumptionsGlobalMixin ],
  data() {
    return {
      showByBuildingType: false,
      isEditing: false,
      currentAvgInputRaw: '',
      currentPrototypeInputSelected: false,
      currentPrototypeInputRaw: '',
      currentAssumptionKeySelected: false,
      inputErrorIndex: false,
      showResetButtonKey: false,
      localAssumptionValue: {},
      disable_function: false
    }
  },
  watch:{
    valuesByCustomCombinations:{
      deep:true,
      handler(){
        if(!this.showByBuildingType && !this.disable_function){
          const assumptionLevel = getAssumptionsLevel(this.valuesByCustomCombinations)
          this.showByBuildingType = Boolean(assumptionLevel != ASSUMPTION_LEVEL.BASE)    
        }
      }
    }
  },
  mounted() {
    this.$eventBus.$on('restoreAssumption', () => {
      if (!this.assumption?.key) return
      const baseValue = this.getBaseValue(this.assumption.key)
      this.currentAvgInputRaw = baseValue
      this.currentPrototypeInputRaw = baseValue
      setTimeout(() => {
        this.currentPrototypeInputRaw = ''
        this.currentPrototypeInputSelected = false
        this.currentAssumptionKeySelected = false
      }, 1000)
    })
  },
  beforeDestroy(){
    this.$eventBus.$off('restoreAssumption')
  },
  computed: {
    isOnBaseAssumptionLevel() {
      return !this.showByBuildingType || (this.getPrototypesWithinPolicySelected?.length || 1) <= 1
    },
    getLastJurisdictionVisited() {
      return this.$store.getters['lastJurisdictionVisited']
    },
    getPrototypesWithinPolicySelected() {
      return this.$route.params.policy_id ? this.getPolicySelectedCustomCombinations
      .map(custom_combination => custom_combination.prototype)
      .reduce((acc, curr) => {
        if(acc.length === 0 || acc[acc.length-1]['id'] !== curr.id) {
          acc.push(curr)
        }
        return acc
      },[]) : false
    },
    getPolicySelectedCustomCombinations() {
      return this.$route.params.policy_id ? this.$store.getters['policy/getterUserPolicy']({ id:this.$route.params.policy_id }).policy_containers
      .map(policy_container => policy_container.custom_combinations)
      .reduce((acc,curr)=> acc.concat(curr)) : false
    },
    getPolicySelectedId() {
      return this.$route.params.policy_id || null
    },
    getPolicySelected() {
      return this.getPolicySelectedId ? this.$store.getters['policy/getterUserPolicy']({id: this.getPolicySelectedId}) : null
    },
    isPolicyAssumption() {
      return !!this.getPolicySelectedId
    },
    valuesByCustomCombinations() {
      if (!this.getPolicySelected || this.disable_function) {
        return null
      }
      return extractAssumptionValuesFromPolicy(this.getPolicySelected, this.assumption.key, this.getBaseValue(this.assumption.key))
    },
    getAverageValueFromPrototypes() {
      let averageValue = 0
      this.getPrototypesWithinPolicySelected.forEach((prototype) => {
        averageValue += this.getValuePerPrototype(this.assumption.key, prototype)
      })
      return averageValue / this.getPrototypesWithinPolicySelected.length
    },
    availableQuarters() {
      const quarters = getQuartersInYear(dayjs(this.getValuePerPrototype(this.assumption.key)).year())
      return quarters.map((quarter) => dayjs(quarter).endOf('quarter'))
    },
  },
  
  methods: {
    precise(number) {
      return parseFloat(parseFloat(number).toPrecision(5))
    },
    setDateValue(value, assumptionKey) {
      let newValue = value

      if(typeof value != 'object') newValue = dayjs(this.getValuePerPrototype(assumptionKey)).year(value)

      const valueFormatted = newValue.format('MM/DD/YYYY')
      this.setValuePerPrototype(valueFormatted,assumptionKey)
    },

    getFormatedDate(date){
      return dayjs(date)
    },

    getValue(assumptionKey) {
      return this.$store.state.assumptions[this.$store.state.assumptions.drawerOptions.type][assumptionKey]
    },

    setValue(value, assumptionKey) {
      this.$store.dispatch('assumptions/updateAssumptionsKeyValue', { key: assumptionKey, value, force: true})
    },

    getSingleInputValue(assumptionKey) {
      if(this.isEditing == assumptionKey || this.isEditing) return this.currentAvgInputRaw
      return !this.$route.params.policy_id ? this.getValue(assumptionKey) : this.getAverageValueFromPrototypes
    },

    getValuePerPrototype(assumptionKey, prototype) {
      if(this.currentPrototypeInputSelected == prototype && this.isEditing) return this.currentPrototypeInputRaw
      return this.getPolicyAssumptionKeyValuePerPrototype(this.$route.params.policy_id, assumptionKey, prototype) ??
      this.getValue(assumptionKey)
    },

    getBaseValue(assumptionKey) {
      return this.tabSelected.slug === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS ? defaultNewBuildingsImpactAssumptionValues[assumptionKey] : defaultExistingBuildingsImpactAssumptionValues[assumptionKey]
    },

    onToggleAssumptionByBuildingType(value, assumptionKey) {
      let assumptionLevel = getAssumptionsLevel(this.valuesByCustomCombinations)
      if (value || assumptionLevel === ASSUMPTION_LEVEL.BASE) {
        this.showByBuildingType = value
        return
      }

      this.$eventBus.$emit('openConfirmPopover', {
        targetElem: this.$refs.switchItem.$el.parentElement,
        text: 'Are you sure? This will revert the assumption to an average for all building types.',
        neverAskUserProp: `assumptions_break_level_${assumptionKey}`,
        okButtonText: 'Ok, please proceed',
        side: 'left',
        onConfirmCallback: () => {
          this.showByBuildingType = false
          this.setValuePerPrototype(this.getAverageValueFromPrototypes,assumptionKey, )
        },
      })
    },

    hasValueUpdated(value, assumptionKey) {
      if(!this.inputErrorIndex) {
        return value != this.getBaseValue(assumptionKey) ? true : false
      }
    },

    getInputKey(assumptionKey, prototype) {
      return `${ assumptionKey }${ prototype ? '-' + prototype.slug : '' }`
    },

    onMouseEnter(assumptionKey, prototype) {
      this.showResetButtonKey = this.getInputKey(assumptionKey, prototype)
    },

    onMouseLeave() {
      this.showResetButtonKey = false
    },

    checkShowResetButton(assumptionKey, prototype = false) {
      return this.showResetButtonKey === this.getInputKey(assumptionKey, prototype)
    },

    // Avg / main input events
    onInputAvg(value, assumptionKey) {
      this.currentAvgInputRaw = value
      this.isEditing = assumptionKey || true
      this.currentAssumptionKeySelected = assumptionKey
    },

    onChangeAvg(assumptionKey) {
      if(this.currentAvgInputRaw === '') return
      this.setValuePerPrototype(parseFloat(this.currentAvgInputRaw), assumptionKey)
      setTimeout(() => {
        this.currentAvgInputRaw = ''
      }, 1000)
    },

    onBlurAvg(assumptionKey) {
      this.onChangeAvg(assumptionKey)
      this.isEditing = false
    },
    
    // Specify by prototype input events
    onInputPrototype(value, assumptionKey, prototype) {
      this.currentPrototypeInputRaw = value
      this.isEditing = assumptionKey
      this.currentPrototypeInputSelected = prototype
      this.currentAssumptionKeySelected = assumptionKey
    },
    
    onChangePrototype(assumptionKey) {
      if(this.currentPrototypeInputRaw === '') return
      this.setValuePerPrototype(parseFloat(this.currentPrototypeInputRaw), assumptionKey, this.currentPrototypeInputSelected)
    },
    
    onBlurPrototype(assumptionKey) {
      this.onChangePrototype(assumptionKey)
      this.isEditing = false
    },

    setValuePerPrototype(value, assumptionKey, prototype = false) {
      if(!this.validateInput(value,assumptionKey)) {
        if(prototype) {
          this.inputErrorIndex = this.getPrototypesWithinPolicySelected.findIndex(prototype => prototype.id === this.currentPrototypeInputSelected.id)
        } else {
          this.inputErrorIndex = assumptionKey
        }
        setTimeout(() => {
          this.inputErrorIndex = false
          this.currentPrototypeInputRaw = ''
          this.currentPrototypeInputSelected = false
          this.currentAssumptionKeySelected = false
        }, 2000)
        return
      }

      if (!this.$route.params.policy_id) {
        this.setValue(value, assumptionKey)
        this.gaEvent(
          GA_LABELS.ASSUMPTIONS_CHANGED,
          {
            assumption: assumptionKey,
            is_policy_assumption: false,
            jurisdiction_slug: this.getLastJurisdictionVisited.slug
          }
        )
      } else {
        this.gaEvent(
            GA_LABELS.ASSUMPTIONS_CHANGED,
            {
              assumption: assumptionKey,
              is_policy_assumption: true,
              jurisdiction_slug: this.getLastJurisdictionVisited.slug
            }
        )
        // TODO: Deprecate the use of the localAssumption
        this.updateLocalAssumption(value, assumptionKey, prototype)
        
        const custom_combinations = this.getPolicySelectedCustomCombinations
          .filter(custom_combination => prototype ? custom_combination.prototype_id == prototype.id : true)  

        this.$store.dispatch('assumptions/updateCustomCombinationsAssumptionsPartially', { 
          custom_combinations, 
          partialAssumption: {
            [assumptionKey]: value
          },
        })
      }

      setTimeout(() => {
        this.currentPrototypeInputRaw = ''
        this.currentPrototypeInputSelected = false
        this.currentAssumptionKeySelected = false
      }, 3000)

      if(this.$refs.PSDropdown) this.$refs.PSDropdown.close() 

    },

    getPolicyAssumptionKeyValuePerPrototype(policy_id, assumptionKey, prototype) {
      return !policy_id ? null : this.$store.getters['assumptions/getterPolicyContextCustomCombinationByKey']({ policy_id, key: assumptionKey, prototype })
    },

    getPolicyAssumptionKeyValue(policy_id, assumptionKey) {
      return !policy_id ? null : this.$store.getters['assumptions/getterPolicyContextCustomCombinationByKey']({ policy_id, key: assumptionKey })
    },

    updateLocalAssumption(value, assumptionKey, prototype) {
      this.$set(this.localAssumptionValue, prototype.slug, value)
    },

    restoreAssumptionToDefault(assumptionKey, prototype) {
      this.setValuePerPrototype(this.getBaseValue(assumptionKey), assumptionKey, prototype)
    },

    startTransition(el) {
      el.style.height = el.scrollHeight + 'px'
      el.style.padding = el.scrollPaddingBottom + 'px'
    },

    endTransition(el) {
      el.style.height = ''
      el.style.padding = ''
    },
  }

}
