<template>
  <div class="card-main psui-flex psui-flex-col psui-justify-center psui-pt-16">
    <transition
      name="fade"
      mode="out-in"
    >
      <div class="psui-w-full psui-flex psui-flex-col psui-justify-center psui-text-center card-main-space">
        <img 
          :src="`/images/policy_wizard/${getImage}`"
          class="card-image psui-mx-auto" 
        >

        <div 
          v-if="isLoadingFirstCaption"
          class="card-main-space"
        >
          <p class="text-20 psui-text-blue-80">
            Generating your {{ buildingType | buildingType }} {{ 'policy' | policyTextRephrase('small') }} based on the <strong>{{ policyOptionTitle }}</strong> option
          </p>

          <div class="psui-flex psui-justify-center">
            <div class="dot-flashing psui-text-blue-80" />
          </div>
        </div>

        <div
          v-else
          v-html="$options.filters.policyTextRephrase(getTextDescription(),'small')"
        />
      </div>
    </transition>
    <div 
      v-if="!isLoadingFirstCaption"
      class="psui-flex psui-justify-between psui-mt-auto psui-pt-5 psui-border-t psui-border-blue-70 psui-border-opacity-25"
    >
      <PsButton
        data-test-id="policy-option-wizard-back-to-policy-options-button"
        size="big"
        :label="'Back to Policy Options' | policyTextRephrase('small')"
        icon="keyboard_double_arrow_left"
        layout="onlytext"
        class="btn-back"
        @click="redirectToPolicyOptions"
      />

      <PsButton
        data-test-id="policy-option-wizard-see-my-policy-button"
        size="big"
        :label="'See My Policy' | policyTextRephrase('small')"
        @click="goToMyPolicy"
      />
    </div>
  </div>
</template>

<script>
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import { POLICY_OPTIONS_DB_SLUGS } from '@/util/Enums.js'
export default {
  name: "PolicyOptionWizard",
  filters: {
    buildingType: (value) =>{
      return value === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS ? 'new construction' : 'existing construction'
    },
  },
  data: () => ({
    policy: null,
    policyOptionTitle: null,
    buildingType: null,
    isLoadingFirstCaption: true,
    policyOption: null,
    POLICY_OPTIONS_DB_SLUGS
  }),
  computed: {
    getImage() {
      return this.isLoadingFirstCaption ? 'step_auto_generate.png' :
      this.getPolicyOptionSlug ? 'step_manual_finish.png' :'step_auto_generate_saved.png'
    },
    getPolicyOptionSlug(){
      return this.policyOption?.slug === POLICY_OPTIONS_DB_SLUGS.DEFAULT_STATE || this.policyOption?.slug === POLICY_OPTIONS_DB_SLUGS.DEFAULT_STATE_EXISTING
    }
  },
  watch:{
    policy(policy){
      this.isLoadingFirstCaption = Boolean(!policy)
    }
  },
  methods: {
    resetState() {
      this.policy = null
      this.isLoadingFirstCaption = true
      this.$emit('register-success')
    },
    redirectToPolicyOptions(){
      this.$eventBus.$emit('closePolicyOptionWizardModal')
      this.$router.replace({
        name:'PolicyOptions',
        query:{
          only_study_type: this.$route.query.only_study_type
        }
      })

      this.resetState()
    },
    goToMyPolicy(){
      this.$router.push({name:'PolicyShow', params:{ policy_id: this.policy.id}})
      this.resetState()
    },
    getTextDescription(){ 
      if(this.policyOption.slug === POLICY_OPTIONS_DB_SLUGS.DEFAULT_STATE || this.policyOption.slug === POLICY_OPTIONS_DB_SLUGS.DEFAULT_STATE_EXISTING) {
        return `
          <p class="psui-text-h4 psui-text-blue-80 psui-mb-4">Your policy is ready to build.</p>
          <p class="psui-text-p psui-text-gray-60">Start adding requirements for the building types and climate zones you selected.</p>
        `
      } else {
        const descriptionText = this.policyOption.slug === POLICY_OPTIONS_DB_SLUGS.FLEXIBLE_ON_BILL ?
            `All compatible cost-effective measures from<br> the latest study results are used to establish your required minimum energy savings.<br>Now you can explore your policy's requirements, share it with a colleague, and more.` :
            `All compatible cost-effective measures from the latest study results are included in your policy.<br>Now you can explore your policy's potential impact, share it with a colleague, and more.`
        return `
          <p class="psui-text-h4 psui-text-blue-80 psui-mb-4">Your <strong class="psui-font-bold">${this.policyOptionTitle}</strong> policy is now saved to My Policies.</p>
          <p class="psui-text-p psui-text-gray-60">${descriptionText}</p>
        `
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .card-main {
    width: 90%;

    @media (min-width: 768px) {
      width: 840px;
      height: 565px;
    }

    &-space {
      > :not(template) ~ :not(template) {
        --space-y-reverse: 0;
        margin-top: calc(3.313rem * calc(1 - var(--space-y-reverse)));
        margin-bottom: calc(3.313rem * var(--space-y-reverse));
      }
    }
  }

  .card-image {
    max-width: 324px;
  }

  .btn-back {
    background-color: transparent;
  }
</style>
