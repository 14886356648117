import getProjectionsResume from '@/business-logic/services/projections/getProjectionsResume'
import sumProjectionData from '@/business-logic/services/projections/sumProjectionData'
import getStudyDataColumns from '@/business-logic/services/study-results/getStudyDataColumns'
import sumStudyResultData from '@/business-logic/services/study-results/sumStudyResultData'
import newBuildingsImpactEstimatesResumed from '@/business-logic/services/impact-algorithms/results/new-buildings/newBuildingsImpactEstimatesResumed'
import Store from '@/store'

// ToDO: Try to rewrite building_units because currently the approach is not so good (having two)
export default class StudyData {
  constructor({ study_data = {}, building_stock_units = 0, assumption = null, building_estimates = null } = {}) {

    this.id = study_data.id || null
    this.study_row_number = study_data.study_row_number || 0
    this.study_id = study_data.study_id || null
    this.prototype_id = study_data.prototype_id || null
    this.measure_id = study_data.measure_id || null
    this.vintage_id = study_data.vintage_id || null
    this.fuel_id = study_data.fuel_id || null
    this.climate_zone_raw = study_data.climate_zone_raw || ''
    this.baseline_fuel_type = study_data.baseline_fuel_type || ''

    //Relationships
    this.study = study_data.study || null
    this.climate_zone = study_data.climate_zone || null
    this.prototype = study_data.prototype || null
    this.vintage = study_data.vintage || null
    this.measure = study_data.measure || null
    this.fuel = study_data.fuel || null
    this.sync = study_data.sync || null

    this.study_data = study_data
    this.building_stock_units = building_stock_units
    this.building_estimates = building_estimates
    this.assumption = assumption

    this.generateStudyResultData()

    //Virtual attrs
    this.measure_combination_conflict = false
    this.measure_is_added = false
    this.is_max = false
    
    this.measure_could_be_combined = false
    if(this.measure && this.measure.include_in_combination) {
      this.measure_could_be_combined = true
    }

    // ToDo: Maybe it should be added again...
    // if (this.measure && !this.isCostEffective) {
    //   this.measure_could_be_combined = false
    // }

  }

  get isCostEffective() {
    return ( this.isOnBillEffective || this.isTdvEffective ||  this.isTdv2022Effective || this.isLsc2025Effective || this.isOnBill2025Effective)
  }

  get isOnBillEffective() {
    return ( this.data.on_bill_cost_ratio >= 1 )
  }

  get isTdvEffective() {
    return ( this.data.tdv_benefit_to_cost_ratio >= 1 )
  }

  get isTdv2022Effective() {
    return ( this.data.tdv2022_benefit_to_cost_ratio >= 1 )
  }

  get isOnBill2025Effective() {
    return ( this.data.on_bill_cost_ratio_2025 >= 1 )
  }

  get isLsc2025Effective() {
    return ( this.data.lsc_2025_benefit_to_cost_ratio >= 1 )
  }

  get isTdvButNotOnBillEffective() {
    // As we have no TDV with LSC, lets update this fnc to consider LSC as "TDV" option to keep using same props.
    return ( !this.isOnBillEffective && !this.isOnBill2025Effective && (this.isTdvEffective ||  this.isTdv2022Effective || this.isLsc2025Effective ) )
  }

  get isLscButNotOnBillEffective() {
    return ( !this.isOnBillEffective && !this.isOnBill2025Effective && this.isLsc2025Effective )
  }

  get isExistingBuildingStudy() {
    return this.fuel_id === null
  }
  
  generateStudyResultData() {
    this.data = getStudyDataColumns({ study_data: this.study_data })
    if(this.building_stock_units > 0 || this.building_estimates) {
      this.generateStudyResultProjections()
    }
  }

  // Source methodology : https://docs.google.com/spreadsheets/d/16PIVWUg9dK3Uz-42CvEvG6QPeiTzEsVO0lhiaq8a7yI/edit#gid=2054033701
  generateStudyResultProjections() {
    let units = 0
    let projectionsCityWideImpactValues = {}
    const study = Store.getters['globalEntities/Study/getterGlobalStudy']({ id: this.study_id })
    if(this.building_stock_units > 0) {
      units = this.building_stock_units
      projectionsCityWideImpactValues = getProjectionsResume({
        assumption: this.assumption,
        building_stock_units: units,
        kwh_savings: this.data.kwh_savings,
        therms_savings: this.data.therms_savings,
        annual_bill_savings: this.data.annual_bill_savings,
        initial_cost: this.data.initial_cost,
        installation_effects_years: study?.impact_period && !isNaN(study?.impact_period) ? Number(study?.impact_period) : this.assumption.years,
        subsidy_needed: this.data.subsidy_needed,
        five_year_payback: this.data.five_year_payback,
        subsidy_needed_care: this.data.subsidy_needed_care,
        five_year_payback_care: this.data.five_year_payback_care
      })
    } else if (this.building_estimates && this.building_estimates.length) {
      // ToDO: change it to a policy impact algorithm??
      units = this.building_estimates.reduce((acc, item) => acc + item.units, 0)
      projectionsCityWideImpactValues = newBuildingsImpactEstimatesResumed(this.building_estimates, this.fuel, {
        care_program_enrollment_rate: this.assumption.care_program_enrollment_rate,
        all_electric_shares_without_policy: this.assumption.all_electric_shares_without_policy,
        all_electric_shares_with_policy: this.assumption.all_electric_shares_with_policy,
        effective_date: this.assumption.start_at,
        termination_date: this.assumption.terminationDate,
        installation_effects_years: study?.impact_period && !isNaN(study?.impact_period) ? Number(study?.impact_period) : this.assumption.years,
        applicability_rate: this.assumption.applicability_rate,
        annual_penetration_rate: this.assumption.annual_penetration_rate,
        delay_installation: this.assumption.delay_installation,
        likelihood_installations_first_third: this.assumption.likelihood_installations_first_third,
        likelihood_installations_second_third: this.assumption.likelihood_installations_second_third,
        likelihood_installations_final_third: this.assumption.likelihood_installations_final_third,
      },
      {
        currentThermsEmissionFactor: this.assumption.current_therms_emissions_factor,
        currentKwhEmissionFactor: this.assumption.current_kwh_emissions_factor,
        kwhSavings: this.data.kwh_savings,
        thermsSavings: this.data.therms_savings,
        annualBillSavings: this.data.annual_bill_savings,
        initialCost: this.data.initial_cost,
        gridMaxRenewablesYear: this.assumption.grid_max_renewables_year,
        gridMaxRenewablesLevel: this.assumption.grid_max_renewables_level,
        currentGridRenewableLevel: this.assumption.current_grid_renewable_level,
        subsidy_needed: this.data.subsidy_needed,
        five_year_payback: this.data.five_year_payback,
        subsidy_needed_care: this.data.subsidy_needed_care,
        five_year_payback_care: this.data.five_year_payback_care
      })
    }

    Object.keys(projectionsCityWideImpactValues).map((col) => {
      this.data[col] = projectionsCityWideImpactValues[col]
    })
    this.projections_units = units
  }

  /**
   * Function that aggregate study_data columns to generate a new measure combined
   */
  sumMeasureCombinations({ dataToAggregate }) {
    sumStudyResultData({ data: this.data, dataToAggregate: dataToAggregate })
    sumProjectionData({ data: this.data, dataToAggregate: dataToAggregate })
  }

}
