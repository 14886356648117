<template>
  <div class="psui-flex psui-flex-col">
    <div
      data-test-id="assumptions-impacts"
      class="building-estimates-border psui-flex psui-items-center psui-justify-between psui-py-4 psui-cursor-pointer"
      @click="openBuildingEstimatesAssumptionsDrawer"
    >
      <span class="psui-text-small psui-font-bold psui-text-gray-80">Building Estimates</span>
      <PsIcon
        icon="chevron_right"
        size="20"
        icon-classes="psui-text-gray-60"
      />
    </div>

    <div class="flex items-center justify-between mt-8">
      <h1 class="psui-text-gray-50 psui-text-accentSmall psui-font-bold psui-uppercase">
        basic
      </h1>
      <PsButton
        data-test-id="assumptions-basic-reset-button"
        :disabled="getIfResetButtonShouldBeDisabledByAssumptionGroup('Basic')"
        class="psui-text-gray-40"
        label="Reset"
        size="small"
        :loading="isLoading == ASSUMPTIONS_DRAWER_DIVISION.BASIC"
        layout="onlytext"
        icon="restart_alt"
        icon-position="left"
        @click="resetAssumptionsToDefault(ASSUMPTIONS_DRAWER_DIVISION.BASIC)"
      />
    </div>
    <!-- <AssumptionsImpactsElectrificationRate
      v-if="getTabSelected.slug === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS && electrificationRateShouldNotBeenShown"
      :user-can-edit-policy="userCanEditPolicy"
    />   -->
    <AssumptionsImpactsEffectiveDate
      :tab-selected="getTabSelected"
      :user-can-edit-policy="userCanEditPolicy"
      :description-model-object="descriptionModelObject"
    />

    <PsRichTooltip
      css-class="w-48"
      :ignore-dialog="getTabSelected.slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS"
    >
      <template #trigger>
        <AssumptionsImpactsActivePolicyDuration
          v-if="getTabSelected.slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS"
          :tab-selected="getTabSelected"
          :user-can-edit-policy="userCanEditPolicy"
          :description-model-object="descriptionModelObject"
        />
      </template>
      <template #content>
        <div class="flex flex-col  gap-1">
          <span class="psui-text-gray-80 font-bold">
            {{ $route.name === 'PolicyShow' ? 'This assumption does not apply to your policy':
              'This assumption does not apply to these results'
            }}
          </span>
          <span class="psui-font-normal psui-text-gray-80">
            Click to learn more
          </span>
        </div>
      </template>
    </PsRichTooltip>

    <PsRichTooltip
      css-class="w-48"
      :ignore-dialog="getTabSelected.slug === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS"
    >
      <template #trigger>
        <AssumptionsImpactsTerminationDate
          v-if="getTabSelected.slug === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS"
          :tab-selected="getTabSelected"
          :user-can-edit-policy="userCanEditPolicy"
          :description-model-object="descriptionModelObject"
        />
      </template>

      <template #content>
        <div
          class="flex flex-col gap-1"
        >
          <span class="psui-text-gray-80 font-bold">
            {{ $route.name === 'PolicyShow' ? 'This assumption does not apply to your policy':
              'This assumption does not apply to these results'
            }}
          </span>
          <span class="psui-font-normal psui-text-gray-80">
            Click to learn more
          </span>
        </div>
      </template>
    </PsRichTooltip>

    <AssumptionsImpactsPenetrationRate
      :tab-selected="getTabSelected"
      :user-can-edit-policy="userCanEditPolicy"
      :description-model-object="descriptionModelObject"
    />
    <AssumptionsImpactsApplicabilityRate
      ref="applicability_rate"
      :tab-selected="getTabSelected"
      :user-can-edit-policy="userCanEditPolicy"
      :description-model-object="descriptionModelObject"
    />

    <PsRichTooltip
      css-class="w-48"
      :ignore-dialog="getTabSelected.slug === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS"
    >
      <template #trigger>
        <AssumptionsElectrificationBusinessAsUsual
          v-if="getTabSelected.slug === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS && $route.name === 'PolicyShow'"
          :tab-selected="getTabSelected"
          :user-can-edit-policy="userCanEditPolicy"
          :description-model-object="descriptionModelObject"
        />
      </template>
      <template #content>
        <div class="flex flex-col  gap-1">
          <span class="psui-text-gray-80 font-bold">
            {{ getTabSelected.slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS && $route.name === 'PolicyShow' ?
              'This assumption does not apply to your policy':
              'This assumption does not apply to these results' }}
          </span>
          <span class="psui-font-normal psui-text-gray-80">
            Click to learn more
          </span>
        </div>
      </template>
    </PsRichTooltip>

    <PsRichTooltip
      css-class="w-48"
      :ignore-dialog="getTabSelected.slug === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS"
    >
      <template #trigger>
        <AssumptionsElectrificationRateWithPolicy
          v-if="getTabSelected.slug === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS"
          :tab-selected="getTabSelected"
          :user-can-edit-policy="userCanEditPolicy"
          :description-model-object="descriptionModelObject"
        />
      </template>
      <template #content>
        <div class="flex flex-col  gap-1">
          <span class="psui-text-gray-80 font-bold">
            {{ getTabSelected.slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS && $route.name === 'PolicyShow' ?
              'This assumption does not apply to your policy':
              'This assumption does not apply to these results' }}
          </span>
          <span class="psui-font-normal psui-text-gray-80">
            Click to learn more
          </span>
        </div>
      </template>
    </PsRichTooltip>
    <div class="flex items-center justify-between mt-6">
      <h1 class="psui-text-gray-50 psui-text-accentSmall psui-font-bold psui-uppercase">
        advanced
      </h1>
      <PsButton
        data-test-id="assumptions-advanced-reset-button"
        :disabled="getIfResetButtonShouldBeDisabledByAssumptionGroup('Advanced')"
        class="psui-text-gray-40"
        label="Reset"
        size="small"
        :loading="isLoading == ASSUMPTIONS_DRAWER_DIVISION.ADVANCED"
        layout="onlytext"
        icon="restart_alt"
        icon-position="left"
        @click="resetAssumptionsToDefault(ASSUMPTIONS_DRAWER_DIVISION.ADVANCED)"
      />
    </div>
    <AssumptionsImpactsInstallationTime
      :tab-selected="getTabSelected"
      :user-can-edit-policy="userCanEditPolicy"
      :description-model-object="descriptionModelObject"
    />
    <AssumptionsCardCleanElectricityProgress
      :tab-selected="getTabSelected"
      :user-can-edit-policy="userCanEditPolicy"
      :description-model-object="descriptionModelObject"
    />
    <AssumptionsImpactsCurrentGridCleanElectricityShare
      :tab-selected="getTabSelected"
      :user-can-edit-policy="userCanEditPolicy"
      :description-model-object="descriptionModelObject"
    />
    <AssumptionsImpactsNaturalGasEmissionsFactor
      :tab-selected="getTabSelected"
      :user-can-edit-policy="userCanEditPolicy"
      :description-model-object="descriptionModelObject"
    />
    <AssumptionsImpactsBaselineInstallationRate
      :tab-selected="getTabSelected"
      :user-can-edit-policy="userCanEditPolicy"
      :description-model-object="descriptionModelObject"
    />
    <AssumptionsImpactsCareProgramEnrollmentRate
      :tab-selected="getTabSelected"
      :user-can-edit-policy="userCanEditPolicy"
      :description-model-object="descriptionModelObject"
    />
    <AssumptionsFlexibleContingentPercentage
      v-if="getTabSelected.slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS && isUserAdmin && isPolicyShowPage"
      :tab-selected="getTabSelected"
      :user-can-edit-policy="userCanEditPolicy"
      :description-model-object="descriptionModelObject"
    />
    <AssumptionsRestoreConfirmationModal />
    <AssumptionsCreatePolicyModal />
  </div>  
</template>

<script>
  import AssumptionsCardCleanElectricityProgress from '@/modules/app/assumptions/impacts/AssumptionsCardCleanElectricityProgress.vue'
  import AssumptionsImpactsActivePolicyDuration from '@/modules/app/assumptions/impacts/AssumptionsImpactsActivePolicyDuration.vue'
  import AssumptionsImpactsApplicabilityRate from '@/modules/app/assumptions/impacts/AssumptionsImpactsApplicabilityRate.vue'
  import AssumptionsImpactsBaselineInstallationRate from '@/modules/app/assumptions/impacts/AssumptionsImpactsBaselineInstallationRate.vue'
  import AssumptionsImpactsCurrentGridCleanElectricityShare from '@/modules/app/assumptions/impacts/AssumptionsImpactsCurrentGridCleanElectricityShare.vue'
  import AssumptionsImpactsEffectiveDate from '@/modules/app/assumptions/impacts/AssumptionsImpactsEffectiveDate.vue'
  import AssumptionsImpactsInstallationTime from '@/modules/app/assumptions/impacts/AssumptionsImpactsInstallationTime.vue'
  import AssumptionsImpactsNaturalGasEmissionsFactor from '@/modules/app/assumptions/impacts/AssumptionsImpactsNaturalGasEmissionsFactor.vue'
  import AssumptionsImpactsPenetrationRate from '@/modules/app/assumptions/impacts/AssumptionsImpactsPenetrationRate.vue'
  import AssumptionsImpactsTerminationDate from '@/modules/app/assumptions/impacts/AssumptionsImpactsTerminationDate.vue'
  import AssumptionsElectrificationBusinessAsUsual from '@/modules/app/assumptions/impacts/AssumptionsElectrificationBusinessAsUsual.vue'
  import AssumptionsElectrificationRateWithPolicy from '@/modules/app/assumptions/impacts/AssumptionsElectrificationRateWithPolicy.vue'
  import AssumptionsImpactsCareProgramEnrollmentRate from '@/modules/app/assumptions/impacts/AssumptionsImpactsCareProgramEnrollmentRate.vue'
  import AssumptionsRestoreConfirmationModal from '@/modules/app/assumptions/shared/AssumptionsRestoreConfirmationModal.vue'
  import AssumptionsCreatePolicyModal from '@/modules/app/assumptions/shared/AssumptionsCreatePolicyModal.vue'
  import AssumptionsFlexibleContingentPercentage from '@/modules/app/assumptions/impacts/AssumptionsFlexibleContingentPercentage.vue'
  import { STUDY_TYPES_DB_SLUGS } from '@/store/global-entities/StudyType.js'
  import { ASSUMPTIONS_DRAWER_DIVISION, ASSUMPTIONS_PATHS } from '@/util/Enums.js'
  import {checkIfPolicyIsOutdated} from "@/util/Helpers"

  const descriptionModelObject = { 
    type: 'helper', 
    buttonTitle: 'Learn More', 
    disableCloseButton: false, 
    buttonLayout: 'solid' 
  }

  const POLICY_SHOW_ROUTE_NAME = 'PolicyShow'

export default {
  name:"AssumptionsImpactsItems",
  components: {
    AssumptionsCardCleanElectricityProgress,
    AssumptionsImpactsActivePolicyDuration,
    AssumptionsImpactsApplicabilityRate,
    AssumptionsImpactsBaselineInstallationRate,
    AssumptionsImpactsCurrentGridCleanElectricityShare,
    AssumptionsImpactsEffectiveDate,
    AssumptionsImpactsInstallationTime,
    AssumptionsImpactsNaturalGasEmissionsFactor,
    AssumptionsImpactsPenetrationRate,
    AssumptionsImpactsTerminationDate,
    AssumptionsElectrificationBusinessAsUsual,
    AssumptionsElectrificationRateWithPolicy,
    AssumptionsImpactsCareProgramEnrollmentRate,
    AssumptionsRestoreConfirmationModal,
    AssumptionsCreatePolicyModal,
    AssumptionsFlexibleContingentPercentage
  },
  props:['get-tab-selected', 'user-can-edit-policy','electrification-rate-should-not-been-shown'],
  data:()=>({
    STUDY_TYPES_DB_SLUGS,
    ASSUMPTIONS_DRAWER_DIVISION,
    descriptionModelObject,
    isLoading: false
  }),
  computed:{
    getPolicyIdByRoute() {
      return this.$route.params.policy_id ?? false
    },
    isUserAdmin(){
      return this.$store.getters['getterLoggedUserIsAdmin']
    },
    isPolicyShowPage(){
      return this.$route.name == POLICY_SHOW_ROUTE_NAME
    }
  },
  mounted() {
    this.$eventBus.$on('customCombinationUpdated', () => {
      this.isLoading = false
    })
    this.$eventBus.$on('closedAssumptionsRestoreConfirmationModal', () => {
      this.isLoading = false
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('customCombinationUpdated')
    this.$eventBus.$off('closedAssumptionsRestoreConfirmationModal')
  },
  methods:{
    getIfResetButtonShouldBeDisabledByAssumptionGroup(key) {
      return !this.$store.getters['assumptions/getterImpactAssumptionsHasAnyValueChanged']({ key, policy_id: this.$route.params.policy_id })
    },
    resetAssumptionsToDefault(key){
      
      const userCanEditPolicy =  this.$store.getters['assumptions/getterUserCanEditTheSelectedPolicy'] ||
      this.$store.getters['policy/getterUserCanEditPolicy'](this.getPolicySelectedId)
      const policy = this.$store.getters['policy/getterUserPolicy']({id: this.getPolicyIdByRoute})
      if(!userCanEditPolicy && policy) {
        const outdatedInfo = checkIfPolicyIsOutdated(policy)
        const isOutdatedModalBlocker = Boolean(outdatedInfo.has_outdated_studies === true && outdatedInfo.is_loading === false && !outdatedInfo.block_policy)
        this.$eventBus.$emit('openPolicyUserPermissionDuplicateAlertModal', {
          policy,
          disableUserLoginCall2Action: isOutdatedModalBlocker,
          disableBtns: isOutdatedModalBlocker,
          customTitle: isOutdatedModalBlocker ? `You can't edit this policy assumptions` : undefined,
          customDescription: isOutdatedModalBlocker ? `This policy is read-only since it is based on an outdated policy architecture and cost-effectiveness study results. Please create a new policy to continue policy development.` : undefined,
        })
      } else {
        this.isLoading = key
        this.$eventBus.$emit('openAssumptionsRestoreConfirmationModal',{policy_id:this.getPolicyIdByRoute, study_type:this.getTabSelected, key})
      }
    },
    openBuildingEstimatesAssumptionsDrawer() {
      this.$store.commit('assumptions/updateDrawerPath', ASSUMPTIONS_PATHS.RESIDENTIAL_BUILDINGS)
      this.$eventBus.$emit('removeTransition')
    },
  }
}
</script>
<style lang="scss">
.building-estimates-border {
  border-bottom: 1px solid rgba(0, 70, 95, .1);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box;

  &:hover {
    ::v-deep span {
      color: #318FAC;
    }
  }
}
</style>
