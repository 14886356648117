<template>
  <div class="w-full p-6">
    <div class="w-full flex justify-end space-x-5">
      <Checkbox
        v-model="isActive"
        label="Only Actives"
      />
      <PsButton
        label="Refresh"
        size="big"
        icon="refresh"
        @click="getSyncs()"
      />

      <PsButton
        label="Sync all studies data"
        size="big"
        icon="sync"
        @click="syncAllStudiesData()"
      />
    </div>
    <div class="w-full flex space-x-10 mt-6">
      <div class="w-1/2 relative">
        <VueSelect
          v-model="studyGroup"
          class="vue-select"
          placeholder="Select to filter"
          :class="studyGroup ? 'filled' : ''"
          label="title"
          :options="study_groups"
        />
        <label>Study</label>
      </div>
      <div
        v-if="studyGroup"
        class="w-1/2 relative"
      >
        <VueSelect
          v-model="study"
          class="vue-select"
          placeholder="Select to filter"
          :class="study ? 'filled' : ''"
          label="released_at"
          :options="studyGroup.studies"
          @input="getSyncs()"
        />
        <label>Version</label>
      </div>
    </div>
    <div class="w-full overflow-x-auto">
      <table class="table table-bordered mt-6 whitespace-nowrap">
        <thead>
          <tr>
            <th>ID</th>
            <th>Study</th>
            <th>Errors</th>
            <th>Is Active</th>
            <th>Synced by</th>
            <th>Published by</th>
            <th>Source JSON</th>
            <th>Created at</th>
            <th>Updated at</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(sync, index) in syncsFiltered"
            :key="`sync-${index}-${sync.id}`"
          >
            <td>{{ sync.id }}</td>
            <td>
              {{ sync.study.id }} - {{ sync.study.title }}
              {{ sync.study.released_at }}
            </td>
            <td class="text-center">
              <span
                v-if="sync.study.errorsTotal"
                class="button-xs rounded-full bg-red text-white mt-2"
              >We found {{ sync.study.errorsTotal }} errors to fix in this
                study</span>
              <span
                v-else
                class="button-xs rounded-full bg-green text-white mt-2"
              >This study has no errors</span>
            </td>
            <td>
              <Checkbox
                class="w-full"
                :label="sync.is_active ? 'Yes' : 'No'"
                :value="sync.is_active"
                @input="
                  updateSync({ sync_id: sync.id, is_active: !sync.is_active })
                "
              />
            </td>
            <td>
              <span v-if="sync.synced_by">{{ sync.synced_by.email }}</span>
            </td>
            <td>
              <span v-if="sync.activated_by">{{
                sync.activated_by.email
              }}</span>
            </td>
            <td class="text-center">
              <a
                class="text-blue02"
                :href="sync.source_file_url"
                target="_blank"
              >
                <feather type="file" />
              </a>
            </td>
            <td>{{ sync.created_at }}</td>
            <td>{{ sync.updated_at }}</td>
            <td>
              <PsButton
                layout="caution"
                label="Delete"
                size="medium"
                icon="delete"
                @click="selectSyncToDelete(sync)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <BaseModal
      ref="deleteModal"
      modal-dialog-classes="w-full max-w-sm"
      title="Attention"
    >
      <div
        v-if="sync_selected"
        class="w-full flex flex-col items-start mt-6"
      >
        <p class="text-base text-gray04">
          Delete
          <span
            class="text-red font-bold"
          >{{ sync_selected.study.id }} -
            {{ sync_selected.study.title }}</span>?
        </p>
        <PsButton
          layout="caution"
          class="psui-mt-6"
          label="Confirm delete"
          size="big"
          @click="deleteSync()"
        />
      </div>
    </BaseModal>

    <Paginator
      v-model="offset"
      :length="syncs.length"
      :limit="limit"
      @getItems="getSyncs({ offset })"
    />
  </div>
</template>

<script>
export default {
  name: 'AdminSyncList',
  data() {
    return {
      studies: [],
      study: false,
      study_groups: [],
      studyGroup: false,
      syncs: [],
      sync_selected: null,
      offset: 0,
      isActive: false,
      limit: 12,
    }
  },
  computed: {
    syncsFiltered() {
      return this.syncs
        .filter((sync) =>
          this.studyGroup &&
          this.studyGroup.id !== this.studiesStudyGroupDict[sync.study.id]
            ? false
            : true
        )
        .filter((sync) =>
          this.isActive && sync.is_active === false ? false : true
        )
    },
    studiesStudyGroupDict() {
      return this.studies.reduce((prev, cur) => {
        prev[cur.id] = cur.study_group_id
        return prev
      }, {})
    },
  },
  mounted() {
    this.getSyncs()
    this.getStudies()
    this.getStudyGroups()
  },
  methods: {
    async getStudies() {
      const query = `
        {
          studies {
            id
            title
            study_group_id
            released_at
          }
        }
      `
      const { data } = await this.$graphql({ query })
      this.studies = data.studies
    },
    async getStudyGroups() {
      const query = `
        {
          study_groups {
            id
            title
            studies (
              orderBy: [{ column: "released_at", order : "desc"}]
            ) {
              title
              id
              released_at
            }
          }
        }
      `
      const { data } = await this.$graphql({ query })
      this.study_groups = data.study_groups
    },

    async getSyncs({ offset = 0 } = {}) {
      this.offset = offset
      const query = `
        {
          syncs(
            ${
              this.study
                ? `where: [{ column: "study_id", operation: "=", value: "${this.study.id}"}]`
                : ``
            }
            orderBy: [{column: "id", order: "desc"}], 
          ) {
            id
            is_active
            created_at
            updated_at
            source_file_url
            study { id title released_at }
            synced_by { id email }
            activated_by { id email }
          }
        }
      `
      const { data } = await this.$graphql({ query })

      this.syncs = data.syncs.map((sync) => {
        const s = { ...sync, study: { ...sync.study, errorsTotal: 0 } }
        this.checkStudyErrors(s.study)
        return s
      })
    },

    syncAllStudiesData() {
      this.$api
        .post('/sync/all', { loader: true })
        .then(() => {
          this.$toast.success('Study sync successfully scheduled')
          this.$toast.info(
            `The sync processs may take up to 10 minutes to finish, so it's time to grab a coffee`,
            { duration: 20000 }
          )
        })
        .catch(() => {
          this.$toast.error('Error dispatching sync studies')
        })
    },

    updateSync({ sync_id, is_active }) {
      this.$api.put(`/sync/${sync_id}`, { is_active }).then(({ data }) => {
        this.$toast.success(data.message)
        this.getSyncs()
      })
    },

    selectSyncToDelete(sync) {
      this.sync_selected = sync
      this.$refs.deleteModal.showModal = true
    },

    deleteSync() {
      this.$api.delete(`/sync/${this.sync_selected.id}`).then(({ data }) => {
        this.$toast.success(data.message)
        this.getSyncs()
          this.$refs.deleteModal.showModal = false
          this.sync_selected = null
      })
    },

    checkStudyErrors(study) {
      this.$api.get(`/studies/check_errors/${study.id}`).then(({ data }) => {
        study.errorsTotal = data.errors.errorsTotal
      })
    },
  },
}
</script>
