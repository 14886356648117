<template>
  <div 
    class="flex items-center cursor-pointer psui-text-small psui-text-blue-60 psui-font-bold transition transition-300 hover:opacity-80"
    :class="{ 'is-expanded' : expanded }"
  >
    <span class="mr-2">{{ expanded ? 'See Less' : 'See More' }}</span>
    <PsIcon 
      :icon="!expanded ? 'keyboard_double_arrow_down' : 'keyboard_double_arrow_up'"
      size="18"
    />
  </div>
</template>

<script>
export default {
  name:"AppSeeMore",
  props: {
    expanded: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style lang="scss" scoped>
  svg {
    transform: rotate(180deg);
  }
  .is-expanded {
    svg {
      transform: rotate(0deg);
    }
  }
</style>