var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"psui-w-full psui-bg-white psui-shadow-elevation-5 psui-rounded-md psui-p-4 psui-mb-4 overflow-y-auto psui-divide-y psui-divide-gray-20"},[_c('div',{staticClass:"psui-w-full psui-flex psui-items-center psui-bg-white psui-pt-1 psui-pb-3"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"psui-w-1/5"},[_c('h3',{staticClass:"psui-font-bold psui-text-gray-80 opacity-100-all-childrens-on-hover psui-flex psui-items-center"},[_vm._v(" "+_vm._s(_vm._f("policyTextRephrase")('Policies'))+" ")])]),_vm._m(2),_vm._m(3),_vm._m(4)]),_vm._l((3),function(index){return _c('div',{key:index,staticClass:"list-policy psui-w-full psui-flex psui-items-center py-3 psui-text-gray-60 actions-wrapper hover:psui-bg-blue-10 hover:psui-text-blue-60 transition-all"},[_c('div',{staticClass:"psui-w-1/3 psui-pl-4"},[_c('vue-skeleton-loader',{attrs:{"width":Math.round((Math.random() * 100) + 80),"height":27}})],1),_c('div',{staticClass:"psui-w-1/4"},[_c('vue-skeleton-loader',{attrs:{"width":Math.round((Math.random() * 100) + 50),"height":27}})],1),_c('div',{staticClass:"psui-w-1/5"},[_c('vue-skeleton-loader',{attrs:{"width":Math.round((Math.random() * 100) + 20),"height":27}})],1),_c('div',{staticClass:"psui-w-1/5"},[_c('vue-skeleton-loader',{attrs:{"width":Math.round((Math.random() * 100) + 30),"height":27}})],1),_c('div',{staticClass:"psui-w-1/5"},[_c('vue-skeleton-loader',{attrs:{"width":Math.round((Math.random() * 100) + 20),"height":27}})],1),_vm._m(5,true)])})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"psui-w-1/3 psui-pl-4"},[_c('h3',{staticClass:"psui-font-bold psui-text-gray-80 opacity-100-all-childrens-on-hover psui-flex psui-items-center"},[_vm._v(" Comparison Name ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"psui-w-1/4"},[_c('h3',{staticClass:"psui-font-bold psui-text-gray-80 opacity-100-all-childrens-on-hover psui-flex psui-items-center"},[_vm._v(" City/County ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"psui-w-1/5"},[_c('h3',{staticClass:"psui-font-bold psui-text-gray-80 opacity-100-all-childrens-on-hover psui-flex psui-items-center"},[_vm._v(" Author ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"psui-w-1/5"},[_c('h3',{staticClass:"psui-font-bold psui-text-gray-80 opacity-100-all-childrens-on-hover psui-flex psui-items-center"},[_vm._v(" Unique views ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"psui-w-auto psui-pr-4"},[_c('i',{staticClass:"psui-icon psui-text-white"},[_vm._v("more_horiz")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"psui-w-auto psui-pr-4"},[_c('div',{staticStyle:{"width":"24px","height":"24px"}})])
}]

export { render, staticRenderFns }