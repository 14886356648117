import PolicyIndex from '@/modules/app/policy/shared/PolicyIndex.vue'
import PoliciesListIndex from '@/modules/app/policy/list/PoliciesListIndex.vue'
import PoliciesShowIndex from '@/modules/app/policy/show/PoliciesShowIndex.vue'
import PolicyShowHeader from '@/modules/app/policy/show/PolicyShowHeader.vue'
import PoliciesListHeader from '@/modules/app/policy/list/PoliciesListHeader.vue'
import PolicyOptionsIndex from '@/modules/app/policy/options/PolicyOptionsIndex.vue'
import PolicyOptionsHeader from '@/modules/app/policy/options/PolicyOptionsHeader.vue'

export default [
  {
    path: '/policies',
    component: PolicyIndex,
    children: [
      { 
        path: '',
        name: 'PoliciesList',
        component: PoliciesListIndex,
        meta: {
          header: PoliciesListHeader
        },
      },
      { 
        path: 'options', 
        name: 'PolicyOptions',
        component: PolicyOptionsIndex,
        meta: {
          header: PolicyOptionsHeader
        },
      },
      { 
        path: ':policy_id/:policy_active_tab?',
        name: 'PolicyShow',
        component: PoliciesShowIndex,
        meta: {
          header: PolicyShowHeader
        },
      },

    ]
  }
]
