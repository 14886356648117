var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-full overflow-y-auto"},[_c('BuildingEstimatesHeader',{staticClass:"sticky top-0 bg-white psui-z-20"}),_c('InAppCalculator',{ref:"inAppCalculator"}),_c('div',{staticClass:"w-full flex flex-col px-12 py-6"},[_c('div',{staticClass:"w-full my-3 mb-6 text-black flex"},[_c('VueSelect',{staticClass:"vue-select",attrs:{"placeholder":"Select a assumption profile to use","label":"title","reduce":item => item.id,"options":_vm.user_policies,"value":_vm.$store.state.assumptions.policy_id_selected}}),_c('PsButton',{staticClass:"ml-3 whitespace-nowrap",attrs:{"label":"Clear all building stocks","size":"big"},on:{"click":function($event){return _vm.clearBuildingStocks()}}})],1),_vm._l((_vm.building_types),function(building_type){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.checkQueryHasKey('only_building_type') || _vm.checkQueryKeyValue('only_building_type', building_type.slug)),expression:"!checkQueryHasKey('only_building_type') || checkQueryKeyValue('only_building_type', building_type.slug)"}],key:`building_type_${building_type.id}`,staticClass:"w-full"},[_c('h1',{staticClass:"cursor-pointer text-2xl font-bold"},[_c('span',{on:{"click":function($event){return _vm.toggleHiddenItem(`building_type_${building_type.id}`)}}},[_vm._v(_vm._s(building_type.title)+" | ")]),_c('span',{staticClass:"text-red",on:{"click":function($event){_vm.addValueToSum(
              _vm.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
                type_prototype_id: building_type.type_prototypes.map(type_prototype => type_prototype.id)
              }),
              `Building type ${building_type.id}`
            )}}},[_vm._v(" Units: "+_vm._s(_vm.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({ type_prototype_id: building_type.type_prototypes.map(type_prototype => type_prototype.id) }))+" ")])]),_vm._l((_vm.climateZones),function(climate_zone){return [(_vm.hiddenItems.indexOf(`building_type_${building_type.id}`) < 0)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.checkQueryKeyValue('exclude_climate_zone_prefix', climate_zone.prefix)),expression:"!checkQueryKeyValue('exclude_climate_zone_prefix', climate_zone.prefix)"}],key:`climate_zone-${climate_zone.id}`,staticClass:"w-full ml-3 mb-2"},[_c('h1',{staticClass:"cursor-pointer mb-2 text-xl font-bold"},[_c('span',{on:{"click":function($event){return _vm.toggleHiddenItem(`climate_zone-${climate_zone.id}`)}}},[_vm._v(_vm._s(climate_zone.raw)+" | ")]),_c('span',{staticClass:"text-red",on:{"click":function($event){_vm.addValueToSum(
                  _vm.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({ 
                    climate_zone_prefix: climate_zone.prefix,
                    type_prototype_id: building_type.type_prototypes.map(type_prototype => type_prototype.id)
                  }),
                  `Climate Zone ${climate_zone.prefix}`
                )}}},[_vm._v(" Units: "+_vm._s(_vm.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({ climate_zone_prefix: climate_zone.prefix, type_prototype_id: building_type.type_prototypes.map(type_prototype => type_prototype.id) }))+" ")])]),_vm._l((building_type.type_prototypes),function(type_prototype){return [(_vm.hiddenItems.indexOf(`climate_zone-${climate_zone.id}`) < 0)?_c('div',{key:`type_prototype_${type_prototype.id}`,staticClass:"ml-3 mb-2 w-full"},[_c('h1',{staticClass:"mb-2 text-xl font-bold"},[_c('span',[_vm._v(_vm._s(type_prototype.title)+" | ")]),_c('span',{staticClass:"cursor-pointer text-red",on:{"click":function($event){_vm.addValueToSum(
                      _vm.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({ 
                        climate_zone_prefix: climate_zone.prefix,
                        type_prototype_id: type_prototype.id,
                      }),
                      `Type prototype ${type_prototype.title}`
                    )}}},[_vm._v(" Units: "+_vm._s(_vm.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({ climate_zone_prefix: climate_zone.prefix, type_prototype_id: type_prototype.id, }))+" ")])]),_vm._l((_vm.type_vintages),function(type_vintage){return _c('div',{key:`type_vintage_${type_vintage.id}`,staticClass:"ml-3 mb-2 w-full flex flex-col"},[_c('AssumptionsBuildingStocksInput',{attrs:{"label":type_vintage.title,"climate_zone_prefix":climate_zone.prefix,"type_vintage_id":type_vintage.id,"type_prototype_id":type_prototype.id}})],1)})],2):_vm._e()]})],2):_vm._e()]})],2)]}),_c('h1',{staticClass:"text-xl font-bold"},[_vm._v(" custom_building_stocks ")]),_c('table',{staticClass:"table table-bordered table-hover table-striped mb-4"},[_vm._m(0),_c('tbody',_vm._l((_vm.$store.state.assumptions.buildingStocks.custom_building_stocks),function(custom_building_stock,index){return _c('tr',{key:`custom_building_stock_${index}`},[_c('td',[_vm._v(_vm._s(custom_building_stock.jurisdiction_id))]),_c('td',[_vm._v(_vm._s(custom_building_stock.climate_zone_prefix))]),_c('td',[_vm._v(_vm._s(custom_building_stock.type_prototype_id))]),_c('td',[_vm._v(_vm._s(custom_building_stock.type_vintage_id))]),_c('td',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(custom_building_stock.units)+" ")])])}),0)])],2)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',[_vm._v("jurisdiction_id")]),_c('td',[_vm._v("climate_zone_prefix")]),_c('td',[_vm._v("type_prototype_id")]),_c('td',[_vm._v("type_vintage_id")]),_c('td',[_vm._v("units")])])])
}]

export { render, staticRenderFns }