import BaseMeta from '../BaseMeta'
import CustomCombinationAssumptions from './CustomCombinationAssumptions'

export default class CustomCombinationMetaData extends BaseMeta {

  assumptions = null
  
  get fillable() {
    return [
      'assumptions', 'fuel_choice', 'require_pv_system', 'compliance_margin_value', 'compliance_margin_measure_id',
      'generate_virtual_compliance_margin', 'require_different_compliance_margins'
    ]
  }

  constructor(data = {}) {
    super(data)
    if(typeof data['assumptions'] !== undefined) {
      this.assumptions = new CustomCombinationAssumptions(data['assumptions'])
    }
    return this.getFillableKeys()
  }

}
