<template>
  <table class="table table-sm">
    <thead>
      <tr>
        <th>Created at</th>
        <th>ID</th>
        <th>Title</th>
        <th>User</th>
        <th>Measures</th>
        <th>Show</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(policy, index) in policies"
        :key="`listItem_${index}`"
      >
        <td>{{ formatDate({ date: policy.created_at, format: 'MM/DD/YYYY HH:mm' }) }}</td>
        <td>{{ policy.id }}</td>
        <td>{{ policy.title }}</td>
        <td>
          <span v-if="policy.user">Logged: {{ policy.user.email }}</span>
          <div v-else-if="policy.user_device">
            <span v-if="policy.user_device.user">Not Logged: identified from the device - {{ policy.user_device.user.email }}</span>
            <span v-else>Device not Logged yet: ID {{ policy.user_device.id }} {{ policy.user_device.platform }} - {{ policy.user_device.browser }}</span>
          </div>
          <span v-else>Unidentified user or device</span>
        </td>
        <td>{{ policy.measures ? policy.measures.length : '--' }}</td>
        <td>
          <router-link
            :to="{ name: 'PolicyShow', params: { policy_id: policy.id }}"
            target="_blank"
          >
            <PsButton
              label="Show"
              size="small"
            />
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ['policies']
}
</script>
