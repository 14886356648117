export default ({ prototype, prototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt }) => {

  function prototypeIndex() {
    return prototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt.map(p => parseInt(p.study_id)).indexOf(parseInt(prototype.study.id))
  }

  function nextPrototype() {
    return prototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt[prototypeIndex() - 1]
  }

  function isNewestVersion() {
    return prototypeIndex() === 0
  }

  function hasNewerVersionReleased() {      
    return nextPrototype() && nextPrototype().study.published_at ? true : false
  }

  function hasNewerVersionOnlyInPdf() {      
    return nextPrototype() && !nextPrototype().study.published_at ? true : false
  }



  if (hasNewerVersionOnlyInPdf()) {
    return 'Newer Version Released'
  } else if (isNewestVersion()) {
    return 'Newest Version'
  } else if (hasNewerVersionReleased()) {
    return 'Newer Version Available'
  } else {
    return false
  }

}