<template>
  <BaseDrawer
    ref="baseDrawer"
    title="Upcoming Features"
    :hierarchy="DRAWER_HIERARCHY.SECONDARY"
  >
    <div class="w-full">
      <div
        class="header-content"
        v-html="header"
      />
      <div class="space--2" />
      <div class="space--2" />
      <div class="content">    
        <div
          v-for="step in steps"
          :key="step.title"
          class="row"
        >
          <div class="media">
            <img
              :src="step.img"
              :alt="step.title"
            >
          </div>
          <div class="description">
            <h2 class="ts--title--5">
              {{ step.title }}
            </h2>
            <p class="ts--p--2">
              {{ step.content }}
            </p>
          </div>
        </div>  
      </div>
    </div>
  </BaseDrawer>
</template>

<script>
import { HELPERS_IDENTIFICATION } from "@/business-logic/constants/helpersEnum"
import { DRAWER_HIERARCHY } from '@/util/Enums.js'

export default {
  name: 'DrawerUpcomingFeatures',
  data() {
    return {
      steps: null,
      header: null,
      HELPERS_IDENTIFICATION,
      DRAWER_HIERARCHY,
    }
  },
  mounted() {
    this.$eventBus.$on('openUpcomingFeaturesDrawer', () => {
      this.getContent()
      this.$refs.baseDrawer.openDrawer()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openUpcomingFeaturesDrawer')
  },
  methods: {
    closeDrawer() {
      this.showDrawer = false
    },
    async getContent(){
      const query = `
        {
          helper (
            where: [
              { column: "slug", operation: "=", value: "${HELPERS_IDENTIFICATION.UPCOMING_FEATURES_SLUG}"}
            ]
          ){
            id
            title
            slug
            description
            content
            created_at
          }
        }
      `
      const { data } = await this.$graphql({ query })
      this.header = data.helper.description
      this.steps = JSON.parse(data.helper.content)
    }
  }
}
</script>

<style lang="scss" scoped>

  .row {
    display: flex;
    border-bottom: 1px solid #E4EEF2;
    padding-bottom: 36px;
    padding-top: 36px;
    align-items: center;
    max-width: 1100px;

    &:last-of-type {
      border-bottom: 0;
    }

    .media {
      flex-shrink: 0;
      margin-right: 60px;
      height: 224px;
      width: 325px;
    }

    &:nth-of-type(2n) {
      flex-direction: row-reverse;
      .media {
        margin-left: 60px;
        margin-right: 0;
      }
    }
  }

  h1 {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 48px;
    color: #4B8CA6;
  }

  .description {
    
    h2 {
      margin-bottom: 28px;
    }
    p {
      margin-bottom: 0;
      color: #404040;
    }
    a {
      color: initial;
    }
  }
  
  .header-content {
    color: #404040;
    font-size: 14px;
    line-height: 18px;

     ::v-deep a {
       color: initial;
     }
  }
    
</style>