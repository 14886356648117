<template>
  <div class="w-full flex flex-col bg-white">
    <div class="w-full flex flex-col bg-white">
      <div class="w-full p-6 px-8 border-b border-ash-200 bg-white flex justify-between">
        <PageTitle title="Default Assumption Input" />
      </div>
    </div>

    <div class="p-8">
      <table class="table table-bordered table-hover table-striped">
        <thead>
          <tr>
            <td>ID</td>
            <td>Title</td>
            <td>Key</td>
            <td>Value</td>
            <td>Type prototype</td>
            <td>Study type</td>
            <td class="psui-w-48">
              Actions
            </td>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="(default_assumption_input, default_assumption_input_index) in default_assumption_inputs"
            :key="`default_assumption_input_${default_assumption_input_index}`" 
            class="cursor-move"
            draggable="true"
            @dragstart="dragStart(default_assumption_input_index)"
            @dragover.prevent
            @dragend="dragEnd"
            @drop="dragFinish(default_assumption_input_index, default_assumption_inputs)"
          >
            <td>{{ default_assumption_input.id }}</td>
            <td>{{ default_assumption_input.title }}</td>
            <td>{{ default_assumption_input.key }}</td>
            <td>{{ default_assumption_input.value }}</td>
            <td>{{ default_assumption_input.type_prototype.title }}</td>
            <td>{{ default_assumption_input.study_type.title }}</td>
            <td class="flex space-x-2">
              <PsButton
                size="medium"
                label="Edit"
                icon="edit"
                @click="$eventBus.$emit('openDefaultAssumptionInputModal', { default_assumption_input_id: default_assumption_input.id })" 
              />
              <PsButton 
                layout="caution"
                size="medium" 
                label="Delete" 
                icon="delete"
                @click="selectDefaultAssumptionInputToDelete(default_assumption_input)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <AdminDefaultAssumptionInputEditModal @getDefaultAssumptionInputs="getDefaultAssumptionInputs" />

    <BaseModal
      ref="deleteModal"
      modal-dialog-classes="w-full max-w-sm"
      title="Attention"
    >
      <div
        v-if="default_assumption_input_selected"
        class="w-full flex flex-col items-start mt-6"
      >
        <p class="text-base text-gray04">
          Delete <span class="text-red font-bold">{{ default_assumption_input_selected.title }}</span>?
        </p>
        <PsButton
          layout="caution"
          class="psui-mt-6"
          label="Confirm delete"
          size="big"
          @click="deleteDefaultAssumptionInput()"
        />
      </div>
    </BaseModal>
  </div>
</template>

<script>
import AdminDefaultAssumptionInputEditModal from './AdminDefaultAssumptionInputEditModal'
export default {
  name: 'AdminDefaultAssumptionInputList',
  components: { AdminDefaultAssumptionInputEditModal },
  data() {
    return {
      default_assumption_inputs: [],
      default_assumption_input_selected: null
    }
  },
  computed: {
    getTableToOrdenateItems() {
      return `default_assumption_inputs`
    }
  },
  mounted() {
    this.getDefaultAssumptionInputs()
  },
  methods: {
    getDefaultAssumptionInputs() {
      const query = `
        {
          default_assumption_inputs (
            orderBy: [
              { column: "id" }
            ]
          ) {
            id
            title
            key
            value
            type_prototype {
              title
            }
            study_type {
              title
            }
            created_at
          }
        }
      `
      this.$graphql({ query, caller: 'AdminDefaultAssumptionInput' })
        .then(({ data }) => {
          this.default_assumption_inputs = data.default_assumption_inputs
        })
    },

    selectDefaultAssumptionInputToDelete(default_assumption_input) {
      this.default_assumption_input_selected = default_assumption_input
      this.$refs.deleteModal.showModal = true
    },

    deleteDefaultAssumptionInput() {
      this.$api.delete(`/api/admin/default_assumption_input/delete/${this.default_assumption_input_selected.id}`)
        .then(() => {
          this.getDefaultAssumptionInputs()
          this.$refs.deleteModal.showModal = false
          this.default_assumption_input_selected = null
        })
    }
  }
}
</script>