<template>
  <PsAccordionItem
    title="Contingent Percentage for Flexible Impact"
    class="display-impact-assumption-icon"
    :class="{'form-dirty': hasValueUpdated(null, null) }"
  >
    <template #header-additionals>
      <PsIcon
        icon="help_outline"
        size="16"
        display="flex"
        class="help psui-items-center psui-h-5 hover:psui-text-blue-60"
        :icon-classes="hasValueUpdated(null, null) ? 'mr-5': ''"
        @click.native.stop="$eventBus.$emit('openDescriptionModal', { slug: assumptionKey, ...descriptionModelObject })"
      />
    </template>
    <template #default>
      <span>
        Admin Assumption Only. We assume that this percentage of units will be able to use the mix of contingent measures (burnout measures) to achieve their flexible score.
      </span>
      <transition
        name="psui-el-compound"
        @enter="startTransition"
        @after-enter="endTransition"
        @before-leave="startTransition"
        @after-leave="endTransition"
      >
        <div
          v-if="specifyBy.length === 0"
          class="psui-flex psui-gap-3 psui-items-center mt-4/5"
        >
          <PsInput
            class="w-1/2"
            :class="{'selected': hasValueUpdated(null, null)}"
            :value="getAssumptionValue(null, null)"
            layout="mini"
            @click.native="checkIfUserCanEditAssumption"
            @input="onInput(null, null, $event.target.value)"
            @change="updateAssumption(null, null, $event.target.value)"
            @mouseenter="onMouseEnterInsideInput(null, null)"
            @mouseleave="onMouseLeaveInsideInput"
          >
            <template #append>
              <span
                v-if="!hasValueUpdated(null, null) || !canShowResetButton(null, null)"
                class="psui-text-gray-50 psui-text-xsmall"
              >%</span>
              <PsTooltip v-else-if="canShowResetButton(null, null)">
                <template #trigger>
                  <PsIcon 
                    icon="restart_alt"
                    icon-classes="psui-text-green-20 cursor-pointer mt-1"
                    size="17"
                    @click.native="updateAssumption(null, null, getBaseValue)"
                  />
                </template>
                <template #content>
                  <h1 class="mb-1">
                    Restore to default value
                  </h1>
                  <span class="psui-font-normal">{{ getBaseValue }}%</span>
                </template>
              </PsTooltip>
            </template>
          </PsInput>
        </div>
      </transition>

      <AssumptionsGrowthWidgetPolicyAssumptions
        v-if="$route.name === 'PolicyShow' && canHaveMultipleSpecifyBy"
        ref="switchItem"
        class="mt-4/5 w-full ml-0"
        header-title="Specify By:"
        :specify-by-options="RESIDENTIAL_NEW_SPECIFICATIONS"
        :specify-by.sync="specifyBy"
        :toggle-confirmer="checkCanAutoToggleAssumptionLevel"
        @toggle="onAssumptionLevelAutoToggle"
      />

      <div
        v-else-if="getSingleSpecifyByEnabled"
        class="flex items-center justify-between mt-3 psui-bg-gray-20 py-1/2 px-3 rounded-t-6"
        :class="{'psui-bg-blue-10 psui-text-blue-60': specifyBy.length > 0 }"
      >
        <span
          class="psui-text-small mr-auto"
          :class="{'psui-font-bold':specifyBy.length > 0}"
        >{{ `Assume different values for each ${getSingleSpecifyByEnabled.label.toLowerCase()}` }}</span>
        <PsSwitch
          ref="switchItem"
          size="small"
          class="flex-shrink-0"
          label="Breakdown by construction types"
          :value="specifyBy.length > 0"
          @change="toggleSingleSpecifyBy($event)"
        />
      </div>
      <transition
        name="psui-el-compound"
        @enter="startTransition"
        @after-enter="endTransition"
        @before-leave="startTransition"
        @after-leave="endTransition"
      >
        <div
          v-if="isSpecifyByClimateZoneEnabled && canSpecifyByClimateZone"
          class="mt-2"
        >
          <div class="flex justify-between items-center">
            <span class="psui-text-gray-80 psui-font-bold psui-text-p">Climate Zone</span>
            <PsToggle
              :items="getClimateZonesWithinPolicySelected"
              key-label="prefix"
              key-value="raw"
              :selected="getClimateZoneSelected"
              @update:selected="climate_zone = $event"
            />
          </div>
          <div
            v-if="!isSpecifyByBuildingTypeEnabled"
            class="psui-flex psui-gap-3 psui-items-center mt-4/5"
          >
            <PsInput
              class="w-1/2"
              :class="{'selected':hasValueUpdated(null, getClimateZoneSelected)}"
              :value="getAssumptionValue(null, getClimateZoneSelected)"
              layout="mini"
              @click.native="checkIfUserCanEditAssumption"
              @input="onInput(null, getClimateZoneSelected, $event.target.value)"
              @change="updateAssumption(null, getClimateZoneSelected, $event.target.value)"
              @mouseenter="onMouseEnterInsideInput(null, getClimateZoneSelected)"
              @mouseleave="onMouseLeaveInsideInput"
            >
              <template #append>
                <span
                  v-if="!hasValueUpdated(null, getClimateZoneSelected) || !canShowResetButton(null, getClimateZoneSelected)"
                  class="psui-text-gray-50 psui-text-xsmall"
                >%</span>
                <PsTooltip v-else-if="canShowResetButton(null, getClimateZoneSelected)">
                  <template #trigger>
                    <PsIcon 
                      icon="restart_alt"
                      icon-classes="psui-text-green-20 cursor-pointer mt-1"
                      size="17"
                      @click.native="updateAssumption(null, getClimateZoneSelected, getBaseValue)"
                    />
                  </template>
                  <template #content>
                    <h1 class="mb-1">
                      Restore to default value
                    </h1>
                    <span class="psui-font-normal">{{ getBaseValue }}%</span>
                  </template>
                </PsTooltip>
              </template>
            </PsInput>
          </div>
        </div>
      </transition>
      <transition
        name="psui-el-compound"
        @enter="startTransition"
        @after-enter="endTransition"
        @before-leave="startTransition"
        @after-leave="endTransition"
      >
        <div
          v-if="isSpecifyByBuildingTypeEnabled && canSpecifyByPrototype"
          class="psui-flex psui-flex-col psui-mt-4 space-y-10"
        >
          <div
            v-for="prototype in getPrototypesWithinPolicySelected"
            :key="`prototype-switch-${prototype.id}`"
            class="psui-flex psui-flex-col psui-justify-between"
          >
            <div
              v-if="canPrototypeInputBeVisible(prototype)"
              class="psui-flex psui-flex-col"
            >
              <span
                class="flex-shrink-0 psui-text-gray-80 psui-text-big psui-font-bold mb-4"
              >{{ prototype.title | prototypeTitleHomesAsUnits }}</span>
              <div class="psui-flex psui-gap-3">
                <PsInput
                  :value="getAssumptionValue(prototype, getClimateZoneSelected)"
                  :class="{'selected':hasValueUpdated(prototype, getClimateZoneSelected)}"
                  layout="mini"
                  @click.native="checkIfUserCanEditAssumption"
                  @input="onInput(prototype, getClimateZoneSelected, $event.target.value)"
                  @change="updateAssumption(prototype, getClimateZoneSelected, $event.target.value)"
                  @mouseenter="onMouseEnterInsideInput(prototype, getClimateZoneSelected)"
                  @mouseleave="onMouseLeaveInsideInput"
                >
                  <template #append>
                    <span
                      v-if="!hasValueUpdated(prototype, getClimateZoneSelected) || !canShowResetButton(prototype, getClimateZoneSelected)"
                      class="psui-text-gray-50 psui-text-xsmall"
                    >%</span>
                    <PsTooltip v-else-if="canShowResetButton(prototype, getClimateZoneSelected)">
                      <template #trigger>
                        <PsIcon 
                          icon="restart_alt"
                          icon-classes="psui-text-green-20 cursor-pointer mt-1"
                          size="17"
                          @click.native="updateAssumption(prototype, getClimateZoneSelected,getBaseValue)"
                        />
                      </template>
                      <template #content>
                        <h1 class="mb-1">
                          Restore to default value
                        </h1>
                        <span class="psui-font-normal">{{ getBaseValue }}%</span>
                      </template>
                    </PsTooltip>
                  </template>
                </PsInput>
              </div>
            </div>
          </div>
        </div> 
      </transition>
    </template>
  </PsAccordionItem>
</template>

<script>
import AssumptionsGrowthWidgetPolicyAssumptions from '@/modules/app/assumptions/building-estimates/AssumptionsGrowthWidgetPolicyAssumptions.vue'
import { ASSUMPTIONS_KEYS } from '@/util/Enums.js'
import AssumptionImpactsByPrototypeAndClimateZoneMixin from './AssumptionImpactsByPrototypeAndClimateZoneMixin'

export default {
  name:"AssumptionsFlexibleContingentPercentage",
  components:{
    AssumptionsGrowthWidgetPolicyAssumptions
  },
  props:['tabSelected', 'descriptionModelObject'],
  mixins: [ AssumptionImpactsByPrototypeAndClimateZoneMixin ],
  data: ()=> ({
    assumptionKey: ASSUMPTIONS_KEYS.flexible_contingent_percentage,
  }),
  computed: {
    getBaseValue() {
      return this.defaultExistingBuildingsImpactAssumptionValues[this.assumptionKey]
    },
  },
}

</script>

<style lang="scss" scoped>
  ::v-deep .psui-el-switch.size-small .psui-el-switch-button.toggle-true {
    background: #318FAC;
  }

  ::v-deep .psui-el-input.status-resting.layout-mini.selected {
    .psui-el-input-wrapper {
      border-color: #5DB883;
    }
  }

  .psui-el-accordion-item {
    &.status-opened {
      .help {
        color: #318FAC;
      }
    }
  }
</style>
