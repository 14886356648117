<template>
  <div class="psui-w-full psui-bg-white psui-shadow-elevation-5 psui-rounded-md psui-p-4 psui-mb-4">
    <div class="psui-w-full psui-flex psui-items-center psui-border-b psui-border-gray-20 psui-pt-1 psui-pb-3">
      <div class="psui-w-1/4 psui-pl-4">
        <h3 class="psui-font-bold psui-text-gray-80 opacity-100-all-childrens-on-hover">
          {{ 'Policy Name' | policyTextRephrase }}
        </h3>
      </div>

      <div class="psui-w-1/5">
        <h3 class="psui-font-bold psui-text-gray-80 opacity-100-all-childrens-on-hover">
          City/County
        </h3>
      </div>
      <div class="psui-w-1/5">
        <h3 class="psui-font-bold psui-text-gray-80 opacity-100-all-childrens-on-hover">
          Last Update
        </h3>
      </div>
      <div class="psui-w-2/5 mr-4">
        <h3 class="psui-font-bold psui-text-gray-80 opacity-100-all-childrens-on-hover">
          Author
        </h3>
      </div>
    </div>
    <div 
      v-for="index in 3" 
      :key="index"
      class="list-policy psui-w-full psui-flex psui-items-center py-4 psui-border-b psui-border-gray-20 last:border-b-0 psui-text-gray-60 actions-wrapper hover:psui-bg-blue-10 hover:psui-text-blue-60 transition-all"
    >
      <div class="psui-w-1/4 psui-pl-4">
        <vue-skeleton-loader
          :width="Math.round((Math.random() * 100) + 100)"
          :height="27"
        />
      </div>

      <div class="psui-w-1/5">
        <vue-skeleton-loader
          :width="Math.round((Math.random() * 100) + 100)"
          :height="27"
        />
      </div>
      <div class="psui-w-1/5 psui-flex psui-items-center">
        <vue-skeleton-loader
          :width="Math.round((Math.random() * 100) + 100)"
          :height="27"
        />      
      </div>
      <div class="psui-w-2/5">
        <vue-skeleton-loader
          :width="Math.round((Math.random() * 100) + 100)"
          :height="27"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PoliciesListTableSkeleton'
}
</script>

<style>

</style>