<template>
  <div class="wrapper psui-flex  psui-m-auto psui-flex-col">
    <div class="header psui-flex">
      <div>
        <img
          src="/logo/logo-cost-effectiveness-explorer-v2-white-background.png"
          alt="Cost Effectiveness Explorer"
          width="158"
          height="32"
        >
      </div>
      <div>
        <img       
          src="/logo/logo-state-program-light-background.png"
          alt="California Energy Codes & Standards Logo"
          width="65"
          height="auto"
        >
      </div>
    </div>
    <div class="psui-text-center psui-mt-12 psui-mb-8">
      <img
        src="/images/upcoming_features/new-studies-is-coming.jpg"
        alt="Maintenance Mode"
        class="psui-inline-block"
      >
    </div>
    <div lass="psui-flex psui-flex-col psui-align-center psui-text-center psui-mb-8">
      <h2 class="psui-text-h5 psui-font-bold psui-text-blue-80">
        We are updating the system to bring new functionalities to you.
      </h2>
      <h4 class="psui-text-h6 psui-text-blue-80">
        The system will be back shortly. Thank you for your patience.
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheMaintenanceMode'
}
</script>

<style lang="scss" scoped>
  .wrapper {
    width: 100%;
    max-width: 544px;
  }
  .header {
    max-width: 544px;
    justify-content: space-between;
    border-bottom: 1px solid #D6DDE5;
    padding-bottom: 20px;
  }
</style>