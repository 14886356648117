import { toFixed } from '@/util/Parsers'
import formatStudyData from '@/formatters/formatStudyData'
import formatCurrency from '@/formatters/formatCurrency'
import formatNumber from '@/formatters/formatNumber'
import { formatNumberExtended } from '@/formatters/formatNumberExtended'
import formatComplianceMarginAsText from '@/formatters/formatComplianceMarginAsText'
import dayjs from 'dayjs'
import { formatDate, parsejurisdictionAsFullText } from '@/util/Helpers'
import Store from '@/store'

export default {
  methods: {
    formatNumber(number) {
      return formatNumber(number)
    },
    toFixed(value, precision = 2) {
      return toFixed(value, precision)
    },
    formatDate(args) {
      return formatDate(args)
    },
    formatDateTimestamp({timestamp, format = 'MM/DD/YYYY', lang = 'en'}) {
      if (!timestamp) return ''
      dayjs.locale(lang)
      return dayjs.unix(timestamp).format(format)
    },
    formatDateTime({date, format = 'MM/DD/YYYY HH:mm:ss', from = 'YYYY-MM-DD HH:mm:ss', lang = 'en'}) {
      if (!date) return ''
      dayjs.locale(lang)
      return dayjs(date, from).format(format)
    },
    formatStudyData(col, value, data, studyId) {
      return formatStudyData(col, value, data, studyId)
    },
    formatCurrency(value) {
      return formatCurrency(value)
    },
    formatUnits(value) {
      if(value == null) return '--'
      return formatNumberExtended(value, 0, 0)
    },
    parsejurisdictionAsFullText(jurisdiction, capitalize = false) {
      return parsejurisdictionAsFullText(jurisdiction, capitalize)
    },
    formatComplianceMarginAsText(value) {
      return formatComplianceMarginAsText(value)
    },
    formatComplianceMargin(value) {
      return `${formatStudyData('compliance_margin', value, null)}`
    },
    jurisdictionFormat(string) {
      if (!Store.getters['lastJurisdictionVisited']) return string
      return string.replace('JURISDICTION', Store.getters['lastJurisdictionVisited'].type)
    }
  },
}

