<template>
  <div class="w-full flex flex-col bg-ash-200">
    <div class="w-full flex flex-col bg-ash-200">
      <div class="w-full p-6 px-8 border-b border-ash-200 bg-white flex justify-between">
        <PageTitle title="Jurisdiction Analysis Dashboard" />
      </div>
    </div>

    <div class="w-full h-auto flex px-8 py-4 justify-end space-x-4">
      <JurisdictionInput
        ref="inputJurisdiction"
        input-class="bg-gray06 border-b-2 border-gray06 mt-6 mr-2"
        :jurisdiction="getJurisdictionSelected"
        @update:jurisdiction="goToJurisdiction"
      />

      <PsDropdown ref="dropdownTimeFrame">
        <template #dropdownTrigger>
          <div class="w-full flex space-x-4">
            <InputWithLabel
              :value="timeFrameSelected.init"
              class="mt-6"
              :label="'Init'"
              :mask="'####-##-##'"
              placeholder="YYYY/MM/DD"
              @blur="timeFrameSelected.init = $event"
            />
            <InputWithLabel
              :value="timeFrameSelected.end"
              class="mt-6"
              :label="'End'"
              :mask="'####-##-##'"
              placeholder="YYYY/MM/DD"
              @blur="timeFrameSelected.end = $event"
            />
          </div>
        </template>
        <template #items>
          <div class="w-full flex flex-col bg-white p-3 space-y-3 rounded">
            <PsButton
              v-for="timeFrame in getTimeFramesForAnalysis" 
              :key="timeFrame.label" 
              :label="timeFrame.label"
              @click="setTimeFrame(timeFrame)"
            />
          </div>
        </template>
      </PsDropdown>
    </div>

    <div class="w-full flex flex-col px-8 py-4">
      <MostSearchedJurisdictions 
        v-if="!getJurisdictionSelected && !$route.params.jurisdiction_id" 
        :time-frame-selected="timeFrameSelected"
        :jurisdiction-selected="jurisdictionSelected"
        @update:jurisdiction="goToJurisdiction"
      />

      <div
        v-else
        class="w-full grid grid-cols-2 gap-4"
      >
        <PoliciesByType 
          :policies="policies"
        />
        <PoliciesByUserType 
          :policies="policies"
        />
        <PoliciesProfile 
          class="col-span-2"
          :policies="policies"
          :get-mapped-policies="getMappedPolicies"
        />
        <SearchsLocation 
          :time-frame-selected="timeFrameSelected"
        />
        <SearchsDevices 
          :time-frame-selected="timeFrameSelected"
        />
      </div>
    </div>
  </div>
</template>

<script>

import getTimeFramesForAnalysis from '@/util/getTimeFramesForAnalysis'
import JurisdictionInput from '@/components/general/JurisdictionInput'
import MostSearchedJurisdictions from './MostSearchedJurisdictions'
import PoliciesByUserType from './PoliciesByUserType'
import PoliciesByType from './PoliciesByType'
import PoliciesProfile from './PoliciesProfile'
import SearchsLocation from './SearchsLocation'
import SearchsDevices from './SearchsDevices'
import { ArrayHelpers } from '@igortrindade/lazyfy'
export default {
  name: 'AdminDashboardIndex',
  components: { 
    JurisdictionInput,
    MostSearchedJurisdictions,
    PoliciesByType,
    PoliciesByUserType,
    PoliciesProfile,
    SearchsLocation,
    SearchsDevices,
  },
  data() {
    return {
      getTimeFramesForAnalysis,
      timeFrameSelected: getTimeFramesForAnalysis[9],
      jurisdictionSelected: false,
      policies: []
    }
  },
  computed: {
    getJurisdictionSelected() {
      if(!this.$route.params.jurisdiction_id) return null
      if(this.$route.params.jurisdiction_id == 'all') return { title: 'All jurisdictions' }
      return this.jurisdictionSelected
    },

    getMappedPolicies() {

      return this.policies.reduce((acc, policy) => {
        policy.policy_containers.map((policy_container) => {
          policy_container.custom_combinations.map((custom_combination) => {

            const policyMapped = {
              id: policy.id,
              title: policy.title,
              created_at: policy.created_at,
              user: policy.user,
              user_device: policy.user_device,
              type_prototype: policy_container.type_prototype,
              type_prototype_id: policy_container.type_prototype_id,
              climate_zone_raw: custom_combination.climate_zone_raw,
              fuel_id: custom_combination.fuel_id,
              vintage_id: custom_combination.vintage_id,
              measures: custom_combination.measures,
              policy_containers: policy.policy_containers
            }

            const finded = ArrayHelpers.find(acc, { 
              id: policyMapped.id,
              climate_zone_raw: policyMapped.climate_zone_raw,
              fuel_id: policyMapped.fuel_id,
              vintage_id: policyMapped.vintage_id,
              type_prototype_id: policyMapped.type_prototype_id
            })

            if(!finded) acc.push(policyMapped)

          })
        })

        return acc
      }, [])
    },
  },
  watch: {
    '$route.path': {
      handler() {
        this.getPolicies()
      },
      immediate: true
    }
  },
  mounted()  {
    this.getPolicies()
  },
  methods: {
    setTimeFrame(timeFrame) {
      this.timeFrameSelected = timeFrame
      this.$refs.dropdownTimeFrame.close()
      this.getPolicies()
    },

    goToJurisdiction(jurisdiction) {
      this.jurisdictionSelected = jurisdiction
      this.$router.push({ params: { jurisdiction_id: jurisdiction.id }})
      this.getPolicies()
    },

    getPolicies() {
      
      const query = `
        {
          policies (
            whereBetween: [{ column: "created_at", init: "${this.timeFrameSelected.init}", end: "${this.timeFrameSelected.end}" }]
            where: [
              { column: "exclude_in_report", value: "false" }
              ${ this.$route.params.jurisdiction_id && this.$route.params.jurisdiction_id != 'all' ?
                `{ column: "jurisdiction_id", value: "${ this.$route.params.jurisdiction_id}" }` 
                : '' 
              }
            ]
            orderBy: [{ column: "created_at", order: "DESC" }]
          ) {
            id
            title
            user_id
            user_device_id
            exclude_in_report
            created_at
            user { id email log_events }
            user_device { id platform browser user { id email log_events } }
            policy_containers {
              id
              type_prototype_id
              type_prototype { id title icon }
              custom_combinations { id climate_zone_raw vintage_id fuel_id meta measures { id } }
            }
          }
        }
      
      `

      this.$graphql({ query, caller: 'AdminJurisdictionDashboardIndex', shouldCache: true })
        .then(({ data }) => {
          this.policies = data.policies
        })
    }
  }
}
</script>