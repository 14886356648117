<template>
  <div class="flex flex-col justify-center">
    <p class="text-p-2 italic text-ash-500 text-center mt-6">
      Soon you'll be able to create policies for new construction and nonresidential buildings.
    </p>
    <div class="py-12 px-16 mt-6">
      <JurisdictionInput
        ref="existingJurisdictionInput"
        :jurisdiction.sync="policy.jurisdiction"
        input-class="bg-transparent border-ash-300"
        @update:jurisdiction="updateJurisdiction"
      />
      <InputWithLabel
        ref="inputPolicyTitle"
        input-class="mt-8 bg-transparent border-ash-300"
        label="Policy Name"
        :value="policy.title"
        @input="$emit('update:policy', { ...policy, title: $event })"
        @keydown.enter="nextStep()"
      />
    </div>
 
    <div class="" />
  </div>
</template>
 
<script>
import InputWithLabel from '@/components/general/InputWithLabel.vue'
import JurisdictionInput from '@/components/general/JurisdictionInput.vue'
import { mapGetters } from 'vuex'
 
export default {
  name: 'PolicyWizardStepTwo',
  components: { JurisdictionInput, InputWithLabel },
  props: ['policy'],
    computed: {
    ...mapGetters(['lastJurisdictionVisited']),
  },
  mounted() {
    this.$eventBus.$on('openExistingBuildingPolicyWizardModal', () => {
      const initialFocusInput = this.lastJurisdictionVisited ? 'inputPolicyTitle' : 'existingJurisdictionInput'
      setTimeout(() => {
        if(this.$refs[initialFocusInput]) this.$refs[initialFocusInput].focusOnInput()        
      }, 100)
    })
  },
  methods: {
    updateJurisdiction(jurisdiction) {
      this.$emit('update:policy', { ...this.policy, jurisdiction: jurisdiction, jurisdiction_id: jurisdiction.id })
      this.$store.dispatch('setUserLastJurisdictionAndFirstClimateZoneVisited', { value: jurisdiction, context: 'updateLastJurisdictionVisitedFromHome'})
    },
    nextStep() {
      if (this.policy.jurisdiction && this.policy.title) {
        this.$emit('nextStep')
      }
    },
  },
}
</script>
 
<style lang="scss" scoped>
  ::v-deep {
    .app-inputwithlabel {
      input {
        border-bottom-width: 2px;
      }
    }
  }
</style>
