<template>
  <div class="psui-flex psui-flex-col psui-mt-4">
    <template v-if="getAllBuildingStockUnits !== null && getTypePrototypeSlugByTypePrototypeId != TYPE_PROTOTYPES_DB_SLUGS.ADU">
      <div
        v-if="!getIsFilteringByYear && getDrawerPath.slug !== path.NON_RESIDENTIAL_BUILDINGS.slug"
        class="w-full"
      >     
        <AssumptionsBuildingStocksInput 
          :is-input-with-edit-icon="true"
          label="Existing units"
          :filters="getBuildingStockFilters"
        />
      </div>
      <div 
        v-if="getDrawerPath.slug !== path.NON_RESIDENTIAL_BUILDINGS.slug"
        class="w-full flex justify-between items-center mb-2"
      >
        <p class="psui-text-small psui-text-gray-60">
          Annual growth rate
        </p>
        <PsInput
          id="ps-input-annual-growth"
          class="building-estimates-input"
          layout="mini"
          :active="isNotDefaultBuildingEstimates"
          :value="getGrowthRate"
          @input="onInputGrowthRate"
          @change="onChangeGrowthRate"
          @blur="onBlurGrowthRate"
          @mouseenter="onMouseEnter"
          @mouseleave="onMouseLeave"
        >
          <template #append>
            <PsTooltip
              v-if="showResetButtonAnnualGrowth"
              class="stock-input"
            >
              <template #trigger>
                <i
                  class="material-icons  psui-text-gray-40 psui-cursor-pointer hover:psui-text-blue-60 " 
                  @click="resetToDefault"
                >
                  restart_alt
                </i>
              </template>
              <template #content>
                Reset to default
              </template>
            </PsTooltip>
            <!-- <i 
              v-else
              class="material-icons-round"
            >percent</i> -->
          </template>
        </PsInput>
      </div>
    </template>
    <div class="w-full flex justify-between items-center">
      <p class="psui-text-small psui-text-gray-60">
        {{ unitMeasurement }}
      </p>
      <PsInput
        id="ps-input-units"
        :data-test-id="`input-assumption-building-estimates-${type_prototype_slug}`"
        :item="`item-${type_prototype_slug}`"
        class="building-estimates-input"
        layout="mini"
        :active="isNotDefaultBuildingEstimates"
        :value="getAllBuildingEstimatesUnitsFormated"
        @input="onInputBuildingEstimates"
        @change.prevent="onChangeBuildingEstimatesUnits"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
      >
        <template
          #append
        >
          <PsTooltip
            v-if="showResetButton"
            class="stock-input"
          >
            <template #trigger>
              <i
                class="material-icons psui-text-gray-40 psui-cursor-pointer" 
                @click="resetToDefault"
              >
                restart_alt
              </i>
            </template>
            <template #content>
              Reset to default
            </template>
          </PsTooltip>
          <span
            v-else
            class="psui-absolute psui-text-xsmall psui-text-gray-40 psui-cursor-text psui-right-0 psui-width-0 psui-height-0 psui-not-italic psui-pr-2"
          >
            {{ unitMeasurement == 'Square feet' ? 'ft&sup2;' : '' }}
          </span>
        </template>
      </PsInput>
    </div>
  </div>
</template>

<script>
import AssumptionsBuildingStocksInput from './AssumptionsBuildingStocksInput.vue'
import { BUILDING_ESTIMATES_BASE_YEARS, ASSUMPTIONS_PATHS } from '@/modules/app/assumptions/shared/enums'
import { MathHelpers } from '@igortrindade/lazyfy'
import { TYPE_PROTOTYPES_DB_SLUGS } from '@/util/Enums.js'
import { formatNumberExtended } from '@/formatters/formatNumberExtended'
import formatNumber from '@/formatters/formatNumber'


export default {
  name: 'AssumptionsBuildingEstimatesInput',
  components: { AssumptionsBuildingStocksInput },
  props: {
    unitMeasurement: {
      type: String,
      default: 'New units'
    },
    filters: {
      type: Object,
      required: true,
    },
    jurisdiction: {
      type: Object
    },
    type_prototype_slug:{
      type: String
    }
  },
  data(){
    return {
      userInputUnitsRaw: false,
      userInputGrowthRateRaw: false,
      userInputGrowthRateAsItIs: '',
      hasEditOnInputGrowthRate: false,
      years: BUILDING_ESTIMATES_BASE_YEARS,
      path: ASSUMPTIONS_PATHS,
      isHovering:{"ps-input-annual-growth": false, "ps-input-units": false},
      TYPE_PROTOTYPES_DB_SLUGS
    }
  },
  computed: {
    getIsFilteringByYear() {
      return this.filters?.year && !Array.isArray(this.filters.year)
    },
    getTypePrototypeIds() {
      const type_prototypes_ids = Array.isArray(this.filters.type_prototype_id) ? this.filters.type_prototype_id : [this.filters.type_prototype_id]
      return this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototypes']({ id: type_prototypes_ids })
        .map(type_prototype => type_prototype.id)
    },
    getBuildingStockFilters() {
      const filters = { 
        type_prototype_id: this.getTypePrototypeIds 
      }
      if(this.filters.climate_zone_prefix) {
        filters.climate_zone_prefix = this.filters.climate_zone_prefix
      }
      return filters
    },
    getAllBuildingStocks() {
      return this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocks'](this.getBuildingStockFilters)
    },
    getDefaultBuildingEstimatesUnits(){
      return this.$store.getters['assumptions/buildingEstimates/getterDefaultJurisdictionBuildingEstimateUnits'](this.filters)
    },
    getAllBuildingStockUnits() {
      return this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits'](this.getBuildingStockFilters)
    },
    getAllBuildingEstimates() {
      return this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimates'](this.filters)
    },
    getAllBuildingEstimatesUnits() {
      return this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits'](this.filters)
    },
    getGrowthRate() {
      if(this.hasEditOnInputGrowthRate) return this.userInputGrowthRateAsItIs
      let baseUnitsValue = this.getBuildingEstimatesUnitsBasedOnYear()
      const yearsRange = this.getYearsRange()
      let totalUnitsValue = this.getAllBuildingEstimatesUnits / yearsRange
      let growthRate = MathHelpers.getPercentageOfAmount(baseUnitsValue, totalUnitsValue, false)
      
      let isGrowthRateNaN = isNaN(growthRate) 
      let isGrowthRateInfinity = !isFinite(growthRate) && !isGrowthRateNaN

      if(isGrowthRateInfinity){
        return '∞%'
      } else if (isGrowthRateNaN) {
        return '0.00%'
      } else {
        return formatNumberExtended(growthRate, 2) + '%'
      }
    },
    getAllBuildingEstimatesUnitsFormated(){
      return this.vueNumberFormat(this.userInputUnitsRaw, { prefix: '', acceptNegative: false, isInteger: false, precision: 0 })
    },
    getDrawerPath(){
      return this.$store.state.assumptions.drawerOptions.path
    },
    unitsFormated() {
      return this.vueNumberFormat(this.userInputUnitsRaw, { prefix: '', acceptNegative: false, isInteger: false, precision: 0 })
    },
    isNotDefaultBuildingEstimates() {
      return formatNumber(this.getDefaultBuildingEstimatesUnits) != formatNumber(this.getAllBuildingEstimatesUnits)
    },
    showResetButton() {
      return this.isNotDefaultBuildingEstimates && this.isHovering["ps-input-units"]
    },
    showResetButtonAnnualGrowth() {
      return this.isNotDefaultBuildingEstimates && this.isHovering["ps-input-annual-growth"]
    },
    getPolicyId() {
      return (this.$route.params.policy_id) ? this.$route.params.policy_id : null
    },
    getTypePrototypeSlugByTypePrototypeId(){
      return this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({id: this.filters.type_prototype_id}).slug
    }
  },
  watch:{

    getAllBuildingEstimatesUnits: {      
      handler(value) {
        this.userInputUnitsRaw = value 
      },
      immediate: true
    },
  },
  methods: {
    
    resetToDefault() {
      this.$store.dispatch('assumptions/buildingEstimates/clearCustomBuildingEstimates', this.getAllBuildingEstimates)
      this.isHovering["ps-input-annual-growth"] = false
      this.isHovering["ps-input-units"] = false
    },

    onChangeBuildingStockUnits(e) {
      this.$store.dispatch('assumptions/buildingStocks/addOrUpdateCustomBuildingStocks', { to: e.target.value, from: this.buildingStockUnits, custom_building_stocks: this.getAllBuildingStocks })
    },

    onChangeBuildingEstimatesUnits() {
      const to = this.userInputUnitsRaw
      const from = this.getAllBuildingEstimatesUnits      
      this.$store.dispatch('assumptions/buildingEstimates/addOrUpdateCustomBuildingEstimates', { custom_building_estimates: this.getAllBuildingEstimates, to, from })
    },

    onInputBuildingEstimates($event) {
      this.userInputUnitsRaw = parseFloat($event.target.value.replace(/\D+/g, '')) || 0
    },
    
    // @TODO - Improve pattern on how this.filters.years is passed
    getYearsRange() {     
      if (!this.filters.year) {
        return BUILDING_ESTIMATES_BASE_YEARS.length
      } else if (this.filters.year?.length) {
        return this.filters.year.length
      } else if (!isNaN(this.filters.year)){
        // It's been passed as the year like "2023"
        return 1
      } else {
        throw new Error('this.filters.year type not recognized')
      }
    },
    
    onInputGrowthRate($event) {
      this.userInputGrowthRateAsItIs = $event.target.value
      this.userInputGrowthRateRaw = parseFloat(this.userInputGrowthRateAsItIs)
      this.hasEditOnInputGrowthRate = true
    },

    onBlurGrowthRate(){
      this.onChangeGrowthRate()
    },

    onChangeGrowthRate() {
      if(!this.hasEditOnInputGrowthRate) return
      const baseUnitsValue = this.getBuildingEstimatesUnitsBasedOnYear()
      const yearsRange = this.getYearsRange()

      this.userInputUnitsRaw = MathHelpers.getAmountOfPercentage(baseUnitsValue, this.userInputGrowthRateRaw) * yearsRange
      this.onChangeBuildingEstimatesUnits()
      this.hasEditOnInputGrowthRate = false
    },

    onMouseEnter(event){
      const getHtmlElementId = event.target.parentNode.id
      if(getHtmlElementId == "ps-input-units") this.isHovering["ps-input-units"] = true 
      if(getHtmlElementId == "ps-input-annual-growth") this.isHovering["ps-input-annual-growth"] = true 
    },

    onMouseLeave(event){
      const getHtmlElementId = event.target.parentNode.id
      if(getHtmlElementId == "ps-input-units") this.isHovering["ps-input-units"] = false 
      if(getHtmlElementId == "ps-input-annual-growth") this.isHovering["ps-input-annual-growth"] = false
    },

    getBuildingEstimatesUnitsBasedOnYear(currentYear = null) {
      const filters = { ...this.filters }
      let baseValue = this.getAllBuildingStockUnits
      let year = currentYear || this.filters.year
      if(year) {
        year = [ ...this.years ].splice(0, this.years.indexOf(year))
        filters.year = year
        const aggregatedBuildingEstimateUnits = this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits'](filters)
        baseValue += aggregatedBuildingEstimateUnits
      }
      return baseValue
    }

  },
}
</script>

<style lang='scss' scoped>
input {
  max-width: 100px;
}

.building-estimates-input {
  i {
    font-size: 12px;
    right: 8px;
    line-height: 120%;
    color: #798490;
  }
}

  ::v-deep .psui-el-tooltip.stock-input {
    .psui-el-tooltip-wrapper {
      .psui-el-tooltip-dialog {
        min-width: 20px;
        width: fit-content;
      }
    }
  }

</style>
