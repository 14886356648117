<template>
  <BaseModal
    ref="baseModal"
    :hierarchy="MODAL_HIERARCHY.SECONDARY"
    :title="title"
    :disable-close="true"
  >
    <div v-html="description" />
    <div class="actions flex w-full flex-col bg-white rounded-6 psui-text-p mt-6 psui-shadow-elevation-5 cursor-pointer">
      <div
        v-for="(button, index) in buttons"
        :key="index"
        class="p-4 psui-text-gray-80 flex items-center font-bold justify-between border-b psui-border-gray-10"
        @click="execute(button, index)"
      >
        <div class="flex items-center w-full">
          <span>{{ button.title }}</span>
        </div>  
        <PsIcon
          icon="arrow_forward_ios"
          size="15"
          color="psui-text-gray-50"
        />
      </div>
    </div>
    <div 
      v-if="checkbox"
      class="flex items-center mt-6"
    >
      <PsCheckboxSimple
        :label="checkbox.label"
        :checked="doNotShowModalAgain"
        @change="doNotShowModalAgain = !doNotShowModalAgain"
      />
    </div>  
  </BaseModal>
</template>

<script>
import { MODAL_HIERARCHY } from '@/util/Enums.js'

export default {
  name: 'OptionsModal',
  data:()=>({
    MODAL_HIERARCHY,
    doNotShowModalAgain: false,
    title:null,
    description:null,
    buttons: null,
    checkbox: null,
  }),
  mounted(){
    this.$eventBus.$on('openOptionsModal',({ title, description, buttons, checkbox })=>{
      this.title = title
      this.description = description
      this.buttons = buttons
      this.checkbox = checkbox
      this.doNotShowModalAgain = false

      if(checkbox?.neverAskUserProp) {
        const userPreference = this.$store.getters['getterUserPrefferenceByPath'](`${this.checkbox?.neverAskUserProp}`)
        if (userPreference !== null && !isNaN(userPreference) && buttons?.[+userPreference]) {
          this.execute(buttons[userPreference], +userPreference)
          return 
        } 
      }
      
      this.$refs.baseModal.showModal = true
    })
  },
  beforeDestroy(){
    this.$eventBus.$off('openOptionsModal')
  },
  methods:{
    execute(button, index) {
      button.action()
      this.$refs.baseModal.showModal = false
      if (this.doNotShowModalAgain == true && button.ignoreDontShowAgain !== true) {
        this.$store.dispatch('setUserPreferences', { payload: { path: `${this.checkbox?.neverAskUserProp}`, value: index.toString() } })
      } 
    },
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .modal-dialog {
    max-width: 440px;
    background: #E6ECF2;
    border-radius: 6px;
    padding: 32px;
    h4 {
      color: #28323B !important;
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;
    }
  }

  .actions div:last-of-type {
    border: 0;
  }
</style>