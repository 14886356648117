var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-header psui-px-8 psui-pt-3"},[_c('resize-observer',{on:{"notify":_vm.onResize}}),_c('div',{ref:"appHeaderTop",staticClass:"app-header-top",class:{ 
      'has-no-space' : !_vm.appHeaderTopHasSpace, 
      'reverse' : !_vm.appHeaderTopHasSpace && _vm.reverseTopOnNoSpace 
    }},[_c('resize-observer',{on:{"notify":_vm.onResize}}),_c('div',{staticClass:"app-header-top-left"},[_vm._t("top-left",function(){return [_c('div',{staticClass:"psui-flex psui-items-center psui-flex-shrink-0 psui-w-auto psui-mr-auto psui-justify-self-start"},[_c('h1',{staticClass:"psui-text-h4 psui-text-gray-80 psui-font-bold psui-mr-2 psui-flex-shrink-0"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.hasJurisdictionSelection)?_c('JurisdictionSelect',{attrs:{"data-test-id":"base-app-header-jurisdiction-select","should-scroll":false,"input-class":'psui-text-h5',"jurisdiction-selected":_vm.lastJurisdictionVisited},on:{"closeInput":function($event){_vm.showInput = false},"change":function($event){return _vm.$emit('on-jurisdiction-select', $event)}}}):_vm._e()],1)]})],2),_c('div',{staticClass:"app-header-top-right"},[_vm._t("top-right")],2)],1),_c('div',{ref:"appHeaderBottom",staticClass:"app-header-bottom psui-mt-auto psui-pt-1",class:{ 
      'has-no-space' : !_vm.appHeaderBottomHasSpace,
      'reverse' : !_vm.appHeaderBottomHasSpace && _vm.reverseBottomOnNoSpace
    }},[_c('div',{staticClass:"app-header-bottom-left"},[_vm._t("bottom-left")],2),_c('div',{staticClass:"app-header-bottom-right"},[_vm._t("bottom-right")],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }