<template>
  <div 
    class="vintage-card w-full h-auto flex flex-col justify-center items-start space-y-1 rounded-md border border-dashed border-deepsky-400 px-6 py-2 relative"
    :class="[disabled ? 'disabled opacity-50 pointer-none cursor-pointer' : 'cursor-pointer']"
    :data-test-id="disabled ? 'policy-container-vintage-button-disabled' : 'policy-container-vintage-button-enabled'"
    @click="storeClearCustomCombination()"
  >
    <div class="psui-flex items-center">
      <h4 class="psui-shrink-0 whitespace-nowrap text-14 font-bold text-deepsky-800 mr-2">
        {{ type_vintage.title }}
      </h4>
      <div class="add psui-flex psui-items-center text-sm border rounded border-deepsky-300 text-deepsky-450 px-2 font-bold transition-all">
        <feather
          type="plus"
          size="14"
        /> Add requirements
      </div>
    </div>
  </div>
</template>

<script>

import PolicyExistingBuildingsWithFlexiblePathRequirements from '@/modules/app/policy/show/requirements/requirements-drawer/PolicyExistingBuildingsWithFlexiblePathRequirements.vue'

export default {
  name: 'ExistingBuildingsPolicyScopeEmptyCell',
  props: {
    type_prototype_id : {
      type: Number,
      required: true
    },
    type_vintage: {
      type: Object,
      required: true
    },    
    climate_zone: {
      type: Object,
      required: true
    },
    policyContainer: {
      type: Object,
      required: true
    }
  },  
  data() {
    return {
      isLoading : false
    }
  },
  computed: {
    disabled() {
      return this.isLoading || !this.userCanEditPolicy || !this.getLatestPublishedStudWithValidPrototypeAndVintageByTypePrototypeTypeVintageAndStudyType({ type_prototype_id: this.type_prototype_id, type_vintage: this.type_vintage})
    },
    userCanEditPolicy() {
      return this.$store.getters['policy/getterUserCanEditPolicy'](this.policyContainer.policy_id)
    },
    getPolicy() {
      return this.$store.getters['policy/getterUserPolicy']({ id: this.policyContainer.policy_id })
    },
    customCombinations() {
      return this.policyContainer.custom_combinations ?? []
    },
  },
  methods: {
    getCustomCombination(type_vintage_id, climate_zone_raw) {
      const vintage_ids_from_type_vintage = this.$store.getters['globalEntities/Vintage/getterGlobalVintages']({ type_vintage_id : type_vintage_id }).map(v => v.id)
      return this.customCombinations.findInArray({ climate_zone_raw, vintage_id: vintage_ids_from_type_vintage  })
    },
    storeClearCustomCombination() {
      if (!this.userCanEditPolicy) {
        this.$toast.info("This shared policy doesn’t have any requirements yet. Duplicate it to add requirements and explore policy options.")
        return
      } 
      if (!this.getLatestPublishedStudWithValidPrototypeAndVintageByTypePrototypeTypeVintageAndStudyType({ type_prototype_id: this.type_prototype_id, type_vintage: this.type_vintage})) return 
      
      if (this.isLoading) return

      this.isLoading = true
      const { prototype, vintage } = this.getLatestPublishedStudWithValidPrototypeAndVintageByTypePrototypeTypeVintageAndStudyType({ type_prototype_id: this.type_prototype_id, type_vintage: this.type_vintage})
      const matchCustomCombination = this.getCustomCombination(vintage.type_vintage_id, this.climate_zone.raw)
      if (matchCustomCombination) {
        this.openPolicyEditCustomCombination(matchCustomCombination.id, matchCustomCombination.climate_zone_raw)
        this.isLoading = false
        return
      }

      const payload = {
        policy_id: this.policyContainer.policy_id,
        jurisdiction_id:  this.getPolicy.jurisdiction_id,
        policy_container_id: this.policyContainer.id,
        climate_zone_raw: this.climate_zone.raw,
        prototype_id: prototype.id,
        vintage_id: vintage.id
      }

      this.$store.dispatch('policy/saveCustomCombinationsToPolicy', [ payload ])
        .then((custom_combinations ) => {
          const { id : custom_combination_id, climate_zone_raw } = custom_combinations[0]
          if (custom_combination_id) {
            this.openPolicyEditCustomCombination(custom_combination_id, climate_zone_raw)
            this.isLoading = false
          } else {
            throw new Error()
          }
        })
        .catch((err) => {
          this.isLoading = false
          this.$appToast({ message: err?.response?.data?.message ?? 'Sorry, try again later' })
        })
    },

    openPolicyEditCustomCombination(custom_combination_id, climate_zone_raw) {
      this.$eventBus.$emit('openDrawerPolicyEditRequirements', { 
        component_name: PolicyExistingBuildingsWithFlexiblePathRequirements.name , 
        climate_zone_raw,
        custom_combination_id,
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .vintage-card:not(.disabled):hover {
    .add {
      border-color: #82C0D9;
      background-color: #82C0D9;
      color: white;
    }
  }
</style>