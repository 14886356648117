<template>
  <div 
    v-feature-flag="['PS_TEMPLATE_DESIGN']"  
    class="psui-w-full psui-mb-0"
  >
    <p 
      class="psui-px-5 psui-text-blue-20 psui-transition psui-delay-300 psui-hidden md:psui-block"
    >
      Find more reach code resources at 
      <a
        href="https://localenergycodes.com"
        class="psui-text-blue-50 hover:psui-text-blue-40 psui-font-bold"
        target="_blank"
        title="Statewide Reach Codes Program"
      >
        LocalEnergyCodes.com
      </a>
    </p>

    <a
      href="https://localenergycodes.com"
      class="psui-text-blue-20 hover:psui-text-blue-40 psui-font-bold psui-px-3 md:psui-px-5 psui-flex psui-justify-center"
      :class="stateSidebar ? 'psui-hidden' : 'psui-block md:psui-hidden'"
      target="_blank"
      title="Statewide Reach Codes Program"
    >
      <svg 
        width="25" 
        height="25" 
        viewBox="0 0 25 25" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
      >
        <path 
          fill-rule="evenodd" 
          clip-rule="evenodd" 
          d="M24.0996 12.4026C24.0996 19.03 18.727 24.4026 12.0996 24.4026C5.47219 24.4026 0.0996094 19.03 0.0996094 12.4026C0.0996094 5.77517 5.47219 0.402588 12.0996 0.402588C18.727 0.402588 24.0996 5.77517 24.0996 12.4026ZM12.3605 2.16343L18.5842 9.5819C18.5842 9.5819 21.4048 12.6732 21.4048 14.9904C21.3721 16.1791 20.8853 17.31 20.0444 18.1509C19.2036 18.9917 18.0726 19.4785 16.884 19.5112C15.5184 19.5112 14.7274 19.2082 14.1621 18.9916C13.8479 18.8712 13.6034 18.7776 13.3687 18.7776C12.712 18.7776 12.8274 20.9415 14.18 22.0234H10.625C11.2818 21.3282 13.5227 15.533 13.5227 11.3591C13.5284 8.8693 13.1513 6.39344 12.4048 4.01821C12.5309 6.35094 12.4655 8.69008 12.2092 11.0121C11.5524 16.3436 10.3929 18.7013 10.3544 18.7782C9.58547 19.1545 8.73717 19.34 7.88135 19.3189C5.75591 19.3189 3.36048 17.7347 3.36048 15.0302C3.36048 12.7117 6.18113 9.62103 6.18113 9.62103L12.3605 2.16343Z" 
          fill="currentColor"
        />
      </svg>
    </a>
  </div>
</template>

<script>
import { isFeatureFlagEnabled } from '@/util/Functions.js'

export default {
  name: 'StateProgramReferralText',
  props: {
    stateSidebar: Boolean,
  },
  data: () => ({    
    isFeatureFlagEnabled,    
  })
}
</script>

<style lang="scss" scoped>
  p {
    color: white;
    font-size: 11px;
    line-height: 130%;

    a {
      text-decoration: none;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }
</style>