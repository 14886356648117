import toggleColumnIsActive from '@/business-logic/services/columns/toggleColumnIsActive'
import moveColumnItem from '@/business-logic/services/columns/moveColumnItem'
import moveColumnGroup from '@/business-logic/services/columns/moveColumnGroup'
import { initialState } from './state'

export default {
  setShowComparisonJurisdictionSelect:(state, payload)=>{
    state.showComparisonJurisdictionSelect = payload
  },
  setAvailableJurisdictions(state, jurisdictions) {
    state.availableJurisdictions = [ ... jurisdictions ]
  },

  toggleColumnIsActive: toggleColumnIsActive,

  moveColumnItem,
  
  moveColumnGroup,

  setAssumptionColumns(state, { different, shared }) {
    let existingColumns = {...state.comparisonColumns.existingBuildings}
    const differentAssumptionsGroup = existingColumns.columnGroups.find((columnGroup) => columnGroup.key === 'different_assumptions')
    const sharedAssumptionsGroup = existingColumns.columnGroups.find((columnGroup) => columnGroup.key === 'shared_assumptions')
    const remainColumnGroups = existingColumns.columnGroups.filter((columnGroup) => !['shared_assumptions', 'different_assumptions'].includes(columnGroup.key))
    
    const sharedColumnsWithCurrentState = shared.map((column) => { 
      const stateColumn = sharedAssumptionsGroup.columns.find((obj) => obj.key === column.key)
      column.isActive = stateColumn?.isActive ?? false
      return column 
    })    
    sharedAssumptionsGroup.columns = sharedColumnsWithCurrentState

    let columnGroups = [ sharedAssumptionsGroup, ...remainColumnGroups ]
    if ( differentAssumptionsGroup ) {
      const differentColumns = different.map((column) => { column.isActive = true; return column })
      differentAssumptionsGroup.columns = differentColumns

      if(differentAssumptionsGroup.columns?.length ) {
        columnGroups.unshift(differentAssumptionsGroup)
      }
    }

    existingColumns = {
      ...existingColumns, 
      columnGroups: columnGroups 
    }

    state.comparisonColumns = {...state.comparisonColumns, existingBuildings : existingColumns }
  },

  setUserComparisons(state, comparisons) {
    state.userComparisons = comparisons
  },

  removeUserComparison(state, { id }) {
    state.userComparisons.removeInArray({ id })
  },

  // eslint-disable-next-line no-unused-vars
  resetComparisonState(state){
    state = {...(initialState() || {})}
  },
}