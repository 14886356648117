<template>
  <PsAccordionItem
    :title="'Active Policy Duration' | policyTextRephrase"
    class="display-impact-assumption-icon"
    :class="{'form-dirty':hasValueUpdated(getSingleInputValue(assumption.key), assumption.key) && tabSelected.slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS}"
    :disabled="tabSelected.slug === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS"
  >
    <template #header-additionals>
      <PsIcon
        icon="help_outline"
        size="16"
        display="flex"
        class="help psui-items-center psui-h-5 hover:psui-text-blue-60"
        :icon-classes="hasValueUpdated(getSingleInputValue(assumption.key), assumption.key) ? 'mr-5': ''"
        @click.native.stop="$eventBus.$emit('openDescriptionModal', { slug: 'impact_active_policy_duration_assumption', ...descriptionModelObject })"
      />
    </template>
    <template #default>
      {{ 'We assume the policy requirements will remain in effect for a period of:' | policyTextRephrase }}

      <PsInput
        class="mt-4/5 w-1/2"
        layout="mini"
        :data-test-id="`input-assumption-${assumption.key}`"
        :has-error="inputErrorIndex === assumption.key ? 'Insert a valid value' : false"
        :hint="`${activePolicyMinimunValue} - ${activePolicyMaximunValue}`"
        :class="{'selected': hasValueUpdated(getSingleInputValue(assumption.key), assumption.key) && inputErrorIndex !== assumption.key}"
        :value="getSingleInputValue(assumption.key)"
        @click.native="checkIfUserCanEditAssumption"
        @input="onInputAvg($event.target.value, assumption.key)"
        @change="onChangeAvg(assumption.key)"
        @blur="onBlurAvg(assumption.key)"
        @mouseenter="onMouseEnter(assumption.key)"
        @mouseleave="onMouseLeave()"
      >
        <template #append>
          <span
            v-if="!hasValueUpdated(getSingleInputValue(assumption.key), assumption.key) || !checkShowResetButton(assumption.key)"
            class="psui-text-xsmall psui-text-gray-50"
          >
            Years
          </span>
          <PsTooltip v-else-if="checkShowResetButton(assumption.key)">
            <template #trigger>
              <PsIcon 
                icon="restart_alt"
                icon-classes="psui-text-green-20 cursor-pointer mt-1"
                size="17"
                @click.native="restoreAssumptionToDefault(assumption.key)"  
              />
            </template>
            <template #content>
              <h1 class="mb-1">
                Restore to default value
                {{ assumption.key }}
              </h1>
              <span class="psui-font-normal">{{ activePolicyDurationBaseValue }}</span>
            </template>
          </PsTooltip>
        </template>
      </PsInput>
    </template>
  </PsAccordionItem>
</template>

<script>
import { defaultExistingBuildingsImpactAssumptionValues } from '@/models/ImpactAssumptions'
import { STUDY_TYPES_DB_SLUGS } from '@/store/global-entities/StudyType.js'
import { ASSUMPTIONS_DRAWER_DIVISION }from '@/util/Enums.js'
import AssumptionImpactsMixin from './AssumptionImpactsMixin'

export default {
  name: 'AssumptionsImpactsActivePolicyDuration',
  props:['tabSelected','userCanEditPolicy', 'descriptionModelObject'],
  mixins: [ AssumptionImpactsMixin ],
  data: () => ({
    activePolicyDurationBaseValue : defaultExistingBuildingsImpactAssumptionValues.active_policy_duration,
    STUDY_TYPES_DB_SLUGS,
    localActivePolicyDurationAssumption: null,
    activePolicyMinimunValue: 0.25,
    activePolicyMaximunValue: 60,
    diaglogShouldBeIgnored: true,
    key: null,
    assumption: {
      drawer_division_type: ASSUMPTIONS_DRAWER_DIVISION.BASIC,
      key: 'active_policy_duration',
    }
  }),
  
  computed: {
    getErrorMessage(){
      return `Choose a value between ${this.activePolicyMinimunValue} and ${this.activePolicyMaximunValue}`
    }
  },

  methods: {
    validateInput(value){ 
      return value <= this.activePolicyMaximunValue && value >= this.activePolicyMinimunValue
    }
  }
}
</script>

<style lang="scss" scoped>
  .psui-el-input.status-resting.layout-mini, .psui-el-input {
    width: 140px;
    &.selected {
      ::v-deep .psui-el-input-wrapper {
        border-color: #5DB883;
      }
    }

    ::v-deep.psui-el-tooltip-dialog {
      width: fit-content;
      .psui-el-tooltip-content {
        border-radius: 6px;
        background-color: #D6DDE5;
        color: #28323B;
        padding: 10px 16px; 
      }
    }
  }

  ::v-deep .psui-el-input.status-resting.layout-mini.selected {
    .psui-el-input-wrapper {
      border-color: #5DB883;
    }
  }

  ::v-deep .psui-el-input.status-error.layout-mini {
    .psui-el-input-wrapper {
      .psui-el-input-append {
        display: none;
      }
    }
  }

  .psui-el-accordion-item {
    &.status-opened {
      .help {
        color: #318FAC;
      }
    }
  }

</style>
