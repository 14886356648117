<template>
  <div class="psui-w-full">
    <PolicyShowContentHeader
      :title="'Policy Documents' | policyTextRephrase"
      :subtitle="getSubtitle | policyTextRephrase"
    >
      <template #right>
        <PsButton 
          label="Download All"
          icon="download"
          size="small"
          layout="ghost"
          :icon-position="getPolicyNewOrExisting === 'existing' ? 'right' : 'left'"
          :loading="isDownloadingAll"
          @click="downloadAll()"
        />
      </template>
    </PolicyShowContentHeader>

    <div
      class="downloads-list psui-w-full psui-bg-white psui-rounded-lg psui-flex psui-flex-col psui-px-6 divide-y psui-divide-gray-20 psui-pb-2"
    >
      <div class="downloads-list-item psui-flex psui-flex-col md:psui-flex-row psui-items-start psui-justify-between psui-py-4">
        <div class="psui-mb-3 md:psui-mb-0">
          <p class="psui-text-p psui-font-bold psui-text-gray-70 psui-mb-1">
            Cost-Effectiveness Evidence
          </p>
          <p class="psui-text-p psui-text-gray-50">
            {{ 'PDF summary of the state-wide study results that support the cost-effectiveness of this policy.' | policyTextRephrase }}
          </p>
        </div>
        <div>
          <PsButton 
            label="Download"
            icon="download"
            size="small"
            :icon-position="getPolicyNewOrExisting === 'existing' ? 'right' : 'left'"
            :loading="isDownloadingPolicyCostEffectivenessEvidencePdf"
            @click="generatePolicyCostEffectivenessEvidencePdf"
          />
        </div>
      </div>
      
      <div class="downloads-list-item psui-flex psui-flex-col md:psui-flex-row psui-items-start psui-justify-between psui-py-4">
        <div class="psui-mb-3 md:psui-mb-0">
          <p class="psui-text-p psui-font-bold psui-text-gray-70 psui-mb-1">
            Requirements Summary
          </p>
          <p class="psui-text-p psui-text-gray-50">
            {{ 'A summary of all requirements in the policy for each building type.' | policyTextRephrase }}
          </p>
        </div>
        <div>
          <PsButton 
            v-if="getPolicyNewOrExisting === 'new'"
            label="Download"
            size="small"
            icon="download"
            :loading="isDownloadingPolicyRequirementsSummaryPdf || isDownloadingAll"
            @click="downloadRequirementsSummary()"
          />

          <PsButton 
            v-else-if="getPolicyNewOrExisting === 'existing'"
            label="Download"
            size="small"
            icon="download"
            icon-position="right"
            :loading="isDownloadingPolicyRequirementsSummaryPdf || isDownloadingFlexibleCompliancePdf || isDownloadingAll"
            @click="downloadPdf()"
          />
        </div>
      </div>

      <div class="downloads-list-item psui-flex psui-flex-col md:psui-flex-row psui-items-start psui-justify-between psui-py-4">
        <div class="psui-mb-3 md:psui-mb-0">
          <p class="psui-text-p psui-font-bold psui-text-gray-70 psui-mb-1">
            Requirements Tables
          </p>
          <p class="psui-text-p psui-text-gray-50">
            {{'XLSX table(s) of this policy’s requirements for drafting you ordinance and other policy documents.' | policyTextRephrase}}
          </p>
        </div>
        <div>
          <PsButton 
            v-if="getPolicyNewOrExisting === 'new'"
            label="Download"
            size="small"
            icon="download"
            :loading="isLoadingXls"
            :disabled="!getPolicyXlsType"
            @click="downloadRequirementsTablesXls()"
          />

          <PsButton 
            v-else-if="getPolicyNewOrExisting === 'existing'"
            label="Download"
            size="small"
            icon="download"
            icon-position="right"
            :loading="isLoadingXls || isLoadingFlexibleXls || isDownloadingAll"
            :disabled="!getPolicyXlsType"
            @click="downloadXls()"
          />
        </div>
      </div>

      <div class="downloads-list-item psui-flex psui-flex-col md:psui-flex-row psui-items-start psui-justify-between psui-py-4">
        <div class="psui-mb-3 md:psui-mb-0">
          <p class="psui-text-p psui-font-bold psui-text-gray-70 psui-mb-1">
            Model Ordinance
          </p>
          <p class="psui-text-p psui-text-gray-50">
            DOC model ordinance templates from the Reach Code Team for drafting your ordinance
          </p>
        </div>
        <div>
          <a 
            href="https://intercom.help/explorer-local-energy-codes/en/articles/6330150-model-ordinances"
            target="_blank"
          >
            <PsButton 
              label="Download"
              size="small"
              icon="open_in_new"
              :icon-position="getPolicyNewOrExisting === 'existing' ? 'right' : 'left'"
              :loading="isDownloadingAll"
            />
          </a>
        </div>
      </div>
    </div>

    <PolicyFlexiblePathDownloadModal :policy="getPolicy" />
  </div>
</template>

<script>
import PolicyShowContentHeader from '../PolicyShowContentHeader.vue'
import PolicyCostEffectivenessEvidencePdf from '@/services/pdf/PolicyCostEffectivenessEvidencePdf'
import PolicyRequirementsSummaryPdf from '@/services/pdf/PolicyRequirementsSummaryPdf'
import PolicyFlexibleCompliancePdf from '@/services/pdf/PolicyFlexibleCompliancePdf'
import PolicyDocumentsApiService from '@/services/api/PolicyDocumentsApiService'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import PolicyFlexiblePathDownloadModal from '../flexible-path/PolicyFlexiblePathDownloadModal.vue'
import CustomCombinationApiService from '@/services/api/CustomCombinationApiService'
import {ExistingBuildingsPOBuilder} from "@/business-logic/services/policy-options/builders/existing-buildings"

export default {
  name: 'PolicyDocuments',
  components: {
    PolicyShowContentHeader,
    PolicyFlexiblePathDownloadModal
  }, 
  props: ['policy','flexible_path_setup'],
  data:()=>({
    tabSelected: null,
    isDownloadingPolicyCostEffectivenessEvidencePdf : false,
    isDownloadingPolicyRequirementsSummaryPdf : false,
    isDownloadingFlexibleCompliancePdf : false,
    isLoadingXls: false,
    isLoadingFlexibleXls: false,
    isDownloadingAll: false,
    STUDY_TYPES_DB_SLUGS
  }),
  computed: {

    getPolicyNewOrExisting() {
      const study_type_slug = this.$store.getters['policy/getterPolicyStudyTypes']({ key: 'slug', policy_id: this.$route.params.policy_id })[0] ?? null
      if(!study_type_slug) return false
      if(study_type_slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS) return 'existing'
      return 'new'
    },

    getPolicyXlsType() {
      if(!this.getPolicyNewOrExisting) return false
      if(this.getPolicyNewOrExisting == 'existing') return 'ExistingResidentialComplianceXls'
      return 'NewSingleFamilyComplianceXls'
    },

    getPolicy() {
      return this.$store.getters['policy/getterUserPolicy']({ id: this.$route.params.policy_id })
    },
    getPolicyStudyType() {
      return this.$store.getters['policy/getterPolicyStudyTypes']({ key: 'slug', policy_id: this.$route.params.policy_id })[0] ?? null
    },

    getSubtitle() {
      return `Here are some documents to help ${this.policy?.jurisdiction?.type === 'City' || this.policy?.jurisdiction?.type === 'State' ? 'the' : ''} ${ this.$options.filters.editJurisdictionTitleTypeBasedOnJurisdictionType(this.policy.jurisdiction, 'title_type') } adopt this policy.`
    },
    getPolicyHasFlexiblePathSetUp() {
      return Boolean(this.flexible_path_setup?.tiers?.some((tier) => {
        return tier?.excluded_measures?.length || tier?.target_scores?.some((ts) => ts?.value != null && ts?.value > 0)
      }))
    },

  },
  methods: {
    async generatePolicyCostEffectivenessEvidencePdf() {
      if (this.isDownloadingPolicyCostEffectivenessEvidencePdf) return

      this.isDownloadingPolicyCostEffectivenessEvidencePdf = true 

      const policy = this.policy
      let presetPromises = []

      for (const container of policy.policy_containers) {
        for (const combination of container.custom_combinations) {
          const vintage_id = combination.vintage_id
          const prototype_id = combination.prototype_id
          const climate_zone_raw = combination.climate_zone_raw

          presetPromises.push(this.getPresets(vintage_id, prototype_id, climate_zone_raw))
        }
      }

      const presetsData = await Promise.all(presetPromises)

      new PolicyCostEffectivenessEvidencePdf({ policy_id: this.$route.params.policy_id, presets: presetsData, flexible_path_setup: this.flexible_path_setup })
        .then(() => {
          this.isDownloadingPolicyCostEffectivenessEvidencePdf = false
        })
        .catch((err) => {
          console.log(err)
          this.isDownloadingPolicyCostEffectivenessEvidencePdf = false
        })
    },

    downloadRequirementsTablesXls() {
      this.isLoadingXls = true
      PolicyDocumentsApiService.downloadRequirementsTableXls({ 
        type: this.getPolicyXlsType, 
        policy_id: this.$route.params.policy_id,
        policy: this.policy
      })
        .then(() => {
          this.isLoadingXls = false
        })
    },

    downloadRequirementsFlexibleTablesXls() {
      this.isLoadingFlexibleXls = true
      PolicyDocumentsApiService.downloadRequirementsTableXls({ 
        type: 'MeasureMenuXls',
        policy_id: this.$route.params.policy_id,
        policy: this.policy
      })
        .then(() => {
          this.isLoadingFlexibleXls = false
        })
    },

    downloadRequirementsSummary(is_flexible_set_up) {
      if (this.isDownloadingPolicyRequirementsSummaryPdf) return
      this.isDownloadingPolicyRequirementsSummaryPdf = true 
      new PolicyRequirementsSummaryPdf({ policy_id: this.$route.params.policy_id, policyType: this.getPolicyNewOrExisting, policy_title: this.policy.title, is_flexible_set_up }).then(() => {
        this.isDownloadingPolicyRequirementsSummaryPdf = false
      })
    },

    downloadFlexibleCompliancePdf(is_flexible_set_up) {
      if (this.isDownloadingFlexibleCompliancePdf) return
      this.isDownloadingFlexibleCompliancePdf = true 
      new PolicyFlexibleCompliancePdf({ policy_id: this.$route.params.policy_id, policyType: this.getPolicyNewOrExisting, policy_title: this.policy.title, is_flexible_set_up, flexible_path_setup: this.flexible_path_setup }).then(() => {
        this.isDownloadingFlexibleCompliancePdf = false
      })
    },

    downloadPdf(){
      const is_flexible_set_up = this.getPolicyHasFlexiblePathSetUp
      if(is_flexible_set_up) this.downloadFlexibleCompliancePdf(is_flexible_set_up)
      else this.downloadRequirementsSummary(is_flexible_set_up)
    },

    downloadXls() {
      const is_flexible_set_up = this.getPolicyHasFlexiblePathSetUp
      if(is_flexible_set_up) this.downloadRequirementsFlexibleTablesXls()
      else this.downloadRequirementsTablesXls()
    },

    downloadAll() {
      this.isDownloadingAll = true
      this.generatePolicyCostEffectivenessEvidencePdf()
      this.downloadPdf()
      this.downloadXls()

      setTimeout(() => {
        window.open('https://intercom.help/explorer-local-energy-codes/en/articles/6330150-model-ordinances', '_system', null)
        this.isDownloadingAll = false
      }, 3000)
    }, 

    async getPresets(vintageId, prototypeId, climateZoneRaw) {
      const { data } = await CustomCombinationApiService.getPresets({ vintage_id: vintageId, prototype_id: prototypeId, climate_zone_raw: climateZoneRaw })
      const normalizedPreset = (await Promise.all(Object.keys(data.presets).map(async (presetName) => {
        const prototype = this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: prototypeId })
        return {
          items: (await ExistingBuildingsPOBuilder.getNormalizedPresetMeasures(data.presets[presetName],
              prototype.study_id, prototypeId, climateZoneRaw, vintageId)),
          presetName
        }
      }))).reduce((acc, curr) => {
        acc[curr.presetName] = curr.items
        return acc
      }, {})
      return {
        vintage_id: vintageId,
        prototype_id: prototypeId,
        climate_zone_raw: climateZoneRaw,
        presets: normalizedPreset
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep {
    .psui-el-dropdown-menu-dialog-wrapper {
      width: 290px;
      margin-top: 8px;
    }

    .psui-el-button {
      &:focus {
        box-shadow: none;
      }
    }

    .menu-download {
      .psui-el-button {
        justify-content: space-between;
        
        &:not(.disabled) {
          color: #515E6A;
          min-height: 38px;

          i {
            color: #A2ACB7;
          }

          &:hover {
            color: #318FAC;
            background-color: #ECF7FB;

            i {
              color: #318FAC;
            }
          }
        }

        &.disabled {
          i {
            color: #D6DDE5;
          }
        }
      }
    }
  }
  .menu-download {
    &-helper,
    .psui-el-button {
      padding: 0.438rem 1rem 0.438rem .5rem;
    }
  }
</style>