<template>
  <BaseModal
    ref="modalSelectStudyTypeAndJurisdiction"
    :disable-close="false"
  >
    <div class="modal-jurisdiction-selection">
      <HomeStudyTypeAndJurisdictionSelect 
        icon="search"
        icon-class="psui-text-blue-60"
        label="Type your city or county’s name"
        data-test-id="modal-jurisdiction-selection-jurisdiction-select"
        :should-scroll="true"
        :jurisdiction-selected="lastJurisdictionVisited"
        :disable-button="true"
        @click="onClick"
        @change="onChange"
      />
    </div>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex'
import HomeStudyTypeAndJurisdictionSelect from '@/modules/app/home/HomeStudyTypeAndJurisdictionSelect.vue'
import RRC from '@/router/rrc'

export default {
  name: 'ModalBuildPolicyAndJurisdictionSelection',
  components:{
    HomeStudyTypeAndJurisdictionSelect
  },
  data: () => ({
    customRedirect: null,
    skipRedirect: false,
  }),
  computed: {
    ...mapGetters(['lastJurisdictionVisited']),
    getIfUserHasJurisdictionSelected(){
      return this.lastJurisdictionVisited ? false : true
    },
  },
  created() {
    this.$eventBus.$on('openModalBuildPolicyAndJurisdictionSelection', ({customRedirect, skipRedirect} = {customRedirect: null, skipRedirect: false}) => {
      this.customRedirect = customRedirect
      this.skipRedirect = skipRedirect
      this.$refs.modalSelectStudyTypeAndJurisdiction.showModal = true
    })
  },
  methods: {
    onChange(jurisdiction) {
      this.$store.dispatch('setUserLastJurisdictionAndFirstClimateZoneVisited', { value: jurisdiction, context: 'updateLastJurisdictionVisitedFromModalJurisdictionSelection' })
    },
    onClick() {
      this.$refs.modalSelectStudyTypeAndJurisdiction.showModal = false
      if (!this.skipRedirect) {
        this.$router.push({ name: this.customRedirect || 'PolicyOptions', params: { rrc: RRC.MODAL_BUILD_POLICY_AND_JURISDICTION_SELECTION_1 } })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  ::v-deep .modal-dialog {
    width: 100%;
    max-width: 760px;
    background: url('/images/home_page/home_page_background.jpg');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-color: #F3F6F9;
    background-size: 100%;
    border-radius: 6px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05), 0px 30px 60px rgba(0, 0, 0, 0.2);
    padding: 72px 32px 100px 32px;

    &-close {
      padding-right: 2rem;
    }
  }

  .modal-jurisdiction-selection {
    ::v-deep .jurisdiction--select {
      .input-wrapper {
        .width-machine {
          width: 100%;
        }
      }
    }
    
  } 
</style>
