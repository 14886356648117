<template>
  <div
    v-if="getPolicyOptionsByRoute.length"
    class="w-full"
  >
    <div class="psui-w-full psui-px-4 sm:psui-px-8 psui-mb-3">
      <p
        v-if="!isExisting"
        class="psui-text-small psui-text-gray-60 psui-mb-5"
      >
        {{ 'Explore policy options designed by the Reach Code Team for California jurisdictions.' | policyTextRephrase('small') }}
      </p>
      <p
        v-else
        class="psui-text-small psui-text-gray-60 psui-mb-5"
      >
        {{ 'Below are some example policies designed by the Reach Codes Team for existing buildings. Select one as a starting point. Regardless of your choice, you will be able to review/edit your policy requirements in the coming steps.' | policyTextRephrase('small') }}
      </p>

      <div
        v-for="policyOption in filterPolicyOptionsByTypePrototypes"
        :key="`policyoption-${policyOption.id}`"
        :data-test-id="`${policyOption.slug}-select-container`"
        :class="[{
          'psui-flex psui-flex-col psui-bg-white psui-py-4 psui-shadow-elevation-5 rounded-md psui-mb-3 policy-option-card':
            policyOption !== defaultPolicyOption,
        },{'psui-bg-blue-20':policyOption.slug == $route.query.policy_option}]"
        @click.self="openNewPolicyDrawer(policyOption, false)"
      >
        <div
          v-if="policyOption !== defaultPolicyOption"
          class="psui-flex psui-items-center psui-justify-between psui-px-4 psui-mb-2"
          @click.self="openNewPolicyDrawer(policyOption, false)"
        >
          <div class="psui-flex psui-items-center psui-space-x-2">
            <p
              class="psui-text-p psui-text-gray-70 psui-font-bold psui-text-gray-70"
              @click.self="openNewPolicyDrawer(policyOption, false)"
            >
              {{ policyOption.title }}
            </p>

            <PsTooltip
              v-if="policyOption.is_recommended"
              position="top"
              class="mr-1/2"
            >
              <template #trigger>
                <PsMiniTag
                  layout="solid-success"
                  message="recommended"
                  class="psui-text-uppercase psui-font-bold psui-cursor-default"
                />
              </template>
              <template #content>
                {{ 'The Reach Code Team recommends this policy.' | policyTextRephrase('small') }} <br>
                See 'Learn More' for details. 
              </template>
            </PsTooltip>
            <PsTooltip
              v-if="(policyOption.slug == POLICY_OPTIONS_DB_SLUGS.ELECTRIC_ONLY || policyOption.slug == POLICY_OPTIONS_DB_SLUGS.ELECTRIC_ONLY_PLUS_EFFICIENCY)"
              position="top"
              class="mr-1/2"
            >
              <template #trigger>
                <PsIcon
                  display="flex"
                  icon="info"
                  color="psui-text-yellow-70"
                  size="16"
                  class="icon"
                  @click.native="openElectricOnlyWarningPage"
                />
              </template>
              <template #content>
                Please be aware that there are ongoing legal disputes concerning mandating fuel types. <br>
                The Electric Only option provided on this platform is solely for informational purposes <br>
                and does not constitute any form of legal advice or recommendation.
              </template>
            </PsTooltip>
          </div>

          <div class="psui-flex psui-space-x-3">
            <PsButton
              label="Learn More"
              layout="ghost"
              size="small"
              icon="keyboard_double_arrow_right"
              icon-position="right"
              @click="openNewPolicyDrawer(policyOption, true)"
            />
            <PsTooltip
              position="top"
              :ignore-dialog="!policyOption.disabled_message"
            >
              <template #trigger>
                <PsButton
                  :id="`policy-options-select-button-${policyOption.slug}`"
                  label="Select"
                  layout="solid"
                  size="small"
                  icon-position="right"
                  icon="keyboard_double_arrow_right"
                  :disabled="
                    policyOption.disabled_message != null || isLoading
                      ? true
                      : false
                  "
                  :loading="isLoading"
                  @click="openNewPolicyDrawer(policyOption, false)"
                />
              </template>
              <template
                v-if="policyOption.disabled_message != null || isLoading
                  ? true
                  : false"
                #content
              >
                <div class="disabled-message-wrapper">
                  {{ policyOption.disabled_message | policyTextRephrase('small') }}
                </div>
              </template>
            </PsTooltip>
          </div>
        </div>
        <div
          v-if="policyOption !== defaultPolicyOption"
          class="psui-px-4 psui-mb-4"
          @click="openNewPolicyDrawer(policyOption, false)"
        >
          <p
            class="psui-text-small psui-text-gray-50"
            v-html="$options.filters.policyTextRephrase(policyOption.short_description,'small')"
          />
        </div>
        <div
          v-if="policyOption !== defaultPolicyOption"
          class="psui-flex psui-space-x-1 psui-px-4"
          @click="openNewPolicyDrawer(policyOption, false)"
        >
          <span
            v-for="policyOptionPrototype in getPolicyOptionPrototypesByCurrentStudy(policyOption)"
            :key="`policyoption-prototype-${policyOptionPrototype.id}`"
            class="psui-text-xsmall psui-text-gray-50 psui-py-1 psui-rounded psui-border psui-border-gray-30 px-1/2"
          >
            {{ getPolicyOptionPrototypeTitle(policyOptionPrototype) }}
          </span>
        </div>
      </div>

      <div
        v-if="filterPolicyOptionsByTypePrototypes.includes(defaultPolicyOption)"
        :data-test-id="`${defaultPolicyOption.slug}-select-container`"
        class="psui-flex psui-flex-col psui-bg-white psui-py-4 psui-shadow-elevation-5 rounded-md psui-mb-3 policy-option-card default"
        :class="{'psui-bg-blue-20':defaultPolicyOption.slug == $route.query.policy_option}"
        @click.self="openNewPolicyDrawer(defaultPolicyOption, false)"
      >
        <div
          class="psui-flex psui-items-center psui-justify-between psui-px-4 psui-mb-2"
          @click.self="openNewPolicyDrawer(defaultPolicyOption, false)"
        >
          <div class="psui-flex psui-items-center psui-space-x-2">
            <p
              class="psui-text-p psui-text-gray-70 psui-font-bold psui-text-gray-70"
              @click.self="openNewPolicyDrawer(defaultPolicyOption, false)"
            >
              {{ defaultPolicyOption.title }}
            </p>
          </div>

          <div class="psui-flex psui-space-x-3">
            <PsButton
              label="Learn More"
              layout="ghost"
              size="small"
              icon="keyboard_double_arrow_right"
              icon-position="right"
              @click="openNewPolicyDrawer(defaultPolicyOption, true)"
            />
            <PsButton
              :id="`policy-options-select-button-${defaultPolicyOption.slug}`"
              label="Select"
              layout="solid"
              size="small"
              icon-position="right"
              icon="keyboard_double_arrow_right"
              :loading="isLoading"
              @click="openNewPolicyDrawer(defaultPolicyOption, false)"
            />
          </div>
        </div>
        <div
          class="psui-px-4 psui-mb-4"
          @click="openNewPolicyDrawer(defaultPolicyOption, false)"
        >
          <p class="psui-text-small psui-text-gray-50">
            Design your own {{ getStudyTypeByRoute | formatStudyTypeSlugInStudyTypeLabel }} {{ 'policy' | policyTextRephrase('small') }} from scratch.
          </p>
        </div>
        <div
          class="psui-flex psui-space-x-1 psui-px-4"
          @click="openNewPolicyDrawer(defaultPolicyOption, false)"
        >
          <span
            v-for="policyOptionPrototype in getPolicyOptionPrototypesByCurrentStudy(defaultPolicyOption)"
            :key="`policyoption-prototype-${policyOptionPrototype.id}`"
            class="psui-text-xsmall psui-text-gray-50 psui-py-1 psui-rounded psui-border psui-border-gray-30 px-1/2"
          >
            {{ getPolicyOptionPrototypeTitle(policyOptionPrototype) }}
          </span>
        </div>
      </div>
    </div>
    <div class="psui-ml-8 psui-text-small psui-text-gray-60 flex items-center">
      <span>
        You are seeing {{ filterPolicyOptionsByTypePrototypes.length }}/{{ getPolicyOptionsByRoute.length }} {{ 'policy' | policyTextRephrase('small') }} options.
      </span>
      <button
        v-if="filterPolicyOptionsByTypePrototypes.length !== getPolicyOptionsByRoute.length"
        class="psui-text-blue-60 psui-font-bold ml-2"
        @click="removePrototypeFilter"
      >
        Remove Filters to see all.
      </button>
    </div>
  </div>
  <div
    v-else
    class="ml-7"
  >
    <vue-skeleton-loader
      :width="459"
      :height="15"
      class="mb-4"
    />
    <vue-skeleton-loader
      v-for="index in getNumberOfSkeletons"
      :key="index"
      :width="getSkeletonWidth"
      :height="120"
      class="mb-2"
    />
    <vue-skeleton-loader
      :width="203"
      :height="18" 
    />
  </div>
</template>

<script>
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import { POLICY_OPTIONS_DB_SLUGS } from '@/util/Enums.js'
import Policy from '@/models/Policy.js'
import { POLICY_OPTIONS_ALGORITHMS } from '@/business-logic/services/policy-options/factory.js'
import { sortByPolicyOptionRecommended } from "@/util/Functions.js"
import { mapGetters } from 'vuex'
import { GA_LABELS } from '@/mixins/GaEventsMixin'

export default {
  name: 'PolicyOptionsContent',
  components: {},
  filters: {
    formatStudyTypeSlugInStudyTypeLabel: function (value) {
      return value.replace(/-/g, ' ').replace('buildings', 'building')
    },
  },
  data: () => ({
    STUDY_TYPES_DB_SLUGS,
    POLICY_OPTIONS_DB_SLUGS,
    policy: new Policy(),
    isLoading: false,
  }),
  computed: {
    ...mapGetters(['lastStudyTypeVisited', 'lastJurisdictionVisited']),
    jurisdictionSelectedId() {
      return this.$store.getters?.['lastJurisdictionVisited']?.id || null
    },
    getUser() {
      return this.$store.state.auth.loggedUser
    },
    getPolicyOptions() {
      return this.$store.getters['policy/getterPolicyOptionsWithPrototypes']()
    },
    defaultPolicyOption() {
      return this.getPolicyOptionsByRoute.find((p) =>
        p?.algorithms?.some(
          (a) =>
            a?.policy_option_slug &&
            a?.policy_option_slug === (this.isExisting ? POLICY_OPTIONS_DB_SLUGS.DEFAULT_STATE_EXISTING : POLICY_OPTIONS_DB_SLUGS.DEFAULT_STATE)
        )
      )
    },
    getStudyTypeByRoute() {
      return this.$route.query.only_study_type || this.lastStudyTypeVisited  
    },
    filterPolicyOptionsByTypePrototypes() {
      return this.getPolicyOptionsByRoute.filter(policy_option => {
        const policyOptionStudyAlgorithms = policy_option.algorithms.filter((a) => a.study_type_slug === this.getStudyTypeByRoute).map((a) => a.name)
        return policy_option.prototypes.some(prototype => !this.getExcludeTypePrototypeIdsFromRoute.includes(prototype.type_prototype_id.toString()) &&
          prototype.policy_option_algorithms.some((a) => policyOptionStudyAlgorithms.includes(a)))
      }).sort((a, b) => {
        return (a?.id || 0) - (b?.id || 0)
      })
    },
    getExcludeTypePrototypeIdsFromRoute() {
      return this.$route?.query?.exclude_type_prototype_ids?.split(',') || []
    },
    getPolicyOptionsByRoute() {
      return this.getPolicyOptionsByBuildingTypeSlug(this.getStudyTypeByRoute).sort(sortByPolicyOptionRecommended)
    },
    getAllUserPolicies() {
      return this.$store.getters['policy/getterUserPolicies']()
    },
    getSkeletonWidth(){
      return window.innerWidth - 300
    },
    getNumberOfSkeletons(){
      return this.getStudyTypeByRoute == STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS ? 3 : 5
    },
    isExisting() {
      return this.getStudyTypeByRoute === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS
    }
  },
  watch: {
    filterPolicyOptionsByTypePrototypes: {
      handler: async function (filterPOByTypePrototypes) {
        await this.updateDisabledPoState(filterPOByTypePrototypes)
      },
      immediate: true,
    },
    jurisdictionSelectedId: {
      handler: async function (value) {
        if (value == null) return
        await this.updateDisabledPoState(this.filterPolicyOptionsByTypePrototypes)
      }
    }
  },
  mounted() {
    this.$store.commit('assumptions/closeDrawer')
    if(this.$route.query.policy_option) {
      this.openNewPolicyDrawer(this.defaultPolicyOption, false)
    }
  },
  methods: {
    async updateDisabledPoState(filterPOByTypePrototypes) {
      if (this.isLoading) return false
      this.isLoading = true
      for await (let policyOption of filterPOByTypePrototypes) {
        policyOption.disabled_message = await this.isDisabledText(policyOption)
      }
      this.isLoading = false
    },
    getPolicyOptionsByBuildingTypeSlug(buildingTypeSlug) {
      return (
        this.getPolicyOptions?.filter((policyOption) => {
          return policyOption?.algorithms?.some(
            (algorithm) => algorithm?.study_type_slug === buildingTypeSlug
          )
        }) || []
      )
    },
    getPolicyOptionPrototypesByCurrentStudy(policyOption) {
      const policyOptionStudyAlgorithms = policyOption?.algorithms?.filter((a) => a.study_type_slug === this.getStudyTypeByRoute)?.map((a) => a.name) || []
      return policyOption?.prototypes?.filter((prototype) => prototype.policy_option_algorithms.some((a) => policyOptionStudyAlgorithms.includes(a)))
    },
    getPolicyOptionPrototypeTitle(prototype) {
      return (
        this.$store.getters[
          'globalEntities/TypePrototype/getterGlobalTypePrototype'
        ]({ id: prototype.type_prototype_id })?.title ||
        prototype?.title ||
        'Unknown'
      )
    },
    openNewPolicyDrawer(policyOption, openOnLearn = false) {
      this.gaEvent(
        GA_LABELS.POLICY_OPTIONS_CLICK, 
        { 
          policy_option_slug: policyOption.slug,
          jurisdiction_slug: this.lastJurisdictionVisited.slug
        }
      )
      
      this.$eventBus.$emit('openPolicyOptionDrawer', {
        policy_option: policyOption,
        current_step: openOnLearn ? 0 : undefined,
        study_type_slug: this.getStudyTypeByRoute,
        policy_option_disabled_message: policyOption?.disabled_message,
      })

      this.$router.replace({
        name:'PolicyOptions', 
        query: {
          ...this.$route.query,
          policy_option: policyOption?.slug
        }
      })
    },
    removePrototypeFilter() {
      if (this.$route.query.exclude_type_prototype_ids) {
        this.$router.replace({
          name: 'PolicyOptions',
          query: { only_study_type: this.$route.query.only_study_type },
        })
      }
    },

    async isDisabledText(policyOption) {
      let PolicyOptionBuilder = null
      for (const value of Object.values(POLICY_OPTIONS_ALGORITHMS)) {
        if (value.policy_option_slug === policyOption.slug) {
          PolicyOptionBuilder = value.constructor
        }
      }
      if (PolicyOptionBuilder) {
        try {
          return await new PolicyOptionBuilder(null, this.getPolicyOptionPrototypesByCurrentStudy(policyOption).map((p) => {
            return {
              prototype_id: p.id,
              climate_zones: this.$store.getters['lastJurisdictionVisited'].climate_zones.map((cz) => cz.raw),
            }
          })).disabledText()
        } catch (error) {
          return null
        }
      }
      return null
    },
    openElectricOnlyWarningPage(){
      window.open('https://berkeleyca.gov/construction-development/green-building/building-electrification', '_blank')
    }
  },
}
</script>

<style lang="scss" scoped>
  .psui-el-button.layout-onlytext.size-small.icon-right {
    background-color: transparent;
  }
  .disabled-message-wrapper {
    max-width: 180px;
    word-wrap: break-word;
  }
  .policy-option-card {
    cursor: pointer;

    .bg-transparent {
      background-color: transparent !important;
    }
  }
</style>
