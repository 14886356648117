import { getImpactPopulationsFromUnits } from '@/business-logic/services/impact-algorithms/common/mechanics'
import { TYPE_FUELS_DB_SLUGS } from '@/util/Enums'
import dayjs from 'dayjs'

function normalizeFuelToSlug(fuel) {
  fuel = typeof fuel === 'string' ? fuel : (fuel && fuel.slug ? fuel.slug : (fuel && fuel.title ? fuel.title : null))
  const supportedSlugs = [TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC, TYPE_FUELS_DB_SLUGS.MIXED_FUEL, TYPE_FUELS_DB_SLUGS.ELECTRIFICATION]
  if (supportedSlugs.includes(fuel)) {
    return fuel
  } else if (fuel && fuel.toLowerCase().includes('mixed')) {
    return TYPE_FUELS_DB_SLUGS.MIXED_FUEL
  } else if (fuel && fuel.toLowerCase().includes('electric')) {
    return TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC
  } else if (fuel && fuel.toLowerCase().includes('electrification')) {
    return TYPE_FUELS_DB_SLUGS.ELECTRIFICATION
  }
  return null
}

export default (
  building_estimates,
  fuel,
  {
    all_electric_shares_without_policy = 25,
    all_electric_shares_with_policy = 25,
    effective_date = null,
    termination_date = null,
    installation_effects_years = 30,
    applicability_rate = 100, // Its really make sense?
    annual_penetration_rate = 100,
    delay_installation = 1,
    likelihood_installations_first_third = 0,
    likelihood_installations_second_third = 33,
    likelihood_installations_final_third = 66,
  },
  mergeElectrification = false) =>
{
  fuel = normalizeFuelToSlug(fuel)
  const years = [...new Set(building_estimates.map((be) => be.year).filter(y => y != null))].sort((a, b) => a - b)
  
  const installationsPerQuarter = []
  const sumByQuarter = (quarter, sumValues) => {
    let installation = installationsPerQuarter.find((q) => q.quarter === quarter)
    if (!installation) {
      installation = {
        quarter: quarter,
        netActiveInstallations: 0,
        netInstallationsStarted: 0,
        netInstallationsCompleted: 0,
        netInstallationsAgedOut: 0,
        accumulativeInstallationsCompleted: 0,
        accumulativeInstallationsAgedOut: 0,
      }
      installationsPerQuarter.push(installation)
    }

    Object.keys(sumValues).forEach((key) => {
      installation[key] += sumValues[key]
    })
  }

  const startEffectsDate = effective_date ? dayjs(effective_date) :
    (years.length ? dayjs(`01/01/${years[0]}`, 'MM/DD/YYYY').startOf('year') : null)
  const endEffectsDate = startEffectsDate ? startEffectsDate.clone().add(installation_effects_years, 'years') : null
  const likelihoodUpgradeYears = installation_effects_years/3
  years.forEach((year) => {
    const yearBe = building_estimates.filter((be) => be.year === year)
    const yearUnits = yearBe.reduce((acc, item) => acc + item.units, 0)
    const threePopulation = getImpactPopulationsFromUnits(yearUnits, all_electric_shares_without_policy, all_electric_shares_with_policy)
    let fuelUnits = 0
    switch(fuel) {
      case TYPE_FUELS_DB_SLUGS.MIXED_FUEL:
        fuelUnits = threePopulation.mixed_fuel_units
        break
      case TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC:
        fuelUnits = threePopulation.all_electric_units + (mergeElectrification ? threePopulation.migrating_to_all_electric_units : 0)
        break
      case TYPE_FUELS_DB_SLUGS.ELECTRIFICATION:
        fuelUnits = threePopulation.migrating_to_all_electric_units + (mergeElectrification ? threePopulation.all_electric_units : 0)
        break
    }
    fuelUnits = (fuelUnits * (annual_penetration_rate/100) * (applicability_rate/100))

    const quarterUnits = fuelUnits/4
    const startAtDate = dayjs(`01/01/${year}`, 'MM/DD/YYYY').startOf('year')
    const endAtDate = dayjs(`01/01/${year}`, 'MM/DD/YYYY').endOf('year')
    for(let currentQuarter = startAtDate; dayjs(currentQuarter).isBefore(endAtDate); currentQuarter = dayjs(currentQuarter).add(1, 'quarter')) {
      if ((effective_date && currentQuarter.clone().endOf('quarter').isBefore(dayjs(effective_date))) ||
        (termination_date && currentQuarter.clone().startOf('quarter').isAfter(dayjs(termination_date))))
      {
        continue
      }

      const installationCompletedQuarter = currentQuarter.add(delay_installation*12, 'months')
      const agedOutQuarter = dayjs(installationCompletedQuarter).add(installation_effects_years, 'year')

      // Force Start installing arrays:
      for(let installingQuarter = currentQuarter.clone(); dayjs(installingQuarter).isBefore(installationCompletedQuarter); installingQuarter = dayjs(installingQuarter).add(1, 'quarter')) {
        sumByQuarter(installingQuarter.format('MM/DD/YYYY'), {})
      }

      sumByQuarter(currentQuarter.format('MM/DD/YYYY'), {
        netInstallationsStarted: quarterUnits
      })

      sumByQuarter(installationCompletedQuarter.format('MM/DD/YYYY'), {
        netInstallationsCompleted: quarterUnits,
      })

      for(let activeQuarter = installationCompletedQuarter; dayjs(activeQuarter).isBefore(agedOutQuarter); activeQuarter = dayjs(activeQuarter).add(1, 'quarter')) {
        const diffFromEffectYears = endEffectsDate.diff(activeQuarter, 'years', true) / likelihoodUpgradeYears
        const likelihoodPercentage = diffFromEffectYears >= 2 ? likelihood_installations_first_third :
          (diffFromEffectYears >= 1 ? likelihood_installations_second_third : likelihood_installations_final_third)
        const likelihoodNormalizedUnits = quarterUnits * (100-likelihoodPercentage) / 100
        sumByQuarter(activeQuarter.format('MM/DD/YYYY'), {
          netActiveInstallations: likelihoodNormalizedUnits,
        })

      }

      sumByQuarter(agedOutQuarter.format('MM/DD/YYYY'), {
        netInstallationsAgedOut: quarterUnits,
      })
    }
  })

  installationsPerQuarter.sort((a, b) => dayjs(a).valueOf() - dayjs(b).valueOf())

  // Create accumulative results:
  installationsPerQuarter.forEach((quarter, index) => {
    const lastInfo = index === 0 ? null : installationsPerQuarter[index-1]
    quarter.accumulativeInstallationsCompleted = lastInfo ? lastInfo.accumulativeInstallationsCompleted : 0
    quarter.accumulativeInstallationsCompleted += quarter.netInstallationsCompleted
    quarter.accumulativeInstallationsAgedOut = lastInfo ? lastInfo.accumulativeInstallationsAgedOut : 0
    quarter.accumulativeInstallationsAgedOut += quarter.netInstallationsAgedOut
  })

  return installationsPerQuarter
}
