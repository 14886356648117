import studyResultsColumns from '@/business-logic/constants/studyResultsColumns'
import getSimplePayback from '@/business-logic/services/study-results/getSimplePayback'
import getOnBillCostRatio from '@/business-logic/services/study-results/getOnBillCostRatio'

export default ({ data, dataToAggregate }) => {
  for(const col of studyResultsColumns) {
    if(col == 'baseline_fuel_type') {
      data[col] = dataToAggregate[col]
      continue
    }
    if(typeof data[col] == 'undefined') {
      data[col] = parseFloat(dataToAggregate[col])
    } else {
      if(col == 'simple_payback') {
        data[col] = getSimplePayback({ initial_cost: data['initial_cost'],  annual_bill_savings: data['annual_bill_savings'] })
      } else if( ['on_bill_cost_ratio', 'on_bill_cost_ratio_2025', 'on_bill_2025_care'].includes(col)) {
        data[col] = getOnBillCostRatio({ initial_cost:  data['initial_cost'], annual_bill_savings_avg: data['annual_bill_savings_avg'], prototype_id: dataToAggregate['prototype_id'] })
      } else {
        data[col] += parseFloat(dataToAggregate[col])
      }
    }
  }
}