export const POLICIES_TAB_DESCRIPTION = {
    POLICIES_DEFAULT : [
        {title:"My Policies", pathName:"PoliciesList"}, {title:"Compare Policies", pathName:"ComparisonShow"}
    ],
    POLICIES_SHOW : [
        {title:"Scope", pathName:"scope"}, {title:"Impact and Details", pathName:"impact"}, {title:"Compliance Table", pathName:"flexible_path"}
    ]
}

export const POLICY_TYPES = {
  SHARED: { value: 'SHARED', label: 'Shared with me' },
  DRAFT: { value: 'DRAFT', label:  'Unsaved draft' },
  OWN: { value: 'OWN', label:  'Created by me' }
}

export const POLICY_ATTACH_TO_USER_RETURNS = {
    HAS_NOT_POLICIES_TO_ATTACH: 'HAS_NOT_POLICIES_TO_ATTACH',
    POLICIES_HAS_BEEN_ATTACHED: 'POLICIES_HAS_BEEN_ATTACHED',
}
