<template>
  <transition
    enter-active-class="transition ease-out duration-200 transform"
    enter-class="opacity-0 translate-x-full"
    enter-to-class="opacity-100"
    leave-active-class="transition ease-in duration-200 transform"
    leave-class="opacity-100"
    leave-to-class="opacity-0 translate-x-full"
    mode="out-in"
  >
    <div 
      v-if="checkDrawerVisibility()"
      class="ps-tooltip-adjustments assumptions-drawer origin-right psui-fixed right-0 top-0 h-screen bg-white shadow-lg flex flex-col flex-shrink-0"
      :class="getClassesByHierarchy"
    >
      <div
        class="btn-trigger"
        @click="$store.commit('assumptions/toggleDrawer',{})"
      >
        <PsTooltip
          :ignore-dialog="!showTooltip"
          layout="gray"
          class="psui-flex psui-leading-none"
        >
          <template #trigger>
            <PsButton
              icon="chevron_right"
              size="small"
              text-color="gray-50"
            />
          </template>
          <template #content>
            <p
              v-if="showTooltip"
            >
              Save
            </p>
          </template>
        </PsTooltip>
      </div>
      <div 
        data-test-id="assumptions-drawer-scrollbar"
        class="wrapper scrolling-touch overflow-y-scroll over"
      >
        <JurisdictionWideAssumptions 
          :class="isUpdating ? 'psui-pointer-events-none opacity-60' : ''" 
        />
      </div>
    </div>
  </transition>
</template>

<script>
import JurisdictionWideAssumptions from '@/modules/app/assumptions/shared/JurisdictionWideAssumptions.vue'
import { mapGetters } from 'vuex'
import { DRAWER_HIERARCHY } from '@/util/Enums.js'

export default {
  name: 'AssumptionsDrawer',
  components: { JurisdictionWideAssumptions },
  props: {
    hierarchy: {
      type: [String],
      required: false,
      default: DRAWER_HIERARCHY.PRIMARY,
      validator: key => {
        return Object.keys(DRAWER_HIERARCHY).includes(key)
      }
    },
    contentContainer: {
      type: [Boolean, String],
      default: 'content-container'
    },
  },
  data: () => ({
    showTooltip: false,
  }),
  computed: {
    getClassesByHierarchy(){
      if(this.hierarchy === DRAWER_HIERARCHY.PRIMARY) {
        return 'psui-z-30'
      } else if(this.hierarchy === DRAWER_HIERARCHY.SECONDARY) {
        return 'psui-z-40'
      } else {
        return ''
      }
    },
    ...mapGetters('assumptions',['getterAssumptionsDrawerOptions']),
    ...mapGetters('assumptions', ['isUpdating']),
  },
  watch: {
    '$route.name': {
      immediate: true,
      handler(newPath, oldPath) {
        if (newPath != 'PolicyShow' && oldPath == 'PolicyShow') {
          this.$store.commit('assumptions/closeDrawer')
        }
      }
    }
  },
  mounted() {
    this.$eventBus.$on('watchToScrollToElement',(id) => {
        setTimeout(()=>{
          window.document.querySelectorAll(`[item=${id}]`)[1].scrollIntoView({block:'center', behavior: 'smooth'})
            setTimeout(()=>{
              window.document.querySelectorAll(`[item=${id}]`)[1].focus()
            },500)
        }, 800)
      })

    this.$eventBus.$on('openAssumptionDrawerFromAppBoot', this.openAssumptionDrawerFromAppBoot)
    this.$eventBus.$on('editAssumptionsChanged', (mutation_type) => {
      this.editAssumptionsChanged(mutation_type)
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openAssumptionDrawerFromAppBoot')
    this.$eventBus.$off('editAssumptionsChanged', this.editAssumptionsChanged)
    this.$eventBus.$off('watchToScrollToElement')
  },
  methods: {
    editAssumptionsChanged(mutation_type) {
      if(this.$route.name === 'PolicyShow' && mutation_type === 'assumptions/clearResultsImpactAssumptions') return
      this.showTooltip = true
    },
    checkDrawerVisibility() {
      this.updateContentContainerWidth()

      const allowedRoutes = ['/policies', '/jurisdiction']
      return this.getterAssumptionsDrawerOptions.visibility && allowedRoutes.filter((route) => this.$route.path.includes(route)).length
    },
    openAssumptionDrawerFromAppBoot(drawerOptions) {
      this.$store.commit('assumptions/setDrawerOptions', drawerOptions)
    },
    updateContentContainerWidth() {
      setTimeout(() => {
        if ( this.getterAssumptionsDrawerOptions.visibility ) {
          document.getElementById(this.contentContainer).style.paddingRight = '350px'
        } else {
          document.getElementById(this.contentContainer).style.paddingRight = '0px'
          this.showTooltip = false
        }
      },100)
    }
  }
}
</script>

<style lang="scss" scoped>
  .assumptions-drawer {
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 350px;

    .wrapper {
      padding-bottom: 60px;
      height: 100%;
    }
    

    .btn-trigger {
      position: absolute;
      top: 50px;
      left: -12px;

      ::v-deep .psui-el-button {
        padding: 0;
        background: white;
        border: 1px solid #79849038;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        &:hover {
          background: #318FAC;
          > i {
            color: white;
          }
        }

        > i {
          color: #798490;
          margin: 0;
        }
      }
    }
     
  }

  .ps-tooltip-adjustments {
    ::v-deep {
      .psui-el-tooltip .psui-el-tooltip-wrapper .psui-el-tooltip-dialog {
        z-index: 50;
      }
      .psui-el-tooltip .psui-el-tooltip-wrapper .psui-el-tooltip-dialog .psui-el-tooltip-content.layout-gray {
        display: flex;
        line-height: 1;
        padding: 8px 16px 8px 16px;

        .psui-el-tooltip-content-wrapper {
          margin-top: 0rem;
        }
      }
    }
  }
  
</style>
