import PolicyApiService from '@/services/api/PolicyApiService'
import ImpactAssumptions, {defaultExistingBuildingsImpactAssumptionValues, defaultNewBuildingsImpactAssumptionValues} from '@/models/ImpactAssumptions'
import { ASSUMPTIONS_DRAWER_DIVISION } from '@/modules/app/assumptions/shared/enums.js'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import { basicAssumptionKeys, advancedAssumptionKeys } from '@/modules/app/assumptions/shared/defaultAssumptions.js'
import EventBus from '@/util/EventBus'
let waitingToUpdatePolicy, waitingToUpdateAssumptions
let updatingKeys = {}

export default { 

  initBuildingsData({ dispatch, rootGetters }) {    
    if(rootGetters['lastJurisdictionVisited']?.id) {
      dispatch('assumptions/buildingStocks/getJurisdictionBuildingStocks', rootGetters['lastJurisdictionVisited']?.id, { root: true })
      dispatch('assumptions/buildingEstimates/getJurisdictionBuildingEstimates', rootGetters['lastJurisdictionVisited']?.id, { root: true })
    }
  },

  // This is going to empty the database AND the state
  resetCustomBuildings({ dispatch }) {
    dispatch('buildingStocks/clearCustomBuildingStocks')
    dispatch('buildingEstimates/clearCustomBuildingEstimates')
  },
  
  // This is going to delete ONLY the state, not the database
  clearCustomBuildings({ commit }) {
    commit('buildingStocks/clearCustomBuildingStocks')
    commit('buildingEstimates/clearCustomBuildingEstimates')
  },

  storeBatchAllCustomBuildings({ dispatch }) {
    return Promise.all([
      dispatch('assumptions/buildingStocks/storeBatchCustomBuildingStocks', null, { root: true }),
      dispatch('assumptions/buildingEstimates/storeBatchCustomBuildingEstimates', null, { root: true })
    ])
    .then(res => res.map(r => r.data))
    .catch((error) => {
      console.log(error)
    })
  },

  getAllCustomBuildings({ dispatch }) {
    return Promise.all([
      dispatch('assumptions/buildingEstimates/getCustomBuildingEstimates', null, { root: true }),
      dispatch('assumptions/buildingStocks/getCustomBuildingStocks', null, { root: true })
    ])
  },

  getPolicy({ commit, dispatch }, policy_id) {
    return PolicyApiService.getNewPolicy(policy_id)
      .then((policy) => {
        commit('setPolicySelected', policy)
        dispatch('policy/addOrUpdatePolicyToUserOrSharedLists', { policy }, { root: true })
        dispatch('setUserLastJurisdictionAndFirstClimateZoneVisited', { value: policy.jurisdiction, context: 'updateLastJurisdictionVisitedFromPoliciesShow' }, { root: true })
      })
  },

  clearImpactAssumptions({ commit, state, rootGetters, dispatch }, policy_id) {
    if (state.policy_id_selected || policy_id) {
      const policy_type = rootGetters['policy/getterUserPolicy']({id:policy_id}).policy_containers[0].study_type.slug
      const defaultValues = policy_type === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS ?  defaultExistingBuildingsImpactAssumptionValues : defaultNewBuildingsImpactAssumptionValues
      if(rootGetters['policy/getterUserCanEditPolicy'](policy_id)) {
        dispatch('updateSelectedCustomCombinationAssumptionsPartially', null)
        PolicyApiService.update(state.policy_id_selected, new ImpactAssumptions({}, defaultValues))
      }
      commit('clearPolicyImpactAssumptions', defaultValues)
    }
    commit('clearResultsImpactAssumptions')
  },

  async clearImpactAssumptionsByKey({ state, commit, getters, rootGetters, dispatch }, {study_type, key}) {
    const defaultValues = study_type?.slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS ?  defaultExistingBuildingsImpactAssumptionValues : defaultNewBuildingsImpactAssumptionValues
    const assumptionsToResetKeys = key === ASSUMPTIONS_DRAWER_DIVISION.BASIC ? basicAssumptionKeys : advancedAssumptionKeys
    const setDefaultAssumptions = assumptionsToResetKeys.reduce((acc, curr) => {
      acc[curr] = defaultValues?.[curr] || undefined
      return acc
    }, {})
    const userCanEditPolicy = Boolean(state.policy_id_selected && (rootGetters['policy/getterUserCanEditPolicy'](state.policy_id_selected) || getters['getterUserCanEditTheSelectedPolicy']))
    if(userCanEditPolicy) {
      const customCombinations = getters['getterPolicyContextCustomCombinationsForAssumptions'](true, true)
      let customCombinationToInsert = []
      customCombinations.forEach((cc)=>{
        const assumption = {
          id: cc.id,
          getAndMerge: true,
          policy_id: cc.policy_id,
          policy_container_id: cc.policy_container_id,
          meta: {
            assumptions: {...setDefaultAssumptions}
          }
        }
        customCombinationToInsert.push(assumption)
      })
      await dispatch('policy/saveCustomCombinationsToPolicy', customCombinationToInsert, { root: true })
    } else if (!state.policy_id_selected) {
      commit('clearResultsImpacAssumptionsByObjectKey', setDefaultAssumptions)
    }
    EventBus.$emit('customCombinationUpdated')
  },

  // TIP: Start passing assumptions here instead of passing key/value, because this approach can receive a set of
  //   assumptions. We expect to deprecate key/value in future
  updateAssumptionsKeyValue({ state, commit, getters, rootGetters, dispatch }, { key, value, force, assumptions }) {
    
    EventBus.$emit('setPolicyIsLoading', true)

    const canEdit = state.policy_id_selected && (rootGetters['policy/getterUserCanEditPolicy'](state.policy_id_selected) || getters['getterUserCanEditTheSelectedPolicy'])
    if (assumptions) {
      if (canEdit) {
        clearTimeout(waitingToUpdatePolicy)
        updatingKeys = {...updatingKeys, ...assumptions}
        waitingToUpdatePolicy = setTimeout(() => {
          dispatch('updateSelectedCustomCombinationAssumptionsPartially', updatingKeys)
          PolicyApiService.update(state.policy_id_selected, {...(state.policyImpactAssumptions || {}), ...assumptions})
          updatingKeys = {}
        }, force ? 0 : 1000)
      }

      clearTimeout(waitingToUpdateAssumptions)
      waitingToUpdateAssumptions = setTimeout(() => {
        Object.keys(assumptions || {}).forEach((key) => {
          commit('updateAssumptionsKeyValue', { key, value: assumptions[key] })
        })
      }, force ? 0 : 200)
      return
    }

    if (canEdit) {
      clearTimeout(waitingToUpdatePolicy)
      updatingKeys[key] = value
      waitingToUpdatePolicy = setTimeout(() => {
        dispatch('updateSelectedCustomCombinationAssumptionsPartially', updatingKeys)
        PolicyApiService.update(state.policy_id_selected, {...(state.policyImpactAssumptions || {}), ...updatingKeys})
        updatingKeys = {}
      }, force ? 0 : 1000)
    }

    clearTimeout(waitingToUpdateAssumptions)
    waitingToUpdateAssumptions = setTimeout(() => {
      commit('updateAssumptionsKeyValue', { key, value })
    }, force ? 0 : 200)

  },

  updateSelectedCustomCombinationAssumptionsPartially({ state, dispatch, commit, getters }, partialAssumption) {
    const customCombinationIds = getters['getterPolicyContextCustomCombinationsForAssumptions'](false)
    const custom_combinations =  (state?.policy_selected?.policy_containers?.map((container) => {
      return container?.custom_combinations?.filter((cc) => customCombinationIds.includes(cc.id)) || []
    }) || []).flat(Infinity)
    dispatch('updateCustomCombinationsAssumptionsPartially', { custom_combinations, partialAssumption })
    const newImpactAssumptions = partialAssumption ? {
        ...state.policyImpactAssumptions,
        ...(partialAssumption || {})
      } :
      commit('setPolicyImpactAssumptions', newImpactAssumptions)
  },

  updateCustomCombinationsAssumptionsPartially({ state, dispatch, getters, rootGetters }, { custom_combinations, partialAssumption }) {
    const userCanEditPolicy =  rootGetters['policy/getterUserCanEditPolicy'](state.policy_id_selected) || getters['getterUserCanEditTheSelectedPolicy']
    
    if(!userCanEditPolicy) {
      EventBus.$emit('openPolicyUserPermissionDuplicateAlertModal', ({ policy: { id: state.policy_id_selected } }))
      return
    }

    if (Array.isArray(custom_combinations) && custom_combinations.length) {
      dispatch('policy/saveCustomCombinationsToPolicy', custom_combinations.map((cc) => {
        return {
          id: cc.id,
          policy_id: cc.policy_id,
          policy_container_id: cc.policy_container_id,
          getAndMerge: true,
          meta: {
            assumptions: partialAssumption
          },
        }
      }), { root: true })
    }
    
  },

  // @Deprecated Method, try filter the desired custom combinations to modify and use the function updateCustomCombinationsAssumptionsPartially
  updateSelectedCustomCombinationByPrototypeSelected({state, getters, rootGetters, dispatch},{custom_combinations, prototypes, value, key, climate_zone_id = false, force = false}){ 
    const userCanEditPolicy =  rootGetters['policy/getterUserCanEditPolicy'](state.policy_id_selected) || getters['getterUserCanEditTheSelectedPolicy']
    const prototypesIds = prototypes.map(prototype => prototype.id)

    const getClimateZonesRaw = rootGetters['lastClimateZonesVisited']
    .filter(climate_zone => climate_zone_id ? climate_zone.id === climate_zone_id : climate_zone)
    .map(climate_zone => climate_zone.raw) 
    
    let customCombinationToInsert = []

    const assumption = {[key]:value}
    if (!force) {
      updatingKeys = {...updatingKeys, ...assumption}
    }

    if(!userCanEditPolicy || !custom_combinations.length) return
    if(!force && waitingToUpdatePolicy) clearTimeout(waitingToUpdatePolicy)
    
    const timeout = setTimeout(()=>{
        custom_combinations.forEach((cc) => {
          if(prototypesIds.includes(cc.prototype.id) && getClimateZonesRaw.includes(cc.climate_zone_raw)) {
            const assumptionData = {
              id: cc.id,
              policy_id: cc.policy_id,
              policy_container_id: cc.policy_container_id,
              getAndMerge: true,
              meta: {
                assumptions: (!force) ? updatingKeys : assumption
              }
            }
            customCombinationToInsert.push(assumptionData)  
          }
        })
      dispatch('policy/saveCustomCombinationsToPolicy', customCombinationToInsert, { root: true })
      if (!force) {
        updatingKeys = {}
        customCombinationToInsert = []
      }
    }, 0)
    if (!force) {
      waitingToUpdatePolicy = timeout
    }
  }
}
