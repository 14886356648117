<template>
  <div
    v-if="getColumnGroups"
    class="psui-w-full space-y-1"
  >
    <div
      v-for="table in getTables"
      :key="table.key"
      class="bg-white px-6 py-2"
    >
      <div class="psui-w-full psui-flex psui-items-center">
        <h3 class="psui-text-blue-80 psui-text-accentSmall psui-font-bold psui-uppercase psui-mr-2">
          {{ jurisdictionFormat(table.title) }}
        </h3>
      </div>

      <PsDialog
        v-if="totalBuildings === 0 && table.key === 'city_wide_impact' && !isLoading"
        :has-close="false"
        class="alignmentDialog maxWidthDialog psui-flex psui-mb-2 psui-mt-2 items-center"
        theme="alert"
      >
        <template #default>
          <div>
            We couldn't calculate your policy impacts because we don’t predict any new units of this building type ({{ getPrototypeTitle }}) being constructed. Add your predictions for future new units to see the impact of your policy.
          </div>
        </template>
        <template #action>
          <div class="psui-pr-2">
            <a 
              class="psui-text-yellow-70 psui-text-small psui-font-bold psui-flex-nowrap cursor-pointer"
              @click="openBuildingEstimatesAssumptionsDrawer()"
            >
              Add Predicted Units
            </a>
          </div>
        </template>
      </PsDialog>
      <div class="table-wrapper-requirements psui-w-full psui-mt-2 psui-whitespace-nowrap psui-flex overflow-scroll">
        <table class="psui-relative psui-align-top psui-w-full">
          <PolicyEditRequirementsTableHead :columns="getActiveColumns(table.columns)" />
          <PolicyEditRequirementsTableBody
            :policy="getPolicySelected"
            :policy_summary_data="getSummaryData(table)"
            :columns="getActiveColumns(table.columns)"
            :has-total="table.meta.hasTotal"
            :is-loading="isLoading"
            :disabled="isDisabledTable(table)"
            :table-group="table.key"
          />
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import PolicyEditRequirementsTableHead from '../PolicyEditRequirementsTable/PolicyEditRequirementsTableHead.vue'
import PolicyEditRequirementsTableBody from '../PolicyEditRequirementsTable/PolicyEditRequirementsTableBody.vue'
import { PolicyImpactAlgorithmFactory } from '@/business-logic/services/impact-algorithms/policy/factory'
import { TYPE_FUELS_DB_SLUGS } from "@/util/Enums"
import { COLUMN_GROUP_KEYS } from './edit-requirements-new-buildings-sf-2022-columns'
import { checkDeepObjectChanges } from '@/util/checkDeepObjectChanges'
import { edr1_total_margin, compliance_margin } from '@/modules/app/shared/default-app-columns'

export default {
  name: 'PolicyEditRequirementsTable',
  components:{
    PolicyEditRequirementsTableHead,
    PolicyEditRequirementsTableBody,
  },
  props: ['custom_combinations', 'policy'],
  data:()=>({
    policy_summary_data:[],
    policyImpactAlgorithm: null,
    resumedCityWideEstimates: [],
    perHomeEstimates: [],
    debouncers: {},
    isLoading: false,
    instantPropValues: {},
    oldCustomCombinationMetas: [],
    getColumnGroups: null,
  }),
  computed: {
    getTables() {
      return this.getColumnGroups ? [ ...Object.values(this.getColumnGroups)[0].columnGroups ] : []
    },
    policyExistingColumnGroupsSelected() {
      return this.$store.getters['policy/getterPolicyNewBuildingsColumns']
    },
    getPolicySelected() {
      return this.$store.getters['assumptions/getterPolicySelected'] 
    },
    totalBuildings() {
      return this.policyImpactAlgorithm?.totalBuildingUnits || this.instantPropValues?.['buildings_units'] || 0
    },
    getPrototypeTitle(){
      return this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: this.custom_combinations[0].prototype_id }).title
    },
    getTypePrototypeByPrototype(){
      const prototype = this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: this.custom_combinations[0].prototype_id })
      const type_prototype_id = prototype.type_prototype_id
      const type_prototype = this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id: type_prototype_id })
      return type_prototype
    },
    getBuildingTypeSelected() {
      return this.$store.getters['globalEntities/BuildingType/getterBuildingTypeSelected'](this.$route.fullPath)
    },
  },
  watch: {
    custom_combinations: {
      deep: true,
      async handler() {
        const currentMetas = this.custom_combinations.map((cc) => {
          return {
            ...(cc?.meta || {})
          }
        })
        if (!this.checkSkipReload(this.oldCustomCombinationMetas, currentMetas)) {
          await this.computeEstimates()
        }
        this.oldCustomCombinationMetas = currentMetas
      }
    },
    totalBuildings: {
      async handler(value) {
        if (value) {
          this.updateInstantProp('buildings_units', value)
        }
        await this.computeEstimates()
      }
    }
  },
  async mounted() {
    await this.computeEstimates()
    this.getColumnGroups = this.getAndAdjustColumnGroups()
    this.$eventBus.$on('buildingEstimatesChanged', () => {
      if (this.policyImpactAlgorithm) {
        this.policyImpactAlgorithm.updateBuildings()
      }
    })
  },
  beforeDestroy() {
    this.$store.commit('assumptions/updateLastClimateZoneSelected', false)
    this.$eventBus.$off('buildingEstimatesChanged')
  },
  methods: {
    getAndAdjustColumnGroups() {
      const firstFuelId = this.custom_combinations.map((cc) => cc.fuel_id).filter(x => x != null).pop()
      const firstFuel = firstFuelId != null ? this.$store.getters['globalEntities/Fuel/getterGlobalFuel']({ id : firstFuelId }) : null
      const study = firstFuel?.study_id != null ? this.$store.getters['globalEntities/Study/getterGlobalStudy']({ id: firstFuel.study_id }) : null
      const columnGroups = this.$store.getters['getterUserPreference']('columnsEditRequirementsNewBuildingsSF2022')
      columnGroups['new-buildings'].columnGroups.forEach((cG) => {
        if (cG.key !== COLUMN_GROUP_KEYS.PER_HOME) return cG
        cG.columns.forEach((c) => {
          if ([edr1_total_margin().key, compliance_margin().key].includes(c.key)) {
            c.isActive = c.key === study.compliance_margin_key
          }
        })
      })
      return columnGroups
    },
    getActiveColumns(columns) {
      return columns.filter(col => col.isActive)
    },
    updateInstantProp(prop, value) {
      this.instantPropValues = {...this.instantPropValues, [prop]: value}
    },
    checkSkipReload(oldMeta, newMeta) {
      if (!oldMeta?.length || !newMeta?.length) {
        return false
      }
      const disableReloadChanges = ['assumptions']
      const keysChanged = [
        ...checkDeepObjectChanges(oldMeta[0], newMeta[0]).filter((key) => !disableReloadChanges.includes(key)),
        ...checkDeepObjectChanges(oldMeta[1], newMeta[1]).filter((key) => !disableReloadChanges.includes(key)),
      ]
      return !keysChanged?.length
    },
    debounce(func, debouncerName = 'default', timeout = 500) {
      if (this.debouncers[debouncerName]) {
        clearTimeout(this.debouncers[debouncerName])
      }
      this.debouncers[debouncerName] = setTimeout(async () => {
        this.debouncers[debouncerName] = null
        func()
      }, timeout)
    },
    async computeEstimates() {
      if (!this.custom_combinations?.length) {
        return
      }
      this.isLoading = true
      const that = this
      this.debounce(async () => {
        try {
          this.policyImpactAlgorithm = PolicyImpactAlgorithmFactory.createByCustomCombinations(that.custom_combinations, that.policy)
          if(this.policyImpactAlgorithm.customCombinations.length == 0) return
          if (!this.policyImpactAlgorithm) {
            console.error(`Could create policyImpactAlgorithm!`)
            return
          }

          this.perHomeEstimates = await this.policyImpactAlgorithm.computePerHomeImpact()
          this.resumedCityWideEstimates = await this.policyImpactAlgorithm.computeResumedCityWideImpact()
        } catch(e) {
          console.error(`Could not compute estimates on new buildings table: `, e)
        } finally {
          this.isLoading = false
          this.$forceUpdate()
        }
      }, 'computeEstimates', 2000)
    },
    getSummaryData(table) {
      return table.key === COLUMN_GROUP_KEYS.CITY_WIDE  ? this.resumedCityWideEstimates : this.perHomeEstimates
    },
    isDisabledTable() {
      if (!this.policyImpactAlgorithm) {
        return false
      }

      const {
        [TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC]: allElectricCustomCombination,
        [TYPE_FUELS_DB_SLUGS.MIXED_FUEL]: mixedFuelCustomCombination
      } = this.policyImpactAlgorithm.getCustomCombinationsByFuel()
      const allElectricMetaInfo = this.policyImpactAlgorithm.getCustomCombinationMetaInfo(allElectricCustomCombination)
      const mixedFuelMetaInfo = this.policyImpactAlgorithm.getCustomCombinationMetaInfo(mixedFuelCustomCombination)
      return Boolean(allElectricMetaInfo.isOnDefaultState && mixedFuelMetaInfo.isOnDefaultState)
    },
    openBuildingEstimatesAssumptionsDrawer() {
      const climateZonePrefix = Number(this.custom_combinations[0].climate_zone_raw.split('-')?.[0])
      this.$store.commit('assumptions/updateTypePrototypeEditing', this.getTypePrototypeByPrototype)
      this.$store.commit('assumptions/openDrawerAtBuildingEstimatesResidentialNew', this.getBuildingTypeSelected)
      this.$store.commit('assumptions/updateDrawerIsEditingFromChart', false)
      this.$store.commit('assumptions/updateLastClimateZoneSelected', climateZonePrefix)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .table-wrapper-requirements {
    overflow-y: hidden;
    
    tr {
      th, td {
        &:first-child {
          box-shadow: inset -1px 0px 0px #EBEEF0;
          max-width: 150px;
          padding-top: 11px;
          padding-bottom: 11px;
        }
      }
    }

    tbody {
      tr:first-child {
        border-top: 1px solid #E6ECF2;
      }
    }
  }
}

.alignmentDialog {
  ::v-deep .psui-el-dialog-action {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
}

.maxWidthDialog {
  ::v-deep .psui-el-dialog-default {
    max-width: 85%;
  }
} 
</style>
