import ImpactAssumptions from '@/models/ImpactAssumptions'
import { defaultNewBuildingsImpactAssumptionValues, defaultExistingBuildingsImpactAssumptionValues } from "@/models/ImpactAssumptions"

export default () => ({
  drawerOptions:{
    visibility: false,
    path: false,
    tab: false,
    type: false, // enum ASSUMPTIONS_DRAWER_TYPES
    isEditingFromChart: false, // sets if the drawer was opened from building estimates chart
    typePrototypeEditing: false,
    lastClimateZoneSelected: false,
  },
  existingBuildingsResultsImpactAssumptions: new ImpactAssumptions({}, defaultExistingBuildingsImpactAssumptionValues),
  newBuildingsResultsImpactAssumptions: new ImpactAssumptions({}, defaultNewBuildingsImpactAssumptionValues),
  policyImpactAssumptions: {},
  policy_id_selected: null,
  policy_selected: {},
  policy_custom_combinations_selected: null,
  showNotLoggedWarning : false,
})
