import CustomCombination from '@/models/CustomCombination'
import { FUEL_CHOICES } from '@/business-logic/services/impact-algorithms'
import { GeneralPerformanceByFuelPolicyImpactAlgorithm } from '@/business-logic/services/impact-algorithms/policy/general-performance-by-fuel'

export default {
  data: () => ({
    isSaving: false,
    isSavingByRequirementId: {},
    isComplianceMarginUnique: true,
    savingRequirements: [],
    saveDebouncer: null,
  }),
  computed: {
    getterUserCanEditPolicy() {
      return this.$store.getters['policy/getterUserCanEditPolicy'](this.$route.params.policy_id)
    },
    getCustomCombinations() {
      return this.custom_combinations.map((cc) => {
        const fuel = this.$store.getters['globalEntities/Fuel/getterGlobalFuel']({ id: cc.fuel_id })
        const type_fuel = this.$store.getters['globalEntities/TypeFuel/getterGlobalTypeFuel']({ id: fuel.type_fuel_id })
        return { ...cc, fuel: { ...fuel, type_fuel } }
      })
    },
    getAllElectricCombination() {
      return this.getCustomCombinations.find((cc) => cc.fuel.type_fuel.slug === 'all-electric') ?? false
    },
    getMixedFuelCombination() {
      return this.getCustomCombinations.find((cc) => cc.fuel.type_fuel.slug === 'mixed-fuel') ?? false
    },
    getStudyComplianceMarginKey() {
      if (!this.getAllElectricCombination?.fuel?.study_id) {
        return 'compliance_margin'
      }
      const study = this.$store.getters['globalEntities/Study/getterGlobalStudy']({ id: this.getAllElectricCombination.fuel.study_id })
      return study.compliance_margin_key || 'compliance_margin'
    },
    isPvSystemEnabled() {
      return GeneralPerformanceByFuelPolicyImpactAlgorithm.pvEnabledComplianceMarginKeys.includes(this.getStudyComplianceMarginKey)
    },
    isComplianceMarginEnabled() {
      return !this.isPvSystemEnabled
    },
  },
  watch: {
    getAllElectricCombination: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (!oldVal && newVal && newVal?.meta?.generate_virtual_compliance_margin) {
          this.isComplianceMarginUnique = true
        }
      },
    },
    getMixedFuelCombination: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (!oldVal && newVal && newVal?.meta?.generate_virtual_compliance_margin) {
          this.isComplianceMarginUnique = true
        }
      },
    },
  },
  methods: {
    onUpdateRequirements({ requirement, key, value, meta }) {
      if (!this.getterUserCanEditPolicy) {
        return
      }

      this.$set(this.isSavingByRequirementId, requirement.id, true)
      this.isSaving = true
      if (this.saveDebouncer) {
        clearTimeout(this.saveDebouncer)
        this.saveDebouncer = null
      }

      // Workaround to change requirement direction as we are using all electric as the unique component
      if (meta?.isMixedFuelMeasure === true && this.isComplianceMarginUnique) {
        requirement = this.getMixedFuelCombination
      }

      const isUpdatingAllElectricRequirement = +requirement.id === +this.getAllElectricCombination.id
      const newRequirementCC = new CustomCombination({ ...requirement })
      const otherRequirementCC = isUpdatingAllElectricRequirement
        ? new CustomCombination({ ...this.getMixedFuelCombination })
        : new CustomCombination({ ...this.getAllElectricCombination })
        
      const setKeyInfo = (ccRequirement, setKey, setValue, setMeta) => {
        // As the cc entered inside this function, it means that this cc must be saved. so we need to add it into our updating list
        const alreadySavingCCObj = this.savingRequirements.find((cc) => +cc.id === +ccRequirement.id)
        if (!alreadySavingCCObj) {
          this.savingRequirements.push(ccRequirement)
        } else {
          ccRequirement = alreadySavingCCObj
        }

        if (setKey === 'compliance_margin_measure_id') {
          ccRequirement.meta.compliance_margin_value = setValue.compliance_margin_value
          ccRequirement.meta.compliance_margin_measure_id = setValue.compliance_margin_measure_id
          ccRequirement.meta.generate_virtual_compliance_margin =
            setValue.virtual_value && newRequirementCC.meta.compliance_margin_value && newRequirementCC.meta.compliance_margin_measure_id ? setValue.virtual_value : undefined
          // Todo: Try to understand why we need it here...
          if (setMeta && setValue.compliance_margin_value && setValue.compliance_margin_value > setMeta.maximumComplianceMarginForLargerPvSystemValue) {
            ccRequirement.meta.require_pv_system = false
          }
        } else {
          ccRequirement.meta[setKey] = setValue
        }
      }

      setKeyInfo(newRequirementCC, key, value, meta)

      if (key === 'fuel_choice' && value !== FUEL_CHOICES.ALLOWED) {
        const resetMeasureRequirementCC = isUpdatingAllElectricRequirement ? otherRequirementCC : newRequirementCC
        const otherFuelChoiceValue = isUpdatingAllElectricRequirement ? FUEL_CHOICES.NOT_ALLOWED : FUEL_CHOICES.REQUIRED
        setKeyInfo(newRequirementCC, 'generate_virtual_compliance_margin', undefined)
        setKeyInfo(otherRequirementCC, 'fuel_choice', otherFuelChoiceValue)
        setKeyInfo(resetMeasureRequirementCC, 'compliance_margin_measure_id', {
          compliance_margin_value: null,
          compliance_margin_measure_id: 0,
        })
      } else if (key === 'fuel_choice' && value === FUEL_CHOICES.ALLOWED) {
        setKeyInfo(otherRequirementCC, 'fuel_choice', FUEL_CHOICES.ALLOWED)
      }

      // Treat here the unique meta...
      if (this.isComplianceMarginUnique && key === 'compliance_margin_measure_id' && otherRequirementCC?.meta?.fuel_choice === FUEL_CHOICES.ALLOWED) {
        setKeyInfo(otherRequirementCC, 'compliance_margin_measure_id', {
          compliance_margin_value: null,
          compliance_margin_measure_id: 0,
          virtual_value: value.compliance_margin_value,
        })
      }

      this.saveDebouncer = setTimeout(() => {
        const newCcList = this.custom_combinations.map((cc) => {
          const newSavingValue = this.savingRequirements.find((i) => +i.id === +cc.id)
          return newSavingValue || cc
        })

        this.$store.dispatch('policy/saveCustomCombinationsToPolicy', this.savingRequirements).then(() => {
          this.savingRequirements.forEach((req) => {
            this.$set(this.isSavingByRequirementId, req.id, false)
          })
          this.isSaving = false
          this.savingRequirements = []
          this.$emit('update:custom_combinations', newCcList)
          this.$emit('updateCustomCombinations', { custom_combinations: newCcList })
        })
      }, 500)
    },
    toggleUniqueComplianceMargin() {
      if (!this.getterUserCanEditPolicy) {
        return
      }
      this.isComplianceMarginUnique = !this.isComplianceMarginUnique
      this.onUpdateRequirements({
        key: 'require_different_compliance_margins',
        value: !this.isComplianceMarginUnique,
        requirement: this.getAllElectricCombination,
      })
      this.onUpdateRequirements({
        key: 'require_different_compliance_margins',
        value: !this.isComplianceMarginUnique,
        requirement: this.getMixedFuelCombination,
      })
      this.resetComplianceMargins()
    },
    resetComplianceMargins() {
      const commonResetObj = {
        key: 'compliance_margin_measure_id',
        value: {
          compliance_margin_value: null,
          compliance_margin_measure_id: 0,
        },
      }
      this.onUpdateRequirements({
        ...commonResetObj,
        requirement: this.getAllElectricCombination,
      })
      this.onUpdateRequirements({
        ...commonResetObj,
        requirement: this.getMixedFuelCombination,
      })
    }
  },
}
