import sumItemsWeightedByAttr from '@/business-logic/services/calcs/sumItemsWeightedByAttr'
import getPolicyCostRatioColumnValue from '@/business-logic/services/policies/getPolicyCostRatioColumnValue'

export default ({ vintageData, studyDataItems }) => {

  // Per home cols
  vintageData.initial_cost = studyDataItems.reduce((acc, item) => acc + item.initial_cost, 0)
  vintageData.annual_bill_savings = studyDataItems.reduce((acc, item) => acc + item.annual_bill_savings, 0)
  vintageData.annual_bill_savings_avg = studyDataItems.reduce((acc, item) => acc + item.annual_bill_savings_avg, 0)
  vintageData.emissions_savings = studyDataItems.reduce((acc, item) => acc + item.emissions_savings, 0)
  vintageData.lifecycle_savings = studyDataItems.reduce((acc, item) => acc + item.lifecycle_savings, 0)
  vintageData.kwh_savings = studyDataItems.reduce((acc, item) => acc + item.kwh_savings, 0)
  vintageData.therms_savings = studyDataItems.reduce((acc, item) => acc + item.therms_savings, 0)
  vintageData.energy_savings_combined = studyDataItems.reduce((acc, item) => acc + item.energy_savings_combined, 0)
  
  // Per home subsidy
  vintageData.subsidy_needed = studyDataItems.reduce((acc, item) => acc + item.subsidy_needed, 0)
  vintageData.five_year_payback = studyDataItems.reduce((acc, item) => acc + item.five_year_payback, 0)
  vintageData.subsidy_needed_care = studyDataItems.reduce((acc, item) => acc + item.subsidy_needed_care, 0)
  vintageData.five_year_payback_care = studyDataItems.reduce((acc, item) => acc + item.five_year_payback_care, 0)
  
  // Cost effectiveness cols
  const on_bill_cost_ratio = getPolicyCostRatioColumnValue({ cost_ratio_column: 'on_bill_cost_ratio', studyDataItems, initial_cost: vintageData.initial_cost, annual_bill_savings_avg: vintageData.annual_bill_savings_avg })
  const tdv_benefit_to_cost_ratio = getPolicyCostRatioColumnValue({ cost_ratio_column: 'tdv_benefit_to_cost_ratio', studyDataItems, initial_cost: vintageData.initial_cost, annual_bill_savings_avg: vintageData.annual_bill_savings })

  const on_bill_cost_ratio_2025 = getPolicyCostRatioColumnValue({ cost_ratio_column: 'on_bill_cost_ratio_2025', studyDataItems, initial_cost: vintageData.initial_cost, annual_bill_savings_avg: vintageData.annual_bill_savings_avg })
  const lsc_2025_benefit_to_cost_ratio = getPolicyCostRatioColumnValue({ cost_ratio_column: 'lsc_2025_benefit_to_cost_ratio', studyDataItems, initial_cost: vintageData.initial_cost, annual_bill_savings_avg: vintageData.annual_bill_savings_avg })
  const on_bill_2025_care = getPolicyCostRatioColumnValue({ cost_ratio_column: 'on_bill_2025_care', studyDataItems, initial_cost: vintageData.initial_cost, annual_bill_savings_avg: vintageData.annual_bill_savings_avg })

  vintageData.on_bill_cost_ratio_2025 = on_bill_cost_ratio_2025
  vintageData.lsc_2025_benefit_to_cost_ratio = lsc_2025_benefit_to_cost_ratio
  vintageData.on_bill_cost_ratio_2025_care = on_bill_2025_care
  vintageData.on_bill_cost_ratio = on_bill_cost_ratio
  vintageData.tdv_benefit_to_cost_ratio = tdv_benefit_to_cost_ratio
  vintageData.tdv2022_benefit_to_cost_ratio = studyDataItems.reduce((acc, item) => acc + item.tdv2022_benefit_to_cost_ratio, 0)
  vintageData.simple_payback = sumItemsWeightedByAttr(studyDataItems, 'simple_payback', 'initial_cost')
  
  // City wide cols
  vintageData.forecast_units_affected = !studyDataItems.length ? 0 : studyDataItems.sort((a, b) => a.forecast_units_affected - b.forecast_units_affected)[0].forecast_units_affected
  vintageData.forecast_initial_cost = !studyDataItems.length ? 0 :  studyDataItems.reduce((acc, item) => acc + item.forecast_initial_cost, 0)
  vintageData.forecast_emissions_savings = !studyDataItems.length ? 0 :  studyDataItems.reduce((acc, item) => acc + item.forecast_emissions_savings, 0)
  vintageData.forecast_kwh_savings = !studyDataItems.length ? 0 :  studyDataItems.reduce((acc, item) => acc + item.forecast_kwh_savings, 0)
  vintageData.forecast_therms_savings = !studyDataItems.length ? 0 :  studyDataItems.reduce((acc, item) => acc + item.forecast_therms_savings, 0)
  vintageData.forecast_lifecycle_savings = !studyDataItems.length ? 0 :  studyDataItems.reduce((acc, item) => acc + item.forecast_lifecycle_savings, 0)

  // City wide subsidy
  vintageData.city_wide_subsidy_needed = !studyDataItems.length ? 0 :  studyDataItems.reduce((acc, item) => acc + item.city_wide_subsidy_needed, 0)
  vintageData.city_wide_five_year_payback = !studyDataItems.length ? 0 :  studyDataItems.reduce((acc, item) => acc + item.city_wide_five_year_payback, 0)
  vintageData.city_wide_subsidy_needed_care = !studyDataItems.length ? 0 :  studyDataItems.reduce((acc, item) => acc + item.city_wide_subsidy_needed_care, 0)
  vintageData.city_wide_five_year_payback_care = !studyDataItems.length ? 0 :  studyDataItems.reduce((acc, item) => acc + item.city_wide_five_year_payback_care, 0)
}
