import MainRouter from '@/router'
import UserDeviceApiService from '@/services/api/UserDeviceApiService'
import UserEventApiService from '@/services/api/UserEventApiService'
export default {

  scrollContainerTop() {
    setTimeout(() => {
      const mainContent = document.getElementById('main-content')
      const firstChild = mainContent.firstChild
      if(firstChild) {
        firstChild.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      }
    }, 10)
  },

  checkRedirectTo(context) {
    if(context.state.redirectTo) {
      MainRouter.push(context.state.redirectTo)
      const event = context.state.redirectToEventToDispatchAfter
      if(event) {
        setTimeout(() => {
          if(typeof event == 'string') {
            window.$eventBus.$emit(event)
          } else {
            window.$eventBus.$emit(event.name, event.payload)
          }
        }, 500)
      }
      setTimeout(() => {
        context.commit('setRedirectTo')
      }, 1000)
    }
  },

  checkDeviceInfo({ commit, dispatch }) {
    UserDeviceApiService.check()
      .then((data) => {
        const { user_device, is_admin, is_team, is_dev, log_events, feature_flags } = data
        commit('setDeviceInfo', { user_device, is_admin, is_team, is_dev, log_events, feature_flags })
        if(is_admin || is_team) {
          dispatch('getAdminAndTeamDevices')
        }

        if (sessionStorage.getItem(`SESSION_STARTED`) !== 'true') {
          sessionStorage.setItem(`SESSION_STARTED`, 'true')
          UserEventApiService.log({ event: 'SESSION_STARTED' })
        }
      })
  },

  getAdminAndTeamDevices({ commit }) {
    UserDeviceApiService.getOnlyAdminAndTeamDevices()
      .then(({ data }) => {
        commit('setAdminAndTeamDevices', data.only_admin_and_team_devices)
      })
  }

}
