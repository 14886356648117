<template>
  <div
    class="w-full h-full overflow-hidden"
    :class="[
      `route-${routeNameClass}`,
      {'bg-blur': getRouteQueryCode && !isUserLoggedIn}
    ]"
  >
    <BaseSidebar
      v-if="getSidebar"
      :sidebar-with="getSideBarWidth"
      :sidebar="getSidebar"
    />
    <div
      id="content-container"
      class="w-full h-full flex overflow-hidden relative"
      :style="getStyle"
    >
      <div
        id="main-content"
        class="w-full overflow-hidden relative z-10"
      >
        <resize-observer @notify="$store.commit('general/setContentContainerWidth', $event.width)" />
        <router-view />
      </div>
    </div>
    
    <AssumptionsDrawer />
    <SidebarMenu />
    <DrawerUpcomingFeatures /> 
    <!-- GENERIC AND GLOBAL COMPONENTS -->
    <DrawerContent />
    <DescriptionModal />
    <LoadingModal />
    <BaseDrawerLeft /> 
    <StudyPDFVersionModal />
    <StudyGroupVersionsTimelineModal />
    <ModalVideos />
    <ModalUserSign />
    <AssumptionsModal />
    <AssumptionsOverwriteDataModal />
    <ConfirmPopover />
    <OptionsModal />
    <!-- <ExistingBuildingModalAutoGeneratePolicyWizard /> -->
    <!-- <NewBuildingPolicyWizardModal /> -->
    <PolicyOptionWizardModal />
    <ModalJurisdictionSelection />
    <ModalBuildPolicyAndJurisdictionSelection />
    <PolicyOptionDrawer />
    <TheEditColumnsDrawer />
    <NewBuildingAreaInfoModal />
    <ComparePoliciesWizardModal />
    <ModalPolicyCreateEdit />
    <MainBenefitsModal />
    <ModalFlexibleRequirements />
    <LoginLoading
      v-if="getRouteQueryCode && !isUserLoggedIn && !($route.name == 'AdminSignIn' || $route.name == 'UserSignin')"
      ref="baseModal"
    />
  </div>
</template>

<script>
import TheEditColumnsDrawer from '../components/general/TheEditColumnsDrawer.vue'
import AssumptionsModal from '@/modules/app/assumptions/shared/AssumptionsModal.vue'
import AssumptionsOverwriteDataModal from '@/modules/app/assumptions/shared/AssumptionsOverwriteDataModal.vue'
import AssumptionsDrawer from '@/modules/app/assumptions/shared/AssumptionsDrawer.vue'
import StudyPDFVersionModal from '@/components/modal/StudyPDFVersionModal.vue'
import StudyGroupVersionsTimelineModal from '@/components/modal/StudyGroupVersionsTimelineModal.vue'
import ModalVideos from '@/components/modal/ModalVideos.vue'
import BaseSidebar from './BaseSidebar'
import SidebarDefault from '@/components/general/SidebarDefault.vue'
import PolicyOptionWizardModal from '@/modules/app/policy/shared/PolicyOptionWizardModal.vue'
import ModalJurisdictionSelection from '@/modules/app/jurisdiction/ModalJurisdictionSelection.vue'
import ModalBuildPolicyAndJurisdictionSelection from '@/modules/app/jurisdiction/ModalBuildPolicyAndJurisdictionSelection.vue'
import PolicyOptionDrawer from '@/modules/app/policy/options/PolicyOptionDrawer.vue'
import NewBuildingAreaInfoModal from '@/modules/app/jurisdiction/study-results/content/general/NewBuildingAreaInfoModal.vue'
import ComparePoliciesWizardModal from '@/modules/app/comparison/ComparePoliciesWizardModal.vue'
import ModalPolicyCreateEdit from '@/modules/app/policy/shared/ModalPolicyCreateEdit.vue'
import MainBenefitsModal from '../modules/app/home/MainBenefitsModal.vue'
import { mapGetters } from 'vuex'
import LoginLoading from '@/components/modal/LoginLoading.vue'
import SidebarMenu from '@/components/general/SidebarMenu.vue'
import DrawerUpcomingFeatures from '@/components/general/DrawerUpcomingFeatures.vue'

export default {
  name: 'BaseLayoutWithHeaderAndSideBar',
  components: { 
    BaseSidebar,
    StudyGroupVersionsTimelineModal,
    StudyPDFVersionModal,
    ModalVideos, 
    AssumptionsDrawer, 
    AssumptionsModal,
    AssumptionsOverwriteDataModal,
    TheEditColumnsDrawer,
    PolicyOptionWizardModal,
    ModalJurisdictionSelection,
    ModalBuildPolicyAndJurisdictionSelection,
    PolicyOptionDrawer,
    NewBuildingAreaInfoModal,
    ComparePoliciesWizardModal,
    ModalPolicyCreateEdit,
    MainBenefitsModal,
    LoginLoading,
    SidebarMenu,
    DrawerUpcomingFeatures
  },
  metaInfo: {
    titleTemplate: '%sCost Effectiveness Explorer'
  },
  data: () => ({
    windowWidth : 2000
  }),
  computed: {
    ...mapGetters(['isUserLoggedIn']),
    routeNameClass() {
      return (this.$route.name ?? this.$route.path).toLocaleLowerCase()
    },
    getSidebar() {
      const parentRouteWithSidebar = this.$route.matched.find(m => m.meta.sidebar && m.meta.sidebar.content)
			if (this.$route.meta.sidebar === null) {
       return false
			} else if (this.$route.meta.sidebar) {
       return this.$route.meta.sidebar
      } else if (parentRouteWithSidebar) {        
        return parentRouteWithSidebar.meta.sidebar
      } else {
        return SidebarDefault
      }
    },
    getStyle() {
      if (this.getSidebar) {
        return { paddingLeft: this.getSideBarWidth }
      } else {
        return false
      }
    },
    getSideBarWidth() {
      if(this.windowWidth < 768) return '62px'
        const mapSizes = {
          small : 40,
          medium: 200,
          large: this.windowWidth >= 1200 ? 610 : 410
        }
      return this.getSidebar?.size ? mapSizes[this.getSidebar.size] + 'px' : `200px`      
    },
    getRouteQueryCode() {
      return this.$route.query.code
    },
  },
  mounted() {
    this.updateWindowWidth()
    window.addEventListener('resize', this.updateWindowWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowWidth)
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth
    }
  },
}
</script>

<style lang="scss" scoped>
  .bg-blur {
    filter: blur(.5px);
    width: 100%;
    height: 100%;
  }
</style>