<template>
  <BaseDrawer
    ref="FlexibleBaseDrawer"
    title="Edit Requirements"
    layout="requirements"
    :hierarchy="DRAWER_HIERARCHY.PRIMARY"
    :enable-full-screen="true"
    class="psui-bg-gray-10"
  >
    <template #header>
      <div class="app-drawer-header psui-flex psui-flex-col psui-bg-white psui-shadow-elevation-5 psui-mb-4">
        <div class="ps-tooltip-adjustments psui-w-auto psui-flex psui-justify-between psui-items-center psui-py-6 psui-pl-12 psui-pr-6">
          <PsButton
            layout="onlytext"
            icon="arrow_back"
            size="small"
            label="back to requirements"
            class="psui-mr-8 mr"
            @click.native="closeDrawer"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="psui-flex psui-text-small psui-text-gray-60 ml-10 pb-6 pt-2 mt-auto">
        <div class="psui-flex">
          <span class="psui-font-bold">Study Source:</span>
          <span class="psui-ml-1">{{ study_title }}</span>
        </div>
        <div class="psui-flex ml-2">
          <span class="psui-font-bold">Release Date:</span>
          <span class="psui-ml-1">{{ study_release_date }}</span>
        </div>
      </div>
    </template>

    <AppBreadcrumb
      :truncate-items="true"
      :items="breadcrumbs"
    />
    <div class="flex flex-col justify-between pt-8">
      <div class="flex flex-col">
        <span class="psui-text-h4 psui-text-blue-70">Maximum Cost-Effective Score</span>
        <span class="psui-text-p psui-text-gray-50 pt-3">This number represents the highest target score that was found to be cost-effective based on the cost-effective measures specificed by your policy. The Max Cost-Effective Score is the sum of the energy savings for such measures.</span>
        
        <div
          class="flex flex-col space-y-4 pt-4"
        >
          <span
            v-if="flexibleScore && total_impact_measures.withContingent && total_impact_measures.withContingent.length && total_impact_measures.withoutContingent && total_impact_measures.withoutContingent.length"
            class="psui-text-blue-70 psui-text-h4"
          >Flexible Impacts</span>
          <PolicyExistingDetailedFLexibleScoreTable
            v-if="displayContingentNonContigentData"
            :title="`With Contingent (${getContingentPercentage}% of unities)`"
            first-column-title="Measures"
            first-column-description="measures from latest study" 
            :total-impact-measures="total_impact_measures.withContingent"
            :affected-unities-normalizer="getContingentPercentage"
          />
          <PolicyExistingDetailedFLexibleScoreTable
            v-if="displayContingentNonContigentData"
            :title="`Without Contingent (${100 - getContingentPercentage}% of unities)`"
            first-column-title="Measures"
            first-column-description="measures from latest study" 
            :total-impact-measures="total_impact_measures.withoutContingent"
            :affected-unities-normalizer="100 - getContingentPercentage"
          />
          <span class="psui-text-blue-70 psui-text-h4">Total Impacts</span>
          <PolicyExistingDetailedFLexibleScoreTable
            v-if="totalImpactCombined.length > 0"
            :indexes="indexes"
            first-column-title="Measures"
            first-column-description="measures from latest study" 
            :total-impact-measures="totalImpactCombined"
          />
        </div>
      </div>
    </div>
  </BaseDrawer>
</template>

<script>

const totalImpactId = 9999
const totalMandatoryImpactId = 999
const flexibleImpactId = 99

import { DRAWER_HIERARCHY } from '@/util/Enums.js'
import AppBreadcrumb from '@/components/general/AppBreadcrumb.vue'
import dayjs from 'dayjs'
import PolicyExistingDetailedFLexibleScoreTable from '@/modules/app/policy/show/requirements/requirements-drawer/PolicyExistingDetailedFLexibleScoreTable.vue'
import RequirementDrawerMixin from '@/modules/app/policy/show/requirements/requirements-drawer/RequirementDrawerMixin.js'
import { defaultExistingBuildingsImpactAssumptionValues } from '@/modules/app/assumptions/shared/defaultAssumptions.js'

export default {
    name:'PolicyExistingDetailedFlexibleScoreDrawer',
    components:{
      AppBreadcrumb,
      PolicyExistingDetailedFLexibleScoreTable
    },
    mixins:[RequirementDrawerMixin],
    data:()=>({
      DRAWER_HIERARCHY,
      breadcrumbs: [],
      study_title: null,
      study_release_date: null,
      total_impact_measures: null,
      totalImpactCombined: [],
      totalImpactId,
      flexibleScore: null,
      totalMandatoryImpactId,
      flexibleImpactId,
      indexes:[],
      customContingentPercentage: null
    }),
    computed:{
      displayContingentNonContigentData(){
        return this.total_impact_measures && this.total_impact_measures.withContingent.length > 0 && this.total_impact_measures.withoutContingent.length > 0
      },
      getContingentPercentage(){
        return this.customContingentPercentage ?? defaultExistingBuildingsImpactAssumptionValues.flexible_contingent_percentage
      }
    },
    mounted() {
      this.$eventBus.$on('openExistingDetailedFlexibleScoreDrawer', ({custom_combination, total_impact_measures, flexible_score}) => {
        this.flexibleScore = flexible_score
        this.total_impact_measures = total_impact_measures

        const study = this.$store.getters['globalEntities/Study/getterGlobalStudy']({ id: custom_combination.prototype.study_id })
        this.study_title = study.title 
        this.study_release_date = `${dayjs(study.released_at).format('MMMM, D YYYY')}`

        this.breadcrumbs = [  
            {title: custom_combination.policy.title},
            { title: custom_combination.prototype.title },
            { title: 'Climate Zone ' + custom_combination.climate_zone.prefix },
            { title: custom_combination.vintage.title },
            { title: 'Maximum Cost-Effective Score'}
          ]

          this.customContingentPercentage = custom_combination?.meta.assumptions?.flexible_contingent_percentage

          this.setMandatoryData()
          
        this.$refs.FlexibleBaseDrawer.showDrawer = true
      })
    },
    beforeDestroy() {
      this.$eventBus.$off('openExistingDetailedFlexibleScoreDrawer')
    },
    methods:{
      closeDrawer(){
        this.totalImpactCombined = []
        this.indexes = []
        this.$refs.FlexibleBaseDrawer.showDrawer = false
      },
      setMandatoryData() {
        this.totalImpactCombined = [...this.total_impact_measures?.mandatory]
        const computeAndPush = (data, title, measureId) => {
          const rowData = this.computeResumedImpacts(data)
          const checkRowDataValuesZeroed = Object.values(rowData).reduce((acc,curr) => {
            return acc + curr
          },0) === 0

          if (Object.keys(rowData).length > 0 && !checkRowDataValuesZeroed) {
            if (measureId !== this.flexibleImpactId){
              this.indexes.push(measureId)
            } 

            this.totalImpactCombined.push({
              data: rowData,
              measure: {
                title
              },
              measure_id: measureId
            })
          }
        }
        const withContingentMeasuresData = this.total_impact_measures?.withContingent
        const withoutContingentMeasuresData = this.total_impact_measures?.withoutContingent

        if (withContingentMeasuresData.length && withoutContingentMeasuresData.length) {
          computeAndPush({ mandatoryMeasuresData: this.total_impact_measures?.mandatory, customContingentPercentage: this.customContingentPercentage }, 'Total Mandatory Measures', totalMandatoryImpactId)
          computeAndPush({ withContingentMeasuresData, withoutContingentMeasuresData, customContingentPercentage: this.customContingentPercentage }, `Flexible Measures (${this.flexibleScore} points)`, flexibleImpactId)
        }
        computeAndPush({ mandatoryMeasuresData: this.total_impact_measures?.mandatory, withContingentMeasuresData, withoutContingentMeasuresData, customContingentPercentage: this.customContingentPercentage }, 'Total', totalImpactId)
      }
    }
  }
</script>
