<template>
  <div
    v-if="typeFilter"
    class="p-6 h-screen pb-20"
  >
    <InputWithLabel
      v-model="typeFilter.title"
      class="mt-6"
      :label="'Title'"
    />
    <InputWithLabel
      v-model="typeFilter.slug"
      class="mt-6"
      :label="'Slug (external id: change with caution)'"
    />

    <InputWithLabel
      v-if="$route.params.type == 'prototype'"
      v-model="typeFilter.description"
      class="mt-6"
      :label="'Description'"
    />

    <InputWithLabel
      v-if="$route.params.type == 'prototype'"
      v-model="typeFilter.building_stock_title"
      class="mt-6"
      :label="'Building estimates title'"
    />

    <InputWithLabel
      v-if="$route.params.type == 'prototype'"
      v-model="typeFilter.building_stock_helper"
      class="mt-6"
      :label="'Building estimates helper'"
    />

    <InputWithLabel
      v-if="$route.params.type == 'prototype'"
      v-model="typeFilter.title_building_estimates_existing"
      class="mt-6"
      :label="'Building estimates existing title'"
    />
    
    <InputWithLabel
      v-if="$route.params.type == 'prototype'"
      v-model="typeFilter.unit_measure"
      class="mt-6"
      :label="'Unit Measure'"
    />

    <InputWithLabel
      v-if="$route.params.type == 'prototype'"
      v-model="typeFilter.units_normalization"
      class="mt-6"
      :mask="'######'"
      :label="'Units normalization'"
    />

    <div class="w-full flex items-center">
      <InputWithLabel
        v-if="$route.params.type == 'prototype'"
        v-model="typeFilter.icon"
        class="mt-6"
        :label="'Icon'"
      />

      <div class="ml-3 h-full flex items-center pt-4">
        <PsIcon 
          :icon="typeFilter.icon"
          color="psui-text-gray-60" 
          size="40"
        />
      </div>
    </div>

    <InputWithLabel
      v-if="['prototype', 'vintage'].includes($route.params.type)"
      v-model="typeFilter.color"
      class="mt-6"
      :label="'Color'"
    />

    <InputWithLabel
      v-if="$route.params.type == 'vintage'"
      v-model="typeFilter.title_long"
      class="mt-6"
      :label="'Title long'"
    />
    <InputWithLabel
      v-if="$route.params.type == 'vintage'"
      v-model="typeFilter.flexible_path_title"
      class="mt-6"
      :label="'Flexible Path Title'"
    />

    <div class="w-full relative mt-6">
      <VueSelect 
        v-model="typeFilter[`${$route.params.type}s`]"
        class="vue-select"
        placeholder="Select items related with this filter"
        label="title"
        :options="items"
        :multiple="true"
        :close-on-select="false"
      >
        <template #option="option">                
          <span class="text-xs block text-ash-600">{{ option.study.title }} - {{ option.study.released_at_formatted }}</span>
          <span class="block mb-4">{{ option.title }}</span>
        </template>

        <template #selected-option="option">
          <div class="flex flex-col p-2">
            <span class="text-xs block text-ash-400">{{ option.study.title }} - {{ option.study.released_at_formatted }}</span>
            <span class="block">{{ option.title }}</span>
          </div>
        </template>
      </VueSelect>
      <label>Filter items</label>
    </div>

    <div class="w-full relative mt-6">
      <VueSelect 
        v-model="typeFilter.building_type_id"
        class="vue-select"
        placeholder="Select items related with this filter"
        label="title"
        :options="building_types"
        :reduce="item => item.id"
        :close-on-select="false"
      />
      <label>Building type</label>
    </div>
    
    <template v-if="['prototype', 'vintage'].includes($route.params.type)">
      <div class="w-full flex flex-row space-x-3 my-6 items-start">
        <Checkbox
          class="border border-deepsky-200 px-2 rounded-sm mr-2 h-8"
          label="Hide in Building Estimates and Assumptions Sidebar"
          :value="typeFilter.hide_in_building_estimates"
          checkbox-classes="mb-0"
          type="checkbox"
          @input="typeFilter.hide_in_building_estimates = $event"
        />
      </div>

      <AdminTypePrototypeTypeVintageSelect
        v-if="$route.params.type === 'prototype'"
        :items.sync="typeFilter.type_vintages" 
      />
      <AdminTypePrototypeTypeVintageSelect
        v-if="$route.params.type === 'vintage'"
        :items.sync="typeFilter.type_prototypes" 
      />
    </template>

    <hr class="border-b border-gray05 my-6">
    <div>
      <PsButton
        v-if="!$route.params.type_filter_id"
        label="Create"
        icon="add"
        size="big"
        @click="store()"
      />
   
      <PsButton
        v-else
        label="Update"
        icon="update"
        size="big"
        @click="update()"
      />
    </div>
  </div>
</template>

<script>
import TypePrototype from '@/models/TypePrototype'
import Study from '@/models/Study'
import AdminTypePrototypeTypeVintageSelect from '@/modules/admin/type_filter/AdminTypePrototypeTypeVintageSelect.vue'

export default {
  name: 'AdminTypeFilterForm',
  components: { AdminTypePrototypeTypeVintageSelect },
  data() {
    return {
      typeFilter: new TypePrototype(),
      items: [],
      prototypesIconsAvailable: [
        '/icons/type-prototypes/single-family.svg', 
        '/icons/type-prototypes/multifamily.svg',
        '/icons/type-prototypes/hotel.svg',
        '/icons/type-prototypes/office.svg',
        '/icons/type-prototypes/retail-small.svg',
      ],
      type_prototypes: [],
      building_types: [],
      type_vintages: [],
    }
  },
  mounted() {
    this.getSelectOptions()
    if(this.$route.params.type_filter_id) {
      this.getTypeFilter()
    }
  },
  methods: {

    async getTypeFilter() {
      const query = `
        {
          type_${this.$route.params.type} (
            find: ${this.$route.params.type_filter_id}
          ) {
            id
            title
            slug
            order
            ${this.$route.params.type == 'prototype' ? 
              ` 
                building_stock_title 
                building_stock_helper 
                color 
                order
                hide_in_building_estimates
                building_type_id
                icon
                title_building_estimates_existing
                units_normalization
                building_type {
                  id
                  title
                }
                type_vintages {
                  id
                  title
                  pivot { 
                    pivot_order
                  }
                }
              ` : ''}
            ${this.$route.params.type == 'vintage' ? `
              color
              order
              title_long
              flexible_path_title
              hide_in_building_estimates
              type_prototypes {
                id
                title
                pivot { 
                  pivot_order
                }
              }
            ` : ''}

            ${this.$route.params.type}s {
              id
              title
              study {
                title
                released_at
              }
            }

            custom_fields {
              id
              item_id
              item_type
              data
            }
          }
        }
      `
      const { data } = await this.$graphql({ query, caller: 'AdminTypeFilterForm' })
      const type = this.$route.params.type
      data[`type_${this.$route.params.type}`][`${this.$route.params.type}s`].map(item => {
        item.study = new Study(item.study)
        return item
      })
      if (type === 'prototype') {
        this.typeFilter = new TypePrototype(data[`type_${this.$route.params.type}`])
      } else {
        this.typeFilter = data[`type_${this.$route.params.type}`]
      }

    },

    store() {
      this.$api.post(`/types/${this.$route.params.type}`, this.typeFilter, { loader: true })
        .then(() => {
          this.$toast.success('Filter successfully created')
          this.$router.push({ name: 'AdminTypeFilterList' })
        })
        .catch((err) => {
          this.$toast.error(err.response.data.feedback)
        })
    },

    update() {
      this.$api.put(`/types/${this.$route.params.type}/${this.typeFilter.id}`, this.typeFilter, { loader: true })
        .then(() => {
          this.$toast.success('Filter successfully updated')
          this.$router.go(-1)
        })
        .catch((err) => {
          this.$toast.error(err.response.data.feedback)
        })
    },

    getSelectOptions() {
      const query = `
        {
          ${this.$route.params.type}s (
            orderBy: [{column: "id", order: "asc"}]
          ) {
            id
            title
            slug
            study {
              title
              released_at
            }
          }

          building_types {
            id
            title
          }

          type_vintages {
            id
            title
          }

          type_prototypes {
            id
            title
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.items = data[`${this.$route.params.type}s`].map((obj) => {
            obj.study = new Study(obj.study)
            return obj
          })
          this.type_prototypes = data.type_prototypes
          this.building_types = data.building_types
        })

    },
  }
}
</script>
