import newBuildingsImpactEstimatesByYear from '@/business-logic/services/impact-algorithms/results/new-buildings/newBuildingsImpactEstimatesByYear'

export default (
  building_estimates,
  fuel,
  {
    all_electric_shares_without_policy = 25,
    all_electric_shares_with_policy = 25,
    effective_date = null,
    termination_date = null,
    installation_effects_years = 30,
    applicability_rate = 100,
    annual_penetration_rate = 100,
    delay_installation = 1,
    likelihood_installations_first_third = 0,
    likelihood_installations_second_third = 33,
    likelihood_installations_final_third = 66,
    care_program_enrollment_rate = 12
  },
  {
    currentThermsEmissionFactor,
    currentKwhEmissionFactor,
    kwhSavings,
    thermsSavings,
    annualBillSavings,
    initialCost,
    gridMaxRenewablesYear,
    gridMaxRenewablesLevel,
    currentGridRenewableLevel,
    subsidy_needed,
    five_year_payback,
    subsidy_needed_care,
    five_year_payback_care
  }) =>
{


  const impactEstimatesPerYearAndCummulative = newBuildingsImpactEstimatesByYear(building_estimates,
    fuel,
    {
      all_electric_shares_without_policy,
      all_electric_shares_with_policy,
      effective_date,
      termination_date,
      installation_effects_years,
      applicability_rate,
      annual_penetration_rate,
      delay_installation,
      likelihood_installations_first_third,
      likelihood_installations_second_third,
      likelihood_installations_final_third,
      care_program_enrollment_rate
    },
    {
      currentThermsEmissionFactor,
      currentKwhEmissionFactor,
      kwhSavings,
      thermsSavings,
      annualBillSavings,
      initialCost,
      gridMaxRenewablesYear,
      gridMaxRenewablesLevel,
      currentGridRenewableLevel,
      subsidy_needed,
      five_year_payback,
      subsidy_needed_care,
      five_year_payback_care
    })
  
    const lastInfo = impactEstimatesPerYearAndCummulative.pop()
  return {
    forecast_emissions_savings: lastInfo?.forecast_emissions_savings?.cummulative,
    forecast_initial_cost: lastInfo?.forecast_initial_cost?.cummulative,
    forecast_kwh_savings: lastInfo?.forecast_kwh_savings?.cummulative,
    forecast_lifecycle_savings: lastInfo?.forecast_lifecycle_savings?.cummulative,
    forecast_therms_savings: lastInfo?.forecast_therms_savings?.cummulative,
    forecast_units_affected: lastInfo?.forecast_units_affected?.cummulative,
    city_wide_subsidy_needed: lastInfo?.subsidy_needed?.cummulative,
    city_wide_five_year_payback: lastInfo?.five_year_payback?.cummulative,
    city_wide_subsidy_needed_care: lastInfo?.subsidy_needed_care?.cummulative,
    city_wide_five_year_payback_care: lastInfo?.five_year_payback_care?.cummulative
  }
}
