import dayjs from 'dayjs'
import getProjectionsInQuarter from './getProjectionsInQuarter'
import ImpactAssumptions from '@/models/ImpactAssumptions'
import { getSubsidyColumnsValues } from '@/business-logic/services/subsidy/getSubsidyColumnsValues'

/**
 * Get advanced projections BY QUARTER
 */
export default ({ 
  assumption, 
  building_stock_units,
  kwh_savings,
  therms_savings,
  annual_bill_savings,
  initial_cost,
  installation_effects_years,
  subsidy_needed,
  five_year_payback,
  subsidy_needed_care,
  five_year_payback_care
}) => {

  if (!(assumption instanceof ImpactAssumptions)) assumption = new ImpactAssumptions(assumption)

  const projectionsByQuarter = []
  let index = 1
  for(let currentQuarter = assumption.start_at; dayjs(currentQuarter).isBefore(dayjs(assumption.getAdvancedFinishAt)); currentQuarter = dayjs(currentQuarter).add(1, 'quarter').format('MM/DD/YYYY')) {
    const currentQuarterDate = dayjs(currentQuarter)

    const regularProjections = getProjectionsInQuarter({
      assumption,
      projectionsByQuarter,
      building_stock_units, 
      currentQuarterDate, 
      index,
      kwh_savings,
      therms_savings,
      annual_bill_savings,
      initial_cost,
      installation_effects_years
    })

    const subsidyProjections = getSubsidyColumnsValues({
      units: regularProjections.installationsStarted,
      subsidy_needed,
      five_year_payback,
      subsidy_needed_care,
      five_year_payback_care,
      care_program_enrollment_rate: assumption.care_program_enrollment_rate
    })


    projectionsByQuarter.push({ ...regularProjections, ...subsidyProjections })
    index++
  }
  return projectionsByQuarter
}
