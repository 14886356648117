import AdminPoliciesList from '@/modules/admin/policy/AdminPoliciesList'
import baseAdminMeta from '@/modules/admin/baseAdminMeta'

export default [
  {
    name: 'AdminPoliciesList',
    path: 'policy',
    component: AdminPoliciesList,
    meta: { ...baseAdminMeta, title: 'Policy List' }
  }
]