import { render, staticRenderFns } from "./PolicyOptionsContent.vue?vue&type=template&id=55474705&scoped=true"
import script from "./PolicyOptionsContent.vue?vue&type=script&lang=js"
export * from "./PolicyOptionsContent.vue?vue&type=script&lang=js"
import style0 from "./PolicyOptionsContent.vue?vue&type=style&index=0&id=55474705&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55474705",
  null
  
)

export default component.exports