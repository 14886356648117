<template>
  <BaseModal
    ref="baseModal"
    title="Debug Variables"
    modal-dialog-classes="min-w-xl"
    :hierarchy="MODAL_HIERARCHY.SECONDARY"
  >
    <div class="w-full flex flex-col mt-4">
      <div 
        v-for="debugVariable in debugVariables" 
        :key="`debug_version_debugVariable_${debugVariable.key}`"
      >
        <div 
          class="w-full flex items-center mt-3 psui-cursor-pointer" 
          @click.stop="toggleVariable(debugVariable.key)"
        >
          <div class="-mb-2">
            <span 
              class="btn-arrow text-2xl material-icons-round psui-text-gray-50 psui-flex-none transition-all psui-transform"
              :class="checkVariableIsOpened(debugVariable.key) ? 'open psui-rotate-90' : 'closed psui-rotate-0'"      
            >
              chevron_right
            </span>
          </div>
          <h4 class="ml-2 text-header-6 font-bold text-deepsky-900 text-left">
            {{ debugVariable.key }}
          </h4>
        </div>
        <div 
          v-if="checkVariableIsOpened(debugVariable.key)"
          class="w-full flex flex-col ml-4"
        >
          <div class="timeline mt-10">
            <div 
              v-for="(version, versionIndex) in getOrderedVersions(debugVariable)"
              :key="`debug_version_debugVariable_${debugVariable.key}_${versionIndex}`"
              class="
                timeline-item w-auto flex items-center ml-6 mr-14 mb-4 p-8 border rounded flex-wrap last:mb-0
                text-ash-450 border-dashed border-ash-300 bg-ash-100
                first:border-solid first:bg-deepsky-100 first:border-deepsky-450 first:text-deepsky-500
              "
            >
              <div>
                <h3 class="text-p-3 font-bold">
                  #{{ version.time }}
                </h3>
              </div>
              <div class="ml-auto flex-shrink-0 text-center">
                <h6 
                  class="text-header-1 font-bold mt-2 text-inherit cursor-pointer transition-all hover:opacity-80"
                  @click="toggleVersion(debugVariable, versionIndex)"
                >
                  {{ !checkVersionIsOpened(debugVariable, versionIndex) ? 'Show version' : 'Hide version' }}
                </h6>
              </div>
              <div 
                v-if="checkVersionIsOpened(debugVariable, versionIndex)"
                class="w-full"
              >
                <pre>{{ version.debugVariable }}</pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { MODAL_HIERARCHY } from '@/util/Enums.js'
import { find, toggleInArray } from '@igortrindade/lazyfy'
export default {
  name: 'ModalDebugVariables',
  data() {
    return {
      MODAL_HIERARCHY,
      debugVariables: [],
      openedVariables: [],
      openedVersions: []
    }
  },

  mounted() {

    this.$eventBus.$on('debug', this.addDebugVariable)

    this.$eventBus.$on('removeVariablesToDebug', (debugVariable) => {
      const key = Object.keys(debugVariable)[0]
      this.debugVariables.removeInArray({ key })
    })

    this.$eventBus.$on('openDebugVariablesModal', () => {
      if (this.$refs.baseModal) {
        this.$refs.baseModal.open()
      }
    })
  },

  beforeDestroy() {
    this.$eventBus.$off('openDebugVariablesModal')
    this.$eventBus.$off('debug')
    this.$eventBus.$off('removeVariablesToDebug')
    this.$refs.baseModal.showModal = false
  },

  methods: {

    addDebugVariable(debugVariable) {
      if(typeof debugVariable !== 'object') {
        console.warn(`Typeof debug varible should be object to list better for debug, given: `, typeof debugVariable)
      } else {

        const key = Object.keys(debugVariable)[0]
        const finded = find(this.debugVariables, { key })
        const time = new Date().getTime()
        
        if(finded) {
          let versionToUpdate = -1
          finded.versions.map((version, index) => {
            if(JSON.stringify(version.debugVariable) == JSON.stringify(debugVariable)) {
              versionToUpdate = index
            }
          })
          // Update the version time to reorder, if we add the item it will trigger some strange infinite loop in render that i didnt found yet
          if(versionToUpdate > -1) {
            finded.versions[versionToUpdate].time = time
          } else {
            finded.versions.push({time, debugVariable})
          }
        } else {
          this.debugVariables.push({ key, versions: [ { time, debugVariable }]})
        }
      }
    },
    
    checkVersionIsOpened(debugVariable, versionIndex) {
      return this.openedVersions.findInArray({ key: debugVariable.key, versionIndex })
    },

    toggleVersion(debugVariable, versionIndex) {
      toggleInArray(this.openedVersions, { key: debugVariable.key, versionIndex })
    },

    checkVariableIsOpened(key) {
      return find(this.openedVariables, { key })
    },

    toggleVariable(key) {
      toggleInArray(this.openedVariables, { key })
    },

    getOrderedVersions(debugVariable) {
      return debugVariable.versions.map((i) => i).sort((a, b) => b.time - a.time)
    }
  }

}
</script>

<style scoped lang="scss">
  .timeline-item {
    position: relative;
    h3:before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      box-sizing: content-box;
      background-color: #D4D8D9;
      border-width: 4px;
      border-color: white;    
      border-radius: 50%;
      left: -34px;
      margin-top: 5px;
      z-index: 1;
    }
    &:after {
      content: "";
      position: absolute;
      width: 2px;
      height: calc(100% + 18px);
      background-color: #D4D8D9;      
      left: -26px;
      top: 0;
    }

    &:last-of-type {
      &:after {
        height: 100%;
      }
    }
    &:first-of-type {
      &:after {
        height: calc(100% - 60px);
        top: 60px;
      }
      h3:before {
        border-color: #69A7BF;
        background-color: #69A7BF;
      }
      + div::after {
        top: -18px;
        height: calc(100% + 36px);
      }
    } // :first-of-type
  }
  .timeline-item ::v-deep {    
    .app-drawer-content-styles * {
      color: #81878A;
    }
  }
</style>
