// import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import GraphQL from '@/util/GraphQL'


// Please DO NOT USE this Enum. Use Enum from '@/util/Enums.js' 
export const STUDY_TYPES_DB_SLUGS = {
  EXISTING_BUILDINGS : 'existing-buildings',
  NEW_BUILDINGS : 'new-buildings'
}

// TODO - This should not be used. To get the study type label you should query by the getterGlobalStudyTypes or STUDY_TYPES_DB_SLUGS
export const STUDY_TYPES_DB_LABELS = {
  EXISTING_BUILDINGS: 'Existing Buildings',
  NEW_BUILDINGS: 'New Buildings'
}

export default {
  namespaced: true,
  
  state: {
    study_types: []
  },

  getters: {
    
    getterGlobalStudyTypes: (state) => (filters = null) => {
      return filters ? state.study_types.findAllInArray(filters) : state.study_types
    },

    getterGlobalStudyType: (state) => (filters = null) => {
      return filters ? state.study_types.findInArray(filters) : false
    },
    getterStudyTypeExistingBuildings(state, getters) {
      return getters['getterGlobalStudyType']({ slug : STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS })
    },
    getterStudyTypeNewBuildings(state, getters) {
      return getters['getterGlobalStudyType']({ slug : STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS })
    },
  },

  actions: {
    loadGlobalStudyTypes(context) {
      const query = `
        {
          study_types (
            orderBy: [{ column: "order", order: "ASC" }]
          ) {
            id
            title
            order
            slug
            title_alternate
          }
        }
      `
      GraphQL({ query })
        .then(({ data }) => {
          context.commit('setGlobalStudyTypes', { study_types: data.study_types})
        })
    }
  },

  mutations: {
    setGlobalStudyTypes(state, { study_types }) {
      state.study_types = study_types
    }
  }
}