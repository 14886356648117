<template>
  <div
    v-if="warningMessage && show" 
    id="footer-notes-by-domain" 
    class="psui-text-small"
    @click="show = false"
    v-html="warningMessage"
  />
</template>

<script>
export default {
  name: 'FooterNotesByDomain',
  data: () => ({
    show: true,
    showAlways : false
  }),
  computed: {
    isNotMainDomains() {
      const mainDomains = [
          'cee-production.vercel.app',
          'explorer.localenergycodes.com',
          'explorer-production-team.vercel.app',
      ]
      return !mainDomains.includes(window.location.hostname)
    },    
    isAdmin() {
      return this.$route.path.includes('admin')
    },
    isProductionApi() {
      return process.env.VUE_APP_BASE_API_URL === "https://explorer-api.localenergycodes.com"
    },
    warningMessage() {
      if (this.isNotMainDomains && this.isProductionApi && this.isAdmin) {
        return 'This admin reflects the production database, please use with caution!'
      } else if (this.isNotMainDomains && this.isProductionApi) {
        return "Production database! Do not create/update data!"
      } else {
        return ''
      }
    }
  },
  watch: {
    $route() {
      this.show = true
    }
  }
}
</script>

<style lang="scss">
  #footer-notes-by-domain {
    position: fixed;
    padding: 4px 16px;
    background-color: violet;
    z-index: 9999;
    top: 0;
    right: 0;
  }
</style>