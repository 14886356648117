
import { getStudyColumnsBySelectedStatus } from '@/util/Functions'

export default {
  
  getterStudyResultsColumns: (state, getters, rootState, rootGetters) => {
    const columns = { ... state.studyResultsColumns}
    const jurisdictionType = rootGetters['lastJurisdictionVisited']?.type
    const jurisdictionWideType = rootGetters['lastJurisdictionVisited']?.titles?.wide_type || jurisdictionType
    if (jurisdictionWideType) {
      ['newBuildings', 'existingBuildings'].forEach((key) => {
        
        const cityWideColumnGroup = columns[key].columnGroups.find((columnGroup) => columnGroup.key === 'city_wide_impact')
        cityWideColumnGroup.title = `${jurisdictionWideType} Estimates`
        
        const cityWideSubsidyColumnGroup = columns[key].columnGroups.find((columnGroup) => columnGroup.key === 'city_wide_subsidy_needed_columns')
        if(cityWideSubsidyColumnGroup) {
          cityWideSubsidyColumnGroup.title = `Subsidy Needed ${ jurisdictionWideType }`
  
          cityWideSubsidyColumnGroup.columns.forEach((column) => {
            column.description = `(${ jurisdictionWideType })`.toLowerCase()
            column.title = column.title.replace(' Subsidy',  '')
          })
        }
        
        if (jurisdictionType === 'City') {
          cityWideColumnGroup.hasHelper = {
            type: 'helper',
            id: 25
          }
        } else {
          cityWideColumnGroup.hasHelper = {
            type: 'helper',
            id: 26
          }
        }

      })
    }
    return columns
  },
  getterExistingBuildingsColumns: (state, getters) => getters.getterStudyResultsColumns['existingBuildings'],
  getterExistingBuildingsColumnsSelected(state, getters) {
    return getStudyColumnsBySelectedStatus(getters.getterStudyResultsColumns['existingBuildings'], true)
  },
  getterExistingBuildingsColumnsUnselected(state, getters) {
    return getStudyColumnsBySelectedStatus(getters.getterStudyResultsColumns['existingBuildings'], false)
  },

  getterNewBuildingsColumns: (state, getters) => getters.getterStudyResultsColumns['newBuildings'],
  getterNewBuildingsColumnsSelected(state, getters) {
    return getStudyColumnsBySelectedStatus(getters.getterStudyResultsColumns['newBuildings'], true)
  },
  getterNewBuildingsColumnsUnselected(state, getters) {
    return getStudyColumnsBySelectedStatus(getters.getterStudyResultsColumns['newBuildings'], false)
  }

}
