<template>
  <tbody class="pb-2">
    <PolicyEditRequirementsTableRow
      v-for="(row, index) in getRows"
      :key="index"
      :item="row"
      :index-row="index"
      :rows-length="getRows.length"
      :helper="`requirements-new-buildings-sf-2022-${tableGroup}-${row.fuel_slug}`"
      :columns="columns"
      :is-loading="isLoading"
      :disabled="disabled"
    />
    <tr
      v-if="hasTotal"
      class="psui-text-small psui-font-bold psui-text-gray-80 psui-border-b psui-border-gray-20 psui-bg-gray-10"
    >
      <td class="psui-sticky psui-z-10 psui-left-0 psui-mt-1 psui-bg-gray-10 psui-pr-4 psui-pl-3">
        <div class="psui-text-small">
          Total
        </div>
      </td>

      <td
        v-for="column of columns"
        :key="column.key"
        class="relative psui-text-right psui-text-small pr-4 psui-font-bold"
      >
        <span v-if="!isLoading && !disabled">{{ getFormattedTotalValue(column.key) }}</span>
        <span v-else-if="disabled && !isLoading">--</span>
        <SkeletonPolicyEditRequirementsTableInput v-else />
      </td>
    </tr>
  </tbody>
</template>

<script>
import PolicyEditRequirementsTableRow from './PolicyEditRequirementsTableRow.vue'
import formatStudyData from '@/formatters/formatStudyData'
import SkeletonPolicyEditRequirementsTableInput from '@/modules/app/policy/show/skeleton/SkeletonPolicyEditRequirementsTableInput.vue'

export default {
	name: 'PolicyEditRequirementsTableBody',
	components: { PolicyEditRequirementsTableRow, SkeletonPolicyEditRequirementsTableInput },
	props: {
		policy: {
			type: Object,
			required: true
		},
    policy_summary_data: {
			type: Array,
			required: true
		},
    columns: {
			type: Array,
			required: true
		},
		hasTotal:{
			type: Boolean,
			default: false
		},
		isLoading:{
			type: Boolean
		},
    disabled:{
      type: Boolean
    },
    tableGroup:{
      type: String
    },
	},
	data: () => ({
		rows: []
	}),
  computed: {
		getRows() {
			const rows = []
			for (let index = 0; index < this.policy_summary_data.length; index++) {
				const item = this.policy_summary_data[index]
				this.addRow(item, 0, `${index}`, rows)
			}
			return rows
		}
  },
  methods: {
    getFormattedTotalValue(key) {
      const value = this.getRows.reduce((acc, item) => acc + (item?.data?.[key] || 0), 0)
      return formatStudyData(key, value, {[key]: value})
    },
		addRow(item, deep = 0, index, rows) {
      item.deep = deep
			item.index = index
			rows.push(item)
			item.last_deep = item.items ? false : true
			if (item.items) {
				const items_child = [...item.items]
				for (let indexChild = 0; indexChild < items_child.length; indexChild++) {
					const item_child = items_child[indexChild]
					item_child.is_last = indexChild + 1 === items_child.length ? true : false
					this.addRow(item_child, deep + 1, `${index}-${indexChild}`, rows)					
				}
			}
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
