<template>
  <router-link
    v-tooltip="tooltip" 
    class="btn cursor-pinter"
    :class="[ getTheme, cssClass, fontSizeClass, `icon--${icon}`, `btn-${size}` ]"
    :to="to"
    :tag="to ? 'a': 'button'"
    @click.native="onClick()"
  >
    {{ label }}
    <img
      v-if="icon"
      :src="`/icons/${icon}.svg`"
      :alt="`Icon ${label}`"
    >
    <feather
      v-if="feather"
      :type="feather"
      class="ml-2"
    />
  </router-link>
</template>

<script>
export default {  
  name: 'AppButton',
  props: {
    label: {
      type: String,
      required: true
    },
    feather: {
      type: String
    },
    icon: {
      type: String,
    },
    theme: {
      type: String,
      default: 'bg-sec text-white'
    },
    cssClass: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'big',
      validator: (value) => ['medium', 'big'].indexOf(value) !== -1
    },
    disabled: {
      type: [Boolean, String]
    },
    to: {
      type: [String, Object],
      default: ''
    },
    tooltip: {
      type: [String, Boolean],
      default: false
    }
  },
  computed: {
    fontSizeClass() {
      switch (this.size) {
        case 'medium':
          return 'ts--accent--book--2'      
        default:
          return 'ts--accent--book--1'
      }
    },
    getTheme() {
      if (this.disabled) {
        return 'border-ash-300 bg-ash-300 opacity-100 text-white cursor-default'
      } else {
        return this.theme
      }
    },    
  },
  methods: {
    onClick() {
      if (this.disabled) return false
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">

  .btn {
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    border-radius: 24px;    
    text-transform: uppercase;    
    border-width: 2px;
    border-style: solid;
    transition: 300ms ease-in all;

    &-big {
      padding: 8px 30px;
    }
    &-medium {
      padding: 2px 30px;
    }
    img {
      margin-left: 10px;
    }
    
    &.icon--arrow-right-white:hover {
      img {
        transition: 400ms ease-in-out;
        transform: translateX(5px);
      }
    }
    
    &:hover {
      opacity: .9;
    }

    &.disabled {
      opacity: 1;
    }
  }

  svg {
    margin-left: 28px;
  }
</style>