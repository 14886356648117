<template>
  <PsAccordionItem
    title="Termination Date"
    class="display-impact-assumption-icon"
    :class="{'form-dirty': hasValueUpdated(getValuePerPrototype(assumption.key),assumption.key)}"
  >
    <template #header-additionals>
      <PsIcon
        icon="help_outline"
        size="16"
        display="flex"
        class="help psui-items-center psui-h-5 hover:psui-text-blue-60"
        :icon-classes="hasValueUpdated(getValuePerPrototype(assumption.key),assumption.key) ? 'mr-5': ''"
        @click.native.stop="$eventBus.$emit('openDescriptionModal', { slug: 'impact_termination_date_assumption', ...descriptionModelObject })"
      />
    </template>
    <template #default>
      <span>        
        {{ 'We assume the policy requirements will remain effective through:' | policyTextRephrase('small') }} 
      </span>
      <div class="flex flex-col items-start space-y-5/6 pt-4/5">
        <PsDropdown
          ref="PSDropdown"
          :data-test-id="`dropdown-assumption-${assumption.key}`"
          @click.native="checkIfUserCanEditAssumption"
          @open="open = true"
          @close="open = false"
        >
          <template #dropdownTrigger>
            <div
              class="dropdown-selected psui-rounded-md psui-border psui-border-gray-30 cursor-pointer psui-flex psui-relative hover:psui-border-green-20"
              :class="[{'open':open},{'psui-border-green-20': hasValueUpdated(getValuePerPrototype(assumption.key),assumption.key)} ]"
            >
              <span
                data-test-id="impacts-termination-date"
                class="psui-text-gray-60 psui-text-small"
              >
                <strong>{{ `Quarter ${ getFormatedDate(getValuePerPrototype(assumption.key)).quarter()}` }}</strong> 
                {{ `(${getFormatedDate(getValuePerPrototype(assumption.key)).startOf('quarter').format('MMM Do')} - ${getFormatedDate(getValuePerPrototype(assumption.key)).endOf('quarter').format('MMM Do')})` }}
              </span>
              <PsIcon
                v-if="hasValueUpdated(getValuePerPrototype(assumption.key),assumption.key)" 
                icon="restart_alt"
                icon-classes="psui-text-green-20 cursor-pointer invisible ml-auto"
                size="17"
                @click.native.stop="setDateValue(getFormatedDate(getBaseValue(assumption.key)), assumption.key)"
              />
            </div>
          </template> 
          <template #items>
            <ul class="dropdown-list psui-py-3">
              <Checkbox
                v-for="quarter in availableQuarters"
                :key="quarter.format('MM/DD')"
                class="w-full pl-5 py-1/2 hover:psui-bg-blue-10"
                checkbox-classe="m-0"
                :label="`<strong>Quarter ${quarter.quarter()}</strong> (${quarter.startOf('quarter').format('MMM Do')} - ${quarter.format('MMM Do')})`"
                :value="quarter.quarter() == getFormatedDate(getValuePerPrototype(assumption.key)).quarter()"
                type="radio"
                @input="setDateValue(quarter, assumption.key)"
              />
            </ul>
          </template>
        </PsDropdown>
        <PsInput
          :data-test-id="`input-assumption-${assumption.key}`"
          :class="{'selected':hasValueUpdated(getValuePerPrototype(assumption.key),assumption.key)}"
          :value="getFormatedDate(getValuePerPrototype(assumption.key)).year()"
          :has-error="inputErrorIndex ? 'Insert a valid value' : false"
          layout="mini"
          class="w-24"
          :hint="`${minYearTerminationDate} - ${maxYearTerminationDate}`"
          @click.native="checkIfUserCanEditAssumption"
          @change="setDateValue($event.target.value,assumption.key)"
        >
          <template #append>
            <PsIcon
              v-if="hasValueUpdated(getValuePerPrototype(assumption.key),assumption.key)" 
              icon="restart_alt"
              icon-classes="psui-text-green-20 cursor-pointer invisible"
              size="17"
              @click.native="setDateValue(getFormatedDate(getBaseValue(assumption.key)),assumption.key)"
            />
          </template>
        </PsInput>
      </div>
    </template>
  </PsAccordionItem>
</template>

<script>

import { ASSUMPTIONS_KEYS } from '@/util/Enums.js'
import dayjs from 'dayjs'
import AssumptionImpactsMixin from './AssumptionImpactsMixin'

export default {
  name: "AssumptionsImpactsTerminationDate",
  props: ['tabSelected', 'userCanEditPolicy', 'descriptionModelObject'],
  mixins: [ AssumptionImpactsMixin ],
  data:()=>({
    localAssumptionValue: {},
    open: false,
    minYearTerminationDate: 2023,
    maxYearTerminationDate: 2025,
    assumption:{
      key: ASSUMPTIONS_KEYS.termination_date
    }
  }),
  methods: {
    validateInput(value){ 
      const getYearFromValue = dayjs(value).year()
      return getYearFromValue <= this.maxYearTerminationDate && getYearFromValue >= this.minYearTerminationDate
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .psui-el-dropdown-menu{
    width: 100%;
    
    &-dialog-wrapper {
      margin-top: 8px;
    } 
  }

  .dropdown-list {
    width: 294px;
  }

  ::v-deep .dropdown-selected {
    padding: 7px 28px 7px 8px;
    transition: all ease 130ms;

    &:hover{
      .material-icons-round {
          visibility: visible;
        }
    }

    &.open {
      &::after{
        transform: rotate(180deg);
      }
    }

    &::after{
      content: 'expand_more';
      position: absolute;
      top: 20%;
      right: 6px;
      font-family: 'Material Icons Round';
      font-weight: normal;
      font-style: normal;
      font-size: 20px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      -webkit-font-feature-settings: 'liga';
      -webkit-font-smoothing: antialiased;
      transition: all 0.25s ease-in-out;
    }
  }

  ::v-deep .psui-el-input.status-resting.layout-mini.selected {
    .psui-el-input-wrapper {
      border-color: #5DB883;
    }

    &:hover {
      .material-icons-round {
        visibility: visible;
      }
    }
  }

  ::v-deep .psui-el-input.status-error.layout-mini {
    .psui-el-input-wrapper {
      .psui-el-input-append {
        display: none;
      }
    }
  }

  .psui-el-accordion-item {
    &.status-opened {
      .help {
        color: #318FAC;
      }
    }
  }
</style>
