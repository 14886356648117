<template>
  <BaseModal
    ref="baseModal"
  >
    <div class="w-full">
      <img
        src="/loading-header.svg"
        alt="Loading header"
      >
      <div class="content">
        <h1 class="psui-text-h4 font-bold psui-text-gray-80 mb-4">
          {{ title }}
        </h1>
        <span class="psui-text-p psui-text-gray-50">{{ description }}</span>

        <div class="flex justify-between mt-4">
          <PsButton
            v-if="showCloseBtn"
            label="Close"
            size="medium"
            class="psui-bg-blue-60"
            @click="close"
          />
          <PsDotLoader class="ml-auto" />
        </div>
      </div>
    </div>
  </BaseModal>
</template>


<script>

export default {
  name: 'LoadingModal',
  data() {
    return {
      title: '',
      description: '',
      showCloseBtn: true
    }
  },
  mounted() {
    this.$eventBus.$on('openLoadingModal', ({title, description, showCloseBtn}) => {
      this.$refs.baseModal.showModal = true
      this.title = title
      this.description = description
      this.showCloseBtn = showCloseBtn
    })

    this.$eventBus.$on('closeLoadingModal', () => {
      this.close()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openLoadingModal')
    this.$eventBus.$off('closeLoadingModal')
  },
  methods: {
    close() {
      this.$refs.baseModal.showModal = false
      setTimeout(() => {
        this.title = ''
        this.description = ''
        this.showCloseBtn = true
      }, 500)
    },
  }
}
</script>

<style lang="scss" scoped>
  .w-full {
    img {
      width: 100%;
    }

    .content {
      padding: 2rem;
    }
  }

  ::v-deep {
    .modal-dialog {
      padding: 0;
      > button.absolute {
        display: none;
      }
    }
  }
</style>
