export const columnTooltip = ({content, placement = 'top-center', classes = ['tooltip-bg-gray', 'tooltip-info']}) => {
  return { 
    content: content, 
    placement: placement,
    classes : classes
  }
}

export const showTooltipOnNegativeValue = (content = 'Negative numbers indicate savings') => {
  return ({value}) => {
    if ( value < 0 ) {
      return columnTooltip({content})
    } else {
      return false
    }
  }
}