<template>
  <BaseModal
    ref="baseModal"
    data-test-id="base-modal-duplicate-comparison"
    modal-dialog-classes="psui-w-full psui-max-w-sm"
    title="Duplicate to Comparisons"
    :show-modal-parent="showModalParent"
    @close="close"
  > 
    <ComparisonFormDuplicate
      v-if="comparison_id"
      :comparison-id="comparison_id"
      :title="title"
      @store-succcess="close"
    />
  </BaseModal>
</template>

<script>
import ComparisonFormDuplicate from './ComparisonFormDuplicate.vue'

const initialState = () => {
  return {
    comparison_id : false,
    title: '',
    showModalParent: false
  }
}

export default {
  name: 'ModalDuplicateComparison',
  components: { ComparisonFormDuplicate },
  data: () => ({
    ...initialState()
  }),
  computed: {
    validationTitle() {
      if(!this.title) return { hasError: true, label: 'Please inform the Comparison Name'}
      return { hasError: false }
    }
  },
  mounted() {
    this.$eventBus.$on('openDuplicateComparisonModal', ({ comparison }) => {
      this.showModalParent = true
      this.comparison_id = comparison.id
      this.title = comparison?.title + ` (2)`
    })
  },
  beforeDestroy() {    
    this.$eventBus.$off('openDuplicateComparisonModal')
  },
  methods: {
    close() {
      this.showModalParent = false
      this.resetState()
    },
    resetState() {
      Object.assign( this.$data, initialState())
    },
    
  }
}
</script>