<template>
  <div>
    <PsDraggable
      :get-columns="getColumns"
      @column-group-helper="$eventBus.$emit('openDrawerContent', {type: $event.type, slug: $event.slug})"
      @column-helper="$eventBus.$emit('openDescriptionModal', {type: $event.type, slug: $event.slug, button:$event.customButton})"
      @drag-start-group="dragStartGroup"
      @drag-end-group="dragEndGroup"
      @drag-over-group="dragOverGroup"
      @drag-start-item="dragStartItem"
      @drag-end-item="dragEndItem"
      @drag-over-item="dragOverItem"
      @on-select-item="toggleColumnIsActive"
    />
  </div>
</template>

<script>
import { GA_LABELS } from '@/mixins/GaEventsMixin'
import { mapGetters } from 'vuex'

export default {
  name: 'DragAndDropEditColumnsList',
  props: ['getColumns', 'module'],
  data() {
    return {
      // Column
      isDraggingColumnGroup: -1,
      isDraggingOverColumn: -1,
      isDraggingColumn: -1,

      // Group
      isDraggingGroup: -1,
      isDraggingOverGroup: -1,
    }
  },

  computed: {
    getColumnsStateKey() {
      return `${this.module}Columns`
    },
    getMoveColumnStoreMutationName() {
      return `${this.module}/moveColumnItem`
    },
    getMoveColumnGroupStoreMutationName() {
      return `${this.module}/moveColumnGroup`
    },
    getToggleColumnIsActiveStoreMutationName() {
      return `${this.module}/toggleColumnIsActive`
    },
    ...mapGetters(['lastJurisdictionVisited']),
  },

  methods: {
    toggleColumnIsActive({ studyKey, indexColumnGroup, indexColumn, columnGroupKey }) {
      this.handleGAEvent({ 
          studyKey, 
          indexColumnGroup, 
          indexColumn, 
          columnGroupKey, 
          type: 'toggle_column'  
        })

      let modifiedIndexColumnGroup = indexColumnGroup
      if(this.module == 'requirements') modifiedIndexColumnGroup += 1 
      
      this.$store.commit(this.getToggleColumnIsActiveStoreMutationName, { columnsStateKey: this.getColumnsStateKey, studyKey, indexColumnGroup: modifiedIndexColumnGroup, indexColumn, columnGroupKey })
    },

    /**
    * Drag and drop COLUMN
    */
    dragStartColumn({ indexColumnGroup, indexColumn }) {
      this.isDraggingColumn = indexColumn
      this.isDraggingColumnGroup = indexColumnGroup
    },
    dragEndColumn() {
      this.moveItemColumn({
        studyKey: this.getColumns.key, 
        indexColumnGroup: this.isDraggingColumnGroup,
        from: this.isDraggingColumn,
        to: this.isDraggingOverColumn
      })
      this.dragLeaveColumn()
      this.dragLeaveGroup()
    },

    dragLeaveColumn() {
      this.isDraggingColumn = -1
      this.isDraggingColumnGroup = -1
      this.isDraggingOverColumn = -1
    },

    dragStartItem({ indexColumnGroup, indexColumn }, event) {
      this.handleGAEvent({ 
          indexColumnGroup, 
          indexColumn,
          type: 'drag_column'  
        })

      let iColumnGroup = indexColumnGroup
      if(this.module == 'requirements') iColumnGroup+=1

      this.isDraggingColumn = indexColumn
      this.isDraggingColumnGroup = iColumnGroup

      event.target.classList.add("on-dragging")
      event.dataTransfer.dropEffect = "move"
    },

    dragEndItem(event) {
      this.moveItem({
        studyKey: this.getColumns.key, 
        indexColumnGroup: this.isDraggingColumnGroup,
        from: this.isDraggingColumn,
        to: this.isDraggingOverColumn
      })
      this.dragLeaveItem()
      this.dragLeaveGroup()
      event.target.classList.remove("on-dragging")
      event.target.classList.remove("on-drag-over")
    },

    dragOverItem({ indexColumnGroup, indexColumn }) {
      let iColumnGroup = indexColumnGroup
      if(this.module == 'requirements') iColumnGroup+=1
      
      if(this.isDraggingColumnGroup != iColumnGroup) return
      this.isDraggingOverColumn = indexColumn

      const colToDragginOut = document.getElementById(`edit-columns-column-${indexColumnGroup}-${indexColumn}`)
      if(colToDragginOut) {
        colToDragginOut.classList.add('is-dragging-out')
        setTimeout(() => colToDragginOut.classList.remove('is-dragging-out'), 10)
      }
    },

    dragLeaveItem() {
      this.isDraggingColumn = -1
      this.isDraggingColumnGroup = -1
      this.isDraggingOverColumn = -1
    },

    moveItem({ studyKey, indexColumnGroup, from, to}) {
      this.$store.commit(this.getMoveColumnStoreMutationName, { 
        columnsStateKey: this.getColumnsStateKey, 
        studyKey, 
        indexColumnGroup, 
        from, 
        to
      })
      this.blinkItems({ indexColumnGroup, to })
      this.dragLeaveItem()
    },

    blinkItems({ indexColumnGroup, to }) {
      let iColumnGroup = indexColumnGroup
      if(this.module == 'requirements') iColumnGroup-=1

      const colToBlink = document.getElementById(`edit-columns-column-${iColumnGroup}-${to}`)
      if(colToBlink) {
        colToBlink.classList.add('blink')
        setTimeout(() => colToBlink.classList.remove('blink'), 800)
        colToBlink.classList.remove('on-drag-over')
      }
    },

    /**
    * Drag and drop GROUP
    */
    dragStartGroup(indexColumnGroup) {
      let columnGroup = indexColumnGroup
      this.handleGAEvent({ 
        indexColumnGroup, 
        type: 'drag_group',  
      })

      if(this.module == 'requirements') columnGroup+=1

      this.isDraggingGroup = columnGroup
    },
    dragEndGroup() {
      this.moveItemGroup({ 
        studyKey: this.getColumns.key,
        from: this.isDraggingGroup,
        to: this.isDraggingOverGroup
      })
      this.dragLeaveGroup()
    },
    dragOverGroup(indexColumnGroup) {
      if (this.isDraggingGroup < 0) return

      let columnGroup = indexColumnGroup

      if (this.module === 'requirements') {
        const adjustedColumnGroup = columnGroup + 1
        this.isDraggingOverGroup = adjustedColumnGroup
      } else {
        this.isDraggingOverGroup = columnGroup
      }
      const colToDragginOut = document.getElementById(`edit-group-${columnGroup}`)

      if (colToDragginOut) {
        colToDragginOut.classList.add('is-dragging-out')
        setTimeout(() => colToDragginOut.classList.remove('is-dragging-out'), 800)
      }
    },
    dragLeaveGroup() {
      this.isDraggingGroup = -1
      this.isDraggingOverGroup = -1
    },
    moveItemGroup({ studyKey, from, to}) {
      this.$store.commit(this.getMoveColumnGroupStoreMutationName, { 
        columnsStateKey: this.getColumnsStateKey,
        studyKey,
        from,to
      })
    },
    handleGAEvent(data){
      let itemSlug
    
      if(data.indexColumn) {
        itemSlug = this.getColumns
        .columnGroups[data.indexColumnGroup]
        .columns[data.indexColumn]
        .key
      } else {
        itemSlug = this.getColumns
        .columnGroups[data.indexColumnGroup]
        .key
      }

      this.gaEvent(GA_LABELS.COLUMNS_CHANGE,
      {
        type: data.type,
        jurisdiction_slug: this.lastJurisdictionVisited.slug,
        item: itemSlug
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .draggable-item {
    padding: 7px 16px;
  }

  ::v-deep.psui-el-checkbox {
    .psui-el-checkmark {
      color: #798490;
      &:hover {
          color: #515E6A;
      }
    }
  }  

  ::v-deep.psui-el-checkbox.size-small{
    .psui-el-checkmark:hover:before {
      color: #64B5CE;
    }
  }

  ::v-deep.psui-el-draggable {
    .psui-el-draggable-item:has(.psui-el-checkbox.disabled) {
      cursor: unset;
      opacity: 0.5;
    }
  }
</style>
