<template>
  <div class="w-full flex flex-col space-y-4">
    <div class="w-full flex justify-between">
      <h3 class="w-2/5 h2-admin-blue psui-text-sky-80">
        Most Searched Jurisdictions
      </h3>

      <PsButton
        class="w-auto"
        :label="`See all jurisdictions insights`"
        @click="$router.push({ params: { jurisdiction_id: 'all' } })"
      />
    </div>
    <DashboardCard
      v-for="jurisdiction in getJurisdictionsList"
      :key="jurisdiction.id"
      class="w-full flex bg-white psui-w-full h-auto psui-rounded-md psui-bg-white psui-shadow-elevation-5 p-8"
    >
      <div class="w-2/5  flex flex-col items-start">
        <h3 class="h2-admin-blue">
          {{ jurisdiction.title }} - {{ jurisdiction.type }}
        </h3>
        <PsButton
          :label="`See ${ jurisdiction.title } insights`"
          @click="goToJurisdiction(jurisdiction)"
        />
      </div>
      <div class="w-1/5 flex flex-col">
        <h4 class="h4-admin-gray">
          Total
        </h4>
        <h2 class="h2-admin-blue">
          {{ jurisdiction.views_count }}
        </h2>
      </div>
      <div class="w-1/5 flex flex-col">
        <h4 class="h4-admin-gray">
          Non logged
        </h4>
        <h2 class="h2-admin-blue">
          {{ getJurisdictionSearchsByUser(jurisdiction.id, 'non_logged_users') }}
        </h2>
      </div>
      <div class="w-1/5 flex flex-col">
        <h4 class="h4-admin-gray">
          Platform users
        </h4>
        <h2 class="h2-admin-blue">
          {{ getJurisdictionSearchsByUser(jurisdiction.id, 'platform_users') }}
        </h2>
      </div>
    </DashboardCard>
  </div>
</template>

<script>
export default {
  name: 'MostSearchedJurisdictions',
  props: ['timeFrameSelected', 'jurisdictionSelected'],
  data() {
    return {
      platform_users: [],
      non_logged_users: [],
    }
  },
  computed: {
    getJurisdictionsList() {
      return [ ...this.platform_users, ...this.non_logged_users ].reduce((acc, jurisdiction) => {
        if(acc.findInArray({ id: jurisdiction.id })) {
          acc.findInArray({ id: jurisdiction.id }).views_count += parseInt(jurisdiction.views_count)
        } else {
          acc.push({ ...jurisdiction, views_count: parseInt(jurisdiction.views_count), title_type: `${jurisdiction.title} - ${ jurisdiction.type }` })
        }
        return acc
      }, []).sort((a, b) => b.views_count - a.views_count)
    },
  },
  watch: {
    timeFrameSelected: {
      deep: true,
      handler() {
        this.getData()
      } 
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$api.get('/api/admin/city/dashboard/get_data/most_search_jurisdictions', { params: {
        init: this.timeFrameSelected.init, 
        end: this.timeFrameSelected.end 
      }})
        .then(({ data }) => {
          this.platform_users = data.platform_users
          this.non_logged_users = data.non_logged_users
        })
    },

    getJurisdictionSearchsByUser(jurisdictionId, typeOfUser) {
      return this[typeOfUser].findInArray({ id: jurisdictionId })?.views_count ?? 0
    },

    goToJurisdiction(jurisdiction) {
      this.$emit('update:jurisdiction', jurisdiction)
    }
  }
}
</script>