<template>
  <tr class="psui-text-gray-70 border-b psui-border-gray-20">
    <td class="title-wrapper psui-sticky psui-z-10 psui-left-0 psui-mt-1 psui-bg-white psui-pr-4 psui-pl-3">
      <div class="psui-bg-white psui-text-small psui-truncate">
        <span
          class="psui-flex psui-items-center psui-space-x-1 opacity-100-childrens-on-hover"
          :class="helper"          
          @mouseenter="showHelper = true"
          @mouseleave="showHelper = false"
        >
          <span>{{ item.title }}</span>
          <PsIcon
            icon="info_outline"
            size="16"
            class="info-outline-icon-open-modal psui-items-center psui-justify-center psui-cursor-pointer psui-opacity-0 transition-all psui-leading-none"
            :style="{ display: 'flex', marginTop: '2px', marginLeft: '4px', visibility: showHelper ? 'unset' : 'hidden' }"
            @click.native.stop="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: helper })"
          />
        </span>
      </div>
    </td>
    <td
      v-for="column of columns"
      :key="column.key"
      class="relative psui-text-right psui-text-small pr-4"
    >
      <span 
        v-if="!isLoading && !disabled"
        v-tooltip="getColumnTooltip({ column: column, value: item.data[column.key] })"
      >
        {{ formatStudyData(column.key, item.data[column.key] || 0, item.data, getStudyId) }}
      </span>
      <span v-else-if="disabled && !isLoading">--</span>
      <SkeletonPolicyEditRequirementsTableInput v-else />
    </td>
  </tr>
</template>

<script>
import SkeletonPolicyEditRequirementsTableInput from '@/modules/app/policy/show/skeleton/SkeletonPolicyEditRequirementsTableInput.vue'

export default {
  name:'PolicyEditRequirementsTableRow',
  components: {  SkeletonPolicyEditRequirementsTableInput },
  props:  {
    item: {
      type: Object,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    indexRow: {
      type: Number,
      required: true
    },
    rowsLength: {
      type: Number,
      required: true
    },
    isLoading:{
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    helper: {
			type: String,
		},
  },  
  data: () =>  ({    
    showHelper: false,
  }),
  computed: {
    getStudyId(){
      return this.item?.studyData?.study_id 
    }
  },
  methods: {
    getColumnTooltip({ column, value }) {
      if (!column.hasTooltipOnContentCell) return false
      return column.hasTooltipOnContentCell({column, value})
    },
  }
}
</script>

<style scoped lang="scss">
  @mixin pseudo {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }

  .title-wrapper {
    min-width: 150px;
  }

  tr {
    &.type-total {      
      td {
        &:before {
          @include pseudo;
          border-right-width: 0;
          border-left-width: 0;
        }
        &:first-of-type::before {
          background: #f6f9fb;
          border-color: #e4eef2;
          border-left-width: 1px;
          border-top-width: 1px;
          border-bottom-width: 1px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }        
      }
    }

    &.type-vintage {      

      td {
        &:before {
          @include pseudo;
          border: 1px solid #82C2DD50;
          border-right-width: 0;
          border-left-width: 0;
        }
        &:first-of-type::before {
          border-left-width: 1px;
          left: 60px;
          border-top-left-radius: 4px;
        }
        &:last-of-type::before {
          border-right-width: 1px;
          right: 20px;
          border-top-right-radius: 4px;
        }
      }

      &.cell-pb-20 {
        td {          
          &:first-of-type::before {
            border-bottom-left-radius: 4px;
          }
          &:last-of-type::before {
            border-bottom-right-radius: 4px;
          }
        }
      }

    }

    &.type-study_data {
      &.is-last td::before {
        @include pseudo;
      }      
      td:first-of-type {
        &:before {
          @include pseudo;
          left: 60px !important;
          border-left: 1px solid #82C2DD50;
        }
      }
      td:last-of-type {
        &:before {
          @include pseudo;
          right: 20px;
          border-right: 1px solid #82C2DD50;
        }
      }      
    }

    &.cell-pb-20 {
      td {
        padding-bottom: 15px;
        &:before {
          bottom: 10px !important;
          border-bottom: 1px solid #82C2DD50;
        }
        &:last-of-type::before {
          right: 20px;
        }
      }
    }
  }

  .info-outline-icon-open-modal {
    color: #798490; // psui-text-gray-50

    &:hover {
      color: #318FAC; // psui-text-blue-60  
    }
  }
</style>