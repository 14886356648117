<template>
  <div class="w-full">
    <PoliciesListTableSkeleton v-if="isLoading" />
    <div
      v-else
      id="policiesListTable"
      class="w-full flex flex-wrap"
    >
      <template v-if="getAllUserPolicies.length > 0">
        <PoliciesListTable        
          :policies="policiesFiltered"
          :style="{ maxHeight: policiesListTableHeight, minHeight: '255px' }"
        />
        <div 
          id="policiesListTableFooter"
          class="psui-flex psui-ml-6 psui-mb-4 psui-text-small"
        >
          <!-- data-test-id="policies-list-table-footer" -->
          <p class="psui-text-gray-60">
            {{ `You are seeing ${policiesFiltered.length}/${getAllUserPolicies.length} policies` | policyTextRephrase }}
          </p>
          <a
            v-if="hasAnyFilterApplied"
            data-test-id="remove-filters-policy-list"
            class="psui-font-bold ml-1 psui-text-blue-60 psui-cursor-pointer"
            @click="removeFilters"
          >Remove Filters to see all</a>
        </div>
      </template>
      <PoliciesListUserHasNoPolicies v-else />
    </div>
  </div>
</template>

<script>
import PoliciesListTableSkeleton from './PoliciesListTableSkeleton.vue'
import PoliciesListUserHasNoPolicies from './PoliciesListUserHasNoPolicies.vue'
import PoliciesListTable from './PoliciesListTable.vue'
import { mapGetters } from 'vuex'
import { POLICY_TYPES } from '@/modules/app/policy/shared/enums.js'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import { checkIfPolicyIsOutdated } from '@/util/Helpers'

export default {
  name: 'PoliciesListUserPolicies',
  components: {
    PoliciesListTable,
    PoliciesListTableSkeleton,
    PoliciesListUserHasNoPolicies,
  },
  props: {
    'is-loading' : {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    policiesListTableHeight: null,
    policiesFiltered: [],
  }),
  computed: {
    getLoggedUser() {
      return this.$store.state.auth.loggedUser
    },
    hasAnyFilterApplied(){
      return this.policiesFiltered.length !== this.getAllUserPolicies.length
    },
    getAllUserPolicies() {
      return this.$store.getters['policy/getterUserPolicies']()
    },
    filterByAuthor() {
      return this.$store.state.auth.user_preferences?.policies?.myPolicies?.filterByAuthorV3 ?? []
    },
    listOfJurisdictionIdsThatShouldBeIncluded() {
      return this.$store.state.auth.user_preferences?.policies?.myPolicies?.filterByJurisdictionV2 ?? []
    },
    getStudyTypeSlugFromRoute() {
      return this.$route.query.only_study_type
    },
    getAllUserPoliciesByStudyType () {
      const study_type_slugs = this.getStudyTypeSlugFromRoute?.split(',') ?? []
      return this.$store.getters['policy/getterUserPoliciesByStudyType']({ study_type_slugs }) 
    },
    policiesPreFiltered() {
      return this.getAllUserPoliciesByStudyType
      .filter(this.filterPoliciesByJurisdiction)
      .filter(this.filterPoliciesByAuthor)
    },
    ...mapGetters(['getterLoggedUser'])
  },
  watch: {
    policiesPreFiltered: {
      deep: true,
      immediate: true,
      handler(policiesFiltered) {
        const newPoliciesFiltered = [...(policiesFiltered || [])]
        newPoliciesFiltered.forEach((policy) => {
          policy.isOutdated = checkIfPolicyIsOutdated(policy).has_outdated_studies
        })
        this.policiesFiltered = newPoliciesFiltered
      }
    },
  },
  created() {
    window.addEventListener("resize", this.setDynamicHeightToElement)
  },
  destroyed() {
    window.removeEventListener("resize", this.setDynamicHeightToElement)
  },
  mounted() {
    this.$store.commit('assumptions/closeDrawer')
    this.setDynamicHeightToElement()
  },
  methods: {
    filterPoliciesByAuthor(policy) {
      let shouldInclude = false
      const policyType = this.$store.getters['policy/getterPolicyType'](policy.id)

      this.filterByAuthor.forEach(policy_type_selected => {
        if (policy_type_selected === policyType) shouldInclude = true
        if (policyType === POLICY_TYPES.DRAFT.value && policy_type_selected === POLICY_TYPES.OWN.value) shouldInclude = true
      })

      return shouldInclude || !this.filterByAuthor.length

    },
    filterPoliciesByJurisdiction(policy) {
      const shouldIncludeAllJurisdisctions = !this.listOfJurisdictionIdsThatShouldBeIncluded || this.listOfJurisdictionIdsThatShouldBeIncluded.length === 0
      const policyJurisdictionIsSelected = this.listOfJurisdictionIdsThatShouldBeIncluded.includes(policy.jurisdiction_id.toString())
      return shouldIncludeAllJurisdisctions || policyJurisdictionIsSelected ? true : false
    },
    removeFilters(){
      this.$store.dispatch('setUserPreferences', {mutation:'resetUserPreferencesFilterByJurisdictionAndFilterByAuthor'})
      
      if(this.$route.query.only_study_type?.split(',')?.length !== 2) {
        this.$router.push({query:{only_study_type:`${Object.values(STUDY_TYPES_DB_SLUGS).join()}`}})
      }
    },
    setDynamicHeightToElement() {
      const policiesListTable = document.getElementById('policiesListTable')
      const policiesListTableFooter = document.getElementById('policiesListTableFooter')

      if (policiesListTable) {
        const boundTop = policiesListTable.getBoundingClientRect().top
        const boundBottom = this.getTotalHeightFromElement(policiesListTableFooter)
        this.policiesListTableHeight = `calc(95vh - (${boundTop}px + ${boundBottom}px))`
      }
      return this.policiesListTableHeight
    },
    getTotalHeightFromElement(el) {
      el = (typeof el === 'string') ? document.querySelector(el) : el
      if ( el === null) return

      let styles = window.getComputedStyle(el)
      let margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom'])

      return el.offsetHeight + margin
    },
  }
}
</script>

<style lang="scss" scoped>
  ::v-depp .psui-el-chips.status-resting.layout-simple.type-checkbox {
    label {
      color: #515E6A;
    }
  }
</style>
