<template>
  <div class="psui-w-full psui-flex psui-flex-col psui-items-center psui-py-8 psui-px-6 psui-text-center">
    <img
      src="/images/comparison/choosing-study.png"
      alt="Choosing Study"
      class="psui-inline-block psui-mb-6"
    >
    <p 
      class="psui-text-h5 psui-font-bold psui-text-gray-70 psui-mb-2"
    >
      {{ getComparisionPageDescriptionBasedOnUserPolicies }}
    </p>
    <p
      v-if="getUserHasOnlyNewBuildingPolicies"
      class="psui-text-p psui-text-gray-60 psui-mb-6"
    >
      The <span class="psui-italic">Compare</span> feature is available for existing building policies only <br>(Yes, we are expanding functionality to support new building policies too) 
    </p>
    <p 
      v-else-if="getUserIsLoggedIn"
      class="psui-text-p psui-text-gray-60 psui-mb-6"
    >
      Create at least two existing building policies for the same city/county to use the <span class="psui-italic">Compare</span> feature <br>(We are working on expanding this functionality to support new building policies too)
    </p>
    <p
      v-else 
      class="psui-text-p psui-text-gray-60 psui-mb-6"
    >
      By the way, the <span class="psui-italic">Compare</span> feature is currently available only for <span class="psui-font-bold">existing building policies</span> <br>(We are working to expand this functionality to support new building policies too)
    </p>
    <div class="flex gap-2">
      <PsButton
        label="Create an Existing Building Policy"
        size="medium"
        @click="goToPolicyOptionsExistingBuildingsTab()"
      />
      <PsButton
        v-if="getUserHasNoPoliciesOnTheCurrentJurisdiction"
        label="Select a Different Jurisdiction"
        size="medium"
        layout="ghost"
        @click="takeToJurisdictionSelect"
      />
    </div>
  </div>
</template>

<script>
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import { jurisdictionTitleTypeBasedOnJurisdictionType } from '@/util/Helpers.js'

const COMPARINSONPAGEDESCRIPTION = {
  loggedUser: 'You do not have any policies saved',
  notloggedUser: 'Hmmm... we did not find any supported policies for the',
  onlyNewBuildingPolicies: 'New Building policies for'
}

export default {
  name: 'ComparisonPoliciesEmpty',
  props: {
    jurisdiction: {
      type: [Object, Boolean],
      default: false
    },
  },
  computed: {
    getComparisionPageDescriptionBasedOnUserPolicies(){
      return this.getUserHasOnlyNewBuildingPolicies ? 
      `${this.getUserNewbuildingPoliciesLength} ${COMPARINSONPAGEDESCRIPTION.onlyNewBuildingPolicies} ${jurisdictionTitleTypeBasedOnJurisdictionType(this.jurisdiction, 'title_type')} are hidden` :
      this.getUserIsLoggedIn ? COMPARINSONPAGEDESCRIPTION.loggedUser : 
      `${COMPARINSONPAGEDESCRIPTION.notloggedUser} ${this.jurisdiction.titles?.title_type}`
    },
    getUserIsLoggedIn(){
      return this.$store.getters['getterLoggedUser']
    },
    getUserHasNoPoliciesOnTheCurrentJurisdiction(){
      return !this.$store.getters['policy/getterJurisdictionsFromUserPolicies'].findInArray({id: this.jurisdiction?.id}) && this.$store.getters['policy/getterUserPolicies']().length > 0 ? `for ${this.jurisdiction.type === 'City' || this.jurisdiction.type === 'State' ? 'the' : ''} ${jurisdictionTitleTypeBasedOnJurisdictionType(this.jurisdiction, 'title_type')}` : null
    },
    getUserHasOnlyNewBuildingPolicies(){
      return this.$store.getters['policy/getterUserPolicies']().length !== 0 ?
      this.$store.getters['policy/getterUserPolicies']().map(policy => policy.policy_containers.map(container => container.study_type.slug)[0])
      .every(element => element === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS) : false
    },
    getUserNewbuildingPoliciesLength(){
      return this.$store.getters['policy/getterUserPolicies']()
      .map(policy => policy.policy_containers.map(container => container.study_type.slug)[0])
      .filter(policy_type => policy_type === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS).length
    }
  },
  methods: {
    goToPolicyOptionsExistingBuildingsTab() {
      this.$router.push({
        name: 'PolicyOptions',
        query: { only_study_type: STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS }
      })
    },
    takeToJurisdictionSelect(){
      this.$store.commit('comparison/setShowComparisonJurisdictionSelect', true)

      setTimeout(()=>{
        document.getElementById('comparison-jurisdiction-select').children[0].lastElementChild.focus()
      },100)
    }
  },
}
</script>

<style>

</style>