<template>
  <div class="w-full flex flex-col bg-white">
    <div class="w-full flex flex-col bg-ash-200">
      <div class="w-full p-6 px-8 border-b border-ash-200 bg-white flex justify-between">
        <PageTitle title="Policies" />
      </div>
    </div>

    <div class="w-full h-auto flex px-8 py-4 justify-end space-x-4">
      <div class="w-full flex flex-col items-start relative">
        <VueSelect 
          v-model="userSelected"
          class="vue-select mt-6"
          placeholder="Filter by user"
          :class="userSelected ? 'filled' : ''"
          label="email"
          :options="users"
          @input="getPolicies()"
        />
      </div>

      <PsDropdown ref="dropdownTimeFrame">
        <template #dropdownTrigger>
          <div class="w-full flex space-x-4">
            <InputWithLabel
              :value="timeFrameSelected.init"
              class="mt-6"
              :label="'Init'"
              :mask="'####-##-##'"
              placeholder="YYYY/MM/DD"
              @blur="timeFrameSelected.init = $event"
            />
            <InputWithLabel
              :value="timeFrameSelected.end"
              class="mt-6"
              :label="'End'"
              :mask="'####-##-##'"
              placeholder="YYYY/MM/DD"
              @blur="timeFrameSelected.end = $event"
            />
          </div>
        </template>
        <template #items>
          <div class="w-full flex flex-col bg-white p-3 space-y-3 rounded">
            <PsButton
              v-for="timeFrame in getTimeFramesForAnalysis" 
              :key="timeFrame.label" 
              :label="timeFrame.label"
              @click="setTimeFrame(timeFrame)"
            />
          </div>
        </template>
      </PsDropdown>
    </div>
    
    <div class="p-6">
      <table class="table table-bordered mt-6 whitespace-nowrap">
        <thead>
          <tr>
            <td>
              Policy ID <OrderByArrowToggle
                column="id"
                :order-by="orderBy"
                @setOrderBy="setOrderBy"
              />
            </td>
            <td>User</td>
            <td>Title</td>
            <td>Custom combination qty</td>
            <td>
              Created at <OrderByArrowToggle
                column="id"
                :order-by="orderBy"
                @setOrderBy="setOrderBy"
              />
            </td>
            <td>User location</td>
            <td>Exclude in reports</td>
            <td class="psui-w-32">
              Actions
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="policy in policies"
            :key="policy.id"
          >
            <td>{{ policy.id }} ({{ policy.fixed_places_id }})</td>
            <td>{{ policy.title }}</td>
            <td>
              <span v-if="policy.user">Logged: {{ policy.user.email }}</span>
              <div v-else-if="policy.user_device">
                <span v-if="policy.user_device.user">Not Logged: identified from the device - {{ policy.user_device.user.email }}</span>
                <span v-else>Device not Logged yet: ID {{ policy.user_device.id }} {{ policy.user_device.platform }} - {{ policy.user_device.browser }}</span>
              </div>
              <span v-else>Unidentified user or device</span>
            </td>
            <td>{{ policy.custom_combinations.length }}</td>
            <td>{{ policy.created_at }}</td>
            <td>
              <span v-if="!policy.user_event_policy_created || !policy.user_event_policy_created.city">--</span>
              <span v-else>{{ policy.user_event_policy_created.city }} - {{ policy.user_event_policy_created.country }}</span>
            </td>
            <td>
              <PsSwitch 
                label="Exclude in report" 
                :value="policy.exclude_in_report"
                @change="toggleExcludeInReport(policy)"
              />
            </td>
            <td class="psui-flex space-x-3">
              <router-link 
                :to="{ name: 'PolicyShow', params: { policy_id: policy.id }}"
                target="_blank"
              >
                <PsButton 
                  label="Show"
                  size="medium"
                  icon="open_in_new"
                />
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>

      <Paginator
        v-model="offset"
        :length="policies.length"
        :limit="limit"
        @getItems="getPolicies(offset)"
      />
    </div>
  </div>
</template>

<script>
import getTimeFramesForAnalysis from '@/util/getTimeFramesForAnalysis'
export default {
  name: 'AdminUsersList',
  data() {
    return {
      policies: [],
      offset: 0,
      limit: 50,
      orderBy: {
        column: "id",
        order: "DESC"
      },
      getTimeFramesForAnalysis,
      timeFrameSelected: getTimeFramesForAnalysis[9],
      users: [],
      userSelected: null,
    }
  },
   watch: {
    timeFrameSelected: {
      deep: true,
      handler() {
        this.getPolicies()
      } 
    }
  },
  mounted() {
    this.getPolicies()
    this.getUsers()
  },
  methods: {

    getPolicies(offset = 0) {
      this.offset = offset
      
      const query = `
        { 
          policies (
            whereBetween: [{ column: "created_at", init: "${this.timeFrameSelected.init}", end: "${this.timeFrameSelected.end}" }]
            ${ this.getUserFilter() }
            orderBy: [{ column: "${this.orderBy.column}", order: "${this.orderBy.order}" }]
            ${this.$route.query.user_id ? `where: [{ column: "user_id", operation: "=", value: "${this.$route.query.user_id}"}]` : ''}
            paginate: { offset: ${offset}, limit: ${this.limit}}
          ) {
            id
            fixed_places_id
            title
            created_at
            updated_at
            exclude_in_report
            user { email }
            custom_combinations {
              id
            }
            user_device {
              id
              platform
              browser
              user {
                id
                email
              }
            }

            user_event_policy_created {
              city
              region
              country
            }
          }
        }
      `

      this.$graphql({ query })
        .then(({ data }) => {
          this.policies = data.policies
        })
    },

    getUserFilter() {
      if(this.userSelected) {
        return `
          where: [{ column: "user_id", value: "${ this.userSelected.id }" }]
          orWhereIn: [{ column: "user_device_id", value: [${this.userSelected.user_devices.map(i => `"${i.id}"`).join(',')}] }]
        `
      }
      return ``
    },

    setOrderBy(column) {
      console.log(column)
      if(this.orderBy.column == column) {
        this.orderBy.order = this.orderBy.order == 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.orderBy.column = column
        this.orderBy.order = 'ASC'
      }
      this.getPolicies()
    },

    toggleExcludeInReport(policy) {
      this.$api.post(`/api/admin/policy/update/${policy.id}`, { exclude_in_report: !policy.exclude_in_report})
        .then(() => {
          this.getPolicies(this.offset)
        })
    },

    setTimeFrame(timeFrame) {
      this.timeFrameSelected = timeFrame
    },

    getUsers() {
      const query = `
        {
           users (
            orderBy: [
              { column: "is_admin", order: "DESC" },
              { column: "email", order: "ASC" },
            ]
           ) {
            id
            email
            user_devices {
              id
            }
           }
        }
      `

      this.$graphql({ query, caller: 'AdminUserDeviceEditModal' })
        .then(({ data }) => {
          this.users = data.users
        })
    },
  }
}
</script>

<style>

</style>