<template>
  <div class="psui-flex psui-gap-6 w-full">
    <vue-skeleton-loader 
      v-for="index in 2"
      :key="index"
      :height="88"
      :width="containerWidth/2 - 207"
    />
    <vue-skeleton-loader
      :height="18"
      :width="18"
    />
  </div>
</template>

<script>
export default {
    name: "PolicyRequirementFuelCellSkeleton",
    props:['containerWidth'],
}
</script>
