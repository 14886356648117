import { defaultExistingBuildingsImpactAssumptionValues } from '@/models/ImpactAssumptions'
import ComparisonApiService from '@/services/api/ComparisonApiService'
import Comparison from '@/models/Comparison'
import { ERRORS } from '@/util/Enums'

import {
  applicability_rate,
  annual_penetration_rate,
  effective_date,
  delay_installation,
  clean_energy_progress,
  current_grid_renewable_level,
  baseline_installation_rate,
  current_kwh_emissions_factor,
  current_therms_emissions_factor,
} from './columns'

import JurisdictionApiService from '@/services/api/JurisdictionApiService'
import Jurisdiction from '@/models/Jurisdiction'

const NULLED_VALUE = 'nulled'

export default {

  getAvailableJurisdictionsgetJurisdictions(context, { additional_jurisdiction_ids }) {
    const jurisdictions_from_user_policies = context.rootGetters['policy/getterJurisdictionsFromUserPolicies'] ?? []
    const jurisdiction_ids = [ ...jurisdictions_from_user_policies.map(jurisdiction => jurisdiction.id), ...additional_jurisdiction_ids ]

    return JurisdictionApiService.getByIds(jurisdiction_ids)
      .then((jurisdictions) => {
        context.commit('setAvailableJurisdictions', jurisdictions.map(j => new Jurisdiction(j)))
      })
  },

  setComparisonAssumptionsGroupColumns(context, { policies }) {
    const assumptionKeys = Object.keys(defaultExistingBuildingsImpactAssumptionValues)
    const assumptionsBetweenPolicies = mapAssumptionsBetweenPolicies(assumptionKeys, policies) 
    const differentAssumptionsBetweenPolicies = Object.entries(assumptionsBetweenPolicies).filter(entry => entry[1] === NULLED_VALUE).map(entry => entry[0])
    const assumptionsColumns = [
      applicability_rate(),
      annual_penetration_rate(),
      effective_date(),
      delay_installation(),
      clean_energy_progress(),
      current_grid_renewable_level(),
      baseline_installation_rate(),
      current_kwh_emissions_factor(),
      current_therms_emissions_factor(),
    ]

    const differentAssumptionColumnsBetweenPolicies = assumptionsColumns.filter((column) => {
      const intersectedKeysBetweenDifferentAssumptionColumns = column.assumption_keys.filter(value => differentAssumptionsBetweenPolicies.includes(value))
      return intersectedKeysBetweenDifferentAssumptionColumns.length > 0 ? true : false
    })
    const differentAssumptionColumnKeysBetweenPolicies = differentAssumptionColumnsBetweenPolicies.map(column => column.key )

    const sharedAssumptionColumnsBetweenPolicies = assumptionsColumns.filter((column) => !differentAssumptionColumnKeysBetweenPolicies.includes(column.key))

    context.commit('setAssumptionColumns', { different: differentAssumptionColumnsBetweenPolicies, shared: sharedAssumptionColumnsBetweenPolicies })
  },

  addOrUpdateComparisonToUserOrSharedLists({ commit, state }, { comparison }) {
    return commit('setUserComparisons', [ ...state.userComparisons.filter((comp) => comp.id != comparison.id), new Comparison(comparison) ])
  },

  async createComparison({ dispatch }, { comparison } = {}) {
    try {
      const response = await ComparisonApiService.create(comparison)
      const newComparison = await ComparisonApiService.getById(response.comparison.id, 'ComparisonApiService.getNewComparison')

      if (newComparison) {
        dispatch('addOrUpdateComparisonToUserOrSharedLists', { comparison: newComparison })
        return newComparison
      } else {
        return false
      }

    } catch (error) {
      throw new Error(error) 
    }
  },

  getUserComparison({ dispatch }, { comparison_id }) {
    return ComparisonApiService.getById(comparison_id, 'ComparisonApiService.getNewComparison')
      .then((comparison) => {
        dispatch('addOrUpdateComparisonToUserOrSharedLists', { comparison })
      })
  },

  async getUserComparisons(context) {
    await ComparisonApiService.getUserComparisons()
      .then(userComparisons => {
        const comparisonsToAdd = context.state.userComparisons
          .filter(comparison => !userComparisons.some(userComparison => userComparison.user_device_id === comparison.user_device_id))
        
        comparisonsToAdd.forEach((newComparison) => {
          const comparisonId = newComparison?.id
          ComparisonApiService.getById(comparisonId) 
        })

        if(comparisonsToAdd.length){  
          context.commit("setUserComparisons", [...comparisonsToAdd,...userComparisons])
          return 
        }

        context.commit("setUserComparisons", [...userComparisons])
      })
  },

  getAllUserComparisons({ dispatch }) {
    return Promise.all([
      dispatch('getUserComparisons'),
    ])
  },

  async updateComparisonPolicies({ dispatch, getters }, { comparison_id, policy_id }) {
    const comparisonSource = getters.getterUserComparison({ id: comparison_id })
    let comparison = new Comparison({ ...comparisonSource })
    comparison.togglePolicy({ policy_id })
    return ComparisonApiService.update(comparison.id, comparison).then(({ comparison: comparisonUpdated }) => {
      dispatch('getUserComparison',{comparison_id: comparisonUpdated.id})
      return comparisonUpdated
    }).catch((error) => {
      if (error === ERRORS.USER_IS_NOT_COMPARISON_AUTHOR) {
        dispatch('addOrUpdateComparisonToUserOrSharedLists', { comparison })
      }
      throw error
    })
  }
}

function mapAssumptionsBetweenPolicies(assumptionKeys, policies) {
  return assumptionKeys.reduce((prev, key) => {
    policies.forEach((policy) => {
      if (!prev[key]) {
        prev[key] = policy[key]
      } else {
        prev[key] = prev[key] === policy[key] ? prev[key] : NULLED_VALUE
      }
    })

    return prev
  }, { })
}
