import Graphql from '@/util/GraphQL'
import * as sha1 from 'sha1'
import asyncCacheResolver from '@/util/asyncCacheResolver'

export default class TypeMeasureApiService {

  static get (measure_constraints) {
    const query = `
      {
        type_measures {
          id
          title
          slug
          measures ${measure_constraints ? `(${measure_constraints})` : ''} {
            id
            study_id
            title
            is_package
            hide_in_flexible_path
            hide_in_compliance_margin_control
            type_measures {
              id
            }
          }
        }
      }
    `

    const cacheKey = `TypeMeasureApiService.get:${sha1(query)}`
    return asyncCacheResolver(cacheKey, async () => {
      return Graphql({ query, caller: `TypeMeasureApiService.get` })
          .then(({data}) => data)
    })
  }

}