<template>
  <div class="w-full flex flex-col bg-ash-200">
    <div class="w-full p-6 px-8 border-b border-ash-200 bg-white flex justify-between">
      <PageTitle title="Policy Options" />

      <div class="flex space-x-4">
        <router-link
          :to="{ name: 'AdminPolicyOptionCreate' }"
        >
          <PsButton
            label="Create policy option"
            size="big"
            icon="add"
          />
        </router-link>
      </div>
    </div>

    <div class="w-full p-4 overflow-x-auto">
      <table class="psui-el-table psui-table">
        <thead>
          <tr>
            <td class="psui-bg-gray-10 psui-text-gray-80 psui-font-normal">
              ID
            </td>
            <td class="psui-bg-gray-10 psui-text-gray-80 psui-font-normal">
              Title
            </td>
            <td class="psui-bg-gray-10 psui-text-gray-80 psui-font-normal">
              Slug
            </td>
            <td class="psui-bg-gray-10 psui-text-gray-80 psui-font-normal">
              Advanced
            </td>
            <td class="psui-bg-gray-10 psui-text-gray-80 psui-font-normal">
              Recommended
            </td>
            <td class="psui-bg-gray-10 psui-text-gray-80 psui-font-normal">
              Actions
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(policy_option, index) in policy_options"
            :key="index"
            class="bg-white psui-text-gray-80 psui-font-normal"
          >
            <td>
              {{ policy_option.id }}
            </td>
            <td>
              {{ policy_option.title }}
            </td>
            <td>
              {{ policy_option.slug }}
            </td>
            <td>
              {{ policy_option.is_advanced }}
            </td>
            <td>
              {{ policy_option.is_recommended }}
            </td>
            <td class="psui-flex psui-space-x-2">
              <router-link 
                :to="{ name: 'AdminPolicyOptionEdit', params: { policy_option_id: policy_option.id }}" 
              >
                <PsButton 
                  label="Edit"
                  size="medium"
                  icon="edit"
                />
              </router-link>

              <PsButton 
                layout="caution"
                label="Delete" 
                size="medium" 
                icon="delete"
                theme="bg-red border-transparent rounded-full text-white"
                @click="setPolicyOptionToDelete(policy_option)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <BaseModal
      ref="deleteModal"
      modal-dialog-classes="w-full max-w-sm"
      title="Attention"
    >
      <div
        v-if="policy_option_selected"
        class="w-full flex flex-col items-start mt-6"
      >
        <p class="text-base text-gray04">
          Delete <span class="text-red font-bold">{{ policy_option_selected.title }}</span>?
        </p>
        <PsButton
          layout="caution"
          class="psui-mt-6"
          label="Confirm delete"
          size="big"
          @click="deletePolicyOption()"
        />
      </div>
    </BaseModal>
  </div>
</template>

<script>
export default {
  name: 'AdminPolicyOptionsIndex',
  data() {
    return {
      policy_options: [],
      policy_option_selected: null
    }
  },
  computed: {
    getPolicyOptions() {
      return this.$store.getters['globalEntities/PolicyOption/getterGlobalPolicyOptions']()
    },
  },
  watch: {
    getPolicyOptions: {      
      handler(value) { this.policy_options = value },
      immediate: true
    },
  },
  methods: {
    setPolicyOptionToDelete(policy_option) {
      this.policy_option_selected = policy_option
      this.$refs.deleteModal.showModal = true
    },

    deletePolicyOption() {
      this.$store.dispatch('globalEntities/PolicyOption/deletePolicyOption', { policy_option : this.policy_option_selected })
        .then(()=>{
          this.$appToast('Policy Option was successfully deleted.')
          this.$refs.deleteModal.showModal = false
          this.policy_option_selected = null
        })
    },
  }
}
</script>