<template>
  <div class="psui-w-full">
    <PolicyShowContentHeader
      :title="'Policy Requirements' | policyTextRephrase"
      :subtitle="'Review and edit your policy requirements' | policyTextRephrase"
    >
      <template #right>
        <PsButton 
          v-if="policyHasCustomCombinations && getPolicyNewOrExisting === 'new'"
          label="Download summary"
          size="small"
          layout="ghost"
          icon="download"
          icon-classes="psui-ml-1"
          :loading="isDownloadingPolicyRequirementsSummaryPdf"
          @click="downloadRequirementsSummary()"
        />

        <PsButton 
          v-else-if="policyHasCustomCombinations && getPolicyNewOrExisting === 'existing'"
          label="Download summary"
          size="small"
          layout="ghost"
          icon="download"
          icon-position="right"
          icon-classes="psui-ml-1"
          :loading="isDownloadingPolicyRequirementsSummaryPdf || isDownloadingFlexibleCompliancePdf"
          @click="downloadPdf()"
        />
      </template>
    </PolicyShowContentHeader>

    <div
      class="psui-w-full psui-flex psui-flex-col psui-space-y-8 psui-mb-20"
    >
      <template
        v-if="getPolicyContainersOrderedByTypePrototypeOrder.length > 0"
      >
        <PolicyContainer
          v-for="policyContainer of getPolicyContainersOrderedByTypePrototypeOrder" 
          :key="policyContainer.id"
          :policy-container="policyContainer"
          :flexible_path_setup="flexible_path_setup"
          :policy="getPolicy"
        />
      </template>
      <PolicyRequirementsAvailableTypePrototypes
        v-if="userCanEditPolicy"
        :type-prototypes="getTypePrototypesIgnoringTypePrototypesWithinPolicySelected"
        :policy="getPolicy"
        :loading-index="loadingIndex"
        @add-requirements="addPolicyContainerToPolicy"
      />
    </div>

    <PolicyFlexiblePathDownloadModal :policy="getPolicy" />
  </div>
</template>

<script>
import PolicyContainer from './PolicyContainer.vue'
import PolicyRequirementsAvailableTypePrototypes from './PolicyRequirementsAvailableTypePrototypes.vue'
import PolicyShowContentHeader from '../PolicyShowContentHeader.vue'
import PolicyDocumentsApiService from '@/services/api/PolicyDocumentsApiService'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import PolicyFlexiblePathDownloadModal from '../flexible-path/PolicyFlexiblePathDownloadModal.vue'
import PolicyRequirementsSummaryPdf from '@/services/pdf/PolicyRequirementsSummaryPdf'
import PolicyFlexibleCompliancePdf from '@/services/pdf/PolicyFlexibleCompliancePdf'
import { mapGetters } from 'vuex'
import FlexiblePathService from '@/services/api/FlexiblePathService.js'
import { ExistingBuildingsPOBuilder } from "@/business-logic/services/policy-options/builders/existing-buildings"

export default {
  name: 'PolicyRequirements',
  components: {
    PolicyContainer,
    PolicyRequirementsAvailableTypePrototypes,
    PolicyShowContentHeader, 
    PolicyFlexiblePathDownloadModal
  },
  props: ['policy','flexible_path_setup'],
  data:()=>({
    loadingIndex: false,
    isLoading: false,
    isDownloadingSummary: false,
    isDownloadingPolicyRequirementsSummaryPdf: false,
    isDownloadingFlexibleCompliancePdf: false,
    STUDY_TYPES_DB_SLUGS,
  }),
  computed: {
    getPolicyContainersOrderedByTypePrototypeOrder() {
      let policyContainers = this.getPolicy?.policy_containers ?? []

      policyContainers.map((policy_container) => {
        const { order } = this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id: policy_container.type_prototype_id })
        policy_container.type_prototype_order = order
        return policy_container
      })

      policyContainers.sort((a, b) => a.type_prototype_order - b.type_prototype_order)
      
      return policyContainers
    },
    getTypePrototypeByPolicyContainers(){
      return this.getPolicyContainersOrderedByTypePrototypeOrder?.map(pc => pc.type_prototype_id) 
    },
    getStudyTypes(){
      return this.$store.getters['globalEntities/StudyType/getterGlobalStudyTypes']()
    },
    getPolicy(){
      return this.$store.getters['policy/getterUserPolicy']({ id: this.$route.params.policy_id })
    },
    // TODO - Check how this can not broke if policy has multiple study types
    getPolicyStudyTypeId(){
      const study_type_slug = this.$store.getters['policy/getterPolicyStudyTypes']({ key: 'slug', policy_id: this.$route.params.policy_id })[0] ?? null
      const studyTypeSelected = this.$store.getters['globalEntities/StudyType/getterGlobalStudyType']({ slug: study_type_slug })
      if(studyTypeSelected) return studyTypeSelected.id
      return false
    },
    getPolicyAvailableTypePrototypesByStudyType() {
      return this.$store.getters['policy/getterPolicyAvailableTypePrototypesByStudyType']({
        policy_id: this.getPolicy.id,
        study_type_id: this.getPolicyStudyTypeId,
        checkCodeCycle: false 
      })
    },
    getTypePrototypesIgnoringTypePrototypesWithinPolicySelected(){

      return this.getPolicyAvailableTypePrototypesByStudyType.filter(tp => !this.getTypePrototypeByPolicyContainers.includes(tp.id))
    },
    policyHasContainers(){
      return this.getPolicy?.policy_containers?.length !== 0 ?? false
    },
    policyHasCustomCombinations() {
      return this.$store.getters['policy/getterPolicyCustomCombinations'](this.$route.params.policy_id)?.length > 0 ?? false
    },
    userCanEditPolicy(){
      return this.$store.getters['policy/getterUserCanEditPolicy'](this.$route.params.policy_id)
    },
    getPolicyXlsType() {
      const study_type_slug = this.$store.getters['policy/getterPolicyStudyTypes']({ key: 'slug', policy_id: this.$route.params.policy_id })[0] ?? null
      if(!study_type_slug) return false
      if(study_type_slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS) return 'ExistingResidentialComplianceXls'
      return 'NewSingleFamilyComplianceXls'
    },
    getPolicyNewOrExisting() {
      const study_type_slug = this.$store.getters['policy/getterPolicyStudyTypes']({ key: 'slug', policy_id: this.$route.params.policy_id })[0] ?? null
      if(!study_type_slug) return false
      if(study_type_slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS) return 'existing'
      return 'new'
    },
    getPolicyHasFlexiblePathSetUp() {
      return Boolean(this.flexible_path_setup?.tiers?.some((tier) => {
        return tier?.excluded_measures?.length || tier?.target_scores?.some((ts) => ts?.value != null && ts?.value > 0)
      }))
    },
    ...mapGetters(['lastJurisdictionVisited']),
  },
  methods:{
    getFLexiblePathByTypeprototype(prototype){
      return this.flexible_path_setup
      .cost_effective_presets
      .some( cost_effective_preset => cost_effective_preset.prototype_id ==  prototype.id)
    },
    createBaseFlexiblePathSetup(prototype){

      const getVintagesByStudyIdAndPrototype = this.$store.getters['globalEntities/Vintage/getterGlobalVintages']({study_id:prototype.study_id})

      return this.lastJurisdictionVisited.climate_zones.map((climate_zone) => {
        const commonProperties = {
          climate_zone_id: climate_zone.id,
          prototype_id : prototype.id
        }
        return {
          cost_effective_presets: [
            commonProperties
          ],
          tiers: [
            {
              ...commonProperties,
              mandatory_measures: [],
              excluded_measures: [],
              target_scores: getVintagesByStudyIdAndPrototype.map((vintage) => {
                return {
                  type_vintage_id: vintage.type_vintage_id,
                  value: 0
                }
              })
            }
          ],
          version:3
        }
      }).pop()

    },
    async addPolicyContainerToPolicy({typePrototype, index, studyTypeId}){
      const newContainer = {
        policy_id: this.getPolicy.id,
        study_type_id: studyTypeId,
        type_prototype_id: typePrototype.id,
        type_prototype_order: typePrototype.order
      }
      this.loadingIndex = index
      return await this.$store.dispatch('policy/updateOrCreatePolicyContainer', newContainer)
      .then( async (data) => {

        const getAllowedPrototype = this.$store.getters['globalEntities/Prototype/getterGlobalPrototypes']({
          type_prototype_id:data.type_prototype_id,
          allow_policy_creation: true,
        })
        .filter(prototype => prototype.policy_option_algorithms.length > 0)
        .pop()
        
        if(!this.getFLexiblePathByTypeprototype(getAllowedPrototype)) {
          const baseFLexiblePathSetup = this.createBaseFlexiblePathSetup(getAllowedPrototype)
          const setup = ExistingBuildingsPOBuilder.mergeFlexConfigs([this.flexible_path_setup, baseFLexiblePathSetup])
          
          const updatedFlexiblePath = new FlexiblePathService({policy_id:this.policy.id})

          return updatedFlexiblePath.updateSetup(setup)
          .then(()=>{
            window.$vueInstance.$appToast({type:'success', message: 'A building type was added to the policy.' })
            this.loadingIndex = false
          })
        }
        return
      })
      .catch(err => {
        if (err?.response?.data?.message) {
          window.$vueInstance.$appToast({ message: err?.response?.data?.message })
        } else {
          window.$vueInstance.$appToast({type:'error', message: 'Something went wrong, please try again.' }) 
        }
        this.loadingIndex = false
      })

    },

    downloadRequirementsTablesXls() {
      this.isLoading = true
      PolicyDocumentsApiService.downloadRequirementsTableXls({ 
        type: this.getPolicyXlsType, 
        policy_id: this.$route.params.policy_id,
        policy: this.getPolicy
      })
        .then(() => {
          this.isLoading = false
        })
    },

    downloadRequirementsSummary(is_flexible_set_up) {
      if (this.isDownloadingPolicyRequirementsSummaryPdf) return
      this.isDownloadingPolicyRequirementsSummaryPdf = true 
      new PolicyRequirementsSummaryPdf({ policy_id: this.$route.params.policy_id, policyType: this.getPolicyNewOrExisting, policy_title: this.policy.title, is_flexible_set_up }).then(() => {
        this.isDownloadingPolicyRequirementsSummaryPdf = false
      })
    },

    downloadFlexibleCompliancePdf(is_flexible_set_up) {
      if (this.isDownloadingFlexibleCompliancePdf) return
      this.isDownloadingFlexibleCompliancePdf = true 
      new PolicyFlexibleCompliancePdf({ policy_id: this.$route.params.policy_id, policyType: this.getPolicyNewOrExisting, policy_title: this.policy.title, is_flexible_set_up }).then(() => {
        this.isDownloadingFlexibleCompliancePdf = false
      })
    },

    downloadPdf(){
      const is_flexible_set_up = this.getPolicyHasFlexiblePathSetUp
      if(is_flexible_set_up) this.downloadFlexibleCompliancePdf(is_flexible_set_up)
      else this.downloadRequirementsSummary(is_flexible_set_up)
    },
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep {
    .psui-el-dropdown-menu-dialog-wrapper {
      width: 290px;
      margin-top: 8px;
    }

    .psui-el-button {
      &:focus {
        box-shadow: none;
      }
    }

    .menu-download {
      .psui-el-button {
        justify-content: space-between;
        
        &:not(.disabled) {
          color: #515E6A;
          min-height: 38px;

          i {
            color: #A2ACB7;
          }

          &:hover {
            color: #318FAC;
            background-color: #ECF7FB;

            i {
              color: #318FAC;
            }
          }
        }

        &.disabled {
          i {
            color: #D6DDE5;
          }
        }
      }
    }
  }
  .menu-download {
    &-helper,
    .psui-el-button {
      padding: 0.438rem 1rem 0.438rem .5rem;
    }
  }
</style>