import dayjs from 'dayjs'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import { defaultExistingBuildingsImpactAssumptionValues } from '@/modules/app/assumptions/shared/defaultAssumptions.js'
export * from '@/modules/app/assumptions/shared/defaultAssumptions.js'

export default class ImpactAssumptions {

  constructor(obj = {}, defaultValues = defaultExistingBuildingsImpactAssumptionValues) {
    this.id = obj.id || null
    if (obj === null) obj = {}

    for ( let key in {...defaultValues, ...obj}) {
      this[key] = obj && obj[key] !== undefined ? obj[key] : defaultValues[key]
    }    
    
    // Helpers
    this.start_at_date = dayjs(this.start_at, 'MM/DD/YYYY')
    this.first_third_end_at = this.start_at_date.add((this.years / 3), 'years')
    this.second_third_end_at = this.start_at_date.add((this.years / 3) * 2, 'years')
    this.final_third_end_at = this.start_at_date.add(this.years, 'years')
  }

  get terminationDate() {
    return this.termination_date
  }

  get getBasicFinishAt() {
    return dayjs(this.start_at_date)
      .add(this.years, 'years')
  }

  get getAdvancedFinishAt() {
    return dayjs(this.start_at_date)
      .add(this.active_policy_duration, 'years')
      .add(this.delay_installation, 'years')
      .add(this.years, 'years')
  }

  get getAnnualPenetrationRateFinal() {
    return ((this.annual_penetration_rate / 100) * (this.applicability_rate / 100))
  }

  getActiveInstallationPeriod({ format = 'YYYY', policy_study_type  } = { }) {
    const policyStudyType = policy_study_type ? policy_study_type : STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS
    const activeInstallationPeriodStart = this.start_at_date
    const activeInstallationPeriodEnd = (policyStudyType === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS) ? 
      activeInstallationPeriodStart.add(this.active_policy_duration, 'years') : 
      dayjs(this.termination_date).add(this.delay_installation,'years')
    return [activeInstallationPeriodStart.format(format), activeInstallationPeriodEnd.format(format)]
  }

  // Please, try to use only this when we need those times instead of getting from the object
  getHelpers({startAt, years} = {startAt: null, years: null}) {
    return {
      start_at_date: dayjs(startAt || this.start_at, 'MM/DD/YYYY'),
      first_third_end_at: this.start_at_date.add((years || this.years) / 3, 'years'),
      second_third_end_at: this.start_at_date.add((years || this.years) / 3 * 2, 'years'),
      final_third_end_at: this.start_at_date.add(years || this.years, 'years')
    }
  } 
}
