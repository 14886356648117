<template>
  <div class="psui-flex psui-flex-col md:psui-flex-row psui-justify-between psui-items-start psui-mb-6">
    <slot name="left">
      <div class="psui-mb-3 md:psui-mb-0">
        <p class="psui-text-h5 psui-font-bold psui-text-gray-70 psui-mb-1">
          {{ title }}
        </p>
        <p class="psui-text-p psui-text-gray-60">
          {{ subtitle }}
        </p>
      </div>
    </slot>
    <slot name="right" />
  </div>
</template>

<script>
export default {
  name: 'PolicyShowContentHeader',
  props: ['title', 'subtitle'],
}
</script>