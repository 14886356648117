import Vue from 'vue'
import { jurisdictionTitleTypeBasedOnJurisdictionType } from '@/util/Helpers.js'
import { isFeatureFlagEnabled } from '@/util/Functions.js'
import Store from '@/store'

Vue.filter("replaceAllHyphenAndAddUppercaseToFirstLetter", text => text.replace(/-/g," ").replace(/(^\w|\s\w)/g, m => m.toUpperCase()))

Vue.filter('editJurisdictionTitleTypeBasedOnJurisdictionType', jurisdictionTitleTypeBasedOnJurisdictionType)

Vue.filter("prototypeTitleHomesAsUnits", prototypeTitle => {
    if (prototypeTitle && prototypeTitle === "ADUs") {
      return prototypeTitle
    } else if (prototypeTitle && prototypeTitle.includes("Homes")) {
      return prototypeTitle.replace("Homes", "Units")
    } else if (prototypeTitle) {
      return `${prototypeTitle} Units`
    } else {
      return ""
    }
})

Vue.filter('policyTextRephrase', (policyText, size) => {
  const jurisdictionType = Store.getters['lastJurisdictionVisited']?.type ?? ''

  const validJurisdictions = ['CCA', 'Utility', 'COG/CAG', 'REN']
  
  if (
    !isFeatureFlagEnabled(['PS_TEMPLATE_DESIGN']) ||
    !validJurisdictions.includes(jurisdictionType) ||
    policyText === null ||
    policyText === undefined 
  ) {
    return policyText
  }

  const pattern = /\b(policy|policies)\b/gi

  function replaceWithCase(match) {
    let rephrasedText 
    if (match.toLowerCase() === 'policy') {
      rephrasedText = 'program'
    } else if (match.toLowerCase() === 'policies') {
      rephrasedText = 'programs'
    }

    if (match[0] === match[0].toUpperCase()) {
      rephrasedText = rephrasedText.charAt(0).toUpperCase() + rephrasedText.slice(1)
    }

    return `${rephrasedText}`
  }

  const rephrasedPolicyText = policyText.replace(pattern, replaceWithCase)

  return rephrasedPolicyText
})