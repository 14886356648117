import { initialState } from './state'
import { toggleInArray } from '@igortrindade/lazyfy'
import editRequirementsNewBuildingsSF2022Columns from '@/modules/app/policy/show/requirements/requirements-drawer/PolicyEditRequirementsNewBuildingsSF2022/edit-requirements-new-buildings-sf-2022-columns.js'
import Store from '@/store'

export default {
  setLoggedUserToken(state, {type, token }) {
    state.loggedUserType = type
    state.loggedUserToken = token
  },
  setLoggedUser(state, {user}) {
    state.loggedUser = user
    Store.dispatch('policy/getAllUserPolicies')
    if (user?.user_preferences) {
      state.user_preferences = { ...user.user_preferences, columnsEditRequirementsNewBuildingsSF2022 : {
          version : 2,
          data: editRequirementsNewBuildingsSF2022Columns
        }
      }
    }
    if (user?.is_team || user?.is_admin) {
      window.localStorage.setItem('is_explorer_team', true)
    }
  },
  setLogout(state) {
    const userPreferences = { ... state.user_preferences }
    const initialStateKeepingUserPreferences = { ...initialState(), user_preferences : userPreferences }
    Object.assign(state, initialStateKeepingUserPreferences )
  },
  setUserPreferences(state, { path, value }) {
    if (path) {
      Object.byString(state.user_preferences, path, value)
    }
  },  
  setUserPreferenceData(state, { path, data }) {
    state.user_preferences = {
      ...state.user_preferences,
      [path]: {
        ...state.user_preferences[path],
        data
      }
    }
  },
  setUserPreferencesFilterByJurisdiction(state, { jurisdiction }) {
    const currentState = state.user_preferences?.policies?.myPolicies?.filterByJurisdictionV2 
    let filterBy = currentState && currentState.length ? currentState : []
    state.user_preferences.policies.myPolicies.filterByJurisdictionV2 = toggleInArray(filterBy, ""+jurisdiction.id)
  },    
  setUserPreferencesFilterByAuthor(state, {authors}){
    const currentState = state.user_preferences?.policies?.myPolicies?.filterByAuthorV3
    let filterBy = currentState && currentState.length ? currentState : []
    state.user_preferences.policies.myPolicies.filterByAuthorV3 = toggleInArray(filterBy,authors)
  },
  setUserPreferencesFilterByStudyType(state, {studyTypeId}){
    const currentState = state.user_preferences?.policies?.myPolicies?.filterByStudyTypeV3
    let filterBy = currentState && currentState.length ? currentState : []
    state.user_preferences.policies.myPolicies.filterByStudyTypeV3 = toggleInArray(filterBy,studyTypeId)
  },
  setUserPreferencesFilterByJurisdictionForComparisons(state, { jurisdiction }) {
    const currentState = state.user_preferences?.policies?.comparison?.filterByJurisdiction
    let filterBy = currentState && currentState.length ? currentState : []
    state.user_preferences.policies.comparison.filterByJurisdiction = toggleInArray(filterBy, ""+jurisdiction.id)
  },    
  setUserPreferencesFilterByAuthorForComparisons(state, { authors }){
    const currentState = state.user_preferences?.policies?.comparison?.filterByAuthor
    let filterBy = currentState && currentState.length ? currentState : []
    state.user_preferences.policies.comparison.filterByAuthor = toggleInArray(filterBy,authors)
  },
  setModuleVisited(state, payload) {
    state.user_preferences.modulesVisited = [... new Set([...state.user_preferences.modulesVisited, payload ])]
  },
  resetUserPreferencesFilterByJurisdictionAndFilterByAuthor(state){
    state.user_preferences.policies.myPolicies.filterByJurisdictionV2 = []
    state.user_preferences.policies.myPolicies.filterByAuthorV3 = []
  },
  resetUserPreferencesFilterByJurisdiction(state) {
    state.user_preferences.policies.myPolicies.filterByJurisdictionV2 = []
  },
  resetUserPreferencesFilterByJurisdictionAndFilterByAuthorForComparisons(state){
    state.user_preferences.policies.comparison.filterByJurisdiction = []
    state.user_preferences.policies.comparison.filterByAuthor = []
  },
  resetUserPreferencesFilterByJurisdictionForComparisons(state) {
    state.user_preferences.policies.comparison.filterByJurisdiction = []
  },
} 
