import { noFormatNumber } from './valueNormalizers'

export function formatCurrencyExtended(value, maximumFractionDigits=2, minimumFractionDigits=2, adjustValueFunc=null) {
  const noFormat = noFormatNumber(value)
  if (noFormat) {
    return noFormat
  }
  if (adjustValueFunc) {
    value = adjustValueFunc(value)
  }
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits, minimumFractionDigits }).format(value)
}
