<template>
  <div class="w-full block relative">
    <select 
      v-model="childValue" 
      :disabled="disabled"
      :class="[disabled ? 'cursor-not-allowed' : '']"
      class="input-with-label block appearance-none w-full h-full border-b-2 border-gray06 bg-gray06 text-header-3 px-3 pb-2 pt-6 font-normal focus:outline-none"
    >
      <option
        :value="''"
        disabled=""
        selected=""
      >
        Select an option
      </option>
      <option
        v-for="item in getItems"
        :key="item[keyValue]"
        class="p-2"
        :value="item[keyValue]"
      >
        {{ item[keyLabel] }}
      </option>
    </select>
    <label
      v-if="label"
      class="absolute pin-l flex items-center text-gray04 text-xs pointer-events-none pl-3"
    >{{ label }}</label>
  </div>
</template>

<script>
// import { find } from '@/util/Functions'
export default {
  name: 'SelectInput',
  props: {
    items: {
      required: true
    },
    value: {
      required: true
    },
    label: {
      default: ''
    },
    keyLabel: {
      default: 'label'
    },
    keyValue: {
      default: 'value'
    },
    validation: {
      type: Object,
      default: () => {
        return { hasError: false, checked: false, filled: false, required: false }
      }
    },
    disabled: {
      default: false
    }
  },
  computed: {
    childValue: {
      get() {
        return this.value
      },
      set(value) {
        if(this.isString) return this.$emit('input', value)
        const finded = find(this.items, { [this.keyValue]: value })
        this.$emit('input', value)
        this.$emit('change', finded)
      }
    },

    isString() {
      return  this.items.length > 0 && typeof this.items[0] === 'string'
    },

    getItems() {
      if (this.items.length > 0 && typeof this.items[0] === 'string') {
        return this.items.map((item) => { 
          return {
            [this.keyLabel]: item,
            [this.keyValue]: item,
          }
        })
      } else {
        return this.items
      }
    },
  }
}
</script>

<style scoped>
.input-with-label + label {
  bottom: 55%;
  font-size: 12px;
  line-height: 16px;
}

</style>