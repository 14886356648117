<template>
  <div 
    class="spin-element flex justify-center items-center absolute"
  >
    <PsIcon
      icon="/spin-animation.svg"
      color="psui-text-blue-60"            
      :disable-stroke="true"
      width="34"
      height="34"
    />
  </div>
</template>

<script>

export default {
  name: 'LoaderSpinner'
}
</script>

<style lang="scss" scoped>
@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.spin-element {
  animation: spin 1s linear infinite;
  top: 50%;
  left: 50%;
  z-index: 20;
}
</style>