<template>
  <div class="p-6">
    <InputWithLabel
      v-model="user.email"
      class="mt-6"
      :label="'User'"
    />

    <div class="w-full flex flex-col">
      <Checkbox
        v-model="user.is_admin"
        class="my-2"
        :label="'Is Admin'"
      />
      <Checkbox
        v-model="user.is_team"
        class="my-2"
        :label="'Is Team'"
      />
      <Checkbox
        v-model="user.accept_periodic_newsletter"
        class="my-2"
        :label="'Accept periodic newsletter'"
      />
      <Checkbox
        v-model="user.accept_features_newsletter"
        class="my-2"
        :label="'Accept feature newsletter'"
      />
      <Checkbox
        v-model="user.has_spreadsheet_access"
        class="my-2"
        :label="'Has access to spreadsheet'"
      />
      <Checkbox
        v-model="user.log_events"
        class="my-2"
        :label="'Log user events'"
      />
      <Checkbox
        v-model="user.is_dev"
        class="my-2"
        :label="'Is developer'"
      />
    </div>

    <hr class="border-b border-gray05 my-6">
    <div class="psui-w-full psui-flex psui-mt-6">
      <PsButton
        label="Update user"
        size="big"
        icon="update"
        @click="update()"
      />
    </div>
  </div>
</template>

<script>
import User from '@/models/User'
export default {
  name: 'AdminUserForm',
  data() {
    return {
      user: new User()
    }
  },
  mounted() {
    if(this.$route.params.user_id) {
      this.getUser()
    }
  },
  methods: {
    getUser() {
      this.$api.get(`/api/admin/users/show/${this.$route.params.user_id}`)
        .then(({ data }) => {
          this.user = data.user
        })
    },

    update() {
      this.$api.post(`/api/admin/users/update/${this.user.id}`, this.user)
        .then(() => {
          this.$toast.success(`User successfully updated`)
          this.$router.push({ name: 'AdminUserList' })
        })
    }
  }
}
</script>
