<template>
  <div class="base-header__actions psui-flex-shrink-0 psui-w-full psui-flex psui-items-center psui-space-x-2">
    <PsButton
      label="Share"
      icon="share"
      size="small"
      layout="onlytext"
      class="psui-text-blue-50"
      @click="onCopyLink"
    />
  </div>
</template>

<script>
import { copyUrlToClipboard } from '@/util/Helpers'
 
 
export default {
name: 'SummaryHeaderActions',

  methods: {
    onCopyLink() {
      copyUrlToClipboard()
      this.$appToast({ message: 'Link copied to clipboard!' })
    },

  },
}
</script>

 
