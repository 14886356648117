<template>
  <div class="psui-flex">
    <div class="psui-flex psui-items-center psui-relative psui-space-x-1">
      <span  
        v-if="codeCycleIsEndingWithinOneYear"
        v-tooltip="{ content: `Careful, the ${study.code_cycle.title} code cycle ends ${ formatDate({ date: study.code_cycle.end_at, format: 'MMMM D, YYYY', from: 'mm/dd/yyyy' }) }.`, placement: 'top-center' }"
        class="psui-flex"
      >
        <PsIcon 
          icon="warning"
          size="16"
          class="psui-text-yellow-20 psui-cursor-pointer"
        />
      </span>
      <span class="psui-text-accent psui-text-gray-50 psui-font-bold">Code Cycle:</span>
    </div>
    <div
      class="flex items-center ml-1 psui-cursor-pointer"
      @click="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: `code_cycle_helper_${study.code_cycle.slug}` })"
    >
      <span class="psui-text-accent psui-text-gray-50 psui-mx-1 hover:psui-text-blue-60">{{ study.code_cycle.title }}</span>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'PrototypeCodeCycle',
  props: {
    studyGroup: {
      type: Object,
      required: true
    },
    prototype: {
      type: Object,
      required: true
    },
    study: {
      type: Object,
      required: true
    }
  },
  computed: {
    codeCycleIsEndingWithinOneYear() {
      const oneYearBeforeCodeCycleEnds = dayjs(this.study.code_cycle.end_at).subtract(1, 'year')      
      const today = dayjs()
      return today.isAfter(oneYearBeforeCodeCycleEnds) ? true : false
    },
  },
}
</script>

<style lang="scss" scoped>
  ::v-deep {
    .psui-el-mini-tag {
      &.psui-el-mini-tag-layout-warning {

        &:hover {
          background-color: #EDAB3E;
          color: white;
        }
      }
    }
  }
</style>