<template>
  <BaseModal
    ref="baseModal"
    :hierarchy="MODAL_HIERARCHY.PRIMARY"
    modal-dialog-classes="w-auto max-w-xs-sm"
    :disable-close="true"
    @close="close"
  >
    <div
      class="w-full"
    >
      <div class="flex flex-col space-y-4">
        <h1 class="psui-font-bold psui-text-gray-80 psui-text-h4">
          Building Estimates
        </h1>
        <p class="psui-text-gray-50 psui-text-p">
          {{ content }}
        </p>
      </div>
      <div class="psui-flex psui-space-x-1 psui-mt-6">
        <PsButton
          label="Got it"
          size="medium"
          @click="close"
        />
        <PsButton 
          label="Learn More about Estimates"
          size="medium"
          layout="onlytext"
          @click="takeToLink"
        />
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { MODAL_HIERARCHY } from '@/util/Enums.js'

export default {
  name: 'NewBuildingAreaInfoModal',
  data: () => ({
    content:false,
    MODAL_HIERARCHY,
    link:false
  }),
  mounted() {
    this.$eventBus.$on('openNewBuildingAreaInfoModal', (payload) => {
      this.$refs.baseModal.showModal = true
      this.content = payload.description
      this.link = payload.hrefLink
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openNewBuildingAreaInfoModal')
  },
  methods: {
    close() {
      this.$refs.baseModal.showModal = false
    },
    takeToLink(){
      window.open(this.link,'_blank')
      this.close()
    }
  }
}
</script>
