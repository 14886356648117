import { FORECAST_MAP_TO_INSTALLATION_MECHANICS_KEYS } from '../index'

export default (quarters) => {
  const years = [...new Set(quarters.map((ie) => ie.year).filter(y => y != null))].sort((a, b) => a - b)
  const acumulative = {}
  const generateAccumulative = (key, sum) => {
    if (!acumulative[key]) {
      acumulative[key] = 0
    }

    acumulative[key] += sum
    return {
      cummulative: acumulative[key],
      current: sum
    }
  }
  return years.map((year) => {
    const result = {
      year,
    }
    const yearInfo = quarters.filter((i) => i.year === year)
    Object.keys(FORECAST_MAP_TO_INSTALLATION_MECHANICS_KEYS).forEach((key) => {
      const keySum = yearInfo.reduce((acc, item) => acc + item[FORECAST_MAP_TO_INSTALLATION_MECHANICS_KEYS[key]] || 0, 0)
      result[key] = generateAccumulative(key, keySum)
    })
    return result
  })
}
