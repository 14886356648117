import Api from '@/util/Api'
import Graphql from '@/util/GraphQL'
import StudyData from '@/models/StudyData'
import {
  GeneralPerformanceByFuelPolicyImpactAlgorithm
} from '@/business-logic/services/impact-algorithms/policy/general-performance-by-fuel'
import Store from '@/store'
import * as sha1 from 'sha1'
import asyncCacheResolver from '@/util/asyncCacheResolver'

const DEFAULT_ATTRIBUTES = `
  id
  study_row_number
  study_id
  prototype_id
  measure_id
  vintage_id
  fuel_id
  climate_zone_raw
  baseline_fuel_type
  initial_cost
  kwh_savings
  therms_savings
  annual_bill_savings
  simple_payback
  tdv_benefit_to_cost_ratio
  tdv2022_benefit_to_cost_ratio
  tdv_benefit_to_cost_ratio_total
  on_bill_cost_ratio
  emissions_savings
  emissions_savings_pct
  compliance_margin
  lifecycle_savings
  energy_savings_combined
  base_kwh
  annual_bill_savings_care
  lifecycle_savings_care
  on_bill_cost_ratio_care
  on_bill_cost_ratio_2025
  lsc_2025_benefit_to_cost_ratio
  on_bill_2025_care
  simple_payback_care
  subsidy_needed
  five_year_payback
  subsidy_needed_care
  five_year_payback_care
  edr1_total_margin

  vintage { id title type_vintage_id }
  prototype { id title type_prototype_id }
  measure { 
    id 
    title
    include_in_combination
    hide_in_results
    is_pre_emptive
    has_larger_pv_included
    hide_in_compliance_margin_control
    compliance_margin_control_title
    slug
  }
  fuel { id title slug }
  climate_zone { id prefix raw }
`

export default class StudyDataApiService {

  static get(payload) {
    const cacheKey = `StudyDataApiService.get:${sha1(JSON.stringify(payload))}`
    const doGet = (retryCount=0) => {
      return Api.post(`/study_result/get_study_data`, payload)
          .then(({ data }) => {
            if (data && data.study_data) {
              return data.study_data
                  .filter((study_data_row) => {
                    return !study_data_row.measure.hide_in_results
                  })
            }
          }).catch(() => {
            if (retryCount && retryCount >= 3) {
              return []
            }
            return doGet((retryCount || 0) + 1)
          })
    }
    return asyncCacheResolver(cacheKey, async () => {
      return doGet()
    })
  }

  static async getForNewBuildingsByMinComplianceMargin(studyId, prototypeId, climateZoneRaw, fuelId, minComplianceMargin = 0, getAll = false) {
    const searchClimateZones = StudyDataApiService.getSearchClimateZones(climateZoneRaw)
    const study = Store.getters['globalEntities/Study/getterGlobalStudy']({ id: studyId })
    const complianceMarginKey = study.compliance_margin_key || 'compliance_margin'
    const removePvMeasures = GeneralPerformanceByFuelPolicyImpactAlgorithm.pvEnabledComplianceMarginKeys.includes(complianceMarginKey)
    const query = `
        {
          study_data(
            where: [
              {column: "study_id", value: "${studyId}", operation: "="}
              {column: "prototype_id", value: "${prototypeId}", operation: "="}
              {column: "fuel_id", value: "${fuelId}", operation: "="}
              {column: "${complianceMarginKey}", value: "${minComplianceMargin}", operation: ">="}
            ]
            whereIn: [{ column: "climate_zone_raw", value: [${searchClimateZones.map(m => `"${m}"`).join(',')}] }]
          ) {
            ${DEFAULT_ATTRIBUTES}
          }
        }
      `
    const cacheKey = `StudyDataApiService.getForNewBuildingsByMinComplianceMargin:${sha1(`${query}-getAll=${getAll}`)}`
    return await asyncCacheResolver(cacheKey, async () => {
      const { data } = await Graphql({ query, caller: 'StudyDataApiService.getForNewBuildingsByMinComplianceMargin' })
      const studyDatas = (data.study_data.length <= 0 ? [] : data.study_data)
        .map((s) => {
          return new StudyData({study_data: s})
        })
        .filter((s) => {
          return !removePvMeasures || (s?.measure?.slug !== GeneralPerformanceByFuelPolicyImpactAlgorithm.pvOnlySlug && s?.measure?.title !== 'PV Only')
        })
      // .filter((s) => {
      //   // return s?.measure?.include_in_combination === true && s.isCostEffective === true
      //   return s.isCostEffective === true || !s?.study_data?.[complianceMarginKey]
      // })

      const firstExistingCzIdx = searchClimateZones.map((czRaw) => {
        return !!studyDatas.find((s) => s.climate_zone_raw === czRaw)
      }).findIndex((i) => i === true)
      const firstExistingCz = firstExistingCzIdx >= 0 ? searchClimateZones[firstExistingCzIdx] : null
      const sortResultFunc = (a, b) => (a?.study_data?.[complianceMarginKey] ?? Number.MAX_SAFE_INTEGER) - (b?.study_data?.[complianceMarginKey] ?? Number.MAX_SAFE_INTEGER)
      const cZStudyData = studyDatas.filter((s) => firstExistingCz && s.climate_zone_raw === firstExistingCz).sort(sortResultFunc)
      return getAll ? cZStudyData : (cZStudyData.shift() || null)
    })
  }

  static async getForNewBuildingsByMeasureId(studyId, prototypeId, climateZoneRaw, fuelId, measureIds, getOne = true) {
    const searchClimateZones = StudyDataApiService.getSearchClimateZones(climateZoneRaw)
    const query = `
      {
        study_data(
          where: [
            {column: "study_id", value: "${studyId}", operation: "="}
            {column: "prototype_id", value: "${prototypeId}", operation: "="}
            {column: "fuel_id", value: "${fuelId}", operation: "="}
          ]
          whereIn: [
            { column: "measure_id", value: [${measureIds.map(m => `"${m}"`).join(',')}] }
            { column: "climate_zone_raw", value: [${searchClimateZones.map(m => `"${m}"`).join(',')}] }
          ]
        ) {
          ${DEFAULT_ATTRIBUTES}
        }
      }
    `
    const cacheKey = sha1(query)
    return await asyncCacheResolver(cacheKey, async () => {
      const { data } = await Graphql({ query, caller: 'StudyDataApiService.getForNewBuildingsByMeasureId' })
      const studyDatas = (!data || !data.study_data || !data.study_data.length ? [] : data.study_data)
        .map((s) => {
          return new StudyData({study_data: s})
        })
      const sortResultFunc = (a, b) => (a?.study_data?.initial_cost ?? Number.MAX_SAFE_INTEGER) - (b?.study_data?.initial_cost ?? Number.MAX_SAFE_INTEGER)

      const firstExistingCzIdx = searchClimateZones.map((czRaw) => {
        return !!studyDatas.find((s) => s.climate_zone_raw === czRaw)
      }).findIndex((i) => i === true)
      const firstExistingCz = firstExistingCzIdx >= 0 ? searchClimateZones[firstExistingCzIdx] : null
      const firstCzResult = studyDatas.filter((s) => firstExistingCz && s.climate_zone_raw === firstExistingCz).sort(sortResultFunc)
      if (!getOne) {
        return firstCzResult
      }
      return firstCzResult.shift() || null
    })
  }

  static async getAllAvailableByPrototype(prototypeId, climateZoneRaw) {
    const searchClimateZones = StudyDataApiService.getSearchClimateZones(climateZoneRaw)
    const query = `
      {
        study_data(
          where: [
            {column: "prototype_id", value: "${prototypeId}", operation: "="}
          ]
          whereIn: [
            { column: "climate_zone_raw", value: [${searchClimateZones.map(m => `"${m}"`).join(',')}] }
          ]
        ) {
          ${DEFAULT_ATTRIBUTES}
        }
      }
    `
    const cacheKey = sha1(query)
    return await asyncCacheResolver(cacheKey, async () => {
      const { data } = await Graphql({ query, caller: 'StudyDataApiService.getAllAvailableByPrototype' })
      const studyDatas = (data.study_data.length <= 0 ? [] : data.study_data)
        .map((s) => {
          return new StudyData({study_data: s})
        })

      const firstExistingCzIdx = searchClimateZones.map((czRaw) => {
        return !!studyDatas.find((s) => s.climate_zone_raw === czRaw)
      }).findIndex((i) => i === true)
      const firstExistingCz = firstExistingCzIdx >= 0 ? searchClimateZones[firstExistingCzIdx] : null
      return studyDatas.filter((s) => firstExistingCz && s.climate_zone_raw === firstExistingCz)
    })
  }

  static getSearchClimateZones(climateZoneRaw, recursive=true) {
    let searchClimateZoneRaws = [climateZoneRaw]
    const climateZone = Store.getters['globalEntities/ClimateZone/getterGlobalClimateZone']({ raw: climateZoneRaw })
    if (climateZone && climateZone.fallback_climate_zone && climateZone.fallback_climate_zone.raw) {
      if (recursive) {
        searchClimateZoneRaws = [...searchClimateZoneRaws, ...StudyDataApiService.getSearchClimateZones(climateZone.fallback_climate_zone.raw)]
      } else {
        searchClimateZoneRaws.push(climateZone.fallback_climate_zone.raw)
      }
    }
    return [... new Set(searchClimateZoneRaws)]
  }

  static async getAllForExisting({ climateZones, measureIds, vintageIds, prototypeIds }) {
    if (!climateZones?.length || !measureIds?.length || !vintageIds?.length || !prototypeIds?.length) return []
    const solver = (cz) => {
      const withFallback = StudyDataApiService.getSearchClimateZones(cz, false)
      if (withFallback.length <= 1) return withFallback
      return [...withFallback, ...solver(withFallback[1])]
    }
    climateZones = [... new Set(climateZones.map(solver).flat(Infinity))]
    const listFormatter = (list) => list.map(i => `"${i}"`).join(',')
    const query = `
      {
        study_data(
          whereIn: [
            { column: "vintage_id", value: [${listFormatter(vintageIds)}] }
            { column: "prototype_id", value: [${listFormatter(prototypeIds)}] },
            { column: "measure_id", value: [${listFormatter(measureIds)}] },
            { column: "climate_zone_raw", value: [${listFormatter(climateZones)}] },
          ]
        ){
          ${DEFAULT_ATTRIBUTES}
        }
      }
    `
    const cacheKey = sha1(query)
    return await asyncCacheResolver(cacheKey, async () => {
      const { data } = await Graphql({ query, caller: 'StudyDataApiService.getAllForExisting' })
      return { studyData: (data.study_data.length <= 0 ? [] : data.study_data), climateZones }
    })
  }
}
