<template>
  <h3>
    {{ count }}
  </h3>
</template>

<script>
export default {
  name: 'ComparisonStatistics',
  props: {
    event: {
      type: String,
      default: 'COMPARISON_SHOW'
    },
    type: {
      type: String,
      required: true
    },
    comparison_id: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      count: 0
    }
  },
  mounted() {
    this.getCount()
  },
  methods: {
    getCount() {
      const query = `
        {
          user_events (
            where: [
              { column: "event", value: "${ this.event }" },
              { column: "table", value: "comparisons" },
              { column: "table_item_id", value: "${ this.comparison_id }" },
            ]
            count: "${ this.type.toLowerCase() === 'total' ? 'id' : 'device_id' }"
          ) {
            count_total
          }
        }
      `
      this.$graphql({ query, caller: 'ComparisonStatistics' })
        .then(({ data }) => {
          this.count = data.user_events[0].count_total
        })
    }
  }
}
</script>