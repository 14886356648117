import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'

export const COLUMN_GROUP_KEYS = {
  CITY_WIDE : 'city_wide_impact',
  PER_HOME : 'per_home_impact'
}

import {
  initial_cost,
  annual_bill_savings,
  emission_savings,
  kwh_savings,
  therms_savings,
  lifecycle_savings,
  compliance_margin,
  forecast_units,
  forecast_emissions_savings,
  forecast_kwh_savings,
  forecast_therms_savings,
  forecast_lifecycle_savings,
  forecast_initial_cost,
  annual_bill_savings_care,
  lifecycle_savings_care,
  subsidy_needed,
  five_years_payback,
  subsidy_needed_care,
  five_years_payback_care,
  city_wide_subsidy_needed,
  city_wide_five_year_payback,
  city_wide_subsidy_needed_care,
  city_wide_five_year_payback_care,
  edr1_total_margin
} from '@/modules/app/shared/default-app-columns'
import { showTooltipOnNegativeValue } from '@/util/TooltipHelpers'

export default {
  [STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS]: {
    key: STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS,
    title: 'New Buildings',
    isActive: true,
    columnGroups: [
      {
        order: 0,
        key: COLUMN_GROUP_KEYS.CITY_WIDE,
        title: 'JURISDICTION-wide Estimates',
        columns: [
          forecast_units({ isActive: true }),
          {
            ...forecast_initial_cost({ isActive: true }),
            hasTooltipOnContentCell: showTooltipOnNegativeValue()
          },
          {
            ...forecast_emissions_savings({ isActive: true }),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
          {
            ...forecast_lifecycle_savings({ isActive: true }),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
          {
            ...forecast_kwh_savings({ isActive: true }),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
          {
            ...forecast_therms_savings({ isActive: true }),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
          city_wide_subsidy_needed({ isActive: false }),
          city_wide_five_year_payback({ isActive: false }),
          city_wide_subsidy_needed_care({ isActive: false }),
          city_wide_five_year_payback_care({ isActive: false }),
        ],
        meta: {
          hasTotal: true
        }
      },
      {
        order: 1,
        key: COLUMN_GROUP_KEYS.PER_HOME,
        title: 'PER-HOME ESTIMATES',
        columns: [
          edr1_total_margin(),
          compliance_margin(),
          {
            ...initial_cost({ isActive: true }),
            hasTooltipOnContentCell: showTooltipOnNegativeValue()
          },
          {
            ...annual_bill_savings({ isActive: true }),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
          {
            ...emission_savings({ isActive: true }),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
          lifecycle_savings({ isActive: true }),
          {
            ...kwh_savings({ isActive: true }),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
          {
            ...therms_savings({ isActive: true }),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
          {
            ...annual_bill_savings_care({ isActive: false }),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
          lifecycle_savings_care({ isActive: false }),
          subsidy_needed({ isActive: false }),
          five_years_payback({ isActive: false }),
          subsidy_needed_care({ isActive: false }),
          five_years_payback_care({ isActive: false }),
        ],
        meta: {
          hasTotal: false
        }
      },
    ]
  }
}
