import ImpactAssumptions from '@/models/ImpactAssumptions'
import { defaultNewBuildingsImpactAssumptionValues, defaultExistingBuildingsImpactAssumptionValues } from "@/models/ImpactAssumptions"
import { ASSUMPTIONS_PATHS, STUDY_TYPES } from '@/modules/app/assumptions/shared/enums.js'
import Store from '@/store'

import Policy from '@/models/Policy'

const checkAssumptionIsOpeningSameType = (state, type) => {
  return Boolean(state.drawerOptions?.visibility && state.drawerOptions?.path && state.drawerOptions?.path?.slug === type?.slug)
}

export default { 
  closeDrawer(state) {
    state.drawerOptions.visibility = false
  },
  openDrawer(state, tabSelected = false) {
    state.drawerOptions = {
      ...state.drawerOptions,
      visibility: true,
      path: false,
      tab: tabSelected
    }
  },

  openDrawerAtSelectedAssumptionsPath(state, path = false, tabSelected = false){
    state.drawerOptions.path = path

    state.drawerOptions = {
      ...state.drawerOptions,
      visibility: true,
      tab: tabSelected
    }
  },
  openDrawerAtImpactTab(state) {
    state.drawerOptions = {
      ...state.drawerOptions,
      visibility: true,
      path: ASSUMPTIONS_PATHS.IMPACT_RESULTS
    }
  },
  openDrawerAtBuildingEstimatesResidentialExisting(state) {
    if(!checkAssumptionIsOpeningSameType(state, ASSUMPTIONS_PATHS.RESIDENTIAL_BUILDINGS)) {
      state.drawerOptions = {
        ...state.drawerOptions,
        visibility: true,
        path: ASSUMPTIONS_PATHS.RESIDENTIAL_BUILDINGS,
        tab: STUDY_TYPES.EXISTING
      }
    }
  },
  openDrawerAtBuildingEstimatesResidentialNew(state, payload = ASSUMPTIONS_PATHS.RESIDENTIAL_BUILDINGS) {
    for(const key in ASSUMPTIONS_PATHS ){
      if(ASSUMPTIONS_PATHS[key].slug === payload.slug) {
        const type = ASSUMPTIONS_PATHS[key]
        if(!checkAssumptionIsOpeningSameType(state, type)) {
          state.drawerOptions = {
            ...state.drawerOptions,
            visibility: true,
            path: ASSUMPTIONS_PATHS[key],
            tab: STUDY_TYPES.NEW
          }
        }
      }
    }
  },
  toggleDrawer(state, {forceVisibility = null, tabSelected = false}) {
    if(forceVisibility !== null) {
      state.drawerOptions.visibility = forceVisibility
    } else {
      state.drawerOptions.visibility = !state.drawerOptions.visibility
    }

    state.drawerOptions = {
      ...state.drawerOptions,
      tab:tabSelected
    }
  },
  updateDrawerPath(state, path) {
    state.drawerOptions.path = path
  },
  updateDrawerTab(state, tab) {
    state.drawerOptions.tab = tab
  },
  updateDrawerType(state, type) {
    state.drawerOptions.type = type
  },
  setDrawerOptions(state, drawerOptions) {
    state.drawerOptions = drawerOptions
  },
  updateAssumptionsKeyValue(state, { key, value }) {
    state[state.drawerOptions.type][key] = value
  },
  setPolicyIdSelected(state, policy_id = null) {
    if ( policy_id != state.policy_id_selected) {
      Store.commit('policy/resetHiddenItemsState')
    }
    state.policy_id_selected = policy_id
    if (!state.policy_id_selected) {
      state.policy_selected = null
      state.policy_custom_combinations_selected = null
      state.policyImpactAssumptions = {}
    }
  },
  setPolicySelected(state, policy = {}) {
    state.policy_selected = new Policy(policy)
  },
  setPolicyCustomCombinationsSelected(state, customCombinationIds = null) {
    state.policy_custom_combinations_selected = Array.isArray(customCombinationIds) ? customCombinationIds : null
  },
  setPolicyImpactAssumptions(state, impactAssumptions = {}) {
    // It's so wrong having impact assumption interface as a policy object, so we are trying to fix it,
    //   but maybe it can affect other places, if so, please fix in that place instead of returning a policy right here...
    // state.policyImpactAssumptions = new Policy(policy)
    state.policyImpactAssumptions = new ImpactAssumptions(impactAssumptions || {})
  },
  clearResultsImpactAssumptions(state) {
    const restoreDefaults = (assumption, defaults) => {
      for ( let key in {...defaults}) {
        assumption[key] = defaults[key]
      }
    }
    restoreDefaults(state.existingBuildingsResultsImpactAssumptions, defaultExistingBuildingsImpactAssumptionValues)
    restoreDefaults(state.newBuildingsResultsImpactAssumptions, defaultNewBuildingsImpactAssumptionValues)
  },
  clearResultsImpacAssumptionsByObjectKey(state, defaultObj){
    const restoreDefaults = (assumption, obj, keys) => {
      for ( let key in {...keys}) {
        if(assumption[key]) {
          assumption[key] = obj[key]
        }
      }
    }
      restoreDefaults(state.existingBuildingsResultsImpactAssumptions, defaultExistingBuildingsImpactAssumptionValues, defaultObj)
      restoreDefaults(state.newBuildingsResultsImpactAssumptions, defaultNewBuildingsImpactAssumptionValues, defaultObj)
  },
  clearPolicyImpacAssumptionsByObjectKey(state, defaultObj){
    const restoreDefaults = (assumption, obj, keys) => {
      for ( let key in {...keys}) {
        if(assumption[key]) {
          assumption[key] = obj[key]
        }
      }
    }
    restoreDefaults(state.policyImpactAssumptions, defaultExistingBuildingsImpactAssumptionValues, defaultObj)
    restoreDefaults(state.policyImpactAssumptions, defaultNewBuildingsImpactAssumptionValues, defaultObj)
    
    state.policy_selected = new Policy({
      ...(state.policy_selected || {}),
      ...state.policyImpactAssumptions || {}
    })
  },
  clearPolicyImpactAssumptions(state, defaultValues) {
    // It's so wrong having impact assumption interface as a policy object, so we are trying to fix it,
    //   but maybe it can affect other places, if so, please fix in that place instead of returning a policy right here...
    state.policy_selected = new Policy({
      ...(state.policy_selected || {}),
      ...(state.policyImpactAssumptions || {}),
      ...new ImpactAssumptions(defaultValues) }
    )
    state.policyImpactAssumptions = new ImpactAssumptions(defaultValues)
  },
  showNotLoggedWarning(state, showNotLoggedWarning) {
    state.showNotLoggedWarning = showNotLoggedWarning
  },
  updateDrawerIsEditingFromChart(state, isEditingFromChart){
    state.drawerOptions.isEditingFromChart = isEditingFromChart
  },
  updateTypePrototypeEditing(state, typePrototype){
    state.drawerOptions.typePrototypeEditing = typePrototype
  },
  updateLastClimateZoneSelected(state, climateZone){
    state.drawerOptions.lastClimateZoneSelected = climateZone
  },
  setupImpactAssumptionsModels(state) {
    if (state.existingBuildingsResultsImpactAssumptions) {
      state.existingBuildingsResultsImpactAssumptions = new ImpactAssumptions({
        ...state.existingBuildingsResultsImpactAssumptions
      }, defaultExistingBuildingsImpactAssumptionValues)
    }
    if (state.newBuildingsResultsImpactAssumptions) {
      state.newBuildingsResultsImpactAssumptions = new ImpactAssumptions({
        ...state.newBuildingsResultsImpactAssumptions
      }, defaultNewBuildingsImpactAssumptionValues)
    }
  }
}
