<template>
  <div
    class="savetopolicy-form"
    :class="{ 'pointer-events-none transition-all': !storeRequestIsFinished }"
  >
    <h2 class="ts--accent--1 text-bluebrand font-bold">
      {{ 'Save selection to a policy' | policyTextRephrase }}
    </h2>
    <p class="text-header-1 text-gray03 mt-1 mb-5">
      {{ getJurisdictionSelected.titles.title_type }}
    </p>

    <AppLoader
      v-if="isLoadingUserPolicies"
      class="mt-6"
    />

    <div
      v-else
      class="w-full flex flex-col"
    >
      <div
        v-if="getExistingBuildingsUserPolicies.length"
        class="policies-list w-full flex flex-col overflow-y-auto pr-3"
      >
        <button
          v-for="policy in getExistingBuildingsUserPolicies"
          :key="policy.id"
          class="policy-item transition-all px-2 py-3 border relative rounded-sm mb-3 text-left"
          :class="{
            'border-blue04 bg-blue04-0 opacity-100 is-selected': checkPolicyIsSelected(policy),
            'border-blue05': !checkPolicyIsSelected(policy),
            'cursor-pointer': !checkUserHasCustomCombination(policy),
          }"
          @click="selectPolicy(policy)"
        >
          <span class="px-6 flex flex-col">
            <Checkbox
              :value="checkPolicyIsSelected(policy)"
              :label="policy.title"
              :class="{ 'font-bold': checkPolicyIsSelected(policy) }"
              :prevent="true"
              checkbox-classes="mb-0"
            />
            <span
              v-if="policy.updated_at"
              class="text-gray03 text-p-1"
            >Edited: {{ formatDate({ date: policy.updated_at }) }}</span>
          </span>
          <div
            v-if="!checkPolicyIsSelected(policy) && checkUserHasCustomCombination(policy)"
            class="absolute top-0 right-0 mr-6 h-full flex items-center text-gray04"
          >
            <ToolTip
              :has-action="true"
              dialog-classes="p-4 right-0 bg-ash-100 border border-ash-300 max-w-xs"
            >
              <template #trigger>
                <feather
                  type="info"
                  size="16"
                  class="text-gray03"
                />
              </template>
              <template #dialog>
                <div v-html="getPolicyToolTipText(policy)" />
              </template>
            </ToolTip>
          </div>
          <div
            v-if="checkPolicyIsSelected(policy) && checkUserHasCustomCombination(policy)"
            class="w-full flex-shrink-0 mt-2 pl-6 text-header-1 text-ash-500"
          >
            {{ getAddMeasuresPolicyFormText }}
          </div>
        </button>
      </div>

      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="userPoliciesSelected.length"
          class="add-to-policy-wrapper flex item-center flex-col mt-4"
        >
          <span class="my-auto">
            <AppButton
              :label="userPoliciesSelected.length > 1 ? 'Save To Policies' : 'Save To Policy' | policyTextRephrase"
              size="medium"
              @click="saveToSelectedPolicies()"
            />
          </span>
        </div>
      </transition>

      <p
        v-if="userPoliciesSelected.length"
        class="mt-6 mb-8 w-full or-separator border-blue05 text-gray03"
      >
        <span>or</span>
      </p>

      <ExistingBuildingPolicyFormCreate @store-success="onPolicyStoreSuccess" />
    </div>
  </div>
</template>

<script>
import ExistingBuildingPolicyFormCreate from '@/modules/app/policy/shared/ExistingBuildingPolicyFormCreate.vue'
import AppButton from '@/components/general/AppButton.vue'
import Checkbox from '@/components/general/Checkbox.vue'
import CustomCombination from '@/models/CustomCombination'
import { toggleInArray } from '@igortrindade/lazyfy'
import { getIfPoliciesHasAnyMeasureConflict } from '@/util/Helpers.js'
import FlexiblePathService from '@/services/api/FlexiblePathService.js'


export default {
  name: 'CustomCombinationToPolicyForm',
  components: { Checkbox, AppButton, ExistingBuildingPolicyFormCreate },
  props: {
    measures: {
      type: Array,
      required: true,
    },
    study_id: {
      type: Number,
      required: true,
    },
    prototype_id: {
      type: Number,
      required: true,
    },
    vintage_id: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    storeRequestIsFinished: true,
    isLoadingUserPolicies: false,
    userPoliciesSelected: [],
  }),
  computed: {
    getJurisdictionSelected() {
      return this.$store.getters['lastJurisdictionVisited']
    },

    getExistingBuildingsUserPolicies() {
      const allExistingBuildingUserPolicies = this.$store.getters['policy/getterUserPoliciesByStudyType']({
        study_type_slugs: ['existing-buildings'],
      })
      return allExistingBuildingUserPolicies.filter(
        (policy) => policy.jurisdiction_id == this.getJurisdictionSelected.id
      )
    },
    getAddMeasuresPolicyFormText(){
      const measureLength = this.measures.length > 1
      return `${measureLength ?"These measures are" : "This measure is"} saved to your policy . You may want to check for potential conflicts between your policy requirements by reviewing any previously set mandatory measures or required flexible scores.`
    }
  },
  methods: {
    selectPolicy(policy) {
      this.userPoliciesSelected = toggleInArray(this.userPoliciesSelected, {
        id: policy.id,
        jurisdiction_id: policy.jurisdiction_id,
        title: policy.title,
      })
    },

    checkPolicyIsSelected(policy) {
      return this.userPoliciesSelected.findInArray({
        id: policy.id,
        jurisdiction_id: policy.jurisdiction_id,
        title: policy.title,
      })
    },

    saveToSelectedPolicies() {

        const getTypeVintageId = this.$store.getters['globalEntities/Vintage/getterGlobalVintage']({id:this.vintage_id})
        let hasConflict = false

          this.userPoliciesSelected.forEach(async policy => {
          const policyBase = this.$store.getters['policy/getterUserPolicy']({id:policy.id})

          
          const flexible_items = new FlexiblePathService({ policy_id: policy.id })
          await flexible_items.getSetup()
          .then((setup) => {
            policyBase.policy_containers.forEach(container => {
              container.custom_combinations.forEach(cc => {
                const climate_zone = this.$store.getters['globalEntities/ClimateZone/getterGlobalClimateZone']({raw:cc.climate_zone_raw})
                const prototype_id = cc.prototype_id
                if(getIfPoliciesHasAnyMeasureConflict({prototype_id, climate_zone, type_vintage_id:getTypeVintageId.type_vintage_id, measures:this.measures, setup})) {
                  hasConflict = true
                }
              })
            })
          })
        })

      if(hasConflict) {

        this.$toast.info(
          'The selected measures cannot be saved in the currently chosen policy as doing so would create conflicts with existing policy measures.', 
          { duration: 10000 }
        )
        
        return
      } 


      this.storeRequestIsFinished = false
      const promises = this.userPoliciesSelected.map((policy) => this.storeCustomCombination(policy))
      Promise.all(promises).then(() => {
        this.userPoliciesSelected.forEach((policy) => this.onStoreCombinationSuccess(policy))
        this.storeRequestIsFinished = true
        this.$emit('success')
      })
    },

    onPolicyStoreSuccess(policy) {
      this.storeCustomCombination(policy).then((res) => {
        if (res) this.onStoreCombinationSuccess(policy)
        this.$emit('success')
      })
    },

    storeCustomCombination(policy) {
      const custom_combination = new CustomCombination({
        policy_id: policy.id,
        jurisdiction_id: policy.jurisdiction_id,
        climate_zone_raw: this.$store.getters['lastClimateZoneVisited'].raw,
        study_id: this.study_id,
        prototype_id: this.prototype_id,
        vintage_id: this.vintage_id,
        measures: this.measures,
      })
      return this.$store.dispatch('policy/saveCustomCombinationsToPolicy', [custom_combination])
    },

    onStoreCombinationSuccess(policy) {        
      this.$appToast({
        message: `${this.measures.length} measure${this.measures.length > 1 ? 's' : ''} added to ${policy.title}`,
        feather: 'check',
        action: this.$options.filters.policyTextRephrase('GO TO POLICY'),
        onClick: () => {
          this.$router.push({ name: 'PolicyShow', params: { policy_id: policy.id } })
        },
        duration: 5500,
      })
    },
    checkUserHasCustomCombination(policy) {
      if (!policy.policy_containers) return false
      for (const policy_container of policy.policy_containers) {
        for (const custom_combination of policy_container.custom_combinations) {
          if (
            custom_combination.climate_zone_raw == this.$store.getters['lastClimateZoneVisited'].raw &&
            custom_combination.prototype_id == this.prototype_id &&
            custom_combination.vintage_id == this.vintage_id
          )
          return true
        }
      }
      return false
    },

    getPolicyToolTipText(policy) {
      if (!policy.custom_combinations) return false
      let climate_zone_raw, prototype, vintage

      for (const policy_container of policy.policy_containers) {
        for (const custom_combination of policy_container.custom_combinations) {
          if (custom_combination.climate_zone_raw == this.$store.getters['lastClimateZoneVisited'].raw)
            climate_zone_raw = custom_combination.climate_zone_raw
          if (custom_combination.prototype_id == this.prototype_id)
            prototype = this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({
              id: this.prototype_id,
            }).title
          if (custom_combination.vintage_id == this.vintage_id)
            vintage = this.$store.getters['globalEntities/Vintage/getterGlobalVintage']({ id: this.vintage_id }).title
        }
      }
      return `
        <p class="font-bold text-ash-700">
          You already have measures saved for ${prototype.toLowerCase()} built ${vintage.toLowerCase()}
          in climate zone ${climate_zone_raw.replace(/\D/g, '')}.
        </p>
        <p class="mt-1 text-ash-700">Those measures will be overwritten if you proceed.</p>
      `
    },
  },
}
</script>

<style lang="scss" scoped>
.savetopolicy-form {
  min-width: 395px;
}
.policies-list {
  max-width: 395px;
  max-height: 274px;
}
.is-selected {
  background-color: #f2f7f9;
}

::v-deep {
  .policy-item {
    &.is-selected,
    &:hover {
      .app-checkbox-item-input {
        opacity: 1;
      }
    }
  }
  .app-checkbox-item-input {
    position: absolute;
    left: 8px;
    opacity: 0;
    + span {
      margin-left: 0;
    }
  }
}
</style>
