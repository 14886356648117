import PolicyOptionApiService from '@/services/api/PolicyOptionApiService'

export default {
  namespaced: true,
  
  state: {
    policy_options: []
  },

  getters: {
    getterGlobalPolicyOptions: (state) => (filters = null) => {
      return filters ? state.policy_options.findAllInArray(filters) : state.policy_options
    },

    getterGlobalPolicyOption: (state) => (filters = null) => {
      return filters ? state.policy_options.findInArray(filters) : false
    },

  },

  actions: {
    loadGlobalPolicyOptions(context) {
      PolicyOptionApiService.get()
        .then(({ policy_options }) => {
          context.commit('setGlobalPolicyOptions', { policy_options: policy_options })
        })
    },

    storeGlobalPolicyOption(context, { policy_option }) {
      return PolicyOptionApiService.updateOrCreate(policy_option)
        .then((response) => {
          if ( policy_option.id ) {
            return context.dispatch('loadGlobalPolicyOptions')
          } else {
            context.commit('addGlobalPolicyOption', { policy_option: response.policy_option })
          }
        })
        .catch((err) => {
          window.$vueInstance.$appToast({ message: err?.response?.data?.message ?? 'Sorry, try again later' })
        })
    },

    deletePolicyOption({ dispatch }, { policy_option } ){
      return PolicyOptionApiService.delete(policy_option.id)
        .then(() => {
          return dispatch('loadGlobalPolicyOptions')
        })
        .catch((err) => {
          window.$vueInstance.$appToast({ message: err?.response?.data?.message ?? 'Sorry, try again later' })
        })
    },
    
  },

  mutations: {
    setGlobalPolicyOptions(state, { policy_options }) {
      state.policy_options = policy_options
    },

    addGlobalPolicyOption(state, { policy_option }) {
      state.policy_options = [ ...state.policy_options, policy_option ]
    },
  }
}

