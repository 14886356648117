<template>
  <div class="w-full flex flex-col">
    <div class="w-full flex justify-between">
      <div class="flex items-center space-x-4">
        <div class="f-icon psui-flex psui-flex-none psui-items-center psui-justify-center psui-rounded-md psui-bg-gray-10 psui-p-3">
          <PsIcon 
            :icon="itemTypePrototype.type_prototype.icon"
            size="40"
            color="psui-text-gray-60"
            icon-classes="psui-text-gray-60"
            :class="`psui-m-1`"
          />
        </div>
        
        <div class="psui-w-full psui-flex psui-flex-col">
          <h3 class="psui-text-gray-80 psui-text-h5 psui-font-bold">
            {{ itemTypePrototype.type_prototype.title }}
          </h3>
          <div class="psui-text-small psui-text-gray-50 mt-1">
            {{ getTypePrototypesCount(itemTypePrototype.type_prototype_id) }} Policies that includes {{ itemTypePrototype.type_prototype.title }}
          </div>
        </div>
      </div>

      <div class="flex space-x-4">
        <div
          v-for="policyType in policyTypesAvailables"
          :key="policyType.title"
          class="select-units psui-flex psui-flex-col psui-space-y-1 psui-rounded psui-border psui-border-gray-20 psui-text-gray-50 psui-px-4 psui-py-2 psui-cursor-pointer psui-transition-all"
          :class="{ 'psui-bg-blue-10 psui-border-blue-50 psui-text-blue-60 active': policyTypeSelected === policyType }"
          @click.stop="policyTypeSelected = policyType"
        >
          <h3 class="psui-text-small psui-font-bold psui-whitespace-nowrap">
            <span>{{ policyType.title }}</span>
          </h3>
          <h2 class="psui-header-3 psui-font-bold psui-whitespace-nowrap">
            <span class="font-normal">{{ getPoliciesByType(policyType.key).length }} policies</span>
          </h2>
        </div>
      </div>
    </div>

    <PoliciesProfileTypePrototypeChart
      :get-policies-by-type="getPoliciesByType(policyTypeSelected.key)"
      :item-type-prototype-index="itemTypePrototypeIndex"
      :policy-type-selected="policyTypeSelected"
    />
  </div>
</template>

<script>
import { ArrayHelpers } from '@igortrindade/lazyfy'
import PoliciesProfileTypePrototypeChart from './PoliciesProfileTypePrototypeChart'
const policyTypesAvailables = [{ key: 'vintage_id', title: 'Existing Buildings' }, { key: 'fuel_id', title: 'New Buildings' }]
export default {
  components: { PoliciesProfileTypePrototypeChart },
  props: ['getMappedPolicies', 'itemTypePrototype', 'itemTypePrototypeIndex'],
  data() {
    return {
      policyTypeSelected: policyTypesAvailables[0],
      policyTypesAvailables
    }
  },
  computed: {
    getTypePrototypePolicies() {
      return ArrayHelpers.findAll(this.getMappedPolicies, { type_prototype_id: this.itemTypePrototype.type_prototype_id })
    }
  },
  methods: {

    getTypePrototypesCount() {
      return ArrayHelpers.uniqueByKey(
        this.getTypePrototypePolicies
      , 'id').length
    },

    getPoliciesByType(key) {
      return ArrayHelpers.uniqueByKey(
        this.getTypePrototypePolicies.filter((policy) => policy[key] !== null)
      , 'id')
    },
  }
}
</script>