<template>
  <div
    class="app-simple-progress-bar w-full flex-shrink-0 relative h-8 flex items-center"
    :style="{width: `${width}px`}"
    :class="{ 'is-breakeven' : getIsBreakEven }"
  >
    <div class="bars line block absolute w-full rounded-full" />
    <div
      class="bars block absolute rounded-tl-full rounded-bl-full" 
      :class="[getBg, {'rounded-tr-full rounded-br-full': getProgressWidth === '100%'}]" 
      :style="{ width: getProgressWidth }"
    />
    <div
      class="block absolute divider border-r-2 psui-border-gray-30 rounded-full"
      :style="{ marginLeft: getBreakEvenLeft }"
    />
  </div>
</template>

<script>
import { GREATER_THEN_ONE_NUMBER } from '@/util/Enums'
export default {
  name: 'SimpleProgressBar',
  props: {
    width: {
      type: [String, Number],
      default: 75
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 5
    },
    breakEven: {
      type: Number,
      default: 1
    },
    value: {
      type: [String, Number],
      default: null
    },
    positiveBg: {
      type: String,
      default: 'psui-bg-blue-60'
    },
    negativeBg: {
      type: String,
      default: 'psui-bg-red-20'
    }
  },
  computed: {
    getVolume(){
      return (this.max - this.min)
    },
    getProgress() {
      if(this.value == GREATER_THEN_ONE_NUMBER) return 100
      const progress = (100 / this.getVolume) * this.value
      return progress > 100 ? 100 : progress
    },
    getProgressWidth() {
      return `${this.getProgress}%`
    },
    getIsBreakEven() {
      return this.value >= this.breakEven ? true : false
    },
    getBreakEvenLeft() {
      return `${(100 / this.getVolume) * this.breakEven}%`
    },
    getBg() {
      return this.getIsBreakEven ? this.positiveBg : this.negativeBg
    }
  }
}
</script>

<style lang="scss" scoped>
  .app-simple-progress-bar {
    .bars {
      height: 8px;
    }

    .divider {
      height: 14px;
    }
    &:not(.is-breakeven) {
      
      > div:first-of-type {
        background-color: #FCEBEB;
      }
    }

    &.is-breakeven {
      > div:first-of-type {
        background-color: #E0EFF6;
      }
    }
  }
</style>