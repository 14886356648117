<template>
  <div class="psui-p-6 md:psui-p-10">
    <div class="hero psui-flex psui-items-center psui-justify-between psui-gap-10 md:psui-gap-24 psui-bg-white psui-w-full psui-shadow-elevation-5 psui-flex-col xl:psui-flex-row psui-rounded-md">
      <div class="psui-flex psui-flex-col psui-flex-grow">
        <h1 class="psui-text-blue-80 psui-text-h4 md:psui-text-h2 psui-font-bold mb-10 text-center xl:text-left">
          Create an account to save your policies, and learn about new features and resources!
        </h1>
        <div class="psui-flex psui-flex-col psui-items-center psui-gap-4 xl:psui-flex-row psui-text-center xl:psui-text-left">
          <PsButton
            class="flex-shrink-0"
            label="Create your account"
            layout="solid"
            @click="$router.push({ name: 'UserSignin' })"
          />
          <router-link
            class="psui-text-blue-60 psui-font-bold"
            :to="{ name: 'PolicyOptions' }"
          >
            or start exploring policy options and other resources
          </router-link>
        </div>
      </div>
      <div class="flex-shrink-0 psui-mx-auto xl:psui-mr-0">
        <img
          src="images/home_page/home_page_workstation.jpg"
          alt="Create an account to save your policies, and learn about new features and resources!"
          class="psui-w-full"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'HomeCreateAccountHero',
}
</script>

<style lang="scss" scoped>
  .hero {
    padding: 1.5rem;

    img { 
      max-width: 252px;
    }

    @media (min-width: 576px) {
      padding: 2.5rem;
    }

    @media (min-width: 768px) {
      padding: 71px 70px;
    }

    @media (min-width:1280px){
      padding: 71px 144.5px;  
    }
  }
</style>