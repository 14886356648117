import numWords from "num-words"
import dayjs from 'dayjs'
import Store from '@/store'
import { ASSUMPTIONS_DRAWER_DIVISION, JURISDICTION_TYPES, STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import { ObjectHelpers } from '@igortrindade/lazyfy'
import { basicAssumptionKeys, advancedAssumptionKeys } from '@/modules/app/assumptions/shared/defaultAssumptions.js'
import MeasureApiService from "@/services/api/MeasureApiService"
import { compliance_margin } from "@/modules/app/shared/default-app-columns"
// import { getMeasureScore }from '@/services/measure-menu/MeasureMenuGeneralService.js'


export const parseClimateZonesToInteger = (items) => {
  return items.map((item) => parseInt(item.split("-").shift()))
}

export const parseIntegerToSentence = ({ value, valueToApplyMultiple }) => {
  if (value >= valueToApplyMultiple) {
    return "multiple"
  } else {
    return numWords(value)
  }
}

export const parsejurisdictionAsFullText = (jurisdiction, capitalize = false) => {
  if (jurisdiction) {
    if (jurisdiction.type == "City") {
      if(capitalize) return `City of ${jurisdiction.title}`
      return `city of ${jurisdiction.title}`
    } else {
      return `${jurisdiction.title} County`
    }
  } else {
    return false
  }
}


export const slugify = (value) => {
  let slug = ""
  if (Array.isArray(value)) {
    return slugify(value.map(slugify))
  } else {
    slug = value.toLowerCase().trim()
    slug = slug.replace(/[^\w\s-]/g, "")
    slug = slug.replace(/\s+/g, "-")
    return slug
  }
}

const defaultPercentageFormatter = {
  style: "percent",
  minimumFractionDigits: 1,
  maximumFractionDigits: 2,
}

export const convertToPercentageIfIsANumber = ( { value, maxValue, numberFormatter, maximumFractionDigitsIfHigherThanOne } = {}) => {
  
  if (typeof value === "number") {
    
    const valueAsPercentage = value / maxValue
    let formatter = { ...defaultPercentageFormatter, ...numberFormatter}

    if (maximumFractionDigitsIfHigherThanOne && valueAsPercentage > 0.01 ) {
      formatter = { ...formatter, maximumFractionDigits : 1 }
    }
    const percentageFormatter = new Intl.NumberFormat("en-US", formatter )

    return percentageFormatter.format(valueAsPercentage)
  } else {
    return value
  }

}

export const formatAsPercentage = (value) => {
  const percentageFormatter = new Intl.NumberFormat("en-US", defaultPercentageFormatter )
  return percentageFormatter.format(value)
}

const numberFormatter = new Intl.NumberFormat("en-US")
export const formatAsNumber = (value) => {
  return numberFormatter.format(value)
}

export const capitalizeString = (s) => {
  if (typeof s !== "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const copyUrlToClipboard = () => {
  const dummy = document.createElement("input"),
    text = window.location.href
  document.body.appendChild(dummy)
  dummy.value = text
  dummy.select()
  document.execCommand("copy",false,text)
  document.body.removeChild(dummy)
}

export const copyToClipboard = (string) => {
  const dummy = document.createElement("input")
  document.body.appendChild(dummy)
  dummy.value = string
  dummy.select()
  document.execCommand("copy")
  document.body.removeChild(dummy)
}

export const scaleDimensions = ({ width, height, maxWidth, maxHeight } = { }) => {
  
  function dimensionsByScale(scale) {
    return { width: width * scale, height: height * scale }
  }

  let scale, dimensions

  if (width < maxWidth ) {
    scale = maxWidth / width
  } else {
    scale = maxHeight / height
  }

  dimensions = dimensionsByScale(scale)
  if (dimensions.width > maxWidth) {
    scale = maxWidth / width
    dimensions = dimensionsByScale(scale)
  } else if (dimensions.height > maxHeight) {
    scale = maxHeight / height
    dimensions = dimensionsByScale(scale)
  }
  
  // console.table({ width, height, scaledWidth : dimensions.width, scaledHeight: dimensions.height, maxWidth, maxHeight })
  return dimensions
}

export const getBaseUrlImageDimensions = (baseUrl) => {
  return new Promise(done => {
    const i = new Image
    i.onload = () => {
      done({ width: i.width, height: i.height })
    }
    i.src = baseUrl
  })
}


export const defaultManualTooltipOptions = { 
  html: true, 
  placement: 'top-center', 
  show: true, 
  trigger: 'manual', 
  hideOnTargetClick: false, 
  autoHide: false, 
  popperOptions : {
    modifiers: { 
      preventOverflow: { enabled: false },
      hide: { enabled: false } 
    }
  }
}

export const formatDate = ({date, format = 'MM/DD/YYYY', from = 'YYYY-MM-DD', lang = 'en'}) => {
  if (!date) return ''
  dayjs.locale(lang)
  return dayjs(date, from).format(format)
}

export const jurisdictionTitleTypeBasedOnJurisdictionType = (jurisdiction, jurisdictionKey, appositive = false, fromPDF = false, fromComparisonDrawer = false) => {
  if (!jurisdiction) return ''
  const screenWidth = window.innerWidth

  const adjustJuridiction = (jurisdiction) => {
    const jurisdictionTypes = [
      { type: JURISDICTION_TYPES.COUNTY },
      { type: JURISDICTION_TYPES.CCA },
      { type: JURISDICTION_TYPES.REN },
      { type: JURISDICTION_TYPES.COG_CAG },
      { type: JURISDICTION_TYPES.UTILITY },
      { type: JURISDICTION_TYPES.AQMD },
      { type: JURISDICTION_TYPES.STATE },
      { type: JURISDICTION_TYPES.CLIMATE_ZONE },
      { type: JURISDICTION_TYPES.AREA },
    ]

    const jurisdictionType = jurisdictionTypes.find(jt => jurisdiction && jurisdiction.type && jt.type.trim().toLowerCase() === jurisdiction.type.trim().toLowerCase())

    if (jurisdictionType) {
      if (jurisdiction.type === JURISDICTION_TYPES.COUNTY && appositive) {
        return `${jurisdiction.titles.title_type_short}`
      } else if (jurisdiction.title.endsWith(')') && appositive && (jurisdiction.type == JURISDICTION_TYPES.CCA || jurisdiction.type == JURISDICTION_TYPES.REN || jurisdiction.type == JURISDICTION_TYPES.COG_CAG || jurisdiction.type == JURISDICTION_TYPES.UTILITY || jurisdiction.type == JURISDICTION_TYPES.AQMD)) {
        return `${jurisdiction.titles.title_type_short}`
      } else if (jurisdiction.title.endsWith(')') && jurisdiction.title.length > 40 && (screenWidth < 1280 || fromPDF)) {
        return jurisdiction.titles.title_type_short
      } else if (fromComparisonDrawer && jurisdiction.titles?.title_type.length > 45) {
        return jurisdiction.titles.title_type_short
      } else {
        return `${jurisdiction.titles?.title_type}`
      }
    }

    return jurisdiction[jurisdictionKey]
  }

  if (Array.isArray(jurisdiction)) {
    return jurisdiction.map(adjustJuridiction)
  }

  return adjustJuridiction(jurisdiction)
}

export const checkIfPolicyIsOutdated = (policy) => {

  const globalPrototypes = Store.getters['globalEntities/Prototype/getterGlobalPrototypes']()

  const getStudy = (id) => Store.getters['globalEntities/Study/getterGlobalStudy']({id})

  const prototypeIdsAndStudy = policy?.policy_containers?.flatMap(container =>
    container?.custom_combinations?.map(combination => {
      return {
        prototype_id: combination?.prototype_id, 
        study: getStudy(combination?.prototype?.study_id)
      }
    })
  )?.reduce( (acc, curr) =>{
      if(!acc.length || !acc.find(item => +item.prototype_id === +curr.prototype_id)) {
        acc.push(curr)
      }
    return acc
  },[])

  if (!policy || !prototypeIdsAndStudy?.length || prototypeIdsAndStudy?.some((i) => i.study === false)) {
    return {
      block_policy: false,
      has_outdated_studies: false,
      is_loading: true,
    }
  }

  const globalPrototypeIds = globalPrototypes
    .filter(prototype => prototype?.allow_policy_creation === true)
    .map(({ id }) => Number(id))

  const policyStudyTypes = Store.getters['policy/getterPolicyStudyTypes']({ policy_id: policy.id })
  const policyType = Array.isArray(policyStudyTypes) ? policyStudyTypes.pop() : null
  const shouldBlockPolicyView = prototypeIdsAndStudy?.some((i) => i.study.disable_outdated_modal !== true) && policyType !== STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS
  const hasOutdatedStudies = globalPrototypeIds.length > 0 && prototypeIdsAndStudy?.some(item =>
    !globalPrototypeIds?.includes(Number(item.prototype_id))
  )
  return {
    block_policy: shouldBlockPolicyView && hasOutdatedStudies,
    has_outdated_studies: hasOutdatedStudies,
    is_loading: false,
  }
}

export const policyHasAnyContainerWithComplianceMarginKey = (policy, checkComplianceMarginKey) => {
  const policyStudyIds = policy.policy_containers.reduce((acc,curr) => {
    curr.custom_combinations.forEach(cc => {
      if(acc.length === 0 || !acc.includes(cc.prototype.study_id)){
        acc.push(cc.prototype.study_id)
      }
    })
    return acc 
  },[])  
  
  return policyStudyIds.some( studyId =>{
    const study = Store.getters['globalEntities/Study/getterGlobalStudy']({ id: studyId })
    return study.compliance_margin_key === (checkComplianceMarginKey || compliance_margin().key)
  })
}

export const truncateString = (string, limit, ending = '...') => {
  if (typeof string !== "string") return ""
  if (string.length > limit) {
    return string.substring(0, limit) + ending
  } else {
    return string
  }
}

export const getSubsetAssumptionsByKey = (key, assumptionsObject) => {
  if(!assumptionsObject) return false
  const assumption_keys = key === ASSUMPTIONS_DRAWER_DIVISION.BASIC ? basicAssumptionKeys : advancedAssumptionKeys
  return ObjectHelpers.filterObjectKeys(assumption_keys, assumptionsObject)
}

export const createArrayOfBuildingEstimatesFilter = ({climateZonePrefix,typePrototypeIdArray,TypeVintageIdArray,yearArray}) => {
  let resultArray = []

    const secondProperty = TypeVintageIdArray ?? yearArray

      for(let typePrototype of typePrototypeIdArray) {
        for(let propertyThree of secondProperty) {
          resultArray.push(
            {
              ...(climateZonePrefix && {climate_zone_prefix:climateZonePrefix}),
              ...(TypeVintageIdArray &&  {type_vintage_id: propertyThree.id}),
              ...(yearArray && {year: propertyThree}),  
              type_prototype_id: typePrototype,
          })
        }
      }

    return resultArray
}

export const checkIfHasFlexiblePathSetUp = ({ type_vintage, climate_zone_raw, flexible_path_setup, type_prototype_id }) => {
  const climate_zone = Store.getters['globalEntities/ClimateZone/getterGlobalClimateZone']({ raw:climate_zone_raw })
  if (!climate_zone) {
    return { hasMandatoryOrExcludedMeasureSet: false, hasTargetNotZeroed: false }
  }

  const tier = flexible_path_setup?.tiers?.find( (tier) => {
    const prototype_tier = Store.getters['globalEntities/Prototype/getterGlobalPrototype']({id: tier.prototype_id})
    return prototype_tier && +tier.climate_zone_id === +climate_zone.id  && +prototype_tier.type_prototype_id === +type_prototype_id
  })
  
  const target_score = tier?.target_scores?.find((target_score) => +target_score.type_vintage_id === +type_vintage.id)
  const hasTargetNotZeroed = Boolean(target_score && target_score.value != null && target_score.value > 0)
  
  const hasMandatoryOrExcludedMeasureSet = Boolean(
      (tier?.mandatory_measures && tier.mandatory_measures?.some((measure) => +measure.type_vintage_id === +type_vintage.id))
    || (tier?.excluded_measures && tier.excluded_measures?.some((measure) => +measure.type_vintage_id === +type_vintage.id))
  )
  
  return { hasMandatoryOrExcludedMeasureSet, hasTargetNotZeroed }
}

export const checkPolicyVintages = ({ custom_combinations, type_vintage, climate_zone_raw, flexible_path_setup, type_prototype_id }) => {
  let hasPrescriptiveConfigured = false
  let hasFlexibleConfigured = false

  if (custom_combinations?.length > 0) {
    const vintagesFromTypeVintage = Store.getters['globalEntities/Vintage/getterGlobalVintages']({ type_vintage_id : type_vintage.id }).map(v => v.id)
    const getTypeVintage = custom_combinations.findInArray({ climate_zone_raw, vintage_id: vintagesFromTypeVintage })
    hasPrescriptiveConfigured = getTypeVintage && getTypeVintage.measures.length > 0
  }

  if (flexible_path_setup && type_prototype_id) {
    const { hasMandatoryOrExcludedMeasureSet, hasTargetNotZeroed } = checkIfHasFlexiblePathSetUp({type_vintage, climate_zone_raw, flexible_path_setup, type_prototype_id})
    hasFlexibleConfigured = Boolean(hasTargetNotZeroed === true)
    if (hasMandatoryOrExcludedMeasureSet) {
      hasPrescriptiveConfigured = true
    }
  }

  return hasPrescriptiveConfigured || hasFlexibleConfigured
}

export const checkIfVintageHasTargetScoreSet = ({ type_vintage, climate_zone_raw, flexible_path_setup, type_prototype_id }) => {
  const { hasTargetNotZeroed } = checkIfHasFlexiblePathSetUp({type_vintage, climate_zone_raw, flexible_path_setup, type_prototype_id})
  return hasTargetNotZeroed
}

export const getIfPoliciesHasAnyMeasureConflict = async ({measures, setup, prototype_id, type_vintage_id, climate_zone}) => {
  let getMeasures

  getMeasures = measures.map(measure => {
    return Store.getters['globalEntities/Measure/getterGlobalMeasure']({id:measure.id}) || null
  })

  if(getMeasures.some(measure => !measure)) {
    const { measures: fetchedMeasures } = await MeasureApiService.getByIds(measures.map(measure => measure.id || measure))
    getMeasures = fetchedMeasures
  }

  const measureIsHiddenInFlexible = getMeasures?.some( measure => {
    return measure.hide_in_flexible_path
  })  

  const targetScoreByTypePrototypeIdAndClimateZone = setup?.tiers
  .find(tier => tier.prototype_id  == prototype_id && tier.climate_zone_id == climate_zone.id)?.target_scores

  if (!measureIsHiddenInFlexible) return false

  if (!targetScoreByTypePrototypeIdAndClimateZone) return false

  return targetScoreByTypePrototypeIdAndClimateZone.some(typeVintage => +typeVintage.type_vintage_id === +type_vintage_id && typeVintage.value != null && typeVintage.value > 0)
}