<template>
  <div class="flex items-center">
    <PsTabHeader
      :items="getBuildingTypes"
      :selected="getBuildingTypeSelected"
      :key-label="'title'"
      :key-value="'slug'"
      layout="underline"
      @change="selectItem"
    />
  </div>
</template>

<script>
import { ASSUMPTIONS_PATHS }from '@/modules/app/assumptions/shared/enums.js'
import { BUILDING_TYPES_DB_SLUGS }from '@/models/BuildingType.js'

export default {
  name: 'BuildingEstimateFilterBuildingType',
  computed: {
    getBuildingTypes() {
      return this.$store.getters['globalEntities/BuildingType/getterGlobalBuildingTypes']()
    },
    getBuildingTypeSelected() {
      return this.$store.getters['globalEntities/BuildingType/getterBuildingTypeSelected'](this.$route.fullPath)
    }
  },
  methods: {
    selectItem(item) {
      
      const getAssumptionsPathByBuildingTypeSelected = item.slug === BUILDING_TYPES_DB_SLUGS.RESIDENTIAL ?
      ASSUMPTIONS_PATHS.RESIDENTIAL_BUILDINGS :
      ASSUMPTIONS_PATHS.NON_RESIDENTIAL_BUILDINGS
      this.$store.commit('assumptions/updateDrawerPath', getAssumptionsPathByBuildingTypeSelected)
      this.setQueryValue('only_building_type', item.slug)
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .psui-el-tooltip {
    .psui-el-tooltip-wrapper {
      .psui-el-tooltip-dialog {
        transition-delay: 0.8s;
      }
    }
  }

  ::v-deep .psui-el-tab-header button.status-disabled {
    cursor: pointer;
  }
</style>
