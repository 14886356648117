<template>
  <div class="w-full flex flex-col">
    <AppLoader
      v-if="isLoading"
      text="Loading..."
    />
    <template v-else>
      <p v-if="policies.length">
        We've found <b>{{ policies.length }} user policies</b> using the prototype {{ prototype.title }}.
      </p>
      <p v-else>
        There's no user policy using the prototype {{ prototype.title }}.
      </p>
    </template>
  </div>
</template>
<script>

  export default {
    props: ['prototype'],
    data() {
      return {
        policies: [],
        isLoading: true
      }
    },
    mounted() {
      this.checkPrototypeHasPolicyOutdated()
    },
    methods: {
      checkPrototypeHasPolicyOutdated() {

        const query = `
          {
            policies (
              where: [
                { column: "exclude_in_report", value: "false" }
              ]
              whereHas: [
                { relationship: "custom_combinations", items: [{ column: "prototype_id", operation: "=", value: "${ this.prototype.id }" }] }
              ]
            ) {
              id
              title
            }
          }
        `

        this.$graphql({ query, caller: `checkPrototypeHasPolicyOutdated` })
          .then(({ data }) => {
            this.policies = data.policies
            this.isLoading = false
          })
        
      }
    }
  }
</script>