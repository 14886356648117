import { PolicyOptionBuilder } from "../index"
import { POLICY_OPTIONS_DB_SLUGS } from '@/util/Enums.js'
import { getDeepObjectTypesAndValues } from "@/util/checkDeepObjectChanges"
import { defaultNewBuildingsImpactAssumptionValues } from "@/models/ImpactAssumptions"
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import { FUEL_CHOICES } from '@/business-logic/services/impact-algorithms'
import { TYPE_FUELS_DB_SLUGS } from '@/util/Enums'
import CustomCombination from '@/models/CustomCombination'
import ImpactAssumptions from "@/models/ImpactAssumptions"
import Store from '@/store'
import StudyDataApiService from '@/services/api/StudyDataApiService'
import PolicyApiService from '@/services/api/PolicyApiService'
import {
  GeneralPerformanceByFuelPolicyImpactAlgorithm
} from "@/business-logic/services/impact-algorithms/policy/general-performance-by-fuel"

export class NewBuildingsPOBuilder extends PolicyOptionBuilder {
  static name = 'new-buildings-default-state'
  static policyOptionSlug = POLICY_OPTIONS_DB_SLUGS.DEFAULT_STATE
  static studyTypeSlug = STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS

  options = {}
  constructor(policyId, climateZonesByPrototypes, options) {
    super(policyId, climateZonesByPrototypes)
    this.options = options || {
      allElectric: {
        fuelChoice: FUEL_CHOICES.ALLOWED,
        measureSlug: ['all-electric-performance-minimum'],
        pvRequired: false,
        virtual: false,
      },
      mixedFuel: {
        fuelChoice: FUEL_CHOICES.ALLOWED,
        measureSlug: ['mixed-fuel-performance-minimum'],
        pvRequired: false,
        virtual: false,
      },
      assumptions: {
        all_electric_shares_with_policy: (assumptions) => assumptions?.all_electric_shares_without_policy ||
          defaultNewBuildingsImpactAssumptionValues.all_electric_shares_without_policy
      }
    }
    this.validateOptions()

    // Define some good constants
    const { id: allElectricTypeFuelId } = Store.getters['globalEntities/TypeFuel/getterGlobalTypeFuel']({ slug: TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC })
    const { id: mixedFuelTypeFuelId } = Store.getters['globalEntities/TypeFuel/getterGlobalTypeFuel']({ slug: TYPE_FUELS_DB_SLUGS.MIXED_FUEL })
    this.allElectricTypeFuelId = allElectricTypeFuelId
    this.mixedFuelTypeFuelId = mixedFuelTypeFuelId
  }

  validateOptions() {
    const checkKeysTypesAndValues = {
      'allElectric.fuelChoice': [null, 'string'],
      'allElectric.measureSlug': [null, 'array', 'function'],
      'allElectric.pvRequired': [null, 'boolean'],
      'allElectric.virtual': [null, undefined, 'boolean'],
      'mixedFuel.fuelChoice': [null, 'string'],
      'mixedFuel.measureSlug': [null, 'array', 'function'],
      'mixedFuel.pvRequired': [null, 'boolean'],
      'mixedFuel.virtual': [null, undefined, 'boolean'],
      'assumptions.all_electric_shares_with_policy': [null, 'function', 'number']
    }

    const objectKeyInfo = getDeepObjectTypesAndValues(this.options)
    for (const [key, rules] of Object.entries(checkKeysTypesAndValues)) {
      if (objectKeyInfo?.[key] === undefined) {
        objectKeyInfo[key] = ['undefined', undefined]
      }
      if (!objectKeyInfo?.[key] || !objectKeyInfo?.[key]?.some((x) => rules.includes(x))) {
        throw new Error(`New Buildings options for ${key} is not valid!`)
      }
    }

    if (this.options.allElectric.virtual === true && this.options.mixedFuel.virtual === true) {
      throw new Error(`You can't have both fuels set as virtual fuels`)
    }
  }

  async getPrototypeInfo(prototypeId) {
    const prototype = Store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: prototypeId })
    const typePrototype = Store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id: prototype.type_prototype_id })
    const studyType = Store.getters['globalEntities/Study/getterStudyStudyType']({ study_id: prototype.study_id })
    return {
      prototype,
      typePrototype,
      studyType
    }
  }

  async getFuelsByPrototype(prototype) {
    const allElectricFuel = Store.getters['globalEntities/Fuel/getterGlobalFuel']({ study_id : prototype.study_id, type_fuel_id: this.allElectricTypeFuelId })
    const mixedFuelFuel = Store.getters['globalEntities/Fuel/getterGlobalFuel']({ study_id : prototype.study_id, type_fuel_id: this.mixedFuelTypeFuelId })
    return {
      allElectricFuel,
      mixedFuelFuel
    }
  }

  async postInstall(args) {
    return await PolicyApiService.completeBatchStore({id: this.policyId }, args.flat(Infinity), undefined)
  }

  async install() {
    const promises = []
    for (const {prototype_id: prototypeId, climate_zones: climateZones} of this.climateZonesByPrototypes) {
      promises.push(this.installPrototype(prototypeId, climateZones))
    }
    return (await Promise.all(promises)).flat(Infinity)
  }

  async installPrototype(prototypeId, climateZonesRaw) {
    const policy = await this.getPolicy()
    const { prototype, typePrototype, studyType } = await this.getPrototypeInfo(prototypeId)
    const { allElectricFuel, mixedFuelFuel } = await this.getFuelsByPrototype(prototype)
    const newContainer = {
      study_type_id: studyType.id,
      type_prototype_id: typePrototype.id,
      type_prototype_order: typePrototype.order
    }
    if (!allElectricFuel || !mixedFuelFuel) {
      return
    }

    const customCombinations = []
    for (const climateZoneRaw of climateZonesRaw) {
      const commonCustomCombinationInfo = {
        prototype_id: prototype.id,
        jurisdiction_id : policy.jurisdiction_id,
        study_id: prototype.study_id,
        climate_zone_raw: climateZoneRaw
      }
      customCombinations.push(new CustomCombination({
        ...commonCustomCombinationInfo,
        fuel_id : allElectricFuel.id,
        meta: await this.generateAllElectricCCMeta(prototype, allElectricFuel.id, climateZoneRaw)
      }))
      customCombinations.push(new CustomCombination({
        ...commonCustomCombinationInfo,
        fuel_id : mixedFuelFuel.id,
        meta: await this.generateMixedFuelCCMeta(prototype, mixedFuelFuel.id, climateZoneRaw)
      }))
    }
    newContainer.custom_combinations = customCombinations
    return newContainer
  }

  async generateAllElectricCCMeta(prototype, fuelId, climateZoneRaw) {
    let virtualComplianceMargin = undefined
    if (this.options.allElectric.virtual === true) {
      const { mixedFuelFuel } = await this.getFuelsByPrototype(prototype)
      const mixedFuelMeta = await this.generateMixedFuelCCMeta(prototype, mixedFuelFuel.id, climateZoneRaw)
      if (mixedFuelMeta.compliance_margin_value && mixedFuelMeta.compliance_margin_measure_id) {
        virtualComplianceMargin = mixedFuelMeta.compliance_margin_value
      }
    }

    return {
      fuel_choice: this.options.allElectric.fuelChoice || undefined,
      ...(await this.getMetaMeasureInfoBySlug(prototype.study_id, prototype.id, fuelId, climateZoneRaw, this.options.allElectric.measureSlug)),
      generate_virtual_compliance_margin: virtualComplianceMargin,
      require_pv_system: this.options.allElectric.pvRequired === true,
      assumptions: await this.getAssumptionsMeta()
    }
  }

  async generateMixedFuelCCMeta(prototype, fuelId, climateZoneRaw) {
    let virtualComplianceMargin = undefined
    if (this.options.mixedFuel.virtual === true) {
      const { allElectricFuel } = await this.getFuelsByPrototype(prototype)
      const allElectricMeta = await this.generateAllElectricCCMeta(prototype, allElectricFuel.id, climateZoneRaw)
      if (allElectricMeta.compliance_margin_value && allElectricMeta.compliance_margin_measure_id) {
        virtualComplianceMargin = allElectricMeta.compliance_margin_value
      }
    }

    return {
      fuel_choice: this.options.mixedFuel.fuelChoice || undefined,
      ...(await this.getMetaMeasureInfoBySlug(prototype.study_id, prototype.id, fuelId, climateZoneRaw, this.options.mixedFuel.measureSlug)),
      generate_virtual_compliance_margin: virtualComplianceMargin,
      require_pv_system: this.options.mixedFuel.pvRequired === true,
      assumptions: await this.getAssumptionsMeta()
    }
  }

  async getMetaMeasureInfoBySlug(studyId, prototypeId, fuelId, climateZoneRaw, measuresSlugs) {
    if (!measuresSlugs?.length) {
      return {}
    }

    const studyData = await this.getMeasureStudyDataBySlug(studyId, prototypeId, fuelId, climateZoneRaw, measuresSlugs)
    if (!studyData) {
      return {}
    }

    const study = Store.getters['globalEntities/Study/getterGlobalStudy']({ id: studyId })
    const complianceMarginKey =  study.compliance_margin_key || 'compliance_margin'
    return {
      compliance_margin_measure_id: (studyData && studyData.study_data && studyData.study_data.measure_id) ? studyData.study_data.measure_id : null,
      compliance_margin_value: (studyData && studyData.study_data && studyData.study_data[complianceMarginKey]) ? studyData.study_data[complianceMarginKey] : null,
    }
  }

  async getMeasureStudyDataBySlug(studyId, prototypeId, fuelId, climateZoneRaw, measuresSlugs) {
    measuresSlugs = typeof measuresSlugs === 'function' ? await measuresSlugs(studyId, prototypeId, fuelId, climateZoneRaw) : measuresSlugs
    const measures = Store.getters['globalEntities/Measure/getterGlobalMeasures']({ slug: measuresSlugs, study_id: studyId }) || []
    const measureIds = Array.isArray(measures) ? measures.map(m => m?.id || null).filter(m => m !== null) : []
    return await StudyDataApiService.getForNewBuildingsByMeasureId(
      studyId, prototypeId, climateZoneRaw, fuelId, measureIds
    )
  }

  async getAssumptionsMeta() {
    const allElectricSharesWithPolicy = typeof this.options.assumptions.all_electric_shares_with_policy === 'function' ?
      await this.options.assumptions.all_electric_shares_with_policy(new ImpactAssumptions({
        ...(await this.getPolicy()),
      }, defaultNewBuildingsImpactAssumptionValues)) : this.options.assumptions.all_electric_shares_with_policy || undefined

    return {
      all_electric_shares_with_policy: allElectricSharesWithPolicy
    }
  }
}

export class NewBuildingsEfficiencyPOBuilder extends NewBuildingsPOBuilder {
  static name = 'new-buildings-efficiency'
  static policyOptionSlug = POLICY_OPTIONS_DB_SLUGS.EFFICIENCY
  constructor(policyId, climateZonesByPrototypes) {
    super(policyId, climateZonesByPrototypes, {
      allElectric: {
        fuelChoice: FUEL_CHOICES.ALLOWED,
        measureSlug: ['electrification-ee', 'ae-ee'],
        pvRequired: false
      },
      mixedFuel: {
        fuelChoice: FUEL_CHOICES.ALLOWED,
        measureSlug: ['mf-ee'],
        pvRequired: false
      },
      assumptions: {
        all_electric_shares_with_policy: (assumptions) => assumptions?.all_electric_shares_without_policy ||
          defaultNewBuildingsImpactAssumptionValues.all_electric_shares_without_policy
      }
    })
  }

  async disabledText() {
    //Both efficiency packages (package_slug = iaa, package_slug = haa) must be either TDV or on-bill cost-effective
    let okay = false
    for (const { prototype_id: prototypeId, climate_zones: climateZones } of this.climateZonesByPrototypes) {
      const { prototype } = await this.getPrototypeInfo(prototypeId)
      const { allElectricFuel, mixedFuelFuel } = await this.getFuelsByPrototype(prototype)
      for (const climateZoneRaw of climateZones) {
        const electricStudyData = await this.getMeasureStudyDataBySlug(prototype.study_id, prototypeId, allElectricFuel.id, climateZoneRaw, this.options.allElectric.measureSlug)
        const mixedStudyData = await this.getMeasureStudyDataBySlug(prototype.study_id, prototypeId, mixedFuelFuel.id, climateZoneRaw, this.options.mixedFuel.measureSlug)
        if (electricStudyData?.isCostEffective === true && mixedStudyData?.isCostEffective === true) {
          okay = true
        }
      }
    }

    if (!okay) {
      return "This option is not possible because it's requirements were not found to be cost-effective"
    }
    return null
  }
}

export class NewBuildingsElectricOnlyPOBuilder extends NewBuildingsPOBuilder {
  static name = 'new-buildings-electric-only'
  static policyOptionSlug = POLICY_OPTIONS_DB_SLUGS.ELECTRIC_ONLY
  constructor(policyId, climateZonesByPrototypes) {
    super(policyId, climateZonesByPrototypes, {
      allElectric: {
        fuelChoice: FUEL_CHOICES.REQUIRED,
        measureSlug: async (studyId, prototypeId, fuelId, climateZoneRaw) => {
          const electrificationBasicEEMeasureSlugs = ['electrification-basic-ee', 'ae-basicee']
          const electrificationBasicEEStudyData = await this.getMeasureStudyDataBySlug(studyId, prototypeId, fuelId, climateZoneRaw, electrificationBasicEEMeasureSlugs)
          if (electrificationBasicEEStudyData?.isCostEffective === true) {
            return electrificationBasicEEMeasureSlugs
          }
          return ['all-electric-performance-minimum']
        },
        pvRequired: false
      },
      mixedFuel: {
        fuelChoice: FUEL_CHOICES.NOT_ALLOWED,
        measureSlug: null,
        pvRequired: false
      },
      assumptions: {
        all_electric_shares_with_policy: 100
      }
    })
  }
}

export class NewBuildingsElectricOnlyPlusEfficiencyPOBuilder extends NewBuildingsPOBuilder {
  static name = 'new-buildings-electric-only-plus-efficiency'
  static policyOptionSlug = POLICY_OPTIONS_DB_SLUGS.ELECTRIC_ONLY_PLUS_EFFICIENCY
  constructor(policyId, climateZonesByPrototypes) {
    super(policyId, climateZonesByPrototypes, {
      allElectric: {
        fuelChoice: FUEL_CHOICES.REQUIRED,
        measureSlug: ['electrification-ee', 'ae-ee'],
        pvRequired: false
      },
      mixedFuel: {
        fuelChoice: FUEL_CHOICES.NOT_ALLOWED,
        measureSlug: null,
        pvRequired: false
      },
      assumptions: {
        all_electric_shares_with_policy: 100
      }
    })
  }

  async disabledText() {
    //Electrification + EE' (package_slug: haa) must be TDV or on-bill cost-effective
    let okay = false
    for (const { prototype_id: prototypeId, climate_zones: climateZones } of this.climateZonesByPrototypes) {
      const { prototype } = await this.getPrototypeInfo(prototypeId)
      const { allElectricFuel } = await this.getFuelsByPrototype(prototype)
      for (const climateZoneRaw of climateZones) {
        const studyData = await this.getMeasureStudyDataBySlug(prototype.study_id, prototypeId, allElectricFuel.id, climateZoneRaw, this.options.allElectric.measureSlug)
        if (studyData?.isCostEffective === true) {
          okay = true
        }
      }
    }

    if (!okay) {
      return "This option is not possible because it's requirements were not found to be cost-effective"
    }
    return null
  }
}

export class NewBuildingsMaxElectricPreferencePOBuilder extends NewBuildingsPOBuilder {
  static name = 'new-buildings-max-electric-preference'
  static policyOptionSlug = POLICY_OPTIONS_DB_SLUGS.MAX_ELECTRIC_PREFERENCE
  constructor(policyId, climateZonesByPrototypes) {
    super(policyId, climateZonesByPrototypes, {
      allElectric: {
        fuelChoice: FUEL_CHOICES.ALLOWED,
        measureSlug: async (studyId, prototypeId, fuelId, climateZoneRaw) => {
          const electrificationBasicEEMeasureSlugs = ['electrification-basic-ee', 'ae-basicee']
          const electrificationBasicEEStudyData = await this.getMeasureStudyDataBySlug(studyId, prototypeId, fuelId, climateZoneRaw, electrificationBasicEEMeasureSlugs)
          if (electrificationBasicEEStudyData?.isCostEffective === true) {
            return electrificationBasicEEMeasureSlugs
          }
          return ['all-electric-performance-minimum']
        },
        pvRequired: false
      },
      mixedFuel: {
        fuelChoice: FUEL_CHOICES.ALLOWED,
        measureSlug: async (studyId, prototypeId, fuelId, climateZoneRaw) => {
          const study = Store.getters['globalEntities/Study/getterGlobalStudy']({ id: studyId })
          const complianceMarginKey =  study.compliance_margin_key || 'compliance_margin'
          const includePvOnFilter = !GeneralPerformanceByFuelPolicyImpactAlgorithm.pvEnabledComplianceMarginKeys.includes(complianceMarginKey)
          const notPreemptiveMeasureIds = (Store.getters['globalEntities/Measure/getterGlobalMeasures']({ is_pre_emptive: [false, null, undefined], has_larger_pv_included: [false, null, undefined, includePvOnFilter], study_id: studyId }) || []).map((m) => m.id)

          // ToDo: Maybe remove study_data?.measure?.hide_in_compliance_margin_control === true here??
          const studyData = ((await StudyDataApiService.getForNewBuildingsByMeasureId(
            studyId, prototypeId, climateZoneRaw, fuelId, notPreemptiveMeasureIds, false
          ))?.filter((studyData) => studyData?.isCostEffective === true && studyData?.measure?.hide_in_compliance_margin_control !== true)?.sort((a, b) => (b?.study_data?.[complianceMarginKey] || 0) - (a?.study_data?.[complianceMarginKey] || 0)) || [])?.shift()

          if (studyData?.measure?.slug) {
            return [studyData?.measure?.slug]
          }
          return null
        },
        pvRequired: false
      },
      assumptions: {
        all_electric_shares_with_policy: (assumptions) => {
          return Number(assumptions?.all_electric_shares_without_policy ||
            defaultNewBuildingsImpactAssumptionValues.all_electric_shares_without_policy) + 25
        }
      }
    })
  }

  async disabledText() {
    //At least one mixed-fuel package must be cost-effective and not pre_emptive (pre_emptive = false)
    let success = 0
    for (const { prototype_id: prototypeId, climate_zones: climateZones } of this.climateZonesByPrototypes) {
      const { prototype } = await this.getPrototypeInfo(prototypeId)
      const { mixedFuelFuel } = await this.getFuelsByPrototype(prototype)
      for (const climateZoneRaw of climateZones) {
        if (this.options.mixedFuel.measureSlug(prototype.study_id, prototypeId, mixedFuelFuel.id, climateZoneRaw)) {
          success++
        }
      }
    }

    if (!success) {
      return "This option is not possible because it's requirements were not found to be cost-effective"
    }
    return null
  }
}


export class NewBuildingsHighPerformancePOBuilder extends NewBuildingsPOBuilder {
  static name = 'new-buildings-high-performance'
  static policyOptionSlug = POLICY_OPTIONS_DB_SLUGS.HIGH_PERFORMANCE
  constructor(policyId, climateZonesByPrototypes) {
    super(policyId, climateZonesByPrototypes, {
      allElectric: {
        fuelChoice: FUEL_CHOICES.ALLOWED,
        measureSlug: [],
        pvRequired: false,
        virtual: true,
      },
      mixedFuel: {
        fuelChoice: FUEL_CHOICES.ALLOWED,
        measureSlug: ['mf-ee-bat-pv'],
        pvRequired: false,
      },
      assumptions: {
        all_electric_shares_with_policy: (assumptions) => assumptions?.all_electric_shares_without_policy ||
            defaultNewBuildingsImpactAssumptionValues.all_electric_shares_without_policy
      }
    })
  }

  async disabledText() {
    // we should have mf-ee-bat-pv package
    let success = 0
    let hasNoCostEffectiveItem = false
    for (const { prototype_id: prototypeId, climate_zones: climateZones } of this.climateZonesByPrototypes) {
      const { prototype } = await this.getPrototypeInfo(prototypeId)
      const { mixedFuelFuel } = await this.getFuelsByPrototype(prototype)
      for (const climateZoneRaw of climateZones) {
        const mixedStudyData = await this.getMeasureStudyDataBySlug(prototype.study_id, prototypeId, mixedFuelFuel.id, climateZoneRaw, this.options.mixedFuel.measureSlug)
        if (mixedStudyData && mixedStudyData.isCostEffective === true) {
          success++
        } else if (mixedStudyData && !mixedStudyData.isCostEffective) {
          hasNoCostEffectiveItem = true
        }
      }
    }

    if (!success) {
      return hasNoCostEffectiveItem ?
          `This policy option is currently unavailable as some of the necessary policy requirements are not cost-effective` :
          `This policy option is currently unavailable because there's no High Performance package available for this jurisdiction or climate zone`
    }
    return null
  }

}
