import policyColumns from './policyColumns'

export const initialState = () => ({
  policyColumns, 
  userPolicies: [],
  userSharedPolicies: [],
  lastPolicyOrder : 0,
  hiddenItems: undefined  
})

export default {...initialState()}