<template>
  <div class="py-6 pl-2">
    <div
      v-if="!isLoading"
      class="flex"
    >
      <div class="flex-none">
        <AppTabHeader
          :items="tabs"
          :selected="tabSelected"
          class="mb-8 mr-8 flex-col text-header-3 text-ash-700"
          notification-class="absolute top-1/2 transform -translate-y-1/2 left-0 rounded-full text-header-1 bg-red flex items-center justify-center w-4 h-4 text-white -ml-3 -mt-2"
          @selectTab="tabSelected = $event"
        />
      </div>

      <div class="flex-auto">
        <template v-if="tabSelected">
          <AdminStudyForm v-show="tabSelected.value === 'study_info'" />
          <AdminStudyGroupStudyFlexiblePaths v-show="tabSelected.value === 'flexiblepaths'" />
          
          <div
            v-for="collectionTab in getCollectionTabs"
            :key="collectionTab.value"
            class="w-full flex flex-col"
          >
            <template v-if="tabSelected.value == collectionTab.value">              
              <ul
                v-if="tabSelected.errors.length"
                class="psui-text-small list-inside w-full bg-red rounded p-4 px-6 flex flex-col text-white mb-4 max-h-1/4 overflow-y-auto"
              >
                <li
                  v-for="error in tabSelected.errors"
                  :key="error"
                  class="my-1"
                >
                  {{ error }}
                </li>
              </ul>
              <AdminFuelSwitchPackage 
                v-if="collectionTab.value == 'fuel_switch_packages'"
                :title="collectionTab.label" 
                :prototypes="study[collectionTab.collection]" 
                :measures="study.measures" 
                :show-study-column="false"
                @changeErrorMessages="reInitTabErrors"
              />
              <AdminStudyItemTable
                v-else
                :type="collectionTab.value.slice(0, -1)"
                :title="collectionTab.label" 
                :items="study[collectionTab.collection]" 
                :show-study-column="false" 
                @getItems="getStudy"
              />
            </template>
          </div>
        </template>
      </div>
    </div>
    <div v-else>
      <h3 class="text-header-4 font-bold">
        Loading Study...
      </h3>
    </div>
  </div>
</template>

<script>
import AdminFuelSwitchPackage from '../study_item/AdminFuelSwitchPackage.vue'
import AdminStudyGroupStudyFlexiblePaths from './AdminStudyGroupStudyFlexiblePaths.vue'
import AdminStudyForm from '../study/AdminStudyForm.vue'
import AdminStudyItemTable from '@/modules/admin/study_item/AdminStudyItemTable'
import Study from '@/models/Study'
import Prototype from '@/models/Prototype'
import Measure from '@/models/Measure'
import { find } from '@/util/Functions'

export default {
  name: 'AdminStudyGroupStudy',
  components: { AdminStudyItemTable, AdminStudyForm, AdminStudyGroupStudyFlexiblePaths, AdminFuelSwitchPackage },
  data() {
    return {
      study: new Study(),
      tabs: [
        { label: 'Study Info', value: 'study_info', errors: [] },
        { label: 'Prototypes', value: 'prototypes', errors: [], collection: 'prototypes' },
        { label: 'Vintages', value: 'vintages', errors: [], collection: 'vintages' },
        { label: 'Measures', value: 'measures', errors: [], collection: 'measures' },
        { label: 'Fuels', value: 'fuels', errors: [], collection: 'fuels' },
        { label: 'Flexible Paths', value: 'flexiblepaths', errors: [] },
        { label: 'Fuel Switch Package', value: 'fuel_switch_packages', errors: [], collection: 'prototypes' },
      ],
      tabSelected: false,
      isLoading : true
    }
  },
  computed: {
    getCollectionTabs() {
      return this.tabs.filter((item) => item.value)
    }
  },
  mounted() {
    if(this.$route.params.study_id) {
      this.getStudy()
    }
    if (!this.tabSelected) this.tabSelected = this.tabs[0]
  },
  methods: {
    
    async getStudy() {
      
      this.isLoading = true

      const query = `
        {
          study (
            where: [
              { column: "id", operation: "=", value: "${this.$route.params.study_id}"}
            ]
          ){
            id
            title
            content
            source_pdf
            source_pdf_update
            version
            version_updated_at
            version_updated_changes
            version_updated_expected_at
            spreadsheet_id
            worksheet_name
            impact_period
            created_at
            is_private
            prototypes(orderBy: [{column: "title", order: "asc"}]) { 
              id 
              title 
              slug 
              description 
              content 
              title_abbreviated 
              icon
              fuel_switch_package_measure_id
              type_prototype_id 
              type_prototype { 
                id 
                title 
              } 
            }
            vintages(orderBy: [{column: "title", order: "asc"}]) { id title slug description content title_long type_vintage_id type_vintage { id title } }
            measures(orderBy: [{column: "order", order: "asc"}]) { id title slug description content include_in_combination is_package exclude_in_combination_reason type_measure_id order hide_in_flexible_path type_measures { id title } }
            fuels(orderBy: [{column: "title", order: "asc"}]) { id title slug description content type_fuel_id type_fuel { id title } }
          }
        }
      `
      const { data } = await this.$graphql({ query })

      data.study.prototypes = data.study.prototypes.map(p => new Prototype(p))
      data.study.measures = data.study.measures.map(p => new Measure(p))
      this.study = {...data.study}
      
      this.checkStudyErrors()

      this.isLoading = false
    },

    onTabSelect(tabSelectedKey) {
      const itemTab = find(this.tabs, { value: tabSelectedKey })
      if (itemTab) this.tabSelected = itemTab
    },
    update() {
      this.$api.put(`/studies/${this.study.id}`, this.study, { loader: true })
        .then(() => {
          this.$toast.success('Study updated')
          this.$router.push({ name: 'AdminStudyList' })
        })
    },

    updateStudyData() {
      const formData = new FormData()
      formData.append('study_csv', this.studyDatumFile)

      this.$api.post(`/studies/${this.study.id}/study-data`, formData, {
        loader: true,
        headers: { 'Content-Type': 'multipart/form-data;' }
      }).then(() => {
        this.$toast.success('Study data updated')
        this.studyDatumFile = new File(["foo"], "", { type: "text/plain" })
      })
      .catch(() => {
        this.$toast.error('Error on update study data file')
      })
    },
    
    checkStudyErrors() {

      // Check prototypes
      this.study.prototypes.forEach((prototype) => {
        const itemTab = find(this.tabs, { value: 'prototypes' })
        const attrs = ['title', 'content', 'description', 'slug', 'title_abbreviated', 'icon', 'type_prototype_id']
        attrs.map((attr) => {
          if(!prototype[attr]) {
            itemTab.errors.push(`${prototype.title} do not have ${attr}`)
          }
        })
      })

      // Check vintages
      this.study.vintages.forEach((vintage) => {
        const itemTab = find(this.tabs, { value: 'vintages' })
        const attrs = ['title', 'content', 'description', 'slug', 'title_long', 'type_vintage_id']
        attrs.map((attr) => {
          if(!vintage[attr]) {
            itemTab.errors.push(`${vintage.title} do not have ${attr}`)
          }
        })
      })

      // Check Measures
      this.study.measures.forEach((measure) => {
        const itemTab = find(this.tabs, { value: 'measures' })
        const attrs = ['title', 'content', 'description', 'slug', 'type_measure_id']
        attrs.map((attr) => {
          if(!measure[attr]) {
            itemTab.errors.push(`${measure.title} do not have ${attr}`)
          }
        })
        if(!measure.include_in_combination && !measure.exclude_in_combination_reason) {
          itemTab.errors.push(`${measure.title} cannot be combined and should have had reason properly filled`)
        }
      })

      // Check fuels
      this.study.fuels.forEach((fuel) => {
        const itemTab = find(this.tabs, { value: 'fuels' })
        const attrs = ['title', 'content', 'description', 'slug', 'type_fuel_id']
        attrs.map((attr) => {
          if(!fuel[attr]) {
            itemTab.errors.push(`${fuel.title} do not have ${attr}`)
          }
        })
      })

      // Check Fuel Switch Package
      this.study.prototypes.forEach((prototype) => {
        const itemTab = find(this.tabs, { value: 'fuel_switch_packages' })
        const attrs = ['fuel_switch_package_measure_id']
        attrs.map((attr) => {
          if(!prototype[attr]) {
            itemTab.errors.push(`${prototype.title} do not have ${attr}`)
          }
        })
      })
    },

    reInitTabErrors(){
      this.tabs = this.tabs.map(tab => {
        tab.errors = []
        return tab
      })
      this.checkStudyErrors()
    },

  }
}
</script>