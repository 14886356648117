<template>
  <PsDropdown>
    <template #dropdownTrigger>
      <PsIcon 
        :data-test-id="`comparisons-list-table-user-actions-${getComparisonId}`"
        icon="more_horiz"
        class="psui-text-gray-50 psui-cursor-pointer leading-none"
      />
    </template>
    <template #items>
      <ul class="psui-w-full psui-font-bold psui-my-3">        
        <li
          v-if="!comparisonIsShared"
          :data-test-id="`edit-name-and-description-button-${getComparisonId}`"
          title="Edit Name and Description"
          class="psui-text-gray-60 hover:psui-bg-blue-10 hover:psui-text-blue-60 psui-font-bold psui-cursor-pointer psui-flex psui-items-center psui-gap-3 psui-px-5 psui-py-1 transition-all"
          @click="$eventBus.$emit('openModalComparisonEditDetails', comparison.id, false )"
        >
          <PsIcon 
            icon="edit" 
            size="22"
          />
          <span class="psui-w-auto psui-text-small">Edit Name and Description</span>
        </li>
        <li
          title="Duplicate"
          data-test-id="duplicate-comparison-button"
          class="psui-text-gray-60 hover:psui-bg-blue-10 hover:psui-text-blue-60 psui-font-bold psui-cursor-pointer psui-flex psui-items-center psui-gap-3 psui-px-5 psui-py-1 transition-all"
          @click="$eventBus.$emit('openDuplicateComparisonModal', { comparison: comparison })"
        >
          <PsIcon 
            icon="library_books" 
            size="22"
          />
          <span class="psui-w-auto psui-text-small">Duplicate</span>
        </li>
        <li
          title="Share"
          class="psui-text-gray-60 hover:psui-bg-blue-10 hover:psui-text-blue-60 psui-font-bold psui-cursor-pointer psui-flex psui-items-center psui-gap-3 psui-px-5 psui-py-1 transition-all"
          @click="shareComparison(comparison)"
        >
          <PsIcon 
            icon="share" 
            size="22"
          />
          <span class="psui-w-auto psui-text-small">Share</span>
        </li>
        <li
          :data-test-id="`delete-comparison-button-${getComparisonId}`"
          title="Delete"
          class="psui-text-gray-60 hover:psui-bg-blue-10 hover:psui-text-blue-60 psui-font-bold psui-cursor-pointer psui-flex psui-items-center psui-gap-3 psui-px-5 psui-py-1 transition-all"
          @click="$eventBus.$emit('openModalDeleteComparison', { 
            comparison: comparison, 
            discardTypeLabel: getComparisonDiscardTypeLabel  
          })"
        >
          <PsIcon 
            icon="delete" 
            size="22"
          />
          <span class="psui-w-auto psui-text-small">
            {{ getComparisonDiscardTypeLabel | replaceAllHyphenAndAddUppercaseToFirstLetter }}
          </span>
        </li>
      </ul> 
    </template>
  </PsDropdown>
</template>

<script>
import { COMPARISON_TYPES } from '@/util/Enums.js'

export default {
  name: 'ComparisonListTableUserActions',
  props: ['comparison'],
  computed: {
    comparisonIsOwn() {
      return this.$store.getters['comparison/getterComparisonType'](this.comparison.id) === COMPARISON_TYPES.OWN.value
    },
    comparisonIsShared() {
      return this.$store.getters['comparison/getterComparisonType'](this.comparison.id) === COMPARISON_TYPES.SHARED.value
    },
    getComparisonId(){
      return this.comparison?.id
    },
    getComparisonDiscardTypeLabel(){
      return this.comparisonIsShared ? 'remove' : 'delete'
    }
  },
  methods: {
    shareComparison(comparison) {
      this.copyToClipboard(window.location.origin + '/comparisons/' + comparison.id)
      this.$toast.success('Comparison url copied to clipboard!')
    },
  }
}
</script>

<style>

</style>