import { STUDY_TYPES_DB_SLUGS } from '@/store/global-entities/StudyType'
import { BUILDING_TYPES_DB_SLUGS }from '@/store/global-entities/BuildingType.js'

import BasePdfService from '@/services/pdf/BasePdfService'
import html2canvas from "html2canvas"
import { POLICY_TYPE_PDF } from '@/util/Enums.js'

export default class BuildingEstimatesGeneratePdfService extends BasePdfService {

  fontSizeDefault = 10
  studyTypes = []
  type_prototypes = []
  prototypes = []
  elemsToImage = []
  html2canvasOptions = {}
  _pageImages = []
  building_type = false
  updateStateCallback = null

  type = POLICY_TYPE_PDF.BUILDING_ESTIMATES  

  constructor(elemsToImage, updateStateCallback, args, building_type) {
    super(args)
    this.elemsToImage = elemsToImage
    this.updateStateCallback = updateStateCallback
    if (args.html2canvasOptions) {
      this.html2canvasOptions = args.html2canvasOptions
    }
    if(building_type){
      this.building_type = building_type
    }
    return this.initPdf()
  }
  
  async initPdf() {
    try {
      this._pageImages = await this.getPageImageElements()
      if (!this._pageImages || !Array.isArray(this._pageImages) || this._pageImages.length <= 0) {
        throw new Error('No page images informed')
      }
      await this.addImagesAsPages({images: this._pageImages})
      await this.export()
    } catch (error) {
      console.error(error)
      throw new Error(error)
    }
  }

  async getPageImageElements() {
    if (this.elemsToImage.length <= 0) {
      throw new Error('no-elements-to-export')
    }

    const placeholderClones = []
    this.elemsToImage.forEach(el => {
      const clone = el.cloneNode(true)
      el.parentElement.insertBefore(clone, el)
      placeholderClones.push(clone)
    })

    const steps = this.building_type.slug === BUILDING_TYPES_DB_SLUGS.RESIDENTIAL ?

    [STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS, STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS] :
    [STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS]

    let results = []
    let idx = 1
    for await (const step of steps) {
      results = [...results, ...(await this.getStepImages(step, idx))]
      idx++
    }

    if (this.updateStateCallback) {
      this.updateStateCallback(false)
    }
    placeholderClones.forEach(clone => {
      clone.remove()
    })
    this.elemsToImage.forEach((el) => {
      const customWidth = el.getAttribute('attr-export-width')
      if (customWidth) {
        el.style.width = null
      }
      el.classList.remove('exporting-to-pdf')
    })
    return results
  }

  getStepImages(step,idx) {
    return new Promise((resolve, reject) => {
      if (this.updateStateCallback) {
        this.updateStateCallback(true, step)
      }

      const fixElemTemplates = () => {
        return new Promise((resolve) => {
          let times = 40
          const fixFunc = () => {
            this.elemsToImage.forEach(el => {
              el.classList.add('exporting-to-pdf')
              const customWidth = el.getAttribute('attr-export-width')
              if (customWidth) {
                el.style.width = customWidth
              }
            })
          }
          fixFunc()
          const interval = setInterval(async () => {
            fixFunc()
            times--
            if (times <= 0) {
              clearInterval(interval)
              resolve()
            }
          }, 50)
        })
      }

      fixElemTemplates().then(() => {
        setTimeout(() => {
          const promises = []
          this.elemsToImage.forEach((el) => {
            const exportSteps = el.getAttribute('attr-export-steps')?.split(',')?.map((i) => Number(i)) || null
            if (!exportSteps || exportSteps?.includes(idx)) {
              const options = { ...this.html2canvasOptions }
              const image = html2canvas(el, options).then(canvas => canvas.toDataURL())
              promises.push(image)
            }
          })
          return Promise.all(promises).then((result) => {
            resolve(result)
          }).catch(reject)
        }, 3000)
      })
    })
  }

}
