<template>
  <div class="psui-w-full psui-flex psui-flex-col psui-p-6">
    <h1 class="text-header-6 text-deepsky-900 font-bold mr-2 flex-shrink-0 pl-4 border-l-6 flex-none psui-border-blue-60">
      Edit Jurisdiction 
    </h1>
    <div v-if="this.jurisdiction">
      <InputWithLabel
        v-model="jurisdiction.title"
        class="mt-6"
        :label="'Title'"
      />

      <InputWithLabel
        v-model="jurisdiction.city"
        class="mt-6"
        :label="'City'"
      />

      <InputWithLabel
        v-model="jurisdiction.county"
        class="mt-6"
        :label="'County'"
      />

      <div
        v-if="getJurisdictionIsCollection"
        class="flex flex-col justify-between mt-6 mb-2"
      >
        <div class="flex space-x-2">
          <PsButton
            label="Add Collection"
            size="small"
            @click="addCollection"
          />
        </div>
        <div class="mt-2">
          <VueSelect 
            v-model="jurisdiction_selected"
            class="vue-select"
            placeholder="Select Jurisdiction"
            :class="jurisdictions.length ? 'filled' : ''"
            label="title"
            :options="jurisdictions"
          />
          <VueSelect 
            v-model="climate_zones_selected"
            class="vue-select mt-2"
            placeholder="Select climate zones"
            :class="jurisdictions.length ? 'filled' : ''"
            label="raw"
            :options="getClimateZones"
            :multiple="true"
          />
          <InputWithLabel
            v-model="percentage"
            label="Percentage"
            class="mt-6"
          />
        </div>
      </div>
      <hr class="border-b border-gray05 my-6">
      <div>
        <PsButton
          label="Update"
          icon="update"
          size="big"
          @click="update()"
        />
        <div>
          <AdminJurisdictionTable
            v-if="jurisdiction.is_collection" 
            :collections="getCollections"
          />
        </div>
      </div>
    </div>
    <AdminJurisdictionEditSkeleton v-else />
  </div>
</template>

<script>
import { JURISDICTION_TYPES } from '@/util/Enums.js'
import Collection from '@/models/Collection.js'
import AdminJurisdictionEditSkeleton from '@/modules/admin/jurisdiction/AdminJurisdictionEditSkeleton.vue'
import AdminJurisdictionTable from '@/modules/admin/jurisdiction/AdminJurisdictionTable.vue'
import JurisdictionCollectionApiService from '@/services/api/JurisdictionCollectionApiService.js'

export default {
  name: 'AdminJurisdictionForm',
  components:{
    AdminJurisdictionEditSkeleton,
    AdminJurisdictionTable
  },
  data:() => ({
    jurisdiction_selected:null,
    jurisdiction: null,
    percentage: null,
    jurisdictions: [],
    climate_zones_selected:[],
    climate_zones: [],
    JURISDICTION_TYPES
  }),
  computed:{
    getJurisdictionById(){
      return this.jurisdictions.find(jurisdiction => jurisdiction.id == this.$route.params.jurisdiction_id)
    },
    getJurisdictionIsCollection(){
      return this.jurisdiction.is_collection ?? false
    },
    getClimateZones(){
      return this.jurisdiction_selected?.climate_zones
    },
    getCollections(){
      return Array.from(new Set(this.jurisdiction?.collections.map(jur => jur.jurisdiction_id))) 
      .map(jurisdiction_id => {
        const getJurisdictionTitle = this.jurisdictions.find(jur => jur.id == jurisdiction_id).title
        
        const getClimateZonesByJurisdiction = this.jurisdiction.collections.reduce((acc, curr)=>{
          if(curr.jurisdiction_id == jurisdiction_id){
            acc.push(curr.climate_zone_prefix)
          }
          return acc
        },[])

        return {
          jurisdiction_id,
          jurisdiction_title: getJurisdictionTitle,
          climate_zones_prefix: getClimateZonesByJurisdiction
        }
      })
    }
  },
  watch:{
    jurisdiction_selected(){
      this.climate_zones_selected = []
    }
  },
  mounted() {
    this.getJurisdictions()
  },
  methods: {
    addCollection(){
      if(!this.jurisdiction_selected || !this.climate_zones_selected || !this.percentage) {
        return this.$appToast({ 
          type: 'info', 
          message: "To add collections, please select a Jurisdiction." 
        })
      }

      this.climate_zones_selected.forEach( cz => {
        const collection = new Collection({
          jurisdiction_collection_id: this.jurisdiction.id,
          jurisdiction_id: this.jurisdiction_selected.id.toString(),
          climate_zone_prefix: cz.prefix,
          percentage: this.percentage
        })
        
        this.jurisdiction.collections.push(collection) 
      }) 
      console.log(this.jurisdiction.collections)

      JurisdictionCollectionApiService.update_or_create(
        {
          jurisdiction_collections: this.jurisdiction.collections
        })
      .then((data)=>console.log(data)) 


    },
    async getJurisdictions() {
      const query = `
        {
          jurisdictions {
            id
            title
            city
            county
            is_collection
            collections {
              jurisdiction_collection_id
              id
              jurisdiction_id
              climate_zone_prefix
              percentage
            }
            climate_zones {
              id
              raw
              prefix
            }
          } 
        }
      `
      const { data } = await this.$graphql({ query })
      
      this.jurisdictions = data.jurisdictions
      this.jurisdiction = data.jurisdictions.find(jurisdiction => jurisdiction.id == this.$route.params.jurisdiction_id)
    },

    update() {
      this.$api.put(`/jurisdiction/${this.jurisdiction.id}`, this.jurisdiction, { loader: true })
        .then(() => {
          this.$toast.success('Jurisdiction successfully updated')
          this.$router.push({ name: 'AdminJurisdictionList' })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .vue-select {
    height: auto !important;
  }

  label {
    width: fit-content;
  }
</style>
