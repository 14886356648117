<template>
  <div class="psui-flex psui-items-center">
    <div
      ref="tooltip"
      :class="{'active': tooltipShow}"
      class="tooltip vue-tooltip-theme"
      :style="{'transform': tooltipTransformStyleProp}"
    >
      <span class="tooltip-inner">Latest published version</span>
    </div>
    <div class="psui-text-small psui-font-bold psui-text-gray-80 whitespace-nowrap psui-mr-2">
      Filter by
    </div>
    <Dropdown
      ref="dropdown"
      :min-width-dropdown="400"
      :dropdown-dialog-classes="'min-w-300 bg-white p-6'"
      button-classes="psui-border-0 p-0"
      title="Source Study"
    >
      <template #buttonLabel>
        <PsChips
          label="Source Study"
          type="button"
          :checked="!getExcludeStudyIds.length ? false : true"
        />
      </template>
      <template #items>
        <div @click.stop>
          <AppAlert            
            v-if="showHelper"
            type="lime" 
            description="Use filters to select <br />different study versions"
            icon="info"
            action="OK"
            class="mb-4 text-lime-600 cursor-pointer"
            @click="showHelper = false"
          />
        </div>
        <div class="w-full flex flex-col font-medium text-gray02">
          <div
            v-for="studyType in getFilteredStudyTypes"
            :key="studyType.id"
            class="mb-2 last:mb-0"
          >           
            <div
              v-for="studyGroup in studyType.study_groups"
              :key="`dropdown-study-group-${studyGroup.id}`"
              class="pl-0"
            >
              <h3
                v-if="studyGroup.studies.length"
                class="text-header-2 font-bold text-gray02 mb-1"
              >
                {{ studyGroup.forecast_filters_source_study_title }}
              </h3>
              <div
                v-for="(study, study_index) in studyGroup.studies"
                :key="`dropdown-study-group-${studyGroup.id}-study-${study.id}`"
                class="pl-3"
              >
                <Checkbox
                  class="w-full"
                  :label="`${study.getTitle} ${study.getPdfTag}${studyPrototypes(studyGroup, study)}`"
                  :value="study.published_at && !checkIfEntityShouldBeVisibleInStudyResults('check_study_in_array', {study, study_index})"
                  label-classes="ts--accent--2 text-gray04 d-block-label"
                  :disabled="!study.published_at"
                  @change="toggleStudy(study.id)"
                >
                  <!--<AppDotNotification
                    v-if="studyGroup.lastPublishedVersion() && studyGroup.lastPublishedVersion().id == study.id || getLatestStudyIdsByAllPrototypes().includes(study.id)"
                    tooltip="Latest published version"
                    class="-mt-1"
                  />-->
                </Checkbox>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
// import AppDotNotification from '@/components/general/AppDotNotification.vue'
import StudyGroupApiService from '@/services/api/StudyGroupApiService'
import StudyGroup from '@/models/StudyGroup'
import Study from '@/models/Study'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import dayjs from 'dayjs'

export default {
  name: 'FilterStudy',
  // components: { AppDotNotification },
  data() {
    return {
      showHelper : false,
      study_types: [],
      interval: null,
      tooltipShow: false,
      tooltipTransformStyleProp: '',
    }
  },
  computed: {
    isFiltering() {
      return this.$route.fullPath.includes('exclude_study_ids')
    },
    getExcludeStudyIds() {
      return this.study_types.reduce((acc, study_type) => {
        study_type.study_groups.map((study_group) => {
          study_group.studies.map((study, index) => {
            if(this.checkIfEntityShouldBeVisibleInStudyResults('check_study_in_array', {study, study_index:index})) {
              acc.push(study.id)
            }
          })
        })
        return acc
      }, [])
    },
    getFilteredStudyTypes(){
      return this.study_types
      .filter(studyType => studyType.slug === this.$route.query.only_study_type)
      .map(studyType => {
        if(studyType.slug == STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS) {
          return studyType  
        } else {
          return {...studyType, study_groups: studyType.study_groups.map(study_group =>  {
            study_group.studies = study_group.studies.filter(study => dayjs(study.released_at).get('year') >= 2022)
            return new StudyGroup(study_group)
          })}
        }
    })
    }
  },
  mounted() {
    StudyGroupApiService.byStudyType()
      .then(study_types => { 
        this.study_types = study_types.map(study_type => {
          study_type.study_groups = study_type.study_groups.map(study_group => {            
            study_group.studies = study_group.studies.map(study => new Study(study))
            study_group.studies = study_group.studies.filter(study => study.is_upcoming ? false : true)
            return new StudyGroup(study_group)
          })
          return study_type
        }) 
      })

    this.$eventBus.$on('openForecastStudyFilter', () => {
      setTimeout(() => { this.$refs.dropdown.open() }, 150)
      this.showHelper = true
    })

    this.interval = setInterval(() => {
      try {
        document.querySelectorAll(".latest-tooltip").forEach((elem) => {
          elem.removeEventListener("mouseover", this.latestTooltipMouseoverFnc)
          elem.removeEventListener("mouseout", this.latestTooltipMouseoutFnc)
          elem.addEventListener('mouseover', this.latestTooltipMouseoverFnc)
          elem.addEventListener('mouseout', this.latestTooltipMouseoutFnc)
        })
      } catch(e) {
        console.warn('Could not set latest tooltip handlers')
      }
    }, 1000)
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  },

  methods: {
    latestTooltipMouseoverFnc(elem) {
      const { width: tooltipWidth, height: tooltipHeight } = this.$refs?.tooltip?.getBoundingClientRect()
      const { top, left } = elem?.target?.getBoundingClientRect()
      this.tooltipTransformStyleProp = `translate3d(${(left || 0) - ((tooltipWidth || 0)/2)}px, ${(top || 0) - 20 - (tooltipHeight || 0)}px, 0px)`
      this.tooltipShow = true
    },
    latestTooltipMouseoutFnc() {
      this.tooltipTransformStyleProp = ''
      this.tooltipShow = false
    },
    studyPrototypes(studyGroup, study) {
      let appendLastVersionDot = ''
      if ((studyGroup?.lastPublishedVersion() && studyGroup?.lastPublishedVersion().id == study.id) || this.getLatestStudyIdsByAllPrototypes().includes(study.id)) {
        appendLastVersionDot = '<div class="-mt-1 bg-lime-600 latest-tooltip" style="border-radius: 4px; width: 8px; height: 8px; display: inline-block;"></div>'
      }
      const prototypes = this.$store.getters['globalEntities/Prototype/getterGlobalPrototypes']({study_id: study.id}).map((p) => p.title)
      if (!prototypes?.length) return ''
      return `${appendLastVersionDot}<br><small>(${prototypes.join(', ')})</small>`
    },
    toggleStudy(study_id) {
      const exclude_study_ids = this.getExcludeStudyIds.join(',')
      const newQuery = this.onlyToggleQueryKeyValue('exclude_study_ids', study_id, { exclude_study_ids })
      if(!newQuery['exclude_study_ids']) newQuery['exclude_study_ids'] = 'all'
      this.$router.push({query: {...newQuery, only_study_type: this.$route.query.only_study_type}})
    }
  }
}
</script>
<style lang="scss" scoped>
  ::v-deep .d-block-label {
    display: block !important;
  }

  .tooltip {
    visibility: hidden;
    z-index: -1;
    position: fixed;
    will-change: transform;
    top: 0;
    left: 0;

    &.active {
      display: block !important;
      z-index: 10000;
      visibility: visible;
    }
  }
</style>