import GraphQL from '@/util/GraphQL'
import Study from '@/models/Study'

export default {
  namespaced: true,
  
  state: {
    studies: []
  },

  getters: {    
    getterGlobalStudies: (state) => (filters = null) => {
      return filters ? state.studies.findAllInArray(filters) : state.studies
    },
    getterGlobalStudy: (state) => (filters = null) => {
      return filters ? state.studies.findInArray(filters) : false
    },
    getterGlobalStudiesByUserType(state, getters, rootState, rootGetters) {
      const userIsAdmin = rootGetters['getterLoggedUserIsAdmin']    
      return userIsAdmin ? state.studies : state.studies.filter(s => s.is_private ? false : true)
    },
    getterStudiesByStudyType: (state, getters, rootState, rootGetters) => ({ study_type_id }) => {
      const studyGroupsByStudyType = rootGetters['globalEntities/StudyGroup/getterGlobalStudyGroups']({study_type_id}) ?? []
      const studyGroupsIdsByStudyType = studyGroupsByStudyType.map(study_group => `${study_group.id}`)
      const studiesByStudyType = getters['getterGlobalStudiesByUserType'].filter(study => studyGroupsIdsByStudyType.includes(`${study.study_group_id}`))
      return studiesByStudyType
    },
    getterLatestPublishedStudiesByStudyTypeAndTypePrototype: (state, getters, rootState, rootGetters) => ({ type_prototype_id, study_type_id }) => {
      const studiesByStudyType = getters['getterStudiesByStudyType']({ study_type_id })
      const latestPublishedStudiesByStudyType = studiesByStudyType.filter(study => study.published_at).sort((a,b) => new Date(b.published_at) - new Date(a.published_at))
      
      const prototypesByTypePrototypeId = rootGetters['globalEntities/Prototype/getterGlobalPrototypes']({ type_prototype_id }) ?? []
      const studiesIdsThatHasPrototypesBasedOnTheTypePrototype = prototypesByTypePrototypeId.map(prototype => `${prototype.study_id}`)
      
      return latestPublishedStudiesByStudyType.filter((study => studiesIdsThatHasPrototypesBasedOnTheTypePrototype.includes(`${study.id}`)))
    },
    getterStudyStudyType: (state, getters, rootState, rootGetters) => ({ study_id }) => {
      const study = getters['getterGlobalStudy']({ id: study_id })
      const study_group = rootGetters['globalEntities/StudyGroup/getterGlobalStudyGroup']({ id: study.study_group_id })
      const study_type = rootGetters['globalEntities/StudyType/getterGlobalStudyType']({ id: study_group.study_type_id })
      return study_type
    }
  },

  actions: {
    loadGlobalStudies(context) {
      const query = `
        {
          studies (
            orderBy: [{ column: "order", order: "ASC" }]
          ) {
            id
            title
            order
            created_at
            expected_at
            released_at
            published_at
            is_private
            study_group_id
            code_cycle_id
            source_pdf
            pdf_export_source_description
            show_version_history
            has_waiting_measures
            impact_period
            custom_fields {
              item_id
              data
            }
          }
        }
      `
      GraphQL({ query })
        .then(({ data }) => {
          const studies = data.studies.map(study => new Study(study))
          context.commit('setGlobalStudies', { studies })
        })
    }
  },

  mutations: {
    setGlobalStudies(state, { studies }) {
      state.studies = studies
    }
  }
}
