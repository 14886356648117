<template>
  <div class="w-full  flex flex-col">
    <h4 class="psui-text-h5 psui-text-gray-80 font-bold whitespace-nowrap">
      Operating System
    </h4>
    <div class="block w-full">
      <ApexChartGeneral 
        :options="getChartOptions()"
        :palette-color-index="0"
      />
    </div>
  </div>
</template>

<script>
import { combinationColors } from '@/business-logic/constants/chartDefaultOptions'
import MathFunctions from '@/util/MathFunctions'
export default {
  name: 'AdminDashboardOsInfo',
  props: ['timeFrameSelected'],
  data() {
    return {
      combinationColors,
      os_info: []
    }
  },
   computed: {
    
    getTotal() {
      return this.os_info.reduce((acc, item) => acc + parseInt(item.os_count), 0)
    },

    mapItemsByPlatform() {
      const oss = []
      for(const os of this.os_info) {
        const finded = oss.findInArray({ platform: os.platform })
        if(!finded) oss.push(os)
      }
      return oss
    },

    getChartSeries() {
      return this.os_info.map((os, index) => {
        return {
          data: this.mapItemsByPlatform.map(platform => {
            return {
              x: platform.platform,
              y: platform.platform == os.platform ? platform.os_count : 0,
              os,
              fillColor: this.combinationColors[0][index],
              strokeColor: this.combinationColors[0][index],
            }
          })
        }
      })
    }
  },

  watch: {
    timeFrameSelected: {
      deep: true,
      handler() {
        this.getData()
      } 
    }
  },

  mounted() {
    this.getData()
  },

  methods: {

    getData() {
      this.$api.get('/api/admin/dashboard/get_data/os_info', { params: {
        init: this.timeFrameSelected.init, 
        end: this.timeFrameSelected.end 
      }})
        .then(({ data }) => {
          this.os_info = data.os_info
        })
    },

    getChartOptions() {
      const self = this
      return { 
        chart: {
          type: 'bar'
        },
        series: this.getChartSeries, 
        yaxis: {
          labels: {
            formatter: function(value) {
              return self.formatUnits(value)
            }
          },
        },
        tooltip: {
          custom: function ({ seriesIndex, dataPointIndex }) {
            const os = self.getChartSeries[seriesIndex].data[dataPointIndex].os
            return `
              <div class="psui-flex" style="background-color: ${ os.fillColor } !important;">
                <div class="apexcharts-tooltip-content psui-w-full psui-bg-white psui-p-4">
                  <h4 class="psui-text-gray-80 psui-text-xsmall psui-font-bold">${ os.os }</h4>
                  <div class="psui-w-full psui-flex psui-items-center justify-between space-x-2">
                    <h2 class="psui-text-gray-80 psui-text-small">${ self.formatUnits(os.os_count) } Device</h2>
                    <span class="psui-text-gray-30 psui-mx-1">|</span>
                    <h2 class="psui-text-gray-50 psui-text-small">${ self.formatNumber(MathFunctions.getPercentageOfAmount(self.getTotal, os.os_count)) }%</h2>
                  </div>
                </div>
              </div>
            `
          }
        }
      }
    }
  }
}
</script>