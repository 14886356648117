export default {
  getterLoggedUser: state => state.loggedUser,
  getterLoggedUserIsAdmin : state => state?.loggedUser?.is_admin ?? false,
  getterLoggedUserType: state => state.loggedUserType,
  getterLoggedUserToken: state => state.loggedUserToken,

  getterUserPrefferenceByPath: state => (path) => {
    return Object.byString(state.user_preferences, path) ?? null
  },
  
  getterUserPreference: state => (path) => {
    return {... state.user_preferences[path]?.data } ?? false
  },

  getterAllUserPreferences: state => state?.user_preferences ?? null,
  
  lastJurisdictionVisited: state => state?.user_preferences?.lastJurisdictionVisited ?? null,
  lastStudyTypeVisited: state => state?.user_preferences?.lastStudyTypeVisited ?? null,
  lastClimateZonesVisited: state => state?.user_preferences?.lastClimateZonesVisited ?? null,
  lastClimateZoneVisited(state) {
    const climate_zone = state?.user_preferences?.lastClimateZonesVisited?.[0]
    if(!climate_zone) return null
    if(state?.user_preferences?.lastJurisdictionVisited?.climate_zones?.findInArray({ raw: climate_zone.raw })) {
      return climate_zone
    }
    return state?.user_preferences?.lastJurisdictionVisited?.climate_zones?.[0]
  },

  hasVisitedModule: state => (module) => {
    return state.user_preferences?.modulesVisited?.includes(module) ? true : false
  },

  isUserLoggedIn(state) {
    return state?.loggedUser ?? null
  }
}
