import { render, staticRenderFns } from "./AdminStudyGroupStudies.vue?vue&type=template&id=a88b186a"
import script from "./AdminStudyGroupStudies.vue?vue&type=script&lang=js"
export * from "./AdminStudyGroupStudies.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports