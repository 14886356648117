<template>
  <transition
    name="fade"
  >
    <div
      v-show="getModalIsShow"
      class="modal-wrapper"
    >
      <div
        class="modal-dialog"
        :class="[modalDialogClasses, getClassesByHierarchy]"
      >
        <button 
          v-if="!disableClose && canModalBeClosed"
          class="modal-dialog-close absolute top-0 right-0 z-10 text-ash-500 pr-4 pt-5 focus:outline-none"
          @click="close()"
        >
          <PsIcon
            icon="close"
            size="24"
            icon-classes="psui-text-gray-50 cursor-pointer"
          />
        </button>
        <h4
          v-if="title"
          :class="titleClasses"
        >
          {{ title | policyTextRephrase }}
        </h4>
        <h3
          v-if="subtitle"
          class="text-base font-bold text-gray03 cursor-pointer"
        >
          {{ subtitle | policyTextRephrase}}
        </h3>
        <div class="w-full">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { MODAL_HIERARCHY, ROUTE_EXCEPTIONS } from '@/util/Enums.js'
import { mapGetters } from 'vuex'

export default {
  name: 'BaseModal',
  props: {
    title: {
      type: String,
      default: ''
    },
    titleClasses: {
      type: String,
      default: 'w-full font-bold text-pri text-lg text-left tracking-wide'
    },
    subtitle: {
      type: String,
      default: ''
    },
    modalDialogClasses: {
      default: 'max-w-sm'
    },
    disableClose: {
      type: Boolean,
      default: false
    },
    showModalParent: {
      type: Boolean,
      default: false
    },
    hierarchy: {
      type: [String],
      required: false,
      validator: key => {
        return Object.keys(MODAL_HIERARCHY).includes(key)
      }
    },
  },
  data() {
    return {
      showModal: false,
    }
  },
  computed: {
    ...mapGetters(['lastJurisdictionVisited']),
    getModalIsShow() {
      return (this.showModalParent || this.showModal)
    },
    getClassesByHierarchy(){
      if(this.hierarchy === MODAL_HIERARCHY.PRIMARY) {
        return 'psui-z-50'
      } else if(this.hierarchy === MODAL_HIERARCHY.SECONDARY) {
        return 'psui-z-60'
      } else {
        return ''
      }
    },
    canModalBeClosed() {
      return Object.values(ROUTE_EXCEPTIONS).includes(this.$route.name) || this.lastJurisdictionVisited ? true : false
    }
  },
  mounted() {
    document.addEventListener("keyup", this.handleEsc)
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleEsc)
  },
  methods: {
    handleEsc(evt) {
      if (this.getModalIsShow && evt.keyCode === 27) this.close()
    },
    open() {
      this.$emit('open')
      this.showModal = true
      this.$emit('update:showModalParent', false)
    },
    close() {
      if (this.disableClose || !this.canModalBeClosed) return false

      this.$emit('close')
      this.showModal = false
      this.$emit('update:showModalParent', false)
    }
  }
}
</script>

<style>
</style>
