
<template>
  <div
    id="building-estimates-index"
    class="w-full h-full flex flex-col overflow-y-auto psui-bg-gray-10"
  >
    <BuildingEstimatesHeader
      ref="header"      
      class="psui-sticky psui-top-0 psui-bg-white psui-shadow-elevation-5 psui-z-20 psui-mb-10"
      @trigger-download="download($event)"
    />
    <div class="psui-w-full psui-flex psui-flex-col psui-px-8 main-content pb-20">
      <div
        :class="{
          'psui-w-full psui-grid psui-grid-cols-5 psui-gap-6': getBuildingTypeSelected.slug === path.RESIDENTIAL_BUILDINGS.slug,
          'psui-w-full': getBuildingTypeSelected.slug === path.NON_RESIDENTIAL_BUILDINGS.slug
        }"
      >
        <div
          v-if="getBuildingTypeSelected.slug === path.RESIDENTIAL_BUILDINGS.slug"
          class="--add-to-pdf"
          :class="{
            'psui-col-span-full' : getterContentContainerWidth < 1000,
            'psui-col-span-2' : getterContentContainerWidth >= 1000,
          }"
          attr-export-steps="1"
          :attr-export-width="`${html2canvasOptions.windowWidth/4}px`"
        >
          <ExistingUnitsDonutsChart
            :get-type-prototypes-with-units="getTypePrototypesWithUnits"
            :override-colors="donutsColor"
          />
        </div>
        
        <div
          class="--add-to-pdf"
          attr-export-steps="1"
          :class="{
            'psui-col-span-full' : getterContentContainerWidth < 1000,
            'psui-col-span-3' : getterContentContainerWidth >= 1000,
          }"
        >
          <FutureUnitsForecastChart 
            :get-type-prototypes-with-units="getTypePrototypesWithUnits"
            :get-climate-zone-selected="getClimateZoneSelected"
            :get-building-type-selected="getBuildingTypeSelected"
            :override-colors="getBuildingTypeSelected.slug == BUILDING_TYPE_DB_SLUGS.NON_RESIDENTIAL ? alternativeColor : false"
          />
        </div>
      </div>
      <div class="psui-w-full psui-mt-6">
        <TypePrototypeCardList
          ref="prototypeCards"
          :get-type-prototypes-with-units="getTypePrototypesWithUnits" 
          :get-building-stock-total-units="getBuildingStockTotalUnits"
          :get-climate-zone-selected="getClimateZoneSelected"
          :get-building-type-selected="getBuildingTypeSelected"
          :palette-color-index="getBuildingTypeSelected.slug == BUILDING_TYPE_DB_SLUGS.RESIDENTIAL ? alternativeColor : combinationColors[0]"
        />
      </div>
    </div>
  </div>
</template>
<script>


import BuildingEstimatesHeader from './BuildingEstimatesHeader.vue'
import ExistingUnitsDonutsChart from './existing/ExistingUnitsDonutsChart.vue'
import FutureUnitsForecastChart from './future/FutureUnitsForecastChart.vue'
import TypePrototypeCardList from './TypePrototypeCardList.vue'
import BuildingEstimatesGeneratePdfService from '@/services/pdf/BuildingEstimatesGeneratePdfService'
import BuildingEstimatesGenerateCsvService from '@/services/csv/BuildingEstimatesGenerateCsvService'
import { GA_LABELS } from '@/mixins/GaEventsMixin'
import { joinCommaPlusAnd } from '@/util/Functions'
import { mapGetters } from "vuex"
import { ASSUMPTIONS_PATHS } from '@/modules/app/assumptions/shared/enums'
import { colors, alternativeColor,combinationColors } from '@/business-logic/constants/chartDefaultOptions'
import {BUILDING_TYPE_DB_SLUGS } from '@/util/Enums.js'



export default {


  name: 'BuildingEstimatesIndex',
  components: { BuildingEstimatesHeader, ExistingUnitsDonutsChart, FutureUnitsForecastChart, TypePrototypeCardList  },
  metaInfo() {
    return {
      title: this.lastJurisdictionVisited?.title_type ? `Building Estimates for ${this.lastJurisdictionVisited?.titles.title_type} | ` : 'Building Estimates '
    }
  },
  data() {
    return {
      isExporting: false,
      containerWidth: false,
      path: ASSUMPTIONS_PATHS,
      BUILDING_TYPE_DB_SLUGS,
      alternativeColor,
      combinationColors
    }
  },
  computed: {
    ...mapGetters({
      lastClimateZonesVisited : 'lastClimateZonesVisited',
      lastJurisdictionVisited : 'lastJurisdictionVisited',
      getterContentContainerWidth: 'general/getterContentContainerWidth',
    }),
    getBuildingTypeSelected() {
      return this.$store.getters['globalEntities/BuildingType/getterBuildingTypeSelected'](this.$route.fullPath)
    },
    getTypePrototypes() {
      if(!this.getBuildingTypeSelected) return []
      return this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototypes']({ building_type_id: this.getBuildingTypeSelected.id })
        .filter((type_prototype) => type_prototype.hide_in_building_estimates === false)
    },
    getClimateZoneSelected() {
      const climate_zones = this.$store?.getters['lastJurisdictionVisited']?.climate_zones ?? []
      return climate_zones
        .filter((climate_zone) => !this.checkQueryKeyValue('exclude_climate_zone_prefix', climate_zone.prefix))
    },
    getTypePrototypesWithUnits() {
      return this.getTypePrototypes.map((type_prototype) => {
        return {
          ...type_prototype,
          building_stock_units: this.getTypePrototypeBuildingStockUnits(type_prototype)
        }
      })
    },
    getBuildingStockTotalUnits() {
      return this.getTypePrototypesWithUnits.reduce((acc, item) => acc + parseInt(item.building_stock_units), 0)
    },

    html2canvasOptions() {
      return {
        windowWidth: 1920,
        logging : true
      }
    },
    donutsColor() {
      const aduTypePrototype = this.getTypePrototypesWithUnits.find((type_prototype) => type_prototype.slug === 'adu')
      if (aduTypePrototype) {
        const building_estimates_units = this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits']({
          type_prototype_id: aduTypePrototype.id,
          climate_zone_prefix: this.getClimateZoneSelected.map(cz => cz.prefix)
        })
        if (building_estimates_units === 0) {
          return colors
        }
      }
      return [...colors.slice(0,1), ...colors.slice(2,3), ...colors.slice(1,2), ...colors.slice(3)]
    }
  },
  mounted(){
    this.$eventBus.$on('watchToScrollToElement', (id)=>{
      if(id) {
        setTimeout(()=>{
          const element = document.getElementById(id)
          element.scrollIntoView({block:'center', behavior: 'smooth'})
        },400)
      }
    })

    this.$store.commit('assumptions/closeDrawer')
    this.$store.commit('assumptions/updateDrawerPath',ASSUMPTIONS_PATHS.RESIDENTIAL_BUILDINGS)   
  },
  beforeDestroy(){
    this.$eventBus.$off('watchToScrollToElement')
  },
  methods: {
    getTypePrototypeBuildingStockUnits(type_prototype) {
      if(!type_prototype.id) return 0
      const filters = {
        type_prototype_id: type_prototype.id,
        climate_zone_prefix: this.getClimateZoneSelected.map(cz => cz.prefix)
      }
      return this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits'](filters)
    },
    setExporting(value, buildingType = null) {
      if (value) {
        this.isExporting = true
        this.$refs.prototypeCards.setExporting(true, buildingType)
      } else {
        this.isExporting = false
        this.$refs.prototypeCards.setExporting(false)
      }
    },
    download(type) {
      const showLoadingModal = () => {
        this.$eventBus.$emit('openLoadingModal', {
          title: 'Download in progress...',
          description: 'We are preparing your document. Your download will be ready in a moment...',
          showCloseBtn: false
        })
      }
      switch(type) {
        case 'PDF':
          this.gaEventDownloadFile(GA_LABELS.BUILDING_ESTIMATES_DOWNLOAD_PDF, window.location.href)
          showLoadingModal()
          this.generatePdfFile()
          break
        case 'CSV':
          this.gaEventDownloadFile(GA_LABELS.BUILDING_ESTIMATES_DOWNLOAD_CSV, window.location.href)
          showLoadingModal()
          this.generateCsvFile()
          break
        default:
          console.error('Unsupported download type: ', type)
          break
      }
    },
    async generatePdfFile() {
      this.gaEventDownloadFile(GA_LABELS.BUILDING_ESTIMATES_DOWNLOAD_PDF)

      const prototypes = this.getTypePrototypes
      .map(type_prototype => this.$store.getters['globalEntities/Prototype/getterGlobalPrototypes']({type_prototype_id:type_prototype.id}))
      .reduce((acc,curr)=> acc.concat(curr),[])

      const args = {
        title: this.lastJurisdictionVisited?.title_type,
        subtitle: `Climate Zone ${joinCommaPlusAnd(this.lastClimateZonesVisited.map(c => c.prefix))}`,
        headline: 'Residential Building Estimates',
        sources : prototypes,
        html2canvasOptions: this.html2canvasOptions
      }

      const pdf = new BuildingEstimatesGeneratePdfService(this.$el.querySelectorAll('.--add-to-pdf'),
          this.setExporting, args, this.getBuildingTypeSelected)
      pdf.then(() => {
        this.$eventBus.$emit('closeLoadingModal')
        this.toastInstance = this.$appToast({ message: 'The PDF is ready and is being downloaded!',
          duration: 5000 })
      }).catch(() => {
        this.$eventBus.$emit('closeLoadingModal')
        this.toastInstance = this.$appToast({ message: 'Sorry, something went wrong with the PDF export. Try again',
          duration: 5000 })
      })
    },
    async generateCsvFile() {
      this.gaEventDownloadFile(GA_LABELS.BUILDING_ESTIMATES_DOWNLOAD_CSV)
      new BuildingEstimatesGenerateCsvService()
        .then(() => {
        this.$eventBus.$emit('closeLoadingModal')
        this.toastInstance = this.$appToast({ message: 'Your CSV is ready!',
          duration: 5000 })
      }).catch((err) => {
        console.log(err)
        this.$eventBus.$emit('closeLoadingModal')
        this.toastInstance = this.$appToast({ message: 'Sorry, something went wrong with the CSV\'s export. Try again',
          duration: 5000 })
      })
    },
    customData() {
      const hasCustomBuildingEstimates = this.$store.getters['assumptions/buildingStocks/getterCustomJurisdictionBuildingStocks']().length ?? false
      return hasCustomBuildingEstimates ? ', some figures edited by user' : ''      
    }
  }
}
</script>
