import Store from '@/store'
import { STUDY_TYPES_DB_SLUGS, ASSUMPTIONS_DRAWER_TYPES } from '@/util/Enums.js'
import { getQueryParamAsNumberList } from '@/util/getQueryParamAsList'
import CustomCombinationApiService from '@/services/api/CustomCombinationApiService'
import { defaultExistingBuildingsImpactAssumptionValues, defaultNewBuildingsImpactAssumptionValues } from '@/models/ImpactAssumptions.js'

export default (router) => {

  /**
   * Update assumptions drawerType on route change
   */
  router.beforeEach(async (to, from, next) => {
    if(to.params.policy_id) {
      // TO-DO remove commented content after 1306 release
      // Store.commit('assumptions/updateDrawerType', ASSUMPTIONS_DRAWER_TYPES.POLICIES)
      Store.commit('assumptions/setPolicyIdSelected', parseInt(to.params.policy_id))
      const selectedCustomCombinations = getQueryParamAsNumberList(to.query?.per_custom_combination_ids)
      Store.commit('assumptions/setPolicyCustomCombinationsSelected', selectedCustomCombinations)
      let policyAssumptions = Store.getters['assumptions/getterPolicySelected']
      let ccAssumptions = {}
      if (selectedCustomCombinations) {
        ccAssumptions = await CustomCombinationApiService.get(selectedCustomCombinations[0])
      } else {
        ccAssumptions = (policyAssumptions?.policy_containers?.[0]?.custom_combinations?.[0] || {})
      }

      let policyType = Store.getters['policy/getterPolicyStudyTypes']({policy_id:to.params.policy_id, policy:policyAssumptions})
      policyType = (policyType && policyType.length) > 0 ? policyType[0] : null
      const defaultPolicyAssumption = policyType === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS ? defaultNewBuildingsImpactAssumptionValues :
        (policyType === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS ? defaultExistingBuildingsImpactAssumptionValues : {})
      policyAssumptions = {...policyAssumptions,...defaultPolicyAssumption, ...(ccAssumptions?.meta?.assumptions || {})}

      Store.commit('assumptions/setPolicyImpactAssumptions', policyAssumptions)
    } else {
      const selectedTab = Store.getters['assumptions/getterAssumptionsDrawerOptions'].tab
      const newType = selectedTab && selectedTab.slug === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS ? ASSUMPTIONS_DRAWER_TYPES.NEW_RESULTS : ASSUMPTIONS_DRAWER_TYPES.EXISTING_RESULTS
      Store.commit('assumptions/updateDrawerType', newType)
      Store.commit('assumptions/setPolicyIdSelected', null)
    }
    next()
  })
}
