<template>
  <div>
    <div
      class="w-full flex flex-col items-start mt-6"
    >
      <p class="text-deepsky-900 text-accent-1 font-bold mb-3">
        {{ 'Rename Your Policy' | policyTextRephrase }}
      </p>
      <InputWithLabel
        v-model="new_title"
        :label="'Policy Name' | policyTextRephrase"
        :validation="validationTitle"
        @keydown.enter="duplicatePolicy"
      />

      <PsButton
        class="mt-6"
        label="Duplicate"
        size="big"
        :disabled="isLoading"
        @click="duplicatePolicy()" 
      />
    </div>
  </div>
</template>

<script>

import PolicyApiService from '@/services/api/PolicyApiService'
export default {
  name: 'PolicyFormDuplicate',
  props:['policyId','title'],
  data: () => ({
    new_title : '',
    isLoading : false,
  }),
  computed: {
    validationTitle() {
      if(!this.title) return { hasError: true, label: 'Please inform the Policy Name'}
      return { hasError: false }
    }
  },
  mounted() {    
    this.new_title = this.title
  },
  methods: {
    duplicatePolicy() {
      if (this.isLoading) return 
      this.isLoading = true
      PolicyApiService.duplicate({ policy_id: this.policyId, title: this.new_title })
        .then(( policy ) => {
          const rephrasedMessage = this.$options.filters.policyTextRephrase('Policy duplicated. Now you can edit it.')
          this.$appToast({ message: rephrasedMessage })
          this.$router.push(`/policies/${policy.id}`)
          this.$store.dispatch('policy/getUserPolicies')
          this.$emit('store-succcess')
          this.isLoading = false
        })
    },
  }
}
</script>