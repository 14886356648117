import Store from '@/store'

export default (jurisdiction_building_stocks) => {

  const jurisdiction = Store.getters['lastJurisdictionVisited']
  const type_prototypes = Store.getters['globalEntities/TypePrototype/getterGlobalTypePrototypes']()
  const type_vintages = Store.getters['globalEntities/TypeVintage/getterGlobalTypeVintages']()
  
  for(const climate_zone of jurisdiction.climate_zones) {
    for(const type_prototype of type_prototypes) {
      for(const type_vintage of type_vintages) {
        const item = {
          jurisdiction_id: jurisdiction.id,
          climate_zone_prefix: climate_zone.prefix,
          type_prototype_id: type_prototype.id,
          type_vintage_id: type_vintage.id
        }

        const building_stock_exists = jurisdiction_building_stocks.findInArray(item)

        if(!building_stock_exists) {
          jurisdiction_building_stocks.push({
            ...item, 
            units: 0,
            row_number: 0
          })
        }
      }
    }
  }

  return jurisdiction_building_stocks
}