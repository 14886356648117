import Vue from 'vue'
import router from '@/router'
import Api from '@/util/Api'
import UserEventApiService from '@/services/api/UserEventApiService'
import { POLICY_ATTACH_TO_USER_RETURNS } from '@/modules/app/policy/shared/enums.js'
import { GA_LABELS, gTagEvent } from '@/mixins/GaEventsMixin' 


export default {  

  getLoggedUser(context, showNotification = true) {
    return new Promise( (resolve, reject) => {
      Api.get(`/auth/get_logged_user`, { ignoreFeedback: true })
      .then(async ({ data }) => {
        
        const user = data
        context.commit('setLoggedUser', { user })
        
        const attachDraftPoliciesToUser = await context.dispatch('policy/attachPoliciesToUser')                 
        if (attachDraftPoliciesToUser === POLICY_ATTACH_TO_USER_RETURNS.HAS_NOT_POLICIES_TO_ATTACH) {
          if (showNotification) Vue.$toast.success('Login successful') 
        } else if (attachDraftPoliciesToUser === POLICY_ATTACH_TO_USER_RETURNS.POLICIES_HAS_BEEN_ATTACHED && user.last_login_at === null) {
          Vue.$toast.success('You now have an account and your policies have been saved.')        
        } else {
          Vue.$toast.success('You are now logged into your account and your policies have been saved.')    
        }
        
        // context.dispatch('general/checkRedirectTo')
        resolve({ user })
      })
      .catch(() => {
        Vue.$toast.error('Error on get logged user')
        reject()
      })
    })
  },

  logoutUser(context, {showNotification = true, redirect = true} = {}) {
    context.commit('setLogout')
    if(showNotification) Vue.$toast.success('Logged out!')
    if(redirect) router.push('/', () => {})
  },

  setUserPreferences({ commit, dispatch }, { mutation = 'setUserPreferences', payload }) {
    commit(mutation, payload)
    dispatch('updateUserPreferencesAtDatabase')
  },
  
  setUserPreferenceData({ commit, dispatch }, { path, data }) {
    if (!path || !data) throw new Error('Try to setUserPreference without path or data')
    commit('setUserPreferenceData', { path, data })
    dispatch('updateUserPreferencesAtDatabase')
  },

  setUserLastJurisdictionVisited({ dispatch }, { context, value }) {
    UserEventApiService.log({ event: 'SHOW_JURISDICTION', table: 'jurisdictions', table_item_id: value.id })
    return dispatch('setUserPreferences', { payload: { path: 'lastJurisdictionVisited', context, value } })
  },
  
  setUserLastClimateZonesVisited({ dispatch }, { context, value }) {
    return dispatch('setUserPreferences', { payload: { path: 'lastClimateZonesVisited', context, value } })
  },
  
  setUserLastStudyTypeVisited({ dispatch }, { context, value }) {
    return dispatch('setUserPreferences', { payload: { path: 'lastStudyTypeVisited', context, value } })
  },

  setUserLastJurisdictionAndFirstClimateZoneVisited({ dispatch, commit, getters }, { context, value }) {
    const lastJurisdictionSelected = getters.lastJurisdictionVisited
    
    UserEventApiService.log({ event: 'SHOW_JURISDICTION', table: 'jurisdictions', table_item_id: value.id })
    dispatch('setUserPreferences', { payload: { path: 'lastClimateZonesVisited', context, value: value.climate_zones } })
    if(lastJurisdictionSelected.slug != value.slug) {
      gTagEvent(GA_LABELS.JURISDICTION_SELECTION, {
        previous_jurisdiction_slug: lastJurisdictionSelected.slug,
        jurisdiction_slug: value.slug,
      })
    }
    
    commit('assumptions/clearResultsImpactAssumptions')
    return dispatch('setUserPreferences', { payload: { path: 'lastJurisdictionVisited', context, value } })
  },

  setModuleVisited({ commit, dispatch }, module ) {
    commit('setModuleVisited', module)
    dispatch('updateUserPreferencesAtDatabase')
  },

  updateUserPreferencesAtDatabase(context) {
    const userId = context.getters.getterLoggedUser?.id
    if (userId) {
      Api.post(`/api/users/update/${userId}`, { user_preferences: context.state.user_preferences })
    }
  }
  
}
