<template>
  <transition name="fade">
    <div 
      v-if="component_name && filters"
      id="policy-edit-requirements-drawer-wrapper"
      :class="{'is-on-full-screen' : isOnFullScreen }"
    >
      <div
        :is="component_name"
        :filters="filters"
        class="policy-edit-requirements-drawer"
        :style="{ maxWidth: getMaxWidth }"
        @close="onCloseDrawer"
      />
      <AssumptionsDrawer v-if="isOnFullScreen" />
    </div>
  </transition>
</template>

<script>
import AssumptionsDrawer from '@/modules/app/assumptions/shared/AssumptionsDrawer.vue'
import PolicyEditRequirementsNewBuildingsSF2022 from '@/modules/app/policy/show/requirements/requirements-drawer/PolicyEditRequirementsNewBuildingsSF2022/index.vue'
import PolicyExistingBuildingsWithFlexiblePathRequirements from '@/modules/app/policy/show/requirements/requirements-drawer/PolicyExistingBuildingsWithFlexiblePathRequirements.vue'

const POLICY_EDIT_REQUIREMENTS_URL_PREFIX = 'per_'

export default {
  name: 'PolicyEditRequirementsDrawer',  
  components: {
    PolicyEditRequirementsNewBuildingsSF2022,
    AssumptionsDrawer,
    PolicyExistingBuildingsWithFlexiblePathRequirements
  },
  data: () => ({
    component_name: false,
    filters : false,
    isOnFullScreen: false,
  }),
  computed: {
    routeHasPrefixedQueryParams() {
      let routeHasPrefixedQueryParams = false
      const queryParamsKeys = Object.keys(this.$route.query)
      queryParamsKeys.forEach(key => {
        routeHasPrefixedQueryParams = key.includes(POLICY_EDIT_REQUIREMENTS_URL_PREFIX) ? true : routeHasPrefixedQueryParams
      })
      return routeHasPrefixedQueryParams
    },
    getMaxWidth(){
      const { visibility } = this.$store.getters['assumptions/getterAssumptionsDrawerOptions']
      const getWidth = visibility ? '330' : '0'
      return `calc(100% - 48px - ${getWidth}px)`
    }
  },
  mounted() {
    this.$eventBus.$on('toggleEditRequirementsDrawerFullScreen', this.toggleFullScreen)
    this.$eventBus.$on('openDrawerPolicyEditRequirements', ({ component_name , ...filters }) => {
      this.initRequirementDrawerComponent({ component_name, ...filters })
      this.addPrefixedQueryParamsToRoute({ component_name, ...filters })
    })
    this.openTheDrawerRequirementsIfRouteHasPrefixedQueryParams()
  },
  beforeDestroy() {
    this.$eventBus.$off('openDrawerPolicyEditRequirements')
    this.$eventBus.$off('toggleEditRequirementsDrawerFullScreen')
  },
  methods: {
    onCloseDrawer() {
      this.component_name = false
      this.removePrefixedQueryParamsFromRoute()
      this.$eventBus.$emit('getPolicy')
      this.$eventBus.$emit('policyUpdated')
    },
    toggleFullScreen() {
      const contentContainer = document.getElementById('content-container')
      const sidebarContainer = document.getElementById('sidebar-container')

      if (this.isOnFullScreen) {
        this.isOnFullScreen = false
        sidebarContainer.classList.add('z-50')
        sidebarContainer.classList.remove('z-10')

        contentContainer.classList.add('z-10')
        contentContainer.classList.remove('z-30')
      } else {
        this.isOnFullScreen = true
        sidebarContainer.classList.add('z-10')
        sidebarContainer.classList.remove('z-50')

        contentContainer.classList.add('z-30')
        contentContainer.classList.remove('z-10')
      }
    },
    disableIsOnFullScreenOnFullScreenExit() {      
      if (!document.fullscreenElement) this.isOnFullScreen = false
    },
    initRequirementDrawerComponent({ component_name , ...filters }) {
      this.component_name = component_name
      this.filters = filters
    },
    removePrefixedQueryParamsFromRoute() {
      let queryWithoutTermsPrefixed = {}
      for (let key in this.$route.query) {
        if (!key.includes(POLICY_EDIT_REQUIREMENTS_URL_PREFIX)) queryWithoutTermsPrefixed[key] = this.$route.query[key]
      }
      this.$router.push({ query: queryWithoutTermsPrefixed })
    },
    addPrefixedQueryParamsToRoute(rawQuery) {
      let query = {}
      for (let key in rawQuery) {
        query[`${POLICY_EDIT_REQUIREMENTS_URL_PREFIX}${key}`] = rawQuery[key]
      }
      this.$router.push({ query })
    },
    openTheDrawerRequirementsIfRouteHasPrefixedQueryParams() {
      if (this.routeHasPrefixedQueryParams) {
        let queryWithoutKeysPrefixed = {}
        for (let key in this.$route.query) {
          if (key.includes(POLICY_EDIT_REQUIREMENTS_URL_PREFIX)) {
            const keyWithoutPrefix = key.replace(POLICY_EDIT_REQUIREMENTS_URL_PREFIX, '')
            queryWithoutKeysPrefixed[keyWithoutPrefix] = this.$route.query[key]
          } else {
            queryWithoutKeysPrefixed[key] = this.$route.query[key]
          }
        }
        this.initRequirementDrawerComponent(queryWithoutKeysPrefixed)
      }
    }
  }
}
</script>

<style lang="scss" scoped>  
  #policy-edit-requirements-drawer-wrapper {
    //width: 100%;
    //display: flex;

    &:has(div) {
      position: fixed;
      background: rgba(0, 0, 0, 0.3);
      display: block;
      width: 100vw;
      height: 100vh;
      left: 0;
      z-index: 40;
    }

    &.is-on-full-screen {
      .policy-edit-requirements-drawer {
        width: 100%;
      }
    }
  }

  .policy-edit-requirements-drawer {
    width: calc(100% - 48px) !important;
    margin: 24px;
    border-radius: 0.7rem;
    max-height: calc(100% - 48px);
    background: #fff;
  }

  .app-drawer-requirements {
    background-color: #F3F6F9 !important;
    
    .psui-text-accentSmall {
      letter-spacing: 0.6px;
    }

    .app-drawer-content {
      height: 100%;
    }
  }
</style>
