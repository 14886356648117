import PolicyRequirementsSummaryBase from './PolicyRequirementsSummaryBase'
import formatComplianceMarginAsText from '@/formatters/formatComplianceMarginAsText'
import MainVuex from '@/store'
import { formatComplianceMarginInfo } from '@/modules/app/policy/show/requirements/requirements-drawer/PolicyEditRequirementsNewBuildingsSF2022/helpers.js'

export default class PolicyRequirementsSummaryNew extends PolicyRequirementsSummaryBase {
  
  constructor(args) {    
    super(args)
    return this.initPdf()
  }
  
  addTableHeaders() {
    const typeFuels = MainVuex.getters['globalEntities/TypeFuel/getterGlobalTypeFuels']({ slug: ['all-electric', 'mixed-fuel'] }).sort((a, b) => a.order - b.order)
    this.tablesHeaders = ['Requirements', ...typeFuels.map(typeFuel => typeFuel.title)]
  }

  getTableBody(custom_combinations, isComplianceMarginEDR1Enabled) {

    custom_combinations.sort((a, b) => {
      const typeFuelA = MainVuex.getters['globalEntities/TypeFuel/getterGlobalTypeFuel']({ id: a.fuel.type_fuel_id })
      const typeFuelB = MainVuex.getters['globalEntities/TypeFuel/getterGlobalTypeFuel']({ id: b.fuel.type_fuel_id })
      return typeFuelA.order - typeFuelB.order
    })

    const rowFuelChoice = ['Fuel choice']
    const rowComplianceMargin = ['Compliance Margin']
    const rowLargerPvSystem = ['Larger PV System']

    for(const custom_combination of custom_combinations) {
      rowFuelChoice.push(this.getFuelChoiceValue(custom_combination.meta))
      rowComplianceMargin.push(this.getComplianceMarginValue(custom_combination, isComplianceMarginEDR1Enabled))
      if (!isComplianceMarginEDR1Enabled) {
        rowLargerPvSystem.push(this.getAdditionalPvsystem(custom_combination.meta))
      }
    }

    return isComplianceMarginEDR1Enabled ? [rowFuelChoice, rowComplianceMargin] : [rowFuelChoice, rowComplianceMargin, rowLargerPvSystem]
  }

  getFuelChoiceValue (customCombinationMeta) {
    if(!customCombinationMeta || Boolean(customCombinationMeta.fuel_choice) === false || customCombinationMeta.fuel_choice == 'allowed') return 'Allowed'
    if(customCombinationMeta.fuel_choice && customCombinationMeta.fuel_choice == 'required') return 'Mandatory'
    return 'Not Allowed'
  }

  getComplianceMarginValue (custom_combination, isComplianceMarginEDR1Enabled) {
    const { hasSomeVirtualComplianceMargin, isComplianceMarginUnique, isAllElectricConstructionRequired } = formatComplianceMarginInfo(custom_combination)

    if(isComplianceMarginEDR1Enabled && isComplianceMarginUnique && hasSomeVirtualComplianceMargin && !isAllElectricConstructionRequired) {
      return formatComplianceMarginAsText(custom_combination.meta.generate_virtual_compliance_margin)      
    } else if(!custom_combination.meta || Boolean(custom_combination.meta.compliance_margin_value) === false) {
      return '0'
    } else {
      return formatComplianceMarginAsText(custom_combination.meta.compliance_margin_value)
    }
  }
  
  getAdditionalPvsystem (customCombinationMeta) {
    if(!customCombinationMeta || Boolean(customCombinationMeta.require_pv_system) === false ) return 'Not Required'
    if(customCombinationMeta.require_pv_system && customCombinationMeta.require_pv_system == 'required') return 'Required'
    return 'Not Required'
  }

}