<template>
  <div
    class="psui-w-full psui-h-full psui-flex psui-flex-col"
  >
    <StudyResultsHeader
      class="psui-sticky psui-top-0 psui-bg-white psui-shadow-elevation-5"
    />
    <StudyResultsContent
      v-if="$route.params.climate_zone_raw"
      id="study-results-index"
      class="w-full"
    />

    <ModalAddToPolicy />
    <StudyResultsProjectionsDrawer />
    <StudyResultsEditColumnsDrawer />
  </div>
</template>

<script>
import StudyResultsContent from './StudyResultsContent.vue'
import StudyResultsEditColumnsDrawer from './StudyResultsEditColumnsDrawer.vue'
import StudyResultsProjectionsDrawer from '@/modules/app/jurisdiction/study-results/StudyResultsProjectionsDrawer.vue'
import ModalAddToPolicy from '@/modules/app/jurisdiction/study-results/content/general/ModalAddToPolicy.vue'
import { mapGetters } from 'vuex'
import StudyResultsHeader from './StudyResultsHeader.vue'
import JurisdictionApiService from '@/services/api/JurisdictionApiService'

export default {
  name: 'StudyResultsIndex',
  components: {
    StudyResultsHeader,
    ModalAddToPolicy,
    StudyResultsProjectionsDrawer,
    StudyResultsEditColumnsDrawer,
    StudyResultsContent,
  },

  metaInfo() {
    return {
      title: this.lastJurisdictionVisited?.title_type ? `Results for ${this.lastJurisdictionVisited?.titles.title_type} | ` : 'Results '
    }
  },

  computed: {
    ...mapGetters([
      'lastJurisdictionVisited',
      'lastClimateZoneVisited',
      'lastStudyTypeVisited',
    ]),
  },
  watch: {
    '$route.params.climate_zone_raw': {
      async handler(raw) {
        if (raw && raw != this.lastClimateZoneVisited?.raw) {
          this.checkClimateZoneParam()
        }
      },
      immediate: true,
    },
    lastJurisdictionVisited: {
      async handler(newJurisdiction, oldJurisdiction) {
        if(newJurisdiction !== oldJurisdiction) {
          if(newJurisdiction && this.$route.name == 'StudyResults' && !this.$route.params.climate_zone_raw) {
            this.checkClimateZoneParam()
          }
        }
      },
      immediate: true,
    },
    lastClimateZoneVisited: {
      async handler(newClimateZone, oldClimateZone) {
        if(newClimateZone !== oldClimateZone) {
          if(newClimateZone?.raw && this.$route.name == 'StudyResults') {
            this.checkClimateZoneParam()
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$store.commit('assumptions/closeDrawer')
    this.checkClimateZoneParam()
  },
  beforeDestroy() {
    this.$store.commit('assumptions/updateDrawerTab', false)
  },
  methods: {
    checkClimateZoneParam() {
      const climateZoneBelongsToJurisdiction =
        this.checkClimateZoneBelongsToJurisdiction(
          this.$route.params.climate_zone_raw
        )
      if (climateZoneBelongsToJurisdiction) {
        this.$store.dispatch('setUserLastClimateZonesVisited', {
          value: [climateZoneBelongsToJurisdiction],
          context: 'updateLastClimateZoneVisitedFromStudyResults',
        })
      } else {
        this.checkRouteHasClimateZoneConflictAndNeedRedirect()
      }
    },

    checkClimateZoneBelongsToJurisdiction(climate_zone_raw) {
      return this.lastJurisdictionVisited?.climate_zones
        ? this.lastJurisdictionVisited.climate_zones.findInArray({
            raw: climate_zone_raw,
          })
        : false
    },

    //adding catch to avoid run time error, a refactoring in whole logic would be great
    checkRouteHasClimateZoneConflictAndNeedRedirect() {
      JurisdictionApiService.getBySlug(
        this.$route.params.jurisdiction_slug,
        'StudyResultsIndex.checkRouteHasClimateZoneConflictAndNeedRedirect'
      ).then(({ jurisdiction }) => {
        const climate_zone = jurisdiction?.climate_zones.findInArray({
          raw: this.$route.params.climate_zone_raw,
        })
        if (climate_zone) {
          this.$store.dispatch('setUserLastClimateZonesVisited', {
            value: [climate_zone],
            context: 'updateLastClimateZoneVisitedFromStudyResults',
          })
          if (this.$route.params.climate_zone_raw != climate_zone.raw) {
            this.$router.push({
              name: 'StudyResults',
              params: {
                ...this.$route.params,
                jurisdiction_slug: this.lastJurisdictionVisited.slug,
                climate_zone_raw: climate_zone.raw,
              },
              query: { ...this.$route.query },
            }).catch(err => {})
          }
        } else {
          this.$router.push({
            name: 'StudyResults',
            params: {
              ...this.$route.params,
              jurisdiction_slug: this.lastJurisdictionVisited.slug,
              climate_zone_raw:
                this.lastJurisdictionVisited.climate_zones?.[0]?.raw,
            },
            query: { ...this.$route.query },
          }).catch(err => {})
        }
      })
    },
  },
}
</script>
