export default (Store) => {
  Store.subscribe((mutation) => {
    if(mutation.type === 'setUserPreferences' && mutation?.payload?.path === 'lastJurisdictionVisited') {
      const jurisdiction = mutation.payload.value

      Store.dispatch('assumptions/buildingStocks/getJurisdictionBuildingStocks', jurisdiction.id)
      Store.dispatch('assumptions/buildingEstimates/getJurisdictionBuildingEstimates', jurisdiction.id)

    }
  })
}
