<template>
  <div>
    <vue-skeleton-loader
      v-for="each in 3"
      :key="each"
      :width="985"
      :height="53"
      class="mt-5"
    />
    <vue-skeleton-loader
      class="mt-5"
      :width="116"
      :height="40"
    />
  </div>
</template>

<script>
  export default {
    name:"AdminJurisdictionEditSkeleton"
  }
</script>
