<template>
  <PsDropdown>
    <template #dropdownTrigger>
      <PsIcon 
        :data-test-id="`policies-list-table-user-actions-${policy.id}`"
        icon="more_horiz"
        class="psui-text-gray-50 psui-cursor-pointer leading-none"
      />
    </template>
    <template #items>
      <ul class="psui-w-full psui-font-bold psui-my-3">      
        <li
          v-if="isPolicyTypeOwn && !isPolicyOutdated"
          title="Edit name"
          class="psui-text-gray-60 hover:psui-bg-blue-10 hover:psui-text-blue-60 psui-font-bold psui-cursor-pointer psui-flex psui-items-center psui-gap-3 psui-px-5 psui-py-1 transition-all"
          @click.stop="$eventBus.$emit('openModalPolicyCreateEdit', policy.id)"
        >
          <PsIcon 
            icon="edit" 
            size="22"
          />
          <span class="psui-w-auto psui-text-small">Edit name</span>
        </li>
        <li
          v-if="!isPolicyOutdated"
          title="Duplicate"
          class="psui-text-gray-60 hover:psui-bg-blue-10 hover:psui-text-blue-60 psui-font-bold psui-cursor-pointer psui-flex psui-items-center psui-gap-3 psui-px-5 psui-py-1 transition-all"
          @click.stop="$eventBus.$emit('openDuplicatePolicyModal', { policy: policy })"
        >
          <PsIcon 
            icon="library_books" 
            size="22"
          />
          <span class="psui-w-auto psui-text-small">Duplicate</span>
        </li>
        <li
          v-if="!isPolicyOutdated"
          title="Share"
          class="psui-text-gray-60 hover:psui-bg-blue-10 hover:psui-text-blue-60 psui-font-bold psui-cursor-pointer psui-flex psui-items-center psui-gap-3 psui-px-5 psui-py-1 transition-all"
          @click.stop="sharePolicy(policy)"
        >
          <PsIcon 
            icon="share" 
            size="22"
          />
          <span class="psui-w-auto psui-text-small">Share</span>
        </li>
        <li
          v-if="isPolicyTypeOwn"
          data-test-id="delete-policy-button"
          title="Delete"
          class="psui-text-gray-60 hover:psui-bg-blue-10 hover:psui-text-blue-60 psui-font-bold psui-cursor-pointer psui-flex psui-items-center psui-gap-3 psui-px-5 psui-py-1 transition-all"
          @click.stop="$eventBus.$emit('openModalDeletePolicy', { policy: policy })"
        >
          <PsIcon 
            icon="delete" 
            size="22"
          />
          <span class="psui-w-auto psui-text-small">Delete</span>
        </li>
        <li
          v-else
          title="Remove"
          class="psui-text-gray-60 hover:psui-bg-blue-10 hover:psui-text-blue-60 psui-font-bold psui-cursor-pointer psui-flex psui-items-center psui-gap-3 psui-px-5 psui-py-1 transition-all"
          @click.stop="removeShared(policy.id)"
        >
          <PsIcon 
            icon="delete" 
            size="22"
          />
          <span class="psui-w-auto psui-text-small">Remove Shared</span>
        </li>
      </ul> 
    </template>
  </PsDropdown>
</template>

<script>
import { POLICY_TYPES } from '@/modules/app/policy/shared/enums.js'

export default {
  name: 'PoliciesListTableUserActions',
  props: ['policy', 'isPolicyOutdated'],
  computed: {
    isPolicyTypeOwn() {
      return this.$store.getters['policy/getterPolicyType'](this.policy.id) === POLICY_TYPES.OWN.value
    },
  },
  methods: {
    sharePolicy(policy) {
      this.copyToClipboard(window.location.origin + '/policies/' + policy.id)
      this.$toast.success('Policy url copied to clipboard!')
    },

    removeShared(policy_id) {
      this.$api.post(`/api/policies/remove_shared`, { policy_id })
        .then(() => {
          this.$store.dispatch('policy/getUserSharedPolicies')
        })
    }
  }
}
</script>

<style>

</style>
