<template>
  <div>
    <BaseAppHeader 
      id="summary-header"
      title="Summary for"
      :has-jurisdiction-selection="true"
      @on-jurisdiction-select="onJurisdictionSelect"
    >
      <template #top-right>
        <SummaryHeaderActions />
      </template>
      <template #bottom-left>
        <SummaryClimateZonesList class="py-3" />
      </template>
    </BaseAppHeader>
  </div>
</template>

<script>
import BaseAppHeader from '@/components/general/BaseAppHeader.vue'
import SummaryHeaderActions from '@/modules/app/jurisdiction/summary/SummaryHeaderActions.vue'
import SummaryClimateZonesList from '@/modules/app/jurisdiction/summary/SummaryClimateZonesList.vue'


export default {
  name: 'SummaryHeader',
  components: { 
    BaseAppHeader,
    SummaryHeaderActions,
    SummaryClimateZonesList
  },
  methods: {
    onJurisdictionSelect(jurisdiction) {
      this.$store.dispatch('setUserLastJurisdictionAndFirstClimateZoneVisited', { value: jurisdiction, context: 'updateLastJurisdictionVisitedFromSummaryHeader'})
      this.pushToParams('jurisdiction_slug', jurisdiction.slug)
    },
  },

}
</script>

