<template>
  <PsDialog
    v-if="!isCloseDialog"
    class="mt-3 mb-5"
    theme="alert"
    :has-close="true"
    message="Be sure to register or login so we can save changes to your workspace"
    layout="vertical"
    @close="closeDialog"
  >
    <template #action>
      <div>
        <p
          class="psui-font-bold mt-1 hover:opacity-80 transition-all cursor-pointer inline-block"
          @click="openModalUserSign()"
        >
          Register / Login
        </p>
      </div>
    </template>
  </PsDialog>
</template>

<script>
export default {
  name: 'AssumptionsBuildingEstimatesUserSign',
  props: {
    sessionStorageKey: String,
  },
  data: () => ({
    isCloseDialog: false,
  }),
  mounted() {
    this.isCloseDialog = Boolean(sessionStorage.getItem(`${this.sessionStorageKey}`))
    this.setRetirectTo()
  },
  methods: {
    closeDialog() {
      this.$store.commit('assumptions/showNotLoggedWarning', false, { root: true })
      this.isCloseDialog = !this.isCloseDialog
      if (this.isCloseDialog) {
        sessionStorage.setItem(`${this.sessionStorageKey}`, 'true')
      }
    },
    openModalUserSign() {
      this.$eventBus.$emit('openModalUserSign')
      this.setRetirectTo()
    },
    setRetirectTo() {
      const event = {
        name: 'openAssumptionDrawerFromAppBoot',
        payload: this.$store.getters['assumptions/getterAssumptionsDrawerOptions'],
      }
      this.$store.commit('general/setRedirectTo', { route: this.$route.path, event })
    },
  },
}
</script>

<style></style>