<template>
  <DashboardCard
    title="Policies By Type"
  >
    <div class=" psui-flex psui-flex-col psui-justify-between">
      <div class="psui-w-full psui-flex psui-justify-end mb-6">
        <PsCardInfos 
          title="Total"
          :total="formatUnits(getTotal)"
        />
      </div>

      <div
        class="psui-block"
        style="height: 300px;"
      >
        <div ref="chartExistingUnits" />
        <ApexChartGeneral 
          :options="getChartOptions()"
        />
      </div>

      <div class="psui-flex psui-mx-auto psui-space-x-2">
        <PsChartLegend
          v-for="(item, index) in getMappedItems" 
          :key="item.title"
          class="cursor-pointer"
          :text="item.title"
          :total="formatUnits(item.total)"
          :percentage="`${formatNumber(getPercentage(item.total))}`"
          :dot-color="{ backgroundColor: colors[index] }"
          @click.native="setSelectedList(index)"
        />
      </div>
    </div>

    <div
      class="psui-w-full psui-p-6 psui-flex psui-items-center psui-space-x-3 psui-relative cursor-pointer"
      @click.stop="showTable = !showTable"
    >
      <span 
        class="btn-arrow material-icons-round psui-text-gray-50 psui-flex-none transition-all psui-transform psui-cursor-pointer"
        :class="showTable ? 'open psui-rotate-90' : 'closed psui-rotate-0'"
      >
        chevron_right
      </span>
      
      <div class="psui-w-full psui-flex psui-flex-col">
        <h3 class="psui-text-gray-80 psui-text-h5 psui-font-bold">
          {{ getMappedItems[selectedListIndex].title }}
        </h3>
      </div>
    </div>

    <div
      v-if="showTable"
      class="w-full mt-4"
    >
      <JurisdictionDashboardPoliciesList :policies="getMappedItems[selectedListIndex].items" />
    </div>
  </DashboardCard>
</template>

<script>
import { colors } from '@/business-logic/constants/chartDefaultOptions'
import { MathHelpers } from '@igortrindade/lazyfy'
import JurisdictionDashboardPoliciesList from './JurisdictionDashboardPoliciesList'
export default {
  name: 'SearchsInJurisdiction',
  components: { JurisdictionDashboardPoliciesList },
  props: ['timeFrameSelected', 'jurisdictionSelected', 'policies'],
  data() {
    return {
      selectedListIndex: 0,
      colors,
      showTable: false
    }
  },
  computed: {
    getTotal() {
      return this.getMappedItems.reduce((acc, item) => acc + item.total, 0)
    },
    getMappedItems() {
      return [ 
        {
          title: "New Buildings",
          total: this.getNewBuildings.length,
          items: this.getNewBuildings
        },
        {
          title: "Existing Buildings",
          total: this.getExistingBuildings.length,
          items: this.getExistingBuildings
        },
        {
          title: "Empty policies",
          total: this.getEmptyPolicies.length,
          items: this.getEmptyPolicies
        }
      ]
    },

    getEmptyPolicies() {
      return this.policies.filter((policy) => {
        return !policy.policy_containers.length || !policy.policy_containers.filter((policy_container) => {
          return policy_container.custom_combinations.length
        }).length
      })
    },

    getNewBuildings() {
      return this.policies.filter((policy) => {
        return policy.policy_containers.filter((policy_container) => {
          return policy_container.custom_combinations.filter((custom_combination) => {
            return custom_combination['fuel_id'] != null
          }).length > 0
        }).length > 0
      })
    },
    getExistingBuildings() {
      return this.policies.filter((policy) => {
        return policy.policy_containers.filter((policy_container) => {
          return policy_container.custom_combinations.filter((custom_combination) => {
            return custom_combination['vintage_id'] != null
          }).length > 0
        }).length > 0
      })
    },
  },
  methods: {
    getPolicies(key) {
      return this.policies.filter((policy) => {
        return policy.policy_containers.filter((policy_container) => {
          return policy_container.custom_combinations.filter((custom_combination) => {
            return custom_combination[key] != null
          }).length > 0
        }).length > 0
      })
    },

    getChartOptions() {
      const self = this
      return {
        series: this.getMappedItems.map((item) => MathHelpers.getValueOrMinPercentage(this.getTotal, item.total, 1) ),
        colors: colors,
        chart: {
          type: 'donut',
          toolbar: { show: false, },
        },
        tooltip: {
          followCursor: false,
          fixed: {
            enabled: true,
            position: "topRight",
            offsetX: 0,
            offsetY: 0,
          },
          custom: function ({ seriesIndex }) {
            const item = self.getMappedItems[seriesIndex]
            return `
              <div class="psui-flex" style="background-color: ${ colors[seriesIndex] } !important;">
                <div class="apexcharts-tooltip-content psui-w-full psui-bg-white psui-p-4">
                  <h4 class="psui-text-gray-80 psui-text-xsmall psui-font-bold">${ item.title }</h4>
                  <div class="psui-w-full psui-flex psui-items-center">
                    <h2 class="psui-text-gray-80 psui-text-small">${ self.formatUnits( item.total ) } policies</h2>
                  </div>
                </div>
              </div>
            `
          }
        }
      }
    },

    getPercentage(value) {
      return 100 / this.getTotal * value
    },

    setSelectedList(index) {
      this.selectedListIndex = index
      this.showTable = true
    }
  }
}
</script>