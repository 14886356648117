<template>
  <div
    v-if="columnGroup"
    class="w-auto flex flex-col h-full"
    :class="[ 'column-group', `column-group-${columnGroup.key}`]"
  >
    <!-- Super header -->
    <div class="w-full px-1 pt-1 sticky top-0 left-0 bg-white z-5">
      <div class="superheader-border w-full border-b-2 border-deepsky-200 h-8 flex items-center">
        <feather 
          size="16" 
          type="info" 
          class="superheader-border-feather text-blue cursor-pointer" 
          @click="openColumnsHelper(columnGroup.hasHelper)"
        />
        <h2 class="pl-2 text-deepsky-900 text-header-2 font-bold">
          {{ columnGroup.title }}
        </h2>
      </div>
      <div class="flex bg-white space-x-4">
        <div
          v-for="(column, colIndex) in columnGroup.columns"
          :key="colIndex"
          class="column-group-subheader flex items-start pt-2 min-w-[168px] w-full"
        >
          <feather 
            size="16" 
            type="info" 
            class="column-group-helper-button text-blue -ml-2 mr-2 cursor-pointer" 
            @click="openColumnsHelper(column.hasHelper)"
          />
          <div class="flex flex-col items-start justify-start">
            <p class="text-deepsky-900 font-bold text-header-2">
              {{ column.title }}
            </p>
            <p class="text-ash-500 h-4">
              {{ column.description }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="flex">
      <template 
        v-for="(column, colIndex) in columnGroup.columns"
        
        class="comparison-table-column"
      >
        <!-- ITEM -->
        <div
          :key="colIndex"
          class="w-full"
        >
          <div 
            v-for="policy in policies"
            :key="policy.id"
            class="comparison-table-row w-full px-4 py-2 max-w-xs row-scope-item border-box"
            :style="{ height: `${itemsRowHeight}px` }"
          >
            <ProgressBarValue
              v-if="getItemMaxValue(column.key) && columnGroup.key === 'city_wide_estimates'"
              class="flex justify-around"
              :value="getPolicyTotalStudyData(policy)[column.key]"
              :max-value="getItemMaxValue(column.key)"
              :bar-class="column.key == 'forecast_initial_cost' ? 'bg-sunrise-200' : 'bg-deepsky-300'"
              :label-class="column.key == 'forecast_initial_cost' ? 'text-sunrise-800 text-header-2' : 'text-deepsky-800 text-header-2'"
              :label="formatStudyData(column.key, getPolicyTotalStudyData(policy)[column.key], getPolicyTotalStudyData(policy)).toString()"
            />
            <template v-else>
              <span class="text-sunrise-800 text-header-2 h-full w-full flex items-center justify-around">
                {{ formatStudyData(column.key, getPolicyTotalStudyData(policy)[column.key], getPolicyTotalStudyData(policy)) }}
              </span>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ProgressBarValue from '@/components/general/ProgressBarValue'
export default {
  name: 'ComparisonTableGroupStudyData',
  components: { ProgressBarValue },
  props: [
    'policies',
    'itemsRowHeight',
    'columnGroup'
  ],
  updated() {
    this.$emit('checkScopeRowHeight')
  },
  methods: {
    getPolicyTotalStudyData(policy) {
      return policy.policy_summary_data[0].data
    },
    getItemMaxValue(columnKey) {
      const orderedPolicies = this.policies.map((item) => item).sort((a, b) => b.policy_summary_data[0].data[columnKey] - a.policy_summary_data[0].data[columnKey])
      return orderedPolicies[0].policy_summary_data[0].data[columnKey]
    }
  }
}
</script>
<style lang="scss" scoped>
.column-group:last-of-type {
  padding-right: 40px;
  .column-item:last-of-type {
    padding-right: 5px;
    > div {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      margin-right: 1px;
      &:last-of-type {
        border-right-width: 1px;
      }
    }
  }
}
</style>
