<template>
  <div class="psui-w-full psui-h-full psui-flex psui-justify-center psui-p-8">
    <div class="psui-flex psui-flex-col psui-py-8 psui-pl-4 psui-text-center">
      <img
        width="240"
        height="180"
        src="/images/building_estimates/building-estimates.png"
        alt="We didn't find any results matching the selected criteria"
        class="psui-mb-6 psui-flex psui-self-center"
      >
      <p class="psui-text-h5 psui-font-bold psui-text-gray-70 psui-mb-2">
        There’s no data to show
      </p>
      <p class="psui-text-p psui-text-gray-60">
        There are no results for your selected filters. Please change your filters to view data.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StudyResultsContentEmpty'
}
</script>

<style>

</style>