<template>
  <BaseModal
    ref="baseModal"
    modal-dialog-classes="w-full max-w-sm"
  >
    <div class="w-full flex flex-col items-start mt-6">
      <h2 class="text-header-6 mx-auto">
        Delete {{ 'policy' | policyTextRephrase }} <span class="text-deepsky-700">{{ title }}</span>?
      </h2>
      <PsButton
        data-test-id="confirm-delete-policy-button"
        class="mt-6 mx-auto"
        label="Confirm delete"
        size="big"
        :loading="isLoading"
        @click="deletePolicy()"
      />
    </div>
  </BaseModal>
</template>

<script>
import PolicyApiService from '@/services/api/PolicyApiService'
export default {
  name: 'ModalDeletePolicy',
  data: () => ({
    policy_id : false,
    title: '',
    isLoading : false,
  }),
  computed: {
    validationTitle() {
      if(!this.title) return { hasError: true, label: 'Please inform the Policy Name'}
      return { hasError: false }
    }
  },
  mounted() {
    this.$eventBus.$on('openModalDeletePolicy', ({ policy }) => {
      this.$refs.baseModal.showModal = true
      this.policy_id = policy.id
      this.title = policy.title
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openModalDeletePolicy')
  },
  methods: {
    deletePolicy() {
      if (this.isLoading) return 

      this.isLoading = true      
            
      PolicyApiService.delete(this.policy_id)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit('policy/removeUserPolicy', { id: this.policy_id })
            const rephrasedMessage = this.$options.filters.policyTextRephrase('Policy deleted successfuly!')
            this.$appToast({ message: rephrasedMessage })
            this.isLoading = false

            if (this.$route.name !== 'PoliciesList') {
              this.$router.push({ name: 'PoliciesList' })
            }

            this.$refs.baseModal.close()
          }
        })
        .catch(() => {
          this.isLoading = false
        })
    }
  }
}
</script>