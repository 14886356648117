<template>
  <div
    class="usersign-form w-full transition-all duration-300 ease-in-out"
    :class="{ 'opacity-60 pointer-events-none' : isLoading }"
  >
    <h3 class="mb-6 w-full text-left text-pri ts--title--5 font-bold">
      <span v-if="isRegistering">Create an account</span>
      <span v-else-if="userEmailExist">Welcome back!</span>
      <span v-else>Sign in or register to start</span>
    </h3>
    
    <div
      v-if="!userEmailExist & !isRegistering"
      class="w-full"
    >
      <AppLoginGoogle class="mb-4 w-full text-sm inline-block" />
      <p class="mb-4 mt-8 w-full or-separator text-gray04">
        <span>or</span>
      </p>
    </div>
    
    <InputWithLabel
      :id="'signin-email'"
      v-model="user.email"
      :label="userEmailExist ? 'Log in using your email' : 'Email'"
      :type="'Email'"
      :validation="validation.email"
      @keydown.enter="checkEmail"
      @input="validationClear()"
    />

    <div
      v-if="userEmailExist || isRegistering"
      class="w-full mt-6"
    >
      <InputWithLabel
        :id="'signin-password'"
        v-model="user.password"
        :type="'password'"
        :label="'Password'"
        :validation="validation.password"
        @keydown.enter="nextStep"
        @input="validationClear()"
      />
    </div>
    
    <div
      v-if="isRegistering"
      class="w-full  mt-6"
    >
      <div class="max-w-sm mt-5 flex flex-wrap  transition duration-300 relative">
        <Toggle 
          label="I would like to receive emails about new features and other updates about the Cost Effectiveness Explorer Tool." 
          label-class="ts--accent--2 pl-1 text-gray04"
          :active.sync="user.accept_features_newsletter"
        />
        <br>
        <Toggle 
          label="I would like to receive the LocalEnergyCodes.com monthly newsletter and periodic announcements." 
          label-class="ts--accent--2 pl-1 text-gray04"
          :active.sync="user.accept_periodic_newsletter"
        />
      </div>

      <AppButton
        label="Register with your email"
        class="mr-auto mt-6"
        @click="userRegister()"
      />
    </div>
    <div
      v-else-if="userEmailExist"
      class="w-full text-center mt-6"
    >
      <div class="flex items-center gap-8">
        <button
          id="login-button"
          data-test-id="login-button"
          :disabled="!user.email || !user.password"
          class="button bg-sec text-white rounded-full uppercase focus:outline-none"
          @click="attemptLogin()"
        >
          Login
        </button>
        <Checkbox
          v-model="user.keep_signed"
          class="ml-auto flex-shrink"
          :label="'Keep me signed in'"
        />
      </div>
      <div class="w-full text-left mt-6">
        <button
          class="w-full text-left text-sec text-sm inline-block font-medium" 
          @click="goToRecoverPassword()"
        >
          I forgot my password
        </button>
      </div>

      <h4 class="w-full text-left text-gray mt-6 text-sm border-solid border-t border-gray06 pt-6">
        You can also <span
          class="text-sec font-medium cursor-pointer"
          @click="resetState()"
        >Create a new account</span>
      </h4>
    </div>
    <div
      v-else
      class="w-full text-center mt-6"
    >
      <button
        id="continue-with-email"
        class="button bg-sec text-white rounded-full uppercase ts--accent--book--1 mx-auto"
        @click="checkEmail()"
      >
        Continue with your email
      </button>
    </div>        

    <div
      v-if="isRegistering"
      class="w-full"
    >
      <p class="mb-4 mt-8 w-full or-separator text-gray04">
        <span>or</span>
      </p>
      <AppLoginGoogle class="mb-4 w-full text-sm inline-block" />
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/general/AppButton.vue'

import User from '@/models/User'
import { emailValidator } from '@/util/Validators'


export default {
  components: { AppButton },
  data() {
    return this.initialState()
  },
  mounted() {
    this.resetState()
  },
  methods: {
    initialState() {
      return {
        user: new User(),    
        isLoading: false,
        userEmailExist : null,
        isRegistering : false,
        validation: {
          email: {
            hasError: false,
            label: ''
          },
          password: {
            hasError: false,
            label: ''
          }
        }
      }
    },
    resetState() {
      Object.assign( this.$data, this.initialState())
    },
    nextStep() {
      if (this.isRegistering) {
        this.userRegister()
      } else {
        this.attemptLogin()
      }
    },
    userEmailFieldValidation() {
      this.validationClear('email')

      if(!this.user.email || !emailValidator(this.user.email)) {
        this.validation.email.hasError = true
        this.validation.email.label = 'Please, fill a valid email'
        return false        
      }
      return true
    },
    
    userPasswordFieldValidation() {
      this.validationClear('password')

      if(!this.user.password) {
        this.validation.password.hasError = true
        this.validation.password.label = 'Please, fill your password'
        return
      }
      
      if(this.user.password && this.user.password.length < 6) {
        this.validation.password.hasError = true
        this.validation.password.label = 'A password should have at least 6 characters'
        return
      }

      return true
    },

    async checkEmail() {
      
      this.isLoading = true

      if (!this.userEmailFieldValidation()) {
        this.isLoading = false
        return
      }

      this.$api.get(`/users/checkEmail/${this.user.email}`)
      .then(({ data }) => {
        const { user_finded } = data

        if(!user_finded) {          
          this.$appToast({ type: 'info', message: `We don’t have this email. Let's go ahead and create your account:` })
          this.userEmailExist = false
          this.isRegistering = true
        } else {
          this.userEmailExist = true
        }

        this.$nextTick(() => {
          document.getElementById('signin-password').focus()
        })
      }).catch(err => {
        console.log(err)
      }).then(() => {        
        this.isLoading = false
      })

    }, // checkEmail

    userRegister() {

      this.isLoading = true 

      if (
        !this.userEmailFieldValidation() ||
        !this.userPasswordFieldValidation()
      ) {
        this.isLoading = false
        return
      }

      this.$api.post(`/users`, this.user )
        .then((response) => {
          const {token} = response.data
          this.$store.commit('setLoggedUserToken', {type: 'app', token})
          return this.$store.dispatch('getLoggedUser', {type: 'app'})          
        })
        .then(() => {
          this.$emit('register-success')
          this.$emit('success')
          this.$toast.success('Successfully registered and logged in')
        })
        .catch((err) => {
          if (err.response?.data?.error === 'Error: email (unique), ') {
            this.$toast.info('Well! Looks like you already have an account with us. Let\'s try to log you in.')
            this.isRegistering = false
            this.userEmailExist = true
            this.attemptLogin()
          }
        })
        .then(() => {
          this.isLoading = false
        })
    },  

    attemptLogin() {
      
      const { email, password } = this.user

      this.isLoading = true 

      if (
        !this.userEmailFieldValidation() ||
        !this.userPasswordFieldValidation()
      ) {
        this.isLoading = false
        return
      }
      
      this.$api.post(`/auth/attempt`, { email, password, type: 'user'}, { ignoreFeedback: true })
        .then((response) => {
          const {token} = response.data
          this.$store.commit('setLoggedUserToken', { type: 'user', token })
          return this.$store.dispatch('getLoggedUser')        
        })
        .then(() => {          
          this.$emit('loginSuccess')
          this.$emit('success')
          this.validationClear()
        })
        .catch((err) => {
          if(err.response && err.response.status == 401) {
            this.validation.password.hasError = true
            this.validation.password.label = 'Incorrect password'
            setTimeout(() => {
              document.getElementById('signin-password').focus()
            }, 200)
          }
        })

      this.isLoading = false

    },

    goToRecoverPassword() {
      this.$router.push({ name: 'UserPasswordRecover', query: { email: this.user.email ? this.user.email : ''}})
      this.$emit('close')
    }
    
  }
}
</script>

<style lang="scss" scoped>
  .usersign-form {
    width: 100%;
    max-width: 360px;
  }
</style>