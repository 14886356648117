const cache = {}
const CACHE_TYPES = {
  PROMISE: 'promise',
  VALUE: 'value'
}

function updateCache(key, type, value, expiration = undefined) {
  cache[key] = {
    type,
    value,
    expiration,
  }
}

export default async (key, asyncResolver, ttl = undefined) => {
  if (cache[key] && (!cache[key].expiration || cache[key].expiration >= new Date().valueOf())) {
    return (cache[key].type === CACHE_TYPES.PROMISE) ? (await cache[key].value) : cache[key].value
  }
  const asyncPromise = asyncResolver()
  const expiration = ttl ? new Date().valueOf() + (ttl * 1000) : undefined
  updateCache(key, CACHE_TYPES.PROMISE, asyncPromise, expiration)
  let result = undefined
  try {
    result = await asyncPromise
  } catch(e) {
    result = undefined
    delete cache[key]
  }
  updateCache(key, CACHE_TYPES.VALUE, result, expiration)
  return result
}

setInterval(() => {
  console.debug('Executing caching cleaner')
  Object.keys(cache).forEach((key) => {
    if (cache[key]?.expiration && cache[key]?.expiration < new Date().valueOf()) {
      delete cache[key]
      console.debug(`Cache key '${key}' cleaned!`)
    }
  })
  console.debug('Cache Cleaner finished')
}, 60000)