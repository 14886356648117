<template>
  <div     
    v-if="!hasVisitedReleaseAlertNewNonResidential2022Study"
    class="ml-1 bg-lime-500 w-6 h-6 rounded-sm flex items-center justify-center text-white cursor-pointer hover:opacity-80 transition-all"
    @click="onClick"
  >
    <feather
      type="gift"
      size="15"
    />
  </div>
</template>

<script>
const moduleName = 'release-alert-new-nonresidential-2022-study'
export default {
  name: 'ReleaseAlertNewNonResidential2022Study',
  computed: {
    hasVisitedReleaseAlertNewNonResidential2022Study() {
      return this.$store.getters.hasVisitedModule(moduleName)
    },
  },
  methods: {
    onClick() {
      this.$store.commit('setModuleVisited', moduleName)
      this.$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: 'release-alert-new-nonresidential-2022-study' })
    },    
  }
}
</script>

<style>

</style>