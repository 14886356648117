<template>
  <div
    v-if="maxTargetScore >= 0"
    v-tooltip="inputTooltip"
    class="w-full h-full flex items-center border border-transparent transition-all"
    :class="[
      getTargetScoreHasError ? 
        'bg-candy-200 border border-candy-500' : 
        'text-deepsky-900',
      inputFocus ? 'border-deepsky-450' : '',
      {'hover:border-deepsky-450' : !getTargetScoreHasError && userCanEditPolicy}
    ]"
  > 
    <input 
      ref="input" 
      v-model="targetScoreInput"
      v-mask="['#', '##', '###']"
      :min="1"
      type="tel" 
      class="w-12 bg-transparent text-header-2 h-full pl-4 z-10 placeholder-deepsky-450 target-score-input"
      :class="{'caret-candy-500 text-candy-500' : getTargetScoreHasError }"
      :disabled="!userCanEditPolicy"
      @change="checkTargetScore()"
      @input="onInputChange(type_vintage.id, climate_zone.id, prototype.id)"
      @focus="onInputFocus"
      @blur="onInputBlur"
      @keyup.enter="$refs.input.blur()"
    >
    <feather
      v-if="!inputFocus || (inputFocus && !getTargetScoreHasError)"
      :class="{ 'text-deepsky-450' : !getTargetScoreHasError, 'cursor-pointer' : userCanEditPolicy, 'invisible' : !userCanEditPolicy }"
      class="target-score-slider-icon text-transparent" 
      size="16"
      :type="!inputFocus ? 'sliders' : 'check'"
      @click.capture="onIconClick()"
    />
    <div
      v-if="isTargetScoreGreaterThanMaxTargetScore"
      v-tooltip="'This Target Score cannot be achieved cost-effectively based on study results'"
      class="highlight-icon"
    >
      <div class="icon" />
    </div>
  </div>
</template>

<script>
import { getMaxCostEffectiveScore, getMaxPossibleScore, getTierTargetScore, setTierTargetScore } from '@/services/measure-menu/MeasureMenuGeneralService'
import { defaultManualTooltipOptions } from '@/util/Helpers'
import debounce from 'lodash/debounce'
import FlexiblePathService from '@/services/api/FlexiblePathService'

export default {
  name: 'TargetScoreInput',
  props: [
    'policy',
    'prototype',
    'climate_zone',
    'type_vintage',
    'tier',
    'type_vintage_index',
    'flexible_path_setup',
    'max_target_scores_by_presets',
    'close_modal_without_changes'
  ],
  data() {
    return {
      targetScoreInput: 0,
      lastTargetScoreInput: [Number, String],
      inputFocus: false,
      inputTooltip: {
        ...defaultManualTooltipOptions,
        placement: 'left-center',
        show: false,
      },
      flexiblePathService: new FlexiblePathService({ policy_id : this.$route.params.policy_id }),
      typeVintageId: null,
      climateZoneId: null,
      prototypeId: null,
    }
  },
  computed: {
    getTargetScoreHasError() {
      return this.maxTargetScore && this.maxTargetScore > 0 && (!this.targetScoreInput || this.targetScoreInput < 1)
    },
    maxTargetScore() {
      return Math.min(this.maxCostEffectiveScore, this.maxPossibleScore)
    },
    maxCostEffectiveScore() {
      const { climate_zone, prototype, type_vintage, max_target_scores_by_presets, flexible_path_setup } = this
      return this.getMaxCostEffectiveScore({ climate_zone, prototype, type_vintage, max_target_scores_by_presets, flexible_path_setup })
    }, 
    maxPossibleScore() {
      const { climate_zone, type_vintage, max_target_scores_by_presets, type_vintage_index, prototype, flexible_path_setup } = this
      return this.getMaxPossibleScore({ climate_zone, type_vintage, max_target_scores_by_presets, type_vintage_index, prototype, flexible_path_setup })
    },
    shouldShowTooltip() {
      return Boolean(this.getTargetScoreHasError)
    },
    userCanEditPolicy() {
      return this.$store.getters['policy/getterUserCanEditPolicy'](this.policy.id)
    },
    isTargetScoreGreaterThanMaxTargetScore() {
      return this.targetScoreInput > this.maxTargetScore
    },
    getTierTargetScoreRaw(){
      return this.getTierTargetScore({ tier:this.tier, prototype:this.prototype, flexible_path_setup:this.flexible_path_setup, type_vintage:this.type_vintage, climate_zone:this.climate_zone, max_target_scores_by_presets:this.max_target_scores_by_presets })
    },
    isScoreAboveMaxAchievableAndBelowMaxCostEffective() {
      return this.targetScoreInput > this.maxPossibleScore && this.targetScoreInput < this.maxCostEffectiveScore
    },
    isScoreAboveMaxAchievableAndMaxCostEffectiveAndEqual() {
      return this.targetScoreInput > this.maxPossibleScore && this.targetScoreInput > this.maxCostEffectiveScore && this.maxCostEffectiveScore == this.maxPossibleScore
    },
    isScoreAboveMaxAchievableAndMaxCostEffectiveAndNotEqual() {
      return this.targetScoreInput > this.maxPossibleScore && this.targetScoreInput > this.maxCostEffectiveScore && this.maxCostEffectiveScore != this.maxPossibleScore
    },
  },
  watch: {
    getTierTargetScoreRaw(value){
      this.targetScoreInput = value
    },
    inputFocus(focus) {
      this.inputTooltip.show = Boolean(focus && this.shouldShowTooltip)
    },
    targetScoreInput(value) {
      this.toggleInputTooltip()
      // Small workaround to define if the number has been changed by the user or at created()
      const isUserChanges = typeof value === 'string'
      if (!isUserChanges) {
        this.lastTargetScoreInput = value
      } 
    },
    close_modal_without_changes(value) {
      if(value == true) {
        this.targetScoreInput = this.lastTargetScoreInput
      } else if (value == false) {
        this.targetScoreInput = this.getTierTargetScore({ tier: this.tier, prototype: this.prototype, flexible_path_setup: this.flexible_path_setup, type_vintage: this.type_vintage, climate_zone: this.climate_zone, max_target_scores_by_presets: this.max_target_scores_by_presets })
      }
    },
    // We will not auto-change target score anymore
    // maxTargetScore(cur, old) {
    //   this.reduceTargetScoreIfIsGreaterThanMax(cur, old)
    // }
  },
  created() {
    const { tier, type_vintage, prototype, flexible_path_setup, climate_zone, max_target_scores_by_presets } = this    
    const targetScoreInput = this.getTierTargetScore({ tier, prototype, flexible_path_setup, type_vintage, climate_zone, max_target_scores_by_presets })  
    this.targetScoreInput = targetScoreInput
    this.lastTargetScoreInput = targetScoreInput
  },
  beforeDestroy() {
    this.$eventBus.$off('openDebugVariablesModal')
  },
  methods: {
    getMaxCostEffectiveScore,
    getMaxPossibleScore,
    getTierTargetScore,
    setTierTargetScore,
    onInputBlur() {
      if (this.getTargetScoreHasError) {
        this.targetScoreInput = this.lastTargetScoreInput
      }
      // Workaround to onIconClick works
      setTimeout(() => {
        this.inputFocus = false
      },200)
    },
    onInputFocus() {
      this.inputFocus = true     
    },
    onIconClick() {
      !this.inputFocus ? this.$refs.input.focus() : this.$refs.input.blur()
    },
    checkTargetScore() {
      if(isNaN(this.targetScoreInput)) throw new Error(`Target score isn't a valid number: "${this.targetScoreInput}" typeof(${typeof(this.targetScoreInput)})`)
      if(this.getTargetScoreHasError) {
        this.targetScoreInput = this.lastTargetScoreInput
        return
      }
      if(this.isScoreAboveMaxAchievableAndBelowMaxCostEffective || this.isScoreAboveMaxAchievableAndMaxCostEffectiveAndEqual || this.isScoreAboveMaxAchievableAndMaxCostEffectiveAndNotEqual) {
        this.openModal()
        return
      }
      const { type_vintage, climate_zone, prototype, type_vintage_index, flexible_path_setup, targetScoreInput : value } = this
      this.setTierTargetScore({ type_vintage, climate_zone, prototype, type_vintage_index, flexible_path_setup, value })
      this.lastTargetScoreInput = this.targetScoreInput
    },
    // reduceTargetScoreIfIsGreaterThanMax(curValue, oldValue) {
    //   const maxTargetScoreHasDecreased = curValue < oldValue
    //   const targetScoreIsLowerThanMaxTargetScore = this.targetScoreInput > curValue
    //   if (maxTargetScoreHasDecreased && targetScoreIsLowerThanMaxTargetScore) {
    //     this.targetScoreInput = curValue
    //     const { type_vintage, climate_zone, prototype, type_vintage_index, flexible_path_setup, targetScoreInput : value } = this
    //     this.setTierTargetScore({ type_vintage, climate_zone, prototype, type_vintage_index, flexible_path_setup, value })
    //   }
    // },
    toggleInputTooltip() {
      if (this.maxTargetScore && this.maxTargetScore > 0 && (this.targetScoreInput == 0 || !this.targetScoreInput)) {
        this.inputTooltip.content = `Choose a value starting by 1`
        this.inputTooltip.show = true
      } else {
        this.inputTooltip.show = false
      }
    },
    openModal: debounce(function () {
      const targetScoreInput = this.targetScoreInput
      const maxCostEffectiveScore = this.maxCostEffectiveScore
      const maxPossibleScore = this.maxPossibleScore
      const typeVintageId = this.typeVintageId
      const climateZoneId = this.climateZoneId
      const prototypeId = this.prototypeId
      const typeVintage = this.type_vintage
      const lastTargetScoreInput = this.lastTargetScoreInput
      
      const payload = {
        targetScoreInput, 
        maxPossibleScore, 
        maxCostEffectiveScore,
        typeVintageId,
        climateZoneId,
        prototypeId,
        typeVintage,
        lastTargetScoreInput
      }
            
      this.$emit('openPolicyFlexiblePathScoreWarningModal', payload)        

    }, 400),
    onInputChange(typeVintageId, climateZoneId, prototypeId) {
      this.typeVintageId = typeVintageId
      this.climateZoneId = climateZoneId
      this.prototypeId = prototypeId
    },
  }
}
</script>

<style>

  .target-score-input {
    transition: all .5s;
  }
  
  .caret-candy-500 {
    caret-color:  #D65C5A !important;
  }

</style>

<style lang="scss" scoped>
  .highlight-icon {
    position: static;
    box-sizing: border-box;
    margin-left: 0.75rem;

    .icon {
      border-radius: 9999px;
      width: 0.5rem;
      height: 0.5rem;
      position: relative;
      background-color: #EDAB3E;        
      border: 1px solid #E0EFF6;

      &::before {
        border-radius: 9999px;
        position: absolute;
        transform-origin: center;
        content: '';
        z-index: -1;
      }
    }
  }

</style>
