<template>
  <div class="w-full pr-6">
    <PsDataTable 
      :header="getTableHeaders"
      :type="'rich'"
      align="right"
      class="psui-w-full psui-el-table-rich"
    >
      <tbody>
        <TypePrototypeFutureTableRow
          v-for="(climate_zone, climate_zone_index) in getClimateZoneSelected" 
          :key="`climate_zone_${climate_zone_index}`"
          :type_prototype="type_prototype"
          :breaking-down-by-construction-implementation-type-selected="breakingDownByConstructionImplementationTypeSelected"
          :breaking-down-by-building-height-selected="breakingDownByBuildingHeightSelected"
          :climate_zone_prefix="climate_zone.prefix"
        />
        <TypePrototypeFutureTableRow
          v-if="getClimateZoneSelected.length > 1"
          :type_prototype="type_prototype"
          :breaking-down-by-construction-implementation-type-selected="breakingDownByConstructionImplementationTypeSelected"
          :breaking-down-by-building-height-selected="breakingDownByBuildingHeightSelected"
          :climate_zone_prefix="null"
        />
      </tbody>
    </PsDataTable>
  </div>
</template>

<script>
import { BUILDING_ESTIMATES_BASE_YEARS } from '@/modules/app/assumptions/shared/enums'
import TypePrototypeFutureTableRow from './TypePrototypeFutureTableRow'
export default {
  name: 'TypePrototypeFutureTable',
  components:  { TypePrototypeFutureTableRow },
  props: [
    'getClimateZoneSelected',
    'type_prototype',
    'breakingDownByConstructionImplementationTypeSelected',
    'breakingDownByBuildingHeightSelected'
  ],
  data() {
    return {
      years: BUILDING_ESTIMATES_BASE_YEARS,
    }
  },
  computed: {
    getTableHeaders() {
      const headers = []
      headers.push(`Climate Zone`)
      this.years.forEach(year => headers.push(year))
      headers.push(`Total growth`)

      return headers
    }
  }
}
</script>