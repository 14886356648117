import GraphQL from '@/util/GraphQL'
import { STUDY_TYPES } from '@/util/Enums'
import CodeCycle from '@/models/CodeCycle'

export default {
  namespaced: true,
  
  state: {
    prototypes: []
  },

  getters: {
    getterGlobalPrototypes: (state) => (filters = null) => {
      return filters ? state.prototypes.findAllInArray(filters) : state.prototypes
    },

    getterGlobalPrototype: (state) => (filters = null) => {
      return filters ? state.prototypes.findInArray(filters) : false
    },
    
    getterProportionalTypePrototypesByPrototypeId: (state, getters) => prototype_id => {
      const prototype = getters.getterGlobalPrototype({ id: prototype_id })
      return prototype?.proportional_type_prototypes ?? []
    },
    
    getterPrototypeStudyTypeId: (state, getters, rootState, rootGetters) => ({ prototype_id }) => {      
      const study_id = getters['getterGlobalPrototype']({ id: prototype_id })?.study_id
      const study_group_id = rootGetters['globalEntities/Study/getterGlobalStudy']({ id: study_id })?.study_group_id
      const study_type_id = rootGetters['globalEntities/StudyGroup/getterGlobalStudyGroup']({ id: study_group_id })?.study_type_id
      return study_type_id
    },

    getterPrototypesByStudyId: (state)=> (filters) => {
      return state.prototypes.filter(pt => filters.includes(pt.study_id))
    },  

    getterPrototypeBuildings: (state, getters, rootState, rootGetters) => ({ prototype_id, filters, list }) => {
      const study_type_id = getters['getterPrototypeStudyTypeId']({ prototype_id })
      const prototype = getters['getterGlobalPrototype']({ id: prototype_id })
      const type_prototype_id = rootGetters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id: prototype?.type_prototype_id })?.id
      const study = rootGetters['globalEntities/Study/getterGlobalStudy']({ id: prototype.study_id })
      const code_cycle = new CodeCycle(rootGetters['globalEntities/CodeCycle/getterGlobalCodeCycle']({ id: study.code_cycle_id }))
      if (study_type_id === STUDY_TYPES.EXISTING_BUILDINGS) {
        return rootGetters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({ ...filters, type_prototype_id })
      } else if (study_type_id === STUDY_TYPES.NEW_BUILDINGS && code_cycle.isCurrent) {
        if (!filters.years) filters.years = code_cycle.years
        return rootGetters['assumptions/buildingEstimates/gettterBuldingEstimateByPrototype']({ filters, prototype_id, list })
      }
      return null
    },

    getterPrototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt: (state, getters, rootState, rootGetters) => ({ type_prototype, study_group }) => {

      const studies = rootGetters['globalEntities/Study/getterGlobalStudies']({ study_group_id: study_group.id })
      const studiesIds = studies.map((study) => study.id)
      const prototypes = state.prototypes.findAllInArray({ type_prototype_id: type_prototype.id, study_id: studiesIds })

      const isAdmin = rootGetters['getterLoggedUser'] && rootGetters['getterLoggedUserType'] == 'admin'
      return prototypes.map((prototype) => {
        const study = rootGetters['globalEntities/Study/getterGlobalStudy']({ id: prototype.study_id })
        return {
          ...prototype,
          study
        }
      })
      .filter(prototype => prototype.study.is_private && !isAdmin ? false : true)
      .sort((a, b) => new Date(b.study.released_at) - new Date(a.study.released_at))
    }

  },

  actions: {
    loadGlobalPrototypes(context) {
      const query = `
        {
          prototypes (
            orderBy: [{ column: "order", order: "ASC" }]
          ) {
            id
            title
            slug
            type_prototype_id
            study_id
            fuel_switch_package_measure_id
            impact_algorithm_results
            impact_algorithm_policy_impact
            impact_algorithm_policy_flexible_path
            policy_option_algorithms
            allow_policy_creation
            show_by_default_in_results
            study {
              id
              study_group_id
            }
            proportional_type_prototypes {
              id
              title
              slug
              pivot {
                percentage
                is_using_fallback_proportional_units
              }
            }
          }
        }
      `
      GraphQL({ query })
        .then(({ data }) => {
          context.commit('setGlobalPrototypes', { prototypes: data.prototypes})
        })
    }
  },

  mutations: {
    setGlobalPrototypes(state, { prototypes }) {
      state.prototypes = prototypes
    }
  }
}
