<template>
  <div class="card psui-flex psui-flex-col psui-shadow-elevation-5 psui-bg-white psui-rounded-lg psui-w-full xl:psui-w-1/3">
    <div class="card-header psui-px-5 2xl:psui-px-8 psui-py-6 psui-space-y-4 sm:psui-space-y-0 xl:psui-space-y-4 psui-flex psui-items-start">
      <img 
        src="images/home_page/home-making-policies.png" 
        alt="Build your own policy from scratch" 
        class="flex-shrink-0"
      >

      <div class="content psui-flex-grow xl:psui-flex-grow-0 psui-space-y-4">
        <h2 class="psui-text-h4 psui-text-blue-80 psui-font-bold">
          Build your own policy from scratch
        </h2>

        <p class="psui-text-p psui-text-gray-60">
          Start with a blank slate and choose policy requirements to fit your goals.
        </p>
      </div>
    </div>

    <div class="card-footer psui-flex psui-flex-row psui-border-t psui-border-gray-20 psui-px-5 2xl:psui-px-8 psui-py-4 psui-mt-auto">
      <PsButton
        label="Draft your policy"
        layout="ghost"
        size="medium"
        @click="$eventBus.$emit('openModalBuildPolicyAndJurisdictionSelection')"
      />
      <PsButton
        label="Learn More"
        layout="onlytext"
        size="medium"
        @click="$eventBus.$emit('openMainBenefitsModal', { type: 'helper', slug: 'home-page-creating-your-own-policy' })"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeMainBenefitsPolicyFromScratch',
}
</script>

<style lang="scss" scoped>
  .card {
    &-header {
      flex-direction: column;

      @media (min-width: 640px) {
        flex-direction: row;

        .content {
          margin-left: 1.5rem;
        }
      }
      
      @media (min-width: 1200px) {
        flex-direction: column;
        .content {
          margin-left: 0;
        }
      }
      
      img {
        max-height: 90.45px;
      }
    }

    &-footer {
      flex-direction: column;
      align-items: flex-start;

      button,
      a {
        &:not(:last-child) {
          margin-bottom: 0.5rem;

          @media (min-width: 432px) {
            margin-bottom: 0;
            margin-right: 0.5rem;
          }
        }
      }

      @media (min-width: 432px) {
        align-items: center;
        flex-direction: row;
      }
    }
  }
</style>
