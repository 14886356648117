<template>
  <div
    ref="policyShow"
    class="w-full psui-bg-gray-10 psui-px-8"
  >
    <PolicyImpact 
      v-if="getActiveTab == 'impact'" 
      :policy="getPolicySelected"
      :is-loading="isLoading"
      :policy_summary_data="policy_summary_data"
      :policy_impact_breakdown="impactBreakdownData"
      :custom_chart_title="chartTitle"
      :is_flexible_set_up="getPolicyHasFlexiblePathSetUp"
      @open-assumptions-applicability-rate="onOpenAssumptionsSidebar({ showCardOnInit: 'applicability_rate' })"
      @updateSummaryId="setPolicySummaryDataById($event)"
      @buildingEstimatesChanged="buildingEstimatesHasChanged"
    />
    <PolicyDocuments
      v-if="getActiveTab == 'documents'"
      :key="$route.fullPath"
      :policy="getPolicySelected"
      :flexible_path_setup="flexible_path_setup"
    />
    
    <PolicyFlexiblePath 
      v-if="getActiveTab == 'flexible_path'"
      :key="$route.fullPath"
      :policy="getPolicySelected"
    />
    
    <PolicyRequirements 
      v-if="getActiveTab == 'requirements'"
      :key="$route.fullPath"
      :flexible_path_setup="flexible_path_setup"
      :policy="getPolicySelected"
    />
    <ModalDeleteCustomCombination />
    <!-- HELPER NAVIGATE THROUGH POLICIES ONLY DEVELOPMENT-->
    <div 
      v-check-env="'development'" 
      class="psui-w-full psui-h-16"
    />
    <div
      v-check-env="'development'"
      class="psui-w-auto psui-absolute psui-bottom-0 psui-flex psui-justify-start psui-pb-4 z-60"
    >
      <div class="bg-white rounded shadow p-4 flex flex-row items-center justify-center">
        <h4 class="psui-mr-4">
          Navigate through policies
        </h4>
        <div class="flex space-x-2">
          <PsButton 
            size="small"
            icon="chevron_left"
            @click="navigatePolicies('sub')" 
          />
          <PsButton 
            size="small"
            icon="chevron_right"
            @click="navigatePolicies('add')" 
          />
        </div>
      </div>
    </div>
    <BaseModal
      ref="confirmRequirementsRemovalModal"
      modal-dialog-classes="w-full max-w-sm"
      :disable-close="true"
    >
      <h1 class="font-size-title font-bold psui-text-gray-80 psui-mb-6">
        If you remove these requirements your policy will have no impact. Do you want to continue?
      </h1>
      <div class="flex psui-flex-wrap">
        <PsButton
          label="Yes"
          size="medium"
          layout="solid"
          icon-position="left"
          class="mr-2"
          @click="confirmDeleteCustomCombinationsByClimateZone"
        />
        <PsButton
          label="No"
          size="medium"
          layout="onlytext"
          @click="closeConfirmRemovalModal"
        />
      </div>
    </BaseModal>
    <PolicySingleFamilyMigrationModal />
  </div>
</template>

<script>
import PolicyImpact from './impact/PolicyImpact'
import PolicyFlexiblePath from './flexible-path/PolicyFlexiblePath'
import PolicyRequirements from './requirements/PolicyRequirements'
import PolicyDocuments from './documents/PolicyDocuments.vue'
import ModalDeleteCustomCombination from '@/modules/app/policy/show/shared/ModalDeleteCustomCombination'
import {PolicyImpactAlgorithmFactory} from '@/business-logic/services/impact-algorithms/policy/factory'
import {FUEL_CHOICES} from '@/business-logic/services/impact-algorithms'
import {
  CITY_WIDE_IMPACT_COLUMNS_KEYS,
  COST_RATIOS_COLUMNS_KEYS,
  PER_HOME_IMPACT_COLUMNS_KEYS
} from '@/business-logic/services/impact-algorithms/policy'
import {forecast_initial_cost, forecast_units,} from '@/modules/app/shared/default-app-columns'
import CustomCombinationApiService from '@/services/api/CustomCombinationApiService'
import PolicyEditRequirementsNewBuildingsSF2022
  from '@/modules/app/policy/show/requirements/requirements-drawer/PolicyEditRequirementsNewBuildingsSF2022/index.vue'
import {checkDeepObjectChanges} from '@/util/checkDeepObjectChanges'
import {checkIfPolicyIsOutdated} from '@/util/Helpers'
import {STUDY_TYPES_DB_SLUGS, TYPE_PROTOTYPES_DB_SLUGS} from '@/util/Enums.js'
import PolicySingleFamilyMigrationModal
  from '@/modules/app/policy/show/requirements/PolicySingleFamilyMigrationModal.vue'
import {mapGetters} from 'vuex'
import {TYPE_FUELS_DB_SLUGS} from '@/util/Enums'
import CustomCombination from '@/models/CustomCombination'
import {ASSUMPTIONS_PATHS} from '@/modules/app/assumptions/shared/enums.js'
import FlexiblePathService from '@/services/api/FlexiblePathService'
import {getIfPoliciesHasAnyMeasureConflict} from '@/util/Helpers.js'
import PolicyImpactsTableContentGenerationMixin
  from '@/modules/app/policy/shared/PolicyImpactsTableContentGenerationMixin'
// import dayjs from 'dayjs'

export default {
  name: 'PolicyShowIndex',
  components: {
    PolicyImpact,
    PolicyFlexiblePath,
    PolicyRequirements,
    ModalDeleteCustomCombination,
    PolicyDocuments,
    PolicySingleFamilyMigrationModal
  },
  mixins: [PolicyImpactsTableContentGenerationMixin],
  metaInfo() {
    const getPageTitle = this.getPageTitle
    return {
      title() {
        return getPageTitle
      }
    }
  },
  data() {
    return {
      isLoading: false,
      impactBreakdownData: null,
      allPolicySummaries: null,
      indexedSummaries: {},
      summaryDebouncer: null,
      chartTitle: null,
      summaryDataRow: {
        type: [ Array, Object ]
      },
      currentPolicyState: null,
      policy_summary_data: [],
      flexible_path_setup: null,
      flexiblePathService: null,
    }
  },
  computed: {
    getActiveTab() {
      if(!this.$route.params.policy_active_tab) return 'requirements'
      return this.$route.params.policy_active_tab
    },
    hasVisitedFlexiblePaths() {
      return this.$store.getters.hasVisitedModule('flexible_paths')
    },
    getLastJurisdictionVisited() {
      return this.$store.getters['lastJurisdictionVisited'] ?? false
    },
    getPageTitle() {
      if(!this.getLastJurisdictionVisited) return `Policy`
      if(this.policy) return `Policy ${this.policy.title} for ${this.getLastJurisdictionVisited.title_type} | `
      return `Policy for ${this.getLastJurisdictionVisited.title_type} | `
    },
    userCanEdit() {
      return this.$store.getters['policy/getterUserCanEditPolicy'](this.getPolicySelected.id)
    },
    getCustomBuildingEstimateByPolicyId(){
      return this.$store.getters['assumptions/buildingEstimates/getterDefaultJurisdictionBuildingEstimates']({type_prototype_id: this.getPolicySelected?.policy_containers[0]?.type_prototype_id})
    },
    getPolicyCustomCombinations() {
      const policy_id = this.$route.params.policy_id
      if (!policy_id) return []
      return this.$store.getters['policy/getterPolicyCustomCombinations'](policy_id) 
    },
    getGlobalPrototypes() {
      return this.$store.getters['globalEntities/Prototype/getterGlobalPrototypes']()
    },
    getIfNewBuildingPolicyHasNotAduAndHasBeenCreatedBeforeDate(){
      return this.getPolicyStudyType === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS && new Date(this.getPolicyByRoute.created_at) <= new Date('2023-01-24') && this.userCanEdit && !this.getIfPolicyHasAduContainer && !this.getIfUserHasvisitedModuleByPolicyId
    },
    getIfPolicyHasAduContainer(){
      return this.getPolicyByRoute.policy_containers.some((container)=>{
        if(this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({id:container.type_prototype_id}).slug == TYPE_PROTOTYPES_DB_SLUGS.ADU ){
          return true
        }
        return false
      })
    },
    getAduTypePrototype(){
      return this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({slug:TYPE_PROTOTYPES_DB_SLUGS.ADU})
    },
    getPolicyByRoute(){
      return this.$store.getters['policy/getterUserPolicy']({ id: this.$route.params.policy_id })
    },
    getIfUserHasvisitedModuleByPolicyId(){
      return this.hasVisitedModule(`policy_migration_modal_id_${this.$route.params.policy_id}`)
    },
    policyHasPrototypeOutdated() {
      const outdatedInfo = checkIfPolicyIsOutdated(this.getPolicySelected)
      return outdatedInfo.has_outdated_studies === true && outdatedInfo.is_loading === false
    },
    blockOutdatedPolicy() {
      const outdatedInfo = checkIfPolicyIsOutdated(this.getPolicySelected)
      return outdatedInfo.block_policy
    },
    getAllBuildingEstimates(){
      return this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits']({ policy_id: this.$route.params.policy_id })
    },
    getUserCanEditPolicy(){
      return this.$store.getters['policy/getterUserCanEditPolicy'](this.$route.params.policy_id)
    },
    getPolicyHasFlexiblePathSetUp() {
      return Boolean(this.flexible_path_setup?.tiers?.some((tier) => {
        return tier?.excluded_measures?.length || tier?.target_scores?.some((ts) => ts?.value != null && ts?.value > 0)
      }))
    },
    getIfPolicyHasAnyClashBetweeenCCMeasuresAndFlexibleMeasures() {
      return this.getPolicyCustomCombinations.some( cc => {
        return cc.measures.some( measure => this.$store.getters['globalEntities/Measure/getterGlobalMeasure']({id:measure.id}).hide_in_flexible_path)
      })
    },
    ...mapGetters([
    'hasVisitedModule'
    ])
  },
  watch: {
    '$route.params.policy_id': {
      immediate: true,
      handler(policy_id) {
        this.getPolicy()
        if(policy_id === this.getPolicySelected?.id) return
        this.$forceUpdate()
        this.openOutdatedPolicyModalAndApplyBlurToBackground()
        this.$store.commit('assumptions/setPolicyIdSelected', this.$route.params.policy_id)
      }
    },
    'getPolicySelected': {
      immediate: true,
      deep: true,
      async handler(policy, oldPolicy) {
        if (policy?.id && policy?.id !== oldPolicy?.id) {
          this.flexiblePathService = new FlexiblePathService({ policy_id : policy?.id })
          this.flexible_path_setup = await this.flexiblePathService.getSetup()

          if (this.getUserCanEditPolicy && this.getPolicyStudyType === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS ) {
            let hasConflict = await Promise.all(this.getPolicySelected?.policy_containers?.map(async container => {
              return await Promise.all(container.custom_combinations.map(async (cc) => {
                const type_vintage_id = cc.vintage.type_vintage_id
                const climate_zone = this.$store.getters['globalEntities/ClimateZone/getterGlobalClimateZone']({raw:cc.climate_zone_raw})
                const prototype_id = cc.prototype_id
                return await getIfPoliciesHasAnyMeasureConflict({
                  prototype_id,
                  climate_zone,
                  type_vintage_id,
                  measures: cc.measures,
                  setup: this.flexible_path_setup
                })
              }))
            }))

            hasConflict = hasConflict?.flat(Infinity)

            if(hasConflict?.some(isValid => isValid)) {
              this.$eventBus.$emit('openModalFlexibleRequirements', {
                policy:this.getPolicySelected,
                flexible_path_setup: this.flexible_path_setup
              })
            } 
          } else if(this.getIfNewBuildingPolicyHasNotAduAndHasBeenCreatedBeforeDate) {
            this.$eventBus.$emit('openPolicySingleFamilyMigrationModal',
            {
              policy: this.getPolicyByRoute,
              policy_id:this.getPolicyByRoute.id,
              study_type_id: this.$store.getters['globalEntities/StudyType/getterGlobalStudyType']({slug:this.getPolicyStudyType}).id,
              type_prototype_id: this.getAduTypePrototype.id,
              type_prototype_order: this.getAduTypePrototype.order
            })
          } 
        }


        if(this.checkSkipReload(this.currentPolicyState, policy)) return
        this.currentPolicyState = policy
        await this.generatePolicyItems()
      }
    },
    'getGlobalPrototypes': {
      immediate: true,
      handler: function () {
        this.$forceUpdate()
        this.openOutdatedPolicyModalAndApplyBlurToBackground()      
      },
    },
  },
  async mounted() {
    this.logUserEvent()
    this.getPolicy()
    this.$eventBus.$on('setPolicyIsLoading', (isLoading) => {
      this.isLoading = isLoading
    })
    this.$eventBus.$on('FlexPathUpdated', async () => {
      this.flexible_path_setup = await this.flexiblePathService.getSetup()
    })
    this.$eventBus.$on('startSavingCustomCombinations', (ccIds) => {
      const isUpdatingSomePolicyContainer = this.getPolicySelected?.policy_containers?.some((container) => {
        return container?.custom_combinations?.some((cc) => ccIds?.includes(+cc.id))
      })
      if (isUpdatingSomePolicyContainer) {
        this.isLoading = true
      }
    })

    this.$eventBus.$on('getPolicy', () => {
      this.getPolicy()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('setPolicyIsLoading')
    this.$eventBus.$off('FlexPathUpdated')
    this.$eventBus.$off('startSavingCustomCombinations')
    this.$eventBus.$off('getPolicy')
  },
  updated() {
    if(this.$refs.flexiblePathNewModuleAlert) this.$refs.flexiblePathNewModuleAlert.open()
  },
  methods: {

    checkSkipReload(oldPolicyState, newPolicyState) {
      if (!oldPolicyState || !newPolicyState) {
        return false
      }
      return !checkDeepObjectChanges(oldPolicyState, newPolicyState)?.length
    },
    // ToDO: Move it to other place like policy algorithms? as its a impact generation of some policy and that can be computed in so many places, its not good to have it here...
    async generateNewBuildingPolicyItems() {
      if (!this.getPolicySelected) {
        return
      }
      this.isLoading = true
      this.indexedSummaries = {}
      const totalImpactId = 'policy'
      const policyContainers = Array.isArray(this.getPolicySelected?.policy_containers) ? this.getPolicySelected.policy_containers : []
      const policyContainersSummaryData = []
      for await (const policyContainer of policyContainers) {
        const containerTitle = `New ${this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id: policyContainer?.type_prototype_id }).title}`
        const helperSlug = `type-prototype-${this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id: policyContainer?.type_prototype_id }).slug}`
        if (!containerTitle) {
          continue
        }
        const containerCustomCombinations = Array.isArray(policyContainer.custom_combinations) ? policyContainer.custom_combinations : []
        let containerCustomCombinationsByClimateZones = containerCustomCombinations.reduce(
            (acc, curr) => {
              let climateZone = acc.find(el => el.climate_zone_raw === curr.climate_zone_raw)
              if(!climateZone) {
                climateZone = {climate_zone_raw: curr.climate_zone_raw, custom_combinations:[]}
                acc.push(climateZone)
              }
              climateZone.custom_combinations.push(curr)
              return acc
            },[])

        // Compute here the climateZones summary data based on Policy Algorithm
        const containerSummaryDatas = []
        for await (const climateZoneInfo of containerCustomCombinationsByClimateZones) {
          climateZoneInfo.policyImpactAlgorithm = PolicyImpactAlgorithmFactory.createByCustomCombinations(climateZoneInfo.custom_combinations, this.getPolicySelected)
          if (!climateZoneInfo.policyImpactAlgorithm) {
            continue
          }
          climateZoneInfo.fuelsSummaryDataByYear = await climateZoneInfo.policyImpactAlgorithm.computeYearlyCityWideImpact()
          climateZoneInfo.fuelsSummaryDataByYear.forEach((fuelSummary) => {
            const fuelSummaryId = `${totalImpactId}:container-${policyContainer.id}:climateZone-${climateZoneInfo.climate_zone_raw}:fuel-${fuelSummary.fuel_slug}`

            const { [fuelSummary.fuel_slug]: customCombination } = climateZoneInfo.policyImpactAlgorithm.getCustomCombinationsByFuel()
            const customCombinationMetaInfo = climateZoneInfo.policyImpactAlgorithm.getCustomCombinationMetaInfo(customCombination)
            const complianceMargin = isNaN(customCombinationMetaInfo.minComplianceMargin) ? 0 : Number(customCombinationMetaInfo.minComplianceMargin)
            fuelSummary.isOnDefaultState = customCombinationMetaInfo.isOnDefaultState
            fuelSummary.disableCostEffectiveAverage = Boolean(complianceMargin === 0 && !customCombinationMetaInfo.isOnDefaultState)
            if (customCombinationMetaInfo.fuelChoice === FUEL_CHOICES.NOT_ALLOWED) {
              fuelSummary.disabled = true
              fuelSummary.disabledText = `Your policy does not allow ${fuelSummary?.fuel_slug} construction for ${containerTitle}`
            }

            fuelSummary.id = fuelSummaryId
            fuelSummary.helper_slug = `type-fuel-${fuelSummary.fuel_slug}`
            this.indexedSummaries[fuelSummaryId] = fuelSummary
          })

          const containerSummaryId = `${totalImpactId}:container-${policyContainer.id}:climateZone-${climateZoneInfo.climate_zone_raw}`
          climateZoneInfo.summaryDataByYear = this.sumSummaryYears(climateZoneInfo.fuelsSummaryDataByYear, {
            title: `Climate Zone ${this.$store.getters['globalEntities/ClimateZone/getterGlobalClimateZone']({ raw: climateZoneInfo.climate_zone_raw }).prefix}`,
            id: containerSummaryId,
            climate_zone_raw: climateZoneInfo.climate_zone_raw,
          })
          this.indexedSummaries[containerSummaryId] = climateZoneInfo.summaryDataByYear

          containerSummaryDatas.push(climateZoneInfo.summaryDataByYear)
        }

        // This will create empty climate zones:
        if (containerCustomCombinationsByClimateZones.length !== this.jurisdictionClimateZonesCount) {
          const usedRaws = containerCustomCombinationsByClimateZones.map((c) => c.climate_zone_raw)
          const notUsedClimateZones = this.getPolicySelected?.jurisdiction?.climate_zones.filter((c) => {
            return !usedRaws.includes(c.raw)
          }).map((c) => {
            return {
              climate_zone_raw: c.raw,
              custom_combinations: [],
              fuelsSummaryDataByYear: [],
              policyImpactAlgorithm: null,
              summaryDataByYear: {
                title: `Climate Zone ${c.prefix}`,
                id: `${totalImpactId}:container-${policyContainer.id}:climateZone-${c.raw}`,
                climate_zone_raw: c.raw,
                years: [],
                per_home_data: {},
                disabled: true,
                disabledText: `Climate Zone ${c.prefix} has no requirements added for ${containerTitle}`,
                policyContainer: policyContainer
              }
            }
          })

          containerCustomCombinationsByClimateZones = [...containerCustomCombinationsByClimateZones, ...notUsedClimateZones]
        }

        containerCustomCombinationsByClimateZones = containerCustomCombinationsByClimateZones
            .sort((a,b) => Number(a?.climate_zone_raw?.split('-')?.shift() || 0) - Number(b?.climate_zone_raw?.split('-')?.shift() || 0))

        const containerSummaryId = `${totalImpactId}:container-${policyContainer.id}`
        const containerSummaryData = this.sumSummaryYears(containerSummaryDatas, {
          title: containerTitle,
          id: containerSummaryId,
          helper_slug: helperSlug
        })
        this.indexedSummaries[containerSummaryId] = containerSummaryData
        policyContainersSummaryData.push({
          policyContainer,
          climateZonesSummaryData: containerCustomCombinationsByClimateZones,
          summaryDataByYear: containerSummaryData
        })
      }

      this.allPolicySummaries = {
        containersSummaryData: policyContainersSummaryData,
        summaryDataByYear: this.sumSummaryYears(policyContainersSummaryData.map((i) => i.summaryDataByYear), {
          title: 'Total Impact',
          id: totalImpactId
        })
      }
      this.indexedSummaries[totalImpactId] = this.allPolicySummaries.summaryDataByYear
      this.setPolicySummaryDataById(totalImpactId)
      this.impactBreakdownData = this.generateNewBuildingImpactBreakdownTableInfo()
      this.isLoading = false
    },

    setPolicySummaryDataById(id) {
      if (!this.indexedSummaries?.[id]) {
        return
      }
      this.policy_summary_data = {
        data: this.indexedSummaries[id].years,
        building_estimates: this.getCustomBuildingEstimateByPolicyId,
        building_stock_units: this.getBuildingStocksUnits({ type_prototype_id: this.getPolicySelected?.policy_containers[0]?.type_prototype_id }),
        studyData: this.getResumedImpactsFromTableRowInfo(this.indexedSummaries[id].years)
      }
      // Update chart title based on context
      const deepIdSize = id.split(':')?.length || 1
      const titles = this.getTitlesFromImpactsById(this.indexedSummaries, id)
      this.chartTitle = null
      if (deepIdSize > 1) {
        this.chartTitle = [
          {
            title : `Total ${this.getPolicySelected.jurisdiction.type}-wide Impact`,
            isReset : true,
            id: this.TOTAL_IMPACT_ID
          },
        ]

        const orderedTitles = ['prototype', 'climateZone', 'fuel', 'vintage', 'measure']
        orderedTitles.forEach((titleType) => {
          if (titles[titleType]) {
            this.chartTitle.push({ title : titles[titleType]})
          }
        })
      }
    },

    sumSummaryYears(summariesWithYears, commonObjData) {
      // Disables rows with default state
      const defaultStateSummaries = summariesWithYears?.filter((x) => x?.isOnDefaultState === true && (x?.isDefaultStateByHerritage !== true || x?.isAllInDefaultState === true))
      if (summariesWithYears?.find((x) => x?.isOnDefaultState === true)) {
        commonObjData.isOnDefaultState = true
        commonObjData.isDefaultStateByHerritage = true
        commonObjData.isAllInDefaultState = Boolean(defaultStateSummaries.length === summariesWithYears.length)
      }
      const disableCostEffectiveAverage = Boolean(summariesWithYears?.filter((x) => x?.disableCostEffectiveAverage === true)?.length)
      if (disableCostEffectiveAverage) {
        commonObjData.disableCostEffectiveAverage = true
      }
      const useSameCostEffectiveOnNewSummary = Boolean(summariesWithYears?.filter((x) => x?.disableCostEffectiveAverage === false)?.length === 1 || summariesWithYears?.length === 1)

      // Compute city/county-wide columns
      const result = summariesWithYears.reduce((acc, curr) => {
        if ((curr?.isOnDefaultState === true && curr?.isDefaultStateByHerritage !== true)) {
          return acc
        }

        curr.years.forEach((currYear) => {
          let yearInfo = acc.years.find((y) => y.year === currYear.year)
          if (!yearInfo) {
            yearInfo = { year: currYear.year }
            acc.years.push(yearInfo)
          }
          CITY_WIDE_IMPACT_COLUMNS_KEYS.forEach((key) => {
            if (!yearInfo[key] && !currYear[key]) {
              return
            }
            if (!yearInfo[key]) {
              yearInfo[key] = {
                cummulative: 0,
                current: 0
              }
            }
            const currValAccumulative = !isNaN(yearInfo?.[key]?.cummulative) ? Number(yearInfo[key].cummulative) : 0
            const currValCurrent = !isNaN(yearInfo?.[key]?.current) ? Number(yearInfo[key].current) : 0
            const newValAccumulative = !isNaN(currYear?.[key]?.cummulative) ? Number(currYear[key].cummulative) : 0
            const newValCurrent = !isNaN(currYear?.[key]?.current) ? Number(currYear[key].current) : 0
            yearInfo[key].cummulative = currValAccumulative + newValAccumulative
            yearInfo[key].current = currValCurrent + newValCurrent
          })
        })
        return acc
      }, { ...commonObjData, years: [] })

      // Now compute per-home and cost-effective columns
      const getUnitiesFromIdx = (idx) => (summariesWithYears?.[idx].isOnDefaultState === true && summariesWithYears?.[idx].isDefaultStateByHerritage !== true) ? 0 :
          (summariesWithYears?.[idx]?.years?.slice(0)?.pop()?.[forecast_units()?.key]?.cummulative || 0)
      const getComplianceCostFromIdx = (idx) => (summariesWithYears?.[idx].isOnDefaultState === true && summariesWithYears?.[idx].isDefaultStateByHerritage !== true) ? 0 :
          Math.abs(summariesWithYears?.[idx]?.years?.slice(0)?.pop()?.[forecast_initial_cost()?.key]?.cummulative || 0)
      const totalUnities = summariesWithYears.map((s, idx) => {
        return getUnitiesFromIdx(idx)
      }).reduce((acc, curr) => acc + curr, 0)
      const totalComplianceCost = summariesWithYears.map((s, idx) => {
        return getComplianceCostFromIdx(idx)
      }).reduce((acc, curr) => acc + curr, 0)
      result.per_home_data = summariesWithYears.map((s) => s.per_home_data).reduce((acc, curr, idx) => {
        const affectedUnities = getUnitiesFromIdx(idx)
        const complianceCost = getComplianceCostFromIdx(idx)
        Object.keys(curr).forEach((key) => {
          if (!acc[key]) {
            acc[key] = 0
          }

          if (useSameCostEffectiveOnNewSummary && !isNaN(Number(curr[key])) && Number(curr[key]) !== 0) {
            acc[key] = Number(curr[key])
          }

          if (useSameCostEffectiveOnNewSummary && acc[key]) {
            return
          }

          if (key === 'baseline_fuel_type') {
            acc[key] = curr[key]
          } else if (PER_HOME_IMPACT_COLUMNS_KEYS.includes(key)) {
            acc[key] += (Number(curr[key] || 0) * affectedUnities) / (totalUnities || 1)
          } else if (COST_RATIOS_COLUMNS_KEYS.includes(key)) {
            if ((result.isOnDefaultState === true && result.isDefaultStateByHerritage === true) ||
                result.disableCostEffectiveAverage === true)
            {
              acc[key] = '--'
            } else {
              acc[key] += (Number(curr[key] || 0) * complianceCost) / (totalComplianceCost || 1)
            }
          }
        })
        return acc
      }, {})
      return result
    },

    generateNewBuildingImpactBreakdownTableInfo(current=null, result = [], deepLevel = 0, brothersCount = null) {
      if (!current) {
        current = this.allPolicySummaries
      }

      const deepKeys = ['containersSummaryData', 'climateZonesSummaryData', 'fuelsSummaryDataByYear']
      const hasDeep = Object.keys(current).some((key) => deepKeys.includes(key))
      const summaryData = current.years && current.id && current.title ? current : current?.summaryDataByYear
      if (summaryData) {
        const skipLevel = Boolean(brothersCount === 1 &&
            summaryData?.title?.startsWith('Climate Zone') &&
            this.jurisdictionClimateZonesCount === 1
        )
        const createDeeperItems = (nextLevelResultList, nextDeepLevel = deepLevel + 1) => {
          deepKeys.forEach((key) => {
            if (current[key]) {
              const nextLevelBrothersCount = current?.[key]?.length || 0
              current[key].forEach((deepItem) => {
                this.generateNewBuildingImpactBreakdownTableInfo(deepItem, nextLevelResultList, nextDeepLevel, nextLevelBrothersCount)
              })
            }
          })
        }

        if (skipLevel) {
          createDeeperItems(result, deepLevel)
          return result
        }
        const disabledByInFuelDefaultState = Boolean(summaryData.isOnDefaultState === true && summaryData.isDefaultStateByHerritage !== true)
        const disabledByAllInDefaultState = Boolean(summaryData.isAllInDefaultState === true)
        let disabledText = summaryData?.disabledText || null
        if (disabledByInFuelDefaultState) {
          const climateZoneTitle = this.indexedSummaries?.[summaryData.id.split(':', 3).join(':')]?.title
          const prototypeTitle = this.indexedSummaries?.[summaryData.id.split(':', 2).join(':')]?.title
          disabledText = `${summaryData?.title} requirements of ${prototypeTitle} on ${climateZoneTitle} is in default state`
        } else if (disabledByAllInDefaultState) {
          const title = summaryData?.id?.split(':')?.length === 1 ? 'policy' : `of ${summaryData?.title}`
          disabledText = `All ${title} requirements are in default state`
        }
        const itemData = this.getResumedImpactsFromTableRowInfo(summaryData.years, summaryData.per_home_data)

        const is_disabled = summaryData?.disabled === true || !Object.keys(itemData).some((key) => key.startsWith('forecast')) ||
            disabledByInFuelDefaultState || disabledByAllInDefaultState
        let actions = []
        const deepIdSize = summaryData?.id.split(':')?.length || 1
        if (deepIdSize === 3 && summaryData?.disabled && !summaryData?.years?.length) {
          actions = [
            {
              icon: 'add_circle_outline',
              title: 'Add requirements',
              text: 'Add requirements',
              callback: () => {
                this.addRequirements(summaryData)
              }
            }
          ]
        } else if (deepIdSize > 2) {
          actions = [
            {
              icon: 'add_circle_outline',
              title: 'Edit requirements',
              text: 'Edit requirements',
              callback: (item) => {
                this.openRequirementsDrawer(item)
              }
            },
            {
              icon: 'delete_outline',
              title: 'Remove requirements',
              text: 'Remove prototype from your policy',
              callback: (item) => {
                this.deleteCustomCombinationsByClimateZone(item)
              }
            },

          ]
        }

        if (!this.getPolicyByRoute.policy_containers) return 
        
        const getTypePrototypeFromContainerNumber = this.getPolicyByRoute
        .policy_containers
        .find(container => container.id == this.retrieveContainerNumberFromString(summaryData.id))

        const getBuildingEstimates = this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits']({ type_prototype_id: getTypePrototypeFromContainerNumber?.type_prototype_id })        
        let has_no_building_estimates_text

        if(getBuildingEstimates == 0 ) {
          actions.push({
              icon: 'bar_chart',
              title: 'Adjust building estimates',
              text: 'Adjust building estimates',
              callback:(item) => {
              this.openBuildingEstimates(item)
            }
          })

          has_no_building_estimates_text = 'There are 0 estimated units in your jurisdiction. Adjust your building estimates to see the citywide impacts.'
        }

        const newItem = {
          data: itemData,
          deep: deepLevel,
          id: summaryData?.id || null,
          helper_slug: `${getTypePrototypeFromContainerNumber?.study_type?.slug}-${summaryData?.helper_slug}` || null,
          title: summaryData?.title || null,
          items: hasDeep ? [] : null,
          has_helper: Boolean(summaryData?.helper_slug),
          is_disabled,
          actions,
          climate_zone_raw: summaryData?.climate_zone_raw || null,
          background_color: getBuildingEstimates === 0 ? 'psui-bg-gray-10' : false,
          tooltip_text: is_disabled ? disabledText : getBuildingEstimates === 0 ? has_no_building_estimates_text : null,
          has_blocked_icon: Boolean(summaryData?.fuel_slug === TYPE_FUELS_DB_SLUGS.MIXED_FUEL && !disabledByInFuelDefaultState),
          study_id: this.getPolicySelected?.policy_containers[0]?.custom_combinations[0]?.prototype?.study_id
        }
        createDeeperItems(newItem.items)
        result.push(newItem)
      }
      return result
    },

    getPolicy() {
      this.isLoading = true
      this.$store.dispatch('policy/getPolicy', this.$route.params.policy_id)
        .then(async () => {
          await this.$store.dispatch('assumptions/getAllCustomBuildings')
          await this.generatePolicyItems()
        })
      this.setUserLastStudyTypeVisited()
    },

    setUserLastStudyTypeVisited() {
      const studyTypes = this.$store.getters['globalEntities/StudyType/getterGlobalStudyTypes']() ?? []
      const studyTypeSelected = studyTypes?.find(studyType => studyType?.slug === this.getPolicyStudyType)
      this.$store.dispatch('setUserLastStudyTypeVisited', { value: studyTypeSelected?.slug, context: 'updateLastStudyTypeVisitedFromPolicyShowIndex'})
    },

    async generatePolicyItems() {
      if (this.summaryDebouncer) {
        clearTimeout(this.summaryDebouncer)
      }
      this.isLoading = true
      return new Promise((resolve) => {
        this.summaryDebouncer = setTimeout(async () => {
          if (!this.getPolicySelected?.id) return
          if (this.getPolicyStudyType === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS) {
            await this.generateNewBuildingPolicyItems()
          } else {
            const { indexedSummaries, impactBreakdownTable } = await this.generateExistingBuildingImpactTableContent(this.flexible_path_setup)
            this.indexedSummaries = indexedSummaries
            this.impactBreakdownData = impactBreakdownTable
            this.setPolicySummaryDataById(this.TOTAL_IMPACT_ID)
            this.isLoading = false
          }
          resolve()
        }, 1000)
      })
    },

    onPolicyDuplicate({ policy }) {
      const rephrasedMessage = this.$options.filters.policyTextRephrase('Policy duplicated. Now you can edit it.')
      this.$appToast({ message: rephrasedMessage })
      this.$router.push(`/policies/${policy.id}/impact`)
    },

    onClickGotIt() {
      this.$store.dispatch('setModuleVisited', 'flexible_paths')
      this.$refs.flexiblePathNewModuleAlert.close()
    },

    setPolicyActiveTab(tab) {
      this.$router.push({ ...this.$route, params: { ...this.$route.params, policy_active_tab: tab}})
    },

    onOpenAssumptionsSidebar() {
      this.$store.commit('assumptions/openDrawer')
    },

    logUserEvent() {
      this.$logUserEvent({ event: 'SHOW_POLICY', table: 'policies', table_item_id: this.$route.params.policy_id })
    },

    navigatePolicies(direction) {
      const policy_id = parseInt(this.$route.params.policy_id)
      const nextPolicyId = (direction == 'add') ? policy_id + 1 : policy_id - 1
      this.$router.push({ ...this.$route,  params: { policy_id: nextPolicyId } })
    },
    // ToDO: Separate it into some action, this BL is being used in two places
    addRequirements(item) {
      if (!item.disabled || !item.policyContainer) {
        return
      }
      this.$eventBus.$emit('setPolicyIsLoading', true)
      const { id : latest_published_study_id } = (this.$store.getters['globalEntities/Study/getterLatestPublishedStudiesByStudyTypeAndTypePrototype']({
        type_prototype_id: item.policyContainer.type_prototype_id,
        study_type_id: item.policyContainer.study_type_id,
      }))?.[0]
      const prototype = this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({
        study_id : latest_published_study_id,
        type_prototype_id: item.policyContainer.type_prototype_id
      })
      const commonCustomCombinationInfo = {
        prototype_id: prototype.id,
        jurisdiction_id : this.getPolicySelected.jurisdiction_id,
        policy_id: this.getPolicySelected.id,
        policy_container_id : item.policyContainer.id,
        study_id : latest_published_study_id
      }

      // All-Electric
      const { id: all_electric_type_fuel_id } = this.$store.getters['globalEntities/TypeFuel/getterGlobalTypeFuel']({ slug: TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC })
      const all_electric_fuel = this.$store.getters['globalEntities/Fuel/getterGlobalFuel']({ study_id : latest_published_study_id, type_fuel_id: all_electric_type_fuel_id })
      const all_electric_custom_combination = new CustomCombination({ ...commonCustomCombinationInfo, climate_zone_raw: item.climate_zone_raw, fuel_id: all_electric_fuel.id })

      // Mixed Fuel
      const { id: mixed_fuel_type_fuel_id } = this.$store.getters['globalEntities/TypeFuel/getterGlobalTypeFuel']({ slug: TYPE_FUELS_DB_SLUGS.MIXED_FUEL })
      const mixed_fuel_fuel = this.$store.getters['globalEntities/Fuel/getterGlobalFuel']({ study_id : latest_published_study_id, type_fuel_id: mixed_fuel_type_fuel_id })
      const mixed_fuel_custom_combination = new CustomCombination({ ...commonCustomCombinationInfo, climate_zone_raw: item.climate_zone_raw, fuel_id: mixed_fuel_fuel.id })

      return this.$store.dispatch(
          'policy/saveCustomCombinationsToPolicy',
          [ all_electric_custom_combination, mixed_fuel_custom_combination ]
      ).then((customCombinations) => {
        this.$eventBus.$emit('openDrawerPolicyEditRequirements', {
          component_name: PolicyEditRequirementsNewBuildingsSF2022.name ,
          custom_combination_ids : customCombinations.map(cc => cc.id).join(','),
        })
      })
    },
    openRequirementsDrawer(item) {
      const customCombinations = this.findCustomCombinationsByClimateZone(item)
      if (!customCombinations) return
      this.$eventBus.$emit("openDrawerPolicyEditRequirements", {
        component_name: PolicyEditRequirementsNewBuildingsSF2022.name,
        custom_combination_ids: customCombinations.map((cc) => cc.id).join(","),
      })
    },
    async deleteCustomCombinationsByClimateZone(item) {
      this.summaryDataRow = item
      if(this.getPolicyCustomCombinations?.length <= 2) {
        this.openConfirmRemovalModal()
        return
      }
      await this.confirmDeleteCustomCombinationsByClimateZone()
    },
    async confirmDeleteCustomCombinationsByClimateZone() {
      if(!this.getUserCanEditPolicy) {
        this.$toast.error("This user cannot edit custom combination for this policy",{ duration: 10000 })
        return
      }

      this.isLoading = true
      if(this.getPolicyCustomCombinations?.length <= 2) {
        this.closeModalAndGoToRequirementsTab()
      }
      const customCombinations = this.findCustomCombinationsByClimateZone(this.summaryDataRow)
      await Promise.all(customCombinations?.map((cc) => CustomCombinationApiService.delete(cc.id, true)))
      await this.$store.dispatch("policy/getPolicy", this.$route?.params?.policy_id)

      this.isLoading = false
      this.closeModalAndGoToRequirementsTab()
    },
    findCustomCombinationsByClimateZone(item) {
      const { id: itemId } = item
      const policyContainerIdMatch = itemId.match(/-(\d+)/)
      const policyContainerId = policyContainerIdMatch ? policyContainerIdMatch[1] : null

      const policyContainer = this.getPolicySelected.policy_containers.find((container) => container.id == policyContainerId)

      let climateZoneRaw = ''
      if(item.climate_zone_raw !== null) {
        climateZoneRaw = item.climate_zone_raw
      } else if (item.items && item.items.length > 0) {
        climateZoneRaw = itemId.match(/climateZone-(.*)/)[1]
      } else {
        climateZoneRaw = itemId.match(/climateZone-(.*?):/)[1]
      }
      const customCombinations = policyContainer?.custom_combinations.filter((cc) => cc.climate_zone_raw == climateZoneRaw)
      return customCombinations
    },
    closeModalAndGoToRequirementsTab() {
      this.closeConfirmRemovalModal()
      if(this.getPolicyCustomCombinations?.length == 0) {
        this.goToRequirementsTab()
      }
    },
    openConfirmRemovalModal() {
      this.$refs.confirmRequirementsRemovalModal.open()
    },
    closeConfirmRemovalModal() {
      this.$refs.confirmRequirementsRemovalModal.showModal = false
    },
    goToRequirementsTab() {
      this.$router.push({
        name: 'PolicyShow',
        params: {
          ...this.$route.params,
          policy_active_tab: 'requirements'
        }
      })
    },
    openOutdatedPolicyModalAndApplyBlurToBackground() {
      if(this.blockOutdatedPolicy && this.getGlobalPrototypes.length > 0) {
        setTimeout(() => {
          this.openOutdatedPolicyModal()
          this.applyBlurToBackground()
        }, 100)
      }
    },
    openOutdatedPolicyModal() {
      this.$eventBus.$emit('openDescriptionModal', {
        type: 'helper',
        slug: 'outdated_data'
      })
    },
    applyBlurToBackground() {
      const policyShow = this.$refs.policyShow
      policyShow?.classList?.add('policy-outdated-bg-blur')
    },
    openBuildingEstimates(item){
      let parentItem = item

      if(!item.items) {
        const regex = /policy:container-\d+/g
        const regexMatched = item.id.match(regex)[0]
        parentItem = this.impactBreakdownData[0].items.find(item => item.id == regexMatched)
      }

      const getTypePrototypeSlug = parentItem.helper_slug.replace(/type-prototype-/,'')

      this.$store.commit('assumptions/openDrawer')
      this.$store.commit('assumptions/updateDrawerPath', ASSUMPTIONS_PATHS.RESIDENTIAL_BUILDINGS) 
      this.$eventBus.$emit('watchToScrollToElement', `item-${getTypePrototypeSlug}`)
    },
    buildingEstimatesHasChanged(){
      if(this.isLoading) return
      this.generatePolicyItems()
    },
    retrieveContainerNumberFromString(string){
      const regex = /policy:container-\d+/g
      const regexMatched = string.match(regex)?.[0].replace(/\D/g, "")

      return regexMatched
    }
  }
}
</script>

<style lang="scss" scoped>
  .policy-show-tabs {
    border-bottom: 1px solid #EBEEF0;
    top: 95px;
    button {
      position: relative;
      top: 1px;
    }
  }
  #badge-flexible-path {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(110%) translateY(-50%);
  }

  .policy-outdated-bg-blur {
    filter: blur(3px);
    width: 100%;
    height: 100%;
  }

  ::v-deep .psui-el-tooltip .psui-el-tooltip-wrapper .psui-el-tooltip-dialog .psui-el-tooltip-content.layout-gray .psui-el-tooltip-content-wrapper {
    max-width: 180px;
    p {
      display: inline-block;
      word-wrap: break-word;
      white-space: normal;
     }
  }
</style>
