<template>
  <div class="card-main psui-flex psui-flex-col psui-justify-center psui-pt-16">
    <transition
      name="fade"
      mode="out-in"
    >
      <div class="psui-flex psui-flex-col psui-mx-8 psui-mb-8 psui-justify-center psui-text-center card-main-space">
        <img 
          :src="`/images/policy_wizard/step_auto_generate_saved.png`"
          class="card-image psui-mx-auto" 
        >
        <div>
          <div
            class="psui-mb-4"
          >
            <p class="psui-text-h4 psui-text-blue-80 psui-mb-4">
              Your Comparison is Ready
            </p>
            <p class="psui-text-p psui-text-gray-60">
              Add a description to help you and your colleagues understand your comparison
            </p>
          </div>
          <PsInputTextArea
            data-test-id="comparison-policies-wizard-step-two-description-input"
            placeholder="I.e., this comparison is comparing the impact of requiring PV for Multifamily vs Single Family Units"
            :rows="2"
            :required="false"
            :value="policyComparisonValueDescription"
            @input="policyComparisonInputTextAreaEvent($event.target.value)"
          />
        </div>
      </div>
    </transition>
    <div 
      class="psui-flex psui-justify-between psui-mt-auto psui-pt-5 psui-border-t psui-border-blue-70 psui-border-opacity-25"
    >
      <PsButton
        size="big"
        label="Back to navigation"
        icon="keyboard_double_arrow_left"
        layout="onlytext"
        class="btn-back"
        @click="redirectToComparisonList"
      />

      <PsButton
        data-test-id="comparison-policies-wizard-skip-and-see-comparison-button"
        size="big"
        :label="buttonLabel"
        @click="goToMyPolicyComparison"
      />
    </div>
  </div>
</template>

<script>
import ComparisonPoliciesSteps from '@/modules/app/comparison/ComparisonPoliciesSteps.js'

export default {
  name: "ComparePoliciesWizardStepTwo",
  props: {
    comparisonId: {
      type: [String, Number],
      default: null
    },
  },
  data: () => ({
    policyComparison: {
      description: null
    },
    buttonLabel: 'Skip and see comparison',
    ComparisonPoliciesSteps,
  }),
  computed: {
    currentStep() {
      return ComparisonPoliciesSteps[1]
    },
    policyComparisonValueDescription() {
      return this.policyComparison?.description
    }
  },
  methods: {
    async goToMyPolicyComparison() {
      if (this.policyComparison.description !== null) {
        await this.currentStep.updateComparison(this.comparisonId, { description: this.policyComparison.description })
      }
      this.$router.push({ name: 'ComparisonShow', params: { comparison_id : this.comparisonId }}, () => {})
      this.$emit('register-success')
    },
    policyComparisonInputTextAreaEvent(description) {
      this.policyComparison.description = description
      this.buttonLabel = this.policyComparison.description ? 'See comparison' : 'Skip and see comparison'
    },
    resetState() {
      this.policyComparison.description = null
      this.$emit('register-success')
    },
    redirectToComparisonList() {
      this.resetState()
    }
  }
}
</script>

<style scoped lang="scss">
  .card-main {
    width: 90%;

    @media (min-width: 48rem) {
      width: 50rem;
      height: 35.313rem;
    }

    &-space {
      > :not(template) ~ :not(template) {
        --space-y-reverse: 0;
        margin-top: calc(3.313rem * calc(1 - var(--space-y-reverse)));
        margin-bottom: calc(3.313rem * var(--space-y-reverse));
      }
    }
  }

  .card-image {
    max-width: 324px;
  }

  .btn-back {
    background-color: transparent;
  }
</style>