<template>  
  <ul
    v-if="items.length > 0"
  >
    <li
      v-for="(item,index) in items"
      :key="item.title"
      :class="liClass"
      :style="{ 'max-width': truncateItems ? `${truncateWidth}px` : 'none' }"
    >
      <router-link
        v-if="item.route"
        :to="item.route"
        :class="{ 'title-truncate' : truncateItems }"
        
        @click.native="onNavigate(item)"
      >
        {{ item.title | policyTextRephrase }}
      </router-link>
      <span 
        v-else 
        :class="{ 'title-truncate' : truncateItems }"
        :title="item.title"
        @click="$emit('click',$event, item, index)"
      >
        {{ item.title | policyTextRephrase}}
      </span>
    </li>
  </ul>
</template>

<script>

export default {
  props: {
    items: {
      type: [Array, Boolean],
    },
    liClass: {
      type: String,
      default: 'psui-text-small psui-text-gray-60'
    },
    truncateItems: {
      type: Boolean,
      default: false
    },
    truncateWidth: {
      type: String,
      default: '120'
    }
  },
  methods: {
    onNavigate(item) {       
      if (item.route === this.$route.path && this.$parent.closeDrawer) {
        this.$parent.closeDrawer()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  ul {
    display: flex;
    align-items: center;
    padding: 5px 0;

    li {
      display: inline-block;
      display: flex;
      align-items: center;
      
      &:not(:last-child) {
        &:after {
          content: "chevron_right";
          display: inline-block;
          font-family: 'Material Icons Round';
          font-size: 16px;
          color: #A2ACB7;
          margin: 0 2px;
          line-height: 1;
        }
      }

      &:first-child {
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      a {
        color: #515E6A;
        text-decoration: none;
        transition: 250ms ease-in-out all;

        &:hover {
          color: #318FAC;
        }
      }

      .title-truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
</style>
