<template>
  <div
    :id="`item-${type_prototype.slug}`"
    class="wrapper psui-w-full psui-flex psui-flex-col psui-space-y-6"
    :class="{'is-open' : display}"
  >
    <div 
      class="btn-collapse psui-w-full psui-rounded-md psui-bg-white psui-flex psui-flex-col psui-transition-shadow psui-duration-300 --add-to-pdf psui-ease-in"
      :class="{'psui-shadow-elevation-20': display || hover, 'psui-shadow-elevation-5': !display && !hover, 'exporting': isExporting}"
      :attr-export-steps="type_prototype.slug === TYPE_PROTOTYPES_DB_SLUGS.ADU ? '2' : '1,2'"
      @mouseover="hover = true"
      @mouseleave="hover = false"
      @click="openCard(false, type_prototype)"
    >
      <div
        class="psui-w-full psui-p-6 psui-flex psui-items-center psui-space-x-3 psui-relative"
        :class="{'psui-cursor-pointer': !display}"
      >
        <span 
          class="btn-arrow material-icons-round psui-text-gray-50 psui-flex-none transition-all psui-transform psui-cursor-pointer"
          :class="display ? 'open psui-rotate-90' : 'closed psui-rotate-0'"
          @click.stop="toggleCard(type_prototype)"
        >
          chevron_right
        </span>
        
        <div class="f-icon psui-flex psui-flex-none psui-items-center psui-justify-center psui-rounded-md psui-bg-gray-10 psui-p-3">
          <PsIcon 
            :icon="type_prototype.icon"
            size="40"
            color="psui-text-gray-60"
            icon-classes="psui-text-gray-60"
            :class="`psui-m-1`"
          />
        </div>
        
        <div class="card-icon-helper psui-w-full psui-flex gap-3">
          <div class="psui-flex psui-flex-col">
            <h3 class="psui-flex psui-text-gray-80 psui-text-h5 psui-font-bold">
              {{ type_prototype.title }}
              <a
                :href="link"
                target="_blank"
                class="text-opacity-0 flex items-center"
                @click.stop="link = INTERCOM_LINK_PER_TYPE_PROTOTYPE_SLUG[type_prototype.slug]"
              >
                <PsIcon
                  icon="info"
                  size="16"
                  :style="{ display: 'flex' }"
                  class="psui-text-gray-40 psui-cursor-pointer psui-ml-2"
                />
              </a>
            </h3>
            <div class="psui-text-small psui-text-gray-50 mt-1">
              {{ type_prototype.building_stock_helper }}
            </div>
          </div>
        </div>
        <div class="psui-flex psui-ml-auto psui-justify-end psui-space-x-2 psui-relative psui-z-10">
          <div
            v-if="getBuildingTypeSelected.slug === path.RESIDENTIAL_BUILDINGS.slug"
            v-tooltip="getTypePrototypeWidgetTooltip(type_prototype, true)"
            class="select-units psui-flex psui-flex-col psui-space-y-1 psui-rounded psui-border psui-px-4 psui-py-2 psui-cursor-pointer psui-transition-all"
            :class="[
              (existingOrNewTabSelected == 'existing' && !isExporting) ? 
                'psui-bg-blue-10 psui-border-blue-50 psui-text-blue-60 active' : 
                'psui-border-gray-20 psui-text-gray-50',
              getTypePrototypeWidgetTooltip(type_prototype, true) ? 'psui-bg-gray-10 hover:psui-border-gray-20 hover:psui-text-gray-50' : 'hover:psui-border-blue-50 hover:psui-text-blue-60',
            ]"
            @click.stop="toggleUnitsTabSelected('existing', type_prototype)"
          >
            <h3 class="psui-text-xsmall psui-font-bold psui-whitespace-nowrap">
              <span>Existing Units </span>
              <span class="font-normal">as of 2020</span>
            </h3>
            <h5 class="psui-flex psui-items-center psui-justify-end psui-text-p psui-text-right">
              <div
                v-if="type_prototype.slug === TYPE_PROTOTYPES_DB_SLUGS.ADU"
                class="helper psui-flex psui-items-center psui-cursor-pointer opacity-0 psui-mr-1"
                @click="openDescriptionModal"
              >
                <PsIcon
                  icon="info_outline"
                  size="16"
                  class="psui-text-gray-50 h-4 transition-all psui-leading-none"
                  :style="{ display: 'inline-block' }"
                />
              </div>
              <span
                v-if="type_prototype.slug !== TYPE_PROTOTYPES_DB_SLUGS.ADU"
                id="edit-icon"
                class="material-icons-round psui-text-blue-50 psui-text-p psui-leading-none psui-mr-1 psui-transition-all hover:psui-text-blue-60"
                @click="openAssumptionsDrawerExistingUnits()"
              >
                edit
              </span>
              {{ formatUnits(getBuildingStocksUnities) }}
            </h5>
          </div>
          <div 
            v-tooltip="getTypePrototypeWidgetTooltip(type_prototype, false)"
            class="select-units psui-flex psui-flex-col psui-space-y-1 psui-rounded psui-border psui-px-4 psui-py-2 psui-cursor-pointer hover:psui-border-blue-50 hover:psui-text-blue-60 psui-transition-all"
            :class="[
              (existingOrNewTabSelected == 'future' && !isExporting) ? 
                'psui-bg-blue-10 psui-border-blue-50 psui-text-blue-60 active' : 
                'psui-border-gray-20 psui-text-gray-50'
            ]"
            @click.stop="toggleUnitsTabSelected('future')"
          >
            <h3 class="psui-text-xsmall psui-font-bold psui-whitespace-nowrap">
              <span>{{ getBuildingTypeSelected.slug === path.RESIDENTIAL_BUILDINGS.slug ? 'Future Units' : 'Future Construction' }} <span>{{ getUnitOfMeasurementByBuildingType }}</span></span>
              <span class="font-normal">{{ BUILDING_ESTIMATES_BASE_YEARS_TIMEFRAME }}</span>
            </h3>
            <h5 class="psui-flex psui-items-center psui-justify-end psui-text-p psui-text-right">
              <span
                id="edit-icon"
                class="material-icons-round psui-text-blue-50 psui-text-p psui-leading-none psui-mr-1 psui-transition-all hover:psui-text-blue-60"
                @click="openAssumptionsDrawerFutureUnits()"
              >
                edit
              </span>
              {{ formatUnits( getTypePrototypeFutureUnits(type_prototype) ) }}
            </h5>
          </div>
        </div>
      </div>
      <div
        v-if="display"
        class="psui-transition-all psui-duration-300 psui-pr-6"
      >
        <div
          v-if="checkExistingTabIsSelected()"
          class="w-full flex flex-col pl-6 pb-6"
        >
          <div class="w-full flex justify-between flex-col">
            <div class="w-full flex justify-between">
              <h4 class="psui-text-h5 psui-text-gray-80 font-bold whitespace-normal">
                Building Estimates for Existing Units
              </h4>
              <ClimateZonesSelecteds :palette-color-index="paletteColorIndex" />
            </div>
          </div>

          <div
            class="psui-overflow-x-auto psui-scrolling-auto pr-6"
            :class="{'collapse': !display}"
          >
            <TypePrototypeExistingChart
              :get-climate-zone-selected="getClimateZoneSelected"
              :type_prototype="type_prototype"
              :palette-color-index="paletteColorIndex"
            />
            <TypePrototypeExistingTable 
              :get-climate-zone-selected="getClimateZoneSelected"
              :type_prototype="type_prototype"
              :palette-color-index="paletteColorIndex"
            />
          </div>
        </div>
        <div
          v-else
          class="w-full flex flex-col psui-pl-6 psui-pb-6"
        >
          <div
            class="psui-w-full psui-flex psui-space-x-2 mb-6"
          >
            <BreakDownWidget
              v-if="getBuildingTypeSelected.slug === path.NON_RESIDENTIAL_BUILDINGS.slug"
              ref="breakdownByConstructionImplementationType" 
              class="hidden"
              switch_title="Break-down by construction type"
              key_label="title"
              key_value="id"
              :items="getConstructionImplementationTypes"
              :selected.sync="breakdown.constructionImplementationType.selected"
              :is-switch-active.sync="breakdown.constructionImplementationType.switch"
            />
            <BreakDownWidget 
              v-if="type_prototype.slug === 'multifamily-units'"
              ref="breakingDowByBuildingHeight"
              switch_title="Break-down by building height"
              key_label="title"
              key_value="id"
              :items="getBuildingHeights"
              :selected.sync="breakdown.buildingHeight.selected"
              :is-switch-active.sync="breakdown.buildingHeight.switch"
            />
          </div>
          <div class="flex items-center justify-between">
            <h4 class="psui-text-h5 psui-text-gray-80 font-bold whitespace-normal">
              {{ getBuildingTypeSelected.slug === path.RESIDENTIAL_BUILDINGS.slug ? 'Forecast for Future Units' : 'Forecast for Future Construction' }} 
              <span
                id="construction"
                class="font-normal"
              >{{ getConstructionImplementationTypeTitleOnResidential }}</span>
            </h4>
            <ClimateZonesSelecteds :palette-color-index="paletteColorIndex" />
          </div> 


          <div
            class="psui-overflow-x-auto psui-scrolling-auto"
            :class="{'collapse': !display}"
          >
            <TypePrototypeFutureChart
              :get-climate-zone-selected="getClimateZoneSelected"
              :type_prototype="type_prototype"
              :palette-color-index="paletteColorIndex"
              :breaking-down-by-construction-implementation-type-selected="breakdown.constructionImplementationType.selected"
              :breaking-down-by-building-height-selected="breakdown.buildingHeight.selected"
              :get-building-type-selected="getBuildingTypeSelected"
            />

            <TypePrototypeFutureTable 
              :get-climate-zone-selected="getClimateZoneSelected"
              :type_prototype="type_prototype"
              :palette-color-index="paletteColorIndex"
              :breaking-down-by-construction-implementation-type-selected="breakdown.constructionImplementationType.selected"
              :breaking-down-by-building-height-selected="breakdown.buildingHeight.selected"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BUILDING_ESTIMATES_BASE_YEARS_TIMEFRAME, ASSUMPTIONS_PATHS } from '@/modules/app/assumptions/shared/enums'
import TypePrototypeExistingTable from './existing/TypePrototypeExistingTable'
import TypePrototypeExistingChart from './existing/TypePrototypeExistingChart'
import TypePrototypeFutureChart from './future/TypePrototypeFutureChart'
import TypePrototypeFutureTable from './future/TypePrototypeFutureTable'
import ClimateZonesSelecteds from './ClimateZonesSelecteds'
import BreakDownWidget from '@/components/general/BreakDownWidget.vue'
import { INTERCOM_LINK_PER_TYPE_PROTOTYPE_SLUG }from '@/store/global-entities/TypePrototype.js'
import { STUDY_TYPES_DB_SLUGS, TYPE_PROTOTYPES_DB_SLUGS, BUILDING_TYPE_DB_SLUGS } from '@/util/Enums.js'

export default {
  name: 'TypePrototypeCardListItem',
  components: { 
    TypePrototypeExistingTable, 
    TypePrototypeExistingChart, 
    TypePrototypeFutureChart,
    TypePrototypeFutureTable,
    ClimateZonesSelecteds,
    BreakDownWidget
    // ByYearAndConstructionImplementationType
  },
  props: ['getBuildingStockTotalUnits', 'getClimateZoneSelected', 'type_prototype', 'paletteColorIndex','getBuildingTypeSelected'],
  data() {
    return {
      BUILDING_ESTIMATES_BASE_YEARS_TIMEFRAME,
      existingOrNewTabSelected: false,
      display: false,
      hover: false,
      isExporting: false,
      prevExportState: null,
      prevExistingOrNewExportState: null,
      breakdownByConstructionImplementationType: {},
      breakdownByBuildingHeight: {},
      path: ASSUMPTIONS_PATHS,
      breakdown: {
        constructionImplementationType: {
          switch: false,
          selected: false
        },
        buildingHeight: {
          switch: false,
          selected: false
        }
      },
      link: null,
      INTERCOM_LINK_PER_TYPE_PROTOTYPE_SLUG,
      TYPE_PROTOTYPES_DB_SLUGS
    }
  },
  computed: {
    getConstructionImplementationTypes() {
      return this.$store.getters['globalEntities/ConstructionImplementationType/getterGlobalConstructionImplementationTypes']
    },
    getBuildingHeights() {
      return this.$store.getters['globalEntities/BuildingHeight/getterGlobalBuildingHeights']
    },
    buildingEstimates() {
      return this.$store.getters['assumptions/buildingEstimates/getterDefaultJurisdictionBuildingEstimates']()
    },
    getConstructionImplementationTypeTitleOnResidential(){
      return this.getBuildingTypeSelected.slug === this.path.RESIDENTIAL_BUILDINGS.slug ? `(${this.getConstructionImplementationTypes[0].title})` : " (ft²)"
    },
    getUnitOfMeasurementByBuildingType(){
      return this.getBuildingTypeSelected.slug === this.path.RESIDENTIAL_BUILDINGS.slug ? "" : "(ft²) "
    },
    getBuildingStocksUnities() {
      if(this.type_prototype.slug === TYPE_PROTOTYPES_DB_SLUGS.ADU) return null
      return this.type_prototype.building_stock_units
    },
    getRouteBuildingTypeSelected(){
      return this.$route.query.only_building_type ?? BUILDING_TYPE_DB_SLUGS.RESIDENTIAL
    }
  },
  methods: {
    setExporting(value, buildingType = null) {
      if (value) {
        if (this.prevExportState === null) {
          this.prevExportState = this.display
        }
        if (this.prevExistingOrNewExportState === null) {
          this.prevExistingOrNewExportState = this.existingOrNewTabSelected
        }
        if (buildingType) {
          this.existingOrNewTabSelected = buildingType === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS ? 'future' : 'existing'
        }
        this.openCard(false, this.type_prototype)
        this.isExporting = true
      } else {
        if (this.prevExistingOrNewExportState !== null) {
          this.existingOrNewTabSelected = this.prevExistingOrNewExportState
          this.prevExistingOrNewExportState = null
        }
        if (this.prevExportState !== true) {
          this.display = false
          this.existingOrNewTabSelected = false
        }
        this.prevExportState = null
        this.isExporting = false
      }
    },
    getTypePrototypeFutureUnits(type_prototype) {
      return this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits']({
        climate_zone_prefix: this.getClimateZoneSelected.map(cz => cz.prefix),
        type_prototype_id: type_prototype.id
      })
    },
    openCard(item, type_prototype) {
      if([false, 'existing'].includes(this.existingOrNewTabSelected) && type_prototype.slug === TYPE_PROTOTYPES_DB_SLUGS.ADU) {
        this.existingOrNewTabSelected = 'future'
        this.display = true
      } else {
        if (!this.display) {
          this.display = true 
          this.existingOrNewTabSelected = this.getBuildingTypeSelected.slug === this.path.RESIDENTIAL_BUILDINGS.slug ? 'existing' : 'future'
        }
  
        if (item) {
          this.existingOrNewTabSelected = item
        } 
      }

    },
    toggleCard(type_prototype) {
      if(!this.existingOrNewTabSelected && type_prototype.slug === TYPE_PROTOTYPES_DB_SLUGS.ADU) {
        this.existingOrNewTabSelected = 'future'
        this.display = true
      } else {
        this.display = !this.display
        if ( !this.display ) {
          this.existingOrNewTabSelected = false
        } else {
          this.existingOrNewTabSelected = this.getBuildingTypeSelected.slug === this.path.RESIDENTIAL_BUILDINGS.slug ? 'existing' : 'future'
        }
      }
    },
    toggleUnitsTabSelected(item, type_prototype) {
      if(item === 'existing' && type_prototype.slug === TYPE_PROTOTYPES_DB_SLUGS.ADU) return
      this.existingOrNewTabSelected = item
      this.openCard(item, type_prototype)
    },
    getFilters(type_prototype, filters) {
      return {
        ...this.getBaseFilters(type_prototype),
        ...filters
      }
    },
    getBaseFilters(type_prototype) {
      const filters = {}

      if (this.breakdownByBuildingHeight[type_prototype.id]) 
        filters.building_height_id =  this.getSelectedBuildingHeight(type_prototype.id)
      
      if (this.breakdownByConstructionImplementationType[type_prototype.id]) 
        filters.construction_implementation_type_id = this.getSelectedConstructionType(type_prototype.id)

      if (this.climate_zone_prefix) 
        filters.climate_zone_prefix = this.climate_zone_prefix
      
      filters.type_prototype_id = type_prototype.id
      
      return filters
    },

    getSelectedBuildingHeight(type_prototype_id) {
      return this.breakdownByBuildingHeight[type_prototype_id] ?? this.breakdownByBuildingHeight[type_prototype_id].id
    },
    getSelectedConstructionType(type_prototype_id) {
      return this.breakdownByConstructionImplementationType[type_prototype_id] ?? this.breakdownByConstructionImplementationType[type_prototype_id].id
    },
    checkSwitch(breakdownObjKey, type_prototype_id) {
      return this[breakdownObjKey][type_prototype_id] ? true : false
    },
    toggleBreakdown(breakdownObjKey, type_prototype_id, items) {
      if(!this[breakdownObjKey][type_prototype_id]) {
        this.$set(this[breakdownObjKey], type_prototype_id, items[0])
      } else {
        this[breakdownObjKey][type_prototype_id] = false
      }
    },
    toggleBreakdownItem(breakdownObjKey, type_prototype_id, selected) {
      this[breakdownObjKey][type_prototype_id] = selected
    },
    checkExistingTabIsSelected() {
      return this.getBuildingTypeSelected.slug === this.path.RESIDENTIAL_BUILDINGS.slug && this.existingOrNewTabSelected === 'existing'
    },
    openAssumptionsDrawerFutureUnits(){
      const getBuildingTypeSLug = {
        [BUILDING_TYPE_DB_SLUGS.RESIDENTIAL]:ASSUMPTIONS_PATHS.RESIDENTIAL_BUILDINGS,
        [BUILDING_TYPE_DB_SLUGS.NON_RESIDENTIAL]:ASSUMPTIONS_PATHS.NON_RESIDENTIAL_BUILDINGS
      }[this.getRouteBuildingTypeSelected]

      this.$store.dispatch('setUserLastStudyTypeVisited', { value: STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS, context: 'updateLastStudyTypeVisitedFromTypePrototypeCardListItem'})
      this.$eventBus.$emit('watchToScrollToElement', `item-${this.type_prototype.slug}`)
      this.$store.commit('assumptions/updateTypePrototypeEditing', this.type_prototype)
      this.$store.commit('assumptions/openDrawerAtBuildingEstimatesResidentialNew', this.getBuildingTypeSelected)
      this.$store.commit('assumptions/openDrawerAtSelectedAssumptionsPath', getBuildingTypeSLug)
      this.$store.commit('assumptions/updateDrawerIsEditingFromChart', true)
    },
    openAssumptionsDrawerExistingUnits(){
      this.$store.dispatch('setUserLastStudyTypeVisited', { value: STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS, context: 'updateLastStudyTypeVisitedFromTypePrototypeCardListItem'})
      this.$store.commit('assumptions/openDrawerAtBuildingEstimatesResidentialExisting', this.getBuildingTypeSelected)

    },
    getTypePrototypeWidgetTooltip(type_prototype, isExisting) {
      if(isExisting && type_prototype.slug === TYPE_PROTOTYPES_DB_SLUGS.ADU) return {content: '<p class="psui-text-xsmall psui-font-bold psui-mb-1">Existing ADUs are included in the Existing Single Family Units.</p>', placement:'left-end'} 
      if(!isExisting && type_prototype.slug === 'single-family-homes' && this.getTypePrototypeFutureUnits(type_prototype) === 0) {
        return `We predict that all newly constructed single family units will be ADUs in your jurisdiction. Adjust this assumption to best fit your data.`
      }
      return false   
    },
    openDescriptionModal() {
      this.$eventBus.$emit("openDescriptionModal", {
        type: "helper",
        slug: "type-prototype-card-list-item-existing-adu",
        button: {
          title: "More Info",
          url: "https://intercom.help/explorer-local-energy-codes/en/articles/6619711-adus"
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper.is-open .collapse {
  min-height: 300px;
  max-height: calc(100vh - 340px);
}

.select-units  {
  &.has-tooltip,
  &.v-tooltip-open {
    &:hover {
      .helper { opacity: 1; }
    }
  }

  &.has-tooltip,
  &.v-tooltip-open {
    &:hover {
      .helper { opacity: 1; }
    }
  }
}

.apexcharts-tooltip {
  border-color: #000 !important;
}

// Can be removed after conclusion of https://epcgo.atlassian.net/browse/PSUI-39
.f-icon ::v-deep svg {
  stroke: none !important;
}

.exporting {
  > div .btn-arrow {
    display: none;
  }

  .f-icon {
    margin-left: 0;
  }

  ::v-deep {
    .psui-el-chart-legend-dot {
      margin-top: 8px;
    }
  }
}

.breakdown-switch {
  padding: 6px 12px;
  &-title {
    margin-right: 50px;
  }
}

::v-deep {
    .psui-icon {
      font-size: 40px;
    }
  }

  .card-icon-helper .text-opacity-0 {
    opacity: 0;      
  }

  .card-icon-helper:hover .text-opacity-0 {
    opacity: 1;
  }
</style>
