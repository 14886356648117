import { formatNumberExtended } from './formatNumberExtended'
import { formatCurrencyExtended } from './formatCurrencyExtended'
import { normalizeToZero } from './valueNormalizers'
import formatEmissionsSavings from './formatEmissionsSavings'
import * as oldNumberFormatter  from './formatNumber'
import { getBuildingTypeSlugByStudyId, getPrototypeByStudyId, getTypePrototype } from '@/util/Functions.js'
import { BUILDING_TYPE_DB_SLUGS, TYPE_PROTOTYPES_DB_SLUGS } from '@/util/Enums.js'

export default (col, value, data, studyId) => {
  if (col === 'flexible_score') {    
    const formattedValue = Math.round(data?.flexible_score ?? data?.energy_savings_combined)

    if(isNaN(formattedValue)) return null

    if (Number(formattedValue) === 0) {
      return '--'
    }
    
    return formattedValue
  }

  if(typeof value == 'undefined') return value

  if(col === 'emissions_savings') return formatEmissionsSavings(value, data)

  if([
    'tdv_benefit_to_cost_ratio',
    'on_bill_cost_ratio',
    'tdv2022_benefit_to_cost_ratio',
    'simple_payback',
    'on_bill_cost_ratio_care',
    'simple_payback_care',
    'on_bill_cost_ratio_2025',
    'lsc_2025_benefit_to_cost_ratio',
    'on_bill_2025_care',
  ].includes(col)) {
    const formattedValue = formatNumberExtended(value, 1, 1)
    if (Number(formattedValue) === 0) {
      return '--'
    }
    return formattedValue
  }

  if([
    'subsidy_needed',
    'subsidy_needed_care',
    'five_year_payback',
    'five_year_payback_care',
    'city_wide_subsidy_needed',
    'city_wide_five_year_payback',
    'city_wide_subsidy_needed_care',
    'city_wide_five_year_payback_care',
  ].includes(col)) {
    if (Number(value) <= 0) {
      return '--'
    }
    return formatCurrencyExtended(value, 0, 0)
  }

  if([
    'initial_cost',
    'annual_bill_savings',
    'annual_bill_savings_avg',
    'lifecycle_savings',
    'forecast_initial_cost',
    'forecast_lifecycle_savings',
    'annual_bill_savings_care',
    'lifecycle_savings_care',
  ].includes(col)) {
    return formatCurrencyExtended(value, 0, 0)
  }

  if ([
    'therms_savings',
  ].includes(col)) {
    return formatNumberExtended(value, 1, 1, normalizeToZero(-0.05))
  }

  if ([
    'emissions_savings_pct'
  ].includes(col)) {
    const percentage = formatNumberExtended(value * 100, 1, 1)
    return `${percentage}%`
  }

  if ([
    'kwh_savings'
  ].includes(col)) {
    return formatNumberExtended(value, 0, 0, normalizeToZero(-0.5))
  }

  if ([
    'energy_savings_combined'
  ].includes(col)) {
    return formatNumberExtended(value, 2, 2, normalizeToZero(-0.005))
  }

  if([
    'compliance_margin',
    'edr1_total_margin',
  ].includes(col)) {
    const buildingTypeSlug = getBuildingTypeSlugByStudyId(studyId)
    const { type_prototype_id } = getPrototypeByStudyId(studyId)
    const typePrototypeSlug = getTypePrototype(type_prototype_id).slug
    if(studyId && (buildingTypeSlug === BUILDING_TYPE_DB_SLUGS.NON_RESIDENTIAL || typePrototypeSlug === TYPE_PROTOTYPES_DB_SLUGS.MULTIFAMILY)){
      const percentage = formatNumberExtended(value * 100, 1, 1)
      return `${percentage}%`
    } else {
      return formatNumberExtended(value, 1, 1, normalizeToZero(-0.05))
    }
  }
  
  if([
    'forecast_emissions_savings',
    'forecast_emissions_savings_pct',
    'forecast_kwh_savings',
    'forecast_therms_savings',
  ].includes(col)) {
    return oldNumberFormatter.default(value)
  }

  if([
    'forecast_units_affected',
    'forecast_units',
    'units',
    'units_affected',
  ].includes(col)) {
    if (col === 'forecast_units_affected' && !data) {
      return value
    }
    return formatNumberExtended(value, 0, 0)
  }

  return value
}
