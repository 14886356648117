import BaseModel from './BaseModel'
import { formatDate } from '@/util/Helpers'
export default class Study extends BaseModel {
  constructor(obj = {}) {
    super(obj)

    this.id = obj.id || null
    this.title = obj.title || ''
    this.content = obj.content || ''
    this.study_group_id = obj.study_group_id || ''
    this.source_pdf = obj.source_pdf || ''
    this.source_pdf_update = obj.source_pdf_update || ''
    this.version = obj.version || ''
    this.version_updated_at = obj.version_updated_at || ''
    this.version_updated_changes = obj.version_updated_changes || ''
    this.version_updated_expected_at = obj.version_updated_expected_at || ''
    this.spreadsheet_id = obj.spreadsheet_id || ''
    this.worksheet_name = obj.worksheet_name || ''
    this.impact_period = obj.impact_period || ''
    this.created_at = obj.created_at || ''
    this.is_private = obj.is_private || false
    this.building_type = obj.building_type || ''
    this.released_at = obj.released_at || ''
    this.expected_at = obj.expected_at || ''
    this.published_at = obj.published_at || ''
    this.code_cycle_id = obj.code_cycle_id || 1
    this.pdf_export_source_description = obj.pdf_export_source_description || ''
    this.show_version_history = obj.show_version_history || false
    this.has_waiting_measures = obj.has_waiting_measures || false
    
    this.is_upcoming = obj.is_upcoming || false   
    this.has_content = obj.has_content || false   

    this.code_cycle = obj.code_cycle || null
    this.prototypes = obj.prototypes || []
    this.vintages = obj.vintages || []
    this.measures = obj.measures || []
    this.fuels = obj.fuels || []    

    // Custom fields
    this.custom_field_item_type = 'studies'
    this.custom_fields_keys = ['title_by_prototypes', 'summary_recommended', 'custom_links', 'summary_message_tag', 'compliance_margin_key', 'disable_outdated_modal', 'enabled_cost_ratio_presets']
    this.addCustomFields()
  }

  get released_at_formatted() {
    return this.released_at ? formatDate({ date: this.released_at, format: 'MMMM D, YYYY', from: 'mm/dd/yyyy' }) : ''
  }
  get released_at_formatted_version() {
    return this.released_at ? this.released_at_formatted  + ' version' : ''
  }
  get getTitle() {
    return this.released_at ? this.released_at_formatted_version : this.title
  }
  get getPdfTag() {
    return this.released_at && !this.published_at ? '(PDF Only)' : ''
  }
  get admin_title_formmated() {
    return this.title_by_prototypes && this.released_at ? `${this.released_at_formatted} ___ ${this.title_by_prototypes}` : this.title 
  }
}
