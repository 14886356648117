import GraphQL from '@/util/GraphQL'

export default {
  namespaced: true,
  
  state: {
    type_fuels: []
  },

  getters: {    
    getterGlobalTypeFuels: (state) => (filters = null) => {
      return filters ? state.type_fuels.findAllInArray(filters) : state.type_fuels
    },

    getterGlobalTypeFuel: (state) => (filters = null) => {
      return filters ? state.type_fuels.findInArray(filters) : false
    },
  },

  actions: {
    loadGlobalTypeFuels(context) {
      const query = `
        {
          type_fuels (
            orderBy: [{ column: "order", order: "asc"}]
          ) {
            id
            title
            slug
            order
          }
        }
      `
      GraphQL({ query })
        .then(({ data }) => {
          context.commit('setGlobalTypeFuels', { type_fuels: data.type_fuels})
        })
    }
  },

  mutations: {
    setGlobalTypeFuels(state, { type_fuels }) {
      state.type_fuels = type_fuels
    }
  }
}
