<template>
  <PsAccordionItem
    title="Natural Gas Emissions Factor"
    class="display-impact-assumption-icon"
    :class="{ 'form-dirty' : hasValueUpdated(getSingleInputValue(assumption.key), assumption.key) }"
  >
    <template #header-additionals>
      <PsIcon
        icon="help_outline"
        size="16"
        display="flex"
        class="help psui-items-center psui-h-5 hover:psui-text-blue-60"
        :icon-classes="hasValueUpdated(getSingleInputValue(assumption.key), assumption.key) ? 'mr-5': ''"
        @click.native.stop="$eventBus.$emit('openDescriptionModal', { slug: 'impact_natural_gas_emissions_factor_assumption', ...descriptionModelObject })"
      />
    </template>
    <template #default>
      <span>We assume grid supplied natural gas is responsible for this quantity of greenhouse gas emissions:</span>

      <PsInput
        :data-test-id="`input-assumption-${assumption.key}`"
        class="mt-3 w-full append-container"
        layout="mini"
        :has-error="inputErrorIndex === assumption.key ? 'Insert a valid value' : false"
        :hint="`${minCurrentTermsEmissionsFactor} - ${maxCurrentTermsEmissionsFactor}`"
        :class="{'selected': hasValueUpdated(getSingleInputValue(assumption.key), assumption.key) && !inputErrorIndex}"
        :value="getSingleInputValue(assumption.key)"
        @click.native="checkIfUserCanEditAssumption"
        @input="onInputAvg($event.target.value, assumption.key)"
        @change="onChangeAvg(assumption.key)"
        @blur="onBlurAvg(assumption.key)"
        @mouseenter="onMouseEnter(assumption.key)"
        @mouseleave="onMouseLeave()"
      >
        <template #append>
          <span
            v-if="!hasValueUpdated(getSingleInputValue(assumption.key), assumption.key) || !checkShowResetButton(assumption.key)"
            class="psui-text-gray-50 psui-text-xsmall"
          >MTCO<sub>2</sub>/therm</span>
          <PsTooltip v-else-if="checkShowResetButton(assumption.key)">
            <template #trigger>
              <PsIcon 
                icon="restart_alt"
                icon-classes="psui-text-green-20 cursor-pointer mt-1"
                size="17"
                @click.native="restoreAssumptionToDefault(assumption.key)"  
              />
            </template>
            <template #content>
              <h1 class="mb-1">
                Restore to default value
              </h1>
              <span class="psui-font-normal">{{ getBaseValue(assumption.key) }}</span>
            </template>
          </PsTooltip>
        </template>
      </PsInput>
    </template>
  </PsAccordionItem>
</template>

<script>

import { ASSUMPTIONS_KEYS }from '@/util/Enums.js'
import AssumptionImpactsMixin from './AssumptionImpactsMixin'

export default {
  name: 'AssumptionsCardPenetrationRate',
  props:['tabSelected','userCanEditPolicy', 'descriptionModelObject'],
  mixins: [ AssumptionImpactsMixin ],
  data:()=>({
    minCurrentTermsEmissionsFactor: 0.001,
    maxCurrentTermsEmissionsFactor: 0.02,
    localAssumptionValue: null,
    assumption: {
      key: ASSUMPTIONS_KEYS.current_therms_emissions_factor 
    }
  }),
  computed: {
    
    getNaturalGasEmissionsFactorValueFromPolicy(){
      return this.$route.params.policy_id ? 
      this.$store.getters['assumptions/getterPolicyContextCustomCombinationByKey']({policy_id: this.$route.params.policy_id, key:'current_therms_emissions_factor'}) :
      null
    },

  },
  methods: {

    checkIfSelectedValueIsWithinMinAndMax(value) {
      return value <= this.maxCurrentTermsEmissionsFactor && value >= this.minCurrentTermsEmissionsFactor
    },

    updateLocalAssumption(value){
      this.localAssumptionValue = value 
    },

    validateInput(value){ 
      return value <= this.maxCurrentTermsEmissionsFactor && value >= this.minCurrentTermsEmissionsFactor
    },
  }
}
</script>

<style lang="scss" scoped>

.psui-el-input.status-resting.layout-mini, .psui-el-input {
    &.selected{
      ::v-deep .psui-el-input-wrapper {
        border-color: #5DB883;
      }
    }
    ::v-deep.psui-el-tooltip-dialog { 
      width: fit-content;
       .psui-el-tooltip-content {
        border-radius: 6px;
        background-color: #D6DDE5;
        color: #28323B;
        padding: 10px 16px; 
       }
    }
  }

  ::v-deep .psui-el-input.status-resting.layout-mini.selected,
    ::v-deep .psui-el-input.status-active.layout-mini.selected{
    .psui-el-input-wrapper {
      border-color: #5DB883;
    }

    .psui-el-input-append {
      margin-left: auto;
    }
  }

  ::v-deep .psui-el-input.status-error.layout-mini {
    .psui-el-input-wrapper {
      .psui-el-input-append {
        display: none;
      }
    }
  }

  .psui-el-accordion-item {
    &.status-opened {
      .help {
        color: #318FAC;
      }
    }
  }

  ::v-deep .append-container .psui-el-input-append {
    justify-content: flex-end;
    width: 100%;
  }
  
</style>
