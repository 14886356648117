<template>
  <transition
    enter-active-class="transition ease-out duration-200 transform"
    enter-class="opacity-0 -translate-x-40"
    enter-to-class="opacity-100"
    leave-active-class="transition ease-in duration-200 transform"
    leave-class="opacity-100"
    leave-to-class="opacity-0 -translate-x-40"
  >
    <div
      v-if="showDrawer" 
      class="origin-right fixed top-0 w-auto h-screen bg-white shadow-lg flex flex-col items-start z-40 p-6 sm:p-10"
      :style="{ left: getMarginLeft}"
    >
      <div class="w-full flex justify-end mb-10">
        <PsIcon
          icon="close"
          size="24"
          icon-classes="psui-text-gray-50 cursor-pointer"
          @click.native="closeDrawer()"
        />
      </div>

      <div class="w-full h-full overflow-y-auto">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'BaseDrawerLeft',
  data() {
    return {
      showDrawer: false,
      title: '',
      content: ''
    }
  },
  computed: {
    getMarginLeft() {
      const sidebar = document.getElementById('sidebar-container')

      if(window.innerWidth < 768 || !sidebar) return '0px'

      const bounds = sidebar.getBoundingClientRect()
      return bounds.width + 'px'
      
    }
  },
  mounted() {
    document.addEventListener("keyup", this.handleEsc)
    this.$eventBus.$on('openLeftDrawer', this.openDrawer)
  },
  beforeDestroy() {
    this.$eventBus.$off('openLeftDrawer')
    document.removeEventListener("keyup", this.handleEsc)
  },
  methods: {

    handleEsc(evt) {
      if (this.showDrawer && evt.keyCode === 27) this.closeDrawer()
    },

    openDrawer() {
      this.showDrawer = true
    },

    closeDrawer() {
      this.showDrawer = false
      setTimeout(() => {
        this.title = ''
        this.content = ''
      }, 500)
    },
  },
}
</script>
