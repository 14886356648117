<template>
  <div
    v-if="maxTargetScoresByPresets"
    class="flexiblepath-prototypecard w-full bg-white flex flex-col p-10 mb-10"
  >
    <div class="flex">
      <AppInfoHelper
        :visible="true"
        :absolute="true"
        position="right"
        @icon-click="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: 'policy-flexiblepath' })"
      >
        <h4 class="text-accent-2 inline-block text-ash-450">
          Flexible Compliance Tables
        </h4>
      </AppInfoHelper>
    </div>        
    
    <h2 class="text-header-5 font-bold text-deepsky-900">
      {{ prototype.title }}
    </h2>    
    <router-link
      class="text-p-1 text-ash-600 italic mt-1 hover:underline"
      :to="resultsRouteArgs"
    >
      Source Study: {{ prototype.study.title }} - {{ formatDate({ date: prototype.study.released_at, format: 'MMMM D, YYYY', from: 'mm/dd/yyyy' }) }}
    </router-link>   

    <div 
      v-for="climate_zone in prototype.climate_zones"
      :key="climate_zone.id"
      class="w-full mt-6"
    >
      <h3 class="text-header-3 font-bold text-deepsky-900">
        Climate Zone {{ climate_zone.prefix }}
      </h3>

      <div class="w-full overflow-x-auto mb-4">
        <div class="w-full text-right py-1">
          <p class="text-header-1 font-bold italic text-ash-600">
            Target Score Table
          </p>
        </div>
        <!-- TYPE_VINTAGE HEADER -->
        <div class="w-full flex justify-end">
          <div 
            v-for="type_vintage in type_vintages" 
            :key="`type_vintage-${type_vintage.id}`"
            class="
              cell-width h-8 flex items-center pl-4 flex-shrink-0
              psui-text-small psui-bg-blue-60 psui-text-white font-bold
              border-white border-b"
          >
            {{ type_vintage.title }}
          </div>
        </div>        
        
        <!-- TIERS -->
        <MeasureMenuTier
          v-for="(tier, tier_index) in getTiers({ climate_zone_id: climate_zone.id, prototype_id : prototype.id, flexible_path_setup })"
          :key="`tier-${tier_index}`"
          :tier="tier"
          :policy="policy"
          :prototype="prototype"
          :climate_zone="climate_zone"
          :type_vintages="type_vintages"
          :flexible_path_setup="flexible_path_setup"
          :max_target_scores_by_presets="maxTargetScoresByPresets"
        />
      </div>

      <!-- MEASURES LIST -->
      <div class="w-full overflow-x-auto">
        <div class="w-full text-right py-1">
          <p class="text-header-1 font-bold italic text-ash-600">
            Measure Score Table
          </p>
          <p class="text-header-1 italic text-ash-600">
            scores reflect energy savings
          </p>
        </div>
        
        <!-- TYPE_VINTAGE HEADER -->
        <div class="w-full flex justify-end">
          <div 
            v-for="type_vintage in type_vintages" 
            :key="`type_vintage-${type_vintage.id}`"
            class="
              cell-width h-8 flex items-center pl-4 flex-shrink-0
              text-header-2 bg-ash-300 text-ash-600 font-bold
              border-white border-b"
          >
            {{ type_vintage.title }}
          </div>
        </div>
        
        <div class="w-full flex flex-col text-header-2">
          <template v-for="type_measure in climate_zone.type_measures">
            <div
              v-if="type_measure.measures.length > 0"
              :key="type_measure.id"
            >
              <div class="w-full pb-2">
                <h1 
                  class="text-header-1 font-bold text-ash-450 bg-ash-200 py-1 flex items-center cursor-pointer transition-all hover:opacity-70"
                  @click="toggleTypeMeasure(climate_zone.id, type_measure.id)"
                >
                  <span class="pl-8 flex items-center">
                    <feather
                      :type="typeMeasuresClosed.includes(getTypeMeasureClimateZonePrefix(climate_zone.id, type_measure.id)) ? 'chevron-right' : 'chevron-down'"
                      class="text-ash-300"
                      :size="16"
                    />
                  </span>
                  {{ type_measure.title }}
                </h1>

                <div
                  v-show="!typeMeasuresClosed.includes(getTypeMeasureClimateZonePrefix(climate_zone.id, type_measure.id))"
                  class="w-full"
                >
                  <template v-for="measure in type_measure.measures">
                    <div
                      v-if="shouldBeIncludedAtMeasureMenuListInAnyTypeVintage({ measure, climate_zone, prototype, flexible_path_setup })"      
                      :key="`measure-${type_measure.id}-${measure.id}`"
                      class="w-full flex"
                    >
                      <div class="cell-width measure-title w-full h-10 flex pl-7 items-center bg-white text-ash-700 opacity-100-childrens-on-hover">
                        <feather 
                          size="16"
                          type="info" 
                          class="cursor-pointer mr-1 opacity-0 transition-all duration-300 ease-in text-ash-400 hover:text-deepsky-800" 
                          @click="$eventBus.$emit('openDescriptionModal', { type: 'measure', id: measure.id })"
                        />
                        <span>{{ measure.flexible_path_title || measure.title }}</span>
                      </div>

                      <div 
                        v-for="(type_vintage, type_vintage_index) in measure.type_vintages" 
                        :key="`measure-type_vintage-${type_vintage.id}`"
                        class="measure-cell relative transition-all cell-width h-10 flex items-center px-4 flex-shrink-0 text-ash-600 opacity-100-all-opacity-0-childrens-on-hover hover:bg-deepsky-100"
                        :data-measure-score="String(getMeasureScore({ measure, type_vintage_index }))"
                      >
                        <template v-if="shouldBeIncludedInTypeVintage({ climate_zone, type_vintage_index })">
                          <span class="z-10">
                            {{ getMeasureScoreTextValue({ measure, climate_zone, prototype, type_vintage_index, flexible_path_setup }) }}
                          </span>
                          <div 
                            v-if="userCanEditPolicy && getMeasureScoreRaw({ measure, type_vintage_index })"
                            class="measure-actions absolute top-0 right-0 w-full h-full flex items-center justify-end space-x-1 pr-1 text-ash-400"
                          >
                            <feather 
                              v-if="!checkMeasureIsExcluded({ measure, climate_zone, prototype, type_vintage_index, flexible_path_setup}) && getMeasureScoreRaw({ measure, type_vintage_index })"
                              v-tooltip="getMandatoryTooltip({ climate_zone, measure, prototype, type_vintage_index})"
                              class="action-pin transition-all opacity-0 bg-ash-200 p-0.5 rounded-sm hover:psui-text-blue-60 hover:psui-bg-blue-20"
                              type="star"
                              :size="20"
                              :class="{
                                'hover:text-deepsky-450 cursor-pointer' : checkMeasureCanBeMandatory({ climate_zone, prototype, measure, type_vintage_index, flexible_path_setup}),
                                'opacity-0 text-deepsky-450' : checkMeasureIsMandatory({ climate_zone, measure, prototype, type_vintage_index, flexible_path_setup }),
                                'opacity-100' : tooltipIsOpen === `${climate_zone.id}-${type_vintage.id}-${measure.id}`
                              }"
                              @click="onToggleMandatoryMeasures({ measure, climate_zone, prototype, type_vintage, type_vintage_index, flexible_path_setup}, $event)"
                            />
                            <feather
                              id="confirm-delete-measure-element"
                              v-tooltip="getMeasureScore({ measure, type_vintage_index }) < 1 && checkMeasureIsPreset({
                                type_vintage,
                                measure, 
                                climate_zone, 
                                prototype, 
                                flexible_path_setup, 
                                max_target_scores_by_presets: maxTargetScoresByPresets
                              }) && checkMeasureIsMandatory({ climate_zone, measure, prototype, type_vintage_index, flexible_path_setup }) ? 'This measure has a score of less than one. You can either delete it from your menu, or make it mandatory': false"
                              class="action-restore transition-all opacity-0 cursor-pointer bg-ash-200 p-0.5 rounded-sm hover:psui-text-blue-60 hover:psui-bg-blue-20"
                              :class="{'opacity-100' : showDeleteTooltip === `${climate_zone.id}-${type_vintage.id}-${measure.id}`}"
                              :type="!checkMeasureIsExcluded({ measure, climate_zone, prototype, type_vintage_index, flexible_path_setup}) ? 'trash' :'rotate-ccw'"
                              :size="20"
                              @click="openMeasureRemovalConfirmPopover({ measure, climate_zone, prototype, type_vintage, type_vintage_index, flexible_path_setup}, $event)"
                            />
                          </div>
                        </template>
                        <template v-else>
                          --
                        </template>                          
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </template>                    
        </div>
      </div>
      
      <MeasureMenuCreditsList
        :climate_zone="climate_zone"
        :type_vintages="type_vintages"
        :prototype="prototype"
        :flexible_path_setup="flexible_path_setup"
        :policy="policy"
      />
      <MeasureMenuCustomCombinationMeasuresList
        :climate_zone="climate_zone"
        :type_vintages="type_vintages"
        :prototype="prototype"
        :flexible_path_setup="flexible_path_setup"
        :policy="policy"
      />
    </div>
  </div>
</template>

<script>
import MeasureMenuTier from './measure-menu/MeasureMenuTier.vue'
import MeasureMenuCreditsList from './measure-menu/MeasureMenuCreditsList.vue'
import MeasureMenuCustomCombinationMeasuresList from './measure-menu/MeasureMenuCustomCombinationMeasuresList.vue'
import MeasureMenuService from '@/services/measure-menu/MeasureMenuGeneralService'
import StudyResultApiService from '@/services/api/StudyResultApiService'
import CustomCombinationApiService from '@/services/api/CustomCombinationApiService.js'

export default {
  name: 'PolicyFlexiblePathMeasureMenuTables',
  components: { MeasureMenuCustomCombinationMeasuresList, MeasureMenuCreditsList, MeasureMenuTier },
  props: ['type_vintages', 'prototype', 'flexible_path_setup', 'policy', 'flexiblePathService'],
  data: () => ({
    resultsRouteArgs: '',
    typeMeasuresClosed : [],
    maxTargetScoresByPresets : false,
    tooltipIsOpen: false,
    showDeleteTooltip: false,
  }),
  computed: {
    userCanEditPolicy() {
      return this.$store.getters['policy/getterUserCanEditPolicy'](this.policy.id)
    },
    getPolicyStudyType(){
      const study_type_slug = this.$store.getters['policy/getterPolicyStudyTypes']({ key: 'slug', policy_id: this.$route.params.policy_id })[0] ?? null
      if(study_type_slug) return study_type_slug
      return null
    },
  },
  mounted() {
    StudyResultApiService.getStudyResultsUrl({ 
      jurisdiction_id: this.policy.jurisdiction_id, 
      study_id: this.prototype.study.id, 
      type_prototype_id: this.prototype.type_prototype_id, 
      study_type_slug: this.getPolicyStudyType,
    }).then((route) => this.resultsRouteArgs = route)
    
    this.getMaxTargetScoresByPresets()

  },
  methods: {      
    ...MeasureMenuService,      
    openPolicyDuplicateModal() {
      this.$eventBus.$emit('openDuplicatePolicyModal', { policy : this.policy })
    },
    getMandatoryTooltip( { climate_zone, prototype, measure, type_vintage_index }) {
      if (!measure.include_in_combination) {
        const globalMeasure = this.$store.getters['globalEntities/Measure/getterGlobalMeasures']({id:measure.id})?.pop()
        return globalMeasure?.exclude_in_combination_reason || 'This measure is included as an option for flexible compliance, and cannot be made mandatory at this time.'
      } else if (this.getOverlapedMandatoryMeasures({ climate_zone , prototype, measure, type_vintage_index, flexible_path_setup: this.flexible_path_setup })) {
        return 'This measure cannot be made mandatory as it would conflict with another mandatory measure.'
      } else if (this.getOverlapedCustomComtinationMeasures({ climate_zone, measure, type_vintage_index })) {
        return 'This measure cannot be made mandatory as it would conflict with another measure used to establish cost-effectiveness.'
      } else if (this.getMeasureScore({ measure, type_vintage_index }) < 1 && this.checkMeasureIsMandatory({ climate_zone, measure, prototype, type_vintage_index, flexible_path_setup: this.flexible_path_setup })){
        return 'This measure has a score of less than one. You can either delete it from your menu, or make it mandatory'
      }
      // else if (!this.checkMeasureIsCostEffectiveness({measure, type_vintage_index})) {
      //   return false
      //   // return 'Measures that are not cost-effective cannot be mandatory.'
      // }
      return false
    },
    
    getTypeMeasureClimateZonePrefix(climate_zone_id, type_measure_id) {
      return `${climate_zone_id}-${type_measure_id}`
    },

    toggleTypeMeasure(climate_zone_id, type_measure_id) {
      const prefix = this.getTypeMeasureClimateZonePrefix(climate_zone_id, type_measure_id)
      if (this.typeMeasuresClosed.includes(prefix)) {
        this.typeMeasuresClosed = [...this.typeMeasuresClosed.filter(id => id === prefix ? false : true)]
      } else {
        this.typeMeasuresClosed = [...this.typeMeasuresClosed, prefix ]
      }
    },    

    getMaxTargetScoresByPresets() {      

      const promises = []
            
      const climate_zones = this.prototype.climate_zones
      climate_zones.forEach((climate_zone) => {
        const type_vintages = climate_zone.type_vintages 
        type_vintages.forEach((type_vintage, type_vintage_index) => {

          const vintage = this.$store.getters['globalEntities/Vintage/getterGlobalVintage']({ study_id: this.prototype.study_id, type_vintage_id: type_vintage.id })
          const getPresetsRequest = CustomCombinationApiService.getPresets({
            vintage_id : vintage.id,
            prototype_id : this.prototype.id,
            climate_zone_raw : climate_zone.raw 
          })
          const mapResponseToAddMaxTargetScoreWithTypeVintageAndZoneRaw = getPresetsRequest.then(({ data }) => {
            const payload = { type_vintage_id: type_vintage.id, climate_zone_raw: climate_zone.raw, data: {} }
            for (const preset in data.presets) {
              // Normalize preset (open packages)
              const openedPresets = data.presets[preset].map((presetItem) => {
                const measure = this.$store.getters['globalEntities/Measure/getterGlobalMeasures']({id:presetItem.id}).pop()
                if (measure?.hide_in_compliance_margin_control === true) return null
                if (!(measure?.is_package === true && measure?.hide_in_flexible_path === true)) {
                  return presetItem
                }

                return (measure?.measures || []).map(({id: pMeasureId}) => {
                  const pScoreMeasure = this.prototype?.climate_zones?.find((c) => c.raw === climate_zone.raw)?.measures?.find((m) => m.id === pMeasureId)
                  if (!pScoreMeasure) {
                    return null
                  }

                  const pMScore = this.getMeasureScore({measure: pScoreMeasure, type_vintage_index})
                  return {
                    ...presetItem,
                    excluded_measures: JSON.stringify(pScoreMeasure?.excluded_measures || []),
                    energy_savings_combined: pMScore.toString(),
                    id: pMeasureId,
                    measure_id: pMeasureId,
                    title: pScoreMeasure.title
                  }
                }).filter((m) => m)
              }).flat(Infinity)

              payload[preset] = openedPresets.reduce((prev, cc) => {
                const measure = this.$store.getters['globalEntities/Measure/getterGlobalMeasures']({id:cc.id}).pop()
                if((!measure?.hide_in_flexible_path || (measure?.is_package === true && measure?.measures?.length)) && measure?.hide_in_compliance_margin_control !== true) {
                  return prev + Math.round(cc.energy_savings_combined)
                }
                return prev
              }, 0)
              payload['data'][preset] = openedPresets
            }
            return payload
          })

          promises.push(mapResponseToAddMaxTargetScoreWithTypeVintageAndZoneRaw)
        })          
      })

      return Promise.all(promises).then(res => {
        this.maxTargetScoresByPresets = [...res]
      })

    },
    onToggleMandatoryMeasures({ measure, climate_zone, prototype, type_vintage, type_vintage_index, flexible_path_setup}, event) {
      const measureCanBeMandatory = this.checkMeasureCanBeMandatory({ climate_zone, prototype, measure, type_vintage_index, flexible_path_setup})
      
      if ( !measureCanBeMandatory ) return

      const measureIsMandatory = this.checkMeasureIsMandatory({ climate_zone, measure, prototype, type_vintage_index, flexible_path_setup })
      const measureIsCostEffectiveness = this.checkMeasureIsCostEffectiveness({ measure, type_vintage_index })
      const measureValueIsBelowOne = this.getMeasureScore({ measure, type_vintage_index }) < 1

      if ( measureIsMandatory || measureIsCostEffectiveness || measureValueIsBelowOne ) {
        this.toggleMandatoryMeasures({ climate_zone, measure, prototype, type_vintage, type_vintage_index, flexible_path_setup })
        return
      }

      this.tooltipIsOpen = `${climate_zone.id}-${type_vintage.id}-${measure.id}`

      this.$eventBus.$emit('openConfirmPopover', {
        sumLeft: 10,
        targetElem: event.target,
        guaranteeScrollElem: document.getElementById('policy-index'),
        text: 'Careful, this measure is not cost-effective on its own, requiring it is complicated.',
        okButtonText: 'Proceed anyway',
        neverAskUserProp: 'skip_non_cost_effective_measures_select_confirmation',
        learnMoreLink: 'https://intercom.help/explorer-local-energy-codes/en/articles/6805213',
        onConfirmCallback: () => {
          this.toggleMandatoryMeasures({ climate_zone, measure, prototype, type_vintage, type_vintage_index, flexible_path_setup })
          this.tooltipIsOpen = false
        },
        onCloseCallback: () => {
          this.tooltipIsOpen = false
        }
      })
    },
    openMeasureRemovalConfirmPopover({ measure, climate_zone, prototype, type_vintage, type_vintage_index, flexible_path_setup}, event){
      const measureIsExcluded = Object.hasOwn(
        this.checkMeasureIsExcluded({ measure, climate_zone, prototype, type_vintage_index, flexible_path_setup}),
        'measure_id'
      )

      const isMeasurePreset = this.checkMeasureIsPreset({
        type_vintage,
        measure,
        climate_zone,
        prototype,
        flexible_path_setup,
        max_target_scores_by_presets: this.maxTargetScoresByPresets
      })

      const getTargetScoreByVintage = this.getTierTargetScore({
        tier: this.getTier({
          climate_zone_id: climate_zone.id,
          prototype_id:prototype.id,
          flexible_path_setup
        }),
        prototype,
        flexible_path_setup,
        type_vintage,
        climate_zone,
        max_target_scores_by_presets: this.maxTargetScoresByPresets
      })
      
      const checkIfScoreIsLowerThanTargetScore = this.getMaxPossibleScore({ climate_zone, type_vintage, max_target_scores_by_presets: this.maxTargetScoresByPresets, type_vintage_index, prototype, flexible_path_setup}) - this.getMeasureScore({ measure, type_vintage_index }) < getTargetScoreByVintage
      if(checkIfScoreIsLowerThanTargetScore && isMeasurePreset && !measureIsExcluded) {
        this.$eventBus.$emit('openConfirmPopover', {
          targetElem: event.target,
          guaranteeScrollElem: document.getElementById('policy-index'),
          text: 'Careful, according to cost-effectiveness study results, removing this measure makes it impossible for permit applicants to meet the Target Score using only cost-effective measures.',
          neverAskUserProp: 'flexible_path_delete_measure_with_slower_target_score',
          buttons: ['Proceed and adjust the target score', 'Proceed without adjusting the target score'],
          learnMoreLink: 'https://intercom.help/explorer-local-energy-codes/en/articles/7903616-max-cost-effective-score-calculations',
          side: 'left',
          onConfirmCallback: ({selectedButtonIdx}) => {
            if (selectedButtonIdx === 0) {
              const adjustedTargetScore = this.getMaxPossibleScore({ climate_zone, type_vintage, max_target_scores_by_presets: this.maxTargetScoresByPresets, type_vintage_index, prototype, flexible_path_setup}) - this.getMeasureScore({ measure, type_vintage_index })
              this.setTierTargetScore({ type_vintage, climate_zone, prototype, type_vintage_index, flexible_path_setup, value: adjustedTargetScore })
            }
            this.toggleExcludedMeasures({ measure, climate_zone, prototype, type_vintage, type_vintage_index, flexible_path_setup})
            this.showDeleteTooltip = false
          },
          onCloseCallback: () => {
            this.showDeleteTooltip = false
          }
        })
        return
      }

      this.toggleExcludedMeasures({ measure, climate_zone, prototype, type_vintage, type_vintage_index, flexible_path_setup})
      this.showDeleteTooltip = false

      // const measureValueIsBelowOne = this.getMeasureScore({ measure, type_vintage_index }) < 1
      // if(measureIsExcluded || !isMeasurePreset || (measureValueIsBelowOne && isMeasurePreset)) {
      //   this.toggleExcludedMeasures({ measure, climate_zone, prototype, type_vintage, type_vintage_index, flexible_path_setup})
      //   return
      // }
      //
      // this.showDeleteTooltip = `${climate_zone.id}-${type_vintage.id}-${measure.id}`
      // this.$eventBus.$emit('openConfirmPopover', {
      //   targetElem: event.target,
      //   guaranteeScrollElem: document.getElementById('policy-index'),
      //   text: 'This measure is used to cost-effectively meet the Max Cost-Effective Score. Deleting it will decrease your Max Possible Score.',
      //   neverAskUserProp: 'flexible_path_measure_confirm_removal',
      //   okButtonText: 'I understand, delete it anyway',
      //   side: 'left',
      //   onConfirmCallback: () => {
      //     this.toggleExcludedMeasures({ measure, climate_zone, prototype, type_vintage, type_vintage_index, flexible_path_setup})
      //     this.showDeleteTooltip = false
      //   },
      //   onCloseCallback: () => {
      //     this.showDeleteTooltip = false
      //   }
      // })
    }
  }
}
</script>

<style scoped lang="scss">
  ::v-deep .cell-width {
    min-width: 144px;
  }
  .measure-title span {
    max-height: 100%;
    width: 100%;
    display: block;
    overflow-y: auto;
  }

  .flexiblepath-prototypecard {
    max-width: 920px;
    box-shadow: 0px 0px 5px rgba(0, 42, 58, 0.05), 0px 3px 10px rgba(0, 42, 58, 0.05);
  }
  
  ::v-deep .measure-cell {
        
    span:before {
      content: attr(data-measure-score-value);      
    }
  }

  
</style>
