<template>
  <div class="w-full p-6">
    <div class="w-full flex flex-col bg-ash-200">
      <div class="w-full p-6 px-8 border-b border-ash-200 bg-white flex justify-between">
        <PageTitle title="Feature Flags" />
      </div>
    </div>
    <div class="flex flex-col">
      <router-link :to="{ name: 'AdminFeatureFlagCreate' }">
        <PsButton
          icon="add"  
          class="mt-2 ml-auto"
          label="Create"
        />
      </router-link>
      <table
        v-if="feature_flags.length > 0"
        class="table table-bordered mt-4 whitespace-no-wrap"
      >
        <thead>
          <tr>
            <th>ID</th>
            <th>Type</th>
            <th>Value</th>
            <th>Feature</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="feature_flag in feature_flags"
            :key="feature_flag.id"
          >
            <td>{{ feature_flag.id }}</td>
            <td>{{ feature_flag.type }}</td>
            <td>{{ feature_flag.value }}</td>

            <td>{{ feature_flag.feature }}</td>
            <td class="psui-flex psui-space-x-2">
              <router-link 
                :to="{ name: 'AdminFeatureFlagEdit', params: {feature_flag_id:feature_flag.id} }"
              >
                <PsButton
                  label="Edit"
                  icon="edit"
                  size="small"
                />
              </router-link>
              <PsButton
                label="Delete"
                layout="caution"
                icon="edit"
                size="small"
                @click="deleteFeatureFlag(feature_flag.id)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import FeatureFlagsApiService from '@/services/api/FeatureFlagsApiService.js'

export default {


  name: 'AdminFeatureFlagIndex',
  data() {
    return {
      feature_flags: [],
    }
  },
  mounted() {
    this.init()
  },
  methods:{
    async init() {
      return FeatureFlagsApiService.getFeatureFlags()
      .then((feature_flags) => {
        this.feature_flags = feature_flags
      })
    },
    async deleteFeatureFlag(feature_flag_id) {
      return await FeatureFlagsApiService.delete(feature_flag_id)
      .then( async () => {
        return this.init()
      })
    }
  }
}
</script>
