import {
  initial_cost,
  annual_bill_savings,
  on_bill_cost_ratio,
  lifecycle_savings,
  compliance_margin,
  tdv2022_benefit_to_cost_ratio,
  tdv_benefit_to_cost_ratio,
  on_bill_cost_ratio_2025,
  on_bill_cost_ratio_2025_care,
  lsc_2025_benefit_to_cost_ratio,
} from '@/modules/app/shared/default-app-columns'

export default {
  existingBuildings: {
    key: 'existingBuildings',
    studyType: 'Existing Buildings',
    columnGroups: [
      {
        order: 0,
        key: "fixed_columns",
        title: 'Cost-Effectiveness',
        columns: [
          on_bill_cost_ratio(),
          on_bill_cost_ratio_2025(),
          on_bill_cost_ratio_2025_care(),
          tdv2022_benefit_to_cost_ratio(),
          lsc_2025_benefit_to_cost_ratio(),
        ],
        hasHelper: {
          type: 'helper',
          id: 23
        }
      },
      {
        order: 1,
        key: "per_home_results",
        title: 'Per Home Results',
        columns: [
          initial_cost(),
          annual_bill_savings(),
          lifecycle_savings(),
        ],
        hasHelper: {
          type: 'helper',
          id: 24
        }
      }
    ]
  },
  newBuildings: {
    key: 'newBuildings',
    studyType: 'New Buildings',
    columnGroups: [
      {
        order: 0,
        key: "fixed_columns",
        title: 'Cost-Effectiveness',
        columns: [
          on_bill_cost_ratio(),
          tdv_benefit_to_cost_ratio(),
        ],
        hasHelper: {
          type: 'helper',
          id: 23
        }
      },
      {
        order: 1,
        key: "per_home_results",
        title: 'Per Home Results',
        columns: [
          initial_cost(),
          annual_bill_savings(),
          lifecycle_savings(),
          compliance_margin(),
        ],
        hasHelper: {
          type: 'helper',
          id: 24
        }
      }
    ]
  }
}
