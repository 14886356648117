import Store from '@/store'
import { objArrayToCsv, downloadRawData } from '@igortrindade/lazyfy'
import { BUILDING_ESTIMATES_BASE_YEARS } from '@/modules/app/assumptions/shared/enums'
import { joinCommaPlusAnd } from '@/util/Functions'

export default class BuildingEstimatesGenerateCsvService {

  constructor() {
    return this.generateAll()
  }
  generateAll() {
    return new Promise((resolve) => {
      if(this.isResidential) this.generateBuildingStocks()
      this.generateBuildingEstimates()
      resolve()
    })
  }

  generateBuildingStocks() {

    const type_prototypes = this.getTypePrototypes().filter((type_prototype) => type_prototype.slug  !== 'adu')
    const climate_zones = this.getClimateZoneSelected()
    const type_vintages = Store.getters['globalEntities/TypeVintage/getterGlobalTypeVintages']({ hide_in_building_estimates: false })

    const rows = []

    for(const climate_zone of climate_zones) {
      for(const type_prototype of type_prototypes) {
        for(const type_vintage of type_vintages) {
          
          const units = Store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
            type_vintage_id: type_vintage.id,
            type_prototype_id: type_prototype.id,
            climate_zone_prefix: climate_zone.prefix
          })

          rows.push({
            "Climate Zone": climate_zone.prefix,
            "Building Type": type_prototype.building_stock_title || type_prototype.title,
            "Vintage": type_vintage.title,
            "Units": units,
          })

        }

      }
    }

    let csvString = ''
    
    if (this.getHeaderExistings(type_prototypes)) csvString += this.getHeaderExistings(type_prototypes) + '\n'
    
    csvString += objArrayToCsv(rows, ',')

    const filename = `${Store.getters['lastJurisdictionVisited']?.slug}-existing-residential-buildings.csv`

    downloadRawData(csvString, filename)

  }

  generateBuildingEstimates() {
    
    const type_prototypes = this.getTypePrototypes()
    const climate_zones = this.getClimateZoneSelected()

    const rows = []
    const unitsType = this.isResidential ? 'Units' : 'ft²'

    for(const climate_zone of climate_zones) {
      for(const type_prototype of type_prototypes) {
        for(const year of BUILDING_ESTIMATES_BASE_YEARS) {
          
          const units = Store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits']({
            year: year,
            type_prototype_id: type_prototype.id,
            climate_zone_prefix: climate_zone.prefix
          })

          const title = (type_prototype.building_stock_title || type_prototype.title).replace(/,/g, ' -')

          rows.push({
            "Climate Zone": climate_zone.prefix,
            "Building Type": title,
            "Year": year,
            [unitsType]: units,
          })

        }

      }
    }

    let csvString = ''
    
    if (this.getHeaderFuture(type_prototypes)) csvString += this.getHeaderFuture(type_prototypes) + '\n'
    
    csvString += objArrayToCsv(rows, ',')

    const filename = `${Store.getters['lastJurisdictionVisited']?.slug}-new-residential-buildings.csv`

    downloadRawData(csvString, filename)

  }

  getHeaderExistings(type_prototypes) {
    const buildingTypes = joinCommaPlusAnd(type_prototypes.map((type_prototype) => type_prototype.building_stock_title || type_prototype.title))
    const titleType = Store.getters['lastJurisdictionVisited']?.titles.title_type
    return `${ titleType } - Existings Units\n\n` +
      `Building Types: ${ buildingTypes } \n\n` +
      `SOURCE: Cost-Effectiveness Explorer - Exported - ${ window.$vueInstance.formatDate({date: new Date()}) }${ this.hasCustomBuildingStocks }\n` +
      `URL: ${window.location.href}\n`
  }

  getHeaderFuture(type_prototypes) {
    const buildingTypes = joinCommaPlusAnd(type_prototypes.map((type_prototype) => type_prototype.building_stock_title || type_prototype.title))
    const titleType = Store.getters['lastJurisdictionVisited']?.titles.title_type
    return `${ titleType } - ${this.isResidential ? 'New Units Forecasts' : 'New Construction Forecast'}\n\n` +
      `Building Types: ${ buildingTypes } \n\n` +
      `SOURCE: Cost-Effectiveness Explorer - Exported - ${ window.$vueInstance.formatDate({date: new Date()}) }${ this.hasCustomBuildingEstimates }\n` +
      `URL: ${window.location.href}\n`
  }

  get hasCustomBuildingStocks () {
    return Store.getters['assumptions/buildingStocks/getterCustomJurisdictionBuildingStocks']().length ? ', some figures edited by user' : ''
  }

  get hasCustomBuildingEstimates () {
    return Store.getters['assumptions/buildingEstimates/getterCustomJurisdictionBuildingEstimates']().length ? ', some figures edited by user' : ''
  }

  get getBuildingTypeSelected() {
    return Store.getters['globalEntities/BuildingType/getterBuildingTypeSelected'](window.$vueInstance.$route.fullPath)
  }

  get isResidential () {
    return this.getBuildingTypeSelected.slug === 'residental-buildings'
  }

  getTypePrototypes() {
    return Store.getters['globalEntities/TypePrototype/getterGlobalTypePrototypes']({ building_type_id: this.getBuildingTypeSelected.id })
      .filter((type_prototype) => type_prototype.hide_in_building_estimates === false)
  }

  getClimateZoneSelected() {
    const climate_zones = Store.getters['lastJurisdictionVisited']?.climate_zones ?? []
    return climate_zones
      .filter((climate_zone) => !window.$vueInstance.checkQueryKeyValue('exclude_climate_zone_prefix', climate_zone.prefix))
  }

}
