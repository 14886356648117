<template>
  <BaseDrawer 
    ref="baseDrawer" 
    title="Add/Hide Columns"
    subtitle="Select or re-arrange columns to display"
    :hierarchy="DRAWER_HIERARCHY.SECONDARY"
  >
    <div class="psui-flex psui-pr-4 pb-2 mb-4/5">
      <div 
        class="border-b-2 py-3 psui-mr-4"
        :class="[studyTypeSelected == 'Existing Buildings' ? 'psui-border-blue-60 text-pri font-bold' : 'border-blue04-10 text-gray font-semi-bold']"
      >
        <button 
          :class="[studyTypeSelected == 'Existing Buildings' ? 'psui-border-blue-60 psui-text-blue-60 font-bold' : 'border-blue04-10 text-gray font-semi-bold']"
          class="focus:outline-none"
          @click="studyTypeSelected = 'Existing Buildings'"
        >
          Existing Buildings
        </button>
      </div>
      <div 
        class="border-b-2 py-3" 
        :class="[studyTypeSelected == 'New Buildings' ? 'psui-border-blue-60 text-pri font-bold' : 'border-blue04-10 text-gray font-semi-bold']"
      >
        <button 
          :class="[studyTypeSelected == 'New Buildings' ? 'psui-border-blue-60 psui-text-blue-60 font-bold' : 'border-blue04-10 text-gray font-semi-bold']"
          class="focus:outline-none"
          @click="studyTypeSelected = 'New Buildings'"
        >
          New Buildings
        </button>
      </div>
    </div>
    <DragAndDropEditColumnsList
      v-if="studyData"
      :get-columns="studyData"
      :module="module"
    />
  </BaseDrawer>
</template>

<script>
import { DRAWER_HIERARCHY } from '@/util/Enums.js'
import { updateStudyResultsColumnGroupTitleAndHelper, updateColumnCopiesAndHelpers } from '@/util/Functions.js'

export default {
  name: 'StudyResultsEditColumnsDrawer',
  data: () => ({
    module: 'studyResults',
    studyTypeSelected: 'Existing Buildings',
    disableCityWide: false,
    DRAWER_HIERARCHY,
    studyDataOrdered: null,
    studyId: null,
    studyData: null
  }),
  computed: {
    getNewBuildingsColumns() {
      return this.$store.getters["studyResults/getterNewBuildingsColumns"] 
    },
    getExistingBuildingsColumns() {
      return this.$store.getters["studyResults/getterExistingBuildingsColumns"]
    },
    getStudyTypes() {
      return this.$store.getters['globalEntities/StudyType/getterGlobalStudyTypes']()
    },
    getColumns() {

      let columns =  this.studyTypeSelected === 'New Buildings' ? { ...this.getNewBuildingsColumns } : { ...this.getExistingBuildingsColumns }

      columns.columnGroups = columns.columnGroups.map(cg => {
        const { columnGroupTitle } = updateStudyResultsColumnGroupTitleAndHelper(this.studyId, cg)
        return { ...cg, title: columnGroupTitle }
      })

      if (this.studyTypeSelected === 'New Buildings') {
        columns.columnGroups = [...columns.columnGroups].filter((column) => {
          return !this.disableCityWide || column.key !== 'city_wide_impact'
        }).map((columnGroup) => {
          if(!this.studyId) {
            return columnGroup
          }
          columnGroup.columns = columnGroup.columns.map(column => {
            const { columnTitle } = updateColumnCopiesAndHelpers(this.studyId, column)
            return { ...column, title: columnTitle }
          })
          return { ...columnGroup,}
        })
        return columns
      }

      return columns
    },
  },
  watch: {
    studyId: {
      handler() {
        this.$forceUpdate()
        this.getModifiedColumns()
      },
      deep: true
    },
    getNewBuildingsColumns: {
      handler() {
        this.getModifiedColumns()
      },
      deep: true
    },
    getExistingBuildingsColumns: {
      handler() {
        this.getModifiedColumns()
      },
      deep: true
    }
  },
  mounted() {
    this.$eventBus.$on('openStudyResultsDrawerEditColumns', (studyType, disableCityWide = false, studyDataOrdered, studyId) => {
      this.studyDataOrdered = studyDataOrdered
      this.studyId = studyId
      if (studyType) {
        this.studyTypeSelected = studyType
      }
      this.disableCityWide = disableCityWide
      
      this.getModifiedColumns()

      this.$refs.baseDrawer.openDrawer()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openStudyResultsDrawerEditColumns')
  },
  methods:{
    getModifiedColumns() {
      if (!this.studyId) return 
      if (!this.studyDataOrdered) this.studyData = this.getColumns

      const columns = {...this.getColumns}
      const updatedGroups = columns.columnGroups.map(group => {
        const disableColumnKeys = ['compliance_margin', 'edr1_total_margin']
        const removeColumnKeys = ['tdv2022_benefit_to_cost_ratio', 'tdv_benefit_to_cost_ratio', 'on_bill_cost_ratio', 'lsc_2025_benefit_to_cost_ratio', 'on_bill_cost_ratio_2025', 'on_bill_2025_care']
        let emptyColumnsCount = 0
        group.columns = group.columns.map((col) => {
          if (this.getIfColumnShouldBeDisabled(col.key, this.studyDataOrdered)) {
            emptyColumnsCount++
            if (disableColumnKeys.includes(col.key)) {
              col = {
                ...col,
                isActive: false,
                isDisabled: true,
                tooltip: 'This data is currently only available for Single Family Homes and ADUs. Expand your filter selections to select this column.'
              }
            }
            if (removeColumnKeys.includes(col.key)) {
              col = {
                ...col,
                isActive: false,
                isDisabled: true,
                tooltip: 'This data is not available for this study.'
              }
            }
          }
          return col
        }).filter((i) => i)
        const allColumnsEmpty = Boolean(emptyColumnsCount >= (group?.columns?.length || 0))
        if (!allColumnsEmpty) {
          return group
        }

        const inactiveColumns = group.columns.map(col => ({ ...col, isActive: false }))
        const disabledTitle = group.key === 'per_home_subsidy_needed_columns' ? 'Subsidy values are not available for this study' : `${group.title} columns are not available for this study`
        return { ...group, columns: inactiveColumns, disabled: allColumnsEmpty, disabledTitle }
      })

      this.studyData = { ...columns, columnGroups: updatedGroups }
    },
  },
}
</script>
<style lang="scss" scoped>
  .app-drawer {
    width: 460px;
  }
  ::v-deep {
    &.app-drawer {
      width: 478px;
    }
  }
</style>
