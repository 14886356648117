import Graphql from '@/util/GraphQL'
import Api from '@/util/Api'
import TypePrototype from '@/models/TypePrototype'
import Tier from '@/models/MeasureMenuTier'
import MeasureMenuGeneralService from '@/services/measure-menu/MeasureMenuGeneralService'
import { find } from '@/util/Functions'
import { DEFAULT_REQUIREMENT_LEVEL } from '@/business-logic/constants/flexiblePathSetup'
import EventBus from '@/util/EventBus'
import * as sha1 from 'sha1'
import asyncCacheResolver from '@/util/asyncCacheResolver'
import Store from '@/store'

const cache = {}
export default class FlexiblePathService {
  
  constructor({ policy_id }) {
    this.policy_id = policy_id
  }

  getSetup() {
        
    const query = `
      {
        flexible_path_setup (
          where: [
            { column: "policy_id", operation: "=", value: "${this.policy_id}"}
          ]
        ) {
          id
          policy_id
          data
        }
      }
    `

    const key = `FlexiblePathService:${sha1(query)}`
    return asyncCacheResolver(key, ()=>{
      return Graphql({ query, shouldCache: false, caller: `FlexiblePathService.getSetup` }).then(({ data }) => {
        this.setup = data?.flexible_path_setup?.data ? data.flexible_path_setup.data : this.getDefaultSetup()
        return this.checkSetupVersion()
      })
    }, 3)


  }

  async updateSetup(flexible_path_setup, ignoreFeedback = true) {
    this.setup = flexible_path_setup
    const userCanEditThisPolicy = Store.getters['policy/getterUserCanEditPolicy'](this.policy_id)
    if(!userCanEditThisPolicy) return this.setup

    return await Api.post(`/api/flexible_path_setup/updateOrCreate`, { policy_id: this.policy_id, data: this.setup }, { ignoreFeedback }).then((result) => {
      EventBus.$emit('FlexPathUpdated')
      return result
    })
  }

  getFlexibleCombinationCachingKey({ climate_zone_raw, prototype_id, vintage_id, target_score, mandatory_measures, excluded_measures }) {
    const convertArrayToStr = (array) => {
      return array.map((i) => +i).sort((a ,b) => a - b).join(',')
    }
    const mandatorySortedStr = mandatory_measures?.length ? convertArrayToStr(mandatory_measures) : ''
    const excludedSortedStr = excluded_measures?.length ? convertArrayToStr(excluded_measures) : ''
    const paramsHash = `cz-${climate_zone_raw}:prototype-${prototype_id}:v-${vintage_id}:m-${mandatorySortedStr}:e-${excludedSortedStr}:t-${target_score}`
    return `flexibleCombination:${sha1(paramsHash)}`
  }

  async getFlexibleCombinationsByTargetScores({ climate_zone_raw, prototype_id, vintage_id, preset, target_score, mandatory_measures, excluded_measures }) {
    if (!climate_zone_raw || !prototype_id || !vintage_id || !preset || !target_score) {
      return null
    }

    const cachingKey = this.getFlexibleCombinationCachingKey({ climate_zone_raw, prototype_id, vintage_id, target_score, mandatory_measures, excluded_measures })
    if (cache[cachingKey]) {
      return cache[cachingKey]
    }

    return await Api.get(`/api/combination_with_lowest_incremental_cost`, {
      params: {
        climate_zone_raw,
        prototype_id,
        vintage_id,
        preset,
        target_score: target_score || undefined,
        mandatory_measures: mandatory_measures?.length ? mandatory_measures.join(',') : undefined,
        excluded_measures: excluded_measures?.length ? excluded_measures.join(',') : undefined,
      }
    }).then((result) => {
      const combinations = Array.isArray(result.data?.combination_with_lowest_incremental_cost_for_min_target_scores) ?
          result.data?.combination_with_lowest_incremental_cost_for_min_target_scores : []

      // Save all responses on our cache and just return the requested one
      combinations.forEach((c) => {
        if (!c?.min_target_score) return
        const cacheKey = this.getFlexibleCombinationCachingKey({ climate_zone_raw, prototype_id, vintage_id, target_score: +c.min_target_score, mandatory_measures, excluded_measures })
        cache[cacheKey] = c
      })

      return combinations.find((c) => +c?.min_target_score === +target_score) || null
    }).catch(() => {
      return null
    })
  }

  getDefaultSetup() {
    return {
      version: 3,
      tiers: [],
      cost_effective_presets: [] // [{ prototype_id: X, climate_zone_id: X, preset: Y }, ...]
    }
  }

  getTypePrototypes() {
    return Api.get(`/api/measure_menu/get_type_prototypes/${this.policy_id}`, { shouldCache: false })
        .then(({ data }) => data.type_prototypes.map(obj => new TypePrototype(obj)))
  }

  getTypePrototypeItems({ type_prototype_id }) {
    return Api.get(`/api/measure_menu/get_prototypes/${this.policy_id}/${type_prototype_id}`, { shouldCache: false })
      .then(({ data }) => {
        data.prototypes.forEach(prototype => {
          prototype.climate_zones.forEach(climate_zone => {
            climate_zone.type_measures.forEach(type_measure => {
              type_measure.measures.sort((a, b) => a.order - b.order)
            })
          })
        })
        return data
      }) 
  }

  checkSetupVersion() {
    if (!this.setup.version) {
      return this.migrateFromVersionOneToThree() 
    } else if (this.setup.version === 2) {
      return this.migrateFromVersionTwoToThree() 
    }
    return this.setup
  }

  async migrateFromVersionTwoToThree() {
    
    const setup = {
      ...this.getDefaultSetup(),
      tiers: this.setup.tiers
    }
    
    this.updateSetup(setup)
    return setup
  }

  async migrateFromVersionOneToTwo() {
    let tiers = []
    const _version_1_data = {...this.setup }
    
    const hasAnyCustomSetupConfiguration = () => {
      return (this.setup.type == 'global' && this.setup.global_target_score_agressiveness != DEFAULT_REQUIREMENT_LEVEL) || (this.setup.type == 'climate_zone' && this.setup.climate_zones_target_score_setups.length > 0) ? true : false
    }

    const getTargetScore = ({ prototype, climate_zone, type_vintage_index }) => {
      // TODO - maxValue will broke as we are not sending the right params
      const maxValue = MeasureMenuGeneralService.getMaxCostEffectiveScore({ climate_zone, prototype, type_vintage_index, flexible_path_setup: this.setup })
      const requirement_level = this.setup.type == 'global' ? this.setup.global_target_score_agressiveness : find(this.setup.climate_zones_target_score_setups, { prototype_id: prototype.id, climate_zone_id : climate_zone.id })?.target_score_agressiveness ?? null
      return requirement_level ? Math.round(maxValue * (requirement_level/100) ) : false
    }

    if (hasAnyCustomSetupConfiguration()) {
      
      const type_prototypes = await this.getTypePrototypes()
      for( let type_prototype of type_prototypes) {
        const { prototypes, type_vintages } = await this.getTypePrototypeItems({ type_prototype_id: type_prototype.id })        
        for ( const prototype of prototypes) {                
          for ( const climate_zone of prototype.climate_zones) {            
            const tier = new Tier({ 
              prototype_id: prototype.id, 
              climate_zone_id : climate_zone.id,
              excluded_measures: _version_1_data.excluded_measures.filter(o => o.climate_zone_id == climate_zone.id && o.prototype_id == prototype.id),
              mandatory_measures: _version_1_data.pinned_measures.filter(o => o.climate_zone_id == climate_zone.id && o.prototype_id == prototype.id)
            })
            for ( const type_vintage_index in type_vintages) {     
              const value = getTargetScore({ climate_zone, prototype, type_vintage_index })
              if (value) tier.updateTargetScore({ type_vintage_id: type_vintages[type_vintage_index].id, value })
            }
            if (tier.target_scores.length > 0) {
              tiers.push(tier)
            }
          }
        }
      }

    }
  
    const setup = {
      ...this.getDefaultSetup(),
      from_version: 1,
      _version_1_data
    }
    setup.tiers = tiers    
   this.updateSetup(setup)
   return setup
  }

}