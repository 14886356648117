<template>
  <div class="state-program-referral w-full flex">
    <StateProgramReferralLogo />
    <p>
      Find more reach code resources at <a
        href="https://localenergycodes.com"
        target="_blank"
        title="Statewide Reach Codes Program"
      >LocalEnergyCodes.com</a>
    </p>
  </div>
</template>

<script>
import StateProgramReferralLogo from './StateProgramReferralLogo.vue'

export default {
  name: 'StateProgramReferral',
  components: { StateProgramReferralLogo }
}
</script>

<style lang="scss" scoped>
  .state-program-referral {
    display: flex;
    align-items: center;
    ::v-deep .logo-state-wrapper img {
      margin-right: 2em;
      padding-right: 2em;
      max-width: 150px;
      border-right: 1px solid #4B8CA680;
    }
  }
</style>