import sumItemsWeightedByAttr from '@/business-logic/services/calcs/sumItemsWeightedByAttr'
import getPolicyCostRatioColumnValue from '@/business-logic/services/policies/getPolicyCostRatioColumnValue'

export default ({ itemData, itemsRef }) => {


  // Per home cols
  itemData.initial_cost = sumItemsWeightedByAttr(itemsRef, 'initial_cost', 'forecast_units_affected')
  itemData.annual_bill_savings = sumItemsWeightedByAttr(itemsRef, 'annual_bill_savings', 'forecast_units_affected')
  itemData.annual_bill_savings_avg = sumItemsWeightedByAttr(itemsRef, 'annual_bill_savings_avg', 'forecast_units_affected')
  itemData.emissions_savings = sumItemsWeightedByAttr(itemsRef, 'emissions_savings', 'forecast_units_affected')
  itemData.lifecycle_savings = sumItemsWeightedByAttr(itemsRef, 'lifecycle_savings', 'forecast_units_affected')
  itemData.kwh_savings = sumItemsWeightedByAttr(itemsRef, 'kwh_savings', 'forecast_units_affected')
  itemData.therms_savings = sumItemsWeightedByAttr(itemsRef, 'therms_savings', 'forecast_units_affected')
  itemData.energy_savings_combined = sumItemsWeightedByAttr(itemsRef, 'energy_savings_combined', 'forecast_units_affected')
  
  // Per home subsidy
  itemData.subsidy_needed = sumItemsWeightedByAttr(itemsRef, 'subsidy_needed', 'forecast_units_affected')
  itemData.five_year_payback = sumItemsWeightedByAttr(itemsRef, 'five_year_payback', 'forecast_units_affected')
  itemData.subsidy_needed_care = sumItemsWeightedByAttr(itemsRef, 'subsidy_needed_care', 'forecast_units_affected')
  itemData.five_year_payback_care = sumItemsWeightedByAttr(itemsRef, 'five_year_payback_care', 'forecast_units_affected')
  

  const on_bill_cost_ratio = getPolicyCostRatioColumnValue({ cost_ratio_column: 'on_bill_cost_ratio', studyDataItems: itemsRef, initial_cost: itemData.initial_cost, annual_bill_savings_avg: itemData.annual_bill_savings_avg })
  const tdv_benefit_to_cost_ratio = getPolicyCostRatioColumnValue({ cost_ratio_column: 'tdv_benefit_to_cost_ratio', studyDataItems: itemsRef, initial_cost: itemData.initial_cost, annual_bill_savings_avg: itemData.annual_bill_savings })
  const on_bill_cost_ratio_2025 = getPolicyCostRatioColumnValue({ cost_ratio_column: 'on_bill_cost_ratio_2025', studyDataItems: itemsRef, initial_cost: itemData.initial_cost, annual_bill_savings_avg: itemData.annual_bill_savings_avg })
  const lsc_2025_benefit_to_cost_ratio = getPolicyCostRatioColumnValue({ cost_ratio_column: 'lsc_2025_benefit_to_cost_ratio', studyDataItems: itemsRef, initial_cost: itemData.initial_cost, annual_bill_savings_avg: itemData.annual_bill_savings_avg })
  const on_bill_2025_care = getPolicyCostRatioColumnValue({ cost_ratio_column: 'on_bill_2025_care', studyDataItems: itemsRef, initial_cost: itemData.initial_cost, annual_bill_savings_avg: itemData.annual_bill_savings_avg })

  // Cost effectiveness cols
  itemData.on_bill_cost_ratio = on_bill_cost_ratio
  itemData.tdv_benefit_to_cost_ratio = tdv_benefit_to_cost_ratio
  itemData.on_bill_cost_ratio_2025 = on_bill_cost_ratio_2025
  itemData.lsc_2025_benefit_to_cost_ratio = lsc_2025_benefit_to_cost_ratio
  itemData.on_bill_2025_care = on_bill_2025_care
  itemData.tdv2022_benefit_to_cost_ratio = itemsRef.reduce((acc, item) => acc + item.tdv2022_benefit_to_cost_ratio, 0)
  itemData.simple_payback = sumItemsWeightedByAttr(itemsRef, 'simple_payback', 'initial_cost')

  // City wide cols
  itemData.forecast_units_affected = itemsRef.reduce((acc, item) => acc + item.forecast_units_affected, 0)
  itemData.forecast_initial_cost = itemsRef.reduce((acc, item) => acc + item.forecast_initial_cost, 0)
  itemData.forecast_emissions_savings = itemsRef.reduce((acc, item) => acc + (item?.forecast_emissions_savings || 0), 0)
  itemData.forecast_kwh_savings = itemsRef.reduce((acc, item) => acc + item.forecast_kwh_savings, 0)
  itemData.forecast_therms_savings = itemsRef.reduce((acc, item) => acc + item.forecast_therms_savings, 0)
  itemData.forecast_lifecycle_savings = itemsRef.reduce((acc, item) => acc + item.forecast_lifecycle_savings, 0)

  // City wide subsidy cols
  itemData.city_wide_subsidy_needed = itemsRef.reduce((acc, item) => acc + item.city_wide_subsidy_needed, 0)
  itemData.city_wide_five_year_payback = itemsRef.reduce((acc, item) => acc + item.city_wide_five_year_payback, 0)
  itemData.city_wide_subsidy_needed_care = itemsRef.reduce((acc, item) => acc + (item?.city_wide_subsidy_needed_care || 0), 0)
  itemData.city_wide_five_year_payback_care = itemsRef.reduce((acc, item) => acc + item.city_wide_five_year_payback_care, 0)

}
