<template>
  <div class="w-full h-full">
    <PolicyShowContentHeader
      :title="`Policy Requirements` | policyTextRephrase"
      :subtitle="`Edit the requirements of your policy` | policyTextRephrase"
    />
    <div
      v-if="policy && policy.is_flexible_path"
      class="w-full grid grid-cols-12"
    >
      <div 
        class="flexiblepath-sidebar flex flex-col pt-11 bg-white border-t border-l border-b rounded-l-6 border-ash-200 px-4"
        :class="{
          'col-span-12 pl-10 pr-10' : getterContentContainerWidth <= 1080,
          'col-span-3 pr-0' : getterContentContainerWidth > 1080,
          'pb-7 pl-10' : getterContentContainerWidth >= 1380,
        }"
      >
        <div
          class="w-full flex justify-start flex-wrap"
          :class="{'space-y-2' : getterContentContainerWidth > 1080 && getterContentContainerWidth < 1250,}"
        >
          <button 
            class="text-header-2 text-ash-700 flex items-center transition-all hover:opacity-70 mr-5"
            @click="$eventBus.$emit('openModalPolicyFlexiblePathDownload')"
          >
            <feather
              type="download"
              class="text-deepsky-450 mr-2 flex-shrink-0"
            />
            Download tables
          </button>
          <button 
            class="text-header-2 text-ash-700 flex items-center transition-all hover:opacity-70 mr-5"
            @click="$store.commit('tutorial/setTutorialShow', { type: 'flexiblePath', show: true })"
          >
            <feather
              type="info"
              class="text-deepsky-450 mr-2 flex-shrink-0"
            />
            Quick Tutorial
          </button>
        </div>
        <div class="w-full flex flex-col pr-6">
          <p class="text-header-2 text-ash-500 mt-4">
            Allow permit applicants to choose the measures that work best for them from a menu.
          </p>
          <button
            class="text-header-2 flex justify-start text-deepsky-500 text-sm font-bold mt-2"
            @click="$store.commit('tutorial/setTutorialShow', { type: 'flexiblePath', show: true })"
          >
            Learn more
          </button>
        </div>
        <template v-if="type_prototypes.length > 0">
          <div 
            class="w-full mt-6 grid"
            :class="{
              'grid-cols-2 gap-4' : getterContentContainerWidth <= 1080,
              'grid-cols-1 gap-0' : getterContentContainerWidth > 1080,
            }"
          >
            <button
              v-for="type_prototype of type_prototypes" 
              :key="`type_prototype-${type_prototype.id}`"              
              class="w-full flex items-center space-x-4 p-3 transition duration-300"
              :class="[ 
                type_prototype.id === type_prototype_selected.id ? 'bg-deepsky-100 text-deepsky-450 border-deepsky-450' : 'bg-ash-200 text-ash-500 border-ash-300 hover:bg-deepsky-100 hover:text-deepsky-450 hover:border-deepsky-450',
                {
                  'border-b-4' : getterContentContainerWidth <= 1080,
                  'border-b-0 border-r-4 my-2 rounded-l-md' : getterContentContainerWidth > 1080,
                }
              ]"
              @click="setTypePrototype(type_prototype)"
            >
              <div                 
                class="p-3 rounded-full"
                :class="[
                  { 'hidden' : getterContentContainerWidth > 1080 && getterContentContainerWidth < 1380 }, 
                  type_prototype.id == type_prototype_selected.id ? 'bg-deepsky-200' : 'bg-ash-200'
                ]"
              >
                <inline-svg 
                  :src="`${type_prototype.custom_fields.data.icon.value}`"
                  width="48" 
                  height="48"
                  aria-label="Type prototype"
                  :stroke="type_prototype.id == type_prototype_selected.id ? '#69A7BF' : '#A9AEB0'"
                />
              </div>
              <h3 class="text-header-3 font-bold">
                {{ type_prototype.title }}
              </h3>
            </button>
            <p class="ts--accent--2 mt-5 mb-8 text-gray03">
              *Please note that the Flexible Paths feature is still in beta. Please <a
                class="underline text-gray03"
                target="_blank"
                href="https://localenergycodes.com/content/contact-us"
                title="Contact us"
              >email us</a> any feedback or corrections.
            </p>
          </div>
        </template>
        <SkeletonTypePrototypeList v-else />
      </div>

      <div
        class="flexiblepath-content w-full flex flex-col p-10 bg-ash-200 overflow-y-auto rounded-r-6"
        :class="{
          'col-span-12' : getterContentContainerWidth <= 1080,
          'col-span-9' : getterContentContainerWidth > 1080,
        }"
      >    
        <template v-if="prototypes.length && flexible_path_setup">
          <PolicyFlexiblePathMeasureMenuTables
            v-for="(prototype, index) of prototypes"
            :key="`prototype-${prototype.id}-${index}`"
            :policy="policy"
            :type_vintages="type_vintages"
            :prototype="prototype" 
            :flexible_path_setup="flexible_path_setup"
            :flexible-path-service="flexiblePathService"
            class="mx-auto xl:mr-auto"
            @updateFlexiblePathSetup="updateFlexiblePathSetup"
          />
        </template>
        <SkeletonPolicyFlexiblePathMeasureMenuTables v-else />

        <PolicyFlexiblePathTutorial />
      </div>
      
      <PolicyFlexiblePathDownloadModal :policy="policy" />
    </div>

    <PolicyFlexiblePathNotAvailable
      v-else-if="policy"
      :policy="policy"
    />
  </div>
</template>

<script>
import PolicyShowContentHeader from '../PolicyShowContentHeader.vue'
import SkeletonTypePrototypeList from './skeleton/SkeletonTypePrototypeList.vue'
import SkeletonPolicyFlexiblePathMeasureMenuTables from './skeleton/SkeletonPolicyFlexiblePathMeasureMenuTables.vue'
import PolicyFlexiblePathMeasureMenuTables from './PolicyFlexiblePathMeasureMenuTables.vue'
import FlexiblePathService from '@/services/api/FlexiblePathService'
import PolicyFlexiblePathDownloadModal from './PolicyFlexiblePathDownloadModal'
import PolicyFlexiblePathNotAvailable from './PolicyFlexiblePathNotAvailable'
import PolicyFlexiblePathTutorial from './PolicyFlexiblePathTutorial'
import { mapGetters } from 'vuex'

const DEFAULT_FLEXIBLE_PATH_SETUP = {
  version: 2,
  tiers: []
}   
export default {
  name: 'PolicyFlexiblePath',
  components: { 
    PolicyFlexiblePathMeasureMenuTables, 
    PolicyFlexiblePathDownloadModal, 
    SkeletonTypePrototypeList,
    SkeletonPolicyFlexiblePathMeasureMenuTables,
    PolicyFlexiblePathNotAvailable,
    PolicyFlexiblePathTutorial,
    PolicyShowContentHeader,
  },
  props: ['policy'],
  data() {
    return {
      type_prototypes: [],
      type_prototype_selected: null,
      prototypes: [],
      type_vintages: [],
      flexible_path_setup: DEFAULT_FLEXIBLE_PATH_SETUP,
      showModalPolicyFlexiblePathDownload: false,
      flexiblePathService: new FlexiblePathService({ policy_id : this.$route.params.policy_id }),
    }
  },
  computed: {
    ...mapGetters({getterContentContainerWidth: 'general/getterContentContainerWidth'})
  },
  mounted() {
    this.getSetup()
    this.getTypePrototypes()
    this.$store.dispatch('setModuleVisited', 'flexible_paths')
    this.$eventBus.$on('updateFlexiblePathSetup', this.updateFlexiblePathSetup)
  },
  beforeDestroy() {
    this.$store.commit('tutorial/setTutorialCurrentPosition', { type: 'flexiblePath', position: null })
    this.$eventBus.$off('updateFlexiblePathSetup')
  },
  methods: {
   
    async getSetup() {
      const setup = await this.flexiblePathService.getSetup()
      this.flexible_path_setup = { ...setup }
    },

    getTypePrototypes() {
      this.flexiblePathService.getTypePrototypes().then((type_prototypes) => {          
        this.type_prototypes = type_prototypes
        this.setTypePrototype(this.type_prototypes[0])
      })
    },

    setTypePrototype(type_prototype) {
      this.type_prototype_selected = type_prototype
      this.setTypePrototypeItems(type_prototype.id)
    },

    setTypePrototypeItems(type_prototype_id) {
      this.prototypes, this.type_vintages = []
      this.flexiblePathService.getTypePrototypeItems({ type_prototype_id }).then(({ prototypes, type_vintages }) => {
        this.prototypes = prototypes ?? []
        this.type_vintages = type_vintages ?? []
      })
    },

    updateFlexiblePathSetup(flexible_path_setup) {
      this.flexible_path_setup = { ...flexible_path_setup }
      if (this.$store.getters['policy/getterUserCanEditPolicy'](this.policy.id)) {
        this.flexiblePathService.updateSetup(this.flexible_path_setup)        
      }
    },

    resetFlexiblePathSetup() {
      const setup  = { version: 2, tiers: [] }
      if(this.$store.getters.getterLoggedUser && this.$store.getters.getterLoggedUser.id == this.policy.user_id) {
        this.$api.post(`/api/flexible_path_setup/updateOrCreate`, { policy_id: this.policy.id, data: setup }, { ignoreFeedback: true })
      }
      this.flexible_path_setup = { ...setup }
    },

    exportFile() {
      window.open(process.env.VUE_APP_BASE_API_URL + `/api/measure_menu/export/${this.$route.params.policy_id}`, "_blank")
    }
  }
}
</script>

<style scoped lang="scss">
  // .flexiblepath-sidebar {
  //   max-width: 476px;
  // }
  .app-alert {
    max-width: 837px;
    margin-left: auto;
    margin-right: auto;
  } 
</style>
