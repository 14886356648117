<template>
  <div class="psui-w-full psui-p-6">
    <div class="w-full overflow-x-auto">
      <table        
        class="table table-bordered mt-10 whitespace-nowrap"
      >
        <thead class="psui-sticky psui-top-0 psui-bg-blue-50 psui-z-10">
          <tr>
            <th
              rowspan="2"
              class="psui-sticky psui-left-0 z-20 psui-bg-blue-50"
            >
              Study Version
            </th>
            <th
              rowspan="2"
              class="psui-sticky psui-left-0 z-20 psui-bg-blue-50"
            >
              Prototype
            </th>
            <th colspan="2">
              Results
            </th>
            <th colspan="4">
              Policies
            </th>
            <th rowspan="2">
              Policy Option Algorithms
            </th>
            <th rowspan="2">
              Actions
            </th>
          </tr>
          <tr>
            <th v-tooltip="`This is not implemented yet. Check out DDEV-544 for details`">
              Show by Default
              <PsIcon                
                icon="info"
                size="16"
                class="psui-mt-2"
              /> 
            </th>
            <th v-tooltip="`Go to Study Items to edit the proportional units`">
              Is using fallback proportional units
              <PsIcon                
                icon="info"
                size="16"
                class="psui-mt-2"
              /> 
            </th>
            <th>Impact Algorithm</th>
            <th>Allowed</th>
            <th>Prescriptive Algorithm</th>
            <th>Flexible Algorithm</th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="studyGroup in studyGroups"            
          >
            <tr
              v-if="getStudyGroupPrototypes(studyGroup).length > 0"
              :key="studyGroup.id"
            >
              <td
                colspan="10"
                class="psui-bg-gray-20"
              >
                {{ studyGroup.title }}
              </td>
            </tr>
            <template v-for="study in studyGroup.studies">
              <template v-for="(prototype) in study.prototypes">
                <tr
                  :key="`${study.id}-prototype-${prototype.id}`"
                  :class="{ 
                    'bg-sunrise-200': prototype.hasUnsavedChanged,
                    'bg-red' : checkIfIsLatestPrototype({ study_type_id: studyGroup.study_type_id, type_prototype_id : prototype.type_prototype_id, prototype_id : prototype.id }),
                    'bg-red' : checkPrototypePolicyAlgorithmsConflicts(studyGroup, prototype),
                    'bg-red' : !prototype.allow_policy_creation & prototype.policy_option_algorithms.length
                  }"
                >
                  <td class="psui-sticky psui-left-0 z-20 bg-white">                  
                    {{ study.getTitle }}
                  </td>
                              
                  <td
                    v-tooltip="getPrototypeTooltip({ study_type: studyGroup.study_type, prototype })"
                    class="psui-sticky psui-left-0 z-20 bg-white"
                  >
                    <span class="psui-flex psui-items-center">
                      <PsIcon 
                        v-if="checkIfIsLatestPrototype({ study_type_id: studyGroup.study_type_id, type_prototype_id : prototype.type_prototype_id, prototype_id : prototype.id })"
                        icon="star"                      
                      />
                      <PsIcon 
                        v-else-if="prototype.allow_policy_creation"
                        icon="info"                      
                      />
                      <span
                        v-else
                        class="psui-pr-6"
                      />
                      <span
                        v-tooltip="`Prototype ID: ${prototype.id}`"
                        class="psui-ml-2"
                      >{{ prototype.title }}</span>
                    </span>
                  </td>
                  
                  <td>
                    <PsChips
                      :label="prototype.show_by_default_in_results ? 'Yes' : 'No'"
                      type="checkbox"
                      :checked="prototype.show_by_default_in_results"
                      @change="prototype.show_by_default_in_results = !prototype.show_by_default_in_results; prototype.hasUnsavedChanged = true"
                    />
                  </td>
                  
                  <td>
                    <PsChips
                      :label="checkPrototypeIsUsingFallbackProportional(prototype.id) ? 'Yes' : 'No'"
                      type="checkbox"
                      :checked="checkPrototypeIsUsingFallbackProportional(prototype.id)"
                      :disabled="true"
                    />
                  </td>
                  
                  <td>
                    <PsInputSelect
                      :value="prototype.impact_algorithm_results"
                      :items="['GENERAL_NEW_BUILDINGS', null ]"
                      layout="default"
                      @input="prototype.impact_algorithm_results = $event"
                    />
                  </td>

                  <td>
                    <PsChips
                      :label="prototype.allow_policy_creation ? 'Yes' : 'No'"
                      type="checkbox"
                      :checked="prototype.allow_policy_creation"
                      @change="prototype.allow_policy_creation = !prototype.allow_policy_creation; prototype.hasUnsavedChanged = true"
                    />
                  </td>

                  <td>
                    <PsInputSelect
                      :disabled="!prototype.allow_policy_creation"
                      :value="prototype.impact_algorithm_policy_impact"
                      :items="['GENERAL_PRESCRIPTIVE_BY_MEASURE', 'GENERAL_PERFORMANCE_BY_FUEL', null ]"
                      @input="prototype.impact_algorithm_policy_impact = $event; prototype.hasUnsavedChanged = true"
                    />
                  </td>

                  <td>
                    <PsInputSelect                    
                      :disabled="!prototype.allow_policy_creation"
                      :value="prototype.impact_algorithm_policy_flexible_path"
                      :items="['GENERAL_BY_MEASURE', null ]"
                      @input="prototype.impact_algorithm_policy_flexible_path = $event; prototype.hasUnsavedChanged = true"
                    />
                  </td>

                  <td>
                    <div class="w-full relative mr-3">
                      <div class="w-full relative mr-3">
                        <Dropdown 
                          ref="dropdownAlgorithms"
                          button-classes="dynamic psui-rounded justify-start border psui-border-gray-30 hover:psui-border-blue-50"
                        >
                          <template #buttonLabel>
                            <span class="psui-text-p psui-text-gray-40 uppercase flex items-center py-2 mr-auto pl-4 font-normal">
                              Algorithms
                            </span>
                          </template>
                          <template #items>
                            <div class="w-full flex flex-col font-medium text-gray02">
                              <PsCheckboxSimple
                                v-for="(algorithm, index) in getPolicyOptionAlgorithmsByStudyType(study)"
                                :key="index"
                                :checked="checkPolicyOptionAlgorithmIsSelected(algorithm, prototype)"
                                :disabled="policyOptionAlgorithmCanNotBeSelect(algorithm, prototype)"
                                :label="algorithm"
                                class="w-full"
                                @change="togglePolicyOptionAlgorithmsSelected(algorithm, prototype); prototype.hasUnsavedChanged = true"
                              />
                            </div>
                          </template>
                        </Dropdown>
                      </div>
                    </div>
                  </td>
                  
                  <td>
                    <div class="psui-flex align-middle space-x-2 text-center">
                      <router-link
                        :to="{ name: 'AdminStudyItemEdit', params: { study_item_id: prototype.id, type: 'prototype' }}"
                      >
                        <PsButton
                          label="Edit"
                          size="medium"
                          style="display: inline-block;"
                        />
                      </router-link>

                      <PsButton
                        :loading="isSavingPrototypeId[prototype.id]"
                        label="Save"
                        size="medium"
                        class="psui-inline-block"
                        @click="savePrototype(prototype)"
                      />
                    </div>
                  </td>
                </tr>
                <tr
                  v-if="checkPrototypePolicyAlgorithmsConflicts(studyGroup, prototype)"
                  :key="`${ study.id }-prototype-info-${ prototype.id }`"
                >
                  <td colspan="10">
                    <AdminPrototypePolicyAlgorithmConflict 
                      :study-group="studyGroup" 
                      :prototype="prototype" 
                      :check-prototype-policy-algorithms-conflicts="checkPrototypePolicyAlgorithmsConflicts" 
                    />
                  </td>
                </tr>
                <tr
                  :key="`${ study.id }-prototype-policies-outdated-${ prototype.id }`"
                >
                  <td colspan="10">
                    <AdminPrototypeCheckPolicyOutdated :prototype="prototype" />
                  </td>
                </tr>
              </template>
            </template>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import StudyGroupApiService from '@/services/api/StudyGroupApiService'
import PrototypeApiService from '@/services/api/PrototypeApiService'
import { POLICY_OPTIONS_ALGORITHMS } from "@/business-logic/services/policy-options/factory"
import AdminPrototypeCheckPolicyOutdated from './AdminPrototypeCheckPolicyOutdated'
import AdminPrototypePolicyAlgorithmConflict from './AdminPrototypePolicyAlgorithmConflict'

export default {
    name: 'AdminPrototypesConfiguration',
    components: { AdminPrototypeCheckPolicyOutdated, AdminPrototypePolicyAlgorithmConflict },
    data:()=>({
      studyGroups:[],
      isSavingPrototypeId : { },
      selectedOptionSlugs : []
    }),
    computed: {
      getAlgorithmsFromEnum() {
        return POLICY_OPTIONS_ALGORITHMS
      }
    },
    mounted(){
      // TODO - Refactor using global Entities
      StudyGroupApiService.all({getPrototypes: true})
      .then((response)=>{
        this.studyGroups = response 
      })
    },
    methods: {
      getPolicyOptionAlgorithmsByStudyType(study) {
        const studyType = this.$store.getters['globalEntities/Study/getterStudyStudyType']({ study_id: study.id })
        return Object.keys(this.getAlgorithmsFromEnum).filter(algorithmName => {
          return this.getAlgorithmsFromEnum[algorithmName].study_type_slug === studyType.slug
        })
      },
      checkIfIsLatestPrototype({ study_type_id, type_prototype_id, prototype_id }) {
        const typePrototypes = this.$store.getters['policy/getterPolicyAvailableTypePrototypesByStudyType']({ study_type_id })
        const typePrototype = typePrototypes.findInArray({ id : type_prototype_id })
        const prototype = typePrototype.prototypes?.[0]
        return prototype?.id == prototype_id ? true : false
      },
      getStudyGroupPrototypes(studyGroup) {
        return studyGroup.studies.reduce((prev, study) => {
          return [...prev, ...study.prototypes ]
        }, [])
      },
      getPrototypeTooltip({ study_type, prototype }) {
        if (!prototype.type_prototype) return false

        if (this.checkIfIsLatestPrototype({ study_type_id: study_type.id, type_prototype_id: prototype.type_prototype.id, prototype_id : prototype.id })) {
          return `This is the default prototype for create a policy of study type ${study_type.title} and type prototype ${prototype.type_prototype.title} ` 
        } else if (prototype.allow_policy_creation) {
          return `This prototype is allowed to policy creation but will not be the default`
        } else {
          return false
        }
      },
      checkPolicyOptionAlgorithmIsSelected( policyOptionAlgorithmSlug, prototype ) {
        return prototype.policy_option_algorithms.includes(policyOptionAlgorithmSlug)
      },
      disablePolicyOptionByStudyType() {
        return false
      },
      policyOptionAlgorithmCanNotBeSelect(policyOptionAlgorithmSlug, prototype) {
        const algorithmSelected = this.getAlgorithmsFromEnum[policyOptionAlgorithmSlug]
        const algorithmSelectedPolicyOptionSlug = algorithmSelected.policy_option_slug

        const hasOtherAlgorithmSelectedWithSamePolicyOptionSlug = prototype.policy_option_algorithms.find((policyOptionAlgorithmSlugFilter) => {
          const policyOptionSlug = this.getAlgorithmsFromEnum[policyOptionAlgorithmSlugFilter].policy_option_slug
          return algorithmSelectedPolicyOptionSlug == policyOptionSlug && policyOptionAlgorithmSlugFilter != policyOptionAlgorithmSlug
        })

        return hasOtherAlgorithmSelectedWithSamePolicyOptionSlug ? true : false
      },
      togglePolicyOptionAlgorithmsSelected(policyOptionAlgorithmSlug, prototype) {
        if ( this.policyOptionAlgorithmCanNotBeSelect(policyOptionAlgorithmSlug, prototype) ) return

        if ( !this.checkPolicyOptionAlgorithmIsSelected(policyOptionAlgorithmSlug, prototype) ) {
          prototype.policy_option_algorithms.push(policyOptionAlgorithmSlug)
        } else {
          prototype.policy_option_algorithms = prototype.policy_option_algorithms.filter(policyOptionAlgorithmSlugFilter => policyOptionAlgorithmSlugFilter !== policyOptionAlgorithmSlug)
        }
      },
      savePrototype(prototype){
        this.$set(this.isSavingPrototypeId, prototype.id, true)
        PrototypeApiService.update(prototype)
          .then(()=>{
            this.$set(this.isSavingPrototypeId, prototype.id, false)
            this.$toast.success('Prototype successfully updated!')
            prototype.hasUnsavedChanged = false
          })
      },
      checkPrototypeIsUsingFallbackProportional(prototype_id) {
        const prototype = this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: prototype_id })
        return prototype.proportional_type_prototypes.filter((type_prototype) => type_prototype?.pivot?.is_using_fallback_proportional_units).length > 0
      },

      checkPrototypePolicyAlgorithmsConflicts(studyGroup, prototype) {
        if(prototype.policy_option_algorithms.length == 0) return false
        const prototypesWithSamePolicyAlgorithm = this.getStudyGroupPrototypes(studyGroup)
          .filter((p) => p.id != prototype.id && p.type_prototype_id == prototype.type_prototype_id)
          .map(p => p)
          .filter(p => p.policy_option_algorithms.length)
        return prototypesWithSamePolicyAlgorithm.length ? prototypesWithSamePolicyAlgorithm : false
      }
    }
}
</script>

<style lang="scss" scoped>
  ::v-deep {
    .app-checkbox {
      width: 100%;

      .dropdown-button {
        padding-top: 3.5px;
        padding-bottom: 3.5px;
      }

      &::after {
        position: absolute;
        display: inline-block;
        content: 'arrow_drop_down';
        font-family: 'Material Icons Round';
        font-size: 24px;
        top: 13%;
        left: calc(100% - 26px);
        z-index: 10;
        color: #798490;
      }
    }
  }
</style>
