<template>
  <div 
    class="psui-w-full psui-bg-gray-10 psui-block overflow-y-auto h-full"
  >
    <StudyResultsContentSkeleton 
      v-if="isLoadingInitialData"
      class="pt-8 px-3 pr-8 md:pl-8" 
    />
    <div 
      v-else-if="hasVisiblePrototypes"
      class="w-full flex flex-col px-3 pr-8 md:pl-8 justify-start"
    >
      <template v-for="studyType in studyTypes">
        <StudyResultsContentStudyType
          v-show="checkIfEntityShouldBeVisibleInStudyResults('study_type',studyType)"
          :key="`studyresults-studytype-${studyType.id}`"
          :study-type="studyType"
        >
          <template v-for="prototype in visiblePrototypes(studyType)">
            <StudyResultsContentPrototype
              :key="`studyresults-prototype-${prototype.id}-${randomString(16)}`"
              :data-test-id="`study-results-content-prototype-${prototype.slug}`"
              :study="prototype.study"
              :prototype="prototype"
              :study-group="prototype.studyGroup"
            >
              <div class="flex flex-col space-y-6">
                <!-- Existing Buildings  -->
                <template v-if="prototype.study.vintages">
                  <template v-for="vintage in getVintagesOrdered(prototype.study.vintages)">                        
                    <StudyResultsContentVintage
                      v-show="checkIfEntityShouldBeVisibleInStudyResults('vintage', vintage, prototype)"
                      :key="`studyresults-vintage-${vintage.id}`"
                      :vintage="vintage"
                      :study="prototype.study"
                      :prototype="prototype"
                    />
                  </template>
                </template>
                <!-- New Buildings  -->
                <template v-if="prototype.study.fuels">
                  <template v-for="fuel in getFuelsOrdered(prototype.study.fuels)">
                    <StudyResultsContentFuel
                      v-show="checkIfEntityShouldBeVisibleInStudyResults('fuel', fuel)"
                      :key="`studyresults-fuel-${fuel.id}`"
                      :data-test-id="`study-results-content-fuel-${prototype.slug}`"
                      :fuel="fuel"
                      :study="prototype.study"
                      :prototype="prototype"
                    />
                  </template>
                </template>
              </div>
            </StudyResultsContentPrototype>
          </template>
        </StudyResultsContentStudyType>
      </template>
    </div>
    <StudyResultsContentEmpty v-else />
  </div>
</template>

<script>
import StudyResultsContentEmpty from './content/StudyResultsContentEmpty.vue'
import StudyResultsContentSkeleton from './content/StudyResultsContentSkeleton.vue'
import StudyResultsContentStudyType from './content/StudyType'
import StudyResultsContentPrototype from './content/Prototype'
import StudyResultsContentVintage from './content/Vintage'
import StudyResultsContentFuel from './content/Fuel'
import { mapGetters } from 'vuex'
import { randomString } from '@/util/Functions'

export default {
  name: 'StudyResultsContent',
  components: { 
    StudyResultsContentStudyType,
    StudyResultsContentPrototype,
    StudyResultsContentVintage,
    StudyResultsContentFuel,
    StudyResultsContentSkeleton,
    StudyResultsContentEmpty,
  },
  data : () => ({
    studyTypes : [],
    type_prototypes: [],
    isLoadingInitialData : false,
    randomString
  }),
  computed: {
    studies() {
      return this.studyTypes.reduce((prev, studyType) => {
        studyType.study_groups.map(studyGroup => {
          prev = [ ...prev, ...studyGroup.studies]
        })
        return prev
      }, [])
    },
    hasVisiblePrototypes() {
      let visiblePrototypes = false
      for (const studyType of this.studyTypes) {
       if(this.visiblePrototypes(studyType).length) visiblePrototypes = true
      }
      return visiblePrototypes
    },

    ...mapGetters('globalEntities/StudyType',['getterStudyTypeNewBuildings'])
  },
  async mounted() {
    this.isLoadingInitialData = true
    await this.getStudyResultsInitialData()
    .then((data)=>{
      this.studyTypes = data.study_types
      this.type_prototypes = data.type_prototypes
    })
    this.isLoadingInitialData = false
  },
  methods: {
    visiblePrototypes(studyType) {
      const prototypes = []
      for (const studyGroup of studyType.study_groups) {
        for (const typePrototype of this.type_prototypes) {
          for (const prototype of this.getStudyGroupPrototypesFilteredByTypePrototypeAndSortedByStudyRelease({ studyGroup, typePrototype })) {
            if (
              this.checkIfEntityShouldBeVisibleInStudyResults('prototype',prototype) && 
              this.checkIfEntityShouldBeVisibleInStudyResults('study_type',studyType) &&
              !prototypes.find((p) => p.id === prototype.id)
            ) {
              prototypes.push({...prototype, studyGroup})
            }
          }
        }
      }
      return prototypes
    },
  }
}

</script>

