import studyResultsColumns from '@/business-logic/constants/studyResultsColumns'

const forecastAndCityWideColumns = [
  'forecast_emissions_savings',
  'forecast_initial_cost',
  'forecast_kwh_savings',
  'forecast_lifecycle_savings',
  'forecast_therms_savings',
  'forecast_units_affected',
  'city_wide_subsidy_needed',
  'city_wide_five_year_payback',
  'city_wide_subsidy_needed_care',
  'city_wide_five_year_payback_care',
]

export default ({ study_data }) => {
  const fullStudyResultsColumns = [
    ...studyResultsColumns,
    ...forecastAndCityWideColumns,
  ]


  return Object.assign({}, ...fullStudyResultsColumns.map((col) => {
    return { [col] : study_data?.[col] || 0}
  }))
}

