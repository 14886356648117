<template>
  <div class="w-full flex flex-col">
    <p
      v-for="p in checkPrototypePolicyAlgorithmsConflicts(studyGroup, prototype)"
      :key="`prototype-conflict-${p.id}`"
    >
      There's conflicts in Policy Option Algorithms within the same Type Prototype <b>{{ prototype.type_prototype.title }}</b>:
      <span>{{ joinCommaPlusAnd(p.policy_option_algorithms) }}</span>
      .
    </p>
  </div>
</template>
<script>
  import { joinCommaPlusAnd } from '@igortrindade/lazyfy'

  export default {
    props: ['studyGroup', 'prototype', 'checkPrototypePolicyAlgorithmsConflicts'],
    data() {
      return {
        policies: []
      }
    },

    methods: {
      joinCommaPlusAnd,
    }
  }
</script>