<template>
  <BaseDrawer 
    ref="baseDrawer"
    hierarchy="PRIMARY"
  > 
    <template #header>
      <div
        v-if="getCurrentStepKeyValue('drawerTitle')"
        class="ps-button-icon-color psui-w-auto psui-flex psui-justify-between psui-items-center psui-py-6 psui-pl-12 psui-pr-6"
      > 
        <h1 
          class="app-drawer-title psui-flex psui-items-center psui-text-gray-50 psui-text-small psui-font-bold psui-uppercase row-onhover"
        >
          {{ getCurrentStepKeyValue('drawerTitle') }} 
        </h1>
        <PsButton
          layout="onlytext"
          icon="close"
          @click="closeDrawer()"
        />
      </div>
    </template>
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        class="w-full flex flex-col h-full"
      >
        <div class="psui-w-full psui-bg-gray-10 psui-pt-4 psui-pb-4 psui-px-12 psui-mb-6">
          <div
            v-if="getCurrentStepKeyValue('title')"
            class="psui-flex psui-items-end psui-space-x-4 psui-mt-1 mb-1/2"
          >
            <p class="psui-text-h4 psui-font-bold psui-text-blue-80">
              {{ getCurrentStepKeyValue('title') }}
            </p>
            <PsMiniTag
              v-if="policy_option.is_recommended" 
              layout="solid-success" 
              message="recommended"
              class="psui-text-uppercase psui-font-bold"
            />
          </div>
          <p
            v-if="policy_option && policy_option.short_description"
            class="psui-text-p psui-text-gray-50"
          >
            {{ policy_option.short_description | policyTextRephrase }} 
          </p>
          <span class="ps-button-layout">
            <PsButton
              v-if="currentStep.id === 'policy-option-information'"
              :label="'Create a policy using this option' | policyTextRephrase"
              layout="onlytext"
              :disabled="policyOptionDisabledMessage != null"
              @click="nextStep"
            />
            <PsButton
              v-else-if="currentStep.id === 'policy-option-type-prototype'"
              label="Learn more"
              layout="onlytext"
              @click="goToPreviousOption"
            />
          </span>
        </div>
        <component
          :is="currentStep.component"
          :current-step="currentStep"
          :policy-option="policy_option"
          :study_type_slug="study_type_slug"
          :prototypes-selected.sync="prototypesSelected"
          :policy.sync="policy"
          :policy-option-disabled-message="policyOptionDisabledMessage"
          @nextStep="nextStep"
        />
      </div>
    </transition>
  </BaseDrawer>
</template>

<script>
import PolicyOptionsSteps from '@/modules/app/policy/wizard/PolicyOptionsSteps.js'
import PolicyOptionCreate from '@/modules/app/policy/options/PolicyOptionCreate.vue'
import PolicyOptionAbout from '@/modules/app/policy/options/PolicyOptionAbout.vue'
import { find, findIndex } from '@/util/Functions'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import Policy from '@/models/Policy.js'
import { mapGetters } from 'vuex'

export default {
  name: 'PolicyOptionDrawer',
  components: { PolicyOptionCreate, PolicyOptionAbout},
  data:()=>({
    policy_option: null,
    policy: new Policy(),
    prototypesSelected:[],
    currentStep: PolicyOptionsSteps[1],
    study_type_slug: STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS,
    PolicyOptionsSteps, 
    policyOptionDisabledMessage: String,
  }),
    computed: {
    ...mapGetters(['lastJurisdictionVisited'])
  },
  watch:{
    lastJurisdictionVisited(){
      this.updateJurisdiction()
    },
    'policy.title': {
      immediate: true,
      handler: async function(value) {
        if (!value) {
          return
        }
        const isProduction = Boolean(window.location.hostname === 'explorer.localenergycodes.com')
        if (!isProduction) {
          const appendQa = Boolean(window.location.hostname.includes('ddev-'))
          const titlePrefix = `[PS${appendQa ? ' QA': ''}] `
          if (this.policy?.title && titlePrefix.startsWith(this.policy.title) && this.policy.title.length < titlePrefix.length) {
            this.policy.title = titlePrefix
          } if (!this.policy?.title.startsWith(titlePrefix)) {
            this.policy.title = `${titlePrefix}${this.policy.title || ''}`
          }
        }
      }
    },
  },
  mounted() {
    this.$eventBus.$on('openPolicyOptionDrawer', ({policy_option, current_step, study_type_slug, policy_option_disabled_message}) => {
      this.policy_option = policy_option

      this.policyOptionDisabledMessage = policy_option_disabled_message

      this.study_type_slug = study_type_slug
      if(current_step === 0) this.currentStep = PolicyOptionsSteps[current_step]
      
      this.updateJurisdiction()

      setTimeout(()=>{
        this.$refs.baseDrawer.openDrawer()
      }, 100)

      document.addEventListener('keydown', this.handleKeyDown)
    })
    this.$eventBus.$on('closePolicyOptionDrawer', ()=>{
      this.resetState()
      this.$refs.baseDrawer.closeDrawer()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openPolicyOptionDrawer')
    this.$eventBus.$off('closePolicyOptionDrawer')

    document.removeEventListener('keydown', this.handleKeyDown)
  },
  methods: {
  handleKeyDown(event){
    if(event.key === 'Escape' || event.key == 'Esc' ) {
      this.closeDrawer()
    }
  },
  resetState() {
    this.policy_option = null
    this.study_type_slug = STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS
    this.currentStep = PolicyOptionsSteps[1]
    this.policy = new Policy()
    this.prototypesSelected = []
  },
  getCurrentStepKeyValue(key) {
    if(!this.currentStep[key]) return false
    if(typeof this.currentStep[key] == 'function') return this.currentStep[key](this.policy_option)
    return this.currentStep[key]
  },
  async nextStep(){
    if(this.currentStep.beforeNext) {

      try {
        this.$eventBus.$emit('openPolicyOptionWizardModal', { policy: null, policyOption: this.policy_option, buildingType: this.study_type_slug })
      const newPolicy = await this.currentStep
      .beforeNext({...this.policy, policy_option: this.policy_option, policy_option_id: this.policy_option.id})
      
      const resultsByAlgorithms = []
      await Promise.all(this.prototypesSelected.map((pAndCz) => {
        const algorithm = this.policy_option.algorithms.find((algorithm) => {
          return (this.policy_option.prototypes.find((p) => p.id === pAndCz.prototype_id)?.policy_option_algorithms || []).includes(algorithm.name)
        })
        if (algorithm?.constructor) {
          const algInstance = (new algorithm.constructor(newPolicy.id, [pAndCz]))
          return algInstance.install().then((result) => {
            let resultItem = resultsByAlgorithms.find((i) => i.name === algorithm.name)
            if (!resultItem) {
              resultItem = { name: algorithm.name, instance: algInstance, results: [] }
              resultsByAlgorithms.push(resultItem)
            }
            resultItem.results.push(result)
          })
        } else {
          

          return Promise.resolve()
        }
      }))

      // Make a post install for each algorithm group and finally create policies
      const newPolicies = await Promise.all(resultsByAlgorithms.map(async (item) => {
        return item?.instance?.postInstall(item.results)
      }))

      this.$eventBus.$emit('openPolicyOptionWizardModal', { policy: newPolicies.pop() || newPolicy, policyOption: this.policy_option, buildingType: this.study_type_slug })


      } catch(err) {
        this.$appToast({ message: 'Oops! Something went wrong with your connection. Please check your network and try again.', type: 'info' })

        this.$eventBus.$emit('closePolicyOptionWizardModal')
      }
   }

    if(this.currentStep.getNextStep) {
        this.currentStep = find(PolicyOptionsSteps, {id:this.currentStep.getNextStep()})
    }
  },
  closeDrawer(){
    this.resetState()

    const newQuery = {...this.$route.query}
    delete newQuery.policy_option
    this.$router.push({
      name: 'PolicyOptions',
      query: newQuery
    })
    .catch((err) => {
      if(err.name != 'NavigationDuplicated') {
        throw err
      }
    })

    if(this.$refs.baseDrawer) this.$refs.baseDrawer.closeDrawer()
  },
  goToPreviousOption(){
    const currentStepIndex = findIndex(PolicyOptionsSteps, {id:this.currentStep.id})
    if(currentStepIndex > 0) this.currentStep = PolicyOptionsSteps[currentStepIndex-1]
  },
  updateJurisdiction(){
    if(this.lastJurisdictionVisited && this.policy_option) {
      this.$set(this.policy_option,'jurisdiction',this.lastJurisdictionVisited)
      this.policy.jurisdiction = { ...this.lastJurisdictionVisited }
      this.policy.jurisdiction_id = this.lastJurisdictionVisited.id
      this.policy_option?.prototypes?.forEach((prototype) => {
        prototype.climateZonesDisabledMessages = {}
      })
    }
  },
  updateCurrentStep(currentStep){
    this.currentStep = PolicyOptionsSteps[currentStep]
  }
},
}
</script>

<style lang="scss" scoped>
  ::v-deep {
    &.app-drawer {
      width: 880px;
    }
    
    .app-drawer-content {
      height: 100%;
      padding: 0;
    }
    
    .app-drawer-body {
      height: 100%;
    }

    .card-prototype {
      .psui-el-checkbox {
        color: #002A3A;
        font-size: 14px;
      }
      .psui-el-checkbox.size-big {
        .psui-el-checkmark {
          &:before {
            margin-right: 12px;
            color: #318FAC;
          }
        }
      }
    }
  }

  .ps-button-layout {
    ::v-deep {
      .psui-el-button.layout-onlytext {
        background-color: #F3F6F9;
      }
      .psui-el-button.size-big{
        padding: 6px 6px 6px 0px;
      }
    }
  }

  .ps-button-icon-color {
    ::v-deep {
      .psui-el-button.layout-onlytext {
        color: #798490;
      }
    }
  }
</style>
