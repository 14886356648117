<template>
  <div class="psui-flex psui-flex-col psui-gap-3">
    <div
      v-for="each in 4"
      :key="each"
      class="psui-flex psui-items-start psui-w-full psui-gap-6 psui-py-6 first:psui-pt-0"
    >
      <vue-skeleton-loader
        :key="each"
        :width="61"
        :height="52"
        class="flex-shrink-0 psui-rounded"
      />

      <div class="psui-flex psui-flex-col psui-gap-y-2">
        <vue-skeleton-loader
          :width="300"
          :height="22"
        />

        <div class="psui-flex psui-gap-3">
          <vue-skeleton-loader
            :width="100"
            :height="18"
          />
          <vue-skeleton-loader
            :width="250"
            :height="18"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name:'HomeNewsListSkeleton'
  }
</script>

