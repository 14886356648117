<template>
  <div class="flex flex-col px-6 gap-7 mt-6">
    <div class="flex flex-col items-center space-y-7">
      <img
        class="mx-auto"
        style="width: 160px; height: 120px"
        src="/images/building_estimates/prescriptive.png"
      >
      <p class="psui-text-gray-80 psui-text-p psui-font-bold text-center">
        Data for Existing Non-Residential buildings is not available yet
      </p>
      <PsButton
        label="Notify me"
        size="medium"
        @click="takeToMailChimp"
      />
    </div>
  </div>  
</template>

<script>
export default {
  name: 'AssumptionsNonResidentialExisting',
  methods:{
    takeToMailChimp(){
      window.open('https://mailchi.mp/localenergycodes/epajkrq24i','_blank')
    }
  }
}
</script>