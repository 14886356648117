import { initialState } from './state'
import toggleColumnIsActive from '@/business-logic/services/columns/toggleColumnIsActive'
import moveColumnItem from '@/business-logic/services/columns/moveColumnItem'
import moveColumnGroup from '@/business-logic/services/columns/moveColumnGroup'
import { deepMergeObject } from '@igortrindade/lazyfy'
import CustomCombination from '@/models/CustomCombination'

export default {

  // eslint-disable-next-line no-unused-vars
  resetPolicyState(state){
    state = {...(initialState() || {})}
  },

  resetHiddenItemsState(state){
    state.hiddenItems = undefined
  },

  removeUserPolicy(state, { id }) {
    state.userPolicies.removeInArray({ id })
  },

  setUserPolicies(state, policies) {
    state.userPolicies = policies
  },

  setUserSharedPolicies(state, shared_policies) {
    state.userSharedPolicies = shared_policies
  },
  
  toggleHiddenItem(state, item) {
    const index = state.hiddenItems.indexOf(item.index)
    if(index > -1) {
      state.hiddenItems.splice(index, 1)
    } else {
      state.hiddenItems.push(item.index)
    }
  },

  setHiddenItems(state, hiddenItems) {
    state.hiddenItems = [...hiddenItems]
  },

  mergeCustomCombinationMetaAssumptions(state, custom_combination) {
    // console.log('mergeCustomCombination', custom_combination)
    const policy = state.userPolicies.findInArray({ id: custom_combination.policy_id})
    if(policy) {

      const policyContainer = policy.policy_containers.findInArray({ id: custom_combination.policy_container_id })

      if(policyContainer) {
        const ccFinded = policyContainer.custom_combinations.findInArray({ id: custom_combination.id })

        if(!ccFinded) {
          policyContainer.custom_combinations.push(new CustomCombination(custom_combination))
        } else {
          ccFinded.meta = ccFinded.meta || {}
          if(!ccFinded.meta?.assumptions) {
            ccFinded.meta.assumptions = custom_combination.meta?.assumptions || {}
          } else {
            ccFinded.meta.assumptions = deepMergeObject(ccFinded.meta.assumptions, custom_combination.meta.assumptions)
          }
        }
      }
    }
  },

  toggleColumnIsActive,

  moveColumnItem,
  
  moveColumnGroup,
  
}
