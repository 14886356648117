import BaseLayoutWithHeaderAndSideBar from '@/layout/BaseLayoutWithHeaderAndSideBar.vue'
import SidebarDefault from '@/components/general/SidebarDefault.vue'
import NumberFormatPlayground from '@/modules/helpers/playgrounds/NumberFormatPlayground'
import ProjectionsPlayground from '@/modules/helpers/playgrounds/ProjectionsPlayground'
import BuildingEstimatesPlayground from '@/modules/helpers/playgrounds/BuildingEstimatesPlayground'
import BuildingEstimatesAssumptionsPlayground from '@/modules/helpers/playgrounds/BuildingEstimatesAssumptionsPlayground'
import MeasureMenuImpactsComplianceCost from '@/modules/helpers/playgrounds/MeasureMenuImpactsComplianceCost/MeasureMenuImpactsComplianceCost'
import DesignSystemIndex from '@/modules/helpers/design/DesignSystemIndex'
import ComponentsIndex from '@/modules/helpers/design/ComponentsIndex'

const baseMeta = {
  sidebar: {
    size: 'medium',
    content: SidebarDefault
  },
}
export default [
  {
    path: '/helpers',
    component: BaseLayoutWithHeaderAndSideBar,
    children: [
      {
        path: 'playgrounds/number',
        name: 'NumberFormatPlayground',
        component: NumberFormatPlayground,
        meta: baseMeta
      },
      {
        path: 'playgrounds/projections',
        name: 'ProjectionsPlayground',
        component: ProjectionsPlayground,
        meta: baseMeta
      },
      {
        path: 'playgrounds/target-score-compliance-cost',
        redirect: 'playgrounds/measure-menu-impacts-compliance-cost',
      },
      {
        path: 'playgrounds/measure-menu-impacts-compliance-cost',
        name: 'MeasureMenuImpactsComplianceCost',
        component: MeasureMenuImpactsComplianceCost,        
      },
      {
        path: 'playgrounds/building-estimates/:jurisdiction_slug',
        name: 'BuildingEstimatesPlayground',
        component: BuildingEstimatesPlayground,        
      },
      {
        path: 'playgrounds/building-estimates-assumptions/',
        name: 'BuildingEstimatesAssumptionsPlayground',
        component: BuildingEstimatesAssumptionsPlayground,        
      },
      {
        path: 'design-system',
        name: 'DesignSystemIndex',
        component: DesignSystemIndex,
        meta: baseMeta
      },
      {
        path: 'design-system/components',
        name: 'ComponentsIndex',
        component: ComponentsIndex,
        meta: baseMeta
      },
    ]
  } 
]