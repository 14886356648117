
/**
 * Columns that should be formatted or aggregated in measures combinations
 * NOTICE: The order of columns alter the results of formatted items
 * Some may depends on other items
 * So organize the array in order of dependency
 */

 export default [
  'initial_cost', 
  'kwh_savings',
  'therms_savings',
  'annual_bill_savings',
  'annual_bill_savings_avg',
  'tdv_benefit_to_cost_ratio',
  'emissions_savings_pct',
  'lifecycle_savings',
  'baseline_fuel_type',
  'compliance_margin',
  'on_bill_cost_ratio',
  'on_bill_cost_ratio_2025',
  'lsc_2025_benefit_to_cost_ratio',
  'on_bill_2025_care',
  'emissions_savings',
  'simple_payback', 
  'tdv2022_benefit_to_cost_ratio',
  'energy_savings_combined',
  'annual_bill_savings_care',
  'lifecycle_savings_care',
  'edr1_total_margin',
  'on_bill_cost_ratio_care',
  'simple_payback_care',
  'subsidy_needed',
  'five_year_payback',
  'subsidy_needed_care',
  'five_year_payback_care',
]
