<template>
  <PsAccordionItem
    title="Baseline Installation Rate"
    class="display-impact-assumption-icon"
    :class="{ 'form-dirty' : hasOverallValueUpdated }"
  >
    <template #header-additionals>
      <PsIcon
        icon="help_outline"
        size="16"
        display="flex"
        class="help psui-items-center psui-h-5 hover:psui-text-blue-60"
        :icon-classes="hasOverallValueUpdated ? 'mr-5': ''"
        @click.native.stop="$eventBus.$emit('openDescriptionModal', { slug: 'impact_baseline_installation_rate_assumption', ...descriptionModelObject })"
      />
    </template>
    <template #default>
      <span
        class="psui-text-p"
        v-html="$options.filters.policyTextRephrase('Without this policy, we assume <b>certain shares of units</b> would end up fulfilling the policy requirements anyway over the policy lifecycle.', 'small')"
      />
      <transition
        name="psui-el-compound"
        @enter="startTransition"
        @after-enter="endTransition"
        @before-leave="startTransition"
        @after-leave="endTransition"
      >
        <div
          v-if="!showBaselineInstallationRateByBuildingType"
          id="default-assumption-column"
          class="flex flex-col space-y-4 mt-3"
        >
          <div
            v-for="(assumption) in Object.values(assumptions)"
            :key="`assumption-${assumption.key}`"
            class="flex items-center justify-between"
          >
            <span class="inline-block psui-text-gray-60 psui-text-small flex-shrink">
              {{ assumption.description | policyTextRephrase('small') }}
            </span>
            <PsInput
              :data-test-id="`input-assumption-${assumption.key}`"
              class="fixed-input"
              :has-error="inputErrorIndex == assumption.key ? 'Insert a valid value' : false"
              :class="{'selected': hasValueUpdated(getSingleInputValue(assumption.key), assumption.key) && inputErrorIndex != assumption.key}"
              layout="mini"
              :value="getSingleInputValue(assumption.key)"
              @click.native="checkIfUserCanEditAssumption"
              @input="onInputAvg($event.target.value, assumption.key)"
              @change="onChangeAvg(assumption.key)"
              @blur="onBlurAvg(assumption.key)"
              @mouseenter="onMouseEnter(assumption.key)"
              @mouseleave="onMouseLeave()"
            >
              <template #append>
                <span
                  v-if="!hasValueUpdated(getSingleInputValue(assumption.key), assumption.key) || !checkShowResetButton(assumption.key)"
                  class="psui-text-gray-50 psui-text-xsmall"
                >%</span>
                <PsTooltip v-else-if="checkShowResetButton(assumption.key)">
                  <template #trigger>
                    <PsIcon 
                      icon="restart_alt"
                      icon-classes="psui-text-green-20 cursor-pointer mt-1"
                      size="17"
                      @click.native="restoreAssumptionToDefault(assumption.key, false)"  
                    />
                  </template>
                  <template #content>
                    <h1 class="mb-1">
                      Restore to default value
                    </h1>
                    <span class="psui-font-normal">{{ getBaseValue(assumption.key) }}</span>
                  </template>
                </PsTooltip>
              </template>
            </PsInput>
          </div>
        </div>
      </transition>
      <div
        v-if="$route.name === 'PolicyShow' && getPrototypesWithinPolicySelected.length > 1"
        class="psui-flex flex-col psui-text-gray-60 mt-3"
      >
        <div
          class="psui-bg-gray-10 py-1/2 px-3 psui-rounded-t psui-flex psui-items-center mt-5"
          :class="{'psui-bg-blue-10':showBaselineInstallationRateByBuildingType}"
        >
          <span
            class="psui-text-small mr-auto"
            :class="{'psui-text-blue-60 psui-font-bold':showBaselineInstallationRateByBuildingType }"
          >Assume different values for each building type</span>
          <PsSwitch
            ref="switchItem"
            class="flex-shrink-0"
            size="small"
            label="Breakdown by construction types"
            :value="showBaselineInstallationRateByBuildingType"
            @change="onToggleAssumptionByBuildingType"
          />
        </div>
        <transition
          name="psui-el-compound"
          @enter="startTransition"
          @after-enter="endTransition"
          @before-leave="startTransition"
          @after-leave="endTransition"
        >
          <div
            v-if="showBaselineInstallationRateByBuildingType"
            id="default-assumption-column"
            class="flex flex-col mt-4/5 gap-8"
          >
            <div
              v-for="(prototype) in getPrototypesWithinPolicySelected"
              :key="`prototype-switch-${prototype.id}`"
              class="flex flex-col"
            >
              <span
                class="flex-shrink-0 psui-text-gray-80 psui-text-small psui-font-bold mb-6"
              >{{ prototype.title | prototypeTitleHomesAsUnits }}</span>
              <div
                class="flex flex-col"
              >
                <div
                  v-for="(assumption, index) in Object.values(assumptions)"
                  :key="`assumption-prototype-${assumption.key}-${ prototype.id }}`"
                  class="flex items-center justify-between space-y-4"
                >
                  <span class="inline-block psui-text-gray-60 psui-text-small flex-shrink mr-16">
                    {{ assumption.description | policyTextRephrase('small') }}
                  </span>
                  <PsInput
                    class="fixed-input"
                    layout="mini"
                    :has-error="inputErrorIndex === index ? 'Insert a valid value' : false"
                    :class="{'selected': hasValueUpdated(getValuePerPrototype(assumption.key, prototype), assumption.key) && inputErrorIndex !== index}"
                    :value="getValuePerPrototype(assumption.key, prototype)"
                    @click.native="checkIfUserCanEditAssumption"
                    @input="onInputPrototype($event.target.value, assumption.key, prototype)"
                    @change="onChangePrototype(assumption.key)"
                    @blur="onBlurPrototype(assumption.key)"
                    @mouseenter="onMouseEnter(assumption.key, prototype)"
                    @mouseleave="onMouseLeave()"
                  >
                    <template #append>
                      <span
                        v-if="!hasValueUpdated(getValuePerPrototype(assumption.key, prototype), assumption.key) || !checkShowResetButton(assumption.key, prototype)"
                        class="psui-text-gray-50 psui-text-xsmall"
                      >%</span>
                      <PsTooltip v-else-if="checkShowResetButton(assumption.key, prototype)">
                        <template #trigger>
                          <PsIcon 
                            icon="restart_alt"
                            icon-classes="psui-text-green-20 cursor-pointer mt-1"
                            size="17"
                            @click.native="restoreAssumptionToDefault(assumption.key, prototype)"  
                          />
                        </template>
                        <template #content>
                          <h1 class="mb-1">
                            Restore to default value
                          </h1>
                          <span class="psui-font-normal">{{ getBaseValue(assumption.key) }}</span>
                        </template>
                      </PsTooltip>
                    </template>
                  </PsInput>
                </div>
              </div>
            </div>
          </div>
        </transition>     
      </div>
    </template>
  </PsAccordionItem>
</template>

<script>
import { CREATE_POLICY_MODAL_DESCRIPTION, ASSUMPTIONS_KEYS }from '@/util/Enums.js'
import { extractAssumptionValuesFromPolicy, getAssumptionsLevel, ASSUMPTION_LEVEL } from '@/util/AssumptionsHelpers'

import AssumptionImpactsMixin from './AssumptionImpactsMixin'

export default {
  name: 'AssumptionsImpactsBaselineInstallationRate',
  props:['tabSelected','userCanEditPolicy', 'descriptionModelObject'],
  mixins: [ AssumptionImpactsMixin ],
  data:()=>({
    showBaselineInstallationRateByBuildingType:false,
    minLikelihoodInstallations: 0,
    maxLikelihoodInstallations: 100,
    CREATE_POLICY_MODAL_DESCRIPTION,
    isEditing: false,
    disable_function:true,
    assumptions: {
      likelihood_installations_first_third:{
        key: ASSUMPTIONS_KEYS.likelihood_installations_first_third,
        description: 'During the first third of the policy lifecycle:'
      },
      likelihood_installations_second_third:{
        key:ASSUMPTIONS_KEYS.likelihood_installations_second_third,
        description: 'During the second third of the policy lifecycle:',
      },
      likelihood_installations_final_third:{
        key: ASSUMPTIONS_KEYS.likelihood_installations_final_third,
        description: 'In the remaining years of the policy lifecycle:'
      }
    }
  }),
  computed: {
    hasOverallValueUpdated() {
      return (this.getBaseValue(this.assumptions.likelihood_installations_first_third.key) != this.getSingleInputValue(this.assumptions.likelihood_installations_first_third.key)) ||
      (this.getBaseValue(this.assumptions.likelihood_installations_second_third.key) != this.getSingleInputValue(this.assumptions.likelihood_installations_second_third.key)) ||
      (this.getBaseValue(this.assumptions.likelihood_installations_final_third.key) != this.getSingleInputValue(this.assumptions.likelihood_installations_final_third.key))
    },
  },
  methods: {
    valuesByCustomCombinationsByKey(key) {
      if (!this.getPolicySelected) {
        return null
      }
      return extractAssumptionValuesFromPolicy(this.getPolicySelected, key, this.getBaseValue(key))
    },
    onToggleAssumptionByBuildingType(value) {
      if (!this.checkIfUserCanEditAssumption()) return false

      let arrayOfAssumptionsLevel = Object.values(this.assumptions).map((item)=>{
        return getAssumptionsLevel(this.valuesByCustomCombinationsByKey(item.key))
      })
      if (value || arrayOfAssumptionsLevel.every(item => item == ASSUMPTION_LEVEL.BASE) ) {
        this.showBaselineInstallationRateByBuildingType = value
        return
      }
      
      this.$eventBus.$emit('openConfirmPopover', {
        targetElem: this.$refs.switchItem.$el.parentElement,
        text: 'Do you really want to break the assumption level? This action will set an averrage for all building types',
        neverAskUserProp: `assumptions_break_level_baseline_installation`,
        okButtonText: 'Ok, please proceed',
        side: 'left',
        onConfirmCallback: () => {
          Object.values(this.assumptions).forEach(item => {
            this.setValuePerPrototype(false,item.key, this.getAverageAssumptionValueByKey(item.key))
          })
          this.showBaselineInstallationRateByBuildingType = false
        },
      })
    },

    start(el) {
      el.style.height = el.scrollHeight + 'px'
      el.style.padding = el.srcollPaddingBottom + 'px'
    },

    end(el) {
      el.style.height = ''
      el.style.padding = ''
    },

    getSingleInputValue(assumptionKey){
      if(this.currentAssumptionKeySelected == assumptionKey && this.isEditing == assumptionKey) return this.currentAvgInputRaw
      if(!this.$route.params.policy_id) return this.getValue(assumptionKey)
      let averageValue = 0      
      this.getPrototypesWithinPolicySelected.forEach((prototype) => {
        averageValue += this.getValuePerPrototype(assumptionKey, prototype)
      })
      return averageValue / this.getPrototypesWithinPolicySelected.length
    },
    
    getAverageAssumptionValueByKey(assumptionKey){
      let averageValue = 0
      this.getPrototypesWithinPolicySelected.forEach((prototype) => {
        averageValue += this.getValuePerPrototype(assumptionKey, prototype)
      })
      return averageValue / this.getPrototypesWithinPolicySelected.length
    },

    getValuePerPrototype(assumptionKey, prototype){
      if(this.isEditing == assumptionKey ) return this.currentPrototypeInputRaw
      return this.getPolicyAssumptionKeyValuePerPrototype(this.$route.params.policy_id, assumptionKey, prototype) ?? 
      this.getValue(assumptionKey)
    },

    // restoreAssumptionToDefault(assumptionKey, prototype = false) {
    //   if(!this.$route.params.policy_id) {
    //     this.setValue(this.getBaseValue(assumptionKey), assumptionKey)
    //   } else {
    //     this.setValuePerPrototype(this.getBaseValue(assumptionKey), assumptionKey, prototype)
    //   }
    // },

    validateInput(value){ 
      return value <= this.maxLikelihoodInstallations && value >= this.minLikelihoodInstallations
    },

  }
}
</script>

<style lang="scss" scoped>
  .fixed-input {
    flex-shrink: 0;
    width: 124px;
  }

  ::v-deep .psui-el-switch.size-small .psui-el-switch-button.toggle-true {
    background: #318FAC;
  }

  .psui-el-input.status-resting.layout-mini, .psui-el-input {
    width: 124px;
    &.selected{
      ::v-deep .psui-el-input-wrapper {
        border-color: #5DB883;
      }
    }


    
    ::v-deep.psui-el-tooltip-dialog {
      
      
      width: fit-content;
       .psui-el-tooltip-content {
        border-radius: 6px;
        background-color: #D6DDE5;
        color: #28323B;
        padding: 10px 16px; 
       }
    }

  }

  ::v-deep .psui-el-input.status-resting.layout-mini.selected {
      .psui-el-input-wrapper {
        border-color: #5DB883;
      }
    }

  ::v-deep .psui-el-input.status-error.layout-mini {
    position: relative;
      .psui-el-input-hint {
        position: absolute;
      }
    .psui-el-input-wrapper {
      .psui-el-input-append {
        display: none;
      }
    }
  }

  .psui-el-accordion-item {
    &.status-opened {
      .help {
        color: #318FAC;
      }
    }
  }

  #default-assumption-column {
    > div {
      &:nth-child(1) {
        > span {
          margin-right: 35px;
        }
      }

      &:nth-child(2) {
        > span {
          margin-right: 10px;
        }
      }

      &:nth-child(3) {
        > span {
          margin-right: 30px;
        }
      }
    }
  }
</style>
