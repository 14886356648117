<template>
  <div class="w-full  flex flex-col">
    <h4 class="psui-text-h5 psui-text-gray-80 font-bold whitespace-nowrap">
      Top users by session
    </h4>
    <div class="block w-full">
      <ApexChartGeneral 
        :options="getChartOptions()"
        :palette-color-index="0"
      />
    </div>
  </div>
</template>

<script>
import { colors } from '@/business-logic/constants/chartDefaultOptions'
import MathFunctions from '@/util/MathFunctions'
export default {
  name: 'AdminDashboardTopPoliciesVisited',
  props: ['timeFrameSelected'],
  data() {
    return {
      colors,
      top_users_by_sessions: []
    }
  },
   computed: {
    
    getTotal() {
      return this.top_users_by_sessions.reduce((acc, item) => acc + parseInt(item.session_count), 0)
    },

    getChartSeries() {
      const data = this.top_users_by_sessions.map((user, index) => {
        return {
          x: `${user.email} (${user.session_count})`,
          y: user.session_count,
          user,
          fillColor: this.colors[index]
        }
      })
      return [{ data }]
    }
  },

  watch: {
    timeFrameSelected: {
      deep: true,
      handler() {
        this.getData()
      } 
    }
  },

  mounted() {
    this.getData()
  },

  methods: {

    getData() {
      this.$api.get('/api/admin/dashboard/get_data/top_users_by_sessions', { params: {
        init: this.timeFrameSelected.init, 
        end: this.timeFrameSelected.end 
      }})
        .then(({ data }) => {
          this.top_users_by_sessions = data.top_users_by_sessions
        })
    },

    getChartOptions() {
      const self = this
      return { 
        chart: {
          type: 'bar',
          stacked: false,
          events: {
            dataPointSelection: function(event, chartContext, { seriesIndex, dataPointIndex }) {
              const item = self.getChartSeries[seriesIndex].data[dataPointIndex]
              window.open(`/policies/${item.policy.id}`, '_blank')
            }
          },
        },
        toolbar: true,
        series: this.getChartSeries, 
        yaxis: {
          labels: {
            formatter: function(value) {
              return self.formatUnits(value)
            }
          },
        },
        tooltip: {
          custom: function ({ seriesIndex, dataPointIndex }) {
            const item = self.getChartSeries[seriesIndex].data[dataPointIndex]
            return `
              <div class="psui-flex" style="background-color: ${ item.fillColor } !important;">
                <div class="apexcharts-tooltip-content psui-w-full psui-bg-white psui-p-4">
                  <h4 class="psui-text-gray-80 psui-text-xsmall psui-font-bold">Users ${ item.user.email } (ID: ${item.user.id})</h4>
                  <div class="psui-w-full psui-flex psui-items-center justify-between space-x-2">
                    <h2 class="psui-text-gray-80 psui-text-small">${ self.formatUnits(item.user.session_count) } Sessions</h2>
                    <h2 class="psui-text-gray-80 psui-text-small">${ self.formatUnits(item.user.devices_count) } Devices</h2>
                    <span class="psui-text-gray-30 psui-mx-1">|</span>
                    <h2 class="psui-text-gray-50 psui-text-small">${ self.formatNumber(MathFunctions.getPercentageOfAmount(self.getTotal, item.user.session_count)) }%</h2>
                  </div>
                </div>
              </div>
            `
          }
        }
      }
    }
  }
}
</script>