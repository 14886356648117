<template>
  <PsTabHeader
    v-if="getStudyTypes.length > 0"
    wrapper-id="base-app-header-navigation-by-study-type"
    data-test-id="base-app-header-navigation-by-study-type"
    class="psui-mr-auto"
    :items="getStudyTypes"
    layout="underline"
    key-label="title"
    key-value="slug"
    :selected="getStudyTypeSelected"
    @change="setStudyTypeSelected"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import RRC from '@/router/rrc'

export default {
  name: 'BaseAppHeaderNavigationByStudyType',
  computed: {
    ...mapGetters(['lastStudyTypeVisited']),
    getStudyTypes() {
      return this.$store.getters['globalEntities/StudyType/getterGlobalStudyTypes']() ?? []
    },
    getStudyTypeSelected() {
      const route = this.$route.query.only_study_type || this.lastStudyTypeVisited 

      this.setQueryValue('only_study_type', route, RRC.BASE_APP_HEADER_NAVIGATION_BY_STUDY_TYPE_1)
      return route
    },
    getDrawerOptions(){
      return this.$store.getters['assumptions/getterAssumptionsDrawerOptions']
    }
  },
  methods: {
    setStudyTypeSelected(studyTypeSlug) {
      this.$store.dispatch('setUserLastStudyTypeVisited', { value: studyTypeSlug, context: 'updateLastStudyTypeVisitedFromBaseAppHeaderNavigationByStudyType'})
      this.setQueryValue('only_study_type', studyTypeSlug, RRC.BASE_APP_HEADER_NAVIGATION_BY_STUDY_TYPE_2)

      const tab = this.getStudyTypes.find(study_type => study_type.slug == studyTypeSlug)
      this.$store.commit('assumptions/setDrawerOptions',{...this.getDrawerOptions, tab})
    }
  }
}
</script>
