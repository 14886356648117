var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.maxTargetScore >= 0)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.inputTooltip),expression:"inputTooltip"}],staticClass:"w-full h-full flex items-center border border-transparent transition-all",class:[
    _vm.getTargetScoreHasError ? 
      'bg-candy-200 border border-candy-500' : 
      'text-deepsky-900',
    _vm.inputFocus ? 'border-deepsky-450' : '',
    {'hover:border-deepsky-450' : !_vm.getTargetScoreHasError && _vm.userCanEditPolicy}
  ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.targetScoreInput),expression:"targetScoreInput"},{name:"mask",rawName:"v-mask",value:(['#', '##', '###']),expression:"['#', '##', '###']"}],ref:"input",staticClass:"w-12 bg-transparent text-header-2 h-full pl-4 z-10 placeholder-deepsky-450 target-score-input",class:{'caret-candy-500 text-candy-500' : _vm.getTargetScoreHasError },attrs:{"min":1,"type":"tel","disabled":!_vm.userCanEditPolicy},domProps:{"value":(_vm.targetScoreInput)},on:{"change":function($event){return _vm.checkTargetScore()},"input":[function($event){if($event.target.composing)return;_vm.targetScoreInput=$event.target.value},function($event){return _vm.onInputChange(_vm.type_vintage.id, _vm.climate_zone.id, _vm.prototype.id)}],"focus":_vm.onInputFocus,"blur":_vm.onInputBlur,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$refs.input.blur()}}}),(!_vm.inputFocus || (_vm.inputFocus && !_vm.getTargetScoreHasError))?_c('feather',{staticClass:"target-score-slider-icon text-transparent",class:{ 'text-deepsky-450' : !_vm.getTargetScoreHasError, 'cursor-pointer' : _vm.userCanEditPolicy, 'invisible' : !_vm.userCanEditPolicy },attrs:{"size":"16","type":!_vm.inputFocus ? 'sliders' : 'check'},on:{"!click":function($event){return _vm.onIconClick()}}}):_vm._e(),(_vm.isTargetScoreGreaterThanMaxTargetScore)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('This Target Score cannot be achieved cost-effectively based on study results'),expression:"'This Target Score cannot be achieved cost-effectively based on study results'"}],staticClass:"highlight-icon"},[_c('div',{staticClass:"icon"})]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }