<template>
  <div class="flex psui-bg-blue-20 p-1 rounded-sm psui-space-x-2 mt-1/2 psui-text-small">
    <div class="flex items-center">
      <div class="flexible-score-input flex flex-col psui-bg-white rounded-sm">
        <PolicyExistingBuildingsRequirementsFlexibleTargetScoreInput 
          :policy="policy"
          :prototype="prototype"
          :climate_zone="climate_zone"
          :type_vintage="type_vintage"
          :flexible_path_setup="flexible_path_setup"
          :tier="getFlexiblePathTier"
          :max_target_scores_by_presets="max_target_scores_by_presets"
          :close_modal_without_changes="closeModalWithoutChanges"
          :type_vintage_index="getTypeVintageIndex"
          @openPolicyFlexiblePathScoreWarningModal="onOpenPolicyFlexiblePathScoreWarningModal($event)"
        />
      </div>
    </div>
    <div class="psui-flex psui-bg-blue-10 rounded-sm w-full pr-4">
      <div class="psui-flex flex-col pt-2 px-2 w-full">
        <div class="cost-effective-score-wrapper flex items-center space-x-1">
          <span class="psui-text-blue-80 psui-font-bold psui-text-small">Maximum cost-effective score</span>
          <PsIcon
            icon="info_outline"
            display="flex"
            size="16"
            icon-classes="psui-text-gray-40 cursor-pointer hover:psui-text-blue-60"
            @click.native="openPolicyExistingCostEffectiveDrawer()"
          />
        </div>
        <div class="flex space-x-2 w-full pb-2">
          <div class="scores flex psui-bg-blue-20 psui-text-gray-60 rounded-sm w-full justify-between mt-1">
            <span
              v-if="getShowFlexibleScoreInput"
            >
              Using <b>available</b> flexible measures:
            </span>
            <span>
              {{ getMaxPossibleScore }}
            </span>
          </div>
          <div
            v-if="getShowFlexibleScoreInput"
            class="scores flex justify-between psui-bg-blue-20 psui-text-gray-60 rounded-sm w-full mt-1"
          >
            <span>
              Using  <b>any</b> flexible measure:
            </span>
            <span>
              {{ maxTargetScore }}
            </span>
          </div>
        </div>
      </div>
      <div
        class="preset-input psui-flex pt-2 psui-flex-col psui-flex-nowrap"
        :class="{'psui-w-full':!getShowFlexibleScoreInput}"
      >
        <div
          class="calculation-method-wrapper flex items-center space-x-1"
          @mouseleave="toggleCalculationMethodAlert($event, false)"
        >
          <span class="psui-text-blue-80 psui-font-bold whitespace-nowrap">Calculation method</span>
          <span
            v-if="getMaxTargetScorePresetSelectedByPreset && getMaxTargetScorePresetSelectedByPreset.customCombinationWarn"
            class="svg-position"
            @mouseover="toggleCalculationMethodAlert($event, true)"
          >
            <span class="cursor-pointer">
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="4.5"
                  fill="#EDAB3E"
                  stroke="#E0EFF6"
                />
              </svg>
            </span>
          </span>
          <PsIcon
            size="16"
            display="flex"
            class="calculation-method-icon psui-text-gray-50 cursor-pointer hover:psui-text-blue-60"
            icon="info_outline"
            @click.native="$eventBus.$emit('openDescriptionModal', { type: 'helper' , slug: 'calculation_method' })"
          />
        </div>
        <PsDropdown
          ref="customCombinationPresents"
        >
          <template #dropdownTrigger>
            <div 
              class="dropdown psui-flex w-full psui-justify-between psui-items-center psui-text-gray-50 psui-bg-white psui-cursor-pointer psui-border psui-border-gray-20 psui-rounded"
            >
              <span class="psui-text-small">
                {{ getMaxTargetScorePresetSelectedByPreset.label }}
              </span>
              <PsIcon 
                icon="expand_more"
                class="psui-text-gray-50 psui-cursor-pointer leading-none"
                size="18"
                display="flex"
              />
            </div>
          </template>
          <template #items>
            <PsDropdownList 
              :items="getCustomCombinationsPresetsFilteredByPresets"
              :selected="getPresetByPrototypeAndClimateZone"
              left-label="label"
              key-value="value"
              layout="onlytext"
              @change="updateMaxTargetScorePreset"
              @openDescriptionModal="openDescriptionModal"
            />
          </template>
        </PsDropdown>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getMaxTargetScorePresetSelected,
  getMaxPossibleScore,
  getMaxCostEffectiveScore
} from '@/services/measure-menu/MeasureMenuGeneralService'
import {
  CUSTOM_COMBINATION_FILTERS,
  CUSTOM_COMBINATION_PRESETS
} from '@/modules/app/jurisdiction/study-results/shared/enums'
  import PolicyExistingBuildingsRequirementsFlexibleTargetScoreInput from '@/modules/app/policy/show/requirements/requirements-drawer/PolicyExistingBuildingsRequirementsFlexibleTargetScoreInput.vue'
  import { setTierTargetScore } from '@/services/measure-menu/MeasureMenuGeneralService'
  import Study from '@/models/Study'
import {
  RESIDENTIAL_NEW_SPECIFICATIONS
} from "@/modules/app/assumptions/impacts/AssumptionImpactsByPrototypeAndClimateZoneMixin"

  export default {
    name:"PolicyExistingBuildingsFlexibleScore",
    components:{
      PolicyExistingBuildingsRequirementsFlexibleTargetScoreInput
    },
    props:['flexible_path_setup','climate_zone','prototype','custom_combination','policy','type_vintage','max_target_scores_by_presets','change_calculation_method_checker', 'flex_mandatory_measures', 'flex_excluded_measures', 'calculation_method'],
    data:()=>({
      targetScore: [Number, String],
      maxPossibleScore: [Number, String],
      maxCostEffectiveScore: [Number, String],
      typeVintageId: [Number, String],
      climateZoneId: [Number, String],
      prototypeId: [Number, String],
      typeVintage: [Object, String],
      closeModalWithoutChanges: null,
      lastTargetScoreInput: [Number, String],
      getMaxPossibleScore: null,
    }),
    computed:{
      getCustomCombinationsPresetsFilteredByPresets() {
        if (!this.custom_combination?.prototype_id) return []
        const prototype = this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: +this.custom_combination.prototype_id })
        const study = this.$store.getters['globalEntities/Study/getterGlobalStudy']({ id: prototype.study_id })
        if (!study) return []
        const enabledPresetsOnStudy = (new Study(study))?.enabled_cost_ratio_presets || []
        return enabledPresetsOnStudy.map((preset) => {
          if (!CUSTOM_COMBINATION_PRESETS?.[preset]) return null
          return {
            ...(CUSTOM_COMBINATION_PRESETS?.[preset] || {}),
            label: CUSTOM_COMBINATION_PRESETS?.[preset]?.calculationMethodLabel || CUSTOM_COMBINATION_PRESETS?.[preset]?.label,
          }
        }).filter((i) => i)
      },
      getPresetByPrototypeAndClimateZone() {
        return getMaxTargetScorePresetSelected({ climate_zone_id : this.climate_zone.id, prototype_id: this.prototype.id, flexible_path_setup: this.flexible_path_setup})
      },
      getShowFlexibleScoreInput() {
        return +this.maxTargetScore !== +this.getMaxPossibleScore
      },
      getMaxTargetScorePresetSelectedByPreset() {
        return this.getCustomCombinationsPresetsFilteredByPresets.find((i) => i.value === this.getPresetByPrototypeAndClimateZone)
      },
      maxTargetScore() {
        const getMaxTargetScore = this.max_target_scores_by_presets.find(mts => mts.climate_zone_raw === this.climate_zone.raw && +mts.type_vintage_id === +this.type_vintage.id)
        return getMaxTargetScore[this.getPresetByPrototypeAndClimateZone]
      },
      getTypeVintageIndex() {
        return this.climate_zone.type_vintages.findIndex(typeVintage => +typeVintage.id === +this.type_vintage.id)
      },
      getFlexiblePathTier() {
        return this.flexible_path_setup?.tiers?.find(tier => tier?.prototype_id === this.prototype.id && +tier?.climate_zone_id === this.climate_zone.id)
      },
      getFlexiblePresetSetupObj() {
        return this.flexible_path_setup?.cost_effective_presets?.find(tier => tier?.prototype_id === this.prototype.id && +tier?.climate_zone_id === this.climate_zone.id)
      },
      getPolicyId() {
        return this.$route.params?.policy_id
      },
      userCanEditPolicy() {
        return this.$store.getters['policy/getterUserCanEditPolicy'](this.getPolicyId)
      },
      isScoreAboveMaxAchievableAndBelowMaxCostEffective() {
        return this.targetScore > this.maxPossibleScore && this.targetScore < this.maxCostEffectiveScore
      },
      isScoreAboveMaxAchievableAndMaxCostEffectiveAndEqual() {
        return this.targetScore > this.maxPossibleScore && this.targetScore > this.maxCostEffectiveScore && +this.maxCostEffectiveScore === +this.maxPossibleScore
      },
      isScoreAboveMaxAchievableAndMaxCostEffectiveAndNotEqual() {
        return this.targetScore > this.maxPossibleScore && this.targetScore > this.maxCostEffectiveScore && +this.maxCostEffectiveScore !== +this.maxPossibleScore
      },
    },
    watch: {
      getPresetByPrototypeAndClimateZone: {
        handler: function() {
          this.getMaxPossibleScore = getMaxPossibleScore({
            climate_zone: this.climate_zone,
            type_vintage: this.type_vintage,
            max_target_scores_by_presets: this.max_target_scores_by_presets,
            prototype: this.prototype,
            type_vintage_index: this.getTypeVintageIndex,
            flexible_path_setup: this.flexible_path_setup
          })
        },
        immediate: true,
        deep: true
      },
      'flex_mandatory_measures': {
        handler: function() {
          this.getMaxPossibleScore = getMaxPossibleScore({
            climate_zone: this.climate_zone,
            type_vintage: this.type_vintage,
            max_target_scores_by_presets: this.max_target_scores_by_presets,
            prototype: this.prototype,
            type_vintage_index: this.getTypeVintageIndex,
            flexible_path_setup: this.flexible_path_setup
          })
        },
        immediate: true,
        deep: true
      },
      'flex_excluded_measures': {
        handler: function() {
          this.getMaxPossibleScore = getMaxPossibleScore({
            climate_zone: this.climate_zone,
            type_vintage: this.type_vintage,
            max_target_scores_by_presets: this.max_target_scores_by_presets,
            prototype: this.prototype,
            type_vintage_index: this.getTypeVintageIndex,
            flexible_path_setup: this.flexible_path_setup
          })
        },
        immediate: true,
        deep: true
      },
    },
    mounted() {
      if(!this.getFlexiblePresetSetupObj?.preset) this.updateMaxTargetScorePreset(this.getPresetByPrototypeAndClimateZone)
    },
    methods:{
      getMaxCostEffectiveScore,
      toggleCalculationMethodAlert(event, show) {
        if (!this.getMaxTargetScorePresetSelectedByPreset.customCombinationWarn) {
          return
        }

        if (!show) {
          this.$eventBus.$emit('closeConfirmPopover', { preventWhenHovering: true })
          return
        }
        this.$eventBus.$emit('openConfirmPopover', {
          targetElem: event.target,
          text: this.getMaxTargetScorePresetSelectedByPreset.customCombinationWarn,
          textAsHtml: true,
          side: 'left',
          buttons: ['Learn More'],
          disableFooter: true,
          sumTop: -9,
          sumLeft: 2,
          // maxWidth: 200,
          onConfirmCallback: () => {
            window.open(`https://intercom.help/explorer-local-energy-codes/en/articles/9468166`, '_blank')
          }
        })
      },
      openDescriptionModal({type,id,slug}){
        this.$eventBus.$emit('openDescriptionModal', {type,id,slug})
      },
      updateMaxTargetScorePreset(preset) {
        this.$refs.customCombinationPresents.close()
        const changeFnc = (canUpdate) => {
          if (!canUpdate) {
            return
          }

          const setup = {...this.flexible_path_setup }
          setup.cost_effective_presets.removeInArray({ climate_zone_id: this.climate_zone.id, prototype_id : this.prototype.id })
          setup.cost_effective_presets.push({ climate_zone_id: this.climate_zone.id, prototype_id : this.prototype.id, preset: preset.value })
          this.$eventBus.$emit('updateFlexiblePathSetup', setup)
        }

        if (this.change_calculation_method_checker) {
          this.change_calculation_method_checker(preset.value, changeFnc, this.$refs.customCombinationPresents.$el)
          return
        }

        changeFnc(true)
      },
      onOpenPolicyFlexiblePathScoreWarningModal(payload) {
        const { targetScoreInput, maxPossibleScore, maxCostEffectiveScore, typeVintageId, climateZoneId, prototypeId, typeVintage, lastTargetScoreInput } = payload

        this.targetScore = targetScoreInput
        this.maxPossibleScore = maxPossibleScore
        this.maxCostEffectiveScore = maxCostEffectiveScore
        this.typeVintageId = typeVintageId
        this.climateZoneId = climateZoneId
        this.prototypeId = prototypeId
        this.typeVintage = typeVintage
        this.lastTargetScoreInput = lastTargetScoreInput

        let title = `Careful, a required flexible score of ${this.targetScore} cannot be achieved cost-effectively.`
        let scenario = 'scenario_1'
        let description = ''
        if (this.isScoreAboveMaxAchievableAndBelowMaxCostEffective || this.isScoreAboveMaxAchievableAndMaxCostEffectiveAndNotEqual) {
          description = `According to cost-effectiveness study results, a score of <b>${this.maxCostEffectiveScore}</b> is the maximum cost-effective score. Based on the measures you have deleted or made mandatory, a score of <b>${this.maxPossibleScore}</b> is the maximum possible score.`
          scenario = 'scenario_2'
        } else if (this.isScoreAboveMaxAchievableAndMaxCostEffectiveAndEqual) {
          description = `According to cost-effectiveness study results, a score of <b>${this.maxCostEffectiveScore}</b> is the maximum cost-effective score.`
          scenario = 'scenario_3'
        }

        let buttons = []
        if (this.isScoreAboveMaxAchievableAndMaxCostEffectiveAndEqual) {
          buttons = [
            {
              title: 'Proceed with the new score anyway',
              action: this.proceedWithTheNewScore,
            },
            {
              title: 'Cancel',
              action: this.goBack,
              ignoreDontShowAgain: true,
            }
          ]
        } else {
          buttons = [
            {
              title: 'Proceed with the new score anyway',
              action: this.proceedWithTheNewScore
            },
            {
              title: 'Restore deleted and mandatory measures and set my Target Score to the maximum cost-effective score',
              action: this.restoreAll,
            },
            {
              title: 'Cancel',
              action: this.goBack,
              ignoreDontShowAgain: true,
            }
          ]
        }

        this.$eventBus.$emit('openOptionsModal',{
          title,
          description: `
        <p class="psui-text-gray-50 mt-4 psui-text-p">
          ${description}
          <a
            target="_blank"
            class="psui-text-blue-60 psui-font-bold psui-cursor-pointer"
            href="https://intercom.help/explorer-local-energy-codes/en/articles/7903616-max-cost-effective-score-calculations"
          >Learn More</a>
        </p>`,
          buttons,
          checkbox: {
            label: "Don't show this next time",
            neverAskUserProp: `do_not_show_policy_flexible_path_score_warning_modal-${this.getPolicyId}-${scenario}`,
          }
        })
      },
      proceedWithTheNewScore() {
        if (!this.userCanEditPolicy) {
          return
        }

        const type_vintage = this.typeVintage
        const climate_zone = this.climate_zone
        const prototype = this.prototype
        const value = this.targetScore
        const flexible_path_setup = this.flexible_path_setup
        const type_vintage_index = this.getTypeVintageIndex

        this.lastTargetScoreInput = this.targetScore
        setTierTargetScore({ type_vintage, climate_zone, prototype, type_vintage_index, flexible_path_setup, value })
        this.closeModalWithoutChanges = false
        setTimeout(() => {
          this.closeModalWithoutChanges = null
        }, 1000)
      },
      restoreAll() {
        if (!this.userCanEditPolicy) {
          return
        }

        this.$emit('onRestore')
        this.closeModalWithoutChanges = false
        setTimeout(() => {
          this.closeModalWithoutChanges = null
        }, 1000)
      },
      goBack() {
        const type_vintage = this.typeVintage
        const climate_zone = this.climate_zone
        const prototype = this.prototype
        const value = this.lastTargetScoreInput

        const flexible_path_setup = this.flexible_path_setup

        setTierTargetScore({ type_vintage, climate_zone, prototype, flexible_path_setup, value })
        this.closeModalWithoutChanges = true
        setTimeout(() => {
          this.closeModalWithoutChanges = null
        }, 1000)
      },
      openPolicyExistingCostEffectiveDrawer(){
        const custom_combination = this.custom_combination
        const flex_mandatory_measures = this.flex_mandatory_measures
        const flex_excluded_measures = this.flex_excluded_measures
        const calculation_method = this.calculation_method
        this.$eventBus.$emit('openPolicyExistingCostEffectiveDrawer', ({custom_combination, flex_mandatory_measures, flex_excluded_measures, calculation_method}))
      }
    }
}
</script>

<style lang="scss" scoped>
  .scores {
    padding: 7px 8px;
  }
  .preset-input {
    min-width: 160px;
  }

  .flexible-score-input {
    height: 100%;
    min-width: 262px;
    padding: 8px;

  }

  .dropdown {
    margin-top: 4px;
    padding: 6px 11px;
  }

  .cost-effective-score-wrapper {
    .cost-effective-score-icon {
      visibility: hidden;
    }

    &:hover {
      .cost-effective-score-icon {
        visibility: visible;
      }
    }
  }

  .calculation-method-wrapper {
    .calculation-method-icon {
      visibility: hidden;
    }
    &:hover {
      .calculation-method-icon {
        visibility: visible;
      }
    }
  }

  .svg-position {
    ::v-deep .psui-el-tooltip-trigger {
      margin-right: calc(0.313rem + 1px); // 5px
      margin-bottom: 0.125rem; // 2px
      margin-left: -0.094rem; // 1.5px
    }
  }
</style>