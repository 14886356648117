import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Api from '@/util/Api'
import Graphql from '@/util/GraphQL'
import EventBus from '@/util/EventBus'
import cacheWorkerInstaller from '@/util/cacheWorkerInstaller'
const { Cache: MemoryCache } = require('memory-cache')
import initLogrocket from '@/services/vendor/initLogrocket'
import initHotjar from '@/services/vendor/initHotjar'
import initIntercom from '@/services/vendor/initIntercom'
import initSentry from '@/services/vendor/initSentry'
import initGtag from '@/services/vendor/initGtag'
import UserEventApiService from '@/services/api/UserEventApiService'
import "@/formatters/filters.js"

Vue.config.productionTip = false

Vue.prototype.$api = Api
Vue.prototype.$graphql = Graphql
Vue.prototype.$eventBus = EventBus
Vue.prototype.$apiCache = new MemoryCache()
Vue.prototype.$logUserEvent = UserEventApiService.log

window.$eventBus = EventBus

require('./initComponents') 
require('./overrides/overrideVuePrototypes') 
initLogrocket(store)
initHotjar(store)
initIntercom(store)
initGtag(store)
initSentry()
cacheWorkerInstaller()

window.$vueInstance = new Vue({
  name: 'MainJs',
  router,
  store,
  render: h => h(App)
}).$mount('#app')

