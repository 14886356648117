<template>
  <div
    v-if="!isLoading"
    class="grid grid-cols-1 sm:grid-cols-2 gap-6 py-6 px-8"
  >
    <PsInput
      v-model="policy_option.title"
      label="Title"
      :value="policy_option.title"
      @input="policy_option.title = $event.target.value"
    />

    <PsInput
      v-model="policy_option.slug"
      label="Slug"
      :value="policy_option.slug"
      @input="policy_option.slug = $event.target.value"
    />

    <PsInputTextArea
      v-model="policy_option.short_description"
      label="Short Description"
      class="col-span-2"
      :rows="2"
      :value="policy_option.short_description"
      @input="policy_option.short_description = $event.target.value"
    />

    <PsInput
      v-model="policy_option.title_drawer_about"
      label="Title Drawer About"
      @input="policy_option.title_drawer_about = $event.target.value"
    />
    
    <PsInput
      v-model="policy_option.title_drawer_create"
      label="Title Drawer Create"
      @input="policy_option.title_drawer_create = $event.target.value"
    />

    <PsCheckboxSimple
      :checked="policy_option.is_advanced"
      label="Is Advanced?"
      @change="policy_option.is_advanced = !policy_option.is_advanced"
    />

    <PsCheckboxSimple
      :checked="policy_option.is_recommended"
      label="Is Recommended?"
      @change="policy_option.is_recommended = !policy_option.is_recommended"
    />

    <div class="w-full flex flex-col col-span-2">
      <label class="font-bold mb-1 gray-80">Alert Text</label>
      <ContentEditor
        :content.sync="policy_option.alert_text"
      />
    </div>

    <div class="w-full flex flex-col col-span-2">
      <label class="font-bold mb-1 gray-80">Description</label>
      <ContentEditor
        :content.sync="policy_option.description"
      />
    </div>

    <div class="col-span-2 border-t border-gray05 py-5 mt-5">
      <PsButton
        :label="!$route.params.policy_option_id ? 'Create' : 'Update'"
        size="big"
        icon="add"
        class="psui-mt-6"
        :loading="isUpdating"
        @click="storePolicyOption()"
      />
    </div>
  </div>

  <SkeletonPolicyOptionForm v-else />
</template>

<script>
import PolicyOption from '@/models/PolicyOption'
import SkeletonPolicyOptionForm from './skeleton/SkeletonPolicyOptionForm.vue'

export default {
  name: 'AdminPolicyOptionForm',
  components: { SkeletonPolicyOptionForm },
  data() {
    return {
      policy_option: new PolicyOption(),
      isLoading: false,
      isUpdating: false
    }
  },
  mounted() {
    if(this.$route.params.policy_option_id) {
      this.setPolicyOption()
    }
  },
  methods: {
    async setPolicyOption() {
      this.isLoading = true
      await this.$store.dispatch('globalEntities/PolicyOption/loadGlobalPolicyOptions')

      const policy_option = this.$store.getters['globalEntities/PolicyOption/getterGlobalPolicyOption']({ id : this.$route.params.policy_option_id })
      this.policy_option = policy_option

      setTimeout(() => { this.isLoading = false }, 500)
    },

    storePolicyOption() {
      this.isUpdating = true

      this.$store.dispatch('globalEntities/PolicyOption/storeGlobalPolicyOption', { policy_option : this.policy_option })
        .then(() => {
          this.$toast.success(`PolicyOption successfully ${ this.policy_option.id ? 'updated' : 'created' }`)
          this.$router.push({ name: 'AdminPolicyOptionsIndex' })
          this.isUpdating = false
        })
    },
  }
}
</script>
