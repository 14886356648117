<template>
  <div class="svg-color flex flex-col px-4 pt-2">
    <p
      class="psui-text-p psui-text-gray-70 mb-1"
      style="font-size: 14px;"
    >
      New Buildings
    </p>
    <PsCollapse
      v-for="typePrototype in type_prototypes"
      :key="typePrototype.id"
      :title="typePrototype.title"
      :disabled="typePrototype.disabled"
      :opened="true"
      class="-ml-1"
    >
      <template #content>
        <div 
          class="collapse-content flex flex-col items-center w-full space-y-1"
        > 
          <div 
            v-for="climateZone in typePrototype.climate_zones" 
            :key="climateZone.id" 
            class="collapse-content-wrapper flex items-center w-full"
          >
            <span 
              class="psui-text-small cursor-pointer whitespace-nowrap hover:psui-text-blue-60"
              :class="[
                { 'psui-text-gray-70': !climateZone.disabled && !isRequirementSelected(typePrototype, climateZone) },
                { 'font-bold psui-text-gray-50': climateZone.disabled && isRequirementSelected(typePrototype, climateZone)},
                { 'psui-text-gray-50': climateZone.disabled }, 
                { 'psui-text-blue-60 font-bold': !climateZone.disabled && isRequirementSelected(typePrototype, climateZone) }
              ]"
              @click="$emit('toggleRequirement', typePrototype, climateZone )"                
            >
              {{ `Climate Zone ${climateZone.prefix}` }}
            </span>
            <div
              
              class="flex w-full"
            >
              <PsTooltip
                v-if="!climateZone.disabled && !isLoadingItem(typePrototype, climateZone)"
                class="ml-auto"
              >
                <template #trigger>
                  <PsIcon
                    icon="/icons/material-icons-downloaded/delete.svg"
                    size="16"
                    class="collapse-content-wrapper-action ml-auto cursor-pointer svg-color-active"
                    display="flex"
                    color="psui-text-gray-50"
                    @click="setRequirement($event ,typePrototype, [climateZone], climateZone.disabled)"
                  />
                </template>
                <template #content>
                  {{ climateZone.disabled ? 'Click to add this vintage to your policy': 'Click to remove this vintage to your policy' }}
                </template>
              </PsTooltip>
              <PsIcon
                v-else-if="isLoadingItem(typePrototype, climateZone)"
                icon="sync"
                size="16"
                display="flex"
                class="rotate ml-auto psui-text-gray-50"
              />
            </div>
          </div>
        </div>
      </template>
      <template #header-action>
        <PsTooltip v-if="!typePrototype.disabled && !isLoadingItem(typePrototype, null)">
          <template #trigger>
            <PsIcon
              icon="/icons/material-icons-downloaded/delete.svg"  
              size="16"
              class="cursor-pointer svg-color-active"
              display="flex"
              color="psui-text-gray-50"
              @click="setRequirement($event, typePrototype, typePrototype.climate_zones, typePrototype.disabled)"
            />              
          </template>
          <template #content>
            {{ typePrototype.disabled ? 'Click to add this prototype to your policy': 'Click to remove this prototype to your policy' }}
          </template>
        </PsTooltip>
        <PsIcon
          v-else-if="isLoadingItem(typePrototype, null)"
          icon="sync"
          size="16"
          display="flex"
          class="rotate ml-auto psui-text-gray-50"
        />
      </template>
    </PsCollapse>
  </div>
</template>

<script>
  export default {
    name:'PolicyEditRequirementsNewBuildingsLeftSideBar',
    props:['type_prototypes','custom_combinations','isLoading'],
    computed:{
      getPrototypeIdAndClimateZoneRawFromCustomCombination() {
        return {
            climate_zone_raw: this.custom_combinations?.[0]?.climate_zone_raw,
            prototype_id: this.custom_combinations?.[0]?.prototype_id
        }
      },
    },
    methods: {
      isRequirementSelected(typePrototype, climateZone) {
        return this.getPrototypeIdAndClimateZoneRawFromCustomCombination.climate_zone_raw == climateZone.raw && 
        this.getPrototypeIdAndClimateZoneRawFromCustomCombination.prototype_id == typePrototype.prototypes[0].id 
      },
      setRequirement(event ,typePrototype, climateZones, isCreating) {
        event.stopPropagation()
        this.$emit('setRequirements',event,typePrototype, climateZones, isCreating)
      },
      isLoadingItem(typePrototype, climateZone) {
        const loadingItem = `${typePrototype?.slug}-${climateZone?.raw}`
        return this.isLoading == loadingItem
      }
    }
  }
</script>

<style lang="scss" scoped>
.collapse-content {
  margin-left: 4px;
  .collapse-content-wrapper {
    font-weight: 400;
    .active {
      font-weight: 700 !important;
    }

    .collapse-content-wrapper-action {
      visibility: hidden;
      &:hover {
        opacity: 10 !important;
      }
    }

    &:hover {
      .collapse-content-wrapper-action {
        visibility: visible;
      }
    }
  }
}

.svg-color {
  :deep(.psui-el-collapse) {
    .svg-color-active {
      svg {
        &:hover {
          fill: #318FAC;
        }
      }
    }
  }
}

.rotate {
  animation: spin 2s infinite linear;
}

@keyframes spin {
    0%  {-webkit-transform: rotate(0deg);}
    100% {-webkit-transform: rotate(-360deg);}   
}
</style>