import MainVuex from '@/store'

const isProductionUrl = window.location.origin.includes('https://explorer.localenergycodes.com') ? true : false

const updateUser = () => {
  if (!window.hj) {
    return
  }

  const user = MainVuex.getters['getterLoggedUser']
  if (user) {
    const userInfo = {
      email: user.email
    }
    console.log('Hotjar service identifying user', userInfo)
    window.hj('identify', user.id, userInfo)
  } else {
    window.hj('identify', null, null)
  }
}
const initHotjar = () => {
  if(isProductionUrl && !window.hj && MainVuex.getters['general/getterDeviceInfo']?.log_events) {
    console.log('Hotjar service started for production')
    const installHotjar = function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}
      h._hjSettings={hjid:3389583,hjsv:6}
      a=o.getElementsByTagName('head')[0]
      r=o.createElement('script');r.async=1
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv
      a.appendChild(r)
    }
    installHotjar(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')
    updateUser()
  }
}

export default async (Store) => {
  Store.subscribe((mutation) => {
    if (!isProductionUrl) return
    if(mutation.type === 'general/setDeviceInfo') {
      initHotjar()
    }
    // updates user info when login and logout
    if(['setLogout', 'setLoggedUser'].includes(mutation.type)) {
      updateUser()
    }
  })
}
