import BasePdfResultsTableService from '@/services/pdf/BasePdfResultsTableService'
import StudyData from '@/models/StudyData'
import StudyType from '@/models/StudyType'
import MainVuex from '@/store'

import StudyDataApiService from '@/services/api/StudyDataApiService'
import StudyResultApiService from '@/services/api/StudyResultApiService'
import { STUDY_TYPES_DB_SLUGS, POLICY_TYPE_PDF } from '@/util/Enums.js'
import  {getSuperscriptStringFromNumber} from '@/util/Functions.js'
import getStudyResultVersionLabel from '@/business-logic/services/study-results/getStudyResultVersionLabel'

export default class StudyResultsGeneratePdfService extends BasePdfResultsTableService {

  fontSizeDefault = 10
  study_types = []
  type_prototypes = []
  prototypes = []
  tables = []

  type = POLICY_TYPE_PDF.RESULTS  

  constructor(args) {   
    super(args)
    return this.initPdf()
  }
  
  async initPdf() {

    try {
      await this.getForecastData()
      const tables = await this.getTables()
      await this.addTables(tables)
      await this.export()
    } catch (error) {
      console.error(error)
      throw new Error(error)
    }
  }

  async getForecastData() {
    
    
    await StudyResultApiService.get().then(({ study_types, type_prototypes }) => {
      this.study_types = study_types.map((i) => new StudyType(i)) ?? []
      this.type_prototypes = type_prototypes ?? []
    })
    
    for(const study_type of this.study_types) {
      const studyResultsAssumption = MainVuex.getters['assumptions/getterAssumptionsByStudyType'](study_type.slug)

      for( const studyGroup of study_type.study_groups) {
        for ( const typePrototype of this.type_prototypes) {
          for ( const prototype of this.getStudyGroupPrototypesFilteredByTypePrototypeAndSortedByStudyRelease({ studyGroup, typePrototype })) {
            if (window.$vueInstance.checkIfEntityShouldBeVisibleInStudyResults('prototype',prototype)
             && window.$vueInstance.checkIfEntityShouldBeVisibleInStudyResults('study_type',study_type)
            ) {
              this.registerSourceStudy(prototype.study.id)

              const key = study_type.slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS ? 'vintages' : 'fuels'

              for ( const prototypeKey of prototype?.study?.[key] ) {
                
                const studyData = await StudyDataApiService.get(this.getStudyDataPayload({ prototype, [key === 'vintages' ? 'vintage' : 'fuel' ] : prototypeKey }))
                const studyDataPayload = {}
                
                if (key === 'vintages') {
                  const building_stock_units = await MainVuex.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({ 
                    jurisdiction_id : MainVuex.getters['lastJurisdictionVisited']?.id,
                    climate_zone_prefix: MainVuex.getters['lastClimateZoneVisited']?.prefix,
                    type_prototype_id: typePrototype.id,
                    type_vintage_id : prototypeKey.type_vintage_id,
                    prototype_id: prototype.id,
                  })
                  studyDataPayload.building_stock_units = building_stock_units
                  studyDataPayload.assumption = studyResultsAssumption
                } else if (key === 'fuels') {
                  const building_estimates = await MainVuex.getters['assumptions/buildingEstimates/getterAllBuildingEstimates']({ 
                    jurisdiction_id : MainVuex.getters['lastJurisdictionVisited']?.id,
                    climate_zone_prefix: MainVuex.getters['lastClimateZoneVisited']?.prefix,
                    type_prototype_id: typePrototype.id,
                    prototype_id: prototype.id,
                  })
                  studyDataPayload.building_estimates = building_estimates 
                  studyDataPayload.assumption = studyResultsAssumption
                  studyDataPayload.building_stock_units = null
                }
                
                prototypeKey.study_data = studyData.map( (study_data) => {
                  if(study_data.fuel_id) {
                     study_data.fuel = MainVuex.getters['globalEntities/Fuel/getterGlobalFuel']({ id: study_data.fuel_id })
                  }
                  return new StudyData({ study_data, ...studyDataPayload })
                })
              }

              prototype.study_type = study_type

              this.prototypes.push(prototype)
            }
          }
        }
      }
    }
    return this.study_types
  }


  getTableHeaders(study_type, study_data) {

    const getterColumns = study_type.slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS ? 'getterExistingBuildingsColumnsSelected' : 'getterNewBuildingsColumnsSelected'      
    const columnGroups = MainVuex.getters[`studyResults/${getterColumns}`].columnGroups

    const headers = [
      {
        key: 'title',
        label: study_type.slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS ? 'Measure' : 'Package',
      }
    ]
    

    if (columnGroups) {
      
      columnGroups.forEach(columnGroup => {
        const isColumnGroupZeroed = columnGroup.columns.reduce((acc,curr)=>{
          const isColumnZerod = study_data.reduce((sAcc, sCurr)=>{
            sAcc+=sCurr?.data?.[curr.key]
            return sAcc
          },0)
          acc.push(isColumnZerod)
          return acc
        },[])
        .every(value => isNaN(value)|| value == 0)

        
        if (columnGroup.columns && !isColumnGroupZeroed) {
          columnGroup.columns.forEach((column) => {

            if (column.isActive) {
                headers.push({
                  key: column.key,
                  label: column.title,
                  groupKey: columnGroup.key,
                  groupTitle: columnGroup.title,
                  description: column.description
                })
            } 
          })
        }
      }) 
    }

    return headers
  }
  
  async getTables() {
    this.prototypes.forEach(prototype => {
      const key = prototype.study_type.slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS ? 'vintages' : 'fuels'
      const prototypeKeys = key === 'vintages' ? this.getVintagesOrdered(prototype.study.vintages) : this.getFuelsOrdered(prototype.study.fuels)
      
      for( const prototypeKey of prototypeKeys) {
        
        if (key === 'vintages' && !window.$vueInstance.checkQueryKeyValue('exclude_type_vintage_ids', prototypeKey.type_vintage_id) && prototypeKey.type_vintage_id
          || key === 'fuels' && !window.$vueInstance.checkQueryKeyValue('exclude_type_fuel_ids', prototypeKey.type_fuel_id)) {

          this.tables.push({
            study: prototype.study,
            study_type: prototype.study_type,
            prototype,
            prototypeKey,
            headers: this.getTableHeaders(prototype.study_type, prototypeKey.study_data),
            rows : prototypeKey.study_data
          })  
          
        }
      }      
    })
  }


  getTableHeaderHeadline({ study_type, prototype, prototypeKey, headers }) {
    let headerHeadlineContent
    const prototypeKeyTitle = prototypeKey.title_long ? prototypeKey.title_long : prototypeKey.title
    if(study_type.slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS) {
      const unities = prototypeKey?.study_data?.[0]?.building_stock_units ?
        Number(prototypeKey?.study_data?.[0]?.building_stock_units)?.toLocaleString() : '--'
      headerHeadlineContent = `${prototype.title} | ${prototypeKeyTitle} (${ unities } units)`
    } else {
      headerHeadlineContent = `${prototype.title} | ${prototypeKeyTitle}`
    }
    return { 
      content: headerHeadlineContent, 
      colSpan: headers.length,
      isHeadline : true,
      styles: { 
        fontSize: 20,
        font: 'Lato-Bold',
        textColor: this.colors.blue80,
        cellPadding:{
          left: 10,
          top: 3,
          bottom: 2, 
        }
      } 
    }
  }

   getTableHeaderSubtitle({ study,prototype }) {
    const studyType = this.study_types.filter(study_type => study_type.id === study.study_group.study_type_id)[0]
    const study_group = studyType.study_groups.filter(study_group => study_group.id === study.study_group.id)[0]
    const prototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt = MainVuex.getters['globalEntities/Prototype/getterPrototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt']({ type_prototype: prototype.type_prototype, study_group })
    const getVersion = getStudyResultVersionLabel({
      prototype: prototype,
      prototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt: prototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt
    })
    const getSourceIndex = getSuperscriptStringFromNumber((this.sources.findIndex(source => source.id === study.id) + 1))
    
    return `Study Source: ${study.title}${getSourceIndex}   |    Release Date: ${study.released_at}    |    ${getVersion}    |    Code Cycle: ${study.code_cycle.slug}`  
  }

  getVintagesOrdered(vintages) {
    const vintagesOrdered = [...vintages]
    vintagesOrdered.sort((a,b) => a.type_vintage?.order - b.type_vintage?.order )
    return vintagesOrdered
  }

  getStudyGroupPrototypesFilteredByTypePrototypeAndSortedByStudyRelease ({ studyGroup, typePrototype }) {
    const url = new URL(window.location.href)
    let searchParams = new URLSearchParams(url.search)
    const exclude_study_ids = searchParams.get('exclude_study_ids') ? searchParams.get('exclude_study_ids').split(',') : []
    const studies =  window.$vueInstance.getStudyGroupPrototypesFilteredByTypePrototypeAndSortedByStudyRelease({ studyGroup, typePrototype })
      .filter((prototype) => !exclude_study_ids.length || !exclude_study_ids.includes(prototype.study_id) )
      return studies?.length ? studies : []
  }

  getFuelsOrdered(fuels) {
    const fuelsOrdered = [...fuels]
    fuelsOrdered.sort((a,b) => a.type_fuel?.order - b.type_fuel?.order )
    return fuelsOrdered
  }
  
  getStudyDataPayload({ prototype, vintage, fuel }) {
  
    const studyDataPayload = {
      climate_zone_raw : window.$vueInstance.$route.params.climate_zone_raw,
      prototype_id: prototype.id,        
    }
  
    if (vintage) studyDataPayload.vintage_id = vintage.id
    if (fuel) studyDataPayload.fuel_id = fuel.id
    
    return studyDataPayload
  }

}
