<template>
  <BaseAppHeader 
    id="study-results-header"
    title="Results for"
    :has-jurisdiction-selection="true"
    @on-jurisdiction-select="onJurisdictionSelect"
  >
    <template #top-right>
      <StudyResultsHeaderActions />
    </template>
    <template #bottom-left>
      <BaseAppHeaderNavigationByStudyType />
    </template>
    <template #bottom-right>
      <FiltersWrapper class="psui-my-2" />
    </template>
  </BaseAppHeader>
</template>

<script>
import StudyResultsHeaderActions from './StudyResultsHeaderActions.vue'
import BaseAppHeaderNavigationByStudyType from '@/components/general/BaseAppHeaderNavigationByStudyType.vue'
import BaseAppHeader from '@/components/general/BaseAppHeader.vue'
import FiltersWrapper from './filters/FiltersWrapper'
import { mapGetters } from 'vuex'

export default {
  name: 'StudyResultsHeader',
  components: { 
    FiltersWrapper,
    BaseAppHeader,
    BaseAppHeaderNavigationByStudyType,
    StudyResultsHeaderActions
  },
  computed:{
    ...mapGetters(['lastStudyTypeVisited'])
  },
  methods: {
    onJurisdictionSelect(jurisdiction) {
      this.$store.dispatch('setUserLastJurisdictionAndFirstClimateZoneVisited', { value: jurisdiction, context: 'updateLastJurisdictionVisitedFromStudyResultsHeader'})
      this.$router.push({
        name: 'StudyResults',
        params: {
          ...this.$route.params,
          jurisdiction_slug: jurisdiction.slug,
          climate_zone_raw: jurisdiction.climate_zones?.[0]?.raw
        },
        query: { ...this.$route.query },
      })
    },
  },    
}
</script>

<style lang="scss" scoped>
  ::v-deep .psui-el-dropdown-menu-dialog-wrapper.psui-duration-300 {
    margin-top: 11px;
  }
</style>
