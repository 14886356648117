<template>
  <div class="psui-flex psui-items-center">
    <p class="psui-text-gray-80 psui-font-bold">
      Filter by
    </p>
    <div class="psui-flex psui-items-center psui-gap-1 psui-ml-2">
      <Dropdown 
        v-if="jurisdictions"
        ref="dropdownJurisdictionFilter"
      >
        <template #buttonLabel>
          <PsChips
            label="City/County"
            type="button"
            :checked="!isSelectingAllJurisdictions"
          />
        </template>
        <template #items>
          <div class="psui-w-full psui-flex psui-flex-col font-medium text-gray02">
            <PsCheckbox
              v-for="(jurisdiction, index) in jurisdictions" 
              :key="`filterby-${jurisdiction ? jurisdiction.id : index}`"
              :data-test-id="`comparison-filter-by-jurisdiction-${jurisdiction.title_type}`"
              :input-value="checkJurisdictionIsSelected(jurisdiction)"
              :value="checkJurisdictionIsSelected(jurisdiction)"
              :label="getJurisdictionTitleType(jurisdiction)"
              size="small"
              @input="setFilterByJurisdiction(jurisdiction)"
            />
          </div>
        </template>
      </Dropdown>

      <Dropdown                 
        ref="dropdownShowComparisonsBy"
        dropdown-dialog-classes="psui-left-0 psui-bg-white"
      >
        <template #buttonLabel>
          <PsChips
            label="Author"
            type="button"
            :checked="isFilteringByAuthor"
          />
        </template>
        <template #items>        
          <PsCheckbox
            v-for="option in getComparisonTypesByUser"
            :key="`filter-${option.label}`"
            :data-test-id="`comparison-filter-by-author-${option.label}`"
            :label="`${option.label}`"
            size="small"
            :input-value="checkShowByOptionSelected(option.value)"
            :value="checkShowByOptionSelected(option.value)"
            @input="setFilterByAuthor(option.value)"
          />
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<script>
import { COMPARISON_TYPES, STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'

export default {
  name: 'ComparisonListFilters',
  props: {
    jurisdictions: {
      type: Array,
      required: true,
    }
  },
  data: () => ({
    authors:[],
    COMPARISON_TYPES,
    STUDY_TYPES_DB_SLUGS
  }),
  computed: {
    getFilterByJurisdiction() {
      return this.$store.state.auth.user_preferences?.policies?.comparison?.filterByJurisdiction ?? []
    },
    getFilterByAuthor() {
      return this.$store.state.auth.user_preferences?.policies?.comparison?.filterByAuthor ?? []
    },
    isSelectingAllJurisdictions() {
      return !this.getFilterByJurisdiction || !this.getFilterByJurisdiction.length || this.jurisdictions.length === this.getFilterByJurisdiction.length
    },
    isFilteringByAuthor() {
      return this.getFilterByAuthor.length > 0
    },
    getComparisonTypesByUser() {
      return COMPARISON_TYPES
    },
    getGlobalStudyTypes(){
      return this.$store.getters['globalEntities/StudyType/getterGlobalStudyTypes']()
    },
  },
  methods: {
    checkJurisdictionIsSelected(jurisdiction) {
      return this.getFilterByJurisdiction.includes(jurisdiction?.id.toString())
    },
    setFilterByJurisdiction(jurisdiction) {
      this.$store.dispatch('setUserPreferences', {
        mutation: 'setUserPreferencesFilterByJurisdictionForComparisons',
        payload : { jurisdiction, jurisdictions: this.jurisdictions }
      })
      this.$refs.dropdownJurisdictionFilter.close()
    },
    checkShowByOptionSelected(option) {
      return this.getFilterByAuthor.includes(option) ?? false
    },
    setFilterByAuthor(authors) {
      this.$store.dispatch('setUserPreferences',{ 
        mutation: 'setUserPreferencesFilterByAuthorForComparisons',
        payload : { authors } 
      })
      this.$refs.dropdownShowComparisonsBy.close()
    },
    setStudyTypeSelected(study_type){
      this.toggleQueryKeyValue('only_study_type', study_type)
    },
    checkIfRouteHasTypeSelected(study_type){
      return this.checkQueryKeyValue('only_study_type', study_type)
    },
    getJurisdictionTitleType(jurisdiction) {
      if (jurisdiction) {
        return this.$options.filters.editJurisdictionTitleTypeBasedOnJurisdictionType(jurisdiction, 'title_type')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .psui-el-checkbox {
    background: none;
  }

  ::v-deep .psui-el-checkmark {
    color: #798490;
    span {
      color: #515E6A;
      font-weight: 700;
    }     
  }
  
  ::v-deep .dropdowndialog {
    padding: 12px 24px
  }

  ::v-deep .dropdown-button {
    border: none;
  }
</style>
