<template>
  <div class="w-full relative mt-2">
    <!-- <pre> {{ getSelectedItems }}</pre> -->
    <VueSelect 
      v-model="getSelectedItems"
      class="vue-select"
      :placeholder="getLabel"
      label="title"
      :options="getOptions"
      :multiple="true"
      :close-on-select="false" 
    />
    <label>{{ getLabel }}</label>
  </div>
</template>

<script>
export default {
  props: ['items'],
  data() {
    return {
      type_prototypes: [],
      type_vintages: [],
      
    }
  },
  computed: {

    getSelectedItems: {
      get() {
        return this.items
      },
      set(val) {
        this.$emit('update:items', val)
      }
    },
    getOptions() {
      if(this.$route.params.type === 'prototype') return this.type_vintages
      return this.type_prototypes
    },
    getLabel(){
      if(this.$route.params.type === 'prototype') return 'Vintages (Type vintages) related with this Type building type'
      return  'Building types (Type Prototype) related with this vintage'
    }
  },

  mounted() {
    this.getSelectOptions()
  },

  methods: {

    getSelectOptions() {
      const query = `
        {

          type_vintages {
            id
            title
          }

          type_prototypes {
            id
            title
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.type_vintages = data.type_vintages
          this.type_prototypes = data.type_prototypes
        })
    }
  }
}
</script>