import Vue from 'vue'
import AppHelper from '@/components/general/AppHelper.vue'
import formatCurrency from '@/formatters/formatCurrency'
import formatNumber from '@/formatters/formatNumber'
import { isFeatureFlagEnabled } from '@/util/Functions.js'

export const moneyDirective = (el, { value }) => {    
  el.setAttribute('title', value)
  const moneyValue = formatCurrency(value)
  el.innerHTML = moneyValue
}

export const numberDirective = (el, { value }) => {
  
  const number = typeof value === 'object' ? value.value : value
  const numberFormated = formatNumber(number)
  const onNegative = typeof value === 'object' && value?.onNegative ? value.onNegative : false
  
  el.setAttribute('title', number)

  if (onNegative && numberFormated < 0) {
    el.innerHTML = onNegative
  } else {
    el.innerHTML = numberFormated
  }
}

export const helperDirective = (el, { arg, value }) => {
  if (value) {

    if(el.childNodes[1]) {
      el.removeChild(el.childNodes[1])
    }

    const ComponentClass = Vue.extend(AppHelper)
    const instance = new ComponentClass({ propsData: { args: value, type: arg } })
    instance.$mount()

    if (value?.cssClass) {
      el.classList.add(value.cssClass)
    } else {
      el.classList.add('--helper')      
    }
    el.appendChild(instance.$el)
  }    
}

Vue.filter('striphtml', function (value) {
  if (value) {
    var div = document.createElement("div")
    div.innerHTML = value
    var text = div.textContent || div.innerText || ""
    return text    
  }
})


const updateCheckEnvVisibility = (el, binding) => {
  if(Array.isArray(binding.value)) {
    el.style.display = ( binding.value.includes(process.env.VUE_APP_ENV)  ) ? "" : "none"
  } else {
    el.style.display = ( binding.value === process.env.VUE_APP_ENV  ) ? "" : "none"
  }
}

Vue.directive('check-env', {
  bind: updateCheckEnvVisibility,
  update: updateCheckEnvVisibility,
})

Vue.directive('go-to-admin-edit', {
  bind(el, binding, vnode) {
    if( (vnode?.context?.$store?.getters?.['getterLoggedUserType'] !== 'admin' || !vnode?.context?.$store?.getters?.['general/getterDeviceInfo']?.is_admin) ) {
      return
    }

    if(binding.value) {

      el.goToAdminHover = (() => {
        el.title = `Click to edit this item on admin. This item is visible only for logged admin users.`
        el.classList.add('go-to-admin-edit-highlight')
      })
      el.goToAdminLeave = (() => {
        el.classList.remove('go-to-admin-edit-highlight')
      })

      el.goToAdminClick = (() => {
        const routeTo = vnode.context.$router.resolve(binding.value)
        window.open(routeTo.href, '_blank')
      })

      el.addEventListener('mouseenter', el.goToAdminHover)
      el.addEventListener('mouseleave', el.goToAdminLeave)
      el.addEventListener('click', el.goToAdminClick)
    }
  },
  unbind(el) {
    el.removeEventListener('mouseenter', el.goToAdminHover)
    el.removeEventListener('mouseleave', el.goToAdminLeave)
    el.removeEventListener('click', el.goToAdminClick)
  }
})

Vue.directive('if-admin', (el, binding, vnode) => {

  if( vnode?.context?.$store?.getters?.['getterLoggedUserType'] !== 'admin') {
    
    // replace HTMLElement with comment node
    const comment = document.createComment(' ')
    Object.defineProperty(comment, 'setAttribute', {
      value: () => undefined,
    })
    vnode.elm = comment
    vnode.text = ' '
    vnode.isComment = true
    vnode.context = undefined
    vnode.tag = undefined
    vnode.data.directives = undefined

    if (vnode.componentInstance) {
      vnode.componentInstance.$el = comment
    }

    if (el.parentNode) {
      el.parentNode.replaceChild(comment, el)
    }
  } else {
    el.title = `This is visible only for logged admins`
    el.style.color = 'deeppink'
  }
})

Vue.directive('add-debug-variable', {
  bind(el, binding, vnode) {
    el.style.display = 'none'
    if( (!vnode?.context?.$store?.getters?.['general/getterDeviceInfo']?.is_dev) ) return
    if(typeof binding.value !== undefined) {
      vnode.context.$eventBus.$emit('debug', JSON.parse(JSON.stringify(binding.value)))
    }
  },
  update(el, binding, vnode) {
    if( (!vnode?.context?.$store?.getters?.['general/getterDeviceInfo']?.is_dev) ) return
    if(typeof binding.value !== undefined) {
      vnode.context.$eventBus.$emit('debug', JSON.parse(JSON.stringify(binding.value)))
    }
  },
  unbind(el, binding, vnode) {
    if(typeof binding.value !== undefined) {
      vnode.context.$eventBus.$emit('removeVariablesToDebug', binding.value)
    }
  }
})

function featureFlagWatchers(vnode, updateDisplay, bindingValue) {
  vnode.context.$store.watch(
    (state) => state.general.deviceInfo.feature_flags,
    () => {
      const isFFEnabled = isFeatureFlagEnabled(bindingValue)
      updateDisplay(isFFEnabled)
    }
  )

  vnode.context.$store.watch(
    (state) => state.general.isDeviceInfoLoaded,
    (newValue) => {
      if (newValue) {
        const isFFEnabled = isFeatureFlagEnabled(bindingValue)
        updateDisplay(isFFEnabled)
      }
    }
  )
}

export const featureFlagDirective = {
  bind(el) {
    el.style.display = 'none'
  },
  inserted(el, binding, vnode) {
    const updateDisplay = (isFFEnabled) => {
      el.__vueFeatureFlag__ = isFFEnabled
      el.style.display = isFFEnabled ? 'none' : ''
    }
    const isFFEnabled = isFeatureFlagEnabled(binding.value)
    updateDisplay(isFFEnabled)

    featureFlagWatchers(vnode, updateDisplay, binding.value)
  },
  unbind(el) {
    delete el.__vueFeatureFlag__
  }
}

export const elseFeatureFlagDirective = {
  bind(el) {
    el.style.display = 'none'
  },
  inserted(el, binding, vnode) {    
    const updateDisplay = (isFFEnabled) => {
      if (isFFEnabled !== undefined) {
        el.style.display = isFFEnabled ? '' : 'none'
      }
    }

    const sibling = vnode?.elm?.previousElementSibling?.__vue__.$vnode.data.directives
    const directives = sibling?.map(directive => directive.value)?.flat(Infinity)
    const isFFEnabled = isFeatureFlagEnabled(directives)
    if(isFFEnabled) {
      updateDisplay(isFFEnabled)
    }

    featureFlagWatchers(vnode, updateDisplay, directives)
  },
  unbind(el) {
    delete el.__vueFeatureFlag__
  }
}
