export function checkDeepObjectChanges(a, b, prefix = '') {
  let changeKeys = []
  const check = (a, b) => {
    Object.keys(a || {}).forEach((key) => {
      const keyPath = `${prefix ? prefix + '.' : ''}${key}`
      if (typeof a[key] === 'object') {
        changeKeys = [...changeKeys, ...checkDeepObjectChanges(a[key],b?.[key], keyPath)]
      }
      if (a[key] !== b?.[key]) {
        changeKeys.push(keyPath)
      }
    })
  }
  check(a,b)
  check(b,a)
  return changeKeys
}

export function getDeepObjectTypesAndValues(a, prefix = '') {
  let props = {}
  Object.keys(a || {}).forEach((key) => {
    const keyPath = `${prefix ? prefix + '.' : ''}${key}`
    if (a[key] && typeof a[key] === 'object') {
      const isArray = Array.isArray(a[key])
      props[keyPath] = [(isArray ? 'array' : typeof a[key]), a[key]]
      if (!isArray) {
        props = {...props, ...getDeepObjectTypesAndValues(a[key], keyPath)}
      }
    } else {
      props[keyPath] = [typeof a[key], a[key]]
    }
  })
  return props
}
