<template>
  <BaseModal
    ref="baseModal"
    modal-dialog-classes="w-full max-w-xs-sm modal-dialog-cls"
    :hierarchy="MODAL_HIERARCHY.SECONDARY"
    title="You created this policy using an older version"
    :disable-close="true"
  > 
    <div class="psui-flex psui-flex-col psui-mt-4 psui-space-y-4">
      <span class="psui-text-gray-50 psui-text-p">
        When setting up a policy for existing buildings, you now choose how you want permit applicants to comply.
        <br><br>
        What compliance method would you like to set for your policy? You can always change your mind later, or check both options to generate both versions <a
          target="_blank"
          href="https://intercom.help/explorer-local-energy-codes/en/articles/8214039"
          class="psui-text-blue-60 psui-font-bold"
        >Learn more</a>
      </span>

      <div>
        <div class="psui-flex psui-flex-col psui-mt-4">
          <div class="psui-flex psui-items-center checkbox-item-elem">
            <PsCheckboxSimple
              size="small"
              :checked="data.prescriptive_compliance"
              @change="data.prescriptive_compliance = !data.prescriptive_compliance"
            />
            <span class="psui-text-gray-50">
              <b>Prescriptive method</b>
              Permit applicants will install a check list of measures.
            </span>
          </div>
          <div class="psui-flex psui-items-center checkbox-item-elem">
            <PsCheckboxSimple
              size="small"
              :checked="data.flexible_compliance"
              @change="data.flexible_compliance = !data.flexible_compliance"
            />
            <span class="psui-text-gray-50">
              <b>Flexible method</b>
              Permit applicants will pick and choose measures from a wider list as long as their choices add up to the minimum energy savings score.
            </span>
          </div>
        </div>
      </div>

      <div class="flex">
        <PsButton
          label="Save and open"
          layout="solid"
          :loading="isLoading"
          size="medium"
          @click="create"
        />
        <PsButton
          label="Cancel"
          layout="onlytext"
          size="medium"
          @click="cancel"
        />
      </div>
    </div>
  </BaseModal>
</template>

<script>
  import { MODAL_HIERARCHY } from '@/util/Enums.js'
  import PolicyApiService from '@/services/api/PolicyApiService'
  import { defaultExistingBuildingsImpactAssumptionValues, defaultNewBuildingsImpactAssumptionValues } from '@/models/ImpactAssumptions'

  export default {
    name:'ModalFlexibleRequirements',
    data:()=>({
      MODAL_HIERARCHY,
      policy:null,
      data: {
        flexible_compliance:false,
        prescriptive_compliance:false
      },
      flexible_path_setup:null,
      isLoading: false
    }),
    computed:{
      getTitle() {
        return this.policy?.title
      },
      getPolicyOptions() {
        return this.$store.getters['policy/getterPolicyOptionsWithPrototypes']()
      },
    },
    mounted() {
      this.$eventBus.$on('openModalFlexibleRequirements', ({policy,flexible_path_setup}) => {
        this.flexible_path_setup = flexible_path_setup
        this.policy = policy
        this.data = {
          flexible_compliance: false,
          prescriptive_compliance: false
        }
        
        setTimeout(()=>{
          this.$refs.baseModal.showModal = true
        }, 500)
      })
    },
    beforeDestroy() {
      this.$eventBus.$off('openModalFlexibleRequirements')
    },
    methods:{
      cancel() {
        this.$router.push({
          name: 'PoliciesList',
        })
        this.$refs.baseModal.showModal = false
      },
      removeUnderscoreAndCapitalize(string){
        return string.replace(/_/g," ").replace(/(^\w|\s\w)/g, m => m.toUpperCase())
      },
      async createPolicy(type, basePolicy = {}) {
        const commonAttrs = [
          'jurisdiction_id', 'policy_option_id', 'user_device_id', 'user_id', 'years',
          ...Object.keys(defaultExistingBuildingsImpactAssumptionValues),
          ...Object.keys(defaultNewBuildingsImpactAssumptionValues),
        ]
        const newPolicyObj = commonAttrs.reduce((acc, curr) => {
          acc[curr] = basePolicy[curr]
          return acc
        }, { is_auto_generated: false, title: `[${this.removeUnderscoreAndCapitalize(type)}] ${basePolicy.title}` })

        const generateContainers = (includeCC) => {
          return basePolicy.policy_containers.map((container) => {
            return {
              ...container,
              id: undefined,
              policy_id: undefined,
              study_type: undefined,
              custom_combinations: container.custom_combinations.map((cc) => {
                return {
                  ...cc,
                  fuel: undefined,
                  id: undefined,
                  measures: includeCC ? cc.measures.map((m) => m.id) : [],
                  meta: {
                    ...cc.meta
                  },
                  policy_container_id: undefined,
                  policy_id: undefined,
                  prototype: undefined,
                  vintage: undefined,
                  user_id: undefined,
                }
              })
            }
          })
        }

        if(type === 'prescriptive_compliance') {
          const clearFlexSetup = {
            ...this.flexible_path_setup,
            tiers: this.flexible_path_setup?.tiers?.map((tier) => {
              return {
                ...tier,
                mandatory_measures: [],
                excluded_measures: [],
                target_scores: tier?.target_scores?.map((tS) => {
                  return {
                    ...tS,
                    value: null
                  }
                }) || []
              }
            }) || []
          }
          return await PolicyApiService.completeBatchStore(newPolicyObj, generateContainers(true), clearFlexSetup)
        } else {
          return await PolicyApiService.completeBatchStore(newPolicyObj, generateContainers(false), this.flexible_path_setup)
        }
      },
      async create() {
        this.isLoading = true

        if (Object.values(this.data).every(type_value => !type_value)) {
          this.$toast.info(`Please, select a option.`, { duration: 10000 })
          this.isLoading = false
          return
        }

        const policies = await Promise.all(
          Object.keys(this.data)
            .filter(type => this.data[type])
            .map(type => this.createPolicy(type, { ...this.policy }))
        )

        await PolicyApiService.delete(this.policy.id)
            .then((response) => {
              if (response.status === 200) {
                this.$store.commit('policy/removeUserPolicy', { id: this.policy.id })
              }
            })

        if (policies.length === 1) {
          this.$router.push(`/policies/${policies[0].id}`)
        } else if (policies.length === 2) {
          this.$router.push(`/policies/${policies[1].id}`)
          window.open(`/policies/${policies[0].id}`, '_blank')
        }

        this.isLoading = false
        this.$refs?.baseModal?.close()
        this.$refs.baseModal.showModal = false
      }
    }
  }
</script>

<style lang="scss" scoped>
::v-deep .modal-dialog-cls {
  max-width: 600px;

  .checkbox-item-elem {
    margin-bottom: 15px;
    align-items: self-start;

    > span {
      font-size: 0.95rem;
      b {
        color: black;
        display: block;
      }
    }

    .psui-el-checkbox {
      margin-top: 3px;
    }
  }
}
</style>
