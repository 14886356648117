<template>
  <div 
    id="wrapper-impact-breakdown"
    class="psui-card psui-w-full psui-bg-white psui-rounded-lg psui-px-6 psui-pb-4 pt-0 psui-shadow-elevation-5 psui-mb-8"
  >
    <div class="psui-card-header psui-flex psui-items-center psui-justify-between psui-mb-4 psui-pt-4">
      <h4 class="psui-text-h6 psui-font-bold psui-text-gray-80">
        Impact Breakdown
      </h4>
      
      <PsButton
        label="Add/Hide Columns"
        layout="onlytext"
        icon="calendar_view_week"
        size="small"
        @click.native="$eventBus.$emit('openDrawerPolicyEditColumns', {studyType:columnSystemType, module:'policy'})"
      />
    </div>

    <PsTableResults
      v-if="!getPolicyIsEmpty"
      id="table-impact-breakdown"
      :policy="policy"
      :summary-data="policy_summary_data"
      :column-groups="policyColumnGroupsSelected.columnGroups"
      :show-rows-collapsed="true"
      :show-order="false"
      :format-function="formatStudyData"
      :custom-level-opened="1"
      :items-hidden-indexes="getItemsHiddenIndexes"
      @collapse-row="onCollapseRow"
      @policy-selected="setPolicyItemSelected"
      @setPolicyItemSelected="setPolicyImpactItemSelected"
      @openDescriptionModal="openDescriptionModal"
    >
      <template #header>
        <PsTableResultsHead
          :header="policyColumnGroupsSelected.columnGroups"
          :first-column-title="`Measures & Packages`"
          :first-column-description="`View impacts by building type and climate zone`"
          @click-column-group-helper="openColumnGroupHelper"
          @click-column-helper="openColumnHelper"
        />
      </template>
    </PsTableResults>
  </div>
</template>

<script>
import { handleTableScroll } from '@/util/Functions'
import { STUDY_TYPES_DB_SLUGS } from '@/store/global-entities/StudyType.js'
import { updateColumnDescriptionCopies } from '@/util/Functions.js'

export default {
  name: 'PolicyTable',
  props: ['policy', 'policy_summary_data'],
  data: () => ({
    scrollableTableEl : null,
  }),
  computed: {
    getItemsHiddenIndexes() {
      return this.$store.getters['policy/getterHiddenItems']
    },
    userCanEditPolicy() {
      return this.$store.getters['policy/getterUserCanEditPolicy'](this.policy?.id)
    },
    policyColumnGroupsSelected() {
      if (this.getPolicyStudyType === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS) {
        return this.$store.getters['policy/getterPolicyExistingBuildingsColumnsSelected']
      } else {
        const studyId = this.policy?.policy_containers[0]?.custom_combinations[0]?.prototype?.study_id
        const newBuildingsData = this.$store.getters['policy/getterPolicyNewBuildingsColumnsSelected']
        const modifiedColumnGroups = newBuildingsData.columnGroups.map(columnGroup => {
          const updatedColumns = columnGroup.columns.map(column => {
            const updatedInfo = updateColumnDescriptionCopies(studyId, column)
            return { ...column, ...(updatedInfo || {}) }
          })
          return { ...columnGroup, columns: updatedColumns }
        })
        return { ...newBuildingsData, columnGroups: modifiedColumnGroups }
      }
    },
    getPolicyIsEmpty() {
      return this.policy_summary_data?.[0]?.items?.length === 0
    },
    columnSystemType() {
      return this.getPolicyStudyType === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS ? 'Existing Buildings' : 'New Buildings'
    },
    getPolicyStudyType() {
      return this.$store.getters['policy/getterPolicyStudyTypes']({policy_id: this.policy?.id, policy: this.policy})[0]
    },
  },
  mounted() {
    document.addEventListener('scroll', handleTableScroll)
    this.watchTableScrolling()
  },
  beforeDestroy() {
    document.removeEventListener('scroll', handleTableScroll)
    this.unwatchTableScrolling()
  },
  methods: {
    openDescriptionModal({type,slug}) {
      this.$eventBus.$emit('openDescriptionModal', {type,slug})
    },
    setPolicyImpactItemSelected({column}){
      const getResetItem = {
        id: "policy",
        isReset: true,
        title: "Total City-wide Impact"
      }
      this.$eventBus.$emit('setPolicyItemSelected', {item:getResetItem, columnSelectedKey: column?.key ?? column})

    },
    openColumnGroupHelper(columnGroup) {
      this.$eventBus.$emit('openDrawerContent', { type: columnGroup.hasHelper.type, id: columnGroup.hasHelper.id, slug: columnGroup.hasHelper.slug })
    },
    openColumnHelper(column) {
      this.$eventBus.$emit('openDescriptionModal', { type: column.hasHelper.type, id: column.hasHelper.id, slug: column.hasHelper.slug })
    },
    onCollapseRow(item) {
      this.$store.commit('policy/setHiddenItems', item)
    },
    setPolicyItemSelected({ item, column }) {
      const element = document.getElementById('policy-index')
      const warning = document.querySelector('.policy-warning')
      const scrollTop =  typeof(warning) != 'undefined' && warning != null ? 77 : 10
      element.scrollTo({ top: scrollTop, left: 0, behavior: 'smooth' })
      this.$eventBus.$emit('setPolicyItemSelected', { item, columnSelectedKey: column.key })
    },
    watchTableScrolling() {
      this.scrollableTableEl = document.getElementById('table-impact-breakdown')

      if (this.scrollableTableEl) {
        this.scrollableTableEl.addEventListener('scroll', this.updatePosition)
      }
    },
    unwatchTableScrolling() {
      if (this.scrollableTableEl) {
        this.scrollableTableEl.removeEventListener('scroll', this.updatePosition)
      }
    },
    updatePosition() {
      const elementToScroll = document.getElementById('policy-index')
      const elementToCalc = document.getElementById('wrapper-impact-breakdown')
      const wrapperRect = elementToCalc.getBoundingClientRect()
      const tableRect = this.scrollableTableEl.getBoundingClientRect()
      const totalSizeDifference = tableRect.top + wrapperRect.height
      const cardHeader = document.getElementById('wrapper-impact-breakdown').querySelector(".psui-card-header")

      let cardHeaderStyles = window.getComputedStyle(cardHeader)
      let cardHeaderHeight = parseFloat(cardHeaderStyles['marginBottom']) + parseFloat(cardHeaderStyles['height'])

      let elementToCalcStyles = window.getComputedStyle(elementToCalc)
      let elementToCalcHeight = parseFloat(elementToCalcStyles['marginTop']) + parseFloat(elementToCalcStyles['marginBottom'])
      let totalSizeToScroll = wrapperRect.height + elementToCalcHeight

      if (totalSizeDifference > (window.outerHeight - cardHeaderHeight)) {
        elementToScroll.scrollTo({ top: totalSizeToScroll, left: 0, behavior: 'smooth' })
      }
    },
  }
}
</script>
