<template>
  <div class="psui-flex psui-items-center">
    <div class="psui-text-small psui-font-bold psui-text-gray-80 whitespace-nowrap psui-mr-2">
      Climate Zone 
    </div>
    <div
      v-if="climate_zones.length > 0"
      class="psui-flex psui-flex-wrap psui-gap-1"
    >
      <PsChips
        v-for="climate_zone in climate_zones"
        :key="`dropdown-climate_zone-${climate_zone.prefix}`"
        :label="`${climate_zone.prefix}`"
        :value="climate_zone.raw == $route.params.climate_zone_raw"
        :checked="climate_zone.raw == $route.params.climate_zone_raw"
        type="radio"
        name="climateZone"
        @change="setClimateZone(climate_zone)"
      />
    </div>
    <div
      v-else
      class="flex space-x-2"
    >
      <vue-skeleton-loader
        :width="30"
        :height="30"
        :rounded="true"
      />
      <vue-skeleton-loader
        :width="30"
        :height="30"
        :rounded="true"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterClimateZone',
  computed: {
    climate_zones() {
      const { climate_zones } = this.$store.getters['lastJurisdictionVisited']
      return climate_zones ? [...climate_zones] : false
    }
  },
  methods: {
    setClimateZone(climate_zone) {
      this.$store.dispatch('setUserPreferences', { payload: { path: 'lastClimateZonesVisited', value: [climate_zone ]}})
      this.pushToParams('climate_zone_raw', climate_zone.raw)
    }
  }
}
</script>

<style>

</style>