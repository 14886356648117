import {
  initial_cost,
  annual_bill_savings,
  simple_payback,
  emission_savings,
  on_bill_cost_ratio,
  on_bill_cost_ratio_2025,
  lsc_2025_benefit_to_cost_ratio,
  kwh_savings,
  therms_savings,
  lifecycle_savings,
  compliance_margin,
  tdv_benefit_to_cost_ratio,
  baseline_fuel_type,
  forecast_units,
  forecast_emissions_savings,
  forecast_kwh_savings,
  forecast_therms_savings,
  forecast_lifecycle_savings,
  forecast_initial_cost,
  tdv2022_benefit_to_cost_ratio,
  energy_savings_combined,
  annual_bill_savings_care,
  lifecycle_savings_care,
  edr1_total_margin,
  on_bill_cost_ratio_care,
  on_bill_cost_ratio_2025_care,
  simple_payback_care,
  subsidy_needed,
  five_years_payback,
  subsidy_needed_care,
  five_years_payback_care,
  city_wide_subsidy_needed,
  city_wide_five_year_payback,
  city_wide_subsidy_needed_care,
  city_wide_five_year_payback_care,
  flexible_score,
} from '@/modules/app/shared/default-app-columns'
import { showTooltipOnNegativeValue } from '@/util/TooltipHelpers'

export default {
  existingBuildings: {
    key: 'existingBuildings',
    studyType: 'Existing Buildings',
    columnGroups: [
      {
        order: 0,
        key: "fixed_columns",
        title: 'Benefit/Cost Ratios',
        columns: [
          on_bill_cost_ratio(),
          on_bill_cost_ratio_2025(),
          on_bill_cost_ratio_2025_care(),
          simple_payback(),
          tdv2022_benefit_to_cost_ratio(),
          lsc_2025_benefit_to_cost_ratio(),
        ],
        hasHelper: {
          type: 'helpers',
          id: 23,
          slug: 'fixed_columns',
        }
      },
      {
        order: 1,
        key: "per_home_results",
        title: 'Per Home Results',
        columns: [
          {
            ...initial_cost(),
            hasTooltipOnContentCell: showTooltipOnNegativeValue()
          },
          {
            ...annual_bill_savings(),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
          {
            ...emission_savings(),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
          lifecycle_savings(),
          {
            ...kwh_savings(),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
          {
            ...therms_savings(),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
          energy_savings_combined(),
          flexible_score(),
        ],
        hasHelper: {
          type: 'helpers',
          id: 24,
          slug: 'per_home_results',
        }
      },
      {
        order: 2,
        key: "city_wide_impact",
        title: 'Wide Impact',
        columns: [
          forecast_units(),
          {
            ...forecast_emissions_savings(),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
          {
            ...forecast_initial_cost(),
            hasTooltipOnContentCell: showTooltipOnNegativeValue()
          },
          {
            ...forecast_lifecycle_savings(),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
          {
            ...forecast_kwh_savings(),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
          {
            ...forecast_therms_savings(),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
        ],
        hasHelper: {
          type: 'helpers',
          id: 25,
          slug: 'city_wide_impact',
        }
      },
      {
        order: 3,
        key: "per_home_subsidy_needed_columns",
        title: 'Subsidy Needed Per Home',
        columns: [
          subsidy_needed(),
          five_years_payback(),
          subsidy_needed_care(),
          five_years_payback_care(),
        ],
        hasHelper: {
          type: 'helpers',
          id: 109,
          slug: 'per_home_subsidy_needed_columns',
          showOnEditHideColumns: true,
          customButton: {
            title: 'Learn More',
            url: 'https://intercom.help/explorer-local-energy-codes/en/articles/6805213'
          }
        }
      },
      {
        order: 4,
        key: "city_wide_subsidy_needed_columns",
        title: 'Subsidy Needed City Wide',
        columns: [
          city_wide_subsidy_needed(),
          city_wide_five_year_payback(),
          city_wide_subsidy_needed_care(),
          city_wide_five_year_payback_care(),
        ],
        hasHelper: {
          type: 'helpers',
          slug: 'city_wide_subsidy_needed_columns',
          showOnEditHideColumns: true,
          customButton: {
            title: 'Learn More',
            url: 'https://intercom.help/explorer-local-energy-codes/en/articles/6805213'
          }
        }
      },
    ]
  },
  newBuildings: {
    key: 'newBuildings',
    studyType: 'New Buildings',
    columnGroups: [
      {
        order: 0,
        key: "fixed_columns",
        title: 'Cost-Effectiveness',
        columns: [
          simple_payback(),
          simple_payback_care(),
          on_bill_cost_ratio(),
          on_bill_cost_ratio_care(),
          tdv_benefit_to_cost_ratio(),         
        ],
        hasHelper: {
          type: 'helpers',
          id: 23,
          slug: 'fixed_columns',
        }
      },
      {
        order: 1,
        key: "per_home_results",
        title: 'Per Home Results',
        columns: [
          edr1_total_margin({ isActive : true, isDisabled: false }),
          compliance_margin({ isActive : false, isDisabled: false }),
          {
            ...initial_cost(),
            hasTooltipOnContentCell: showTooltipOnNegativeValue()
          },
          {
            ...annual_bill_savings(),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
          {
            ...annual_bill_savings_care(),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
          {
            ...emission_savings(),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
          lifecycle_savings(),
          lifecycle_savings_care(),
          {
            ...kwh_savings(),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
          {
            ...therms_savings(),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
          baseline_fuel_type(),
          energy_savings_combined(),
        ],
        hasHelper: {
          type: 'helpers',
          id: 24,
          slug: 'per_home_results',
        }
      },
      {
        order: 2,
        key: "city_wide_impact",
        title: 'Wide Impact',
        columns: [
          forecast_units(),
          {
            ...forecast_emissions_savings(),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
          {
            ...forecast_initial_cost(),
            hasTooltipOnContentCell: showTooltipOnNegativeValue()
          },
          {
            ...forecast_lifecycle_savings(),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
          {
            ...forecast_kwh_savings(),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
          {
            ...forecast_therms_savings(),
            hasTooltipOnContentCell: showTooltipOnNegativeValue('Negative numbers indicate an increase')
          },
        ],
        hasHelper: {
          type: 'helpers',
          id: 25,
          slug: 'city_wide_impact',
        }
      },
      {
        order: 3,
        key: "per_home_subsidy_needed_columns",
        title: 'Subsidy Needed Per Home',
        columns: [
          subsidy_needed(),
          five_years_payback(),
          subsidy_needed_care(),
          five_years_payback_care(),
        ],
        hasHelper: {
          type: 'helpers',
          id: 109,
          slug: 'per_home_subsidy_needed_columns',
          showOnEditHideColumns: true,
          customButton: {
            title: 'Learn More',
            url: 'https://intercom.help/explorer-local-energy-codes/en/articles/6805213'
          }
        }
      },
      {
        order: 4,
        key: "city_wide_subsidy_needed_columns",
        title: 'Subsidy Needed City Wide',
        columns: [
          city_wide_subsidy_needed(),
          city_wide_five_year_payback(),
          city_wide_subsidy_needed_care(),
          city_wide_five_year_payback_care(),
        ],
        hasHelper: {
          type: 'helpers',
          slug: 'city_wide_subsidy_needed_columns',
          showOnEditHideColumns: true,
          customButton: {
            title: 'Learn More',
            url: 'https://intercom.help/explorer-local-energy-codes/en/articles/6805213'
          }
        }
      }
    ]
  }
}
