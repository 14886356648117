<template>
  <div
    class="mt-6"
  >
    <div
      :is="getComponent" 
      :building_types="building_types"
      :building_type="building_type"
      :type_vintages="type_vintages"
      :jurisdiction="jurisdiction"
      :get-tab-selected="getTabSelected"
    />
  </div>
</template>

<script>

import AssumptionsResidentialNew from './AssumptionsResidentialNew.vue'
import AssumptionsResidentialExisting from './AssumptionsResidentialExisting.vue'
import { mapGetters } from "vuex"
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'

export default {
  name: 'AssumptionsResidential',
  components: { AssumptionsResidentialNew },
  props: ['building_types', 'building_type', 'type_vintages', 'path','getTabSelected' ],
  computed: {   
    ...mapGetters(['lastStudyTypeVisited']),
    getComponent() {
      const tabSelectedWithinBuildingEstimates = {
      [STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS]:AssumptionsResidentialExisting,
        [STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS]: AssumptionsResidentialNew
      }

      return tabSelectedWithinBuildingEstimates[this.getTabSelected.slug]    
    },
    jurisdiction() {
      return this.$store.getters['lastJurisdictionVisited'] ?? false
    },
    getPolicyType(){
      return this.$route.params.policy_id ?
      this.$store.getters['policy/getterUserPolicy']({id: this.$route.params.policy_id}).policy_containers[0].study_type.slug 
      : this.lastStudyTypeVisited
    },
    getStudyTypes(){
      return this.$store.getters['globalEntities/StudyType/getterGlobalStudyTypes']()
    },
  
  },
  watch:{
    getPolicyType(value){
      this.$store.commit('assumptions/updateDrawerTab', this.getStudyTypes.findInArray({slug:value}))
    }
  },
  mounted() {
    this.$store.commit('assumptions/updateDrawerTab', this.getStudyTypes.findInArray({slug:this.getPolicyType}))
  },
}
</script>

<style scoped lang="scss">
  h2 {
    margin-bottom: 10px;
  }

  ::v-deep .psui-el-input {
    width: 140px;
  }

  ::v-deep .psui-el-tab-header button.status-disabled {
    cursor: pointer;
  }

  .notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
</style>
