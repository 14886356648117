<template>
  <BaseModal
    ref="baseModal"
    modal-dialog-classes="w-full max-w-sm"
    :title="isEditing ? 'Edit policy name' : 'Create a new policy'"
    @close="clearPolicy"
  >
    <div class="w-full flex flex-col items-start mt-6">
      <InputWithLabel
        ref="policyName"
        v-model="policy.title"
        label="Policy Name"
        :validation="validation.title"
      />

      <JurisdictionSelect
        v-show="!isEditing"
        ref="jurisdictionSelect"
        data-test-id="jurisdiction-select-modal-policy-create-edit"
        class="w-full"
        :should-scroll="false"
        :input-class="'input-with-label block appearance-none w-full h-full border-b-2 border-gray06 bg-gray06 text-header-3 px-3 pb-2 py-6 font-normal mt-3'"
        @jurisdictionSelected="onJurisdictionSelect"
      />
      <p
        v-if="validation.jurisdiction.hasError && validation.jurisdiction.label"
        class="text-red w-full pl-3 pt-2 text-p-1"
      >
        {{ validation.jurisdiction.label }}
      </p>
      
      <PsButton
        class="mt-8" 
        size="medium" 
        :label="submitFormLabel"
        @click="submit()"
      />
    </div>
  </BaseModal>
</template>

<script>
import Policy from '@/models/Policy'
export default {
  name: 'ModalPolicyCreateEdit',
  data() {
    return {
      policy: new Policy(),
      shouldRedirect: true,
      validation: {
        title: {
          hasError: false,
          label: ''
        },
        jurisdiction: {
          hasError: false,
          label: ''
        },
      }
    }
  },
  computed: {
    isEditing() {
      return this.policy?.id ?? null
    },
    submitFormLabel() {
      return this.isEditing ? 'Update' : 'Create and Save'
    }
  },
  mounted() {
    this.$eventBus.$on('openModalPolicyCreateEdit', (id = null, shouldRedirect = true) => {
      this.$refs.baseModal.showModal = true
      if(id) {
        this.policy.id = id
        this.getPolicy()
        this.shouldRedirect = shouldRedirect
      }
      this.$refs.policyName.focusOnInput()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openModalPolicyCreateEdit')
  },
  methods: {

    clearPolicy() {
      this.policy = new Policy()
    },
    onJurisdictionSelect({ jurisdiction }) {
      this.policy.jurisdiction_id = jurisdiction.id
    },

    getPolicy() {
      const query = `
        {
          policy (findBy: { column: "id", value: "${this.policy.id}" }) {
            id
            title
            jurisdiction_id
            jurisdiction {
              id
              title
              type
              title_type
              titles
              county
              slug
              climate_zones {
                id
                raw
                prefix
              }
            }
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.policy = data.policy
          this.$refs.jurisdictionSelect.setJurisdiction(this.policy.jurisdiction)
        })
    },

    submit() {      
      this.validationClear('title')
      
      if (!this.policy.title || this.policy.title.length <= 3) {
        this.validation.title.hasError = true
        this.validation.title.label = 'Please add a policy name at least three characters.'
        return 
      } 
      
      if (!this.policy.jurisdiction_id) {
        this.validation.jurisdiction.hasError = true
        this.validation.jurisdiction.label = 'Please select a jurisdiction'
        return
      }
      
      this.storePolicy()        
        
    },

    storePolicy() {
      const url = !this.policy.id ? `/api/policies/store` : `/api/policies/update/${this.policy.id}`
      this.$api.post(url, this.policy )
        .then(({ data : policy }) => {          
          this.$refs.baseModal.showModal = false
          if(this.shouldRedirect) this.$router.push({ name: 'PolicyShow', params: { policy_id : policy.id }}, () => {})
          this.policy = new Policy()
          this.$eventBus.$emit('policyUpdated')
          this.$eventBus.$emit('comparisonUpdated')
        })
    }

  }
}
</script>