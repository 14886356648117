export default class Jurisdiction {
  constructor(obj = {}) {
    this.key = obj.key || 0
    this.id = obj.id || null
    this.title = obj.title || null
    this.type = obj.type || null
    this.title_type = obj.title_type || null
    this.county = obj.county || null
    this.slug = obj.slug || null
    this.created_at = obj.created_at || null
    this.updated_at = obj.updated_at || null
    this.is_collection = obj.is_collection || null
    this.collections = obj.collections || []
    this.climate_zones = obj.climate_zones || []
    this.titles = obj.titles || {
      title: '',
      type: '',
      type_long: '',
      title_type: '',
      title_type_short: '',
      wide_type: ''
    }
  }


  get label () {
    const { titles } = this
    return titles.title_type
  }
}