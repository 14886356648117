import { GREATER_THEN_ONE_NUMBER } from '@/business-logic/constants/helpersEnum'

export function normalizeToZero(minThreshold, maxThreshold=0) {
  return (value) => {
    if (!isNaN(value) && value > minThreshold && value < maxThreshold) {
      return 0
    }
    return value
  }
}

export function noFormatNumber(value) {
  if (value === GREATER_THEN_ONE_NUMBER ) {
    return '∞'
  } else if (value === null || value === '' || isNaN(value) || Number.isNaN(value)) {
    return value ? value : '--'
  }
  return null
}
