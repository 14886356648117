<template>
  <ul class="dropdown-menu-list-radio psui-w-full psui-my-3">
    <li 
      v-for="(item, index) in getItems" 
      :key="`${index}-${getKeyValue(item)}`"
      v-tooltip="getTooltip(item)"
      :class="{
        'is-selected psui-bg-blue-10 psui-text-blue-60': getSelected === getKeyValue(item) && !item.disabled, 
        'disabled-item': item.disabled
      }"
      class="psui-flex psui-items-end psui-justify-between psui-rounded-md psui-text-gray-60 hover:psui-text-blue-60 hover:psui-bg-blue-10 psui-mx-3 psui-px-2 psui-cursor-pointer transition-all"
      @click="selectItem(item)"
    >
      <span class="psui-flex psui-flex-initial psui-w-auto psui-text-small psui-font-bold">{{ getKeyLabelLeft(item) }}</span>
      <span class="dropdown-menu-list-item-dashed psui-flex psui-flex-1 psui-border-b psui-border-gray-40 psui-border-dashed psui-mx-2 psui-mb-1" />
      <span class="psui-flex psui-flex-initial psui-w-auto psui-text-xsmall">{{ getKeyLabelRight(item) }}</span>      
      <PsIcon    
        v-if-admin    
        v-go-to-admin-edit="item.goToAdminEditRoute"
        icon="lock"
        size="16"
        class="psui-ml-2 psui--mb-1 psui-flex-shrink-0 psui-text-blue-60"
        style="display: inline-block"
        @click.stop=""
      />
    </li>
  </ul>  
</template>

<script>
export default {
  name: 'DropdownMenuListRadio',
  props: {
    items: {
      type: Array,
      required: true
    },
    keyLabelLeft: {
      type: [String, Function],
      default: 'left_label'
    },
    keyLabelRight: {
      type: [String, Function],
      default: 'right_label'
    },
    keyValue: {
      type: [String, Function],
      default: 'value'
    },
    selected: {},
  },
  computed: {
    getSelected() {
      if (this.selected !== undefined) {
        if (typeof this.selected === 'object' && this.selected[this.keyValue] ) {
          return this.selected[this.keyValue]        
        } else {
          return this.selected
        }
      } else {
        return false
      }
    },
    getItems() {
      return this.items
    },
  },
  methods: {
    selectItem(item) {
      if (item.disabled) return
      this.$emit('update:selected', this.getKeyValue(item) )
      this.$emit('change', item )
    },

    getKeyLabelLeft(item) {
      if(typeof this.keyLabelLeft == 'function') return this.keyLabelLeft(item)
      if(typeof item === 'string') return item
      return item[this.keyLabelLeft]
    },

    getKeyLabelRight(item) {
      if(typeof this.keyLabelRight == 'function') return this.keyLabelRight(item)
      if(typeof item === 'string') return item
      return item[this.keyLabelRight]
    },

    getKeyValue(item) {
      if(typeof this.keyValue == 'function') return this.keyValue(item)
      if(typeof item === 'string') return item
      return item[this.keyValue]
    },
    getTooltip(item) {
      let tooltip = {
        html: true, 
        placement: 'bottom',
        show: false,
        trigger: 'hover', 
        hideOnTargetClick: false, 
        autoHide: false, 
        popperOptions : {
          modifiers: { 
            preventOverflow: { enabled: true },
            hide: { enabled: false } 
          }
        }
      }      
      if (item.disabled == true && item.tooltip) {
        tooltip.content = item.tooltip
        tooltip.show = true
        return tooltip
      } else {
        tooltip.show = false
        return tooltip
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .dropdown-menu-list-radio {
    li {
      padding-top: 6px;
      padding-bottom: 6px;

      &::before {
        content: 'radio_button_unchecked';
        font-family: 'Material Icons Round';                            
        font-size: 20px;
        color: #E6ECF2;
        margin-right: 8px;
        line-height: 1;
      }

      &:hover,
      &.is-selected {
        .psui-border-b {
          border-color: #318FAC;
        }
      }

      &.is-selected {
        &:before {
          content: 'radio_button_checked';
          color: #318FAC;
        }
      }
    }

    .dropdown-menu-list-item-dashed {
      min-width: 20px;
    }
  }

  .disabled-item {
    color: #A2ACB7; // psui-text-gray-40
    background-color: #fff;
  }
</style>