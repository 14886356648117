import { getCombinationIncrementalCost } from '@/services/measure-menu/MeasureMenuImpactsService'
import studyResultsColumns from '@/business-logic/constants/studyResultsColumns'
import projectionsColumns from '@/business-logic/constants/projectionsColumns'
import { CITY_WIDE_IMPACT_COLUMNS_KEYS } from '@/business-logic/services/impact-algorithms/policy'
import { forecast_units } from '@/modules/app/shared/default-app-columns'
import { defaultExistingBuildingsImpactAssumptionValues }from '@/modules/app/assumptions/shared/defaultAssumptions.js'

export default {
  data:()=>({
    defaultContingentPercentage: defaultExistingBuildingsImpactAssumptionValues.flexible_contingent_percentage,
  }),
  computed: {

  },
  methods: {
    getCombinationIncrementalCost,
    computeResumedImpacts({withContingentMeasuresData = [], withoutContingentMeasuresData = [], mandatoryMeasuresData = [], customContingentPercentage = undefined, preserveUnities = true, computeProjectionsByYear = false}) {
      
      const mergeData = mandatoryMeasuresData && Array.isArray(mandatoryMeasuresData) ? mandatoryMeasuresData : []
      const contingentLowestCombination = [...(withContingentMeasuresData || []), ...mergeData]
      const nonContingentLowestCombination = [...(withoutContingentMeasuresData || []), ...mergeData]
      let contingentPercentage = (+(customContingentPercentage ?? this.defaultContingentPercentage)) / 100

      const result = [...studyResultsColumns, ...projectionsColumns, 'flexible_score'].reduce((acc, key) => {
        const contingentValue = this.getCombinationIncrementalCost(contingentLowestCombination, key, preserveUnities)
        const nonContingentValue = this.getCombinationIncrementalCost(nonContingentLowestCombination, key, preserveUnities)
        if (['forecast_units_affected', 'baseline_fuel_type'].includes(key))
          acc[key] = contingentValue || nonContingentValue
        else
          acc[key] = (contingentValue * contingentPercentage) + (nonContingentValue * (1 - contingentPercentage))
        return acc
      }, {})

      if (computeProjectionsByYear) {
        const contingentProjectsByYear = this.computeProjectionsByYear(contingentLowestCombination, contingentPercentage, preserveUnities)
        const nonContingentProjectsByYear = this.computeProjectionsByYear(nonContingentLowestCombination, (1 - contingentPercentage), preserveUnities)
        result.projectionsByYear = this.computeProjectionsByYear([
          { projectionsByYear: contingentProjectsByYear},
          { projectionsByYear: nonContingentProjectsByYear}
        ], 1, false)
      }
      return result
    },
    computeProjectionsByYear(combinationList, normalizer = 1, preserveUnities = false, projectionsByYearKey = 'projectionsByYear') {
      return combinationList.reduce((acc, curr) => {
        if (!curr[projectionsByYearKey] || !Array.isArray(curr[projectionsByYearKey])) return acc
        curr[projectionsByYearKey].forEach((currYear) => {
          let yearInfo = acc.find((y) => y.year === currYear.year)
          if (!yearInfo) {
            yearInfo = { year: currYear.year }
            acc.push(yearInfo)
          }

          CITY_WIDE_IMPACT_COLUMNS_KEYS.forEach((key) => {
            if (!yearInfo[key] && !currYear[key]) {
              return
            }
            if (!yearInfo[key]) {
              yearInfo[key] = {
                cummulative: 0,
                current: 0
              }
            }
            const currValAccumulative = !isNaN(yearInfo?.[key]?.cummulative) ? Number(yearInfo[key].cummulative) : 0
            const currValCurrent = !isNaN(yearInfo?.[key]?.current) ? Number(yearInfo[key].current) : 0
            const newValAccumulative = !isNaN(currYear?.[key]?.cummulative) ? Number(currYear[key].cummulative) * normalizer : 0
            const newValCurrent = !isNaN(currYear?.[key]?.current) ? Number(currYear[key].current) * normalizer : 0

            if (key === forecast_units()?.key && preserveUnities) {
              yearInfo[key].cummulative = currValAccumulative > newValAccumulative ? currValAccumulative : newValAccumulative
              yearInfo[key].current = currValCurrent > newValCurrent ? currValCurrent : newValCurrent
            } else {
              yearInfo[key].cummulative = currValAccumulative + newValAccumulative
              yearInfo[key].current = currValCurrent + newValCurrent
            }
          })
        })
        return acc
      }, [])
    },
  }
}