import {
  ExistingBuildingsFlexibleOnBillPoBuilder,
  ExistingBuildingsGeneralCostEffectiveRetrofitOnBillPoBuilder,
  ExistingBuildingsGeneralCostEffectiveRetrofitTdvOrOnBillPoBuilder,
  ExistingBuildingsPOBuilder,
  ExistingBuildingsPrescriptiveOnBillPoBuilder
} from "./builders/existing-buildings"
import {
  NewBuildingsEfficiencyPOBuilder, NewBuildingsElectricOnlyPlusEfficiencyPOBuilder,
  NewBuildingsElectricOnlyPOBuilder, NewBuildingsMaxElectricPreferencePOBuilder,
  NewBuildingsHighPerformancePOBuilder,
  NewBuildingsPOBuilder
} from "./builders/new-buildings"

export const POLICY_OPTIONS_ALGORITHMS = [
  // Existing Buildings Builders
  ExistingBuildingsPOBuilder,
  ExistingBuildingsGeneralCostEffectiveRetrofitOnBillPoBuilder,
  ExistingBuildingsGeneralCostEffectiveRetrofitTdvOrOnBillPoBuilder,
  ExistingBuildingsPrescriptiveOnBillPoBuilder,
  ExistingBuildingsFlexibleOnBillPoBuilder,
  // New Buildings Builders
  NewBuildingsPOBuilder,
  NewBuildingsEfficiencyPOBuilder,
  NewBuildingsElectricOnlyPOBuilder,
  NewBuildingsElectricOnlyPlusEfficiencyPOBuilder,
  NewBuildingsMaxElectricPreferencePOBuilder,
  NewBuildingsHighPerformancePOBuilder,
].map((builder) => {
  return {
    constructor: builder,
    study_type_slug: builder.studyTypeSlug,
    policy_option_slug: builder.policyOptionSlug,
    name: builder.name,
  }
}).reduce((acc, curr) => {
  acc[curr.name] = curr
  return acc
}, {})
