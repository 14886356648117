<template>
  <div
    class="base-header__filters psui-w-full psui-flex psui-flex-wrap psui-items-center psui-justify-between"
  >
    <div class="psui-flex psui-items-center psui-gap-1 psui-flex-wrap">
      <p class="psui-text-gray-70 mr-1/2 psui-text-small">
        {{ climateZoneText }}
      </p>
      <span
        v-for="prefix in climateZonesPrefixes"
        :key="prefix"
        class="psui-flex psui-items-center psui-justify-center psui-text-small psui-text-gray-60 psui-bg-gray-10 psui-rounded-sm psui-py-1 psui-px-3"
      >
        {{ prefix }}
      </span>
    </div>
  </div>
</template>
 
<script>
 
export default {
  name: 'SummaryClimateZonesList',
  computed: {
    jurisdiction() {
      return this.$store.getters['lastJurisdictionVisited']
    },
    pageTitle() {
      return `${this.jurisdiction.title_type} - Summary`
    },
    climateZoneText() {
      if (this.jurisdiction) {
        const zonesLength = this.jurisdiction?.climate_zones?.length
        return `${this.$options.filters.editJurisdictionTitleTypeBasedOnJurisdictionType(this.jurisdiction, 'title_type')} has ${zonesLength} climate zone${zonesLength > 1 ? 's' : ''}`
      } else {
        return ''
      }
    },
    climateZonesPrefixes() {
      if (this.jurisdiction) {
        return this.jurisdiction.climate_zones.map((cz) => cz.prefix).sort((a, b) => parseInt(a) - parseInt(b))
      } else {
        return ''
      }
    }
  },
}
</script>
 
