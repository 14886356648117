<template>
  <div class="psui-flex psui-items-center psui-ml-1">
    <Dropdown
      ref="dropdown"
      :min-width-dropdown="400"
      :dropdown-dialog-classes="'min-w-300 bg-white p-6'"
      button-classes="psui-border-0 p-0"
      title="Building Type"
    >
      <template #buttonLabel>
        <PsChips
          label="Building Type"
          type="button"
          :checked="isFiltering"
        />
      </template>
      <template #items>
        <div class="w-64 flex flex-col font-medium text-gray02">
          <div
            class="mb-2 last:mb-0"
          >
            <div
              v-for="prototype in getAvailablePrototypes"
              :key="`dropdown-prototype-${prototype.id}`"
            >
              <Checkbox
                class="w-full"
                :label="prototype.title ? prototype.title : prototype.type_prototype.title"
                :value="!checkQueryKeyValue('exclude_type_prototype_ids', prototype.type_prototype_id)"
                label-classes="ts--accent--2 text-gray04"
                @change="toggleQueryKeyValue('exclude_type_prototype_ids', prototype.type_prototype_id)"
              />
            </div>
          </div>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>

export default {
  name: 'FilterBuildingType',
  data() {
    return {
      studyTypes: [],
      type_prototypes: []
    }
  },
  computed: {
    isFiltering() {
      return this.$route.fullPath.includes('exclude_type_prototype_ids') 
    },
    getStudyTypeSelected(){
      return this.$store.getters['globalEntities/StudyType/getterGlobalStudyType']({ slug: this.getQueryKey('only_study_type').shift() })  
    },
  },
  async mounted() {
    await this.getStudyResultsInitialData()
    .then((data)=>{
      this.studyTypes = data.study_types
      this.type_prototypes = data.type_prototypes
    })
  },
}
</script>
