import getStudyColumnsBySelectedStatus from '@/business-logic/services/columns/getStudyColumnsBySelectedStatus'
import { POLICY_TYPES } from '@/modules/app/policy/shared/enums'
import { groupBy } from '@/util/Functions'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import Jurisdiction from '@/models/Jurisdiction'
import { uniqueByKey } from '@igortrindade/lazyfy'
import dayjs from 'dayjs'
import {
  defaultExistingBuildingsImpactAssumptionValues,
  defaultNewBuildingsImpactAssumptionValues
} from '@/models/ImpactAssumptions'
import PolicyApiService from '@/services/api/PolicyApiService'
import Router from '@/router'
import { POLICY_OPTIONS_ALGORITHMS } from "@/business-logic/services/policy-options/factory"
import ImpactAssumptions from '../../../../models/ImpactAssumptions'
import { checkIfPolicyIsOutdated } from '@/util/Helpers'

export default {
  getterPolicyColumns: (state, getters, rootState, rootGetters) => {
    const columns = { ... state.policyColumns}
    const jurisdictionType = rootGetters['lastJurisdictionVisited']?.type

    const jurisdictionWideType = rootGetters['lastJurisdictionVisited']?.titles?.wide_type || jurisdictionType
    const studyType = rootGetters['lastStudyTypeVisited']
    if (jurisdictionWideType) {
      const getColumnGroupsFromStudyType = (studyType === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS) ? columns.existingBuildings : columns.newBuildings
      
      const cityWideColumnGroup = getColumnGroupsFromStudyType.columnGroups.find((columnGroup) => columnGroup.key === 'city_wide_impact')
      cityWideColumnGroup.title = `${ jurisdictionWideType } Estimates`
      
      const cityWideSubsidyColumnGroup = getColumnGroupsFromStudyType.columnGroups.find((columnGroup) => columnGroup.key === 'city_wide_subsidy_needed_columns')
      if(cityWideSubsidyColumnGroup) {
        cityWideSubsidyColumnGroup.title = `Subsidy Needed ${ jurisdictionWideType }`

        cityWideSubsidyColumnGroup.columns.forEach((column) => {
          column.description = `(${ jurisdictionWideType })`.toLowerCase()
          column.title = column.title.replace(' Subsidy',  '')
        })

        if(cityWideSubsidyColumnGroup?.chartProjection?.subtitle) {
          cityWideSubsidyColumnGroup.chartProjection.subtitle = `(${ jurisdictionWideType })`.toLowerCase()
        }
      }

      if(jurisdictionType == 'City') {
        cityWideColumnGroup.hasHelper = {
          type: 'helper',
          id: 25
        }
      } else {
        cityWideColumnGroup.hasHelper = {
          type: 'helper',
          id: 26
        }
      }

    }
    return columns
  },
  getterPolicyExistingBuildingsColumns: (state, getters) => getters.getterPolicyColumns['existingBuildings'],
  getterPolicyExistingBuildingsColumnsSelected: (state, getters) => getStudyColumnsBySelectedStatus(getters.getterPolicyColumns['existingBuildings'], true),
  getterPolicyExistingBuildingsColumnsUnselected: (state, getters) => getStudyColumnsBySelectedStatus(getters.getterPolicyColumns['existingBuildings'], false),
  getterPolicyNewBuildingsColumns: (state, getters) => getters.getterPolicyColumns['newBuildings'],
  getterPolicyNewBuildingsColumnsSelected: (state, getters) => getStudyColumnsBySelectedStatus(getters.getterPolicyColumns['newBuildings'], true),
  getterPolicyNewBuildingsColumnsUnselected: (state, getters) => getStudyColumnsBySelectedStatus(getters.getterPolicyColumns['newBuildings'], true),
  getterHiddenItems: state => state.hiddenItems,
  getterUserPoliciesByJurisdiction(state, getters, rootState, rootGetters) {
    const jurisdiction = rootGetters['lastJurisdictionVisited']
    return jurisdiction ? state.userPolicies.filter((policy) => policy.jurisdiction_id == jurisdiction.id) : []
  },
  getterUserPolicies: state => (filters = null) => {
    const userPolicies = [...state.userPolicies, ...state.userSharedPolicies]
    return filters ? userPolicies.findAllInArray(filters) : userPolicies
  },
  getterUserPolicy: (state, getters) => (filters = null) => {
    
    const userPolicies = [...state.userPolicies, ...state.userSharedPolicies]
    const policy = filters ? userPolicies.findInArray(filters) : false

    // Check if need to adjust current bug on assumptions (ToDo: remove that when new assumptions model was released)
    const activePolicyId = Router.currentRoute.params?.policy_id ? Router.currentRoute.params?.policy_id : null
    
    if (policy && activePolicyId && Number(policy.id) === Number(activePolicyId)) {
      const types = getters['getterPolicyStudyTypes']({ policy: policy })
      if (types.includes(STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS)) {
        let isWrongAssumptionsInstalled = true
        Object.keys(defaultExistingBuildingsImpactAssumptionValues).forEach((key) => {
          if (defaultExistingBuildingsImpactAssumptionValues[key] !== policy[key] && key !== 'start_at') {
            isWrongAssumptionsInstalled = false
          }
        })
        if (isWrongAssumptionsInstalled &&
          (dayjs(policy.created_at).isAfter(dayjs().subtract(1, 'days')) ||
            dayjs(policy.created_at).isBefore(dayjs('2022-08-01'))))
        {
          Object.keys(defaultNewBuildingsImpactAssumptionValues).forEach((key) => {
            policy[key] = defaultNewBuildingsImpactAssumptionValues[key]
          })
          setTimeout(async () => PolicyApiService.update(activePolicyId, policy))
        }
      }
    }
    return policy
  },
  getterUserPoliciesByStudyType : (state, getters) =>  ({ study_type_slugs }) => {
    return getters['getterUserPolicies']().filter(policy => {
      // TODO - Compare array with array instead of only get the first item
      const policyStudyTypes = getters['getterPolicyStudyTypes']({ policy })[0]
      return !study_type_slugs.length || !policyStudyTypes || study_type_slugs?.includes(policyStudyTypes)
    })
  },
  getterPolicyType: (state, getters, rootState, rootGetters) => policy_id => {
    const isOnUserPolicies = state.userPolicies.find((policy => policy.id == policy_id))
    const isOnSharedUserPolicies = state.userSharedPolicies.find((policy => policy.id == policy_id))
    
    const loggedUser = rootGetters['getterLoggedUser']

    const isUserPolicy = isOnUserPolicies && loggedUser && loggedUser.id === isOnUserPolicies?.user_id
    const isDraftPolicy = isOnUserPolicies && (isOnUserPolicies?.user_id === null || (!loggedUser && rootGetters?.['general/getterDeviceInfo']?.id && rootGetters?.['general/getterDeviceInfo']?.id === isOnUserPolicies?.user_device_id))
    const isSharedPolicy = Boolean(isOnSharedUserPolicies)
    
    if(isUserPolicy) {
      return POLICY_TYPES.OWN.value
    } else if (isDraftPolicy) {
      return POLICY_TYPES.DRAFT.value 
    } else if (isSharedPolicy)  {
      return POLICY_TYPES.SHARED.value
    } else {
      return POLICY_TYPES.SHARED.value
    }

  },

  getterPolicyCustomCombinations : (state, getters, rootState, rootGetters) => (policy_id) => {
    const policy = getters['getterUserPolicy']({ id: policy_id })
    if (!policy) return []
    
    let custom_combinations = [...policy?.policy_containers].reduce((prev, cur) => {
      prev = [...prev, ... cur.custom_combinations]
      return prev
    }, [])
    
    // Remove custom combinations that are from existing building studies and do not have measures added
    custom_combinations = custom_combinations.filter(custom_combination => {
      const study_type_id = rootGetters['globalEntities/Prototype/getterPrototypeStudyTypeId']({ prototype_id : custom_combination.prototype_id })
      const study_type = rootGetters['globalEntities/StudyType/getterGlobalStudyType']({ id : study_type_id })
      const studyTypeExistingBuildings = rootGetters['globalEntities/StudyType/getterStudyTypeExistingBuildings']
      const customCombinationIsFromExistingBuildings = study_type.slug == studyTypeExistingBuildings.slug ? true : false
      return customCombinationIsFromExistingBuildings && !custom_combination.measures.length ? false : true
    })
    return custom_combinations
  },

  getterFilteredPolicyCustomCombinations: (state, getters) => (filters = {}) => {
    const { policy_id } = filters
    if(!policy_id) throw new Error('policy_id is required')
    const custom_combinations = getters['getterPolicyCustomCombinations'](policy_id)
    return custom_combinations.findAllInArray(filters)
  },

  getterPolicyTypeLabel: (state, getters) => policy_id => {
    const policyTypeValue = getters['getterPolicyType'](policy_id)
    return POLICY_TYPES[policyTypeValue]?.label ?? ''
  },

  getterUserCanEditPolicy: (state, getters) => (policy_id, checkEdr1Outdated=true) => {
    const policyType = getters['getterPolicyType'](policy_id)
    let blockByEdr1Outdated = false
    if (checkEdr1Outdated) {
      const policy = getters['getterUserPolicy']({ id: policy_id })
      const outdatedInfo = checkIfPolicyIsOutdated(policy)
      const policyHasPrototypeOutdated = Boolean(outdatedInfo.has_outdated_studies === true && outdatedInfo.is_loading === false)
      blockByEdr1Outdated = Boolean(!outdatedInfo.block_policy && policyHasPrototypeOutdated)
    }
    return Boolean([POLICY_TYPES.OWN.value, POLICY_TYPES.DRAFT.value].includes(policyType) && !blockByEdr1Outdated)
  },

  getterJurisdictionsFromUserPolicies : (state, getters) => {
    const userPolicies = getters.getterUserPolicies()
    return uniqueByKey(userPolicies, 'jurisdiction_id').map((policy) =>  new Jurisdiction(policy.jurisdiction))
  },

  getterUserPoliciesGroupedByJurisdiction: (state, getters) => {
    const userPolicies = getters.getterUserPolicies()
    if (userPolicies.length > 0) {
      return groupBy(userPolicies, (c) => c.jurisdiction_id )
    } else {
      return []
    }
  },
  
  getterUserHasPoliciesToSave: (state, getters, rootState, rootGetters ) => {
    const userPolicies = getters.getterUserPolicies()
    return userPolicies.length && !rootGetters['getterLoggedUser']
  },
  
  getterPolicyStudyTypes: (state, getters) => ({ key = 'slug' , policy_id, policy }) => {
    const userPolicy = policy ? policy : getters['getterUserPolicy']({ id: policy_id })
    const policyContainers = userPolicy?.policy_containers ?? null
    const policyStudyTypes = userPolicy && policyContainers ? policyContainers.reduce((acc, container) => {
      return [...new Set([...acc, container.study_type[key]])]
    }, []) : false
    return policyStudyTypes
  },
  
  getterPolicyAvailableTypePrototypesByStudyType: (state, getters, rootState, rootGetters) => ({study_type_id, policy_id, checkCodeCycle = true}) => {
    const prototypes = getters['getterPolicyAvailablePrototypesByStudyType']({ study_type_id, policy_id, checkCodeCycle })    
    const type_prototypes_with_prototypes = prototypes.reduce((prev, prototype) => {
      const type_prototype_id = prototype.type_prototype_id
      const type_prototype = rootGetters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id : prototype.type_prototype_id })
      prev[type_prototype_id] = prev[type_prototype_id] ?? { ...type_prototype, prototypes : [] } 
      prev[type_prototype_id].prototypes.push(prototype)
      return prev
    }, {} )

    return Object.values(type_prototypes_with_prototypes)
  },
  
  getterPolicyAvailablePrototypesByStudyType: (state, getters, rootState, rootGetters) => ({ study_type_id, policy_id, checkCodeCycle = true }) => {
    const policyAvailablePrototypes = getters['getterPolicyAvailablePrototypes']({ policy_id, checkCodeCycle })
    const policyAvailablePrototypesByStudyType = policyAvailablePrototypes.filter((prototype) => {
      return rootGetters['globalEntities/Prototype/getterPrototypeStudyTypeId']({ prototype_id : prototype.id }) == study_type_id
    })

    return policyAvailablePrototypesByStudyType
  },
  
  getterPolicyAvailablePrototypes: (state, getters, rootState, rootGetters) => ({ policy_id, checkCodeCycle = true }) => {
    const all_prototypes = rootGetters['globalEntities/Prototype/getterGlobalPrototypes']()
    const available_prototypes = all_prototypes
      .filter(prototype => prototype.type_prototype_id !== null )
      .filter(prototype => prototype.allow_policy_creation === true )
      .filter(prototype => prototype.impact_algorithm_policy_impact == null && prototype.impact_algorithm_policy_flexible_path == null ? false : true )
    
      const code_cycle_id = getters.getterUserPolicy({ id : policy_id })?.code_cycle?.id

    // ToDo: are we still using code cycle logic within our policies?
    if (code_cycle_id && checkCodeCycle) {
      const study_ids_with_same_policy_code_cycle = rootGetters['globalEntities/Study/getterGlobalStudies']({ code_cycle_id }).map(study => study.id)
      const prototypes_with_same_policy_code_cycle = available_prototypes.findAllInArray({ study_id : study_ids_with_same_policy_code_cycle })
      return prototypes_with_same_policy_code_cycle
    } else {
      return available_prototypes
    }    
  },

  getterPolicyOptionsWithPrototypes: (state, getters, _, rootGetters) => () => {
    const dbPolicyOptions = rootGetters['globalEntities/PolicyOption/getterGlobalPolicyOptions']()
    // ToDO: Filter just latest study prototypes???
    const availablePrototypes = rootGetters['globalEntities/Prototype/getterGlobalPrototypes']()
    return dbPolicyOptions.map((policyOption) => {
      const algorithmsNames = Object.keys(POLICY_OPTIONS_ALGORITHMS).filter((key) => policyOption?.slug && POLICY_OPTIONS_ALGORITHMS[key]?.policy_option_slug === policyOption?.slug)
      return {
        ...policyOption,
        prototypes: availablePrototypes?.filter((p) => p?.allow_policy_creation && (p?.policy_option_algorithms || []).some((a) => algorithmsNames.includes(a))) || [],
        algorithms: (algorithmsNames?.filter((a) => availablePrototypes.some((p) => (p?.policy_option_algorithms || []).includes(a))) || [])
          .map((key) => POLICY_OPTIONS_ALGORITHMS[key])
      }
    })
  },
  getterClimateZonesWithinPolicySelected: (state, getters,_,rootGetters) => ({policy_id}) => {  
    const policy = getters['getterUserPolicy']({ id: policy_id })
    
    return policy.policy_containers
    .map(container => container.custom_combinations)
    .reduce((acc,curr)=>{return acc.concat(curr)},[])
    .map(climate_zone => climate_zone.climate_zone_raw)
    .reduce((acc,curr)=>{
      if(acc.length === 0 || !acc.includes(curr)) {
        acc.push(curr)
      }
      return acc 
    },[])
    .map(climate_zone_raw => rootGetters['globalEntities/ClimateZone/getterGlobalClimateZone']({raw:climate_zone_raw}))
    .sort((a,b) => {return a.prefix - b.prefix}) 
  },
  getterClimateZonesByPrototypeWithinPolicySelected: (state, getters,_,rootGetters) => ({policy_id, prototype})=>{
    const policy = getters['getterUserPolicy']({ id: policy_id })
    const type_prototype_id = rootGetters['globalEntities/Prototype/getterGlobalPrototype']({id:prototype.id}).type_prototype_id

    return policy.policy_containers
    .filter(container => container.type_prototype_id == type_prototype_id ) 
    .map(container => container.custom_combinations)
    .reduce((acc,curr)=>{return acc.concat(curr)},[])
    .map(cc => rootGetters['globalEntities/ClimateZone/getterGlobalClimateZone']({raw:cc.climate_zone_raw}))
    .reduce((acc,curr)=>{
      if(acc.length === 0 || !acc.includes(curr)) {
        acc.push(curr)
      }
      return acc
    },[])
  },

  getterPolicyAvgAssumptions: (state, getters) => ({ policy_id }) => {

    const impactAssumptions = new ImpactAssumptions()
    const policyCustomCombinations = getters['getterFilteredPolicyCustomCombinations']({ policy_id })

    // Avg keys
    const avgKeysToSumAndGetTheAverageBasedOnCcQuantity = [
      'annual_penetration_rate',
      'applicability_rate',
      'likelihood_installations_first_third',
      'likelihood_installations_second_third',
      'likelihood_installations_final_third'
    ]
    
    avgKeysToSumAndGetTheAverageBasedOnCcQuantity.map((key) => {
      let assumptionKeyFoundOnCcs = false
      const policyCustomCombinationsAssumptionKeySumValue = policyCustomCombinations.reduce((acc, curr) => {
        if(curr?.meta?.assumptions[key] !== undefined) {
          assumptionKeyFoundOnCcs = true
          return acc + parseFloat(curr?.meta?.assumptions?.[key])
        } else {
          acc + defaultExistingBuildingsImpactAssumptionValues[key]
        }
      }, 0)

      if(assumptionKeyFoundOnCcs) {
        impactAssumptions[key] = policyCustomCombinationsAssumptionKeySumValue / policyCustomCombinations.length
      }
    })

    // Assumptions keys with the first value that differs from the default
    Object.keys(defaultExistingBuildingsImpactAssumptionValues)
      .filter((key) => !avgKeysToSumAndGetTheAverageBasedOnCcQuantity.includes(key))
      .map((key) => {
        policyCustomCombinations.find((cc) => {
          if(cc?.meta?.assumptions[key] !== undefined && cc?.meta?.assumptions[key] != defaultExistingBuildingsImpactAssumptionValues[key]) {
            impactAssumptions[key] = cc?.meta?.assumptions[key]
            return true
          }
        })
      })


    return impactAssumptions

  }

}
