<template>
  <div class="p-6">
    <InputWithLabel
      v-model="feature_flag.feature"
      class="mt-6"
      label="Feature"
    />

    <InputWithLabel
      v-model="feature_flag.type"
      class="mt-6"
      label="Type"
    />
    <InputWithLabel
      v-model="feature_flag.value"
      class="mt-6"
      label="Value"
    />
    <hr class="border-b border-gray05 my-6">
    <div> 
      <PsButton
        :label="$route.params.feature_flag_id ? 'Update' : 'Create'"
        :icon="$route.params.feature_flag_id ? 'update' : 'add'"
        size="big"
        @click="update_or_create"
      />
      {{ feature_flag }}
    </div>
  </div>
</template>

<script>
import FeatureFlag from '@/models/FeatureFlag.js'
import FeatureFlagsApiService from '@/services/api/FeatureFlagsApiService.js'

export default {
  name: 'AdminFeatureFlagForm',
  data() {
    return {
      feature_flag: new FeatureFlag(),
    }
  },
  async mounted(){
    if(this.$route.params.feature_flag_id) {
      await FeatureFlagsApiService.getFeatureFlagById(this.$route.params.feature_flag_id)
      .then((feature_flag) => {
        this.feature_flag = feature_flag
      })
    }
  },
  methods: {
    async update_or_create(){
      await FeatureFlagsApiService.update_or_create(this.feature_flag)
      .then(()=>{
        this.$toast.success(`Feature successfully ${this.$route.params.feature_flag_id ? 'updated' : 'created'}`)
        this.$router.push({ name: 'AdminFeatureFlagIndex' })
      })
    },
  }
}
</script>
