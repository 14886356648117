<template>
  <div class="flex items-center ml-1">
    <Dropdown
      ref="dropdown"
      :min-width-dropdown="400"
      :dropdown-dialog-classes="'min-w-300 bg-white p-6'"
      button-classes="psui-border-0 p-0"
      title="Fuel Type"
    >
      <template #buttonLabel>
        <PsChips
          label="Fuel Type"
          type="button"
          :checked="isFiltering"
        />
      </template>
      <template #items>
        <div class="w-full flex flex-col font-medium text-gray02">
          <Checkbox
            v-for="type_fuel in type_fuels"
            :key="`filter-fuel-${type_fuel.id}`"
            class="w-full"              
            label-classes="ts--accent--2 text-gray04"
            :label="type_fuel.title"
            :value="!checkQueryKeyValue('exclude_type_fuel_ids', type_fuel.id)"
            @change="toggleQueryKeyValue('exclude_type_fuel_ids', type_fuel.id)"
          />
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
export default {
  name: 'FilterTypeFuel',
  data() {
    return {
      type_fuels: []
    }
  },
  computed: {
    isFiltering() {
      return this.$route.fullPath.includes('exclude_type_fuel_ids')
    },
  },
  mounted() {
    // TODO - Apply Global Entities for Type Fuel
    this.getTypeFuels()
  },
  methods: {
    getTypeFuels() {
      const query = `
        {
          type_fuels (
            orderBy: [{ column: "title", order: "asc" }]
          ){
            id
            title
            slug
          }
        }
      `
      this.$graphql({ query, shouldCache: true })
        .then(({ data }) => {
          this.type_fuels = data.type_fuels.filter((item) => item.slug !== 'electrification')
        })
    }
  }
}
</script>