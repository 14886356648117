<template>
  <div class="psui-w-full psui-flex psui-flex-col psui-space-y-6">
    <TypePrototypeCardListItem
      v-for="(type_prototype) in getTypePrototypeWithUnitsByHideInBuildingEstimates"
      ref="cardItems" 
      :key="`tyoe_prototype_card_list_item_${type_prototype.id}`" 
      class="psui-w-full psui-rounded-md psui-bg-white psui-flex psui-flex-col"
      :palette-color-index="paletteColorIndex"
      :type_prototype="type_prototype"
      :get-climate-zone-selected="getClimateZoneSelected"
      :get-building-stock-total-units="getBuildingStockTotalUnits"
      :get-building-type-selected="getBuildingTypeSelected"
    />
  </div>
</template>

<script>
import TypePrototypeCardListItem from './TypePrototypeCardListItem.vue'
export default {
  name: 'TypePrototypeCardList',
  components: { TypePrototypeCardListItem  },
  props: ['getTypePrototypesWithUnits', 'getBuildingStockTotalUnits', 'getClimateZoneSelected', 'paletteColorIndex','getBuildingTypeSelected'],
  computed:{
    getTypePrototypeWithUnitsByHideInBuildingEstimates(){
      return  this.getTypePrototypesWithUnits.filter(tp => tp.hide_in_building_estimates === false)
    }
  },
  methods: {
    setExporting(value, buildingType = null) {
      if (this.$refs?.cardItems?.length > 0) {
        this.$refs?.cardItems.forEach((cardItem) => {
          cardItem.setExporting(value, buildingType)
        })
      }
    }
  }
}
</script>
