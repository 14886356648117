import Api from '@/util/Api'

const FILE_NAME_BY_TYPES = {
  'MeasureMenuXls': 'Flexible Requirements Table',
  'ExistingResidentialComplianceXls': 'Prescriptive Requirements Table',
  'NewSingleFamilyComplianceXls': 'New Buildings Requirements Table'
}

export default class PolicyDocumentsApiService {

  static downloadRequirementsTableXls({ type, policy_id, fileName = '', policy = '' }) {

    let finalFileName
    if(fileName) {
      finalFileName = fileName
    } else if(policy) {
      const name = FILE_NAME_BY_TYPES[type]
      finalFileName = `${name} - ${policy.title} - ${ policy.jurisdiction.titles.title_type.toLowerCase()}`
    } else {
      finalFileName = `${ fileName || type }-${policy_id}`
    }
    return Api.get(process.env.VUE_APP_BASE_API_URL + `/api/xls/generate?type=${ type }&policy_id=${ policy_id }`, { responseType: 'blob' })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${ finalFileName }.xlsx`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
  }

}