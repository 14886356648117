<template>
  <div class="w-full">
    <div
      v-if="policy.policyMap"
      class="bg-deepsky-200 gap-1 p-4 rounded-md"
    >
      <span class="text-header-4 text-deepsky-800 font-bold mr-2 pl-2">{{ policy.title }}</span>
      <span class="text-header-3 text-ash-450">{{ policy.jurisdiction | editJurisdictionTitleTypeBasedOnJurisdictionType('title_type') }}</span>
      <ul
        v-for="study_type in policy.policyMap.study_types"
        :key="study_type.id"
        class="flex flex-col mt-4 bg-deepsky-100 p-4 rounded-md"
      >
        <div class="text-header-2 text-deepsky-800 font-bold pb-3">
          <div class="float-left climate_zone-prefix ml-2">
            Climate Zone
          </div>
          <div class="float-left type_vintage-container">
            Building Type
          </div>
          <div class="float-left px-2">
            Vintage
          </div>
        </div>
        <template v-for="type_prototype in study_type.type_prototypes">
          <template v-for="climate_zone in type_prototype.climate_zones">
            <li
              v-if="checkTypePrototypeHasItem(type_prototype)"
              :key="`${study_type.id}-${type_prototype.id}-${climate_zone.id}`"
              class="bg-white p-2 flex rounded-md items-center mb-1 last:mb-0"
              :style="{order : climate_zone.prefix}"
            >
              <div class="climate_zone-prefix float-left py-px">
                <span class="bg-deepsky-500 text-white flex items-center justify-center m-auto h-7 w-7 rounded-full font-bold">
                  {{ climate_zone.prefix }}
                </span> 
              </div>
              <div class="type_vintage-container float-left">
                <div class="flex items-center px-2 py-1 text-header-2 bg-deepsky-200 text-deepsky-500 font-bold inline-flex rounded-lg border border-deepsky-200">
                  <img 
                    :src="(type_prototype.slug === 'single-family-homes') ? '/images/single-family-home.svg' : '/images/multifamily.svg'"
                    class="mr-2 text-deepsky-500 w-5 h-4"                      
                  >
                  {{ type_prototype.title }}
                </div>
              </div>
              <div class="">
                <template v-for="type_vintage in climate_zone.type_vintages">
                  <span
                    v-if="type_vintage.vintages.length > 0"
                    :key="`${climate_zone.id}-${type_vintage.id}`"
                    class=""
                    :class="type_vintage.vintages.length === 0 ? 'opacity-50' : ''"
                  >
                    <span class="text-header-1 text-deepsky-500 font-bold border boder-deepsky-500 rounded-lg px-4 py-2 inline-block mx-px">{{ type_vintage.title }}</span>
                  </span>
                </template>
              </div>
            </li>
          </template>
        </template>
      </ul>
    </div>
    <div class="flex justify-center text-center mt-8">
      <router-link
        :to="{
          name: 'PolicyShow', 
          params: { 
            policy_id: policy.id, 
            policy_active_tab: getPolicyActiveTab
          }
        }"
      >
        <PsButton
          :label="policy.is_flexible_path ? 'Review flexible compliance tables' : 'Review scope and requirements'"
          size="medium"

          @click.native="close()"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import PolicyApiService from '@/services/api/PolicyApiService'
export default {
  name: 'StepPolicySuccess',
  props: ['policy'],  
  computed: {
    selectedTypePrototypeIds() {
      return [...this.policy.typePrototypes].map(typePrototype => parseInt(typePrototype.id))
    },
    getPolicyActiveTab() {
      if (!this.policy.policy_active_tab) {
        return this.policy.is_flexible_path ? 'flexible_path' : 'impact'
      }
      return this.policy.policy_active_tab
    }
  },
  mounted() {
    this.getPolicyMap()
  },
  methods: {
    async getPolicyMap() {
      const policyMap = await PolicyApiService.getPolicyMap({ policy_id : this.policy.id })
      this.$emit('update:policy', { ...this.policy, policyMap })
    },
    checkTypePrototypeHasItem(type_prototype) {
      for(const climate_zone of type_prototype.climate_zones) {
        for(const type_vintage of climate_zone.type_vintages) {
          if(type_vintage.vintages.length) return true
        }
      }
      return false
    },
    close() {
      this.$eventBus.$emit('closeModalAutoGeneratePolicyWizard')
      this.$eventBus.$emit('policyUpdated')
    }
  }
}
</script>

<style>
.climate_zone-prefix {
  width: 100px;
}
.type_vintage-container {
  width: 190px;
}
</style>