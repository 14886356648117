import {
  initial_cost,
  annual_bill_savings,
  simple_payback,
  emission_savings,
  on_bill_cost_ratio,
  kwh_savings,
  therms_savings,
  lifecycle_savings,
  compliance_margin,
  edr1_total_margin,
  tdv_benefit_to_cost_ratio,
  forecast_units,
  forecast_emissions_savings,
  forecast_kwh_savings,
  forecast_therms_savings,
  forecast_lifecycle_savings,
  forecast_initial_cost,
  tdv2022_benefit_to_cost_ratio,
  energy_savings_combined,
  subsidy_needed,
  five_years_payback,
  subsidy_needed_care,
  five_years_payback_care,
  city_wide_subsidy_needed,
  city_wide_five_year_payback,
  city_wide_subsidy_needed_care,
  city_wide_five_year_payback_care,
  on_bill_cost_ratio_2025,
  on_bill_cost_ratio_2025_care,
  lsc_2025_benefit_to_cost_ratio,
} from '@/modules/app/shared/default-app-columns'

export default {
  existingBuildings: {
    key: 'existingBuildings',
    studyType: 'Existing Buildings',
    columnGroups: [
      {
        order: 0,
        key: "fixed_columns",
        title: 'Benefit/Cost Ratios',
        columns: [
          on_bill_cost_ratio({ isActive : false }),
          on_bill_cost_ratio_2025({ isActive : false }),
          on_bill_cost_ratio_2025_care({ isActive : false }),
          simple_payback({ isActive : false }),
          tdv2022_benefit_to_cost_ratio({ isActive : false }),
          lsc_2025_benefit_to_cost_ratio({ isActive : false }),
        ],
        hasHelper: {
          type: 'helper',
          id: 23
        }
      },
      {
        order: 1,
        key: "per_home_results",
        title: 'Per Home Results',
        columns: [
          initial_cost({ isActive : false }),
          annual_bill_savings({ isActive : false }),
          emission_savings({ isActive : false }),
          lifecycle_savings({ isActive : false }),
          kwh_savings({ isActive : false }),
          therms_savings({ isActive : false }),
          { 
            ...energy_savings_combined({ isActive : false }),
            hasProjections: true,
            chartProjection: {
              title: 'Energy Savings',
              subtitle: 'site MMBTU/year'
            },
          }
        ],
        hasHelper: {
          type: 'helper',
          id: 24
        }
      },
      {
        order: 2,
        key: "city_wide_impact",
        title: 'Wide Impact',
        columns: [
          forecast_units({ isActive : true }),
          forecast_initial_cost({ isActive : true }),
          forecast_emissions_savings({ isActive : true }),
          forecast_lifecycle_savings({ isActive : true }),
          forecast_kwh_savings({ isActive : true }),
          forecast_therms_savings({ isActive : true }),
        ],
        hasHelper: {
          type: 'helper',
          id: 25
        }
      },
      {
        order: 3,
        key: "per_home_subsidy_needed_columns",
        title: 'Subsidy Needed Per Home',
        columns: [
          subsidy_needed({ isActive : false }),
          five_years_payback({ isActive : false }),
          subsidy_needed_care({ isActive : false }),
          five_years_payback_care({ isActive : false }),
        ],
        hasHelper: {
          type: 'helpers',
          id: 109,
          slug: 'per_home_subsidy_needed_columns',
          showOnEditHideColumns: true,
          customButton: {
            title: 'Learn More',
            url: 'https://intercom.help/explorer-local-energy-codes/en/articles/6805213'
          }
        }
      },
      {
        order: 4,
        key: "city_wide_subsidy_needed_columns",
        title: 'Subsidy Needed City Wide',
        columns: [
          city_wide_subsidy_needed({ isActive : false }),
          city_wide_five_year_payback({ isActive : false }),
          city_wide_subsidy_needed_care({ isActive : false }),
          city_wide_five_year_payback_care({ isActive : false }),
        ],
        hasHelper: {
          type: 'helpers',
          slug: 'city_wide_subsidy_needed_columns',
          showOnEditHideColumns: true,
          customButton: {
            title: 'Learn More',
            url: 'https://intercom.help/explorer-local-energy-codes/en/articles/6805213'
          }
        }
      },
    ]
  },
  newBuildings: {
    key: 'newBuildings',
    studyType: 'New Buildings',
    columnGroups: [
      {
        order: 0,
        key: "fixed_columns",
        title: 'Cost-Effectiveness',
        columns: [
          simple_payback({ isActive : false }),
          on_bill_cost_ratio({ isActive : false }),
          tdv_benefit_to_cost_ratio({ isActive : false }),
        ],
        hasHelper: {
          type: 'helper',
          id: 23
        }
      },
      {
        order: 1,
        key: "per_home_results",
        title: 'Per Home Results',
        columns: [
          initial_cost({ isActive : false }),
          annual_bill_savings({ isActive : false }),
          emission_savings({ isActive : false }),
          lifecycle_savings({ isActive : false }),
          kwh_savings({ isActive : false }),
          therms_savings({ isActive : false }),
          compliance_margin({ isActive : false, isDisabled: false }),
          edr1_total_margin({ isActive : false, isDisabled: false }),
        ],
        hasHelper: {
          type: 'helper',
          id: 24
        }
      },
      {
        order: 2,
        key: "city_wide_impact",
        title: 'Wide Impact',
        columns: [
          forecast_units({ isActive : true }),
          forecast_initial_cost({ isActive : true }),
          forecast_emissions_savings({ isActive : true }),
          forecast_lifecycle_savings({ isActive : true }),
          forecast_kwh_savings({ isActive : true }),
          forecast_therms_savings({ isActive : true }),
        ],
        hasHelper: {
          type: 'helper',
          id: 25
        }
      },
      {
        order: 3,
        key: "per_home_subsidy_needed_columns",
        title: 'Subsidy Needed Per Home',
        columns: [
          subsidy_needed({ isActive : false }),
          five_years_payback({ isActive : false }),
          subsidy_needed_care({ isActive : false }),
          five_years_payback_care({ isActive : false }),
        ],
        hasHelper: {
          type: 'helpers',
          id: 109,
          slug: 'per_home_subsidy_needed_columns',
          showOnEditHideColumns: true,
          customButton: {
            title: 'Learn More',
            url: 'https://intercom.help/explorer-local-energy-codes/en/articles/6805213'
          }
        }
      },
      {
        order: 4,
        key: "city_wide_subsidy_needed_columns",
        title: 'Subsidy Needed City Wide',
        columns: [
          city_wide_subsidy_needed({ isActive : false }),
          city_wide_five_year_payback({ isActive : false }),
          city_wide_subsidy_needed_care({ isActive : false }),
          city_wide_five_year_payback_care({ isActive : false }),
        ],
        hasHelper: {
          type: 'helpers',
          slug: 'city_wide_subsidy_needed_columns',
          showOnEditHideColumns: true,
          customButton: {
            title: 'Learn More',
            url: 'https://intercom.help/explorer-local-energy-codes/en/articles/6805213'
          }
        }
      }
    ]
  }
}
