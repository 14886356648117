<template>
  <div>
    <AssumptionsBuildingStocksInput
      v-if="getDrawerPath.slug !== path.NON_RESIDENTIAL_BUILDINGS.slug"
      label="Existing Units"
      :filters="filters"
      class="stock-input"
    />
    <div
      v-for="year in years"
      :key="year"
      class="pt-4 psui-text-small psui-text-gray-80"
    >
      <h2 class="font-bold mb-4">
        {{ year }}
      </h2>
      <AssumptionsBuildingEstimatesInputs
        :filters="{...filters, year }"
        unit-measurement="Square feet"
      />
    </div>
  </div>
</template>

<script>
import AssumptionsBuildingStocksInput from '../AssumptionsBuildingStocksInput.vue'
import AssumptionsBuildingEstimatesInputs from '../AssumptionsBuildingEstimatesInputs.vue'
import { BUILDING_ESTIMATES_BASE_YEARS, ASSUMPTIONS_PATHS } from '@/modules/app/assumptions/shared/enums'
export default {
  name: 'AssumptionsResidentialNewYears',
  components: { AssumptionsBuildingEstimatesInputs, AssumptionsBuildingStocksInput },
  props: {
    filters: Object,
  },
  data: () => ({
    years: BUILDING_ESTIMATES_BASE_YEARS,
    path: ASSUMPTIONS_PATHS
  }),
  computed:{
    getDrawerPath(){
      return this.$store.state.assumptions.drawerOptions.path
    }
  }
}
</script>

<style lang='scss' scoped>
  ::v-deep .psui-el-input {
    width: 130px;
  }

  ::v-deep .stock-input {
    & > div {
      margin-bottom: 0;
    }
  }

</style>