<template>
  <div class="w-full p-6">
    <InputWithLabel
      v-model="search"
      :label="'Search for help'"
      class="mb-4"
      @keydown.enter="getHelpers({ offset: 0 })"
    />

    <div class="w-full flex justify-end">
      <router-link :to="{ name: 'AdminHelperCreate' }">
        <PsButton
          label="Create"
          icon="add"
          size="big"
        />
      </router-link>
    </div>
    <table class="table table-bordered mt-6 whitespace-nowrap">
      <thead>
        <tr>
          <th>ID</th>
          <th>Title</th>
          <th>Slug</th>
          <th>Created at</th>
          <th class="psui-w-48">
            Edit
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="helper in helpers"
          :key="helper.id"
        >
          <td>{{ helper.id }}</td>
          <td>{{ helper.title }}</td>
          <td>{{ helper.slug }}</td>
          <td>{{ helper.created_at }}</td>
          <td class="psui-flex psui-space-x-2">
            <router-link
              :to="{
                name: 'AdminHelperEdit',
                params: { helper_id: helper.id },
              }"
            >
              <PsButton
                label="Edit"
                icon="edit"
                size="medium"
              />
            </router-link>
            <PsButton
              layout="caution"
              label="Delete"
              size="medium"
              icon="delete"
              @click="selectHelperToDelete(helper)"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <BaseModal
      ref="deleteModal"
      modal-dialog-classes="w-full max-w-sm"
      title="Attention"
    >
      <div
        v-if="helper_selected"
        class="w-full flex flex-col items-start mt-6"
      >
        <p class="text-base text-gray04">
          Delete
          <span class="text-red font-bold">{{ helper_selected.title }}</span>?
        </p>
        <PsButton
          layout="caution"
          class="psui-mt-6"
          label="Confirm delete"
          size="big"
          @click="deleteHelper()"
        />
      </div>
    </BaseModal>

    <Paginator
      v-model="offset"
      :length="helpers.length"
      :limit="limit"
      @getItems="getHelpers({ offset })"
    />
  </div>
</template>

<script>
export default {
  name: 'AdminHelperList',
  data() {
    return {
      helpers: [],
      helper_selected: null,
      search: '',
      offset: 0,
      limit: 12,
    }
  },
  mounted() {
    this.getHelpers()
  },
  methods: {
    async getHelpers({ offset = 0 } = {}) {
      this.offset = offset
      const query = `
        {
          helpers(
            where: [
              { column: "title", operation: "iLIKE", value: "%${this.search}%"}
            ],
            orWhere: [
              { column: "slug", operation: "iLIKE", value: "%${this.search}%"}
            ],
            orderBy: [{column: "id", order: "asc"}], 
            paginate: { offset: ${this.offset}, limit: ${this.limit}}
          ) {
            id
            title
            slug
            created_at
          }
        }
      `
      const { data } = await this.$graphql({ query })

      this.helpers = data.helpers
    },

    selectHelperToDelete(helper) {
      this.helper_selected = helper
      this.$refs.deleteModal.showModal = true
    },

    deleteHelper() {
      this.$api.delete(`/helper/${this.helper_selected.id}`).then(({ data }) => {
        this.$toast.success(data.message)
        this.getHelpers()
          this.$refs.deleteModal.showModal = false
          this.helper_selected = null
      })
    },
  },
}
</script>
