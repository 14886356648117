import Store from '@/store'
import { defaultExistingBuildingsImpactAssumptionValues } from '@/modules/app/assumptions/shared/defaultAssumptions'
import ImpactAssumptions from '@/models/ImpactAssumptions'
import generatePolicyProjections from './generatePolicyProjections'

export default ({ policy, climate_zone, prototype, vintage, studyData }) => {

  const building_stock_units = Store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
    jurisdiction_id: policy.jurisdiction_id,
    climate_zone_prefix: climate_zone.climate_zone_prefix,
    type_prototype_id: prototype.type_prototype_id,
    type_vintage_id: vintage.type_vintage_id,
    prototype_id: prototype.id,
  })

  const customCombinationFilters = {
    policy_id: policy.id,
    climate_zone_raw: climate_zone.climate_zone_raw,
    prototype_id: prototype.id,
  }

  const custom_combinations = Store.getters['policy/getterFilteredPolicyCustomCombinations'](customCombinationFilters)
  const ccMetaAssumption = custom_combinations[0]?.meta?.assumptions ? custom_combinations[0].meta.assumptions : {}
  const impactAssumptions = new ImpactAssumptions(ccMetaAssumption, defaultExistingBuildingsImpactAssumptionValues)
  
  const { projectionsByYear } = generatePolicyProjections({ impactAssumptions, data: studyData, building_stock_units })
  
  return projectionsByYear

}