
import dayjs from 'dayjs'
import getBaseUnitsChangesInQuarter from './getBaseUnitsChangesInQuarter'
import getBaselinePercentage from './getBaselinePercentage'
import { getKwhEmissionFactorByQuarter } from '@/business-logic/services/impact-algorithms/common/mechanics'

/**
 * get advanced projections in quarter for all columns of advanced projection
 */
export default ({
  assumption, 
  building_stock_units, 
  currentQuarterDate,
  projectionsByQuarter, 
  index,
  kwh_savings,
  therms_savings,
  annual_bill_savings,
  initial_cost,
  installation_effects_years,
}) => {

  const start_at_date = dayjs(assumption.start_at)

  const baseUnitChanged = getBaseUnitsChangesInQuarter({ building_stock_units, annual_pennetration_rate_final: assumption.getAnnualPenetrationRateFinal})

  // Installations Started
  const installationsStarted = (
    currentQuarterDate.isBefore(start_at_date.clone().startOf('quarter').add((assumption.active_policy_duration), 'years'))
  ) ? baseUnitChanged : 0
  
  const virtualStartYear = start_at_date.year()
  const virtualStartQuarter = start_at_date.quarter()
  const assumptionInstallationDelay = assumption.delay_installation
  const assumptionActivePolicyDuration = assumption.active_policy_duration
  const virtualFirstInstallationsComplete = virtualStartYear + ((virtualStartQuarter-1)*0.25) + assumptionInstallationDelay
  const virtualLastInstallationsComplete = virtualStartYear + ((virtualStartQuarter-1)*0.25) + assumptionInstallationDelay + assumptionActivePolicyDuration - 0.25

  // Installations Completed
  const mechanicsYear = parseFloat(currentQuarterDate.year())
  const mechanicsQuarter = parseFloat(currentQuarterDate.quarter())
  const mechanicsYearQuarter = mechanicsYear - 0.25 + (mechanicsQuarter * 0.25 )
  const installationsCompleted = 
    mechanicsYearQuarter >= virtualFirstInstallationsComplete &&
    mechanicsYearQuarter <= virtualLastInstallationsComplete ? 
    baseUnitChanged : 0  
  
  //Baseline Installations
  const {
    baselineInstallationsStartedPercentage,
    baselineInstallationsCompletedPercentage
  } = getBaselinePercentage({ currentQuarterDate, assumption, installation_effects_years })

  // Baseline Installations Started
  const baselineInstallationsStarted = installationsStarted > 0 ? installationsStarted / 100 * baselineInstallationsStartedPercentage : 0
  const baselineInstallationsCompleted = installationsCompleted > 0 ? installationsCompleted / 100 * baselineInstallationsCompletedPercentage : 0

  // netInstallationsStarted
  const netInstallationsStarted = installationsStarted > 0 ? (installationsStarted - baselineInstallationsStarted) : 0

  // netInstallationsCompleted
  const netInstallationsCompleted = installationsCompleted > 0 ? (installationsCompleted - baselineInstallationsCompleted) : 0
  
  // Cummulative installations completed
  const cummulativeInstallationsCompleted = projectionsByQuarter.reduce((acc, item) => acc + item.installationsCompleted, installationsCompleted)
    
  // Installations aged out
  const installationsAgedOut = (
    currentQuarterDate.isSameOrAfter(start_at_date.add((assumption.years + assumption.delay_installation), 'years'))
    ) ? baseUnitChanged : 0
  
  // Cummulative installations aged out
  const cummulativeInstallationsAgedOut = projectionsByQuarter.reduce((acc, item) => acc + item.installationsAgedOut, installationsAgedOut)

  // Active installations
  const activeInstallations = (cummulativeInstallationsCompleted - cummulativeInstallationsAgedOut)
  
  // Baseline active installations
  const baselineActiveInstallations = activeInstallations > 0 ? activeInstallations / 100 * baselineInstallationsStartedPercentage : 0
  
  // Net active installations
  const netActiveInstallations = activeInstallations > 0 ? activeInstallations - baselineActiveInstallations : 0
  
  // Therms saved
  const thermsSaved = therms_savings != 0 ? netActiveInstallations * (therms_savings / 4) : 0
  
  // GHG savings from therms
  const ghgSavingsFromTherms = thermsSaved * assumption.current_therms_emissions_factor

  // Kwh saved
  const kwhSaved = kwh_savings != 0 ? netActiveInstallations * (kwh_savings / 4) : 0

  // Grid renewable share change per year
  const gridMaxRenewablesYearFromToday = assumption.grid_max_renewables_year - dayjs().year()
  const gridRenewableShareChangePerYear = 
  (assumption.grid_max_renewables_level -  assumption.current_grid_renewable_level) / 
  (gridMaxRenewablesYearFromToday <= 0 ? 1 : gridMaxRenewablesYearFromToday)

  // Grid renewable level
  const startYearGridRenewableLevel = assumption.current_grid_renewable_level -
  (dayjs().year() - start_at_date.year()) * gridRenewableShareChangePerYear
  
  // Grid renewable share
  let gridRenewableShare
  gridRenewableShare = startYearGridRenewableLevel +  (gridRenewableShareChangePerYear) * (currentQuarterDate.year() - start_at_date.year())
  if(gridRenewableShare > assumption.grid_max_renewables_level) {
    gridRenewableShare = assumption.grid_max_renewables_level
  }
      

  // Kwh emissions factor
  const kwhEmissionsFactor = getKwhEmissionFactorByQuarter(assumption.current_kwh_emissions_factor, assumption.current_grid_renewable_level, gridRenewableShare)

  // GHG savings from kwh
  const ghgSavingsFromKwh = kwhEmissionsFactor * kwhSaved

  // GHG savings
  const ghgSavings = ghgSavingsFromKwh + ghgSavingsFromTherms

  // Cumulative GHG Savings
  const cumulativeGhgSavings = projectionsByQuarter.reduce((acc, item) => acc + item.ghgSavings, ghgSavings)

  // Bill Savings
  const billSavings = netActiveInstallations * (annual_bill_savings / 4)
  
  // Compliance cost
  const complianceCost = netInstallationsStarted * (initial_cost)

  return {
    ref: (index / 4) - 0.25,
    year: currentQuarterDate.year(),
    quarter: currentQuarterDate.quarter(),
    baseUnitChanged,
    installationsStarted,
    installationsCompleted,
    baselineInstallationsStarted,
    baselineInstallationsStartedPercentage,
    baselineInstallationsCompleted,
    baselineInstallationsCompletedPercentage,
    netInstallationsStarted,
    netInstallationsCompleted,
    cummulativeInstallationsCompleted,
    installationsAgedOut,
    cummulativeInstallationsAgedOut,
    activeInstallations,
    baselineActiveInstallations,
    netActiveInstallations,
    thermsSaved,
    ghgSavingsFromTherms,
    kwhSaved,
    gridRenewableShare,
    kwhEmissionsFactor,
    ghgSavingsFromKwh,
    ghgSavings,
    startYearGridRenewableLevel,
    gridRenewableShareChangePerYear,
    cumulativeGhgSavings,
    billSavings,
    complianceCost,
    virtualFirstInstallationsComplete,
    virtualLastInstallationsComplete,
    mechanicsYearQuarter
  }
}
