import { render, staticRenderFns } from "./AssumptionsResidential.vue?vue&type=template&id=5900e49d&scoped=true"
import script from "./AssumptionsResidential.vue?vue&type=script&lang=js"
export * from "./AssumptionsResidential.vue?vue&type=script&lang=js"
import style0 from "./AssumptionsResidential.vue?vue&type=style&index=0&id=5900e49d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5900e49d",
  null
  
)

export default component.exports