import GraphQL from '@/util/GraphQL'
import Api from '@/util/Api'
import dayjs from 'dayjs'

export default class ArticlesApiService {

    static getArticles (limit = 4){
        const query = `
        {
            articles(limit: ${limit}) {
              id
              published_at
              created_at
              intercom_id
              title
              has_custom_data
              content
              description
              meta
              state
            }
          }
        `
        return GraphQL({query,caller:'ArticlesApiService.getArticles'})
        .then(({data})=>{
            return [...(data?.articles || []).map((article) => {
              article.published_at = article.published_at ? dayjs(article.published_at).format('MM/DD/YYYY') : null
              return article
            }).sort((a,b)=>  dayjs(b.published_at || undefined) - dayjs(a.published_at || undefined))].slice(0, limit)
        })
    }

    static updateArticle(article) {

      const payload = {
        ...article,
        published_at: dayjs(article.published_at, 'MM/DD/YYYY').format('YYYY-MM-DD'),
      }

      return Api.post(`/api/admin/article/update_or_create`, payload)
    }
}