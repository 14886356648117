<template>
  <div 
    v-if="hasWarningToShow.length"
    class="policy-warning psui-w-full psui-px-4 sm:psui-px-8"
    :class="hasWarningToShow ? 'psui-mb-6' : 'psui-mb-4'"
  >
    <!--  This alert is being treated as a separated context, i.e, it can be used combined with other alerts  -->
    <PsDialog
      v-if="hasWarningToShow.includes(TYPES.HAS_EDR1_NEW_BUILDING_OUTDATED_CONTAINERS) || hasWarningToShow.includes(TYPES.HAS_EDR2EFF_NEW_BUILDING_OUTDATED_CONTAINERS) || hasWarningToShow.includes(TYPES.HAS_OUTDATED_CONTAINERS)"
      theme="alert"
      icon="error_outline"
      class="psui-w-full"
      :class="{'mb-2': hasOtherWarnings}"
      :has-close="false"
    >
      <template #action>
        <div
          class="psui-flex psui-space-x-5 psui-mr-2 psui-font-bold"
          style="min-width: 220px"
        >
          <a
            class="psui-text-small psui-cursor-pointer"
            @click="$router.push({name:'PolicyOptions'})"
          >
            {{ 'Create a New Policy' | policyTextRephrase }}
          </a>
          <a
            href="https://intercom.help/explorer-local-energy-codes/en/articles/9562821-updating-policies-using-outdated-study-data"
            target="_blank"
          >
            Learn More
          </a>
        </div>
      </template>
      <p v-if="hasWarningToShow.includes(TYPES.HAS_EDR2EFF_NEW_BUILDING_OUTDATED_CONTAINERS)">
        This {{ 'policy' | policyTextRephrase }} was created on {{ formatDate({ date: getPolicy.created_at}) }} using cost-effectiveness data released on {{ formatDate({ date: policyStudy.released_at }) }}. Newer cost-effectiveness study results released on {{ formatDate({ date: newestPolicyStudy.released_at }) }} are now available in the Explorer. In addition, under direction from the Reach Codes Team we changed new construction {{ 'policy' | policyTextRephrase }} architecture on 01/19/2024, to use a more comprehensive compliance metric (EDR1). You can view this {{ 'policy' | policyTextRephrase }}, but you will be unable to make further changes. From here, we suggest you <a
          class="psui-text-small a-btn-effect"
          @click="$router.push({name:'PolicyOptions'})"
        >create a new {{ 'policy' | policyTextRephrase }} here</a>, which will be based on the newest results and {{ 'policy' | policyTextRephrase }} architecture.
      </p>
      <p v-else>
        This {{ 'policy' | policyTextRephrase }} was created on {{ formatDate({ date: getPolicy.created_at}) }} using cost-effectiveness data released on {{ formatDate({ date: policyStudy.released_at }) }}. Newer cost-effectiveness study results released on {{ formatDate({ date: newestPolicyStudy.released_at }) }} are now available in the Explorer. You can view this {{ 'policy' | policyTextRephrase }}, but you will be unable to make further changes. From here, we suggest you <a
          class="psui-text-small a-btn-effect"
          @click="$router.push({name:'PolicyOptions'})"
        >create a new {{ 'policy' | policyTextRephrase }} here</a>, which will be based on the newest results.
      </p>
    </PsDialog>

    <PsDialog
      v-if="hasWarningToShow.includes(TYPES.IS_VIEWING_A_SHARED_POLICY)"
      theme="alert"
      icon="error_outline"
      :message="'Viewing a shared policy' | policyTextRephrase"
      class="psui-w-full"
      :has-close="false"
    >
      <template #action>
        <div class="psui-flex psui-space-x-5 psui-mr-2">
          <p 
            class="psui-text-yellow-70 psui-text-small psui-font-bold psui-cursor-pointer"
            @click="$eventBus.$emit('openDuplicatePolicyModal', { policy: getPolicy })"
          >
            Duplicate to edit
          </p>
        </div>
      </template>
    </PsDialog>
    <PsDialog
      v-else-if="hasWarningToShow.includes(TYPES.USER_HAS_POLICIES_TO_SAVE)"
      theme="alert"
      icon="error_outline"
      :message="'Remember to save your policies for when you come back later.' | policyTextRephrase"
      class="psui-w-full psui-cursor-pointer"
      :has-close="false"
      @click.native="onUserClickToSavePolicies()"
    >
      <template #action>
        <div class="psui-flex psui-space-x-5 psui-mr-2">
          <p class="psui-text-yellow-70 psui-text-small psui-font-bold psui-cursor-pointer">
            Create an account
          </p>
          <p class="psui-text-yellow-70 psui-text-small psui-font-bold psui-cursor-pointer">
            Login
          </p>
        </div>
      </template>
    </PsDialog>
    <PsDialog
      v-else-if="hasWarningToShow.includes(TYPES.USER_IS_LOGGED_OUT_AND_HAS_NO_POLICIES)"
      theme="informative"
      icon="login"
      :message="'Already have policies saved to an account?' | policyTextRephrase"
      class="psui-w-full psui-cursor-pointer"
      :has-close="false"
      @click.native="$eventBus.$emit('openModalUserSign')"
    >
      <template #action>
        <div class="psui-flex psui-space-x-5 psui-mr-2">
          <p class="psui-text-blue-60 psui-text-small psui-font-bold">
            Login
          </p>
        </div>
      </template>
    </PsDialog>
  </div>
</template>

<script>

import { checkIfPolicyIsOutdated, policyHasAnyContainerWithComplianceMarginKey } from '@/util/Helpers'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import { POLICY_TYPES } from '@/modules/app/policy/shared/enums.js'
import { compliance_margin, edr1_total_margin } from "@/modules/app/shared/default-app-columns"

const TYPES = {
  IS_VIEWING_A_SHARED_POLICY : 'IS_VIEWING_A_SHARED_POLICY',
  USER_IS_LOGGED_OUT_AND_HAS_NO_POLICIES : 'USER_IS_LOGGED_OUT_AND_HAS_NO_POLICIES',
  USER_HAS_POLICIES_TO_SAVE : 'USER_HAS_POLICIES_TO_SAVE',
  HAS_OUTDATED_CONTAINERS: 'HAS_OUTDATED_CONTAINERS',
  HAS_EDR2EFF_NEW_BUILDING_OUTDATED_CONTAINERS: 'HAS_EDR2EFF_NEW_BUILDING_OUTDATED_CONTAINERS',
  HAS_EDR1_NEW_BUILDING_OUTDATED_CONTAINERS: 'HAS_EDR1_NEW_BUILDING_OUTDATED_CONTAINERS',
}

export default {
  name: 'PolicyHeaderUserWarnings',
  data: () => ({
    TYPES
  }),
  computed: {    
    hasWarningToShow() {
      let warnings = []
      if (this.isViewingASharedPolicy) warnings.push(TYPES.IS_VIEWING_A_SHARED_POLICY)
      if (this.userHasPoliciesToSave && this.$route.name !== 'PolicyOptions') warnings.push(TYPES.USER_HAS_POLICIES_TO_SAVE)
      if (this.userIsLoggedOutAndHasNoPoliciesToShow) warnings.push(TYPES.USER_IS_LOGGED_OUT_AND_HAS_NO_POLICIES)

      // Outdated warnings
      if (this.policyHasOutdatedContainers) warnings.push(TYPES.HAS_OUTDATED_CONTAINERS)
      if (this.policyHasEdr2EffOutdatedContainers) warnings.push(TYPES.HAS_EDR2EFF_NEW_BUILDING_OUTDATED_CONTAINERS)
      if (this.policyHasEdr1OutdatedContainers) warnings.push(TYPES.HAS_EDR1_NEW_BUILDING_OUTDATED_CONTAINERS)
      return warnings
    },
    hasOtherWarnings() {
      return this.hasWarningToShow?.filter((w) => ![TYPES.HAS_OUTDATED_CONTAINERS, TYPES.HAS_EDR2EFF_NEW_BUILDING_OUTDATED_CONTAINERS, TYPES.HAS_EDR1_NEW_BUILDING_OUTDATED_CONTAINERS].includes(w))?.length > 0
    },
    isViewingASharedPolicy() {
      const isPolicyShow = this.$route.name === 'PolicyShow'
      const policyType = this.$store.getters['policy/getterPolicyType'](this.$route.params.policy_id)
      return isPolicyShow && policyType === POLICY_TYPES.SHARED.value
    },
    userHasPoliciesToSave() {
      return this.$store.getters['policy/getterUserHasPoliciesToSave']
    },
    userIsLoggedOutAndHasNoPoliciesToShow() {
      const isPoliciesListOrComparisonShow = this.$route.name === 'PoliciesList' || this.$route.name === 'ComparisonShow'
      const userIsLoggedOut = !this.$store.getters['isUserLoggedIn']
      const userHasNoPoliciesToShow = !this.$store.getters['policy/getterUserHasPoliciesToSave']
      return isPoliciesListOrComparisonShow && userIsLoggedOut && userHasNoPoliciesToShow ? true : false
    },
    policyStudy() {
      const policyStudyIds = [... new Set(this.getPolicy?.policy_containers?.map((c) => {
        return c?.custom_combinations?.map((cc) => {
          return cc?.prototype?.study_id || null
        })?.filter((i) => i !== null) || []
      })?.flat(Infinity) || [])]

      const firstStudyId = policyStudyIds.shift()
      if (!firstStudyId) return null
      return this.$store.getters['globalEntities/Study/getterGlobalStudy']({ id: firstStudyId })
    },
    policyTypePrototypes() {
      return [... new Set(this.getPolicy?.policy_containers?.map((c) => {
        return c?.custom_combinations?.map((cc) => {
          return cc?.prototype_id ? this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: cc.prototype_id })?.type_prototype_id || null : null
        })?.filter((i) => i !== null) || []
      })?.flat(Infinity) || [])]
    },
    newestPolicyStudy() {
      if (!this.policyStudy) return null
      return this.$store.getters['globalEntities/Prototype/getterPrototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt']({
        type_prototype: { id: this.policyTypePrototypes },
        study_group: {id: this.policyStudy.study_group_id}
      })?.shift()?.study || null
    },
    getPolicy() {
      const isPolicyShowView = this.$route.params.policy_id
      const getPolicySelected = this.$store.getters['assumptions/getterPolicySelected']
      return isPolicyShowView && getPolicySelected ? getPolicySelected : false
    },
    getPolicyType() {
      return this.$store.getters['policy/getterPolicyStudyTypes']({policy_id: this.$route.params.policy_id}).pop()
    },
    policyHasEdr2EffComplianceMarginKey() {
      return policyHasAnyContainerWithComplianceMarginKey(this.getPolicy, compliance_margin().key)
    },
    policyHasEdr1ComplianceMarginKey() {
      return policyHasAnyContainerWithComplianceMarginKey(this.getPolicy, edr1_total_margin().key)
    },
    policyOutdatedInfo() {
      return checkIfPolicyIsOutdated(this.getPolicy)
    },
    policyHasOutdatedContainers() {
      return this.policyOutdatedInfo.has_outdated_studies === true && this.policyOutdatedInfo.is_loading === false && this.policyStudy && this.policyStudy.released_at && this.newestPolicyStudy && this.newestPolicyStudy.released_at
    },
    policyHasEdr2EffOutdatedContainers() {
      return this.policyHasOutdatedContainers && this.getPolicyType === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS && this.policyHasEdr2EffComplianceMarginKey
    },
    policyHasEdr1OutdatedContainers() {
      return this.policyHasOutdatedContainers && this.getPolicyType === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS && this.policyHasEdr1ComplianceMarginKey
    }
  },
  methods: {
    onUserClickToSavePolicies() {
      this.$store.commit('general/setRedirectTo', { route: window.location.pathname })
      this.$eventBus.$emit('openModalUserSign')
    }
  }
}
</script>

<style lang="scss" scoped>

.a-btn-effect {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
