<template>
  <div 
    class="py-2 px-6 bg-white"
  >
    <EditRequirementsNewBuildingsSD2022AdminInfo
      v-if="getAllElectricCombination && getMixedFuelCombination"
      v-if-admin
      :requirement-all-electric="getAllElectricCombination" 
      :requirement-mixed-fuel="getMixedFuelCombination"
      :policy="policy"
    />
    <div class="psui-w-full">
      <h3
        class="psui-text-blue-80 psui-text-accentSmall psui-font-bold psui-uppercase psui-mb-2"
      >
        {{ 'Policy Requirements' | policyTextRephrase }}
      </h3>
      
      <div
        v-if="getAllElectricCombination || getMixedFuelCombination"
        class="psui-w-full psui-flex flex-col psui-bg-blue-10 rounded-t rounded-b psui-text-blue-70 psui-font-bold"
        @click="openNewBuildingsDuplicateModal"
      >
        <div :class="{'disable-interaction': !getterUserCanEditPolicy}">
          <div class="psui-w-full psui-flex psui-items-center flex-row px-2 py-2-5">
            <PsSwitch
              ref="switchRequireAllElectricConstruction"
              class="flex-shrink-0"
              size="small"
              label=""
              background-color="psui-bg-blue-60"
              :value="isAllElectricConstructionRequired"
              @change="toggleRequireAllElectricConstruction"
            />
            <span
              class="psui-text-small psui-ml-1 psui-mr-1"
            >Require all electric construction</span>
            <span
              v-if="getterUserCanEditPolicy"
              class="svg-position force-enabling-interaction"
            >
              <PsRichTooltip
                css-class="w-48"
                layout="blue"
              >
                <template #trigger>
                  <span class="cursor-pointer">
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                    >
                      <circle
                        cx="5"
                        cy="5"
                        r="4.5"
                        fill="#EDAB3E"
                        stroke="#E0EFF6"
                      />
                    </svg>
                  </span>
                </template>
                <template #content>
                  <p style="font-size: 0.75rem; font-weight: 700; line-height: 1rem;">
                    Please be aware that there are ongoing legal disputes concerning mandating fuel types.
                  </p>
                  <p style="font-size: 0.75rem; font-weight: 400; margin-top: 2px; line-height: 1rem;">
                    The Electric Only option provided on this platform is solely for informational purposes
                  </p>
                </template>
              </PsRichTooltip>
            </span>
            <PsIcon
              icon="info_outline"
              size="16"
              class="info-outline-icon-open-modal psui-items-center psui-justify-center psui-cursor-pointer force-enabling-interaction"
              :style="{ display: 'flex', marginTop: '1px' }"
              @click.native.stop="openRequireEqualMinimumPerformanceHelper('allElectricConstructionRequired', 'require_all_electric_construction');"
            />
          </div>
          
          <hr class="divider psui-w-full psui-border-solid psui-border-white">

          <div class="psui-w-full psui-flex psui-items-center psui-flex-row px-2 py-2-5">
            <PsSwitch
              ref="switchRequireAllElectricConstruction"
              class="flex-shrink-0"
              size="small"
              label=""
              background-color="psui-bg-blue-60"
              :value="isAMinimumPerformanceScoreRequired"
              @change="toggleRequireAMinimumPerformanceScore(isAMinimumPerformanceScoreRequired)"
            />
            <span
              class="psui-text-small psui-ml-1 psui-mr-1"
            >Require a minimum performance score (EDR1)</span>
            <PsIcon
              icon="info_outline"
              size="16"
              class="info-outline-icon-open-modal psui-items-center psui-justify-center psui-cursor-pointer force-enabling-interaction"
              :style="{ display: 'flex', marginTop: '1px' }"
              @click.native.stop="openRequireEqualMinimumPerformanceHelper('minimumPerformanceScoreRequired', 'require_minimum_performance_score')"
            />
          </div>

          <div
            v-if="isAMinimumPerformanceScoreRequired"
            class="pl-9 psui-flex psui-flex-col psui-pt-1 "
          >
            <div 
              v-if="isComplianceMarginUnique && !isAllElectricConstructionRequired"
              class="size-to-content psui-flex psui-flex-row psui-p-2 psui-rounded psui-gap-2 psui-bg-blue-20"
              :class="{ 'pointer-events-none opacity-60' : isSavingByRequirementId[getAllElectricCombination.id] }"
            >
              <div
                class="psui-flex psui-space-x-2 psui-items-center"
              >
                <PsDropdown
                  :ref="`compliance_margin_measure_id_dropdown_${getAllElectricCombination.id}`"
                  class="psui-el-dropdown-menu-compliance"
                  :disabled="!getterUserCanEditPolicy"
                >
                  <template #dropdownTrigger>
                    <span
                      class="psui-el-dropdown-menu-item psui-flex psui-justify-between psui-items-center psui-w-full psui-text-gray-80 psui-bg-white psui-px-2 psui-py-1 psui-rounded psui-shadow-elevation-5"
                      :class="{ 'selected psui-border psui-border-blue-60 psui-text-blue-60' : 1 === 2, 'psui-bg-gray-20 psui-text-gray-50 psui-cursor-default psui-shadow-none': !getterUserCanEditPolicy}"
                    >
                      <span class="loader-spinner">
                        <LoaderSpinner 
                          v-if="isSavingByRequirementId[getAllElectricCombination.id]"
                        />
                        <span
                          class="py-0.75 psui-text-small psui-font-normal psui-text-gray-50"
                          :class="{ 'ml-1' : isSavingByRequirementId[getAllElectricCombination.id] }"
                        >
                          {{ getComplianceMarginMetaRequirementValue(getAllElectricCombination, 'allElectricAndMixedFuel') ? getComplianceMarginMetaRequirement(getAllElectricCombination, 'allElectricAndMixedFuel').left_label_formatted : '+0.0' }}
                        </span>
                      </span>
                      <PsIcon
                        icon="expand_more"
                        size="18"
                        class="psui-text-gray-50 psui-cursor-pointer leading-none"
                        :class="{'cursor-default':!getterUserCanEditPolicy}"
                      />                                            
                    </span>
                  </template>
                  <template #items>
                    <DropdownMenuListRadio
                      :items="complianceMarginMeasureIdOptionsAllElectricAndMixedFuel"
                      :key-value="'compliance_margin_measure_id'"
                      key-label-left="left_label_formatted"
                      :selected="getComplianceMarginMetaRequirementValue(getAllElectricCombination, 'allElectricAndMixedFuel')"
                      @change="setComplianceMarginMetaRequirement(getAllElectricCombination, $event, 'allElectric')"
                    />
                  </template>
                </PsDropdown>
              </div>
              <div
                style="display: flex; align-items: center;"
              >
                <p class="subtitle">
                  max {{ formatComplianceMargin(getTheLowestValueBetweenMaximumComplianceMargins) }}
                </p>
                <PsIcon
                  icon="info_outline"
                  size="16"
                  class="info-outline-icon-open-modal psui-items-center psui-justify-center psui-cursor-pointer force-enabling-interaction"
                  :style="{ display: 'flex', marginTop: '-1px', marginLeft: '2px'}"
                  @click.native.stop="openAllElectricAndMixedFuelMaxHelper()"
                />
              </div>
            </div>

            <div
              v-else
              class="psui-w-full psui-flex psui-flex-col lg:psui-flex-row psui-bg-blue-10 psui-rounded-t psui-rounded-b psui-text-blue-70 psui-font-bold"
            >
              <div
                class="size-to-content psui-flex psui-flex-row psui-p-2 psui-rounded psui-gap-2 psui-bg-blue-20 psui-mr-4 lg:psui-mr-4 lg:psui-mb-0"
                :class="{ 'pointer-events-none opacity-60' : isSavingByRequirementId[getAllElectricCombination.id] }"
              >
                <div class="icons">
                  <PsIcon
                    :icon="`/icons/type-fuel/all-electric.svg`"
                    size="20"
                    :color="'psui-text-blue-60'"
                  />
                </div>
                <div
                  style="display: flex; align-items: center;"
                >
                  <div class="title">
                    All Electric
                  </div>
                  <PsIcon
                    icon="info_outline"
                    size="16"
                    class="info-outline-icon-open-modal psui-items-center psui-justify-center psui-cursor-pointer force-enabling-interaction"
                    :style="{ display: 'flex', marginTop: '1px', marginLeft: '2px' }"
                    @click.native.stop="openRequireEqualMinimumPerformanceHelper(undefined, 'all_electric_edr1_config');"
                  />
                </div>
                <div
                  class="psui-flex psui-space-x-2 psui-items-center"
                >
                  <PsDropdown
                    :ref="`compliance_margin_measure_id_dropdown_${getAllElectricCombination.id}`"
                    class="psui-el-dropdown-menu-compliance"
                    :disabled="!getterUserCanEditPolicy"
                  >
                    <template #dropdownTrigger>
                      <span
                        class="psui-el-dropdown-menu-item psui-flex psui-justify-between psui-items-center psui-w-full psui-text-gray-80 psui-bg-white psui-px-2 psui-py-1 psui-rounded psui-shadow-elevation-5"
                        :class="{ 'selected psui-border psui-border-blue-60 psui-text-blue-60' : 1 === 2, 'psui-bg-gray-20 psui-text-gray-50 psui-cursor-default psui-shadow-none': !getterUserCanEditPolicy}"
                      >
                        <span class="loader-spinner">
                          <LoaderSpinner 
                            v-if="isSavingByRequirementId[getAllElectricCombination.id]"
                          />
                          <span
                            class="py-0.75 psui-text-small psui-font-normal psui-text-gray-50"
                            :class="{ 'ml-1' : isSavingByRequirementId[getAllElectricCombination.id] }"
                          >
                            {{ getComplianceMarginMetaRequirementValue(getAllElectricCombination, 'allElectric') ? getComplianceMarginMetaRequirement(getAllElectricCombination, 'allElectric').left_label_formatted : '+0.0' }}
                          </span>
                        </span>
                        <PsIcon
                          icon="expand_more"
                          class="psui-text-gray-50 psui-cursor-pointer leading-none"
                          :class="{'cursor-default':!getterUserCanEditPolicy}"
                          size="18"
                        />
                      </span>
                    </template>
                    <template #items>
                      <DropdownMenuListRadio
                        :items="complianceMarginMeasureIdOptionsAllElectric"
                        :key-value="'compliance_margin_measure_id'"
                        key-label-left="left_label_formatted"
                        :selected="getComplianceMarginMetaRequirementValue(getAllElectricCombination, 'allElectric')"
                        @change="setComplianceMarginMetaRequirement(getAllElectricCombination, $event, 'allElectric')"
                      />
                    </template>
                  </PsDropdown>
                </div>
                <div
                  style="display: flex; align-items: center;"
                >
                  <div class="subtitle">
                    max {{ formatComplianceMargin(getTheMaximumComplianceMarginForLargerPvSystemValueAllElectric) }}
                  </div>
                  <PsIcon
                    icon="info_outline"
                    size="16"
                    class="info-outline-icon-open-modal psui-items-center psui-justify-center psui-cursor-pointer force-enabling-interaction"
                    :style="{ display: 'flex', marginTop: '-1px', marginLeft: '2px'}"
                    @click.native.stop="openAllElectricAndMixedFuelMaxHelper('all_electric_max_score')"
                  />
                </div>
              </div>
              <div
                v-if="!isAllElectricConstructionRequired"
                class="size-to-content psui-flex psui-flex-row psui-p-2 psui-rounded psui-gap-2 psui-bg-blue-20"
                :class="{ 'pointer-events-none opacity-60' : isSavingByRequirementId[getMixedFuelCombination.id] }"
              >
                <div class="icons">
                  <PsIcon
                    :icon="`/icons/type-fuel/mixed-fuel.svg`"
                    size="20"
                    :color="'psui-text-blue-60'"
                  />
                </div>                
                <div
                  style="display: flex; align-items: center;"
                >
                  <div class="title">
                    Mixed Fuel
                  </div>
                  <PsIcon
                    icon="info_outline"
                    size="16"
                    class="info-outline-icon-open-modal psui-items-center psui-justify-center psui-cursor-pointer force-enabling-interaction"
                    :style="{ display: 'flex', marginTop: '1px', marginLeft: '2px' }"
                    @click.native.stop="openRequireEqualMinimumPerformanceHelper(undefined, 'mixed_fuel_edr1_config');"
                  />
                </div>
                <div
                  class="psui-flex psui-space-x-2 psui-items-center"
                >
                  <PsDropdown            
                    :ref="`compliance_margin_measure_id_dropdown_${getMixedFuelCombination.id}`"
                    class="psui-el-dropdown-menu-compliance"
                    :disabled="!getterUserCanEditPolicy"
                  >
                    <template #dropdownTrigger>
                      <span 
                        class="psui-el-dropdown-menu-item psui-flex psui-justify-between psui-items-center psui-w-full psui-text-gray-80 psui-bg-white psui-px-2 psui-py-1 psui-rounded psui-shadow-elevation-5"
                        :class="{ 'selected psui-border psui-border-blue-60 psui-text-blue-60' : 1 === 2, 'psui-bg-gray-20 psui-text-gray-50 psui-cursor-default psui-shadow-none': !getterUserCanEditPolicy}"
                      >
                        <span class="loader-spinner">
                          <LoaderSpinner 
                            v-if="isSavingByRequirementId[getMixedFuelCombination.id]"
                          />
                          <span
                            class="py-0.75 psui-text-small psui-font-normal psui-text-gray-50"
                            :class="{ 'ml-1' : isSavingByRequirementId[getMixedFuelCombination.id] }"
                          >
                            {{ getComplianceMarginMetaRequirementValue(getMixedFuelCombination, 'mixedFuel') ? getComplianceMarginMetaRequirement(getMixedFuelCombination, 'mixedFuel').left_label_formatted : '+0.0' }}
                          </span>
                        </span>
                        <PsIcon 
                          icon="expand_more"
                          class="psui-text-gray-50 psui-cursor-pointer leading-none"
                          :class="{'cursor-default':!getterUserCanEditPolicy}"
                          size="18"
                        />
                      </span>
                    </template>
                    <template #items>
                      <DropdownMenuListRadio
                        :items="complianceMarginMeasureIdOptionsMixedFuel"
                        :key-value="'compliance_margin_measure_id'"
                        key-label-left="left_label_formatted"
                        :selected="getComplianceMarginMetaRequirementValue(getMixedFuelCombination, 'mixedFuel')"
                        @change="setComplianceMarginMetaRequirement(getMixedFuelCombination, $event, 'mixedFuel')"
                      />
                    </template>
                  </PsDropdown>
                </div>
                <div
                  style="display: flex; align-items: center;"
                >
                  <div class="subtitle">
                    max {{ formatComplianceMargin(getTheMaximumComplianceMarginForLargerPvSystemValueMixedFuel) }}
                  </div>
                  <PsIcon
                    icon="info_outline"
                    size="16"
                    class="info-outline-icon-open-modal psui-items-center psui-justify-center psui-cursor-pointer force-enabling-interaction"
                    :style="{ display: 'flex', marginTop: '-1px', marginLeft: '2px' }"
                    @click.native.stop="openAllElectricAndMixedFuelMaxHelper('mixed_fuel_max_score')"
                  />
                </div>
              </div>
            </div>

            <div class="size-to-content psui-flex psui-items-center">
              <PsRichTooltip
                layout="blue"
                :ignore-dialog="!isAllElectricConstructionRequired"
                position="custom"
                :custom-position="dynamicTooltipPosition"
                @mouseenter.native="updateTooltipPosition"
              >
                <template #trigger>
                  <div
                    class="psui-flex psui-flex-row pb-2 pt-4 psui-items-center"
                    :class="!isAllElectricConstructionRequired ? 'opacity-100' : 'opacity-50 disable-interaction'"
                  >
                    <PsSwitch
                      ref="switchItem"
                      class="flex-shrink-0"
                      size="small"
                      label=""
                      background-color="psui-bg-blue-60"
                      :value="!isComplianceMarginUnique"
                      @change="toggleUniqueComplianceMargin"
                    />
                    <span
                      class="psui-text-small psui-ml-1 psui-mr-1"
                    >Require different minimum performance scores depending on fuel type</span>
                  </div>
                </template>
                <template #content>
                  <p style="font-size: 0.75rem; font-weight: 700; line-height: 120%;">
                    This option is not available<br>since you are currently requiring<br>all-electric construction.
                  </p>
                </template>
              </PsRichTooltip>
              <span
                v-if="getterUserCanEditPolicy"
                class="svg-position force-enabling-interaction psui-items-center psui-justify-center psui-flex"
              >
                <PsRichTooltip
                  css-class="w-48"
                  layout="blue"
                >
                  <template #trigger>
                    <span
                      class="cursor-pointer psui-block"
                      style="margin-top: 9px;"
                      :class="!isAllElectricConstructionRequired ? 'opacity-100' : 'opacity-50'"
                    >
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <circle
                          cx="5"
                          cy="5"
                          r="4.5"
                          fill="#EDAB3E"
                          stroke="#E0EFF6"
                        />
                      </svg>
                    </span>
                  </template>
                  <template #content>
                    <p style="font-size: 0.75rem; font-weight: 700; line-height: 1rem;">
                      Please be aware that there are ongoing legal disputes concerning setting requirements by fuel type.
                    </p>
                    <p style="font-size: 0.75rem; font-weight: 400; margin-top: 2px; line-height: 1rem;">
                      This option is provided solely for informational purposes.
                    </p>
                  </template>
                </PsRichTooltip>
              </span>
              <PsIcon
                icon="info_outline"
                size="16"
                class="psui-items-center psui-justify-center force-enabling-interaction psui-cursor-pointer psui-mt-2"
                :class="!isAllElectricConstructionRequired ? 'info-outline-icon-open-modal opacity-100' : 'opacity-50'"
                :style="{ display: 'flex', marginTop: '10px' }"
                @click.native.stop="openRequireEqualMinimumPerformanceHelper()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {formatComplianceMarginDropdown, formatComplianceMarginInfo} from './helpers'
import {TYPE_FUELS_DB_SLUGS} from "@/util/Enums"
import StudyDataApiService from '@/services/api/StudyDataApiService'
import PolicyRequirementsNewBuildingsMixin
  from '@/modules/app/policy/show/requirements/requirements-drawer/PolicyEditRequirementsNewBuildingsSF2022/PolicyRequirementsNewBuildingsMixin.js'
import EditRequirementsNewBuildingsSD2022AdminInfo from './EditRequirementsNewBuildingsSD2022AdminInfo.vue'
import {FUEL_CHOICES} from '@/business-logic/services/impact-algorithms'
import {checkIfPolicyIsOutdated} from "@/util/Helpers"
import LoaderSpinner from '@/components/general/LoaderSpinner.vue'

export default {
  name: 'EditRequirementsNewBuildingsSF2022EDR1Meta',
  components: { 
    EditRequirementsNewBuildingsSD2022AdminInfo,
    LoaderSpinner,
  },
  props: ['custom_combinations','policy'],
  mixins: [PolicyRequirementsNewBuildingsMixin],
  data: () => ({
    study_datas_all_electric: [],
    study_datas_mixed_fuel: [],
    isAllElectricConstructionRequired: false,
    isAMinimumPerformanceScoreRequired: false,
    complianceMarginMeasureIdOptionsAllElectricAndMixedFuel: [],
    dynamicTooltipPosition: 'top: 0; left: 0;',
  }),
  computed: {
    complianceMarginMeasureIdOptionsAllElectric() {
      return formatComplianceMarginDropdown(this.getStudyDataComplianceMarginOptionsAllElectric, TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC)
    },
    complianceMarginMeasureIdOptionsMixedFuel() {
      return formatComplianceMarginDropdown(this.getStudyDataComplianceMarginOptionsMixedFuel, TYPE_FUELS_DB_SLUGS.MIXED_FUEL)
    },
    getStudyDataComplianceMarginOptionsAllElectric() {
      return this.getComplianceMarginOptionsByStudyDatas(this.study_datas_all_electric)
    },
    getStudyDataComplianceMarginOptionsMixedFuel() {
      return this.getComplianceMarginOptionsByStudyDatas(this.study_datas_mixed_fuel)
    },
    getMaxComplianceMarginValueAllElectric() {
      return this.getStudyDataComplianceMarginOptionsAllElectric?.[0]?.study_data?.[this.getStudyComplianceMarginKey] ?? 0
    },
    getTheLowestValueBetweenMaximumComplianceMargins() {      
      const maximumComplianceMarginValueAllElectric = this.getTheMaximumComplianceMarginForLargerPvSystemValueAllElectric
      const maximumComplianceMarginValueMixedFuel = this.getTheMaximumComplianceMarginForLargerPvSystemValueMixedFuel
      const lowestValueBetweenAllElectricAndMixedFuelMaximumComplianceMargins = Math.min(maximumComplianceMarginValueAllElectric, maximumComplianceMarginValueMixedFuel)
      return lowestValueBetweenAllElectricAndMixedFuelMaximumComplianceMargins ?? 0
    },
    getTheMaximumComplianceMarginForLargerPvSystemValueAllElectric() {
      return this.getTheMaximumComplianceMarginForLargerPvSystemValueByStudyDatas(this.study_datas_all_electric)
    },
    getTheMaximumComplianceMarginForLargerPvSystemValueMixedFuel() {
      return this.getTheMaximumComplianceMarginForLargerPvSystemValueByStudyDatas(this.study_datas_mixed_fuel)
    },
    isReadOnlyOutdatedPolicy() {
      const policy = this.$store.getters['policy/getterUserPolicy']({id: this.$route.params.policy_id})
      const outdatedInfo = checkIfPolicyIsOutdated(policy)
      return Boolean(outdatedInfo.has_outdated_studies === true && outdatedInfo.is_loading === false && !outdatedInfo.block_policy)
    },
    getStudyComplianceMarginKey() {
      if (!this.getAllElectricCombination?.fuel?.study_id) {
        return 'compliance_margin'
      }
      const study = this.$store.getters['globalEntities/Study/getterGlobalStudy']({ id: this.getAllElectricCombination.fuel.study_id })
      return study.compliance_margin_key || 'compliance_margin'
    },
    currentRequirementUuid() {
      return `${this.getAllElectricCombination?.prototype_id || 'unknown'}_${this.getAllElectricCombination?.climate_zone_raw || 'unknown'}`
    }
  },
  watch: {
    complianceMarginMeasureIdOptionsAllElectric: function() {
      this.getComplianceMarginMeasureIdOptionsAllElectricAndMixedFuel()
    },
    complianceMarginMeasureIdOptionsMixedFuel: function() {
      this.getComplianceMarginMeasureIdOptionsAllElectricAndMixedFuel()
    },
    isComplianceMarginUnique: {
      immediate: true,
      handler(newValue) {
        if(newValue === true && this.getterUserCanEditPolicy) {
          this.getComplianceMarginMeasureIdOptionsAllElectricAndMixedFuel()
        }
      },
    },
    custom_combinations: {
      handler(value) {
        const { isAllElectricConstructionRequired, isComplianceMarginUnique } = formatComplianceMarginInfo(value)
        this.isAllElectricConstructionRequired = isAllElectricConstructionRequired
        this.isComplianceMarginUnique = isComplianceMarginUnique
      }
    },
    currentRequirementUuid: {
      immediate: false,
      async handler() {
        this.init()
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const { isAllElectricConstructionRequired, isComplianceMarginUnique } = formatComplianceMarginInfo(this.custom_combinations)

      this.isAMinimumPerformanceScoreRequired = false
      this.isAllElectricConstructionRequired = isAllElectricConstructionRequired
      this.isComplianceMarginUnique = isComplianceMarginUnique

      this.custom_combinations.forEach((item) => {
        if((item.meta.generate_virtual_compliance_margin != null && item.meta.generate_virtual_compliance_margin > 0) || item.meta.compliance_margin_value > 0){
          this.toggleRequireAMinimumPerformanceScore(false, true)
        }
      })

      this.getStudyDataByCustomCombination()
    },
    updateTooltipPosition(event) {
      const left = (event?.x || 0) - (event?.target?.getBoundingClientRect()?.x || 0)
      this.dynamicTooltipPosition = `left: ${left}px; top: 5px;`
    },
    async getStudyDataByCustomCombination() {
      const studyDatas = (await StudyDataApiService.getAllAvailableByPrototype(this.getAllElectricCombination.prototype_id, this.getAllElectricCombination.climate_zone_raw))
          .sort((a, b) => b.study_data[this.getStudyComplianceMarginKey] - a.study_data[this.getStudyComplianceMarginKey])
      this.study_datas_all_electric = studyDatas.filter(study_data => {
        const fuel = this.$store.getters['globalEntities/Fuel/getterGlobalFuel']({ id: study_data.fuel_id })
        const typeFuel = this.$store.getters['globalEntities/TypeFuel/getterGlobalTypeFuel']({ id: fuel.type_fuel_id })
        return typeFuel.slug === TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC
      })
      this.study_datas_mixed_fuel = studyDatas.filter(study_data => {
        const fuel = this.$store.getters['globalEntities/Fuel/getterGlobalFuel']({ id: study_data.fuel_id })
        const typeFuel = this.$store.getters['globalEntities/TypeFuel/getterGlobalTypeFuel']({ id: fuel.type_fuel_id })
        return typeFuel.slug === TYPE_FUELS_DB_SLUGS.MIXED_FUEL
      })
    },
    getComplianceMarginMetaRequirementValue(requirement, origin) {
      const key = 'compliance_margin_measure_id'
      let metaValue = requirement?.meta?.[key]
      let searchArray

      switch(origin) {
        case 'allElectric':
          searchArray = this.complianceMarginMeasureIdOptionsAllElectric
          break
        case 'mixedFuel':
          searchArray = this.complianceMarginMeasureIdOptionsMixedFuel
          break
        case 'allElectricAndMixedFuel':
          searchArray = this.complianceMarginMeasureIdOptionsAllElectricAndMixedFuel
          break
        default:
          searchArray = []
      }

      if (this.isComplianceMarginUnique) {
        const mixedFuelMetaValue = this.getMixedFuelCombination?.meta?.[key]
        if (mixedFuelMetaValue && !metaValue && searchArray.map((v) => +v.value).includes(+mixedFuelMetaValue)) {
          return mixedFuelMetaValue
        }
      }

      if ((!requirement?.meta?.compliance_margin_value && !requirement?.meta?.compliance_margin_measure_id) || !searchArray.map((v) => +v.value).includes(+metaValue)) {
        metaValue = undefined
      }

      return metaValue === undefined ? searchArray.findInArray({ default: true }).value : metaValue
    },
    getComplianceMarginMetaRequirement(requirement, origin) {
      const value = this.getComplianceMarginMetaRequirementValue(requirement, origin)
      let searchArray

      switch(origin) {
        case 'allElectric':
          searchArray = this.complianceMarginMeasureIdOptionsAllElectric
          break
        case 'mixedFuel':
          searchArray = this.complianceMarginMeasureIdOptionsMixedFuel
          break
        case 'allElectricAndMixedFuel':
          searchArray = this.complianceMarginMeasureIdOptionsAllElectricAndMixedFuel
          break
        default:
          searchArray = []
      }

      const optionByValue = searchArray.findInArray({ value })
      const defaultOption = searchArray.findInArray({ default: true })

      return value ? optionByValue : defaultOption
    },
    setComplianceMarginMetaRequirement(requirement, value, origin) {
      const key = 'compliance_margin_measure_id'

      this.$refs?.[`compliance_margin_measure_id_dropdown_${requirement.id}`]?.close()

      const isMixedFuelMeasure = Boolean(value?.compliance_margin_measure_id &&
        !!this.complianceMarginMeasureIdOptionsMixedFuel.find((i) => i?.compliance_margin_measure_id === value?.compliance_margin_measure_id)
      )

      let maximumComplianceMarginForLargerPvSystemValue
      switch(origin) {
        case 'allElectric':
          maximumComplianceMarginForLargerPvSystemValue = this.getTheMaximumComplianceMarginForLargerPvSystemValueAllElectric
          break
        case 'mixedFuel':
          maximumComplianceMarginForLargerPvSystemValue = this.getTheMaximumComplianceMarginForLargerPvSystemValueMixedFuel
          break
        default:
          maximumComplianceMarginForLargerPvSystemValue = null
      }

      this.onUpdateRequirements({
        requirement,
        key,
        value,
        meta : {
          maximumComplianceMarginForLargerPvSystemValue,
          isMixedFuelMeasure,
        }
      })
    },
    setFuelChoiceMetaRequirement(value) {      
      this.onUpdateRequirements({
        requirement: this.getAllElectricCombination,
        key: 'fuel_choice',
        value,
        meta: null
      })
    },
    openNewBuildingsDuplicateModal(){
      const policy = this.$store.getters['policy/getterUserPolicy']({id: this.$route.params.policy_id})
      if(this.getterUserCanEditPolicy) return
      let args = { policy }
      if (this.isReadOnlyOutdatedPolicy) {
        args = {... args,
          disableUserLoginCall2Action: true,
          disableBtns: true,
          customTitle: `You can't edit this policy`,
          customDescription: `This policy is read-only since it is based on an outdated policy architecture and cost-effectiveness study results. Please create a new policy to continue policy development.`
        }
      }
      this.$eventBus.$emit('openPolicyUserPermissionDuplicateAlertModal', args)
    },
    getComplianceMarginMeasureIdOptionsAllElectricAndMixedFuel(){
      if(this.isComplianceMarginUnique) {
        const complianceMarginAllElectric = this.complianceMarginMeasureIdOptionsAllElectric
        const complianceMarginMixedFuel = this.complianceMarginMeasureIdOptionsMixedFuel
        const combinedArray = complianceMarginAllElectric.concat(complianceMarginMixedFuel)
        const filteredArray = combinedArray.filter((v,i,a) => a.findIndex(t => (t.compliance_margin_value === v.compliance_margin_value)) === i || +v.compliance_margin_value !== 0)
        const modifiedArray = filteredArray.map(item => {
          if (item.compliance_margin_value === 0) {
            return {...item, right_label: 'State Performance Minimum'}
          }
          return item
        })
        const lowestValueBetweenAllElectricAndMixedFuelMaximumComplianceMargins = this.getTheLowestValueBetweenMaximumComplianceMargins
        const sortedArray = modifiedArray.sort((a, b) => b.compliance_margin_value - a.compliance_margin_value)
        this.complianceMarginMeasureIdOptionsAllElectricAndMixedFuel = sortedArray.map(item => {
          if (item.compliance_margin_value > lowestValueBetweenAllElectricAndMixedFuelMaximumComplianceMargins) {
            return {
              ...item,
              disabled: true,
              tooltip: `
                <p style="font-size: 0.75rem; font-weight: 700; line-height: 1rem;">This performance score exceeds the max you can require for mixed fuel buildings.</p>
                <p style="font-size: 0.75rem; font-weight: 400; margin-top: 2px; line-height: 1rem;">To require different performance scores for each fuel type, enable the toggle to the left.</p>
              `
            }
          }
          return {...item, disabled: false}
        })
      }
      return []
    },
    getComplianceMarginOptionsByStudyDatas(studyDatas) {
      const enableAll = this.getStudyComplianceMarginKey !== 'compliance_margin'
      let studyDataWithoutPreEmptivesAndLargerPvIncluded = studyDatas
        .filter((study_data) => {
          return study_data?.data?.[this.getStudyComplianceMarginKey] && study_data?.data?.[this.getStudyComplianceMarginKey] > 0 &&
            study_data?.measure?.hide_in_compliance_margin_control !== true && study_data.isCostEffective &&
            (enableAll || !study_data.measure.has_larger_pv_included)
        })

      let compliance_margin_values = new Set([... studyDataWithoutPreEmptivesAndLargerPvIncluded.map(sd => sd.study_data[this.getStudyComplianceMarginKey] )])

      const maxCM = Math.max(...[...compliance_margin_values])
      studyDataWithoutPreEmptivesAndLargerPvIncluded = studyDataWithoutPreEmptivesAndLargerPvIncluded.filter((study_data) => {
        return !study_data.measure.is_pre_emptive || (study_data?.data?.[this.getStudyComplianceMarginKey] || 0) < maxCM
      })
      compliance_margin_values = new Set([... studyDataWithoutPreEmptivesAndLargerPvIncluded.map(sd => sd.study_data[this.getStudyComplianceMarginKey] )])

      let study_datas_with_unique_compliance_margin = []
      for(let compliance_margin_value of compliance_margin_values) {
        const studyDataWithSameComplianceMargin = studyDataWithoutPreEmptivesAndLargerPvIncluded.filter(sd => +sd.study_data[this.getStudyComplianceMarginKey] === +compliance_margin_value)
        studyDataWithSameComplianceMargin.sort((a,b) => a.study_data.initial_cost - b.study_data.initial_cost)
        study_datas_with_unique_compliance_margin.push(studyDataWithSameComplianceMargin[0])
      }

      return study_datas_with_unique_compliance_margin
    },
    getTheMaximumComplianceMarginForLargerPvSystemValueByStudyDatas(studyDatas) {
      const enableAll = this.getStudyComplianceMarginKey !== 'compliance_margin'
      const study_datas = studyDatas.filter(study_data => {
        return !study_data.measure.is_pre_emptive &&
            study_data?.data?.[this.getStudyComplianceMarginKey] && study_data?.data?.[this.getStudyComplianceMarginKey] > 0 &&
            study_data?.measure?.hide_in_compliance_margin_control !== true && study_data.isCostEffective &&
            (enableAll || study_data.measure.has_larger_pv_included)
      })
      return study_datas?.[0]?.study_data?.[this.getStudyComplianceMarginKey] ?? null
    },
    toggleRequireAllElectricConstruction() {
      if (!this.getterUserCanEditPolicy) {
        return
      }

      this.isAllElectricConstructionRequired = !this.isAllElectricConstructionRequired
      if(this.isAllElectricConstructionRequired) {
        this.isComplianceMarginUnique = true
        this.setFuelChoiceMetaRequirement(FUEL_CHOICES.REQUIRED)
      } else {
        this.setFuelChoiceMetaRequirement(FUEL_CHOICES.ALLOWED)
        if (this.isComplianceMarginUnique) {
          const currentCM = (+this.getComplianceMarginMetaRequirement(this.getAllElectricCombination, 'allElectricAndMixedFuel')?.compliance_margin_value || 0)
          if (currentCM > (+this.getTheLowestValueBetweenMaximumComplianceMargins || 0)) {
            this.resetComplianceMargins()
          }
        }
      }
    },  
    toggleRequireAMinimumPerformanceScore(isAMinimumPerformanceScoreRequired, force = false) {
      if (!this.getterUserCanEditPolicy && !force) {
        return
      }
      this.isAMinimumPerformanceScoreRequired = !isAMinimumPerformanceScoreRequired
      if (this.isAMinimumPerformanceScoreRequired && !this.isComplianceMarginUnique && !force) {
        this.toggleUniqueComplianceMargin()
      } else if (!this.isAMinimumPerformanceScoreRequired && !force) {
        this.resetComplianceMargins()
      }
    },
    openRequireEqualMinimumPerformanceHelper(origin, slug) {
      if(origin === 'differentMinimumPerformanceScores' && this.isAllElectricConstructionRequired) return 
      this.$eventBus.$emit('openDescriptionModal', {
        type: 'helper',
        slug: slug || 'require_equal_minimum_performance',
        // button: {
        //   title: 'More Info',
        //   url: 'https://intercom.help/explorer-local-energy-codes/en/articles/6619711-adus',
        // },
        disableCloseButton: true,
        buttonLayout: 'solid',
      })
    },
    openAllElectricAndMixedFuelMaxHelper(slug) {
      this.$eventBus.$emit('openDescriptionModal', {
        type: 'helper',
        slug: slug || 'all_electric_and_mixed_fuel_max_score',
        disableCloseButton: false,
      })
    },
  },
}
</script>

<style lang="scss" scoped>

.disable-interaction, .disable-interaction * {
  pointer-events: none !important;
}

.force-enabling-interaction, .force-enabling-interaction * {
  pointer-events: all !important;
}

.info-outline-icon-show-tooltip {
  color: #798490; // psui-text-gray-50
}

.info-outline-icon-show-tooltip:hover {
  color: #A2ACB7; // psui-text-gray-40
}

.info-outline-icon-open-modal {
  color: #798490; // psui-text-gray-50

  &:hover {
    color: #318FAC; // psui-text-blue-60  
  }
}

.report-icon {
  color: #EDAB3E; // psui-text-yellow-20
}

.report-icon:hover {
  color: #FFC057; 
}

.vertical-divider {
  border-left: 1px solid #64B5CE;
  height: 1.5rem;
}

.size-to-content {
  width: fit-content;
  height: fit-content;
}

@mixin font-lato($size, $weight: 400) {
  font-family: 'Lato';
  font-size: $size;
  font-style: normal;
  font-weight: $weight;
  line-height: 130%;
}

@mixin flex-center($gap: 8px) {
  display: flex;
  align-items: center;
  gap: $gap;
}

.icons {
  @include flex-center;
  padding: 6px;
  border-radius: 2px;
  background: #ECF7FB;
}

.title {
  color: #00465F;
  @include font-lato(14px);
}

.subtitle {
  color: #515E6A; // gray-60
  @include font-lato(14px);
}

.psui-el-dropdown-menu-compliance {
  width: 13.313rem;
  height: 2rem;
}

.pl-9 {
  padding-left: 2.125rem;
}

.py-2-5 {
  padding-top: 0.625rem; /* 10px */
  padding-bottom: 0.625rem; /* 10px */
}

::v-deep .psui-el-dropdown-menu-dialog-wrapper {
  margin-top: 4px;
}

::v-deep .psui-el-tooltip .psui-el-tooltip-wrapper .psui-el-tooltip-dialog {
  z-index: 20;
}

::v-deep .psui-el-tooltip .psui-el-tooltip-wrapper .psui-el-tooltip-dialog .psui-el-tooltip-content.layout-blue .psui-el-tooltip-content-wrapper {
  margin-top: 0;
}

.svg-position {
  ::v-deep .psui-el-tooltip-trigger {
    margin-right: calc(0.313rem + 1px); // 5px
    margin-bottom: 0.125rem; // 2px
    margin-left: -0.094rem; // 1.5px
  }
}

.loader-spinner {
  display: flex;
  align-items: center;

  ::v-deep .spin-element {
    position: static;
    display: inline-flex;
    padding: 0;
    margin: 0;
    width: 14px;
    height: 14px;

    svg path {
      fill: #798490; // gray-50
    }
  }
}
</style>
