<template>
  <div class="psui-w-full psui-bg-white psui-shadow-elevation-5 psui-rounded-md psui-pb-4 psui-mb-4 overflow-y-auto">
    <div class="psui-w-full psui-flex psui-items-center psui-bg-white psui-pt-4 psui-pb-3 psui-sticky psui-z-10 psui-top-0 psui-shadow-elevation-10">
      <div class="psui-w-2/5 psui-max-w-lg psui-pl-4">
        <h3 class="psui-font-bold psui-text-gray-80 opacity-100-all-childrens-on-hover flex items-center">
          {{ 'Policy Name' | policyTextRephrase }}
          <button
            class="focus:outline-none ml-2 w-4 h-4 flex items-center justify-center"
            :class="{ 'opacity-0' : orderByColumn != 'title' }"
          >
            <feather
              size="16"
              :type="orderByDirection == 'asc' ? 'arrow-down' : 'arrow-up'"
              class="text-blue cursor-pointer"
              @click="setOrderColumn('title')"
            />
          </button>
        </h3>
      </div>

      <div class="psui-w-1/5">
        <h3 class="psui-font-bold psui-text-gray-80 opacity-100-all-childrens-on-hover flex items-center">
          City/County
          <button
            class="focus:outline-none ml-2 w-4 h-4 flex items-center justify-center"
            :class="{ 'opacity-0' : orderByColumn != 'jurisdiction_title' }"
          >
            <feather
              size="16"
              :type="orderByDirection == 'asc' ? 'arrow-down' : 'arrow-up'"
              class="text-blue cursor-pointer"
              @click="setOrderColumn('jurisdiction_title')"
            />
          </button>
        </h3>
      </div>
      <div class="psui-w-1/6">
        <h3 class="psui-font-bold psui-text-gray-80 opacity-100-all-childrens-on-hover flex items-center">
          Last Update
          <button
            class="focus:outline-none ml-2 w-4 h-4 flex items-center justify-center"
            :class="{ 'opacity-0' : orderByColumn != 'updated_at' }"
          >
            <feather
              size="16"
              :type="orderByDirection == 'asc' ? 'arrow-down' : 'arrow-up'"
              class="text-blue cursor-pointer"
              @click="setOrderColumn('updated_at')"
            />
          </button>
        </h3>
      </div>
      <div class="psui-w-1/6">
        <h3 class="psui-font-bold psui-text-gray-80 opacity-100-all-childrens-on-hover flex items-center">
          Type
          <button
            class="focus:outline-none ml-2 w-4 h-4 flex items-center justify-center"
            :class="{ 'opacity-0' : orderByColumn != 'type' }"
          >
            <feather
              size="16"
              :type="orderByDirection == 'asc' ? 'arrow-down' : 'arrow-up'"
              class="text-blue cursor-pointer"
              @click="setOrderColumn('type')"
            />
          </button>
        </h3>
      </div>
      <div class="psui-w-1/5 mr-4">
        <h3 class="psui-font-bold psui-text-gray-80 opacity-100-all-childrens-on-hover flex items-center">
          Author
          <button
            class="focus:outline-none ml-2 w-4 h-4 flex items-center justify-center"
            :class="{ 'opacity-0' : orderByColumn != 'author' }"
          >
            <feather
              size="16"
              :type="orderByDirection == 'asc' ? 'arrow-down' : 'arrow-up'"
              class="text-blue cursor-pointer"
              @click="setOrderColumn('author')"
            />
          </button>
        </h3>
      </div>
      <div class="psui-w-auto">
        <i class="psui-icon psui-text-white">more_horiz</i>
      </div>
    </div>    
    <div 
      v-for="(policy, index) in policiesOrdered"
      :key="`${index}-${policy.id}`"
      class="list-policy psui-w-full psui-flex psui-items-center hover:psui-text-blue-60 py-4 psui-border-b psui-border-gray-20 last:border-b-0 actions-wrapper hover:psui-bg-blue-10 transition-all"
      :class="[isPolicyBlocked(policy) ? 'psui-text-gray-40 cursor-default' : 'psui-text-gray-60 cursor-pointer']"
      @click="goToMyPolicy(policy)"
    >
      <div
        class="ps-button psui-w-2/5 psui-max-w-lg psui-pl-4 psui-flex psui-items-center psui-cursor-pointer" 
        :class="{'cursor-default':isPolicyBlocked(policy)}"
      >
        <PsRichTooltip
          v-if="checkIfNeedShowPolicyTitleTooltip(policy.title)"
          layout="blue"
        >
          <template #trigger>
            <h3 
              class="policy-title psui-cursor-pointer"
            >
              {{ policy.title }}
            </h3>
          </template>
          <template #content>
            <span class="psui-font-bold psui-break-words psui-block psui-mb-1">
              {{ policy.title }}
            </span>
          </template>
        </PsRichTooltip>
        <h3
          v-else
          class="policy-title psui-cursor-pointer"
          :class="{'cursor-default psui-text-':isPolicyBlocked(policy)}"
        >
          {{ policy.title }}
        </h3>
        <PsRichTooltip
          v-if="isPolicyBlocked(policy)" 
        >
          <template #trigger>
            <PsIcon
              icon="info"
              size="14"
              class="ps-icon psui-text-gray-40 text-opacity-0 psui-cursor-pointer psui-items-center psui-justify-center psui-pl-2"
              :style="{ display: 'flex' }"
              @click.stop.native="openOutdatedPolicyModal()"
            />
          </template>
          <template #content>
            Outdated Data
          </template>
        </PsRichTooltip>
      </div>

      <div class="psui-w-1/5">
        <h3>
          <PsTooltip 
            :ignore-dialog="policy.jurisdiction.type !== JURISDICTION_TYPES.COUNTY && policy.jurisdiction.type !== JURISDICTION_TYPES.CCA && policy.jurisdiction.type !== JURISDICTION_TYPES.REN && policy.jurisdiction.type !== JURISDICTION_TYPES.COG_CAG && policy.jurisdiction.type !== JURISDICTION_TYPES.UTILITY && policy.jurisdiction.type !== JURISDICTION_TYPES.AQMD"
            :class="{ 'cursor-pointer': policy.jurisdiction.type === JURISDICTION_TYPES.COUNTY || policy.jurisdiction.type === JURISDICTION_TYPES.CCA || policy.jurisdiction.type === JURISDICTION_TYPES.REN || policy.jurisdiction.type === JURISDICTION_TYPES.COG_CAG || policy.jurisdiction.type === JURISDICTION_TYPES.UTILITY || policy.jurisdiction.type === JURISDICTION_TYPES.AQMD }"
          >
            <template #trigger>
              {{ policy.jurisdiction | editJurisdictionTitleTypeBasedOnJurisdictionType('title_type', true) }}
            </template>
            <template #content>
              {{ getTooltip(policy.jurisdiction) | policyTextRephrase }}
            </template>
          </PsTooltip>
        </h3>
      </div>
      <div class="psui-w-1/6 psui-flex psui-items-center ">
        <h3 
          :data-test-id="`policies-list-table-updated_at-${policy.id}`"
        >
          {{ formatDate({ date: policy.updated_at}) }}
        </h3>      
      </div>
      <div class="psui-w-1/6">
        <h3>
          {{ $store.getters['policy/getterPolicyStudyTypes']({key: 'title', policy }).join(', ') }}
        </h3>
      </div>

      <div class="psui-w-1/5 flex items-center">
        <h3>
          {{ $store.getters['policy/getterPolicyTypeLabel'](policy.id) }}
        </h3>

        <div
          class="ml-6 flex justify-end"
        >
          <PsButton
            v-if="getPolicyType(policy.id) === POLICY_TYPES.DRAFT.value" 
            label="Save"
            layout="solid"
            size="small"
            class="psui-w-20"
            @click.stop="$eventBus.$emit('openModalUserSign')"
          />
          <PsButton 
            v-else-if="getPolicyType(policy.id) === POLICY_TYPES.SHARED.value"
            label="Duplicate"
            layout="ghost"
            size="small"
            class="duplication-button psui-w-20"
            @click.stop="$eventBus.$emit('openDuplicatePolicyModal', { policy: policy })"
          />
        </div>
      </div>
      
      <div
        class="psui-w-auto psui-flex psui-items-center psui-pr-4"
        :class="!isPolicyTypeOwn(policy) ? 'invisible' : 'visible'"
      >
        <PoliciesListTableUserActions
          :policy="policy"
          :is-policy-outdated="isPolicyBlocked(policy)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PoliciesListTableUserActions from './PoliciesListTableUserActions.vue'
import { POLICY_TYPES } from '../shared/enums.js'
import { JURISDICTION_TYPES } from '@/util/Enums.js'
import { checkIfPolicyIsOutdated } from '@/util/Helpers'

export default {
  name: 'PoliciesListTable',
  components: { PoliciesListTableUserActions },
  props: ['policies', 'filter', 'is-loading'],
  data: () => ({
    POLICY_TYPES,
    JURISDICTION_TYPES,
    checkIfPolicyIsOutdated
  }),
  computed: {
    orderByColumn: {
      get() {
        return this.$store.state.auth.user_preferences?.policies?.myPolicies?.orderByColumn ?? 'title'
      },
      set(value) {
        this.$store.dispatch('setUserPreferences', { payload: { path: 'policies.myPolicies.orderByColumn', value } })
      }
    },
    orderByDirection: {
      get() {
        return this.$store.state.auth.user_preferences?.policies?.myPolicies?.orderByDirection ?? 'asc'
      },
      set(value) {
        this.$store.dispatch('setUserPreferences', { payload: { path: 'policies.myPolicies.orderByDirection', value } })
      }
    },  
    getUser() {
      return this.$store.state.auth.loggedUser
    },
    policiesOrdered() {
      
      return this.policies.map(item => item)
      .sort((a, b) => {
        const valueA = this.getValueToOrderByColumnType(a)
        const valueB = this.getValueToOrderByColumnType(b) 
        return ((valueA?.toLowerCase() || '') < (valueB?.toLowerCase() || '') ? -1 : 1) * (this.orderByDirection == 'asc' ? 1 : -1)
      })
    },
    getAllUserPolicies() {
      return this.$store.getters['policy/getterUserPolicies']()
    },
  },
  methods: {
    setOrderColumn(column) {
      if(this.orderByColumn == column) {
        this.orderByDirection = (this.orderByDirection == 'asc') ? 'desc' : 'asc'
      } else {
        this.orderByColumn = column
        this.orderByDirection = 'asc'
      }
    },
    isPolicyBlocked(policy){
      return checkIfPolicyIsOutdated(policy).block_policy && checkIfPolicyIsOutdated(policy).has_outdated_studies  
    },
    getValueToOrderByColumnType(value){
      const columnFunction = {
        jurisdiction_title: this.parsejurisdictionAsFullText(value, true),
        title: value[this.orderByColumn],
        updated_at: value[this.orderByColumn],
        type: this.getterPolicyTypeLabel(value, value.id),
        author: this.getPolicyType(value),
      }
      return columnFunction[this.orderByColumn]
    },
    getPolicyType(policy_id){
      return this.$store.getters['policy/getterPolicyType'](policy_id)
    },
    getterPolicyTypeLabel(policy,policy_id){
      return this.$store.getters['policy/getterPolicyStudyTypes']({policy,policy_id}).pop()
    },
    goToMyPolicy(policy){
      if(!checkIfPolicyIsOutdated(policy).block_policy) this.$router.push({name:'PolicyShow', params:{ policy_id: policy.id}})      
    },
    openOutdatedPolicyModal() {
        this.$eventBus.$emit('openDescriptionModal', {
        type: 'helper',
        slug: 'outdated_data'
      })
    },
    isPolicyTypeOwn(policy) {
      return this.$store.getters['policy/getterPolicyType'](policy.id) === POLICY_TYPES.OWN.value
    },
    checkIfNeedShowPolicyTitleTooltip(title) {
      return (title.length > 50) ? true : false
    },
    getTooltip(jurisdiction){
      if(jurisdiction.titles.type === JURISDICTION_TYPES.COUNTY) {
          return jurisdiction.titles.type_long
      } else  {
        return jurisdiction.titles.title
      }
    }
  }
}
</script>

<style scoped lang="scss">
  ::v-deep .psui-el-tooltip {
    .psui-el-tooltip-wrapper {
      .psui-el-tooltip-dialog {
        width: 180px;
        left: inherit !important;
        z-index: 50;

        .psui-el-tooltip-content {
          span {
            line-height: 120%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }

  .list-policy {
    .policy-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media (max-width: 640px) {
        max-width: 6.25rem;
      }
      @media (min-width: 641px) and (max-width: 1024px) {
        max-width: 10rem;
      }
      @media (min-width: 1025px) and (max-width: 1280px) {
        max-width: 15rem;
      }
      @media (min-width: 1281px) and (max-width: 1600px) {
        max-width: 20rem;
      }
      @media (min-width: 1601px) {
        max-width: 30rem;
      }
    }

    &:hover .policy-title {
      color: #318FAC !important;
    }
  }

  .ps-button {
    
    line-height: 1;
    ::v-deep {
      .psui-el-tooltip .psui-el-tooltip-wrapper .psui-el-tooltip-dialog .psui-el-tooltip-content {
        padding: 0.5rem 1rem;
      }
      .psui-el-tooltip .psui-el-tooltip-wrapper .psui-el-tooltip-dialog .psui-el-tooltip-content.layout-gray .psui-el-tooltip-content-wrapper {
        margin-top: 0;
        line-height: 1;
      }
    }

    .ps-icon {
      padding-top: 0.125rem;
    }
  }
</style>
