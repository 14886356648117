<template>
  <div 
    id="policy-index"
    class="psui-w-full psui-h-full psui-flex psui-flex-col psui-overflow-y-auto psui-bg-gray-10"
  >
    <div
      :is="getHeaderComponent"
      class="psui-sticky psui-top-0 psui-bg-white psui-mb-6 psui-shadow-elevation-5"
    />
    <PolicyHeaderUserWarnings />
    <router-view />
    <ModalDeletePolicy />
    <ModalDuplicatePolicy />
    <PolicyUserPermissionDuplicateAlertModal @close="$emit('close')" />
    <PolicyEditRequirementsDrawer />
    <DrawerPolicyEditColumns />
  </div>
</template>

<script>
import PolicyHeaderUserWarnings from './PolicyHeaderUserWarnings.vue'
import ModalDeletePolicy from '@/modules/app/policy/shared/ModalDeletePolicy'
import PolicyEditRequirementsDrawer from '@/modules/app/policy/show/requirements/requirements-drawer/PolicyEditRequirementsDrawer.vue'


export default {
  name: 'PoliciesIndex',
  components: { 
    ModalDeletePolicy,
    PolicyHeaderUserWarnings,
    PolicyEditRequirementsDrawer
  },
  computed:{
    getHeaderComponent(){
      return this.$route?.meta?.header
    }
  },
  metaInfo() {
    const title = this.$route.name === 'PolicyOptions' ? 'Policy Options | ' : 'My Policies | '
    const rephrasedTitle = this.$options.filters.policyTextRephrase(title)
    return {
      title: rephrasedTitle
    }
  },
  mounted() {
    this.$store.commit('general/setSidebarPolicyTooltipShouldHide', true)
  }
}
</script>