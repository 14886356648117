<template>
  <PsDraggable
    v-if="columnTabSelectedKey && getColumnTabJurisdictionWideFormated"
    :get-columns="getColumnTabJurisdictionWideFormated"
    @drag-start-group="dragStartGroup"
    @drag-end-group="dragEndGroup"
    @drag-over-group="dragOverGroup"
    @drag-start-item="dragStartItem"
    @drag-end-item="dragEndItem"
    @drag-over-item="dragOverItem"
    @on-select-item="toggleColumnIsActive"
    @column-helper="$eventBus.$emit('openDescriptionModal', {type: $event.type, slug: $event.slug, button:$event.customButton})"
  />
</template>

<script>
export default {
  name: 'UserPreferencesDragAndDropEditColumnsList',
  props: ['columnTabSelectedKey', 'userPreferencesColumnTabsKey'],
  data() {
    return {
      // Column
      isDraggingColumnGroup: -1,
      isDraggingOverColumn: -1,
      isDraggingColumn: -1,

      // Group
      isDraggingGroup: -1,
      isDraggingOverGroup: -1,
    }
  },

  computed: {
    getColumnTabs() {
      return this.userPreferencesColumnTabsKey ? this.$store.getters['getterUserPreference'](this.userPreferencesColumnTabsKey) : false
    },
    getColumnTab() {
      return this.getColumnTabs ? this.getColumnTabs[this.columnTabSelectedKey] : false
    },
    getColumnTabJurisdictionWideFormated() {
      if (!this.getColumnTab) return false
      let columnTab = this.getColumnTab
      columnTab.columnGroups = columnTab.columnGroups.map(columnGroup => {
        columnGroup.title = this.jurisdictionFormat(columnGroup.title)
        return columnGroup
      })
      return columnTab
    }
  },

  methods: {

    updateUserPreferenceData(data) {
      this.$store.dispatch('setUserPreferenceData', { path : this.userPreferencesColumnTabsKey, data })
    },

    toggleColumnIsActive({ studyKey, indexColumnGroup, indexColumn }) {      
      const columnTabs = this.getColumnTabs
      columnTabs[studyKey].columnGroups[indexColumnGroup].columns[indexColumn].isActive = !columnTabs[studyKey].columnGroups[indexColumnGroup].columns[indexColumn].isActive
      this.updateUserPreferenceData(columnTabs)
    },

    /* Drag and Drop Columns */
    /* ----------------------------------------- */            
      dragStartItem({ indexColumnGroup, indexColumn }, event) {
        this.isDraggingColumn = indexColumn
        this.isDraggingColumnGroup = indexColumnGroup
        event.target.classList.add("on-dragging")
        event.dataTransfer.dropEffect = "move"
      },
      dragEndItem(event) {
        const indexColumnGroup = this.isDraggingColumnGroup,
              from = this.isDraggingColumn,
              to = this.isDraggingOverColumn,
              columnTabs = this.getColumnTabs

        columnTabs[this.columnTabSelectedKey].columnGroups[indexColumnGroup].columns.splice(to, 0, columnTabs[this.columnTabSelectedKey].columnGroups[indexColumnGroup].columns.splice(from, 1)[0])
        this.updateUserPreferenceData(columnTabs)

        this.blinkItems({ indexColumnGroup, to })
        this.dragLeaveItem()
        this.dragLeaveGroup()
        event.target.classList.remove("on-dragging")
        event.target.classList.remove("on-drag-over")
      },      
      dragOverItem({ indexColumnGroup, indexColumn }) {
        if(this.isDraggingColumnGroup != indexColumnGroup) return
        this.isDraggingOverColumn = indexColumn

        const colToDragginOut = document.getElementById(`edit-columns-column-${indexColumnGroup}-${indexColumn}`)
        if(colToDragginOut) {
          colToDragginOut.classList.add('is-dragging-out')
          setTimeout(() => colToDragginOut.classList.remove('is-dragging-out'), 10)
        }
      },
      dragLeaveItem() {
        this.isDraggingColumn = -1
        this.isDraggingColumnGroup = -1
        this.isDraggingOverColumn = -1
      },
      blinkItems({ indexColumnGroup, to }) {
        const colToBlink = document.getElementById(`edit-columns-column-${indexColumnGroup}-${to}`)
        if(colToBlink) {
          colToBlink.classList.add('blink')
          setTimeout(() => colToBlink.classList.remove('blink'), 800)
          colToBlink.classList.remove('on-drag-over')
        }
      },
    /* ----------------------------------------- Drag and Drop Columns */

    /* Drag And Drop Column Groups */
    /* ----------------------------------------- */
      dragStartGroup({ indexColumnGroup }) {
        this.isDraggingGroup = indexColumnGroup
      },
      dragEndGroup() {        
        let columnTabs = this.getColumnTabs
        const from = this.isDraggingGroup
        const to = this.isDraggingOverGroup
        columnTabs[this.columnTabSelectedKey].columnGroups.splice(to, 0, columnTabs[this.columnTabSelectedKey].columnGroups.splice(from, 1)[0])
        this.updateUserPreferenceData(columnTabs)
        this.dragLeaveGroup()
      },
      dragOverGroup(indexColumnGroup) {
        if(this.isDraggingGroup < 0) return
        this.isDraggingOverGroup = indexColumnGroup + 1
        const colToDragginOut = document.getElementById(`edit-group-${indexColumnGroup}`)
        if(colToDragginOut) {
          colToDragginOut.classList.add('is-dragging-out')
          setTimeout(() => colToDragginOut.classList.remove('is-dragging-out'), 800)
        }
      },
      dragLeaveGroup() {
        this.isDraggingGroup = -1
        this.isDraggingOverGroup = -1
      },
    /* ----------------------------------------- Drag And Drop Column Groups */          
  }
}
</script>≈