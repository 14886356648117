<template>
  <DashboardCard
    title="Searchs locations"
  >
    <div class=" psui-flex psui-flex-col psui-justify-between">
      <div class="psui-w-full psui-flex psui-justify-end mb-6">
        <PsCardInfos 
          title="Total"
          :total="formatUnits(getTotal)"
        />
      </div>

      <div
        class="psui-block"
        style="height: 300px;"
      >
        <div ref="chartExistingUnits" />
        <ApexChartGeneral 
          :options="getChartOptions()"
        />
      </div>

      <div class="psui-flex psui-mx-auto psui-space-x-2">
        <PsChartLegend
          v-for="(item, index) in getMappedItems" 
          :key="item.title"
          class="cursor-pointer"
          :text="item.title"
          :total="formatUnits(item.total)"
          :percentage="`${formatNumber(getPercentage(item.total))}`"
          :dot-color="{ backgroundColor: colors[index] }"
          @click.native="setSelectedList(index)"
        />
      </div>
    </div>

    <div
      class="psui-w-full psui-p-6 psui-flex psui-items-center psui-space-x-3 psui-relative cursor-pointer"
      @click.stop="showTable = !showTable"
    >
      <span 
        class="btn-arrow material-icons-round psui-text-gray-50 psui-flex-none transition-all psui-transform psui-cursor-pointer"
        :class="showTable ? 'open psui-rotate-90' : 'closed psui-rotate-0'"
      >
        chevron_right
      </span>
      
      <div class="psui-w-full psui-flex psui-flex-col">
        <h3 class="psui-text-gray-80 psui-text-h5 psui-font-bold">
          {{ getMappedItems[selectedListIndex].title }}
        </h3>
      </div>
    </div>

    <div
      v-if="showTable"
      class="w-full mt-4"
    >
      <table class="table table-sm">
        <thead>
          <tr>
            <th>Country</th>
            <th>Region</th>
            <th>City</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, indexHere) in getMappedItems[selectedListIndex].items"
            :key="`listItem_${indexHere}`"
          >
            <td>{{ item.country }}</td>
            <td>{{ item.region }}</td>
            <td>{{ item.city }}</td>
            <td>{{ item.count }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </DashboardCard>
</template>

<script>
import { colors } from '@/business-logic/constants/chartDefaultOptions'
import { MathHelpers } from '@igortrindade/lazyfy'
export default {
  name: 'SearchsLocation',
  props: ['timeFrameSelected', 'jurisdictionSelected'],
  data() {
    return {
      platform_users: [],
      non_logged_users: [],
      admin_users: [],
      selectedListIndex: 0,
      colors,
      showTable: false
    }
  },
  computed: {
    getTotal() {
      return this.getMappedItems.reduce((acc, i) => acc + i.total, 0)
    },
    getMappedItems() {
      return [ 
        {
          title: "Unlogged users",
          total: this.non_logged_users.reduce((acc, i) => acc + parseInt(i.count), 0),
          items: this.non_logged_users
        },
        {
          title: "Platform users",
          total: this.platform_users.reduce((acc, i) => acc + parseInt(i.count), 0),
          items: this.platform_users
        }
      ]
    }
  },
  watch: {
    timeFrameSelected: {
      deep: true,
      handler() {
        this.getData()
      } 
    },
    '$route.path': {
      deep: true,
      handler() {
        this.getData()
      } 
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$api.get('/api/admin/city/dashboard/get_data/searchs_from', { params: {
        init: this.timeFrameSelected.init, 
        end: this.timeFrameSelected.end,
        jurisdiction_id: this.$route.params.jurisdiction_id
      }})
        .then(({ data }) => {
          this.platform_users = data.platform_users
          this.non_logged_users = data.non_logged_users
          this.admin_users = data.admin_users
        })
    },

    getChartOptions() {
      const self = this
      return {
        series: this.getMappedItems.map((item) => MathHelpers.getValueOrMinPercentage(this.getTotal, item.total, 1) ),
        colors: colors,
        chart: {
          type: 'donut',
          toolbar: { show: false, },
        },
        tooltip: {
          followCursor: false,
          fixed: {
            enabled: true,
            position: "topRight",
            offsetX: 0,
            offsetY: 0,
          },
          custom: function ({ seriesIndex }) {
            const item = self.getMappedItems[seriesIndex]
            return `
              <div class="psui-flex" style="background-color: ${ colors[seriesIndex] } !important;">
                <div class="apexcharts-tooltip-content psui-w-full psui-bg-white psui-p-4">
                  <h4 class="psui-text-gray-80 psui-text-xsmall psui-font-bold">${ item.title }</h4>
                  <div class="psui-w-full psui-flex psui-items-center">
                    <h2 class="psui-text-gray-80 psui-text-small">${ self.formatUnits( item.total ) } policies</h2>
                  </div>
                </div>
              </div>
            `
          }
        }
      }
    },

    getPercentage(value) {
      return 100 / this.getTotal * value
    },

    setSelectedList(index) {
      this.selectedListIndex = index
      this.showTable = true
    }
  }
}
</script>