<template>
  <div>
    <table class="table table-bordered mt-6 whitespace-no-wrap">
      <thead>
        <tr>
          <th>ID</th>
          <th>Jurisdiction Title</th>
          <th>Climate Zones</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="collection in collections"
          :key="`collection-${collection.jurisdiction_id}`"
        >
          <td>{{ collection.jurisdiction_id }}</td>
          <td>{{ collection.jurisdiction_title }}</td>
          <td>
            <span
              v-for="(climate_zone, index) in collection.climate_zones_prefix"
              :key="`jurisdiction-${index}cz-prefix-${climate_zone}`"
              class="font-bold text-blue02"
            >
              {{ climate_zone }}{{ index != collection.climate_zones_prefix.length-1 ? ", " : "" }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    props:[
      'collections'
    ],
  }
</script>

<style lang="scss" scoped>

</style>