var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full p-6"},[_c('div',{staticClass:"w-full flex justify-end"},[_c('PsButton',{staticClass:"psui-mr-3",attrs:{"icon":"sync","size":"medium","label":"Sync building stock"},on:{"click":function($event){return _vm.sync()}}}),_c('router-link',{attrs:{"to":{ name: 'AdminBuildingStockCreate' }}},[_c('PsButton',{attrs:{"size":"medium","label":"Create","icon":"add"}})],1)],1),_c('div',{staticClass:"w-full relative mt-6"},[_c('VueSelect',{staticClass:"vue-select",class:_vm.jurisdictionSelected ? 'filled' : '',attrs:{"placeholder":"Select a jurisdiction","label":"title","options":_vm.jurisdictions},on:{"input":function($event){return _vm.getBuildingStocks()}},model:{value:(_vm.jurisdictionSelected),callback:function ($$v) {_vm.jurisdictionSelected=$$v},expression:"jurisdictionSelected"}}),_c('label',[_vm._v("Jurisdiction to filter")])],1),_c('table',{staticClass:"table table-bordered mt-6 whitespace-nowrap"},[_vm._m(0),_c('tbody',_vm._l((_vm.building_stocks),function(building_stock,index){return _c('tr',{key:`building_stock-${index}-${building_stock.id}`},[_c('td',[_vm._v(_vm._s(building_stock.id))]),_c('td',[_vm._v(_vm._s(building_stock.jurisdiction.title))]),_c('td',_vm._l((building_stock.climate_zones),function(climate_zone,index){return _c('router-link',{key:`building_stock-cz-${index}-${climate_zone.id}`,staticClass:"font-bold text-blue02",attrs:{"to":{
              name: 'AdminClimateZoneEdit',
              params: { climate_zone_id: climate_zone.id },
            }}},[_vm._v(" "+_vm._s(climate_zone.raw)+" ")])}),1),_c('td',[_vm._v(_vm._s(building_stock.type_prototype.title))]),_c('td',[_vm._v(_vm._s(building_stock.type_vintage.title))]),_c('td',[_vm._v(_vm._s(building_stock.units))]),_c('td',[_vm._v(_vm._s(building_stock.row_number))]),_c('td',{staticClass:"psui-flex psui-space-x-2"},[_c('router-link',{attrs:{"to":{
              name: 'AdminBuildingStockEdit',
              params: { building_stock_id: building_stock.id },
            }}},[_c('PsButton',{attrs:{"label":"Edit","icon":"edit","size":"medium"}})],1),_c('PsButton',{attrs:{"layout":"caution","label":"Delete","size":"medium","icon":"delete"},on:{"click":function($event){return _vm.selectBuildingStockToDelete(building_stock)}}})],1)])}),0)]),_c('BaseModal',{ref:"deleteModal",attrs:{"modal-dialog-classes":"w-full max-w-sm","title":"Attention"}},[(_vm.building_stock_selected)?_c('div',{staticClass:"w-full flex flex-col items-start mt-6"},[_c('p',{staticClass:"text-base text-gray04"},[_vm._v(" Delete "),_c('span',{staticClass:"text-red font-bold"},[_vm._v(_vm._s(_vm.building_stock_selected.jurisdiction.title))]),_vm._v("? ")]),_c('PsButton',{staticClass:"psui-mt-6",attrs:{"layout":"caution","label":"Confirm delete","size":"big"},on:{"click":function($event){return _vm.deleteBuildingStock()}}})],1):_vm._e()]),_c('Paginator',{attrs:{"length":_vm.building_stocks.length,"limit":_vm.limit},on:{"getItems":function($event){return _vm.getBuildingStocks({ offset: _vm.offset })}},model:{value:(_vm.offset),callback:function ($$v) {_vm.offset=$$v},expression:"offset"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("ID")]),_c('th',[_vm._v("Jurisdiction")]),_c('th',[_vm._v("Climate zones")]),_c('th',[_vm._v("Prototype")]),_c('th',[_vm._v("Vintage")]),_c('th',[_vm._v("Units")]),_c('th',[_vm._v("Table row number")]),_c('th',{staticClass:"psui-w-48"},[_vm._v(" Edit ")])])])
}]

export { render, staticRenderFns }