export default {
  getterPolicyExistingBuildingsColumns: (state) => {
    const existingColumnsGroups = state.requirementsColumns['existingBuildings']
    return {
      ...existingColumnsGroups,
      columnGroups: existingColumnsGroups.columnGroups
      .map(cg => {
        if(cg.key == 'city_wide_impact') {
          return {
            ...cg,
            title: `City-${cg.title}`
          }
        }
        return cg
      })
    }

    
  }
}