import BaseModel from './BaseModel'

export default class Prototype extends BaseModel {

  constructor(obj = {}) {
    super()
    this.id = obj.id || null
    this.study_id = obj.study_id || null
    this.title = obj.title || ''
    this.slug = obj.slug || ''
    this.description = obj.description || ''
    this.content = obj.content || ''
    this.title_abbreviated = obj.title_abbreviated || ''
    this.icon = obj.icon || null
    this.type_prototype_id = obj.type_prototype_id || null
    this.type_prototype = obj.type_prototype || null
    this.fuel_switch_package_measure_id = obj.fuel_switch_package_measure_id || null
    this.impact_algorithm_results = obj.impact_algorithm_results || ''
    this.impact_algorithm_policy_impact = obj.impact_algorithm_policy_impact || ''
    this.impact_algorithm_policy_flexible_path = obj.impact_algorithm_policy_flexible_path || ''
    this.allow_policy_creation = obj.allow_policy_creation || true
    this.show_by_default_in_results = obj.show_by_default_in_results || true
    this.policy_option_algorithms = obj.policy_option_algorithms || []
  }

}
