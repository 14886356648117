<template>
  <BaseModal
    ref="baseModal"
    modal-dialog-classes="w-auto bg-ash-100"
    @close="close"
  >
    <div class="info-wrapper text-center mt-4">
      <div class="text-header-5 text-ash-700 mb-6 font-bold">
        We are preparing your measure menu tables
      </div>
      <div class="text-p-2 flex items-center justify-center">
        <Checkbox
          v-model="downloadModelOrdinance"
          size="24"
          checkbox-classes="mb-0"
        >
          <span class="text-ash-700">Also download the model ordinance template</span>
        </Checkbox>
      </div>

      <div class="w-full flex justify-center">
        <PsButton
          class="mt-6"
          label="Download"
          icon="download"
          size="medium"
          :loading="isDownloadingXls"
          @click="download()"
        />
      </div>
    </div>
  </BaseModal>
</template>

<script>

import PolicyDocumentsApiService from '@/services/api/PolicyDocumentsApiService'

export default {
  name: 'PolicyFlexiblePathDownloadModal',
  props: ['policy'],
  data() {
    return {
      downloadModelOrdinance: false,
      isDownloadingXls: false
    }
  },
  mounted() {
    this.$eventBus.$on('openModalPolicyFlexiblePathDownload', this.open)
  },
  beforeDestroy() {
    this.$eventBus.$off('openModalPolicyFlexiblePathDownload')
  },
  methods: {
    download() {

      this.isDownloadingXls = true
      PolicyDocumentsApiService.downloadRequirementsTableXls({ 
        type: 'MeasureMenuXls', 
        policy_id: this.$route.params.policy_id,
        policy: this.policy
      })
        .then(() => {
          this.isDownloadingXls = false
        })

      if (this.downloadModelOrdinance) {
        this.$api.get(`/api/download/get_file/${encodeURIComponent('https://localenergycodes.com/download/1224/file_path/fieldList/2022 Model Ordinance - Single Family Renovations.docx')}`, { responseType: 'blob'})
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'Model_Ord_SF_Renovations.docx')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
      }
      this.close()
    },

    open() {
      this.$refs.baseModal.showModal = true
    },

    close() {
      this.$refs.baseModal.showModal = false
    }
  }
}
</script>