import Graphql from '@/util/GraphQL'
import PromiseWorker from 'promise-worker'
import formatCurrency from '@/formatters/formatCurrency'
import Store from '@/store'
import StudyData from '@/models/StudyData'
import { defaultExistingBuildingsImpactAssumptionValues } from '@/models/ImpactAssumptions.js'
import getSimplePayback from '@/business-logic/services/study-results/getSimplePayback'
import getOnBillCostRatio from '@/business-logic/services/study-results/getOnBillCostRatio'

const workerCombinationWithLowestIncreamentalCost = new PromiseWorker(new Worker('/workers/combination_with_lowest_increamental_cost.js'))

export const getComplianceCostRange = async ({ studyDataArgs, minTargetScore, measuresExcludedIds, measuresMandatoryIds, debug }) => {
  const studyDataRows = (await getInitialStudyData({ studyDataArgs, measuresExcludedIds, measuresMandatoryIds })).combinableStudyData

  const methodOnePermutation = await workerCombinationWithLowestIncreamentalCost.postMessage({ studyDataRows: [...studyDataRows], minTargetScore, shouldExcludeMeasuresContingent : false, debug })
  const methodTwoPermutation = await workerCombinationWithLowestIncreamentalCost.postMessage({ studyDataRows: [...studyDataRows], minTargetScore, shouldExcludeMeasuresContingent : true, debug })
  
  return Promise.all([
    methodOnePermutation,
    methodTwoPermutation
  ]).then((methods => {
    return {
      studyDataRows: [...studyDataRows],
      methods: [methods[0], methods[1]],
    }
  }))
}

export const getComplianceCostRangeImpacts = async ({ studyDataArgs, minTargetScore, measuresExcludedIds, measuresMandatoryIds }) => {
  const studyDataRows = (await getInitialStudyData({ studyDataArgs, measuresExcludedIds, measuresMandatoryIds })).combinableStudyData
  
  const getTheNextTargetScoreImpact = async (targetScore, shouldExcludeMeasuresContingent, output = {}) => {
    if (targetScore <= minTargetScore) {
      const { lowestCombinationIncrementalCost, combinationWithLowestIncrementalCost } = await workerCombinationWithLowestIncreamentalCost.postMessage({ studyDataRows, minTargetScore : targetScore, shouldExcludeMeasuresContingent })
      const targetScoreFounded = getCombinationTargetScore(combinationWithLowestIncrementalCost)
      while ( (targetScore <= targetScoreFounded) && (targetScore <= minTargetScore)) {
        output[targetScore] = lowestCombinationIncrementalCost
        targetScore++
      }
      return getTheNextTargetScoreImpact(targetScore, shouldExcludeMeasuresContingent, output)
    } else {
      return output
    }
  }

  return Promise.all([getTheNextTargetScoreImpact(1, false), getTheNextTargetScoreImpact(1, true)])
}

export const getInitialStudyData = async ({ studyDataArgs, measuresExcludedIds, measuresMandatoryIds }) => {
  let studyDataRows = await getStudyData(studyDataArgs)

  if(!studyDataRows.length) return false
  studyDataRows = studyDataRows.map(study_data => {
    study_data.measure.isFullyRepresentedPackage = checkIsFullyRepresentedPackage(study_data, studyDataRows)
    return study_data
  })

  const combinableStudyData = studyDataRows.filter(study_data => {
    return measuresExcludedIds?.map((i) => +i)?.includes(+study_data.measure_id) // Remove Excluded Measures
    || measuresMandatoryIds?.map((i) => +i)?.includes(+study_data.measure_id) // Remove Mandatory Measures
    || getMeasureScore(study_data) <= 0 // Remove Measures with Negative Measure Score
    || study_data.measure.isFullyRepresentedPackage // Remove Fully Represented Packages
    || study_data.measure.hide_in_flexible_path
        ? false : true
  })

  const mandatoryStudyData = ((!measuresMandatoryIds || !Array.isArray(measuresMandatoryIds)) ? [] : measuresMandatoryIds.map((id) => {
      return studyDataRows?.find((study_data) => +study_data.measure_id === +id)
    })?.filter((x) => x) || [])
  const excludedByMandatoryMeasureIds = mandatoryStudyData.map((s) => Array.isArray(s?.measure?.exclude_measures) ? s?.measure?.exclude_measures : []).flat(Infinity)

  return {
    combinableStudyData,
    mandatoryStudyData,
    excludedByMandatoryMeasureIds,
  }
}

export const getStudyData = ({ vintage_id, vintage_ids, prototype_id, climate_zone_raw }) => {
  vintage_ids = vintage_ids ?? [ vintage_id ]
  const vintage_ids_string = vintage_ids.map(id => `"${id}"`).join(',')

  const climate_zone = Store.getters['globalEntities/ClimateZone/getterGlobalClimateZone']({ raw: climate_zone_raw })

  const query = `
    {
      study_data (
        where: [
          {column: "prototype_id", operation: "=", value: "${prototype_id}"},
          {column: "climate_zone_raw", operation: "=", value: "${climate_zone_raw}"},
        ],
        whereIn: [
          { column: "vintage_id", value: [${vintage_ids_string}]}
        ],
        orderBy: [ { column: "initial_cost" , order: "asc"}]
      ){
        id
        study_row_number
        climate_zone_raw
        study_id
        prototype_id
        measure_id
        measure {
          id 
          title
          is_package
          is_contingent
          exclude_measures
          hide_in_flexible_path
          is_other_child_measures
          measures {
            id
            title
          }
        }
        vintage_id
        fuel_id
        baseline_fuel_type
        initial_cost
        kwh_savings
        therms_savings
        annual_bill_savings
        simple_payback
        tdv_benefit_to_cost_ratio
        on_bill_cost_ratio
        on_bill_cost_ratio_2025
        on_bill_2025_care
        lsc_2025_benefit_to_cost_ratio
        emissions_savings
        emissions_savings_pct
        compliance_margin
        lifecycle_savings
        tdv2022_benefit_to_cost_ratio
        energy_savings_combined
      }
    }
  `
  return Graphql({ query })
  .then(({ data }) => {
    if(!data.study_data.length && climate_zone.fallback_climate_zone) {
      return getStudyData({ vintage_id, vintage_ids, prototype_id, climate_zone_raw: climate_zone.fallback_climate_zone.raw })
    }
    return data.study_data      
  })
}

// https://epcgo.atlassian.net/wiki/spaces/BL/pages/598540289/Compliance+Costs+Range+Calculations
export const checkIsFullyRepresentedPackage = (study_data, studyDataRows) => {
  
  if (study_data.measure.is_other_child_measures || !study_data.measure.is_package) return false
  
  const singleMeasuresIds = study_data.measure.measures.map(m => m.id)
  const studyDataRowsDic = studyDataRows.reduce((acc, studyData) => {
    acc[studyData.measure_id] = true
    return acc
  }, {})
  
  for (const measure_id of singleMeasuresIds) {
    if (!studyDataRowsDic[measure_id]) return false
  }
  
  return true

}

export const roundToRange = (number) => {
  const n = Math.round(number)
  const digits = n.toString().length
  const digitsToWithdraw = n < 3 ? 0 : digits - 2
  return (n.toString().substring(0, digits - digitsToWithdraw)).padEnd(digits, '0')
}

export const formatImpactRange = (targetScore, range) => {
  const minValue = range[0][targetScore]
  const maxValue = range[1][targetScore]
  return minValue !== maxValue ? `${formatCurrency(roundToRange(minValue))} - ${formatCurrency(roundToRange(maxValue))}` : `${formatCurrency(roundToRange(minValue))}`
}


// Method is also implemented at workers/combination_with_lowest_incremental_costs.js
// Please, update it there if you update here
export const getCombinationTargetScore = (combination) => {
  return combination.reduce((acc, study_data) => {        
    return acc + getMeasureScore(study_data)
  }, 0)
}

// Method is also implemented at workers/combination_with_lowest_incremental_costs.js
// Please, update it there if you update here
export const getCombinationIncrementalCost = (combination, key = undefined, preserveUnities = true) => {
  const result = combination.reduce((acc, study_data) => {
    let value = study_data.data?.[key] ?? study_data?.[key] ?? study_data?.study_data?.[key] ?? 0
    if (key === 'simple_payback') {
      const totalInitialCost = getCombinationIncrementalCost(combination, 'initial_cost')
      const totalAnnualBillSavings = getCombinationIncrementalCost(combination, 'annual_bill_savings')
      value = getSimplePayback({ initial_cost: totalInitialCost, annual_bill_savings: totalAnnualBillSavings })
    } else if (['on_bill_cost_ratio', 'on_bill_cost_ratio_2025', 'on_bill_2025_care'].includes(key)) {
      const totalInitialCost = getCombinationIncrementalCost(combination, 'initial_cost')
      const totalAnnualBillSavingsAvg = getCombinationIncrementalCost(combination, 'annual_bill_savings_avg')
      value = getOnBillCostRatio({initial_cost: totalInitialCost, annual_bill_savings_avg: totalAnnualBillSavingsAvg})
    } else if (key === 'flexible_score') {
      value = combination.reduce((acc, curr) => acc + Math.round(curr.data?.energy_savings_combined ?? curr?.energy_savings_combined ?? curr?.study_data?.energy_savings_combined ?? 0), 0)
    } else if (!['baseline_fuel_type', 'forecast_units_affected'].includes(key)) {
      value = parseFloat(value)
    }

    const keepValueKeys = ['baseline_fuel_type', 'simple_payback', 'on_bill_cost_ratio', 'on_bill_cost_ratio_2025', 'on_bill_2025_care', 'flexible_score']
    if (preserveUnities) keepValueKeys.push('forecast_units_affected')
    if (keepValueKeys.includes(key) && !isNaN(value) && !isNaN(acc) && acc > value ) return acc
    if (keepValueKeys.includes(key)) return value
    return acc + value
  }, 0)

  if (key === 'initial_cost') return Math.round(result)
  return result
}

// Method is also implemented at workers/combination_with_lowest_incremental_costs.js
// Please, update it there if you update here
export const getMeasureScore = (study_data) => {
  return Math.round(study_data.energy_savings_combined ?? study_data.study_data.energy_savings_combined)
}

// Compute city wide flexible estimates
export const getFlexibleForecastRange = async ({ studyDataArgs, minTargetScore, measuresExcludedIds, measuresMandatoryIds, debug }) => {
  await Store.dispatch('assumptions/buildingStocks/getJurisdictionBuildingStocks', studyDataArgs.jurisdiction.id)

  const vintage = Store.getters['globalEntities/Vintage/getterGlobalVintage']({ id: studyDataArgs.vintage_id })
  const prototype = Store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: studyDataArgs.prototype_id })
  const climatezonePrefixes = [Number(studyDataArgs.climate_zone_raw.split('-')[0])]
  const buildingStocks = Store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
    jurisdiction_id: studyDataArgs.jurisdiction.id,
    climate_zone_prefix: climatezonePrefixes,
    type_vintage_id: vintage.type_vintage_id,
    type_prototype_id: prototype.type_prototype_id,
    prototype_id: prototype.id,
  })

  const studyDataInfo = await getInitialStudyData({ studyDataArgs, measuresExcludedIds, measuresMandatoryIds })
  const studyDataRows = studyDataInfo.combinableStudyData
  const cityWideStudyDatas = studyDataRows.map((studyDataRow) => {
    return new StudyData({
          study_data: studyDataRow,
          building_stock_units: buildingStocks,
          assumption: defaultExistingBuildingsImpactAssumptionValues
        }
    )
  })
  const cityWideMandatoryStudyDataRows = studyDataInfo.mandatoryStudyData.map((studyDataRow) => {
    return new StudyData({
          study_data: studyDataRow,
          building_stock_units: buildingStocks,
          assumption: defaultExistingBuildingsImpactAssumptionValues
        }
    )
  })

  const methodOnePermutationPromises = []
  const methodTwoPermutationPromises = []
  const items = [
    {
      valueName: 'Initial cost',
      valueKey: 'initial_cost',
    },
    {
      valueName: '',
      valueKey: 'forecast_emissions_savings',
    },
    {
      valueName: '',
      valueKey: 'forecast_initial_cost',
    },
    {
      valueName: '',
      valueKey: 'forecast_kwh_savings',
    },
    {
      valueName: '',
      valueKey: 'forecast_lifecycle_savings',
    },
    {
      valueName: '',
      valueKey: 'forecast_therms_savings',
    },
    {
      valueName: '',
      valueKey: 'forecast_units_affected',
    }
  ]

  await Promise.all(items.map(async (i) => {
    methodOnePermutationPromises.push(await workerCombinationWithLowestIncreamentalCost.postMessage({ studyDataRows: [...cityWideStudyDatas], minTargetScore, shouldExcludeMeasuresContingent : false, debug, valueFinder: i.valueKey, excludedByMandatoryMeasureIds: studyDataInfo.excludedByMandatoryMeasureIds }))
    methodTwoPermutationPromises.push(await workerCombinationWithLowestIncreamentalCost.postMessage({ studyDataRows: [...cityWideStudyDatas], minTargetScore, shouldExcludeMeasuresContingent : true, debug, valueFinder: i.valueKey, excludedByMandatoryMeasureIds: studyDataInfo.excludedByMandatoryMeasureIds }))
  }))

  return Promise.all([
    methodOnePermutationPromises,
    methodTwoPermutationPromises
  ]).then(([oneResults, twoResults]) => {
    const reduceFnc = (acc, curr, index) => {
      const key = curr?.valueFinder || 'initial_cost'
      acc[key] = {
        min: curr.lowestCombinationIncrementalCost,
        max: curr.highestCombinationIncrementalCost,
        minCombination: curr.combinationWithLowestIncrementalCost,
        maxCombination: curr.combinationWithHighestIncrementalCost,
        title: items[index].valueName,
        key: key
      }
      return acc
    }
    return {
      studyDataRows: [...studyDataRows],
      cityWideMandatoryStudyDataRows: [...cityWideMandatoryStudyDataRows],
      methods: [oneResults[0], twoResults[0]],
      rangedData: [
        oneResults.reduce(reduceFnc, {}),
        twoResults.reduce(reduceFnc, {}),
      ]
    }
  })
}

export default {
  getComplianceCostRange,
  getFlexibleForecastRange,
  getComplianceCostRangeImpacts,
  getCombinationTargetScore,
  getCombinationIncrementalCost,
  getInitialStudyData,
  getMeasureScore,
  getStudyData,
  checkIsFullyRepresentedPackage,
  roundToRange,
  formatImpactRange
}
