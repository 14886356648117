<template>
  <div class="w-full flex flex-col space-y-4 bg-white p-6">
    <div class="w-full flex flex-col bg-ash-200">
      <div class="w-full p-6 border-b border-ash-200 bg-white flex justify-between">
        <PageTitle title="User device list" />
      </div>
    </div>

    <div class="w-full flex flex-col items-start relative">
      <VueSelect 
        v-model="filterByUserIds"
        class="vue-select mt-6"
        placeholder="Filter by users"
        :class="filterByUserIds.length ? 'filled' : ''"
        label="email"
        :reduce="i => i.id"
        :options="users"
        :multiple="true"
        :close-on-select="false"
        @input="getUserDevices()"
      />
      <p
        class="text-red font-bold cursor-pointer"
        @click="setFilterByUser([])"
      >
        Clear user
      </p>
    </div>
    
    <div class="w-full overflow-x-auto mt-4 bg-white">
      <table class="w-full table-auto text-sm w-full whitespace-nowrap">
        <thead>
          <tr class="bg-gray-200">
            <th class="sticky top-0 left-0 z-10 bg-ash-200">
              User
            </th>
            <th class="sticky top-0 bg-ash-200 md:left-0 bg-gray-200 ">
              ID (unique device ID)
              <OrderByArrowToggle
                column="id"
                :order-by="orderBy"
                @setOrderBy="setOrderBy"
              />
            </th>
            <th class="sticky top-0 bg-ash-200">
              OS
            </th>
            <th class="sticky top-0 bg-ash-200">
              Platform
            </th>
            <th class="sticky top-0 bg-ash-200">
              Browser
            </th>
            <th class="sticky top-0 bg-ash-200">
              Last session from
            </th>
            <th class="sticky top-0 bg-ash-200">
              Session count
              <OrderByArrowToggle
                column="session_count"
                :order-by="orderBy"
                @setOrderBy="setOrderBy"
              />
            </th>
            <th class="sticky top-0 bg-ash-200">
              Last session at
              <OrderByArrowToggle
                column="last_session_at"
                :order-by="orderBy"
                @setOrderBy="setOrderBy"
              />
            </th>
            <th class="sticky top-0 bg-ash-200">
              Created at 
              <OrderByArrowToggle
                column="created_at"
                :order-by="orderBy"
                @setOrderBy="setOrderBy"
              />
            </th>
            <th class="sticky top-0 bg-ash-200">
              Edit
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="user_device in user_devices"
            :key="user_device.id"
            class="border-b border-ash-200"
          >
            <td class="sticky left-0 bg-white border border-ash-200">
              <span v-if="user_device.user">{{ user_device.user.email }}</span>
              <span v-else>Non logged user</span>
            </td>
            <td>{{ user_device.id }} ({{ user_device.uid }})</td>
            <td>{{ user_device.os }}</td>
            <td>{{ user_device.platform }}</td>
            <td>{{ user_device.browser }}</td>
            <td>
              <span v-if="!user_device.country && !user_device.city">--</span>
              <span v-else>{{ user_device.city }} - {{ user_device.country }}</span>
            </td>
            <td>{{ user_device.session_count }}</td>
            <td>{{ user_device.last_session_at }}</td>
            <td>{{ user_device.created_at }}</td>
            <td>
              <PsButton 
                label="Edit"
                size="small"
                @click="$eventBus.$emit('openAdminUserDeviceEditModal', user_device)" 
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <Paginator
      v-model="offset"
      :length="user_devices.length"
      :limit="limit"
      class="mb-4"
      @getItems="getUserDevices(offset)"
    />

    <AdminUserDeviceEditModal @update="getUserDevices" />
  </div>
</template>

<script>
import AdminUserDeviceEditModal from './AdminUserDeviceEditModal'
export default {
  name: 'AdminUserDeviceList',
  components: { AdminUserDeviceEditModal },
  data() {
    return {
      user_devices: [],
      users: [],
      offset: 0,
      limit: 20,
      filterByUserIds: [],
      orderBy: {
        column: "id",
        order: "DESC"
      }
    }
  },
  mounted() {
    this.getUserDevices()
    this.getUsers()
  },
  methods: {

    getUserDevices(offset = 0) {
      this.offset = offset

      const whereIn = this.filterByUserIds.length ? 
        `whereIn: [{ column: "user_id", value: [${this.filterByUserIds.map(i => `"${i}"`).join(',')}] }]` : ''
      
      const query = `
        { 
          user_devices (
            ${ whereIn }
            orderBy: [{ column: "${this.orderBy.column}", order: "${this.orderBy.order}" }]
            paginate: { offset: ${offset}, limit: ${this.limit}}
          ) {
            id
            uid
            user_id
            os
            platform
            browser
            is_mobile
            session_count
            last_session_at
            country
            city
            created_at
            user { email }
          }
        }
      `

      this.$graphql({ query, caller: 'AdminUserDeviceList.getUserDevices' })
        .then(({ data }) => {
          this.user_devices = data.user_devices
        })
    },

    getUsers() {
      const query = `
        {
           users (
            orderBy: [
              { column: "is_admin", order: "DESC" },
              { column: "email", order: "ASC" },
            ]
           ) {
            id
            email
           }
        }
      `

      this.$graphql({ query, caller: 'AdminUserDeviceEditModal' })
        .then(({ data }) => {
          this.users = data.users
        })
    },

    setOrderBy(column) {
      console.log(column)
      if(this.orderBy.column == column) {
        this.orderBy.order = this.orderBy.order == 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.orderBy.column = column
        this.orderBy.order = 'ASC'
      }
      this.getUserDevices()
    },

    setFilterByUser(value) {
      this.filterByUserIds = value
      this.getUserDevices()
    }
  }
}
</script>

<style scoped>

table tr th, table tr td {
  padding: 8px;
}

table tr:hover {
  background-color: #EBEEF0 !important;
}

</style>