import ComparisonApiService from '@/services/api/ComparisonApiService.js'
import Store from '@/store'
import Vue from 'vue'

export default [
  //STEP 1
  {
    guide:'STEP 1 OF 2',
    id:'creating-comparison',
    component:'ComparePoliciesWizardStepOne',
    title:({ policyComparison, currentJurisdiction })=>{
      if(!policyComparison) return null
      const baseText = `Creating a comparison between ${ policyComparison?.policy_ids?.toString().split(',').length } policies for ${ currentJurisdiction.type === 'City' || currentJurisdiction.type === 'State' ? 'the' : '' } ${ currentJurisdiction.titles.title_type }`
      return Vue.options.filters.policyTextRephrase(baseText)
    },
    createComparison:  async ({ policyComparison }) => {
      const newComparison = await Store.dispatch('comparison/createComparison', { 
        comparison : policyComparison
      })
      return newComparison
    },
  },
  //STEP 2
  {
    guide:'STEP 2 OF 2',
    id:'policy-comparison-created',
    component:'ComparePoliciesWizardStepTwo',
    updateComparison: async (comparisonId, { description }) => {
      await ComparisonApiService.update(comparisonId, { description })
      Store.dispatch('comparison/getUserComparison', { comparison_id: comparisonId })
    },
  },
]