import Graphql from '@/util/GraphQL'
import * as sha1 from 'sha1'
import asyncCacheResolver from '@/util/asyncCacheResolver'

export default class MeasureApiService {

  static get (measure_constraints) {
    const query = `
      {
        measures ${measure_constraints ? `(${measure_constraints})` : ''} {
          id
          study_id
          title
          hide_in_compliance_margin_control
          type_measures {
            id
          }
          is_package
          measures {
            id
            study_id
            title
          }
        }
      }
    `

    const cacheKey = `MeasureApiService.get:${sha1(query)}`
    return asyncCacheResolver(cacheKey, async () => {
      return Graphql({ query, caller: `MeasureApiService.get` })
          .then(({data}) => data )
    })
  }

  static getByIds(measures_ids){
    const query = `
      {
        measures (
          whereIn: [
            { column: "id", value: [${measures_ids.map(item => `"${item}"`).join(',')}] }      
          ]
        ) {
          id
          hide_in_flexible_path
          title
        }
      }
    `
    
    const cacheKey = `MeasureApiService.getByIds:${sha1(query)}`
    return asyncCacheResolver(cacheKey, () => {
      return Graphql({ query, caller: `MeasureApiService.getByIds` })
      .then(({data}) => data )
    })
  }

}