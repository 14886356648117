<template>
  <div class="w-full h-full psui-w-full h-auto psui-rounded-md psui-bg-white psui-shadow-elevation-5 p-8">
    <h4 
      v-if="title" 
      class="psui-text-h5 psui-text-gray-80 font-bold whitespace-nowrap"
    >
      {{ title }}
    </h4>
    <slot />
  </div>
</template>

<script>
export default {
  props: ['title']
}
</script>
