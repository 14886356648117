<template>
  <BaseModal
    ref="baseModal"
    title="Are you sure you want to reset your assumptions?"
    @close="close"
  >
    <div class="flex flex-col mt-4 psui-text-gray-50 gap-6">
      <span class="psui-text-p">
        Resetting your assumptions will return all assumptions to their defaults, and will discard any custom assumptions you have already set.
      </span>
      <div class="flex gap-1">
        <PsButton
          data-test-id="modal-restore-button"
          label="Restore default"
          size="big"
          @click="restoreAssumptions"
        />
        <PsButton
          label="Keep custom values"
          size="big"
          layout="onlytext"
          @click="close()"
        />
      </div>
    </div>
  </BaseModal>
</template>

<script>


export default {
    name:"AssumptionsRestoreConfirmationModal",
    data:()=>({
      study_type: null,
      policy_id: null,
      key: null,
    }),
    mounted(){
      this.$eventBus.$on('openAssumptionsRestoreConfirmationModal',({policy_id, study_type, key})=>{
        this.$refs.baseModal.showModal = true
        this.key = key
        this.policy_id = policy_id
        this.study_type = study_type
      })
      this.$eventBus.$on('closeAssumptionsRestoreConfirmationModal', this.close)
    },
    beforeDestroy(){
      this.$eventBus.$off('openAssumptionsRestoreConfirmationModal')
      this.$eventBus.$off('closeAssumptionsRestoreConfirmationModal')
    },
    methods:{
        close(){
          this.$refs.baseModal.showModal = false
          this.$eventBus.$emit('closedAssumptionsRestoreConfirmationModal')
        },
        restoreAssumptions(){
          this.$eventBus.$emit('restoreAssumption', this.key)
          this.$store.dispatch('assumptions/clearImpactAssumptionsByKey', {study_type:this.study_type, key: this.key})
          this.close()
        }
    }
}
</script>

<style>

</style>
