<template>
  <div class="w-full h-full overflow-y-auto">
    <BuildingEstimatesHeader class="sticky top-0 bg-white psui-z-20" />

    <InAppCalculator ref="inAppCalculator" />

    <div class="w-full flex flex-col px-12 py-6">
      <div class="w-full my-3 mb-6 text-black flex">
        <VueSelect 
          class="vue-select"
          placeholder="Select a assumption profile to use"
          label="title"
          :reduce="item => item.id"
          :options="user_policies"
          :value="$store.state.assumptions.policy_id_selected"
        />
        <!-- <AppButton
          class="ml-3 whitespace-nowrap"
          label="Clear all building stocks"
          @click="clearBuildingStocks()"
        /> -->
        <PsButton
          class="ml-3 whitespace-nowrap"
          label="Clear all building stocks"
          size="big"
          @click="clearBuildingStocks()"
        />
      </div>
    
      <template 
        v-for="building_type in building_types" 
        class="w-full my-6"
      >
        <div 
          v-show="!checkQueryHasKey('only_building_type') || checkQueryKeyValue('only_building_type', building_type.slug)"
          :key="`building_type_${building_type.id}`"
          class="w-full"
        >
          <h1 class="cursor-pointer text-2xl font-bold">
            <span @click="toggleHiddenItem(`building_type_${building_type.id}`)">{{ building_type.title }} | </span>
            <span
              class="text-red" 
              @click="addValueToSum(
                $store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
                  type_prototype_id: building_type.type_prototypes.map(type_prototype => type_prototype.id)
                }),
                `Building type ${building_type.id}`
              )"
            >
              Units: 
              {{ $store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
                type_prototype_id: building_type.type_prototypes.map(type_prototype => type_prototype.id)
              })
              }}
            </span>
          </h1>
          <template v-for="climate_zone in climateZones">
            <div 
              v-if="hiddenItems.indexOf(`building_type_${building_type.id}`) < 0"
              v-show="!checkQueryKeyValue('exclude_climate_zone_prefix', climate_zone.prefix)" 
              :key="`climate_zone-${climate_zone.id}`"
              class="w-full ml-3 mb-2"
            >
              <h1 class="cursor-pointer mb-2 text-xl font-bold">
                <span @click="toggleHiddenItem(`climate_zone-${climate_zone.id}`)">{{ climate_zone.raw }} | </span>
                <span 
                  class="text-red"
                  @click="addValueToSum(
                    $store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({ 
                      climate_zone_prefix: climate_zone.prefix,
                      type_prototype_id: building_type.type_prototypes.map(type_prototype => type_prototype.id)
                    }),
                    `Climate Zone ${climate_zone.prefix}`
                  )"
                >
                  Units: 
                  {{ $store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({ 
                    climate_zone_prefix: climate_zone.prefix,
                    type_prototype_id: building_type.type_prototypes.map(type_prototype => type_prototype.id)
                  })
                  }}
                </span>
              </h1>
              <template v-for="type_prototype in building_type.type_prototypes">
                <div
                  v-if="hiddenItems.indexOf(`climate_zone-${climate_zone.id}`) < 0" 
                  :key="`type_prototype_${type_prototype.id}`"
                  class="ml-3 mb-2 w-full"
                >
                  <h1 class="mb-2 text-xl font-bold">
                    <span>{{ type_prototype.title }} | </span>
                    <span 
                      class="cursor-pointer text-red"
                      @click="addValueToSum(
                        $store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({ 
                          climate_zone_prefix: climate_zone.prefix,
                          type_prototype_id: type_prototype.id,
                        }),
                        `Type prototype ${type_prototype.title}`
                      )"
                    >
                      Units: 
                      {{ $store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({ 
                        climate_zone_prefix: climate_zone.prefix,
                        type_prototype_id: type_prototype.id,
                      }) 
                      }}
                    </span>
                  </h1>

                  <div
                    v-for="type_vintage in type_vintages" 
                    :key="`type_vintage_${type_vintage.id}`" 
                    class="ml-3 mb-2 w-full flex flex-col"
                  >
                    <AssumptionsBuildingStocksInput
                      :label="type_vintage.title"
                      :climate_zone_prefix="climate_zone.prefix" 
                      :type_vintage_id="type_vintage.id" 
                      :type_prototype_id="type_prototype.id"
                    />
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </template>

      
      <!-- <h1 class="mt-6 text-xl font-bold">jurisdiction_building_stocks</h1>
      <table class="table table-bordered table-hover table-striped mb-4">
        <thead>
          <tr>
            <td>jurisdiction_id</td>
            <td>climate_zone_prefix</td>
            <td>type_prototype_id</td>
            <td>type_vintage_id</td>
            <td>units</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user_building_stock, index) of $store.state.assumptions.buildingStocks.jurisdiction_building_stocks" :key="`user_building_stock_${index}`">
            <td>{{ user_building_stock.jurisdiction_id }}</td>
            <td>{{ user_building_stock.climate_zone_prefix }}</td>
            <td>{{ user_building_stock.type_prototype_id }}</td>
            <td>{{ user_building_stock.type_vintage_id }}</td>
            <td class="font-bold">{{ user_building_stock.units }}</td>
          </tr>
        </tbody>
      </table> -->
      
      <h1 class="text-xl font-bold">
        custom_building_stocks
      </h1>
      <table class="table table-bordered table-hover table-striped mb-4">
        <thead>
          <tr>
            <td>jurisdiction_id</td>
            <td>climate_zone_prefix</td>
            <td>type_prototype_id</td>
            <td>type_vintage_id</td>
            <td>units</td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(custom_building_stock, index) of $store.state.assumptions.buildingStocks.custom_building_stocks"
            :key="`custom_building_stock_${index}`"
          >
            <td>{{ custom_building_stock.jurisdiction_id }}</td>
            <td>{{ custom_building_stock.climate_zone_prefix }}</td>
            <td>{{ custom_building_stock.type_prototype_id }}</td>
            <td>{{ custom_building_stock.type_vintage_id }}</td>
            <td class="font-bold">
              {{ custom_building_stock.units }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import AssumptionsBuildingStocksInput from '../../app/assumptions/building-estimates/AssumptionsBuildingStocksInput.vue'
import BuildingEstimatesHeader from '@/modules/app/jurisdiction/building-estimates/BuildingEstimatesHeader.vue'
import InAppCalculator from './InAppCalculator'
export default {
  name: 'BuildingEstimatesPlayground',
  components: { BuildingEstimatesHeader, InAppCalculator, AssumptionsBuildingStocksInput },
  data() {
    return {
      type_vintages: [],
      building_types: [],
      user_policies: [],
      hiddenItems: []
    }
  },
  computed: {
    climateZones() {
      return this.$store?.getters['lastJurisdictionVisited']?.climate_zones ?? []
    }
  },
  mounted() {
    this.getData()
    this.getUserPolicys()
  },
  methods: {

    getValue({ climate_zone_prefix, type_vintage_id, type_prototype_id}) {
      return this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({ 
        climate_zone_prefix,
        type_vintage_id,
        type_prototype_id,
      })
    },
    getData() {
      const query = `
        {
          type_vintages {
            id
            title
            slug
          }
          building_types (
            where: [
              { column: "slug", operation: "NOT ILIKE", value: "%total%" }
            ]
          ) {
            id
            title
            slug
            type_prototypes {
              id
              title
              slug
            }
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.type_vintages = data.type_vintages
          this.building_types = data.building_types
        })
    },

    getUserPolicys() {
      const query = `
        {
          currentUser {
            policies  {
              id
              title
            }
          }
        }
      `

      this.$graphql({ query })
        .then(({ data }) =>{
          this.user_policies = data.currentUser.policies
        })
    },

    setUserBuildingStock( { climate_zone_prefix, type_prototype_id, type_vintage_id }) {
      const building_stock = {
        jurisdiction_id: this.$store.getters['lastJurisdictionVisited'].id,
        climate_zone_prefix,
        type_prototype_id,
        type_vintage_id
      }

      this.$store.commit('assumptions/buildingStocks/setCustomBuildingStock', building_stock)
    },

    clearBuildingStocks() {
      this.$store.commit('assumptions/buildingStocks/clearAllBuildingStocks')
      this.$store.dispatch('assumptions/buildingStocks/getJurisdictionBuildingStocks', this.$store.getters['lastJurisdictionVisited'].id)
    },

    toggleHiddenItem(item) {
      if(this.hiddenItems.indexOf(item) > -1) {
        this.hiddenItems.splice(this.hiddenItems.indexOf(item), 1)
      } else {
        this.hiddenItems.push(item)
      }
    },

    addValueToSum(value, label) {
      this.$refs.inAppCalculator.addValueToSum(value, label)
    },
  }
}
</script>