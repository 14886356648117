<template>
  <div>
    <div
      class="chart-wrapper psui-w-full psui-flex psui-relative psui-overflow-hidden psui-pb-6"
      :class="{ 'is-negative' : getChartIsNegative, 'is-positive' : !getChartIsNegative }"
    >    
      <div class="chart-axis-y-wrapper psui-min-w-10 psui-h-full psui-flex psui-flex-col">
        <h2 class="chart-axis-y-caption psui-text-xsmall psui-text-gray-80">
          <span>
            {{ columnSelected.chartProjection.title }}
          </span>
          <span v-if="columnSelected.chartProjection.subtitle">
            ・ {{ columnSelected.chartProjection.subtitle }}
          </span>
        </h2>

        <div
          v-for="(item, index) in getYLabels"
          :key="`chart-item-label-${index}`"
          class="chart-axis-y text-gray05 psui-w-full psui-px-2 psui-flex psui-justify-end psui-items-start psui-flex-1 psui-relative"
        >
          <div class="psui-flex psui-justify-end text-gray02">
            {{ item.formatted }}
          </div>
        </div>
      </div>

      <div class="chart-content psui-w-full psui-flex psui-flex-col psui-relative">
        <div class="chart-content-x-lines psui-h-full psui-flex psui-flex-col">
          <div
            v-for="(item, index) in getYLabels"
            :key="`chart-item-y-lines-${index}`"
            class="chart-content-x-line text-gray05 psui-w-full psui-px-2 psui-flex psui-justify-end psui-items-start psui-flex-1"
          />
        </div>

        <div class="chart-content-bars w-full h-full flex border-l border-b border-gray05 px-4 z-10">
          <div
            v-for="(item, index) in getChartData"
            :key="`chart-item=-${index}-${getItemKey}`"
            class="chart-content-bars-wrapper psui-h-full psui-flex psui-flex-1 psui-relative psui-items-end text-gray02"
            :style="{ marginLeft: getMargin, marginRight: getMargin }"
            :class="{ 'is-label' : getXLabels.indexOf(index) > -1 }"
            :data-year="item.year"
          >
            <div
              v-tooltip="getTooltip(index)"
              class="chart-content-bars-item psui-w-full psui-h-full psui-block"
              :style="item.styles"
            />
            <div class="chart-content-bars-caption psui-w-full psui-h-5 psui-absolute psui-bottom-0 psui-flex psui-justify-center">
              {{ item.year }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="assumption && !disableActivePeriod"
      id="active-installation-period"
      :style="{ paddingLeft: horizontalDistanceBetweenStarBarAndPeriodBar}"
    >
      <div
        class="bar"
        :style="{ width: horizontalDistanceBetweenStartBarAndEndBar }"
      />
      <span
        v-tooltip="activeInstallationPeriodTooltip"
        class="ts--accent--3 text-gray03"
      >Active Installation Period</span>
    </div>

    <div
      v-check-env="['staging', 'development']"
      class="border rounded shadow p-3"
    >
      Chart summed data: <span :data-test-id="`policy-impact-chart-projection-resume-total-value-debugger-${columnSelected.key}`">{{ getReducedDataToDebug }}</span> - only visible in development or staging
    </div>
  </div>
</template>

<script>
import { randomString, removeNegative } from '@/util/Functions'
import getChartLabelsWithNegative from '@/util/getChartLabelsWithNegative'
import formatStudyData from '@/formatters/formatStudyData'
import ImpactAssumptions from '@/models/ImpactAssumptions'

export default {
  name: 'ChartProjection',
  props: {
    assumption: {
      type: Object,
      default() {
        return new ImpactAssumptions()
      }
    },
    disableActivePeriod: {
      type: [Boolean],
      required: true
    },
    columnSelected: {
      type: Object,
      required: true
    },
    columns: {
      type: [Array, Object],
      required: true
    },
    projectionsByYear: {
      type: Array,
      required: true
    },
  },
  data: () => ({
    horizontalDistanceBetweenStartBarAndEndBar : 0,
    horizontalDistanceBetweenStarBarAndPeriodBar: 0
  }),
  computed: {    
    assumptionModel() {
      return new ImpactAssumptions(this.assumption)
    },
    getItemKey() {
      return randomString(10)
    },
    getYLabels() {      
      const labels = getChartLabelsWithNegative(this.getMax, this.getMin)
      return labels.map((label) => {
        return {
          value: label,
          formatted: formatStudyData(this.columnSelected.key, label)
        }
      })
    },
    getXLabels() {
      return this.getProjectionData.map((item, index) => index).filter((item, index, arr) => {
        if(index === 0 || (index % 10 === 0 && ((arr.length - index) >= 9)) || index == arr.length-1) return true
        return false
      })
    },
    getMargin() {
      return `${(100 / this.getProjectionData.length) / 8}%`
    },
    getMin() {
      return this.getProjectionData.map((a) => a).sort((a, b) => a?.current - b?.current)?.[0]?.current || 0
    },
    getMax() {
      return this.getProjectionData.map((a) => a).sort((a, b) => a?.current - b?.current)?.[this.getProjectionData.length-1]?.current || 0
    },
    getUnformattedMax() {
      return this.getProjectionData.map((a) => a).sort((a, b) => removeNegative(a?.current) - removeNegative(b?.current))?.[this.getProjectionData.length-1]?.current || 0
    },
    getUnformattedMin() {
      const filtered = this.getProjectionData.filter((item) => this.getChartIsNegative && (item?.current || 0) < 0 || !this.getChartIsNegative && (item?.current || 0) > 0).sort((a, b) => removeNegative(a?.current) - removeNegative(b?.current))
      if(filtered.length) return filtered?.[0]?.current || 0
      return 0
    },
    getChartIsNegative() {
      return Boolean(this.getUnformattedMax < 0)
    },
    getChartData() {
      let labels = getChartLabelsWithNegative(this.getMax, this.getMin)      
      if (this.getChartIsNegative) labels.reverse()
      
      const lowestLabel = labels[0]
      const highestLabel = labels[labels.length - 1]
      const range = Math.abs(lowestLabel) + Math.abs(highestLabel)

      const columnsThatNegativeValuesIndicateSavings = [ 'forecast_initial_cost' ]

      const chartData = this.getProjectionData.map((item) => {
        const pct = Math.abs(item?.current || 0) * 100 / range
        let height
        if (!range) {
          height = 0
        } else {
          height = pct === 0 ? '.5%' : `${pct}%`
        }
        const isItemNegative = (item?.current || 0) < 0 ? true : false
        let colorByGraph

        if ( columnsThatNegativeValuesIndicateSavings.includes(item.column_key) ) {
          colorByGraph = (item?.current || 0) < 0 ? '#4B8CA6' : `#D65C5A`
        } else {
          colorByGraph = (item?.current || 0) < 0 ? '#D65C5A' : `#4B8CA6`
        }

        const backgroundColor = pct === 0 ? 'gray' : colorByGraph
        return { 
          ...item, 
          isNegative: isItemNegative,
          styles: {
            backgroundColor,
            height,
            position: 'relative',
            bottom: `${(100 / (labels.length - 1)) * labels.indexOf(0)}%`,
            transform: isItemNegative ? `rotateX(180deg)` : ''
          }
        }
      })

      return chartData
    },
    getProjectionData() {
      return this.projectionsByYear.map((projection) => {
        return {
          column_key: this.columnSelected.key,
          current: projection[this.columnSelected.key]?.current || 0,
          cummulative: projection[this.columnSelected.key].cummulative,
          year: projection.year
        }
      })
    },

    getReducedDataToDebug() {
      return this.formatStudyData(this.columnSelected.key == 'forecast_units_affected' ? 'forecast_units' : this.columnSelected.key, this.getProjectionData.reduce((acc, item) => acc + item.current, 0))
    },

    activeInstallationPeriodTooltip() {
      return {
        html: true,
        content: this.$options.filters.policyTextRephrase(`The Active Installation Period is the portion of a policy’s lifecycle when requirements are being actively installed.`),
        placement: 'right',
        offset: 10
      }
    },
    getPolicyId() {
      return (this.$route?.params?.policy_id) ? this.$route.params.policy_id : null
    },
    getPolicyStudyType(){
      return this.$store.getters['policy/getterPolicyStudyTypes']({policy_id:this.getPolicyId})[0]
    },
  },
  watch: {
    columnSelected() {
      setTimeout(() => {
        this.drawTheActiveInslattionPeriod()
      }, 500)
    }
  },
  mounted() {
    window.addEventListener('resize',this.drawTheActiveInslattionPeriod)
    this.drawTheActiveInslattionPeriod()
  },
  beforeDestroy(){
    window.removeEventListener('resize',this.drawTheActiveInslattionPeriod)
  },
  methods: {
    drawTheActiveInslattionPeriod() {
      if (!this.assumptionModel?.getActiveInstallationPeriod) return
      const policyStudyType = this.getPolicyStudyType
      const activeInstallationPeriod = this.assumptionModel.getActiveInstallationPeriod({ format: 'YYYY', policy_study_type: policyStudyType })
      const activeIinstallationPeriodEl = document.getElementById('active-installation-period')
      const activeInstallationPeriodStartBarEl = document.querySelector(`[data-year='${activeInstallationPeriod[0]}']`)
      const activeInstallationPeriodEndBarEl = document.querySelector(`[data-year='${activeInstallationPeriod[1]}']`)

      const activeInstallationBarMargins = this.getMargin.replace('%', '') * 2

      if (!this.disableActivePeriod && activeInstallationPeriodStartBarEl && activeInstallationPeriodEndBarEl) {
        this.horizontalDistanceBetweenStarBarAndPeriodBar = `${activeInstallationPeriodStartBarEl.getBoundingClientRect().left - activeIinstallationPeriodEl.getBoundingClientRect().left}px`
        this.horizontalDistanceBetweenStartBarAndEndBar = `calc(${activeInstallationPeriodEndBarEl.getBoundingClientRect().left - activeInstallationPeriodStartBarEl.getBoundingClientRect().left}px - ${activeInstallationBarMargins}%)`
      }
    },

    getTooltip(index) {
 
      const item = this.projectionsByYear[index]

      const html = true
      let content = `${ formatStudyData(this.columnSelected.key, item[this.columnSelected.key]?.current || 0) }`
      return {
        html,
        content,
      }
    },

    onyNumbers(value) {
      return parseFloat(value.toString().replace('-', ''))
    }
  }
}
</script>

<style lang="scss" scoped>

.chart-wrapper {
  margin-top: 35px;
  height: 400px;
}

.chart-content-bars-wrapper {  
  &:after {
    content: "";
    width: 2px;
    height: 7px;
    background-color: #D7D2CB;
    position: absolute;
    bottom: -7px;
    margin-left: calc(50% - 2px);
  }

  .chart-content-bars-caption {
    bottom: -1.5rem;
  }

  &.is-label {
    + div .chart-content-bars-caption::before {
      content: "";
      background-color: white;
      position: absolute;
      left: -200%;
      width: 400%;
      bottom: 0;
      top: 13%;
      z-index: -1;
    }
    .chart-content-bars-caption {
      opacity: 1;
    }
  }

  &:hover {
    .chart-content-bars-caption {
      opacity: 1;
      z-index: 3;
    }
    + .chart-content-bars-wrapper .chart-content-bars-caption {
      opacity: 0;
    }
  }
}

.chart-content-bars-item {
  transition: height 0.45s cubic-bezier(.17,.67,.83,.67), background-color 0.45s cubic-bezier(.17,.67,.83,.67);
  transform-origin: bottom;
  height: 0;  
}
.chart-content-bars-caption {
  opacity: 0;
  transition: 300ms ease-in-out all;
}

.chart-axis-y-wrapper {
  position: relative;
  transition: 300ms ease-in-out all;
  padding-left: 30px;

  &:after {
    content: "";
    width: 1px;
    right: -1px;
    background-color: #D7D2CB;
    position: absolute;
    height: 30px;
    top: -30px;
  }
  
  .chart-axis-y {
    min-width: 20px;
    > div {
      position: relative;
      top: -20px
    }    
  }
}

.chart-axis-y-caption {
  position: absolute;
  left: 0;
  transform: rotate(270deg) translateX(-50%);
  transform-origin: left top;
  top: 45%;
  width: auto;
  white-space: nowrap;
}

.chart-content-x-lines {
  position: absolute;
  width: 100%;
  .chart-content-x-line {
    opacity: 0.4;
    border-top: 1px solid #CDCCCC;
    width: 100%;
    //margin-top: -px;
    &:first-of-type {
      display: none;
    }
  }
}



.is-positive {
  .chart-axis-y-wrapper {
    flex-direction: column-reverse;

    .chart-axis-y {
      &:first-of-type {
        height: 0;
        flex: 0;
      }
      &:last-of-type {
        > div {
          transform: translateY(15px);
        }
      }
    }
  }
}


.is-negative {
  .chart-axis-y-wrapper {
    .chart-axis-y {
      &:first-of-type {
        > div {
          transform: translateY(15px);
        }
      }
      &:last-of-type {
        height: 0;
        flex: 0;
      }
    }
  }
}

#active-installation-period {
  margin-top: 24px;
  transition: 300ms ease-in-out all;

  .bar {
    border-top: 1px solid #CDCCCC;
    position: relative;
    transition: inherit;
    &:before, &:after {
      content: "";
      position: absolute;
      background-color: #CDCCCC;
      height: 9px;
      width: 1px;
      transform: translateY(-50%);
    }
    &:after {
      right: 0;
    }
  }
}
</style>

<style lang="scss">
.tooltip-projections-chart {
  background: #FFFFFF;
  border: 2px solid #E4EEF2;
  box-sizing: border-box;
  box-shadow: 0px 24px 48px rgba(0, 42, 58, 0.1);
  border-radius: 10px;
  padding: 12px;

  .tooltip-inner {
    max-width: initial;
    padding: 0;
  }

  table {
    thead {
      border-bottom: 1px solid #E4EEF2;      
    }
    th {
      font-weight: bold;
      line-height: 16px;
      letter-spacing: 3px;
      text-transform: uppercase;
      font-size: 16px;
      color: #151C22;
      padding-bottom: 10px;
      padding-left: 15px;
      text-align: left;
    }

    tr {
      td {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 120%;
        color: #383B44;
        padding-top: 12px;
        padding-left: 15px;
        // padding-right: 24px;
        &.key {
          font-weight: bold;
          font-size: 14px;
          color: #151C22;
        }
      }
      &:first-of-type {
        td {
          padding-top: 22px;
        }
      }
      &.active td {
        color: red;
      }
    }
  }
}
</style>
