<template>
  <div
    v-click-outside="close"
    class="overflow-x-none psui-relative"
    :class="[cssClass, 'psui-space-y-6']"
    @keydown.up="navigate('up')"
    @keydown.down="navigate('down')"
    @keydown.enter="selectOnEnter()"
    @keydown.tab="selectOnEnter()"
    @keydown.esc="close()"
  >
    <p class="psui-text-h4 psui-font-bold psui-text-blue-70">
      Build your own policy from scratch for:
    </p>
    <div class="psui-flex psui-space-x-4 psui-items-center">
      <div class="psui-w-56">
        <PsDropdown
          ref="study_type_select"
          class="psui-w-full"
        >
          <template #dropdownTrigger>
            <div
              class="psui-el-dropdown-menu-item psui-flex psui-text-gray-80 psui-justify-between psui-items-center psui-w-full psui-bg-white psui-px-4 rounded psui-shadow-elevation-5 default-height psui-cursor-pointer"
            >
              <span class="psui-text-h5 psui-font-bold psui-text-blue-60">
                {{ getStudyTypeSelected.title }}
              </span>
              <PsIcon 
                icon="expand_more"
                class="psui-text-blue-60 psui-cursor-pointer"
                size="24"
                display="flex"
              />
            </div>
          </template>
          <template #items>
            <ul class="psui-w-full psui-divide-y psui-divide-gray-20 psui-rounded-lg psui-p-4">
              <li
                v-for="(study_type, index) in getStudyTypes"
                :id="`study-type-list-item-${index}`"
                :key="`dropdown-study-type-${study_type.id}`"
                tabindex="0"
                class="psui-w-full psui-flex psui-justify-between psui-items-center psui-p-4 psui-text-h5 psui-text-gray-80 psui-cursor-pointer hover:psui-bg-gray-10 focus:outline-none psui-transition"
                @click="setStudyTypeSelected(study_type)"
              >
                {{ study_type.title }}
              </li>
            </ul>
          </template>
        </PsDropdown>
      </div>

      <div>
        <span class="psui-text-h5 psui-text-gray-70">in</span>
      </div>
      
      <div class="jurisdiction--select psui-relative psui-flex-grow">
        <div class="input-wrapper psui-relative psui-pr-4 default-height">
          <div class="width-machine">
            {{ search ? search : placeholder }}       
          </div>
          
          <input
            :id="randomStringToInputIdAndName"
            ref="input"
            v-model="search"
            type="text"
            :label="label"
            :placeholder="placeholder"
            :class="inputClass"
            :name="randomStringToInputIdAndName"
            class="appearance-none psui-text-h5"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            @input="handleRequest()"
            @focus="onFocus"
            @blur="onBlur"
          >
          <transition
            enter-active-class="transition  ease-out duration-100 transform"
            enter-class="opacity-0 scale-95"
            enter-to-class="opacity-100 scale-100"
            leave-active-class="transition-all ease-in duration-100 transform"
            leave-class="opacity-100 scale-100"
            leave-to-class="opacity-0 scale-95"
          >
            <PsButton
              :label="changeTextBasedOnWidth"
              :disabled="isFocused || disableButton"
              size="big"
              layout="onlytext"
              class="psui-my-2"
              icon-class="psui-text-blue-60"
              :icon="icon"
              :icon-position="iconPosition"
              @click="$emit('click')"
            />
          </transition>
        </div>

        <transition
          enter-active-class="transition ease-out duration-100 transform"
          enter-class="opacity-0 scale-95"
          enter-to-class="opacity-100 scale-100"
          leave-active-class="transition ease-in duration-100 transform"
          leave-class="opacity-100 scale-100"
          leave-to-class="opacity-0 scale-95"
        >
          <div 
            v-if="search"
            class="psui-origin-top-right psui-absolute psui-left-0 psui-overflow-y-auto psui-mt-4 psui-rounded-lg psui-border psui-border-blue-10 psui-p-4 psui-shadow-elevation-20 psui-z-40 psui-bg-white psui-min-w-full max-h-1/3"
            :style="{ width: `${width}px`}"
            role="menu" 
            aria-orientation="vertical"
          >
            <AppLoader v-if="!requestFetched && search && !jurisdictions.length" />
            <div class="psui-w-full psui-flex psui-flex-wrap psui-bg-white">
              <div
                v-if="requestFetched && !jurisdictions.length"
                class="psui-w-full psui-p-3 psui-flex psui-flex-col"
              >
                <p class="psui-text-h5 psui-text-red-20">
                  Hmmmm
                </p>
                <p class="psui-text-small psui-text-gray-60">
                  Please check the spelling and try again
                </p>
              </div>
              <ul
                v-else
                class="psui-w-full psui-divide-y psui-divide-gray-20"
              >
                <li
                  v-for="(jurisdiction, index) in jurisdictions"
                  :id="`jurisdiction-list-item-${index}`"
                  :key="`dropdown-jurisdiction-${jurisdiction.id}`"
                  tabindex="0"
                  :class="{'psui-bg-gray-10' : index == currentSelected}"
                  class="psui-w-full psui-flex psui-justify-between psui-items-center psui-p-4 psui-text-h5 psui-text-gray-80 psui-cursor-pointer focus:psui-outline-none psui-transition"
                  @click="setJurisdiction(jurisdiction)"
                  @mouseover="currentSelected = index"
                >
                  <h3>{{ jurisdiction.title }}</h3>
                  <p class="psui-text-small psui-text-gray-50 psui-ml-auto psui-transition">
                    {{ jurisdiction.type }}
                  </p>
                  <PsIcon 
                    icon="arrow_forward"
                    size="24"
                    class="psui-text-blue-60 psui-transition"
                    :class="{'psui-ml-3' : index == currentSelected}"
                    :display="index == currentSelected ? 'flex' : 'none'"
                  />
                </li>
              </ul>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <div class="modal-dialog-footer psui-flex psui-justify-between psui-pb-4">
      <p class="psui-text-small psui-text-gray-60">
        Don’t worry, you can create as many policies as you want for <br>different building types and jurisdictions later.
      </p>

      <PsButton
        label="Next"
        size="big"
        class="psui-mr-px"
        :disabled="!getStudyTypeSelected || !jurisdictionSelected"
        @click="goToPolicyOptionsWithStudyType()"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { delayExecution } from '@/util/Functions'
import { randomString } from '@/util/Functions'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'

export default {
  name: 'HomeStudyTypeAndJurisdictionSelect',
  props: {
    width: {
      default: 244
    },
    focusOnMount: {
      default: false
    },
    shouldScroll: {
      default: false
    },
    inputClass: {
      default: 'psui-text-gray-40 psui-text-h5'
    },
    label: {
      type: String,
      default: 'Type a City or County'
    },
    selectOnlyFirstClimateZone: {
      type: Boolean,
      default: false
    },
    jurisdictionSelected: {
      type: [ Object, Boolean ],
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    iconPosition: {
      type: String,
      default: 'left'
    },
    buttonLabel: {
      type: String,
      default: ''
    },
    disableButton: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      search: '',
      showDropDown: true,
      jurisdictions: [],
      requestFetched: false,
      cancelToken: null,
      currentSelected: 0,
      isFocused: false,
      randomStringToInputIdAndName: randomString(16),
      shouldChangeLabelButton: false,
    }
  },
  computed: {
    getStudyTypeSelected() {
      if (this.lastStudyTypeVisited) {
        return this.$store.getters['globalEntities/StudyType/getterGlobalStudyType']({ slug: this.lastStudyTypeVisited })
      }
      return this.$store.getters['globalEntities/StudyType/getterGlobalStudyType']({ slug: STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS })
    },
    getLastJurisdictionVisited() {
      return this.$store.getters['lastJurisdictionVisited']
    },
    placeholder() {
      return this.jurisdictionAsText && !this.isFocused ? this.jurisdictionAsText : this.label
    },
    jurisdictionAsText() {
      return this.jurisdictionSelected?.title_type && !this.shouldChangeLabelButton ? 
      this.$options.filters.editJurisdictionTitleTypeBasedOnJurisdictionType(this.jurisdictionSelected, 'title_type') :
      this.jurisdictionSelected?.title_type ? 
      `Exploring resources for ${this.$options.filters.editJurisdictionTitleTypeBasedOnJurisdictionType(this.jurisdictionSelected, 'title_type')}` :
      false
    },
    cssClass() {
      let cssClass = []
      
      if (this.jurisdictionSelected) {
        cssClass.push('is-selected')
      }
      
      if (this.isFocused) {
        cssClass.push('is-focused')
      }
      
      if (this.showDropDown) {
        cssClass.push('is-searching')
      }

      return cssClass
    },
    changeTextBasedOnWidth(){
      return this.shouldChangeLabelButton ? 'Explore' : this.buttonLabel
    },
    getStudyTypes() {
      return this.$store.getters['globalEntities/StudyType/getterGlobalStudyTypes']()
    },
    ...mapGetters(['lastStudyTypeVisited'])
  },
  mounted() {
    window.addEventListener('resize', this.setTextBasedOnWidthSize)

    if(this.focusOnMount) this.$refs.input.focus()
  },
  beforeDestroy() {
    window.removeEventListener('resize',this.setTextBasedOnWidthSize)
  }, 
  methods: {
    async getJurisdictions() {
      this.cancelToken = this.$api.CancelToken.source()
      this.$api.get(`/app/jurisdictions/${this.search}`, {cancelToken: this.cancelToken.token })
        .then(({ data }) => {
          this.jurisdictions = data.jurisdictions
          this.requestFetched = true
          this.cancelToken = null
          this.currentSelected = 0
        })
        .catch( () => {})
    },
    close() {
      this.search = ''
      this.requestFetched = false
      this.$emit('closeInput')
      this.currentSelected = -1
      this.jurisdictions = []
    },
    onBlur() {
      this.isFocused = false
    },
    onFocus() {
      this.isFocused = true
      if(!this.shouldScroll) return
      let bounds = this.$refs.input.getBoundingClientRect()
      const scrollTop = (document.documentElement.clientWidth - bounds['top']) -30
      document.getElementById('main-content').scrollTo({
        top: scrollTop,
        left: 0,
        behavior: 'smooth'
      })
    },
    selectOnEnter() {
      if(this.currentSelected < 0) return
      const jurisdictionSelected = this.jurisdictions[this.currentSelected]
      if(this.jurisdictions.length) this.setJurisdiction(jurisdictionSelected)
    },
    setJurisdiction(jurisdiction) {
      this.$emit('change', jurisdiction)
      this.close()
      this.search = ''
      this.requestFetched = false
    },
    navigate (direction) {
      if(this.jurisdictions.length) {
        if (direction == 'up') {
          if(this.currentSelected > 0) {
            this.currentSelected--
            document.getElementById(`jurisdiction-list-item-${this.currentSelected}`).focus()
          }
        } else {
          if(this.currentSelected < this.jurisdictions.length-1) {
            this.currentSelected++
            document.getElementById(`jurisdiction-list-item-${this.currentSelected}`).focus()
          }
        }
      }
    },
    handleRequest() {
      if (this.cancelToken) {
        this.cancelToken.cancel("Operation canceled due to new request.")
      }
      if(!this.search || this.search == '') {
        this.jurisdictions = []
        return
      }
      this.requestFetched = false
      delayExecution(300, this.getJurisdictions)
    },
    setTextBasedOnWidthSize(){
      const getDocumentWidth = window.innerWidth
      if(getDocumentWidth <= 1280 && !this.shouldChangeLabelButton ) {
        this.shouldChangeLabelButton = true
      } else if(getDocumentWidth > 1280 && this.shouldChangeLabelButton) {
        this.shouldChangeLabelButton = false
      }
    },
    setStudyTypeSelected(study_type) {
      this.$refs?.['study_type_select']?.close()
      this.$store.dispatch('setUserLastStudyTypeVisited', { value: study_type.slug, context: 'updateLastStudyTypeVisitedFromHome'})
    },
    goToPolicyOptionsWithStudyType() {
      this.$router.push({
        name:'PolicyOptions',
        query: { 
          only_study_type: this.getStudyTypeSelected.slug,
          policy_option: 'build-your-own'
        }
      })
      this.$parent.close()
    }
  }
}
</script>

<style lang="scss" scoped>
  .default-height {
    min-height: 60px;
  }

  .jurisdiction--select {
    background: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.03), 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    input::placeholder {
      color: #318FAC;
      font-size: 18px;
      line-height: 120%;
    }

    &.is-selected {
      input::placeholder {
        color: #E0EFF6;
      }
    }
  }

  .input-wrapper {
    position: relative;
    overflow: visible;
    width: 100%;
    display: inline-flex;
    font-weight: 700;
    
    .width-machine {
      opacity: 0;
      background: #318FAC;
      display: inline-flex;
      align-items: center;
      position: relative;
      min-width: 320px;
      font-size: 18px;
      line-height: 120%;
    }

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      font-weight: 700;
      top: 0; 
      left: 0;
      color: #318FAC;
      padding: 19px 16px;
      border-radius: 10px;
    }

    .psui-el-button {
      position: relative;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  input:-webkit-credentials-autofill:focus,
  input:-webkit-credentials-autofill:focus,
  input::-webkit-contacts-auto-fill {
    visibility: hidden !important;
  }

  ::v-deep {
    .psui-el-button.size-big.icon-left i {
      margin: 0;
    }

    .input-wrapper .psui-el-button {
      padding: 0;
    }

    .psui-el-dropdown-menu-dialog-wrapper {
      margin-top: 18px;
    }
  }
</style>