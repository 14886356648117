import BuildingTypeApiService from '@/services/api/BuildingTypeApiService'

export const BUILDING_TYPES_DB_SLUGS = {
  RESIDENTIAL: 'residental-buildings',
  NON_RESIDENTIAL: 'non-residental-buildings'
}

export default class BuildingType extends BuildingTypeApiService {
  constructor(obj = {}) {
    super()
    this.id = obj.id ?? null
    this.title = obj.title ?? ''
    this.content = obj.content ?? ''
    this.icon = obj.icon ?? ''
    this.slug = obj.slug ?? ''

    this.type_prototypes = obj.type_prototypes ?? []
    this.custom_fields = obj.custom_fields ?? null
  }
}