<template>
  <BaseModal
    ref="baseModal"
    modal-dialog-classes="psui-w-full psui-max-w-sm"
    :disable-close="true"
    @close="closeModal"
  >
    <div class="psui-w-full psui-flex psui-flex-col psui-items-start">
      <h2 class="psui-text-h4 psui-font-bold psui-text-gray-80 psui-mb-4">
        {{ `Are you sure you want to ${discardTypeLabel} this comparison?` }}
      </h2>
      <p class="psui-text-p psui-text-gray-50">
        You won’t be able to see this comparison anymore.
      </p>
      <div class="psui-flex psui-space-x-2 psui-mt-6">
        <PsButton
          data-test-id="confirm-delete-comparison-button"
          size="big"
          :label="getDiscardButtonTitle | replaceAllHyphenAndAddUppercaseToFirstLetter"
          layout="caution"
          :loading="isLoading"
          @click="deleteComparison()"
        />
        <PsButton
          size="big" 
          label="Cancel"
          layout="onlytext"
          @click="closeModal()"
        />
      </div>
    </div>
  </BaseModal>
</template>

<script>
import ComparisonApiService from '@/services/api/ComparisonApiService'
export default {
  name: 'ModalDeleteComparison',
  data: () => ({
    comparison_id : false,
    title: '',
    isLoading : false,
    discardTypeLabel: null
  }),
  computed: {
    validationTitle() {
      if(!this.title) return { hasError: true, label: 'Please inform the Comparison Name'}
      return { hasError: false }
    },
    getDiscardButtonTitle(){
      return `${this.discardTypeLabel} comparison`
    }
  },
  mounted() {
    this.$eventBus.$on('openModalDeleteComparison', ({ comparison, discardTypeLabel}) => {
      this.$refs.baseModal.showModal = true
      this.discardTypeLabel = discardTypeLabel
      this.comparison_id = comparison.id
      this.title = comparison.title
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openModalDeleteComparison')
  },
  methods: {
    deleteComparison() {
      if (this.isLoading) return 

      this.isLoading = true      
            
      ComparisonApiService.delete(this.comparison_id)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit('comparison/removeUserComparison', { id: this.comparison_id })
            this.$appToast('Comparison deleted successfuly!')
            this.isLoading = false

            if (this.$route.name !== 'ComparisonList') {
              this.$router.push({ name: 'ComparisonList' })
            }

            this.$refs.baseModal.showModal = false
          }
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    closeModal() {
      this.$refs.baseModal.showModal = false
    }
  }
}
</script>