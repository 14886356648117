<template>
  <div>
    <div class="psui-w-full psui-flex psui-flex-col psui-items-start psui-mt-6">
      <p class="text-deepsky-900 text-accent-1 psui-font-bold psui-mb-3">
        Rename Your Comparison
      </p>

      <InputWithLabel
        v-model="newTitle"
        :label="'Comparison Name'"
        :validation="validationTitle"
        @keydown.enter="duplicateComparison"
      />

      <PsButton
        data-test-id="confirm-duplicate-comparison-button"
        class="mt-6"
        label="Duplicate"
        size="big"
        :disabled="isLoading"
        @click="duplicateComparison()" 
      />
    </div>
  </div>
</template>

<script>
  import ComparisonApiService from '@/services/api/ComparisonApiService'

  export default {
    name: 'ComparisonFormDuplicate',
    props: [ 'comparisonId', 'title' ],
    data: () => ({
      newTitle: '',
      isLoading: false,
    }),
    computed: {
      validationTitle() {
        if ( !this.title ) return { hasError: true, label: 'Please inform the Comparison Name'}
        return { hasError: false }
      }
    },
    mounted() {    
      this.newTitle = this.title
    },
    methods: {
      duplicateComparison() {
        if (this.isLoading) return 
        this.isLoading = true
        
        ComparisonApiService.duplicate({ comparison_id: this.comparisonId, title: this.newTitle})
          .then(( comparison ) => {
            this.$appToast({ message: 'Comparison duplicated. Now you can edit it.' })
            this.$store.dispatch('comparison/getUserComparison', { comparison_id: comparison.id })
            
            comparison.policies.forEach( async ( policy ) => {
              await this.$store.dispatch('policy/addOrUpdatePolicyToUserOrSharedLists',{policy})
            })

            this.$store.dispatch('policy/attachPoliciesToUser')

            this.$emit('store-succcess')
            this.isLoading = false
          })
      },
    }
  }
</script>