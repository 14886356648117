export default {
  methods: {

    checkQueryHasKey(key) {
      return typeof this.$route.query[key] === 'undefined' || !this.$route.query[key] ? false : true
    },

    checkQueryKeyValue(key, value) {
      if(!this.$route.query[key] || !this.$route.query[key].length) return false
      const items = Array.isArray(this.$route.query[key]) ? this.$route.query[key] : this.$route.query[key].split(',')
      if(items.length) {
        return items.filter(item => item == value).length > 0
      } else {
        return this.$route.query[key] == value
      }
    },

    toggleQueryKeyValue(key, value) {
      this.$router.push({ query: { ...this.onlyToggleQueryKeyValue(key, value) } })
    },

    onlyToggleQueryKeyValue(key, value, newQuery = null) {
      if(!newQuery) newQuery = Object.assign({}, this.$route.query)
      if(!newQuery[key]) {
        newQuery[key] = typeof value == 'string' ? value : value.toString()
      } else {
        const isArray = Array.isArray(newQuery[key]) || newQuery[key].split(',')
        //If array of items
        if(isArray.length) {
          const hasItem = isArray.filter(item => item == value)
          if(hasItem.length) {
            const itemsFiltered = isArray.filter(item => item != value)
            if(!itemsFiltered.length) {
              delete newQuery[key]
            } else {
              newQuery[key] = itemsFiltered.join(',')
            }
          } else {
            isArray.push(value)
            newQuery[key] = isArray.join(',')
          }
        } else {
          newQuery[key] = value.toString()
        }
      }
      return newQuery
    },

    getQueryKey(key) {
      if(!this.$route.query[key]) return []
      return this.$route.query[key].split(',')
    },

    getQueryParameters() {
      const params = Object.assign({}, this.$route.params)
      for(const key of Object.keys(params)) {
        if(typeof params[key] == 'undefined' || params[key] == null) delete params[key]
      }
      const query = Object.assign({}, this.$route.query)
      for(const key of Object.keys(query)) {
        if(typeof query[key] == 'undefined' || query[key] == null) delete query[key]
        query[key] = this.getQueryKeyItems(key, query)
      }
      return { ...params, ...query }
    },

    getQueryKeyItems(key, query = null) {
      query = query ? query : Object.assign({}, this.$route.query)
      if(!query[key]) return []
      if(typeof query[key] == 'string') return query[key].split(',')
      return query[key]
    },

    pushToParams(key, value) {
      const params = Object.assign({}, this.$route.params)
      if(typeof key == 'object') {
        const keys = Object.keys(key)
        for(const k of keys) {
          params[k] = key[k]
        }
      } else {
        params[key] = value
      }
      const query = Object.assign({}, this.$route.query)
      params[key] = value
      this.$router.push({ params: { ...params }, query }, () => {})
    },

    setQueryValue(key, value, rrc = null) {
      if (!rrc) console.warn('Please, register your rrc')
      this.$router.push({ ...this.$route, params: { ...this.$route.params, rrc }, query: { ...this.$route.query, [key]: value }}, () => {})
    },

    removeQueryValue(key) {
      const query = Object.assign({}, this.$route.query)
      delete query[key]
      this.$router.push({ ...this.$route, query }, () => {})
    },

    getStudyResultsRoute({ jurisdiction_slug = null, climate_zone_raw = null, queryRoute } = {}) {
      jurisdiction_slug = jurisdiction_slug || this.$store.getters['lastJurisdictionVisited']?.slug || this.$route.params.jurisdiction_slug
      climate_zone_raw = climate_zone_raw || this.$store.getters['lastClimateZoneVisited']?.raw  || this.$route.params.climate_zone_raw
      return {
        name: 'StudyResults',
        params: {
          jurisdiction_slug,
          climate_zone_raw
        },
        query:{
          only_study_type: queryRoute
        }
      }
    }
  }
}