<template>
  <div>
    <div class="flex gap-1 px-6 my-6 ">
      <PsRichTooltip
        layout="blue"
        title="New Construction policies are not available yet."
        class="inline-flex"
      >
        <template #trigger>
          <PsButton
            :label="'Save to policy' | policyTextRephrase"
            icon="add_circle"
            size="medium"
            disabled
          />
        </template>
      </PsRichTooltip>
 
      <PsButton
        data-test-id="assumptions-nonresidential-new-restore-default-button"
        label="Restore default"
        icon="restart_alt"
        size="medium"
        layout="ghost"
        :disabled="!isNotDefaultState"
        @click="$store.commit('assumptions/buildingEstimates/clearCustomBuildingEstimates')"
      />
    </div>
    <AssumptionsGrowthWidget
      :specify-by-options="getSpecifyByOptionsFilteredByRoute"
      :specify-by.sync="specifyBy"
      header-title="Square Footage Growth"
      :building-type="building_type"
    />
    <div
      class="mx-7"
    >
      <h2 class="font-bold psui-text-gray-80 mb-1">
        Total forecast for 2023-2025
      </h2>
 
      <ul class="flex justify-between psui-text-small psui-text-gray-60">
        <li>Forecast units for {{ jurisdiction.climate_zones.length }} climate zones</li>
        <li class="psui-text-gray-80">
          {{ formatUnits($store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits'](getBaseFilters)) }}
        </li>
      </ul>
 
      <hr class="psui-border-blue-70 opacity-10 mt-7">
    </div>
    <div
      v-if="getIfClimateZoneFilterShouldBeShown"
      class="mx-7"
    >
      <AssumptionsBuildingEstimatesClimateZones      
        :climate_zone_prefix="getClimateZonePrefixSelected"
        :climate_zones="jurisdiction.climate_zones"
        :specify-by="specifyBy"
        :filters="{...getBaseFilters, climate_zone_prefix }"        
        @setClimateZonePrefix="climate_zone_prefix = $event"
      />
      <ul class="flex justify-between psui-text-small psui-text-gray-60">
        <li>Forecast units in selected climate zone</li>
        <li class="psui-text-gray-80">
          {{ formatUnits($store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits'](getFilters)) }}
        </li>
      </ul>
      <hr class="psui-border-blue-70 opacity-10 mt-7">
    </div>
    <div
      :is="getComponent"
      :specify-by="specifyBy"
      :jurisdiction="jurisdiction"
      :climate_zones="jurisdiction.climate_zones"
      :type_prototypes_ids="type_prototypes_ids"
      :type_prototypes="getTypePrototypeByBuildingType"
      :climate_zone_prefix="getClimateZonePrefixSelected"
      :filters="getFilters"
      class="mx-7"
    />
  </div>
</template>
 
<script>
import AssumptionsBuildingEstimatesClimateZones from '../AssumptionsBuildingEstimatesClimateZones.vue'
import AssumptionsNonResidentialNewBuildingTypes from './AssumptionsNonResidentialNewBuildingTypes.vue'
import AssumptionsNonResidentialNewOverall from './AssumptionsNonResidentialNewOverall.vue'
import AssumptionsNonResidentialNewYears from './AssumptionsNonResidentialNewYears.vue'
import AssumptionsGrowthWidget from '../AssumptionsGrowthWidget.vue'
import { RESIDENTIAL_NEW_SPECIFICATIONS, BUILDING_ESTIMATES_BASE_YEARS } from '@/modules/app/assumptions/shared/enums'
import DataAndObjectFunctions from '@/util/DataAndObjectFunctions'
import { createArrayOfBuildingEstimatesFilter }from '@/util/Helpers.js'
import formatNumber from '@/formatters/formatNumber'

export default {
  name: 'AssumptionsNonResidentialNew',
  components: {
    AssumptionsGrowthWidget,
    AssumptionsNonResidentialNewBuildingTypes,
    AssumptionsBuildingEstimatesClimateZones
  },
  props: {
    jurisdiction: [ Boolean, Object ],
    building_type: [ Boolean, Object ],
    type_prototypes: [ Boolean, Array ]
  },
  data: () => ({
    specifyByOptions : RESIDENTIAL_NEW_SPECIFICATIONS,
    specifyBy: [],
    climate_zone_prefix: false
  }),
  computed: {
    getComponent() {
      if (this.specifyBy.length === 0 || this.specifyByIncludesOnlyClimateZone) {
        return AssumptionsNonResidentialNewOverall
      } else if (this.specifyByIncludesOnlyYear || (this.specifyByIncludesYear && !this.specifyByIncludesBuildingType) ) {
        return AssumptionsNonResidentialNewYears
      } else if (this.specifyByIncludesBuildingType) {
        return AssumptionsNonResidentialNewBuildingTypes
      } else {
        return false
      }
    },
    //old
    // type_prototypes_ids() {
    //   return this.building_type ? this.building_type.type_prototypes.map(i => i.id ) : false
    // },
    type_prototypes_ids(){
      return this.getTypePrototypeByBuildingType.map(tp => tp.id)
    },
    specifyByIncludesClimateZone() {
      return this.specifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.CLIMATE_ZONE.key)
    },
    specifyByIncludesOnlyClimateZone() {
      return this.specifyBy.length === 1 && this.specifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.CLIMATE_ZONE.key)
    },
    specifyByIncludesBuildingType() {
      return this.specifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.BUILDING_TYPE.key)
    },
    specifyByIncludesYear() {
      return this.specifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.YEAR.key)
    },
    specifyByIncludesOnlyYear() {
      return this.specifyBy.length === 1 && this.specifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.YEAR.key)
    },
    getBaseFilters() {
      return { type_prototype_id: this.type_prototypes_ids, year: BUILDING_ESTIMATES_BASE_YEARS }
    },
    getFilters() {
      const filters = { ...this.getBaseFilters }
      if (this.getClimateZonePrefixSelected) filters.climate_zone_prefix = this.getClimateZonePrefixSelected
      return filters
    },
    getClimateZonePrefixSelected() {
      if (!this.specifyByIncludesClimateZone) return false
      if(!this.climate_zone_prefix) return this.jurisdiction.climate_zones[0].prefix
      return this.climate_zone_prefix
    },
    getTypePrototypeByBuildingType(){
      return this.type_prototypes?.filter(tp => tp.building_type_id === this.building_type.id)
    },
    getAssumptionsDrawerOptions(){
      return this.$store.getters['assumptions/getterAssumptionsDrawerOptions']
    },
    getterLastClimateZonesVisited(){
      return this.$store.getters['lastClimateZonesVisited']
    },
    getSpecifyByOptionsFilteredByRoute(){
      const allowedFilterProperties = ['BUILDING_TYPE','YEAR']
      if(this.$route.name === 'BuildingEstimates' && this.getQueryKey('exclude_climate_zone_prefix').length === 0){
        return DataAndObjectFunctions.filterObjectKeys(allowedFilterProperties,RESIDENTIAL_NEW_SPECIFICATIONS) 
      }
      return RESIDENTIAL_NEW_SPECIFICATIONS
    },
    getIfClimateZoneFilterShouldBeShown(){
      if(this.$route.name === 'BuildingEstimates' && this.specifyByIncludesClimateZone) {
        return this.getQueryKey('exclude_climate_zone_prefix').length !== 0 
      }
      return this.specifyByIncludesClimateZone && this.lastJurisdictionVisitedClimateZonesLength > 1 
    },
      isNotDefaultState(){
      const getArrayOfFilters = createArrayOfBuildingEstimatesFilter({typePrototypeIdArray:this.getFilters.type_prototype_id, yearArray:this.getFilters.year})
      return getArrayOfFilters.some(filter => {
        return formatNumber(this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits'](filter)) != formatNumber(this.$store.getters['assumptions/buildingEstimates/getterDefaultJurisdictionBuildingEstimateUnits'](filter))
      })
    }
  },
  mounted(){
    if(this.getAssumptionsDrawerOptions.isEditingFromChart) {
      this.specifyBy.push(this.specifyByOptions["BUILDING_TYPE"].key, this.specifyByOptions["CLIMATE_ZONE"].key)
      this.climate_zone_prefix = this.getterLastClimateZonesVisited[0]["prefix"]
    }

    this.$eventBus.$on('watchToScrollToElement', () => {
      const getClimateZonesSelectedWithinBuildingEstimatesClimateZoneFilter = this.jurisdiction.climate_zones
      .filter(climate_zone => !this.getQueryKey('exclude_climate_zone_prefix')
      .includes(climate_zone.prefix.toString())) 
      if(getClimateZonesSelectedWithinBuildingEstimatesClimateZoneFilter.length === 1){        
        this.climate_zone_prefix = getClimateZonesSelectedWithinBuildingEstimatesClimateZoneFilter.shift().prefix
      }
    })
    
    this.setRetirectTo()
  },
  methods: {
    openAssumptionsModal() {
      const eventName = 'openAssumptionsModal'
      const eventPayload = 'ESTIMATES'
      this.$eventBus.$emit(eventName, eventPayload)
      this.$store.commit('general/setRedirectTo', { route: this.$route.path, event: { name: eventName, payload: eventPayload } })
    },
    setRetirectTo() {
      const event = {
        name: 'openAssumptionDrawerFromAppBoot',
        payload: this.$store.getters['assumptions/getterAssumptionsDrawerOptions']
      }
      this.$store.commit('general/setRedirectTo', { route: this.$route.fullPath, event })
    }
  }
}
</script>
 
<style lang="scss" scoped>
 
  ::v-deep .psui-el-tooltip {
    flex-grow: 1;
    .psui-el-tooltip-wrapper {
      .psui-el-tooltip-dialog {
        width: 180px;
        z-index: 50;
      }
    }
 
    .psui-el-tooltip-trigger {
      display: flex;
 
      button {
        flex-grow: 1;
        display: flex;
       
      }
    }
  }
 
</style>
