<template>
  <div>
    <div class="px-6">
      <div class="flex justify-between gap-1 mb-6">
        <PsButton
          :label="'Save to policy' | policyTextRephrase"
          icon="add_circle"
          size="medium"
          @click="openAssumptionsModal()"
        />

        <PsButton
          data-test-id="assumptions-residential-new-restore-default-button"
          label="Restore default"
          icon="restart_alt"
          size="medium"
          layout="ghost"
          :disabled="!isNotDefaultState"
          @click="restoreToDefault()"
        />
      </div>
    </div>

    <AssumptionsBuildingEstimatesUserSign 
      v-if="isNotDefaultState && !userIsLoggedIn"
      class="mx-6"
      :session-storage-key="'assumptions-building-estimates-user-sign-residential-new'"
    />
    <AssumptionsGrowthWidget 
      :specify-by-options="getSpecifyByOptionsFilteredByRoute" 
      :specify-by.sync="specifyBy"
      :building-type="building_type"
      header-title="Specify By"
    />
    <div
      v-if="jurisdiction && specifyBy.length === 0"
      class="mx-7"
    >
      <h2 class="font-bold psui-text-gray-80 mb-1 psui-text-big">
        Residential Units as of 2020
      </h2>
      <ul class="flex justify-between psui-text-small psui-text-gray-60">
        <li>Total units in {{ jurisdiction.climate_zones.length }} climate zones</li>
        <li class="psui-text-gray-80">
          {{ formatUnits($store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({})) }}
        </li>
      </ul>
      <hr class="psui-border-blue-70 opacity-10 my-7">
    </div>
    
    <div
      v-if="specifyBy.length === 0"
      class="mx-7"
    >
      <h2 class="font-bold psui-text-gray-80 mb-1">
        New units forecasted for 2023-2025
      </h2>

      <ul class="flex justify-between psui-text-small psui-text-gray-60">
        <li>Forecast units for {{ jurisdiction.climate_zones.length }} climate zones</li>
        <li class="psui-text-gray-80">
          {{ formatUnits($store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits'](getBaseFilters)) }}
        </li>
      </ul>

      <hr class="psui-border-blue-70 opacity-10 mt-7">
    </div>

    <div
      v-if="getIfClimateZoneFilterShouldBeShown"
      class="mx-7"
      :class="{'mb-7':specifyByIncludesBuildingType}"
    > 
      <AssumptionsBuildingEstimatesClimateZones       
        :climate_zone_prefix="getClimateZonePrefixSelected"
        :climate_zones="jurisdiction.climate_zones"
        :specify-by="specifyBy"
        :filters="{...getBaseFilters, climate_zone_prefix }"        
        @setClimateZonePrefix="climate_zone_prefix = $event"
      />
      <ul class="flex justify-between psui-text-small psui-text-gray-60">
        <li>Forecast units in selected climate zone</li>
        <li class="psui-text-gray-80">
          {{ formatUnits($store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits'](getFilters)) }}
        </li>
      </ul>
    </div>
    <div 
      :is="getComponent"
      :specify-by="specifyBy"
      :jurisdiction="jurisdiction"
      :climate_zones="jurisdiction.climate_zones" 
      :type_prototypes_ids="type_prototypes_ids"
      :type_prototypes="building_type.type_prototypes"
      :climate_zone_prefix="getClimateZonePrefixSelected"
      :filters="getFilters"
      class="mx-7"
    />
  </div>
</template>

<script>
import AssumptionsBuildingEstimatesClimateZones from '../AssumptionsBuildingEstimatesClimateZones.vue'
import AssumptionsResidentialNewBuildingTypes from '@/modules/app/assumptions/building-estimates/residential/AssumptionsResidentialNewBuildingTypes.vue'
import AssumptionsResidentialNewOverall from './AssumptionsResidentialNewOverall.vue'
import AssumptionsResidentialNewYears from './AssumptionsResidentialNewYears.vue'
import AssumptionsGrowthWidget from '../AssumptionsGrowthWidget.vue'
import { RESIDENTIAL_NEW_SPECIFICATIONS, BUILDING_ESTIMATES_BASE_YEARS } from '@/modules/app/assumptions/shared/enums'
import AssumptionsBuildingEstimatesUserSign from '@/modules/app/assumptions/building-estimates/AssumptionsBuildingEstimatesUserSign.vue'
import { mapGetters } from "vuex"
import DataAndObjectFunctions from '@/util/DataAndObjectFunctions'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import { createArrayOfBuildingEstimatesFilter }from '@/util/Helpers.js'
import formatNumber from '@/formatters/formatNumber'

export default {
  name: 'AssumptionsResidentialNew',
  components: { 
    AssumptionsGrowthWidget, 
    AssumptionsResidentialNewBuildingTypes, 
    AssumptionsBuildingEstimatesClimateZones,
    AssumptionsBuildingEstimatesUserSign 
  },
  props: {
    jurisdiction: [ Boolean, Object ],
    building_type: [ Boolean, Object ],
    getTabSelected:[String, Object]
  },
  data: () => ({
    specifyByOptions : RESIDENTIAL_NEW_SPECIFICATIONS,
    specifyBy: [],
    climate_zone_prefix: false,
  }),
  computed: {
    ...mapGetters(['lastJurisdictionVisited', 'lastClimateZonesVisited']),
    getComponent() {
      if (this.specifyBy.length === 0 || this.specifyByIncludesOnlyClimateZone) {
        return AssumptionsResidentialNewOverall
      } else if (this.specifyByIncludesOnlyYear || (this.specifyByIncludesYear && !this.specifyByIncludesBuildingType) ) {
        return AssumptionsResidentialNewYears
      } else if (this.specifyByIncludesBuildingType) {
        return AssumptionsResidentialNewBuildingTypes
      } else {
        return false
      }
    },
    type_prototypes_ids() {
      return this.building_type ? this.building_type.type_prototypes.map(i => i.id ) : false
    },
    specifyByIncludesClimateZone() {
      return this.specifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.CLIMATE_ZONE.key)
    },
    specifyByIncludesOnlyClimateZone() {
      return this.specifyBy.length === 1 && this.specifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.CLIMATE_ZONE.key)
    },
    specifyByIncludesBuildingType() {
      return this.specifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.BUILDING_TYPE.key)
    },
    specifyByIncludesYear() {
      return this.specifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.YEAR.key)
    },
    specifyByIncludesOnlyYear() {
      return this.specifyBy.length === 1 && this.specifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.YEAR.key)
    },
    getBaseFilters() {
      return { type_prototype_id: this.type_prototypes_ids, year: BUILDING_ESTIMATES_BASE_YEARS }
    },
    getFilters() {
      const filters = { ...this.getBaseFilters }
      if (this.getClimateZonePrefixSelected) filters.climate_zone_prefix = this.getClimateZonePrefixSelected
      return filters
    },
    getClimateZonePrefixSelected() {
      if (!this.specifyByIncludesClimateZone) return false
      if(!this.climate_zone_prefix || !this.lastJurisdictionVisited?.climate_zones.map(i => i.prefix).includes(this.climate_zone_prefix)) {
        return this.jurisdiction?.climate_zones[0]?.prefix
      } else {
        return this.climate_zone_prefix
      }
    },
    userIsLoggedIn() {
      return this.$store.getters.getterLoggedUser
    },
    getAssumptionsDrawerOptions(){
      return this.$store.getters['assumptions/getterAssumptionsDrawerOptions']
    },
    getterLastClimateZonesVisited(){
      return this.lastClimateZonesVisited
    },
    getterCustomJurisdictionBuildingEstimates() {
      const policy_id = (this.$route.params.policy_id) ? this.$route.params.policy_id : null
      return this.$store.getters['assumptions/buildingEstimates/getterCustomJurisdictionBuildingEstimates']({ policy_id })
    },
    lastJurisdictionVisitedClimateZonesLength(){
      return this.lastJurisdictionVisited?.climate_zones?.length
    },
    getIfClimateZoneFilterShouldBeShown(){
      if(this.$route.name === 'BuildingEstimates' && this.specifyByIncludesClimateZone) {
        return this.getIfRouteHasAClimateZoneExcluded 
      }
      return this.specifyByIncludesClimateZone && this.lastJurisdictionVisitedClimateZonesLength > 1 
    },
    getSpecifyByOptionsFilteredByRoute(){
      const allowedFilterProperties = ['BUILDING_TYPE','YEAR']
      if(this.$route.name === 'BuildingEstimates' && !this.getIfRouteHasAClimateZoneExcluded){
        return DataAndObjectFunctions.filterObjectKeys(allowedFilterProperties,RESIDENTIAL_NEW_SPECIFICATIONS) 
      }
      return RESIDENTIAL_NEW_SPECIFICATIONS
    },
    getIfRouteHasAClimateZoneExcluded(){
      return this.getQueryKey('exclude_climate_zone_prefix').length !== 0
    },
    getIfUserHasNewBuildingPolicies(){
      return this.$store.getters['policy/getterUserPoliciesByJurisdiction'].filter(policy =>{
        const getPolicyType = this.$store.getters['policy/getterPolicyStudyTypes']({policy_id: policy.id,policy})
        return getPolicyType.includes(STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS)
      }).length > 0
    },
    isNotDefaultState(){
      const getArrayOfFilters = createArrayOfBuildingEstimatesFilter({climateZonePrefix:this.climate_zone_prefix,typePrototypeIdArray:this.getFilters.type_prototype_id, yearArray:this.getFilters.year})
      return getArrayOfFilters.some(filter => {
        return formatNumber(this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits'](filter)) != formatNumber(this.$store.getters['assumptions/buildingEstimates/getterDefaultJurisdictionBuildingEstimateUnits'](filter))
      })
    }
  },
  mounted(){
    if(this.getQueryKey('exclude_climate_zone_prefix').length === 0) {
      this.specifyBy.push(this.specifyByOptions["BUILDING_TYPE"].key)
    } else {   
      this.specifyBy.push(this.specifyByOptions["BUILDING_TYPE"].key, this.specifyByOptions["CLIMATE_ZONE"].key)
      const cZPrefixFromPolicyEditRequirementsNBSF2022 = this.getAssumptionsDrawerOptions.lastClimateZoneSelected
      if(!cZPrefixFromPolicyEditRequirementsNBSF2022 || !this.lastJurisdictionVisited?.climate_zones.map(i => i.prefix).includes(cZPrefixFromPolicyEditRequirementsNBSF2022)) {
        this.climate_zone_prefix = this.getterLastClimateZonesVisited[0]['prefix']
      } else {
        this.climate_zone_prefix = cZPrefixFromPolicyEditRequirementsNBSF2022
      }
    }

    this.$eventBus.$on('watchToScrollToElement', () => {
      const getClimateZonesSelectedWithinBuildingEstimatesClimateZoneFilter = this.jurisdiction.climate_zones
      .filter(climate_zone => !this.getQueryKey('exclude_climate_zone_prefix')
      .includes(climate_zone.prefix.toString())) 
      if(getClimateZonesSelectedWithinBuildingEstimatesClimateZoneFilter.length === 1){        
        this.climate_zone_prefix = getClimateZonesSelectedWithinBuildingEstimatesClimateZoneFilter.shift().prefix
      }
    })

  },
  methods: {
    openAssumptionsModal() {
      if(!this.getIfUserHasNewBuildingPolicies) {
        this.$router.push({
          name: 'PolicyOptions',
          query: { only_study_type: STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS },
        })
        return
      }

      const eventName = 'openAssumptionsModal'
      const eventPayload = this.getTabSelected.slug
      this.$eventBus.$emit(eventName, {eventPayload, userHasPolicies:this.getIfUserHasNewBuildingPolicies})
      this.$store.commit('general/setRedirectTo', { route: this.$route.path, event: { name: eventName, payload: eventPayload } })
    },
    restoreToDefault() {
      this.$store.dispatch('assumptions/buildingEstimates/clearCustomBuildingEstimates', this.getterCustomJurisdictionBuildingEstimates)
    },
  }
}
</script>

<style lang="scss" scoped>
  
  ::v-deep .psui-el-tooltip {
    flex-grow: 1;
    .psui-el-tooltip-wrapper {
      .psui-el-tooltip-dialog {
        width: 180px;
        z-index: 50;
      }
    }

    .psui-el-tooltip-trigger {
      display: flex;

      button {
        flex-grow: 1;
        display: flex;
        
      }
    }
  }

</style>
