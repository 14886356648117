import GraphQL from '@/util/GraphQL'

export default {
  namespaced: true,
  
  state: {
    type_vintages: []
  },

  getters: {    
    getterGlobalTypeVintages: (state) => (filters = null) => {
      return filters ? state.type_vintages.findAllInArray(filters) : state.type_vintages
    },

    getterGlobalTypeVintage: (state) => (filters = null) => {
      return filters ? state.type_vintages.findInArray(filters) : false
    },
  },

  actions: {
    loadGlobalTypeVintages(context) {
      const query = `
        {
          type_vintages (
            orderBy: [{ column: "order", order: "asc"}]
          ) {
            id
            title
            slug
            color
            title_long
            flexible_path_title
            hide_in_building_estimates
            order
          }
        }
      `
      GraphQL({ query })
        .then(({ data }) => {
          context.commit('setGlobalTypeVintages', { type_vintages: data.type_vintages})
        })
    }
  },

  mutations: {
    setGlobalTypeVintages(state, { type_vintages }) {
      state.type_vintages = type_vintages
    }
  }
}