import Vue from 'vue'
import mainVuex from '@/store'
import mainRouter from '@/router'
import axios from 'axios'
import { getCache, setCache } from '@/util/RequestCache'

/**
 * Default headers
 */
axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Accept'] = 'application/json'

/**
 * Request interceptors
 */
axios.interceptors.request.use(
  config => {
    
    getCache(config)
    /**
     * Set authorization headers according type of user for the config
     */

    if(mainVuex.getters.getterLoggedUserToken) {
      config.headers.authorization = `Bearer ${mainVuex.getters.getterLoggedUserToken}`
    }

    config.headers['device-id'] = mainVuex.getters['general/getterDeviceInfo']?.id ?? null
    config.headers['app-version'] = mainVuex.getters['general/getterAppVersion']

    return config

  },
   (error) => {
    console.log(error)
    return Promise.reject(error)
  }
)

/**
 * Response interceptors
 */
axios.interceptors.response.use(function (response) {
  
  setCache(response)

  if(response?.data?.feedback) {
    if(!response.config.ignoreFeedback) Vue.$toast.success(response.data.feedback)
  }

  return response
}, function (error) {

  /**
   * Handle unauthenticated user
   * Check which type of user based on the request url
   * Clear the token
   * Set userLogged false
   */
  if(error.response && [401, 403].includes(error.response.status)) {

    // Handle unauthenticated users
    if(!['UserSignin', 'AdminSignIn'].includes(mainRouter.history.current.name)) {
      Vue.$toast.info(`Please login`)
      mainVuex.dispatch('logoutUser', { redirect: false, showNotification: false })
      if(mainRouter.currentRoute?.meta?.auth?.required) {
        if(mainRouter.currentRoute.meta.auth.is_admin) {
          mainRouter.push('/admin/login')
        } else {
          mainRouter.push('/login')
        }
      }
    }
  }

  // if(error.response.status == 500) {
  //   mainRouter.push('/')
  //   Vue.$toast.info(`Oops! Something went wrong while trying to connect to our servers.`)
  // }
  
  if(error.response?.data?.feedback) {
    if(!error.response.config.ignoreFeedback) Vue.$toast.error(error.response.data.feedback)
  }

  return Promise.reject(error)
})

export default axios
