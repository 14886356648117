import BasePdfService from '@/services/pdf/BasePdfService'
import formatStudyData from '@/formatters/formatStudyData'
import autoTable from 'jspdf-autotable'
import { POLICY_TYPE_PDF } from '@/util/Enums.js'

export default class BasePdfResultsTableService extends BasePdfService {

  constructor(args) {    
    super(args)
  }

  async addTables() {
    this.tables.map((table, tableIndex) => {
      const maxColums = 7
      const bodies = this.formatTableRowsWithFixedColumns(table, maxColums)
      const headers = this.formatTableHeadersWithFixedColumns(table, maxColums)            
      
      let lastHeaderIndex = 0

      headers.forEach((head, headerIndex) => {
        lastHeaderIndex = headerIndex
        const yPosition = lastHeaderIndex === tableIndex && this.doc?.lastAutoTable?.finalY ? this.doc?.lastAutoTable?.finalY + 20 : this.margins.top + 20      
        const body = bodies[headerIndex]

        if (headers.length > 1) {            
          head.splice(2,0, this.addTableIndexCaption({ colSpan : head[head.length - 1].length , tableIndex, headerIndex, headers }))
        }

        if (table.rows.length > 0) {
          autoTable(this.doc, {
            theme: 'plain',
            body,
            head, 
            startY: yPosition,
            styles: {
              font: 'Lato-Regular',
              fontSize: this.fontSizeDefault,
            },
            margin: {...this.margins, top: 0},
            willDrawCell: (data) => {
              // Fix margin of secondary pages, for some reason, secondary pages are not respecting startY...
              if (data.cell.raw?.isHeadline) {
                data.settings.margin = { ...data.settings.margin, top: data.pageCount > 0 ? this.margins.top + 20 : 0 }
              }
              this.onTableBreak(data)
            },
            didDrawCell: (data) => {
              
              this.addVerticalBlueBarALongTheHeadline(data, table)
              this.addBlueBottomLineBetweenTheColumLabel(data)
              if(table.type == POLICY_TYPE_PDF.COST_EFFECTIVENESS_EVIDENCE) {
                this.addRequiredMeasuresAndPackagesBottomLine(data)
              }
  
            } // didDrawCell()
  
          }) // autoTable
        }

        if (table.type === POLICY_TYPE_PDF.COST_EFFECTIVENESS_EVIDENCE) {
          if (tableIndex !== this.tables.length - 1 || headerIndex !== headers.length - 1) {
            if (table.rows.length > 0) {
              this.doc.lastAutoTable = 0
              this.addPage()
            }
          }
        } else if (table.rows.length > 0) {
          this.doc.lastAutoTable = 0
          if (tableIndex + 1 !== this.tables.length || headerIndex + 1 !== headers.length) {
            this.addPage()
          }
        }

      }) // headers.forEach((head, headerIndex) 

      if (table.type === POLICY_TYPE_PDF.COST_EFFECTIVENESS_EVIDENCE) {
        const yPositionFSU = this.doc?.lastAutoTable?.finalY ? this.doc?.lastAutoTable?.finalY + 20 : this.margins.top + 20
        this.addFlexibleSetUpTable(table, tableIndex, yPositionFSU)
      } 

    }) // tables.map((table, tableIndex)

  }
  
  async addFlexibleSetUpTable(table, tableIndex, yPosition) {

    let cEHeaders = [
      [
        [
          {
            content: 'Maximum Cost-Effective Target Score Calculation',
            colSpan: 6,
            isContentDivisionTitle: true,
            styles: {
              fontSize: 12,
              font: "Lato-Bold",
              textColor: this.colors.blue80,
              cellPadding: { 
                top: 0,
                right: 12 * 1.5,
                left: 0,
                bottom: this.fontSizeDefault - 2,
              }
            }
          },
        ],
        [
          {
            content: "Cost-Effective Measures and Packages",
            key: "title",
            isFirstHeaderColumn: true,
            isHeaderColumn: true,
            styles: {
              fontSize: 10,
              font: "Lato-Bold",
              valign: "top",
              halign: "left",
              cellPadding: {
                top: this.fontSizeDefault,
                right: this.fontSizeDefault * 1.5,
                bottom: 0
              }
            },
          },
          {
            content: "Energy Savings",
            groupKey: "fixed_columns",
            groupTitle: "",
            key: "energy_savings_combined",
            isHeaderColumn: true,
            styles: {
              fontSize: 10,
              font: "Lato-Bold",
              valign: "top",
              halign: "right",
              cellPadding: {
                top: this.fontSizeDefault,
                right: this.fontSizeDefault * 1.5,
              }
            }
          },
          {
            content: "Flexible Score",
            groupKey: "fixed_columns",
            groupTitle: "",
            key: "flexible_score",
            isHeaderColumn: true,
            styles: {
              fontSize: 10,
              font: "Lato-Bold",
              valign: "top",
              halign: "right",
              cellPadding: {
                top: this.fontSizeDefault,
                right: this.fontSizeDefault * 1.5,
              }
            }
          }
        ],
        [
          {
            content: "",
            key: "title",
            isDescriptionColumn: true,
            styles: {
              textColor: "#798490",
              font: "Lato-Regular",
              fontSize: 7,
              valign: "top",
              halign: "right",
              cellPadding: {
                right: this.fontSizeDefault * 1.5,
                top: 0,
                bottom: 0
              }
            },
          },
          {
            content: "site MMBtu/year",
            groupKey: "fixed_columns",
            groupTitle: "",
            key: "energy_savings_combined",
            isDescriptionColumn: true,
            styles: {
              textColor: "#798490",
              font: "Lato-Regular",
              fontSize: 7,
              valign: "top",
              halign: "right",
              cellPadding: {
                right: this.fontSizeDefault * 1.5,
              }
            }
          },
          {
            content: "",
            groupKey: "fixed_columns",
            groupTitle: "",
            key: "flexible_score",
            isDescriptionColumn: true,
            styles: {
              textColor: "#798490",
              font: "Lato-Regular",
              fontSize: 7,
              valign: "top",
              halign: "right",
              cellPadding: {
                right: this.fontSizeDefault * 1.5,
              }
            }
          },
        ]
      ]
    ]

    if (!table.rows.length > 0) {
      const tableHeadline = this.getTableHeaderHeadlineTitle({ headers: cEHeaders, ...table })
      const tableTitle = this.getTableHeaderHeadlineSubtitle(table)       
      cEHeaders[0].unshift([tableHeadline])
      cEHeaders[0].splice(1, 0, [tableTitle])
    }

    if (table.is_flexible_set_up) {

      const headers = [
        {
          key: "title",
          label: "Cost-Effective Measures and Packages"
        },
        {
          key: "energy_savings_combined",
          label: "Energy Savings",
          groupKey: "fixed_columns",
          groupTitle: "",
          description: "site MMBtu/year"
        },
        {
          key: "flexible_score",
          label: "Flexible Score",
          groupKey: "fixed_columns",
          groupTitle: "",
          description: ""
        }
      ]

      const maxColums = 3

      if(table.preset != undefined && table.preset.length) {          
        const newPresetTable = this.createNewPresetAndExcludedAndMandatoryMeasuresTable(table, 'preset', headers)
        const presetBodies = this.formatPresetAndExcludedAndMandatoryMeasuresTableRowsWithFixedColumns(newPresetTable, maxColums)
        presetBodies.forEach(body => this.addSumRow(body, 'preset', table?.calculation_method))

        if(table.excluded_and_mandatory_measures != undefined && table.excluded_and_mandatory_measures.length) { 
          const newEAndMMTable = this.createNewPresetAndExcludedAndMandatoryMeasuresTable(table, 'excluded_and_mandatory_measures', headers)
          const eAndMMBodies = this.formatPresetAndExcludedAndMandatoryMeasuresTableRowsWithFixedColumns(newEAndMMTable, maxColums)

          let lastBodiesPresetRow = [] 
          const presetBodiesCopy = JSON.parse(JSON.stringify(presetBodies))
          presetBodiesCopy.forEach((bPC) => {
            bPC.forEach(row => {
              if(row[0].isSumPresetRow) lastBodiesPresetRow = row
            })
          })

          eAndMMBodies[0].push(lastBodiesPresetRow)
          eAndMMBodies.forEach(body => this.addSumRow(body, 'excluded_and_mandatory_measures', table?.calculation_method))
          const eAndMMBodiesFiltered = eAndMMBodies[0].filter(body => !body.some(item => item.isSumPresetRow === true))
          presetBodies[0].push(...eAndMMBodiesFiltered)
        } 

        this.processHeaders(cEHeaders, presetBodies, tableIndex, yPosition)
      } 
      else if(table.excluded_and_mandatory_measures != undefined && table.excluded_and_mandatory_measures.length) {          
        const newEAndMMTable = this.createNewPresetAndExcludedAndMandatoryMeasuresTable(table, 'excluded_and_mandatory_measures', headers)
        const eAndMMBodies = this.formatPresetAndExcludedAndMandatoryMeasuresTableRowsWithFixedColumns(newEAndMMTable, maxColums)
        eAndMMBodies.forEach(body => this.addSumRow(body, 'excluded_and_mandatory_measures', table?.calculation_method))
        this.processHeaders(cEHeaders, eAndMMBodies, tableIndex, yPosition)                  
      } 
      else {
        this.doc.lastAutoTable = 0
        if (tableIndex + 1 !== this.tables.length && table.rows.length > 0) {
          this.addPage()
        }
      }
    }
  }

  addTableIndexCaption({ colSpan, headerIndex, headers }) {
    return [
      {
        colSpan,
        content: `Table ${headerIndex + 1} of ${headers.length}`,
        styles: {
          cellPadding: { 
            top: this.fontSizeDefault,
            right: this.fontSizeDefault * 1.5,
            left: 0,
            bottom: 0
          },
          font: 'Lato-Regular',
          fontSize: this.fontSizeDefault * .75,
          textColor: '#76848A'
        }
      }
    ]
  }

  addBlueBottomLineBetweenTheColumLabel(data) {
    if (data.cell.raw?.isGroupKey) {
                  
      // Add the blue bottom line between the column label and colum key label
      // const xFinalPos = this.pageWidth - data.settings.margin.right
      const xFinalPos = data.cell.x + data.cell.width - 5
      const yPos = data.cell.y + data.cell.height + 1
      
      this.doc.setDrawColor(this.colors.blue05)
      this.doc.setLineWidth(1.4)
      this.doc.line(data.cell.x, yPos, xFinalPos, yPos) 
      

      // Add the gray bar below the table header columns
      // const pageSize = this.doc.internal.pageSize
      // const xFinalPosition = data.cell.x + data.cell.width - 5
      // const yPosition = data.cell.y + data.cell.height + 20
                     
      // this.doc.setDrawColor('#e1e2e6');
      // this.doc.setLineWidth(0.1);
      // this.doc.line(data.settings.margin.left, yPosition, xFinalPosition, yPosition); 

    }
  }

  addVerticalBlueBarALongTheHeadline(data, table) {
    if (data.cell.raw?.isHeadline) {
      this.doc.setDrawColor(this.colors.blue60)
      this.doc.setLineWidth(.9)
      if(table.type == POLICY_TYPE_PDF.COST_EFFECTIVENESS_EVIDENCE){
        this.doc.line(22, data.cell.y - 9, 22, (data.cell.y + 13))
      } else {
        this.doc.line(22, data.cell.y + 2, 22, (data.cell.y + 32))
      }
    }
  }

  formatTableRows(table) {
    return table.rows.map((row, indexRow) => {
      let rowArray = []      
      table.headers.map(({ key }, indexHeader ) => {
        let content = ''        
        switch (key) {
          case 'title':
            content = row.measure.title              

            break
        
          default:
            content = formatStudyData(key, row.data[key], row.data )
            break
        }


        let cell = { 
          content: content,
          key: key,
          styles: {
            font: 'Lato-Regular',
            textColor: this.colors.gray04,
            halign: 'right',
            cellPadding: { 
              top: this.fontSizeDefault,
              right: this.fontSizeDefault * 1.5,
              left: 0,
              bottom: 0
            },
          }
        }
        
        // is First Column
        if (indexHeader === 0) {
          cell.styles.font = 'Lato-Bold'
          cell.styles.textColor = this.colors.gray01
          cell.styles.halign = 'left'
        }

        if (indexRow === 0) {
          cell.styles.cellPadding.top = 12
        }

        rowArray.push(cell)
      })
      return rowArray
    })
  }

  getTableHeaderTitle(table, headers = false) {
    if (!headers) {
      headers = table.headers
    }
    return { 
      content: this.getTableHeaderSubtitle(table), 
      colSpan: headers.length,
      styles: { 
        fontSize: 10,
        font: 'Lato-Regular',
        textColor: this.colors.gray70,
        cellPadding:{
          left: 10,
        }
      } 
    }
  }

  getTableHeaderGroupColumns(headers) {
    return [...headers].reduce((prev, header, index) => {
      
      if (!prev[header.groupKey]) {
        const key = header.groupKey ?? index
        const content = header.groupTitle ?? ''
        prev[key] = {
          content,
          colSpan: 1,
          groupKey: key,
          isGroupKey: true,
          styles: { 
            font: 'Lato-Bold',            
            valign: 'top',
            cellPadding: { 
              top: 6, 
              left: 0, 
              right: 10, 
              bottom: 5 
            }
          }
        }
      } else {
        prev[header.groupKey].colSpan = prev[header.groupKey].colSpan + 1
      }

      return prev

    }, {})
  }

  getTableHeaderColumns(table) {
    return [...table.headers]
      .map((item, index) => {
        let column = {
          content: `${item.label}`,
          groupKey : item.groupKey,
          groupTitle : item.groupTitle,
          key : item.key,
          styles: {
            font: 'Lato-Bold',
            valign: 'top',
            halign: 'right',
            cellPadding: { 
              top: this.fontSizeDefault,
              right: this.fontSizeDefault + 5.5, 
            }
          }
        }

        // isFistColumn
        if (index === 0) {
          column.isFirstHeaderColumn = true
          column.styles.halign = 'left'
          // column.styles.cellPadding.left = 12
        }
        
        return column
      })
  }

  getTableHeaderDescriptionColumns(table){
    return [...table.headers]
    .map((item, index)=>{
      let column = {
        content: `${item.description ? item.description : ""}`,
        groupKey: item.groupKey,
        groupTitle: item.groupTitle,
        key:item.key,
        styles: {
          textColor: this.colors.gray50,
          font: 'Lato-Regular',
          fontSize: 7,
          valign: 'top',
          halign: 'right',
          cellPadding:{
            right:15,
          }
        }
      }

      if (index === 0) {
        column.isFirstHeaderColumn = true
        column.content = ""
      }
      
      return column
    })
  }


  formatTableRowsWithFixedColumns(table, maxColumsByTable) {

    const allRows = this.formatTableRows(table)
    const rows = []

    allRows.forEach((row) => {

      const titleColum = row.shift()      
      const maxColumsByTableWithoutTitle = maxColumsByTable - 1

      const rowChunks = new Array(Math.ceil(row.length / maxColumsByTableWithoutTitle)).fill().map(() => row.splice(0, maxColumsByTableWithoutTitle))
      // console.log('rowChunks', rowChunks)
      rowChunks.forEach((rowChunk, index) => {
        if (!rows[index]) {
          rows[index] = []
        }
        rows[index].push([ titleColum, ...rowChunk ] )
      })

    })

    return rows
  }

  formatTableHeadersWithFixedColumns(table, maxColumsByTable) {  
    let tableHeaderColumns = this.getTableHeaderColumns(table)
    let tableHeaderDescription = this.getTableHeaderDescriptionColumns(table)
    const tableHeaderColumnTitle = tableHeaderColumns.shift()
    const maxColumsByTableWithoutTitle = maxColumsByTable - 1
        
    const columnHeadersChunks = new Array(Math.ceil(tableHeaderColumns.length / maxColumsByTableWithoutTitle))
    .fill()
    .map(() => tableHeaderColumns.splice(0, maxColumsByTableWithoutTitle))
    return columnHeadersChunks.reduce((prev, columnHeaders) => {
      const columHeadersPlusTitle = [ tableHeaderColumnTitle, ...columnHeaders ]

      const columnHeadersDescription = columHeadersPlusTitle.map((title) =>{  
        const finded = tableHeaderDescription.find(element => element.key === title.key)
        return {...title, content: finded.content, styles: finded.styles}
      })
      const headerGroupColumns = Object.values(this.getTableHeaderGroupColumns(columHeadersPlusTitle))
      const tableHeadline = this.getTableHeaderHeadline({ headers: columHeadersPlusTitle, ...table })
      const tableTitle = this.getTableHeaderTitle(table, columHeadersPlusTitle)

      let titleAndLine = [] 
      if(table.type == POLICY_TYPE_PDF.COST_EFFECTIVENESS_EVIDENCE) {
        titleAndLine = this.addRequiredMeasuresAndPackagesTitle()
      }
      
      prev.push([
        [ tableHeadline ],
        [ tableTitle ],
        titleAndLine, 
        headerGroupColumns,
        columHeadersPlusTitle,
        columnHeadersDescription
      ])

      return prev
    }, [])
  }

  formatTableHeader(table) {

    const tableHeadline = this.getTableHeaderHeadline(table)
    const tableTitle = this.getTableHeaderTitle(table)       
    const tableHeaderGroupColumns = this.getTableHeaderGroupColumns(table.headers)
    const tableHeaderColumns = this.getTableHeaderColumns(table)

    return [ 
      [ tableHeadline ], 
      [ tableTitle ], 
      [ ...Object.values(tableHeaderGroupColumns)],
      [ ...tableHeaderColumns ]
    ]
  }

}