<template>
  <BaseModal
    ref="baseModal"
    modal-dialog-classes="w-auto max-w-sm"
    :show-modal-parent.sync="showModalParent"
    @close="close"
  >
    <div
      v-if="showModalParent"
      class="w-full"
    >
      <CustomCombinationToPolicyForm         
        :measures="measuresIds"
        :study_id="study_id"
        :prototype_id="prototype_id"
        :vintage_id="vintage_id"
        @success="$refs.baseModal.close()"
      />
    </div>
  </BaseModal>
</template>

<script>
import CustomCombinationToPolicyForm from './CustomCombinationToPolicyForm.vue'
export default {
  name: 'ModalAddToPolicy',
  components: { CustomCombinationToPolicyForm },
  data: () => ({
    showModal: false,
    study_id : false,
    prototype_id: false,
    vintage_id: false,
    measuresIds: [],
    showModalParent: false
  }),
  mounted() {
    this.$eventBus.$on('openAddToPolicyModal', (payload) => {
      const { measuresIds, study_id, prototype_id, vintage_id } = payload
      this.measuresIds = measuresIds
      this.study_id = study_id
      this.prototype_id = prototype_id
      this.vintage_id = vintage_id
      this.showModalParent = true
      this.$store.commit('general/setRedirectTo', { route: window.location.pathname, event: { name: 'openAddToPolicyModal', payload }})
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openAddToPolicyModal')
  },
  methods: {
    close() {
      this.showModalParent = false
      this.$store.commit('general/setRedirectTo')
    }
  }
}
</script>

<style scoped lang="scss">
.info-wrapper {
  max-width: 460px;
}
</style>