<template>
  <BaseModal
    ref="baseModal"
    modal-dialog-classes="migration-modal"
    :hierarchy="MODAL_HIERARCHY.SECONDARY"
    title="New building estimates for ADUs are available"
    :disable-close="true"
  >
    <p class="psui-text-gray-50 mt-4 psui-text-p">
      {{ 'We now have refined building estimates, breaking out ADUs from Single Family Units. We recommend using the new ADU estimates, and adding ADUs to your policy.' | policyTextRephrase }}
       
      <a
        target="_blank"
        class="psui-text-blue-60 psui-font-bold psui-cursor-pointer"
        href="https://intercom.help/explorer-local-energy-codes/en/articles/6930996-adjusting-your-policy-for-adus"
      >Learn More</a>
    </p>
    <div class="flex w-full flex-col bg-white rounded-6 psui-text-p mt-6 psui-shadow-elevation-5 cursor-pointer">
      <div
        class="adu-helper p-4 psui-text-gray-80 flex items-center font-bold justify-between border-b psui-border-gray-10"
        @click.stop="createAduContainer($event)"
      >
        <div class="flex items-center w-full">
          <span>Use new ADU estimates</span>
          <PsMiniTag
            class="mx-2"
            message="recommended"
            layout="solid-default"
          />
          <PsTooltip>
            <template #trigger>
              <PsIcon
                icon="help_outline"
                size="20"
                icon-classes="psui-text-blue-60 hidden pointer-events-none"
                @click.prevent.stop
              />                
            </template>
            <template #content>
              <div class="psui-text-gray-80 psui-text-small">
                <h1>We will automatically update the number of ADUs</h1>
                <p class="psui-font-normal mt-1">
                  This will decrease your original number of projected Single Family Units.
                </p>
              </div>
            </template>   
          </PsTooltip>
        </div>   
        <PsIcon
          icon="arrow_forward_ios"
          size="15"
          color="psui-text-gray-50"
        />
      </div>
      <div
        class="sf-helper p-4 psui-text-gray-80 flex items-center font-bold justify-between"
        @click.stop="setSingleFamilyAsTotalValueOfBuildingEstimates($event)"
      >
        <div class="flex items-center w-full">
          <span>I'll update the estimates manually later</span> 
          <PsTooltip>
            <template #trigger>
              <PsIcon
                icon="help_outline"
                size="20"
                icon-classes="psui-text-blue-60 ml-2 hidden"
              />                
            </template>
            <template #content>
              <div class="psui-text-gray-80 psui-text-small">
                <h1>{{ 'We will set the projected number of ADUs for your policy to 0.' | policyTextRephrase }}</h1>
                <p class="psui-font-normal mt-1">
                  {{'Your projected Single Family Units will remain the same. You will be able to update this number manually later in your policy assumptions' | policyTextRephrase}}
                </p>
              </div>
            </template>   
          </PsTooltip>
        </div> 
        <PsIcon 
          icon="arrow_forward_ios"
          size="15"
          color="psui-text-gray-50"
        />
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { MODAL_HIERARCHY, TYPE_PROTOTYPES_DB_SLUGS, STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import { mapGetters } from 'vuex'
import { POLICY_OPTIONS_ALGORITHMS } from '@/business-logic/services/policy-options/factory.js'

export default {
  name: 'PolicySingleFamilyMigrationModal',
  data:()=>({
    MODAL_HIERARCHY,
    policy_id: null,
    policy: null,
    study_type_id: null,
    type_prototype_id: null,
    type_prototype_order:null,
  }),
  computed:{
    getPolicyOptions() {
      return this.$store.getters['policy/getterPolicyOptionsWithPrototypes']()
    },
    getPolicyOptionClimateZones(){
      return this.policy.policy_containers
      .map((policy_container => policy_container.custom_combinations))
      .reduce((acc,curr)=>acc.concat(curr),[])
      .map(climate_zone => climate_zone.climate_zone_raw)
      .sort()
      .reduce((acc,curr)=>{
        if(acc.length === 0 || acc[acc.length -1] !== curr){
          acc.push(curr)
        }
        return acc
      },[])
    },
    ...mapGetters([
      'lastClimateZonesVisited',
      'hasVisitedModule'
    ]),
  },
  mounted() {
    this.$eventBus.$on('openPolicySingleFamilyMigrationModal', ({policy,policy_id,study_type_id,type_prototype_id,type_prototype_order}) => {
      this.policy = policy
      this.policy_id = policy_id
      this.study_type_id = study_type_id
      this.type_prototype_id = type_prototype_id
      this.type_prototype_order = type_prototype_order
      
        this.$refs.baseModal.showModal = true
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openPolicySingleFamilyMigrationModal')
  },
  methods: {
    async createAduContainer(event){
      if(event.target.className === 'psui-el-tooltip-trigger') return
      
      const getPrototypeWithClimateZonesObject = {
        climate_zones: this.getPolicyOptionClimateZones,
        prototype_id: this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({type_prototype_id: this.type_prototype_id}).id
      }
      
      const getPolicyOptionSlugByPolicy = this.$store.getters['globalEntities/PolicyOption/getterGlobalPolicyOption']({id:this.policy?.policy_option?.id})?.slug

      const that = this
      try {
        await new Promise((resolve)=>{

          const policyOptionSlug = this.policy.policy_option ? getPolicyOptionSlugByPolicy : 'default-state'

          const policyBuilder = this.getNewBuildingPolicyBuiderFromPolicyOptionSlug(policyOptionSlug)
          window.$vueInstance.$appToast({type:'success', message: 'A building type was added to the policy.' })           
          new policyBuilder.constructor(this.policy_id,[getPrototypeWithClimateZonesObject]).install()

          that.setModuleHasBeenVisited()
          return resolve()
          
        })
      } catch (err) {
        console.log('err',err)
        if (err?.response?.data?.message) {
          window.$vueInstance.$appToast({ message: err?.response?.data?.message })
        } else {
          window.$vueInstance.$appToast({type:'error', message: 'Something went wrong, please try again.' }) 
        }
        this.$refs.baseModal.showModal = false
      } 

      
    },
    setModuleHasBeenVisited(){
      const getModuleVisited = `policy_migration_modal_id_${this.$route.params.policy_id}`
      if(!this.hasVisitedModule(getModuleVisited)) this.$store.commit('setModuleVisited', getModuleVisited)
      
      this.$refs.baseModal.showModal = false
    },
    setSingleFamilyAsTotalValueOfBuildingEstimates(){
      const getSingleFamilyTypePrototype = this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({slug:TYPE_PROTOTYPES_DB_SLUGS.SINGLE_FAMILY})

      const to = 0

      const fromAdu = this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits'](
        {
          type_prototype_id: this.type_prototype_id,
        climate_zone_prefix: this.$store.getters['globalEntities/ClimateZone/getterGlobalClimateZone']({raw:this.getPolicyOptionClimateZones[0]}).prefix 
        }
      )
      
        this.getPolicyOptionClimateZones.forEach((cz_raw)=>{

        const fromSingleFamily =  this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits'](
          {
            type_prototype_id: getSingleFamilyTypePrototype.id,
            climate_zone_prefix: this.$store.getters['globalEntities/ClimateZone/getterGlobalClimateZone']({raw:cz_raw}).prefix 
          }
        )

          const getSumSingleAduBuildingEstimatesUnits = fromSingleFamily + fromAdu

          this.$store.dispatch('assumptions/buildingEstimates/addOrUpdateCustomBuildingEstimates', { custom_building_estimates: this.getAllBuildingEstimates(this.type_prototype_id,cz_raw), to,  from:fromAdu})
          this.$store.dispatch('assumptions/buildingEstimates/addOrUpdateCustomBuildingEstimates', { custom_building_estimates: this.getAllBuildingEstimates(getSingleFamilyTypePrototype.id, cz_raw), to:getSumSingleAduBuildingEstimatesUnits,  from:fromSingleFamily})
        })

      this.setModuleHasBeenVisited()
    },
    getAllBuildingEstimates(type_prototype_id,climate_zone_raw) {
        const getClimateZonePrefixByClimateZoneRaw = this.$store.getters['globalEntities/ClimateZone/getterGlobalClimateZone']({raw:climate_zone_raw}).prefix
        return this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimates']({climate_zone_prefix: getClimateZonePrefixByClimateZoneRaw, type_prototype_id})
    },
    getNewBuildingPolicyBuiderFromPolicyOptionSlug(slug){
      return Object.values(POLICY_OPTIONS_ALGORITHMS).find(el => el.policy_option_slug === slug && el.study_type_slug === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS)
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .modal-dialog{
    max-width: 440px;
    background: #E6ECF2;
    border-radius: 6px;
    padding: 32px;

    h4 {
      color: #28323B !important;
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;
    }
  }

  ::v-deep .adu-helper:hover, ::v-deep .sf-helper:hover{
    .material-icons-round {
      display: block;
    }

    .psui-el-tooltip-wrapper { 
      .psui-el-tooltip-dialog {
        width: 180px;
        .psui-el-tooltip-content {
          background: #D6DDE5;
        }
      }
    }
  }

</style>
