<template>
  <div class="flex items-center">
    <p class="psui-text-gray-80 psui-font-bold">
      Filter by
    </p>
    <div class="flex items-center gap-1 ml-2">
      <Dropdown
        ref="dropdownShowPoliciesBy"
        dropdown-dialog-classes="left-0 bg-white"
      >
        <template #buttonLabel>
          <PsChips
            label="Building Type"
            type="button"
            :checked="isFilteringByTypePrototype"
          />
        </template>
        <template #items>
          <PsCheckbox
            v-for="type_prototype in getTypePrototypesFromStudyTypesByRoute"
            :key="`filter-${type_prototype.id}`"
            :label="`${type_prototype.title}`"
            size="small"
            :value="
              !checkQueryKeyValue(
                'exclude_type_prototype_ids',
                type_prototype.id
              )
            "
            @input="
              toggleQueryKeyValue(
                'exclude_type_prototype_ids',
                type_prototype.id
              )
            "
          />
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<script>
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import StudyTypeApiService from "@/services/api/StudyTypeApiService"
export default {
  name: "PolicyOptionsFilteredByBuildingTypes",
  data: () => ({
    STUDY_TYPES_DB_SLUGS,
    study_types: [],
  }),
  computed: {
    getStudyTypeByRoute() {
      return (
          this.$route.query.only_study_type ??
          STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS
      )
    },
    getTypePrototypesFromStudyTypesByRoute() {
      const slugToFilter = this.getStudyTypeByRoute === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS ? STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS : STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS
      return this.study_types
          .filter((study_type) => {
            return study_type.slug === slugToFilter
          })
          .map((study_type) => {
            const filteredTypePrototypes = []
            const typePrototypes = (study_type?.type_prototypes || [])
            typePrototypes.forEach((typePrototype) => {
              if (this.availableTypePrototypes.includes(typePrototype.id)) {
                filteredTypePrototypes.push(typePrototype)
              }
            })
            return filteredTypePrototypes
          })[0]
    },
    isFilteringByTypePrototype() {
      return this.$route.fullPath.includes("exclude_type_prototype_ids")
    },
    availableTypePrototypes() {
      let availableTypePrototypes = []
      this.$store.getters['policy/getterPolicyOptionsWithPrototypes']().map((policyOption) => {
        availableTypePrototypes = [...new Set([...availableTypePrototypes, ...(policyOption?.prototypes?.map((p) => p.type_prototype_id) || [])])]
      })
      return availableTypePrototypes
    },
  },
  mounted() {
    StudyTypeApiService.getWithTypePrototypes().then(({ data }) => {
      this.study_types = data.study_types
    })
  },
}
</script>
<style lang="scss" scoped>
::v-deep .psui-el-checkbox {
  background: none;
}
::v-deep .psui-el-checkmark {
  color: #798490;
  span {
    color: #515e6a;
    font-weight: 700;
  }
}
::v-deep .dropdowndialog {
  padding: 12px 24px;
}
::v-deep .dropdown-button {
  border: none;
}
</style>
