import BaseModel from './BaseModel'

export default class TypePrototype extends BaseModel {
  constructor(obj = {}) {
    super(obj)

    this.id = obj.id || null
    this.title = obj.title || ''
    this.slug = obj.slug || ''
    this.building_stock_title = obj.building_stock_title || ''   
    this.building_stock_helper = obj.building_stock_helper || ''   
    this.color = obj.color || ''   
    this.order = obj.order != undefined ? obj.order : 9999
    this.building_type_id = obj.building_type_id || null
    this.hide_in_building_estimates = obj.hide_in_building_estimates || false
    this.title_building_estimates_existing = obj.title_building_estimates_existing || ''
    this.icon = obj.icon || ''
    this.units_normalization = obj.units_normalization || ''
    
    // Queries
    this.building_stocks = obj.building_stocks || false
    this.prototypes = obj.prototypes || []
    this.type_prototypes = obj.type_prototypes || []
    this.type_vintages = obj.type_vintages || []
    
    // Custom fields
    this.custom_field_item_type = 'type_prototypes'
    this.custom_fields_keys = ['description', 'unit_measure']
    this.addCustomFields()
  }

}