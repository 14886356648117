var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDrawer',{ref:"baseDrawer",attrs:{"title":"Add/Hide Columns","subtitle":"Select or re-arrange columns to display","hierarchy":_vm.DRAWER_HIERARCHY.SECONDARY}},[(_vm.showStudyTypeTabs)?_c('div',{staticClass:"psui-flex psui-pr-4 pb-2 mb-4/5"},[_c('div',{staticClass:"border-b-2 py-3 psui-mr-4",class:[
        _vm.studyTypeSelected == 'Existing Buildings' ? 'psui-border-blue-60 text-pri font-bold' : 'border-blue04-10 text-gray font-semi-bold',
        { 'psui-opacity-75 psui-cursor-not-allowed' : _vm.studyTypeSelected == 'New Buildings' }
      ]},[_c('button',{staticClass:"focus:outline-none",class:[
          _vm.studyTypeSelected == 'Existing Buildings' ? 'psui-text-blue-60 font-bold' : 'border-blue04-10 text-gray font-semi-bold',
          { 'psui-pointer-events-none' : _vm.studyTypeSelected == 'New Buildings' }
        ],on:{"click":function($event){_vm.studyTypeSelected = 'Existing Buildings'}}},[_vm._v(" Existing Buildings ")])]),_c('div',{staticClass:"border-b-2 py-3",class:[
        _vm.studyTypeSelected == 'New Buildings' ? 'psui-border-blue-60 text-pri font-bold' : 'border-blue04-10 text-gray font-semi-bold',
        { 'psui-opacity-75 psui-cursor-not-allowed' : _vm.studyTypeSelected == 'Existing Buildings' }
      ]},[_c('button',{staticClass:"focus:outline-none",class:[
          _vm.studyTypeSelected == 'New Buildings' ? 'psui-text-blue-60 font-bold' : 'border-blue04-10 text-gray font-semi-bold',
          { 'psui-pointer-events-none' : _vm.studyTypeSelected == 'Existing Buildings' }
        ],on:{"click":function($event){_vm.studyTypeSelected = 'New Buildings'}}},[_vm._v(" New Buildings ")])])]):_vm._e(),_c('DragAndDropEditColumnsList',{attrs:{"get-columns":_vm.getColumns,"module":_vm.module}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }