<template>
  <div class="psui-w-full psui-flex psui-flex-wrap psui-items-center psui-space-y-1 ">
    <PolicyOptionsFilteredByBuildingTypes class="psui-flex-shrink-0" />
  </div>
</template>

<script>
import PolicyOptionsFilteredByBuildingTypes from '@/modules/app/policy/options/PolicyOptionsFilteredByBuildingTypes.vue'
export default {
  name: 'PolicyOptionsFiltersWrapper',
  components: {
    PolicyOptionsFilteredByBuildingTypes,
  },
}
</script>
