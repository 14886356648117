<template>
  <div 
    ref="wrapper"
    class="forecast-card psui-w-full psui-transition-all psui-duration-300 psui-border border-deepsky-200 rounded-6 psui-bg-white psui-px-6 psui-py-4"
    :class="!expanded && studyData && studyData.length > 0 ? 'cursor-pointer not-expanded' : 'is-expanded'"
    @click="!expanded && studyData && studyData.length > 0 ? $emit('update:expanded', !expanded) : ''"
  >
    <div class="forecast-card--header psui-mb-4">
      <slot name="header" />
    </div>
    <div 
      :class="[
        isLoadingStudyData ? 'opacity-50 disabled' : '',
        !studyDataLastPayloadIndex ? 'blur' : ''
      ]"
      class="forecast-card--body w-full flex flex-col"
      @click="handleBodyClick()"
    >
      <slot
        name="body"
        :study-data="studyData"
      />
    </div>

    <div class="forecast-card--footer flex pt-3">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
// import AppSeeMore from '@/components/general/AppSeeMore.vue'
import StudyDataApiService from '@/services/api/StudyDataApiService'

const isInOrCloseToViewport = (element) => {
  if (!element) return false      
  
  const rect = element.getBoundingClientRect()
  if (rect.height === 0 && rect.width === 0) return false

  return (
    ( rect.bottom >= 200 && rect.bottom <= 50 ) ||
    ( rect.top <= window.innerHeight + 200 && rect.top >= -50 )
  )
}

export default {
  name: 'StudyResultsContentCard',
  // components: { AppSeeMore },  
  props: {
    expanded: {
      type: Boolean,
      required: false,
    },
    prototype: {
      type: Object,
    },
    vintage: {
      type: [Object, Boolean],
      default: false      
    },
    fuel: {
      type: [Object, Boolean],
      default: false      
    },
    climate_zone: {
      type: Object
    },
    jurisdiction: {
      type: Object
    },
  },
  data: () => ({
    studyData: [],
    studyDataLastPayloadIndex: false,
    customCombination: [],
    isLoadingStudyData: false,
  }),
  watch: {
    $route: {
      handler(i) {
        if (i) this.requestDataUpdateIfElementIsInOrCloseToViewPort('route')
      },
      immediate: true
    },
    expanded(cur, prev) {
      if (!prev && cur) this.updateStudyData()
    }
  },  
  mounted() {
    this.requestDataUpdateIfElementIsInOrCloseToViewPort('mounted')
    this.requestDataOnStudyResultsScroll()    
  },
  methods: {
    handleBodyClick() {
      if (!this.expanded && this.studyData && this.studyData.length > 0) {
        this.$emit('update:expanded', !this.expanded)
      }
    },
    requestDataOnStudyResultsScroll() {
      const resultsIndexEl = document.getElementById('study-results-index')
      resultsIndexEl.addEventListener('scroll', () => { this.requestDataUpdateIfElementIsInOrCloseToViewPort('scroll') }, false)
      window.addEventListener('resize', () => { this.requestDataUpdateIfElementIsInOrCloseToViewPort('resize') }, false)
    },
    requestDataUpdateIfElementIsInOrCloseToViewPort($event) {
      if (isInOrCloseToViewport(this.$refs?.wrapper)) {
        this.updateStudyData($event)
      }
    },
    async updateStudyData() {
      
      const studyDataPayload = this.getStudyDataPayload()
      const payloadStudyDataIndex = Object.values(studyDataPayload).join('-')

      if (payloadStudyDataIndex === this.studyDataLastPayloadIndex) return false
      this.studyDataLastPayloadIndex = payloadStudyDataIndex
      
      this.isLoadingStudyData = true
      
      this.studyData = await StudyDataApiService.get(studyDataPayload)
      if (this.vintage) this.studyData.map((s) => { s.vintage = this.vintage; return s } )
      if (this.fuel) this.studyData.map((s) => { s.fuel = this.fuel; return s } )

      this.$emit('study-data-update', this.studyData)
      setTimeout(() => { this.isLoadingStudyData = false }, 500)
    },

    getStudyDataPayload() {

      const studyDataPayload = {
        climate_zone_raw : this.climate_zone.raw,
        prototype_id: this.prototype.id,        
      }

      if (this.vintage) studyDataPayload.vintage_id = this.vintage.id
      if (this.fuel) studyDataPayload.fuel_id = this.fuel.id
      
      return studyDataPayload
    },    

  },
}
</script>

<style lang="scss" scoped>

  .forecast-card {
    max-width: 100%;

    &--body {
      max-height: 196px;
      overflow: hidden;
      position: relative;
      width: 100%;
    }
    
    &:not(.is-expanded) {
      ::v-deep .table-has-scroll-right {        
        box-shadow: none !important;        
      }
      .savetopolicy-btn {
        opacity: 0;
      }
      .forecast-card--body {
        cursor: pointer;        
        ::v-deep {
          .hide-if-collapsed {
            opacity: 0;
          }
          .forecast-table--cell {
            background-color: transparent;
          }
          .column-group-helper-button, .measure-combination-add {
            opacity: 0;
          }
        }
      }
    }

  }  

  .is-expanded {

    .forecast-card--body {
      max-height: 100%;

      &::after {
        display: none;
      }
    }    
  }

  .blur {
    filter: blur(1.5px);
  }
</style>
