import LogRocket from 'logrocket'
import createPlugin from 'logrocket-vuex'
import EventBus from '@/util/EventBus'
import MainVuex from '@/store'
import { PsUrls } from '@/data/ps-urls'
import MainRouter from '@/router'

const isProductionUrl = window.location.origin.includes('https://explorer.localenergycodes.com') ? true : false
const isPsUrl = Boolean(PsUrls.find((i) => i.url === window.location.host))

const identifyUser = (user) => {
  if (user) {
    console.log('LogRocket service identifying user')
    LogRocket.identify(user.id, { email : user.email })
  }
}

const addStoreToMutation = (mutation) => {
  mutation.store = JSON.parse(JSON.stringify({ ...MainVuex.state, route: false }))
  return mutation
}

const initLogRocket = (isManually = false) => {

  const { disable_tracking } = MainRouter.currentRoute.query

  if(isProductionUrl && !isManually && (!disable_tracking || window.location.href.includes('cee-production.vercel.app'))) {
    if(MainVuex.getters['general/getterDeviceInfo']?.log_events) {
      console.log(`LogRocket service started for production (isPsUrl=${isPsUrl})`)
      LogRocket.init('ecxjdb/cost-effectiveness-explorer')
      const LogRocketPlugin = createPlugin(LogRocket, addStoreToMutation)
      LogRocketPlugin(MainVuex)
      identifyUser(MainVuex.getters['getterLoggedUser'])
    }
  } else if((!isProductionUrl && !disable_tracking) || isManually) {
    console.log('LogRocket service started for QA')
    LogRocket.init('explorer-devs/explorer-qa')
    const LogRocketPlugin = createPlugin(LogRocket, addStoreToMutation)
    LogRocketPlugin(MainVuex)
    identifyUser(MainVuex.getters['getterLoggedUser'])
  }
}

export default async (Store) => {

  Store.subscribe((mutation) => {

    // On check device info request
    // The check device info request happens every page load or after a login / register action
    if(mutation.type === 'general/setDeviceInfo' && window.location.hostname !== 'localhost') {
      initLogRocket()
    }

    //When user logout clear user info from LogRocket
    if(mutation.type === 'setLogout') {
      LogRocket.identify()
    }
  })

  EventBus.$on('initLogRocketManually', () => {
    initLogRocket(true)
  })

}