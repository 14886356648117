<template>
  <div class="flex items-center ml-1">
    <Dropdown
      ref="dropdown"
      :min-width-dropdown="400"
      :dropdown-dialog-classes="'min-w-300 bg-white p-6'"
      button-classes="psui-border-0 p-0"
      title="Vintage"
    >
      <template #buttonLabel>
        <PsChips
          label="Vintage"
          type="button"
          :checked="isFiltering"
        />
      </template>
      <template #items>
        <div class="w-full flex flex-col font-medium text-gray02">
          <div
            v-for="(group,index) in vintagesWithinPrototypes"
            :key="`group-${group.id}`"
            :class="{'psui-border-t psui-border-gray-30 pt-2': index > 0, 'pb-2': index == 0}"
          > 
            <div class="flex space-x-2 items-center">
              <span
                v-if="group"
                class="psui-text-small psui-text-gray-50"
              >{{ getTitle(group.prototypes, vintagesWithinPrototypes) }}</span>
              <PsTooltip
                v-if="group.prototypes.length >= 2 && vintagesWithinPrototypes.length >= 2"
              >
                <template #trigger>
                  <PsMiniTag
                    layout="default"
                    :message="`+${group.prototypes.length}`" 
                    class="psui-text-uppercase psui-font-bold"
                  />
                </template>
                <template #content>
                  <ul
                    class="list-none"
                  >
                    <li
                      v-for="(prototype) in getFilteredPrototypeItens(group.prototypes)"
                      
                      :key="prototype.slug"
                    >
                      {{ prototype.type_prototype.title }}
                    </li>
                  </ul>
                </template>
              </PsTooltip>
            </div>
            <div
              v-if="group.type_vintages"
              :key="`prototype-wrapper-${group.id}`"
              class="mt-2"
            >
              <template
                v-for="type_vintage in group.type_vintages"
              >
                <Checkbox
                  v-if="type_vintage"
                  :key="`filter-vintage-${type_vintage.id}-${group.id}`"
                  class="w-full"              
                  label-classes="ts--accent--2 text-gray04"
                  :label="type_vintage.title"
                  :value="!checkQueryKeyValue('exclude_type_vintage_ids', type_vintage.id)"
                  @change="toggleQueryKeyValue('exclude_type_vintage_ids', type_vintage.id)"
                />
              </template>
            </div>
          </div>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
export default {
  name: 'FilterTypeVintage',
  data() {
    return {
      type_vintages: [],
      studyTypes: [],
      type_prototypes: [],
      availablePrototypes: []
    }
  },
  computed: {
    getExcludedStudyIds(){
      return this.getQueryKey('exclude_type_vintage_ids')
    },
    isFiltering() {
      return this.$route.fullPath.includes('exclude_type_vintage_ids')
    },
    getExcludedTypeVintageIds(){
      return this.getQueryKey('exclude_type_vintage_ids') ? this.getQueryKey('exclude_type_vintage_ids').length : false
    },
    vintagesWithinPrototypes(){
      return this.getAvailablePrototypes.reduce((acc,curr)=>{
        const typeVintages = curr.study.vintages.reduce((acc,curr)=>{
            if(curr.type_vintage_id) {
              const type_vintage = this.$store.getters['globalEntities/TypeVintage/getterGlobalTypeVintage']({id:curr.type_vintage_id})
              acc.push(type_vintage)
            }
            return acc
        },[])

        const id = typeVintages
        .sort((a,b)=> a.id-b.id)
        .map(tp => tp.id)
        .join('-')

        let group = acc.find(g => g.id == id)

        if(!group) {
          group = {
            id,
            prototypes:[],
            type_vintages: typeVintages 
          }
          acc.push(group)
        }

        group.prototypes.push(curr)

        return acc
      },[])
    }
  },
  watch:{
    getExcludedStudyIds:{
      handler: async function(){
        await this.getStudyInitialData()
      }
    }
  },
  async mounted() {
    await this.getStudyInitialData()
  },
  methods: {
    async getStudyInitialData(){
      await this.getStudyResultsInitialData()
      .then((data)=>{
        this.studyTypes = data.study_types
        this.type_prototypes = data.type_prototypes
      })

      this.getTypeVintages()
    },
    getFilteredPrototypeItens(prototypes){
      return prototypes.filter((p,index) => index != 0)
    },
    getTitle(prototypes, vintages){
      if(prototypes.length == 2 && vintages.length == 1) {
        return prototypes.map(p => p.type_prototype.title).join(" | ")
      }
      return prototypes?.[0]?.type_prototype?.title || ''
    },
    getTypeVintages() {
      const query = `
        {
          type_vintages {
            id
            title
            vintages {
              id
            }
          }
        }
      `
      this.$graphql({ query, shouldCache: true })
        .then(({ data }) => {
          this.type_vintages = data.type_vintages.filter(tp => tp.vintages.length > 0)
        })
    },
    checkIfTypeVintageShouldBeShown(type_vintage, prototype){

      const lastJurisdictionVisited = this.$store.getters['lastJurisdictionVisited']
      const lastClimateZoneVisited = this.$store.getters['lastClimateZoneVisited']
      const filter = {
        jurisdiction_id: lastJurisdictionVisited.id,
        climate_zone_prefix: lastClimateZoneVisited.prefix,
        type_prototype_id: prototype.type_prototype_id,
        type_vintage_id: type_vintage.id,
      }

      const buildingStockUnits = this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits'](filter)

      return Boolean(buildingStockUnits) 
      
    },
  }
}
</script>