import Api from '@/util/Api'
import GraphQL from '@/util/GraphQL'
import { DeviceUUID } from 'device-uuid'
import IndexedDBService from '@/services/general/IndexedDBService'
import { StringHelpers } from '@igortrindade/lazyfy'
import MainRouter from '@/router'

export default class UserDeviceApiService {

    static async check() {
      return UserDeviceApiService.getDeviceInfo()
        .then((deviceInfo) => {
          return Api.post(`/api/users/user_devices/check`, deviceInfo)
          .then(({ data }) => {
              const { disable_tracking } = MainRouter.currentRoute.query
              // We re-enabled tracking gor cee-production for a while since Paulo can filter by hostname on analytics
              if(disable_tracking) {
                data.log_events = false
              }
              return data
            })
        })
    }

    static getOnlyAdminAndTeamDevices() {
      const query = `
        {
          only_admin_and_team_devices {
            id
            uid
            user_id
            platform
            os
            browser
            last_session_at
            user {
              id
              email
              is_admin
              is_team
            }
          }
        }
    `
      return GraphQL({ query, caller: 'UserDeviceApiService.getOnlyAdminAndTeamDevices' })
    }

    static async getDeviceInfo() {
      const device = new DeviceUUID()
      const deviceUuidInfo = device.parse()
      const { isMobile, resolution, platform } = deviceUuidInfo
      const { user_device_id, generated_new_one } = await UserDeviceApiService.getOrGenerateDeviceRandomId()
      
      const deviceInfo = {}
      deviceInfo.uid = user_device_id
      deviceInfo.os = deviceUuidInfo.os ?? ''
      deviceInfo.browser = deviceUuidInfo?.browser ?? null
      deviceInfo.version = deviceUuidInfo?.version ?? null
      deviceInfo.is_mobile = isMobile ?? false
      deviceInfo.platform = platform ?? null
      deviceInfo.resolution_width = resolution[0] ?? null
      deviceInfo.resolution_height = resolution[1] ?? null
      
      console.log(`%cDevice_id: ${ user_device_id } - generated new one: ${ generated_new_one }`, `color: ${ generated_new_one ? 'yellow' : 'green' }`)
      
      return deviceInfo

    }


    static async getOrGenerateDeviceRandomId() {

      const db = new IndexedDBService({ table: 'user_device_id' })
      await db.openDatabase()

      const findIndexedDbDeviceId = await db.get('user_device_id')

      if(findIndexedDbDeviceId) {
        return { user_device_id: findIndexedDbDeviceId.user_device_id, generated_new_one: false }
      }

      const user_device_id = StringHelpers.randomString(36)

      await db.add({ id: 'user_device_id', user_device_id })

      return { user_device_id, generated_new_one: true }

    }

}