import VueRouter from 'vue-router'

function isPromise(obj) {
  return !!obj && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function'
}

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(...args) {
  const result = originalPush.call(this, ...args)

  if (isPromise(result)) {
    return result.catch((err) => {
      if (err.name !== 'NavigationDuplicated') {
        throw err
      }
    })
  }

  return result
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(...args) {
  const result = originalReplace.call(this, ...args)

  if (isPromise(result)) {
    return result.catch((err) => {
      if (err.name !== 'NavigationDuplicated') {
        throw err
      }
    })
  }

  return result
}
