//TO-DO Remove all commented content after 1306 release

import Policy from '@/models/Policy'
import { uniqueByKey, findAll } from '@igortrindade/lazyfy'
import { STUDY_TYPES_DB_SLUGS, ASSUMPTIONS_DRAWER_TYPES } from '@/util/Enums.js'
import {defaultExistingBuildingsImpactAssumptionValues, defaultNewBuildingsImpactAssumptionValues} from '@/models/ImpactAssumptions.js'
import { getSubsetAssumptionsByKey }from '@/util/Helpers.js'

export default {  
  
  isUpdating(state) {
    return state.buildingStocks.is_updating ||state.buildingEstimates.is_updating
  },

  getterAssumptionsSelected(state) {
    return state[state.drawerOptions.type]
  },

  getterAssumptionsDrawerOptions(state){
    return state.drawerOptions
  },

  getterImpactAssumptionsChanges: (state) => ({key, defaultAssumptions}) => {
    const assumptions = state.drawerOptions.type === ASSUMPTIONS_DRAWER_TYPES.EXISTING_RESULTS
        ? { ...state.existingBuildingsResultsImpactAssumptions }
        : { ...state.newBuildingsResultsImpactAssumptions }

    let assumptionItemsChanged = false     

    const subsetValues = getSubsetAssumptionsByKey(key,assumptions)


    Object.keys(subsetValues).forEach((key) => {
      if(defaultAssumptions[key] != assumptions[key]) {
        assumptionItemsChanged = true
      }
    })

    return assumptionItemsChanged
  },
  
  getterImpactAssumptionsHasAnyValueChanged: (state, getters, rootState, rootGetters) => ({ key, policy_id = null }) => {
    
    const assumptions = state.drawerOptions.type === ASSUMPTIONS_DRAWER_TYPES.EXISTING_RESULTS
    ? defaultExistingBuildingsImpactAssumptionValues
    : defaultNewBuildingsImpactAssumptionValues
    
    if(!policy_id) return getters.getterImpactAssumptionsChanges({ key, defaultAssumptions: assumptions })

      const custom_combinations = rootGetters['policy/getterPolicyCustomCombinations'](policy_id)

      let hasAnyValueChangedOnAllCc = custom_combinations.filter((cc) => {
        if(!cc?.meta?.assumptions) return false
        const ccAssumptionsDiffFromDefault = Object.keys(getSubsetAssumptionsByKey(key, cc?.meta?.assumptions)).filter((changedAssumptionKey) => {
          // if(cc?.meta?.assumptions[changedAssumptionKey] != assumptions[changedAssumptionKey]) {
          //   console.log(`Assumption key (${ changedAssumptionKey }) default (${ assumptions[changedAssumptionKey] }) value: ${ cc?.meta?.assumptions[changedAssumptionKey] }`)
          // }
          return cc?.meta?.assumptions[changedAssumptionKey] != assumptions[changedAssumptionKey]
        })
        return ccAssumptionsDiffFromDefault.length > 0
      }).length > 0
      return hasAnyValueChangedOnAllCc
  },
  
  getterPolicySelected: state => state.policy_selected,

  getterAssumptionsByStudyType: (state) => (study_type_slug)=>{
    if(study_type_slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS) return state.existingBuildingsResultsImpactAssumptions
    if(study_type_slug === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS) return state.newBuildingsResultsImpactAssumptions
    return null
  },

  getterPolicyScope: (state, getters, rootState, rootGetters) => {
    
    const type_vintages = rootGetters['globalEntities/TypeVintage/getterGlobalTypeVintages']()
    const vintages = rootGetters['globalEntities/Vintage/getterGlobalVintages']()

    const policy = new Policy(state.policy_selected)

    for(const policy_container of policy.policy_containers) {
      policy_container.climate_zones = policy.jurisdiction.climate_zones
      
      for(const climate_zone of policy_container.climate_zones) {
        climate_zone.type_vintages = type_vintages
        for(const type_vintage of climate_zone.type_vintages) {

          const vintagesInCustomCombinations = uniqueByKey(policy_container.custom_combinations, 'vintage_id')

          // console.log('policy_container.custom_combinations', policy_container.custom_combinations)
          // console.log('vintagesInCustomCombinations', vintagesInCustomCombinations)
          // console.log('vintages', vintages)
          type_vintage.vintages = findAll(vintages, { id: vintagesInCustomCombinations.map((vintage) => vintage.vintage_id )})
        }
      }

    }

    return policy
  },

  getterUserCanEditTheSelectedPolicy(state,getters,rootState,rootGetters) {
    const policy_selected_id = state.policy_selected?.id
    return policy_selected_id ? rootGetters['policy/getterUserCanEditPolicy'](policy_selected_id) : false
  },

  getterPolicyContextCustomCombinationsForAssumptions: (state) => (ignoreSelectedCC = false, asFullCC = false) => {
    let customCombinationIds = !ignoreSelectedCC ? state?.policy_custom_combinations_selected || null : null
    // If there is no requirements opened and try to edit assumptions, save in all custom combinations...
    // TIP: is it the desired behaviour for all entire application??
    if (!customCombinationIds && state?.policy_selected) {
      customCombinationIds = state?.policy_selected?.policy_containers?.reduce((acc, container) => {
        container?.custom_combinations?.forEach((container) => {
          if (container?.id) {
            acc.push(asFullCC ? container : container?.id)
          }
        })
        return acc
      }, [])
    }
    return customCombinationIds
  },
  getterPolicyContextCustomCombinationByKey:(state, getters, rootState, rootGetters)=>({ prototype = false, policy_id, key })=>{
    const getTypePrototypeByPrototype = rootGetters['globalEntities/Prototype/getterGlobalPrototype']({id:prototype.id}).type_prototype_id
    const getPolicySelected = rootGetters['policy/getterUserPolicy']({id:policy_id})

    const getFilteredContainerByPrototype = prototype ? 
    getPolicySelected.policy_containers.filter(container => container.type_prototype_id === getTypePrototypeByPrototype) :
    getPolicySelected.policy_containers

    return getFilteredContainerByPrototype
      .map(policy_container => policy_container.custom_combinations)
      .reduce((acc,curr) => acc.concat(curr),[])
      .map(custom_combination => custom_combination?.meta?.assumptions)
      .reduce((acc,curr) => {
          if(acc.length === 0 || (acc?.[acc.length-1]?.[key] != curr?.[key]) ) {
            acc.push(curr)
          }
        return acc
      },[])[0]?.[key] ?? null
  },
  getterPolicyContextCustomCombinationByKeyAndPrototype:(state,getters,rootState,rootGetters)=>({ prototype = false, policy_id, climate_zone, key }) => {
    const getTypePrototypeByPrototype = rootGetters['globalEntities/Prototype/getterGlobalPrototype']({id:prototype.id}).type_prototype_id
    const getPolicySelected = rootGetters['policy/getterUserPolicy']({id:policy_id})

    const getPolicyCustomCombinationLength = getPolicySelected.policy_containers
      .filter(container => prototype ? container.type_prototype_id === getTypePrototypeByPrototype : container)
      .map(policy_container => policy_container.custom_combinations)
      .reduce((acc,curr) => acc.concat(curr),[])
      .filter(custom_combination=> climate_zone ? custom_combination.climate_zone_raw == climate_zone.raw : custom_combination)
      .map(custom_combination => custom_combination.meta.assumptions).length

    return getPolicySelected.policy_containers
      .filter(container => prototype ? container.type_prototype_id === getTypePrototypeByPrototype : container)
      .map(policy_container => policy_container.custom_combinations)
      .reduce((acc,curr) => acc.concat(curr),[])
      .filter(custom_combination=> climate_zone ? custom_combination.climate_zone_raw == climate_zone.raw : custom_combination)
      .map(custom_combination => custom_combination.meta.assumptions)
      .reduce((acc,curr)=>{return acc + curr?.[key]},0)/getPolicyCustomCombinationLength
  },
  getterPolicyContextAssumptionValueByePrototypeAndClimateZone:(state,getters,rootState,rootGetters)=>({prototype,policy_id, climate_zone})=>{
    const getTypePrototypeByPrototype = rootGetters['globalEntities/Prototype/getterGlobalPrototype']({id:prototype.id}).type_prototype_id
    const getPolicySelected = rootGetters['policy/getterUserPolicy']({id:policy_id})

    return getPolicySelected.policy_containers
    .filter(container => container.type_prototype_id === getTypePrototypeByPrototype)
    .map(policy_container => policy_container.custom_combinations)
    .reduce((acc,curr) => acc.concat(curr),[])
    .filter(custom_combination => custom_combination.climate_zone_raw == climate_zone.raw)[0]
    
  }
}
