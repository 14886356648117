<template>
  <div class="p-6">
    <InputWithLabel
      v-model="helper.title"
      class="mt-6"
      :label="'Title'"
    />

    <InputWithLabel
      v-model="helper.slug"
      class="mt-6"
      :label="'Slug (external id)'"
    />
    <span class="w-full text-gray04 rounded bg-blue04-50 p-2 mt-2 block">Attention! The slug attach the helper with the correspondent item on the application, change it with caution.</span>

    <div class="w-full flex flex-col mt-6">
      <label>Short description</label>
      <ContentEditor
        :content.sync="helper.description"
      />
    </div>    
    <div
      v-if="helper.slug === HELPERS_IDENTIFICATION.UPCOMING_FEATURES_SLUG"
      class="w-full flex flex-col mt-6"
    >
      <AdminUpcomingFeatures        
        :features="helper.content"
        @upcoming-features="onUpdateUpcomingFeatures"
      />
    </div>
    <div
      v-else
      class="w-full flex flex-col mt-6"
    >
      <label>Content (full)</label>
      <ContentEditor
        :content.sync="helper.content"
      />
    </div>
    <hr class="border-b border-gray05 my-6">
    <div>
      <PsButton
        v-if="!$route.params.helper_id"
        label="Create"
        icon="add"
        size="big"
        @click="store()"
      />   
      <PsButton
        v-else
        label="Update"
        icon="update"
        size="big"
        @click="update()"
      />
    </div>
  </div>
</template>

<script>
import AdminUpcomingFeatures from './AdminUpcomingFeatures.vue'
import { HELPERS_IDENTIFICATION } from "@/business-logic/constants/helpersEnum"

export default {
  name: 'AdminHelperForm',
  components:{ AdminUpcomingFeatures },
  data() {
    return {
      helper: {
        id: '',
        title: '',
        slug: '',
        description: '',
        content: ''
      },
      HELPERS_IDENTIFICATION
    }
  },
  mounted() {
    if(this.$route.params.helper_id) {
      this.getHelper()
    }
  },
  methods: {
    async getHelper() {
      const query = `
        {
          helper (
            where: [
              { column: "id", operation: "=", value: "${this.$route.params.helper_id}"}
            ]
          ){
            id
            title
            slug
            description
            content
            created_at
          }
        }
      `
      const { data } = await this.$graphql({ query })
      this.helper = data.helper
    },
    onUpdateUpcomingFeatures(features){
      this.helper.content = features
    },
    store() {
      this.$api.post(`/helper`, this.helper, { loader: true })
        .then(() => {
          this.$toast.success('Helper successfully created')
          this.$router.push({ name: 'AdminHelperList' })
        })
    },

    update() {
      this.$api.put(`/helper/${this.helper.id}`, this.helper, { loader: true })
        .then(() => {
          this.$toast.success('Helper successfully updated')
          this.$router.push({ name: 'AdminHelperList' })
        })
    }
  }
}
</script>
