<template>
  <div class="psui-flex psui-items-center psui-ml-auto">
    <h4 class="psui-text-gray-80 psui-text-small psui-font-bold psui-mr-2">
      {{ climateZonesTitle }}
    </h4>

    <span class="psui-flex psui-flex-wrap">
      <template v-for="(climate_zone, index) in climateZones">
        <PsChartLegend
          v-if="!checkQueryKeyValue('exclude_climate_zone_prefix', climate_zone.prefix)"
          :key="`existing_units_chart${climate_zone.id}`"
          :text="climate_zone.prefix"
          :dot-color="{ backgroundColor: paletteColorIndex[index] }"
        />
      </template>
    </span>
  </div>
</template>

<script>
export default {
  name: 'ClimateZonesSelecteds',
  props: ['paletteColorIndex'],
  computed: {
    climateZones() {
      return this.$store?.getters['lastJurisdictionVisited']?.climate_zones
    },
    climateZonesTitle() {
      const total = this.$store?.getters['lastClimateZonesVisited']
      return (total.length === 1) ? 'Climate Zone:' : 'Climate Zones:'
    }
  }
}
</script>