<template>
  <PsTooltip class="back-to-comparisons">
    <template #trigger>
      <button
        data-test-id="comparison-show-header-return-to-list-button"
        class="flex items-center psui-text-gray-80 hover:psui-text-blue-60"
        @click="goToComparisonList"
      >
        <i class="material-icons-round icon flex items-center">arrow_back</i>
      </button>
    </template>
    <template #content>
      Back to all comparisons
    </template>
  </PsTooltip>
</template>

<script>
export default {
  name: 'ComparisonShowHeaderReturnToListButton',
  methods: {
    goToComparisonList(){
      this.$router.push({ name: 'ComparisonList'})
    },
  }
}
</script>

<style>

</style>