<template>
  <div class="w-full p-6">
    <div class="w-full flex flex-col bg-ash-200">
      <div class="w-full p-6 px-8 border-b border-ash-200 bg-white flex justify-between">
        <PageTitle title="Articles" />
      </div>
    </div>

    <table class="table table-bordered mt-6 whitespace-nowrap">
      <thead>
        <tr>
          <th>ID</th>
          <th>IntercomID</th>
          <th>Title</th>
          <th>Has custom data</th>
          <th>Status</th>
          <th>Published at</th>
          <th>Created at</th>
          <th class="psui-w-48">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="article in articles"
          :key="article.id"
        >
          <td>{{ article.id }}</td>
          <td>{{ article.intercom_id }}</td>
          <td>{{ article.title }}</td>
          <td>
            <PsChips
              :label="article.has_custom_data ? 'Yes' : 'No'"
              type="checkbox"
              :checked="article.has_custom_data"
              :disabled="true"
              class="pointer-events-none"
            />
          </td>
          <td>{{ article.state }}</td>
          <td>{{ formatDate({ date: article.published_at }) }}</td>
          <td>{{ article.created_at }}</td>
          <td class="psui-flex psui-space-x-2">
            <PsButton
              label="Show on Intercom"
              icon="search"
              size="small"
              :disabled="!article.url"
              @click="showArticle(article.url)"
            />
            <PsButton
              label="Edit"
              icon="edit"
              size="small"
              @click="selectArticleToEdit(article)"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <BaseModal
      ref="editArticleModal"
      modal-dialog-classes="w-full max-w-lg"
      title="Edit article"
      style="margin-left: 245px"
    >
      <div
        v-if="article_selected"
        class="w-full flex flex-col items-start mt-6 space-y-4"
      >
        <InputWithLabel
          v-model="article_selected.title"
          class="mt-6"
          :label="'Title'"
        />

        <InputWithLabel
          v-model="article_selected.published_at"
          class="mt-6"
          :label="'Published at'"
          :mask="'##/##/####'"
          placeholder="MM/DD/YYYY"
          :required="true"
        />

        <ContentEditor
          label="Short content"
          class="w-full mt-6"
          :content.sync="article_selected.description"
        />

        <div class="w-full flex flex-col space-y-4">
          <label class="flex items-center text-gray04 text-xs pointer-events-none font-semi-bold -mb-3">Buttons</label>
          <table class="table table-bordered table-hover table-striped table-fixed break-words">
            <thead>
              <th width="20%">
                Label
              </th>
              <th width="60%">
                Url
              </th>
              <th width="20%">
                Actions
              </th>
            </thead>
            <tbody>
              <tr class="bg-white">
                <td class="w-1/5">
                  <InputWithLabel
                    v-model="buttonToAdd.label"
                    :label="'Button label'"
                  />
                </td>
                <td class="w-3/5">
                  <InputWithLabel
                    v-model="buttonToAdd.url"
                    :label="'Button url'"
                  />
                </td>
                <td class="w-1/5">
                  <div class="h-full flex items-center justify-center">
                    <PsButton
                      label="Add"
                      size="big"
                      :disabled="!buttonToAdd.label || !buttonToAdd.url"
                      @click="addButton()"
                    />
                  </div>
                </td>
              </tr>
              <template v-if="article_selected.meta && article_selected.meta.buttons">
                <tr 
                  v-for="(button, index) in article_selected.meta.buttons" 
                  :key="index"
                >
                  <td class="w-1/5">
                    <p>{{ button.label }}</p>
                  </td>
                  <td class="w-3/5">
                    <p>{{ button.url }}</p>
                  </td>
                  <td class="w-1/5">
                    <div class="flex items-center justify-center">
                      <PsButton
                        class="psui-mt-6"
                        label="Remove"
                        size="small"
                        @click="removeButton(index)"
                      />
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>

        <PsButton
          class="psui-mt-6"
          label="Save"
          size="big"
          @click="updateOrCreate()"
        />
      </div>
    </BaseModal>
  </div>
</template>

<script>
import ArticlesApiService from '@/services/api/ArticlesApiService.js'

export default {
  name: 'AdminArticleList',
  data() {
    return {
      articles: [],
      article_selected: null,
      buttonToAdd: {
        label: '',
        url: '',
      },
      search: '',
      offset: 0,
      limit: 12,
    }
  },
  mounted() {
    this.getArticles()
  },
  methods: {
    
    getArticles() {
      ArticlesApiService.getArticles().then((articles) => {
        this.articles = articles
      })
    },

    selectArticleToEdit(article) {
      this.article_selected = article
      this.$refs.editArticleModal.showModal = true
    },

    updateOrCreate() {

      ArticlesApiService.updateArticle(this.article_selected)
        .then(() => {
          this.getArticles()
          this.$refs.editArticleModal.showModal = false
          this.article_selected = null
        })
        .catch(err => {
          console.log(err)
          this.$toast.error('Error updating article')
        })

    },

    showArticle(url) {
      window.open(url, '_blank')
    },

    addButton() {
      if(!this.article_selected.meta) this.$set(this.article_selected, 'meta', { ...this.article_selected, buttons: [] })
      this.article_selected.meta.buttons.push(JSON.parse(JSON.stringify(this.buttonToAdd)))
      this.buttonToAdd = {
        label: '',
        url: '',
      }
    },

    removeButton(index) {
      this.article_selected.meta.buttons.splice(index, 1)
    },
  },
}
</script>
