import { render, staticRenderFns } from "./StudyResultsContentCard.vue?vue&type=template&id=1b2cb226&scoped=true"
import script from "./StudyResultsContentCard.vue?vue&type=script&lang=js"
export * from "./StudyResultsContentCard.vue?vue&type=script&lang=js"
import style0 from "./StudyResultsContentCard.vue?vue&type=style&index=0&id=1b2cb226&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b2cb226",
  null
  
)

export default component.exports