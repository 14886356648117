<template>
  <div>
    <div
      :is="getComponent"
      :building_types="building_types"
      :building_type="building_type"
      :type_vintages="type_vintages"
      :type_prototypes="type_prototypes"
      :jurisdiction="jurisdiction"
      :study_type="getTabSelected"
    />
  </div>
</template>

<script>
import AssumptionsBreadcrumb from '@/modules/app/assumptions/shared/AssumptionsBreadcrumb.vue'
import AssumptionsNonResidentialNew from './AssumptionsNonResidentialNew.vue'
import AssumptionsNonResidentialExisting from './AssumptionsNonResidentialExisting.vue'
import { STUDY_TYPES } from '@/modules/app/assumptions/shared/enums'
import { STUDY_TYPES_DB_SLUGS }from '@/util/Enums.js'
import { mapGetters } from 'vuex'

export default {
  name: 'AssumptionsNonResidential',
  components: { AssumptionsBreadcrumb, AssumptionsNonResidentialNew, AssumptionsNonResidentialExisting},
  props: ['building_types', 'building_type', 'type_vintages', 'path', 'type_prototypes','getTabSelected' ],
  data: () => ({    
    STUDY_TYPES : STUDY_TYPES,
  }),
  computed: {    
    getComponent() {
      const tabSelectedWithinBuildingEstimates = {
      [STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS]:AssumptionsNonResidentialExisting,
        [STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS]: AssumptionsNonResidentialNew
      }

      return tabSelectedWithinBuildingEstimates[this.getTabSelected.slug]
    },
    jurisdiction() {
      return this.$store.getters['lastJurisdictionVisited'] ?? false
    },
    ...mapGetters({
      getterStudyTypeNewBuildings: 'globalEntities/StudyType/getterStudyTypeNewBuildings'
    })
  },
}
</script>

<style lang="scss" scoped>
  h2 {
    margin-bottom: 10px;
  }
  ::v-deep .psui-el-input {
    width: 140px;
  }

  .notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
</style>
