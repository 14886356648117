<template>
  <div class="flex items-center">
    <PsMiniTag
      :layout="getMiniTagLayout ? getMiniTagLayout : 'solid-info'"
      :message="studyResultVersionLabel"
      class="psui-cursor-pointer psui-leading-none psui-transition-all psui-duration-300"
      @click="onClick()"
    />
  </div>
</template>

<script>

import getStudyResultVersionLabel from '@/business-logic/services/study-results/getStudyResultVersionLabel'

export default {
  name: 'PrototypeVersion',
  props: {
    studyGroup: {
      type: Object,
      required: true
    },
    prototype: {
      type: Object,
      required: true
    },
    study: {
      type: Object,
      required: true
    }
  },
  computed: {
    studyResultVersionLabel() {
      return getStudyResultVersionLabel({ 
        prototype: this.prototype, 
        prototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt: this.getPrototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt 
      })
    },
    prototypeIndex() {
      return this.getPrototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt.map(p => parseInt(p.study_id)).indexOf(parseInt(this.prototype.study.id))
    },
    nextPrototype() {
      return this.getPrototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt[this.prototypeIndex - 1]
    },
    isNewestVersion() {
      return this.prototypeIndex === 0
    },
    newestPrototypeId() {
      return this.getPrototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt ? this.getPrototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt?.[0]?.id : false
    },
    newestStudyId() {
      return this.getPrototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt ? this.getPrototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt?.[0]?.study_id : false
    },
    hasNewerVersionReleased() {      
      return this.nextPrototype && this.nextPrototype.study.published_at ? true : false
    },
    hasNewerVersionOnlyInPdf() {      
      return this.nextPrototype && !this.nextPrototype.study.published_at ? true : false
    },
    getMiniTagLayout() {
      if (this.hasNewerVersionOnlyInPdf) {
        return 'solid-info'
      } else if (this.isNewestVersion) {
        return 'solid-success'      
      } else if (this.hasNewerVersionReleased) {
        return 'warning'      
      } else {
        return false
      }
    },
    getPrototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt() {
      return this.$store.getters['globalEntities/Prototype/getterPrototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt']({ 
        type_prototype: this.prototype.type_prototype, 
        study_group: this.studyGroup 
      })
    }
  },
  methods: {
    onClick() {
      if (this.hasNewerVersionReleased) {
        const route_exclude_study_ids = this.$route?.query?.exclude_study_ids ? this.$route?.query?.exclude_study_ids.split(',') : []
        if (!route_exclude_study_ids.includes(`${this.newestStudyId}`)) {
          this.scrollToNewestPrototype()
        } else {
          this.$eventBus.$emit('openForecastStudyFilter')
        }
      } else if (this.isNewestVersion) {
        this.$eventBus.$emit('openStudyGroupVersionsTimelineModal', { studyGroupId: this.studyGroup.id, typePrototype: this.prototype.type_prototype })
      } else if (this.hasNewerVersionOnlyInPdf) {        
        this.$eventBus.$emit('openNewStudyVersionModal', { study: this.nextPrototype.study })                
      } else {
        return false
      }
    },
    scrollToNewestPrototype() {
      const studyResultsIndexEl = document.getElementById(`study-results-index`)
      const studyResultsHeaderEl = document.getElementById(`study-results-header`)
      const prototypeEl = document.getElementById(`forecast-prototype-${this.newestPrototypeId}`)
      studyResultsIndexEl.scrollTo({ top: prototypeEl.offsetTop - studyResultsHeaderEl.offsetHeight - 30, left: 0, behavior: 'smooth' })
    }
  },
}
</script>

<style lang="scss" scoped>
  ::v-deep {
    .psui-el-mini-tag {
      &.psui-el-mini-tag-layout-warning {

        &:hover {
          background-color: #EDAB3E;
          color: white;
        }
      }
    }
  }
</style>