<template>
  <div>    
    <PsRichTooltip
      v-if="disabled"
      title="Please select at least one measure"
      layout="red"
    >
      <template #trigger>
        <PsButton
          data-test-id="custom-combination-save-to-policy-button-disabled"
          size="big"
          icon="text_snippet"
          :label="'Save to Policy' | policyTextRephrase"
          :disabled="disabled"
        />
      </template>
    </PsRichTooltip>
    <PsButton
      v-else
      data-test-id="custom-combination-save-to-policy-button-enabled"
      size="big"
      icon="text_snippet"
      :label="'Save to Policy' | policyTextRephrase"
      :loading="loading"      
      @click="loading ? '' : $emit('click')"
    />
  </div>
</template>

<script>
export default {
  name: 'CustomCombinationToPolicyButton',
  props: {
    disabled : {
      type:Boolean,
      default:true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {

    getTooltip() {
      return this.isDisabled ? false : ''
    }
  },
}
</script>
