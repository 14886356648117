<template>
  <BaseDrawer
    ref="baseDrawer"
    title="Edit Requirements"
    class="app-drawer-requirements psui-relative h-screen"
    layout="requirements"
    :hierarchy="DRAWER_HIERARCHY.PRIMARY"
    :enable-full-screen="true"
    drawer-classes="psui-bg-gray-10"
  >
    <template #header>
      <div class="app-drawer-header psui-flex psui-flex-col psui-bg-white psui-shadow-elevation-5 psui-mb-4">
        <div class="ps-tooltip-adjustments psui-w-auto psui-flex psui-justify-between psui-items-center">
          <div
            class="back-to-requirements-container"
            @click="close()"
          >
            <PsButton
              layout="onlytext"
              label="back to requirements"
              icon="arrow_back"
              size="small"
              class="back-to-requirements-button"
            />
          </div>
        </div>
      </div>
    </template>
    <div class="app-breadcrump">
      <AppBreadcrumb
        v-if="breadcrumbs"
        :truncate-items="true"
        :items="breadcrumbs"
        truncate-width="300"
      />
    </div>
    <div
      class="container flex flex-col justify-between h-full"
    >
      <div class="flex flex-col">
        <div class="policy-existing-cost-effective-drawer-content">
          <h1 class="headline">
            Understanding  “Maximum Cost-Effective Score” 
          </h1>

          <div class="helper-container">
            <div
              v-if="!isHelperLoading && isUserAdmin && $store.getters.getterLoggedUserType == 'admin'"
              v-go-to-admin-edit="{ name: 'AdminHelperEdit', params: { helper_id: helperId } }"
              class="bg-violet py-1 px-2 my-1"
            >
              Edit this helper at Admin
            </div>
            <div
              v-if="isHelperLoading"
              class="w-full flex mt-6 max-w-full overflow-hidden"
            >
              <AppLoader />
            </div>
            <RenderHtmlExtended
              v-else-if="!isHelperLoading && helperContent"
              :html="helperContent"
              class="render-html-content"
            />
            <div
              v-else
              class="rounded bg-gray06 p-3"
            >
              <p class="text-gray">
                No content available
              </p>
            </div>
          </div>
        </div>
        <span
          v-for="(header, index) in headers"
          :key="`header-${index}`"
        >
          <table class="policy-existing-cost-effective-drawer-table table">
            <thead class="table-header">
              <tr>
                <th
                  v-for="(item, itemIndex) in header"
                  :key="itemIndex"
                  class="table-header-content"
                >
                  <div class="table-header-content-wrapper flex items-center">
                    {{ item.content }}
                    <PsIcon
                      v-if="item.hasHelper"
                      icon="info_outline"
                      size="16"
                      class="table-header-content-wrapper-icon opacity-0 psui-text-gray-40 hover:psui-text-blue-60 psui-transition psui-leading-none psui-cursor-pointer psui-ml-1"
                      display="flex"
                      @click.native="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: item.hasHelper.slug })"
                    />
                  </div>
                </th>
              </tr>
              <tr>
                <th
                  v-for="(item, itemIndex) in header"
                  :key="itemIndex"
                  class="table-header-description"
                >
                  {{ item.description }}
                </th>
              </tr>
            </thead>

            <tbody class="table-body">
              <tr
                v-for="(item, itemIndex) in bodies[index]"
                :key="itemIndex"
                :class="[item[0].isSumRow ? 'sum-row' : 'ordinary-row', itemIndex == bodies[index].length - 1 ? 'last-sum-row' : '']"
              >
                <td
                  v-for="(subItem, subIndex) in item"
                  :key="`sub-${subIndex}`"
                  class="table-body-item my-auto"
                >
                  <div class="table-body-item-wrapper flex items-center">
                    <RenderHtmlExtended
                      :html="formatContent(subItem.content, subIndex)"
                      :class="{'left-center-alignment': subItem && subItem.key && subItem.key !== 'title' && subIndex > 0}"
                      class="flex flex-col"
                    />
                    <PsIcon
                      v-if="subIndex == 0 && !subItem.isSumRow"
                      icon="info_outline"
                      size="16"
                      class="table-body-item-wrapper-icon opacity-0 psui-text-gray-40 hover:psui-text-blue-60 psui-transition psui-leading-none psui-cursor-pointer psui-ml-1"
                      display="flex"
                      @click.native="$eventBus.$emit('openDescriptionModal', { type: 'measure', slug: subItem.measure_id })"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </span>
        <span class="psui-text-small psui-text-gray-60">Calculations are based on the "{{ calculationMethodName }}" cost-effectiveness method. <a
          class="psui-font-bold psui-text-blue-60 cursor-pointer"
          @click="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: 'calculation_method' })"
        >Learn more</a> </span>
      </div>
      <div
        v-if="!isLoading && getterUserCanEditPolicy && getStudySource"
        class="psui-flex psui-items-center psui-text-small psui-text-gray-60 psui-leading-none my-4"
        :style="styles"
        @mouseenter="onHoverTypePrototype"
        @mouseleave="onHoverTypePrototype"
      >
        <span>
          <strong class="psui-font-bold">Study Source:</strong>
        </span>
        <span
          class="psui-ml-1 psui-cursor-pointer transition-all psui-text-gray-60 hover:psui-text-blue-60"
          @click.stop="onClickStudySourceInfo(getStudySource.id)"
        >
          {{ getStudySource.title }}
        </span>
        <span class="psui-ml-2">
          <strong class="psui-font-bold">Release Date:</strong>
        </span>
        <span
          v-if="getStudySource.released_at"
          class="psui-ml-1 psui-cursor-pointer transition-all psui-text-gray-60 hover:psui-text-blue-60"
          @click="$eventBus.$emit('openStudyGroupVersionsTimelineModal', { studyGroupId: getStudySource.study_group_id })"
        >
          {{ formatDate({ date: getStudySource.released_at, format: 'MMMM D, YYYY', from: 'mm/dd/yyyy' }) }}
        </span>
        <div
          class="psui-flex psui-mx-2"
          :class="isHoveringTypePrototype ? 'opacity-1' : 'opacity-0'"
        >
          <PsIcon
            :style="{ display: 'flex' }"
            icon="info_outline"
            size="16"
            class="psui-cursor-pointer transition-all psui-text-gray-40 hover:psui-text-blue-60"
            @click.native="openPrototypeDescriptionModal(getPrototype)"
          />
        </div>
      </div>
    </div>
  </BaseDrawer>
</template>

<script>
import AppBreadcrumb from '@/components/general/AppBreadcrumb.vue'
import { mapGetters } from 'vuex'
import { DRAWER_HIERARCHY } from '@/util/Enums.js'
import CustomCombinationApiService from '@/services/api/CustomCombinationApiService'
import formatStudyData from '@/formatters/formatStudyData'
import RenderHtmlExtended from '@/components/general/RenderHtmlExtended.vue'
import HelperApiService from '@/services/api/HelperApiService.js'
import { formatNumberExtended } from '@/formatters/formatNumberExtended.js'
import {ExistingBuildingsPOBuilder} from "@/business-logic/services/policy-options/builders/existing-buildings"
import {CUSTOM_COMBINATION_PRESETS} from "@/modules/app/jurisdiction/study-results/shared/enums"

export default {
  name: 'PolicyExistingCostEffectiveDrawer',
  components: {
    AppBreadcrumb,
    RenderHtmlExtended,
  },
  data() {
    return {
      custom_combination: null,
      breadcrumbs: null,
      isLoading: false,
      isHoveringTypePrototype: false,
      DRAWER_HIERARCHY,
      tables: [],
      headers: [],
      bodies: [],
      isHelperLoading: true,
      helperContent: '',
      helperId: null,
      flex_mandatory_measures: [],
      flex_excluded_measures: [],
      calculation_method: '',
      windowHeight: window.innerHeight,
      styles: '',
    }
  },
  computed: {
    getterUserCanEditPolicy() {
      return this.$store.getters['policy/getterUserCanEditPolicy'](this.$route.params.policy_id)
    },
    getPrototype() {
      if (!this.custom_combination) return false
      const custom_combination = this.custom_combination
      return this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: custom_combination.prototype_id })
    },
    getStudySource() {
      const prototype = this.getPrototype
      const study = this.$store.getters['globalEntities/Study/getterGlobalStudy']({ id: prototype.study_id })
      return { id: study.id, title: study.title, released_at: study.released_at, study_group_id: study.study_group_id }
    },
    isUserAdmin() {
      return this.$store.getters['getterLoggedUserIsAdmin']
    },
    calculationMethodName() {
      if (!this.calculation_method) return ''
      return CUSTOM_COMBINATION_PRESETS[this.calculation_method]?.calculationMethodLabel || CUSTOM_COMBINATION_PRESETS[this.calculation_method].label
    },
    ...mapGetters('assumptions', ['getterAssumptionsDrawerOptions']),
  },
  watch: {
    windowHeight: {
      handler() {
        this.getStudySourceStyles()
      },
      immediate: true,
    },
  },
  mounted() {
    this.resetState()
    this.$eventBus.$on('openPolicyExistingCostEffectiveDrawer', async ({ custom_combination, flex_mandatory_measures, flex_excluded_measures, calculation_method }) => {
      if (this.isLoading) return
      this.isLoading = true

      setTimeout(() => {
        this.getStudySourceStyles()
      }, 300)

      this.flex_mandatory_measures = flex_mandatory_measures
      this.flex_excluded_measures = flex_excluded_measures
      this.calculation_method = calculation_method

      this.resetState()
      this.getBreadcrumbs(custom_combination)
      this.custom_combination = custom_combination

      const helperType = 'helper'
      const helperSlug = 'cost_effective_score'

      await this.getHelperContent({ helperType, helperId: null, helperSlug })

      await this.getTables()
      await this.addTables()
      this.$refs.baseDrawer.openDrawer()
      this.isLoading = false
    })
  },
  created() {
    window.addEventListener('resize', () => {
      this.windowHeight = window.innerHeight
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openPolicyExistingCostEffectiveDrawer')
    window.removeEventListener('resize', () => {
      this.windowHeight = window.innerHeight
    })
  },
  methods: {
    getStudySourceStyles() {
      const headerHeight = document.getElementsByClassName('app-drawer-header')[0]?.offsetHeight
      const breadcrumpHeight = document.getElementsByClassName('app-breadcrump')[0]?.offsetHeight
      const drawerContentHeight = document.getElementsByClassName('policy-existing-cost-effective-drawer-content')[0]?.offsetHeight
      const tableHeight = document.getElementsByClassName('policy-existing-cost-effective-drawer-table')[0]?.offsetHeight
      const studySourceHeight = document.getElementsByClassName('study-source')[0]?.offsetHeight
      const marginsHeight = 21

      const drawerContentTotalHeight = headerHeight + breadcrumpHeight + drawerContentHeight + tableHeight + studySourceHeight + marginsHeight

      let styles = {}
      if (this.windowHeight > drawerContentTotalHeight) {
        styles = {
          position: 'absolute',
          'margin-top': 'auto',
        }
      } else {
        styles = {
          position: '',
          'padding-bottom': '0px',
        }
      }
      this.styles = styles
      this.$forceUpdate()
    },
    formatContent(content, index) {
      if (index == 0) {
        return content
      } else if (index == 1) {
        return formatNumberExtended(parseFloat(content), 1, 0).toString()
      } else {
        return formatNumberExtended(parseFloat(content), 0, 0).toString()
      }
    },
    resetState() {
      this.tables = []
      this.headers = []
      this.bodies = []
      this.isLoading = false
    },
    getBreadcrumbs(custom_combination) {
      this.breadcrumbs = [
        { title: custom_combination.policy.title },
        { title: custom_combination.prototype.title },
        { title: 'Climate Zone ' + custom_combination.climate_zone.prefix },
        { title: custom_combination.vintage.title },
        { title: 'Maximum Cost-Effective Score' },
      ]
    },
    async getTables() {
      const flex_mandatory_measures = this.flex_mandatory_measures
      const flex_excluded_measures = this.flex_excluded_measures
      const preset = await this.getPresets()

      const headers = [
        {
          content: 'Measures',
          description: 'used to calculate Maximum Cost-Effective Score',
          key: 'title',
          hasHelper: {
            type: 'helpers',
            slug: 'measures',
          }
        },
        {
          content: 'Energy Savings',
          description: 'site MMBtu/year',
          key: 'energy_savings_combined',
          hasHelper: {
            type: 'helpers',
            id: 62,
            slug: 'energy_savings_combined',
          }
        },
        {
          content: 'Flexible Score',
          description: 'rounded energy savings',
          key: 'flexible_score',
          hasHelper: {
            type: 'helpers',
            slug: 'flexible_score',
          },
        },
      ]

      const preset_rows = preset.map((item) => {
        return [
          {
            content: item.title,
            key: 'title',
            measure_id: item.measure_id,
          },
          {
            content: formatStudyData('energy_savings_combined', item.energy_savings_combined, item, null),
            key: 'energy_savings_combined',
          },
          {
            content: formatStudyData('flexible_score', null, item, null),
            key: 'flexible_score',
          },
        ]
      })

      const combinedMeasures = [...flex_mandatory_measures, ...flex_excluded_measures]

      const uniqueMeasures = Array.from(combinedMeasures.reduce((map, obj) => map.set(`${obj.measure_id}-${obj.type_vintage_id}`, obj), new Map()).values())

      this.tables.push({
        headers,
        preset_rows,
        excluded_and_mandatory_measures: uniqueMeasures,
      })
    },
    async addTables() {
      const tableHeaders = this.tables[0].headers
      const initialRows = this.tables[0].preset_rows
      const specialMeasures = this.tables[0].excluded_and_mandatory_measures

      const excludedMeasureIds = new Set(specialMeasures.map((measure) => measure.measure_id))

      const filteredInitialRows = initialRows.filter((row) => !excludedMeasureIds.has(row[0].measure_id))
      const excludedMeasureRows = initialRows.filter((row) => excludedMeasureIds.has(row[0].measure_id))

      this.headers.push({ ...tableHeaders })

      if (filteredInitialRows.length && !excludedMeasureRows.length) {
        const sumRowForFilteredRows = await this.addSumRow(filteredInitialRows, 'preset_only')
        const combinedTableBody = [...filteredInitialRows, ...sumRowForFilteredRows]

        this.bodies.push(combinedTableBody)
      } else if (filteredInitialRows.length && excludedMeasureRows.length) {
        const sumRowForFilteredRows = await this.addSumRow(filteredInitialRows, 'preset_excluded_and_mandatory_measures')
        const combinedRows = [...filteredInitialRows, ...excludedMeasureRows]

        const sumRowForCombinedRows = await this.addSumRow(combinedRows, 'excluded_and_mandatory_measures')
        const combinedTableBody = [...filteredInitialRows, ...sumRowForFilteredRows, ...excludedMeasureRows, ...sumRowForCombinedRows]

        this.bodies.push(combinedTableBody)
      } else if (excludedMeasureRows.length) {
        const sumRowForExcludedMeasures = await this.addSumRow(excludedMeasureRows, 'excluded_and_mandatory_measures')
        const combinedTableBody = [...excludedMeasureRows, ...sumRowForExcludedMeasures]

        this.bodies.push(combinedTableBody)
      }
    },
    async addSumRow(rows, from) {
      let energySavingsSum = 0
      let flexibleScoreSum = 0

      for (let row of rows) {
        for (let cell of row) {
          if (cell.key === 'energy_savings_combined') {
            energySavingsSum += parseFloat(cell.content)
          } else if (cell.key === 'flexible_score' && cell.content !== '--') {
            flexibleScoreSum += parseInt(cell.content)
          }
        }
      }

      const sumRow = [
        {
          content:
            from == 'preset_only'
              ? '<p style="color: #318FAC; font-family: Lato; font-size: 14px; font-style: normal; font-weight: 700; line-height: 130%;">Maximum</p>'
              : from == 'preset_excluded_and_mandatory_measures'
              ? `<p style="color: #318FAC; font-family: Lato; font-size: 14px; font-style: normal; font-weight: 700; line-height: 130%;">Total (Maximum Cost-Effective Score using <u>available</u> flexible measures)</p>
                 <p style="color: #318FAC; font-family: Lato; font-size: 14px; font-style: normal; font-weight: 400; line-height: 130%;">Measures you have removed or set to mandatory are not included</p>`
              : `<p style="color: #318FAC; font-family: Lato; font-size: 14px; font-style: normal; font-weight: 700; line-height: 130%;">Total (Maximum Cost-Effective Score using <u>any</u> flexible measures)</p>
                 <p style="color: #318FAC; font-family: Lato; font-size: 14px; font-style: normal; font-weight: 400; line-height: 130%;">Includes measures you have removed or set to mandatory (listed above)</p>`,
          key: 'title',
          isSumRow: true,
        },
        { content: energySavingsSum.toFixed(2), key: 'energy_savings_combined' },
        { content: flexibleScoreSum, key: 'flexible_score' },
      ]

      return [sumRow]
    },
    async getPresets() {
      const { data } = await CustomCombinationApiService.getPresets({
        vintage_id: this.custom_combination.vintage_id,
        prototype_id: this.custom_combination.prototype_id,
        climate_zone_raw: this.custom_combination.climate_zone_raw,
      })
      return (await ExistingBuildingsPOBuilder.getNormalizedPresetMeasures(data.presets[this.calculation_method],
          this.getPrototype.study_id, this.getPrototype.id, this.custom_combination.climate_zone_raw, this.custom_combination.vintage_id))
    },
    async getHelperContent({ helperType, helperId = null, helperSlug = null }) {
      const type = helperType
      const id = helperId
      const slug = helperSlug

      const result = await HelperApiService.get({ type, id, slug })

      if (!result || result.length === 0) {
        this.$toast.error(`Sorry, we can't find the requested resource :(`)
        return
      }

      const dataType = Array.isArray(result) ? result : [result]

      const dataBySlug = dataType?.find((item) => {
        return item?.slug === slug
      })

      const dataById = dataType?.find((item) => {
        return item?.id === id
      })

      const helper = dataBySlug || dataById || null

      this.helperContent = helper?.content
      this.helperId = helper?.id
      this.isHelperLoading = false
    },
    close() {
      this.$refs.baseDrawer.closeDrawer()
      this.resetState()
    },
    onHoverTypePrototype() {
      this.isHoveringTypePrototype = !this.isHoveringTypePrototype
    },
    onClickStudySourceInfo(study_id) {
      this.$eventBus.$emit('openDrawerContent', { type: 'study', id: study_id })
    },
    openPrototypeDescriptionModal(prototype) {
      this.$eventBus.$emit('openDescriptionModal', { type: 'prototype', id: prototype.id })
    },
  },
}
</script>

<style lang="scss" scoped>
$font-family-lato: Lato;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-20: 20px;
$font-weight-400: 400;
$font-weight-700: 700;
$line-height-120: 120%;
$line-height-130: 130%;
$color-blue-10: #ecf7fb;
$color-blue-60: #318fac;
$color-blue-70: #00465f;
$color-gray-10: #f3f6f9;
$color-gray-50: #798490;
$color-gray-60: #515E6A;
$color-gray-80: #28323b;

.app-drawer-requirements {
  width: 100%;
} 

.table-body-item-wrapper:hover {
  .table-body-item-wrapper-icon {
    opacity: 100;
  }
}

.app-drawer-title {
  padding-left: 14px;
}

.back-to-requirements-container {
  display: flex;
  height: 26px;
  padding-right: 24px;
  padding-left: 18px;
  align-items: center;
  gap: 4px;

  .back-to-requirements-button {
    padding-left: 4px;
    color: $color-blue-60;
    text-align: right;
    font-size: $font-size-14;
    font-weight: $font-weight-700;
    line-height: $line-height-130;
  }
}

.app-breadcrump {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
  padding-left: 24px;
}

.container {
  padding-right: 20px;
  padding-left: 14px;
  padding-bottom: 26px;

  .policy-existing-cost-effective-drawer-content {
    padding-top: 20px;

    .headline {
      color: $color-blue-70;
      font-size: $font-size-20;
      font-weight: $font-weight-700;
      padding-bottom: 12px;
    }

    .helper-container {
      width: 100%;
      padding-bottom: 16px;

      .render-html-content {
        color: $color-gray-60;
        font-size: $font-size-16;
        font-weight: $font-weight-400;
        line-height: $line-height-130;
      }
    }
  }

  .table {
    width: 100%;
    background-color: #ffffff;
    box-shadow: -1px 0px 2px rgba(0, 0, 0, 0.03), 1px 2px 4px rgba(0, 0, 0, 0.05), -1px 2px 2px rgba(0, 0, 0, 0.05);
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    td {
      vertical-align: middle;
    }

    .table-header {
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 1;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: -6px;
        right: 0;
        left: 0;
        height: 1rem;
        background-color: inherit;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        z-index: -1;
        box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.03);
      }

      tr th {
        border: none;
        padding-top: 8px;
        &:nth-child(1) {
          padding-left: 1.5rem;
        }
        &:nth-child(2),
        &:nth-child(3) {
          text-align: center;
        }
      }

      .table-header-content {
        color: $color-gray-80;
        font-size: $font-size-14;
        font-weight: $font-weight-700;
        padding-bottom: 0;

        &:not(:first-child) {
          .table-header-content-wrapper {
            place-content: center;
            margin-left: 18px;
          }
        }

        &:hover {
          .table-header-content-wrapper {
            .table-header-content-wrapper-icon {
              opacity: 100;
            }
          }
        }
      }

      .table-header-description {
        color: $color-gray-50;
        font-size: $font-size-12;
        font-weight: $font-weight-400;
        padding-top: 0;
      }
    }

    .table-body {
      tr {
        &:first-child {
          border-top: 2px solid $color-gray-10;
        }
        &:not(:last-child) {
          border-bottom: 2px solid $color-gray-10;
        }

        td {
          font-family: $font-family-lato;
          font-size: $font-size-14;
          font-style: normal;
          line-height: $line-height-130;

          &:nth-child(1) {
            padding-left: 1.5rem;
          }
          &:nth-child(2),
          &:nth-child(3) {
            text-align: center;
          }

          .left-center-alignment {
            width: calc(50% + 1em);
            text-align: right;
          }
        }
      }

      .sum-row {
        background-color: $color-blue-10;

        td {
          color: $color-blue-60;
          font-weight: $font-weight-700;
        }
      }

      .ordinary-row {
        background-color: #ffffff;

        td {
          color: $color-gray-80;
          font-weight: $font-weight-400;
        }
      }

      .last-sum-row {
        td {
          &:first-child {
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-bottom-right-radius: 0.5rem;
          }
        }
      }
    }
  }
}

.study-source {
  padding-left: 24px;
  position: absolute;
  font-size: 13px;
  bottom: 24px;
}

.ps-tooltip-adjustments {
  padding: 7px 0;
}
</style>
