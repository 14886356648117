<template>
  <BaseDrawer
    ref="baseDrawer"
    class="app-drawer-requirements psui-relative"
    layout="requirements"
    :hierarchy="DRAWER_HIERARCHY.PRIMARY"
    :enable-full-screen="true"
    drawer-width="100%"
    transition-type="none"
    :open-left-panel-on-mounted="true"
  > 
    <template #leftPanel>
      <PolicyEditRequirementsExistingBuildingsLeftSideBarVue
        :current-custom-combination="custom_combination"
        :is-loading-drawer="isLoading"
        :flexible-path="flexible_path_setup"
        :filters="filters"
        @toggleRequirements="toggleRequirements"
        @toggleVintageRequirements="toggleVintageRequirements"
      />
    </template>

    <template #mainHeader>
      <div
        class="existing-header psui-w-auto psui-flex psui-justify-between psui-items-center bg-white psui-shadow-elevation-5"
        :class="{'has-actions': !$refs.baseDrawer.leftPanelIsOpen}"
      >
        <AppBreadcrumb
          v-if="showBreadcrumbItems"
          :items="showBreadcrumbItems"
          class="breadcrumb psui-w-3/5"
          @click="handleBreadcrumbClick"
        />
              
        <PsButton
          layout="ghost"
          label="Done"
          size="small"
          :loading="isSavingCustomCombination"
          class="ml-auto"
          @click.native="saveRequirement()"
        />
      </div>
    </template>
    
    <template #header>
      <div
        class="flex flex-col mb-2"
      >
        <div
          v-if="!isLoading && filters.custom_combination_id"
          class="psui-flex pl-6 pr-3 items-center justify-between py-2"
        >
          <h1 
            class="psui-text-gray-80 psui-text-small psui-font-bold"
          >
            {{ 'Configure your policy requirements for existing buildings' | policyTextRephrase }}
          </h1>
          <div class="flex space-x-6 pr-3">
            <div
              class="psui-flex items-center psui-cursor-pointer justify-end psui-text-blue-60 hover:opacity-80"
              @click="openDrawerWithEditColumns"
            >
              <PsIcon
                icon="calendar_view_week"
                size="18"
              />
              <span class="psui-ml-1 psui-font-bold psui-text-small">Add/Hide Columns</span>
            </div>
            <div
              class="psui-flex items-center psui-cursor-pointer justify-end psui-text-blue-60 hover:opacity-80"
              @click="openAssumptionsSidebar"
            >
              <PsIcon
                icon="tune"
                size="18"
              />
              <span class="psui-ml-1 psui-font-bold psui-text-small">{{ !getterUserCanEditPolicy ? 'View Assumptions':'Edit Assumptions' }}</span>
            </div>
          </div>
        </div>
        <div v-if="!isLoading && filters.custom_combination_id">
          <div
            v-if="!getterUserCanEditPolicy"
            class="px-6 psui-py-4"
          >
            <PsDialog
              theme="alert"
              :has-close="false"
              class="psui-cursor-pointer"
              @click.native="$eventBus.$emit('openDuplicatePolicyModal', { policy: getPolicy })"
            >
              <template #default>
                <div class="psui-flex psui-space-x-4">
                  <p class="psui-mr-auto">
                    You are viewing a shared {{ 'policy' | policyTextRephrase }}. To review other options and make changes <strong>duplicate this {{'policy' | policyTextRephrase}}</strong>.
                  </p>
                </div>
              </template>
            </PsDialog>
          </div>
          <div
            class="flex flex-col bg-white px-6 py-2"
            :class="{'pointer-events-none':!getterUserCanEditPolicy}"
          >
            <span class="psui-text-blue-80 psui-text-accentSmall psui-font-bold psui-uppercase">
              {{'POLICY REQUIREMENTS' | policyTextRephrase}}
            </span>
            <div
              class="flex flex-col psui-bg-blue-10 mt-2 p-2 rounded-t-sm"
              :class="{'psui-bg-blue-10':isSpecificMeasuresActive}"
            >
              <div class="flex items-center py-px">
                <div class="flex items-center mr-1/2">
                  <PsTooltip
                    layout="blue"
                    :ignore-dialog="true"
                    class="require-specific-measures-tooltip"
                  >
                    <template #trigger>
                      <PsSwitch
                        :value="isSpecificMeasuresActive"
                        size="small"
                        background-color="psui-bg-blue-60"
                        class="flex"
                        @change="setSpecificMeasureActive"
                      />
                    </template>
                    <template #content>
                      <span class="psui-font-bold">
                        To enable the switch button, please select one or more measures in the table below.
                      </span>
                    </template>
                  </PsTooltip>
                  <span class="psui-text-small psui-text-blue-70 font-bold ml-1">Require specific measures</span>
                </div>
                <PsIcon
                  icon="info_outline"
                  size="16"
                  icon-classes="psui-text-gray-40 cursor-pointer hover:psui-text-blue-60"
                  @click.native="$eventBus.$emit('openDescriptionModal', { type: 'helper' , slug: 'require_specific_measures' })"
                />
              </div>
              <div
                v-if="isSpecificMeasuresActive && getCustomCombinationsIncluded.length > 0"
                class="flex space-x-4 p-2 mt-2 psui-bg-blue-20"
              >
                <span
                  v-for="measure in getCustomCombinationsIncluded"
                  :key="`measure-${measure.id}`"
                  class="psui-text-gray-70 psui-text-small py-1 px-2 psui-bg-white psui-shadow-elevation-5 rounded-xs"
                >
                  {{ measure.title }}
                </span>
              </div>
            </div>
            <div
              class="flex flex-col psui-bg-blue-10 p-2 rounded-b-sm border-t border-white"
              :class="{'psui-bg-blue-10':isFlexibleScoreRequired}"
            >
              <div class="flex items-center py-px">
                <PsSwitch
                  ref="switchItem"
                  :value="isFlexibleScoreRequired"
                  background-color="psui-bg-blue-60"
                  size="small"
                  @change="setMinimumFlexiblesScore"
                />
                <span class="psui-text-small psui-text-blue-70 font-bold mx-1">Require a minimum flexible score</span>
                <PsIcon
                  icon="info_outline"
                  size="16"
                  icon-classes="psui-text-gray-40 cursor-pointer hover:psui-text-blue-60"
                  @click.native="$eventBus.$emit('openDescriptionModal', { type: 'helper' , slug: 'require_flexible_score' })"
                />
              </div>
              <PolicyExistingBuildingsFlexibleScore
                v-if="isFlexibleScoreRequired && climateZoneFromFlexibleObj"
                :custom_combination="custom_combination"
                :type_vintage="getTypeVintage"
                :flexible_path_setup="flexible_path_setup"
                :climate_zone="climateZoneFromFlexibleObj"
                :policy="getPolicy"
                :prototype="prototypeFromFlexibleObj"
                :max_target_scores_by_presets="max_target_scores_by_presets"
                :change_calculation_method_checker="checkCanChangeCalculationMethod"
                :flex_mandatory_measures="getFlexibleMandatoryMeasures"
                :flex_excluded_measures="getExcludedMeasures"
                :calculation_method="getPresetByPrototypeAndClimateZone"
                @onRestore="clearMandatoryAndExcludedFromPreset"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <div
      v-if="!isLoading && filters.custom_combination_id"
      id="tableWrapper"
      class="psui-flex psui-flex-col psui-shadow-elevation-10 psui-bg-white pb-2 px-6 h-full"
    >
      <PsTableResults
        v-if="!isLoading"
        :summary-data="type_measures"
        :column-groups="getEditRequirementsColumns"
        :show-rows-collapsed="false"
        :disable-switch-buttons="!isSpecificMeasuresActive"
        switch-button-background-color="psui-bg-blue-60"
        table-max-height="auto"
        :format-function="formatStudyData"
        :prevent-is-disabled-on-items-value="true"
        :prevent-click-on-switch-buttons="!getterUserCanEditPolicy"
        :items-hidden-indexes="hiddenIndexes"
        layout="flexible"
        class="mt-2"
        @switchButtonItemChanged="setMeasure"
        @removeOrAddButtonChange="removeItem"
        @policy-selected="openProjections"
      >
        <template #header>
          <PsTableResultsHeadFlexible
            v-if="!isLoading"
            :header="getHeaderColumns"
            first-column-title="Measure List"
            @click-column-group-helper="openColumnGroupHelper"
            @click-column-helper="openColumnHelper"
            @column-alert-show="toggleWarningAlert"
            @column-alert-hide="toggleWarningAlert"
          />
        </template>
        <template #footer>
          <tfoot
            v-if="isSpecificMeasuresActive || isFlexibleScoreRequired"
            class="requirements-footer psui-sticky psui-z-15 bottom-0"
          > 
            <tr class="h-5" />
            <tr class="py-2">
              <td
                class="psui-text-blue-60 psui-font-bold psui-text-small psui-sticky left-0 z-15 psui-bg-blue-10"
                :class="{'total-error psui-text-yellow-70 psui-bg-yellow-10 z-20': hasTotalImpactError}"
              >
                <div v-if="!hasTotalImpactError">
                  {{ getRequirementsTotalImpact.title }}
                  <PsIcon
                    icon="info_outline"
                    size="16"
                    class="info-flexible-score psui-text-gray-40 hover:psui-text-blue-60"
                    @click.native="openDetailedFlexibleScoreDrawer"
                  />
                </div>
                <div
                  v-else
                  class="psui-flex"
                >
                  <i class="material-icons-round icon flex items-center">info</i>
                  <div class="psui-flex psui-space-x-4 items-center ml-1">
                    <p class="psui-mr-auto">
                      {{ 'Your required flexible score can’t be achieved, try reducing it to see your policy impacts.' | policyTextRephrase }} 
                      <a
                        href="https://intercom.help/explorer-local-energy-codes/en/articles/8613922-why-can-t-i-see-my-flexible-score-s-impact"
                        target="_blank"
                        class="psui-font-bold"
                      >Learn more.</a>
                    </p>
                  </div>
                </div>
              </td>
              <template v-for="(columnGroup, columnIndex) of getEditRequirementsColumns">
                <td
                  v-for="column of columnGroup.columns"
                  :key="`footer-${column.key}-${columnIndex}`"
                  class="pl-3 psui-bg-blue-10 psui-text-small psui-text-gray-70"
                  :class="{'psui-text-yellow-70 psui-bg-yellow-10': hasTotalImpactError}"
                >
                  <div
                    v-if="!isLoadingTotalImpact || hasTotalImpactError"
                    class="flex items-center"
                  >
                    <span
                      :class="[column.key == 'flexible_score' || column.key == 'mandatory' ? 'mx-auto' : 'ml-auto']"
                    >
                      {{ getTotalImpactColumnContent(column, columnIndex) }}
                    </span>
                    <PsProgressBar
                      v-if="column.isChart && totalImpactData[column.key] != null"
                      :value="getTotalImpactColumnContent(column, columnIndex) == '--' ? 0 : totalImpactData[column.key]"
                      :force-break-even="getTotalImpactColumnContent(column, columnIndex) === '--' ? true : false"
                      class="ml-2"
                    />
                  </div>
                  <div v-else>
                    <vue-skeleton-loader
                      :width="80"
                      :height="21"
                      class="text-right"
                    />
                  </div>
                </td>
              </template>
            </tr>
          </tfoot>
        </template>
      </PsTableResults>
      <vue-skeleton-loader
        v-else
        class="mt-2"
        :width="getTableWidth"
        :height="getTableHeight"
      />
      <StudyResultsDrawerEditColumns />
    </div>
    <PolicyEditRequirementsInfoError v-else-if="!filters.custom_combination_id" />
    <PolicyEditRequirementsSkeleton
      v-else
    />

    <template
      v-if="!isLoading && getterUserCanEditPolicy && filters.custom_combination_id"
      #footer
    >
      <div
        v-if="getStudySource"
        class="psui-flex psui-items-center psui-text-small psui-text-gray-50 psui-leading-none my-4 pl-6 mt-2"
        @mouseenter="onHoverTypePrototype"
        @mouseleave="onHoverTypePrototype"
      >
        <span class="psui-font-bold">
          Study Source:
        </span>
        <span
          class="psui-ml-2 psui-cursor-pointer transition-all psui-text-gray-50 hover:psui-text-blue-60"
          @click.stop="onClickStudySourceInfo(getStudySource.id)"
        >
          {{ getStudySource.title }}
        </span>
        <span class="psui-ml-2">
          <strong class="psui-font-bold">Release Date:</strong>
        </span>
        <span
          v-if="getStudySource.released_at"
          class="psui-ml-2 psui-cursor-pointer transition-all psui-text-gray-50 hover:psui-text-blue-60"
          @click="$eventBus.$emit('openStudyGroupVersionsTimelineModal', { studyGroupId: getStudySource.study_group_id })"
        >
          {{ formatDate({ date: getStudySource.released_at, format: 'MMMM D, YYYY', from: 'mm/dd/yyyy' }) }}
        </span>
        <div
          class="psui-flex psui-mx-2"
          :class="isHoveringTypePrototype ? 'opacity-1' : 'opacity-0'"
        >
          <PsIcon
            :style="{ display: 'flex' }"
            icon="info_outline"
            size="16"
            class="psui-cursor-pointer transition-all psui-text-gray-40 hover:psui-text-blue-60"
            @click.native="onClickStudySourceInfo(getStudySource.id)"
          />
        </div>
      </div>
      <StudyResultsProjectionsDrawer />
      <PolicyExistingDetailedFlexibleScoreDrawer />
      <PolicyExistingCostEffectiveDrawer />
    </template>
  </BaseDrawer>
</template>

<script>
import StudyResultsProjectionsDrawer from '@/modules/app/jurisdiction/study-results/StudyResultsProjectionsDrawer.vue'
import PolicyEditRequirementsSkeleton from './PolicyEditRequirementsSkeleton.vue'
import AppBreadcrumb from '@/components/general/AppBreadcrumb.vue'
import StudyResultsDrawerEditColumns
  from '@/modules/app/jurisdiction/study-results/content/general/StudyResultsDrawerEditColumns.vue'
import CustomCombinationApiService from '@/services/api/CustomCombinationApiService'
import { DRAWER_HIERARCHY } from '@/util/Enums.js'
import { mapGetters } from 'vuex'
import TypeMeasureApiService from '@/services/api/TypeMeasureApiService.js'
import PolicyExistingBuildingsFlexibleScore
  from '@/modules/app/policy/show/requirements/requirements-drawer/PolicyExistingBuildingsFlexibleScore.vue'
import FlexiblePathService from '@/services/api/FlexiblePathService.js'
import {
  checkMeasureIsExcluded,
  checkMeasureIsPreset,
  getMaxPossibleScore,
  getMaxTargetScorePresetSelected,
  getMeasureScore,
  setTierTargetScore,
  toggleExcludedMeasures
} from '@/services/measure-menu/MeasureMenuGeneralService.js'
import { compareArray } from '@igortrindade/lazyfy'
import { CUSTOM_COMBINATION_PRESETS } from '@/modules/app/jurisdiction/study-results/shared/enums'
import { ExistingBuildingsPOBuilder } from "@/business-logic/services/policy-options/builders/existing-buildings"
import StudyData from '@/models/StudyData'
import cloneDeep from 'lodash/cloneDeep'
import ImpactAssumptions from '@/models/ImpactAssumptions'
import requirementDrawerMixin from './RequirementDrawerMixin'
import formatStudyData from '@/formatters/formatStudyData'
import PolicyExistingDetailedFlexibleScoreDrawer from '@/modules/app/policy/show/requirements/requirements-drawer/PolicyExistingDetailedFlexibleScoreDrawer.vue'
import MeasureApiService from '@/services/api/MeasureApiService.js'
import PolicyExistingCostEffectiveDrawer from '@/modules/app/policy/show/requirements/requirements-drawer/PolicyExistingCostEffectiveDrawer.vue'
import PolicyEditRequirementsExistingBuildingsLeftSideBarVue from '@/modules/app/policy/show/requirements/requirements-drawer/PolicyEditRequirementsExistingBuildingsLeftSideBar.vue'
import PolicyEditRequirementsInfoError from '@/modules/app/policy/show/requirements/requirements-drawer/PolicyEditRequirementsNewBuildingsSF2022/PolicyEditRequirementsInfoError.vue'
import { updateColumnCopiesAndHelpers } from '@/util/Functions'

export default {
  name: 'PolicyExistingBuildingsWithFlexiblePathRequirements',
  components: {
    PolicyEditRequirementsExistingBuildingsLeftSideBarVue,
    StudyResultsDrawerEditColumns,
    AppBreadcrumb,
    PolicyEditRequirementsSkeleton,
    PolicyExistingBuildingsFlexibleScore,
    StudyResultsProjectionsDrawer,
    PolicyExistingDetailedFlexibleScoreDrawer,
    PolicyExistingCostEffectiveDrawer,
    PolicyEditRequirementsInfoError
  },
  props: ['filters'],
  mixins:[requirementDrawerMixin],
  data() {
    return {
      custom_combination: [],
      breadcrumbs: null,
      DRAWER_HIERARCHY,
      isLoading : true,
      isSavingCustomCombination: false,
      isHoveringTypePrototype: false,
      type_measures: [],
      study_id: null,
      isSpecificMeasuresActive: false,
      isFlexibleScoreRequired: false,
      flexiblePathService: null,
      flexible_path_setup: null,
      max_target_scores_by_presets: null,
      prototypes:[],
      baseCustomCombination: {},
      baseFlexiblePath: null,
      movingMeasuresWhenFlexEnabled: [],
      oldFlexScore: null,
      hiddenIndexes: undefined,
      studyDataModified: null,
      alreadyExecRemovedSync: false,
      isLoadingTotalImpact: false,
      totalImpactDebouncer: null,
      totalImpactData: {},
      totalImpactMeasures: null,
      hasTotalImpactError: false,
      cachedData: {}
    }
  },
  computed: {
    showBreadcrumbItems() {
      return this.breadcrumbs
    },
    getMeasureAndPackagesGroup() {
      return this.type_measures.find(g => g.slug === 'measure_and_packages')
    },
    getTableWidth(){
      return  document.getElementById('tableWrapper').clientWidth
    },
    getTableHeight(){
      return document.getElementById('tableWrapper').clientHeight
    },
    prototypeFromFlexibleObj() {
      return this.prototypes.find(prototype => +prototype.type_prototype_id === +this.getPrototype.type_prototype_id)
    },
    climateZoneFromFlexibleObj() {
      return this.prototypeFromFlexibleObj.climate_zones.find((c) => c.raw === this.getClimateZone.raw)
    },
    getTypeVintageIndex() {
      return this.climateZoneFromFlexibleObj.type_vintages.findIndex((typeVintage) => +typeVintage.id === +this?.custom_combination?.vintage?.type_vintage_id) ?? undefined
    },
    getBaseFlexiblePathTier() {
      return this.baseFlexiblePath?.tiers.find(tier => +tier?.prototype_id === +this.custom_combination?.prototype?.id && +tier?.climate_zone_id === +this.custom_combination?.climate_zone?.id)
    },
    getFlexiblePathTier() {
      return this.flexible_path_setup?.tiers.find(tier => +tier?.prototype_id === +this.custom_combination?.prototype?.id && +tier?.climate_zone_id === +this.custom_combination?.climate_zone?.id)
    },
    getFlexibleTargetScoreObj() {
      return this.getFlexiblePathTier?.target_scores?.find((x) => +x.type_vintage_id === +this?.custom_combination?.vintage?.type_vintage_id)
    },
    getBaseTargetScore() {
      return this.getBaseFlexiblePathTier?.target_scores?.find((x) => +x.type_vintage_id === +this?.custom_combination?.vintage?.type_vintage_id)?.value || 0
    },
    getCurrentTargetScore() {
      return this.getFlexibleTargetScoreObj?.value ?? null
    },
    getIfHasFlexibleConfigured() {
      return Boolean(this.getFlexiblePathTier && (this.getCurrentTargetScore || this.getExcludedMeasures.length > 0))
    },
    getIfCustomCombinationOrFlexiblePathHasChanged() {
       return this.getIfFlexiblePathChanged || this.getIfCustomCombinationChanged
    },
    getIfFlexiblePathChanged() {
      return !compareArray((this.getBaseFlexiblePathTier?.mandatory_measures || []),(this.getFlexiblePathTier?.mandatory_measures || []), 'measure_id') ||
          !compareArray((this.getBaseFlexiblePathTier?.excluded_measures || []),(this.getFlexiblePathTier?.excluded_measures || []), 'measure_id') ||
          +this.getBaseTargetScore !== +this.getCurrentTargetScore ||
          this.getBasePresetByPrototypeAndClimateZone !== this.getPresetByPrototypeAndClimateZone
    },
    getIfCustomCombinationChanged() {
      return !compareArray((this.baseCustomCombination?.measures || []),(this.custom_combination?.measures || []), "id")
    },
    getPresetByPrototypeAndClimateZone() {
      return getMaxTargetScorePresetSelected({ climate_zone_id : this.custom_combination?.climate_zone?.id, prototype_id: this.custom_combination?.prototype?.id, flexible_path_setup: this.flexible_path_setup})
    },
    getBasePresetByPrototypeAndClimateZone() {
      return getMaxTargetScorePresetSelected({ climate_zone_id : this.custom_combination?.climate_zone?.id, prototype_id: this.custom_combination?.prototype?.id, flexible_path_setup: this.baseFlexiblePath})
    },
    getDefaultTargetScoreByCurrentPreset() {
      return this.getMaxTargetScoreByCurrentPreset || 0
    },
    getMaxPossibleScoreByCurrentPreset() {
      if (this.getTypeVintageIndex == null) {
        return this.getMaxTargetScoreByCurrentPreset
      }

      return getMaxPossibleScore({
        climate_zone: this.climateZoneFromFlexibleObj,
        type_vintage: this.climateZoneFromFlexibleObj?.type_vintages?.[this.getTypeVintageIndex],
        max_target_scores_by_presets: this.max_target_scores_by_presets,
        prototype: this.custom_combination?.prototype,
        type_vintage_index: this.getTypeVintageIndex,
        flexible_path_setup: this.flexible_path_setup
      })
    },
    getMaxTargetScorePresetMeasures() {
      const getMaxTargetScore = this.max_target_scores_by_presets.find(mts => mts.climate_zone_raw === this.custom_combination?.climate_zone?.raw && +mts?.type_vintage_id === +this?.custom_combination?.vintage?.type_vintage_id)
      return getMaxTargetScore?.data?.[this.getPresetByPrototypeAndClimateZone]?.map((m) => m.measure_id) || []
    },
    getMaxTargetScoreByCurrentPreset() {
      const getMaxTargetScore = this.max_target_scores_by_presets.find(mts => mts.climate_zone_raw === this.custom_combination?.climate_zone?.raw && +mts?.type_vintage_id === +this?.custom_combination?.vintage?.type_vintage_id)
      return getMaxTargetScore[this.getPresetByPrototypeAndClimateZone]
    },
    getMandatoryMeasures() {
      return [
        ...(this.custom_combination?.measures?.map((m) => {
          return {
            ...m,
            measure_id: m.id,
            type_vintage_id: this.custom_combination.vintage.type_vintage_id
          }
        }) || []),
        ...(this.getFlexibleMandatoryMeasures.map((m) => {
          return {
            ...m,
            id: m.measure_id,
            title: this.$store.getters['globalEntities/Measure/getterGlobalMeasure']({ id: m.measure_id }).title,
            pivot: {
              measure_id: m.measure_id,
              custom_combination_id: this.custom_combination.id,
            }
          }
        }) || [])
      ].reduce((acc, curr) => {
        if (!acc.find((i) => i.id === curr.id)) {
          acc.push(curr)
        }
        return acc
      }, [])
    },
    getFlexibleMandatoryMeasures() {
      return Array.isArray(this.getFlexiblePathTier?.mandatory_measures) ?
          this.getFlexiblePathTier.mandatory_measures.filter(m => +m?.type_vintage_id === +this.custom_combination?.vintage?.type_vintage_id)
          : []
    },
    getExcludedMeasures() {
      return Array.isArray(this.getFlexiblePathTier?.excluded_measures) ?
          this.getFlexiblePathTier.excluded_measures.filter(m => +m?.type_vintage_id === +this.custom_combination?.vintage?.type_vintage_id)
          : []
    },
    getBuildingStockFilters() {
      const jurisdiction_id = this.custom_combination.jurisdiction_id
      const climate_zone_prefix = this.custom_combination.climate_zone.prefix
      const type_prototype_id =  this.custom_combination?.prototype.type_prototype_id
      const type_vintage_id = this.custom_combination.vintage.type_vintage_id
      const prototype_id = this.custom_combination.prototype.id

      return { jurisdiction_id, climate_zone_prefix, type_prototype_id, type_vintage_id,prototype_id  }
    },
    getEditRequirementsColumns() {
      return this.$store.getters['requirements/getterPolicyExistingBuildingsColumns'].columnGroups.map(cg => {
        const columnGroup = {... cg }
        if (!this.getPrototype?.study_id || !this.studyDataModified?.length) {
          return columnGroup
        }

        const removeColumnKeys = ['tdv2022_benefit_to_cost_ratio', 'tdv_benefit_to_cost_ratio', 'on_bill_cost_ratio', 'lsc_2025_benefit_to_cost_ratio', 'on_bill_cost_ratio_2025', 'on_bill_2025_care']
        const autoActivateColumns = removeColumnKeys.filter((i) => i !== 'on_bill_2025_care')
        columnGroup.columns = columnGroup.columns.map((column) => {
          let autoActivate = false
          if (column.currentStudyId !== this.getPrototype.study_id) {
            column.currentStudyId = this.getPrototype.study_id
            autoActivate = true
          }

          const data = updateColumnCopiesAndHelpers(this.getPrototype.study_id, column)
          const newColumn = { ...column, title: data?.columnTitle || column.title }
          if (removeColumnKeys.includes(column.key) && this.getIfColumnShouldBeDisabled(column.key, this.studyDataModified)) {
            column.isActive = false
            newColumn.isActive = false
          } else if (autoActivate && autoActivateColumns.includes(column.key)) {
            column.isActive = true
            newColumn.isActive = true
          }

          // Let's create a config in the future to enable those kind of warnings. For a while, it will be done right here because of the deadline for webinar
          const warningKeys = ['annual_bill_savings', 'lifecycle_savings', 'forecast_lifecycle_savings']
          if (this.getPrototype.study_id === 48 && warningKeys.includes(column.key)) {
            newColumn.warning = {
              text: `Values displayed are based on 2025 escalation rates. Download the full set of results <a style="text-decoration: underline" href="https://localenergycodes.com/download/1799/file_path/fieldList/2022%20Single%20Family%20Retrofits%20Study%20Data.xlsx" target="_blank">here</a>.`,
            }
          }
          return newColumn
        }).filter((i) => i)

        return {...columnGroup, columns: columnGroup.columns.filter(item => item.isActive)}
      })
      .filter(cg => {
        if(cg.key != "measure_list" ) {
          return cg.columns.length > 0
        }
        return cg
      })
    },
    getHeaderColumns() {
      const firstColumn = {
        'isActive': true,
        'key': 'measures',
        'title': 'Available Measures',
        'description': null,
        'isChart': false,
        'hasHelper': {
          'type': 'helpers',
          'slug': 'measures'
        }
      }

      return this.getEditRequirementsColumns.map(columnGroup => {
        if(columnGroup.key == 'measure_list') {
          return {...columnGroup, columns: columnGroup.columns.toSpliced(0,0,firstColumn)}
        }
        return columnGroup
      })
    },
    getterUserCanEditPolicy() {
      return this.$store.getters['policy/getterUserCanEditPolicy'](this.$route.params.policy_id)
    },
    getPolicy() {
      return this.$store.getters['policy/getterUserPolicy']({ id: this.$route.params.policy_id }) ?? false
    },
    getClimateZone(){
      if (!this.custom_combination) return false
      return {...this.custom_combination?.climate_zone}
    },
    getPrototype() {
      if (!this.custom_combination) return false
      const custom_combination = this.custom_combination
      return this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: custom_combination.prototype_id })
    },
    getTypeVintage(){
      return this.$store.getters['globalEntities/TypeVintage/getterGlobalTypeVintage']({id:this.custom_combination.vintage.type_vintage_id})
    },
    getStudySource() {
      const prototype = this.getPrototype
      const study = this.$store.getters['globalEntities/Study/getterGlobalStudy']({ id: prototype.study_id })
      return { id: study.id, title: study.title, released_at: study.released_at, study_group_id: study.study_group_id }
    },
    getCustomCombinationsIncluded() {
      return this.custom_combination?.measures
    },
    getBuildingStocksUnits() {
      return this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits'](this.getBuildingStockFilters)
    },
    getRequirementsTotalImpact() {
      return {
        id: 1021,
        data: this.totalImpactData,
        slug: 'combined_impact',
        title: 'Combined Impact for All Requirements',
        not_visible: true,
        is_appended: true,
        measures: []
      }
    },
    isUserAdmin(){
      return this.$store.getters['getterLoggedUserIsAdmin']
    },
    ...mapGetters('assumptions',['getterAssumptionsDrawerOptions']),
  },
  watch: {
    '$route'($route, $previous) {
      if ($route.params.policy_id != $previous.params.policy_id) this.$emit('close')
      if ($route?.query?.per_custom_combination_id != $previous?.query?.per_custom_combination_id) {
        this.init()
      }
    },
    '$route.params.policy_id': {
      handler: function(value) {
        if (value) {
          this.flexiblePathService = new FlexiblePathService({ policy_id : this.$route.params.policy_id })
        }
      },
      immediate: true,
    },
    getCurrentTargetScore: {
      handler: function() {
        this.computeRequirementsTotalImpact()
      },
      immediate: false
    },
    getPresetByPrototypeAndClimateZone: {
      handler: function() {
        this.syncStatesAndTooltips()
        this.computeRequirementsTotalImpact()
      },
      immediate: true
    },
    isSpecificMeasuresActive: {
      handler: function(value, oldVal) {
        // if (!oldVal && value && !this.getMandatoryMeasures?.length) {
        //   this.isSpecificMeasuresActive = false
        // }
        if(!value && oldVal && this.getMandatoryMeasures?.length) {
          this.setMandatoryMeasures([])
        }
      },
      immediate: false
    },
    isFlexibleScoreRequired: {
      handler: function(value) {
        // Sync measures removal action
        this.type_measures.forEach((group) => {
          const icon = (group.slug === 'removed_measures') ? 'settings_backup_restore' : 'remove_circle_outline'
          const tooltip = (group.slug === 'removed_measures') ? '<p class="psui-text-white psui-text-xsmall"> Restore this measure </p>'
              : '<p class="psui-text-white psui-text-xsmall"> Remove from your measure list </p>'
          group.items.forEach((item) => {
            item.remove_add_button = Boolean(value) || undefined
            item.remove_add_button_icon = value ? icon : undefined
            item.remove_add_tooltip = value ? tooltip : undefined
          })
        })

        // Enable/Disable Package column and add/remove its tooltip
        const index = this.getMeasureAndPackagesGroup?.index ?? this.type_measures.findIndex((x) => x === this.getMeasureAndPackagesGroup)
        this.hiddenIndexes = value && +index >= 0 ? [+index] : undefined
        if (this.getMeasureAndPackagesGroup) {
          this.getMeasureAndPackagesGroup.is_disabled = Boolean(this.hiddenIndexes)
          this.getMeasureAndPackagesGroup.text_color = this.hiddenIndexes ? '#A2ACB7' : ''
          this.getMeasureAndPackagesGroup.tooltip_text = this.hiddenIndexes ? `
            <div class="flex flex-col psui-space-y-1 psui-text-white">
              <span class="psui-font-bold">
                Packages are not available when you are requiring a flexible score.
              </span>
              <span class="psui-font-normal">
                Flexible scores work with individual measures.
              </span>
            </div>
          ` : ''
        }

        this.syncStatesAndTooltips()
      },
      immediate: false
    },
    getMandatoryMeasures: {
      handler: function (value, oldVal) {
        if (!value?.length && this.isSpecificMeasuresActive && oldVal?.length > 0) {
          this.isSpecificMeasuresActive = false
        } else if (value?.length && !this.isSpecificMeasuresActive) {
          this.isSpecificMeasuresActive = true
        }

        // Updates visual mandatory toggle
        this.type_measures.forEach((group) => {
          group.items.forEach((item) => {
            const isMandatory = value.some((i) => i.id === item.id) && !this.getExcludedMeasures.some((i) => +i.measure_id === +item.id)
            item.data = {
              ...(item.data || {}),
              mandatory: isMandatory,
            }
          })
        })

        this.syncStatesAndTooltips()
        this.computeRequirementsTotalImpact()
      },
      deep: true,
      immediate: false
    },
    getExcludedMeasures: {
      handler: function (value) {
        this.syncRemovedGroupAndItems()

        // Uncheck all excluded measures that are mandatory
        const newMandatoryMeasures = this.getMandatoryMeasures.filter((m) => !value.some((i) => i.measure_id === m.id))
        if (newMandatoryMeasures.length !== this.getMandatoryMeasures.length) {
          this.setMandatoryMeasures(newMandatoryMeasures)
        }

        this.syncStatesAndTooltips()
        this.computeRequirementsTotalImpact()
      },
      immediate: false
    },
  },
  async mounted() {
    this.$refs.baseDrawer.openDrawer()
    await this.init()

    this.$eventBus.$on('startSavingCustomCombinations', (ccIds) => {
      if (ccIds?.includes(+this.custom_combination.id)) {
        this.isLoading = true
      }
    })

    this.$eventBus.$on('customCombinationChanged', (cc) => {
      if (+cc.id === +this.custom_combination.id) {
        this.custom_combination.meta = cc.meta
        this.setStudyData(this.custom_combination)
        this.type_measures.forEach(type_measure => {
          type_measure.items.forEach((item) => {
            const getData = new StudyData({
              study_data: this.custom_combination.study_data.find(cc => +cc.measure_id === +item.id),
              building_stock_units: this.getBuildingStocksUnits,
              assumption: new ImpactAssumptions(this.custom_combination?.meta?.assumptions || {})
            })

            item.data = {
              ...item.data,
              ...getData,
              ...getData.data
            }
          })
        })
        this.computeRequirementsTotalImpact()
        this.isLoading = false
      }
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('startSavingCustomCombinations')
    this.$eventBus.$off('customCombinationChanged')
  },
  methods: {
    toggleWarningAlert(column, event, show) {
      if (!column.warning?.text) return
      if (!show) {
        this.$eventBus.$emit('closeConfirmPopover', { preventWhenHovering: true })
        return
      }
      this.$eventBus.$emit('openConfirmPopover', {
        targetElem: event.target,
        text: column.warning.text,
        textAsHtml: true,
        side: 'right',
        buttons: [],
        disableFooter: true,
        sumTop: -7,
        sumLeft: 5
      })
    },
    toggleVintageRequirements(){
      this.isSpecificMeasuresActive = false
      this.toggleFlexibleRequired(false)
    },
    async handleBreadcrumbClick($event, item, index) {
      if(index == 0) {
        return await this.saveRequirement()
        .then(() => {
          this.$router.push({path:'/policies'})
        })
      } else if(index == 1) {
        return await this.saveRequirement()
      }
      return   
    },
    openDetailedFlexibleScoreDrawer(){
      if(this.isUserAdmin) {
        return this.$eventBus.$emit("openExistingDetailedFlexibleScoreDrawer", {
          custom_combination:this.custom_combination,
          total_impact_measures: this.totalImpactMeasures,
          flexible_score: this.getCurrentTargetScore
        })
      }
      
      this.$eventBus.$emit('openDescriptionModal', { 
        type: 'helper', 
        slug: 'combined_requirements_impact' 
      })
      
    },
    checkCanChangeCalculationMethod(preset, next, targetElem) {
      if (!preset || !CUSTOM_COMBINATION_PRESETS?.[preset] || this.getPresetByPrototypeAndClimateZone === preset) {
        return next(true)
      }

      const hasNonCeInsidePresetMeasures = this.getMandatoryMeasures.some((measure) => {
        const itemGroup = this.type_measures.find((group) => {
          return group?.items.some((i) => +i.id === +measure.id)
        })
        const item = itemGroup?.items?.find((i) => +i.id === +measure.id)
        if (!item) {
          return false
        }

        const onBillValue = item.data['on_bill_cost_ratio'] !== null ? +item.data['on_bill_cost_ratio'] : +'--'
        const tdvValue = item.data['tdv2022_benefit_to_cost_ratio'] !== null ? +item.data['tdv2022_benefit_to_cost_ratio'] : +'--'
        const onBill2025Value = item.data['on_bill_cost_ratio_2025'] !== null ? +item.data['on_bill_cost_ratio_2025'] : +'--'
        const lscValue = item.data['lsc_2025_benefit_to_cost_ratio'] !== null ? +item.data['lsc_2025_benefit_to_cost_ratio'] : +'--'
        const isOnBillNotCostEffective = Boolean(!isNaN(onBillValue) && onBillValue < 1)
        const isTdvNotCostEffective = Boolean(!isNaN(tdvValue) && tdvValue < 1)
        const isOnBill2025NotCostEffective = Boolean(!isNaN(onBill2025Value) && onBill2025Value < 1)
        const isLscNotCostEffective = Boolean(!isNaN(lscValue) && lscValue < 1)
        let presetNotCostEffective = false
        if (preset === CUSTOM_COMBINATION_PRESETS.ALL_POSSIBLE_ON_BILL.value)
          presetNotCostEffective = isOnBillNotCostEffective
        else if (preset === CUSTOM_COMBINATION_PRESETS.ALL_POSSIBLE_ON_BILL_OR_TDV.value)
          presetNotCostEffective = isOnBillNotCostEffective && isTdvNotCostEffective
        else if (preset === CUSTOM_COMBINATION_PRESETS.ALL_POSSIBLE_ON_BILL_2025.value)
          presetNotCostEffective = isOnBill2025NotCostEffective
        else if (preset === CUSTOM_COMBINATION_PRESETS.ALL_POSSIBLE_LSC_2025.value)
          presetNotCostEffective = isLscNotCostEffective
        else if (preset === CUSTOM_COMBINATION_PRESETS.ALL_POSSIBLE_ON_BILL_2025_OR_LSC_2025.value)
          presetNotCostEffective = isOnBill2025NotCostEffective && isLscNotCostEffective
        return presetNotCostEffective
      })

      if (!hasNonCeInsidePresetMeasures) {
        return next(true)
      }

      this.$eventBus.$emit('openConfirmPopover', {
        targetElem,
        guaranteeScrollElem: document.getElementsByClassName('psui-el-table-results-wrapper')?.[0] || undefined,
        text: `Some of the measures you require are not cost-effective under ${CUSTOM_COMBINATION_PRESETS?.[preset]?.label} calculation method.`,
        neverAskUserProp: 'skip_changing_calculation_method',
        okButtonText: `I understand, switch to ${CUSTOM_COMBINATION_PRESETS?.[preset]?.label}`,
        side: 'bottom',
        layout: 'none',
        sumLeft: targetElem.getBoundingClientRect().width < 300 ? (-(targetElem.getBoundingClientRect().width - ((window.innerWidth - targetElem.getBoundingClientRect().right)/2))) : 0,
        sumTop: -25,
        onConfirmCallback: () => {
          next(true)
        },
        onCloseCallback: () => {
          next(false)
        }
      })
    },
    openProjections(value) {
      const selectedMeasure = this.$store.getters['globalEntities/Measure/getterGlobalMeasure']({id:value.item.id})
      this.$eventBus.$emit('openProjectionsModal', {
        studyData: this.studyDataModified,
        columnSelectedKey: value.column.key,
        prototype: this.custom_combination.prototype,
        measure: selectedMeasure,
        vintage: this.custom_combination.vintage,
        building_stock_units: this.getBuildingStocksUnits,
      })
    },
    openColumnHelper(column) {
      this.$eventBus.$emit('openDescriptionModal', { type: column.hasHelper.type, id: column.hasHelper.id, slug: column.hasHelper.slug })
    },
    openColumnGroupHelper(columnGroup) {
      this.$eventBus.$emit('openDrawerContent', { type: columnGroup.hasHelper.type, id: columnGroup.hasHelper.id, slug: columnGroup.hasHelper.slug })
    },
    setSpecificMeasureActive() {
      this.isSpecificMeasuresActive = !this.isSpecificMeasuresActive
    },
    toggleFlexibleRequired(value) {
      this.isFlexibleScoreRequired = value

      if (value) {
        if (!this.getCurrentTargetScore) {
          const aux = this.getCurrentTargetScore
          const defaultToBeUsed = this.getMaxPossibleScoreByCurrentPreset === 0 && 
          (this.oldFlexScore === 0 || (!this.oldFlexScore && this.getCurrentTargetScore === 0)) ? 0 : this.getDefaultTargetScoreByCurrentPreset


          const argsForCommonFncs = {
            climate_zone: this.climateZoneFromFlexibleObj,
            prototype: this.prototypeFromFlexibleObj,
            type_vintage: this.getTypeVintage,
            type_vintage_index: this.getTypeVintageIndex,
            flexible_path_setup: this.flexible_path_setup,
            value: this.oldFlexScore ?? defaultToBeUsed
          }
          
          setTierTargetScore(argsForCommonFncs)
          this.oldFlexScore = aux ?? null
        }

        // Move packages to its default groups
        if (this.getMeasureAndPackagesGroup) {
          this.movingMeasuresWhenFlexEnabled = (this.getMeasureAndPackagesGroup.items || []).filter((i, index) => {
            i.orderIdx = index
            const measure = this.$store.getters['globalEntities/Measure/getterGlobalMeasure']({ id: i.id })
            return measure && measure?.is_package === true && measure?.hide_in_flexible_path !== true
          }).map((item) => {
            const group = this.type_measures.find((t) => (item.type_measures || []).map((iT) => +iT.id).includes(t.id))
            if (!group) return null
            group.items = Array.isArray(group.items) ? [...group.items, item] : [item]
            return { item, group: group.slug }
          }).filter((i) => i)
          this.getMeasureAndPackagesGroup.items = (this.getMeasureAndPackagesGroup.items || []).filter((i) => !this.movingMeasuresWhenFlexEnabled.some((mI) => mI.item.id === i.id))
        }
      } else {
        if (this.getFlexiblePathTier) {
          const excludeMatch = (x) => +x.type_vintage_id !== +this.custom_combination.vintage.type_vintage_id
          this.getFlexiblePathTier.excluded_measures = (this.getFlexiblePathTier?.excluded_measures || []).filter(excludeMatch)
          this.getFlexiblePathTier.mandatory_measures = (this.getFlexiblePathTier?.mandatory_measures || []).filter(excludeMatch)
          if (this.getFlexibleTargetScoreObj) {
            this.oldFlexScore = this.getCurrentTargetScore
            this.getFlexibleTargetScoreObj.value = null
          }
        }

        // Move measures back to packages groups
        if (this.movingMeasuresWhenFlexEnabled?.length) {
          this.getMeasureAndPackagesGroup.items = [...(this.getMeasureAndPackagesGroup.items || []), ...this.movingMeasuresWhenFlexEnabled.map((mI) => mI.item)]
          this.movingMeasuresWhenFlexEnabled.forEach((item) => {
            const groupToRemove = this.type_measures.find((group) => group.slug === item.group)
            if (groupToRemove) {
              groupToRemove.items = groupToRemove.items.filter((i) => i.id !== item.item.id)
            }
          })
          this.type_measures.forEach((group) => {
            group.items = group.items.sort((a,b) => (a?.orderIdx || 0) - (b?.orderIdx || 0))
          })
          this.movingMeasuresWhenFlexEnabled = []
        }
      }
    },
    openNoPermissionModal() {
      const policy = this.$store.getters['policy/getterUserPolicy']({ id: this.$route.params.policy_id })
      this.$eventBus.$emit('openPolicyUserPermissionDuplicateAlertModal', { policy })
    },
    setMeasure(measure) {
      if (!this.getterUserCanEditPolicy) {
        this.openNoPermissionModal()
        return
      }

      const onBillValue = measure.data['on_bill_cost_ratio'] !== null ? +measure.data['on_bill_cost_ratio'] : +'--'
      const tdvValue = measure.data['tdv2022_benefit_to_cost_ratio'] !== null ? +measure.data['tdv2022_benefit_to_cost_ratio'] : +'--'
      const onBill2025Value = measure.data['on_bill_cost_ratio_2025'] !== null ? +measure.data['on_bill_cost_ratio_2025'] : +'--'
      const lscValue = measure.data['lsc_2025_benefit_to_cost_ratio'] !== null ? +measure.data['lsc_2025_benefit_to_cost_ratio'] : +'--'
      const isOnBillNotCostEffective = Boolean(!isNaN(onBillValue) && onBillValue < 1)
      const isTdvNotCostEffective = Boolean(!isNaN(tdvValue) && tdvValue < 1)
      const isOnBill2025NotCostEffective = Boolean(!isNaN(onBill2025Value) && onBill2025Value < 1)
      const isLscNotCostEffective = Boolean(!isNaN(lscValue) && lscValue < 1)
      const bothNotCostEffective = isOnBillNotCostEffective && isTdvNotCostEffective && isOnBill2025NotCostEffective && isLscNotCostEffective

      // const presetNotCostEffective = this.getPresetByPrototypeAndClimateZone === CUSTOM_COMBINATION_PRESETS.ALL_POSSIBLE_ON_BILL_OR_TDV.value ? bothNotCostEffective : isOnBillNotCostEffective
      const isMeasureFlexScoreLessThanOne = Math.round(Number(measure.data?.energy_savings_combined || 0)) < 1

      const isMeasureMandatory = this.getMandatoryMeasures.some(cc_measure => +cc_measure.id === +measure.id)
      if(bothNotCostEffective && !isMeasureMandatory && !isMeasureFlexScoreLessThanOne) {
        this.$eventBus.$emit('openConfirmPopover', {
          targetElem: document.querySelector(`[id="${measure.id}"] [data-test-id="mandatory"]`),
          guaranteeScrollElem: document.getElementsByClassName('psui-el-table-results-wrapper')?.[0] || undefined,
          text: 'Careful, this measure is not cost-effective on its own, requiring it is complicated.',
          neverAskUserProp: 'skip_non_cost_effective_measures_select_confirmation',
          learnMoreLink: 'https://intercom.help/explorer-local-energy-codes/en/articles/6805213',
          okButtonText: 'I understand',
          side: 'bottom',
          layout: 'none',
          onConfirmCallback: () => {
            this.toggleMeasure(measure)
          },
          onCloseCallback: () => {}
        })
      } else {
        this.toggleMeasure(measure)
      }
    },
    toggleMeasure(measure) {
      const newToggleValue = !this.getMandatoryMeasures.some(cc_measure => +cc_measure.id === +measure.id)
      this.toggleMandatoryOnCustomCombination(measure, newToggleValue)
      this.toggleMandatoryOnFlexibleSetup(measure, newToggleValue)
    },
    toggleMandatoryOnCustomCombination(measure, value) {
      const retrivedMeasure = this.$store.getters['globalEntities/Measure/getterGlobalMeasure']({id:measure.id})
      const index = this.custom_combination.measures.findIndex(existingMeasure => +existingMeasure.id === +retrivedMeasure.id)
      if(value && index < 0) {
        this.custom_combination.measures.push({
          id: retrivedMeasure.id,
          title: retrivedMeasure.title,
          pivot: {
            measure_id: retrivedMeasure.id,
            custom_combination_id: this.custom_combination.id
          }
        })
      } else if (!value && index >= 0) {
        this.custom_combination.measures.splice(index, 1)
      }
    },
    toggleMandatoryOnFlexibleSetup(measure, value) {
      if (!this.getFlexiblePathTier) {
        return
      }

      this.getFlexiblePathTier.mandatory_measures = (this.getFlexiblePathTier.mandatory_measures || []).filter((m) => {
        return !(m.measure_id === measure.id && m.type_vintage_id === this.custom_combination.vintage.type_vintage_id)
      })

      if (value) {
        this.getFlexiblePathTier.mandatory_measures.push({
          measure_id: measure.id,
          type_vintage_id: this.custom_combination.vintage.type_vintage_id,
        })
      }
    },
    removeItem(measure, $event) {
      if (!this.getterUserCanEditPolicy) {
        this.openNoPermissionModal()
        return
      }

      if (measure.is_disabled) return

      const cc_measure = this.climateZoneFromFlexibleObj.measures.find(cc_measure => +cc_measure.id === +measure.id)
      const argsForCommonFncs = {
        climate_zone: this.climateZoneFromFlexibleObj,
        measure: cc_measure,
        prototype: this.prototypeFromFlexibleObj,
        type_vintage: this.getTypeVintage,
        type_vintage_index: this.getTypeVintageIndex,
        flexible_path_setup: this.flexible_path_setup,
        max_target_scores_by_presets: this.max_target_scores_by_presets
      }

      const isMeasureExcluded = Object.hasOwn(checkMeasureIsExcluded(argsForCommonFncs), 'measure_id')
      const isMeasureMandatory = this.getMandatoryMeasures.some((m) => +m.id === +measure.id)
      const isMeasurePreset = Boolean(checkMeasureIsPreset(argsForCommonFncs))
      const pMScore = isMeasurePreset && !isMeasureExcluded && !isMeasureMandatory ? +getMeasureScore(argsForCommonFncs) : 0
      const newOptimalTargetScoreAfterChange = +(+this.getMaxPossibleScoreByCurrentPreset - pMScore)
      const isPossibleScoreLessThanTargetScoreAfterChange = newOptimalTargetScoreAfterChange < +this.getCurrentTargetScore

      if (isPossibleScoreLessThanTargetScoreAfterChange && isMeasurePreset && !isMeasureExcluded && !isMeasureMandatory) {
        this.$eventBus.$emit('openConfirmPopover', {
          targetElem: $event.target,
          guaranteeScrollElem: document.getElementsByClassName('psui-el-table-results-wrapper')?.[0] || undefined,
          text: 'Careful, according to cost-effectiveness study results, removing this measure makes it impossible for permit applicants to meet the required flexible score using only cost-effective measures.',
          neverAskUserProp: 'flexible_path_delete_measure_with_slower_target_score',
          buttons: ['Proceed and adjust the required flexible score', 'Proceed without adjusting the required flexible score'],
          learnMoreLink: 'https://intercom.help/explorer-local-energy-codes/en/articles/7903616-max-cost-effective-score-calculations',
          side: 'right',
          sumLeft: 10,
          sumTop: -4,
          onConfirmCallback: ({selectedButtonIdx}) => {
            if (selectedButtonIdx === 0) {
              setTierTargetScore({ ...argsForCommonFncs, value: newOptimalTargetScoreAfterChange })
            }
            toggleExcludedMeasures(argsForCommonFncs)
          },
          onCloseCallback: () => {}
        })
        return
      }

      toggleExcludedMeasures(argsForCommonFncs)
    },
    syncRemovedGroupAndItems() {
      if (!this.alreadyExecRemovedSync) {
        this.type_measures.forEach((group) => {
          group.items.forEach((i, index) => {
            i.orderIdx = i.orderIdx ?? index
          })
        })
      }

      const removedGroupSlug = 'removed_measures'
      const marginAuxGroupSlug = 'removed_measures_margin_aux'
      let removedGroup = this.type_measures.find((group) => group.slug === removedGroupSlug)

      // Move all not excluded back
      if (removedGroup) {
        removedGroup.items.forEach((item) => {
          const isExcluded = this.getExcludedMeasures.some((m) => m.measure_id === item.id)
          if (!isExcluded && item.originalGroupSlug) {
            const originalGroup = this.type_measures.find((group) => group.slug === item.originalGroupSlug)
            if (!originalGroup) {
              console.error(`Could not find original group of restored measure: ${item.title} (${item.id})`)
              return
            }

            delete item.originalGroupSlug
            originalGroup.items.push({
              ...item,
              is_excluded: false,
              remove_add_button_icon: 'remove_circle_outline',
              remove_add_tooltip: '<p class="psui-text-white psui-text-xsmall"> Remove from your measure list </p>',
              data:{
                ...item.data,
                is_disabled: item?.originalDisabledState ?? false,
              }
            })
            delete item.originalDisabledState
          }
        })
        removedGroup.items = removedGroup.items.filter((i) => this.getExcludedMeasures.some((m) => m.measure_id === i.id))
      }

      // Sync new items to the removed group
      if (this.getExcludedMeasures.length) {
        if (!removedGroup) {
          const groupTemplate = { data: {}, title: '', measures: [] }
          removedGroup = {...groupTemplate, slug: removedGroupSlug, items: []}
          this.type_measures.push({...groupTemplate, slug: marginAuxGroupSlug, items: [], type: marginAuxGroupSlug})
          this.type_measures.push(removedGroup)
        }

        this.getExcludedMeasures.forEach(({measure_id}) => {
          const itemFinder = (m) => m.id === measure_id
          const group = this.type_measures.find((group) => group.slug !== removedGroupSlug && !!group.items.find(itemFinder))
          if (!group) {
            return
          }
          const itemIdx = group.items.findIndex(itemFinder)
          const item = group.items[itemIdx]
          item.orderIdx = item.orderIdx ?? itemIdx
          item.originalGroupSlug = group.slug
          item.originalDisabledState = item?.data?.is_disabled ?? item?.is_disabled
          group.items.splice(itemIdx, 1)
          removedGroup.items.push({
            ...item,
            is_excluded: true,
            remove_add_button_icon: 'settings_backup_restore',
            remove_add_tooltip: '<p class="psui-text-white psui-text-xsmall"> Restore this measure </p>',
            data:{
              ...item.data,
              is_disabled: true,
            }
          })
        })
        removedGroup.title = `Unavailable Measures (${this.getExcludedMeasures.length})`
      } else if(removedGroup) {
        // Just remove group if there's nothing on it anymore
        this.type_measures = this.type_measures.filter(group => group.slug !== removedGroupSlug && group.slug !== marginAuxGroupSlug)
      }

      // Order all groups based on orderIdx
      this.type_measures.forEach((group) => {
        group.items = group.items.sort((a, b) => (a?.orderIdx || 0) - (b?.orderIdx || 0))
      })
      this.alreadyExecRemovedSync = true
    },
    async getMeasures() {
      const [measureGroups, packages] = await Promise.all([
        new Promise((resolve, reject) => {
          const localCacheKey = `typeMeasureApiByStudyId:${this.study_id}`
          if (this.cachedData[localCacheKey]) {
            return resolve(this.cachedData[localCacheKey])
          }
          return TypeMeasureApiService.get(
            `
              where:[
                {column: "is_package", value: "false"},
                {column: "study_id", value : "${this.study_id}"}
              ]
            `
          ).then(data => {
            // As defined on DDP-1656, lets use hide_in_compliance_margin_control as a filter for requirements
            const result = (data?.type_measures || []).map((i) => {
              return {
                ...i,
                measures: (i?.measures || []).filter((m) => m.hide_in_compliance_margin_control !== true)
              }
            })
            this.cachedData[localCacheKey] = result
            resolve(result)
          }).catch(e => reject(e))
        }),
        new Promise((resolve, reject) => {
          const localCacheKey = `measureApiByStudyId:${this.study_id}`
          if (this.cachedData[localCacheKey]) {
            return resolve(this.cachedData[localCacheKey])
          }
          return MeasureApiService.get(
            `
              where: [
                {column: "is_package", value: "true"},
                {column: "study_id", value : "${this.study_id}"}
              ]
            `
          ).then( data => {
            // As defined on DDP-1656, lets use hide_in_compliance_margin_control as a filter for requirements
            const result = (data?.measures || []).filter((m) => m.hide_in_compliance_margin_control !== true)
            this.cachedData[localCacheKey] = result
            resolve(result)
          }).catch(e => reject(e))
        }),
      ])

      const packageGroupId = Math.max(...measureGroups.map((m) => +m.id)) + 1
      this.type_measures = [
        ...measureGroups.filter((mG) => mG?.measures?.length),
        {
          id: packageGroupId,
          slug: 'measure_and_packages',
          title: 'Packages',
          measures: packages || []
        }
      ]
      this.addMeasuresColumnInfo()
    },
    addMeasuresColumnInfo() {
      const getData = (value) => {
        const getData = new StudyData({
          study_data: value,
          building_stock_units: this.getBuildingStocksUnits,
          assumption: new ImpactAssumptions(this.custom_combination?.meta?.assumptions || {})
        })

        return {
          ...getData,
          ...getData.data,
          isCostEffective: getData.isCostEffective,
          mandatory: this.getMandatoryMeasures.some(measure => +measure.id === +value.measure_id)
        }
      }

      this.type_measures = this.type_measures.map(type_measure => {
        return {
          ...type_measure,
          data: {},
          items: type_measure.measures
              ?.filter(measure => this.custom_combination.study_data.some(cc => cc.measure_id == measure.id))
              ?.map(measure => {
                return {
                  ...measure,
                  data: getData(this.custom_combination.study_data.find(cc => cc.measure_id == measure.id)),
                  actions:[
                    {
                      icon:'info_outline',
                      ignoreDialog: true,
                      callback:() => {
                        this.$eventBus.$emit('openDescriptionModal', { type: 'measure', id: measure.id })
                      }
                    }
                  ]
                }
              }) || [],
        }
      })
    },
    async getFlexiblePathSetup() {
      if (this.flexible_path_setup) return
      const setup = await this.flexiblePathService.getSetup()
      setup?.tiers?.forEach((t) => {
        if (!t?.mandatory_measures) {
          t.mandatory_measures = []
        }
        if (!t?.excluded_measures) {
          t.excluded_measures = []
        }
      })
      this.flexible_path_setup = {...setup}
      this.baseFlexiblePath = cloneDeep(setup)
    },
    async toggleRequirements(prototype, climateZone, vintage, customCombination, setup, type) {
      if(type) {
        this.toggleFlexibleRequired(false)
        this.isSpecificMeasuresActive = false
      }
            
      this.flexible_path_setup = setup
      this.filters.custom_combination_id = customCombination.id
      this.filters.climate_zone_raw = climateZone.raw
      
      if(customCombination?.id) {
        this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          per_climate_zone_raw: climateZone.raw,
          per_custom_combination_id: customCombination?.id,
        }
      }).catch(()=>{})
      this.setBreadcrumbs(customCombination)
    }
      this.$forceUpdate()
      this.isLoading = false
    },
    resetDataState() {
      this.custom_combination = null
      this.type_measures = []
      this.study_id = null
      this.isSpecificMeasuresActive = false
      this.isFlexibleScoreRequired = false
      this.max_target_scores_by_presets = null
      this.prototypes = []
      this.baseCustomCombination = {}
      this.movingMeasuresWhenFlexEnabled = []
      this.oldFlexScore = null
      this.hiddenIndexes = undefined
      this.studyDataModified = null
      this.alreadyExecRemovedSync = false
      this.isLoadingTotalImpact = false
      this.totalImpactDebouncer = null
      this.totalImpactData = {}
      this.totalImpactMeasures = null
      this.hasTotalImpactError = false
    },
    async init() {
      this.resetDataState()
      this.isLoading = true
      this.$forceUpdate()
      await this.getCustomCombination()
      await Promise.all([
        this.getMeasures(),
        this.getPrototypes(),
      ])
      await Promise.all([
        this.getMaxTargetScoreByPresets(),
        this.getFlexiblePathSetup(),
      ])

      // Set prescriptive config when load
      if (this.custom_combination.measures.length > 0 && !this.isSpecificMeasuresActive) {
        this.setSpecificMeasureActive()
      }

      // Set Flexible config when load
      if (this.getIfHasFlexibleConfigured) {
        this.setMinimumFlexiblesScore()
      }

      this.setMandatoryMeasures(this.getMandatoryMeasures)
      this.isLoading = false
    },
    async getPrototypes() {
      const localCacheKey = `prototypesByTypePrototypeId:${this.custom_combination.prototype.type_prototype_id}`
      if (this.cachedData[localCacheKey]) {
        this.prototypes = this.cachedData[localCacheKey]
        return
      }
      await this.flexiblePathService.getTypePrototypeItems({
        type_prototype_id: this.custom_combination.prototype.type_prototype_id
      })
      .then(({ prototypes }) => {
        this.cachedData[localCacheKey] = prototypes ?? []
        this.prototypes = this.cachedData[localCacheKey]
      })
    },
    async getMaxTargetScoreByPresets() {
      await CustomCombinationApiService.getPresets({
          vintage_id: this.custom_combination.vintage_id,
          prototype_id: this.getPrototype.id,
          climate_zone_raw: this.getClimateZone.raw
      }).then(async ({data}) => {
        const maxTargetScoreByPreset = {
          type_vintage_id: this.custom_combination.vintage.type_vintage_id,
          climate_zone_raw: this.getClimateZone.raw,
          data: {},
        }

        for await (const preset of Object.keys(data.presets)) {
          // Remove this line to disable package opening... Remember also to change reduce to use data.presets[preset]
          maxTargetScoreByPreset['data'][preset] = (await ExistingBuildingsPOBuilder.getNormalizedPresetMeasures(data.presets[preset],
              this.getPrototype.study_id, this.getPrototype.id, this.getClimateZone.raw, this.custom_combination.vintage_id))
          maxTargetScoreByPreset[preset] = maxTargetScoreByPreset['data'][preset].reduce((acc,curr)=>{
            const measure = this.$store.getters['globalEntities/Measure/getterGlobalMeasure']({id:curr.id})
            if ((!measure?.hide_in_flexible_path || (measure?.is_package === true && measure?.measures?.length)) && measure?.hide_in_compliance_margin_control !== true) {
              return acc + Math.round(curr.energy_savings_combined)
            }
            return acc
          }, 0)

          // "opening" packages to make all mechanics works with individual packages
          // Enable this line to open package if you removed it above
          // maxTargetScoreByPreset['data'][preset] = (await ExistingBuildingsPOBuilder.getNormalizedPresetMeasures(data.presets[preset],
          //     this.getPrototype.study_id, this.getPrototype.id, this.getClimateZone.raw, this.custom_combination.vintage_id))
        }

        this.max_target_scores_by_presets = [maxTargetScoreByPreset]
      })
    },
    syncStatesAndTooltips() {
      this.type_measures.forEach((group) => {
        group.items = group.items.map((item) => {
          const globalMeasure = this.$store.getters['globalEntities/Measure/getterGlobalMeasure']({ id: item.id })
          const ccMeasure = this.climateZoneFromFlexibleObj?.measures.find(cc_measure => +cc_measure.id === +item.id)
          const excludeCombinedMeasures = ccMeasure.exclude_measures
          const isMandatory = this.getMandatoryMeasures.some((m) => +m.id === +item.id)
          const isExcluded = this.getExcludedMeasures.some((m) => +m.measure_id === +item.id)

          // Check measure combined conflict
          const selfExcludedConflict = this.climateZoneFromFlexibleObj?.measures?.find(cc_measure => +cc_measure.id === +excludeCombinedMeasures?.find((id) => this.getMandatoryMeasures?.some((m) => m.id === id)))?.title
          const mandatoriesExcludedConflict = this.getMandatoryMeasures?.find((m) => {
            return this.climateZoneFromFlexibleObj?.measures?.find(cc_measure => +cc_measure.id === +m.id)?.exclude_measures?.includes(+item.id)
          })?.title
          const combinationConflictMeasure = selfExcludedConflict || mandatoriesExcludedConflict
          const hasCombinationConflict = Boolean(combinationConflictMeasure)

          // Check flex disabled conflict
          const disableByHiddenOnEnabledFlexContext = Boolean(globalMeasure?.hide_in_flexible_path && this.isFlexibleScoreRequired)

          // Check CE based on calculation method
          const onBillValue = item.data['on_bill_cost_ratio'] !== null ? +item.data['on_bill_cost_ratio'] : +'--'
          const tdvValue = item.data['tdv2022_benefit_to_cost_ratio'] !== null ? +item.data['tdv2022_benefit_to_cost_ratio'] : +'--'
          const onBill2025Value = item.data['on_bill_cost_ratio_2025'] !== null ? +item.data['on_bill_cost_ratio_2025'] : +'--'
          const lscValue = item.data['lsc_2025_benefit_to_cost_ratio'] !== null ? +item.data['lsc_2025_benefit_to_cost_ratio'] : +'--'

          const isOnBillNotCostEffective = Boolean(!isNaN(onBillValue) && onBillValue < 1)
          const isTdvNotCostEffective = Boolean(!isNaN(tdvValue) && tdvValue < 1)
          const isOnBill2025NotCostEffective = Boolean(!isNaN(onBill2025Value) && onBill2025Value < 1)
          const isLscNotCostEffective = Boolean(!isNaN(lscValue) && lscValue < 1)

          const bothNotCostEffective = isOnBillNotCostEffective && isTdvNotCostEffective && isOnBill2025NotCostEffective && isLscNotCostEffective
          let presetNotCostEffective = false
          if (this.getPresetByPrototypeAndClimateZone === CUSTOM_COMBINATION_PRESETS.ALL_POSSIBLE_ON_BILL.value)
            presetNotCostEffective = isOnBillNotCostEffective
          else if (this.getPresetByPrototypeAndClimateZone === CUSTOM_COMBINATION_PRESETS.ALL_POSSIBLE_ON_BILL_OR_TDV.value)
            presetNotCostEffective = isOnBillNotCostEffective && isTdvNotCostEffective
          else if (this.getPresetByPrototypeAndClimateZone === CUSTOM_COMBINATION_PRESETS.ALL_POSSIBLE_ON_BILL_2025.value)
            presetNotCostEffective = isOnBill2025NotCostEffective
          else if (this.getPresetByPrototypeAndClimateZone === CUSTOM_COMBINATION_PRESETS.ALL_POSSIBLE_LSC_2025.value)
            presetNotCostEffective = isLscNotCostEffective
          else if (this.getPresetByPrototypeAndClimateZone === CUSTOM_COMBINATION_PRESETS.ALL_POSSIBLE_ON_BILL_2025_OR_LSC_2025.value)
            presetNotCostEffective = isOnBill2025NotCostEffective && isLscNotCostEffective

          item.is_disabled = Boolean(!ccMeasure?.include_in_combination || hasCombinationConflict || disableByHiddenOnEnabledFlexContext)
          if (!item.data) item.data = {}
          item.data.is_disabled = item.is_disabled
          item.background_color = item.is_disabled ? '#F3F6F9' : isExcluded.is_excluded ? '' :  '#FFFFFF'
          item.has_flag = isMandatory && (bothNotCostEffective || presetNotCostEffective)

          // Set tooltips:
          if (!ccMeasure?.include_in_combination && globalMeasure.exclude_in_combination_reason) {
            item.tooltip_text = globalMeasure.exclude_in_combination_reason
          } else if (!ccMeasure?.include_in_combination && this.isFlexibleScoreRequired) {
            item.tooltip_text = `This ${globalMeasure?.is_package ? 'package' : 'measure'} is included as an option for flexible compliance, and cannot be made mandatory at this time.`
          } else if (hasCombinationConflict) {
            item.tooltip_text = globalMeasure?.is_package ? `
              <div class="flex flex-col psui-space-y-1 psui-text-white">
                <span class="psui-font-bold">
                  You are already requiring '${combinationConflictMeasure}' which is included or cannot be combined with this package.
                </span>
                <span class="psui-font-normal">
                  Remove it from your requirements and try again.
                </span>
              </div>
            ` : `
              <div class="flex flex-col psui-space-y-1 psui-text-white">
                <span class="psui-font-bold">
                  You are already requiring '${combinationConflictMeasure}' which cannot be combined with this measure.
                </span>
                <span class="psui-font-normal">
                  Remove it from your requirements and try again.
                </span>
              </div>
            `
          } else if (disableByHiddenOnEnabledFlexContext) {
            item.tooltip_text = `This ${globalMeasure?.is_package ? 'package' : 'measure'} cannot be used in flexible path.`
          } else if (bothNotCostEffective) {
            item.tooltip_text = `This ${globalMeasure?.is_package ? 'package' : 'measure'} is not cost-effective.`
          } else if (presetNotCostEffective) {
            item.tooltip_text = `This ${globalMeasure?.is_package ? 'package' : 'measure'} is not cost-effective under the ${CUSTOM_COMBINATION_PRESETS?.[this.getPresetByPrototypeAndClimateZone].label} calculation method.`
          } else {
            item.tooltip_text = null
          }

          return item
        })
      })
    },
    setMandatoryMeasures(measures) {
      if (this.custom_combination) {
        this.custom_combination.measures = (measures || []).map((m) => {
          return {
            id: m.id,
            title: this.$store.getters['globalEntities/Measure/getterGlobalMeasure']({ id:m.id }).title,
            pivot: {
              measure_id: m.id,
              custom_combination_id: this.custom_combination.id,
            }
          }
        })
      }

      if(this.getFlexiblePathTier) {
        const differentVintageConfigs = (this.getFlexiblePathTier.mandatory_measures || []).filter((m) => +m.type_vintage_id !== +this?.custom_combination?.vintage?.type_vintage_id)
        const newMandatoryMeasures = (measures || []).map((m) => {
          return { measure_id: m.id, type_vintage_id: this?.custom_combination?.vintage?.type_vintage_id }
        })
        this.getFlexiblePathTier.mandatory_measures = [...differentVintageConfigs, ...newMandatoryMeasures]
      }
    },
    setMinimumFlexiblesScore() {
      const measuresWithoutHiddenPackages = this.getMandatoryMeasures.filter(measure => {
        const globalMeasure = this.$store.getters['globalEntities/Measure/getterGlobalMeasure']({ id: measure.id })
        return !globalMeasure?.is_package || globalMeasure?.hide_in_flexible_path !== true
      })

      const toggleOn = () => {
        this.setMandatoryMeasures(measuresWithoutHiddenPackages)
        this.toggleFlexibleRequired(true)
      }
      if (this.getMandatoryMeasures.length !== measuresWithoutHiddenPackages.length && !this.isFlexibleScoreRequired && this.$refs?.switchItem?.$el?.parentElement) {
        this.$eventBus.$emit('openConfirmPopover', {
          targetElem: this.$refs.switchItem.$el.parentElement,
          guaranteeScrollElem: document.getElementsByClassName('psui-el-table-results-wrapper')?.[0] || undefined,
          text: 'In order to set a flexible score you will need to remove packages from your requirements.',
          neverAskUserProp: `minimum_flexible_score`,
          okButtonText: 'Remove and set flexible score',
          side: 'bottom',
          layout: 'none',
          sumTop: -20,
          onConfirmCallback: toggleOn,
        })
      } else if (this.isFlexibleScoreRequired) {
        this.toggleFlexibleRequired(false)
      } else {
        toggleOn()
      }
    },
    clearMandatoryAndExcludedFromPreset() {
      if (!this.getterUserCanEditPolicy) {
        this.openNoPermissionModal()
        return
      }

      // Updates excluded + target score
      if (this.getFlexiblePathTier) {
        this.getFlexiblePathTier.excluded_measures = (this.getFlexiblePathTier?.excluded_measures || []).filter(measure => +measure.type_vintage_id !== +this?.custom_combination?.vintage?.type_vintage_id || !(this.getMaxTargetScorePresetMeasures || []).includes(measure.measure_id))
        if (this.getFlexibleTargetScoreObj) {
          this.getFlexibleTargetScoreObj.value = this.getMaxTargetScoreByCurrentPreset
        }
      }

      // Updates mandatory measures
      const newMeasures = this.getMandatoryMeasures.filter((m) => !(this.getMaxTargetScorePresetMeasures || []).includes(m.id))
      this.setMandatoryMeasures(newMeasures)
    },
    setStudyData(custom_combination){
      this.studyDataModified = custom_combination.study_data.map((studyDataRow) => {
          return new StudyData({
              study_data: studyDataRow, 
              building_stock_units: this.getBuildingStocksUnits,
              assumption: new ImpactAssumptions(this.custom_combination?.meta?.assumptions || {})
            }
          )
          }).sort((a, b) => {
            if(a.measure) return a.measure.order - b.measure.order
            return 1
          })
    },
    async getCustomCombination() {
      let { custom_combination_id } = this.filters
      if (!custom_combination_id) {
        this.$emit('close')
        return
      }

      const localCacheKey = `custom_combination:${custom_combination_id}`
      if (this.cachedData[localCacheKey]) {
        await new Promise((r) => { setTimeout(r, 200)})
      }
      const custom_combination = this.cachedData[localCacheKey] || (await CustomCombinationApiService.show(custom_combination_id))
      this.cachedData[localCacheKey] = custom_combination
      this.custom_combination = custom_combination
      this.baseCustomCombination = cloneDeep({...custom_combination})

      this.setStudyData(custom_combination)

      this.study_id = this.custom_combination?.prototype?.study_id || this.custom_combination.study_data.reduce((acc,curr) => {
        if(acc.length == 0 || !acc.includes(curr.study_id)) {
          acc.push(curr.study_id)
        }
        return acc
      },[]).shift()

      if (!this.study_id) {
        this.$emit('close')
        return
      }

      this.setBreadcrumbs(custom_combination)
    },
    setBreadcrumbs(custom_combination) {
      this.breadcrumbs = [
        { title: 'My Policies' },
        { title: custom_combination.policy.title },
        { title: custom_combination.prototype.title },
        { title: 'Climate Zone ' + custom_combination.climate_zone.prefix },
        { title: custom_combination.vintage.title },
      ]
    },
    async saveRequirement(toast=true, close=true) {
      if (close) {
        this.$emit('close')
      }
      if(!this.getterUserCanEditPolicy || !this.getIfCustomCombinationOrFlexiblePathHasChanged || this.isSavingCustomCombination) return

      this.isSavingCustomCombination = true
      let promises = []
      if (this.getIfFlexiblePathChanged) {
        promises.push(
          this.flexiblePathService.updateSetup(this.flexible_path_setup)
          )
      }

      if (this.getIfCustomCombinationChanged) {
        promises.push(
          this.$store.dispatch('policy/saveCustomCombinationsToPolicy', [ { ...this.custom_combination } ])
        )
      }

      await Promise.all(promises)
      this.baseFlexiblePath = cloneDeep(this.flexible_path_setup)
      this.isSavingCustomCombination = false
      

      if (toast) {
        this.$appToast({ message: 'Changes to your policy have been saved.', type: 'success', duration: 1500 })
      }


    },
    openAssumptionsSidebar() {
      const getExistingStudyType = this.$store.getters['globalEntities/StudyType/getterStudyTypeExistingBuildings']
      this.$store.commit('assumptions/setDrawerOptions', {...this.getterAssumptionsDrawerOptions,tab:getExistingStudyType})
      this.$store.commit('assumptions/openDrawerAtImpactTab')
    },
    onClickStudySourceInfo(study_id){
      this.$eventBus.$emit('openDrawerContent', { type: 'study', id: study_id })
    },
    onHoverTypePrototype(){
      this.isHoveringTypePrototype = !this.isHoveringTypePrototype
    },
    openDrawerWithEditColumns() {
      this.$eventBus.$emit('openDrawerPolicyEditColumns', {
        studyType:'Existing Buildings',
        module: 'requirements',
        studyId: this.getPrototype.study_id,
        customCombination: this.custom_combination,
        studyData: this.studyDataModified,
      })
    },
    async computeRequirementsTotalImpact() {
      this.isLoadingTotalImpact = true
      this.hasTotalImpactError = false
      if (this.totalImpactDebouncer) {
        clearTimeout(this.totalImpactDebouncer)
      }

      this.totalImpactDebouncer = setTimeout(async () => {
        this.totalImpactDebouncer = null

        const inputs = {
          climate_zone_raw: this.custom_combination?.climate_zone_raw,
          prototype_id: this.custom_combination?.prototype.id,
          vintage_id: this.custom_combination?.vintage_id,
          jurisdiction_id: this.custom_combination?.jurisdiction.id
        }
        const excludedMeasureIds = this.getExcludedMeasures.map((m) => +m.measure_id)
        const mandatoryMeasures = this.getMandatoryMeasures.map((m) => +m.measure_id)
        const calculationMethod = this.getPresetByPrototypeAndClimateZone
        const targetScore = this.getCurrentTargetScore ? +this.getCurrentTargetScore : null

        const flexCombination = await this.flexiblePathService.getFlexibleCombinationsByTargetScores({
          ...inputs,
          preset: calculationMethod,
          target_score: targetScore,
          mandatory_measures: mandatoryMeasures,
          excluded_measures: excludedMeasureIds
        })

        const measureFinderById = (mId) => {
          const itemFinder = (i) => +i?.data?.measure_id === +mId
          const group = this.type_measures.find((g) => g.items.some(itemFinder))
          const measureInfo = group?.items?.find(itemFinder)?.data || null
          if (!measureInfo) return null
          return {
            measure: {...(measureInfo?.measure || {})},
            data: {...(measureInfo?.data || {})},
            measure_id: mId,
          }
        }
        const withContingentMeasuresCombination = (flexCombination?.result_allowing_is_contingent?.measuresIdsOfCombinationWithLowestIncrementalCost || []).map(measureFinderById)
        const withoutContingentMeasuresCombination = (flexCombination?.result_not_allowing_is_contingent?.measuresIdsOfCombinationWithLowestIncrementalCost || []).map(measureFinderById)
        const mandatoryMeasuresWithData = mandatoryMeasures.map(measureFinderById)

        this.hasTotalImpactError = Boolean(flexCombination !== null && (
            (withContingentMeasuresCombination.length === 0 || withoutContingentMeasuresCombination.length === 0) ||
            (withContingentMeasuresCombination.some((x) => x === null) || withoutContingentMeasuresCombination.some((x) => x === null))
        ))


        const dataMapper = (i) => i?.data

        const hasFlexibleContingentSetUp = this.custom_combination?.meta?.assumptions?.flexible_contingent_percentage
        let data

        data = {
          withContingentMeasuresData:withContingentMeasuresCombination.map(dataMapper).filter((s) => s),
          withoutContingentMeasuresData:withoutContingentMeasuresCombination.map(dataMapper).filter((s) => s),
          mandatoryMeasuresData:mandatoryMeasuresWithData.map(dataMapper).filter((s) => s),
          customContingentPercentage: hasFlexibleContingentSetUp
        }

        this.totalImpactMeasures = {
          withContingent: withContingentMeasuresCombination,
          withoutContingent: withoutContingentMeasuresCombination,
          mandatory: mandatoryMeasuresWithData,
          customContingentPercentage: hasFlexibleContingentSetUp
        }

        this.totalImpactData = this.computeResumedImpacts(data)

        this.isLoadingTotalImpact = false
      }, 700)
    },
    getTotalImpactColumnContent(column) {
      if (this.hasTotalImpactError) {
        return ''
      }
      return formatStudyData(column?.key, this.totalImpactData?.[column.key], this.totalImpactData)
    },
  }
}
</script>

<style lang="scss" scoped>

::v-deep .existing-header {
  padding: 7px 24px 7px 16px;

    .breadcrumb {
      li:nth-of-type(2),li:nth-of-type(1) {
        span:hover {
          cursor: pointer;
          color: rgb(49 143 172/var(--tw-text-opacity));
        }
      }
    }
}


.total-error {
  max-width: 352px;
}

::v-deep td {
  &:hover {
    .info-flexible-score {
      span {
        vertical-align: bottom;
        visibility: visible;
        cursor: pointer;
      }
    }
  }
  .info-flexible-score {
    span {
      vertical-align: bottom;
      visibility: hidden;
      cursor: pointer;
    }
  }
}

.requirements-footer {
  tr:nth-of-type(2) {
    box-shadow: rgba(0, 0, 0, 0.04) 0px -8px 8px, rgba(0, 0, 0, 0.08) 0px -2px 5px;
    height: 36px;
  }

  tr {
    td {
      padding-left: 20px;

      &:not(:first-child) {
        text-align: right;
      }

      &:nth-child(2) {
        z-index: 15;
        left: 352px;
        position: sticky;
        width: 352px;
        padding-left: 0;
        text-align: center;
        padding-left: 17px;
      }
      &:nth-child(3) {
        position: sticky;
        z-index: 15;
        left: 492px;
        min-width: 140px;
        padding-left: 0;
      }
    }
  }
}

::v-deep {
  .app-drawer-content {
    padding-right: 0;
    padding-left: 0 !important;
    border-radius: 0;
    padding-bottom: 0;
    overflow-y: hidden;

    .psui-el-table-results-wrapper {
      max-height: 100%;
    }

  }
}

::v-deep.app-drawer-requirements {
  .psui-el-tooltip.require-specific-measures-tooltip {
    .psui-el-tooltip-wrapper {
      .psui-el-tooltip-dialog {
        transition-delay: 0.1s;
      }
    }
  }

  .app-drawer-header {
    margin-bottom: 5px !important;
  }

  > .app-drawer-content {
    display: flex;
    flex-direction: column;
    padding: 0 16px 0 0 !important;
    height: 100%;

    .psui-el-table-results.layout-flexible {
      tbody {
        tr {
          td {
            .actions {
              padding-left: 0 !important;
              .psui-el-tooltip-trigger {
                > div {
                  padding: 0 4px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.edit-custom-combination-forecast-table ::v-deep {
  .city-wide-actions {
    display: none !important;
  }
}

.table-wrapper {
  tr {
    th,
    td {
      &:first-child {
        box-shadow: inset -1px 0px 0px #ebeef0;
        max-width: 150px;
        padding-top: 11px;
        padding-bottom: 11px;
      }
    }
  }

  tbody {
    tr:first-child {
      border-top: 1px solid #e6ecf2;
    }
  }
}

::v-deep .is-warning-column {
  padding-right: 12px;
}

::v-deep .warning-svg-position {
  position: absolute;
  right: calc(-1rem + 15px);
  top: 4px;
  .psui-el-tooltip-trigger {
    margin-right: calc(0.313rem + 1px);
    margin-bottom: 0.125rem;
    margin-left: -0.094rem;
  }
}

::v-deep .removed_measures_margin_aux td {
  visibility: hidden;
  padding: 0 !important;
  content: '' !important;
  height: 0 !important;
  margin-bottom: 14px !important;

  .actions-button {
    display: none !important;
  }

  &:nth-child(3) {
    visibility: visible;
  }
}

</style>
