import AdminPolicyOptionsIndex from '@/modules/admin/policy_options/AdminPolicyOptionsIndex'
import AdminPolicyOptionForm from '@/modules/admin/policy_options/AdminPolicyOptionForm'
import baseAdminMeta from '@/modules/admin/baseAdminMeta'

export default [
  {
    name: 'AdminPolicyOptionsIndex',
    path: 'policy_options',
    component: AdminPolicyOptionsIndex,
    meta: { ...baseAdminMeta, header: false }
  },
  {
    name: 'AdminPolicyOptionCreate',
    path: 'policy_options/create',
    component: AdminPolicyOptionForm,
    meta: { ...baseAdminMeta, title: 'Policy Option create' }
  },
  {
    name: 'AdminPolicyOptionEdit',
    path: 'policy_options/:policy_option_id/edit',
    component: AdminPolicyOptionForm,
    meta: { ...baseAdminMeta, title: 'Policy Option edit' }
  }
]