import BuildingHeight from './BuildingHeight'
import BuildingType from './BuildingType'
import ClimateZone from './ClimateZone'
import CodeCycle from './CodeCycle'
import ConstructionImplementationType from './ConstructionImplementationType'
import PolicyOption from './PolicyOption'
import Prototype from './Prototype'
import Study from './Study'
import StudyGroup from './StudyGroup'
import TypePrototype from './TypePrototype'
import StudyType from './StudyType'
import TypeVintage from './TypeVintage'
import Vintage from './Vintage'
import Fuel from './Fuel'
import TypeFuel from './TypeFuel'
import Measure from './Measure'

export default {
  namespaced: true,
  modules: {
    BuildingHeight,
    BuildingType,
    ClimateZone,
    CodeCycle,
    ConstructionImplementationType,
    PolicyOption,
    Prototype,
    Study,
    StudyGroup,
    StudyType,
    TypePrototype,
    TypeVintage,
    Vintage,
    Fuel,
    TypeFuel,
    Measure,
  },
  actions: {
    loadGlobalEntities({ dispatch }) {
      dispatch('globalEntities/BuildingHeight/loadGlobalBuildingHeights', null, { root: true })
      dispatch('globalEntities/BuildingType/loadGlobalBuildingTypes', null, { root: true })
      dispatch('globalEntities/ClimateZone/loadGlobalClimateZones', null, { root: true })
      dispatch('globalEntities/ConstructionImplementationType/loadGlobalConstructionImplementationTypes', null, { root: true })
      dispatch('globalEntities/Prototype/loadGlobalPrototypes', null, { root: true })
      dispatch('globalEntities/TypePrototype/loadGlobalTypePrototypes', null, { root: true })
      dispatch('globalEntities/Vintage/loadGlobalVintages', null, { root: true })
      dispatch('globalEntities/TypeVintage/loadGlobalTypeVintages', null, { root: true })
      dispatch('globalEntities/StudyType/loadGlobalStudyTypes', null, { root: true })
      dispatch('globalEntities/StudyGroup/loadGlobalStudyGroups', null, { root: true })
      dispatch('globalEntities/Study/loadGlobalStudies', null, { root: true })
      dispatch('globalEntities/CodeCycle/loadGlobalCodeCycles', null, { root: true })
      dispatch('globalEntities/PolicyOption/loadGlobalPolicyOptions', null, { root: true })
      dispatch('globalEntities/Fuel/loadGlobalFuels', null, { root: true })
      dispatch('globalEntities/TypeFuel/loadGlobalTypeFuels', null, { root: true })
      dispatch('globalEntities/Measure/loadGlobalMeasures', null, { root: true })
    }
  }
}
