<template>
  <div class="w-auto">
    <div class="w-full flex justify-end">
      <button
        class="button button-blue04 mr-4"
        @click="syncAllStudiesData()"
      >
        Sync all
      </button>      
      <router-link
        :to="{ name: 'AdminStudyGroupStudyAdd' }"
        class="button button-blue04"
      >
        <feather
          type="plus"
          class="mr-2 -ml-1"
        /> Add Study
      </router-link>
    </div>

    <table class="table table-bordered mt-6 whitespace-nowrap">
      <thead>
        <tr>
          <th>Study Version</th>
          <th>Is Private?</th>
          <th>Errors</th>
          <th>Released at</th>
          <th>Published at</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="study in studyGroupStudies"
          :key="study.id"
        >          
          <td>            
            <router-link :to="{name: 'AdminStudyGroupStudyEdit', params: { study_group_id: study.study_group_id, study_id: study.id }}">
              {{ study.id }} / {{ study.admin_title_formmated }}
            </router-link>
          </td>
          <td>{{ study.is_private ? 'Yes' : 'No' }}</td>          
          <td class="text-left">
            <span 
              v-if="studyErrors[study.id]" 
              class="button-xs rounded-full bg-red text-white mt-2"
              :v-tooltip="`We found {{ studyErrors[study.id] }} errors to fix in this study`"
            >
              {{ studyErrors[study.id] }}
            </span>
            <span 
              v-else 
              v-tooltip="'This study has no errors'"
              class="button-xs rounded-full bg-green text-white mt-2"
            >0</span>
          </td>
          <td>{{ study.released_at }}</td>
          <td>{{ study.published_at }}</td>
          <td class="flex items-end">
            <button
              class="button-sm button-blue04 mr-2"
              @click="syncStudy(study.id)"
            >
              Sync
            </button>            
            <router-link 
              :to="{name: 'AdminStudyGroupStudyEdit', params: { study_group_id: study.study_group_id, study_id: study.id }}"
              label="edit" 
              class="button-sm button-blue04 mr-2"
            >
              Edit
            </router-link>
            <button
              label="duplicate"
              class="button-sm bg-sunrise-500 rounded-full text-white mr-2" 
              @click="duplicateStudy(study)"
            >
              Duplicate
            </button>
            <button
              label="delete"
              class="button-sm button-red" 
              @click="selectStudyToDelete(study)"
            >
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <BaseModal
      ref="deleteStudyModal"
      modal-dialog-classes="w-full max-w-sm"
      title="Attention"
    >
      <div
        v-if="studySelected"
        class="w-full flex flex-col items-start mt-6"
      >
        <p class="text-base text-gray04">
          Delete <span class="text-red font-bold">{{ studySelected.title }}</span> and all items related with that study like <span class="text-red font-bold">prototypes, vintages, measures, packages and user custom combinations</span>?
        </p>
        <AppButton
          class="mt-6"
          label="Confirm delete"
          size="big"
          @click="deleteStudy()" 
        />
      </div>
    </BaseModal>
  </div>
</template>

<script>
import Study from '@/models/Study'

export default {
  name: 'AdminStudyTable',
  props: {
    studies: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      studySelected: null,
      studyErrors: {}
    }
  },
  computed: {
    studyGroupStudies() {
      return this.studies.map(study => new Study(study))
    }
  },
  watch: {
    studies: {
      handler() {
        this.studies.forEach((study) => this.checkStudyErrors(study))
      },
      immediate: true
    }
  },
  methods: {

    syncStudy(study_id) {
      this.$api.post(`/sync/${study_id}`, { loader: true})
        .then(() => {
          this.$toast.success('Study sync successfully scheduled')
          this.$toast.info(`The sync processs may take up to 5 minutes to finish, so it's time to grab a coffee`, { duration: 10000 })
        })
        .catch(() => {
          this.$toast.error('Error dispatching sync study')
        })
    },

    selectStudyToDelete(study) {
      this.studySelected = study
      this.$refs.deleteStudyModal.showModal = true
    },

    deleteStudy() {
      this.$api.delete(`/studies/${this.studySelected.id}`, { loader: true})
        .then(() => {
          this.$toast.success('Study successfully deleted')
          this.$refs.deleteStudyModal.showModal = false
        })
        .catch(() => {
          this.$toast.error('Error removing study')
        })
    },

    duplicateStudy(study) {
      this.$api.get(`/studies/duplicate/${study.id}`, { loader: true})
        .then(() => {
          this.$toast.success('Study successfully duplicated')
        })
        .catch(() => {
          this.$toast.error('Error duplicating study')
        })
    },

    syncAllStudiesData() {
      this.$api.post('/sync/all', { loader: true})
        .then(() => {
          this.$toast.success('Study sync successfully scheduled')
          this.$toast.info(`The sync processs may take up to 10 minutes to finish, so it's time to grab a coffee`, { duration: 20000 })
        })
        .catch(() => {
          this.$toast.error('Error dispatching sync studies')
        })
    },

    checkStudyErrors(study) {
      this.$api.get(`/studies/check_errors/${study.id}`)
        .then(({ data }) => {
          this.$set(this.studyErrors, study.id, data.errors.errorsTotal)
        })
    }
  }
}
</script>

<style>

</style>