<template>
  <div>
    <template v-for="(type_measure, type_measure_index) in type_measures">
      <div
        :key="type_measure.id"
        class="mb-10"
      >
        <h4 class="text-header-4 text-deepsky-800 pl-3 pb-3">
          {{ type_measure.title }} ({{ type_measure.id }})
        </h4>
        <table class="table whitespace-nowrap">
          <thead>
            <tr>
              <th
                class="font-normal"
                width="40"
              >
                Order
              </th>
              <th
                class="font-normal"
                width="40"
              >
                ID
              </th>
              <th class="font-normal">
                Title
              </th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="(measure, index) in type_measure.measures" 
              :key="`measure-${type_measure.id}-${measure.id}`"
              draggable="true"
              class="cursor-move"
              :class="{ 'is-dragging-out' : type_measure_index == typeMeasureIndex && index == dragAndDropDraggingIndex}"
              @dragstart="onDrag(index, type_measure_index)"
              @dragover="onDrag(index, type_measure_index)"
              @dragend="dragFinish(index, type_measure.measures, type_measure)"
            >
              <td>{{ measure.pivot.pivot_order }}</td>
              <td>{{ measure.id }}</td>
              <td>{{ measure.title }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  name: 'AdminStudyGroupStudyFlexiblePaths',
  data() {
    return {
      type_measures: [],
      dragAndDropOrdenationUrl: '/api/ordenation/sync_type_measure_measures_order',
      typeMeasureIndex: false
    }
  },
  mounted() {
    this.getStudyMeasuresByType()
  },
  methods: {    
    getStudyMeasuresByType() {
      const query = `
        {
          type_measures {
            id
            title
            measures(
              where: [{column: "study_id", operation: "=", value: "${this.$route.params.study_id}"}]
              orderBy: [{column: "pivot_order", order: "ASC"}]
            ) {
              id
              title
              pivot {
                pivot_order
              }
              type_measures {
                id
                title
              }
            } 
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.type_measures = data.type_measures
        })
      
    },
    onDrag(index, type_measure_index) {
      if (type_measure_index === this.typeMeasureIndex || this.typeMeasureIndex === false) {
        this.dragAndDropDraggingIndex = index
        this.typeMeasureIndex = type_measure_index        
      }
    },
    dragFinish(index, items, type_measure){
      this.moveItem(index, this.dragAndDropDraggingIndex, items, type_measure)
      this.dragAndDropDraggingIndex = -1
      this.typeMeasureIndex = false
    },
    moveItem(from, to, items, type_measure) {
      items.splice(to, 0, items.splice(from, 1)[0])
      this.remapItems(items, type_measure)
    },

    remapItems(items, type_measure) {
      const orderedItems = items.map( (item, index) => {
        const order = index
        return {
          type_measure_id: type_measure.id,
          measure_id: item.id,
          pivot_order: order
        }
      })
      this.$api.post(this.dragAndDropOrdenationUrl, { items: orderedItems })
        .then(() => {
          this.getStudyMeasuresByType()
        })
    },
  }
}
</script>