import { ObjectHelpers } from '@igortrindade/lazyfy'

export default class BaseMeta {

  get fillable () {
    return []
  }

  getFillableKeys() {
    return ObjectHelpers.filterObjectKeys(this.fillable, this)
  }

  constructor(data = {}) {
    this.setFillableKeys(data)
  }

  setFillableKeys(data = {}) {
    for(const key of this.fillable) {
      if(data[key] !== undefined) {
        this[key] = data[key]
      }
    }
  }
  
}