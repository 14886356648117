import EventBus from '@/util/EventBus'
export default (Store) => {
  Store.subscribe((mutation) => {
    const mutationsToWatch = [
      'assumptions/buildingEstimates/addOrUpdateCustomBuildingEstimates',
      'assumptions/buildingEstimates/addCustomBuildingEstimatesToPolicy',
      'assumptions/buildingEstimates/storeBatchCustomBuildingEstimates',
      'assumptions/buildingEstimates/clearCustomBuildingEstimates',
      'assumptions/buildingEstimates/setJurisdictionBuildingEstimates',
      'assumptions/buildingEstimates/deleteCustomBuildingEstimateOfPolicy',
      'assumptions/buildingEstimates/setCustomBuildingEstimates',
      'assumptions/buildingEstimates/clearAllBuildingEstimates',
    ]
    if(mutationsToWatch.includes(mutation.type)) {
      EventBus.$emit('buildingEstimatesChanged')
    }   
  })
}
