<template>
  <BaseAppHeader
    id="comparison-header"
  >
    <template #top-left>
      <div
        v-if="getComparison"
        data-test-id="comparison-show-header-top-left"
        class="psui-flex w-full justify-between items-center pl-2"
      >
        <div class="psui-flex psui-items-center">
          <ComparisonShowHeaderReturnToListButton />          
          <h1 
            v-tooltip="{ content: getComparison.title, placement: 'bottom-start' }"
            class="comparison-header-title psui-text-h4 psui-text-gray-80 font-bold flex-shrink-0 transition-all pl-1/2"
          >
            {{ getComparison.title }}
          </h1>
          <span
            v-if="getComparisonJurisdiction"
            class="psui-pl-2 psui-text-h4 psui-text-gray-40 mr-2"
          >{{ getComparisonJurisdiction | editJurisdictionTitleTypeBasedOnJurisdictionType('title_type') }}</span>
          <AppSharedIcon v-if="!getterUserCanEditComparison" />
        </div>
      </div>
    </template>
    <template #top-right>
      <div class="psui-flex psui-gap-2 psui-transition-all">
        <PsButton
          data-test-id="comparison-policies-header-share-button"
          label="Share"
          icon="share"
          size="small"
          layout="onlytext"
          @click="copyToClipboard"
        />

        <PsDropdown class="more-actions">
          <template #dropdownTrigger>
            <PsButton
              icon="more_horiz"
              size="small"
              layout="onlytext"
            />
          </template>
          <template #items>
            <ul class="psui-w-full psui-font-bold psui-my-3">
              <li
                title="Delete"
                class="psui-text-red-20 hover:psui-text-red-70 psui-font-bold psui-cursor-pointer psui-flex psui-items-center psui-gap-3 psui-px-5 psui-py-1 transition-all"
                @click="$eventBus.$emit('openModalDeleteComparison', { comparison: getComparison, discardTypeLabel: getComparisonDiscardTypeLabel })"
              >
                <PsIcon 
                  icon="delete" 
                  size="22"
                />
                <span class="psui-w-auto psui-text-small"> {{ getComparisonDiscardTypeLabel | replaceAllHyphenAndAddUppercaseToFirstLetter }} from my comparisons</span>
              </li>
            </ul> 
          </template>
        </PsDropdown>
      </div>
    </template>    
    <template 
      v-if="getterUserCanEditComparison"
      #bottom-left
    >
      <div class="psui-ml-8">
        <PsButton
          :label="'Select Policies' | policyTextRephrase"
          icon="edit_note"
          size="small"
          class="psui-my-3"
          layout="ghost"
          @click="$eventBus.$emit('openComparisonEditPoliciesDrawer', { comparison_id: getComparison.id })"
        />
      </div>
    </template>
    <template
      #bottom-right
    >
      <div class="psui-flex psui-mb-3 psui-gap-3">
        <PsButton
          data-test-id="comparison-policies-header-organize-columns-button"
          label="Organize Columns"
          size="small"
          icon="calendar_view_week"
          layout="ghost"
          @click="$eventBus.$emit('openDrawerComparisonEditColumns')"
        />
      </div>
    </template>
  </BaseAppHeader>
</template>

<script>
import ComparisonShowHeaderReturnToListButton from './ComparisonShowHeaderReturnToListButton.vue'
import AppSharedIcon from '../../../../components/general/AppSharedIcon.vue'
import { copyUrlToClipboard } from '@/util/Helpers'
import BaseAppHeader from '@/components/general/BaseAppHeader.vue'
import Jurisdiction from '@/models/Jurisdiction'
import {mapGetters} from 'vuex'
import { COMPARISON_TYPES } from '@/util/Enums.js'

const TYPES = {
  IS_VIEWING_A_SHARED_POLICY : 'IS_VIEWING_A_SHARED_POLICY',
  USER_IS_LOGGED_OUT_AND_HAS_NO_POLICIES : 'USER_IS_LOGGED_OUT_AND_HAS_NO_POLICIES',
  USER_HAS_POLICIES_TO_SAVE : 'USER_HAS_POLICIES_TO_SAVE',
}

export default {
  name: 'ComparisonHeader',
  components:{BaseAppHeader, AppSharedIcon, ComparisonShowHeaderReturnToListButton},
  data() {
    return {
      TYPES,
    }
  },
  computed: {
    getComparisonId() {
      return this.$route?.params?.comparison_id
    },
    ...mapGetters(['lastJurisdictionVisited']),
    getComparison() {      
      return this.$store.getters['comparison/getterUserComparison']({ id: this.getComparisonId })
    },
    getComparisonJurisdiction() {
      return this.getComparison?.jurisdiction ? (new Jurisdiction(this.getComparison?.jurisdiction)) : null
    },
    getterUserCanEditComparison() {
      return this.$store.getters['comparison/getterUserCanEditComparison'](this.getComparison?.id)
    },
    getAvailableJurisdictions() {
      return this.$store.getters['comparison/getterAvailableJurisdictions']?.map((jurisdiction) => {
        return {
          ...jurisdiction,
          title_type: this.$options.filters.editJurisdictionTitleTypeBasedOnJurisdictionType(jurisdiction, 'title_type'),
        }
      }) ?? []
    },
    existingComparisonSharedColumns() {
      return this.$store.getters['comparison/getterExistingBuildingsColumnsSelected'].columnGroups
      .find(cg => cg.key == 'shared_assumptions') 
    },
    comparisonIsShared() {
      return this.$store.getters['comparison/getterComparisonType'](this.getComparisonId) == COMPARISON_TYPES.SHARED.value
    },
    getComparisonDiscardTypeLabel(){
      return this.comparisonIsShared ? 'remove' : 'delete'
    }
  },
  methods:{
    onJurisdictionSelect(jurisdiction_id) {
      const jurisdiction = this.getAvailableJurisdictions.findInArray({ id: jurisdiction_id })
      if (jurisdiction) this.$store.dispatch('setUserLastJurisdictionAndFirstClimateZoneVisited', { value: jurisdiction, context: 'updateLastJurisdictionVisitedFromPolicyComparison'})
      
      const policies_ids = this.$store.getters['policy/getterUserPoliciesGroupedByJurisdiction'][jurisdiction_id]?.map((i) => i.id).join(',')
      if(this.$route.name !== 'ComparisonShow') this.$router.push({ name: 'ComparisonShow', query: { jurisdiction_id, policies_ids }})
      this.$refs['dropdownJurisdiction'].close()
    },
    copyToClipboard(){
      copyUrlToClipboard()
      this.$appToast({ message: 'Link copied to clipboard!' })
    },
  },
}
</script>

<style lang="scss" scoped>

::v-deep {
  .psui-el-tooltip.back-to-comparisons {
    .psui-el-tooltip-wrapper {
      .psui-el-tooltip-dialog {
        left: 212px !important;
      }
    }
  }

  .more-actions {
    .psui-el-button {
      i {
        margin-right: 0;
      }
    }
  }
}

.icon {
  font-size: 18px;
}

.shared-icon {
  font-size: 16px;
}

.comparison-header-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 640px) {
    max-width: 6.25rem;
  }
  @media (min-width: 641px) and (max-width: 1024px) {
    max-width: 15rem;
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    max-width: 20rem;
  }
  @media (min-width: 1281px) and (max-width: 1600px) {
    max-width: 25rem;
  }
  @media (min-width: 1601px) {
    max-width: 40rem;
  }
}

::v-deep.app-header {
  padding-left: 0;
}

::v-deep {
  .app-header-bottom {
    min-height: 15px;
  }
}

</style>
