import BasePdfResultsTableService from '@/services/pdf/BasePdfResultsTableService'
import Policy from '@/models/Policy'
import StudyData from '@/models/StudyData'
import MainVuex from '@/store'
import { STUDY_TYPES_DB_SLUGS, POLICY_TYPE_PDF } from '@/util/Enums.js'
import { getSuperscriptStringFromNumber, getPolicyCustomCombinationsBasedOnFlexiblePathSetupIfNeeded } from '@/util/Functions.js'
import columns from './columns'
import { compliance_margin, edr1_total_margin } from '@/modules/app/shared/default-app-columns'
import dayjs from 'dayjs'
import GraphQL from '@/util/GraphQL'
import formatStudyData from '@/formatters/formatStudyData'
import autoTable from 'jspdf-autotable'
import StudyDataApiService from '@/services/api/StudyDataApiService'
import * as sha1 from 'sha1'
import asyncCacheResolver from '@/util/asyncCacheResolver'
import { getMaxTargetScorePresetSelected } from '@/services/measure-menu/MeasureMenuGeneralService'
import { CUSTOM_COMBINATION_PRESETS } from '@/modules/app/jurisdiction/study-results/shared/enums.js'
import { jurisdictionTitleTypeBasedOnJurisdictionType } from '@/util/Helpers.js'

export default class PolicyCostEffectivenessEvidencePdf extends BasePdfResultsTableService {

  fontSizeDefault = 10
  policy_id = null
  study_types = []
  studies = []
  type_prototypes = []
  prototypes = []
  vintages = []
  fuels = []
  policy = new Policy()
  tables = []
  presets = []
  flexible_path_setup = {}

  type = POLICY_TYPE_PDF.COST_EFFECTIVENESS_EVIDENCE

  constructor(args) {    
    super(args)
    this.policy_id = args.policy_id
    this.presets = args.presets
    this.flexible_path_setup = args.flexible_path_setup
    return this.initPdf()
  }

  setPdfArgs() {
    this.setPdfTitle()
    this.setPdfSubtitle()
    this.setPdfSources()
  }

  setPdfTitle() {
    this.title = jurisdictionTitleTypeBasedOnJurisdictionType(this.policy.jurisdiction, 'title_type', null, true)
    if (this.title.length >= 48) {
      const words = this.title.split(' ')
      let currentLine = ''
      let lines = []
      for (const word of words) {
        if ((currentLine + ' ' + word).length <= 48) {
          currentLine += ' ' + word
        } else {
          lines.push(currentLine.trim())
          currentLine = word
        }
      }
      lines.push(currentLine.trim())
      this.title = lines.join('\n')
    }

    this.pageHeaderTitle = this.policy.jurisdiction?.titles.title_type
  }

  setPdfSubtitle() {
    let climateZones = this.climate_zones.map((climate_zone) => climate_zone.prefix)
    if (climateZones.length > 1) {
      let lastZone = climateZones.pop()
      this.subtitle = `Climate Zones ${climateZones.join(', ')} and ${lastZone}`
    } else {
      this.subtitle = `Climate Zone ${climateZones[0]}`
    }
  }

  setPdfSources() {
    this.studies.forEach(study => this.registerSourceStudy(study.id))
  }
  
  async initPdf() {

    try {
      await this.getPolicy()
      await this.getTables()
      this.setPdfArgs()
      await this.addTables()
      await this.export()
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async getPolicy() {
    const policy = MainVuex.getters['policy/getterUserPolicies']({ id: this.policy_id })[0]
    this.policy = new Policy(policy)
  }

  async getTables() {
    this.prototypes = []

    let policy_custom_combinations = getPolicyCustomCombinationsBasedOnFlexiblePathSetupIfNeeded(this.policy_id, this.flexible_path_setup)
    
    this.prototypes = MainVuex.getters['globalEntities/Prototype/getterGlobalPrototypes']({ id: policy_custom_combinations.map((custom_combination) => custom_combination.prototype_id) })
    this.climate_zones = MainVuex.getters['globalEntities/ClimateZone/getterGlobalClimateZones']({ raw: policy_custom_combinations.map((custom_combination) => custom_combination.climate_zone_raw) })

    for( const climate_zone of this.climate_zones) {

     for(const prototype of this.prototypes) {
  
        const study = MainVuex.getters['globalEntities/Study/getterGlobalStudy']({ id: prototype.study_id })
        const study_group = MainVuex.getters['globalEntities/StudyGroup/getterGlobalStudyGroup']({ id: study.study_group_id })
        const study_type = MainVuex.getters['globalEntities/StudyType/getterGlobalStudyType']({ id: study_group.study_type_id })

        if(!this.studies.findInArray({ id: study.id })) this.studies.push(study)

        const custom_combination_with_this_prototype_and_climate_zone = policy_custom_combinations.findAllInArray({ prototype_id: prototype.id, climate_zone_raw: climate_zone.raw })
        const key = study_type.slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS ? 'vintage' : 'fuel'
        
        let vintageOrFuels
        
        if(key === 'vintage') {
          const vintage_ids = custom_combination_with_this_prototype_and_climate_zone.map((custom_combination) => custom_combination.vintage_id)
          vintageOrFuels = JSON.parse(JSON.stringify(MainVuex.getters['globalEntities/Vintage/getterGlobalVintages']({ id: vintage_ids })))
        } else {
          const fuel_ids = custom_combination_with_this_prototype_and_climate_zone.map((custom_combination) => custom_combination.fuel_id)
          vintageOrFuels = JSON.parse(JSON.stringify(MainVuex.getters['globalEntities/Fuel/getterGlobalFuels']({ id: fuel_ids })))
        }
  
        for(const vintageOrFuel of vintageOrFuels) {
          let is_flexible_set_up = false
          let preset = []
          let excludedAndMandatoryMeasures = []
          let calculationMethodSet = undefined
          let flexibleMandatoryMeasureIds = []

          if(key === 'vintage') {
            // Soares don't believe thats necessary anymore after flex restructure
            // vintageOrFuel.title = vintageOrFuel?.type_vintage?.flexible_path_title ?? vintageOrFuel.title

            const tierVintageFinder = (i) => +i.type_vintage_id === +vintageOrFuel.type_vintage_id
            let matchingTier = this.flexible_path_setup?.tiers?.find((t) => {
              return (+t.prototype_id === +prototype.id && +t.climate_zone_id === +climate_zone.id)
            })
            let matchingScore = matchingTier?.target_scores?.find(tierVintageFinder)?.value

            is_flexible_set_up = matchingScore && matchingScore > 0
            if (is_flexible_set_up) {
              const vintageIds = MainVuex.getters['globalEntities/Vintage/getterGlobalVintages']({ type_vintage_id: vintageOrFuel.type_vintage_id }).map(vintage => vintage.id)

              let matchingPreset = this.presets.find((preset) => {
                return (
                  +preset.prototype_id === +prototype.id &&
                  preset.climate_zone_raw === climate_zone.raw &&
                  vintageIds.includes(preset.vintage_id)
                )
              })

              flexibleMandatoryMeasureIds = matchingTier?.mandatory_measures?.filter(tierVintageFinder)?.map(measure => measure.measure_id) || []
              let excludedMeasureIds = matchingTier?.excluded_measures?.filter(tierVintageFinder)?.map(measure => measure.measure_id) || []
              let allMandatoryAndExcludedMeasureIds = [...new Set([...excludedMeasureIds.concat(flexibleMandatoryMeasureIds)])]

              calculationMethodSet = getMaxTargetScorePresetSelected({ climate_zone_id: climate_zone?.id, prototype_id: prototype?.id, flexible_path_setup: this.flexible_path_setup })
              matchingPreset?.presets?.[calculationMethodSet]?.forEach((m) => {
                if (allMandatoryAndExcludedMeasureIds.includes(m.measure_id)) {
                  if (!excludedAndMandatoryMeasures.find(existingMeasure => existingMeasure.measure_id === m.measure_id)) {
                    excludedAndMandatoryMeasures.push(m)
                  }
                } else {
                  preset.push(m)
                }
              })
            }
          }

          const custom_combination = policy_custom_combinations.findInArray({      
            climate_zone_raw: climate_zone.raw,
            prototype_id: prototype.id,
            [`${key}_id`]: vintageOrFuel.id
          })

          if(!custom_combination && !is_flexible_set_up) continue

          const studyDatas = await this.getStudyData({ climate_zone, prototype, key, vintageOrFuel, custom_combination, flexibleMandatoryMeasureIds })
          vintageOrFuel.study_data = studyDatas.map( (study_data) => {
            if(study_data.fuel_id) {
               study_data.fuel = MainVuex.getters['globalEntities/Fuel/getterGlobalFuel']({ id: study_data.fuel_id })
            }
            return new StudyData({ study_data })
          })
  
          this.tables.push({
            study,
            study_type,
            prototype: prototype.title,
            climate_zone,
            key : vintageOrFuel.title_long ? vintageOrFuel.title_long : vintageOrFuel.title,
            vintageOrFuel,
            headers: this.getTableHeaders(study_type, study.compliance_margin_key || 'compliance_margin'),
            rows : vintageOrFuel.study_data,
            calculation_method: calculationMethodSet ? CUSTOM_COMBINATION_PRESETS[calculationMethodSet].label : undefined,
            is_flexible_set_up,
            preset,
            excluded_and_mandatory_measures: excludedAndMandatoryMeasures,
            type: this.type,
          })
        } 
      }
    }
  }

  async getStudyData({ climate_zone, prototype, key, vintageOrFuel, custom_combination, flexibleMandatoryMeasureIds }) {
    const DEFAULT_ATTRIBUTES = `
      id
      study_row_number
      study_id
      prototype_id
      measure_id
      vintage_id
      fuel_id
      climate_zone_raw
      baseline_fuel_type
      initial_cost
      kwh_savings
      therms_savings
      annual_bill_savings
      simple_payback
      tdv_benefit_to_cost_ratio
      tdv2022_benefit_to_cost_ratio
      tdv_benefit_to_cost_ratio_total
      on_bill_cost_ratio
      on_bill_cost_ratio_2025
      on_bill_2025_care
      lsc_2025_benefit_to_cost_ratio
      emissions_savings
      emissions_savings_pct
      compliance_margin
      edr1_total_margin
      edr2_total_margin
      lifecycle_savings
      base_kwh
      measure { id title hide_in_results }
      fuel { id title }  
    `

    if (key === 'vintage' && !custom_combination?.measures?.length && !flexibleMandatoryMeasureIds?.length) {
      return Promise.resolve([])
    }
  
    const solver = (cz) => {
      const withFallback = StudyDataApiService.getSearchClimateZones(cz)
      if (withFallback.length <= 1) return withFallback
      return [...withFallback, ...solver(withFallback[1])]
    }
  
    let climate_zone_raws = [...new Set(solver(climate_zone.raw).filter(cz => cz !== undefined))]
    const prototype_id = prototype.id
    const ccMeasureIds = custom_combination?.measures?.map((m) => m.id) || []
    const allMeasureIds = [... new Set([...flexibleMandatoryMeasureIds, ...ccMeasureIds])]
    const searchMeasureIds = allMeasureIds.map(measureId => `"${measureId}"`).join(',')
  
    const query = `
      {
        study_data (
          where: [
            { column: "prototype_id", value: "${prototype_id}" },
            { column: "${key}_id", value: "${vintageOrFuel.id}" },
          ]
          whereIn: [
            { column: "climate_zone_raw", value: [${climate_zone_raws.map(cz => `"${cz}"`).join(',')}] },
            ${ searchMeasureIds.length ? `{ column: "measure_id", value: [${searchMeasureIds}] }` : ''}
          ]
        ) {
          ${DEFAULT_ATTRIBUTES}
        }
      }
    `
  
    const cacheKey = sha1(query)
    const result = await asyncCacheResolver(cacheKey, async () => {
      const { data } = await GraphQL({ query, caller: 'PolicyPdfImpact' })
      return data.study_data.length <= 0 ? [] : data.study_data
    })
  
    const groupedData = result.reduce((acc, item) => {
      (acc[item.climate_zone_raw] = acc[item.climate_zone_raw] || []).push(item)
      return acc
    }, {})
  
    for (const cz of climate_zone_raws) {
      if (groupedData[cz] && groupedData[cz].length > 0) {
        return groupedData[cz].filter((study_data_row) => !study_data_row.measure.hide_in_results) || []
      }
    }
  
    return []
  }
  
  getTableHeaders(study_type, compliance_margin_key) {

    const columnsGroupType = study_type.slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS ? 'existingBuildings' : 'newBuildings'      
    const columnGroups = columns[columnsGroupType].columnGroups

    const headers = [
      {
        key: 'title',
        label: study_type.slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS ? 'Measure' : 'Measure/Package',
      }
    ]

    if (columnGroups) {
      columnGroups.forEach(columnGroup => {
        if (columnGroup.columns) {
          columnGroup.columns.forEach((column) => {
            let useColumn = {... column }
            if (column.key === compliance_margin().key && compliance_margin_key === edr1_total_margin().key) {
              useColumn = {... edr1_total_margin() }
            }
            headers.push({
              key: useColumn.key,
              label: useColumn.title,
              groupKey: columnGroup.key,
              groupTitle: columnGroup.title,
              description: useColumn.description
            })
          })
        }
      }) 
    }
    return headers
  }

  getTableHeaderHeadline({ headers, prototype, climate_zone, type }) {
    let styles = { 
      fontSize: 14,
      font: 'Lato-Bold',
      textColor: this.colors.gray02,
      cellPadding: { top: 8, right: 5, left: 12, bottom: 0 }
    }
    
    if(type == POLICY_TYPE_PDF.COST_EFFECTIVENESS_EVIDENCE) {
      styles.cellPadding = { top: -8, right: 5, left: 12, bottom: 0 }
    }
  
    return { 
      content: `${prototype} | Climate Zone ${climate_zone.prefix}`, 
      colSpan: headers.length,
      isHeadline : true,
      styles: styles
    }
  }

  getTableHeaderSubtitle({ study, vintageOrFuel, study_type }) {
     
     const getSourceIndex = this.sources.findIndex((source =>  source.id === study.id))+1  
     
     const getSuperscriptStringFromIndex = getSuperscriptStringFromNumber(getSourceIndex)
     
    if(study_type.slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS) {
      return `${vintageOrFuel.title}   |   ${study.title} ${getSuperscriptStringFromIndex}   |   Release Date: ${dayjs(study.released_at).format('MMMM DD, YYYY')}` 
    } else if (study_type.slug === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS) {
      return `${vintageOrFuel.title}   |   ${study.title} ${getSuperscriptStringFromIndex}   |   Release Date: ${dayjs(study.released_at).format('MMMM DD, YYYY')}   |   Code Cycle: ${this.policy.code_cycle.title}`
    } else {
      return ''
    }
  }

  getTableHeaderTitle(table, headers = false) {
    if (!headers) {
      headers = table.headers
    }
    return { 
      content: this.getTableHeaderSubtitle(table), 
      colSpan: headers.length, 
      styles: { 
        fontSize: 9,
        font: 'Lato-Bold',
        textColor: this.colors.gray03,
        cellPadding: { top: 2, right: 5, left: 12, bottom: 5 }
      } 
    }
  }

  processHeaders(cEHeaders, bodies, tableIndex, yPosition) {
    let cEYPosition = yPosition
    const pageHeight = this.doc.internal.pageSize.height
    const pageHeightAvailable = pageHeight - (cEYPosition + this.margins.bottom)  

    cEHeaders.forEach((head, cEHeaderIndex) => {                   
      const body = bodies[cEHeaderIndex]  
  
      if (cEHeaders.length > 1) {            
        head.splice(2,0, this.addTableIndexCaption({ colSpan : head[head.length - 1].length , tableIndex, cEHeaderIndex, cEHeaders }))
      }
  
      const totalCEHeadersTopAndBottomPaddings = this.calculateTotalPaddingHeights(cEHeaders)
      const totalBodiesTopAndBottomPaddings = this.calculateTotalPaddingHeights(bodies)
      const totalCEHeadersAndBodiesTopAndBottomPaddings = totalCEHeadersTopAndBottomPaddings + totalBodiesTopAndBottomPaddings
  
      if(pageHeightAvailable < totalCEHeadersAndBodiesTopAndBottomPaddings) {
        this.addPage()
        cEYPosition = this.margins.top + 10
      }
  
      autoTable(this.doc, {
        theme: 'plain',
        body,
        head, 
        startY: cEYPosition,
        styles: {
          font: 'Lato-Regular',
          fontSize: this.fontSizeDefault,
        },
        margin: { 
          top: this.margins.top,  
          right: (this.pageWidth / 2) - (this.margins.right + this.margins.left),
          left: this.margins.left, 
          bottom: this.margins.bottom
        },
        willDrawCell: (data) => {
          this.onPolicyCostEffectivenessTableBreak(data)
        },
        didDrawCell: (data) => {
          this.addVerticalBlueBarALongTheHeadlineTitleAndSubtitle(data)
          this.addMaximumCostEffectiveTargetScoreCalculationBottomLine(data)
          this.addBlueRectangle(data)
        } // didDrawCell()
      }) // autoTable
  
      this.doc.lastAutoTable = 0
  
      if (tableIndex + 1 !== this.tables.length || cEHeaderIndex + 1 !== cEHeaders.length) {
        this.addPage()
      }
    }) // cEHeaders.forEach((head, cEHeaderIndex)
  }

  createNewPresetAndExcludedAndMandatoryMeasuresTable(table, measures, headers) {
    return {
      is_flexible_set_up: table.is_flexible_set_up,
      [measures]: table[measures],
      headers: headers
    }
  }

  addBlueRectangle(data) {
    if (data.cell.raw?.isContentDivisionTitle) {
      let text = '' 
      const startY = data.cell.y + data.cell.height
      const titleFontSize = 10
      const textFontSize = 8.5
      const maxWidth = (this.pageWidth / 2) - (this.margins.left * 3)
      const marginLeft = (this.pageWidth / 2) + (this.margins.left * 2)
  
      this.doc.setFillColor(this.colors.blue20) 
      let rectHeight = 55
      this.doc.rect(marginLeft, startY + 10, maxWidth, rectHeight, 'F')
  
      this.doc.setTextColor(this.colors.blue60)
      this.doc.setFontSize(titleFontSize)
      this.doc.setFont('Lato-Bold')
      this.doc.text(`Maximum Cost-Effective Score`, marginLeft + 5, startY + 25, { baseline : 'bottom' })
  
      this.doc.setTextColor(this.colors.gray80)
      this.doc.setFontSize(textFontSize)
      this.doc.setFont('Lato-Regular')
      text = `The maximum cost-effective score is the highest flexible score that can be met cost-effectively, based on the energy savings of measures for your policy. Any required flexible score that falls below or is equal to this maximum score has a cost-effective pathway available to permit applicants.`
      let splitText = this.doc.splitTextToSize(text, maxWidth - 8)
      this.doc.text(splitText, marginLeft + 5, startY + 35, { baseline : 'bottom' })
  
      this.doc.setFillColor('#FFFFFF') 
    }
  }

  addSumRow(body, from, calculation_method) {
    let columnSums = {}

    body.forEach(row => {
      row.forEach(cell => {
        if (!isNaN(cell.content)) {
          if (columnSums[cell.key]) {
            columnSums[cell.key] += parseFloat(cell.content)
          } 
          else {
            columnSums[cell.key] = parseFloat(cell.content)
          }
        }
      })
    })

    let sumRow = []

    sumRow.push({
      content: from == 'preset' ? `Maximum using available flexible measures (${calculation_method})` : `Maximum using any flexible measures (${calculation_method})`,
        key: "title",
        isSumPresetRow: from == 'preset' ? true : false,
        styles: {
          fontSize: 10,
          font: 'Lato-Bold',
          textColor: this.colors.blue60,
          halign: 'left', 
          fillColor: this.colors.blue10,
          cellPadding: {
            top: this.fontSizeDefault / 2,
            right: this.fontSizeDefault * 1.5,
            left: 0,
            bottom: this.fontSizeDefault / 2
          }
        }
    })

    for (let key in columnSums) {
      sumRow.push({
        content: key == 'energy_savings_combined' ? columnSums[key].toFixed(2) : columnSums[key].toFixed(0),
        key: key,
        isSumPresetRow: from == 'preset' ? true : false,
        styles: {
          fontSize: 10,
          font: 'Lato-Regular',
          textColor: this.colors.blue60,
          halign: 'right', 
          fillColor: this.colors.blue10,
          cellPadding: {
            top: this.fontSizeDefault / 2,
            right: this.fontSizeDefault * 1.5,
            left: 0,
            bottom: this.fontSizeDefault / 2
          }
        }
      })
    }

    body.push(sumRow)
  }

  formatPresetAndExcludedAndMandatoryMeasuresTableRowsWithFixedColumns(table, maxColumsByTable) {
    const allPresetRows = this.formatPresetAndExcludedAndMandatoryMeasuresTableRows(table)
    const rows = []

    allPresetRows.forEach((row) => {
      const titleColum = row.shift()   
      const maxColumsByTableWithoutTitle = maxColumsByTable - 1

      const rowChunks = new Array(Math.ceil(row.length / maxColumsByTableWithoutTitle)).fill().map(() => row.splice(0, maxColumsByTableWithoutTitle))
      rowChunks.forEach((rowChunk, index) => {
        if (!rows[index]) {
          rows[index] = []
        }
        rows[index].push([ titleColum, ...rowChunk ] )
      })
      
    })
    return rows
  }

  formatPresetAndExcludedAndMandatoryMeasuresTableRows(table) {
    let arrayToMap

    if (table.preset) {
      arrayToMap = table.preset
    } else if (table.excluded_and_mandatory_measures) {
      arrayToMap = table.excluded_and_mandatory_measures
    }

    return arrayToMap.map((row, indexRow) => {
      let rowArray = []      
      table.headers.map(( {key} , indexHeader ) => {
        let content = ''        
        switch (key) {
          case 'title':
            content = row.title              
            break
          default:
            content = formatStudyData(key, row[key], row )
            break
        }

        let cell = { 
          content: content,
          key: key,
          styles: {
            fontSize: 10,
            font: 'Lato-Regular',
            textColor: this.colors.gray04,
            halign: 'right',
            cellPadding: { 
              top: this.fontSizeDefault / 2,
              right: this.fontSizeDefault * 1.5,
              left: 0,
              bottom: this.fontSizeDefault / 2
            },
          }
        }
        
        // is First Column
        if (indexHeader === 0) {
          cell.styles.font = 'Lato-Bold'
          cell.styles.textColor = this.colors.gray01
          cell.styles.halign = 'left'
        }

        if (indexRow === 0) {
          cell.styles.cellPadding.top = 5
        }

        rowArray.push(cell)
      })
      return rowArray
    })
  }

  addMaximumCostEffectiveTargetScoreCalculationBottomLine(data) {
    if (data.cell.raw?.isContentDivisionTitle) {
      const lineColor = this.colors.blue50
      const lineWidth = 0.5
      const lineStartX = this.margins.left
      const lineEndX = this.pageWidth - this.margins.right
      const lineY = data.cell.y + data.cell.height - 6

      // Add line
      this.doc.setDrawColor(lineColor)
      this.doc.setLineWidth(lineWidth)
      this.doc.line(lineStartX, lineY, lineEndX, lineY)
    }
  } 

  addRequiredMeasuresAndPackagesTitle() {
    const title = `Required Measures and Packages`
    const titleFontSize = 12
    const titleFont = 'Lato-Bold'
    const titleColor = this.colors.blue80

    return [
      {
        content: title,
        colSpan: 6,
        isContentDivisionTitle: true,
        styles: {
          fontSize: titleFontSize,
          font: titleFont,
          textColor: titleColor,
          cellPadding: { 
            top: this.fontSizeDefault,
            right: titleFontSize * 1.5,
            left: 0,
            bottom: this.fontSizeDefault - 2,
          }
        }
      },
    ]
  }

  addRequiredMeasuresAndPackagesBottomLine(data) {
    if (data.cell.raw?.isContentDivisionTitle) {
      const lineColor = this.colors.blue50
      const lineWidth = 0.5
      const lineStartX = this.margins.left
      const lineEndX = this.pageWidth - this.margins.right
      const lineY = data.cell.y + data.cell.height - 6

      // Add line
      this.doc.setDrawColor(lineColor)
      this.doc.setLineWidth(lineWidth)
      this.doc.line(lineStartX, lineY, lineEndX, lineY)
    }
  } 

  onPolicyCostEffectivenessTableBreak(data) {
    if (data.pageCount > 1) {
      const sufix = '  (Continued)'
      const currentTitle = data.table.head[0].cells[0].text[0]
      const modifiedTitle = data.table.head[0].cells[0].text + sufix
      
      if (currentTitle.indexOf(sufix) === -1) {
        data.table.head[0].cells[0].text = [modifiedTitle]
      }
    }
  }

  calculateTotalPaddingHeights(data) {
    let totalPadding = 0
  
    data.forEach(row => {
      row.forEach(cell => {
        if(cell[0]?.styles?.cellPadding.top) totalPadding += cell[0]?.styles?.cellPadding.top
        if(cell[0]?.styles?.cellPadding.bottom) totalPadding += cell[0]?.styles?.cellPadding.bottom
        if(cell[0]?.styles?.fontSize) totalPadding += cell[0]?.styles?.fontSize        
      })
    })
  
    return totalPadding 
  }

  getTableHeaderHeadlineTitle({ headers, prototype, climate_zone, type }) {
    let styles = { 
      fontSize: 14,
      font: 'Lato-Bold',
      textColor: this.colors.gray02,
      cellPadding: { top: 8, right: 5, left: 12, bottom: 0 }
    }
    
    if(type == POLICY_TYPE_PDF.COST_EFFECTIVENESS_EVIDENCE) {
      styles.cellPadding = { top: -8, right: 5, left: 12, bottom: 0 }
    }
  
    return { 
      content: `${prototype} | Climate Zone ${climate_zone.prefix}`, 
      colSpan: headers.length,
      isHeadline : true,
      styles: styles
    }
  }

  getTableHeaderHeadlineSubtitle(table) {
    const { study, vintageOrFuel, study_type } = table
    const getSourceIndex = this.sources.findIndex((source =>  source.id === study.id)) + 1  
    const getSuperscriptStringFromIndex = getSuperscriptStringFromNumber(getSourceIndex)
  
    let content = ''
    if(study_type.slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS) {
      content = `${vintageOrFuel.title}   |   ${study.title} ${getSuperscriptStringFromIndex}   |   Release Date: ${dayjs(study.released_at).format('MMMM DD, YYYY')}`
    }
  
    return { 
      content: content, 
      colSpan: 10,
      styles: { 
        fontSize: 9,
        font: 'Lato-Bold',
        textColor: this.colors.gray03,
        cellPadding: { top: 2, right: 5, left: 12, bottom: 15 }
      } 
    }
  }

  addVerticalBlueBarALongTheHeadlineTitleAndSubtitle(data) {
    if (data.cell.raw?.isHeadline) {
      this.doc.setDrawColor(this.colors.blue60)
      this.doc.setLineWidth(.9)
      this.doc.line(22, data.cell.y - 9, 22, (data.cell.y + 13))
    }
  }


}