<template>
  <div class="psui-flex psui-justify-between psui-gap-6 psui-flex-col xl:psui-flex-row psui-pt-4 psui-mb-4">
    <PsTestimonialCard
      v-for="testimonial in HOME_TESTIMONIALS"
      :key="testimonial.user"
      :description="testimonial.description"
      :user="testimonial.user"
      :position="testimonial.position"
      :jurisdiction="testimonial.jurisdiction"
    />
  </div>
</template>

<script>
import { HOME_TESTIMONIALS } from '@/modules/app/home/HomeContents.js'

export default {
  name:'HomeTestimonialsList',
  data: () => ({
    HOME_TESTIMONIALS
  })
}
</script>

