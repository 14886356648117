<template>
  <div class="home-available-resources psui-w-full psui-flex psui-flex-col psui-px-6 md:psui-px-10 psui-py-6">
    <span class="psui-text-h4 psui-font-bold psui-text-blue-80 psui-mb-4">
      Accelerate policy development with other available resources...
    </span>

    <div class="psui-flex psui-flex-col lg:psui-flex-row psui-gap-4">
      <div
        v-for="content in HOME_AVAILABLE_RESOURCES"
        :key="content.key"
        class="available-resoure-card psui-flex psui-flex-col sm:psui-flex-row psui-w-full psui-items-start xl:psui-items-center psui-p-6 psui-space-y-4 sm:psui-space-y-0 sm:psui-space-x-4 psui-bg-white psui-rounded-lg psui-shadow-elevation-5 hover:psui-shadow-elevation-20 psui-transition psui-cursor-pointer"
        @click="$router.push({name:content.path})"
      >
        <div class="psui-flex psui-flex-shrink-0 psui-items-center psui-bg-blue-10 psui-rounded-lg">
          <PsIcon
            :icon="content.icon"
            size="30"
            class="icon"
            icon-classes="psui-text-blue-60 psui-p-4"
          />
        </div>
        
        <div class="psui-flex psui-flex-grow psui-items-center psui-space-x-4">
          <div class="psui-flex psui-flex-col psui-mr-auto">
            <p class="psui-text-p psui-font-bold psui-text-blue-80 psui-mb-1">
              {{ content.title }}
            </p>
            <p class="psui-text-small psui-text-gray-50">
              {{ content.description }}
            </p>
          </div>

          <PsIcon 
            icon="arrow_forward"
            size="24"
            icon-classes="arrow-next psui-text-blue-60 psui-transition"
            display="flex"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HOME_AVAILABLE_RESOURCES } from "@/modules/app/home/HomeContents.js"

export default {
  name:'HomeAvailableResources',
  data: () => ({
    HOME_AVAILABLE_RESOURCES,
  })
}
</script>

<style lang="scss">
  .home-available-resources {
    background-color: #E4EEF5;
  }

  .available-resoure-card {
    &:hover {
      .arrow-next {
        transform: translateX(5px);
      }
    }
  }
</style>