<template>
  <BaseModal
    ref="baseModal"
    modal-dialog-classes="w-auto psui-bg-gray-10"
    :show-modal-parent.sync="showModalParent"
    @close="close"
  >
    <PolicyOptionWizard
      ref="wizard"
      @register-success="onRegisterSuccess"
    />
  </BaseModal>
</template>

<script>
import PolicyOptionWizard from '../wizard/PolicyOptionWizard.vue'
export default {
  name: 'PolicyOptionWizardModal',
  components: { 
    PolicyOptionWizard
  },
  data() {
    return {
      isReadyToUserForm : false,
      showModalParent: false
    }
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.state.auth.loggedUser
    }
  },
  mounted() {
    this.$eventBus.$on('openPolicyOptionWizardModal', ({policy, policyOption, buildingType}) => {
      this.open({policy, policyOption, buildingType})
    })
    this.$eventBus.$on('closePolicyOptionWizardModal', ()=>{
      this.close()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openPolicyOptionWizardModal')
    this.$eventBus.$off('closePolicyOptionWizardModal')
  },
  methods: {
    open({policy, policyOption, buildingType}) {
      if(this.$refs.wizard){
        this.$refs.wizard.policy = policy || null
        this.$refs.wizard.policyOptionTitle = policyOption?.title_drawer_create || policyOption?.title || null
        this.$refs.wizard.buildingType = buildingType || null
        this.$refs.wizard.policyOption = policyOption || null
      }
      this.showModalParent = true
    },
    close() {
      this.showModalParent = false
      this.$eventBus.$emit('closePolicyOptionDrawer')
    },
    onRegisterSuccess(){
      this.close()
      this.$eventBus.$emit('closePolicyOptionDrawer')
    }
  }
}
</script>
