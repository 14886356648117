<template>
  <PsDialog
    :message="getMessage"
    :has-close="false"
  />
</template>

<script>
export default {
  name: 'CustomAdminPsDialog',
  computed: {
    getMessage() {
      return this.$slots?.default?.[0]?.text ?? ''
    }
  },
}
</script>

<style>

</style>