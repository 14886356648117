import dayjs from 'dayjs'

export default [
  //Today
  {
      label: `Today (${dayjs().startOf('day').format('dddd')})`,
      init: dayjs().startOf('day').subtract(1, 'days').format('YYYY-MM-DD'),
      end: dayjs().add(1, 'days').format('YYYY-MM-DD'),
      compairLabel: `Last ${dayjs().subtract(7, 'days').format('dddd')}`,
      compairInit: dayjs().subtract(7, 'days').startOf('day').format('YYYY-MM-DD'),
      compairEnd: dayjs().subtract(6, 'days').startOf('day').format('YYYY-MM-DD'),
  },
  //Yerterday
  {
      label: `Yesterday (${dayjs().subtract(1, 'days').format('dddd')})`,
      init: dayjs().subtract(2, 'days').format('YYYY-MM-DD'),
      end: dayjs().format('YYYY-MM-DD'),
      compairLabel: `Last ${dayjs().subtract(8, 'days').format('dddd')}`,
      compairInit: dayjs().subtract(8, 'days').startOf('day').format('YYYY-MM-DD'),
      compairEnd: dayjs().subtract(7, 'days').startOf('day').format('YYYY-MM-DD'),
  },
  //Before yesterday
  {
    label: dayjs().subtract(2, 'days').format('dddd'),
    init: dayjs().subtract(3, 'days').format('YYYY-MM-DD'),
    end: dayjs().subtract(1, 'days').format('YYYY-MM-DD'),
    compairLabel: `Última ${dayjs().subtract(9, 'days').format('dddd')}`,
    compairInit: dayjs().subtract(9, 'days').startOf('day').format('YYYY-MM-DD'),
    compairEnd: dayjs().subtract(8, 'days').startOf('day').format('YYYY-MM-DD'),
  },
  //This week
  {
    label: `This week`,
    init: dayjs().startOf('week').add(1, 'days').format('YYYY-MM-DD'),
    end: dayjs().endOf('week').add(2, 'days').format('YYYY-MM-DD'),
    compairLabel: `Last week`,
    compairInit: dayjs().subtract(7, 'days').startOf('week').add(1, 'days').format('YYYY-MM-DD'),
    compairEnd: dayjs().subtract(7, 'days').endOf('week').add(2, 'days').format('YYYY-MM-DD'),
  },
  // Last week
  {
    label: `Last week`,
    init: dayjs().subtract(7, 'days').startOf('week').add(1, 'days').format('YYYY-MM-DD'),
    end: dayjs().subtract(7, 'days').endOf('week').add(2, 'days').format('YYYY-MM-DD'),
    compairLabel: `-7 days`,
    compairInit: dayjs().subtract(2, 'weeks').startOf('week').add(1, 'days').format('YYYY-MM-DD'),
    compairEnd: dayjs().subtract(2, 'weeks').endOf('week').add(2, 'days').format('YYYY-MM-DD'),
  },
  //This month
  {
    label: 'This month',
    init: dayjs().startOf('month').format('YYYY-MM-DD'),
    end: dayjs().endOf('month').add(1, 'day').format('YYYY-MM-DD'),
    compairLabel: `${dayjs().subtract(1, 'month').startOf('month').format('MMMM')}`,
    compairInit: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
    compairEnd: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
  },
  //Last month
  {
    label: `${dayjs().subtract(1, 'month').subtract(1, 'day').format('MMMM')}`,
    init: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
    end: dayjs().subtract(1, 'month').endOf('month').add(1, 'day').format('YYYY-MM-DD'),
    compairLabel: `${dayjs().subtract(2, 'month').startOf('month').format('MMMM')}`,
    compairInit: dayjs().subtract(2, 'month').startOf('month').format('YYYY-MM-DD'),
    compairEnd: dayjs().subtract(2, 'month').endOf('month').format('YYYY-MM-DD'),
  },
  //Before last month
  {
    label: `${dayjs().subtract(2, 'month').format('MMMM')}`,
    init: dayjs().subtract(2, 'month').startOf('month').format('YYYY-MM-DD'),
    end: dayjs().subtract(2, 'month').endOf('month').add(1, 'day').format('YYYY-MM-DD'),
    compairLabel: `${dayjs().subtract(3, 'month').startOf('month').format('MMMM')}`,
    compairInit: dayjs().subtract(3, 'month').startOf('month').format('YYYY-MM-DD'),
    compairEnd: dayjs().subtract(3, 'month').endOf('month').format('YYYY-MM-DD'),
  },
  {
    label: `${dayjs().subtract(3, 'month').format('MMMM')}`,
    init: dayjs().subtract(3, 'month').startOf('month').format('YYYY-MM-DD'),
    end: dayjs().subtract(3, 'month').endOf('month').add(1, 'day').format('YYYY-MM-DD'),
    compairLabel: `${dayjs().subtract(3, 'month').startOf('month').format('MMMM')}`,
    compairInit: dayjs().subtract(3, 'month').startOf('month').format('YYYY-MM-DD'),
    compairEnd: dayjs().subtract(3, 'month').endOf('month').format('YYYY-MM-DD'),
  },
  // This year
  {
    label: `This year (${dayjs().format('YYYY')})`,
    init: dayjs().startOf('year').format('YYYY-MM-DD'),
    end: dayjs().endOf('year').add(1, 'day').format('YYYY-MM-DD'),
    compairLabel: `${dayjs().subtract(1, 'year').format('YYYY')}`,
    compairInit: dayjs().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
    compairEnd: dayjs().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
  },
  // Last year
  {
    label: `${ dayjs().subtract(1, 'year').format('YYYY')}`,
    init: dayjs().subtract(1, 'year').subtract(1, 'day').startOf('year').format('YYYY-MM-DD'),
    end: dayjs().subtract(1, 'year').endOf('year').add(1, 'day').format('YYYY-MM-DD'),
    compairLabel: `${dayjs().subtract(2, 'year').format('YYYY')}`,
    compairInit: dayjs().subtract(2, 'year').startOf('year').format('YYYY-MM-DD'),
    compairEnd: dayjs().subtract(2, 'year').endOf('year').format('YYYY-MM-DD'),
  },
  // Last 10 years
  {
    label: `Last 10 years`,
    init: dayjs().subtract(10, 'year').startOf('year').format('YYYY-MM-DD'),
    end: dayjs().format('YYYY-MM-DD'),
  },
  // All time
  {
    label: `All time`,
    init: dayjs().subtract(10, 'year').startOf('year').format('YYYY-MM-DD'),
    end: dayjs().add(1, 'day').format('YYYY-MM-DD'),
    compairLabel: `${dayjs().subtract(20, 'year').format('YYYY')}`,
    compairInit: dayjs().subtract(20, 'year').startOf('year').format('YYYY-MM-DD'),
    compairEnd: dayjs().subtract(20, 'year').endOf('year').format('YYYY-MM-DD'),
  },
]
