<template>
  <BaseDrawer 
    ref="baseDrawer" 
    title="Add/Hide Columns"
    subtitle="Select or re-arrange columns to display"
    :hierarchy="DRAWER_HIERARCHY.SECONDARY"
  >
    <DragAndDropEditColumnsList
      :get-columns="getterRemoveZeroedColumnGroups"
      module="comparison"
    />
  </BaseDrawer>
</template>

<script>
import { DRAWER_HIERARCHY } from '@/util/Enums.js'

export default {
  name: 'DrawerComparisonEditColumns',
  data() {
    return {
      studyTypeSelected: 'Existing Buildings',
      DRAWER_HIERARCHY
    }
  },

  computed: {
    getColumns() {
      if(this.studyTypeSelected == 'New Buildings') {
        return this.$store.getters['comparison/getterNewBuildingsColumns']
      }
      return this.$store.getters['comparison/getterExistingBuildingsColumns']
    },
    getterRemoveZeroedColumnGroups(){
      return {...this.getColumns, columnGroups: this.getColumns.columnGroups.filter(column => column?.columns?.length)} 
    }
  },
  mounted() {
    this.$eventBus.$on('openDrawerComparisonEditColumns', (studyType) => {
      if (studyType) {
        this.studyTypeSelected = studyType 
      }
      this.$refs.baseDrawer.openDrawer()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openDrawerComparisonEditColumns')
  },
}
</script>

<style lang="scss" scoped>
  ::v-deep {
    &.app-drawer {
      width: 478px;
    }
  }
</style>
