<template>
  <div
    class="relative psui-rounded-md psui-bg-gray-20 mx-6 mb-7 p-2"
    :class="{'psui-bg-blue-20':specifyBy.length}"
  >
    <p
      class="psui-text-small psui-text-gray-60 flex justify-between items-center mb-2 px-2 py-1"
      :class="{'psui-text-blue-60 psui-font-bold':specifyBy.length}"
    >
      {{ headerTitle }}
    </p>
    <div class="psui-rounded-md psui-shadow-elevation-5 psui-bg-white p-3">
      <div class="checkbox-wrapper w-1/2 flex flex-col space-y-1">
        <PsCheckboxSimple 
          v-for="(specify, key) in specifyByOptionsFilteredByClimateZonesLength"
          :key="key"
          size="small"
          :label="specify.label"
          class="checkbox-button"
          :checked="specifyBy.includes(specify.key)"
          :with-prevent="true"
          @click="setSpecificationsByCheckboxValue(specify.key, $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: 'AssumptionsGrowthWidgetPolicyAssumptions',
  props: {
    specifyByOptions: Object,
    specifyBy: [ Array ],
    headerTitle: String,
    toggleConfirmer: {
      type: Function
    }
  },
  computed: {
    ...mapGetters(['lastJurisdictionVisited']),
    specifyByOptionsFilteredByClimateZonesLength() {
      if(this.lastJurisdictionVisited.climate_zones.length > 1) {
        return this.specifyByOptions
      } else {
        return Object.keys(this.specifyByOptions)
            .filter(key => key !== 'CLIMATE_ZONE')
            .reduce((obj, key) => {
              obj[key] = this.specifyByOptions[key]
              return obj
            }, {})
      }
    },
  },
  methods:{
    setSpecificationsByCheckboxValue(value, event){
      const newToggleState = !this.specifyBy.includes(value)
      if ( this.toggleConfirmer && !this.toggleConfirmer(value, newToggleState) ) {
        event?.preventDefault() 
        return
      }
      this.$emit('toggle', value, newToggleState)
    }
  }
}
</script>
<style lang='scss' scoped>
  .checkbox-button {
    background: none;
  }
</style>
