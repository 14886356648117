import lastJurisdictionVisitedSubscription from "./lastJurisdictionVisitedSubscription"
import setLoggedUserSubscription from "./setLoggedUserSubscription"
import allAssumptionsMutationChanges from "./allAssumptionsMutationChanges"
import buildingEstimatesChangeSubscription from "./buildingEstimatesChangeSubscription"
import editAssumptionsChangeSubscription from "./editAssumptionsChangeSubscription"
import userPreferencesUpdated from "./userPreferencesUpdated"

export default (Store) => {
  lastJurisdictionVisitedSubscription(Store)
  setLoggedUserSubscription(Store)
  allAssumptionsMutationChanges(Store)
  buildingEstimatesChangeSubscription(Store)
  editAssumptionsChangeSubscription(Store)
  userPreferencesUpdated(Store)
}
