import getProjectionsByYear from './getProjectionsByYear'

/**
 * get projections resume / cummulative last value to use in table
 */
export default ({ 
  assumption,
  building_stock_units,
  kwh_savings,
  therms_savings,
  annual_bill_savings,
  initial_cost,
  installation_effects_years,
  subsidy_needed,
  five_year_payback,
  subsidy_needed_care,
  five_year_payback_care
}) => {

  const projectionsByYear = getProjectionsByYear({ 
    assumption,
    building_stock_units: building_stock_units,
    kwh_savings,
    therms_savings,
    annual_bill_savings,
    initial_cost,
    installation_effects_years,
    subsidy_needed,
    five_year_payback,
    subsidy_needed_care,
    five_year_payback_care
  })

  const lastItemOfProjection = projectionsByYear.length - 1

  const forecast_emissions_savings = projectionsByYear[lastItemOfProjection]?.forecast_emissions_savings?.cummulative || 0
  const forecast_initial_cost = projectionsByYear[lastItemOfProjection]?.forecast_initial_cost?.cummulative || 0
  const forecast_kwh_savings = projectionsByYear[lastItemOfProjection]?.forecast_kwh_savings?.cummulative || 0
  const forecast_lifecycle_savings = projectionsByYear[lastItemOfProjection]?.forecast_lifecycle_savings?.cummulative || 0
  const forecast_therms_savings = projectionsByYear[lastItemOfProjection]?.forecast_therms_savings?.cummulative || 0
  const forecast_units_affected = projectionsByYear[lastItemOfProjection]?.forecast_units_affected?.cummulative || 0
  const city_wide_subsidy_needed = projectionsByYear[lastItemOfProjection]?.city_wide_subsidy_needed?.cummulative || 0
  const city_wide_five_year_payback = projectionsByYear[lastItemOfProjection]?.city_wide_five_year_payback?.cummulative || 0
  const city_wide_subsidy_needed_care = projectionsByYear[lastItemOfProjection]?.city_wide_subsidy_needed_care?.cummulative || 0
  const city_wide_five_year_payback_care = projectionsByYear[lastItemOfProjection]?.city_wide_five_year_payback_care?.cummulative || 0

  return { 
    forecast_emissions_savings,
    forecast_initial_cost,
    forecast_kwh_savings,
    forecast_lifecycle_savings,
    forecast_therms_savings,
    forecast_units_affected,
    city_wide_subsidy_needed,
    city_wide_five_year_payback,
    city_wide_subsidy_needed_care,
    city_wide_five_year_payback_care,
    projectionsByYear
  }

}
