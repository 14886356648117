<template>
  <ButtonAnchor
    class="button relative rounded border-2 border-gray05 flex items-center justify-center text-gray04 bg-white font-normal inline-block p-4 ts--p--1" 
    :to="redirectUrl"
    :disabled="redirectUrl == ''"
    label="Continue with Google"
    icon="google"
  />
</template>

<script>

export default {
  name: 'AppLoginGoogle',
  data() {
    return {
      redirectUrl: ''
    }
  },
  mounted() {
    this.getRedirectUrl()
  },
  methods: {
    getRedirectUrl() {
      const type = this.$route.name == 'AdminSignIn' ? 'admin' : 'user'
      this.$api.get(`/api/auth/google/get_redirect_url?type=${type}`)
        .then(({ data }) => {
          const encodedUrl = btoa(window.location.href)
          this.redirectUrl = data.redirectUrl + `&state=${encodeURIComponent(encodedUrl)}`
        })
    },

    
  }
}
</script>