import BaseMeta from '../BaseMeta'
import { defaultExistingBuildingsImpactAssumptionValues, defaultNewBuildingsImpactAssumptionValues } from '@/modules/app/assumptions/shared/defaultAssumptions'

export default class CustomCombinationAssumptions extends BaseMeta {

  get fillable () {
    return new Set([ ...Object.keys({...defaultExistingBuildingsImpactAssumptionValues, ...defaultNewBuildingsImpactAssumptionValues }) ])
  }

  constructor(data = {}) {
    super(data)
    return this.getFillableKeys()
  }

}