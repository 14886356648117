<template>
  <BaseDrawer 
    ref="baseDrawer" 
    title="Add/Hide Columns"
    subtitle="Select or re-arrange columns to display"
    :hierarchy="DRAWER_HIERARCHY.SECONDARY"
  >
    <div 
      v-if="showStudyTypeTabs" 
      class="psui-flex psui-pr-4 pb-2 mb-4/5"
    >
      <div 
        class="border-b-2 py-3 psui-mr-4"
        :class="[
          studyTypeSelected == 'Existing Buildings' ? 'psui-border-blue-60 text-pri font-bold' : 'border-blue04-10 text-gray font-semi-bold',
          { 'psui-opacity-75 psui-cursor-not-allowed' : studyTypeSelected == 'New Buildings' }
        ]"
      >
        <button 
          :class="[
            studyTypeSelected == 'Existing Buildings' ? 'psui-text-blue-60 font-bold' : 'border-blue04-10 text-gray font-semi-bold',
            { 'psui-pointer-events-none' : studyTypeSelected == 'New Buildings' }
          ]"
          class="focus:outline-none"
          @click="studyTypeSelected = 'Existing Buildings'"
        >
          Existing Buildings
        </button>
      </div>
      <div 
        class="border-b-2 py-3" 
        :class="[
          studyTypeSelected == 'New Buildings' ? 'psui-border-blue-60 text-pri font-bold' : 'border-blue04-10 text-gray font-semi-bold',
          { 'psui-opacity-75 psui-cursor-not-allowed' : studyTypeSelected == 'Existing Buildings' }
        ]"
      >
        <button 
          :class="[
            studyTypeSelected == 'New Buildings' ? 'psui-text-blue-60 font-bold' : 'border-blue04-10 text-gray font-semi-bold',
            { 'psui-pointer-events-none' : studyTypeSelected == 'Existing Buildings' }
          ]"
          class="focus:outline-none"
          @click="studyTypeSelected = 'New Buildings'"
        >
          New Buildings
        </button>
      </div>
    </div>
    <DragAndDropEditColumnsList
      :get-columns="getColumns"
      :module="module"
    />
  </BaseDrawer>
</template>

<script>
// TODO - Stop use static names and use labels/slugs from global entities
import { randomString, updateColumnCopiesAndHelpers, updateStudyResultsColumnGroupTitleAndHelper } from '@/util/Functions'
import { DRAWER_HIERARCHY } from '@/util/Enums.js'

export default {
  name: 'DrawerPolicyEditColumns',
  data() {
    return {
      studyTypeSelected: 'Existing Buildings',
      module: 'policy',
      DRAWER_HIERARCHY,
      showStudyTypeTabs: false,
      customCombination: null,
      studyId: null,
      studyData: [],
    }
  },

  computed: {
    getItemKey() {
      return randomString(10)
    },
    getColumns() {
      const columns = (this.studyTypeSelected === 'New Buildings' ) ? {... this.$store.getters[`${this.module}/getterPolicyNewBuildingsColumns`] } : {...this.$store.getters[`${this.module}/getterPolicyExistingBuildingsColumns`]}
      columns.columnGroups = columns.columnGroups.map(cg => {
        const columnGroup = {... cg }
        if (!this.studyId || !this.studyData?.length) {
          return columnGroup
        }

        const removeColumnKeys = ['tdv2022_benefit_to_cost_ratio', 'tdv_benefit_to_cost_ratio', 'on_bill_cost_ratio', 'lsc_2025_benefit_to_cost_ratio', 'on_bill_cost_ratio_2025', 'on_bill_2025_care']
        columnGroup.columns = columnGroup.columns.map((column) => {
          const newColumn = {...column }
          if (removeColumnKeys.includes(newColumn.key) && this.getIfColumnShouldBeDisabled(newColumn.key, this.studyData)) {
            newColumn.isActive = false
            newColumn.isDisabled = true
            newColumn.tooltip = 'This data is not available for this study.'
          }
          const data = updateColumnCopiesAndHelpers(this.studyId, newColumn)
          return { ...newColumn, title: data?.columnTitle || column.title }
        }).filter((i) => i)
        return columnGroup
      })

      if(this.module === 'requirements') {
        columns.columnGroups = columns.columnGroups.filter(cg => cg.key != "measure_list")
      }

      return columns
    },

  },
  mounted() {
    this.$eventBus.$on('openDrawerPolicyEditColumns', (payload) => {
      if (payload) {
        this.studyTypeSelected = payload.studyType
        this.module = payload.module
        this.studyId = payload.studyId
        this.customCombination = payload.customCombination
        this.studyData = payload.studyData
      }
      this.$refs.baseDrawer.openDrawer()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openDrawerPolicyEditColumns')
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep {
    &.app-drawer {
      width: 478px;
    }
  }
</style>
