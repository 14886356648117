<template>
  <div
    class="w-full leading-none"
  >
    <span class="psui-text-gray-60 psui-text-small ">
      {{ text }}
    </span>
    <button
      v-if="fuel"
      class="psui-text-blue-60 hover:psui-text-blue-50 ml-4 psui-text-small"
    >
      <a
        :href="url"
        target="_blank"
      >
        {{ label }}
      </a>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ShowLearnMore',
  props: {
    text: {
      type: String,
    },
    label: {
      type: String,
      default: 'Learn More',
    },
    url: {
      type: String,
    },
    fuel:{
      type: Object
    }
  }
}
</script>