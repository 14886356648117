
import { getMaxTargetScorePresetSelected } from '@/services/measure-menu/MeasureMenuGeneralService'
import FlexiblePathService from '@/services/api/FlexiblePathService'
import StudyDataApiService from '@/services/api/StudyDataApiService'
import StudyData from '@/models/StudyData'
import ImpactAssumptions from '@/models/ImpactAssumptions'
import {
    CITY_WIDE_IMPACT_COLUMNS_KEYS, COST_RATIOS_COLUMNS_KEYS,
    PER_HOME_IMPACT_COLUMNS_KEYS
} from '@/business-logic/services/impact-algorithms/policy'
import { forecast_initial_cost, forecast_units } from '@/modules/app/shared/default-app-columns'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import requirementDrawerMixin from '@/modules/app/policy/show/requirements/requirements-drawer/RequirementDrawerMixin'

export default {
    data() {
        return {
            TOTAL_IMPACT_ID: 'policy'
        }
    },
    mixins: [requirementDrawerMixin],
    computed: {
        getPolicySelectedId() {
            return this.$route.params.policy_id || null
        },
        getPolicySelected() {
            return this.getPolicySelectedId ? this.$store.getters['policy/getterUserPolicy']({id: this.getPolicySelectedId}) : null
        },
        getPolicyStudyType() {
            return this.$store.getters['policy/getterPolicyStudyTypes']({ key: 'slug', policy_id: this.getPolicySelected?.id, policy: this.getPolicySelected })[0] ?? null
        },
        jurisdictionClimateZonesCount() {
            return this.getPolicySelected?.jurisdiction?.climate_zones?.length ?? 0
        },
    },
    methods: {
        getTypePrototypeById(id) {
            return this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id })
        },
        getPrototypeById(id) {
            return this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: +id }) || this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: id?.toString() })
        },
        getClimateZoneByRaw(raw) {
            return this.$store.getters['globalEntities/ClimateZone/getterGlobalClimateZone']({ raw })
        },
        getClimateZoneById(id) {
            return this.$store.getters['globalEntities/ClimateZone/getterGlobalClimateZone']({ id: id?.toString() }) || this.$store.getters['globalEntities/ClimateZone/getterGlobalClimateZone']({ id: +id })
        },
        getVintageById(id) {
            return this.$store.getters['globalEntities/Vintage/getterGlobalVintage']({ id: +id }) || this.$store.getters['globalEntities/Vintage/getterGlobalVintage']({ id: id?.toString() })
        },
        getVintagesByPrototypeId(prototypeId) {
            const studyId = this.getPrototypeById(prototypeId)?.study_id
            return (this.$store.getters['globalEntities/Vintage/getterGlobalVintages']({ study_id: +studyId }) || this.$store.getters['globalEntities/Vintage/getterGlobalVintage']({ study_id: studyId?.toString() }))
                .filter((v) => v.type_vintage_id)
        },
        getTypeVintageById(id) {
            return this.$store.getters['globalEntities/TypeVintage/getterGlobalTypeVintage']({ id })
        },
        getBuildingStocksUnits(filters) {
            return this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits'](filters)
        },
        groupContainerInfoByClimateZone(customCombinations, flexibleSetup, flexibleTypePrototype) {
            let groupedCustomCombinations = customCombinations.reduce(
                (acc, curr) => {
                    let climateZone = acc.find(el => el.climate_zone_raw === curr.climate_zone_raw)
                    if(!climateZone) {
                        climateZone = { climate_zone_raw: curr.climate_zone_raw, custom_combinations:[], flex_tiers: [] }
                        acc.push(climateZone)
                    }
                    climateZone.custom_combinations.push(curr)
                    return acc
                }, [])

            if (flexibleSetup && flexibleTypePrototype && Array.isArray(flexibleSetup?.tiers) && flexibleSetup?.tiers?.length) {
                flexibleSetup?.tiers.forEach((tier) => {
                    const climateZone = this.getClimateZoneById(tier.climate_zone_id)
                    const prototype = this.getPrototypeById(tier.prototype_id)
                    const isTierConfigured = tier?.excluded_measures?.length || tier?.mandatory_measures?.length || tier?.target_scores?.some((ts) => ts?.value != null && ts?.value > 0)
                    if (!climateZone || !isTierConfigured || +prototype.type_prototype_id !== +flexibleTypePrototype) {
                        return
                    }

                    let groupClimateZone = groupedCustomCombinations.find(el => el.climate_zone_raw === climateZone.raw)
                    if (!groupClimateZone) {
                        groupClimateZone = { climate_zone_raw: climateZone.raw, custom_combinations:[], flex_tiers: [] }
                        groupedCustomCombinations.push(groupClimateZone)
                    }

                    const tierPreset = getMaxTargetScorePresetSelected({ climate_zone_id : tier.climate_zone_id, prototype_id: tier.prototype_id, flexible_path_setup: flexibleSetup})
                    groupClimateZone.flex_tiers.push({ ...tier, preset: tierPreset})
                })
            }

            // Check and create empty climate zones:
            if (groupedCustomCombinations.length !== this.jurisdictionClimateZonesCount) {
                const usedRaws = groupedCustomCombinations.map((c) => c.climate_zone_raw)
                const notUsedClimateZones = this.getPolicySelected?.jurisdiction?.climate_zones.filter((c) => {
                    return !usedRaws.includes(c.raw)
                }).map((c) => {
                    return {
                        climate_zone_raw: c.raw,
                        custom_combinations: [],
                        flex_tiers: [],
                    }
                })
                groupedCustomCombinations = [...groupedCustomCombinations, ...notUsedClimateZones]
            }

            return groupedCustomCombinations
                .sort((a,b) => Number(a?.climate_zone_raw?.split('-')?.shift() || 0) - Number(b?.climate_zone_raw?.split('-')?.shift() || 0))
        },
        async getVintagesAndMeasuresFromGroupedContainerInfo(group) {
            const vintagesAndMeasures = []
            const getOrCreateGroup = (vintage) => {
                let vintageGroup = vintagesAndMeasures.find((vG) => +vG.vintage_id === +vintage.id)
                if (!vintageGroup) {
                    const typeVintage = this.getTypeVintageById(vintage.type_vintage_id)
                    vintageGroup = { vintage, vintage_id: +vintage.id, prototype_ids: [], measures: [], flex_configured: false, has_flex_error: false, flex_measures: null, flex_target_score: null, preset: null, assumptions: {}, order: typeVintage?.order ?? 999 }
                    vintagesAndMeasures.push(vintageGroup)
                }
                return vintageGroup
            }
            const concatAndAdjustVintageGroupMeasures = (group, measureIds) => {
                group.measures = [... new Set([...group.measures, ...(measureIds || []).map(m => +m)])]
            }
            group?.custom_combinations?.forEach((cc) => {
                if (!cc.vintage_id) {
                    return
                }
                const vintage = this.getVintageById(cc.vintage_id)
                if (!vintage) {
                    return
                }
                const vintageGroup = getOrCreateGroup(vintage)
                concatAndAdjustVintageGroupMeasures(vintageGroup, (cc.measures || []).map(m => +m.id))
                vintageGroup.prototype_ids = [... new Set([...vintageGroup.prototype_ids, +cc.prototype_id])]
                vintageGroup.assumptions = {
                    ...vintageGroup.assumptions,
                    ...(cc?.meta?.assumptions || {})
                }
            })

            const flexService = new FlexiblePathService({ policy_id: this.getPolicySelectedId })
            const flexPromises = []
            group?.flex_tiers?.forEach((tier) => {
                const prototype = this.getPrototypeById(tier.prototype_id)
                if (!prototype || !prototype.study_id) return
                tier?.mandatory_measures?.forEach((m) => {
                    const vintage = this.$store.getters['globalEntities/Vintage/getterGlobalVintage']({ type_vintage_id: +m.type_vintage_id,  study_id: +prototype.study_id })
                    if (!vintage) {
                        return
                    }
                    const vintageGroup = getOrCreateGroup(vintage)
                    concatAndAdjustVintageGroupMeasures(vintageGroup, [m.measure_id])
                    vintageGroup.prototype_ids = [... new Set([...vintageGroup.prototype_ids, +tier.prototype_id])]
                })

                tier?.target_scores?.filter((ts) => ts?.value != null && +ts?.value > 0)?.forEach((tS) => {
                    const vintage = this.$store.getters['globalEntities/Vintage/getterGlobalVintage']({ type_vintage_id: +tS.type_vintage_id,  study_id: +prototype.study_id })
                    if (!vintage) {
                        return
                    }
                    const vintageGroup = getOrCreateGroup(vintage)
                    vintageGroup.flex_configured = true
                    vintageGroup.flex_target_score = +tS.value
                    vintageGroup.preset = tier.preset
                    vintageGroup.promiseIdx = flexPromises.length
                    vintageGroup.prototype_ids = [... new Set([...vintageGroup.prototype_ids, +tier.prototype_id])]

                    const excludedMeasures = tier?.excluded_measures?.filter((m) => +m.type_vintage_id === +tS.type_vintage_id)?.map((m) => +m.measure_id) || []
                    flexPromises.push(flexService.getFlexibleCombinationsByTargetScores({
                        climate_zone_raw: group.climate_zone_raw,
                        prototype_id: prototype.id,
                        vintage_id: vintage.id,
                        preset: tier.preset,
                        target_score: +tS.value,
                        mandatory_measures: vintageGroup.measures,
                        excluded_measures: excludedMeasures
                    }))
                })
            })

            const flexResults = await Promise.all(flexPromises)
            flexResults.forEach((flexCombination, idx) => {
                const vintageGroup = vintagesAndMeasures.find((g) => g.promiseIdx != null && g.promiseIdx === idx)
                const withContingentMeasuresCombination = (flexCombination?.result_allowing_is_contingent?.measuresIdsOfCombinationWithLowestIncrementalCost || []).map(m => +m)
                const withoutContingentMeasuresCombination = (flexCombination?.result_not_allowing_is_contingent?.measuresIdsOfCombinationWithLowestIncrementalCost || []).map(m => +m)

                vintageGroup.has_flex_error = Boolean(flexCombination !== null && (
                    (withContingentMeasuresCombination.length === 0 || withoutContingentMeasuresCombination.length === 0)
                ))
                delete vintageGroup.promiseIdx
                vintageGroup.flex_measures = {
                    with_contingent: withContingentMeasuresCombination,
                    without_contingent: withoutContingentMeasuresCombination,
                }
            })

            // Check and complement with empty vintages...
            const allPrototypeIds = [... new Set(vintagesAndMeasures.map((v) => v.prototype_ids).flat(Infinity))]
            if (allPrototypeIds.length === 1) {
                this.getVintagesByPrototypeId(allPrototypeIds[0]).forEach((vintage) => {
                    const vintageGroup = getOrCreateGroup(vintage)
                    if (!vintageGroup.prototype_ids.includes(+allPrototypeIds[0])) {
                        vintageGroup.prototype_ids.push(+allPrototypeIds[0])
                    }
                })
            }

            return vintagesAndMeasures.sort((a, b) => a.order - b.order)
        },
        async getExistingStudyDataByContainers(flexibleSetup) {
            const searchInputs = { climateZones: [], measureIds: [], vintageIds: [], prototypeIds: [] }
            const policyContainers = Array.isArray(this.getPolicySelected?.policy_containers) ? this.getPolicySelected.policy_containers : []
            for await (const policyContainer of policyContainers) {
                let groupedClimateZones = this.groupContainerInfoByClimateZone(Array.isArray(policyContainer.custom_combinations) ? policyContainer.custom_combinations : [], flexibleSetup, policyContainer?.type_prototype_id)
                for await (const climateZoneInfo of groupedClimateZones) {
                    const vintagesAndMeasures = await this.getVintagesAndMeasuresFromGroupedContainerInfo(climateZoneInfo)
                    const allMeasureIds = vintagesAndMeasures.map((vintageGroup) => {
                        return [
                            ...(vintageGroup?.measures || []),
                            ...(vintageGroup?.flex_measures?.with_contingent || []),
                            ...(vintageGroup?.flex_measures?.without_contingent || [])
                        ]
                    }).flat(Infinity)

                    const vintageIds = vintagesAndMeasures.map((v) => +v.vintage_id)
                    const prototypeIdsByCcs = climateZoneInfo.custom_combinations.map((cc) => +cc.prototype_id)
                    const prototypeIdsByFlex = climateZoneInfo.flex_tiers.map((ft) => +ft.prototype_id)

                    searchInputs.measureIds = [...new Set([...searchInputs.measureIds, ...allMeasureIds])]
                    searchInputs.prototypeIds = [...new Set([...searchInputs.prototypeIds, ...prototypeIdsByCcs, ...prototypeIdsByFlex])]
                    searchInputs.vintageIds = [...new Set([...searchInputs.vintageIds, ...vintageIds])]
                    searchInputs.climateZones = [...new Set([...searchInputs.climateZones, ...[climateZoneInfo.climate_zone_raw]])]
                }
            }

            return await StudyDataApiService.getAllForExisting(searchInputs)
        },
        async generateExistingBuildingImpactTableContent(flexibleSetup) {
            if (!this.getPolicySelected) {
                return null
            }

            const indexedSummaries = {}
            const { studyData, climateZones: studyDataClimateZonePriorities } = await this.getExistingStudyDataByContainers(flexibleSetup)
            const policyContainers = Array.isArray(this.getPolicySelected?.policy_containers) ? this.getPolicySelected.policy_containers : []
            const policyContainersSummaryData = []
            for await (const policyContainer of policyContainers) {
                const typePrototype = this.getTypePrototypeById(policyContainer?.type_prototype_id)
                if (!typePrototype || policyContainersSummaryData.some((i) => i.type_prototype_id === +typePrototype.id)) {
                    continue
                }

                const containerSummaryId = `${this.TOTAL_IMPACT_ID}:container-${policyContainer.id}`
                let groupedClimateZones = this.groupContainerInfoByClimateZone(Array.isArray(policyContainer.custom_combinations) ? policyContainer.custom_combinations : [], flexibleSetup, policyContainer?.type_prototype_id)

                const containerSummaryDatas = []
                for await (const climateZoneInfo of groupedClimateZones) {                   
                    const climateZone = this.getClimateZoneByRaw(climateZoneInfo.climate_zone_raw)
                    const climateZoneSummaryId = `${containerSummaryId}:climateZone-${climateZoneInfo.climate_zone_raw}`
                    const vintagesAndMeasures = await this.getVintagesAndMeasuresFromGroupedContainerInfo(climateZoneInfo)
                    const climateZoneSummaryDatas = []
                    for await (const vintageGroup of vintagesAndMeasures) {
                        const vintageSummaryId = `${climateZoneSummaryId}:vintage-${vintageGroup.vintage.slug}`
                        const studyDataFinder = this.generateStudyDataByMeasureIdMapper(studyData, vintageGroup.vintage_id, climateZone.prefix, vintageGroup.prototype_ids, studyDataClimateZonePriorities, vintageGroup.assumptions)
                        const mandatoryMeasures = (vintageGroup?.measures || [])
                        .map(studyDataFinder)
                        .filter(studyData => studyData)
                        
                        let vintageSummaryDatas = [
                            ...mandatoryMeasures.map(this.generateStudyDataToMeasureRowMapper(vintageSummaryId))
                        ]

                        // Threat flex data here
                        if (vintageGroup.flex_configured === true) {
                            const withContingentFlexMeasures = (vintageGroup?.flex_measures?.with_contingent || []).map(studyDataFinder)
                            const withoutContingentFlexMeasures = (vintageGroup?.flex_measures?.without_contingent || []).map(studyDataFinder)
                            const hasError = Boolean(
                                vintageGroup.has_flex_error ||
                                withContingentFlexMeasures.some((i) => i === null) ||
                                withoutContingentFlexMeasures.some((i) => i === null) ||
                                !withContingentFlexMeasures.length ||
                                !withoutContingentFlexMeasures.length
                            )

                            let flexTotalData = {}

                            if (!hasError) {
                                const dataMapper = (i) => i?.data
                                const flexResumedImpact = this.computeResumedImpacts({
                                    withContingentMeasuresData: withContingentFlexMeasures.map(dataMapper).filter((s) => s),
                                    withoutContingentMeasuresData: withoutContingentFlexMeasures.map(dataMapper).filter((s) => s),
                                    computeProjectionsByYear: true,
                                    customContingentPercentage: vintageGroup?.assumptions?.flexible_contingent_percentage
                                })
                                flexTotalData = this.extractPerHomeAndCityWideKeysFromFullData(flexResumedImpact)
                                flexTotalData.years = flexResumedImpact.projectionsByYear
                            }
                            vintageSummaryDatas.push({
                                title: `Flexible Measures (${vintageGroup.flex_target_score} points)`,
                                id: `${vintageSummaryId}:flexible-impact`,
                                is_selected: false,
                                is_disabled: hasError,
                                tooltip_text: hasError ? `Your required flexible score can’t be achieved, try reducing it to see impacts` : undefined,
                                per_home_data: flexTotalData?.perHome || {},
                                city_wide_data: flexTotalData?.cityWide || {},
                                helper_slug: `flexible-impact-row`,
                                years: flexTotalData?.years || [],
                            })
                        }
                        vintageSummaryDatas.forEach((s) => {
                            indexedSummaries[s.id] = s
                        })
                        vintageGroup.summaryDataByYear = this.sumSummaryYearsForExisting(vintageSummaryDatas, {
                            title: vintageGroup.vintage.title,
                            id: vintageSummaryId,
                            // helper_slug: `type-vintage-${this.getTypeVintageById(vintageGroup.vintage.type_vintage_id).slug}`
                        }, true)
                        indexedSummaries[vintageSummaryId] = vintageGroup.summaryDataByYear
                        climateZoneSummaryDatas.push(vintageGroup.summaryDataByYear)
                    }
                    climateZoneInfo.summaryDataByYear = this.sumSummaryYearsForExisting(climateZoneSummaryDatas, {
                        title: `Climate Zone ${climateZone.prefix}`,
                        id: climateZoneSummaryId,
                    })
                    indexedSummaries[climateZoneSummaryId] = climateZoneInfo.summaryDataByYear
                    containerSummaryDatas.push(climateZoneInfo.summaryDataByYear)
                }
                // Create by building type resumed level
                const containerSummaryData = this.sumSummaryYearsForExisting(containerSummaryDatas, {
                    title: `Existing ${typePrototype.title}`,
                    id: containerSummaryId,
                    helper_slug: `${policyContainer?.study_type?.slug}-type-prototype-${typePrototype.slug}`,
                    type_prototype_id: +typePrototype.id,
                })
                indexedSummaries[containerSummaryId] = containerSummaryData
                policyContainersSummaryData.push(containerSummaryData)
            }
            // Create total impacts level
            indexedSummaries[this.TOTAL_IMPACT_ID] = this.sumSummaryYearsForExisting(policyContainersSummaryData, {
                title: 'Total Impact',
                id: this.TOTAL_IMPACT_ID,
            })
            const impactBreakdownTable = this.generateExistingBuildingImpactBreakdownTableInfo(indexedSummaries, indexedSummaries[this.TOTAL_IMPACT_ID])
            return {
                indexedSummaries,
                impactBreakdownTable
            }
        },
        getTitlesFromImpactsById(indexedSummaries, id) {
            const currentLevel = id.split(':').length
            const titlesMapper = (this.getPolicyStudyType === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS) ?
                { 2: 'prototype', 3: 'climateZone', 4: 'fuel' } :
                { 2: 'prototype', 3: 'climateZone', 4: 'vintage', 5: 'measure' }
            return Array.from({ length: currentLevel - 1 }, (_, i) => i + 2).reduce((acc, idx) => {
                acc[titlesMapper[idx]] = indexedSummaries?.[id.split(':', idx).join(':')]?.title || null
                return acc
            }, {})
        },
        generateExistingBuildingImpactBreakdownTableInfo(indexedSummaries, current=null, result = [], deepLevel = 0, brothersCount = null) {
            const hasDeep = Boolean(current['children']?.length)
            const summaryData = current.years && current.id && current.title ? current : current?.summaryDataByYear
            if (summaryData) {
                // Skips if there's just 1 climate zone on jurisdiction...
                const currentLevel = summaryData.id.split(':').length
                const skipLevel = Boolean(brothersCount === 1 && currentLevel === 3 && this.jurisdictionClimateZonesCount === 1)
                const createDeeperItems = (nextLevelResultList, nextDeepLevel = deepLevel + 1) => {
                    if (current['children']) {
                        const nextLevelBrothersCount = current?.['children']?.length || 0
                        current['children'].forEach((deepItem) => {
                            this.generateExistingBuildingImpactBreakdownTableInfo(indexedSummaries, deepItem, nextLevelResultList, nextDeepLevel, nextLevelBrothersCount)
                        })
                    }
                }

                if (skipLevel) {
                    createDeeperItems(result, deepLevel)
                    return result
                }
                
                const itemData = this.getResumedImpactsFromTableRowInfo([], summaryData.per_home_data, summaryData.city_wide_data)
                const isItemDataEmpty = Boolean(Object.keys(itemData).reduce((acc, key) => {
                    if (!isNaN(itemData[key])) acc += +itemData[key]
                    return acc
                }, 0) === 0)
                const titles = this.getTitlesFromImpactsById(indexedSummaries, summaryData.id)
                let disabledText = null
                if ((!hasDeep || isItemDataEmpty) && currentLevel < 5) {
                    switch(currentLevel) {
                        case 2:
                            disabledText = `${titles.prototype} has no requirements added in your policy`
                            break
                        case 3:
                            disabledText = `${titles.climateZone} has no requirements added in ${titles.prototype}`
                            break
                        case 4:
                            disabledText = `${titles.vintage} has no requirements added`
                            break
                    }
                }

                const isDisabled = Boolean(!!disabledText && !summaryData?.is_disabled)
                const newItem = {
                    data: itemData,
                    deep: deepLevel,
                    id: summaryData.id,
                    helper_slug: summaryData?.helper_slug || null,
                    title: summaryData?.title || null,
                    items: (hasDeep && !isDisabled) ? [] : null,
                    has_helper: !!summaryData?.helper_slug,
                    is_disabled: isDisabled,
                    actions: [],
                    tooltip_text: disabledText || summaryData?.tooltip_text || null,
                    // Do we really need the information bellow?
                    // climate_zone_raw: summaryData?.climate_zone_raw || null,
                    // study_id: this.getPolicySelected?.policy_containers[0]?.custom_combinations[0]?.prototype?.study_id
                }
                if (hasDeep && !isDisabled) {
                    createDeeperItems(newItem.items)
                }
                result.push(newItem)
            }
            return result
        },
        generateStudyDataByMeasureIdMapper(studyData, vintageId, climateZonePrefix, enabledPrototypes, studyDataClimateZonePriorities, assumptions) {
            return (measureId) => {
                const baseStudyData = studyData.filter((s) => {
                    return +s.vintage_id === +vintageId &&
                        +s.measure_id === +measureId &&
                        +s.climate_zone.prefix === +climateZonePrefix &&
                        enabledPrototypes.includes(+s.prototype_id)
                }).sort((a, b) => {
                    let aIdx = studyDataClimateZonePriorities.findIndex((i) => a.climate_zone_raw === i)
                    const bIdx = studyDataClimateZonePriorities.findIndex((i) => b.climate_zone_raw === i)
                    return (aIdx < 0 ? 999 : aIdx) - (bIdx < 0 ? 999 : bIdx)
                }).shift()
                if (!baseStudyData) return null

                return new StudyData({
                    study_data: baseStudyData,
                    building_stock_units: this.getBuildingStocksUnits({
                        jurisdiction_id: this.getPolicySelected.jurisdiction_id,
                        climate_zone_prefix: climateZonePrefix,
                        type_prototype_id: this.getPrototypeById(baseStudyData.prototype_id).type_prototype_id,
                        type_vintage_id: this.getVintageById(vintageId).type_vintage_id,
                        prototype_id: baseStudyData.prototype_id,
                    }),
                    assumption: new ImpactAssumptions(assumptions || {})
                })
            }
        },
        generateStudyDataToMeasureRowMapper(vintageSummaryId) {
            return (measureStudyData) => {
                const data = this.extractPerHomeAndCityWideKeysFromFullData(measureStudyData.data)
                return {
                    title: measureStudyData.measure.title,
                    id: `${vintageSummaryId}:measure-${measureStudyData.measure_id}`,
                    is_selected: false,
                    per_home_data: data.perHome,
                    city_wide_data: data.cityWide,
                    years: measureStudyData.data.projectionsByYear,
                }
            }
        },
        extractPerHomeAndCityWideKeysFromFullData(fullData) {
            return Object.keys(fullData).reduce((data, key) => {
                if (CITY_WIDE_IMPACT_COLUMNS_KEYS.includes(key)) data.cityWide[key] = fullData[key]
                if ([...COST_RATIOS_COLUMNS_KEYS, ...PER_HOME_IMPACT_COLUMNS_KEYS].includes(key)) data.perHome[key] = fullData[key]
                return data
            }, { perHome: {}, cityWide: {} })
        },
        // ToDo: this method is new compatible, reuse it on new buildings when we refactor and bring the new buildings to here..
        sumSummaryYearsForExisting(summariesWithYears, commonObjData, preserveUnities = false) {
            // ToDo: Create a function to be passed here and defines those disabled states so we can reuse it on every part of code...
            // Disables rows with default state
            const defaultStateSummaries = summariesWithYears?.filter((x) => x?.isOnDefaultState === true && (x?.isDefaultStateByHerritage !== true || x?.isAllInDefaultState === true))
            if (summariesWithYears?.find((x) => x?.isOnDefaultState === true)) {
                commonObjData.isOnDefaultState = true
                commonObjData.isDefaultStateByHerritage = true
                commonObjData.isAllInDefaultState = Boolean(defaultStateSummaries.length === summariesWithYears.length)
            }
            const disableCostEffectiveAverage = Boolean(summariesWithYears?.filter((x) => x?.disableCostEffectiveAverage === true)?.length)
            if (disableCostEffectiveAverage) {
                commonObjData.disableCostEffectiveAverage = true
            }
            const useSameCostEffectiveOnNewSummary = Boolean(summariesWithYears?.filter((x) => x?.disableCostEffectiveAverage === false)?.length === 1 || summariesWithYears?.length === 1)

            // Compute city/county-wide columns projections
            const result = {
                ...commonObjData,
                years: this.computeProjectionsByYear(
                    summariesWithYears.filter((i) => !(i?.isOnDefaultState === true && i?.isDefaultStateByHerritage !== true)), 1, preserveUnities, 'years'
                ) || [],
                children: summariesWithYears
            }
            // Compute new results data
            const summariesFullData = summariesWithYears.map((s) => { return { ...(s.per_home_data || {}), ...(s.city_wide_data || {}) } })
            const newData = this.extractPerHomeAndCityWideKeysFromFullData(
                this.computeResumedImpacts({
                    mandatoryMeasuresData: summariesFullData,
                    preserveUnities,
                })
            )
            result.city_wide_data = newData.cityWide

            // Preserve unities means that per_home doesn't need to be weighted changed by unities when we are grouping (example: vintage measures)
            if (preserveUnities) {
                result.per_home_data = newData.perHome
            } else {
                const per_home_base_object = {} 
                
                PER_HOME_IMPACT_COLUMNS_KEYS.forEach(key => {
                    per_home_base_object[key] = 0
                })
                const summariesWithPerHome = summariesWithYears
                .map((s) => s?.per_home_data || per_home_base_object)
                
                if (summariesWithPerHome.length) {
                    const getUnitiesFromIdx = (idx) => (summariesWithYears?.[idx].isOnDefaultState === true && summariesWithYears?.[idx].isDefaultStateByHerritage !== true) ? 0 :
                        (summariesWithYears?.[idx]?.years?.slice(0)?.pop()?.[forecast_units()?.key]?.cummulative || 0)

                    const getComplianceCostFromIdx = (idx) => (summariesWithYears?.[idx].isOnDefaultState === true && summariesWithYears?.[idx].isDefaultStateByHerritage !== true) ? 0 :
                        Math.abs(summariesWithYears?.[idx]?.years?.slice(0)?.pop()?.[forecast_initial_cost()?.key]?.cummulative || 0)
                    
                    const totalUnities = summariesWithYears.map((s, idx) => {
                    return getUnitiesFromIdx(idx)
                    }).reduce((acc, curr) => acc + curr, 0)

                    const totalComplianceCost = summariesWithYears.map((s, idx) => {
                    return getComplianceCostFromIdx(idx)
                    }).reduce((acc, curr) => acc + curr, 0)
                    
                    result.per_home_data = summariesWithPerHome.reduce((acc, curr, idx) => {
                        if (!curr) return acc

                        const affectedUnities = getUnitiesFromIdx(idx)
                        const complianceCost = getComplianceCostFromIdx(idx)
                        Object.keys(curr).forEach((key) => {
                            if (!acc[key]) {
                                acc[key] = (key === 'baseline_fuel_type') ? null : 0
                            }

                            if (useSameCostEffectiveOnNewSummary && !isNaN(Number(curr[key])) && Number(curr[key]) !== 0) {
                                acc[key] = Number(curr[key])
                            }

                            if (useSameCostEffectiveOnNewSummary && acc[key]) {
                                return
                            }

                            if (key === 'baseline_fuel_type') {
                                acc[key] = curr[key]
                            } else if(key === 'emissions_savings_pct') {
                                acc[key] = undefined
                            } else if (PER_HOME_IMPACT_COLUMNS_KEYS.includes(key)) {
                                acc[key] += (Number(curr[key]) * affectedUnities) / (totalUnities || 1)
                            } else if (COST_RATIOS_COLUMNS_KEYS.includes(key)) {
                                if ((result.isOnDefaultState === true && result.isDefaultStateByHerritage === true) ||
                                    result.disableCostEffectiveAverage === true)
                                {
                                    acc[key] = '--'
                                } else {
                                    acc[key] += (Number(curr[key]) * complianceCost) / (totalComplianceCost || 1)
                                }
                            }
                        })
                        return acc
                    }, {})
                }
            }

            // We cant compute percentage of emissio reductions on higher levels
            if (result?.per_home_data?.emissions_savings_pct) delete result.per_home_data.emissions_savings_pct
            return result
        },
        getResumedImpactsFromTableRowInfo(yearsSummary, perHomeInfo = {}, cityWideInfo = {}) {
            const lastInfo = yearsSummary?.slice(0)?.pop() || {}
            const cityWideResumed = CITY_WIDE_IMPACT_COLUMNS_KEYS.map((key) => lastInfo?.[key]?.cummulative !== undefined ? { key, value: lastInfo[key].cummulative } : null)
                .filter(k => k !== null)
                .reduce((curr, acc) => {
                    curr[acc.key] = acc.value
                    return curr
                }, {})
            return {
                ...cityWideResumed,
                ...cityWideInfo,
                ...perHomeInfo,
            }
        },
    }
}