import PolicyApiService from '@/services/api/PolicyApiService'
import PolicyContainerApiService from '@/services/api/PolicyContainerApiService'
import CustomCombinationApiService from '@/services/api/CustomCombinationApiService'
import { POLICY_ATTACH_TO_USER_RETURNS } from '@/modules/app/policy/shared/enums.js'
import Container from '@/models/Container'
import Policy from '@/models/Policy'
import CustomCombination from '@/models/CustomCombination'
import EventBus from '@/util/EventBus'
import { deepMergeObject } from '@igortrindade/lazyfy'
import { GA_LABELS } from '@/mixins/GaEventsMixin'
import UserEventApiService from '@/services/api/UserEventApiService'

export default {
  addOrUpdatePolicyToUserOrSharedLists({ commit, state, rootGetters }, { policy }) {
    // NOT LOGGED AND DEVICE DIFF FROM CURRENT DEVICE - ADD TO SHARED
    if(!rootGetters['getterLoggedUser'] && rootGetters['general/getterDeviceInfo'].id != policy.user_device_id) {
      return commit('setUserSharedPolicies', [ ...state.userSharedPolicies.filter((pol) => pol.id != policy.id), new Policy(policy) ])
    }

    // NOT LOGGED AND DEVICE IS EQUAL CURRENT DEVICE - ADD TO USER
    if(!rootGetters['getterLoggedUser'] && rootGetters['general/getterDeviceInfo'].id == policy.user_device_id) {
      return commit('setUserPolicies', [ ...state.userPolicies.filter((pol) => pol.id != policy.id), new Policy(policy) ])
    }
    
    // LOGGED AND POLICY BELONGS TO USER - ADD TO USER
    if(rootGetters['getterLoggedUser'].id == policy.user_id) {
      return commit('setUserPolicies', [ ...state.userPolicies.filter((pol) => pol.id != policy.id), new Policy(policy) ])
    }

    // LOGGED BUT POLICY DOESNT BELONGS TO USER - ADD TO SHARED
    if(rootGetters['getterLoggedUser'].id != policy.user_id) {
      return commit('setUserSharedPolicies', [ ...state.userSharedPolicies.filter((pol) => pol.id != policy.id), new Policy(policy) ])
    }

  },

  getUserPolicies({ commit }) {
    return PolicyApiService.getUserPolicies()
      .then((policies) => {
        if(policies?.length) commit('setUserPolicies', policies)
      })
  },

  getUserSharedPolicies({ commit }) {
    return PolicyApiService.getUserSharedPolicies()
    .then((shared_policies) => {
      if(shared_policies.length) commit('setUserSharedPolicies', shared_policies)
    })
  },

  getAllUserPolicies({ dispatch }) {
    return Promise.all([
      dispatch('getUserPolicies'),
      dispatch('getUserSharedPolicies')
    ])
  },

  getUserPolicy({ dispatch }, { policyId, forceGet }) {
    return PolicyApiService.getNewPolicy(policyId, forceGet || false)
      .then((policy) => {
        return dispatch('addOrUpdatePolicyToUserOrSharedLists', { policy }).then(() => {
          return policy
        })
      })
  },

  attachPoliciesToUser({ dispatch, state }) {
    const user_policies_ids = state.userPolicies.filter((policy) => !policy.user_id).map(policy => policy.id)
    const user_shared_policies_ids = state.userSharedPolicies.map(policy => policy.id)
    
    return new Promise((resolve) => {
      if (user_policies_ids.length || user_shared_policies_ids.length) {
        return PolicyApiService
          .attachToUser({ user_policies_ids, user_shared_policies_ids })
          .then(() => {
            dispatch('getAllUserPolicies')
            resolve(POLICY_ATTACH_TO_USER_RETURNS.POLICIES_HAS_BEEN_ATTACHED)
          })
      } else {
        resolve(POLICY_ATTACH_TO_USER_RETURNS.HAS_NOT_POLICIES_TO_ATTACH)
      }
    })
    
  },

  updateOrCreatePolicyContainer({dispatch}, container){
    const newContainer = new Container(container)
    return PolicyContainerApiService.update_or_create(newContainer)
    .then((data)=>{
      dispatch('getAllUserPolicies')
      return dispatch('getUserPolicy', { policyId: data.policy_id })
      .then(()=>{
        return data
      })
    })
  },

  deleteContainerFromPolicy({ dispatch }, policy_container){
    const refreshPolicy = policy_container?.refreshPolicy ?? true
    const container = policy_container?.container || policy_container
    return PolicyContainerApiService.delete(container.id).then(()=> {
      if (refreshPolicy === true) {
        return dispatch('getAllUserPolicies').then(() => {
          return dispatch('getUserPolicy', { policyId: container.policy_id } )
        })
      }
    })
  },

  /**
   * Creates a policy and add it to the vuex
   * If pass study_type_slug and type_prototype_slug will generate a policy container with the type_prototype
   */
  async createPolicy({ dispatch, rootGetters }, { study_type_slug, type_prototype_slug, policy } = {}) {
    const study_type = study_type_slug ? rootGetters['globalEntities/StudyType/getterGlobalStudyType']({ slug: study_type_slug }) : false
    const type_prototype = type_prototype_slug ? rootGetters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ slug: type_prototype_slug }) : false
        
    try {
      const response = await PolicyApiService.store(policy)


      if (study_type && type_prototype) {
        const policyContainerPayload = {           
          policy_id: response.id,
          study_type_id : study_type.id , 
          type_prototype_id : type_prototype.id
        }
        await dispatch('updateOrCreatePolicyContainer', policyContainerPayload)
      }
      

      const newPolicy = await PolicyApiService.getNewPolicy(response.id, true)
      if (newPolicy) {
        dispatch('addOrUpdatePolicyToUserOrSharedLists', { policy: newPolicy })
        
        return newPolicy.id
      } 
        
      return false
      

    } catch (error) {
      throw new Error(error) 
    }
  },

  getPolicy({ dispatch }, policy_id) {
    return Promise.all([  
      dispatch('getUserPolicy', { policyId: policy_id }),
      dispatch('assumptions/getPolicy', policy_id, { root: true }),
    ])
  },

  saveCustomCombinationsToPolicy(context, custom_combinations = []) {
    EventBus.$emit('startSavingCustomCombinations', custom_combinations?.map((cc) => +cc?.id))
    return Promise.all(custom_combinations.map(cc => {
      if (cc && cc.getAndMerge === true) {
        delete cc.getAndMerge
        // ToDO: When decided with Dev Team, change here to just call some endpoint that can update by id and do the deep merge in meta
        //  it will cost less them 1 get + 1 save

        context.commit('mergeCustomCombinationMetaAssumptions', cc)
        return CustomCombinationApiService.get(cc.id).then((ccDb) => {
          const merged = new CustomCombination(deepMergeObject(ccDb, cc ))
          // console.log('merged', merged)
          // return CustomCombinationApiService.updateById(cc.id, cc)
          // console.log('saving merged:', merged)
          return CustomCombinationApiService.updateOrCreate(merged)
        })
      }
      return CustomCombinationApiService.updateOrCreate(cc)
    }))
    .then((result) => {
      result.forEach((cc) => {
        EventBus.$emit('customCombinationChanged', new CustomCombination({
          ...cc,
          meta: typeof cc?.meta === 'string' ? JSON.parse(cc.meta) : cc.meta
        }))
      })

      if (!result?.length) {
        return result
      }

      return context.dispatch('getPolicy', result[0].policy_id)
      .then(()=>{
        EventBus.$emit('customCombinationUpdated')
        return result
      })
    })
  }
}
