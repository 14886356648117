<template>
  <div class="w-full p-6 mb-20">
    <AppTabHeader      
      :items="tabs"
      :selected.sync="selectedTab"
    />

    <hr class="border-b border-ash-300 my-6">    
    
    <PsDialog 
      v-if="measure.is_package && measure.title.toLowerCase().includes('pv') && !measure.slug.endsWith('-pv')"
      class="mt-4"
    >
      Attention: <b>{{ measure.title }}</b> has no <b>pv</b> defined on the slug: <b>{{ measure.slug }}</b>
    </PsDialog>

    <div v-show="selectedTab === 'Content'">      
      <InputWithLabel
        v-model="measure.title"
        class="mt-6"
        :label="'Base Title'"
      />
      
      <InputWithLabel
        v-if="isExistingBuildingStudy"
        v-model="measure.flexible_path_title"
        class="mt-6"
        :label="'Flexible Path Title'"
      />

      <InputWithLabel
        v-model="measure.compliance_margin_control_title"
        class="mt-6"
        :label="'Compliance margin control title'"
      />

      <div class="w-full relative mt-6">
        <VueSelect 
          v-model="measure.type_measures"
          class="vue-select"
          label="title"
          :options="type_measures"
          :reduce="item => item.id"
          :close-on-select="false"
          :multiple="true"
        />
        <label>Measures Type</label>
      </div>

      <ContentEditor
        label="Short Description"
        class="mt-6"
        :content.sync="measure.description"
      />
      
      <ContentEditor
        label="Description"
        class="mt-6"
        :content.sync="measure.content"
      />
    </div>
    
    <div v-show="selectedTab === 'Business Logic'">
      <div class="w-full flex flex-row space-x-3 my-6 items-start">
        <Checkbox
          class="border border-deepsky-200 px-2 rounded-sm mr-2 h-8"
          label="Measure"
          :value="!measure.is_package"
          checkbox-classes="mb-0"
          type="radio"
          @input="setMeasureIsPackage(false)"
        />
        <Checkbox
          class="border border-deepsky-200 px-2 rounded-sm mr-2 h-8"
          label="Package"
          :value="measure.is_package"
          checkbox-classes="mb-0"
          type="radio"
          @input="setMeasureIsPackage(true)"
        />
      </div>           

      <div class="flex flex-col">
        <div
          v-if="measure.is_package || measure.measures.length"
          class="w-full relative mb-2"
        >
          <VueSelect 
            v-model="measure.measures"
            class="vue-select"
            label="title"
            :options="measures"
            :close-on-select="false"
            :multiple="true"
            @input="checkMeasureIsPackage"
          >
            <template #option="option">                
              {{ option.id }} - {{ option.title }}
            </template>
            <template #selected-option="option">          
              <span class="text-ash-400 mr-2">{{ option.id }}</span>
              {{ option.title }}
            </template>
          </VueSelect>
          <label>Please, select all measures that compose the package {{ measure.title }}</label>
        </div> 
      
        <Checkbox
          v-if="measure.is_package"
          v-model="measure.is_other_child_measures"
          label="This package includes child measures that were not included in the study?"
          class="inline-block"
        />

        <Checkbox
          v-model="measure.hide_in_results"
          :label="`This ${measure.is_package ? 'package' : 'measure'} should be excluded in Study Results`"
          class="inline-block"
        />

        <!--  We decided on DDP-1656 to use this flag for disabling requirements measures/packages, even dont having a good name haha.  -->
        <!--  Tip: If we want to revert it, this one was supposed to be new buildings template only -->
        <Checkbox
          v-model="measure.hide_in_compliance_margin_control"
          :label="`This ${measure.is_package ? 'package' : 'measure'} should be excluded in Policy Requirements Control?`"
          class="inline-block"
        />

        <Checkbox
          v-model="measure.is_pre_emptive"
          :label="`This ${measure.is_package ? 'package' : 'measure'} is Pre Emptive?`"
          class="inline-block"
        />
        
        <template v-if="isExistingBuildingStudy">
          <Checkbox          
            v-model="measure.hide_in_flexible_path"
            :label="`This ${measure.is_package ? 'package' : 'measure'} should be excluded in Flexible Paths`"
            class="inline-block"
          />
          <Checkbox          
            v-model="measure.include_in_combination"
            :disabled="measure.hide_in_results ? true : false"
            :label="`This ${measure.is_package ? 'package' : 'measure'} is allowed to be included in a USER Custom Combination`"
            class="inline-block"
          />

          <div class="w-full relative mb-2">
            <VueSelect 
              v-model="measure.exclude_measures"
              class="vue-select"
              placeholder="Select measures to exclude from measures combined"
              :options="measures"
              :close-on-select="false"
              :multiple="true"
              :reduce="item => item.id"
              label="title"
            >
              <template #option="option">                
                {{ option.id }} - {{ option.title }}
              </template>

              <template #selected-option="option">          
                <span class="text-ash-400 mr-2">{{ option.id }}</span>
                {{ option.title }}
              </template>
            </VueSelect>
            <label>{{ `Select the measures or packages that can not be combined after the ${measure.title} has been added to a Custom Combination (User or Preset)` }}</label>
          </div>

          <InputWithLabel
            v-if="!measure.include_in_combination"
            v-model="measure.exclude_in_combination_reason"
            :label="'Tooltip to explain the user why this measure is not available to a User Custom Combination'"
            class="mb-3"
          /> 
        </template>

        <template v-else>
          <Checkbox
            v-model="measure.has_larger_pv_included"
            :label="`This ${measure.is_package ? 'package' : 'measure'} has large PV included?`"
            class="inline-block"
          />
        </template>

        <div class="w-full relative mb-2">
          <VueSelect 
            v-model="measure.exclude_in_custom_combination_presets"
            class="vue-select"
            :options="CUSTOM_COMBINATION_PRESETS"
            :close-on-select="false"
            :multiple="true"
            :reduce="item => item.value"
          />
          <label>{{ `Select the Custom Combination Presets that this ${measure.is_package ? 'package' : 'measure'} is not allowed to be added.` }}</label>
        </div>
          
        <Checkbox
          v-model="measure.is_contingent"
          :label="`This ${measure.is_package ? 'package' : 'measure'} is contingent?`"
          class="inline-block"
        />
      </div>      
      
      <AdminStudyItemMeasureFormExclusionSuggestions 
        :measure="measure" 
        :measures-dic="measuresDic"
        @addMeasureToExcludedMeasures="addMeasureToExcludedMeasures"
      />      
    </div>

    <InputWithLabel
      v-show="selectedTab === 'Others'"
      v-model="measure.slug"
      class="mt-6"
      :label="'Slug (external id)'"
    />

    <hr class="border-b border-gray05 my-6">

    <button
      class="mt-6 button button-blue04"
      @click="update()"
    >
      Update {{ measure.is_package ? 'Package' : 'Measure' }}
    </button>
  </div>
</template>

<script>
import AdminStudyItemMeasureFormExclusionSuggestions from './AdminStudyItemMeasureFormExclusionSuggestions.vue'
import AppTabHeader from '@/components/general/AppTabHeader.vue'
import Measure from '@/models/Measure'
import { studyTypes } from '@/util/Enums'
import { CUSTOM_COMBINATION_PRESETS } from '@/modules/app/jurisdiction/study-results/shared/enums.js'
import { uniqueByKey } from '@igortrindade/lazyfy'

export default {
  name: 'AdminStudyItemMeasureForm',
  components: { AppTabHeader, AdminStudyItemMeasureFormExclusionSuggestions },
  data() {
    return {
      tabs : ['Content', 'Business Logic', 'Others'],
      selectedTab: 'Business Logic',
      measure: new Measure(),
      types: [],
      measures: [],
      type_measures: [],
      CUSTOM_COMBINATION_PRESETS: Object.values(CUSTOM_COMBINATION_PRESETS)
    }
  },
  computed: {
    measuresDic() {
      return this.measures.length > 0 ? this.measures.reduce((prev, cur) => {
        prev[cur.id] = cur
        return prev
      }, {}) : false
    },
    isExistingBuildingStudy() {
      return this.measure?.study?.study_group?.study_type?.title  === studyTypes.EXISTING_BUILDINGS
    },
    isNewBuildings() {
      return this.measure?.study?.study_group?.study_type?.title === studyTypes.NEW_BUILDINGS
    }
  },
  watch: {
    'measure.hide_in_results'(hide_in_results) {
      if (hide_in_results) this.measure.include_in_combination = false
    }
  },
  mounted() {
    this.getBaseModel()
    this.getTypes()

    if(this.$route.params.type == 'measure') {
      this.getMeasuresAndTypes()
    }
  },
  methods: {
    async getBaseModel() {
      const query = `
        {
          measure (
            where: [
              { column: "id", value: "${this.$route.params.study_item_id}"}
            ]
          ){
            id
            study_id
            title
            slug
            description
            content
            order
            type_measure_id
            include_in_combination
            exclude_measures
            exclude_in_combination_reason
            exclude_in_custom_combination_presets
            hide_in_flexible_path
            hide_in_results
            flexible_path_title
            is_package
            is_contingent
            is_other_child_measures
            is_pre_emptive
            has_larger_pv_included
            hide_in_compliance_margin_control
            compliance_margin_control_title
            measures {
              id
              title
              exclude_measures
            }
            type_measures { 
              id 
              title 
            }
            study {
              study_group {
                study_type {
                  title
                }
              }
            }
          }
        }
      `
      const { data } = await this.$graphql({ query })

      this.measure = new Measure(data.measure)
    },

    getMeasuresAndTypes() {
      const query = `
        {
          measure(
            where: [{column: "id", value: "${this.$route.params.study_item_id}"}]
          ) {
            study {
              measures (
                orderBy: [{column: "title", order: "ASC" }]
                where: [
                  { column: "id", operation: "<>", value: "${this.$route.params.study_item_id}"}
                ]
              ) {
                id
                title
                exclude_measures
              }
            }
          }
          type_measures(
            orderBy: [{column: "title", order: "ASC"}]
          ) {
            id
            title
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.measures = data.measure.study.measures
          this.type_measures = data.type_measures
        })
    },

    async getTypes() {      
      const query = `
        {
          type_measures{
            id
            title
          }
        }
      `
      const { data } = await this.$graphql({ query })
      this.types = data[`type_measures`]

    },

    update() {
      this.$api.post(`/api/admin/measure/${this.$route.params.study_item_id}`, this.measure, { loader: true })
        .then(() => {
          this.$toast.success('Item updated successfully')
          this.$router.go(-1)
        })
    },

    setMeasureIsPackage(is_package) {
      this.measure.is_package = is_package
      if(!is_package) this.measure.measures = []
    },

    checkMeasureIsPackage() {
      if(this.measure.measures.length) {
        this.measure.is_package = true
        this.measure.exclude_measures = uniqueByKey([ ...this.measure.exclude_measures, ...this.measure.measures.map(m => m.id) ] )
      } else {
        this.measure.is_package = false
      }
    },    
  
    addMeasureToExcludedMeasures(measure_ids) {
      const sanitize_measure_ids = this.sanitizeMeasureIds(measure_ids)
      this.$set(this.measure, 'exclude_measures', [...new Set([...this.measure.exclude_measures, ...sanitize_measure_ids])])
    },

    sanitizeMeasureIds(measure_ids) {
      return measure_ids.filter((measure_id) => this.measuresDic[measure_id] ? true : false )
    }
  }
}
</script>

<style lang="scss">
  .vs__dropdown-menu {
    border-bottom: 1px solid gray !important;
    border-left: 1px solid gray !important;
    border-right: 1px solid gray !important;
    // box-shadow: 1px 1px 1px 1px gray;
  }
</style>
