<template>
  <vue-skeleton-loader
    :width="100"
    :height="21"
    class="inline-block"
  />
</template>

<script>
export default {
    name:'SkeletonPolicyEditRequirementsFooter'
}
</script>

<style>

</style>