<template>
  <div class="w-full">
    <div class="w-full flex justify-between items-center mb-2">
      <p class="psui-text-small psui-text-gray-60">
        {{ label }}
      </p>
      <PsInput
        ref="psinput"
        :value="unitsFormated"
        layout="mini"
        :active="isNotDefaultBuildingStock"
        :disabled="!isEditing && isInputWithEditIcon && !isNotDefaultBuildingStock"
        @input="onInput"
        @change="onChange"
        @blur="onBlur"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
      >
        <template
          #append
        >
          <PsTooltip
            v-if="isInputWithEditIcon && !isEditing && !isNotDefaultBuildingStock"
            class="stock-input"
          >
            <template #trigger>
              <i
                class="material-icons psui-text-gray-40 psui-cursor-pointer hover:psui-text-blue-60"
                @click="onChangeInputStatus"
              >
                edit
              </i>
            </template>
            <template #content>
              Click to edit
            </template>
          </PsTooltip>
          <PsTooltip
            v-if="showResetButton"
            class="stock-input"
          >
            <template #trigger>
              <i
                class="material-icons psui-text-gray-40 psui-cursor-pointer" 
                @click="resetToDefault"
              >
                restart_alt
              </i>
            </template>
            <template #content>
              Reset to default
            </template>
          </PsTooltip>
          <span 
            v-if="!isInputWithEditIcon && !isEditing && !showResetButton"
            class="psui-absolute psui-text-xsmall psui-text-gray-40 psui-cursor-text psui-right-0 psui-width-0 psui-height-0 psui-pr-2"
          >
            {{ unitMeasurement }}
          </span>
        </template>
      </PsInput>
    </div>
  </div>
</template>

<script>
import formatNumber from '@/formatters/formatNumber'

export default {
  name: 'AssumptionsBuildingStocksInput',
  props: {
    label: {
      type: String,
      required: true
    },
    filters: {
      type: Object,
      required: true,
    },
    isInputWithEditIcon: {
      type: Boolean,
      default: false
    },
    unitMeasurement: {
      type: String,
      default: 'Units'
    }
  },
  data: () => ({
    unitsRaw: false,
    isEditing: false,
    existingUnitsInitialValue: null,
    isHovering: false
  }),
  computed: {
    getDefaultBuildingStockUnits(){
      return this.$store.getters['assumptions/buildingStocks/getterDefaultJurisdictionBuildingStocksUnits'](this.filters)
    },
    getBuildingStocks() {
      return this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocks'](this.filters)
    },
    getBuildingStockUnits() {
      return this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits'](this.filters)
    },
    unitsFormated() {
      return this.vueNumberFormat(this.unitsRaw, { prefix: '', acceptNegative: false, isInteger: false, precision: 0 })
    },
    isNotDefaultBuildingStock() {
      return formatNumber(this.getDefaultBuildingStockUnits) != formatNumber(this.getBuildingStockUnits)
    },
    showResetButton() {
      return this.isNotDefaultBuildingStock && this.isHovering
    },
    getPolicyId() {
      return (this.$route.params.policy_id) ? this.$route.params.policy_id : null
    },
  },
  watch: {
    getBuildingStockUnits: {      
      handler(value) { this.unitsRaw = value },
      immediate: true
    },
  },
  mounted(){
    this.existingUnitsInitialValue = this.unitsFormated
  },
  methods: {
    resetToDefault() {
      this.$store.dispatch('assumptions/buildingStocks/clearCustomBuildingStocks', this.getBuildingStocks)
      this.isEditing = false
      this.isHovering = false
    },
    onInput($event) {
      this.unitsRaw = parseFloat($event.target.value.replace(/\D+/g, '')) || 0
      this.$forceUpdate()
    },
    onChange($event) {   
      $event.preventDefault()
      const to = parseInt(this.unitsRaw)
      const from = parseInt(this.getBuildingStockUnits)
      this.$store.dispatch('assumptions/buildingStocks/addOrUpdateCustomBuildingStocks', { custom_building_stocks: this.getBuildingStocks, to, from })
    },
    onChangeInputStatus(){
      
      setTimeout(() => {
        this.$refs.psinput.$el.querySelector('input').focus()
      }, 50)

      this.isEditing = !this.isEditing
    },
    onBlur(event){
      if(event.target.value === this.existingUnitsInitialValue) this.isEditing = false
    },
    onMouseLeave(){
      this.isHovering = false 
    },
    onMouseEnter(){
      this.isHovering = true
    }
  }
}
</script>

<style lang="scss" scoped>

  i {
    font-size: 12px;
    right: 8px;
  }

  ::v-deep .psui-el-tooltip.stock-input {
    .psui-el-tooltip-wrapper {
      .psui-el-tooltip-dialog {
        min-width: 20px;
        width: fit-content;
        z-index: 100;
      }
    }
  }
</style>
