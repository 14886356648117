<template>
  <div class="base-header psui-w-full psui-flex psui-flex-col z-25">
    <BaseAppHeader :title="'My Policies' | policyTextRephrase">
      <template #top-right>
        <div class="base-header__actions psui-flex-shrink-0 psui-w-full psui-flex psui-items-center psui-space-x-2">
          <PoliciesListHeaderQuickGuide />
          <AppPolicyCreateButton />
        </div>
      </template>
      <template #bottom-left>
        <div
          v-if="getAllUserPolicies.length"
          class="psui-flex pb-2"
        >
          <PoliciesListFilters
            :jurisdictions="policiesJurisdictions"
            class="psui-flex psui-mr-auto"
          />        
        </div>
      </template>
    </BaseAppHeader>
  </div>
</template>

<script>
import AppPolicyCreateButton from '@/components/general/AppPolicyCreateButton.vue'
import PoliciesListHeaderQuickGuide from './PoliciesListHeaderQuickGuide.vue'
import PoliciesListFilters from "../list/PoliciesListFilters.vue"
import { mapGetters } from "vuex"
import { uniqueByKey } from "@igortrindade/lazyfy"
import BaseAppHeader from '@/components/general/BaseAppHeader.vue'
import { POLICIES_TAB_DESCRIPTION } from "../shared/enums.js"

export default {
  name: "PoliciesListHeader",
  components: {
    PoliciesListFilters,
    PoliciesListHeaderQuickGuide,
    BaseAppHeader,
    AppPolicyCreateButton,
  },
  data() {
    return {
      userPoliciesIds: "",
      POLICIES_TAB_DESCRIPTION,
      tabSelected: false,
    }
  },
  computed: {
    getAllUserPolicies() {
      return this.$store.getters['policy/getterUserPolicies']()
    },
    policiesJurisdictions() {
      if (!this.getAllUserPolicies) return []
      return uniqueByKey(
        this.getAllUserPolicies,
        "jurisdiction_id"
      ).map((policy) => policy.jurisdiction)
    },
    ...mapGetters(["getterLoggedUser"]),
  },
}
</script>
