<template>
  <div class="psui-w-full psui-h-full psui-rounded-md psui-bg-white psui-shadow-elevation-5 psui-p-8 psui-flex psui-flex-col psui-justify-between">
    <div class="psui-w-full psui-flex psui-flex-col psui-justify-between">
      <div class="psui-flex psui-items-start">
        <h4 class="psui-text-h5 psui-text-gray-80 psui-font-bold psui-mr-auto">
          {{ getBuildingTypeSelected.slug === path.RESIDENTIAL_BUILDINGS.slug ? 'New Unit Forecasts' : 'New Construction Forecast' }} 
          
          <span class="psui-font-normal">{{ getTitleByBuildingTypeSelected }}</span>
        </h4>
        <PsCardInfos
          v-if="getAnnualGrowthRate"
          id="card-edit-icon" 
          title="Annual growth rate"
          :subtitle="BUILDING_ESTIMATES_BASE_YEARS_TIMEFRAME"
          :total="getAnnualGrowthRate"
          @click="openAssumptionsDrawer()"
        />
        <PsCardInfos
          id="card-edit-icon" 
          class="psui-ml-2"
          :title="`Total ${getUnitOfMeasurementByBuildingTypeSelected}`"
          :subtitle="BUILDING_ESTIMATES_BASE_YEARS_TIMEFRAME"
          :total="formatNumber(getAllBuildingEstimatesUnits)"
          @click="openAssumptionsDrawer()"
        />
      </div>

      <div class="psui-block">
        <ApexChartGeneral 
          :options="getChartOptions()"
          :palette-color-index="paletteColorIndex"
        />
      </div>
      <div 
        class="w-full flex justify-end space-x-4 overflow-x-auto py-4"
      >
        <div
          v-for="(type_prototype, index) in getTypePrototypeThatHasUnits" 
          :key="`new_units_chart_type_prototype_${type_prototype.id}`"
          class="card-icon-helper flex items-center"
        >
          <PsChartLegend         
            :text="getTypePrototypeAxisTitleByBuildingType(type_prototype)"
            :dot-color="{ backgroundColor: getColors[index] }"
          />
          <a
            :href="INTERCOM_LINK_PER_TYPE_PROTOTYPE_SLUG[type_prototype.slug]"
            target="_blank"
            class="text-opacity-0 flex items-center"
          >
            <PsIcon
              icon="info"
              size="16"
              class="psui-text-gray-40 psui-cursor-pointer"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

const NON_RESIDENTIAL_PROTOTYPE_TITLES = {
  [TYPE_PROTOTYPES_DB_SLUGS.HOSPITAL_AND_OTHER]: 'Hospital',
  [TYPE_PROTOTYPES_DB_SLUGS.HOTELS_AND_MOTELS]: 'Hotels',
  [TYPE_PROTOTYPES_DB_SLUGS.MANUFACTURING_PLANTS]: 'Manufacturing',
  [TYPE_PROTOTYPES_DB_SLUGS.OFFICE_AND_BANK_BUILDINGS]: 'Office',
  [TYPE_PROTOTYPES_DB_SLUGS.SCHOOL_LIBRARIES_AND_LABDS]: 'Schools',
  [TYPE_PROTOTYPES_DB_SLUGS.STORES_AND_RESTAURANTS]: 'Retail'
} 



import { BUILDING_ESTIMATES_BASE_YEARS, BUILDING_ESTIMATES_BASE_YEARS_TIMEFRAME, ASSUMPTIONS_PATHS } from '@/modules/app/assumptions/shared/enums'
import { colors } from '@/business-logic/constants/chartDefaultOptions'
import { MathHelpers } from '@igortrindade/lazyfy'
import { INTERCOM_LINK_PER_TYPE_PROTOTYPE_SLUG } from '@/store/global-entities/TypePrototype.js'
import { STUDY_TYPES_DB_SLUGS, TYPE_PROTOTYPES_DB_SLUGS }from '@/util/Enums.js'

export default {
  name: 'FutureUnitsForecastChart',
  filters:{
    
  },
  props: ['getClimateZoneSelected', 'getTypePrototypesWithUnits', 'paletteColorIndex', 'getBuildingTypeSelected', 'overrideColors'],
  data() {
    return {
      chart: null,
      chartIsLoading: false,
      BUILDING_ESTIMATES_BASE_YEARS,
      BUILDING_ESTIMATES_BASE_YEARS_TIMEFRAME,
      colors,
      path: ASSUMPTIONS_PATHS,
      INTERCOM_LINK_PER_TYPE_PROTOTYPE_SLUG
    }
  },
  computed: {
    getTypePrototypeThatHasUnits() {
      return this.getTypePrototypesWithUnits.map((type_prototype) => {
        const building_estimates_units = this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits']({
            type_prototype_id: type_prototype.id,
            climate_zone_prefix: this.getClimateZoneSelected.map(cz => cz.prefix)
          })
        return { ...type_prototype, building_estimates_units }
      })
      .filter((type_prototype) => type_prototype.building_estimates_units)
    },
    getChartSeries() {
      return this.getTypePrototypeThatHasUnits.map( (type_prototype, index) => {
        return {
          data: BUILDING_ESTIMATES_BASE_YEARS.map((year) => {
            
            const building_estimates_units = this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits']({
              type_prototype_id: type_prototype.id,
              climate_zone_prefix: this.getClimateZoneSelected.map(cz => cz.prefix),
              year
            })
            const total_building_estimates_units_in_year = this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits']({
              type_prototype_id: this.getTypePrototypeThatHasUnits.map((t) => t.id),
              climate_zone_prefix: this.getClimateZoneSelected.map(cz => cz.prefix),
              year
            })

            return {
              x: year,
              y: MathHelpers.getValueOrMinPercentage(this.getAllBuildingEstimatesUnits, parseInt(building_estimates_units), 1),
              fillColor: this.getColors[index],
              strokeColor: this.getColors[index],
              year,
              type_prototype,
              building_estimates_units,
              total_building_estimate_units: this.getAllBuildingEstimatesUnits,
              total_building_estimates_units_in_year,
              percentage: MathHelpers.getPercentageOfAmount(this.getAllBuildingEstimatesUnits, building_estimates_units)
            }
          })
        }
      })
    },

    getAllBuildingEstimatesUnits() {
      return this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits']({
        climate_zone_prefix: this.getClimateZoneSelected.map(cz => cz.prefix),
        type_prototype_id: this.getAllTypePrototypeByBuildingTypeSelected,

      })
    },

    getAllBuildingStockUnits() {
      return this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
        climate_zone_prefix: this.getClimateZoneSelected.map(cz => cz.prefix),
        type_prototype_id: this.getAllTypePrototypeByBuildingTypeSelected 
      })
    },
    getAnnualGrowthRate() {
      const yearLength = BUILDING_ESTIMATES_BASE_YEARS.length ?? 3
      const percentage = (this.getAllBuildingEstimatesUnits / yearLength) // This number should always devided by the number of years!
      return this.getAllBuildingStockUnits && MathHelpers.getPercentageOfAmount(this.getAllBuildingStockUnits, percentage, true)
    },
    getDrawerPath(){
      return this.$store.state.assumptions.drawerOptions.path
    },
    getAllTypePrototypeByBuildingTypeSelected(){
      if(!this.getBuildingTypeSelected) {
        return []
      } else {
        return this.$store
          .getters['globalEntities/TypePrototype/getterGlobalTypePrototypes']({ building_type_id : this.getBuildingTypeSelected.id })
          .map(type_prototype => type_prototype.id)
      }
    },
    getTitleByBuildingTypeSelected(){
      return this.getBuildingTypeSelected.slug ===  this.path.RESIDENTIAL_BUILDINGS.slug ? "" : `(${this.getUnitOfMeasurementByBuildingTypeSelected})`
    },
    getUnitOfMeasurementByBuildingTypeSelected(){
      return this.getBuildingTypeSelected.slug ===  this.path.RESIDENTIAL_BUILDINGS.slug ? "" : "ft²"
    },
    getColors() {
      return this.overrideColors || colors
    }
  },
  methods: {
    getChartOptions() {
      const self = this
      return {
        height: 320,
        series: this.getChartSeries, 
        chart: {
          type: 'bar',
        },
        xaxis: {
          labels: {
            formatter: function(value) {
              if(!BUILDING_ESTIMATES_BASE_YEARS.includes(Math.ceil(value))) return ''
              return Math.ceil(value)
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              return self.formatUnits(value)
            }
          },
        },
        tooltip: {
          custom: function ({ seriesIndex, dataPointIndex }) {
            const item = self.getChartSeries[seriesIndex].data[dataPointIndex]
            const unitsOrSqft = self.getBuildingTypeSelected.slug === self.path.RESIDENTIAL_BUILDINGS.slug ? 'Units' : 'ft²'
            
            return `
              <div class="rounded-sm psui-shadow-elevation-30 flex" style="background-color: ${ item.fillColor } !important;">
                <div class="w-full bg-white p-4 ml-2 flex flex-col space-y-2">
                  <h4 class="psui-text-gray-80 psui-text-xsmall font-bold">Year: ${ item.year }</h4>
                  <div class="w-full flex justify-between space-x-2">
                    <h2 class="psui-text-gray-80 psui-text-small" >${ self.formatUnits(item.building_estimates_units) } ${ unitsOrSqft }</h2>
                    ${
                      // (!item.type_prototype || item.type_prototype.building_stock_units === 0) ? '' :
                      // `
                      //   <div class="psui-bg-gray-30 vertical-divider block py-2"></div>
                      //   <h2 class="psui-text-gray-50 psui-text-small">${ self.formatNumber(MathHelpers.getPercentageOfAmount(item.type_prototype.building_stock_units, item.building_estimates_units)) }%</h2>
                      // `
                      `
                        <div class="psui-bg-gray-30 vertical-divider block py-2"></div>
                        <h2 class="psui-text-gray-50 psui-text-small">${ self.formatNumber(MathHelpers.getPercentageOfAmount(item.total_building_estimates_units_in_year, item.building_estimates_units)) }%</h2>
                       `
                    }
                  </div>
                  <h4 class="psui-text-gray-40 psui-text-xsmall font-bold">${ self.getTypePrototypeAxisTitleByBuildingType(item.type_prototype)}</h4>
                </div>
              </div>
            `
          }
        }
      }
    },
    openAssumptionsDrawer() {
      this.$store.dispatch('setUserLastStudyTypeVisited', { value: STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS, context: 'updateLastStudyTypeVisitedFromFutureUnitsForecastChart'})
      this.$store.commit('assumptions/openDrawerAtBuildingEstimatesResidentialNew', this.getBuildingTypeSelected)
      // this.$store.commit('assumptions/updateDrawerIsEditingFromChart', true)
    },
    getIntercomLinkFromTypePrototypeSlug(type_prototype_slug){
      return INTERCOM_LINK_PER_TYPE_PROTOTYPE_SLUG[type_prototype_slug]
    },
    getTypePrototypeAxisTitleByBuildingType(type_prototype) {
      return this.getBuildingTypeSelected.slug ==  this.path.RESIDENTIAL_BUILDINGS.slug ? type_prototype.title : NON_RESIDENTIAL_PROTOTYPE_TITLES[type_prototype.slug]
    },
  }
}
</script>

<style scoped>
  ::v-deep .apexcharts-tooltip.apexcharts-theme-light {
    border: none !important;
  }

  .card-icon-helper .text-opacity-0 {
    opacity: 0;      
  }

  .card-icon-helper:hover .text-opacity-0 {
    opacity: 1;
  }

</style>
