export default class Collection {
  constructor(obj = {}) {
    this.id = obj.id || null
    this.jurisdiction_collection_id = obj.jurisdiction_collection_id || null
    this.jurisdiction_id = obj.jurisdiction_id || null
    this.climate_zone_prefix = obj.climate_zone_prefix || null
    this.row_number = obj.row_number || null
    this.percentage = obj.percentage || null
  }
}
