<template>
  <div class="flex items-center">
    <div class="mr-1/2 psui-text-small psui-font-bold psui-text-gray-80 whitespace-nowrap">
      {{ climateZonesTitle }}
    </div>
    <div 
      class="psui-flex psui-flex-wrap psui-gap-1"
      @click="IsTooltipOn($event)"
      @mouseleave="IsTooltipOn($event)"
    >
      <PsChips 
        v-for="climate_zone in climateZones"
        :key="`dropdown-climate_zone-${climate_zone.id}`"
        v-tooltip="{
          content: checkClimateZoneCouldBeToggled(climate_zone), 
          trigger: 'manual',
          show: isOpen
        }"
        type="checkbox"
        :disabled="checkClimateZoneCouldBeToggled(climate_zone) ? true : false"
        :label="`${climate_zone.prefix}`"
        :checked="!checkQueryKeyValue('exclude_climate_zone_prefix', climate_zone.prefix)"
        @change="onUpdateClimateZone(climate_zone)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BuildingEstimateFilterClimateZone',
  data(){
    return {
      isOpen: false,
    }
  },
  computed: {
    climateZones() {
      if(this.$store?.getters['lastJurisdictionVisited']?.climate_zones) {
        const climateZones = [...this.$store?.getters['lastJurisdictionVisited']?.climate_zones]
        return climateZones.length > 0 ? climateZones.sort((a,b) => a.prefix - b.prefix) : []
      } else {
        return []
      }
    },
    climateZonesTitle() {
      return (this.climateZones.length === 1) ? 'Climate Zone' : 'Climate Zones'
    }
  },
  mounted() {
    this.updateLastClimateZonesVisited()
  },
  methods: {
    onUpdateClimateZone(climate_zone) {
      if (!this.checkClimateZoneCouldBeToggled(climate_zone)) {
        this.toggleQueryKeyValue('exclude_climate_zone_prefix', climate_zone.prefix)
        this.updateLastClimateZonesVisited()        
      }  
    },
    updateLastClimateZonesVisited() {
      const activeClimateZones = this.climateZones.filter(cz => {
        return !this.checkQueryKeyValue('exclude_climate_zone_prefix', cz.prefix)
      })
      this.$store.dispatch('setUserLastClimateZonesVisited', { value: activeClimateZones, context: 'updateLastClimateZonesVisitedFromBuildingEstimates'})
    },
    checkClimateZoneCouldBeToggled(climate_zone) {
      if(this.checkQueryKeyValue('exclude_climate_zone_prefix', climate_zone.prefix)) return false
      if(this.getQueryKeyItems('exclude_climate_zone_prefix').length < this.climateZones.length-1) return false
      return `You must have at least one Climate Zone selected.`
    },
    IsTooltipOn(event){
      if(event.type === 'mouseleave') this.isOpen = false
      if(event.type === 'click') this.isOpen = !this.isOpen
    }
  }
}
</script>