<template>
  <BaseModal
    :key="comparison.id"
    ref="baseModal"
    modal-dialog-classes="psui-w-full psui-p-8 psui-rounded-md"
    title-classes="psui-w-full psui-text-h4 psui-font-bold psui-text-left psui-text-gray-80 psui-tracking-wide"
    title="Comparison details"
    @close="close"
  >
    <div class="w-full mt-4">
      <PsInput
        v-model="comparison.title"
        data-test-id="modal-comparison-edit-details-name-input"
        label="Name"
        class="psui-w-full psui-mb-4"
        :active="comparison.title ? true : false"
        :value="comparison.title"
        :validation="validation.title"
        :has-error="validation.title.label"
        @input="comparison.title = $event.target.value"
      >
        <template #hint>
          {{ validation.title.label }}
        </template>
      </PsInput>
        
      <PsInputTextArea
        v-model="comparison.description"
        :data-test-id="`modal-comparison-edit-details-description-input-${comparison.description}`"
        label="Description"
        class="psui-mb-5"
        :active="comparison.description ? true : false"
        :rows="6"
        :value="comparison.description"
        @input="comparison.description = $event.target.value"
      />
      
      <div class="psui-flex psui-space-x-2 psui-pt-6">
        <PsButton
          size="big" 
          label="Done"
          data-test-id="modal-comparison-edit-details-done-button"
          @click="submit()"
        />
        <PsButton
          data-test-id="modal-comparison-edit-details-cancel-button"
          size="big" 
          label="Cancel"
          layout="onlytext"
          @click="close()"
        />
      </div>
    </div>
  </BaseModal>
</template>

<script>
import ComparisonApiService from '@/services/api/ComparisonApiService.js'
import Comparison from '@/models/Comparison'

export default {
  name: 'ModalComparisonEditDetails',
  data() {
    return {
      comparison: new Comparison(),
      shouldRedirect: true,
      validation: {
        title: {
          hasError: false,
          label: ''
        },
        description: {
          hasError: false,
          label: ''
        },
      }
    }
  },
  computed: {
    isEditing() {
      return this.comparison?.id ?? null
    }
  },
  mounted() {
    this.$eventBus.$on('openModalComparisonEditDetails', (comparison_id = null, shouldRedirect = true) => {
      this.comparison.id = comparison_id
      this.getComparison()
      this.shouldRedirect = shouldRedirect
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openModalComparisonEditDetails')
  },
  methods: {
    close() {
      this.$refs.baseModal.showModal = false
    },
    getComparison() {
      const query = `
        {
          comparison (findBy: { column: "id", value: "${this.comparison.id}" }) {
            id
            title
            description
            user_id
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.comparison = data.comparison
          this.$refs.baseModal.showModal = true
        })
    },
    submit() {      
      this.validationClear('title')
      
      if (!this.comparison.title || this.comparison.title.length <= 3) {
        this.validation.title.hasError = true
        this.validation.title.label = 'Please add a comparison name at least three characters.'
        return 
      }
      
      this.storeComparison()
    },
    storeComparison() {
      ComparisonApiService.update(this.comparison.id, this.comparison)
        .then(() => {
          this.$refs.baseModal.showModal = false
          if(this.shouldRedirect) this.$router.push({ name: 'ComparisonShow', params: { comparison_id : this.comparison.id }}, () => {})
          this.$store.dispatch('comparison/getUserComparison', { comparison_id: this.comparison.id })
          this.comparison = new Comparison()
          this.$toast.success('Comparison successfully updated!')
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .modal-dialog {
    max-width: 26.25rem;
  }
</style>