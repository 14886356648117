<template>
  <div class="flex flex-col">
    <span class="psui-text-gray-70 psui-bg-gray-10">{{ title }}</span>
    <div
      class="flex flex-col psui-bg-white psui-overflow-auto psui-mr-4 max-w-full"
    >
      <table>
        <thead>
          <tr>
            <th class="py-2">
              <div
                v-if="firstColumnTitle"
              >
                <p class="psui-text-small psui-font-bold psui-gray-text-80">
                  {{ firstColumnTitle }}
                </p>
                <p class="psui-text-xsmall psui-text-gray-50">
                  {{ firstColumnDescription }}
                </p>
              </div>
            </th>

            <template v-for="columnGroup of getEditRequirementsColumns">
              <th 
                v-for="column of columnGroup.columns" 
                :key="`${columnGroup.key}-${column.key}`"
                class="py-2" 
              >
                <div class="psui-flex psui-flex-col">
                  <p
                    v-if="column.title"
                    class="psui-text-small psui-font-bold psui-gray-text-80"
                  >
                    {{ column.title }}
                  </p>
                  <p
                    v-if="column.description"
                    class="psui-text-xsmall psui-text-gray-50"
                  >
                    {{ column.description }}
                  </p>
                </div>
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in totalImpactMeasures"
            :key="`item-${title}-${item.measure_id}`"
          >
            <td
              class="psui-bg-white psui-text-gra-80 z-15"
              :class="{'psui-bg-blue-10 psui-text-blue-60 psui-font-bold':getIndexId(item.measure_id)}"
            >
              <div class="psui-text-small">
                {{ item.measure.title }}
              </div>
            </td>
            <template v-for="columnGroup of getEditRequirementsColumns">
              <td
                v-for="(column,index) in columnGroup.columns"
                :key="`items-${columnGroup.key}-${column.key}-${index}`"
                class="psui-bg-white psui-text-gray-80"
                :class="{'psui-bg-blue-10 psui-text-blue-60':getIndexId(item.measure_id)} "
              > 
                <div
                  v-if="column.isChart && item.data[column.key] != null"
                  class="flex items-center space-x-2 justify-end"
                >
                  <span>
                    {{ getItemContent(column,item) }}
                  </span>
                  <PsProgressBar
                    :value="item.data[column.key] == '--' ? 0 : item.data[column.key]"
                    :force-break-even="item.data[column.key] === '--' ? true : false"
                  />
                </div>
                <div
                  v-else
                >
                  {{ getItemContent(column,item) }}
                </div>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import formatStudyData from '@/formatters/formatStudyData'

  export default {
    name:'PolicyExistingDetailedFLexibleScoreTable',
    props:['firstColumnTitle','firstColumnDescription','totalImpactMeasures','title', 'maxWidth','indexes', 'affectedUnitiesNormalizer'],
    computed:{
      getEditRequirementsColumns(){
        return this.$store.getters['requirements/getterPolicyExistingBuildingsColumns'].columnGroups.map(cg => {
          return {
            ...cg,
            columns:cg.columns.filter(item => item.isActive)
          }
        }).filter(cg => cg.key !== "measure_list")
      }
    },
    methods:{
      getItemContent(column, item){
        let data = item.data?.[column.key]
        if (column?.key === 'forecast_units_affected' && this.affectedUnitiesNormalizer !== undefined && !isNaN(this.affectedUnitiesNormalizer)) {
          data = data * this.affectedUnitiesNormalizer / 100
        }
        return formatStudyData(column?.key, data, item?.data)
      },
      getIndexId(index){
        return this.indexes ? this.indexes.find(id => id == index ) : false
      }
    }
  }
</script>

<style lang="scss" scoped>
    th {
      &:first-of-type {
        background-color: white;
        position: sticky;
        left: 0;
        padding-left: 16px;

        div {
          display: flex;
          flex-direction: column;
          min-width: 300px;  
          text-align: left;    
        }
      }

      div {
        display: flex;
        flex-direction: column;
        min-width: 255px;
        padding-right: 15px;
        text-align: right;
      }
    }
  
  td {
    padding: 12px 0px;
    border-top: 1px solid #F3F6F9;
    &:first-of-type{
      position: sticky;
      left: 0;
      padding-left: 16px;
      
      div {
        min-width: 300px;
        text-align: left;
      }
    }

    div {
      padding-right: 16px;
      text-align: right;
    }
  }

</style>