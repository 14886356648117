<template>
  <BaseModal 
    ref="baseModal"
    :title="`${user.email} events`"
    modal-dialog-classes="max-w-lg"
    @close="close"
  >
    <div class="w-full overflow-x-auto mt-4 max-h-1/2 relative">
      <table class="table table-bordered table-hover table-striped sticky top-0">
        <thead>
          <tr class="sticky top-0 bg-white">
            <th>Event</th>
            <th>Info</th>
            <th>Created at</th>
            <th>App Version</th>
            <th>Device Id</th>
            <th>Device Platform</th>
            <th>Device Os</th>
            <th>Device Browser</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="user_event in user_events"
            :key="`user_event_${user_event.id}`" 
            class="whitespace-nowrap"
          >
            <td>{{ user_event.event }}</td>
            <td>{{ user_event.notes }}</td>
            <td>{{ user_event.created_at }}</td>
            <td>{{ user_event.app_version }}</td>
            <td>{{ user_event.user_device.id }}</td>
            <td>{{ user_event.user_device.platform }}</td>
            <td>{{ user_event.user_device.os }}</td>
            <td>{{ user_event.user_device.browser }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </BaseModal>
</template>

<script>
import User from '@/models/User'
export default {
  name: "AdminUserEventModalShow",
  data() {
    return {
      user: new User(),
      user_events: []
    }
  },
  mounted() {
    this.$eventBus.$on('openUserEventModal', (user) => {
      this.user = user
      this.$refs.baseModal.showModal = true
      if(user.id) this.getUserEvents()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openUserEventModal')
  },
  methods: {

    getUserEvents() {
      const query = `
        {
          user_events (
            where: [
              { column: "user_id", value: "${this.user.id}" }
            ]
            orderBy: [
              { column: "created_at", order: "DESC" }
            ]
          ) {
            id
            user_id
            device_id
            table
            table_item_id
            event
            notes
            app_version
            created_at
            ip
            user_device {
              id
              uid
              os
              platform
              browser
              is_mobile
              session_count
              last_session_at
            }
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.user_events = data.user_events
        })
    },

    close() {
      this.user = new User()
      this.user_events = []
      this.$refs.baseModal.showModal = false
    }
  }
}
</script>

<style>

</style>