import BaseModel from './BaseModel'

export default class Measure extends BaseModel {

  constructor(obj = {}) {
    super()
    this.id = obj.id || null
    this.study_id = obj.study_id || null
    this.title = obj.title || ''
    this.title_long = obj.title_long || ''
    this.title_abbreviated = obj.title_abbreviated || ''
    this.slug = obj.slug || ''
    this.description = obj.description ||''
    this.content = obj.content || ''
    this.icon = obj.icon || ''
    this.include_in_combination = obj.include_in_combination || false
    this.measures = obj.measures || []
    this.exclude_measures = obj.exclude_measures || []
    this.exclude_in_combination_reason = obj.exclude_in_combination_reason || ''
    this.exclude_in_custom_combination_presets = obj.exclude_in_custom_combination_presets || []
    this.type_prototype_id = obj.type_prototype_id || ''
    this.type_vintage_id = obj.type_vintage_id || ''
    this.type_measure_id = obj.type_measure_id || ''
    this.type_fuel_id = obj.type_fuel_id || ''
    this.order = obj.order || 999
    this.hide_in_flexible_path = obj.hide_in_flexible_path || false
    this.hide_in_results = obj.hide_in_results || false
    this.flexible_path_title = obj.flexible_path_title || ''
    this.is_package = obj.is_package || false
    this.is_contingent = obj.is_contingent || false
    this.is_other_child_measures = obj.is_other_child_measures || false
    this.is_pre_emptive = obj.is_pre_emptive || false
    this.has_larger_pv_included = obj.has_larger_pv_included || false
    this.hide_in_compliance_margin_control = obj.hide_in_compliance_margin_control || false
    this.compliance_margin_control_title = obj.compliance_margin_control_title || ''
    
    //Relationships
    this.measures = obj.measures || []
    this.type_measures = obj.type_measures || []
    this.study = obj.study || false
  }

}
