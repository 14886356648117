import Api from '@/util/Api'
import GraphQL from '@/util/GraphQL'

export default class DefaultAssumptionInputApiService {

  static storeOrUpdate(payload = null) {
    payload = payload ?? this
    return Api.post(`/api/admin/default_assumption_input/update_or_create`, { ...payload })
  }

  static show(default_assumption_input_id = null) {
    default_assumption_input_id = default_assumption_input_id ?? this.id
    const query = `
      {
        default_assumption_input (find: ${default_assumption_input_id}) {
          id
          title
          content
          key
          value
          type_prototype_id
          study_type_id
          created_at
          updated_at
          meta
          type_prototype {
            id
            title
            slug
          }
          study_type {
            id
            title
          }
        }
      }
    `
    return GraphQL({ query })
  }


}