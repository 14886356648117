<template>
  <div 
    :data-test-id="`comparison-show-index-${getComparisonId}`"
    class="psui-w-full psui-flex psui-flex-col psui-px-4 sm:psui-px-8 psui-pb-7"
  >
    <ComparisonPoliciesEmpty
      v-if="!isLoading && !checkIfComparisonHasPolicies"
      :jurisdiction="jurisdiction"
    />
    <SkeletonComparisonTable
      v-else-if="isLoading"
      :comparison-policies-length="getComparisonPoliciesLength"
    />
    <ComparisonTable
      v-else
      :policies="policies"
      @update-policy-order="updatePolicyOrder"
    />
  </div>
</template>

<script>
import ComparisonPoliciesEmpty from './ComparisonPoliciesEmpty.vue'
import ComparisonTable from './ComparisonTable'
import generatePolicyItemsTotal from '@/business-logic/services/policies/generatePolicyItemsTotal'
import { mapGetters } from 'vuex'
import SkeletonComparisonTable from '../skeleton/SkeletonComparisonTable'
import ComparisonApiService from '@/services/api/ComparisonApiService'
import Jurisdiction from '@/models/Jurisdiction.js'

export default {
  name: 'ComparisonShowIndex',
  components: {
    ComparisonTable,
    SkeletonComparisonTable,
    ComparisonPoliciesEmpty
  },
  metaInfo() {
    const title = this.jurisdiction ? `Comparing ${this.jurisdiction?.titles.title_type}'s policies | ` : `Compare policies`
    return {
      title: title,
      meta: [
        { vmid: 'og:title', property: 'og:title', content: `Comparing ${this.jurisdiction?.titles.title_type}'s policies` }
      ]
    }
  },
  data: () => ({
    comparison: null,
    policies: [],
    log: false,
    policies_ids: false,
    isLoading: false,
  }),
  computed: {
    jurisdiction() {
      return this.jurisdictions ? this.jurisdictions.find((jur) => parseInt(jur.id) === parseInt(this.$route.query.jurisdiction_id)) : false
    },
    jurisdictions() {
      return this.$store.getters['comparison/getterAvailableJurisdictions'] ?? []
    },    
    getUserComparisons() {
      return this.$store.getters['comparison/getterUserComparisons']()
    },
    getLastJurisdictionVisitedId(){
      return this.lastJurisdictionVisited.id
    },
    checkIfComparisonHasPolicies() {
      return !!this.comparison?.policies.length
    },
    getComparisonPoliciesLength(){
      return this.comparison?.policies.length
    },
    getDeviceInfo(){
      return this.$store.getters['general/getterDeviceInfo']
    },
    getComparisonId(){
      return this.comparison?.id
    },
    getUserPolicies(){
      return this.$store.getters['policy/getterUserPolicies']()
    },
    ...mapGetters(['getterLoggedUser','lastJurisdictionVisited']),
  },

  async mounted() {
    await this.loadData()
    this.$eventBus.$on('comparisonUpdated', () => {
      this.isLoading = true
      this.loadData()
    })

    this.logUserEvent()
  },
  beforeDestroy() {
    this.$eventBus.$off('comparisonUpdated')
  },
  methods: {    
    async loadData() {
      this.isLoading = true
      this.comparison = await ComparisonApiService.getById(this.$route?.params?.comparison_id)
      if (!this.comparison || this.comparison.deleted_at) {
        this.$toast.info(
          "Either this policy does not exist, or it has been deleted by the owner. We're going to update your policy's list for you!",
          { duration: 10000 }
        )
        this.$eventBus.$emit('policyDeletedVisited')

        return this.$router.push({
          name: 'ComparisonList'
        })
      }
      if((this.comparison && !this.getterLoggedUser && this.getDeviceInfo.id !== this.comparison.user_device_id) || (this.comparison && this.getterLoggedUser?.id !== this.comparison?.user_id)) {
        await this.$store.dispatch('comparison/addOrUpdateComparisonToUserOrSharedLists', { comparison: this.comparison })
      }
      await this.getComparisons()
      this.isLoading = false
    },

    updatePolicyOrder({ from, to }) {
      this.policies.splice(to, 0, this.policies.splice(from, 1)[0])
      let routePolicyIds = this.$route.query.policies_ids.split(',')
      routePolicyIds.splice(to, 0, routePolicyIds.splice(from, 1)[0])
    },

    async getComparisons() {
      this.policies = []
      if(!this.comparison.policy_ids) return

      this.comparison.policy_ids.split(',')
      .forEach((policy_id)=>{
        if(!this.getUserPolicies.find(policy => policy.id == policy_id)) {
          this.$store.dispatch('assumptions/getPolicy',policy_id)
        } else {
          this.$store.dispatch('setUserLastJurisdictionAndFirstClimateZoneVisited', 
            { value: new Jurisdiction(this.comparison.jurisdiction), context: 'updateLastJurisdictionVisitedFromComparisonShow' }, 
            { root: true }
          )
        }
      })


      return this.$api.get(`/api/comparison/get?policy_ids=${this.comparison.policy_ids}`)
        .then(({ data }) => {
          const policies = data.policies.map((policy) => {
            generatePolicyItemsTotal({ policy, items: policy.policy_summary_data })
            return policy
          })
          this.policies = policies
          this.isLoading = false
          return this.updateAssumptionsGroupColumnsIfPoliciesAreDifferent()
        })
    },
    
    async updateAssumptionsGroupColumnsIfPoliciesAreDifferent() {
      return this.$store.dispatch('comparison/setComparisonAssumptionsGroupColumns', { policies: this.policies })
    },

    logUserEvent() {
      this.$logUserEvent({ event: 'COMPARISON_SHOW', table: 'comparisons', table_item_id: this.$route?.params?.comparison_id })
    },
 }
}
</script>
