<template>
  <div class="w-full h-full flex flex-col">
    <h4 class="psui-text-h5 psui-text-gray-80 font-bold whitespace-nowrap">
      Type of device
    </h4>
    <div class="block w-full h-full">
      <ApexChartGeneral 
        :options="getChartOptions()"
        :palette-color-index="0"
      />
    </div>
  </div>
</template>

<script>
import { colors } from '@/business-logic/constants/chartDefaultOptions'
import MathFunctions from '@/util/MathFunctions'
export default {
  name: 'AdminDashboardMobileInfo',
  props: ['timeFrameSelected'],
  data() {
    return {
      colors,
      mobile_info: [],
    }
  },
  computed: {
    getDevicesTotal() {
      return this.mobile_info.reduce((acc, item) => acc + parseInt(item.device_count), 0)
    },
    getSessionsTotal() {
      return this.mobile_info.reduce((acc, item) => acc + parseInt(item.session_count), 0)
    },
  },
  watch: {
    timeFrameSelected: {
      deep: true,
      handler() {
        this.getData()
      } 
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$api.get('/api/admin/dashboard/get_data/mobile_info', { params: {
        init: this.timeFrameSelected.init, 
        end: this.timeFrameSelected.end 
      }})
        .then(({ data }) => {
          this.mobile_info = data.mobile_info
        })
    },
    getChartOptions() {
      const self = this
      return {
        series: this.mobile_info.map((item) => parseInt(item.device_count)),
        chart: {
          type: 'donut',
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: true
            }
          }
        },
        colors: colors,
        tooltip: {
          custom: function ({ seriesIndex }) {
            const item = self.mobile_info[seriesIndex]
            return `
              <div class="psui-flex" style="background-color: ${ colors[seriesIndex] } !important;">
                <div class="apexcharts-tooltip-content psui-w-full psui-bg-white psui-p-4">
                  <h4 class="psui-text-gray-80 psui-text-xsmall psui-font-bold">${ item.is_mobile ? 'Mobile' : 'Desktop' }</h4>
                  <div class="psui-w-full psui-flex psui-items-center justify-between space-x-2">
                    <h2 class="psui-text-gray-80 psui-text-small" >${ self.formatUnits(item.device_count) } Devices</h2>
                    <span class="psui-text-gray-30 psui-mx-1">|</span>
                    <h2 class="psui-text-gray-50 psui-text-small">${ self.formatNumber(MathFunctions.getPercentageOfAmount(self.getDevicesTotal, item.device_count)) }%</h2>
                  </div>
                  <div class="psui-w-full psui-flex psui-items-center justify-between space-x-2">
                    <h2 class="psui-text-gray-80 psui-text-small" >${ self.formatUnits(item.session_count) } Sessions</h2>
                    <span class="psui-text-gray-30 psui-mx-1">|</span>
                    <h2 class="psui-text-gray-50 psui-text-small">${ self.formatNumber(MathFunctions.getPercentageOfAmount(self.getSessionsTotal, item.session_count)) }%</h2>
                  </div>
                </div>
              </div>
            `
          }
        }
      }
    }

  }
}
</script>
