<template>
  <div 
    ref="tooltip"
    v-click-outside="close"
    class="app-checkbox inline-block text-left tooltip-wrapper"
    :class="[id]"
    @mouseover="open()"
  >
    <div ref="tooltipTrigger">
      <slot name="trigger" />
    </div>

    <div class="relative">
      <div
        ref="dialog"
        role="menu"
        class="dialog hidden origin-top-right fixed w-auto rounded shadow-lg z-50 opacity-0 transition duration-300 ease-in-out" 
        aria-orientation="vertical" 
        :class="[dialogClasses]"
        :style="{ minWidth: minWidthDropdown, marginLeft: marginLeft }"
      >
        <div class="w-full">
          <span
            v-if="arrow"
            class="arrow"
            :style="arrow"
          />
          <h2
            v-if="title"
            class="text-gray02 font-bold whitespace-nowrap mb-4 ts--accent--1"
          >
            {{ title }}
          </h2>
          <slot name="dialog" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { randomLetters, getParentScrollableEl } from '@/util/Functions'

export default {
  name: 'ToolTip',
  props: {
    buttonClasses: {
      type: String,
      default: 'bg-white border border-blue'
    },
    title: {
      type: String,
    },
    dialogClasses: {
      type: String,
      default: 'p-4 left-0 bg-white border border-blue05 max-w-xs'
    },
    minWidthDropdown: {
      type: [String, Number],
    },
    maxWidthDropDown: {
      type: String,
      default: '240px'
    },
    ignoreDialog: {
      default: false
    },
    arrow: {
      type: [Boolean, Object],
      default: false
    },
    keepOpen: {
      default: false
    }
  },
  data() {
    return {
      show: false,
      id: '',
      marginLeft: '-0px',
      scrollableParentEl : null ,
      closingTimeout: null,
    }
  },
  computed: {
    getMaxWidth() {
      let bounds = this.$refs.tooltip.getBoundingClientRect()
      return (document.documentElement.clientWidth - bounds['left']) -30
    }
  },
  mounted() {
    this.id = randomLetters(6)
    document.addEventListener("keyup", this.handleEsc)
    window.addEventListener("resize", this.updatePosition)
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleEsc)
    document.removeEventListener("resize", this.updatePosition)
    window.addEventListener("mousemove", this.handleMouseMoveOnWindow)
    this.unwatchParentScrolling()
  },
  methods: {
    toggle() {
      if (!this.show) {
        this.open()
      } else {
        this.close()
      }
    },
    open() {
      if(this.show || this.ignoreDialog) return
      this.$emit('open')
      this.show = true
      this.$refs.dialog.style.opacity = 0
      this.$refs.dialog.style.display = 'block'
      setTimeout(() => {
        this.updatePosition()
        this.watchParentScrolling()
        window.addEventListener("mousemove", this.handleMouseMoveOnWindow)
      }, 10)
    },

    close() {
      if (this.show && this.$refs.dialog) {
        this.$emit('close')
        this.$refs.dialog.style.display = 'none'
        this.$refs.dialog.style.opacity = 0
        this.show = false
      }
      this.unwatchParentScrolling()
      window.removeEventListener("mousemove", this.handleMouseMoveOnWindow)
    },

    handleMouseMoveOnWindow($event) {
      if($event.target.closest(`.${this.id}`) || this.keepOpen) {
        clearTimeout(this.closingTimeout)
      } else {
        this.startTimeout()
      }
    },

    startTimeout() {
      clearTimeout(this.closingTimeout)
      this.closingTimeout = setTimeout(() => {
        this.close()
      }, 10)
    },

    handleEsc(evt) {
      if (this.show && evt.keyCode === 27) this.close()
    },

    watchParentScrolling() {
      this.scrollableParentEl = getParentScrollableEl(this.$refs.tooltip)
      if (this.scrollableParentEl) {
        this.scrollableParentEl.addEventListener('scroll', this.updatePosition)
      }
    },

    unwatchParentScrolling() {
      if (this.scrollableParentEl) {
        this.scrollableParentEl.removeEventListener('scroll', this.updatePosition)
      }
    },
    
    updatePosition() {
      
      const dialog = this.$refs.dialog
      const dropdownTrigger = this.$refs.tooltipTrigger
      if (!dialog || !dropdownTrigger) return 

      const rectTrigger = dropdownTrigger.getBoundingClientRect()
      const rectDialog = dialog.getBoundingClientRect()
      const windowWidth = document.documentElement.clientWidth

      dialog.style.position = 'fixed'
      dialog.style.top = `${rectTrigger.y + rectTrigger.height }px`
      
      if (( rectTrigger.x + rectDialog.width + 20 ) > windowWidth ) {
        dialog.style.left = `${windowWidth - rectDialog.width - 30}px`
      } else {        
        dialog.style.left = `${rectTrigger.x}px`
      }

      if(rectTrigger.top < 40) {
        this.close()
        return
      }

      setTimeout(() => { dialog.style.opacity = 100 }, 100)

    },
    
  }
}
</script>

<style scoped lang="scss">
  .arrow {    
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 10px 7px;
    border-color: transparent transparent transparent transparent;
    top: -10px;
  }

</style>