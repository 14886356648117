import EventBus from '@/util/EventBus'

export default (Store) => {
  Store.subscribe((mutation) => {

    if(mutation.type === 'setLoggedUser' ) {
      Store.dispatch('assumptions/initBuildingsData')
      // might check if this it is really necessary since checkBuildingStockAndEstimateConflict is doing the same thing if there is or there is not conflict 
      // Store.dispatch('assumptions/getAllCustomBuildings')
      EventBus.$emit('checkBuildingStockAndEstimateConflict')
      Store.dispatch('general/checkDeviceInfo')
    }
    
    if(mutation.type === 'setLogout' ) {
      Store.dispatch('assumptions/clearCustomBuildings')
      Store.commit('policy/resetPolicyState')
      Store.commit('comparison/resetComparisonState')
    }
   
  })
}