<template>
  <div class="h-full psui-w-full psui-flex psui-flex-col psui-justify-center overflow-hidden">
    <div class="flex flex-col items-center">
      <img 
        :src="`/images/policy_wizard/step_manual_finish.png`"
        class="card-image psui-mx-auto" 
      >
      <p class="text-20 psui-text-blue-80">
        Please select or add a climate zone or building type to your policy!
      </p>
    </div>
  </div>
</template>

<script>
export default {
    name: 'PolicyEditRequirementsInfoError'
  }
</script>

<style lang="scss">
    .app-drawer-content {
      height: 100%;
    }
</style>