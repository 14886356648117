import getProjectionsResume from '@/business-logic/services/projections/getProjectionsResume'

export default ({ impactAssumptions, data, building_stock_units }) => {

  const projectionsCityWideImpactValues = getProjectionsResume({ 
    assumption: impactAssumptions,
    building_stock_units: building_stock_units,
    kwh_savings: data.kwh_savings,
    therms_savings: data.therms_savings,
    annual_bill_savings: data.annual_bill_savings,
    initial_cost: data.initial_cost,
    subsidy_needed: data.subsidy_needed,
    five_year_payback: data.five_year_payback,
    subsidy_needed_care: data.subsidy_needed_care,
    five_year_payback_care: data.five_year_payback_care,
  })

  Object.keys(projectionsCityWideImpactValues).map((col) => {
    data[col] = projectionsCityWideImpactValues[col]
  })

  return projectionsCityWideImpactValues

}
