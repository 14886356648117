import formatComplianceMarginAsText from '@/formatters/formatComplianceMarginAsText'
import FiltersMixin from '@/mixins/FiltersMixin.js'
import { TYPE_FUELS_DB_SLUGS } from "@/util/Enums"
import Store from '@/store'
import { FUEL_CHOICES } from '@/business-logic/services/impact-algorithms'
import { GeneralPerformanceByFuelPolicyImpactAlgorithm } from '@/business-logic/services/impact-algorithms/policy/general-performance-by-fuel'

export const formatComplianceMarginDropdown = (study_datas, fuelSlug) => {
  return [
    ...study_datas.map(study_data_object => {
      const study = Store.getters['globalEntities/Study/getterGlobalStudy']({ id: study_data_object.study_data.study_id })
      const complianceMarginKey =  study.compliance_margin_key || 'compliance_margin'
      const left_label = `${formatComplianceMarginAsText(study_data_object.study_data[complianceMarginKey])}`
      const measureTitle = study_data_object.measure.compliance_margin_control_title ?? study_data_object.measure.title
      const right_label = `${measureTitle}`
      const compliance_margin_measure_id = study_data_object.measure.id
      const compliance_margin_value = study_data_object.study_data[complianceMarginKey]
      const compliance_margin_value_formatted = FiltersMixin.methods.formatComplianceMargin(compliance_margin_value)
      const goToAdminEditRoute = { name: 'AdminStudyItemEdit', params: { study_item_id: compliance_margin_measure_id, type: 'measure' }}
      return { 
        left_label, 
        right_label, 
        value: compliance_margin_measure_id, 
        compliance_margin_measure_id, 
        compliance_margin_value,
        goToAdminEditRoute,
        compliance_margin_value_formatted,
        left_label_formatted: left_label
      }
    }),
    { 
      left_label: formatComplianceMarginAsText(0), 
      left_label_formatted: formatComplianceMarginAsText(0),
      right_label: fuelSlug === TYPE_FUELS_DB_SLUGS.MIXED_FUEL ? 'Mixed Fuel (State Performance Minimum)' : 'Electrification (State Performance Minimum)',
      value: 0,
      compliance_margin_measure_id: 0,
      compliance_margin_value: 0,
      default: true,
      compliance_margin_value_formatted: 0
    }
  ]
}


export function formatComplianceMarginInfo(customCombinations = []) {
  let customCombinationsArray = []

  if (typeof customCombinations === 'object' && !Array.isArray(customCombinations)) {
    customCombinationsArray = [customCombinations]
  } else {
    customCombinationsArray = customCombinations
  }

  if (!customCombinationsArray || customCombinationsArray.length === 0) {
    return {
      isAllElectricConstructionRequired: false,
      hasSomeVirtualComplianceMargin: false,
      isComplianceMarginUnique: false,
      complianceMargins: [],
    }
  }

  const isAllElectricConstructionRequired = (customCombinationsArray || []).some((cc) => {
    return cc?.meta?.fuel_choice && cc.meta.fuel_choice !== FUEL_CHOICES.ALLOWED
  })

  const hasSomeVirtualComplianceMargin = (customCombinationsArray || []).some((cc) => {
    return (
      cc?.meta?.generate_virtual_compliance_margin &&
      !isNaN(cc.meta.generate_virtual_compliance_margin) &&
      cc.meta.generate_virtual_compliance_margin > 0
    )
  })

  const complianceMargins = [...new Set((customCombinationsArray || [])
      .map((cc) => cc?.meta?.compliance_margin_value ? +cc.meta.compliance_margin_value : null)
      .filter((cm) => cm !== null) 
    )
  ]

  const requireDifferentComplianceMargins = (customCombinationsArray || []).some((cc) => {
    return (cc?.meta?.require_different_compliance_margins === true)
  })

  const ignoreRequireDifferentComplianceMargins = (customCombinationsArray || []).every((cc) => {
    return (cc?.meta?.require_different_compliance_margins === undefined)
  })

  const isComplianceMarginUnique = !isAllElectricConstructionRequired && (ignoreRequireDifferentComplianceMargins || !requireDifferentComplianceMargins) && (complianceMargins.length <= 1 || hasSomeVirtualComplianceMargin)

  return {
    isAllElectricConstructionRequired,
    hasSomeVirtualComplianceMargin,
    isComplianceMarginUnique,
    complianceMargins,
  }
}


export function checkIfPrototypeStudyHasComplianceMarginEDR1Enabled(policy_container) {
  if(!policy_container.custom_combinations.length) return false

  const getStudyComplianceMarginKey = () => {
    const study = Store.getters['globalEntities/Study/getterGlobalStudy']({ id: policy_container.custom_combinations[0].prototype.study_id })
    return study.compliance_margin_key || 'compliance_margin'
  }

  const isPvSystemEnabled = GeneralPerformanceByFuelPolicyImpactAlgorithm.pvEnabledComplianceMarginKeys.includes(getStudyComplianceMarginKey())

  const isComplianceMarginEDR1Enabled = !isPvSystemEnabled

  return isComplianceMarginEDR1Enabled 
}