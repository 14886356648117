<template>
  <div class="psui-w-full psui-flex psui-flex-wrap psui-items-end psui-space-y-1">
    <FilterClimateZone class="psui-mr-6" />
    <div class="psui-flex psui-flex-wrap">
      <FilterSourceStudy class="psui-flex-shrink-0" />
      <FilterBuildingType
        class="psui-flex-shrink-0"
      />
      <FilterTypeVintage
        v-if="getStudyType === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS"
        class="psui-flex-shrink-0"
      />
      <FilterTypeFuel
        v-else-if="getStudyType === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS"
        class="psui-flex-shrink-0"
      />
      <FilterCostEffective class="psui-flex-shrink-0" />
    </div>
  </div>
</template>

<script>
import FilterClimateZone from './FilterClimateZone'
import FilterSourceStudy from './FilterSourceStudy'
import FilterBuildingType from './FilterBuildingType'
import FilterTypeVintage from './FilterTypeVintage'
import FilterTypeFuel from './FilterTypeFuel.vue'
import FilterCostEffective from './FilterCostEffective.vue'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import { mapGetters } from 'vuex'


export default {
  name: 'FiltersWrapper',
  components: { 
    FilterClimateZone,
    FilterSourceStudy,
    FilterBuildingType,
    FilterTypeVintage, 
    FilterTypeFuel,
    FilterCostEffective
  },
  data: () => ({
    STUDY_TYPES_DB_SLUGS,
    studyTypes:null,
    type_prototypes: null,
    availablePrototypes: null
  }),
  computed: {
    getStudyType(){
      return this.$route.query.only_study_type ?? STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS
    },
    ...mapGetters({getterContentContainerWidth: 'general/getterContentContainerWidth'}),
  },
}
</script>