<template>
  <div class="w-full flex items-center justify-center h-screen py-6 overflow-y-auto p-3">
    <LoaderSpinner
      v-if="getRouteQueryCode"
    />
    <div 
      v-else
      class="login-card-w rounded"
    >
      <div class="apply-card bg-white flex flex-wrap justify-center">
        <UserSignForm
          @loginSuccess="onSuccess"
          @register-success="onSuccess"
        /> 
      </div>
    </div>
  </div>
</template>

<script>
import UserSignForm from './UserSignForm.vue'
import LoaderSpinner from '@/components/general/LoaderSpinner.vue'

export default {
  name: 'SignIn',
  components: { 
    UserSignForm, 
    LoaderSpinner, 
  },
  computed: {
    getRouteQueryCode() {
      return this.$route.query.code
    }
  },
  methods: {
    onSuccess() {
      this.$router.push('/policies')
    }
  }
}
</script>