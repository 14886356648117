<template>
  <div class="w-full h-full">
    <div class="block">
      <ApexChartGeneral 
        :options="getChartOptions()"
        :palette-color-index="paletteColorIndex"
      />
    </div>
  </div>
</template>

<script>
import { combinationColors } from '@/business-logic/constants/chartDefaultOptions'
import MathFunctions from '@/util/MathFunctions'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums'

export default {
  name: 'TypePrototypeExistingChart',
  props: ['getClimateZoneSelected', 'type_prototype', 'paletteColorIndex'],
  data() {
    return {
      combinationColors
    }
  },
   computed: {
    
    getTotal() {
      return this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
        climate_zone_prefix: this.getClimateZoneSelected.map(cz => cz.prefix),
        type_prototype_id: this.type_prototype.id
      })
    },

    climateZones() {
      return this.$store?.getters['lastJurisdictionVisited']?.climate_zones
    },

    getChartSeries() {
      return this.getClimateZoneSelected.map((climate_zone) => {
        const climate_zone_current_index_color = this.climateZones.findIndexInArray({ raw: climate_zone.raw })
        return {
          data: this.$store.getters['globalEntities/TypeVintage/getterGlobalTypeVintages']({ hide_in_building_estimates: false }).map((type_vintage) => {
            const building_stock_units = this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
              type_vintage_id: type_vintage.id,
              climate_zone_prefix: climate_zone.prefix,
              type_prototype_id: this.type_prototype.id
            })
            const all_building_stock_units_for_this_stack = this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
              type_prototype_id: this.type_prototype.id
            })
            const building_stock_units_min_percentage = MathFunctions.getMinPercentageOfAmount(all_building_stock_units_for_this_stack, building_stock_units, 2)
            const palette_color_index = this.paletteColorIndex
            return {
              x: type_vintage.title,
              y: building_stock_units_min_percentage,
              fillColor: palette_color_index[climate_zone_current_index_color],
              strokeColor: palette_color_index[climate_zone_current_index_color],
              climate_zone,
              type_vintage,
              building_stock_units,
              total_building_estimate_units: this.getTotal,
              percentage: MathFunctions.getPercentageOfAmount(this.getTotal, building_stock_units),
              type_prototype: this.type_prototype,
            }
          })
        }
      })
    },
    getStudyGroupByStudyType(){
      return this.$store.getters['globalEntities/StudyGroup/getterGlobalStudyGroups']({ study_type_id : 1}).map(studyGroup =>  studyGroup.id)
    }

  },

  methods: {
    getChartOptions() {
      const self = this
      return { 
        chart: {
          type: 'bar',
          toolbar: { show: false, },
          events: {
            dataPointSelection: function(event, chartContext, { seriesIndex, dataPointIndex }) {
              const item = self.getChartSeries[seriesIndex].data[dataPointIndex]
              if (item.x === 'Post 2005') return 
              const { type_prototype, type_vintage, climate_zone } = item
              self.goToStudyResults({ 
                type_prototype_id: type_prototype.id,
                type_vintage_id: type_vintage.id,
                climate_zone_raw: climate_zone.raw,
              })
            }
          },
        },
        series: this.getChartSeries, 
        yaxis: {
          labels: {
            formatter: function(value) {
              return self.formatUnits(value)
            }
          },
        },
        tooltip: {
          custom: function ({ seriesIndex, dataPointIndex }) {
            const item = self.getChartSeries[seriesIndex].data[dataPointIndex]
            return `
              <div class="psui-flex" style="background-color: ${ item.fillColor } !important;">
                <div class="apexcharts-tooltip-content psui-w-full psui-bg-white psui-p-4">
                  <h4 class="psui-text-gray-80 psui-text-xsmall psui-font-bold">Climate Zone ${ item.climate_zone.prefix }</h4>
                  <div class="psui-w-full psui-flex psui-items-center">
                    <h2 class="psui-text-gray-80 psui-text-small">${ self.formatUnits(item.building_stock_units) } Units</h2>
                    <span class="psui-text-gray-30 psui-mx-1">|</span>
                    <h2 class="psui-text-gray-50 psui-text-small">${ self.formatNumber(MathFunctions.getPercentageOfAmount(self.getTotal, item.building_stock_units)) }%</h2>
                  </div>
                  <h4 class="psui-text-gray-40 psui-text-xsmall psui-font-bold">${ item.type_vintage.title }</h4>
                </div>
              </div>
            `
          }
        }
      }
    },

    goToStudyResults({ type_vintage_id, type_prototype_id, climate_zone_raw }) {
      
      const exclude_type_prototype_ids = this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototypes']()
        .filter((item) => item.id != type_prototype_id)
        .map((item) => item.id).join(',')
      
      const exclude_type_vintage_ids = this.$store.getters['globalEntities/TypeVintage/getterGlobalTypeVintages']()
        .filter((item) => item.id != type_vintage_id)
        .map((item) => item.id).join(',')

      const exclude_study_ids = this.$store.getters['globalEntities/Study/getterGlobalStudies']()
        .filter(study => study.study_group_id !== this.getStudyGroupByStudyType[0] && study.study_group_id !== this.getStudyGroupByStudyType[1] )
        .map(study => study.id).join(',')  

      
      const route = {
        name: 'StudyResults',
        params: { 
          jurisdiction_slug: this.$route.params.jurisdiction_slug,
          climate_zone_raw: climate_zone_raw 
        },
        query: {
          only_study_type: STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS,
          exclude_type_prototype_ids,
          exclude_type_vintage_ids,
          exclude_study_ids
        }
      }
      this.$router.push(route)
    },
  }
}
</script>

<style scoped>
  ::v-deep .apexcharts-tooltip.apexcharts-theme-light {
    border: none !important;
  }
  ::v-deep .apexcharts-bar-area:hover:not(:last-child) { 
    opacity: 0.9 !important;
    cursor: zoom-in !important;
  }
</style>