<template>
  <div class="psui-w-full psui-flex psui-flex-col py-16 psui-items-center psui-px-6 psui-text-center">
    <img
      src="/images/comparison/ilustration.png"
      alt="Ilustration"
      class="psui-inline-block psui-mb-6"
    >
    <div class="flex flex-col space-y-2">
      <p class="psui-font-bold psui-text-gray-70 psui-text-center psui-text-h5">
        {{ `You have ${userComparisons.length} comparisons, but none match the selected filters` }}
      </p>
      <p class="psui-text-gray-60 psui-text-big psui-text-center">
        <span
          class="psui-text-blue-60 psui-font-bold cursor-pointer"
          @click="$emit('removeFilters')"
        >
          Remove
        </span>
        or adjust your filters to find what you are looking for
      </p>
    </div>
  </div>
</template>

<script>


export default {
  name: 'ComparisonFilteredListEmpty',
  props:['userComparisons'],
}
</script>

