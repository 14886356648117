<template>
  <div class="news-list psui-flex psui-flex-col psui-mt-10 psui-mx-auto">
    <p class="psui-flex psui-items-end psui-text-blue-80 psui-text-h2 psui-font-bold psui-mb-6">
      What's New
      <a
        href="https://intercom.help/explorer-local-energy-codes/en/collections/3798644-what-s-new"
        target="_blank"
        class="psui-ml-4 psui-text-small psui-text-blue-60 psui-flex psui-items-center psui-pb-1"
      >
        See more
        <PsIcon
          icon="open_in_new"
          size="18"
          icon-classes="psui-text-blue-60 psui-ml-1"
          display="flex"
        />
      </a>
    </p>

    <div
      v-if="articles && !isLoading"
      class="psui-flex psui-flex-col divide-y psui-divide-gray-20 psui-space-y-reverse"
    >
      <div 
        v-for="article in articles"
        :key="article.id" 
        class="article psui-flex psui-items-start psui-w-full psui-gap-6 psui-py-6 first:psui-pt-0"
      >
        <PsDateCardInfo
          class="flex-shrink-0"
          :date="article.published_at"
        />
        <div class="psui-flex psui-flex-col">
          <p class="psui-text-p psui-text-blue-80 psui-font-bold psui-mb-1">
            {{ article.title }}
          </p>
          <div 
            class="psui-text-p psui-text-gray-60 psui-mb-1"
            v-html="article.description"
          />  
          <div
            v-if="article.meta"
            class="article-buttons psui-flex gap-y-8 gap-x-2"
          >
            <a
              v-for="button in article.meta.buttons"
              :key="`${article.id}-button-${button.label}`"
              :href="button.url"
              target="_blank"
            >
              <PsButton
                :label="button.label"
                size="medium"
                layout="onlytext"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <HomeNewsListSkeleton v-else />
  </div>
</template>

<script>
import HomeNewsListSkeleton from '@/modules/app/home/HomeNewsListSkeleton.vue'


export default {
  name:'HomeNewsList',
  components:{
    HomeNewsListSkeleton
  },
  props: ['articles', 'is-loading']
}
</script>

<style lang="scss" scoped>
  .psui-el-button {
    &.layout-onlytext {
      background-color:transparent ;
    }
    &.size-medium:first-of-type {
      padding-left: 0;
    } 
  }

  .news-list {
    max-width: 900px;

    @media (min-width: 768px) {
      width: 80%;
    }
    
    @media (min-width:1600px){
      max-width: 1024px
    }
  }
  
  .article:not(:first-child) {
    padding-top: 32px;
  }

  .article:not(:last-child) {
    padding-bottom: 32px;
  }

  .article {
    &-buttons {
      .psui-el-button {
        background-color: transparent;

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
</style>