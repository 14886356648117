import Api from '@/util/Api'
import Store from '@/store'
export default class PrototypeApiService {

  static update(payload, config = false){
    return Api.post(`/api/admin/prototype/${payload.id}`, payload, config)
    .then(({data})=> {
      Store.dispatch('globalEntities/Prototype/loadGlobalPrototypes', null, { root: true })
      return data
    })
  }
  
}