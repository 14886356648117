import BaseLayoutWithHeaderAndSideBar from '@/layout/BaseLayoutWithHeaderAndSideBar.vue'
import AppHome from '@/modules/app/home/AppHome.vue'
import SidebarDefault from '@/components/general/SidebarDefault.vue'
import JurisdictionRoutes from '@/modules/app/jurisdiction/routes'
import PolicyRoutes from '@/modules/app/policy/routes'
import ComparisonRoutes from '@/modules/app/comparison/routes'

export default [
  {
    path: '/',
    component: BaseLayoutWithHeaderAndSideBar,
    meta: {
      sidebar: {
        size: 'medium',
        content: SidebarDefault
      },
    },
    query: { utm_source : 'explorer-sidebar' },
    children: [
      {
        path: '',
        name: 'AppHome',
        component: AppHome,
      },

      ...PolicyRoutes,
      ...ComparisonRoutes,
      ...JurisdictionRoutes,
      
    ]
  } 
]