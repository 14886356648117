<template>
  <PsButton
    :label="'Explore policy options' | policyTextRephrase('small')"
    :icon="icon"
    :layout="layout"
    size="small"
    icon-position="left"
    @click="goToPolicyOptionsTab()"
  />
</template>

<script>
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'

export default {
  name: 'AppPolicyCreateButton',
  props: {
    icon: {
      type: String,
      default: 'explore'
    },
    layout: {
      type: String,
      default: 'solid'
    },
  },
  data: () => ({
    STUDY_TYPES_DB_SLUGS
  }),
  methods: {
    goToPolicyOptionsTab(){
      if(this.$route.name === 'PoliciesList'){
        this.$router.push({
          name: 'PolicyOptions',
          query: { only_study_type: STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS }
        })
      } else if(this.$route.name === 'StudyResults') {
        if(this.$route.query.only_study_type === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS || !this.$route.query.only_study_type) {
          this.$router.push({
            name: 'PolicyOptions',
            query: { only_study_type: STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS }
          })
        } else if(this.$route.query.only_study_type === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS) {
          this.$router.push({
            name: 'PolicyOptions',
            query: { only_study_type: STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS }
          })
        }
      }
    }
  }
}
</script>

<style>

</style>