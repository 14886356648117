<template>
  <div class="psui-w-full psui-flex psui-flex-col psui-px-4 sm:psui-px-8 psui-pb-7">
    <PsDialog
      v-if="getUserIsntLoggedAndHasNoComparisons"
      theme="informative"
      icon="login"
      :message="'Already have policies or comparisons saved?' | policyTextRephrase('small')"
      class="psui-w-full psui-cursor-pointer mb-6"
      :has-close="false"
      @click.native="openModalUserSign"
    >
      <template #action>
        <div class="psui-flex psui-space-x-5 psui-mr-2">
          <p class="psui-text-blue-60 psui-text-small psui-font-bold">
            Login
          </p>
        </div>
      </template>
    </PsDialog>
    <ComparisonListUserComparisons 
      :is-loading="isLoading" 
      :is-loading-from-google-login="isLoadingFromGoogleLogin"
    />
  </div>
</template>

<script>
import ComparisonListUserComparisons from './ComparisonListUserComparisons.vue'
import ComparisonApiService from '@/services/api/ComparisonApiService.js'

export default {
  name: 'ComparisonList',
  components: { ComparisonListUserComparisons },
  data: () => ({
    isLoading : false,
    policyDeletedVisited: false,
    isLoadingFromGoogleLogin: false
  }),
  computed: {
    getUser() {
      return this.$store.state.auth.loggedUser
    },
    getAllUserComparisons() {
      return this.$store.getters['comparison/getterUserComparisons']()
    },

    getUserIsntLoggedAndHasNoComparisons() {
      return !this.getUser && !this.getAllUserComparisons.length
    }
  },
  mounted() {    
    this.$eventBus.$on('redirectFromGoogleLogin',({ isLoading }) => {
      this.isLoadingFromGoogleLogin = isLoading
    })
    
    this.init()

    this.$eventBus.$on('policyDeletedVisited', ()=>{
      this.policyDeletedVisited = true
    })
  },
  beforeDestroy(){
    this.$eventBus.$off('redirectFromGoogleLogin')
    this.$eventBus.$off('policyDeletedVisited')
  },
  methods: {
    openModalUserSign() {
      this.$store.commit('general/setRedirectTo', {route: this.$route.path})
      this.$eventBus.$emit('openModalUserSign')
    },
    async init() {
      this.isLoading = true
      const comparisonsIds = this.getAllUserComparisons.map(comp => comp.id)
      const comparisons = await ComparisonApiService.getUserComparisonsByIds(comparisonsIds)

      const updatedState = this.getAllUserComparisons.filter(userComp => !comparisons.find(comp => comp.id == userComp.id && comp.deleted_at))

      if (updatedState.length != this.getAllUserComparisons.length && !this.policyDeletedVisited) {
        this.$toast.info(
         "It seems that one or more of your shared policies were deleted by the owner. We're going to update your policy's list for you!",
         { duration: 10000 }
        )
      
      }
      this.$store.commit('comparison/setUserComparisons', updatedState)
  
      await this.$store.dispatch('comparison/getAllUserComparisons')
      .then(()=>{
        this.isLoading = false
      })
    }
  }
}
</script>
