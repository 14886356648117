<template>
  <BaseModal 
    ref="baseModal"
    title="Default Assumption Input"
    modal-dialog-classes="max-w-lg"
    @close="close"
  >
    <div class="w-full flex flex-col">
      <InputWithLabel
        v-model="default_assumption_input.title"
        class="mt-6"
        label="Title"
      />

      <InputWithLabel
        v-model="default_assumption_input.content"
        class="mt-6"
        label="Content"
      />

      <InputWithLabel
        v-model="default_assumption_input.key"
        class="mt-6"
        label="Key"
      />

      <InputWithLabel
        v-model="default_assumption_input.value"
        class="mt-6"
        label="Value"
      />

      <div class="relative mt-6">
        <VueSelect 
          v-model="default_assumption_input.type_prototype_id"
          class="vue-select"
          placeholder="Select"
          :reduce="item => item.id"
          label="title"
          :options="type_prototypes"
        />
        <label>Type prototype</label>
      </div>

      <div class="w-full relative mt-6">
        <VueSelect 
          v-model="default_assumption_input.study_type_id"
          class="vue-select"
          placeholder="Select a study type"
          :class="default_assumption_input.study_type_id ? 'filled' : ''"
          label="title"
          :options="study_types"
          :reduce="item => item.id"
        />
        <label>Study type</label>
      </div>

      <div class="mt-6">
        <vue-json-editor
          v-model="default_assumption_input.meta" 
          class="mt-6" 
          :expanded-on-start="true" 
          :mode="'code'" 
          :modes="['code', 'tree']"
        />
      </div>

      <div class="w-full flex mt-6">
        <PsButton
          size="medium"
          :label="default_assumption_input.id ? `Update` : `Store`"
          @click="storeOrUpdate()"
        />
      </div>
    </div>
  </BaseModal>
</template>

<script>
import DefaultAssumptionInputApiService from '@/services/api/DefaultAssumptionInputApiService'
import DefaultAssumptionInput from '@/models/DefaultAssumptionInput'
import VueJsonEditor from 'vue-json-editor'
export default {
  name: "AdminDefaultAssumptionInputEditModal",
  components: { VueJsonEditor },
  data() {
    return {
      default_assumption_input: new DefaultAssumptionInput(),
      type_prototypes: [],
      study_types: []
    }
  },
  mounted() {
    this.$eventBus.$on('openDefaultAssumptionInputModal', ({ default_assumption_input_id = null } = {}) => {
      this.default_assumption_input.id = default_assumption_input_id
      this.$refs.baseModal.showModal = true
      if(default_assumption_input_id) this.getDefaultAssumptionInput(default_assumption_input_id)
    })
    this.getSelectOptions()
  },
  beforeDestroy() {
    this.$eventBus.$off('openDefaultAssumptionInputModal')
  },
  methods: {
    getDefaultAssumptionInput(default_assumption_input_id) {
      DefaultAssumptionInputApiService.show(default_assumption_input_id)
      .then(({ data }) => {
        this.default_assumption_input = new DefaultAssumptionInput(data.default_assumption_input)
      })
    },

    getSelectOptions() {
      const query = `
        {
          type_prototypes {
            id
            title
          }
          study_types {
            id
            title
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.type_prototypes = data.type_prototypes
        })
    },

    storeOrUpdate() {
      DefaultAssumptionInputApiService.storeOrUpdate(this.default_assumption_input)
        .then(() => {
          this.$emit('getDefaultAssumptionInputs')
          this.close()
        })
    },

    close() {
      this.default_assumption_input = new DefaultAssumptionInput()
      this.$refs.baseModal.showModal = false
    }
  }
}
</script>