var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex justify-between items-center psui-text-small"},[_c('div',{staticClass:"flexible-wrapper flex space-x-1 items-center w-full"},[_c('span',{staticClass:"psui-text-blue-80 psui-font-bold"},[_vm._v("Required flexible score")]),_c('PsIcon',{staticClass:"flexible-icon ml-auto psui-text-gray-40 cursor-pointer hover:psui-text-blue-60",attrs:{"size":"16","display":"flex","icon":"info_outline"},nativeOn:{"click":function($event){return _vm.$eventBus.$emit('openDescriptionModal', { type: 'helper' , slug: 'require_flexible_score' })}}})],1),(_vm.isTargetScoreGreaterThanMaxTargetScore)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('The required flexible score cannot be achieved cost-effectively because certain measures are mandatory or have been removed.'),expression:"'The required flexible score cannot be achieved cost-effectively because certain measures are mandatory or have been removed.'"}],staticClass:"highlight-icon"},[_c('div',{staticClass:"icon"})]):_vm._e()]),(_vm.maxTargetScore >= 0)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.inputTooltip),expression:"inputTooltip"}],staticClass:"w-full flex items-center psui-border psui-justify-between psui-border-gray-30 transition-all rounded-sm mt-1 h-8",class:[
      _vm.getTargetScoreHasError ? 
        'bg-candy-200 border border-candy-500' : 
        'psui-border-gray-30',
      _vm.inputFocus ? 'psui-border-green-70' : '',
      {'hover:psui-border-green-20' : !_vm.getTargetScoreHasError && _vm.userCanEditPolicy},
    ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.targetScoreInput),expression:"targetScoreInput"},{name:"mask",rawName:"v-mask",value:(['#', '##', '###']),expression:"['#', '##', '###']"}],ref:"input",staticClass:"bg-transparent text-header-2 h-full psui-pl-2 z-10 placeholder-deepsky-450 target-score-input flex psui-text-gray-50 w-full",class:{'caret-candy-500 text-candy-500' : _vm.getTargetScoreHasError },attrs:{"min":1,"type":"tel","disabled":!_vm.userCanEditPolicy},domProps:{"value":(_vm.targetScoreInput)},on:{"change":function($event){return _vm.checkTargetScore()},"input":[function($event){if($event.target.composing)return;_vm.targetScoreInput=$event.target.value},function($event){return _vm.onInputChange(_vm.type_vintage.id, _vm.climate_zone.id, _vm.prototype.id)}],"focus":_vm.onInputFocus,"blur":_vm.onInputBlur,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$refs.input.blur()}}}),_c('div',{staticClass:"flex flex-col mr-1"},[_c('PsIcon',{staticClass:"ml-auto psui-text-gray-50 cursor-pointer",attrs:{"size":"14","display":"flex","icon":"expand_less"},nativeOn:{"click":function($event){return _vm.setTargetScore('increment')}}}),_c('PsIcon',{staticClass:"ml-auto psui-text-gray-50 cursor-pointer",attrs:{"size":"14","display":"flex","icon":"expand_more"},nativeOn:{"click":function($event){return _vm.setTargetScore('decrement')}}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }