<template>
  <div class="w-full flex flex-col bg-ash-200">
    <div class="w-full p-6 px-8 border-b border-ash-200 bg-white flex justify-between">
      <PageTitle title="Building Estimate" />
      <div class="flex space-x-4">
        <PsButton
          label="Sync Residential"
          @click="sync('residential')"
        />
        <PsButton
          label="Sync Non Residential"
          @click="sync('non_residential')"
        />
      </div>
    </div>
    <div class="w-full p-3 bg-white flex space-x-4">
      <Dropdown          
        v-for="filter in filters"
        :key="filter.source"
        ref="dropdown"
        :button-classes="'px-2 bg-transparent w-full'"
        :dropdown-dialog-classes="'left-0 bg-white'"
      >
        <template #buttonLabel>            
          <span 
            class="text-header-4 pb-1 flex items-center"
            :class="[filter.selecteds.length ? 'border-pri border-b-2 text-deepsky-800 font-bold' : 'border-b border-gray05 text-deepsky-500']"
          >
            Filter by {{ filter.label }} ({{ filter.items.length }})
            <feather
              class="ml-2"
              type="chevron-down"
              size="14"
            />
          </span>
        </template>
        <template #items>
          <div class="flex flex-col p-5 space-y-2 max-h-1/2 overflow-y-auto">
            <Checkbox
              v-for="item in filter.items" 
              :key="filter.source + item[filter.valueKey]"
              :label="item[filter.labelKey] + ''"
              :value="filter.selecteds.indexOf(item) > -1 ? true : false"
              @change="toggleFilter(filter, item)"
            />
          </div>
        </template>
      </Dropdown>
    </div>

    <div class="w-full p-4 overflow-x-auto">
      <table class="psui-el-table psui-table">
        <thead>
          <tr>
            <td class="psui-bg-gray-10 psui-text-gray-80 psui-font-normal">
              Jurisdiction
            </td>
            <td class="psui-bg-gray-10 psui-text-gray-80 psui-font-normal">
              Clim. zone prefix
            </td>
            <td class="psui-bg-gray-10 psui-text-gray-80 psui-font-normal">
              Type prototype
            </td>
            <td class="psui-bg-gray-10 psui-text-gray-80 psui-font-normal">
              Const. implementation type
            </td>
            <td class="psui-bg-gray-10 psui-text-gray-80 psui-font-normal">
              Building height
            </td>
            <td class="psui-bg-gray-10 psui-text-gray-80 psui-font-normal">
              Year
            </td>
            <td class="psui-bg-gray-10 psui-text-gray-80 psui-font-normal">
              Value (Units or Square ft.)
            </td>
            <td class="psui-bg-gray-10 psui-text-gray-80 psui-font-normal">
              BL Tracing
            </td>
            <td class="psui-bg-gray-10 psui-text-gray-80 psui-font-normal">
              Spreadsheet row
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(building_estimate, index) in building_estimates"
            :key="index"
            class="bg-white psui-text-gray-80 psui-font-normal"
          >
            <td>
              {{ building_estimate.jurisdiction.titles.title_type }}
            </td>
            <td>
              {{ building_estimate.climate_zone_prefix }}
            </td>
            <td>
              {{ building_estimate.type_prototype.title }}
            </td>
            <td>
              {{ building_estimate.construction_implementation_type.title }}
            </td>
            <td>
              <span v-if="building_estimate.building_height">{{ building_estimate.building_height.title }}</span>
            </td>
            <td>
              {{ building_estimate.year }}
            </td>
            <td>
              {{ building_estimate.units }}
            </td>
            <td>
              <PsButton
                label="Show tracing"
                @click="showBuildingEstimateTracing(building_estimate)"
              />
            </td>
            <td>
              {{ building_estimate.row_number }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <BaseModal 
      ref="baseModal"
      title="Building Estimate Business Logic tracing"
      modal-dialog-classes="w-auto"
    >
      <div 
        v-if="buildingEstimateSelected" 
        class="w-full flex flex-col mt-4 space-y-4"
      >
        <h4 class="text-center">
          The value of statements are defined between: [VALUE]
        </h4>
        <div class="w-full flex-col items-start">
          <h4>Jurisdiction: {{ buildingEstimateSelected.jurisdiction.titles.title_type }}</h4>
          <h4>Climate zone prefix: {{ buildingEstimateSelected.climate_zone_prefix }}</h4>
          <h4>Type prototype: {{ buildingEstimateSelected.type_prototype.title }}</h4>
          <h4>Construction implementation type: {{ buildingEstimateSelected.construction_implementation_type.title }}</h4>
          <h4>Building height: <span v-if="buildingEstimateSelected.building_height">{{ buildingEstimateSelected.building_height.title }}</span></h4>
          <h4>Spreadsheet row number: {{ buildingEstimateSelected.row_number }}</h4>
        </div>
        <div 
          v-for="(tracing, indexTracing) in buildingEstimateSelected.tracing"
          :key="`tracing-${indexTracing}`"
          class="w-full flex flex-col"
        >
          <div class="w-full flex border border-ash-200 p-2">
            <PsIcon
              icon="chevron_right"
              color="psui-text-blue-50"
            />
            <div class="w-full break-words">
              {{ tracing }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { toggleInArray } from '@igortrindade/lazyfy'
export default {
  name: 'AdminBuildingEstimateIndex',
  data() {
    return {
      building_estimates: [],
      filters: [
        { source: 'jurisdictions', key: 'jurisdiction_id', label: 'Jurisdictions', items: [], selecteds: [], valueKey: 'id', labelKey: 'title' },
        { source: 'climate_zones', key: 'climate_zone_prefix', label: 'Climate zones', items: [], selecteds: [], valueKey: 'prefix', labelKey: 'prefix' },
        { source: 'type_prototypes', key: 'type_prototype_id', label: 'Type prototypes', items: [], selecteds: [], valueKey: 'id', labelKey: 'title' },
        { source: 'construction_implementation_types', key: 'construction_implementation_type_id', label: 'Const. implemt. types', items: [], selecteds: [], valueKey: 'id', labelKey: 'title' },
        { source: 'building_heights', key: 'building_height_id', label: 'Building Heights', items: [], selecteds: [], valueKey: 'id', labelKey: 'title' },
      ],
      buildingEstimateSelected: null
    }
  },
  mounted() {
    this.getBuildingEstimates()
    this.getSelectOptions()
  },
  methods: {

    getBuildingEstimates() {
      let filtersSelecteds = ''
      this.filters.map((filter) => {
        if(filter.selecteds.length) {
          filtersSelecteds += `{ column: "${filter.key}", value: [${filter.selecteds.map(i => `"${ i[filter.valueKey] }"`).join(',')}] }`
        }
      })
      const query = `
        {
          building_estimates (
            limit: 30
            whereIn: [${filtersSelecteds}]
          ) {
            id
            jurisdiction_id
            climate_zone_prefix
            construction_implementation_type_id
            type_prototype_id
            building_height_id
            year
            growth_rate
            base_building_stock_units
            units
            units_type
            row_number
            tracing
            jurisdiction { id title_type titles }
            construction_implementation_type { id title }
            type_prototype { id title }
            building_height { id title }
          }
        }
      `
      this.$graphql({ query, caller: `AdminBuildingEstimateIndex.getBuildingEstimates` })
        .then(({ data }) => {
          this.building_estimates = data.building_estimates
        })
    },

    getSelectOptions() {
      const query = `
        {
          jurisdictions ( orderBy: [{ column: "title" }]) { id title type slug }
          climate_zones (distinct: "prefix", orderBy: [{ column: "prefix" }]) { prefix }
          construction_implementation_types { id title slug }
          type_prototypes { id title slug }
          building_heights { id title slug }
        }
      `
      this.$graphql({ query, caller: `AdminBuildingEstimateIndex.getSelectOptions` })
        .then(({ data }) => {
          Object.keys(data).map((key) => {

            data.jurisdictions = data.jurisdictions.map((jurisdiction) => { return { ...jurisdiction, title: `${jurisdiction.title} (${jurisdiction.type})` }})
            const findFilterItem = this.filters.findInArray({ source: key })
            findFilterItem.items = data[key]
          })
        })
    },

    toggleFilter(filter, item) {
      filter.selecteds = toggleInArray(filter.selecteds, item)
      this.getBuildingEstimates()
    },

    showBuildingEstimateTracing(building_estimate) {
      this.buildingEstimateSelected = building_estimate
      this.$refs.baseModal.showModal = true
    },

    sync(type) {
      this.$api.get(`/api/admin/building_estimate/sync/${type}`, { ignoreFeedback: false })
        .then(() => {
          this.getBuildingEstimates()
        })
        .catch(() => {
          this.$appToast({ message: 'Ops! Somnething went wrong syncing Building Estimates', type: 'error' })
        })
    }
  }
}
</script>
