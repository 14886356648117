<template>
  <div class="forecast-assumptions-widget psui-w-auto psui-flex psui-items-center ts--accent--2">
    <p class="text-gray02 psui-font-bold psui-flex">
      <span>{{ jurisdictionType }}-wide estimates use these </span>
      <button
        class="text-blue02 psui-border-b border-gray05 psui-ml-1 psui-font-bold psui-flex psui-items-center"
        @click="openAssumptionsDrawer()"
      >
        Assumptions
        <img
          src="/icon-arrow-down.svg"
          class="ml-2"
        >
      </button>
    </p>
  </div>
</template>

<script>

export default {
  name: 'AssumptionsWidget',
  props: ['studyGroup'],  
  computed: {
    jurisdictionType() {
      return this.$store.getters['lastJurisdictionVisited']?.type ?? 'City'
    },
  },
  methods: {
    openAssumptionsDrawer() {
      const studyType = this.$store.getters['globalEntities/StudyType/getterGlobalStudyType']({ id: this.studyGroup.study_type_id })
      let tabSelected
      if ( studyType.title === 'Existing Buildings' ) tabSelected = { label:'Existing Buildings', value:"EXISTING" }
      if ( studyType.title === 'New Buildings' ) tabSelected = { label:'New Buildings', value:'NEW' }

      this.$store.commit('assumptions/toggleDrawer', { tabSelected: tabSelected })
    }
  }
}
</script>