import Api from '@/util/Api'
import Comparison from '@/models/Comparison'
import GraphQL from '@/util/GraphQL'
export default class ComparisonApiService {

  static get() {
    const query = `
      {
        comparisons {
          ${myComparisonArgs}
        }
      }
    `

    return GraphQL({ query, caller: `ComparisonApiService.get`})
      .then(({ data }) => data )
  }

  static getUserComparisonsByIds(comparisons_ids){
    const query = `
    {
      comparisons (
        whereIn: [
          { column: "id", value: [${comparisons_ids.map(item => `"${item}"`).join(',')}] }      
        ]
      ){
        id
        title
        description
        user_id
        deleted_at
        jurisdiction_id
        user_device_id
      }
    }
    `

    return GraphQL({query, caller: `ComparisonApiService.getUserComparisonsByIds`})
    .then(({data}) => {
      return data.comparisons
    })
  }


  static getUserComparisons() {
    return GraphQL({ query: getUserComparisonsQueryArgs('comparisons'), caller: 'ComparisonApiService.getUserComparisons' })
      .then(({ data }) => {
        if(!data.currentUser) return []
        return data.currentUser.comparisons.map((comparison) => new Comparison(comparison))
      })
  }

  static getById(comparison_id, caller = undefined) {
    const query = `
      {
        comparison ( findBy: { column: "id", value: "${ comparison_id }" } ) {
          ${myComparisonArgs}
        }
      }
    `
    return GraphQL({ query, caller })
      .then(({ data }) => {
        if (!data?.comparison?.id) {
          return null
        }
        return new Comparison(data.comparison)
      }).catch(() => {
        return null
      })
  }
  
  static create(payload, config = false) {
    return Api.post('/api/comparison/create', new Comparison(payload), config)        
      .then(({data})=>{
        return data
      })
  }

  static update(comparison_id, payload, config) {
    return Api.post(
      `/api/comparison/update/${comparison_id}`,
      { ...payload},
      config
    ).then(({ data }) => data )
      .catch(err => {
        if (err?.response?.data?.message) {
          window.$vueInstance.$appToast({ message: err?.response?.data?.message })
        } else {
          window.$vueInstance.$appToast({type:'error', message: 'Something went wrong, please try again.' })
        }
      })
  }

  static delete(comparison_id) {
    return Api.delete(`/api/comparison/delete/${comparison_id}`)
  }

  static duplicate({ comparison_id, title }) {
    return Api.post(`/api/comparison/duplicate/${ comparison_id }`, { title })
      .then(({ data }) => {
        return data.comparison
      })
  }
}

function getUserComparisonsQueryArgs(comparison_type = 'comparisons') {
  return `
    {
      currentUser {
        id
        email
        ${comparison_type} (
          whereNull: { columns: ["deleted_at"] }
        ) {
          ${myComparisonArgs}
        }
      }
    }
  `
}

const myComparisonArgs = `
  id
  title
  description
  user_id
  jurisdiction_id
  user_device_id
  jurisdiction {
    id
    title
    type
    title_type
    titles
  }
  policy_ids
  policies {
    id
    policy_containers {
      id
      type_prototype {
        id
        title
      }
    }
  }
  created_at
  updated_at
  deleted_at
`
