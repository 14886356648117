import CustomBuildingEstimate from "@/models/CustomBuildingEstimate"
import BuildingEstimateApiService from "@/services/api/BuildingEstimateApiService"
import CustomBuildingEstimateApiService from "@/services/api/CustomBuildingEstimateApiService"
import { ERRORS } from "@/util/Enums"
import generateEmptyBuildingEstimates from './generateEmptyBuildingEstimates'
import attachPolicyToBuildingEstimates from '@/modules/app/assumptions/shared/attachPolicyToBuildingEstimates'
import updateUnits from '@/modules/app/assumptions/shared/updateUnits'

export default {
  
  async getJurisdictionBuildingEstimates(context, jurisdiction_id) {
    const jurisdiction_building_estimates = await BuildingEstimateApiService.getJurisdictionUnits(jurisdiction_id)
    const building_estimates = generateEmptyBuildingEstimates(jurisdiction_building_estimates)
    context.commit('setJurisdictionBuildingEstimates', { jurisdiction_id, building_estimates })
    return building_estimates
  },

  getCustomBuildingEstimates(context) {
    context.commit('buildingEstimatesInit')
    return new CustomBuildingEstimateApiService().get()
      .then((custom_building_estimates) => {
        custom_building_estimates = custom_building_estimates.map((item) => new CustomBuildingEstimate(item))
        const policy_id_selected = context.rootState.assumptions.policy_id_selected
        context.commit('setCustomBuildingEstimates', { custom_building_estimates, policy_id_selected })
        context.commit('buildingEstimatesFinish')
        return custom_building_estimates
      }).catch((err) => {
        context.commit('buildingEstimatesFail')
        throw new Error(`buildingEstimatesFail ${err}`)
      })
  },

  addOrUpdateCustomBuildingEstimates({ commit, dispatch, rootState }, { custom_building_estimates, to, from }) {
    commit('buildingEstimatesInit')
    const policy_id_selected = rootState.assumptions.policy_id_selected
    custom_building_estimates = attachPolicyToBuildingEstimates(JSON.parse(JSON.stringify(custom_building_estimates)), policy_id_selected)
    custom_building_estimates = updateUnits({ items: custom_building_estimates, from, to })
    commit('setCustomBuildingEstimates', { custom_building_estimates, policy_id_selected })
    setTimeout(() => {
      dispatch('assumptions/storeBatchAllCustomBuildings', null, { root: true }).then(() => commit('buildingEstimatesFinish'))
    }, 200)
  },

  addCustomBuildingEstimatesToPolicy({ commit, dispatch, rootState }, {  current_custom_building_estimates, policy_id }) {
    const newCustomBuildingEstimates = current_custom_building_estimates.map(cbe => {
      return {
        ...cbe,
        policy_id
      }
    })
    dispatch('clearCustomBuildingEstimates', newCustomBuildingEstimates)
      .then(() => {
        const policy_id_selected = policy_id ?? rootState.assumptions.policy_id_selected
        commit('setCustomBuildingEstimates', { custom_building_estimates: newCustomBuildingEstimates, policy_id_selected })
      })
  },

  storeBatchCustomBuildingEstimates({ state }, custom_building_estimates = null ) {
    const payload = custom_building_estimates ?? state.custom_building_estimates
    return CustomBuildingEstimateApiService
      .store_batch(payload)
  },

  clearCustomBuildingEstimates(context, custom_building_estimates ) {
    return CustomBuildingEstimateApiService
      .delete_batch(custom_building_estimates)
      .then(({ data }) => {
        if (data.deleted) {
          context.commit('clearCustomBuildingEstimates', custom_building_estimates )
        }
      })
      .catch((e) => {
        if (e === ERRORS.USER_NOT_LOGGED_IN_DELETE_FAIL) {
          context.commit('clearCustomBuildingEstimates', custom_building_estimates )
        } else {
          throw new Error(e)
        }
      })
  }
}
