// RRC - ROUTER REDIRECT CASES
// Let's make sure all redirects have a ENUM item and its passed like below
// $router.push({ rrc: RRC[CASE] })
// This is important to easily identify what's the source of the redirection
// We should never use a redirect case in more than one place
export default {
  MODAL_JURISDICTION_SELECTION_1 : 'MODAL_JURISDICTION_SELECTION_1',
  BASE_APP_HEADER_NAVIGATION_BY_STUDY_TYPE_1 : 'BASE_APP_HEADER_NAVIGATION_BY_STUDY_TYPE_1',
  BASE_APP_HEADER_NAVIGATION_BY_STUDY_TYPE_2 : 'BASE_APP_HEADER_NAVIGATION_BY_STUDY_TYPE_2',
  MODAL_BUILD_POLICY_AND_JURISDICTION_SELECTION_1: 'MODAL_BUILD_POLICY_AND_JURISDICTION_SELECTION_1',
}
