<template>
  <PsButton
    label="Share"
    icon="share"
    size="small"
    layout="onlytext"
    class="psui-text-blue-50"
    @click="copyLink"
  />
</template>

<script>
import { GA_LABELS } from '@/mixins/GaEventsMixin'
import { copyUrlToClipboard } from '@/util/Helpers'

export default {
  name: 'PolicyOptionsHeaderActions',
  methods: {
    copyLink() {
      copyUrlToClipboard()
      this.$appToast({ message: 'Link copied to clipboard!', duration: 5500 })
      this.gaEventShareUrl(GA_LABELS.RESULTS_SHARE_URL)
    }
  },    
}
</script>

