import editRequirementsNewBuildingsSF2022Columns from '@/modules/app/policy/show/requirements/requirements-drawer/PolicyEditRequirementsNewBuildingsSF2022/edit-requirements-new-buildings-sf-2022-columns.js'

export const initialState = () => ({
  loggedUser: null,
  loggedUserType: null,
  loggedUserToken: null,
  user_preferences: {
    lastJurisdictionVisited: false,
    lastClimateZonesVisited: false,
    lastStudyTypeVisited: false,
    policies: {
      myPolicies: {
        filterByAuthorV3: [],
        filterByJurisdictionV2: [],
        filterByStudyTypeV3: [],
        orderByColumn: 'title',
        orderByDirection: 'asc',
      },
      comparison: {
        showOnlyDifferentAssumptionColumns: false,
        filterByJurisdiction: [],
        filterByAuthor: []
      }
    },
    modulesVisited: [],
    columnsEditRequirementsNewBuildingsSF2022 : {
      version : 2,
      data: editRequirementsNewBuildingsSF2022Columns
    },
  }
})

export default {
  ...initialState()
}

