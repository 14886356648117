export default class DefaultAssumptionInput {

  constructor(obj = {}) {
    this.id = obj.id || null
    this.title = obj.title || ''
    this.content = obj.content || ''
    this.key = obj.key || ''
    this.value = obj.value || ''
    this.type_prototype_id = obj.type_prototype_id || ''
    this.study_type_id = obj.study_type_id || ''
    this.meta = obj.meta || null
    this.created_at = obj.created_at || ''
    this.updated_at = obj.updated_at || ''
  }
}