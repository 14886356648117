<template>
  <div 
    :class="isCalculating ? 'pointer-events-none opacity-30 cursor-not-allowed' : ''"
    class="w-full h-full flex flex-col overflow-y-auto p-8"
  >
    <h1 class="text-header-6">
      Inputs
    </h1>
    <div class="flex border p-3 space-x-6">
      <JurisdictionSelect
        :should-scroll="false"
        :input-class="'text-header-6'"
        :jurisdiction-selected="userInputs.jurisdiction || false"
        @change="onJurisdictionSelect"
      />
      <div
        v-if="userInputs.jurisdiction"
        class="w-full relative"
        style="max-width: 200px"
      >
        <VueSelect 
          v-model="userInputs.climate_zone"
          class="vue-select"
          label="raw"
          :reduce="item => item.raw"
          :options="userInputs.jurisdiction.climate_zones"
        />
        <label>Climate Zone</label>
      </div>
      
      <div 
        v-if="userInputs.climate_zone"
        class="w-full relative"
      >
        <VueSelect 
          v-model="userInputs.study_id"
          class="vue-select"
          label="title"
          :reduce="item => item.id"
          :options="studies"
        />
        <label>Existing Buildings Study Version </label>
      </div>

      <div
        v-if="userInputs.study_id"
        class="w-full relative"
        style="max-width: 350px"
      >
        <VueSelect 
          v-model="userInputs.prototype_id"
          class="vue-select"
          label="title"          
          :reduce="item => item.id"
          :options="$store.getters['globalEntities/Prototype/getterGlobalPrototypes']({ study_id: userInputs.study_id })"
        />
        <label>Prototype</label>
      </div>
      
      <div
        v-if="userInputs.study_id"
        class="w-full relative"
        style="max-width: 350px"
      >
        <VueSelect 
          v-model="userInputs.vintage_id"
          class="vue-select"
          label="title"
          :reduce="item => item.id"
          :options="$store.getters['globalEntities/Vintage/getterGlobalVintages']({ study_id: userInputs.study_id })"
        />
        <label>Vintage</label>
      </div>
    </div>
    
    <div
      v-if="hasEnoughForTargetScore"
      class="flex border border-ash-200 p-3 space-x-6 mt-6"
    >
      <AppButton
        v-if="baseTargetScore"
        label="Refresh Compliance Cost Range"
        @click="getMeasureMenuImpacts()" 
      />
      <!--      <AppButton-->
      <!--        v-if="baseTargetScore"-->
      <!--        label="Get Chart Forecast"-->
      <!--        @click="getMeasureMenuImpactsForecast()" -->
      <!--      />-->
      <InputWithLabel
        v-if="baseTargetScore"
        type="number"
        label="Base Target Score"
        :value="baseTargetScore"
        :disabled="true"
        class="max-w-xs"
        :tooltip="`This target score represents all possible measures from the selected CZ, Prototype and Vintage selected`"
      />
      <InputWithLabel
        v-if="baseTargetScore"
        v-model="targetScore"
        type="number"
        label="Target Score"
        class="max-w-xs"
      />
      <InputWithLabel
        v-model="userInputs.contingentPercentage"
        type="number"
        label="Contingent Unities Percentage"
        class="max-w-xs"
      />

      <div
        class="w-full relative"
        style="max-width: 250px;"
      >
        <VueSelect
          v-model="userInputs.calculationMethod"
          class="vue-select"
          label="title"
          :reduce="item => item.value"
          :options="calculationMethods"
        />
        <label style="font-size: 12px; line-height: 16px; color: #64666D !important;">Calculation Method</label>
      </div>
    </div>
    
    <div class="grid grid-cols-3 my-8 gap-8">
      <div
        v-if="studyDataForAllPossibleMeasures.length > 0"
        class="flex flex-col space-y-8"
      >
        <table          
          class="border-collapse border text-left w-full"
        >
          <thead>
            <tr>
              <th
                colspan="4"
                class="font-normal text-header-6"
              >
                All Possible ({{ calculationMethodTitle }}) Measures ({{ studyDataForAllPossibleMeasures.length }})<br>
                <small
                  v-if="getPrototype && getVintage"
                  class="italic"
                >{{ getPrototype.title }} / {{ getVintage.title }} / {{ userInputs.climate_zone }}</small>
              </th>
            </tr>
            <tr>
              <th class="text-header-4 font-bold italic">
                Measure
              </th>
              <th
                class="text-header-4 font-bold italic"
                width="40"
              >
                Score
              </th>
              <th
                class="text-header-4 font-bold italic"
                width="20"
              >
                Excluded
              </th>
              <th
                class="text-header-4 font-bold italic"
                width="20"
              >
                Mandatory
              </th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="study_data in studyDataForAllPossibleMeasures" 
              :key="study_data.id"
              class="hover:bg-ash-200"
              :class="`measure-${study_data.measure_id}`"
              @mousedown="blinkMeasureRow(study_data.measure_id, true)"
              @mouseup="blinkMeasureRow(study_data.measure_id, false)"
            >
              <td>{{ study_data.measure.title }}</td>
              <td>{{ study_data.measure_score }}</td>
              <td
                :class="{ 'bg-red': userInputs['measures_excluded'].includes(+study_data.measure_id), 'bg-black': isMandatoryOrExcludedDisabled(study_data) }"
                @click="toggleMeasure('measures_excluded', study_data.measure_id, study_data)"
              />
              <td 
                :class="{'bg-deepsky-300': userInputs['measures_mandatory'].includes(+study_data.measure_id), 'bg-black': isMandatoryOrExcludedDisabled(study_data) }"
                @click="toggleMeasure('measures_mandatory', study_data.measure_id, study_data)"
              />
            </tr>
          </tbody>
        </table>

        <table 
          v-if="studyDataWithoutAllPossibleMeasures.length > 0"
          class="border-collapse border text-left w-full"
        >
          <thead>
            <tr>
              <th
                colspan="4"
                class="font-normal text-header-6"
              >
                Measures without the All Possible ({{ studyDataWithoutAllPossibleMeasures.length }})
              </th>
            </tr>
            <tr>
              <th class="text-header-4 font-bold italic">
                Measure
              </th>
              <th
                class="text-header-4 font-bold italic"
                width="40"
              >
                Score
              </th>
              <th
                class="text-header-4 font-bold italic"
                width="20"
              >
                Excluded
              </th>
              <th
                class="text-header-4 font-bold italic"
                width="20"
              >
                Mandatory
              </th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="study_data in studyDataWithoutAllPossibleMeasures" 
              :key="study_data.id"
              class="hover:bg-ash-200"
              :class="`measure-${study_data.measure_id}`"
              @mousedown="blinkMeasureRow(study_data.measure_id, true)"
              @mouseup="blinkMeasureRow(study_data.measure_id, false)"
            >
              <td>{{ study_data.measure.title }}</td>
              <td>{{ study_data.measure_score }}</td>
              <td
                :class="{ 'bg-red': userInputs['measures_excluded'].includes(+study_data.measure_id), 'bg-black': isMandatoryOrExcludedDisabled(study_data) }"
                @click="toggleMeasure('measures_excluded', study_data.measure_id, study_data)"
              />
              <td 
                :class="{ 'bg-deepsky-300': userInputs['measures_mandatory'].includes(+study_data.measure_id), 'bg-black': isMandatoryOrExcludedDisabled(study_data) }"
                @click="toggleMeasure('measures_mandatory', study_data.measure_id, study_data)"
              />
            </tr>
          </tbody>
        </table>
      </div> <!-- grid-col-1-->

      <div
        v-if="complianceCostRangeStudyDataSorted.length > 0"
        class=""
      >
        <table class="border-collapse text-left w-full">
          <thead>
            <tr>
              <th
                colspan="9"
                class="font-normal text-header-6"
              >
                Measures Used for Combinations ({{ complianceCostRangeStudyDataSorted.length }}) <br>
                <small class="italic">All measures from the related Study Data whithout the excluded and mandatory measures</small>
              </th>
            </tr>
            <tr>
              <!-- <th @click="toogleSortBy('study_row_number')" class="text-header-4 font-bold italic">Row</th> -->
              <th
                class="text-header-4 font-bold italic"
                @click="toogleSortBy('id')"
              >
                ID
              </th>
              <th
                class="text-header-4 font-bold italic"
                @click="toogleSortBy('title')"
              >
                Measure
              </th>
              <th
                class="text-header-4 font-bold italic"
                @click="toogleSortBy('score')"
              >
                Score
              </th>
              <th class="text-header-4 font-bold italic">
                Cost/Score
              </th>
              <th
                class="text-header-4 font-bold italic"
                @click="toogleSortBy('initial_cost')"
              >
                Initial Cost
              </th>
              <th
                class="text-header-4 font-bold italic"
                @click="toogleSortBy('is_contingent')"
              >
                Is Contigent?
              </th>
              <th
                class="text-header-4 font-bold italic"
                @click="toogleSortBy('isFullyRepresentedPackage')"
              >
                Is isFRP?
              </th>
              <th
                class="text-header-4 font-bold italic"
                @click="toogleSortBy('is_package')"
              >
                Is Package?
              </th>
              <th class="text-header-4 font-bold italic">
                Exclude Measures
              </th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="study_data in complianceCostRangeStudyDataSorted" 
              :key="study_data.id"
              class="hover:bg-ash-200"
              :class="`measure-${study_data.measure_id}`"
              @mousedown="blinkMeasureRow(study_data.measure_id, true)"
              @mouseup="blinkMeasureRow(study_data.measure_id, false)"
            >
              <!-- <td>{{study_data.id}}</td> -->
              <td>{{ study_data.measure_id }}</td>
              <td>{{ study_data.measure.title }}</td>
              <td>{{ getMeasureScore(study_data) }}</td>          
              <td>{{ formatCurrency(study_data.initial_cost/getMeasureScore(study_data)) }}</td>          
              <td>{{ formatCurrency(study_data.initial_cost) }}</td>          
              <td>{{ study_data.measure.is_contingent ? 'Yes' : '' }}</td>
              <td>{{ study_data.measure.isFullyRepresentedPackage ? 'Yes' : '' }}</td>
              <td>{{ study_data.measure.is_package ? 'Yes' : '' }}</td>
              <td>{{ study_data.measure.exclude_measures }}</td>          
            </tr>
          </tbody>
        </table>
      </div>
      <!-- grid-col-2 -->

      <div
        v-if="complianceCostRange"
        class="border-4 border-red space-y-8 p-4"
      >
        <table
          v-for="(method, index) in complianceCostRange"
          :key="index"
          class="border-collapse text-left w-full"
        >
          <thead>
            <tr>
              <th
                colspan="4"
                class="font-normal text-header-6 text-red"
              >
                Ordered Combinations - Method ({{ index + 1 }}) {{ !index ? '(Contingents)' : '(Without Contingents)' }} <br>  <br>
                <small class="italic">
                  <strong>Lowest Implementation Cost</strong> {{ formatCurrency(method.lowestCombinationIncrementalCost) }} <br>
                  <strong>All Combinations</strong> {{ method.combinationsCount }} <br>
                  <strong>Valid Combinations</strong> {{ method.combinationsValidCount }} <br>
                  Min Target Score {{ method.minTargetScore }} <br>
                </small>
              </th>
            </tr>
            <tr>
              <th class="text-header-4 font-bold italic" />
              <th class="text-header-4 font-bold italic">
                Measure
              </th>
              <th class="text-header-4 font-bold italic">
                Score
              </th>
              <th class="text-header-4 font-bold italic">
                Initial cost
              </th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="study_data in method.combinationWithLowestIncrementalCost" 
              :key="study_data.id"
              class="hover:bg-ash-200"
              :class="`measure-${study_data.measure_id}`"
              @mousedown="blinkMeasureRow(study_data.measure_id, true)"
              @mouseup="blinkMeasureRow(study_data.measure_id, false)"
            >
              <td>{{ study_data.measure_id }}</td>
              <td>{{ study_data.measure.title }}</td>
              <td>{{ getMeasureScore(study_data) }}</td>          
              <td>{{ formatCurrency(study_data.initial_cost) }}</td>
            </tr>
            <tr>              
              <td colspan="2">
                Total
              </td>
              <td>{{ getCombinationTargetScore(method.combinationWithLowestIncrementalCost) }}</td>
              <td>{{ formatCurrency(getCombinationIncrementalCost(method.combinationWithLowestIncrementalCost)) }}</td>
            </tr>
          </tbody>
        </table>
        <!-- <pre>{{complianceCostRange}}</pre> -->
      </div>
      <!-- grid-col-3 -->
      
      <div
        v-if="permutations"
        class="border-4 border-lime-600 space-y-8 p-4"
      >
        <table
          v-for="(method, index) in permutations"
          :key="index"
          class="border-collapse text-left w-full"
        >
          <thead>
            <tr>
              <th
                colspan="12"
                class="font-normal text-header-6 text-lime-600"
              >
                Permutations - Method ({{ index + 1 }}) {{ !index ? '(Contingents)' : '(Without Contingents)' }} <br>  <br>
                <small class="italic">
                  <strong>Lowest Implementation Cost</strong> {{ formatCurrency(method.lowestCombinationIncrementalCost) }} <br>
                  <strong>All Combinations</strong> {{ method.combinationsCount }} <br>
                  <strong>Valid Combinations</strong> {{ method.combinationsValidCount }} <br>
                  Min Target Score {{ method.minTargetScore }} <br>
                </small><br>
                City Wide Estimates and lowest initial cost possible combination:
              </th>
            </tr>
            <tr>
              <th
                class="text-header-4 font-bold italic"
                colspan="2"
              >
                Affected Units
              </th>
              <th
                class="text-header-4 font-bold italic"
                colspan="2"
              >
                Emissions Reductions
              </th>
              <th
                class="text-header-4 font-bold italic"
                colspan="2"
              >
                Compliance Cost
              </th>
              <th
                class="text-header-4 font-bold italic"
                colspan="2"
              >
                Electricity Savings
              </th>
              <th
                class="text-header-4 font-bold italic"
                colspan="2"
              >
                Lifecycle Savings
              </th>
              <th
                class="text-header-4 font-bold italic"
                colspan="2"
              >
                Gas Savings
              </th>
            </tr>
            <tr>
              <td colspan="2">
                {{ formatStudyData('forecast_units_affected', rangedData[index].forecast_units_affected.min, {}) }}
              </td>
              <td colspan="2">
                {{ formatStudyData('forecast_emissions_savings', rangedData[index].forecast_emissions_savings.min) }} - {{ formatStudyData('forecast_emissions_savings', rangedData[index].forecast_emissions_savings.max) }}
              </td>
              <td colspan="2">
                {{ formatStudyData('forecast_initial_cost', rangedData[index].forecast_initial_cost.min) }} - {{ formatStudyData('forecast_initial_cost', rangedData[index].forecast_initial_cost.max) }}
              </td>
              <td colspan="2">
                {{ formatStudyData('forecast_kwh_savings', rangedData[index].forecast_kwh_savings.min) }} - {{ formatStudyData('forecast_kwh_savings', rangedData[index].forecast_kwh_savings.max) }}
              </td>
              <td colspan="2">
                {{ formatStudyData('forecast_lifecycle_savings', rangedData[index].forecast_lifecycle_savings.min) }} - {{ formatStudyData('forecast_lifecycle_savings', rangedData[index].forecast_lifecycle_savings.max) }}
              </td>
              <td colspan="2">
                {{ formatStudyData('forecast_therms_savings', rangedData[index].forecast_therms_savings.min) }} - {{ formatStudyData('forecast_therms_savings', rangedData[index].forecast_therms_savings.max) }}
              </td>
            </tr>
            <tr>
              <th
                class="text-header-4 font-bold italic"
                colspan="4"
              >
                Measure
              </th>
              <th class="text-header-4 font-bold italic">
                Score
              </th>
              <th class="text-header-4 font-bold italic">
                Initial cost
              </th>
              <th class="text-header-4 font-bold italic">
                Affected Units
              </th>
              <th class="text-header-4 font-bold italic">
                Emissions Reductions
              </th>
              <th class="text-header-4 font-bold italic">
                Compliance Cost
              </th>
              <th class="text-header-4 font-bold italic">
                Electricity Savings
              </th>
              <th class="text-header-4 font-bold italic">
                Lifecycle Savings
              </th>
              <th class="text-header-4 font-bold italic">
                Gas Savings
              </th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="study_data in method.combinationWithLowestIncrementalCost" 
              :key="study_data.id"
              class="hover:bg-ash-200"
              :class="`measure-${study_data.measure_id}`"
              @mousedown="blinkMeasureRow(study_data.measure_id, true)"
              @mouseup="blinkMeasureRow(study_data.measure_id, false)"
            >
              <td>{{ study_data.measure_id }}</td>
              <td colspan="3">
                {{ study_data.measure.title }}
              </td>
              <td>{{ getMeasureScore(study_data) }}</td>
              <td>{{ formatCurrency(study_data.study_data.initial_cost) }}</td>
              <td>{{ formatStudyData('forecast_units_affected', +study_data.data.forecast_units_affected, {}) }}</td>
              <td>{{ formatStudyData('forecast_emissions_savings', study_data.data.forecast_emissions_savings) }}</td>
              <td>{{ formatStudyData('forecast_initial_cost', study_data.data.forecast_initial_cost) }}</td>
              <td>{{ formatStudyData('forecast_kwh_savings', study_data.data.forecast_kwh_savings) }}</td>
              <td>{{ formatStudyData('forecast_lifecycle_savings', study_data.data.forecast_lifecycle_savings) }}</td>
              <td>{{ formatStudyData('forecast_therms_savings', study_data.data.forecast_therms_savings) }}</td>
            </tr>
            <tr>              
              <td colspan="4">
                Total
              </td>
              <td>{{ getCombinationTargetScore(method.combinationWithLowestIncrementalCost) }}</td>
              <td>{{ formatCurrency(getCombinationIncrementalCost(method.combinationWithLowestIncrementalCost)) }}</td>
              <td>{{ formatStudyData('forecast_units_affected', getCombinationIncrementalCost(method.combinationWithLowestIncrementalCost, 'forecast_units_affected'), {}) }}</td>
              <td>{{ formatStudyData('forecast_emissions_savings', getCombinationIncrementalCost(method.combinationWithLowestIncrementalCost, 'forecast_emissions_savings')) }}</td>
              <td>{{ formatStudyData('forecast_initial_cost', getCombinationIncrementalCost(method.combinationWithLowestIncrementalCost, 'forecast_initial_cost')) }}</td>
              <td>{{ formatStudyData('forecast_kwh_savings', getCombinationIncrementalCost(method.combinationWithLowestIncrementalCost, 'forecast_kwh_savings')) }}</td>
              <td>{{ formatStudyData('forecast_lifecycle_savings', getCombinationIncrementalCost(method.combinationWithLowestIncrementalCost, 'forecast_lifecycle_savings')) }}</td>
              <td>{{ formatStudyData('forecast_therms_savings', getCombinationIncrementalCost(method.combinationWithLowestIncrementalCost, 'forecast_therms_savings')) }}</td>
            </tr>
          </tbody>
        </table>

        <div v-if="mandatoryOnlyCityWideImpacts">
          <span class="block mt-3 mb-3 font-normal text-header-6 text-blue">City Wide Impacts (Mandatory Measures)</span>
          <table class="border-collapse text-left w-full">
            <thead>
              <tr>
                <th
                  class="text-header-4 font-bold italic"
                >
                  Affected Units
                </th>
                <th
                  class="text-header-4 font-bold italic"
                >
                  Emissions Reductions
                </th>
                <th
                  class="text-header-4 font-bold italic"
                >
                  Compliance Cost
                </th>
                <th
                  class="text-header-4 font-bold italic"
                >
                  Electricity Savings
                </th>
                <th
                  class="text-header-4 font-bold italic"
                >
                  Lifecycle Savings
                </th>
                <th
                  class="text-header-4 font-bold italic"
                >
                  Gas Savings
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ formatStudyData('forecast_units_affected', mandatoryOnlyCityWideImpacts.forecast_units_affected, {}) }}
                </td>
                <td>
                  {{ formatStudyData('forecast_emissions_savings', mandatoryOnlyCityWideImpacts.forecast_emissions_savings) }}
                </td>
                <td>
                  {{ formatStudyData('forecast_initial_cost', mandatoryOnlyCityWideImpacts.forecast_initial_cost) }}
                </td>
                <td>
                  {{ formatStudyData('forecast_kwh_savings', mandatoryOnlyCityWideImpacts.forecast_kwh_savings) }}
                </td>
                <td>
                  {{ formatStudyData('forecast_lifecycle_savings', mandatoryOnlyCityWideImpacts.forecast_lifecycle_savings) }}
                </td>
                <td>
                  {{ formatStudyData('forecast_therms_savings', mandatoryOnlyCityWideImpacts.forecast_therms_savings) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="contingentWeightedOnlyCityWideImpacts">
          <span class="block mt-3 mb-3 font-normal text-header-6 text-blue">City Wide Impacts (Contingent Weighted)</span>
          <table class="border-collapse text-left w-full">
            <thead>
              <tr>
                <th
                  class="text-header-4 font-bold italic"
                >
                  Affected Units
                </th>
                <th
                  class="text-header-4 font-bold italic"
                >
                  Emissions Reductions
                </th>
                <th
                  class="text-header-4 font-bold italic"
                >
                  Compliance Cost
                </th>
                <th
                  class="text-header-4 font-bold italic"
                >
                  Electricity Savings
                </th>
                <th
                  class="text-header-4 font-bold italic"
                >
                  Lifecycle Savings
                </th>
                <th
                  class="text-header-4 font-bold italic"
                >
                  Gas Savings
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ formatStudyData('forecast_units_affected', contingentWeightedOnlyCityWideImpacts.forecast_units_affected, {}) }}
                </td>
                <td>
                  {{ formatStudyData('forecast_emissions_savings', contingentWeightedOnlyCityWideImpacts.forecast_emissions_savings) }}
                </td>
                <td>
                  {{ formatStudyData('forecast_initial_cost', contingentWeightedOnlyCityWideImpacts.forecast_initial_cost) }}
                </td>
                <td>
                  {{ formatStudyData('forecast_kwh_savings', contingentWeightedOnlyCityWideImpacts.forecast_kwh_savings) }}
                </td>
                <td>
                  {{ formatStudyData('forecast_lifecycle_savings', contingentWeightedOnlyCityWideImpacts.forecast_lifecycle_savings) }}
                </td>
                <td>
                  {{ formatStudyData('forecast_therms_savings', contingentWeightedOnlyCityWideImpacts.forecast_therms_savings) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="finalCityWideImpacts">
          <span class="block mt-3 mb-3 font-normal text-header-6 text-blue">City Wide Impacts (Flexible Weighted Measures + Mandatory Measures)</span>
          <table class="border-collapse text-left w-full">
            <thead>
              <tr>
                <th
                  class="text-header-4 font-bold italic"
                >
                  Affected Units
                </th>
                <th
                  class="text-header-4 font-bold italic"
                >
                  Emissions Reductions
                </th>
                <th
                  class="text-header-4 font-bold italic"
                >
                  Compliance Cost
                </th>
                <th
                  class="text-header-4 font-bold italic"
                >
                  Electricity Savings
                </th>
                <th
                  class="text-header-4 font-bold italic"
                >
                  Lifecycle Savings
                </th>
                <th
                  class="text-header-4 font-bold italic"
                >
                  Gas Savings
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ formatStudyData('forecast_units_affected', finalCityWideImpacts.forecast_units_affected, {}) }}
                </td>
                <td>
                  {{ formatStudyData('forecast_emissions_savings', finalCityWideImpacts.forecast_emissions_savings) }}
                </td>
                <td>
                  {{ formatStudyData('forecast_initial_cost', finalCityWideImpacts.forecast_initial_cost) }}
                </td>
                <td>
                  {{ formatStudyData('forecast_kwh_savings', finalCityWideImpacts.forecast_kwh_savings) }}
                </td>
                <td>
                  {{ formatStudyData('forecast_lifecycle_savings', finalCityWideImpacts.forecast_lifecycle_savings) }}
                </td>
                <td>
                  {{ formatStudyData('forecast_therms_savings', finalCityWideImpacts.forecast_therms_savings) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- grid-col-4 -->

      <div
        v-if="impactForecastByTargetScore"
        class="border-4 border-lime-600 space-y-8 p-4"
      >
        <table class="border-collapse text-left w-full">
          <thead>
            <tr>
              <th
                colspan="4"
                class="font-normal text-header-6 text-lime-600"
              >
                Implementation Cost Forecast <br>  <br>
              </th>
            </tr>
            <tr>              
              <th class="text-header-4 font-bold italic">
                Score
              </th>
              <th class="text-header-4 font-bold italic">
                Low <br><small class="font-normal">(Permutations, Contingent/M1)</small>
              </th>
              <th class="text-header-4 font-bold italic">
                High <br><small class="font-normal">(Permutations, NoContingent/M2)</small>
              </th>
              <th class="text-header-4 font-bold italic">
                Range
              </th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="index in Number(baseTargetScoreForRange)" 
              :key="index"
              class="hover:bg-ash-200"              
            >
              <td>{{ index }}</td>
              <td>{{ formatCurrency(impactForecastByTargetScore[0][index]) }}</td>
              <td>{{ formatCurrency(impactForecastByTargetScore[1][index]) }}</td>
              <td>{{ formatCurrency(roundToRange(impactForecastByTargetScore[0][index])) }} - {{ formatCurrency(roundToRange(impactForecastByTargetScore[1][index])) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>


    <div
      v-if="complianceCostRange"
      class="grid grid-cols-2 gap-8"
    >
      <div class="border-4 border-red p-4">
        <h1 class="text-header-6 py-4">
          Ordered Combinations
        </h1>
        <div class="grid grid-cols-2 gap-8">
          <div
            v-for="(method, index) in complianceCostRange"
            :key="index"
          >
            <table class="border-collapse text-left w-full">
              <thead>
                <tr>
                  <th
                    colspan="4"
                    class="font-normal text-header-6 text-red"
                  >
                    Method ({{ index + 1 }}) {{ !index ? '(Contingents)' : '(Without Contingents)' }} <br>  <br>
                    <small class="italic">                
                      <strong>All Combinations</strong> {{ method.combinationsCount }}                
                      <strong>Valid Combinations</strong> {{ method.combinationsValidCount }}                
                    </small>
                  </th>
                </tr>
                <tr>
                  <th class="text-header-4 font-bold italic">
                    Index
                  </th>
                  <th class="text-header-4 font-bold italic">
                    Measures
                  </th>
                  <th class="text-header-4 font-bold italic">
                    Score
                  </th>
                  <th class="text-header-4 font-bold italic">
                    Initial cost
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  v-for="(combination, combinationIndex) in method.combinations" 
                  :key="combinationIndex"
                  class="hover:bg-ash-200"            
                >
                  <td>{{ index + 1 }}</td>
                  <td><pre>{{ combination.map(s => s.measure.title) }}</pre></td>
                  <td>{{ getCombinationTargetScore(combination) }}</td>          
                  <td>{{ formatCurrency(getCombinationIncrementalCost(combination)) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
      <div class="border-4 border-lime-600 p-4">
        <h1 class="text-header-6 py-4">
          Permutations
        </h1>
        <div class="grid grid-cols-2 gap-8">
          <div
            v-for="(method, index) in permutations"
            :key="index"
          >
            <table class="border-collapse text-left w-full">
              <thead>
                <tr>
                  <th
                    colspan="4"
                    class="font-normal text-header-6 text-lime-600"
                  >
                    Method ({{ index + 1 }}) {{ !index ? '(Contingents)' : '(Without Contingents)' }} <br>  <br>
                    <small class="italic">                
                      <strong>All Combinations</strong> {{ method.combinationsCount }}                
                      <strong>Valid Combinations</strong> {{ method.combinationsValidCount }}                
                    </small>
                  </th>
                </tr>
                <tr>
                  <th class="text-header-4 font-bold italic">
                    Index
                  </th>
                  <th class="text-header-4 font-bold italic">
                    Measures
                  </th>
                  <th class="text-header-4 font-bold italic">
                    Score
                  </th>
                  <th class="text-header-4 font-bold italic">
                    Initial cost
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  v-for="(combination, combinationIndex) in method.combinations"
                  :key="combinationIndex"
                  class="hover:bg-ash-200"
                >
                  <td>{{ combinationIndex + 1 }}</td>
                  <td><pre>{{ combination.map(s => s.measure.title) }}</pre></td>
                  <td>{{ getCombinationTargetScore(combination) }}</td>
                  <td>{{ formatCurrency(getCombinationIncrementalCost(combination)) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomCombinationApiService from '@/services/api/CustomCombinationApiService'
import MeasureMenuImpactsService from '@/services/measure-menu/MeasureMenuImpactsService'
import { CUSTOM_COMBINATION_PRESETS } from '@/modules/app/jurisdiction/study-results/shared/enums.js'

const defaultUserInputs = {  
  climate_zone : '',
  study_id: null,
  prototype_id : '',
  vintage_id: '',
  jurisdiction: null,
  measures_excluded : [],
  measures_mandatory : [],
  contingentPercentage: 50,
  calculationMethod: CUSTOM_COMBINATION_PRESETS.ALL_POSSIBLE_ON_BILL_OR_TDV.value,
}

export default {
  name: 'MeasureMenuImpactsComplianceCost',
  data: () => ({
    userInputs: {...defaultUserInputs},
    
    isCalculating : false,  
    sortBy: 'target_score',
    sortDirection : 0,
    targetScore : 0,

    studyDataByMeasures: false,
    allPossibleMeasuresIds: [],
    complianceCostRange : false,
    complianceCostRangeStudyData: [],
    permutations: {},
    rangedData: [],
    cityWideMandatoryStudyDataRows: [],

    impactForecastByTargetScore: false,
  }),
  computed: {
    studies() {
      return this.$store.getters['globalEntities/Study/getterStudiesByStudyType']({ study_type_id : 1 }).map(study => {
        study.label = `${study.title} - ${study.released_at}`        
        return { ...study }
      })
    },
    getVintage() {
      return this.userInputs.vintage_id ? this.$store.getters['globalEntities/Vintage/getterGlobalVintage']({ id : this.userInputs.vintage_id }) : false
    },
    getPrototype() {
      return this.userInputs.prototype_id ? this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id : this.userInputs.prototype_id }) : false
    },
    measures() {
      return this.userInputs.study_id ? this.userInputs.study_id.measures : []
    },
    hasEnoughForTargetScore() {
      return this.userInputs.climate_zone && this.userInputs.prototype_id && this.userInputs.vintage_id
    },
    studyDataForAllPossibleMeasures() {
      if (this.allPossibleMeasuresIds) {
        return [...Object.values(this.studyDataByMeasures)].filter(study_data => this.allPossibleMeasuresIds.includes(study_data.measure_id))
      }
      return false
    },
    studyDataWithoutAllPossibleMeasures() {
      if (this.allPossibleMeasuresIds) {
        return [...Object.values(this.studyDataByMeasures)].filter(study_data => this.allPossibleMeasuresIds.includes(study_data.measure_id) ? false : true)
      }
      return false
    },
    complianceCostRangeStudyDataSorted() {
      return [...this.complianceCostRangeStudyData]
      .map((study_data) => {
        study_data.title = study_data.measure.title
        study_data.is_contingent = study_data.measure.is_contingent
        study_data.score = this.getMeasureScore(study_data)
        return study_data
      })
      .sort((a, b) => {
        return this.sortDirection ? b[this.sortBy] - a[this.sortBy] : a[this.sortBy] - b[this.sortBy]
      })
    },
    baseTargetScore() {
      if (this.studyDataForAllPossibleMeasures) {
        return this.studyDataForAllPossibleMeasures.reduce((acc, { measure, measure_score }) => {
          acc = this.checkMeasure('measures_excluded', measure.id) || this.checkMeasure('measures_mandatory', measure.id) ? acc : acc + measure_score
          return acc
        }, 0)
      }
      return false
    },
    baseTargetScoreForRange() {
      return this.targetScore > 0 ? this.targetScore : this.baseTargetScore
    },
    userInputPayload() {
      const { vintage_id, prototype_id, climate_zone, jurisdiction } = this.userInputs
      return vintage_id && prototype_id && climate_zone && jurisdiction ? {
        vintage_id, prototype_id, climate_zone_raw : climate_zone, jurisdiction
      } : false
    },
    complianceCostRangePayload() {
      if (!this.userInputPayload || !this.baseTargetScoreForRange) return false
      return { 
        studyDataArgs : this.userInputPayload, 
        minTargetScore: this.baseTargetScoreForRange,
        measuresExcludedIds: [...this.userInputs.measures_excluded],
        measuresMandatoryIds : [...this.userInputs.measures_mandatory],
      }
    },
    mandatoryOnlyCityWideImpacts() {
      return this.computeResumedCityWideImpacts(false, true)
    },
    contingentWeightedOnlyCityWideImpacts() {
      return this.computeResumedCityWideImpacts(true, false)
    },
    finalCityWideImpacts() {
      return this.computeResumedCityWideImpacts(true, true)
    },
    calculationMethods() {
      return Object.keys(CUSTOM_COMBINATION_PRESETS).reduce((acc, key) => {
        acc.push({title: CUSTOM_COMBINATION_PRESETS[key].label, value: CUSTOM_COMBINATION_PRESETS[key].value})
        return acc
      }, [])
    },
    calculationMethodTitle() {
      if (!this.userInputs.calculationMethod) return ''
      return `${CUSTOM_COMBINATION_PRESETS[this.userInputs.calculationMethod].label}`
    }
  },
  watch: {
    'userInputs.study_id'(value, prev) {
      this.resetVintageAndPrototypeIfStudyChanges(value, prev)
    },
    userInputs: {
      handler(userInputs) {        
        for(let key in userInputs) {
          if (key === 'jurisdiction') continue
          if (userInputs[key]) {
            const setValue = ['measures_excluded', 'measures_mandatory'].includes(key) ? JSON.stringify(userInputs[key]) : userInputs[key]
            this.setQueryValue(key, setValue)
          } else {
            this.removeQueryValue(key)
          }
        }
      },
      deep: true,
    },
    userInputPayload: {
      handler(payload) {
        if (payload) this.getBaseTargetScore()      
      },
      immediate: true
    },
    'userInputs.calculationMethod'() {
      this.getBaseTargetScore()
    },
    complianceCostRangePayload: {
      deep: true,
      handler(payload) {
        if (payload) this.getMeasureMenuImpacts()
      },
    },
  },
  async mounted(){
    document.getElementById('content-container').style['padding-left'] = '0'
    document.getElementById('sidebar-container').style['display'] = 'none'
    this.setUserInputsByRoute()
  },
  methods: {
    computeResumedCityWideImpacts(includeCombinations = true, includeMandatory = false) {

      if (this.permutations?.length !== 2) return null
      const mergeData = includeMandatory && this.cityWideMandatoryStudyDataRows && Array.isArray(this.cityWideMandatoryStudyDataRows) ? this.cityWideMandatoryStudyDataRows : []
      const contingentLowestCombination = [...(includeCombinations ? this.permutations[0].combinationWithLowestIncrementalCost : []), ...mergeData]
      const nonContingentLowestCombination = [...(includeCombinations ? this.permutations[1].combinationWithLowestIncrementalCost : []), ...mergeData]
      const items = ['initial_cost', 'forecast_units_affected', 'forecast_emissions_savings', 'forecast_initial_cost', 'forecast_kwh_savings', 'forecast_lifecycle_savings', 'forecast_therms_savings']
      let contingentPercentage = (+(this.userInputs.contingentPercentage ?? 50)) / 100
      return items.reduce((acc, key) => {
        const contingentValue = this.getCombinationIncrementalCost(contingentLowestCombination, key)
        const nonContingentValue = this.getCombinationIncrementalCost(nonContingentLowestCombination, key)
        if (key === 'forecast_units_affected')
          acc[key] = contingentValue || nonContingentValue
        else
          acc[key] = (contingentValue * contingentPercentage) + (nonContingentValue * (1 - contingentPercentage))
        return acc
      }, {})
    },
    isMandatoryOrExcludedDisabled(studyData) {
      if (this.userInputs?.measures_excluded?.map((x) => +x)?.includes(+studyData.measure_id) || this.userInputs?.measures_mandatory?.map((x) => +x)?.includes(+studyData.measure_id)) return false
      return Boolean(!this.isCalculating && !this.complianceCostRangeStudyData.find((x) => +x.measure_id === +studyData.measure_id))
    },
    onJurisdictionSelect(jurisdiction) {
      this.userInputs.jurisdiction = jurisdiction
      this.userInputs.climate_zone = jurisdiction.climate_zones?.[0]?.raw
      this.$store.dispatch('setUserLastJurisdictionAndFirstClimateZoneVisited', { value: jurisdiction, context: 'updateLastJurisdictionVisitedFromStudyResults'})
      this.pushToParams('jurisdiction_slug', jurisdiction.slug)
    },
    setUserInputsByRoute() {
      for (let key in this.userInputs) {
        if (['jurisdiction'].includes(key)) continue

        if (this.checkQueryHasKey(key)) {
          if (['measures_excluded', 'measures_mandatory'].includes(key)) {
            try {
              this.userInputs[key] = JSON.parse(this.$route.query[key]).map((x) => +x)
            } catch(e) {
              this.userInputs[key] = defaultUserInputs[key]
            }
          } else {
            this.userInputs[key] = isNaN(this.$route.query[key]) ? this.$route.query[key] : Number(this.$route.query[key])
          }
        } else {
          this.userInputs[key] = defaultUserInputs[key]
        }
      }

      if (this.$store.getters['lastJurisdictionVisited']) {
        this.userInputs.jurisdiction = this.$store.getters['lastJurisdictionVisited']
      }
    },    
    async getBaseTargetScore() {
      if (!this.userInputPayload) return false 
      this.studyDataByMeasures = await this.getStudyDataByMeasures()
      const { data } = await CustomCombinationApiService.getPresets(this.userInputPayload)
      this.allPossibleMeasuresIds = this.userInputs.calculationMethod ? data.presets[CUSTOM_COMBINATION_PRESETS[this.userInputs.calculationMethod].value].map(i => i.measure_id) : []
    },
    async getStudyDataByMeasures() {
      const studyData = await this.getStudyData(this.userInputPayload)
      return studyData.reduce((acc, study_data) => {
        study_data.measure_score = this.getMeasureScore(study_data)
        acc[study_data.measure_id] = study_data
        return acc
      },{})
    },
    checkMeasure(collection, measure_id) {
      const measures = this.userInputs[collection].length ? [...this.userInputs[collection]] : [ this.userInputs[collection]]
      return measure_id ? measures.includes(+`${measure_id}`) : false
    },
    toggleMeasure(collection, measure_id, study_data) {
      if (this.isMandatoryOrExcludedDisabled(study_data)) return
      if (this.userInputs[collection].includes(+`${measure_id}`)) {
        this.userInputs[collection] = [...this.userInputs[collection].filter(i => +i !== +measure_id)]
      } else {
        this.$set(this.userInputs, collection, [...this.userInputs[collection], +`${measure_id}` ])
      }
    },    
    getMeasureMenuImpacts() {
      this.isCalculating = true
      this.complianceCostRange = false
      this.complianceCostRangeStudyData = []
      this.getFlexibleForecastRange({...(this.complianceCostRangePayload || {}), debug: true})
        .then(({ methods, studyDataRows, rangedData, cityWideMandatoryStudyDataRows }) => {
        this.complianceCostRangeStudyData = studyDataRows
        this.cityWideMandatoryStudyDataRows = cityWideMandatoryStudyDataRows
        this.isCalculating = false
        this.permutations = methods
        this.rangedData = rangedData
      })
    },
    
    getMeasureMenuImpactsForecast() {
      this.isCalculating = true
      this.impactForecastByTargetScore = false
      this.getComplianceCostRangeImpacts(this.complianceCostRangePayload)
        .then((impacts) => {
          this.impactForecastByTargetScore = impacts
          this.isCalculating = false
        })
      
    },

    blinkMeasureRow(measure_id, status) {
      document.querySelectorAll(`.measure-${measure_id}`).forEach(item => {
        item.style.backgroundColor = status ? 'red' : 'transparent'
      })
    },
    toogleSortBy(sortBy) {
      if (this.sortBy === sortBy) {
        this.sortDirection = !this.sortDirection
      } else {
        this.sortBy = sortBy
      }
    },
    resetVintageAndPrototypeIfStudyChanges(value, prev) {      
      if (value && prev != value && prev !== null) {
        this.userInputs = {
          ...this.userInputs,
          vintage_id: '',
          prototype_id: ''
        }
      }
    },
    ...MeasureMenuImpactsService
  },
  
  
}
</script>

<style scoped lang="scss">
  table {    
    th {
      text-align: left;
    }

    td, th {
      padding: 10px;
      border: 1px solid lightgray;
    }
  }
</style>