// tailwind.config.js
module.exports = {

  prefix: '',

  content: [
    "./src/*.{html,js,ts,vue}",
    "./src/**/*.{html,js,ts,vue}",
    "./src/**/**/*.{html,js,ts,vue}",
    "./src/**/**/**/*.{html,js,ts,vue}",
    "./src/**/**/**/**/*.{html,js,ts,vue}",
    "./src/**/**/**/**/**/*.{html,js,ts,vue}",
    "./src/**/**/**/**/**/**/*.{html,js,ts,vue}",
    "./src/**/**/**/**/**/**/**/*.{html,js,ts,vue}",
  ],

  theme: {

    extend: {
      colors: {
  
        'pri': '#002A3A !important',
        'sec': '#4B8CA6 !important',
        'white': '#ffffff !important',
        'gray': '#76848A !important',
        'gray-light': '#E4EEF2 !important',
        'gray01': '#151C22 !important',
        'gray02': '#383B44 !important',
        'gray03': '#979797 !important',
        'gray04': '#64666D !important',
        'gray05': '#CDCCCC !important',
        'gray06': '#F7F7F7 !important',
        'gray07': '#F9F9FB !important',
        'bluebrand': '#002A3A !important',
        'blue02': '#4B8CA6 !important',
        'blue03': '#39B1BF !important',
        'blue04': '#82C2DD !important',
        'blue04-50': '#82C2DD50 !important',
        'blue04-10': '#82C2DD10 !important',
        'blue05': '#E4EEF2 !important',
        'blue05-50': '#E4EEF250 !important',
  
        'black': '#32322c !important',
        'purple': '#6700C4',
        'blue': '#00649a',
        'blue-light': '#24aaf2',
        'grey-light': '#EBEBEB',
        'grey': '#858585',
        'grey-medium': '#E5E5E5',
        'green-light': '#1ee489',
        'green': '#16B96E',
        'green-dark': '#5e8c61',
        'yellow': '#F8D832',
        'orange': '#ff6600',
        'smoke': 'rgba(78, 78, 78, 0.7)',
        'smoke-light': 'rgba(78, 78, 78, 0.3)',
        'black-20': 'rgba(0, 0, 0, 0.1)',
        'smoke-white': 'rgba(255, 255, 255, 0.8)',
        'white-10': 'rgba(255, 255, 255, 0.1)',
        'white-20': 'rgba(255, 255, 255, 0.2)',
        'red': '#D65C5A',
        'transparent': 'rgba(255, 255, 255, 0)',
  
        // Style Guide 2.0
        'deepsky-100' : '#F6F9FB',
        'deepsky-200' : '#E3EEF2',
        'deepsky-300' : '#C5DEE8',
        'deepsky-400' : '#82C0D9',
        'deepsky-450' : '#69A7BF',
        'deepsky-500' : '#4B8CA6',
        'deepsky-600' : '#2E6C84',
        'deepsky-700' : '#215163',
        'deepsky-800' : '#133D4D',
        'deepsky-900' : '#002A3A',
  
        'ash-0'   : '#FFFFFF',
        'ash-100' : '#FAFAFA',
        'ash-200' : '#EBEEF0',
        'ash-300' : '#D4D8D9',
        'ash-400' : '#A9AEB0',
        'ash-450' : '#949AA2',
        'ash-500' : '#81878A',
        'ash-600' : '#575F63',
        'ash-700' : '#3C4245',
        'ash-800' : '#262A2B',
        
        'lime-100': '#F8FCF9',
        'lime-200': '#DAEFE2',
        'lime-300': '#BCE2CC',
        'lime-400': '#80C99E',
        'lime-500': '#62BC87',
        'lime-600': '#44A06A',
        'lime-700': '#337850',
        'lime-800': '#225035',
        'lime-900': '#153221',
  
        'sunrise-100':'#FFF9F1',
        'sunrise-200':'#FDE9C8',
        'sunrise-300':'#FCD390',
        'sunrise-400':'#FABD59',
        'sunrise-500':'#DB8A06',
        'sunrise-600':'#C57C05',
        'sunrise-700':'#996104',
        'sunrise-800':'#633E03',
        'sunrise-900':'#422902',
        
        'candy-100':'#FFF7F7',
        'candy-200':'#FCEBEB',
        'candy-300':'#F5C3C2',
        'candy-400':'#EB8D8C',
        'candy-500':'#D65C5A',
        'candy-600':'#BD4A48',
        'candy-700':'#96302F',
        'candy-800':'#5E1514',
        'candy-900':'#330C0B',
        
        'violet':'#ee82ee',
        
      },
  
      fontFamily: {
        'sans': ['Lato', 'Roboto', 'Gotham Rounded A', 'Gotham Rounded B', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', 'sans-serif'],
      },
  
      fontSize: {
        'xxs': '.7rem',     // 12px
        'xs': '.85rem',     // 12px
        'sm': '14px',    // 14px
        'medium': '16px',    // 14px
        'base': '18px',     // 16px
        'lg': '24px',   // 18px
        'xl': '28px',    // 20px
        '2xl': '2rem',    // 24px
        '3xl': '2.5rem',  // 30px
        '4xl': '3rem',   // 36px
        '5xl': '4rem',      // 48px,
        '10': '10px',
        '11': '11px',
        '12': '12px',
        '14': '14px',
        '16': '16px',
        '18': '18px',
        '20': '20px',
        '22': '22px',
        '24': '24px',
      },
  
      fontWeight: {
        'light': 300,
        'normal': 400,
        'medium': 500,
        'semi-bold': 600,
        'bold': 700,
      },
  
      boxShadow: {
        sm: '2px 2px 6px 0px rgba(47, 49, 82, 0.1)',
        top: '-4px -4px 8px 0px rgba(47, 49, 82, 0.1)',
        default: '3px 3px 10px 0px rgba(47, 49, 82, 0.1)',
        'default-light': '3px 3px 10px 0px rgba(47, 49, 82, 0.05)',
        md: '4px 4px 20px 0px rgba(204,204,204,.4)',
        lg: '4px 4px 60px 0px rgba(47, 49, 82, 0.20)',
        divider: '1px 0px 0px #FFFFFF'
      },
  
      screens: {
        'xs': '384px',
        'sm': '576px',
        'md': '768px',
        'lg': '992px',
        'xl': '1200px',
        'xxl': '1400px',
        '2xl': '1536px',
      },
  
      zIndex: {
        '0': 0,
        '5': 5,
        '10': 10,
        '15': 15,
        '20': 20,
        '25': 25,
        '30': 30,
        '40': 40,
        '50': 50,
        '60': 60,
      },
  
      width: {
        'auto': 'auto',
        'px': '1px',
        '1': '0.25rem',
        '2': '0.5rem',
        '3': '0.75rem',
        '4': '1rem',
        '5': '1.25rem',
        '6': '1.5rem',
        '7': '1.75rem',
        '8': '2rem',
        '10': '2.5rem',
        '12': '3rem',
        '14': '3.5rem',
        '16': '4rem',
        '20': '5rem',
        '24': '6rem',
        '32': '8rem',
        '48': '12rem',
        '52': '15rem',
        '64': '16rem',
        '1/2': '50%',
        '1/3': '33.33333%',
        '2/3': '66.66667%',
        '1/4': '25%',
        '3/4': '75%',
        '1/5': '20%',
        '2/5': '40%',
        '3/5': '60%',
        '4/5': '80%',
        '1/6': '16.66667%',
        '2/6': '33.22%',
        '5/6': '83.33333%',
        'full': '100%',
        'screen': '100vw',
        'sidebar': '15rem',
        '100': '100px',
        '200': '200px',
        '260': '260px',
        '300': '300px',
      },
  
  
      height: {
        'auto': 'auto',
        'px': '1px',
        '1': '0.25rem',
        '2': '0.5rem',
        '3': '0.75rem',
        '4': '1rem',
        '5': '1.25rem',
        '6': '1.5rem',
        '7': '1.75rem',
        '8': '2rem',
        '9': '2.25rem',
        '10': '2.5rem',
        '12': '3rem',
        '13': '3.2rem',
        '14': '3.5rem',
        '16': '4rem',
        '20': '5rem',
        '22': '5.5rem',
        '24': '6rem',
        '32': '8rem',
        '48': '12rem',
        '56': '14rem',
        '64': '16rem',
        '80': '20rem',
        '90': '90vh',
        '99': '99vh',
        'full': '100%',
        '1/3': '33vh',
        '1/2': '50vh',
        '3/4': '75vh',
        '4/5': '80vh',
        'w-1/4': '8vw',
        'w-1/3': '11vw',
        'w-1/2': '17vw',
        'w-full': '33vw',
        'screen': '100vh',
      },
  
      minHeight: {
        'full': '100%',
        'screen': '100vh',
        '3/4': '75vh'
      },
  
      maxHeight: {
        'full': '100%',
        'screen': '100vh',
        '1/4': '25vh',
        '1/3': '33vh',
        '1/2': '50vh',
        '3/4': '75vh',
      },
  
      maxWidth: {
        'xs': '298px',
        'xs-sm':'420px',
        'sm': '576px',
        'md': '768px',
        'lg': '992px',
        'xl': '1200px',
        '1/4': '25%',
        '1/3': '33%',
        '1/2': '50%',
        '3/4': '75%',
        '8/10': '80%',
        '9/10': '90%',
        'full': '100%',
      },
  
      minWidth: {
        'xs': '20rem',
        'sm': '30rem',
        'md': '40rem',
        'lg': '50rem',
        'xl': '60rem',
        '1/4': '25%',
        '1/3': '33%',
        '1/2': '50%',
        '3/4': '75%',
        '8/10': '80%',
        '9/10': '90%',
        'full': '100%',
      },
  
      padding: {
        'px': '1px',
        '0': '0',
        '05': '0.1rem',
        '0.5': '0.125rem',
        '0.75': '0.188rem',
        '1': '0.25rem',
        '1/2': '0.375rem',
        '2': '0.5rem',
        '2/3': '0.625rem',
        '3': '0.75rem',
        '3/4': '0.875rem',
        '4': '1rem',
        '4/5': '1.125rem',
        '5': '1.25rem',
        '5/6':'1.375rem',
        '6': '1.5rem',
        '7': '1.75rem',
        '8': '2rem',
        '9': '2.25rem',
        '10': '2.5rem',
        '11': '2.75rem',
        '12': '3rem',
        '16': '4rem',
        '20': '5rem',
        '24': '6rem',
        '32': '8rem',
      },
  
      margin: {
        'auto': 'auto',
        'px': '1px',
        '0': '0',
        '0.5': '0.125rem',
        '1': '0.25rem',
        '1/2': '0.375rem',
        '2': '0.5rem',
        '2/3': '0.625rem',
        '3': '0.75rem',
        '4': '1rem',
        '4/5': '1.125rem',
        '5': '1.25rem',
        '5/6':'1.375rem',
        '6': '1.5rem',
        '7': '1.75rem',
        '8': '2rem',
        '10': '2.5rem',
        '11': '2.75rem',
        '12': '3rem',
        '13': '3.5rem',
        '16': '4rem',
        '20': '5rem',
        '24': '6rem',
        '32': '8rem',
        '44': '11rem',
        '48': '12rem',
        '-1': '-0.25rem',
        '-2': '-0.5rem',
        '-3': '-0.75rem',
        '-4': '-1rem',
        '-5': '-1.25rem',
        '-6': '-1.5rem',
        '-8': '-2rem',
        '-10': '-2.5rem',
        '-12': '-3rem',
        '-16': '-4rem',
        '-20': '-5rem',
        '-24': '-6rem',
        '-32': '-8rem',
      },
  
      borderWidth: {
        default: '1px',
        '0': '0',
        '2': '2px',
        '2/3': '1.5px',
        '3': '3px',
        '4': '4px',
        '6': '6px',
        '8': '8px',
      },
  
      borderRadius: {
        default: '10px',
        'none': '0',
        'xs': '2px',
        'sm': '4px',
        '6': '6px',
        'md': '8px',
        'lg': '20px',
        'full': '9999px',
      },
  
      opacity: {
        '10': '.1',
        '20': '.2',
        '30': '.3',
        '40': '.4',
        '50': '.5',
        '60': '.6',
        '70': '.7',
        '80': '.8',
        '90': '.9',
        '100': '1',
      },

      backgroundOpacity: {
        '10': '.1',
        '20': '.2',
        '30': '.3',
        '40': '.4',
        '50': '.5',
        '60': '.6',
        '70': '.7',
        '80': '.8',
        '90': '.9',
        '100': '1',
      },
      space:{
        '7':'1.75rem',
        '5/6':'1.375rem'
      }
    },

  },
  variants: {
    backgroundColor: ['responsive', 'hover', 'active', 'focus', 'group-hover', 'even', 'first'],
    border: ['responsive', 'hover', 'focus', 'even', 'last', 'first'],
    borderColor: ['responsive', 'hover', 'focus', 'group-hover', 'first'],
    borderRadius: ['responsive', 'hover', 'focus', 'last', 'first'],
    borderStyle: ['responsive', 'hover', 'focus', 'even', 'last', 'first'],
    borderWidth: ['responsive', 'hover', 'focus', 'even', 'last', 'first'],
    height: ['responsive'],
    margin: ['responsive', 'even', 'odd', 'last'],
    minWidth: ['responsive'],
    maxWidth: ['responsive'],
    padding: ['responsive', 'even'],
    opacity: ['responsive', 'hover', 'focus', 'active', 'group-hover', 'disabled'],
    textColor: ['responsive', 'hover', 'focus', 'group-hover', 'first'],
    textAlign: ['responsive'],
    display: ['responsive', 'last']
  },
  plugins: [
    require('tailwindcss-tables')()
  ],
  corePlugins: {
    backgroundOpacity: true
  },
}
