<template>
  <BaseDrawer
    ref="baseDrawer"
    title="Policy Comparison"
    class="app-drawer-content psui-w-full"
    drawer-classes="psui-bg-gray-10"
    drawer-width="41.5rem"
    :hierarchy="DRAWER_HIERARCHY.SECONDARY"
    @close="onCloseDrawer()"
  >  
    <div class="psui-mx-10 psui-flex psui-flex-col mb-44">
      <h4
        v-if="currentJurisdiction"
        data-test-id="compare-policies-for"
        class="psui-text-h4 psui-text-gray-80 psui-font-bold psui-mr-2 psui-flex-shrink-0 psui-flex items-center psui-mt-3"
      >
        {{ 'Compare Policies for' | policyTextRephrase }}
        <JurisdictionSelect
          v-if="getShowComparisonJurisdictionSelect"
          id="comparison-jurisdiction-select"
          data-test-id="comparison-drawer-select-jurisdiction"
          class="ml-2 pb-1"
          :should-scroll="false"
          :input-class="'psui-text-h5'"
          :jurisdiction-selected="currentJurisdiction"
          @closeInput="showInput = false"
          @change="setJurisdictionSelected"
        />
        <Dropdown
          v-else-if="getAvailableJurisdictions.length > 1 && !getShowComparisonJurisdictionSelect"
          ref="dropdownJurisdiction"
          data-test-id="jurisdiction-dropdown"
          :button-classes="'px-2 bg-transparent w-full'"
          :dropdown-dialog-classes="'left-0 bg-white z-25'"
          class="mt-0"
          :run-on-top="true"
        >
          <template #buttonLabel>            
            <span 
              v-if="currentJurisdiction"
              class="text-header-4 text-deepsky-500 border-b border-dashed border-gray05 pb-1 flex items-center mt-0"
            >
              {{ currentJurisdiction | editJurisdictionTitleTypeBasedOnJurisdictionType('title_type', null, null, true) }}
              <feather
                v-if="getAvailableJurisdictions.length > 1"
                class="ml-2"
                type="chevron-down"
                size="14"
              />
            </span>
          </template>
          <template #items>
            <DropdownMenuList              
              :items="getAvailableJurisdictions"
              :selected="currentJurisdiction.id"
              key-label="label"
              key-value="id"
              @update:selected="onJurisdictionSelect"
            />
          </template>
        </Dropdown>
        <span
          v-else
          class="text-header-4 text-deepsky-500 border-gray05 ml-2 font-normal"
        >
          {{ currentJurisdiction | editJurisdictionTitleTypeBasedOnJurisdictionType('title_type') }}
        </span>
      </h4>
      <p class="psui-mt-3 psui-mb-6 psui-text-gray-50 psui-text-p">
        {{ 'Select 2 or more policies to start' | policyTextRephrase }}
      </p>

      <div
        v-if="!policiesByJurisdiction.length"
        class="psui-w-full"
      >
        <h2 class="text-header-3 text-ash-400">
          You have no saved policies available for this jurisdiction.
        </h2>
      </div>
      <div
        v-else
        class="psui-w-full"
      >
        <div class="psui-flex psui-items-center psui-bg-white psui-mb-4 psui-px-6 psui-py-2 psui-rounded-md psui-shadow-elevation-5">
          <p class="psui-text-gray-80 psui-font-bold psui-mr-2">
            Filter by
          </p>
          <!-- Commented code according to DDEV-1869. We are not displaying policies for New Buildings yet -->
          <!-- Keep code for future use -->
          <!-- <div class="mr-1">
            <Dropdown
              button-classes="psui-bg-white"
            >
              <template v-slot:buttonLabel>
                <PsChips
                  label="Type"
                  type="button"
                  :checked="isFilteringByStudyType"
                />
              </template>
              <template v-slot:items>
                <PsCheckboxSimple
                  v-for="option in getGlobalStudyTypes"
                  :key="`filter-${option.slug}`"
                  :label="`${option.title}`"
                  size="small"
                  :input-value="checkIfFilterByStudyTypeHasBeenApplied(option.slug)"
                  :value="checkIfFilterByStudyTypeHasBeenApplied(option.slug)"
                  @change="setFilterByStudyType(option.slug)"
                />
              </template>
            </Dropdown>
          </div> -->
          <Dropdown
            ref="comparisonCreateDrawerAuthorFilter"
            button-classes="psui-bg-white"
          >
            <template #buttonLabel>
              <PsChips
                label="Author"
                type="button"
                :checked="isFilteringByAuthor"
              />
            </template>
            <template #items> 
              <PsCheckboxSimple
                v-for="option in getPolicyTypesByUserPolicyComparison"
                :key="`filter-${option.value}`"
                :label="`${option.label}`"
                size="small"
                :checked="checkIfFilterByAuthorHasBeenApplied(option.value)"
                @change="setFilterByAuthor(option.value)"
              />
            </template>
          </Dropdown>
        </div>

        <div 
          data-test-id="comparison-policies-select-drawer-policies-list"  
          class="psui-bg-white psui-rounded-md psui-shadow-elevation-5 psui-divide-y psui-divide-gray-20"
        >
          <div class="w-full psui-flex psui-items-center leading-none py-4 pl-4">
            <div class="psui-w-2/5 psui-items-center">
              <div
                class="app-checkbox-item psui-flex pl-1 items-center opacity-100-all-childrens-on-hover"
              >
                <PsCheckbox
                  class="app-checkbox-item cursor-pointer transition-all flex items-center"
                  label-classes="psui-w-auto psui-text-gray-80 psui-text-p psui-font-bold"
                  :label="'Policy Name' | policyTextRephrase"
                  :disabled="policiesFiltered.length <= 0"
                  :value="checkIfAllPoliciesAreSelected"
                  @change.native="toggleAllPolicies()"
                />
                <button
                  class="focus:outline-none flex items-center leading-none"
                  :class="{ 'opacity-0' : orderBy.column != 'title' }"
                >
                  <feather
                    size="16"
                    :type="orderBy.direction == 'asc' ? 'arrow-down' : 'arrow-up'"
                    class="text-blue ml-2 cursor-pointer"
                    @click="setOrderColumn('title')"
                  />
                </button>
              </div>
            </div>

            <div class="psui-flex psui-w-3/5 psui-items-center">
              <div class="psui-w-1/2 psui-flex opacity-100-all-childrens-on-hover">
                <h3 class="psui-text-p psui-font-bold psui-text-gray-80">
                  Type
                </h3>
                <button
                  class="focus:outline-none flex items-center leading-none"
                  :class="{ 'opacity-0' : orderBy.column != 'study_type' }"
                >
                  <feather
                    size="16"
                    :type="orderBy.direction == 'asc' ? 'arrow-down' : 'arrow-up'"
                    class="text-blue ml-2 cursor-pointer"
                    @click="setOrderColumn('study_type')"
                  />
                </button>
              </div>

              <div class="psui-w-1/2 psui-flex opacity-100-all-childrens-on-hover">
                <h3 class="psui-text-p psui-font-bold psui-text-gray-80">
                  Author
                </h3>
                <button
                  class="focus:outline-none flex items-center leading-none"
                  :class="{ 'opacity-0' : orderBy.column != 'author' }"
                >
                  <feather
                    size="16"
                    :type="orderBy.direction == 'asc' ? 'arrow-down' : 'arrow-up'"
                    class="text-blue ml-2 cursor-pointer"
                    @click="setOrderColumn('author')"
                  />
                </button>
              </div>
            </div> 
          </div>

          <div
            v-for="policy in policiesFiltered"
            :key="`comparison-policies-select-drawer-policy-${ policy.id }`"
            class="psui-w-full psui-flex psui-items-center psui-leading-none psui-py-4 psui-pl-4 psui-text-gray-60 opacity-100-all-childrens-on-hover"
          >
            <div class="psui-w-2/5 psui-pl-1 psui-items-center mr-auto">
              <PsCheckboxSimple
                data-test-id="comparison-policies-select-drawer-policy-checkbox"
                class="comparison-policy-name psui-cursor-pointer psui-transition-all"
                :label="policy.title"
                :checked="checkIfPolicyIsSelected(policy.id)"
                @change.native="toggleComparisonPolicySelected(policy.id)"
              />
            </div>

            <div class="psui-w-3/5 psui-flex psui-items-center">
              <div class="psui-w-1/2">
                <p class="psui-text-p">
                  {{ getPolicyStudyType(policy) }} 
                </p>
              </div>

              <div class="psui-w-1/2">
                <p class="psui-text-p">
                  {{ getPolicyTypeLabel(policy.id) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="comparison-policies-tooltip psui-w-full fixed bottom-0 psui-flex mt-auto psui-items-end psui-space-x-6 psui-pt-6 psui-pb-10 psui-pl-10 psui-bg-white psui-shadow-elevation-30 psui-border psui-border-gray-20"
    >
      <div class="ps-input">
        <PsInput
          data-test-id="comparison-policies-select-drawer-comparison-name-input"
          label="Comparison Name"
          placeholder="My comparison"
          layout="mini"
          :value="comparison.title"
          @input="comparisonData($event.target.value)"
        />
      </div>
      <PsRichTooltip
        position="top"
        :ignore-dialog="!getInputError"
      >
        <template #trigger>
          <span class="create-a-policy-button">
            <PsButton
              data-test-id="comparison-policies-select-drawer-create-comparison-button"
              :disabled="getInputError"
              label="Create comparison"
              size="big"
              @click="createComparison"
            />
          </span>
        </template>
        <template #content>
          {{ TooltipMessage() }}
        </template>
      </PsRichTooltip>
    </div>
  </BaseDrawer>
</template>

<script>
import { mapGetters } from 'vuex'
import { showPoliciesByOptions } from '@/business-logic/constants/policiesEnum'
import { POLICY_TYPES } from '@/modules/app/policy/shared/enums.js'
import { DRAWER_HIERARCHY, STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import Jurisdiction from '@/models/Jurisdiction'
import { toggleInArray } from '@igortrindade/lazyfy'
import Comparison from '@/models/Comparison'

export default {
  name: 'ComparisonPoliciesSelectDrawer',
  props: {
    jurisdiction: {
      type: [Object, Boolean],
      default: false
    },
    jurisdictions: {
      type: Array,
      required: true
    },
  },
  data: () => ({
    showPoliciesByOptions: Object.values(showPoliciesByOptions),
    showPoliciesBy: showPoliciesByOptions.ALL,
    orderBy: {
      column: 'title',
      direction: 'asc'
    },
    POLICY_TYPES,
    DRAWER_HIERARCHY,
    filterByAuthor: [],
    filterByStudyType: [],
    comparisonPolicyIds: [],
    comparison: {
      type: Object,
      default: () => new Comparison()
    },
    currentJurisdiction: null,
  }),
  computed: {
    ...mapGetters(['getterLoggedUser']),
    policiesByJurisdiction() {
      if(!this.currentJurisdiction) return []
      const jurisdictionPolicies = this.$store.getters['policy/getterUserPoliciesGroupedByJurisdiction'][ this.currentJurisdiction?.id ]
      return jurisdictionPolicies ? jurisdictionPolicies : []
    },
    policiesFilteredByJurisdiction() {
      const policiesByJurisdictionWithExtraKeys = this.policiesByJurisdiction?.map((policy) => {
        return {
          ...policy,
          study_type: policy.policy_containers?.[0]?.study_type_id,
          author: this.getPolicyType(policy.id)
        }
      }).filter(policy => {
        return policy.policy_containers?.some(container => container.study_type.slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS)
      })
      return policiesByJurisdictionWithExtraKeys ? policiesByJurisdictionWithExtraKeys?.filter((policy) => {
        if (this.showPoliciesBy === showPoliciesByOptions.SHARED ) {
          return this.getterLoggedUser && policy.user_id !== this.getterLoggedUser.id
        } else if ( this.showPoliciesBy === showPoliciesByOptions.USER ) {
          return policy.user_id === this.getterLoggedUser?.id || !this.getterLoggedUser
        } else {
          return true
        }
      })
      .sort((a, b) => {
        const key = this.orderBy.column
        const order = this.orderBy.direction
        if (!Object.prototype.hasOwnProperty.call(a, key) || !Object.prototype.hasOwnProperty.call(b, key)) {
          // property doesn't exist on either object
          return 0
        }

        const varA = (typeof a[key] === 'string')
          ? a[key].toUpperCase() : a[key]
        const varB = (typeof b[key] === 'string')
          ? b[key].toUpperCase() : b[key]

        let comparison = 0
        if (varA > varB) {
          comparison = 1
        } else if (varA < varB) {
          comparison = -1
        }
        return (
          (order === 'desc') ? (comparison * -1) : comparison
        )

      }) : []
    },
    getFilterByAuthor() {
      return this.filterByAuthor ?? []
    },
    isFilteringByAuthor() {
      return this.getFilterByAuthor.length > 0
    },
    getFilterByStudyType(){
      return this.filterByStudyType ?? []
    },
    isFilteringByStudyType() {
      return this.getFilterByStudyType.length > 0
    },
    getPolicyTypesByUserPolicyComparison(){
      let policyTypes = []

      if(this.$store.getters['getterLoggedUser']){
        for (const [key, value] of Object.entries(POLICY_TYPES) ) {
          if(key !== POLICY_TYPES.DRAFT.value) policyTypes.push(value)
        }
      } else {
        policyTypes = POLICY_TYPES
      }
      return policyTypes
    },
    getShowComparisonJurisdictionSelect(){
      return this.$store.getters['comparison/getterShowComparisonHeader']
    },
    lastJurisdictionVisited() {
      return new Jurisdiction(this.$store.getters['lastJurisdictionVisited'])
    },
    getAvailableJurisdictions() {
      return this.$store.getters['policy/getterJurisdictionsFromUserPolicies'] ?? []
    },
    policiesFiltered() {
      return this.policiesFilteredByJurisdiction
      .filter(this.filterPoliciesByAuthor)
      .filter(this.filterPoliciesByStudyType)
    },
    getGlobalStudyTypes(){
      return this.$store.getters['globalEntities/StudyType/getterGlobalStudyTypes']()
    },
    uniqueComparisonPolicyIds() {
      const policiesIds = this.comparisonPolicyIds
      const uniquePoliciesIds = [...new Set(policiesIds?.toString().split(',')?.map(id => isNaN(id) ? null : Number(id))?.filter(id => id != null && id != ''))]
      return uniquePoliciesIds
    },
    checkIfAllPoliciesAreSelected() {
      return this.policiesFiltered.length > 0 ? this.policiesFiltered.every(policy => this.comparisonPolicyIds.includes(policy.id)) : false
    },
    checkIfAtLeastTwoPoliciesAreSelected() {
      return this.uniqueComparisonPolicyIds.length >= 2 ? true : false
    },
    checkIfInputHasValue() {
      return this.comparison.title ? true : false
    },
    getInputError() {
      return !this.checkIfInputHasValue || !this.checkIfAtLeastTwoPoliciesAreSelected 
    },
    isUserLoggedIn() {
      return this.$store.getters['isUserLoggedIn']
    }
  },
  watch: {
    comparisonPolicyIds() {
      this.comparisonData()
    },
    currentJurisdiction() {
      this.resetState()
    },
  },
  mounted() {
    const currentJurisdiction = this.jurisdiction ? this.jurisdiction : this.lastJurisdictionVisited || null
    const setJurisdiction = this.getAvailableJurisdictions.findInArray({ id: currentJurisdiction?.id })
    if(setJurisdiction) this.currentJurisdiction = setJurisdiction
    else this.currentJurisdiction = this.getAvailableJurisdictions[0]
    if(!this.currentJurisdiction) this.currentJurisdiction = new Jurisdiction(this.lastJurisdictionVisited)
    this.$eventBus.$on('openComparisonPoliciesSelectDrawer', () => {      
      this.$refs.baseDrawer.openDrawer()
      this.hideIntercom()
    })
  },
  beforeDestroy() {
    this.showIntercom()
    this.$eventBus.$off('openComparisonPoliciesSelectDrawer')
  },
  methods: {
    resetState(){
      this.comparisonPolicyIds = []
      this.comparison = new Comparison() 
    },
    hideIntercom() {
      const intercom = document.querySelector('.intercom-app')
      if (intercom) {
        intercom.style.display = 'none'
      }
    },
    showIntercom(){
      const intercom = document.querySelector('.intercom-app')
      if (intercom) {
        intercom.style.display = 'block'
      }
    },
    setOrderColumn(column) {
      if(column == 'author') {
        this.orderBy.column = 'author'
        this.orderBy.direction = (this.orderBy.direction == 'asc') ? 'desc' : 'asc'
      } else if (column == 'title') {
        this.orderBy.column = 'title'
        this.orderBy.direction = (this.orderBy.direction == 'asc') ? 'desc' : 'asc'
      } else {
        this.orderBy.column = 'study_type'
        this.orderBy.direction = (this.orderBy.direction == 'asc') ? 'desc' : 'asc'
      }
    },
    checkIfFilterByAuthorHasBeenApplied(option) {
      return this.getFilterByAuthor.includes(option) ?? false
    },
    checkIfFilterByStudyTypeHasBeenApplied(option) {
      return this.getFilterByStudyType.includes(option) ?? false
    },
    setFilterByAuthor(authors) {
      const currentState = this.filterByAuthor
      let filterBy = currentState && currentState.length ? currentState : []
      this.filterByAuthor = toggleInArray(filterBy, authors)
      this.$refs.comparisonCreateDrawerAuthorFilter.close()
    },
    setFilterByStudyType(study_type_slug) {
      const currentState = this.filterByStudyType
      let filterBy = currentState && currentState.length ? currentState : []
      this.filterByStudyType = toggleInArray(filterBy, study_type_slug)
      this.comparisonPolicyIds = []
    },
    setJurisdictionSelected(jurisdiction){
      const setJurisdiction = this.getAvailableJurisdictions.findInArray({ id: jurisdiction.id })
      this.currentJurisdiction = setJurisdiction
    },
    onJurisdictionSelect(jurisdiction_id) {
      const jurisdiction = this.getAvailableJurisdictions.findInArray({ id: jurisdiction_id })
      if (jurisdiction) this.currentJurisdiction = jurisdiction
      this.$refs['dropdownJurisdiction'].close()
    },
    filterPoliciesByAuthor(policy) {
      let shouldInclude = false
      const policyType = this.$store.getters['policy/getterPolicyType'](policy.id)
      this.getFilterByAuthor.forEach(policy_by_author_selected => {
        if (policy_by_author_selected === policyType) shouldInclude = true
        if (policyType === POLICY_TYPES.DRAFT.value && policy_by_author_selected === POLICY_TYPES.OWN.value) shouldInclude = true
      })
      return shouldInclude || !this.getFilterByAuthor.length
    },
    filterPoliciesByStudyType(policy) {
      let shouldInclude = false
      const policySudyType = this.$store.getters['policy/getterPolicyStudyTypes']({policy_id: policy.id, policy })
      this.getFilterByStudyType.forEach(study_type_slug => {
        if (study_type_slug === policySudyType.toString()) shouldInclude = true
      })
      return shouldInclude || !this.getFilterByStudyType.length
    },
    toggleComparisonPolicySelected(policyId) {
      if(!policyId) return
      const currentComparisonPoliciesIds = this.comparisonPolicyIds
      const uniqueCurrentComparisonPoliciesIds = [...new Set(currentComparisonPoliciesIds?.toString().split(',')?.map(id => isNaN(id) ? null : Number(id))?.filter(id => id != null && id != ''))]
      const newComparisonPoliciesIds = toggleInArray(uniqueCurrentComparisonPoliciesIds, policyId)
      this.comparisonPolicyIds = newComparisonPoliciesIds
    },
    toggleAllPolicies() {
      const getPolicyFilteredIDs =  this.policiesFiltered.map(policy=>policy.id)

      if(this.policiesFiltered.length === 0) return

      if(this.checkIfAllPoliciesAreSelected) {
        this.comparisonPolicyIds = this.comparisonPolicyIds.filter(policy_id => !getPolicyFilteredIDs.includes(policy_id))
      } else {
        getPolicyFilteredIDs.forEach(policy => {
          if(!this.comparisonPolicyIds.includes(policy)){
            this.comparisonPolicyIds.push(policy)
          }
        })
      }
    },
    TooltipMessage() {
      if (!this.checkIfAtLeastTwoPoliciesAreSelected) {
        return 'Select 2 or more policies to start'
      } else if (this.checkIfAtLeastTwoPoliciesAreSelected && !this.checkIfInputHasValue) {
        return 'Give a name for your comparison first'
      } 
    },
    comparisonData(title){
      this.comparison.jurisdiction_id = this.currentJurisdiction.id
      this.comparison.description = null
      this.comparison.policy_ids = this.comparisonPolicyIds.toString()
      if (this.isUserLoggedIn) {
        this.comparison.user_id = this.getterLoggedUser.id
      }
      if (title === undefined) return
      this.comparison.title = title
    },
    createComparison(){
      if (this.getInputError) return
      this.$eventBus.$emit('openComparePoliciesWizardModal', { policyComparison: this.comparison, currentJurisdiction: this.currentJurisdiction } )
      this.$refs.baseDrawer.closeDrawer()
    },
    getPolicyStudyType(policy) {
      return this.$store.getters['policy/getterPolicyStudyTypes']({key: 'title', policy }).join(', ')
    },
    getPolicyTypeLabel(policy_id) {
      return this.$store.getters['policy/getterPolicyTypeLabel'](policy_id)
    },
    getPolicyType(policy_id) {
      return this.$store.getters['policy/getterPolicyType'](policy_id)
    },
    onCloseDrawer() {
      this.resetState()
      this.showIntercom()
    },
    checkIfPolicyIsSelected(policy_id) {
      return this.comparisonPolicyIds?.includes(Number(policy_id))
    },
  }
}
</script>

<style lang="scss" scoped>  
  ::v-deep {
    &.app-drawer {
      width: 478px;

      &-content {    
        margin-top: 0;    
      }
    }

    .comparison-policy-name {
      .psui-el-checkmark {
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          @media (max-width: 1024px) {
            max-width: 6.25rem;
          }
          @media (min-width: 1025px) and (max-width: 1280px) {
            max-width: 10rem;
          }
          @media (min-width: 1281px) {
            max-width: 11rem;
          }
        }
      }
    }

    .app-checkbox-item {
      span {
        white-space: normal;
        width: 250px;
        word-break: break-all; 
      }
      .psui-el-checkmark {
        &:before {
          font-weight: 400;
        }
      }
    }
  }

  .ps-input {
    ::v-deep {
      .psui-el-input label {
        display: flex;
        line-height: 1;
        margin-bottom: 8px;
      }
    }

    ::v-deep .psui-el-input-wrapper {
      width: 23rem;
      height: 2.75rem;
    }

    ::v-deep input {
      font-size: 16px !important;
      width: 23rem;
      height: 2.75rem;
      background: transparent;
      padding-top: 2px;
    }
  }

  .create-a-policy-button {
    ::v-deep .psui-el-button.size-big {
      padding: 0.594rem 1.594rem;
      height: 2.75rem;
    }
  }

  .app-drawer-content {
    ::v-deep {
      .app-drawer-content {
        padding: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .app-checkbox-item {
    ::v-deep {
      .app-checkbox-item span {
        width: auto;
      }
    }
  }

  .comparison-policies-tooltip {
    ::v-deep .psui-el-tooltip .psui-el-tooltip-wrapper .psui-el-tooltip-dialog .psui-el-tooltip-content.layout-gray {
      width: 11.985rem;
    }

    ::v-deep .psui-el-tooltip .psui-el-tooltip-wrapper .psui-el-tooltip-dialog .psui-el-tooltip-content.layout-gray .psui-el-tooltip-content-wrapper {
      margin-top: 0; 
      font-weight: 700;
    }
  }
</style>
