import dayjs from 'dayjs'
import { getSubsidyColumnsValues } from '@/business-logic/services/subsidy/getSubsidyColumnsValues'


export function getQuarters(startAtDate, activePolicyDuration, installationDelay, years) {
  if (!startAtDate) {
    return []
  }
  const endAtDate = dayjs(startAtDate)
    .add(activePolicyDuration, 'years')
    .add(installationDelay, 'years')
    .add(years, 'years')
  const quarters = []
  for(let currentQuarter = startAtDate; dayjs(currentQuarter).isBefore(endAtDate); currentQuarter = dayjs(currentQuarter).add(1, 'quarter')) {
    const quarterInfo = currentQuarter?.format('MM/DD/YYYY')
    if (quarterInfo) {
      quarters.push(quarterInfo)
    }
  }
  return quarters
}


export function getImpactPopulationsFromUnits(units, allElectricWithoutPolicySharePercentage, allElectricWithPolicySharePercentage) {
  const mixedFuelSharePercentage = (100-allElectricWithPolicySharePercentage)
  const migratingToAllElectricSharePercentage = allElectricWithPolicySharePercentage - allElectricWithoutPolicySharePercentage

  // ToDO: Could that numbers be decimals?
  const mixedPop = (mixedFuelSharePercentage/100) * units
  const allElectricPop = (allElectricWithoutPolicySharePercentage/100) * units
  const migratingPop = (migratingToAllElectricSharePercentage/100) * units
  return {
    mixed_fuel_units: mixedPop,
    migrating_to_all_electric_units: migratingPop,
    all_electric_units: allElectricPop,
    shares_percentages: {
      all_electric_with_policy: allElectricWithPolicySharePercentage / 100,
      all_electric_without_policy: allElectricWithoutPolicySharePercentage / 100,
      mixed_fuel_with_policy: mixedFuelSharePercentage / 100,
      migrating_to_all_electric_with_policy: migratingToAllElectricSharePercentage / 100
    },
  }
}

export function getImpactEstimatesFromQuarterInstallations(
  quarterInstallations,
  startAtDate,
  care_program_enrollment_rate,
  // Impacts Estimate params interface
  {
   currentThermsEmissionFactor,
   currentKwhEmissionFactor,
   kwhSavings,
   thermsSavings,
   annualBillSavings,
   initialCost,
   gridMaxRenewablesYear,
   gridMaxRenewablesLevel,
   currentGridRenewableLevel,
   subsidy_needed,
   five_year_payback,
   subsidy_needed_care,
   five_year_payback_care
  },
  unitsNormalization = 1)

{
  return quarterInstallations.map((quarterInfo) => {
    //Subsidy Projections
    const subsidyProjections = getSubsidyColumnsValues({
      units: quarterInfo.netInstallationsStarted,
      subsidy_needed,
      five_year_payback,
      subsidy_needed_care,
      five_year_payback_care,
      care_program_enrollment_rate: care_program_enrollment_rate
    })


    // Therms saved
    const thermsSaved = getThermsSavingsPerQuarter(quarterInfo.netActiveInstallations/unitsNormalization, thermsSavings)

    // GHG savings from therms
    const ghgSavingsFromTherms = thermsSaved * currentThermsEmissionFactor

    // Kwh saved
    const kwhSaved = getKwhSavingsPerQuarter(quarterInfo.netActiveInstallations/unitsNormalization, kwhSavings)

    // Renewable Grid share
    const renewableGridShare = getGridRenewableShare(dayjs(quarterInfo.quarter), startAtDate, {
      gridMaxRenewablesYear,
      gridMaxRenewablesLevel,
      currentGridRenewableLevel,
    })

    const kwhEmissionFactor = getKwhEmissionFactorByQuarter(currentKwhEmissionFactor, currentGridRenewableLevel, renewableGridShare)
    const ghgSavingsFromKwh = getGhgSavingsFromKwh(kwhEmissionFactor, kwhSaved)

    return {
      quarter: quarterInfo.quarter,
      year: dayjs(quarterInfo.quarter).year(),
      kwh_savings: kwhSaved,
      ghg_savings_from_kwh: ghgSavingsFromKwh,
      ghg_savings: getGhgSavings(ghgSavingsFromKwh, ghgSavingsFromTherms),
      bill_savings: getBillSavingsPerQuarter(quarterInfo.netActiveInstallations/unitsNormalization, annualBillSavings),
      compliance_cost: getComplianceCost(quarterInfo.netInstallationsStarted/unitsNormalization, initialCost),
      therms_savings: thermsSaved,
      active_installations: quarterInfo.netActiveInstallations,
      installations_started: quarterInfo.netInstallationsStarted,
      installations_completed: quarterInfo.netInstallationsCompleted,
      ...subsidyProjections
    }
  })
}

export function getKwhEmissionFactorByQuarter(currentKwhEmissionFactor, currentGridRenewableLevel, quarterRenewableGridShare) {
  const fullFactor = currentKwhEmissionFactor / (((100 - currentGridRenewableLevel)/100) || 1)
  return parseFloat((fullFactor * (1 - (quarterRenewableGridShare / 100))).toPrecision(5))
}

export function getGhgSavingsFromKwh(kwhEmissionFactorInQuarter, kwhSavedInQuarter) {
  return kwhEmissionFactorInQuarter * kwhSavedInQuarter
}

export function getGhgSavings(ghgSavingsFromKwh, ghgSavingsFromTherms) {
  return (!isNaN(ghgSavingsFromKwh) ? ghgSavingsFromKwh : 0) + (!isNaN(ghgSavingsFromTherms) ? ghgSavingsFromTherms : 0)
}

export function getBillSavingsPerQuarter(netActiveInstallations, annualBillSavings) {
  return netActiveInstallations * (annualBillSavings / 4)
}

export function getThermsSavingsPerQuarter(netActiveInstallations, thermsSavingsByYear) {
  return thermsSavingsByYear !== 0 ? netActiveInstallations * (thermsSavingsByYear / 4) : 0
}

export function getKwhSavingsPerQuarter(netActiveInstallations, kwhSavingsByYear) {
  return kwhSavingsByYear !== 0 ? netActiveInstallations * (kwhSavingsByYear / 4) : 0
}

export function getComplianceCost(netInstallationsStarted, initialCost) {
  return netInstallationsStarted * (initialCost)
}

export function getGridRenewableShare(
  currentQuarterDate,
  startAtDate,
  {
    gridMaxRenewablesYear,
    gridMaxRenewablesLevel,
    currentGridRenewableLevel,
  })
{
  // Grid renewable share change per year
  const gridMaxRenewablesYearFromToday = gridMaxRenewablesYear - dayjs().year()
  const gridRenewableShareChangePerYear =
    (gridMaxRenewablesLevel -  currentGridRenewableLevel) /
    (gridMaxRenewablesYearFromToday <= 0 ? 1 : gridMaxRenewablesYearFromToday)

  // Grid renewable level
  const startYearGridRenewableLevel = currentGridRenewableLevel -
    (dayjs().year() - startAtDate.year()) * gridRenewableShareChangePerYear

  // Grid renewable share
  let gridRenewableShare
  gridRenewableShare = (startYearGridRenewableLevel + (gridRenewableShareChangePerYear * (currentQuarterDate.year() - startAtDate.year())))
  if(gridRenewableShare > gridMaxRenewablesLevel) {
    gridRenewableShare = gridMaxRenewablesLevel
  }
  return gridRenewableShare
}
// Ends Impact estimates mechanics
