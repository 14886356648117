<template>
  <div
    v-if="getRequirement"
    class="psui-w-full psui-flex psui-flex-row psui-rounded-md psui-pt-3"
    @click="openNewBuildingsDuplicateModal"
  >
    <div
      class="psui-flex psui-items-center psui-w-175"
      :class="{'disable-interaction': !getterUserCanEditPolicy}"
    >
      <div class="psui-flex psui-items-center psui-text-small psui-font-bold psui-text-blue-70 psui-mx-1 psui-space-x-2">
        <PsIcon
          :icon="`/icons/type-fuel/${getRequirement.fuel.type_fuel.slug}.svg`"
          size="20"
          :color="`${allElectricIsMandatory ? 'psui-text-gray-50' : 'psui-text-blue-60'}`"
          :icon-classes="`${allElectricIsMandatory ? 'psui-text-gray-50' : 'psui-text-blue-60'} psui-leading-none`"
        />
        <div
          class="psui-flex psui-items-center psui-space-x-1 psui-cursor-pointer opacity-100-childrens-on-hover"
          @click="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: `requirements-new-buildings-sf-2022-${getRequirement.fuel.type_fuel.slug}-fuel-type` })"
        >
          <span :class="{'psui-text-gray-50': allElectricIsMandatory}">{{ getRequirement.fuel.type_fuel.title }}</span>
          <PsIcon
            icon="info"
            size="16"
            class="psui-text-blue-60 text-16 psui-opacity-0 transition-all psui-leading-none"
            :style="{ display: 'inline-block' }"
          />
        </div>
      </div>
    </div>
    
    <div class="psui-flex psui-w-3/4">
      <div 
        :class="isPvSystemEnabled ? 'third-width-responsive' : 'half-width-responsive'"
      >
        <PsDropdown
          :ref="`fuel_choice_dropdown_${getRequirement.id}`"
          :disabled="!getterUserCanEditPolicy"
        >
          <template #dropdownTrigger>
            <span
              class="psui-el-dropdown-menu-item psui-flex psui-text-gray-80 psui-justify-between psui-items-center psui-w-full psui-bg-white psui-px-2 psui-py-1 psui-rounded psui-shadow-elevation-5"
              :class="{'psui-bg-gray-20 psui-text-gray-50 psui-cursor-default psui-shadow-none': !getterUserCanEditPolicy}"
            >
              <span
                class="py-0.75 psui-text-small"
                :class="{'psui-text-gray-50': !getterUserCanEditPolicy}"
              >{{ getMetaRequirement( getRequirement, 'fuel_choice').label }}</span>
              <PsIcon 
                icon="expand_more"
                class="psui-text-gray-50 psui-cursor-pointer leading-none"
                :class="{'cursor-default': !getterUserCanEditPolicy}"
                size="18"
              />
            </span>
          </template>
          <template #items>
            <div class="px-4 py-4 flex flex-col gap-2 font-bold">
              <PsRadioButtonSimple 
                v-for="(fuel_choice, index) in fuel_choice_options"
                :key="`fuel-choice-${index}`"
                :label="fuel_choice.label"
                :name="`mixed-fuel-choice-${fuel_choice.label}`"
                :checked="fuel_choice.value === getMetaRequirement(getRequirement, 'fuel_choice').value"
                size="small"
                @change="setMetaRequirement(getRequirement, 'fuel_choice', fuel_choice.value)"
              />
            </div>
          </template>
        </PsDropdown>
      </div>

      <div
        v-if="!isComplianceMarginUnique"
        class="psui-flex psui-space-x-2 psui-items-center"
        :class="isPvSystemEnabled ? 'third-width-responsive' : 'half-width-responsive'"
      >
        <PsDropdown
          :ref="`compliance_margin_measure_id_dropdown_${getRequirement.id}`"
          :disabled="!getterUserCanEditPolicy || allElectricIsMandatory"
          class="psui-el-dropdown-menu-compliance"
        >
          <template #dropdownTrigger>
            <span
              class="psui-el-dropdown-menu-item psui-flex psui-justify-between psui-text-gray-80 psui-items-center psui-w-full psui-bg-white psui-px-2 psui-py-1 psui-rounded psui-shadow-elevation-5"
              :class="{'psui-bg-gray-20 psui-cursor-default psui-shadow-none': !getterUserCanEditPolicy || allElectricIsMandatory, 'selected psui-border psui-border-blue-60 psui-text-blue-60' : 1 === 2}"
            >
              <span
                class="py-0.75 psui-text-small"
                :class="{'psui-text-gray-50': !getterUserCanEditPolicy}"
              >
                {{ allElectricIsMandatory ? '--' : getMetaRequirementValue(getRequirement, 'compliance_margin_measure_id') ? getMetaRequirement(getRequirement, 'compliance_margin_measure_id').left_label : '+0.0' }}
              </span>
              <PsIcon
                v-if="!allElectricIsMandatory"
                icon="expand_more"
                class="psui-text-gray-50 psui-cursor-pointer leading-none"
                :class="{'cursor-default': !getterUserCanEditPolicy}"
                size="18"
              />
            </span>
          </template>
          <template #items>
            <DropdownMenuListRadio               
              :items="compliance_margin_measure_id_options"
              :selected="getMetaRequirementValue(getRequirement, 'compliance_margin_measure_id')"
              @change="setMetaRequirement(getRequirement, 'compliance_margin_measure_id', $event)"
            />            
          </template>
        </PsDropdown>

        <p 
          v-if="!allElectricIsMandatory"
          class="psui-text-xsmall psui-text-gray-60"
        >
          max {{ allElectricIsMandatory ? '--' : formatComplianceMarginAsText(getMaxComplianceMarginValue) }}
        </p>
        <p 
          v-else
          class="psui-text-xsmall psui-text-gray-60"
        >
          --
        </p>
      </div>

      <div
        v-if="isPvSystemEnabled"
        class="psui-flex psui-space-x-2 psui-items-center"
        :class="isPvSystemEnabled ? 'third-width-responsive ml-1/2' : 'psui-w-0'"
      >
        <PsDropdown
          :ref="`require_pv_system_dropdown_${getRequirement.id}`"
          :disabled="!getterUserCanEditPolicy || !largerPvSystemRequestCanBeRequired || allElectricIsMandatory"
        >
          <template #dropdownTrigger>
            <span 
              :title="!largerPvSystemRequestCanBeRequired ? 'Not available' : false"
              class="psui-el-dropdown-menu-item psui-flex psui-justify-between psui-items-center psui-w-full psui-bg-white psui-px-2 psui-py-1 psui-rounded psui-shadow-elevation-5"
              :class="{'psui-bg-gray-20 psui-cursor-default psui-shadow-none': !getterUserCanEditPolicy || !largerPvSystemRequestCanBeRequired || allElectricIsMandatory}"
            >
              <span
                class="py-0.75 psui-text-small"
                :class="{'psui-text-gray-50': !getterUserCanEditPolicy || !largerPvSystemRequestCanBeRequired || allElectricIsMandatory}"
              >
                {{ getMetaRequirement(getRequirement, 'require_pv_system').label }}
              </span>
              <PsIcon
                icon="expand_more"
                class="psui-text-gray-50 psui-cursor-pointer leading-none"
                :class="{'cursor-default': !getterUserCanEditPolicy || !largerPvSystemRequestCanBeRequired || allElectricIsMandatory}"
                size="18"
                @mouseenter="isHoveringLargerPvSystemExpandIcon = !isHoveringLargerPvSystemExpandIcon"
                @mouseleave="isHoveringLargerPvSystemExpandIcon = !isHoveringLargerPvSystemExpandIcon"
              />
            </span>
          </template>
          <template #items>
            <div class="px-4 py-4 flex flex-col gap-2 font-bold">
              <PsRadioButtonSimple 
                v-for="(require_pv_system, index) in require_pv_system_options"
                :key="`require-pv-system-${index}`"
                :label="require_pv_system.label"
                :name="`mixed-require-pv-system-${require_pv_system.label}`"
                :checked="require_pv_system.value === getMetaRequirement(getRequirement, 'require_pv_system').value"
                size="small"
                @change="setMetaRequirement(getRequirement, 'require_pv_system', require_pv_system.value)"
              />
            </div>
          </template>
        </PsDropdown>

        <PsIcon
          v-if="isHoveringLargerPvSystemExpandIcon && largerPvSystemRequestCanBeRequired"
          icon="info"
          size="13.33"
          class="psui-text-gray-50"
          icon-classes="ml-2"
        /> 
        <p
          v-if-admin
          class="psui-text-xsmall psui-text-gray-60"
        >
          max {{ formatComplianceMarginAsText(getTheMaximumComplianceMarginForLargerPvSystemValue) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import StudyDataApiService from '@/services/api/StudyDataApiService'
import { 
  FUEL_CHOICE_ALLOWED,
  FUEL_CHOICE_NOT_ALLOWED,
  REQUIRE_PV_SYSTEM_REQUIRED,
  REQUIRE_PV_SYSTEM_NOT_REQUIRED,
} from './enums'
import { FUEL_CHOICES } from '@/business-logic/services/impact-algorithms'
import { TYPE_FUELS_DB_SLUGS } from "@/util/Enums"
import { formatComplianceMarginDropdown } from './helpers'
import {
  GeneralPerformanceByFuelPolicyImpactAlgorithm
} from '@/business-logic/services/impact-algorithms/policy/general-performance-by-fuel'
import { checkIfPolicyIsOutdated } from "@/util/Helpers"

const fuel_choice_options = [
  { ... FUEL_CHOICE_ALLOWED, default: true },
  FUEL_CHOICE_NOT_ALLOWED,
]

const require_pv_system_options = [
  REQUIRE_PV_SYSTEM_REQUIRED,
  { ... REQUIRE_PV_SYSTEM_NOT_REQUIRED, default: true },
]

export default {
  name: 'EditRequirementsNewBuildingsSF2022MixedFuel',
  props: ['requirement-all-electric', 'requirement-mixed-fuel', 'type-fuel-slug', 'is-compliance-margin-unique'],
  data: () => ({    
    study_datas : [],
    fuel_choice_options,
    require_pv_system_options,
    isHoveringLargerPvSystemExpandIcon:false
  }),
  computed: {
    getRequirement() {
      return this.requirementMixedFuel
    },
    compliance_margin_measure_id_options () {     
      return formatComplianceMarginDropdown(this.getStudyDataComplianceMarginOptions, TYPE_FUELS_DB_SLUGS.MIXED_FUEL)
    },
    getStudyDataComplianceMarginOptions() {
      const enableAll = this.getStudyComplianceMarginKey !== 'compliance_margin'
      const requiringPv = this.getMetaRequirement(this.getRequirement, 'require_pv_system').value
       let studyDataWithoutPreEmptivesAndLargerPvIncluded = this.study_datas
        .filter(study_data => {
          return !study_data.measure.is_pre_emptive &&
              study_data?.data?.[this.getStudyComplianceMarginKey] && study_data?.data?.[this.getStudyComplianceMarginKey] > 0 &&
              study_data?.measure?.hide_in_compliance_margin_control !== true && study_data.isCostEffective &&
              (enableAll || (requiringPv && study_data.measure.has_larger_pv_included) || (!requiringPv && !study_data.measure.has_larger_pv_included))
        })
      
      let study_datas_with_unique_compliance_margin = []
      const compliance_margin_values = new Set([... studyDataWithoutPreEmptivesAndLargerPvIncluded.map(sd => sd.study_data[this.getStudyComplianceMarginKey] )])
      
      for( let compliance_margin_value of compliance_margin_values) {
        const studyDataWithSameComplianceMargin = studyDataWithoutPreEmptivesAndLargerPvIncluded.filter(sd => +sd.study_data[this.getStudyComplianceMarginKey] === compliance_margin_value)
        studyDataWithSameComplianceMargin.sort((a,b) => a.study_data.initial_cost - b.study_data.initial_cost)
        study_datas_with_unique_compliance_margin.push(studyDataWithSameComplianceMargin[0])
      }

      return study_datas_with_unique_compliance_margin
    },
    getMaxComplianceMarginValue() {
      return this.getStudyDataComplianceMarginOptions?.[0]?.study_data?.[this.getStudyComplianceMarginKey] ?? 0
    },
    getComplianceMarginSelected() {
      return this.getRequirement?.meta?.compliance_margin_value ?? 0
    },
    getTheMaximumComplianceMarginForLargerPvSystemValue() {
      const enableAll = this.getStudyComplianceMarginKey !== 'compliance_margin'
      const study_datas = this.study_datas.filter(study_data => {
        return !study_data.measure.is_pre_emptive &&
            study_data?.data?.[this.getStudyComplianceMarginKey] && study_data?.data?.[this.getStudyComplianceMarginKey] > 0 &&
            study_data?.measure?.hide_in_compliance_margin_control !== true && study_data.isCostEffective &&
            (enableAll || study_data.measure.has_larger_pv_included)
      })
      return study_datas?.[0]?.study_data?.[this.getStudyComplianceMarginKey] ?? null
    },    
    largerPvSystemRequestCanBeRequired() {
      const maxComplianceMarginSelectedIsLessOrEqualMaxComplianceMarginForLargerPv = this.getComplianceMarginSelected <= this.getTheMaximumComplianceMarginForLargerPvSystemValue
      return this.getTheMaximumComplianceMarginForLargerPvSystemValue !== null && maxComplianceMarginSelectedIsLessOrEqualMaxComplianceMarginForLargerPv
    },
    allElectricIsMandatory() {
      return this.getMetaRequirementValue(this.requirementAllElectric, 'fuel_choice') === FUEL_CHOICES.REQUIRED
    },
    getterUserCanEditPolicy() {
      return this.$store.getters['policy/getterUserCanEditPolicy'](this.$route.params.policy_id)
    },
    getStudyComplianceMarginKey() {
      if (!this.getRequirement?.fuel?.study_id) {
        return 'compliance_margin'
      }
      const study = this.$store.getters['globalEntities/Study/getterGlobalStudy']({ id: this.getRequirement.fuel.study_id })
      return study.compliance_margin_key || 'compliance_margin'
    },
    isPvSystemEnabled() {
      return GeneralPerformanceByFuelPolicyImpactAlgorithm.pvEnabledComplianceMarginKeys.includes(this.getStudyComplianceMarginKey)
    },
    isReadOnlyOutdatedPolicy() {
      const policy = this.$store.getters['policy/getterUserPolicy']({id: this.$route.params.policy_id})
      const outdatedInfo = checkIfPolicyIsOutdated(policy)
      return Boolean(outdatedInfo.has_outdated_studies === true && outdatedInfo.is_loading === false && !outdatedInfo.block_policy)
    },
  },
  mounted() {
    this.getStudyDataByCustomCombination()
  },
  methods: {
    async getStudyDataByCustomCombination() {
      const studyDatas = (await StudyDataApiService.getAllAvailableByPrototype(this.getRequirement.prototype_id, this.getRequirement.climate_zone_raw))
          .sort((a, b) => b.study_data[this.getStudyComplianceMarginKey] - a.study_data[this.getStudyComplianceMarginKey])
      this.study_datas = studyDatas.filter(study_data => {
        const fuel = this.$store.getters['globalEntities/Fuel/getterGlobalFuel']({ id: study_data.fuel_id })
        const typeFuel = this.$store.getters['globalEntities/TypeFuel/getterGlobalTypeFuel']({ id: fuel.type_fuel_id })
        return typeFuel.slug === this.typeFuelSlug
      })
    },
    getMetaRequirementValue(requirement, key) {
      let metaValue = requirement?.meta?.[key]
      if (key.includes('compliance_margin') && ((!requirement?.meta?.compliance_margin_value && !requirement?.meta?.compliance_margin_measure_id) || !this.compliance_margin_measure_id_options.map((v) => +v.value).includes(+metaValue))) {
        metaValue = undefined
      }
      return metaValue === undefined ? this[`${key}_options`].findInArray({ default: true }).value : metaValue
    },
    getMetaRequirement(requirement, key) {
      const value = this.getMetaRequirementValue(requirement, key)
      const optionByValue = this[`${key}_options`].findInArray({ value })
      const defaultOption = this[`${key}_options`].findInArray({ default: true })
      return value ? optionByValue : defaultOption
    },
    setMetaRequirement(requirement, key, value) {
      this.$refs?.[`${key}_dropdown_${requirement.id}`]?.close()
      this.$emit('update-requirements', { requirement, key, value, meta : { maximumComplianceMarginForLargerPvSystemValue : this.getTheMaximumComplianceMarginForLargerPvSystemValue } })
    },
    openNewBuildingsDuplicateModal() {
      const policy = this.$store.getters['policy/getterUserPolicy']({id: this.$route.params.policy_id})
      if(this.getterUserCanEditPolicy) return

      let args = { policy }
      if (this.isReadOnlyOutdatedPolicy) {
        args = {... args,
          disableUserLoginCall2Action: true,
          disableBtns: true,
          customTitle: `You can't edit this policy`,
          customDescription: `This policy is read-only since it is based on an outdated policy architecture and cost-effectiveness study results. Please create a new policy to continue policy development.`
        }
      }
      this.$eventBus.$emit('openPolicyUserPermissionDuplicateAlertModal', args)
    },
  }
}
</script>

<style lang="scss" scoped>
  .disable-interaction, .disable-interaction * {
    pointer-events: none !important;
  }

  .psui-w-175 {
    width: 175px;
  }

  .psui-el-dropdown-menu {
    width: 130px;
  }

  .psui-el-dropdown-menu-dialog-wrapper {
    margin-top: 3px;
  }

  .dropdown-menu-list-radio {
    min-width: 290px !important;
  }

  .psui-el-dropdown-menu-compliance {
    max-width: 80px;
  }
</style>
