<template>
  <div class="w-full">
    <div
      class="table-wrapper w-full whitespace-nowrap text-left"
    > 
      <ShowLearnMore
        v-if="hasNoStudyData"
        :fuel="fuel"
        text="Results for this case are pending revision by the study provider."
        url="https://intercom.help/explorer-local-energy-codes/en/articles/6958416-why-are-there-so-few-nonres-packages"
        class="psui-bg-gray-10 psui-rounded-md psui-pl-2 py-3"
      />
      <table
        v-else
        class="relative align-top w-full"
      >
        <ForecastTableHead
          :study="study"
          :study-type="getStudyType.slug"
          @openProjectionsModal="openProjectionsModal($event)"
        />
        <ForecastTableBody
          :is-view-mode="isViewMode"
          @openProjectionsModal="openProjectionsModal($event)"
        />
      </table>
    </div>
    <ShowLearnMore
      v-if="!hasNoStudyData && studyHasWaitingMeasuresEqualToTrue"
      :fuel="fuel"
      text="Some packages are not currently available."
      url="https://intercom.help/explorer-local-energy-codes/en/articles/6958416-why-are-there-so-few-nonres-packages"
      class="mt-3"
    />
  </div>
</template>

<script>
import ForecastTableBody from './ForecastTableBody.vue'
import ForecastTableHead from './ForecastTableHead.vue'
import { getMeasuresLengthText, find, findIndex } from '@/util/Functions'
import StudyData from '@/models/StudyData'
import { mapGetters, mapState } from 'vuex'
import { STUDY_TYPES } from '@/util/Enums'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import CustomCombinationApiService from '@/services/api/CustomCombinationApiService'
import { CUSTOM_COMBINATION_FILTERS, CUSTOM_COMBINATION_PRESETS } from '../../shared/enums'
import { compareArray } from '@igortrindade/lazyfy'
import ShowLearnMore from '@/modules/app/jurisdiction/study-results/content/ShowLearnMore.vue'
import { updateStudyResultsColumnGroupTitleAndHelper, updateColumnCopiesAndHelpers } from '@/util/Functions.js'
import { compliance_margin, edr1_total_margin } from '@/modules/app/shared/default-app-columns'

const initCustomCombinationPresets = () => {
  return Object.keys(CUSTOM_COMBINATION_PRESETS).reduce((prev, cur) => {
    prev[cur] = []
    return prev
  }, {})
}

export default {
  name: 'ForecastTable',
  components: { ForecastTableHead, ForecastTableBody, ShowLearnMore },
  props: {
    study: {
      type: Object,
      required: true
    },
    study_data: {
      type: Array,
      required: true
    },
    measuresPreSelectedIds: {
      type: Array,
      required: false,
      default: function() {
        return []
      }
    },
    prototype: {
      type: Object
    },
    vintage: {
      type: Object
    },
    fuel: {
      type: Object
    },
    building_stock_units: {
      type: [Number, String, Boolean]
    },
    customCombinationPresetSelected: {
      type: Object,
      default() {
        return CUSTOM_COMBINATION_FILTERS.NONE
      }
    },
    building_estimates: {
      type: Array,
      required: false,
      default: function() {
        return []
      }
    },
    dataFilterSelected: {
      type: String,
      default: 'none'
    },
    climate_zone_raw: {
      type: String
    },
    expanded: {
      type: Boolean
    },
    isViewMode : {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      measureCombinations: [],
      orderColumn: null,
      orderDirection: 'desc',
      studyDataHere: [],
      rowOnHover: false,
      customCombinationPresets: initCustomCombinationPresets(),
      customCombinationSelected: false,
      shouldUpdateWhenExpanded : false,
      STUDY_TYPES,
      STUDY_TYPES_DB_SLUGS
    }
  },
  computed: {    
    ...mapGetters('assumptions', ['getterAssumptionsSelected']),
    ...mapState('assumptions', ['existingBuildingsResultsImpactAssumptions', 'newBuildingsResultsImpactAssumptions']),
    getMeasureCombination() {
      if(!this.measureCombinations.length) return false

      const custom_combination = this.measureCombinations[0]
      const climate_zone = this.$store.getters['globalEntities/ClimateZone/getterGlobalClimateZone']({ raw: custom_combination.climate_zone_raw })
      const prototype = this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: custom_combination.prototype_id })
      const vintage = this.$store.getters['globalEntities/Vintage/getterGlobalVintage']({ id: custom_combination.vintage_id })

      const building_stock_units = this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
        climate_zone_prefix: climate_zone.prefix,
        type_vintage_id: vintage.type_vintage_id,
        type_prototype_id: prototype.type_prototype_id,
        prototype_id: prototype.id
      })

      const measureCombination = new StudyData({
        study_data: this.measureCombinations[0].data, 
        building_stock_units: building_stock_units,
        assumption: this.getterAssumptionsSelected
      })
      this.measureCombinations.forEach((studyData, index) => {
        if(index < 1) return
        measureCombination.sumMeasureCombinations({ dataToAggregate: studyData.data })
      })
      measureCombination.measure = { title: getMeasuresLengthText(this.measureCombinations) }
      measureCombination.measures_combined = this.measureCombinations.length
      return measureCombination
    },

    columnsSelectedForStudy() {
      const currentStudyType = this.$store.getters['globalEntities/Study/getterStudyStudyType']({ study_id: this.study.id })
      const newBuildingsStudyType = this.$store.getters['globalEntities/StudyType/getterStudyTypeNewBuildings']

      if (currentStudyType?.slug === newBuildingsStudyType?.slug) {
        let newBuildingColumns = { ...this.$store.getters['studyResults/getterNewBuildingsColumnsSelected'] }

        if (!this.building_estimates || !this.building_estimates.length) {
          newBuildingColumns.columnGroups = newBuildingColumns.columnGroups.filter(cg => cg.key !== 'city_wide_impact')
        }

        const disableColumnKeys = ['compliance_margin', 'edr1_total_margin']
        newBuildingColumns.columnGroups = newBuildingColumns.columnGroups.map(columnGroup => {
          let columns = columnGroup.columns.map(cgColumn => {
            const { column, columnTitle, columnHasHelper } = updateColumnCopiesAndHelpers(this.study.id, cgColumn)
            let forceDisable = Boolean(disableColumnKeys.includes(column.key) && this.getStudyDataOrdered && this.getIfColumnShouldBeDisabled(column.key, this.getStudyDataOrdered))
            return {
              ...column,
              title: columnTitle,
              hasHelper: columnHasHelper,
              isActive: forceDisable ? false : column.isActive,
              isDisabled: forceDisable ? true : column.isDisabled,
            }
          }).filter((column) => {
            return !column.isDisabled
          })
          
          const { columnGroupTitle, columnGroupHasHelper } = updateStudyResultsColumnGroupTitleAndHelper(this.study.id, columnGroup)

          return {
            ...columnGroup,
            columns,
            title: columnGroupTitle,
            hasHelper: columnGroupHasHelper,
          }
        })

        return newBuildingColumns
      }

      // Existing treatment
      return {
        ...this.$store.getters['studyResults/getterExistingBuildingsColumnsSelected'],
        columnGroups: this.$store.getters['studyResults/getterExistingBuildingsColumnsSelected'].columnGroups.map(cg => {
          const disableColumnKeys = ['lsc_2025_benefit_to_cost_ratio', 'tdv2022_benefit_to_cost_ratio', 'tdv_benefit_to_cost_ratio', 'on_bill_cost_ratio_2025', 'on_bill_cost_ratio', 'on_bill_2025_care']
          let columns = cg.columns.map(cgColumn => {
            let forceDisable = Boolean(disableColumnKeys.includes(cgColumn.key) && this.getStudyDataOrdered && this.getIfColumnShouldBeDisabled(cgColumn.key, this.getStudyDataOrdered))
            return {
              ...cgColumn,
              isActive: forceDisable ? false : cgColumn.isActive,
              isDisabled: forceDisable ? true : cgColumn.isDisabled,
            }
          }).filter((column) => {
            return !column.isDisabled
          })
          const {
            columnGroupTitle,
            columnGroupHasHelper
          } = updateStudyResultsColumnGroupTitleAndHelper(this.study.id, cg)
          return {...cg, columns, title: columnGroupTitle, hasHelper: columnGroupHasHelper}
        }).filter(columnGroup => !(this.getStudyDataOrdered && columnGroup && columnGroup.columns.every(col => this.getIfColumnShouldBeDisabled(col.key, this.getStudyDataOrdered))))
      }
    },

    getStudyDataOrdered() {
      return this.studyDataHere
        .filter(this.filterBasedOnCostEffectiveFilter)
        .sort((a, b) => {
          if(this.orderDirection === 'asc') return a.data[this.orderColumn] - b.data[this.orderColumn]
          return b.data[this.orderColumn] - a.data[this.orderColumn]
        })
    },

    getProjectionAssumptions() {
      return (this.building_estimates && this.building_estimates.length) ?
          this.newBuildingsResultsImpactAssumptions : this.existingBuildingsResultsImpactAssumptions
    },

    getStudyTypeByPrototype(){
      const prototype_id = this.prototype.id
      return this.$store.getters['globalEntities/Prototype/getterPrototypeStudyTypeId']({ prototype_id })
    },

    getStudyType() {
      return this.$store.getters['globalEntities/StudyType/getterGlobalStudyType']({ id: this.getStudyTypeByPrototype })
    },

    hasNoStudyData() {
      return this.study_data.length === 0
    },

    studyHasWaitingMeasuresEqualToTrue() {
      return this.$store.getters['globalEntities/Study/getterGlobalStudy']({id: this.study.id})?.has_waiting_measures === true
    },

    getTypePrototypeIsNonResidential() {
      return this.$store.getters['globalEntities/BuildingType/getterBuildingTypeByTypePrototypeIdIsNonResidential'](this.prototype.type_prototype_id)
    },
    getPolicyOptionAlgorithm(){
      return  this.$store.getters['globalEntities/PolicyOption/getterGlobalPolicyOption']({id:this.getPolicyOptionIdFromPolicy})
    },
    getPolicyOptionIdFromPolicy(){
      return this.$store.getters['policy/getterUserPolicy']({id:this.$route.params.policy_id}).policy_option.id
    },
    ...mapGetters('assumptions', ['getterAssumptionsSelected']),
    ...mapState('assumptions', ['existingBuildingsResultsImpactAssumptions', 'newBuildingsResultsImpactAssumptions']),

  },
  watch: {
    'getProjectionAssumptions': {
      deep: true,
      handler() {
        if (this.studyDataHere.length > 0) {
          this.generateStudyDataWithProjections('getProjectionAssumptions')
        } else {
          this.shouldUpdateWhenExpanded = true
        }
      }
    },
    study_data: {
      handler() {
        this.generateStudyDataWithProjections('study_data')
      }
    },
    building_stock_units: {
      handler() {
        if (this.expanded) this.generateStudyDataWithProjections('building_stock_units')
      }
    },
    building_estimates: {
      handler() {
        if (this.expanded) this.generateStudyDataWithProjections('building_estimates')
      }
    },
    expanded: {
      handler(expanded) {
        if (expanded && this.shouldUpdateWhenExpanded) {
          this.generateStudyDataWithProjections('on_expanded')
        }
        this.getCustomCombinationPresets()
      }
    }
  },
  mounted() {
    this.setOrderColumnDefault()
    if(this.expanded) {
      this.getCustomCombinationPresets()
      this.generateStudyDataWithProjections('mounted')
    }
  },
  methods: {
    generateStudyDataWithProjections() {
      setTimeout(() => {
        this.studyDataHere = this.study_data.map((studyDataRow) => {
          return new StudyData({ 
              study_data: studyDataRow, 
              building_stock_units: this.building_stock_units,
              building_estimates: this.building_estimates,
              assumption: this.getProjectionAssumptions
            }
          )
        }).sort((a, b) => {
          if(a.measure) return a.measure.order - b.measure.order
          return 1
        })
        this.addMeasuresPreselected()
        this.checkCombinedMeasures()
        this.updateMaxTags()
      }, 50)
    },
    filterBasedOnCostEffectiveFilter(study_data) {      

      if (this.checkQueryKeyValue('show_only_cost_effectiveness', 'onBill') && !study_data.isOnBillEffective) return false
      if (this.checkQueryKeyValue('show_only_cost_effectiveness', 'tdv') && !study_data.isTdvEffective) return false
      if (this.checkQueryKeyValue('show_only_cost_effectiveness', 'onbill2025') && !study_data.isOnBill2025Effective) return false
      if (this.checkQueryKeyValue('show_only_cost_effectiveness', 'lsc') && !study_data.isLsc2025Effective) return false

      return true
    },

    setOrderColumnDefault() {
      this.orderColumn = this.columnsSelectedForStudy?.columnGroups[0]?.columns[0]?.key
      this.orderDirection = 'desc'
    },

    setOrderColumn({ orderColumn }) {
      if(orderColumn == this.orderColumn) {
        if(this.orderDirection == 'asc') {
          this.orderDirection = 'desc'
        } else {
          this.orderDirection = 'asc'
        }
      } else {
        this.orderColumn = orderColumn
        this.orderDirection = 'asc'
      }
    },

    toggleMeasure(studyDataRow) {
      const findedMeasureAddedIndex = findIndex(this.measureCombinations, { measure: studyDataRow.measure })
      if(findedMeasureAddedIndex > -1) {
        this.measureCombinations.splice(findedMeasureAddedIndex, 1)
        studyDataRow.measure_is_added = false
      } else {
        this.measureCombinations.push(studyDataRow)
        studyDataRow.measure_is_added = true
      }
      this.checkCombinedMeasures()
    },

    getCustomCombinationPresets() {
      if(!this.vintage || !this.prototype) return
      return CustomCombinationApiService.getPresets({ 
        vintage_id: this.vintage.id, 
        prototype_id:  this.prototype.id, 
        climate_zone_raw: this.climate_zone_raw
      })
        .then(({ data }) => {
          this.customCombinationPresets = data.presets
          this.checkCombinedMeasures()
        })
    },

    setCustomCombinationPreset(customCombinationPreset) {
      this.customCombinationSelected = customCombinationPreset

      this.measureCombinations = []

      this.studyDataHere.forEach((studyDataRow) => {
        studyDataRow.measure_is_added = false
      })
      
      if(customCombinationPreset == CUSTOM_COMBINATION_FILTERS.NONE)  {
        this.checkCombinedMeasures()
        return
      }

      for(const measureToAdd of this.customCombinationPresets[customCombinationPreset.value]) {
        const studyDataRowFinded = find(this.studyDataHere, { measure_id: measureToAdd.id})
        if(studyDataRowFinded) {
          this.measureCombinations.push(studyDataRowFinded)
          studyDataRowFinded.measure_is_added = true
        }
      }
      this.checkCombinedMeasures()
    },

    checkCombinedMeasures() {
      this.studyDataHere.forEach((studyDataRow) => {
        if(!studyDataRow.measure) {
          studyDataRow.measure_could_be_combined = false
          return
        }
        
        if(!studyDataRow.measure.include_in_combination) {
          studyDataRow.measure_could_be_combined = false
          return
        }

        for(const item of this.measureCombinations) {
          if(item.measure.exclude_measures && item.measure.exclude_measures.includes(studyDataRow.measure.id)){            
            studyDataRow.measure_could_be_combined = false
            studyDataRow.measure_combination_conflict = item.measure
            return
          }
          if(studyDataRow.measure.exclude_measures && studyDataRow.measure.exclude_measures.includes(item.measure.id)){
            studyDataRow.measure_could_be_combined = false
            studyDataRow.measure_combination_conflict = item.measure
            return
          }
        }

        studyDataRow.measure_combination_conflict = false
        return studyDataRow.measure_could_be_combined = true
      })

      this.$emit('update:combined-measures-count', this.measureCombinations.length)
      this.updatecustomCombinationPresetSelected()
    },

    updateMaxTags() {
      const setMax = (field) => {
        const maxStudyDataIds = this.getStudyDataIdsThatHasMaxFieldValue(field)
        const studyDataMaxSorted = this.studyDataHere.findAllInArray({ id: maxStudyDataIds })
            .sort((a, b) => {
              return (b.data.on_bill_cost_ratio || b.data.on_bill_cost_ratio_2025 || 0) - (a.data.on_bill_cost_ratio || a.data.on_bill_cost_ratio_2025 || 0)
            })
        studyDataMaxSorted.forEach((studyData, index) => { studyData.is_max = index === 0 })
      }
      setMax(compliance_margin().key)
      setMax(edr1_total_margin().key)
    },

    getStudyDataIdsThatHasMaxFieldValue(field) {
      if(this.getTypePrototypeIsNonResidential || this.prototype.title.toLowerCase().includes('multifamily')) return []
      const maxData = { ids: [], value: Number.MIN_SAFE_INTEGER }
      for(const studyData of this.studyDataHere) {
        // For a while, even having the possibility to select non-ce measures, we just check as "MAX" the CE measures
        if (studyData.measure.is_pre_emptive || !studyData.isCostEffective) continue
        
        studyData.is_max = false
        if(studyData.data?.[field] && studyData.data[field] > maxData.value) {
          maxData.ids = [studyData.id]
          maxData.value = studyData.data[field]
        } else if (Number(studyData.data[field]) === Number(maxData.value)) {
          maxData.ids.push(studyData.id)
          maxData.value = studyData.data[field]
        }
      }
      return maxData.ids
    },
    
    updatecustomCombinationPresetSelected() {
      if(this.study.study_group.study_type_id == 2 || !this.measureCombinations.length) {
        return this.$emit('update:customCombinationPresetSelected', CUSTOM_COMBINATION_FILTERS.NONE)
      }

      const customCombinationPresetsMatched = []
      const matchAllPossibleOnBillMeasures = compareArray(this.customCombinationPresets.ALL_POSSIBLE_ON_BILL, this.measureCombinations, 'measure_id')
      if(matchAllPossibleOnBillMeasures) customCombinationPresetsMatched.push(CUSTOM_COMBINATION_FILTERS.ALL_POSSIBLE_ON_BILL.value)

      const matchAllPossibleOnBillOrTdvMeasures = compareArray(this.customCombinationPresets.ALL_POSSIBLE_ON_BILL_OR_TDV, this.measureCombinations, 'measure_id')
      if(matchAllPossibleOnBillOrTdvMeasures) customCombinationPresetsMatched.push(CUSTOM_COMBINATION_FILTERS.ALL_POSSIBLE_ON_BILL_OR_TDV.value)

      const matchAllPossibleOnBill2025Measures = compareArray(this.customCombinationPresets?.ALL_POSSIBLE_ON_BILL_2025 || [], this.measureCombinations, 'measure_id')
      if(this.customCombinationPresets?.ALL_POSSIBLE_ON_BILL_2025?.length && matchAllPossibleOnBill2025Measures) customCombinationPresetsMatched.push(CUSTOM_COMBINATION_FILTERS.ALL_POSSIBLE_ON_BILL_2025.value)

      const matchAllPossibleLscMeasures = compareArray(this.customCombinationPresets?.ALL_POSSIBLE_LSC_2025 || [], this.measureCombinations, 'measure_id')
      if(this.customCombinationPresets?.ALL_POSSIBLE_LSC_2025?.length && matchAllPossibleLscMeasures) customCombinationPresetsMatched.push(CUSTOM_COMBINATION_FILTERS.ALL_POSSIBLE_LSC_2025.value)

      const matchAllPossibleOnBill2025OrLscMeasures = compareArray(this.customCombinationPresets?.ALL_POSSIBLE_ON_BILL_2025_OR_LSC_2025 || [], this.measureCombinations, 'measure_id')
      if(this.customCombinationPresets?.ALL_POSSIBLE_ON_BILL_2025_OR_LSC_2025?.length && matchAllPossibleOnBill2025OrLscMeasures) customCombinationPresetsMatched.push(CUSTOM_COMBINATION_FILTERS.ALL_POSSIBLE_ON_BILL_2025_OR_LSC_2025.value)
      
      if (this.customCombinationSelected && customCombinationPresetsMatched.includes(this.customCombinationSelected.value)) {
        return this.$emit('update:customCombinationPresetSelected', CUSTOM_COMBINATION_FILTERS[this.customCombinationSelected.value])        
      } else if (customCombinationPresetsMatched.length > 1) {
        const getMeasuredOption = Object.values(CUSTOM_COMBINATION_FILTERS).find(obj => obj.policyOptionSlug && obj.policyOptionSlug === this.getPolicyOptionAlgorithm?.slug) || CUSTOM_COMBINATION_FILTERS[customCombinationPresetsMatched[0]]
        return this.$emit('update:customCombinationPresetSelected', getMeasuredOption)
      } else if (customCombinationPresetsMatched.length > 0) {
        return this.$emit('update:customCombinationPresetSelected', CUSTOM_COMBINATION_FILTERS[customCombinationPresetsMatched[0]])
      } else {
        return this.$emit('update:customCombinationPresetSelected', CUSTOM_COMBINATION_FILTERS.CUSTOM)
      }
    },

    addMeasuresPreselected() {
      this.measureCombinations = []
      for(const measureId of this.measuresPreSelectedIds) {
        const studyData = this.studyDataHere.findInArray({ measure_id: measureId })
        if(studyData) {
          studyData.measure_is_added = true
          this.measureCombinations.push(studyData)
        }
      }
      this.updatecustomCombinationPresetSelected()
    },

    openProjectionsModal({ columnKey, measure } = {}) {
      const selectedMeasure = measure ?? this.getStudyDataOrdered[0].measure
      
      this.$eventBus.$emit('openProjectionsModal', {
        studyData: this.getStudyDataOrdered,
        columnSelectedKey: columnKey,
        prototype: this.prototype,
        measure: selectedMeasure,
        vintage: this.vintage,
        fuel: this.fuel,
        building_stock_units: this.building_stock_units,
        building_estimates: this.building_estimates,
      })
    }
  }
}
</script>

<style lang="scss">

  $cell-padding-vertical: 12px;
  $cell-padding-horizontal: 12px;
  $cell-padding-horizontal-half: 8px;

  .forecast-card {
    &.not-expanded {
      .table-wrapper {
        overflow: hidden;
      }
    }

    &--body {
      &:after {
        content: "";
        position: absolute;
        top: 0; left: 0; bottom: 0; right: 0;
        z-index: 20;
      }
    }

    &.is-expanded {
      .forecast-card--body {
        max-height: 100%;

        &::after {
          display: none;
        }
      }    
    }
  }

  .table-wrapper {
    min-height: 265px;
    max-height: calc(100vh - 300px);
    overflow: auto;
    position: relative;
    min-height: 30px;


    tr {
      th, td {
        &:first-child {
          box-shadow: inset -1px 0px 0px #EBEEF0;
        }
      }
    }
    
    thead {
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04), 5px 2px 5px rgba(0, 0, 0, 0.08);

      tr {
        th {
          padding-top: 8px;
          padding-bottom: 8px;
          padding-left: 24px;
          padding-right: 24px;

          > .flex {
            max-width: 230px;
          }

          &.psui-text-xsmall {
            line-height: 120%;
          }

          &:not(.sticky) {
            position: relative;
          }

          &:nth-child(1) {
            padding-left: 0;
          }

          &:not(:nth-child(2)) {
            padding-left: 0;
          }
        }

        &:first-of-type {

          th {
            &:after {
              content: "";
              position: absolute;
              left: 0; bottom: 0px;
              width: calc(100% - 24px); height: 1px;
              display: block;
              box-shadow: inset 0 -1px 0 #d6dde5;
            }

            &:nth-child(2) {
              padding-left: 24px;

              &:after {
                width: calc(100% - 48px);
                left: 24px;
              }
            }

            &:not(:nth-child(2)) {
              padding-left: 0;
            }

            &:last-child {

              &:after {
                left: 0;
                width: 100%;
              }
            }
          }
        }
        
        &:last-of-type {
          th {
            padding-bottom: $cell-padding-vertical;
          }
        } // &:last-of-type
      }
    }

    th {
      padding: 8px 24px 0 0;

      .info-helper {
        &[size="14"] {
          span {
            line-height: 14px;
            &.icon { 
              font-size: 14px; 
            }
          }
        }
        &[size="16"] {
          span {
            line-height: 16px;
            &.icon { 
              font-size: 16px;    
            }
          }
        }
      }
    }

    td {
      padding-left: 24px;
      padding-right: 24px;
    }

    tbody {
      tr {
        border-bottom: 1px solid #E6ECF2;
        
        td {
          background: #ffffff;
          min-height: 45px;

          &:first-child {
            padding-left: 12px;
          }

          button {
            display: none;
          }
          
          &:hover {
            button {
              display: block;
            }
          }
        }

        &:hover {
          td {
            background-color: #F3F6F9;
          }
        }

        &.row-customcombination {
          border-bottom: 0;
          td {
            background-color: #E0EFF6;

            .bars.line {
              background-color: #fff !important;
            }

            p {
              color: #318FAC;
            }

            &:first-of-type {
              box-shadow: inset -1px 0px 0px #C5DEE8;
            }
          }
        }

        &.row-added {
          td {
            transition: none;
            background-color: #ECF7FB; 
          }
        }

        &.row-added-not-cost-effective {
          td {
            transition: none;
            background-color: #F3F6F9;
          }
        }
      }
    }

    @media screen and (min-height: 900px) {
      max-height: 540px;
    }

    &.table-wrapper-new-construction {
      min-height: 130px;

      @media screen and (min-height: 900px) {
        max-height: inherit;
      }
    }
  }    

</style>
