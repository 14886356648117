import { render, staticRenderFns } from "./ComparePoliciesWizardStepOne.vue?vue&type=template&id=149f1252&scoped=true"
import script from "./ComparePoliciesWizardStepOne.vue?vue&type=script&lang=js"
export * from "./ComparePoliciesWizardStepOne.vue?vue&type=script&lang=js"
import style0 from "./ComparePoliciesWizardStepOne.vue?vue&type=style&index=0&id=149f1252&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "149f1252",
  null
  
)

export default component.exports