<template>
  <div class="psui-w-full">
    <ComparisonListTableSkeleton 
      v-if="isLoading || isLoadingFromGoogleLogin" 
    />
    <div
      v-else-if="comparisonsFiltered.length > 0"
      class="psui-w-full psui-flex psui-flex-wrap"
    >
      <ComparisonListTable        
        :comparisons="comparisonsFiltered"
      />
      <div 
        id="comparisonsListTableFooter"
        data-test-id="comparisons-list-table-footer"
        class="psui-text-small psui-flex psui-space-x-2 psui-mx-8 psui-mb-4"
      >
        <p 
          data-test-id="comparison-counts"
          class="psui-text-gray-60"
        >
          {{ `You are seeing ${comparisonsFiltered.length}/${getAllUserComparisons.length} comparisons` }}
        </p>
        <a
          v-if="hasAnyFilterApplied"
          data-test-id="remove-filters-comparison-list"
          class="psui-font-bold ml-1 psui-text-blue-60 psui-cursor-pointer"
          @click="removeFilters"
        >Remove Filters to see all</a>
      </div>
    </div>
    <ComparisonFilteredListEmpty
      v-else-if="comparisonsFiltered.length == 0 && getAllUserComparisons.length > 0"
      :user-comparisons="getAllUserComparisons"
      @removeFilters="removeFilters"
    />
    <ComparisonListEmpty
      v-else 
      :jurisdiction="jurisdiction"
    />
   
    <ComparisonCreateDrawer
      :jurisdiction="jurisdiction"
      :jurisdictions="jurisdictions"
    />
  </div>
</template>

<script>
import ComparisonListTableSkeleton from '../skeleton/ComparisonListTableSkeleton.vue'
import ComparisonListTable from './ComparisonListTable.vue'
import ComparisonListEmpty from './ComparisonListEmpty.vue'
import ComparisonFilteredListEmpty from './ComparisonFilteredListEmpty.vue'
import ComparisonCreateDrawer from '../ComparisonCreateDrawer.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ComparisonListUserComparisons',
  components: {
    ComparisonCreateDrawer,
    ComparisonListEmpty, 
    ComparisonListTable, 
    ComparisonListTableSkeleton,
    ComparisonFilteredListEmpty
  },
  props: {
    'is-loading' : {
      type: Boolean,
      default: false
    },
    'is-loading-from-google-login' : {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    comparisonsFiltered: []
  }),
  computed: {
    getLoggedUser() {
      return this.$store.state.auth.loggedUser
    },
    jurisdiction() {
      return this.jurisdictions ? this.jurisdictions.find((jur) => parseInt(jur.id) === parseInt(this.$route.query.jurisdiction_id)) : false
    },
    jurisdictions() {
      return this.$store.getters['comparison/getterAvailableJurisdictions'] ?? []
    },
    hasAnyFilterApplied() {
      return this.comparisonsFiltered.length !== this.getAllUserComparisons.length
    },
    getAllUserComparisons() {
      return this.$store.getters['comparison/getterUserComparisons']()
    },
    filterByAuthor() {
      return this.$store.state.auth.user_preferences?.policies?.comparison?.filterByAuthor ?? []
    },
    listOfJurisdictionIdsThatShouldBeIncluded() {
      return this.$store.state.auth.user_preferences?.policies?.comparison?.filterByJurisdiction ?? []
    },
    ...mapGetters(['getterLoggedUser']),
  },
  watch: {
    listOfJurisdictionIdsThatShouldBeIncluded: {
      deep: true,
      immediate: true,
      handler() {
        this.updateComparisonFiltered()
      }
    },
    filterByAuthor: {
      deep: true,
      immediate: true,
      handler() {
        this.updateComparisonFiltered()
      }
    },
    getAllUserComparisons: {
      deep: true,
      immediate: true,
      handler() {
        this.updateComparisonFiltered()
      }
    }
  },
  mounted() {
    this.$store.commit('assumptions/closeDrawer')
    this.removeFilters()
  },
  methods: {
    updateComparisonFiltered() {
      this.comparisonsFiltered = this.getAllUserComparisons
          .filter(this.filterComparisonsByJurisdiction)
          .filter(this.filterComparisonsByAuthor)
      this.$forceUpdate()
    },
    filterComparisonsByJurisdiction(comparison) {
      const shouldIncludeAllJurisdisctions = !this.listOfJurisdictionIdsThatShouldBeIncluded || this.listOfJurisdictionIdsThatShouldBeIncluded.length === 0
      const comparisonJurisdictionIsSelected = this.listOfJurisdictionIdsThatShouldBeIncluded.includes(comparison.jurisdiction_id?.toString())
      return shouldIncludeAllJurisdisctions || comparisonJurisdictionIsSelected ? true : false
    },
    filterComparisonsByAuthor(comparison) {
      let shouldInclude = false
      const comparisonType = this.$store.getters['comparison/getterComparisonType'](comparison.id)

      this.filterByAuthor.forEach(comparison_type_selected => {
        if (comparison_type_selected === comparisonType) shouldInclude = true
      })

      return shouldInclude || !this.filterByAuthor.length
    },
    removeFilters() {
      this.$store.dispatch('setUserPreferences', { mutation:'resetUserPreferencesFilterByJurisdictionAndFilterByAuthorForComparisons' })
    },
  }
}
</script>
