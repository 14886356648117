<template>
  <ul>
    <li 
      class="pt-8 px-6 border-b border-deepsky-200 transition-all text-deepsky-900 hover:text-deepsky-500 cursor-pointer" 
      @click="openQuickGuideUrl()"
    >
      <h2 class="text-header-6 font-bold">
        Quick Guide
      </h2>
      <p class="text-header-4 text-ash-500 pt-4 pb-8">
        We help you understand what a cost-effectiveness study is and why it matters.
      </p>
    </li>
    <li 
      class="pt-8 px-6 border-b text-deepsky-900 border-deepsky-200 hover:text-deepsky-500 transition-all cursor-pointer" 
      @click="openUrl('https://localenergycodes.com/content/contact-us')"
    >
      <h2 class="text-header-6 font-bold">
        Help
      </h2>
      <p class="text-header-4 text-ash-500 pt-4 pb-8">
        Contact us for assistance with this tool.
      </p>
    </li>
  </ul>
</template>

<script>
import { GA_LABELS } from '@/mixins/GaEventsMixin'
export default {
  name: 'SidebarMenuHelp',
  methods: {
    openChangeLog() {     
      this.$eventBus.$emit('openDrawerChangelog')
    },    
    openUrl(url) {
      window.open(url)
    },
    openQuickGuideUrl() {
      this.openUrl('https://intercom.help/explorer-local-energy-codes/en/collections/3076245-quick-guide')
      this.gaEvent(GA_LABELS.MENU_INFO_QUICK_GUIDE, { value: 'MENU_INFO_QUICK_GUIDE' })
    }
  }
}
</script>

<style scoped>
  p {
    max-width: 300px;
  }
</style>