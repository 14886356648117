var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"psui-flex psui-flex-col psui-gap-4 psui-w-full"},_vm._l((_vm.climateZones),function(climate_zone,climate_zone_index){return _c('div',{key:`requirements-by-fuel climate-zone_${climate_zone.id}`,staticClass:"policy-container-card psui-w-full psui-flex psui-py-4 psui-px-6 psui-items-center psui-rounded-md border-2/3 psui-border-gray-30 psui-border-dashed transition-all",class:{ 
      'psui-bg-gray-20 psui-border-0' : _vm.isCustomCombinationsByClimateZoneAreVisible({ climate_zone_raw: climate_zone.raw }),
      'psui-cursor-pointer' : !_vm.isLoading
    },on:{"click":function($event){return _vm.addOrEditRequirements({ climate_zone_raw: climate_zone.raw, index: climate_zone_index })}}},[_c('div',{class:{
        'psui-block' : _vm.getterContentContainerWidth < 768,
        'psui-flex  psui-w-full' : _vm.getterContentContainerWidth > 768,
      }},[_c('div',{staticClass:"psui-flex psui-flex-col psui-items-start psui-justify-center psui-flex-shrink-0",staticStyle:{"width":"190px"}},[_c('div',{staticClass:"psui-flex psui-flex-row psui-items-center psui-justify-center psui-flex-shrink-0"},[_c('h5',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getTooltip(climate_zone, climate_zone_index)),expression:"getTooltip(climate_zone, climate_zone_index)"}],staticClass:"psui-flex psui-text-h6 psui-font-bold psui-text-gray-70 psui-mb-2",on:{"mouseenter":function($event){_vm.showTooltip = { climate_zone_index }},"mouseleave":function($event){_vm.showTooltip = null}}},[_vm._v(" Climate Zone "+_vm._s(climate_zone.prefix)+" ")])]),_c('button',{staticClass:"psui-text-blue-60 psui-font-bold psui-text-small block"},[_vm._v(" "+_vm._s(_vm.isCustomCombinationsByClimateZoneAreVisible({ climate_zone_raw: climate_zone.raw}) ? !_vm.getterUserCanEditPolicy ? 'Open Requirements' : 'Edit Requirements' : 'Add Requirements')+" ")])]),(_vm.isCustomCombinationsByClimateZoneAreVisible({ climate_zone_raw: climate_zone.raw }))?_c('div',{staticClass:"psui-w-full psui-flex psui-flex-grow",class:{
          'psui-space-y-6 pt-6 psui-flex-wrap' : _vm.getterContentContainerWidth <= 1024,
          'psui-space-x-6  ' : _vm.getterContentContainerWidth > 1024,
        }},_vm._l((_vm.getCustomCombinationsByClimateZoneSorted({ climate_zone_raw: climate_zone.raw})),function(custom_combination,custom_combination_index){return _c('PolicyRequirementFuelCell',{key:`custom_combination_${custom_combination_index}`,class:{
            'psui-w-full' : _vm.getterContentContainerWidth <= 1024,
            'psui-w-1/2 ' : _vm.getterContentContainerWidth > 1024,
          },attrs:{"custom_combination":custom_combination}})}),1):(_vm.isDeletingPolicy === climate_zone.raw)?_c('PolicyRequirementFuelCellSkeleton',{attrs:{"container-width":_vm.getterContentContainerWidth}}):_vm._e()],1),_c('div',{staticClass:"psui-pl-4 w-8 psui-mb-auto"},[(_vm.getterUserCanEditPolicy && _vm.isCustomCombinationsByClimateZoneAreVisible({ climate_zone_raw: climate_zone.raw }))?_c('button',{on:{"click":function($event){$event.stopPropagation();return _vm.deleteCustomCombinationsByClimateZone({ climate_zone_raw: climate_zone.raw })}}},[_c('PsIcon',{staticClass:"psui-text-gray-40 psui-cursor-pointer leading-none hover:psui-bg-blue-10 hover:psui-text-blue-60 psui-gap-3 psui-px-5 psui-py-1 transition-all",attrs:{"icon":"delete_outline","size":"18"}})],1):_vm._e()])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }