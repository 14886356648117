export default class Container {

  constructor( obj = {}) {
    this.id = obj.id || null
    this.policy_id = obj.policy_id || null
    this.study_type_id = obj.study_type_id || null
    this.title = obj.title || null
    this.type_prototype_id = obj.type_prototype_id || null
    this.type_prototype_order = obj.type_prototype_order || null 
    this.custom_combinations = obj.custom_combinations || null
  }

}