import PolicyApiService from '@/services/api/PolicyApiService'
import Store from '@/store'
import router from '@/router/index.js'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import MainVuex from '@/store'

export default [
  //STEP 1
  // {
  //   id: 'video-helper',
  //   component: 'StepHelperVideo',
  //   title: 'Some cool video from Jasmine!',
  //   guide: 'STEP 1 OF 4',
  //   nextButtonText: 'NEXT',
  // },
  {
    id: 'policy-basics',
    component: 'StepJurisdictionAndTitle',
    title: 'Let’s begin your new existing residential building policy...',
    // subtitle: 'We’ll ask you a few questions. You can always revise your choices later, and you can draft as many policies as you like.',
    guide: 'STEP 1 OF 4',
    breadcrumbTitle: 'Policy Basics',
    hasNextHelper: () => true,
    getNextButtonText: 'NEXT',
    getNextButtonTooltip: (policy) => {
      if(!policy.jurisdiction) return 'Please, select a City or County.'
      if(!policy.title) return 'Please, add a Policy name.'
      return null
    },
    getNextStep() {
      return 'flexible-path'
    },
    beforeNext: async (policy) => {
      if(policy.id) {
        policy.loading = true
        await PolicyApiService.update(policy.id, policy)
        policy.loading = false
      }
    }
  },

    // STEP 2
    {
      id: 'flexible-path',
      component: 'StepFlexiblePath',
      title: 'Would you like to allow a flexible compliance path?',
      guide: 'STEP 2 OF 4',
      breadcrumbTitle: 'Compliance Method',
      hasNextHelper: () => true,
      getNextButtonText: (policy) => {
        if(policy.is_flexible_path) return 'Establish target score'
        return 'CHOOSE MEASURE SELECTION METHOD'
      },
      getNextStep() {
        return 'auto-generate'
      },
      beforeNext: async (policy) => {
        if(policy.id) {
          policy.loading = true
          await PolicyApiService.update(policy.id, policy)
          policy.loading = false
        }
      }
    },

      // STEP 3
      {
        id: 'auto-generate',
        component: 'StepAutoGenerate',
        title: (policy) => {
          if (policy.is_flexible_path) {
            return 'How would you like to establish your policy’s target scores?'            
          }
          return 'How would you like to choose the measures required by your policy?'
        },
        guide: 'STEP 3 OF 4',
        breadcrumbTitle: 'Target Score Cost-Effectiveness',
        hasNextHelper: (policy) => policy.is_auto_generated,
        getNextButtonText: (policy) => {
          if(policy.is_auto_generated) return 'CHOOSE BUILDING TYPE'
          return 'CREATE POLICY'
        },
        getNextStep(policy) {
          if(policy.is_auto_generated) return 'building-types'
          return 'finish-manual-generation-policy'
        },
        beforeNext: async (policy) => {
          if(!policy.id) {
            policy.loading = true
            const study_type_slug = Store.getters['globalEntities/StudyType/getterStudyTypeExistingBuildings']?.slug
            const type_prototype_slug = Store.getters['globalEntities/TypePrototype/getterGlobalTypePrototypeSingleFamily']?.slug
            const policy_id = await Store.dispatch('policy/createPolicy', { policy, type_prototype_slug, study_type_slug })
            const newPolicy = await PolicyApiService.getNewPolicy(policy_id, true)
            if(router.history.current.name === 'PoliciesList') {
              const newQuery = Object.values(STUDY_TYPES_DB_SLUGS).join()
              router.push({query:{only_study_type:newQuery}})
            } else if (router.history.current.name === 'ComparisonShow'){
              const getLastjurisdictionVisitedId = MainVuex.getters['lastJurisdictionVisited'].id
              const getUserPoliciesIds = MainVuex.getters['policy/getterUserPoliciesGroupedByJurisdiction'][getLastjurisdictionVisitedId].map((i) => i.id).join(',')
              router.push({ name: 'ComparisonShow', query: { jurisdiction_id:getLastjurisdictionVisitedId, policies_ids:getUserPoliciesIds }})
            }
            policy.loading = false
            return newPolicy
          } else {
            policy.loading = true
            await PolicyApiService.update(policy.id, policy)
            policy.loading = false
          }
        }
      },

        // STEP 4
        {
          id: 'building-types',
          component: 'StepBuildingTypes',
          title: 'What building types do you want to include in your policy?',
          subtitle: 'We will include all compatible cost-effective measures from the latest study results for each building type you select.',
          guide: 'STEP 4 OF 4',
          breadcrumbTitle: 'Building Types',
          shouldCreatePolicy: true,
          getNextButtonText: () => {
            return 'CREATE POLICY'
          },
          getNextStep() {
            return 'generating-policy-loader'
          },
          getNextButtonTooltip: (policy) => {
            if(!policy.typePrototypes.length) return 'Choose at least one building type.'
            return null
          },
          beforeNext: async (policy) => {
            const type_prototype_ids = policy.typePrototypes.map(item => item.id)
            policy.loading = true
            await PolicyApiService.autoGenerate({ type_prototype_ids, policy_id : policy.id }, { ignoreFeedback: true })
            policy.policyMap = await PolicyApiService.getPolicyMap({ policy_id : policy.id })
            if(router.history.current.name === 'PoliciesList') {

              const newQuery = Object.values(STUDY_TYPES_DB_SLUGS).join()

              router.push({query:{only_study_type:newQuery}})
            } else if (router.history.current.name === 'ComparisonShow'){
              const getLastjurisdictionVisitedId = MainVuex.getters['lastJurisdictionVisited'].id
              const getUserPoliciesIds = MainVuex.getters['policy/getterUserPoliciesGroupedByJurisdiction'][getLastjurisdictionVisitedId].map((i) => i.id).join(',')
              router.push({ name: 'ComparisonShow', query: { jurisdiction_id:getLastjurisdictionVisitedId, policies_ids:getUserPoliciesIds }})
            }

            policy.loading = false
            return false
          }
        },

          // LOADER
          {
            id: 'generating-policy-loader',
            component: 'StepGeneratePolicyLoading',
            title: 'Generating policy',
            getNextStep: () => {
              return 'finish-auto-generation-policy'
            }
          },

            // FINISH
            {
              id: 'finish-auto-generation-policy',
              component: 'StepAutoGeneratePolicySuccess',
              title: 'Your policy is ready!',
              subtitle: (policy) => {
                if(policy.is_flexible_path) {
                  return 'Measure menu tables are ready for each of the climate zones, building types and vintages shown below. Next you can review these tables and customize them.'
                }
                return `All compatible cost-effective measures from the latest study results are included in your policy. Now you can explore your policy's potential impact, share it with a colleague, and more.`
              }
            },

        // FINISH
        {
          id: 'finish-manual-generation-policy',
          component: 'StepManualFinish',
          title: 'Your policy is ready to build.',
        },

]