import Api from '@/util/Api'
import Store from '@/store'
export default class UserEventApiService {

  static log({ event = '', notes = '', table = null, table_item_id = null  }) {
    if(!event) return
    setTimeout(() => {
      const deviceId = Store.getters['general/getterDeviceInfo']?.uid
      if(deviceId) {
        console.debug(`Log system event '${event}' sent for device id '${deviceId}'`)
        return Api.post(`/api/users/user_events/store`, { event, notes, table, table_item_id })
      }
    }, 200)
  }

}
