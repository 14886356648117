import Api from '@/util/Api'

export default class JurisdictionCollectionApiService { 
  
  static update_or_create(payload, config) {
    return Api.post('jurisdiction_collection/update_or_create_batch', payload, config)
    .then(({ data }) => {
      console.log(data)
        return data  
    })
    .catch(err => console.log(err))
  }
}