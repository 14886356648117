<template>
  <div
    v-click-outside="close"
    class="jurisdiction--select overflow-x-none relative"
    :class="cssClass"
    @keydown.up="navigate('up')"
    @keydown.down="navigate('down')"
    @keydown.enter="selectOnEnter()"
    @keydown.tab="selectOnEnter()"
    @keydown.esc="close()"
  > 
    <div class="input-wrapper relative">
      <div
        class="width-machine"
      >
        {{ search ? search : placeholder }}       
      </div>
      
      <input
        :id="randomStringToInputIdAndName"
        ref="input"
        v-model="search"
        type="text"
        :label="label"
        :placeholder="placeholder"
        :class="inputClass"
        :name="randomStringToInputIdAndName"
        data-test-id="home-jurisdiction-select-input"
        class="psui-appearance-none"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        @input="handleRequest()"
        @focus="onFocus"
        @blur="onBlur"
      >
      <transition
        enter-active-class="transition  ease-out duration-100 transform"
        enter-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="transition-all ease-in duration-100 transform"
        leave-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95"
      >
        <PsButton
          :label="changeTextBasedOnWidth"
          :disabled="isFocused || disableButton"
          size="big"
          layout="onlytext"
          class="psui-my-2"
          :icon="icon"
          :icon-position="iconPosition"
          @click="$emit('click')"
        />
      </transition>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100 transform"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="transition ease-in duration-100 transform"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div 
        v-if="search"
        class="psui-origin-top-right psui-absolute psui-left-0 psui-overflow-y-auto psui-mt-4 psui-rounded-lg psui-border psui-border-blue-10 psui-p-4 psui-shadow-elevation-20 psui-z-40 psui-bg-white psui-min-w-full max-h-1/3"
        :style="{ width: `${width}px`}"
        role="menu" 
        aria-orientation="vertical"
      >
        <AppLoader v-if="!requestFetched && search && !jurisdictions.length" />
        <div class="psui-w-full psui-flex psui-flex-wrap psui-bg-white">
          <div
            v-if="requestFetched && !jurisdictions.length"
            class="psui-w-full psui-p-3 psui-flex psui-flex-col"
          >
            <p class="psui-text-h5 psui-text-red-20">
              Hmmmm
            </p>
            <p class="psui-text-small psui-text-gray-60">
              Please check the spelling and try again
            </p>
          </div>
          <ul
            v-else
            class="psui-w-full psui-divide-y psui-divide-gray-20"
          >
            <li
              v-for="(jurisdiction, index) in jurisdictions"
              :id="`jurisdiction-list-item-${index}`"
              :key="`dropdown-jurisdiction-${jurisdiction.id}`"
              :data-test-id="`jurisdiction-list-item-${index}`"
              tabindex="0"
              :class="{'psui-bg-gray-10' : index == currentSelected}"
              class="psui-w-full psui-flex psui-justify-between psui-items-center psui-p-4 psui-text-h5 psui-text-gray-80 psui-cursor-pointer focus:psui-outline-none psui-transition"
              @click="setJurisdiction(jurisdiction)"
              @mouseover="currentSelected = index"
            >
              <h3>{{ jurisdiction.titles.title }}</h3>
              <p class="psui-text-small psui-text-gray-50 psui-ml-auto psui-transition">
                {{ jurisdiction.titles.type_long }}
              </p>              
              <div
                class="psui-transition psui-pl-3"
                :class="index == currentSelected ? 'psui-opacity-100' : 'psui-opacity-0'"
              >
                <PsIcon 
                  icon="arrow_forward"
                  size="24"
                  class="psui-text-blue-60 psui-transition"                
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { delayExecution } from '@/util/Functions'
import { randomString } from '@/util/Functions'

export default {
  name: 'HomeJurisdictionSelect',
  props: {
    width: {
      default: 244
    },
    focusOnMount: {
      default: false
    },
    shouldScroll: {
      default: false
    },
    inputClass: {
      default: 'psui-text-blue-60 psui-text-h5'
    },
    label: {
      type: String,
      default: 'Type a City or County'
    },
    selectOnlyFirstClimateZone: {
      type: Boolean,
      default: false
    },
    jurisdictionSelected: {
      type: [ Object, Boolean ],
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    iconPosition: {
      type: String,
      default: 'left'
    },
    buttonLabel: {
      type: String,
      default: ''
    },
    disableButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: '',
      showDropDown: true,
      jurisdictions: [],
      requestFetched: false,
      cancelToken: null,
      currentSelected: 0,
      isFocused: false,
      randomStringToInputIdAndName: randomString(16),
      shouldChangeLabelButton: false,
    }
  },
  computed: {
    getLastJurisdictionVisited() {
      return this.$store.getters['lastJurisdictionVisited']
    },
    placeholder() {
      return this.jurisdictionAsText && !this.isFocused ? this.jurisdictionAsText : this.label
    },    
    jurisdictionAsText() {
      const policyTextRephrase = this.$options.filters.policyTextRephrase

      let text
      if (this.jurisdictionSelected?.title_type && !this.shouldChangeLabelButton) {
        text = `Exploring policy options and resources for ${this.$options.filters.editJurisdictionTitleTypeBasedOnJurisdictionType(this.jurisdictionSelected, 'title_type')}`
      } else if (this.jurisdictionSelected?.title_type) {
        text = `Exploring resources for ${this.$options.filters.editJurisdictionTitleTypeBasedOnJurisdictionType(this.jurisdictionSelected, 'title_type')}`
      } else {
        return false
      }

      return policyTextRephrase(text)
    },
    cssClass() {
      let cssClass = []
      
      if (this.jurisdictionSelected) {
        cssClass.push('is-selected')
      }
      
      if (this.isFocused) {
        cssClass.push('is-focused')
      }
      
      if (this.showDropDown) {
        cssClass.push('is-searching')
      }

      return cssClass
    },
    changeTextBasedOnWidth(){
      return this.shouldChangeLabelButton ? 'Explore' : this.buttonLabel
    }
  },
  mounted() {
    window.addEventListener('resize', this.setTextBasedOnWidthSize)

    if(this.focusOnMount) this.$refs.input.focus()
  },
  beforeDestroy() {
    window.removeEventListener('resize',this.setTextBasedOnWidthSize)
  }, 
  methods: {

    async getJurisdictions() {
      this.cancelToken = this.$api.CancelToken.source()
      this.$api.get(`/app/jurisdictions/${this.search}`, {cancelToken: this.cancelToken.token })
        .then(({ data }) => {
          this.jurisdictions = data.jurisdictions
          this.requestFetched = true
          this.cancelToken = null
          this.currentSelected = 0
        })
        .catch( () => {})
    },

    close() {
      this.search = ''
      this.requestFetched = false
      this.$emit('closeInput')
      this.currentSelected = -1
      this.jurisdictions = []
    },
    onBlur() {
      this.isFocused = false
    },
    onFocus() {
      this.isFocused = true
      if(!this.shouldScroll) return
      let bounds = this.$refs.input.getBoundingClientRect()
      const scrollTop = (document.documentElement.clientWidth - bounds['top']) -30
      document.getElementById('main-content').scrollTo({
        top: scrollTop,
        left: 0,
        behavior: 'smooth'
      })
    },

    selectOnEnter() {
      if(this.currentSelected < 0) return
      const jurisdictionSelected = this.jurisdictions[this.currentSelected]
      if(this.jurisdictions.length) this.setJurisdiction(jurisdictionSelected)
    },

    setJurisdiction(jurisdiction) {
      this.$emit('change', jurisdiction)
      this.close()
      this.$emit('click')
      this.search = ''
      this.requestFetched = false
    },

    navigate (direction) {
      if(this.jurisdictions.length) {
        if (direction == 'up') {
          if(this.currentSelected > 0) {
            this.currentSelected--
            document.getElementById(`jurisdiction-list-item-${this.currentSelected}`).focus()
          }
        } else {
          if(this.currentSelected < this.jurisdictions.length-1) {
            this.currentSelected++
            document.getElementById(`jurisdiction-list-item-${this.currentSelected}`).focus()
          }
        }
      }
    },

    handleRequest() {
      if (this.cancelToken) {
        this.cancelToken.cancel("Operation canceled due to new request.")
      }
      if(!this.search || this.search == '') {
        this.jurisdictions = []
        return
      }
      this.requestFetched = false
      delayExecution(300, this.getJurisdictions)
    },
    setTextBasedOnWidthSize(){
      const getDocumentWidth = window.innerWidth
      if(getDocumentWidth <= 1280 && !this.shouldChangeLabelButton ) {
        this.shouldChangeLabelButton = true
      } else if(getDocumentWidth > 1280 && this.shouldChangeLabelButton) {
        this.shouldChangeLabelButton = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .jurisdiction--select {
    width: fit-content;
    background: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.03), 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    input::placeholder {
      color: #318FAC;
    }
  }

  .input-wrapper {
    position: relative;
    overflow: visible;
    width: 100%;
    display: inline-flex;
    font-weight: 700;
    
    
    .width-machine {
      opacity: 0;
      background: #318FAC;
      display: inline-flex;
      align-items: center;
      position: relative;
      margin-right: 47px;
      min-width: 320px;
      font-size: 18px;
      line-height: 120%;
    }

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      font-weight: 700;
      left: 0;
      top: 0;
      color: #318FAC;
      padding: 10px 16px;
      border-radius: 6px;
    }

    .psui-el-button{
      position: relative;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  input:-webkit-credentials-autofill:focus,
  input:-webkit-credentials-autofill:focus,
  input::-webkit-contacts-auto-fill {
    visibility: hidden !important;
  }

  ::v-deep {
    .psui-el-button.size-big.icon-left i {
      margin:0;
    }
  }
</style>