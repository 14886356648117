<template>
  <div
    class="psui-w-full psui-h-full psui-flex psui-flex-col psui-overflow-y-auto"
  >
    <PolicyOptionsContent 
      class="w-full" 
    />
  </div>
</template>

<script>
import PolicyOptionsContent from "@/modules/app/policy/options/PolicyOptionsContent.vue"
import { mapGetters } from 'vuex'

export default {
  name: "PolicyOptionsIndex",
  components: {
    PolicyOptionsContent,
  },
  computed: {
    getUser() {
      return this.$store.state.auth.loggedUser
    },
    ...mapGetters(['lastJurisdictionVisited'])
  },
  async mounted() {
    if(!this.lastJurisdictionVisited) this.$eventBus.$emit('openModalJurisdictionSelection', { skipRedirect: true })
  }
}
</script>
