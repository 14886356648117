
import PolicyRequirementsSummaryExisting from './PolicyRequirementsSummaryExisting'
import PolicyRequirementsSummaryNew from './PolicyRequirementsSummaryNew'

export default class PolicyRequirementsSummaryPdf {

  policyType = 'new'

  constructor(args) {
    this.policyType = args.policyType
    return this.generatePdf(args)
  }

  generatePdf(args) {
    if(this.policyType === 'new') {
      return new PolicyRequirementsSummaryNew(args)
    } else {
      return new PolicyRequirementsSummaryExisting(args)
    }
  }

}